import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme
} from '@mui/material';
import { styled } from '@mui/system';

const Styled = {
  Container: styled(Stack)({
    position: 'relative'
  }),
  Label: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'required'
  })<{ required?: boolean }>(({ required, theme }) => ({
    marginBottom: '11px',
    position: 'relative',

    '&:after': {
      content: required ? '"*"' : '""',
      color: theme.palette.error.main,
      marginLeft: '4px'
    }
  })),
  ErrorText: styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    marginTop: '5px'
  }))
};

interface Props {
  name: string;
  label: string;
  options: string[];
  dataTestId?: string;
  required?: boolean;
  exclusive?: boolean;
  placeholder?: string;
}

const ReactHookFormsToggle: React.FC<Props> = ({
  name,
  label,
  required,
  options,
  exclusive = false
}) => {
  const theme = useTheme();

  const {
    control,
    formState: { errors }
  } = useFormContext();

  const handleToggle = (option: string, value, onChange): void => {
    let newSelections: string[];

    if (value.includes(option)) {
      newSelections = value.filter((opt) => opt !== option);
    } else {
      newSelections = [...value, option];
    }

    onChange(newSelections);
  };

  return (
    <Styled.Container>
      <FormControl fullWidth>
        <Styled.Label
          variant="EC_TYPE_BASE"
          data-testid={`${name}-select-label`}
          required={required}
        >
          {label}
        </Styled.Label>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }): JSX.Element => (
            <ToggleButtonGroup
              value={value}
              aria-label={`${name}-label`}
              id={name}
              exclusive={exclusive}
              sx={{
                height: '44px',
                width: '148px',
                backgroundColor: theme.palette.WHITE.main
              }}
            >
              {options.map((option) => (
                <ToggleButton
                  key={option}
                  value={option}
                  sx={{ width: '75px' }}
                  onClick={(): void => handleToggle(option, value, onChange)}
                >
                  <Typography
                    variant="EC_TYPE_2XS"
                    color={theme.palette.GRAY_3.main}
                  >
                    {option}
                  </Typography>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        />

        {errors[name] && (
          <Styled.ErrorText
            variant="EC_TYPE_2XS"
            data-testid={`${name}-error-message`}
          >
            {errors[name].message}
          </Styled.ErrorText>
        )}
      </FormControl>
    </Styled.Container>
  );
};

export default ReactHookFormsToggle;
