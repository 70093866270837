import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    padding: '24px',
    border: `1px solid ${theme.palette.GOLD_1.main}`,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
    backgroundColor: theme.palette.WHITE.main,
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.gold1.main,
      border: '1px solid transparent'
    }
  })),
  Content: styled(Box)({
    flex: 'auto'
  }),
  Title: styled(Typography)(({ theme }) => ({
    color: theme.palette.GOLD_1.main,
    marginBottom: '8px'
  }))
};

interface Props {
  title: string;
  text: string;
  image: React.ReactNode;
  handleSelection: () => void;
  button?: React.ReactNode;
}

const CreateAccountTypeOption: React.FC<Props> = ({
  title,
  text,
  image,
  handleSelection,
  button
}) => {
  return (
    <Styled.Root onClick={handleSelection}>
      <Box>{image}</Box>
      <Styled.Content>
        <Styled.Title variant="EC_TYPE_LG">{title}</Styled.Title>
        <Box>
          <Typography variant="body1">{text}</Typography>
          {button}
        </Box>
      </Styled.Content>
    </Styled.Root>
  );
};

export default CreateAccountTypeOption;
