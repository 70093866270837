import * as React from 'react';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Styled = {
  DividerBox: styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: '22px',
    '& .divider-counter': {
      paddingRight: '12px'
    },
    '& .divider-avatar': {
      width: '20px',
      height: '20px',
      backgroundColor: theme.palette.GRAY_3.main
    },
    '& .divider-number': {
      color: theme.palette.neutral.contrastText,
      lineHeight: '20px'
    },
    '& .divider-div': {
      borderBottom: `1px solid ${theme.palette.GRAY_3.main}`,
      width: '100%'
    },
    '& .divider-delete i': {
      color: theme.palette.GRAY_3.main,
      '&:hover': {
        cursor: 'pointer',
        color: theme.palette.red2.main
      }
    }
  })),
  TrashIcon: styled('i')({
    fontSize: '16.67px',
    lineHeight: '16.67px',
    paddingLeft: '12px'
  })
};

interface DividerProps {
  count: number;
  handleDeleteField: () => void;
}

/**
 * Divider between RHF Field Array objects. Place inside Grid container
 */
const FormFieldArrayDivider = ({
  count,
  handleDeleteField
}: DividerProps): React.ReactElement => {
  return (
    <Grid item xs={12}>
      <Styled.DividerBox data-testid="field-array-divider-root">
        <span className="divider-counter">
          <Avatar className="divider-avatar">
            <Typography
              data-testid="field-array-divider-counter"
              variant="EC_TYPE_2XS"
              className="divider-number"
            >
              {count}
            </Typography>
          </Avatar>
        </span>
        <div className="divider-div" />
        <span
          data-testid="field-array-divider-remove-btn"
          className="divider-delete"
          onClick={handleDeleteField}
        >
          <Styled.TrashIcon className="ri-delete-bin-fill" />
        </span>
      </Styled.DividerBox>
    </Grid>
  );
};

export default FormFieldArrayDivider;
