export interface Codebook<ValueType = number, LabelType = string> {
  value: ValueType;
  label: LabelType;
}

export enum EcCampus {
  AUSTIN = 1,
  BOULDER = 2,
  ONLINE = 3
}

export interface CareerRolesCodebook {
  value: number;
  label: string;
}

export enum SkillType {
  TECHNICAL_SKILL = 1,
  SOFT_SKILL = 2
}

export interface SkillCodebook extends Codebook {
  type: SkillType;
}

export enum BadgeType {
  CANDIDATE_BADGE = 1,
  EMPLOYER_BADGE = 2
}

export interface BadgeCodebook extends Codebook {
  type?: BadgeType;
  workAndLearn?: boolean;
}

export interface PublicCodebooks {
  graduationTypes: Codebook[];
  interests: Codebook[];
}

export interface CandidateCodebooks {
  careerRoles: Codebook[];
  certificates: Codebook[];
  yearsOfExperience: Codebook[];
}

export interface ApplicationStatusCodebook extends Codebook {
  selectable: true;
  subStatuses?: ApplicationStatusCodebook[];
}

export enum ApplicationStatus {
  INVITED = 1
  //Add other values if needed
}

export interface CodebookCountInterface extends Codebook {
  count: number;
}

export interface ExternshipProgramCodebook extends Codebook {
  versionCode: string;
  versionName: string;
}

export interface ExternshipCourseCodebook {
  label: string;
  shortName: string;
  hours: number;
  value: number;
  programs: Codebook[];
}

export interface ExternshipStartDateCodebook {
  academicYear: number;
  startDate: Date;
}
