import * as React from 'react';
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { styled } from '@mui/system';

const Styled = {
  Container: styled(Stack)({
    position: 'relative'
  }),
  LabelContainer: styled(Stack)({
    justifyContent: 'space-between',
    alignItems: 'center'
  }),
  Label: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'required'
  })<{ required?: boolean }>(({ required, theme }) => ({
    marginBottom: '11px',
    position: 'relative',

    '&:after': {
      content: required ? '"*"' : '""',
      color: theme.palette.error.main,
      marginLeft: '4px'
    }
  })),
  ErrorText: styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    marginTop: '5px'
  }))
};

interface Props {
  name: string;
  label?: string;
  options: { label: string; value: any }[];
  dataTestId?: string;
  rules?: RegisterOptions;
  placeholder?: string;
  tooltip?: JSX.Element;
}

const ReactHookFormsMultiSelect: React.FC<Props> = ({
  name,
  label,
  options,
  dataTestId,
  rules,
  placeholder,
  tooltip
}) => {
  const theme = useTheme();

  const [displayLabels, setDisplayLabels] = React.useState<string[]>([]);

  const {
    control,
    watch,
    formState: { errors }
  } = useFormContext();

  const fieldValue = watch(name);

  const getLabel = (value: any): string => {
    const option = options.find((opt) => opt.value === value);
    return option?.label ?? '';
  };

  React.useEffect(() => {
    const value = fieldValue;
    const labels = Array.isArray(value) && value.map((val) => getLabel(val));
    labels && setDisplayLabels(labels);
  }, [fieldValue]);

  const handleChange = (
    onChange: (...event: any[]) => void,
    event: SelectChangeEvent<any[]>
  ): void => {
    const {
      target: { value }
    } = event;

    onChange(value);
  };

  return (
    <Styled.Container id={name}>
      {label && (
        <Styled.LabelContainer direction="row">
          <Styled.Label
            data-testid={`${name}-multi-select-label`}
            variant="EC_TYPE_BASE"
            required={!!rules?.required}
          >
            {label}
          </Styled.Label>
          {tooltip ?? null}
        </Styled.LabelContainer>
      )}
      <Controller
        name={name}
        control={control}
        rules={{ ...rules }}
        render={({ field: { onChange, value, ...rest } }): JSX.Element => {
          return (
            <FormControl error={!!errors[name]}>
              <Select
                {...rest}
                data-testid={`${name}-multi-select-box`}
                labelId={`${name}-label`}
                multiple
                displayEmpty
                defaultValue=""
                onChange={(e): void => handleChange(onChange, e)}
                value={value ?? []}
                inputProps={{ 'data-testid': dataTestId }}
                renderValue={(selected): React.ReactNode => {
                  if (!placeholder && (!selected || selected.length === 0)) {
                    return <></>;
                  }

                  if (placeholder && (!selected || selected.length === 0)) {
                    return (
                      <Typography
                        data-testid={`${name}-placeholder`}
                        variant="EC_TYPE_2XS"
                        sx={{ color: theme.palette.GRAY_3.main }}
                      >
                        {placeholder}
                      </Typography>
                    );
                  }

                  return <>{displayLabels.join(', ')}</>;
                }}
              >
                {options.map((option, idx) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    data-testid="menu-item"
                  >
                    <Checkbox
                      checked={!!value && value.indexOf(option.value) > -1}
                    />
                    <ListItemText
                      data-testid={`list-item-${idx}`}
                      primary={option.label}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        }}
      />
      {errors[name] && (
        <Styled.ErrorText
          variant="EC_TYPE_2XS"
          data-testid={`${name}-error-message`}
        >
          {errors[name].message}
        </Styled.ErrorText>
      )}
    </Styled.Container>
  );
};

export default ReactHookFormsMultiSelect;
