import * as React from 'react';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/system';

interface CustomTooltipProps extends TooltipProps {
  tooltipStyle?: React.CSSProperties;
}

const CustomTooltip = styled(
  ({ className, ...props }: CustomTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
  {
    shouldForwardProp: (prop) => prop !== 'tooltipStyle'
  }
)(({ tooltipStyle }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    ...tooltipStyle
  }
}));

const TooltipIcon = styled('i')(({ theme }) => ({
  fontSize: '17px',
  lineHeight: '17px',
  marginBottom: '4px',
  color: theme.palette.GOLD_1.main
}));

const basicStyles: React.CSSProperties = {
  maxWidth: '178px',
  padding: '12px 18px',
  fontSize: '12px',
  fontStyle: 'normal',
  lineHeight: '15px',
  letterSpacing: '0em'
};

interface Props {
  message: TooltipProps['title'];
  placement?: TooltipProps['placement'];
  displayArrow?: boolean;
  customTooltipStyles?: React.CSSProperties;
  customIconStyles?: React.CSSProperties;
  dataTestId?: string;
}

const EcToolTip = ({
  message,
  placement = 'right-start',
  displayArrow = true,
  customTooltipStyles,
  customIconStyles,
  dataTestId
}: Props): React.ReactElement => {
  const tooltipStyle = customTooltipStyles ?? basicStyles;

  const lineIcon = 'ri-information-line';
  const fillIcon = 'ri-information-fill';
  const [iconClass, setIconClass] = React.useState<string>(lineIcon);
  const iconRef = React.useRef<HTMLElement | null>(null);

  const handleIconHover = (val: string): void => {
    setIconClass(val);
  };

  React.useEffect(() => {
    if (iconRef.current) {
      const { current } = iconRef;

      current.addEventListener(
        'mouseover',
        () => handleIconHover(fillIcon),
        false
      );
      current.addEventListener(
        'mouseleave',
        () => handleIconHover(lineIcon),
        false
      );

      return (): void => {
        current.removeEventListener(
          'mouseover',
          () => handleIconHover(fillIcon),
          false
        );
        current.removeEventListener(
          'mouseleave',
          () => handleIconHover(lineIcon),
          false
        );
      };
    }
  }, [iconRef]);

  return (
    <CustomTooltip
      data-testid={dataTestId}
      arrow={displayArrow}
      placement={placement}
      title={message}
      tooltipStyle={tooltipStyle}
    >
      <TooltipIcon
        className={iconClass}
        ref={iconRef}
        sx={{ ...customIconStyles }}
      />
    </CustomTooltip>
  );
};

export default EcToolTip;
