import * as React from 'react';
import { Box, Typography } from '@mui/material';
import theme from '@themes/ui/escoffier';

interface Props {
  id?: string;
  title?: string;
}
const LegalContentSection: React.FC<Props> = ({ id, title, children }) => {
  return (
    <Box id={id} sx={{ a: { color: theme.palette.primary.main } }}>
      {title && (
        <Typography variant="EC_TYPE_3XL" color={theme.palette.GRAY_4.main}>
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default LegalContentSection;
