import * as React from 'react';
import { Box, Typography } from '@mui/material';
import NodeCircle, { NodeCircleColors } from '@components/NodeCircle';
import Skill from '@components/Skill';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const Styled = {
  Root: styled(Box)({
    maxWidth: '337px',
    backgroundColor: theme.palette.WHITE.main,
    padding: '28px 24px',
    borderRadius: '4px',
    overflow: 'hidden'
  }),
  ItemContainer: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    rowGap: '20px'
  }),
  KeyItem: styled(Box)({
    display: 'flex',
    alignItems: 'center',
    columnGap: '20px',
    '& .key-item-text': {
      textTransform: 'uppercase',
      color: theme.palette.GOLD_1.main
    }
  }),
  Node: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'bgColor'
  })<{ bgColor: string }>(({ bgColor }) => ({
    position: 'relative',
    flexShrink: 0,
    width: '34px',
    height: '34px',
    backgroundColor: bgColor,
    border: `5px solid ${theme.palette.GOLD_1.main}`,
    borderRadius: '50%'
  }))
};

interface KeyItemProps {
  text: string;
  item: JSX.Element;
}

const KeyItem = ({ text, item }: KeyItemProps): JSX.Element => (
  <Styled.KeyItem>
    {item}
    <Typography
      variant="EC_TYPE_XL"
      className="key-item-text"
      sx={{ whiteSpace: 'wrap' }}
    >
      {text}
    </Typography>
  </Styled.KeyItem>
);

const CareerGoalTooltipKey = (): React.ReactElement => {
  return (
    <Styled.Root>
      <Typography variant="EC_TYPE_BASE">
        This is a generalized description and common path of the career goal
        that you have selected. You can change your goal to explore other career
        options.
      </Typography>
      <Styled.ItemContainer>
        <KeyItem
          text="skills i have"
          item={
            <Skill skill="Skill" checked disableMargin sx={{ width: '58px' }} />
          }
        />
        <KeyItem
          text="skills i need"
          item={<Skill skill="Skill" disableMargin sx={{ width: '58px' }} />}
        />
        <KeyItem
          text="qualified positions"
          item={
            <NodeCircle
              bgColor={NodeCircleColors.BG_COMPLETE}
              enableHover={false}
              smallNodes
            />
          }
        />
        <KeyItem
          text="my past jobs"
          item={
            <NodeCircle
              bgColor={NodeCircleColors.BG_COMPLETE}
              enableHover={false}
              smallNodes
              checked
            />
          }
        />
        <KeyItem
          text="requires more skills or experience"
          item={
            <NodeCircle
              bgColor={NodeCircleColors.BG_INCOMPLETE}
              enableHover={false}
              smallNodes
            />
          }
        />
      </Styled.ItemContainer>
    </Styled.Root>
  );
};

export default CareerGoalTooltipKey;
