import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CtaButton from '@components/CtaButton';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const Styled = {
  Root: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isMobile'
  })<{ isMobile: boolean }>(({ isMobile }) => ({
    display: 'flex',
    minHeight: isMobile ? 'unset' : '500px',
    flexDirection: isMobile ? 'column-reverse' : 'row'
  })),
  LeftImage: styled(Box, {
    shouldForwardProp: (prop) =>
      prop !== 'backgroundImage' && prop !== 'isMobile'
  })<{ backgroundImage: string; isMobile: boolean }>(
    ({ backgroundImage, isMobile }) => ({
      flex: isMobile ? 'unset' : 4,
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      height: isMobile ? '375px' : 'unset'
    })
  ),
  RightContent: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isMobile'
  })<{ isMobile: boolean }>(({ isMobile }) => ({
    flex: isMobile ? 'unset' : 5
  })),
  TitleContainer: styled(Box)({
    backgroundColor: theme.palette.GOLD_1.main,
    padding: '12px',
    textAlign: 'center'
  }),
  Title: styled(Typography)({
    color: 'white',
    marginBottom: '0px'
  }),
  MainContent: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'calc(100% - 64px)',
    padding: '32px 28px'
  }),
  Subtitle: styled(Typography)({
    textTransform: 'uppercase'
  })
};

export interface NextStep {
  backgroundImage: string;
  title: string;
  subtitle?: string;
  content: React.ReactNode;
  cta?: { label: string; path?: string; externalLink?: string };
}

interface Props {
  step: NextStep;
  isMobile: boolean;
}

const NextStepCard: React.FC<Props> = ({ step, isMobile }) => {
  return (
    <Styled.Root isMobile={isMobile}>
      <Styled.LeftImage
        backgroundImage={step.backgroundImage}
        isMobile={isMobile}
      />
      <Styled.RightContent isMobile={isMobile}>
        <Styled.TitleContainer>
          <Styled.Title variant="EC_TYPE_4XL">{step.title}</Styled.Title>
        </Styled.TitleContainer>
        <Styled.MainContent>
          <Box>
            <Styled.Subtitle variant="EC_TYPE_2XL">
              {step.subtitle}
            </Styled.Subtitle>
            <Box>{step.content}</Box>
          </Box>
          {step.cta && (
            <Grid container sx={{ marginTop: '32px' }}>
              <Grid item xs={12} md={6}>
                <CtaButton
                  fullWidth
                  to={step.cta.path}
                  label={step.cta.label}
                  externalLink={step.cta.externalLink}
                />
              </Grid>
            </Grid>
          )}
        </Styled.MainContent>
      </Styled.RightContent>
    </Styled.Root>
  );
};

export default NextStepCard;
