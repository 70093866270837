import { api } from '@api/configs/base';
import { apiConfig } from '@api/configs/api.config';
import { ApiResponse } from './models/base.models';
import { Notifications } from './models/notificationsApi.models';
import { NotificationsPutReq } from './models/notificationsApi.models';

export class NotificationsApi {
  static async getNotificationsByEmployerId(
    employerId: number
  ): Promise<ApiResponse<Notifications[]>> {
    try {
      const response = await api.get<ApiResponse<Notifications[]>>(
        `${apiConfig.notificationsUrl}/employer/${employerId}`
      );
      return response.data;
    } catch (error) {
      console.error(
        `Error for NotificationsApi.getNotificationsByEmployerId: ${error}`
      );
      throw error;
    }
  }

  static async getNotificationsByUserId(
    userId: string,
    role: string
  ): Promise<ApiResponse<Notifications[]>> {
    try {
      const response = await api.get<ApiResponse<Notifications[]>>(
        `${apiConfig.notificationsUrl}/user/${userId}?role=${role}`
      );
      return response.data;
    } catch (error) {
      console.error(
        `Error for NotificationsApi.getNotificationsByUserId: ${error}`
      );
      throw error;
    }
  }

  static async updateNotification(
    data: NotificationsPutReq
  ): Promise<ApiResponse<NotificationsPutReq>> {
    try {
      const response = await api.post<ApiResponse<NotificationsPutReq>>(
        `${apiConfig.notificationsUrl}`,
        data
      );
      return response.data;
    } catch (error) {
      console.error(`Error for NotificationsApi.updateNotification: ${error}`);
      throw error;
    }
  }
}
