import * as React from 'react';
import {
  AgreementEntity,
  AgreementType,
  ExternshipEntity
} from '@api/models/externshipApi.models';
import {
  AttestationEntity,
  AttestationStatus
} from '@interfaces/attestation.interface';
import { Box, Button, Link, Paper, Theme, Typography } from '@mui/material';
import {
  CareerServiceExternshipFile,
  UploadFilesDto
} from '@api/models/careerServicesApi.models';
import { FormProvider, useForm } from 'react-hook-form';
import { CareerServicesApi } from '@api/CareerService.api';
import { downloadAll } from '@common/helpers/pdfHelpers';
import { FileType } from '@api/models/fileApi.models';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { getFilePath } from '@common/helpers/getFilePath';
import ImageUploadWell from '@components/ImageUpload/ImageUploadWell';
import StudentPageCardHeader from '@pages/CareerServices/StudentPage/StudentPageCardHeader';
import { styled } from '@mui/system';
import { useKeycloakContext } from '@common/context/keycloakContext';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Styled = {
  CardRoot: styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.WHITE.main,
    padding: '32px',
    '&:not(:last-child)': {
      marginBottom: '58px'
    }
  })),
  FlexBox: styled(Box)({
    display: 'flex',
    columnGap: '8px'
  }),
  DocumentBox: styled(Box)({
    '&:not(:last-child)': {
      marginBottom: '16px'
    }
  }),
  DownloadAllBtn: styled(Button)(({ theme }) => ({
    padding: 0,
    typography: (theme as Theme).typography.EC_TYPE_3XS,
    color: theme.palette.primary.main,
    marginTop: '12px',
    marginBottom: '24px'
  })),
  DocBtn: styled(Button)(({ theme }) => ({
    padding: 0,
    typography: (theme as Theme).typography.EC_TYPE_SM,
    color: theme.palette.primary.main
  }))
};

interface DocumentRowProps {
  documentType: string;
  document: AgreementEntity | AttestationEntity;
  signersName?: string;
  managerName?: string;
}

const DocumentRow = ({
  documentType,
  document,
  signersName,
  managerName
}: DocumentRowProps): React.ReactElement => {
  const isAgreement = !!document && 'agreementType' in document;
  const _signersName = isAgreement ? signersName : managerName;

  const filePath = getFilePath(document?.file?.fullName);

  return (
    <Styled.DocumentBox>
      <Link
        href={`${BASE_URL}/${filePath}`}
        target="_blank"
        rel="noopener noreferrer"
        underline="none"
      >
        <Styled.DocBtn
          variant="text"
          startIcon={<i className="ri-file-text-fill" />}
          size="medium"
        >
          {documentType}
        </Styled.DocBtn>
      </Link>
      <Styled.FlexBox>
        <Typography variant="EC_TYPE_2XS">
          SIGNED BY: {_signersName || 'N/A'}
        </Typography>
        {isAgreement && document.managerSignDate && (
          <Typography variant="EC_TYPE_2XS">
            SIGNED:{' '}
            {formatDate(String(document.managerSignDate), 'LLL dd, yyyy')}
          </Typography>
        )}
        {!!document?.file?.createdAt && (
          <Typography variant="EC_TYPE_2XS">
            {isAgreement && document.managerSignDate
              ? 'LAST UPDATED:'
              : 'COMPLETED:'}{' '}
            {formatDate(document.file.createdAt, 'LLL dd, yyyy')}
          </Typography>
        )}
      </Styled.FlexBox>
    </Styled.DocumentBox>
  );
};

const getFileArray = (externship: ExternshipEntity): (string | undefined)[] => {
  const urlArray: string[] = [];
  const siteAgreement = externship?.company
    ?.siteAgreements?.[0] as AgreementEntity;
  const externshipAgreement = externship.externshipAgreement;
  const supervisorAgreements =
    externship.supervisors?.map((s) => s.proxyAgreement) || [];
  const attestation = externship.attestation;

  if (siteAgreement?.file)
    urlArray.push(getFilePath(siteAgreement.file?.fullName));
  if (externshipAgreement?.file)
    urlArray.push(getFilePath(externshipAgreement.file?.fullName));
  if (supervisorAgreements.some((agreement) => !!agreement?.file)) {
    supervisorAgreements.forEach((a) =>
      urlArray.push(getFilePath(a?.file.fullName))
    );
  }
  if (attestation?.file) urlArray.push(getFilePath(attestation.file.fullName));

  return urlArray;
};

interface UploadFormValues {
  files: number[];
}

interface Props {
  externship: ExternshipEntity;
}

const DocumentRepositoryCard: React.FC<Props> = ({ externship }) => {
  const [uploadedFiles, setUploadedFiles] = React.useState<FileType[]>([]);

  const { keycloakUser } = useKeycloakContext();
  const userName = `${keycloakUser.given_name} ${keycloakUser.family_name}`;

  const { manager, supervisors } = externship;
  const fileArray = getFileArray(externship);
  const allFilesToDownload = [
    ...fileArray,
    ...(uploadedFiles?.map((f) => f.fullName) || [])
  ];

  const methods = useForm<UploadFormValues>({
    defaultValues: {
      files: []
    }
  });

  const { reset } = methods;

  const getFileIds = (files?: CareerServiceExternshipFile[]): number[] => {
    if (!files) return [];

    const _files = files.map((f) => f.file);
    setUploadedFiles(_files);

    return _files.map((f) => f.id);
  };

  const handleValidForm = async (data: number[]): Promise<void> => {
    const reqBody: UploadFilesDto = {
      files: data,
      uploadedBy: userName
    };

    try {
      const res = await CareerServicesApi.uploadFiles(externship.id, reqBody);

      if (res?.length) {
        setUploadedFiles(res.map((r) => r.file));
      }
    } catch (error: any) {
      console.error(
        'Error for DocumentRepository -> CareerServicesApi.uploadFiles',
        error
      );
    }
  };

  const handleDownloadAllClick = (): void => {
    allFilesToDownload.forEach((url) => downloadAll(url));
  };

  React.useEffect(() => {
    reset({
      files: getFileIds(externship.careerServiceFiles)
    });
  }, [externship]);

  const attestationComplete =
    externship.attestationStatus === AttestationStatus.COMPLETED;

  const noProxy =
    supervisors?.length === 0 ||
    !!supervisors?.some((s) => s.proxyAgreement === null);
  const noSiteAgreements =
    !externship.company?.siteAgreements ||
    externship.company?.siteAgreements?.length === 0;

  const noDocuments =
    noSiteAgreements &&
    !externship.externshipAgreement &&
    noProxy &&
    !externship.attestation &&
    uploadedFiles?.length === 0;

  const getAgreementsSigner = (agreement: AgreementEntity): string => {
    if (
      agreement.agreementType === AgreementType.PROXY &&
      agreement.supervisorSignatory
    ) {
      const { firstName, lastName } = agreement.supervisorSignatory;
      return `${firstName} ${lastName}`;
    }

    if (
      agreement.agreementType === AgreementType.EXTERNSHIP &&
      agreement.managerSignatory
    ) {
      const { firstName, lastName } = agreement.managerSignatory;
      return `${firstName} ${lastName}`;
    }

    if (
      agreement.agreementType === AgreementType.SITE &&
      agreement.managerSignatory
    ) {
      const { firstName, lastName } = agreement.managerSignatory;
      return `${firstName} ${lastName}`;
    }

    return '';
  };

  return (
    <Styled.CardRoot elevation={3} key={externship.id}>
      <StudentPageCardHeader externship={externship} />
      <>
        {noDocuments ? (
          <Box>
            <Typography variant="EC_TYPE_2XL" padding="24px 0">
              No documents to display
            </Typography>
            <Typography variant="EC_TYPE_BASE">
              Once documents are created, they will appear here, or you may
              upload files.
            </Typography>
          </Box>
        ) : (
          <>
            <Styled.DownloadAllBtn
              variant="text"
              size="small"
              onClick={handleDownloadAllClick}
            >
              Download All
            </Styled.DownloadAllBtn>
            {!!externship.company?.siteAgreements?.length && (
              <DocumentRow
                documentType="Site Agreement"
                document={
                  externship.company.siteAgreements[0] as AgreementEntity
                }
                signersName={getAgreementsSigner(
                  externship.company.siteAgreements[0]
                )}
              />
            )}
            {externship.externshipAgreement && (
              <DocumentRow
                documentType="Externship Agreement"
                document={externship.externshipAgreement}
                signersName={getAgreementsSigner(
                  externship.externshipAgreement
                )}
              />
            )}
            {!!supervisors?.length &&
              supervisors.map((s) => {
                if (s.proxyAgreement) {
                  return (
                    <DocumentRow
                      key={s.id}
                      documentType="Supervisor Proxy Agreement"
                      document={s.proxyAgreement}
                      signersName={getAgreementsSigner(s.proxyAgreement)}
                    />
                  );
                }
              })}
            {!!externship.attestation && attestationComplete && (
              <DocumentRow
                documentType="Attestation Survey"
                document={externship.attestation}
                managerName={`${manager.firstName} ${manager.lastName}`}
              />
            )}
          </>
        )}
        <Box mt="36px">
          <FormProvider {...methods}>
            <ImageUploadWell
              name="careerServiceFiles"
              displayText="Upload Documents"
              multiple
              clickableFiles
              disableUploadedFileDisplay={uploadedFiles.length < 1}
              secure={true}
              files={uploadedFiles}
              validTypes={['application/pdf']}
              maxFileSizeBytes={10000000}
              uploadCallback={handleValidForm}
              customUploadSectionTitle="Uploaded Files"
            />
          </FormProvider>
        </Box>
      </>
    </Styled.CardRoot>
  );
};

export default DocumentRepositoryCard;
