import { Codebook } from '@api/models/codebook.models';
import { CompanyProfile } from '@api/models/companyApi.models';
import { ESourceAccess } from '@api/models/glrcAccess.models';

export enum CourseType {
  FULL = 1,
  MICRO = 2
}

export enum TopicTypes {
  KITCHEN_BASICS = 'kitchen-basics',
  CULINARY = 'culinary',
  PASTRY = 'pastry',
  PLANT_BASED = 'plant-based',
  HOLISTIC = 'holistic-nutrition'
}

export enum TopicTypeIds {
  KITCHEN_BASICS = 1,
  CULINARY = 2,
  PASTRY = 3,
  PLANT_BASED = 4,
  HOLISTIC = 5
}

export enum GlrcContentTypes {
  VIDEO = 1,
  COURSE = 2
}

export interface GlrcSubTopic {
  name: string;
  videos: GlrcContent[];
}

export interface GlrcVideo {
  id: number;
  url: string;
  title: string;
  length: string;
  lengthNumber: number | null;
  imgUrl: string;
  bookmarked: boolean;
  topicLabel: string;
  topicCodebook: Codebook;
  section: string;
}

export interface GlrcTopic {
  id: number;
  name: string;
  description: string;
  imgUrl: string;
  subTopics: GlrcSubTopic[];
  fullCourses?: GlrcContent[];
  microCourses?: GlrcContent[];
  comingAttraction?: boolean;
  path?: string;
}

export interface GlrcContentType {
  contentType: GlrcContentTypes;
  name: string;
  imgUrl: string;
  path: string;
  icon: string;
  description: string;
}

export interface GlrcContent {
  id: number;
  contentType: Codebook;
  contentTopic: Codebook;
  section: string | null;
  title: string;
  numberOfLessons: number | null;
  description: string | null;
  courseId: number;
  courseType: number;
  link: string;
  thumbnail: string;
  bookmarked: boolean;
  lengthOfVideo: number | null;
  tags: string | null;
  lengthOfCourse: number | null;
}

export interface GlrcContentPutReq {
  contentTypeId: number;
  contentTopicId: number;
  section: string | null;
  title: string | null;
  numberOfLessons: number | null;
  description: string | null;
  courseId: number | null;
  courseType: number | null;
  link: string | null;
  thumbnail: string | null;
  lengthOfVideo: number | null;
}

export interface ESourceGroupEntity {
  id: number;
  name: string;
  company: CompanyProfile;
  customization: boolean;
}

export interface ESourceGroupWithAccessDto extends ESourceGroupEntity {
  accesses: ESourceAccess[];
}

export interface CreateESourceGroupDto {
  name: string;
  companyId: number;
  customization: boolean;
  accesses: ESourceAccess[];
}

export interface AssignUserToESourceGroupDto {
  employerId: number;
  groupId?: number;
}

export interface BulkAssignUserToGroupDto {
  employerIds: number[];
  groupId?: number;
}
