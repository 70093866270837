import * as React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Icon,
  Modal,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useTagManager } from '@common/hooks/useTagManager';

// wide window modal dimensions (16:9)
const WIDTH = 70;
const HEIGHT = WIDTH * 0.5625;

const useStyles = makeStyles({
  playButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '40px'
  }
});

const Styled = {
  Root: styled(Card, {
    shouldForwardProp: (props) => props !== 'fullWidthBox'
  })((prop: { fullWidthBox?: boolean }) => ({
    position: 'relative',
    margin: '0',
    overflow: 'hidden',
    width: prop.fullWidthBox ? '100%' : '250px',
    cursor: 'pointer',
    borderRadius: prop.fullWidthBox ? 0 : '4px',
    color: prop.fullWidthBox ? '#fff' : '',
    '&:hover': {
      color: prop.fullWidthBox ? theme.palette.GOLD_1.main : ''
    }
  })),
  CardMedia: styled(CardMedia)({}),
  TitleBox: styled(CardContent)({
    height: '80.25px',
    backgroundColor: '#FFFFFF',
    textAlign: 'center'
  }),
  Title: styled(Typography)({
    position: 'relative',
    top: '25%'
  }),
  VideoBox: styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: `${HEIGHT}vw`,
    width: `${WIDTH}vw`,
    margin: 'auto',
    boxShadow: '0 0 50px #000000',
    '@media only screen and (max-width: 949px)': {
      width: '90vw',
      height: '50vw'
    },
    '& iframe': {
      width: '100%',
      height: '100%',
      border: 0,
      borderTopWidth: 0,
      borderBottomWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderStyle: 'inset',
      backgroundColor: '#333333'
    }
  }),
  Span: styled('span')({
    width: '14px',
    height: '14px',
    position: 'absolute',
    top: '-32px !important',
    right: '5px',
    cursor: 'pointer',
    padding: '0',
    background: '0 0'
  }),
  CloseButton: styled(Icon)({
    color: 'white',
    '&:hover': { color: '#997755' }
  })
};

interface EmbeddedVideoProps {
  fullWidthBox?: boolean;
  videoData: {
    title?: string;
    url: string;
    img: string;
  };
}

interface VideoPlayerModalProps {
  open: boolean;
  url: string;
  handleClose: () => void;
}

export const VideoPlayerModal: React.FC<VideoPlayerModalProps> = ({
  open,
  url,
  handleClose
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      componentsProps={{
        backdrop: { style: { backgroundColor: '#333333', opacity: 0.8 } }
      }}
    >
      <Styled.VideoBox>
        <Styled.Span onClick={handleClose}>
          <Styled.CloseButton className="ri-close-line" />
        </Styled.Span>
        <iframe
          className="ec-embedded-video"
          src={url}
          frameBorder="0"
          allowFullScreen
        />
      </Styled.VideoBox>
    </Modal>
  );
};

const EmbeddedVideo: React.FC<EmbeddedVideoProps> = (props) => {
  const { sendToGtm } = useTagManager();
  const { title, url, img } = props.videoData;
  const { fullWidthBox } = props;
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const classes = useStyles();

  const handleOpenModal = (): void => {
    setOpenModal(true);
    sendToGtm('video_player', {
      action: 'open',
      video_url: url
    });
  };

  const pauseVideo = (): void => {
    const iframe = document.querySelector(
      '.ec-embedded-video'
    ) as HTMLIFrameElement;

    if (iframe) {
      iframe.contentWindow?.postMessage(
        '{"event":"command","func":"pauseVideo","args":""}',
        '*'
      );
    }
  };

  const handleCloseModal = (): void => {
    pauseVideo();

    setOpenModal(false);
    sendToGtm('video_player', {
      action: 'close',
      video_url: url
    });
  };

  return (
    <>
      <Styled.Root onClick={handleOpenModal} fullWidthBox={fullWidthBox}>
        <CardMedia
          component="img"
          width="100%"
          src={img}
          height={fullWidthBox ? '100%' : ''}
        />
        {fullWidthBox && (
          <i className={`ri-play-circle-line ${classes.playButton}`}></i>
        )}
        {!fullWidthBox && (
          <Styled.TitleBox>
            <Styled.Title variant="EC_TYPE_SM" className="video-title">
              {title}
            </Styled.Title>
          </Styled.TitleBox>
        )}
      </Styled.Root>
      <VideoPlayerModal
        open={openModal}
        url={url}
        handleClose={handleCloseModal}
      />
    </>
  );
};

export default EmbeddedVideo;
