import * as React from 'react';
import {
  useFetchMarketplaceSearchResults,
  UseFetchSearchCriteria
} from '@common/fetches/useFetchMarketplaceSearchResults';
import CandidateMarketplaceMainContent from '@pages/Employer/CandidateMarketplacePage/CandidateMarketplaceMainContent';
import CandidateMarketplaceSkeleton from '@components/Skeletons/CandidateMarketplaceSkeleton';
import { CandidateProfile } from '@api/models/candidateApi.models';
import FiltersWrapper from '@components/Filters/FiltersWrapper';
import { getEmployerPathWithParamsId } from '@common/helpers/getPathWithParamsId';
import { IPageInfoRes } from '@interfaces/pageInfo.interfaces';
import { MobileSearchFilterOverlayProvider } from '@common/context/mobileSearchFilterOverlayContext';
import NotFoundPage from '@pages/Errors/NotFoundPage';
import PageLayoutLeftSidebar from '@components/Layout/PageLayoutLeftSidebar';
import { SortMenuContextProvider } from '@common/context/sortMenuContext';
import { useFetchCompanyProfile } from '@common/fetches/useFetchCompanyProfile';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';

const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_PER_PAGE_COUNT = 25;
const MOBILE_BREAKPOINT = 900;

const sortMenuButtons = [
  {
    label: 'Graduation Type',
    id: 'graduationType.value'
  },
  {
    label: 'Date Updated',
    id: 'updatedAt'
  },
  {
    label: 'Date Created',
    id: 'createdAt'
  },
  {
    label: 'Alphabetical',
    id: 'firstName'
  }
];

const searchCriteria: UseFetchSearchCriteria = {
  pageInfo: {
    currentPage: DEFAULT_PAGE_NUMBER,
    currentPageEndRow: 0,
    currentPageStartRow: 0,
    filteredRows: 0,
    rowsPerPage: DEFAULT_PER_PAGE_COUNT,
    totalPages: 0,
    totalRows: 0,
    sortCategory: 'graduationType.value',
    sortOrder: 'ASC'
  } as IPageInfoRes,
  filters: [],
  searchType: 'CandidateProfile'
};

const CandidateMarketplacePage: React.FC = () => {
  const {
    searchData: candidates,
    pageInfo,
    filters,
    loading,
    filterMarketplaceResults: filterResults,
    resetMarketplaceResults: resetResults,
    filterFormState,
    setFilterFormState,
    sortResults,
    goToPage,
    expandedFilters,
    setExpandedFilters
  } = useFetchMarketplaceSearchResults<CandidateProfile>(
    searchCriteria,
    DEFAULT_PAGE_NUMBER
  );

  const { paramsId, storeId } = useParamsOrStoreCompanyId();
  const { companyProfile } = useFetchCompanyProfile(storeId);
  const companyHasTalentSearch =
    companyProfile?.partnershipLevelPermissions.TALENT_SEARCH_ENABLED;

  if (storeId && !companyHasTalentSearch) {
    return <NotFoundPage />;
  }

  const [filterOverlayOpen, setFilterOverlayOpen] =
    React.useState<boolean>(false);

  const openMobileFilter = (): void => {
    setFilterOverlayOpen(true);
  };

  const closeMobileFilter = (): void => {
    setFilterOverlayOpen(false);
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [pageInfo.currentPage]);

  return (
    <>
      {loading ? (
        <CandidateMarketplaceSkeleton />
      ) : (
        <MobileSearchFilterOverlayProvider
          value={{
            filterOverlayOpen,
            openMobileFilter,
            closeMobileFilter,
            mobileBreakpoint: MOBILE_BREAKPOINT
          }}
        >
          <PageLayoutLeftSidebar
            pageTitle="Talent Search"
            breadcrumb={{
              label: 'Dashboard',
              path: getEmployerPathWithParamsId('/dashboard', paramsId)
            }}
            sidebar={
              <FiltersWrapper
                filtersData={filters}
                filterResults={filterResults}
                resetResults={resetResults}
                state={filterFormState}
                setState={setFilterFormState}
                expandedFilters={expandedFilters}
                setExpandedFilters={setExpandedFilters}
              />
            }
            content={
              <SortMenuContextProvider
                value={{
                  sortCategory: pageInfo.sortCategory,
                  sortOrder: pageInfo.sortOrder,
                  sortMenuButtons,
                  onSortClick: sortResults
                }}
              >
                <CandidateMarketplaceMainContent
                  candidates={candidates}
                  pageInfo={pageInfo}
                  defaultPageNumber={DEFAULT_PAGE_NUMBER}
                  goToPage={goToPage}
                />
              </SortMenuContextProvider>
            }
          />
        </MobileSearchFilterOverlayProvider>
      )}
    </>
  );
};

export default CandidateMarketplacePage;
