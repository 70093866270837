import * as React from 'react';
import GlobalSolutionsModal from './GlobalSolutionsModal';
import { Link } from '@mui/material';

interface Props {
  link: string;
  text?: string;
}

const ExternalEduLink: React.FC<Props> = ({ link, text, children }) => {
  const [externalEduLink, setExternalEduLink] = React.useState<string>();

  return (
    <>
      <Link
        onClick={(): void => setExternalEduLink(link)}
        underline="none"
        sx={{
          ':hover': {
            cursor: 'pointer'
          }
        }}
      >
        {children || text}
      </Link>
      <GlobalSolutionsModal
        open={!!externalEduLink}
        handleClose={(): void => setExternalEduLink(undefined)}
        externalLink={externalEduLink || ''}
      />
    </>
  );
};

export default ExternalEduLink;
