import {
  CareerServicesExtern,
  EcEnrollmentsResponse
} from '@api/models/careerServicesApi.models';
import { useEffect, useState } from 'react';
import { CareerServicesApi } from '@api/CareerService.api';
import { getCurrentTimezoneDateTime } from '@common/helpers/dateHelpers/getCurrentTimezoneDateTime';

export type FetchCareerServicesExterns = {
  externs: CareerServicesExtern[];
  lastFetch: string;
  loaded: true | null;
  loading: boolean;
  fetchExterns: (date?: Date, refresh?: boolean) => Promise<void | null>;
  setExterns: React.Dispatch<React.SetStateAction<CareerServicesExtern[]>>;
};

export const useFetchCareerServicesExterns = (
  date?: Date,
  doNotFetchOnLoad?: boolean
): FetchCareerServicesExterns => {
  const [externs, setExterns] = useState<CareerServicesExtern[]>([]);
  const [lastFetch, setLastFetch] = useState<string>('');
  const [loaded, setLoaded] = useState<true | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchExterns = async (
    date?: Date,
    refresh?: boolean
  ): Promise<void | null> => {
    try {
      setLoading(true);

      if (date) {
        const res: EcEnrollmentsResponse =
          await CareerServicesApi.GetExternshipEnrollmentsByExternshipBeginDate(
            date,
            refresh
          );

        setExterns(res.enrollments);
        setLastFetch(getCurrentTimezoneDateTime(res.lastFetch.date));
      }
    } catch (error: any) {
      console.error(
        'Error for useFetchCareerServicesExterns -> CareerServicesApis.GetExternshipEnrollments()',
        error
      );
      setExterns([]);
      return null;
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (!doNotFetchOnLoad) {
      fetchExterns(date);
    }
  }, []);

  return {
    externs,
    lastFetch,
    loaded,
    loading,
    fetchExterns,
    setExterns
  };
};
