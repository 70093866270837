import { api } from '@api/configs/base';
import { apiConfig } from '@api/configs/api.config';
import { PdfPostRequest } from '@api/models/pdfApi.models';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export class PdfApi {
  static async generate(data: PdfPostRequest): Promise<Blob> {
    try {
      const res = await api.post<Blob>(
        `${baseUrl}${apiConfig.pdfUrl}/create`,
        data,
        {
          responseType: 'blob'
        }
      );

      return res.data;
    } catch (error: any) {
      console.error(`Error for PdfApi.generate(): ${error}`);
      throw error;
    }
  }
}
