import * as React from 'react';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme
} from '@mui/material';
import { GlrcVideo } from '@interfaces/glrc.interfaces';
import { sortTwoDimension } from '@common/helpers/sortByCategory';
import { styled } from '@mui/system';
import { useTableSortContext } from '@common/context/tableSortContext';

const Styled = {
  Root: styled(TableContainer)(({ theme }) => ({
    marginTop: '36px',
    '&.MuiTableHead-root': {
      height: '36px !important'
    },
    '& .MuiTableCell-root': {
      paddingTop: 0,
      paddingBottom: 0
    },
    '& .title-cell': {
      paddingLeft: '12px'
    },
    '& .table-data-row *': {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px'
    },
    '& .table-data-row:nth-of-type(even)': {
      backgroundColor: theme.palette.BROWN_1.main
    }
  })),
  Head: styled(TableHead)(({ theme }) => ({
    height: '36px',
    backgroundColor: theme.palette.GOLD_1.main,
    '& .table-header-row *': {
      ...(theme as Theme).typography.EC_TYPE_LG,
      color: theme.palette.WHITE.main
    }
  })),
  TitleCell: styled(TableCell)({
    paddingLeft: '12px'
  }),
  ButtonRow: styled(Box)(({ theme }) => ({
    display: 'flex',
    '& .icon-button': {
      padding: '3px 6px'
    },
    '& .delete-button': {
      color: theme.palette.primary.main
    }
  }))
};

interface Props {
  videos: GlrcVideo[];
  handleOpenEditModal: (videoId: number) => void;
  handleOpenDeleteModal: (videoId: number) => void;
}

const getTitle = (str: string): string => {
  return str
    .split(' ')
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
    .join(' ');
};

const GlrcAdminVideoTable: React.FC<Props> = ({
  videos,
  handleOpenEditModal,
  handleOpenDeleteModal
}) => {
  const { sortBy, sortOrder, page, rowsPerPage } = useTableSortContext();

  return (
    <Styled.Root>
      <Table aria-label="GLRC video table">
        <Styled.Head>
          <TableRow className="table-header-row">
            <Styled.TitleCell>Topic</Styled.TitleCell>
            <TableCell>Title</TableCell>
            <TableCell>Section</TableCell>
            <TableCell>Time</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </Styled.Head>
        <TableBody>
          {sortTwoDimension(
            videos,
            sortOrder,
            sortBy as keyof GlrcVideo,
            'title'
          )
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((v) => {
              const openDeleteModal = (): void => {
                handleOpenDeleteModal(v.id);
              };

              const openEditModal = (): void => {
                handleOpenEditModal(v.id);
              };

              return (
                <TableRow key={`${v.id}-${v.url}`} className="table-data-row">
                  <TableCell
                    data-testid="video-table-topic-cell"
                    className="title-cell"
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    {v.topicLabel}
                  </TableCell>
                  <TableCell data-testid="video-table-title-cell">
                    {getTitle(v.title)}
                  </TableCell>
                  <TableCell data-testid="video-table-section-cell">
                    {v.section}
                  </TableCell>
                  <TableCell data-testid="video-table-time-cell">
                    {v.length ?? 'N/A'}
                  </TableCell>
                  <TableCell align="right" sx={{ paddingRight: 0 }}>
                    <Styled.ButtonRow>
                      <IconButton
                        className="icon-button delete-button"
                        onClick={openDeleteModal}
                        aria-label="Delete content"
                        data-testid="video-table-delete-btn"
                      >
                        <i className="ri-delete-bin-2-line" />
                      </IconButton>
                      <IconButton
                        className="icon-button"
                        onClick={openEditModal}
                        aria-label="Edit content"
                        data-testid="video-table-edit-btn"
                      >
                        <i className="ri-edit-line" />
                      </IconButton>
                    </Styled.ButtonRow>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Styled.Root>
  );
};

export default GlrcAdminVideoTable;
