import * as React from 'react';
import { BLACK, BLUE_1, OUTLINE_GRAY } from '@themes/ui/escoffier';
import { Box, Link } from '@mui/material';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { NavItem } from '@components/Layout/Header/Header.models';

const activeLinkStyles = {
  color: BLACK,
  paddingBottom: '5px',
  borderBottom: `5px solid ${BLUE_1}`
};

const linkStyles = {
  textDecoration: 'none',
  display: 'block',
  width: 'fit-content',
  margin: '25px 0px',
  color: OUTLINE_GRAY,
  paddingBottom: '10px',
  '&:first-of-type': { marginTop: '0px' }
};

interface Props {
  links: NavItem[];
}

const NestedLinkList: React.FC<Props> = ({ links }) => {
  const { url } = useRouteMatch();

  return (
    <Box data-testid="nested-link-nav">
      {links.map((item) => (
        <Link
          key={item.label}
          variant="EC_TYPE_2XL"
          activeStyle={activeLinkStyles}
          component={NavLink}
          to={`${url}${item.path}`}
          sx={linkStyles}
        >
          {item.label.toUpperCase()}
        </Link>
      ))}
    </Box>
  );
};

export default NestedLinkList;
