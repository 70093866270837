import * as React from 'react';
import { Box, Icon, IconButton } from '@mui/material';
import GlrcCart, { CartItem } from '@pages/Partner/GlrcCart';
import RootPageLayout from '@components/Layout/RootPageLayout';
import theme from '@themes/ui/escoffier';

const cartItems: CartItem[] = [
  {
    icon: 'ri-slideshow-3-line',
    name: 'Video Library',
    price: 350,
    seats: 10
  },
  {
    icon: 'ri-stack-line',
    name: 'Kitchen Basics',
    price: 1000,
    seats: 15
  }
];

const GlrcCartComponentPage: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <RootPageLayout muiMaxWidth={false}>
      <Box textAlign={'center'}>
        <IconButton
          size="small"
          data-testid="cart-menu-icon"
          onClick={handleClick}
          sx={{ color: theme.palette.GOLD_1.main }}
        >
          <Icon className="ri-shopping-cart-2-fill" />
        </IconButton>
        <GlrcCart
          open={open}
          anchorEl={anchorEl}
          cartItems={cartItems}
          handleClose={handleClose}
        />
      </Box>
    </RootPageLayout>
  );
};

export default GlrcCartComponentPage;
