import { FeatureFlagConfig } from '@common/hooks/useFeatureFlags';
import { NavItem } from '@components/Layout/Header/Header.models';

export const candidateSettingsNav = [
  { label: 'Account Settings', path: '/account' }
  // { label: 'Notifications', path: '/notifications' } -- Candidate Notifications has not been implemented yet
];

export const ecAdminViewingAsEmployerNav = (
  featureFlags: FeatureFlagConfig,
  customThemeAccess: boolean
): NavItem[] => [
  { label: 'Company settings', path: '/company' },
  ...(featureFlags.GLRC_CUSTOMIZATION && customThemeAccess
    ? [{ label: 'Customizations', path: '/customizations' }]
    : [])
];

export const employerSettingsNav = (
  featureFlags: FeatureFlagConfig,
  customThemeAccess: boolean
): NavItem[] => [
  { label: 'Account Settings', path: '/account' },
  { label: 'Company settings', path: '/company' },
  { label: 'Notifications', path: '/notifications' },
  ...(featureFlags.COMPANY_LOCATION
    ? [{ label: 'Locations', path: '/locations' }]
    : []),
  ...(featureFlags.GLRC_CUSTOMIZATION && customThemeAccess
    ? [{ label: 'Customizations', path: '/customizations' }]
    : [])
];

export const recruiterSettingsNav = [
  { label: 'Account Settings', path: '/account' },
  { label: 'Notifications', path: '/notifications' }
];

export const escoffierAdminSettingsNav = [
  { label: 'Account Settings', path: '/account' },
  { label: 'Notifications', path: '/notifications' }
];

export const partnerSettingsNav = [];

export const careerServicesSettingsNav = [
  { label: 'Account Settings', path: '/account' }
];

export const authorizedUserSettingsNav = [
  { label: 'Account Settings', path: '/account' }
];
