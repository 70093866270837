/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  Autocomplete,
  Box,
  Grid,
  Icon,
  InputAdornment,
  Stack,
  TextField,
  Theme,
  Typography,
  useTheme
} from '@mui/material';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import {
  ExtractedAddressDetails,
  Location
} from '@interfaces/location.interfaces';
import debounce from 'lodash/fp/debounce';
import EcChip from '@components/EcChip';
import EcGoogleMap from '@components/EcGoogleMap';
import FormFieldErrorText from '@components/Forms/FormFieldErrorText';
import { getOneLineAddress } from '@common/helpers/addressHelpers/getOneLineAddress';
import { getTwoLineAddress } from '@common/helpers/addressHelpers/getTwoLineAddress';
import parse from 'autosuggest-highlight/parse';
import { styled } from '@mui/system';

const Styled = {
  FieldContainer: styled(Stack)({
    position: 'relative'
  }),
  Label: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'required'
  })<{ required?: boolean }>(({ required, theme }) => ({
    marginBottom: '11px',
    ['@media (max-width:480px)']: {
      marginBottom: '5px',
      fontSize: (theme as Theme).typography.EC_TYPE_SM.fontSize,
      lineHeight: (theme as Theme).typography.EC_TYPE_SM.lineHeight
    },

    '&:after': {
      content: required ? '"*"' : '""',
      color: theme.palette.error.main,
      marginLeft: '4px'
    }
  })),
  Part: styled('span', {
    shouldForwardProp: (prop) => prop !== 'highlight'
  })<{ highlight?: boolean }>(({ highlight }) => ({
    fontWeight: highlight ? '700' : '400'
  })),
  DeleteIcon: styled(Icon)(({ theme }) => ({
    '&.MuiChip-deleteIcon': {
      color: theme.palette.WHITE.main,
      fontSize: '10px',
      lineHeight: '13px'
    }
  })),
  TextField: styled(TextField)({
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0
    }
  })
};

let autocompleteService;
let placesService;

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

interface PlacePrediction {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id: string;
}

export enum PlaceType {
  ALL = 1,
  ESTABLISHMENTS = 2,
  CITIES = 3
}

interface Props {
  name: string;
  label: string;
  rules?: RegisterOptions;
  searchByPlace?: boolean;
  small?: boolean;
  zipOnly?: boolean;
  placeholder?: string;
  placeType?: PlaceType;
  displayMap?: boolean;
  dataTestId?: string;
  maxWidth?: string;
  readOnly?: boolean;
  onDeleteCallback?: () => void;
}

const RhfLocationSearch: React.FC<Props> = ({
  name,
  label,
  rules,
  searchByPlace,
  small,
  zipOnly,
  placeholder = '',
  placeType = PlaceType.ALL,
  displayMap = false,
  dataTestId,
  maxWidth,
  readOnly = false,
  onDeleteCallback
}) => {
  const [options, setOptions] = React.useState<readonly PlacePrediction[]>([]);
  const [inputValue, setInputValue] = React.useState('');

  const theme = useTheme();

  const {
    control,
    formState: { errors }
  } = useFormContext();

  const fetch = React.useMemo(
    () =>
      debounce(
        500,
        (
          request: {
            input: string;
            types?: string[];
            componentRestrictions?: { country: string[] };
          },
          callback: (results?: readonly PlacePrediction[]) => void
        ) => {
          if (autocompleteService) {
            autocompleteService.getPlacePredictions(request, callback);
          }
        }
      ),
    []
  );

  React.useEffect(() => {
    const _window = window as any;

    if (!autocompleteService && _window.google) {
      autocompleteService =
        new _window.google.maps.places.AutocompleteService();
    }

    if (!placesService && _window.google) {
      placesService = new _window.google.maps.places.PlacesService(
        document.createElement('div')
      );
    }
  });

  const getPlaceTypes = (): string[] => {
    switch (placeType) {
      case PlaceType.ALL:
        return [];
      case PlaceType.ESTABLISHMENTS:
        return ['establishment'];
      case PlaceType.CITIES:
        return ['locality', 'postal_code', 'sublocality_level_1'];
      default:
        return [];
    }
  };

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return;
    }

    fetch(
      {
        input: inputValue,
        componentRestrictions: { country: ['us', 'pr'] },
        types: getPlaceTypes()
      },
      (results?: readonly PlacePrediction[]) => {
        if (active) {
          let newOptions: readonly PlacePrediction[] = [];

          if (results) {
            newOptions = [...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return (): void => {
      active = false;
    };
  }, [inputValue]);

  const getPlaceholder = (): string => {
    let _placeholder;

    if (searchByPlace) {
      _placeholder = placeholder || 'Search for an address, city or zip code';
    } else {
      _placeholder = small
        ? ''
        : placeholder || 'Search for a city or zip code';
    }

    return _placeholder;
  };

  const getCityStateCountry = (placeDetailsResult): ExtractedAddressDetails => {
    const extractedData = {} as ExtractedAddressDetails;

    placeDetailsResult.address_components.forEach((addressComp) => {
      const addressType = addressComp.types[0];

      if (addressType === 'street_number') {
        extractedData.streetNumber = addressComp.short_name;
      }

      if (addressType === 'route') {
        extractedData.streetName = addressComp.short_name;
      }

      if (addressType === 'subpremise') {
        extractedData.addressLineTwo = addressComp.long_name;
      }

      //For NY Google API does not use city but instead five boroughs
      if (addressType === 'sublocality_level_1') {
        extractedData.city = addressComp.long_name;
      }

      if (addressType === 'administrative_area_level_3') {
        extractedData.city = addressComp.long_name;
      }

      if (addressType === 'locality') {
        extractedData.city = addressComp.long_name;
      }

      if (addressType === 'administrative_area_level_1') {
        extractedData.state = addressComp.long_name;
        extractedData.shortState = addressComp.short_name;
      }

      if (addressType === 'country') {
        extractedData.country = addressComp.long_name;
      }

      if (addressType === 'postal_code') {
        extractedData.zip = addressComp.short_name;
      }
    });

    return extractedData;
  };

  const handleChange = (
    currValue: Location[] | Location,
    newValue: PlacePrediction | null,
    onChange
  ): void => {
    const isLocationArray = Array.isArray(currValue);

    const isPreviouslySelected = isLocationArray
      ? currValue.filter((loc) => loc.placeId === newValue?.place_id).length
      : currValue?.placeId === newValue?.place_id;

    if (newValue && !isPreviouslySelected) {
      const _newValue = newValue as PlacePrediction;

      placesService.getDetails(
        {
          placeId: _newValue.place_id,
          fields: [
            'geometry.location',
            'address_components',
            'name',
            'formatted_phone_number'
          ]
        },
        (placeDetails) => {
          const extractedAddress = getCityStateCountry(placeDetails);

          const newLocation = {
            description: newValue.description,
            placeId: newValue.place_id,
            lat: placeDetails.geometry.location.lat(),
            lng: placeDetails.geometry.location.lng(),
            name: placeDetails.name,
            phone: placeDetails.formatted_phone_number,
            ...extractedAddress
          };

          let newLocationValue;

          if (isLocationArray) {
            newLocationValue = currValue ? [...currValue] : [];
            newLocationValue.push(newLocation);
          } else {
            newLocationValue = newLocation;
          }

          onChange(newLocationValue);
        }
      );
    }

    setInputValue('');
    setOptions([]);
  };

  const handleDelete = (
    locationIdx: number,
    locations: Location[],
    onChange: (locList: Location[]) => void
  ): void => {
    const newLocationList = locations.filter(
      (location, idx) => locationIdx !== idx
    );

    onChange(newLocationList);

    if (onDeleteCallback) {
      onDeleteCallback();
    }
  };

  const renderOption = (props, option): React.ReactNode => {
    const _option = option as PlacePrediction;
    const matches = _option.structured_formatting.main_text_matched_substrings;
    const parts = parse(
      _option.structured_formatting.main_text,
      matches.map((match: any) => [match.offset, match.offset + match.length])
    );

    return (
      <li {...props} key={_option.place_id}>
        <Grid container alignItems="center">
          {!small && (
            <Grid item>
              <Box
                sx={{
                  color: theme.palette.GRAY_3.main,
                  mr: 2
                }}
              >
                <i className="ri-map-pin-2-fill"></i>
              </Box>
            </Grid>
          )}
          <Grid item xs>
            {parts.map((part, idx) => {
              return (
                <Styled.Part
                  key={`${part}-${idx}`}
                  highlight={part.highlight}
                  sx={{
                    typography: 'EC_TYPE_BASE'
                  }}
                >
                  {part.text}
                </Styled.Part>
              );
            })}
            <Typography
              variant="EC_TYPE_2XS"
              sx={{
                fontWeight: '400',
                color: theme.palette.GRAY_3.main
              }}
            >
              {_option.structured_formatting.secondary_text}
            </Typography>
          </Grid>
        </Grid>
      </li>
    );
  };

  const LocationChips = (
    locations: Location[],
    onChange: (locList: Location[]) => void
  ): React.ReactNode => {
    return (
      <Grid container spacing={1} mt={0.5} data-testid={`${name}-chips`}>
        {locations.map((item, idx) => (
          <Grid item key={item.placeId}>
            <EcChip
              data-testid={item.placeId}
              label={getOneLineAddress(item)}
              handleDelete={(): void => handleDelete(idx, locations, onChange)}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Styled.FieldContainer id={name}>
      {!readOnly && (
        <Styled.Label
          data-testid={`${name}-input-label`}
          variant={small ? 'EC_TYPE_2XS' : 'EC_TYPE_BASE'}
          required={!!rules?.required}
        >
          {label}
        </Styled.Label>
      )}
      <Controller
        control={control}
        name={name}
        rules={{ ...rules }}
        render={({ field: { value, onChange } }): JSX.Element => {
          return (
            <>
              {!readOnly && (
                <>
                  <Autocomplete
                    blurOnSelect
                    data-testid={dataTestId}
                    sx={{ maxWidth }}
                    disabled={readOnly}
                    inputValue={inputValue}
                    value={Array.isArray(value) || !value ? null : value}
                    isOptionEqualToValue={(option, value): boolean => {
                      return (
                        !Array.isArray(value) &&
                        value.placeId === option.place_id
                      );
                    }}
                    getOptionLabel={(option): string => {
                      return zipOnly && !Array.isArray(value)
                        ? value?.zip || ''
                        : (option as PlacePrediction).description;
                    }}
                    filterOptions={(x): any => x}
                    options={options}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    onChange={(event, newValue): void =>
                      handleChange(value, newValue, onChange)
                    }
                    onInputChange={(event, newInputValue): void =>
                      setInputValue(newInputValue)
                    }
                    renderInput={(params): React.ReactNode => (
                      <Styled.TextField
                        {...params}
                        type={zipOnly ? 'number' : 'text'}
                        placeholder={getPlaceholder()}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: !small && (
                            <InputAdornment position="start">
                              <i className="ri-map-pin-2-fill" />
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                    renderOption={(props, option): React.ReactNode =>
                      renderOption(props, option)
                    }
                  />

                  {Array.isArray(value) && value?.length
                    ? LocationChips(value, onChange)
                    : null}
                  {!Array.isArray(value) &&
                    !!value &&
                    LocationChips([value], onChange)}
                </>
              )}
              {displayMap && Array.isArray(value) && !!value?.length && (
                <Box mt="24px">
                  {value.map((loc) => (
                    <Box key={loc.placeId} sx={{ marginBottom: '24px' }}>
                      <Typography variant="EC_TYPE_LG">{loc.name}</Typography>
                      {getTwoLineAddress(loc)}
                    </Box>
                  ))}
                  <EcGoogleMap locations={value} />
                </Box>
              )}
              {displayMap && !Array.isArray(value) && !!value && (
                <Box mt="24px">
                  <Box mb="24px">
                    <Typography variant="EC_TYPE_LG">{value.name}</Typography>
                    {getTwoLineAddress(value)}
                  </Box>
                  <EcGoogleMap locations={[value]} />
                </Box>
              )}
              {errors[name] && (
                <FormFieldErrorText
                  name={name}
                  message={errors[name].message}
                  disableAbsolutePosition
                />
              )}
            </>
          );
        }}
      />
    </Styled.FieldContainer>
  );
};

export default RhfLocationSearch;
