import * as React from 'react';
import LegalContentSection from '@components/LegalContentSection';

const TermsAndConditionsContent = (): React.ReactElement => {
  return (
    <LegalContentSection title="Escoffier Connect">
      <div>
        <div>
          <p>
            <a href="#econnect">EConnect</a>
            &nbsp;|&nbsp;
            <a href="#esource">ESource</a>
          </p>
        </div>
        <div>
          <h2>Website Users other than EConnect Users and ESource Users</h2>
          <p>
            These Terms of Use (“<strong>Terms</strong>“) apply to all users of
            this Website, including both administrators and users (“
            <strong>you</strong>“) of this website, any related phone apps, and
            any related social media accounts (the “<strong>Website</strong>”).
            These Terms include our&nbsp;
            <a href="https://escoffierglobal.com/privacy/">Privacy Policy</a>.
          </p>
          <h3>
            <em>Services Offered</em>
          </h3>
          <p>
            We offer the following services (the “<strong>Services</strong>“) on
            the Website:
          </p>
          <ul>
            <li>your ability to browse the Website; and</li>
            <li>
              your ability to request and interact with us in connection with
              the services that we may offer to you.
            </li>
          </ul>
          <h3>
            <em>Binding Agreement; Revisions</em>
          </h3>
          <p>
            These Terms constitute a binding agreement between you and Auguste
            Escoffier Global Solutions, LLC, which is owned by Triumph Higher
            Education Corporation (“<strong>we</strong>,” “<strong>us</strong>,”
            and “<strong>our</strong>”), and you accept these Terms each time
            that you use or access the Website or its content or otherwise use
            our tools or our Services.
          </p>
          <p>
            We may revise these Terms from time to time. If we make any
            revisions, they will become effective when we post the modified
            version of these Terms on our Website. By visiting our Website or
            using our Services after we have posted revisions, you accept those
            revisions. The date at the end of these Terms indicates when they
            were last updated.
          </p>
          <p>
            These Terms will remain in full force and effect while you are a
            user of the Website. We may pursue all of our legal remedies upon
            any breach by you of these Terms. Even after you are no longer a
            user of the Website, certain provisions of these Terms will remain
            in effect, including&nbsp;
            <em>“Binding Agreement; Revisions,”&nbsp;</em>“
            <em>
              Use of our Website; Services and Content; Consequences of
              Violating These Terms
            </em>
            ,” “<em>Terms Applicable to All Users</em>,” “<em>Ideas</em>,” “
            <em>
              Identification of Agent to Receive Notification and Elements of
              Notification of Claimed Copyright or Trademark Infringement
            </em>
            ,” “
            <em>
              Policy Regarding Termination of Users Who Infringe the Copyright
              or Other Intellectual
            </em>
            Property,” “<em>Liability Disclaimers</em>,” “
            <em>
              Disclaimer of Warranties,” “Disclaimer of Consequential Damages,”
              “Limitation of Liability,” “Class Action Waiver,” “Links to Other
              Sites,” and “Indemnity.”
            </em>
          </p>
          <h3>
            <em>
              Use of our Website; Services and Content; Consequences of
              Violating These Terms
            </em>
          </h3>
          <p>
            If you choose to register with the Website, you will be asked to
            login with Facebook, Google or another service or to create an
            account with us and provide us with your information, including your
            name, zip code, career and education level and a valid email address
            (“Information”). All Information that you supply to us must be
            accurate, complete and not misleading and must apply to you and not
            any other person. You are solely responsible for the content and
            accuracy of any Information that you submit to us. We may offer
            services and products to you based on the Information that you
            submit to us or that you agree to receive from us in accordance with
            our&nbsp;
            <a href="https://escoffierglobal.com/privacy/">Privacy Policy</a>.
          </p>
          <p>
            If you request an account, you have no ownership rights in your
            account, and if you cancel your account with us or your account is
            terminated due to inactivity or otherwise, your Information will no
            longer be available to you or to third parties, but it may continue
            to be used to compile aggregate data.
          </p>
          <p>
            You may only use the Website, its content, our tools, and our
            Services in accordance with these Terms and then only for your
            personal or company use. The content of the Website, including page
            designs, layouts, text, graphics, images, video, audio, data and
            information, logos, button and icons, and software, and our tools (
            <em>e.g.</em>, our profile creator) are protected under copyright,
            trademark and other laws. All content on the Website (other than
            certain content that you and other users submit and retain) and all
            tools that we provide are our or our licensors’ property. Your
            unauthorized use of content or tools may violate applicable law for
            which you will be held responsible. If you are authorized to make a
            copy of any content or any tool, you must preserve all copyright,
            trademark, service mark, and other proprietary notices contained in
            that content.
          </p>
          <p>
            Any software code that is used to operate the Website, generate or
            display any content on the Website or provide our tools or our
            Services is also protected by copyright and other law, and you may
            not copy, use, or adapt any such software code. You agree not to
            sell or modify content (other than your own content) or any tool or
            reproduce, display, publicly perform, distribute, or otherwise use
            either in any way, except as explicitly authorized by these Terms.
          </p>
          <p>
            If you violate these Terms, we can terminate your access to the
            Website and its content and its tools, and you will no longer be
            able to use our Services. In addition, violations of these Terms
            could subject you to civil and/or criminal liability. We will
            investigate occurrences that may involve such violations, and we
            will cooperate with law enforcement authorities.
          </p>
          <h3>
            <em>Terms Applicable to All Users</em>
          </h3>
          <ul>
            <li>
              Tools that we may supply may only be used by individuals visiting
              the Website.
            </li>
            <li>
              You are required to maintain the confidentiality of your
              information. &nbsp;If you have a password, you may not share it
              with anyone, and you are solely responsible for any account that
              you create, even if you have not authorized those uses. You will
              immediately notify us of any unauthorized use of your account or
              password.
            </li>
            <li>
              You cannot post, provide, distribute, store or destroy any
              material, including content, in violation of applicable law or in
              breach of our Privacy Policy.
            </li>
            <li>
              You cannot do anything that imposes an unreasonable burden or load
              on the Website.
            </li>
            <li>
              You cannot attempt to interfere with our Services to anyone else.
            </li>
            <li>
              You cannot violate or attempt to violate the security of the
              Website, including the use of probes, scans or tests of
              vulnerabilities unless we authorize you to do so.
            </li>
            <li>
              You cannot forge any TCP/IP packet header or any part of any other
              header information.
            </li>
            <li>
              You cannot reverse engineer or decompile any parts of the Website
              or its content or its tools.
            </li>
            <li>
              You cannot navigate or search the Website using tools other than
              the ones that we make available to you or have otherwise approved,
              though you may use standard web browsers.
            </li>
            <li>
              You cannot use any data mining, robots, scrapers, or similar data
              gathering or extraction methods.
            </li>
            <li>
              You cannot aggregate, copy or duplicate any content, unless
              explicitly permitted by these Terms.
            </li>
            <li>
              You cannot frame or link to any content, unless explicitly
              permitted by these Terms.
            </li>
            <li>
              You cannot post any content that (includes links to another who)
              promotes, endorses or assists false or misleading information or
              illegal or any other activities that violate these Terms.
            </li>
            <li>You cannot access data or information not intended for you.</li>
            <li>
              You cannot submit information if it violates the intellectual
              property rights of others.
            </li>
            <li>You cannot delete or alter anything posted by others.</li>
            <li>
              You cannot harass, incite harassment or advocate harassment of any
              group, company, or individual.
            </li>
            <li>
              You cannot send unsolicited mail or email, “junk mail,” “chain
              letters,” or unsolicited mass mailing, “spamming,” or “phishing,”
              or make unsolicited phone calls or send unsolicited texts, tweets
              or faxes promoting and/or advertising products or services to any
              user of the Website.
            </li>
            <li>
              You cannot use our Services for any unlawful purpose or any
              illegal activity.
            </li>
            <li>
              You cannot post or submit any content, resume, or job ad that is
              defamatory, libelous, offensive, vulgar, obscene, threatening,
              abusive, hateful, racist, discriminatory, menacing, of a sexual
              nature, or likely to cause annoyance, inconvenience,
              embarrassment, violence, or anxiety or could cause harassment to
              any person or include any links to pornographic, indecent or
              sexually explicit material of any kind, nor can you link to any
              items that facilitate any of the foregoing.
            </li>
            <li>
              You cannot provide or link to material that exploits people under
              the age of 18 in a sexual, violent or other manner, or solicits
              personal information from anyone under 18.
            </li>
            <li>
              You cannot provide instructional information about illegal
              activities or other activities prohibited by these Terms,
              including making or buying illegal weapons.
            </li>
            <li>
              You must be 13 years of age or older to use the Website, and, if
              you are over 13 and under 18, you cannot use the Website without
              the supervision of a parent or legal guardian.
            </li>
          </ul>
          <h3>
            <em>Ideas</em>
          </h3>
          <p>
            You should not send us your creative suggestions, ideas, drawings,
            concepts, inventions, or other information (“
            <strong>Your Ideas</strong>“), but if you do, we may use Your Ideas
            that you submit to us without liability to you.
          </p>
          <p>
            <em>
              Identification of Agent to Receive Notification and Elements of
              Notification of Claimed Copyright or Trademark Infringement
            </em>
          </p>
          <p>
            If you believe that your copyrighted work or trademark has been
            uploaded, posted or copied to the Website and is accessible in a way
            that constitutes infringement against your rights, please contact us
            at:&nbsp;
            <a href="mailto:compliance@escoffierglobal.com">
              compliance@escoffierglobal.com
            </a>
          </p>
          <p>or by regular mail at:</p>
          <p>
            Auguste Escoffier Global Solutions, LLC
            <br />
            150 N. Martingale Rd.
            <br />
            Suite 300
            <br />
            Schaumburg, IL. 60173
            <br />
            Attn: Compliance
          </p>
          <h3>
            <em>
              Policy Regarding Termination of Users Who Infringe the Copyright
              or Other Intellectual
            </em>
            Property
          </h3>
          <p>
            We require that our users refrain from violating the intellectual
            property rights of others. You agree not to use the Website to
            infringe the intellectual property rights of any other person, and
            we reserve the right to terminate your account if you do. If,
            however, you believe in good faith that a notice of copyright
            infringement has been wrongly filed against you, please contact us
            as provided in Section 6 above.
          </p>
          <h3>
            <em>Liability Disclaimers</em>
          </h3>
          <p>
            We are not responsible for any content provided by users, or its
            quality, truth, accuracy, reliability, completeness, timeliness or
            legality, and we make no representation or warranties about any
            content provided by users.
          </p>
          <p>
            The risks of using content provided by other users may include risks
            of physical harm or of dealing with strangers, underage persons or
            people acting under false pretenses. You assume all of those risks,
            and we make no representation or warranties about relating to such
            risks. Because user authentication is difficult, we cannot know that
            a user is who they claim to be.
          </p>
          <p>
            In any of your dealings with other users, if a dispute arises, your
            sole remedy is against them, you release us (and our agents and
            employees) from any claims, demands and damages (actual and
            consequential and direct and indirect) of every kind and nature,
            known and unknown, suspected and unsuspected, disclosed and
            undisclosed, arising out of or in any way connected with such
            disputes to the fullest extent permitted by law.
          </p>
          <p>
            The Website and its content and its tools may contain or create
            inaccuracies or errors and any tool or form that you use may not
            work properly or as you expect. we makes no representations or
            warranties about the accuracy, reliability, completeness, or
            timeliness of the Website or its content or about the utility or the
            effectiveness of the tools, and you use the Website and its content
            and the tools is at your own risk.
          </p>
          <p>
            If you are a California resident, you waive California Civil Code
            Section 1542, that says: “A general release does not extend to
            claims which the creditor does not know or suspect to exist in his
            or her favor at the time of executing the release, which if known by
            him or her must have materially affected his or her settlement with
            the debtor.”
          </p>
          <p>
            We encourage you to keep a back-up copy of any of Your Content. We
            are not liable for the deletion, loss, or unauthorized modification
            of any User Content.
          </p>
          <p>
            We do not provide or make any representation as to the quality or
            nature of any third party products or services purchased through the
            Website.
          </p>
          <p>
            If you believe that something on the Site violates these Terms,
            please contact us&nbsp;
            <a href="mailto:compliance@escoffierglobal.com">
              compliance@escoffierglobal.com
            </a>
          </p>
          <p>
            If we are notified of any content, tools or other materials that do
            not conform to these Terms, we may investigate and make an
            appropriate determination.
          </p>
          <h3>
            <em>Disclaimer of Warranties</em>
          </h3>
          <p>
            TO THE FULLEST EXTENT PROVIDED BY LAW, WE DO NOT WARRANT THAT THE
            WEBSITE WILL OPERATE ERROR-FREE OR THAT OUR WEBSITE IS FREE OF
            COMPUTER VIRUSES OR OTHERHARMFUL MECHANISMS. IF YOUR USE OF OUR
            WEBSITE, ITS CONTENT, ANY TOOLS, OR OUR SERVICES RESULTS IN THE NEED
            FOR SERVICING OR REPLACING EQUIPMENT OR DATA OR ANY OTHER COSTS, WE
            ARE NOT RESPONSIBLE FOR THOSE COSTS. OUR WEBSITE AND ITS CONTENT AND
            OUR TOOLS ARE PROVIDED ON AN “AS IS” BASIS WITHOUT ANY WARRANTIES OF
            ANY KIND. WE, TO THE FULLEST EXTENT PERMITTED BYLAW, DISCLAIM ALL
            WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THEWARRANTY OF
            MERCHANTABILITY, FITNESS FOR PARTICULAR PURPOSE AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES ABOUT THE
            ACCURACY,RELIABILITY, COMPLETENESS, OR TIMELINESS OF THE WEBSITE,
            ITS CONTENT, OUR TOOLS, OUR SERVICES, SOFTWARE, TEXT, GRAPHICS, AND
            LINKS.
          </p>
          <h3>
            <em>Disclaimer of Consequential Damages</em>
          </h3>
          <p>
            WE DISCLAIM ALL INCIDENTAL AND CONSEQUENTIAL DAMAGES, LOST PROFITS,
            OR DAMAGES RESULTING FROM LOST DATA, LOST EMPLOYMENT OPPORTUNITY OR
            BUSINESS INTERRUPTION RESULTING FROM THE USE OR INABILITY TO USE THE
            WEBSITE, ITS CONTENT, OUR TOOLS AND OUR SERVICES, WHETHER BASED ON
            WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR
            NOT WE ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
          <h3>
            <em>Limitation of Liability</em>
          </h3>
          <p>
            OUR MAXIMUM LIABILITY ARISING OUT OF OR IN CONNECTION WITH YOUR USE
            OF THE WEBSITE, ITS CONTENT, OUR TOOLS, OR OUR SERVICES, REGARDLESS
            OF THE CAUSE OF ACTION (WHETHER IN CONTRACT, TORT, BREACH OF
            WARRANTY OR OTHERWISE) WILL NOT EXCEED $100.
          </p>
          <h3>
            <em>Class Action Waiver</em>
          </h3>
          <p>
            As a condition for your ability to use or access the Website, its
            content, our tools and our Services, you agree not to sue us as a
            class plaintiff or class representative, join a class action as a
            class member, or participate in a class-action lawsuit brought
            against us relating to your use of or access to the Website, its
            content, our tools or our Services. If you are a job seeker, you
            acknowledge that your use of the Website is at no charge to you, and
            you further acknowledge that part of the price you are paying us to
            use the Website is your acceptance of this class action waiver.
            Nothing in this paragraph, however, limits your right to bring a
            lawsuit as an individual plaintiff.
          </p>
          <h3>
            <em>Links to Other Sites</em>
          </h3>
          <p>
            The Website contains links to other websites. These links are
            provided solely as a convenience to you, and we are not responsible
            for the content of such linked sites and do not make any
            representations or warranties regarding the content or accuracy of
            materials on such websites. If you decide to access a linked
            website, you do so at your own risk.
          </p>
          <h3>
            <em>Indemnity</em>
          </h3>
          <p>
            You agree to defend, indemnify, and hold harmless us, our
            affiliates, and our and their officers, directors, employees and
            agents, from and against any claims, actions or demands, including
            without limitation reasonable legal and accounting fees, alleging or
            resulting from (i) any of Your Content or other material you provide
            to us, (ii) your use of the Website, its content, our tools, or our
            Services, or (iii) your breach of these Terms.
          </p>
          <h3>
            <em>General</em>
          </h3>
          <p>
            We make no claim or representation that the Website or its content
            may be lawfully viewed or accessed, or that our tools may be used,
            outside of the United States. Access to the Website or the content
            or our tools may not be legal by certain persons or in certain
            countries. If you access the Website or its content or use our
            tools, you do so at your own risk. You are responsible for
            compliance with the laws of your country or jurisdiction. Any
            software downloaded from the Website is further subject to United
            States export control laws, and you are responsible for the
            violation of any such laws. By downloading or using such software,
            you represent and warrant that you are not violating such laws.
          </p>
          <p>
            These Terms are governed by the internal substantive laws of the
            State of Illinois, without respect to its conflict of laws
            principles. Jurisdiction for any claims arising under this agreement
            shall lie exclusively with the state or federal courts within Cook
            County, Illinois. If any provision of these Terms are found to be
            invalid by any court having competent jurisdiction, the invalidity
            of such provision shall not affect the validity of the remaining
            provisions of these Terms, which shall remain in full force and
            effect. No waiver of any term of these Terms shall be deemed a
            further or continuing waiver of such term or any other term. In
            addition, our failure to enforce any term of these Terms shall not
            be deemed as a waiver of such term or otherwise affect our ability
            to enforce such term at any point in the future. These Terms
            constitute the entire agreement between you and us with respect to
            the use of the Website, its content, our tools, or our Services. No
            changes to these Terms shall be made except by a revised posting on
            this page.
          </p>
          <h3>
            <em>Mobile Services</em>
          </h3>
          <p>
            If you use the Website through a mobile device, you agree that
            information about your use of the Website through your mobile device
            and carrier may be communicated to us, including the identity of
            your mobile carrier and your mobile device and your physical
            location. In addition, use of the Website through a mobile device
            may cause data to be displayed on and through your mobile device. By
            accessing the Website using a mobile device, you represent that to
            the extent you import any of Your Content to your mobile device that
            you have authority to share the transferred data with your mobile
            carrier or other access provider. In the event you change or
            deactivate your mobile account, you must promptly update your
            Website account information to ensure that your messages are not
            sent to the person that acquires your old number and the failure to
            do so is your responsibility. You acknowledge you are responsible
            for all charges and necessary permissions related to accessing the
            Website through your mobile access provider. Therefore, you should
            check with your provider to find out if the Website is available and
            the terms for such services for your specific mobile devices.
          </p>
          <p>
            By using any downloadable application to enable your use of the
            Website, you are confirming your acceptance of the terms of the end
            user license agreement associated with the app provided at download
            or installation, or as it may be updated from time to time.
          </p>
          <p>Update effective:&nbsp;09/01/2023.</p>
          <h2 id="econnect">EConnect</h2>
          <h3>
            <strong>Terms of Use</strong>
          </h3>
          <p>
            These Terms of Use (“<strong>Terms</strong>“) apply to job seekers,
            employers or other users (“<strong>you</strong>“) of this website,
            any related phone apps, and any related social media accounts (the “
            <strong>Website</strong>”). These Terms include our&nbsp;
            <a href="https://escoffierglobal.com/privacy/">Privacy Policy</a>.
          </p>
          <h3>
            <em>Services Offered</em>
          </h3>
          <p>
            We offer the following services (the “<strong>Services</strong>“) on
            the Website:
          </p>
          <ul>
            <li>
              creation and support of accounts for job seekers, employers and
              others;
            </li>
            <li>posting of employment opportunities offered by employers;</li>
            <li>
              facilitation of job applications, profiles, and resumes, and other
              information provided by job seekers;
            </li>
            <li>
              support of information provided by others, including ads; and
            </li>
            <li>search and matching capabilities;</li>
          </ul>
          <h3>
            <em>Binding Agreement; Revisions</em>
          </h3>
          <p>
            These Terms constitute a binding agreement between you and Auguste
            Escoffier Global Solutions, LLC, which is owned by Triumph Higher
            Education Corporation (“<strong>we</strong>,” “<strong>us</strong>,”
            and “<strong>our</strong>”), and you accept these Terms each time
            that you use or access the Website or its content or otherwise use
            our tools or our Services.
          </p>
          <p>
            If we have entered into a “hard-copy” written agreement with you
            that incorporates these Term of Use by reference, the combination of
            that written agreement and these Term of Use shall be the binding
            agreement between you and us. In the event of any inconsistency or
            conflict between that written agreement and these Terms of Use,
            these Terms of Use will prevail.
          </p>
          <p>
            We may revise these Terms from time to time. If we make any
            revisions, they will become effective when we post the modified
            version of these Terms on our Website. By visiting our Website or
            using our Services after we have posted revisions, you accept those
            revisions. The date at the end of these Terms indicates when they
            were last updated.
          </p>
          <p>
            These Terms will remain in full force and effect while you are a
            user of the Website. We may pursue all of our legal remedies,
            including removal of Your Content from the Website and immediate
            termination of your account and your access to the Website, its
            content, our tools and our Services upon any breach by you of these
            Terms. Even after you are no longer a user of the Website, certain
            provisions of these Terms will remain in effect, including “
            <em>Binding Agreement; Revisions</em>,” “
            <em>
              Use of our Website; Services and Content; Consequences of
              Violating These Terms
            </em>
            ,” “<em>Terms Applicable to All Users</em>,” “
            <em>Additional Terms Applicable to Employers</em>,” “
            <em>Additional Terms Applicable to Job Seekers</em>,” “
            <em>Ideas</em>,” “
            <em>
              Identification of Agent to Receive Notification and Elements of
              Notification of Claimed Copyright or Trademark Infringement
            </em>
            ,” “
            <em>
              Policy Regarding Termination of Users Who Infringe the Copyright
              or Other Intellectual&nbsp;
            </em>
            Property,<em>“</em>&nbsp;“<em>Liability Disclaimers</em>,” “
            <em>
              Disclaimer of Warranties,” “Disclaimer of Consequential Damages,”
              “Limitation of Liability,” “Class Action Waiver,” “Links to Other
              Sites,” “Indemnity,” “General”, “Mobile Services,” and
              “Arbitration.”
            </em>
          </p>
          <h3>
            <em>
              Use of our Website; Services and Content; Consequences of
              Violating These Terms
            </em>
          </h3>
          <p>
            You may only use the Website, its content, our tools, and our
            Services in accordance with these Terms and then only for your
            personal or company use. The content of the Website, including page
            designs, layouts, text, graphics, images, video, audio, data and
            information, logos, button and icons, and software, and our tools (
            <em>e.g.</em>, our profile creator) are protected under copyright,
            trademark and other laws. All content on the Website (other than
            certain content that you and other users submit and retain) and all
            tools that we provide are our or our licensors’ property. Your
            unauthorized use of content or tools may violate applicable law for
            which you will be held responsible. If you are authorized to make a
            copy of any content or any tool, you must preserve all copyright,
            trademark, service mark, and other proprietary notices contained in
            that content.
          </p>
          <p>
            Any software code that is used to operate the Website, generate or
            display any content on the Website or provide our tools or our
            Services is also protected by copyright and other law, and you may
            not copy, use, or adapt any such software code. You agree not to
            sell or modify content (other than your own content) or any tool or
            reproduce, display, publicly perform, distribute, or otherwise use
            either in any way, except as explicitly authorized by these Terms.
          </p>
          <p>
            If you violate these Terms, we can terminate your access to the
            Website and its content and its tools, and you will no longer be
            able to use our Services. In addition, violations of these Terms
            could subject you to civil and/or criminal liability. We will
            investigate occurrences that may involve such violations, and we
            will cooperate with law enforcement authorities.
          </p>
          <h3>
            <em>Terms Applicable to All Users</em>
          </h3>
          <ul>
            <li>
              Content, including our databases and other features, may be used
              only by individuals seeking employment opportunities or career
              information and by employers seeking employees.
            </li>
            <li>
              Tools, including our profiles or resume builders, may be used only
              by individuals seeking employment opportunities or career
              information and by employers seeking employees.
            </li>
            <li>
              You are required to maintain the confidentiality of your account,
              your profile, and your password. You may not share your password
              with anyone, and you are solely responsible for your account, even
              if you have not authorized those uses. You will immediately notify
              us of any unauthorized use of your account or password.
            </li>
            <li>
              You cannot post, provide, distribute, store or destroy any
              material, including content, in violation of applicable law or in
              breach of our Privacy Policy.
            </li>
            <li>
              You cannot do anything that imposes an unreasonable burden or load
              on the Website.
            </li>
            <li>
              You cannot attempt to interfere with our Services to anyone else.
            </li>
            <li>
              You cannot violate or attempt to violate the security of the
              Website, including the use of probes, scans or tests of
              vulnerabilities unless we authorize you to do so.
            </li>
            <li>
              You cannot forge any TCP/IP packet header or any part of any other
              header information.
            </li>
            <li>
              You cannot reverse engineer or decompile any parts of the Website
              or its content or its tools.
            </li>
            <li>
              You cannot navigate or search the Website using tools other than
              the ones that we make available to you or have otherwise approved,
              though you may use standard web browsers.
            </li>
            <li>
              You cannot use any data mining, robots, scrapers, or similar data
              gathering or extraction methods.
            </li>
            <li>
              You cannot aggregate, copy or duplicate any content, unless
              explicitly permitted by these Terms.
            </li>
            <li>
              You cannot frame or link to any content, unless explicitly
              permitted by these Terms.
            </li>
            <li>
              You cannot post any content that (includes links to another who)
              promotes, endorses or assists false or misleading information or
              illegal or any other activities that violate these Terms.
            </li>
            <li>You cannot access data or information not intended for you.</li>
            <li>
              You cannot submit information if it violates the intellectual
              property rights of others.
            </li>
            <li>You cannot delete or alter anything posted by others.</li>
            <li>
              You cannot harass, incite harassment or advocate harassment of any
              group, company, or individual.
            </li>
            <li>
              You cannot send unsolicited mail or email, “junk mail,” “chain
              letters,” or unsolicited mass mailing, “spamming,” or “phishing,”
              or make unsolicited phone calls or send unsolicited texts, tweets
              or faxes promoting and/or advertising products or services to any
              user of the Website.
            </li>
            <li>
              You cannot use our Services for any unlawful purpose or any
              illegal activity.
            </li>
            <li>
              You cannot post or submit any content, resume, or job ad that is
              defamatory, libelous, offensive, vulgar, obscene, threatening,
              abusive, hateful, racist, discriminatory, menacing, of a sexual
              nature, or likely to cause annoyance, inconvenience,
              embarrassment, violence, or anxiety or could cause harassment to
              any person or include any links to pornographic, indecent or
              sexually explicit material of any kind, nor can you link to any
              items that facilitate any of the foregoing.
            </li>
            <li>
              You cannot provide or link to material that exploits people under
              the age of 18 in a sexual, violent or other manner, or solicits
              personal information from anyone under 18.
            </li>
            <li>
              You cannot provide instructional information about illegal
              activities or other activities prohibited by these Terms,
              including making or buying illegal weapons.
            </li>
            <li>
              You must be 13 years of age or older to use the Website, and, if
              you are over 13 and under 18, you cannot use the Website without
              the supervision of a parent or legal guardian.
            </li>
          </ul>
          <h3>
            <em>Additional Terms Applicable to Employers</em>
          </h3>
          <p>
            Employers are fully responsible for their postings on the Website.
            We are not the employer with respect to any of your job postings,
            and you will be solely responsible for your employment decisions. If
            you cancel your employer account or your employer account is
            terminated, all of your account information may be deleted. In order
            to protect our users from commercial advertising or solicitation, we
            reserve the right to restrict the number of emails which an employer
            may send. You may only use the Website, its content, our tools and
            our Services in accordance with applicable law. We have not checked,
            vetted or otherwise researched any job poster or job seekers, and
            you communicate with them at your own risk.
          </p>
          <p>A job description may not contain:</p>
          <ul>
            <li>any hyperlinks unless authorized by us;</li>
            <li>
              misleading, unreadable, or “hidden” keywords, repeated keywords or
              keywords that are not relevant to the job opportunity being
              presented; or
            </li>
            <li>
              names of persons, colleges, cities, states, towns or countries
              that are not related to the posting.
            </li>
          </ul>
          <p>You may not use your job descriptions or company profile to:</p>
          <ul>
            <li>
              post jobs in a manner that does not comply with applicable law;
            </li>
            <li>
              post jobs that include any screening requirement or criterion in
              connection with a job description if the requirement or criterion
              is not an actual and legal requirement of the posted job;
            </li>
            <li>
              post any franchise, pyramid scheme, “club membership”,
              distributorship, multi-level marketing opportunity, or sales
              representative agency arrangement;
            </li>
            <li>
              post any business opportunity that requires an upfront or periodic
              payment or requires recruitment of other members, sub-distributors
              or sub-agents;
            </li>
            <li>
              post any business opportunity that pays commission only unless the
              posting clearly states that;
            </li>
            <li>
              promote any opportunity that does not represent bona fide
              employment, which is generally indicated by the employer’s use of
              IRS forms W-2 or 1099 unless the posting clearly states that and
              describes the opportunity in sufficient detail to explain the
              nature of the opportunity to the job seeker (<em>e.g.</em>, “This
              is part-time.”, “This is a temporary position.”, “This is an
              unpaid internship.”, “This is not an W-2 employee position.”)
            </li>
            <li>
              post jobs on the Website for modeling, acting, talent or
              entertainment agencies or talent scouting positions;
            </li>
            <li>
              advertise sexual services or seek employees for jobs of a sexual
              nature;
            </li>
            <li>
              request the use of human body parts or the donation of human
              parts, including, without limitation, reproductive services such
              as egg donation and surrogacy;
            </li>
            <li>post jobs located in countries other than the U.S.; and</li>
            <li>
              except where allowed by applicable law, post jobs which require
              the applicant to provide information relating to his/her (i)
              racial or ethnic origin, (ii) political beliefs, (iii)
              philosophical or religious beliefs, (iv) membership of a trade
              union, (v) physical or mental health (including pregnancy status),
              (vi) sexual life, (vii) the commission of criminal offenses or
              proceedings, or (vii) age.
            </li>
          </ul>
          <p>
            We reserve the right to remove any job description or other content
            or any tools from the Website that does not comply with these Terms
            or that we believe is not in our best interest. If at any time
            during your use of the Website, its content, our tools, or our
            Services, you make a misrepresentation of fact to us or job seekers
            or otherwise mislead us or them in regard to your business
            activities, we may terminate your use of the Website, its content,
            our tools and our Services.
          </p>
          <p>
            Employers may only use a database in accordance with these Terms and
            applicable law and may not disclose any data from a database to any
            third party.
          </p>
          <p>Employers may not:</p>
          <ul>
            <li>
              use information from a database other than as an employer seeking
              employees or to communicate with and provide services to a job
              seeker seeking employment with you;
            </li>
            <li>
              communicate with job seekers with excessive frequency or in ways
              that are unlikely to be of interest to the job seekers; or
            </li>
            <li>
              engage in any other activities that are inconsistent with these
              Terms.
            </li>
          </ul>
          <p>
            In order to facilitate a beneficial experience for all users, we
            reserve the right to limit your usage of our resources.
          </p>
          <p>
            <em>Additional Terms Applicable to Job Seekers</em>
          </p>
          <p>
            When you register with the Website, you will be asked to login with
            Facebook, Google or another service or to create an account with us
            and provide us with your information, including your name, zip code,
            career and education level and a valid email address
            (“Information”). All Information that you supply to us must be
            accurate, complete and not misleading and must apply to you and not
            any other person. You are solely responsible for the content and
            accuracy of any Information that you submit to us. We may offer
            services and products to you based on the Information that you
            submit to us or that you agree to receive from us in accordance with
            our&nbsp;
            <a href="https://escoffierglobal.com/privacy/">Privacy Policy</a>.
          </p>
          <p>
            You have no ownership rights in your account, and if you cancel your
            account with us or your account is terminated due to inactivity or
            otherwise, your Information will no longer be available to you or to
            third parties, but it may continue to be used to compile aggregate
            data
          </p>
          <h3>
            <em>Your Submissions</em>
          </h3>
          <p>
            All Information and materials that you submit to us (“
            <strong>Your Content</strong>“) is your responsibility. We neither
            own nor control Your Content, and you (or your licensors) retain all
            patent, trademark and copyright rights to Your Content. By
            submitting Your Content to us, you grant us a worldwide,
            non-exclusive, royalty-free, transferable, sub-licensable license to
            use, reproduce, adapt, distribute and publish Your Content. In
            addition, if you submit Your Content intending it to be available to
            the general public, you grant us a worldwide, non-exclusive,
            royalty-free license to reproduce, adapt, distribute and publish
            such User Content for the purpose of promoting us and our Services.
            We may refuse to accept any of Your Content at our discretion.
          </p>
          <p>
            If you submit Your Content in any public area of the Website, you
            also grant a worldwide, non-exclusive, royalty-free, transferable,
            sub-licensable license to our other users to access, display, view,
            store and reproduce such content for their personal use.
          </p>
          <p>
            You represent and warrant that you have the right to grant the
            licenses described above.
          </p>
          <p>
            We may take action with respect to Your Content that we deem
            appropriate if it could create liability for us, damage our brand or
            image, or cause us to lose other users or service providers.
          </p>
          <p>
            We do not represent or guarantee the truthfulness, accuracy, or
            reliability of any content or tool submitted to us by any user
            including job posters. Your reliance on content or tools submitted
            by other users will be at your own risk. We have not checked, vetted
            or otherwise researched any job poster or job seekers, and you
            communicate with them at your own risk.
          </p>
          <p>
            The following is a partial list of content that is prohibited on the
            Website:
          </p>
          <ul>
            <li>
              You cannot post any resume or profile or apply for any job for
              anyone else; and
            </li>
            <li>
              You cannot solicit passwords or personal identifying information
              from other users.
            </li>
          </ul>
          <h3>
            <em>Ideas</em>
          </h3>
          <p>
            You should not send us your creative suggestions, ideas, drawings,
            concepts, inventions, or other information (“
            <strong>Your Ideas</strong>“), but if you do, we may use Your Ideas
            that you submit to us without liability to you.
          </p>
          <h3>
            <em>
              Identification of Agent to Receive Notification and Elements of
              Notification of Claimed Copyright or Trademark Infringement
            </em>
          </h3>
          <p>
            If you believe that your copyrighted work or trademark has been
            uploaded, posted or copied to the Website and is accessible in a way
            that constitutes infringement against your rights, please contact us
            at:&nbsp;
            <a href="mailto:compliance@escoffierglobal.com">
              compliance@escoffierglobal.com
            </a>
          </p>
          <p>or by regular mail at:</p>
          <p>
            Auguste Escoffier Global Solutions, LLC
            <br />
            150 N. Martingale Rd.
            <br />
            Suite 300
            <br />
            Schaumburg, IL. 60173
            <br />
            Attn: Compliance
          </p>
          <h3>
            <em>
              Policy Regarding Termination of Users Who Infringe the Copyright
              or Other Intellectual&nbsp;Property
            </em>
          </h3>
          <p>
            We require that our users refrain from violating the intellectual
            property rights of others. You agree not to use the Website to
            infringe the intellectual property rights of any other person, and
            we reserve the right to terminate the your account if you do. If,
            however, you believe in good faith that a notice of copyright
            infringement has been wrongly filed against you, please contact us
            as provided in Section 6 above.
          </p>
          <h3>
            <em>Liability Disclaimers</em>
          </h3>
          <p>
            We are not responsible for any content provided by users, or its
            quality, truth, accuracy, reliability, completeness, timeliness or
            legality, and we make no representation or warranties about any
            content provided by users.
          </p>
          <p>
            The risks of using content provided by other users may include risks
            of physical harm or of dealing with strangers, underage persons or
            people acting under false pretenses. You assume all of those risks,
            and we make no representation or warranties about relating to such
            risks. Because user authentication is difficult, we cannot know that
            a user is who they claim to be.
          </p>
          <p>
            In any of your dealings with other users, if a dispute arises, your
            sole remedy is against them, you release us (and our agents and
            employees) from any claims, demands and damages (actual and
            consequential and direct and indirect) of every kind and nature,
            known and unknown, suspected and unsuspected, disclosed and
            undisclosed, arising out of or in any way connected with such
            disputes to the fullest extent permitted by law.
          </p>
          <p>
            The Website and its content and its tools may contain or create
            inaccuracies or errors and any tool or form that you use may not
            work properly or as you expect. we makes no representations or
            warranties about the accuracy, reliability, completeness, or
            timeliness of the Website or its content or about the utility or the
            effectiveness of the tools, and you use the Website and its content
            and the tools is at your own risk.
          </p>
          <p>
            If you are a California resident, you waive California Civil Code
            Section 1542, that says: “A general release does not extend to
            claims which the creditor does not know or suspect to exist in his
            or her favor at the time of executing the release, which if known by
            him or her must have materially affected his or her settlement with
            the debtor.”
          </p>
          <p>
            We encourage you to keep a back-up copy of any of Your Content. We
            are not liable for the deletion, loss, or unauthorized modification
            of any User Content.
          </p>
          <p>
            We do not provide or make any representation as to the quality or
            nature of any third party products or services purchased through the
            Website.
          </p>
          <p>
            If you believe that something on the Site violates these Terms,
            please contact us at&nbsp;
            <a href="mailto:compliance@escoffierglobal.com">
              compliance@escoffierglobal.com
            </a>
          </p>
          <p>
            If we are notified of any content, tools or other materials that do
            not conform to these Terms, we may investigate and make an
            appropriate determination.
          </p>
          <h3>
            <em>Disclaimer of Warranties</em>
          </h3>
          <p>
            TO THE FULLEST EXTENT PROVIDED BY LAW, WE DO NOT WARRANT THAT THE
            WEBSITE WILL OPERATE ERROR-FREE OR THAT OUR WEBSITE IS FREE OF
            COMPUTER VIRUSES OR OTHER HARMFUL MECHANISMS. IF YOUR USE OF OUR
            WEBSITE, ITS CONTENT, ANY TOOLS, OR OUR SERVICES RESULTS IN THE NEED
            FOR SERVICING OR REPLACING EQUIPMENT OR DATA OR ANY OTHER COSTS, WE
            ARE NOT RESPONSIBLE FOR THOSE COSTS. OUR WEBSITE AND ITS CONTENT AND
            OUR TOOLS ARE PROVIDED ON AN “AS IS” BASIS WITHOUT ANY WARRANTIES OF
            ANY KIND. WE, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIM ALL
            WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE WARRANTY OF
            MERCHANTABILITY, FITNESS FOR PARTICULAR PURPOSE AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES ABOUT THE ACCURACY,
            RELIABILITY, COMPLETENESS, OR TIMELINESS OF THE WEBSITE, ITS
            CONTENT, OUR TOOLS, OUR SERVICES, SOFTWARE, TEXT, GRAPHICS, AND
            LINKS.
          </p>
          <h3>
            <em>Disclaimer of Consequential Damages</em>
          </h3>
          <p>
            WE DISCLAIM ALL INCIDENTAL AND CONSEQUENTIAL DAMAGES, LOST PROFITS,
            OR DAMAGES RESULTING FROM LOST DATA, LOST EMPLOYMENT OPPORTUNITY OR
            BUSINESS INTERRUPTION RESULTING FROM THE USE OR INABILITY TO USE THE
            WEBSITE, ITS CONTENT, OUR TOOLS AND OUR SERVICES, WHETHER BASED ON
            WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR
            NOT WE ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
          <h3>
            <em>Limitation of Liability</em>
          </h3>
          <p>
            OUR MAXIMUM LIABILITY ARISING OUT OF OR IN CONNECTION WITH YOUR USE
            OF THE WEBSITE, ITS CONTENT, OUR TOOLS, OR OUR SERVICES, REGARDLESS
            OF THE CAUSE OF ACTION (WHETHER IN CONTRACT, TORT, BREACH OF
            WARRANTY OR OTHERWISE) WILL NOT EXCEED $100.
          </p>
          <h3>
            <em>Class Action Waiver</em>
          </h3>
          <p>
            As a condition for your ability to use or access the Website, its
            content, our tools and our Services, you agree not to sue us as a
            class plaintiff or class representative, join a class action as a
            class member, or participate in a class-action lawsuit brought
            against us relating to your use of or access to the Website, its
            content, our tools or our Services. If you are a job seeker, you
            acknowledge that your use of the Website is at no charge to you, and
            you further acknowledge that part of the price you are paying us to
            use the Website is your acceptance of this class action waiver.
            Nothing in this paragraph, however, limits your right to bring a
            lawsuit as an individual plaintiff.
          </p>
          <h3>
            <em>Links to Other Sites</em>
          </h3>
          <p>
            The Website contains links to other websites. These links are
            provided solely as a convenience to you, and we are not responsible
            for the content of such linked sites and do not make any
            representations or warranties regarding the content or accuracy of
            materials on such websites. If you decide to access a linked
            website, you do so at your own risk.
          </p>
          <h3>
            <em>Indemnity</em>
          </h3>
          <p>
            You agree to defend, indemnify, and hold harmless us, our
            affiliates, and our and their officers, directors, employees and
            agents, from and against any claims, actions or demands, including
            without limitation reasonable legal and accounting fees, alleging or
            resulting from (i) any of Your Content or other material you provide
            to us, (ii) your use of the Website, its content, our tools, or our
            Services, or (iii) your breach of these Terms.
          </p>
          <h3>
            <em>General</em>
          </h3>
          <p>
            We make no claim or representation that the Website or its content
            may be lawfully viewed or accessed, or that our tools may be used,
            outside of the United States. Access to the Website or the content
            or our tools may not be legal by certain persons or in certain
            countries. If you access the Website or its content or use our
            tools, you do so at your own risk. You are responsible for
            compliance with the laws of your country or jurisdiction. Any
            software downloaded from the Website is further subject to United
            States export control laws, and you are responsible for the
            violation of any such laws. By downloading or using such software,
            you represent and warrant that you are not violating such laws.
          </p>
          <p>
            These Terms are governed by the internal substantive laws of the
            State of Illinois, without respect to its conflict of laws
            principles. Jurisdiction for any claims arising under this agreement
            shall lie exclusively with the state or federal courts within Cook
            County, Illinois. If any provision of these Terms are found to be
            invalid by any court having competent jurisdiction, the invalidity
            of such provision shall not affect the validity of the remaining
            provisions of these Terms, which shall remain in full force and
            effect. No waiver of any term of these Terms shall be deemed a
            further or continuing waiver of such term or any other term. In
            addition, our failure to enforce any term of these Terms shall not
            be deemed as a waiver of such term or otherwise affect our ability
            to enforce such term at any point in the future. These Terms
            constitute the entire agreement between you and us with respect to
            the use of the Website, its content, our tools, or our Services. No
            changes to these Terms shall be made except by a revised posting on
            this page.
          </p>
          <h3>
            <em>Mobile Services</em>
          </h3>
          <p>
            If you use the Website through a mobile device, you agree that
            information about your use of the Website through your mobile device
            and carrier may be communicated to us, including the identity of
            your mobile carrier and your mobile device and your physical
            location. In addition, use of the Website through a mobile device
            may cause data to be displayed on and through your mobile device. By
            accessing the Website using a mobile device, you represent that to
            the extent you import any of Your Content to your mobile device that
            you have authority to share the transferred data with your mobile
            carrier or other access provider. In the event you change or
            deactivate your mobile account, you must promptly update your
            Website account information to ensure that your messages are not
            sent to the person that acquires your old number and the failure to
            do so is your responsibility. You acknowledge you are responsible
            for all charges and necessary permissions related to accessing the
            Website through your mobile access provider. Therefore, you should
            check with your provider to find out if the Website is available and
            the terms for such services for your specific mobile devices.
          </p>
          <p>
            By using any downloadable application to enable your use of the
            Website, you are confirming your acceptance of the terms of the end
            user license agreement associated with the app provided at download
            or installation, or as it may be updated from time to time.
          </p>
          <p>&nbsp;</p>
          <p>Update effective 09/01/2023</p>
          <p>&nbsp;</p>
          <h2 id="esource">ESource</h2>
          <p>
            <strong>Terms of Use</strong>
          </p>
          <p>
            These Terms of Use (“<strong>Terms</strong>“) apply to all users of
            ESource, including both administrators and users (“
            <strong>you</strong>“) of this website, any related phone apps, and
            any related social media accounts (the “<strong>Website</strong>”).
            These Terms include our&nbsp;
            <a href="https://escoffierglobal.com/privacy/">Privacy Policy</a>.
          </p>
          <h3>
            <em>Services Offered</em>
          </h3>
          <p>
            We offer the following services (the “<strong>Services</strong>“) on
            the Website:
          </p>
          <ul>
            <li>training videos; and</li>
            <li>courses relating to the culinary arts</li>
          </ul>
          <h3>
            <em>Binding Agreement; Revisions</em>
          </h3>
          <p>
            These Terms constitute a binding agreement between you and Auguste
            Escoffier Global Solutions, LLC, which is owned by Triumph Higher
            Education Corporation (“<strong>we</strong>,” “<strong>us</strong>,”
            and “<strong>our</strong>”), and you accept these Terms each time
            that you use or access the Website or its content or otherwise use
            our tools or our Services.
          </p>
          <p>
            If we have entered into a “hard-copy” written agreement with you
            that incorporates these Term of Use by reference, the combination of
            that written agreement and these Term of Use shall be the binding
            agreement between you and us. In the event of any inconsistency or
            conflict between that written agreement and these Terms of Use, the
            written agreement shall take precedence.
          </p>
          <p>
            We may revise these Terms from time to time. If we make any
            revisions, they will become effective when we post the modified
            version of these Terms on our Website. By visiting our Website or
            using our Services after we have posted revisions, you accept those
            revisions. The date at the end of these Terms indicates when they
            were last updated.
          </p>
          <p>
            These Terms will remain in full force and effect while you are a
            user of the Website. We may pursue all of our legal remedies,
            including removal of Your Content from the Website and immediate
            termination of your account and your access to the Website, its
            content, our tools and our Services upon any breach by you of these
            Terms. Even after you are no longer a user of the Website, certain
            provisions of these Terms will remain in effect, including&nbsp;
            <em>“Binding Agreement; Revisions,”&nbsp;</em>“
            <em>
              Use of our Website; Services and Content; Consequences of
              Violating These Terms
            </em>
            ,” “<em>Terms Applicable to All Users</em>,” “<em>Ideas</em>,” “
            <em>
              Identification of Agent to Receive Notification and Elements of
              Notification of Claimed Copyright or Trademark Infringement
            </em>
            ,” “
            <em>
              Policy Regarding Termination of Users Who Infringe the Copyright
              or Other Intellectual
            </em>
            Property,” “<em>Liability Disclaimers</em>,” “
            <em>
              Disclaimer of Warranties,” “Disclaimer of Consequential Damages,”
              “Limitation of Liability,” “Class Action Waiver,” “Links to Other
              Sites,” and “Indemnity.”
            </em>
          </p>
          <h3>
            <em>
              Use of our Website; Services and Content; Consequences of
              Violating These Terms
            </em>
          </h3>
          <p>
            When you register with the Website, you will be asked to login with
            Facebook, Google or another service or to create an account with us
            and provide us with your information, including your name, zip code,
            career and education level and a valid email address
            (“Information”). All Information that you supply to us must be
            accurate, complete and not misleading and must apply to you and not
            any other person. You are solely responsible for the content and
            accuracy of any Information that you submit to us. We may offer
            services and products to you based on the Information that you
            submit to us or that you agree to receive from us in accordance with
            our&nbsp;
            <a href="https://escoffierglobal.com/privacy/">Privacy Policy</a>.
          </p>
          <p>
            You have no ownership rights in your account, and if you cancel your
            account with us or your account is terminated due to inactivity or
            otherwise, your Information will no longer be available to you or to
            third parties, but it may continue to be used to compile aggregate
            data
          </p>
          <p>
            You may only use the Website, its content, our tools, and our
            Services in accordance with these Terms and then only for your
            personal or company use. The content of the Website, including page
            designs, layouts, text, graphics, images, video, audio, data and
            information, logos, button and icons, and software, and our tools (
            <em>e.g.</em>, our profile creator) are protected under copyright,
            trademark and other laws. All content on the Website (other than
            certain content that you and other users submit and retain) and all
            tools that we provide are our or our licensors’ property. Your
            unauthorized use of content or tools may violate applicable law for
            which you will be held responsible. If you are authorized to make a
            copy of any content or any tool, you must preserve all copyright,
            trademark, service mark, and other proprietary notices contained in
            that content.
          </p>
          <p>
            Any software code that is used to operate the Website, generate or
            display any content on the Website or provide our tools or our
            Services is also protected by copyright and other law, and you may
            not copy, use, or adapt any such software code. You agree not to
            sell or modify content (other than your own content) or any tool or
            reproduce, display, publicly perform, distribute, or otherwise use
            either in any way, except as explicitly authorized by these Terms.
          </p>
          <p>
            If you violate these Terms, we can terminate your access to the
            Website and its content and its tools, and you will no longer be
            able to use our Services. In addition, violations of these Terms
            could subject you to civil and/or criminal liability. We will
            investigate occurrences that may involve such violations, and we
            will cooperate with law enforcement authorities.
          </p>
          <h3>
            <em>Terms Applicable to All Users</em>
          </h3>
          <ul>
            <li>
              Content, including our content and other features, may be used
              only by ESource administrators and authorized users seeking
              training in the culinary arts.
            </li>
            <li>
              Tools that we may supply may only be used by individuals seeking
              training services.
            </li>
            <li>
              You are required to maintain the confidentiality of your account,
              your profile, and your password. You may not share your password
              with anyone, and you are solely responsible for your account, even
              if you have not authorized those uses. You will immediately notify
              us of any unauthorized use of your account or password.
            </li>
            <li>
              You cannot post, provide, distribute, store or destroy any
              material, including content, in violation of applicable law or in
              breach of our Privacy Policy.
            </li>
            <li>
              You cannot do anything that imposes an unreasonable burden or load
              on the Website.
            </li>
            <li>
              You cannot attempt to interfere with our Services to anyone else.
            </li>
            <li>
              You cannot violate or attempt to violate the security of the
              Website, including the use of probes, scans or tests of
              vulnerabilities unless we authorize you to do so.
            </li>
            <li>
              You cannot forge any TCP/IP packet header or any part of any other
              header information.
            </li>
            <li>
              You cannot reverse engineer or decompile any parts of the Website
              or its content or its tools.
            </li>
            <li>
              You cannot navigate or search the Website using tools other than
              the ones that we make available to you or have otherwise approved,
              though you may use standard web browsers.
            </li>
            <li>
              You cannot use any data mining, robots, scrapers, or similar data
              gathering or extraction methods.
            </li>
            <li>
              You cannot aggregate, copy or duplicate any content, unless
              explicitly permitted by these Terms.
            </li>
            <li>
              You cannot frame or link to any content, unless explicitly
              permitted by these Terms.
            </li>
            <li>
              You cannot post any content that (includes links to another who)
              promotes, endorses or assists false or misleading information or
              illegal or any other activities that violate these Terms.
            </li>
            <li>You cannot access data or information not intended for you.</li>
            <li>
              You cannot submit information if it violates the intellectual
              property rights of others.
            </li>
            <li>You cannot delete or alter anything posted by others.</li>
            <li>
              You cannot harass, incite harassment or advocate harassment of any
              group, company, or individual.
            </li>
            <li>
              You cannot send unsolicited mail or email, “junk mail,” “chain
              letters,” or unsolicited mass mailing, “spamming,” or “phishing,”
              or make unsolicited phone calls or send unsolicited texts, tweets
              or faxes promoting and/or advertising products or services to any
              user of the Website.
            </li>
            <li>
              You cannot use our Services for any unlawful purpose or any
              illegal activity.
            </li>
            <li>
              You cannot post or submit any content, resume, or job ad that is
              defamatory, libelous, offensive, vulgar, obscene, threatening,
              abusive, hateful, racist, discriminatory, menacing, of a sexual
              nature, or likely to cause annoyance, inconvenience,
              embarrassment, violence, or anxiety or could cause harassment to
              any person or include any links to pornographic, indecent or
              sexually explicit material of any kind, nor can you link to any
              items that facilitate any of the foregoing.
            </li>
            <li>
              You cannot provide or link to material that exploits people under
              the age of 18 in a sexual, violent or other manner, or solicits
              personal information from anyone under 18.
            </li>
            <li>
              You cannot provide instructional information about illegal
              activities or other activities prohibited by these Terms,
              including making or buying illegal weapons.
            </li>
            <li>
              You must be 13 years of age or older to use the Website, and, if
              you are over 13 and under 18, you cannot use the Website without
              the supervision of a parent or legal guardian.
            </li>
          </ul>
          <h3>
            <em>Ideas</em>
          </h3>
          <p>
            You should not send us your creative suggestions, ideas, drawings,
            concepts, inventions, or other information (“
            <strong>Your Ideas</strong>“), but if you do, we may use Your Ideas
            that you submit to us without liability to you.
          </p>
          <h3>
            <em>
              Identification of Agent to Receive Notification and Elements of
              Notification of Claimed Copyright or Trademark Infringement
            </em>
          </h3>
          <p>
            If you believe that your copyrighted work or trademark has been
            uploaded, posted or copied to the Website and is accessible in a way
            that constitutes infringement against your rights, please contact us
            at:&nbsp;
            <a href="mailto:compliance@escoffierglobal.com">
              compliance@escoffierglobal.com
            </a>
          </p>
          <p>or by regular mail at:</p>
          <p>
            Auguste Escoffier Global Solutions, LLC
            <br />
            150 N. Martingale Rd.
            <br />
            Suite 300
            <br />
            Schaumburg, IL. 60173
            <br />
            Attn: Compliance
          </p>
          <h3>
            <em>
              Policy Regarding Termination of Users Who Infringe the Copyright
              or Other Intellectual Property
            </em>
          </h3>
          <p>
            We require that our users refrain from violating the intellectual
            property rights of others. You agree not to use the Website to
            infringe the intellectual property rights of any other person, and
            we reserve the right to terminate your account if you do. If,
            however, you believe in good faith that a notice of copyright
            infringement has been wrongly filed against you, please contact us
            as provided in Section 6 above.
          </p>
          <h3>
            <em>Liability Disclaimers</em>
          </h3>
          <p>
            We are not responsible for any content provided by users, or its
            quality, truth, accuracy, reliability, completeness, timeliness or
            legality, and we make no representation or warranties about any
            content provided by users.
          </p>
          <p>
            The risks of using content provided by other users may include risks
            of physical harm or of dealing with strangers, underage persons or
            people acting under false pretenses. You assume all of those risks,
            and we make no representation or warranties about relating to such
            risks. Because user authentication is difficult, we cannot know that
            a user is who they claim to be.
          </p>
          <p>
            In any of your dealings with other users, if a dispute arises, your
            sole remedy is against them, you release us (and our agents and
            employees) from any claims, demands and damages (actual and
            consequential and direct and indirect) of every kind and nature,
            known and unknown, suspected and unsuspected, disclosed and
            undisclosed, arising out of or in any way connected with such
            disputes to the fullest extent permitted by law.
          </p>
          <p>
            The Website and its content and its tools may contain or create
            inaccuracies or errors and any tool or form that you use may not
            work properly or as you expect. we makes no representations or
            warranties about the accuracy, reliability, completeness, or
            timeliness of the Website or its content or about the utility or the
            effectiveness of the tools, and you use the Website and its content
            and the tools is at your own risk.
          </p>
          <p>
            If you are a California resident, you waive California Civil Code
            Section 1542, that says: “A general release does not extend to
            claims which the creditor does not know or suspect to exist in his
            or her favor at the time of executing the release, which if known by
            him or her must have materially affected his or her settlement with
            the debtor.”
          </p>
          <p>
            We encourage you to keep a back-up copy of any of Your Content. We
            are not liable for the deletion, loss, or unauthorized modification
            of any User Content.
          </p>
          <p>
            We do not provide or make any representation as to the quality or
            nature of any third party products or services purchased through the
            Website.
          </p>
          <p>
            If you believe that something on the Site violates these Terms,
            please contact us&nbsp;
            <a href="mailto:compliance@escoffierglobal.com">
              compliance@escoffierglobal.com
            </a>
          </p>
          <p>
            If we are notified of any content, tools or other materials that do
            not conform to these Terms, we may investigate and make an
            appropriate determination.
          </p>
          <h3>
            <em>Disclaimer of Warranties</em>
          </h3>
          <p>
            TO THE FULLEST EXTENT PROVIDED BY LAW, WE DO NOT WARRANT THAT THE
            WEBSITE WILL OPERATE ERROR-FREE OR THAT OUR WEBSITE IS FREE OF
            COMPUTER VIRUSES OR OTHERHARMFUL MECHANISMS. IF YOUR USE OF OUR
            WEBSITE, ITS CONTENT, ANY TOOLS, OR OUR SERVICES RESULTS IN THE NEED
            FOR SERVICING OR REPLACING EQUIPMENT OR DATA OR ANY OTHER COSTS, WE
            ARE NOT RESPONSIBLE FOR THOSE COSTS. OUR WEBSITE AND ITS CONTENT AND
            OUR TOOLS ARE PROVIDED ON AN “AS IS” BASIS WITHOUT ANY WARRANTIES OF
            ANY KIND. WE, TO THE FULLEST EXTENT PERMITTED BYLAW, DISCLAIM ALL
            WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THEWARRANTY OF
            MERCHANTABILITY, FITNESS FOR PARTICULAR PURPOSE AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES ABOUT THE
            ACCURACY,RELIABILITY, COMPLETENESS, OR TIMELINESS OF THE WEBSITE,
            ITS CONTENT, OUR TOOLS, OUR SERVICES, SOFTWARE, TEXT, GRAPHICS, AND
            LINKS.
          </p>
          <h3>
            <em>Disclaimer of Consequential Damages</em>
          </h3>
          <p>
            WE DISCLAIM ALL INCIDENTAL AND CONSEQUENTIAL DAMAGES, LOST PROFITS,
            OR DAMAGES RESULTING FROM LOST DATA, LOST EMPLOYMENT OPPORTUNITY OR
            BUSINESS INTERRUPTION RESULTING FROM THE USE OR INABILITY TO USE THE
            WEBSITE, ITS CONTENT, OUR TOOLS AND OUR SERVICES, WHETHER BASED ON
            WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR
            NOT WE ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
          <h3>
            <em>Limitation of Liability</em>
          </h3>
          <p>
            OUR MAXIMUM LIABILITY ARISING OUT OF OR IN CONNECTION WITH YOUR USE
            OF THE WEBSITE, ITS CONTENT, OUR TOOLS, OR OUR SERVICES, REGARDLESS
            OF THE CAUSE OF ACTION (WHETHER IN CONTRACT, TORT,BREACH OF WARRANTY
            OR OTHERWISE) WILL NOT EXCEED $100.
          </p>
          <h3>
            <em>Class Action Waiver</em>
          </h3>
          <p>
            As a condition for your ability to use or access the Website, its
            content, our tools and our Services, you agree not to sue us as a
            class plaintiff or class representative, join a class action as a
            class member, or participate in a class-action lawsuit brought
            against us relating to your use of or access to the Website, its
            content, our tools or our Services. If you are a job seeker, you
            acknowledge that your use of the Website is at no charge to you, and
            you further acknowledge that part of the price you are paying us to
            use the Website is your acceptance of this class action waiver.
            Nothing in this paragraph, however, limits your right to bring a
            lawsuit as an individual plaintiff.
          </p>
          <h3>
            <em>Links to Other Sites</em>
          </h3>
          <p>
            The Website contains links to other websites. These links are
            provided solely as a convenience to you, and we are not responsible
            for the content of such linked sites and do not make any
            representations or warranties regarding the content or accuracy of
            materials on such websites. If you decide to access a linked
            website, you do so at your own risk.
          </p>
          <h3>
            <em>Indemnity</em>
          </h3>
          <p>
            You agree to defend, indemnify, and hold harmless us, our
            affiliates, and our and their officers, directors, employees and
            agents, from and against any claims, actions or demands, including
            without limitation reasonable legal and accounting fees, alleging or
            resulting from (i) any of Your Content or other material you provide
            to us, (ii) your use of the Website, its content, our tools, or our
            Services, or (iii) your breach of these Terms.
          </p>
          <h3>
            <em>General</em>
          </h3>
          <p>
            We make no claim or representation that the Website or its content
            may be lawfully viewed or accessed, or that our tools may be used,
            outside of the United States. Access to the Website or the content
            or our tools may not be legal by certain persons or in certain
            countries. If you access the Website or its content or use our
            tools, you do so at your own risk. You are responsible for
            compliance with the laws of your country or jurisdiction. Any
            software downloaded from the Website is further subject to United
            States export control laws, and you are responsible for the
            violation of any such laws. By downloading or using such software,
            you represent and warrant that you are not violating such laws.
          </p>
          <p>
            These Terms are governed by the internal substantive laws of the
            State of Illinois, without respect to its conflict of laws
            principles. Jurisdiction for any claims arising under this agreement
            shall lie exclusively with the state or federal courts within Cook
            County, Illinois. If any provision of these Terms are found to be
            invalid by any court having competent jurisdiction, the invalidity
            of such provision shall not affect the validity of the remaining
            provisions of these Terms, which shall remain in full force and
            effect. No waiver of any term of these Terms shall be deemed a
            further or continuing waiver of such term or any other term. In
            addition, our failure to enforce any term of these Terms shall not
            be deemed as a waiver of such term or otherwise affect our ability
            to enforce such term at any point in the future. These Terms
            constitute the entire agreement between you and us with respect to
            the use of the Website, its content, our tools, or our Services. No
            changes to these Terms shall be made except by a revised posting on
            this page.
          </p>
          <h3>
            <em>Mobile Services</em>
          </h3>
          <p>
            If you use the Website through a mobile device, you agree that
            information about your use of the Website through your mobile device
            and carrier may be communicated to us, including the identity of
            your mobile carrier and your mobile device and your physical
            location. In addition, use of the Website through a mobile device
            may cause data to be displayed on and through your mobile device. By
            accessing the Website using a mobile device, you represent that to
            the extent you import any of Your Content to your mobile device that
            you have authority to share the transferred data with your mobile
            carrier or other access provider. In the event you change or
            deactivate your mobile account, you must promptly update your
            Website account information to ensure that your messages are not
            sent to the person that acquires your old number and the failure to
            do so is your responsibility. You acknowledge you are responsible
            for all charges and necessary permissions related to accessing the
            Website through your mobile access provider. Therefore, you should
            check with your provider to find out if the Website is available and
            the terms for such services for your specific mobile devices.
          </p>
          <p>
            By using any downloadable application to enable your use of the
            Website, you are confirming your acceptance of the terms of the end
            user license agreement associated with the app provided at download
            or installation, or as it may be updated from time to time.
          </p>
          <p>Update effective:&nbsp;09/01/2023.</p>
        </div>
      </div>
    </LegalContentSection>
  );
};

export default TermsAndConditionsContent;
