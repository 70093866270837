import * as React from 'react';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { AutoCompleteOption } from '@components/Forms/ReactHookFormsAutoComplete';
import FormFieldErrorText from '@components/Forms/FormFieldErrorText';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isMobileView'
  })<{ isMobileView: boolean }>(({ isMobileView }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    columnGap: '24px',
    rowGap: '24px',
    flexDirection: isMobileView ? 'column' : 'row'
  })),
  AutocompleteWrapper: styled(Box)({
    flex: 1,
    width: '100%'
  })
};

export interface AutocompleteSearchProps {
  id: string;
  options: AutoCompleteOption[];
  ctaText: string;
  disabled?: boolean;
  isMobileView?: boolean;
  onCtaClick: (value: AutoCompleteOption) => void;
  requiredMsg?: string;
  disabledErrMsg?: string;
  placeholder?: string;
}

const AutocompleteSearch: React.FC<AutocompleteSearchProps> = ({
  id,
  options,
  ctaText,
  disabled = false,
  isMobileView = false,
  onCtaClick,
  requiredMsg = 'A selection is required',
  disabledErrMsg,
  placeholder = ''
}) => {
  const [searchValue, setSearchValue] = React.useState<AutoCompleteOption>();
  const [showReqErrMsg, setReqErrMsg] = React.useState(false);
  const [showDisabledMsg, setShowDisabledMsg] = React.useState(false);

  const handleClick = (): void => {
    if (searchValue) {
      onCtaClick(searchValue);
      setSearchValue(undefined);
    } else {
      setReqErrMsg(true);
    }
  };

  const handleDisabledClick = (): void => {
    setShowDisabledMsg(true);
  };

  React.useEffect(() => {
    if (!disabled) {
      setShowDisabledMsg(false);
    }
  }, [disabled]);

  return (
    <Box>
      <Styled.Root
        isMobileView={isMobileView}
        onClick={disabled ? handleDisabledClick : undefined}
      >
        <Styled.AutocompleteWrapper>
          <Autocomplete
            id={id}
            key={searchValue?.label}
            value={searchValue}
            disabled={disabled}
            fullWidth
            options={options}
            getOptionDisabled={(option): boolean => {
              if (typeof option === 'string') {
                return false;
              } else {
                return !!option.disable;
              }
            }}
            onChange={(event, newValue): void => {
              if (newValue) {
                setSearchValue(newValue);
                setReqErrMsg(false);
              } else {
                setSearchValue(undefined);
              }
            }}
            isOptionEqualToValue={(option, value): boolean => {
              if (typeof option !== 'string' && typeof value !== 'string') {
                return option.value === value.value;
              }
              return false;
            }}
            renderInput={({ ...params }): React.ReactNode => (
              <TextField {...params} placeholder={placeholder} />
            )}
          />
          {isMobileView && showReqErrMsg && (
            <FormFieldErrorText message={requiredMsg} name={id} />
          )}

          {isMobileView && showDisabledMsg && disabledErrMsg && (
            <FormFieldErrorText message={disabledErrMsg} name={id} />
          )}
        </Styled.AutocompleteWrapper>

        <Button
          variant="contained"
          disabled={disabled}
          fullWidth={isMobileView}
          onClick={handleClick}
        >
          {ctaText}
        </Button>
      </Styled.Root>
      {!isMobileView && showReqErrMsg && (
        <FormFieldErrorText
          message={requiredMsg}
          name={id}
          disableAbsolutePosition
        />
      )}
      {!isMobileView && showDisabledMsg && disabledErrMsg && (
        <FormFieldErrorText
          message={disabledErrMsg}
          name={id}
          disableAbsolutePosition
        />
      )}
    </Box>
  );
};

export default AutocompleteSearch;
