import * as React from 'react';
import theme from '@themes/ui/escoffier';

const styles = {
  iconWithLabel: {
    display: 'flex',
    ...theme.typography.EC_TYPE_3XS
  },
  icon: {
    height: '13px',
    width: '13px',
    marginRight: '6px'
  }
};

interface Props {
  label: string;
  svgIcon: any;
  style?: any;
  color?: string;
  dataTestId?: string;
}

const ResumeIconWithLabel: React.FC<Props> = ({
  label,
  svgIcon: SvgIcon,
  style,
  color = '#333333',
  dataTestId = 'resume-icon-with-label'
}) => {
  let combinedStyles = {
    ...styles.iconWithLabel
  };

  if (style) {
    combinedStyles = { ...styles.iconWithLabel, ...style };
  }

  return (
    <div style={{ ...combinedStyles, color }} data-testid={dataTestId}>
      <SvgIcon style={styles.icon} />
      {label}
    </div>
  );
};

export default ResumeIconWithLabel;
