/* eslint-disable */
import { Codebook } from '@api/models/codebook.models';

// TODO: using number | string because educationType is not yet a Codebook
// The types here can be replaced with Codebook when educationType is a Codebook
type ValueReturnType = number | string;
type CodebookInputType<T extends ValueReturnType> = {
  value: T;
  label: string;
};

/**
 * Extracts an array containing only the value properties of an array of Codebooks
 * @param {Codebook[]} codebookArray
 * @returns number[] or string[] array of Codebook['value'] (Will only be number[] once educationType Codebook is created)
 */
export const getCodebookValueArray = <T extends ValueReturnType>(
  codebookArray: CodebookInputType<T>[]
): T[] => {
  return codebookArray.map((item) => item.value);
};

export const getCodebookValue = <T extends ValueReturnType>(
  codebook: CodebookInputType<T>
): T => {
  return codebook.value;
};
