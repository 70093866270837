import * as React from 'react';
import { StudentApi } from '@api/Students.api';
import { StudentWithSchoolStatus } from '@api/models/studentApi.models';

export type FetchStudentByCandidateId = {
  studentWithSchoolStatus?: StudentWithSchoolStatus;
  loaded: boolean;
  loading: boolean;
};

export const useFetchStudentWithSchoolStatusByCandidateId = (
  candidateId: number
): FetchStudentByCandidateId => {
  const [student, setStudent] = React.useState<StudentWithSchoolStatus>();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchStudent = async (): Promise<void> => {
    if (candidateId) {
      try {
        setLoading(true);
        const res = await StudentApi.getStudentWithSchoolStatusByCandidateId(
          candidateId
        );

        setStudent(res);
        setLoaded(true);
      } catch (error: any) {
        console.error(
          'Error for useFetchStudentWithSchoolStatusByCandidateId -> StudentApi.getStudentByCandidateId()',
          error
        );
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    }
  };

  React.useEffect(() => {
    fetchStudent();
  }, [candidateId]);

  return { studentWithSchoolStatus: student, loaded, loading };
};
