import * as React from 'react';
import {
  ApplicationOverview,
  ApplicationsByEmployer,
  DateRange
} from '@common/models/common.models';
import { Box, Typography } from '@mui/material';
import ApplicantsModal from '@pages/EcAdmin/AdminDashboardPage/ApplicantsModal/ApplicantsModal';
import ApplicationStatusOverview from '@pages/EcAdmin/AdminDashboardPage/ApplicationStatusOverview';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import ColoredBox from '@components/ColoredBox';
import ExpandableEmployerBreakdown from '@pages/EcAdmin/AdminDashboardPage/ExpandableEmployerBreakdown';
import LabelWithValue from '@components/LabelWithValue';
import NoDataMessage from './NoDataMessage';
import StepSection from '@components/StepSection';
import { useApplicationStatusData } from '@pages/EcAdmin/AdminDashboardPage/useApplicationStatusData';

interface Props {
  applicationOverview: ApplicationOverview;
  chartLabel: string;
  dateRange: DateRange;
  companyId?: number;
  hideModals?: boolean;
  parentCompanyId?: number;
}

const JobApplicationsSection: React.FC<Props> = ({
  applicationOverview,
  chartLabel,
  dateRange,
  parentCompanyId,
  hideModals
}) => {
  const { perStatus, total } = applicationOverview;
  const { appStatusPieData, notHiredBarData, status, openModal, setOpenModal } =
    useApplicationStatusData(perStatus, total, hideModals);

  const sortByEmployer = (
    appsByEmployers: ApplicationsByEmployer[]
  ): ApplicationsByEmployer[] =>
    appsByEmployers.sort((a, b) =>
      a.employer.toLowerCase().localeCompare(b.employer.toLowerCase())
    );

  const sortedAppsByEmployers = sortByEmployer(
    applicationOverview.applicationsByEmployers
  );

  return (
    <StepSection title="Job Applications" dataTestId="job-applications-section">
      {perStatus.length ? (
        <>
          <ApplicationStatusOverview
            dataTestId="job-apps-pie-chart"
            label={chartLabel}
            pieData={appStatusPieData.slice(1)}
            notHiredData={notHiredBarData}
            content={
              <BottomMarginContentWrapper
                bottomMargin="8px"
                dataTestId="status-wrapper"
              >
                {appStatusPieData.map((data) => (
                  <React.Fragment key={data.name}>
                    {!!data.value && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {data.fill && <ColoredBox color={data.fill} />}
                        <LabelWithValue
                          onClick={data.onClick}
                          label={data.name}
                          value={data.value}
                        />
                      </Box>
                    )}
                  </React.Fragment>
                ))}
              </BottomMarginContentWrapper>
            }
          />
          <BottomMarginContentWrapper
            bottomMargin="32px"
            dataTestId="employer-breakdown"
          >
            <Typography variant="EC_TYPE_LG">Breakdown by Employer</Typography>
            <Box>
              {sortedAppsByEmployers.map((employerApps) => (
                <ExpandableEmployerBreakdown
                  key={employerApps.employer}
                  employerApps={employerApps}
                  chartLabel={chartLabel}
                  dateRange={dateRange}
                  hideModals={hideModals}
                  parentCompanyId={parentCompanyId}
                />
              ))}
            </Box>
          </BottomMarginContentWrapper>
          {openModal && dateRange && (
            <ApplicantsModal
              open={openModal}
              status={status}
              parentCompanyId={parentCompanyId}
              dateRange={dateRange}
              handleClose={(): void => setOpenModal(false)}
            />
          )}
        </>
      ) : (
        <NoDataMessage
          title="No job applications submitted within the selected date range"
          dataTestId="no-applications-container"
        />
      )}
    </StepSection>
  );
};

export default JobApplicationsSection;
