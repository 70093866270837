import { ExternshipStatus } from '@api/models/externshipApi.models';

export const ACTIVE_STATUSES = [ExternshipStatus.ACTIVE];

export const PROPOSED_STATUSES = [
  ExternshipStatus.PENDING,
  ExternshipStatus.PENDING_SITE_AGREEMENT,
  ExternshipStatus.PENDING_EXT_MNG_AGREEMENT,
  ExternshipStatus.PENDING_EXT_STD_AGREEMENT,
  ExternshipStatus.PENDING_EXT_CS_AGREEMENT,
  ExternshipStatus.PENDING_PROXY_AGREEMENT,
  ExternshipStatus.PENDING_SOFT_VERIFICATION
];

export const COMPLETED_STATUSES = [
  ExternshipStatus.COMPLETED,
  ExternshipStatus.NO_PASS
];

export const INACTIVE_STATUSES = [
  ExternshipStatus.ARCHIVED,
  ExternshipStatus.CANCELED,
  ExternshipStatus.DECLINED,
  ExternshipStatus.CS_REJECTED
];

export const ALL_STATUSES = [
  ...ACTIVE_STATUSES,
  ...PROPOSED_STATUSES,
  ...COMPLETED_STATUSES,
  ...INACTIVE_STATUSES
];

export enum ExternshipFilter {
  ALL_STATUSES = 10,
  PROPOSED_STATUSES = 20,
  ACTIVE_STATUSES = 30,
  COMPLETED_STATUSES = 40,
  INACTIVE_STATUSES = 50
}

export const studentViewFilterMap = {
  10: ALL_STATUSES,
  20: PROPOSED_STATUSES,
  30: ACTIVE_STATUSES,
  40: COMPLETED_STATUSES,
  50: INACTIVE_STATUSES
};
