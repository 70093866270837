import * as React from 'react';
import ErrorPageLayout from '@pages/Errors/ErrorPageLayout';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useKeycloakContext } from '@common/context/keycloakContext';

const NotFoundPage: React.FC = () => {
  const { isAuthenticated } = useKeycloakContext();

  const link = isAuthenticated ? '/user/dashboard' : '/';
  const linkText = isAuthenticated ? 'dashboard' : 'home';

  return (
    <ErrorPageLayout
      header="OOPS! WE CAN'T FIND THAT PAGE."
      subtext={
        <>
          You might want to double check how you got here, or head{' '}
          {isAuthenticated && <>back to your </>}
          <Link
            data-testid="not-found-page-link"
            component={RouterLink}
            to={link}
            underline="none"
          >
            {linkText}
          </Link>
          .
        </>
      }
    />
  );
};

export default NotFoundPage;
