import * as React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import {
  FormControl,
  MenuItem,
  Select,
  Stack,
  Theme,
  Typography
} from '@mui/material';
import FormFieldErrorText from './FormFieldErrorText';
import { styled } from '@mui/system';

const Styled = {
  Container: styled(Stack)({
    position: 'relative'
  }),
  LabelContainer: styled(Stack)({
    justifyContent: 'space-between',
    alignItems: 'center'
  }),
  Label: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'required'
  })<{ required: boolean }>(({ required, theme }) => ({
    marginBottom: '11px',
    position: 'relative',
    ['@media (max-width:480px)']: {
      marginBottom: '5px',
      fontSize: (theme as Theme).typography.EC_TYPE_SM.fontSize,
      lineHeight: (theme as Theme).typography.EC_TYPE_SM.lineHeight
    },

    '&:after': {
      content: required ? '"*"' : '""',
      color: theme.palette.error.main,
      marginLeft: '4px'
    }
  }))
};

interface Props {
  name: string;
  label?: string;
  dataTestId?: string;
  rules?: RegisterOptions;
  options: { label: string | JSX.Element; value: any; disabled?: boolean }[];
  placeholder?: string;
  tooltip?: JSX.Element;
  disabled?: boolean;
  disableRequiredAstrisk?: boolean;
  small?: boolean;
}

const ReactHookFormsSelect: React.FC<Props> = ({
  label,
  name,
  options,
  dataTestId,
  placeholder,
  rules,
  tooltip = undefined,
  disabled = false,
  disableRequiredAstrisk = false,
  small
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const getLabel = (value: any): string | JSX.Element => {
    const option = options.find((opt) => opt.value === value);

    if (option) {
      return option.label;
    }

    return '';
  };

  return (
    <Styled.Container id={name}>
      <FormControl fullWidth>
        {label && (
          <Styled.LabelContainer direction="row">
            <Styled.Label
              data-testid={`${name}-select-label`}
              variant={small ? 'EC_TYPE_2XS' : 'EC_TYPE_BASE'}
              required={!!rules?.required && !disableRequiredAstrisk}
            >
              {label}
            </Styled.Label>
            {tooltip ?? null}
          </Styled.LabelContainer>
        )}
        <Controller
          name={name}
          control={control}
          rules={{ ...rules }}
          render={({ field }): JSX.Element => (
            <FormControl error={!!errors[name]}>
              <Select
                {...field}
                data-testid="rhf-select-component"
                disabled={disabled}
                value={field.value ?? ''}
                labelId={`${name}-label`}
                displayEmpty
                defaultValue=""
                renderValue={(selected): React.ReactNode => {
                  if (
                    !placeholder &&
                    ((!selected && selected !== 0) || selected.length === 0)
                  ) {
                    return <></>;
                  }

                  if (
                    placeholder &&
                    ((!selected && selected !== 0) || selected.length === 0)
                  ) {
                    return <>{placeholder}</>;
                  }
                  return <>{getLabel(selected)}</>;
                }}
                inputProps={{ 'data-testid': dataTestId }}
              >
                {options.map((option, idx) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={!!option.disabled}
                    data-testid={`select-menu-item-${idx}`}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        {errors[name] && (
          <FormFieldErrorText name={name} message={errors[name].message} />
        )}
      </FormControl>
    </Styled.Container>
  );
};

export default ReactHookFormsSelect;
