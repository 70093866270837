import {
  CandidateAboutPutReq,
  CandidateCareerGoalPutReq,
  CandidateExperiencePutReq,
  CandidatePersonalizePutReq,
  CandidateProfile,
  CandidateProfilePublic,
  SaveResumePutReq
} from '@api/models/candidateApi.models';
import { api } from '@api/configs/base';
import { apiConfig } from '@api/configs/api.config';
import { ApiResponse } from '@api/models/base.models';
import { CandidateCareerInfo } from '@interfaces/careerJourney.interfaces';
import { Codebook } from '@api/models/codebook.models';

export class CandidateApi {
  static async getById(id: number): Promise<ApiResponse<CandidateProfile>> {
    try {
      const response = await api.get<ApiResponse<CandidateProfile>>(
        `${apiConfig.candidateUrl}/${id}`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for CandidateApi.getById(): ${error}`);
      throw error;
    }
  }

  static async getByIdPublic(
    id: number
  ): Promise<ApiResponse<CandidateProfilePublic>> {
    try {
      const response = await api.get<ApiResponse<CandidateProfile>>(
        `${apiConfig.candidateUrl}/${id}/public`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for CandidateApi.getByIdPublic(): ${error}`);
      throw error;
    }
  }

  static async getByKeycloakId(
    key: string
  ): Promise<ApiResponse<CandidateProfile>> {
    try {
      const response = await api.get<ApiResponse<CandidateProfile>>(
        `${apiConfig.candidateUrl}/user/${key}`
      );
      return response.data;
    } catch (error: any) {
      console.error(`Error for CandidateApi.getByKeycloakId(): ${error}`);
      throw error;
    }
  }

  static async updateCandidateAbout(
    id: number,
    data: CandidateAboutPutReq
  ): Promise<ApiResponse<CandidateAboutPutReq>> {
    try {
      const response = await api.put<ApiResponse<CandidateAboutPutReq>>(
        `${apiConfig.candidateUrl}/${id}/about`,
        data
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for CandidateApi.updateCandidateAbout(): ${error}`);
      throw error;
    }
  }

  static async updateCandidateExperience(
    id: number,
    data: CandidateExperiencePutReq
  ): Promise<ApiResponse<CandidateExperiencePutReq>> {
    try {
      const response = await api.put<ApiResponse<CandidateExperiencePutReq>>(
        `${apiConfig.candidateUrl}/${id}/experience`,
        data
      );

      return response.data;
    } catch (error: any) {
      console.error(
        `Error for CandidateApi.updateCandidateExperience(): ${error}`
      );
      throw error;
    }
  }

  static async updateCandidatePersonalize(
    id: number,
    data: CandidatePersonalizePutReq
  ): Promise<ApiResponse<CandidatePersonalizePutReq>> {
    try {
      const response = await api.put<ApiResponse<CandidatePersonalizePutReq>>(
        `${apiConfig.candidateUrl}/${id}/personalize`,
        data
      );

      return response.data;
    } catch (error: any) {
      console.error(
        `Error for CandidateApi.updateCandidatePersonalize(): ${error}`
      );
      throw error;
    }
  }

  static async updateCandidateStudentId(
    candidateId: number,
    studentId: string
  ): Promise<void> {
    try {
      await api.put<ApiResponse<void>>(
        `${apiConfig.candidateUrl}/${candidateId}/student/${studentId}`
      );
    } catch (error: any) {
      console.error(
        `Error for CandidateApi.updateCandidateStudentId(): ${error}`
      );
      throw error;
    }
  }

  static async findInvitedCandidatesByJobPostId(
    jobPostId: number
  ): Promise<ApiResponse<number[]>> {
    try {
      const response = await api.get<ApiResponse<number[]>>(
        `${apiConfig.candidateUrl}/job-post/${jobPostId}/invited-candidates`
      );
      return response.data;
    } catch (error) {
      console.error(`Error for CandidateApi.findInvitedCandidates(): ${error}`);
      throw error;
    }
  }

  // TODO educationType Codebook: not currently set up in back end
  static async getEducationTypes(): Promise<ApiResponse<Codebook[]>> {
    try {
      const response = await api.get<ApiResponse<Codebook[]>>(
        `${apiConfig.candidateUrl}/educationTypes`
      );

      return response.data;
    } catch (error) {
      console.error(`Error for CandidateApi.getEducationTypes(): ${error}`);
      throw error;
    }
  }

  static async setCareerGoal(
    candidateId: number,
    careerData: CandidateCareerGoalPutReq
  ): Promise<ApiResponse<CandidateCareerGoalPutReq>> {
    try {
      const response = await api.put<ApiResponse<CandidateCareerGoalPutReq>>(
        `${apiConfig.candidateUrl}/${candidateId}/goal`,
        careerData
      );

      return response.data;
    } catch (error) {
      console.error(`Error for CandidateApi.setCareerGoal(): ${error}`);
      throw error;
    }
  }

  static async removeCareerGoal(
    candidateId: number
  ): Promise<ApiResponse<CandidateCareerInfo>> {
    try {
      const response = await api.put(
        `${apiConfig.candidateUrl}/${candidateId}/goal/remove`
      );
      return response.data;
    } catch (error) {
      console.error(`Error for CandidateApi.removeCareerGoal(): ${error}`);
      throw error;
    }
  }

  static async getCareerInfo(
    candidateId: number
  ): Promise<ApiResponse<CandidateCareerInfo>> {
    try {
      const response = await api.get<ApiResponse<CandidateCareerInfo>>(
        `${apiConfig.candidateUrl}/${candidateId}/careers`
      );

      return response.data;
    } catch (error) {
      console.error(`Error for CandidateApi.getCareerInfo(): ${error}`);
      throw error;
    }
  }

  static async saveResume(
    candidateId: number,
    data: SaveResumePutReq
  ): Promise<ApiResponse<CandidateProfile>> {
    try {
      const response = await api.put<ApiResponse<CandidateProfile>>(
        `${apiConfig.candidateUrl}/${candidateId}/resume/pdf`,
        data
      );

      return response.data;
    } catch (error) {
      console.error(`Error for CandidateApi.saveResume(): ${error}`);
      throw error;
    }
  }

  static async setEnabled(
    candidateId: number,
    value: boolean
  ): Promise<ApiResponse<CandidateProfile>> {
    try {
      const response = await api.patch<ApiResponse<CandidateProfile>>(
        `${apiConfig.candidateUrl}/${candidateId}/enabled/${value}`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for CandidateApi.setEnabled(): ${error}`);
      throw error;
    }
  }
}
