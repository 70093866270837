import * as React from 'react';
import { FileType } from '@api/models/fileApi.models';
import ImageUploadWell from '@components/ImageUpload/ImageUploadWell';
import StepSection from '@components/StepSection';
import StepSubSection from '@components/StepSubSection';

interface Props {
  resumes: FileType[];
}

const ProfileResumeSection: React.FC<Props> = ({ resumes }) => {
  return (
    <StepSection title="Resume" dataTestId="candidate-resume-section">
      <StepSubSection
        title="Attach a resume (optional)"
        subtext="A new, professional resume will be automatically generated from information that you provided in your profile. If you have another resume that you would like to show, you can upload it here:"
      >
        <ImageUploadWell
          name="resumeId"
          displayText="Drop your resume here"
          validTypes={['application/pdf']}
          multiple={false}
          files={resumes}
          secure={true}
        />
      </StepSubSection>
    </StepSection>
  );
};

export default ProfileResumeSection;
