import * as React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import {
  includesLetter,
  includesNumber,
  minLength,
  notMatchEmail
} from '@common/rhf-validators/passwordValidators';
import { AuthApi } from '@api/Auth.api';
import Box from '@mui/material/Box';
import { emailPattern } from '@common/regex/email';
import { InviteInfo } from '@api/models/inviteApi.models';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import ReactHookFormsInputPhoneMask from '@components/Forms/ReactHookFormsInputPhoneMask';
import ReactHookFormsPassword from '@components/Forms/ReactHookFormsPassword';
import { RecruiterSignupRequest } from '@api/models/authApi.models';
import RhfAcceptTermsCheckbox from '@components/Forms/RhfAcceptTermsCheckbox';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)({
    margin: '0 auto',
    maxWidth: '474px',
    minWidth: '350px'
  }),
  GridItem: styled(Grid)({
    marginBottom: '34px'
  }),
  CreateAccountBtn: styled(Button)({
    marginTop: '52px',
    height: '49px',
    width: '227px',
    // EC-Type-lg
    fontSize: '20px',
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '25px',
    letterSpacing: '0em'
  })
};

interface FormValues {
  firstName: string;
  lastName: string;
  companyId: number;
  companyName: string;
  jobTitle: string;
  email: string;
  password: string;
  confirmPassword: string;
  acceptTerms: boolean;
  externshipId: number | null;
  phone: string;
}

interface Props {
  setEmailConfirmationSent: React.Dispatch<React.SetStateAction<boolean>>;
  inviteInfo: InviteInfo | undefined;
}

const CreateRecruiterAccountForm: React.FC<Props> = ({
  setEmailConfirmationSent,
  inviteInfo
}) => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);

  const methods = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      companyId: 0,
      companyName: '',
      jobTitle: '',
      email: '',
      password: '',
      confirmPassword: '',
      acceptTerms: false,
      externshipId: null,
      phone: ''
    }
  });

  const { handleSubmit, watch, setValue, setError, reset } = methods;

  const currPassword = watch('password');
  const termsAccepted = watch('acceptTerms');
  const email = watch('email');

  const handleModalOpen = (): void => {
    setModalIsOpen(true);
  };

  const handleModalClose = (): void => {
    setModalIsOpen(false);
  };

  const handleAcceptTermsAndConditions = (): void => {
    setValue('acceptTerms', true, { shouldValidate: true });
    handleModalClose();
  };

  React.useEffect(() => {
    if (inviteInfo) {
      const { company } = inviteInfo;
      reset({
        email: inviteInfo.email,
        firstName: inviteInfo?.firstName ?? '',
        lastName: inviteInfo?.lastName ?? '',
        jobTitle: inviteInfo?.jobTitle ?? '',
        phone: inviteInfo?.phone ?? '',
        externshipId: inviteInfo?.externshipId ?? null,
        companyName: company.name,
        companyId: company.id
      });
    }
  }, [inviteInfo]);

  const handleValidForm = (data: FormValues): void => {
    const postBody: RecruiterSignupRequest = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      companyId: data.companyId,
      jobTitle: data.jobTitle,
      password: data.password,
      confirm_password: data.confirmPassword,
      inviteCode: inviteInfo?.inviteCode,
      roles: inviteInfo?.roles,
      externshipId: inviteInfo?.externshipId ?? null,
      phone: data.phone
    };

    AuthApi.recruiterSignup(postBody)
      .then(() => setEmailConfirmationSent(true))
      .catch((error: any) => {
        const errData = error.response.data.data;

        if (errData.length) {
          errData.forEach(({ field, message }) =>
            setError(field, { type: 'manual', message })
          );
        }
      });
  };

  return (
    <Styled.Root>
      <Typography variant="EC_TYPE_3XL" mt={6} mb={4}>
        Sign Up
      </Typography>
      <FormProvider {...methods}>
        <form>
          <Grid
            container
            columnSpacing={3}
            alignItems="flex-end"
            sx={{ marginBottom: '14px' }}
          >
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsInput
                name="firstName"
                label="First Name"
                autocomplete="given-name"
                dataTestId="create-recruiter-firstName-input"
                rules={{ required: 'First name is required' }}
              />
            </Styled.GridItem>
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsInput
                name="lastName"
                label="Last Name"
                autocomplete="family-name"
                dataTestId="create-recruiter-lastName-input"
                rules={{ required: 'Last name is required' }}
              />
            </Styled.GridItem>

            <Styled.GridItem item xs={12} sm={12}>
              <ReactHookFormsInput
                disabled={true}
                name="email"
                label="Email"
                dataTestId="create-recruiter-email-input"
                autocomplete="email"
                rules={{
                  required: 'Email is required.',
                  pattern: {
                    value: emailPattern,
                    message: 'Please enter a valid email'
                  }
                }}
              />
            </Styled.GridItem>

            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsInputPhoneMask
                name="phone"
                label="Personal Phone Number"
                dataTestId="create-recruiter-personal-phone-number-input"
                rules={{
                  required: 'Phone number is required'
                }}
              />
            </Styled.GridItem>
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsInput
                name="jobTitle"
                label="Job Title"
                dataTestId="create-recruiter-jobTitle-input"
                rules={{ required: 'Job title is required' }}
              />
            </Styled.GridItem>

            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsPassword
                name="password"
                label="Create Password"
                dataTestId="create-recruiter-password-input"
                autocomplete="new-password"
                displayTooltip
                rules={{
                  required: 'Password is required',
                  validate: {
                    notMatchEmail: notMatchEmail(email),
                    minLength,
                    includesNumber,
                    includesLetter
                  }
                }}
              />
            </Styled.GridItem>
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsPassword
                name="confirmPassword"
                label="Confirm Password"
                autocomplete="new-password"
                dataTestId="create-recruiter-confirmPassword-input"
                rules={{
                  required: 'Confirm password is required',
                  validate: (value): boolean | string =>
                    value === currPassword || 'Passwords do not match'
                }}
              />
            </Styled.GridItem>

            <Styled.GridItem item xs={12} sm={12}>
              <ReactHookFormsInput
                disabled={true}
                name="companyName"
                label="Company Name"
                dataTestId="create-recruiter-companyName-input"
                rules={{ required: 'Company name is required' }}
              />
            </Styled.GridItem>
          </Grid>
          <RhfAcceptTermsCheckbox
            termsAccepted={termsAccepted}
            modalOpen={modalIsOpen}
            setModalOpen={handleModalOpen}
            setModalClosed={handleModalClose}
            handleAcceptTermsAndConditions={handleAcceptTermsAndConditions}
          />
          <Styled.CreateAccountBtn
            variant="contained"
            onClick={handleSubmit(handleValidForm)}
            data-testid="create-recruiter-btn"
          >
            Create Account
          </Styled.CreateAccountBtn>
        </form>
      </FormProvider>
    </Styled.Root>
  );
};

export default CreateRecruiterAccountForm;
