import get from 'lodash/fp/get';
import { SortOrderType } from '@interfaces/search.interfaces';

/**
 * Function by sorting table data of type T, by category of type keyof T
 * @param array the data to be sorted
 * @param order type OrderType 'asc' | 'desc'
 * @param orderBy category to sort by. Must be a key property of the array passed
 * @returns the passed array, sorted asc or desc, by passed category
 */
export function sortTableData<T, P extends keyof T>(
  array: T[],
  order: SortOrderType,
  orderBy: P
): T[] {
  return array.sort((a, b) => {
    const itemA =
      typeof get(orderBy, a) === 'number'
        ? get(orderBy, a)
        : (a[orderBy] as unknown as string).toString().toLowerCase();

    const itemB =
      typeof get(orderBy, b) === 'number'
        ? get(orderBy, b)
        : (b[orderBy] as unknown as string).toString().toLowerCase();

    if (order === 'DESC') {
      return itemA < itemB ? 1 : -1;
    }
    return itemA > itemB ? 1 : -1;
  });
}

const getItem = <T>(orderBy: any, item: T): any => {
  return typeof get(orderBy, item) === 'number'
    ? get(orderBy, item)
    : (item[orderBy] as unknown as string).toString().toLowerCase();
};

export function sortTwoDimension<T, P extends keyof T>(
  array: T[],
  order: SortOrderType,
  orderBy: P,
  secondaryOrderBy: P
): T[] {
  return array.sort((a, b) => {
    const itemA = getItem(orderBy, a);
    const itemB = getItem(orderBy, b);
    const itemA2 = getItem(secondaryOrderBy, a);
    const itemB2 = getItem(secondaryOrderBy, b);

    if (order === 'ASC') {
      if (itemA < itemB) {
        return -1;
      } else if (itemA > itemB) {
        return 1;
      } else {
        if (itemA2 < itemB2) {
          return -1;
        } else if (itemA2 > itemB2) {
          return 1;
        } else {
          return 0;
        }
      }
    } else {
      if (itemA < itemB) {
        return 1;
      } else if (itemA > itemB) {
        return -1;
      } else {
        if (itemA2 < itemB2) {
          return 1;
        } else if (itemA2 > itemB2) {
          return -1;
        } else {
          return 0;
        }
      }
    }
  });
}
