import * as React from 'react';
import { Button, Grid } from '@mui/material';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import {
  SetLoginSettings,
  UserLoginSettings
} from '@api/models/settingsApi.models';
import EcDialog from '@components/EcDialog';
import { emailPattern } from '@common/regex/email';
import { IKeycloakUser } from '@api/models/userApi.models';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import { scrollToError } from '@common/helpers/scrollToError';
import { SettingsApi } from '@api/Settings.api';
import StepSubSection from '@components/StepSubSection';
import { styled } from '@mui/system';
import { useToast } from '@components/Toast';

const Styled = {
  SaveBtn: styled(Button)(({ theme }) => ({
    marginTop: '30px',
    marginBottom: '13px',
    color: theme.palette.WHITE.main
  }))
};

interface Props {
  isCandidate: boolean;
  isCompanyAdmin: boolean;
  isGlrcUser: boolean;
  keycloakUser: IKeycloakUser;
  isUserSSO: boolean;
  setLoginSettings: SetLoginSettings;
  loginSettings?: UserLoginSettings;
}

const ChangeLoginOptions: React.FC<Props> = ({
  isCandidate,
  isCompanyAdmin,
  isGlrcUser,
  keycloakUser,
  isUserSSO,
  loginSettings,
  setLoginSettings
}) => {
  const settingsSaved = React.useRef<boolean>(false);
  const setSettingsSaved = (val: boolean): void => {
    settingsSaved.current = val;
  };
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const displaySubmitBtn =
    (isCandidate && !isUserSSO) || isCompanyAdmin || isGlrcUser;

  const defaultValues = {
    firstName: keycloakUser?.given_name,
    lastName: keycloakUser?.family_name,
    email: keycloakUser?.email,
  };

  const { openToast } = useToast();

  const methods = useForm<UserLoginSettings>({
    defaultValues,
    mode: 'onChange'
  });

  const formOrder = {
    email: 1,
  };

  const {
    handleSubmit,
    watch,
    reset,
    getValues,
    setError,
    formState: { errors, isDirty, dirtyFields }
  } = methods;

  const email = watch('email');

  const setSettings = React.useCallback(
    (newSettings: UserLoginSettings) => {
      setLoginSettings(newSettings);
    },
    [setLoginSettings]
  );

  const onInvalid = (errors: FieldErrors): void => {
    setIsModalOpen(false);
    scrollToError(errors, formOrder);
  };

  const onValid = async (data: UserLoginSettings): Promise<void> => {
    setIsModalOpen(false);
    const postBody = {
      isCandidate: isCandidate,
      isCompany: isCompanyAdmin,
      isGlrcUser: isGlrcUser,
      userId: keycloakUser.sub,
      username: keycloakUser.preferred_username,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      jobTitle: data.jobTitle,
      phone: data.phone
    };

    try {
      // TODO: Need to enable career-services settings update.
      // See settings.controller.ts -> updateUser(). Need service to update career-services user.
      await SettingsApi.updateUser(postBody);

      setLoginSettings(data);
      openToast('Your changes have been updated successfully');
    } catch (error: any) {
      console.error(
        'Error for ChangeLoginOptions -> SettingsApi.updateUser()',
        error
      );
      const errData = error.response.data;
      const message = errData.data.message;

      if (errData.data) {
        setError(errData.data.field, {
          type: 'manual',
          message: message
        });
        scrollToError(methods.formState.errors, formOrder);
      }
    } finally {
      setSettingsSaved(true);
    }
  };

  const onOpenModalHandler = (): void => {
    // Check is no errors in form state and is email changed
    const validFormWithEmailChanged =
      !Object.keys(errors).length && dirtyFields.email;

    if (validFormWithEmailChanged) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  };

  const onCloseHandler = (): void => {
    setIsModalOpen(false);
  };

  /* 
    Resets the form with defaultValues from database
    from initial render
  */
  React.useEffect(() => {
    if (loginSettings) {
      reset(loginSettings);
    }
  }, [loginSettings]);

  /* 
    This will reset the state of the form once it is successfully submitted
    Save Changes button will return to disabled state until form isDirty again
  */
  React.useEffect(() => {
    if (settingsSaved.current && !open) {
      const submitted = {
        ...getValues()
      };

      setSettings(submitted);
      setSettingsSaved(false);
    }
  }, [settingsSaved.current, open]);

  return (
    <>
      <StepSubSection title="Login Options">
        <FormProvider {...methods}>
          <Grid container spacing={4}>
            {isCompanyAdmin && (
              <Grid item xs={12} sm={6}>
                <ReactHookFormsInput
                  dataTestId="account-settings-first-name-input"
                  label="First Name"
                  name="firstName"
                  rules={{
                    required: 'First Name is required'
                  }}
                />
              </Grid>
            )}
            {isCompanyAdmin && (
              <Grid item xs={12} sm={6}>
                <ReactHookFormsInput
                  dataTestId="account-settings-last-name-input"
                  label="Last Name"
                  name="lastName"
                  rules={{
                    required: 'Last Name is required'
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <ReactHookFormsInput
                dataTestId="account-settings-email-input"
                label="Email"
                name="email"
                disabled={isUserSSO}
                rules={{
                  pattern: {
                    value: emailPattern,
                    message: 'Please enter a valid email'
                  },
                  required: 'Email is required'
                }}
              />
            </Grid>
          </Grid>
          {displaySubmitBtn && (
            <Styled.SaveBtn
              data-testid="update-account-settings-btn"
              variant="contained"
              onClick={
                dirtyFields.email && email !== ''
                  ? onOpenModalHandler
                  : handleSubmit(onValid, onInvalid)
              }
              disabled={!isDirty}
            >
              Update
            </Styled.SaveBtn>
          )}
        </FormProvider>
      </StepSubSection>
      <EcDialog
        content={`Please double check ${email} is the correct email address you want to change to. If the email address is incorrect, you will not be able to access this account. `}
        open={isModalOpen}
        confirmActionText="Change Email"
        handleClose={onCloseHandler}
        title="Verify Your Email Address"
        handleConfirm={handleSubmit(onValid, onInvalid)}
      />
    </>
  );
};
export default ChangeLoginOptions;
