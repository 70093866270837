/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { AdminContentManagementApi } from '@api/AdminContentManagement.api ';
import { DirectConnectPost } from '@api/models/adminContentManagementApi.models';

export type FetchDirectConnectPosts = {
  directConnectPosts: DirectConnectPost[];
  setDirectConnectPosts: React.Dispatch<
    React.SetStateAction<DirectConnectPost[]>
  >;
  fetchDirectConnectPosts: () => Promise<void>;
  loaded: boolean;
  loading: boolean;
};

export const useFetchDirectConnectPosts = (): FetchDirectConnectPosts => {
  const [directConnectPosts, setDirectConnectPosts] = React.useState<
    DirectConnectPost[]
  >([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchDirectConnectPosts = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await AdminContentManagementApi.getDirectConnectPosts();

      setDirectConnectPosts(res);
      setLoaded(true);
    } catch (error: any) {
      console.error(
        'Error for useFetchDirectConnectPosts -> AdminContentManagementApi.getDirectConnectPosts()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    fetchDirectConnectPosts();
  }, []);

  return {
    directConnectPosts,
    setDirectConnectPosts,
    fetchDirectConnectPosts,
    loaded,
    loading
  };
};
