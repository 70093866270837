/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import CompanyAlreadyExistsModal, {
  ExistingCompany
} from '@pages/Public/CreateAccountPage/CompanyAlreadyExistsModal';
import {
  EmployerSSOSignupRequest,
  JoinCompanyRequestBase
} from '@api/models/authApi.models';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { JoinRequest, JoinRequestStatus } from '@api/models/companyApi.models';
import RhfLocationSearch, {
  PlaceType
} from '@components/Forms/RhfLocationSearch';
import { AuthApi } from '@api/Auth.api';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import EcCrownWithLines from '@components/EcCrownWithLines';
import EcGoogleMap from '@components/EcGoogleMap';
import { getTwoLineAddress } from '@common/helpers/addressHelpers/getTwoLineAddress';
import { Location } from '@interfaces/location.interfaces';
import NoDataMessage from '@components/NoDataMessage';
import { notBeEmptyString } from '@common/rhf-validators/emptyString.validator';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import ReactHookFormsInputPhoneMask from '@components/Forms/ReactHookFormsInputPhoneMask';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { scrollToError } from '@common/helpers/scrollToError';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useFetchEmployerByKeycloakUserId } from '@common/fetches/useFetchEmployerByKeycloakUserId';
import { useFetchJoinRequestsByUserId } from '@common/fetches/useFetchJoinRequestsByUserId';
import { useKeycloakContext } from '@common/context/keycloakContext';

const Styled = {
  JoinRequestContainer: styled(Box)({
    border: `1px solid ${theme.palette.GRAY_2.main}`
  }),
  GoldHeader: styled(Box)({
    backgroundColor: theme.palette.gold1.main,
    minHeight: '67px'
  }),
  JoinRequestDetails: styled(Box)({
    padding: '64px 24px',
    display: 'flex',
    justifyContent: 'center'
  }),
  AwaitingApprovalContainer: styled(Box)({
    width: '100%',
    maxWidth: '700px'
  }),
  PendingRequest: styled(Box)({
    marginTop: '32px'
  }),
  CompanyName: styled(Typography)({
    color: theme.palette.GOLD_1.main,
    marginBottom: 0
  }),
  FormContainer: styled(Box)({
    maxWidth: '600px',
    margin: '0 auto'
  }),
  Button: styled(Button)({
    marginTop: '48px'
  })
};

interface FormValues {
  companyName: string;
  companyPhone: string;
  locations: Location[];
}

const formOrder = {
  companyName: 1,
  companyPhone: 2,
  locations: 3
};

const PendingUserDashboard: React.FC = () => {
  const { keycloakUser } = useKeycloakContext();
  const [existingCompanies, setExistingCompanies] = React.useState<
    ExistingCompany[]
  >([]);
  const [currExistingCompany, setCurrExistingCompany] =
    React.useState<ExistingCompany>();

  const { joinRequests, fetchJoinRequestsByUserId } =
    useFetchJoinRequestsByUserId(keycloakUser.sub);

  const [pendingRequests, setPendingRequests] = React.useState<JoinRequest[]>(
    []
  );

  const { employer: pendingEmployer } = useFetchEmployerByKeycloakUserId(
    keycloakUser.sub
  );

  React.useEffect(() => {
    if (existingCompanies.length) {
      setCurrExistingCompany(existingCompanies[0]);
    }
  }, [existingCompanies]);

  React.useEffect(() => {
    if (joinRequests) {
      const pendingRequests = joinRequests.filter(
        (r) => r.status === JoinRequestStatus.PENDING
      );

      setPendingRequests(pendingRequests);
    }
  }, [joinRequests]);

  const methods = useForm<FormValues>({
    defaultValues: {
      companyName: '',
      companyPhone: '',
      locations: []
    }
  });

  const { handleSubmit, setError, setValue, watch } = methods;

  const locations = watch('locations');

  React.useEffect(() => {
    if (locations.length === 1) {
      const companyName = locations[0].name || '';
      setValue('companyName', companyName);
    }
  }, [locations]);

  const handleRemoveLocation = (location: Location): void => {
    const newExistingCompaniesList = existingCompanies.filter(
      (l) => l.location.placeId !== location.placeId
    );
    setExistingCompanies(newExistingCompaniesList);

    const newLocationsList = locations.filter(
      (l) => l.placeId != location.placeId
    );
    setValue('locations', newLocationsList);
  };

  const handleError = (error): void => {
    const errData = error.response.data.data;
    if (errData.length) {
      if (errData[0].companyName && errData[0].companyId) {
        setExistingCompanies(errData);
      } else {
        errData.forEach(({ field, message }) =>
          setError(field, { type: 'manual', message })
        );
        scrollToError(methods.formState.errors, formOrder);
      }
    }
  };

  const handleInvalidForm = (errors: FieldErrors): void => {
    scrollToError(errors, formOrder);
  };

  // Request to create company
  const handleValidForm = async (data: FormValues): Promise<void> => {
    if (pendingEmployer) {
      const postBody: EmployerSSOSignupRequest = {
        // Employer Info
        userId: keycloakUser.sub,
        email: pendingEmployer.email,
        firstName: pendingEmployer.firstName,
        lastName: pendingEmployer.lastName,
        jobTitle: pendingEmployer.jobTitle,
        phone: pendingEmployer.phone || '',

        // Company Info
        companyName: data.companyName,
        companyPhone: data.companyPhone,
        locations: data.locations,
        offerings: []
      };

      try {
        await AuthApi.pendingUserSignup(postBody);
        window.location.href = '/user/dashboard';
      } catch (error) {
        handleError(error);
      }
    }
  };

  // Request to Join Company
  const handleValidFormForJoin = async (): Promise<void> => {
    if (currExistingCompany && pendingEmployer) {
      try {
        const postBody: JoinCompanyRequestBase = {
          userId: keycloakUser.sub,
          email: pendingEmployer.email,
          firstName: pendingEmployer.firstName,
          lastName: pendingEmployer.lastName,
          jobTitle: pendingEmployer.jobTitle,
          phone: pendingEmployer.phone || '',
          companyId: currExistingCompany.companyId
        };
        await AuthApi.joinRequestSsoSignUp(postBody);
        await fetchJoinRequestsByUserId();
        window.scrollTo(0, 0);
      } catch (error) {
        console.error(
          'Error for PendingUserDashboard.handleValidFormForJoin()',
          error
        );
        handleError(error);
      }
    }
  };

  const PendingRequest: React.FC<{ request: JoinRequest }> = ({ request }) => {
    return (
      <Styled.PendingRequest>
        <Box mt="24px" mb="24px">
          <Styled.CompanyName variant="EC_TYPE_4XL">
            {request.company.name}
          </Styled.CompanyName>
          {request.company.locations.map((l) => (
            <Box key={l.placeId}>{getTwoLineAddress(l)}</Box>
          ))}
        </Box>
        <EcGoogleMap locations={request.company.locations} />
      </Styled.PendingRequest>
    );
  };

  return (
    <RootPageLayout muiMaxWidth={false}>
      <BottomMarginContentWrapper bottomMargin={'48px'}>
        <EcCrownWithLines />
        <Styled.JoinRequestContainer>
          <Styled.GoldHeader />
          <Styled.JoinRequestDetails>
            {pendingRequests.length ? (
              <Styled.AwaitingApprovalContainer>
                <NoDataMessage
                  dataTestId="awaiting-approval-message"
                  title="AWAITING APPROVAL"
                  subtitle="You asked to join an organization that requires approval."
                  subTitleColor={theme.palette.GRAY_3.main}
                />
                {pendingRequests.map((r) => (
                  <PendingRequest key={r.id} request={r} />
                ))}
              </Styled.AwaitingApprovalContainer>
            ) : (
              <NoDataMessage
                dataTestId="select-your-company-message"
                title="SELECT YOUR COMPANY"
                subtitle="Select a company location to join another company or to create a new one."
                subTitleColor={theme.palette.GRAY_3.main}
              />
            )}
          </Styled.JoinRequestDetails>
        </Styled.JoinRequestContainer>
        {!pendingRequests.length && (
          <Styled.FormContainer>
            <FormProvider {...methods}>
              <BottomMarginContentWrapper bottomMargin="16px">
                <ReactHookFormsInput
                  name="companyName"
                  label="Company Name"
                  dataTestId="create-employer-companyName-input"
                  rules={{
                    required: 'Company name is required',
                    validate: {
                      notBeEmptyString: notBeEmptyString('Company name')
                    }
                  }}
                />
                <ReactHookFormsInputPhoneMask
                  name="companyPhone"
                  label="Phone Number"
                  dataTestId="create-employer-phone-number-input"
                  rules={{
                    required: 'Phone number is required'
                  }}
                />
                <RhfLocationSearch
                  name={'locations'}
                  label={'Company Location'}
                  placeholder="Find your company"
                  placeType={PlaceType.ESTABLISHMENTS}
                  displayMap
                  rules={{ required: 'Required' }}
                />
              </BottomMarginContentWrapper>
            </FormProvider>
            <Styled.Button
              fullWidth
              variant="contained"
              onClick={handleSubmit(handleValidForm, handleInvalidForm)}
            >
              Create company
            </Styled.Button>
          </Styled.FormContainer>
        )}
      </BottomMarginContentWrapper>
      {currExistingCompany && (
        <CompanyAlreadyExistsModal
          key={currExistingCompany.companyId}
          open={!!currExistingCompany}
          handleClose={(): void => setCurrExistingCompany(undefined)}
          company={currExistingCompany}
          handleRemoveLocation={handleRemoveLocation}
          handleJoinGroup={handleValidFormForJoin}
        />
      )}
    </RootPageLayout>
  );
};

export default PendingUserDashboard;
