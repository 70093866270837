import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import { CareerProfile } from '@interfaces/careerProfile.interfaces';
import { DateTime } from 'luxon';
import EducationSection from '@pages/Shared/ProfileSetup/EducationSection';
import { FileType } from '@api/models/fileApi.models';
import FormActionsComponent from '@components/Forms/FormActionsComponent';
import PageDescriptionComponent from '@components/Layout/Common/PageDescriptionComponent';
import PageFormLayoutComponent from '@components/Layout/PageFormLayoutComponent';
import { PartnerEmployeeProfileRequest } from '@api/models/partnerEmployeeApi.models';
import PersonalInfoSection from '@pages/Shared/ProfileSetup/PersonalInfoSection';
import { scrollToError } from '@common/helpers/scrollToError';
import SkillSetSection from '@pages/Candidate/CandidateProfileSetupPage/CandidateExperienceStep/SkillSetSection';
import { styled } from '@mui/system';
import { useFetchPartnerEmployeeProfile } from '@common/fetches/useFetchPartnerEmployeeProfile';
import { useHistory } from 'react-router-dom';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { usePostOrPutPartnerEmployeeProfile } from '@common/putRequests/usePostOrPutPartnerEmployeeProfile';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import WorkSection from '@pages/Shared/ProfileSetup/WorkSection';

const Styled = {
  FormWrapper: styled(Box)({
    '& > *': {
      marginBottom: '75px'
    }
  })
};

type OmitType = 'certificates' | 'technicalSkills' | 'softSkills';

interface FormValues extends Omit<PartnerEmployeeProfileRequest, OmitType> {
  certificates: CareerProfile['certificates'];
  technicalSkills: CareerProfile['technicalSkills'];
  softSkills: CareerProfile['softSkills'];
}

const PartnerEmployeeProfileSetupPage: React.FC = () => {
  const [partnerEmployeeId] = useStore<number | undefined>(
    UseStoreKeys.PARTNER_EMPLOYEE_ID
  );
  const history = useHistory();

  const { partnerEmployeeProfile, setStoreIdByKeycloakId } =
    useFetchPartnerEmployeeProfile(partnerEmployeeId);

  const { keycloakUser } = useKeycloakContext();

  const { createProfile, updateProfile } = usePostOrPutPartnerEmployeeProfile();

  const [files, setFiles] = React.useState<FileType[]>([]);
  const [educationRequired, setEducationRequired] = React.useState(true);
  const [workExperienceRequired, setWorkExperienceRequired] =
    React.useState(true);

  const methods = useForm<FormValues>({
    defaultValues: {
      // personal
      firstName: keycloakUser.given_name,
      lastName: keycloakUser.family_name,
      phone: '',
      locations: [],
      photoId: undefined,
      // education
      education: [],
      workAndLearn: '',
      certificates: [],
      // work
      workExperience: [],
      // skills
      technicalSkills: [],
      softSkills: []
    }
  });

  const formOrder = {
    firstName: 0,
    lastName: 10,
    phone: 20,
    locations: 30,
    photoId: 40,
    education: 50,
    certificates: 60,
    workAndLearn: 70,
    workExperience: 80,
    technicalSkills: 90,
    softSkills: 100
  };

  const {
    handleSubmit,
    reset,
    getValues,
    setError,
    formState: { errors, isDirty }
  } = methods;

  const setMinLengthError = (
    field: keyof FormValues,
    message: string
  ): void => {
    setError(field, {
      type: 'minLength',
      message
    });
  };

  const handleInvalidForm = (): void => {
    if (getValues().education?.length === 0 && educationRequired) {
      setMinLengthError(
        'education',
        'Please add education or select not applicable'
      );
    }
    if (getValues().workExperience?.length === 0 && workExperienceRequired) {
      setMinLengthError(
        'workExperience',
        'Please add work experience or select not applicable'
      );
    }
  };

  const handleValidForm = async (
    data: FormValues,
    onSuccess: () => void
  ): Promise<void> => {
    let extraErrors = false;

    if (data.education.length === 0 && educationRequired) {
      extraErrors = true;
      setMinLengthError(
        'education',
        'Please add education or select not applicable'
      );
    }

    if (data.workExperience.length === 0 && workExperienceRequired) {
      setMinLengthError(
        'workExperience',
        'Please add work experience or select not applicable'
      );
    }

    if (extraErrors) {
      scrollToError(errors, formOrder);
      return;
    }

    const request: PartnerEmployeeProfileRequest = {
      userId: keycloakUser.sub,
      email: keycloakUser.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      locations: data.locations,
      photoId: data.photoId,
      education: data.education.map((edu) => ({
        ...edu,
        graduationYear: Number(edu.graduationYear),
        campus: edu.campus || undefined,
        program: edu.program || undefined,
        areaOfFocus: edu.areaOfFocus || undefined,
        institution: edu.institution || undefined
      })),
      certificates: data.certificates.map((cert) => cert.value),
      workAndLearn: data.workAndLearn,
      workExperience: data.workExperience,
      technicalSkills: data.technicalSkills.map((skill) => skill.value),
      softSkills: data.softSkills.map((skill) => skill.value),
      educationNotApplicable: !educationRequired ? 1 : 0,
      workExperienceNotApplicable: !workExperienceRequired ? 1 : 0
    };

    try {
      if (!!partnerEmployeeProfile && !!partnerEmployeeId) {
        await updateProfile(partnerEmployeeId, request);
      } else {
        await createProfile(request).then(() => {
          setStoreIdByKeycloakId(keycloakUser.sub);
        });
      }

      onSuccess();
    } catch (error: any) {
      console.error('Error for PartnerEmployeeProfileSetupPage', error);

      const errData = error.response.data.data;
      if (errData?.length) {
        errData.forEach(({ field, message }) =>
          setError(field, { type: 'manual', message })
        );
      }

      scrollToError(errors, formOrder);
    }
  };

  const handleFormSubmit = (onSuccess: () => void): void => {
    const onValid = async (data): Promise<void> =>
      handleValidForm(data, onSuccess);

    handleSubmit(onValid, handleInvalidForm)();
  };

  const handleLastStepAction = (): void => {
    history.push('/partner-employee/career-journey');
  };

  React.useEffect(() => {
    if (partnerEmployeeProfile) {
      const {
        education,
        educationNotApplicable,
        workExperienceNotApplicable,
        workExperience,
        photo
      } = partnerEmployeeProfile;

      reset({
        ...partnerEmployeeProfile,
        education: education.map((item) => ({
          ...item,
          campus: item.campus?.value,
          program: item.program?.value,
          // This fixes react warning "A component is changing an uncontrolled input to be controlled"
          graduationYear: item.graduationYear || ('' as unknown as number),
          areaOfFocus: item.areaOfFocus || '',
          institution: item.institution || ''
        })),
        workExperience: workExperience?.map((item) => ({
          ...item,
          startDate: DateTime.fromISO(item.startDate),
          endDate: item?.endDate ? DateTime.fromISO(item.endDate) : null,
          currentPosition: !item?.currentPosition
            ? false
            : item.currentPosition,
          position: item?.position.value
        }))
      });

      if (educationNotApplicable === 1) {
        setEducationRequired(false);
      }
      if (workExperienceNotApplicable === 1) {
        setWorkExperienceRequired(false);
      }
      if (photo) {
        setFiles([photo]);
      }
    } else {
      reset({
        firstName: keycloakUser.given_name,
        lastName: keycloakUser.family_name
      });
    }
  }, [partnerEmployeeProfile]);

  return (
    <PageFormLayoutComponent
      sideContent={
        <PageDescriptionComponent title="Profile">
          Tell us about who you are and your background. This information will
          be used to create a customized career journey map.
        </PageDescriptionComponent>
      }
      content={
        <FormProvider {...methods}>
          <Styled.FormWrapper>
            <PersonalInfoSection
              files={files}
              candidateAccount={false}
              disableSections={['firstName', 'lastName']}
            />
            <EducationSection
              educationRequired={educationRequired}
              setEducationRequired={setEducationRequired}
            />
            <WorkSection
              workExperienceRequired={workExperienceRequired}
              setWorkExperienceRequired={setWorkExperienceRequired}
            />
            <SkillSetSection />
          </Styled.FormWrapper>
        </FormProvider>
      }
      actions={
        <FormActionsComponent
          onSubmit={handleFormSubmit}
          submitBtnText="Save and Finish"
          changeDetected={isDirty}
          lastStepAction={handleLastStepAction}
          hidePreviousBtn
        />
      }
    />
  );
};

export default PartnerEmployeeProfileSetupPage;
