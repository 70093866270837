import * as React from 'react';
import { AppRoles, roles } from '@api/models/userApi.models';
import {
  AssignUserToESourceGroupDto,
  BulkAssignUserToGroupDto
} from '@interfaces/glrc.interfaces';
import {
  Box,
  Button,
  FormControl,
  Icon,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import {
  BulkUpdateUserRoleDto,
  Seat,
  SettingsPutReq
} from '@api/models/settingsApi.models';
import {
  GridActionsCellItem,
  GridColDef,
  GridRowSelectionModel
} from '@mui/x-data-grid-pro';
import { allowedGridFilterOperators } from '@common/fetches/useFetchGridTableByType';
import { allSeatOptions } from './SeatApprovalsTabContent';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { convertEmployersToSeats } from '@common/fetches/useFetchSettings';
import EcDataGrid from '@components/DataGrid/EcDataGrid';
import EcDialog from '@components/EcDialog';
import EcGridTableToolbar from '@components/DataGrid/EcGridTableToolbar';
import { GlrcContentApi } from '@api/GlrcContent.api';
import InviteUserBox from '../CompanySettings/InviteUserBox';
import { SettingsApi } from '@api/Settings.api';
import { styled } from '@mui/system';
import { useCompanySettingsContext } from '@common/context/companySettingsContext';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useHistory } from 'react-router-dom';
import { WHITE } from '@themes/ui/escoffier';

const UNASSIGNED_ESOURCE_GROUP_VALUE = 'Unassigned';

const Styled = {
  Select: styled(Select)({
    height: '30px',
    flex: '1 0 120px',
    fontSize: '12px',
    lineHeight: 'unset',
    backgroundColor: WHITE
  }),
  GlrcSeatInfoWrapper: styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    columnGap: '16px',
    marginBottom: '24px'
  }),
  BulkAssignWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'visible'
  })<{ visible: boolean }>(() => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    gap: '16px',
    margin: '32px 0px',
    ['@media (max-width:599px)']: {
      flexDirection: 'column'
    }
  })),
  BulkAssignLabel: styled(Typography)(({ theme }) => ({
    marginBottom: '5px',
    textTransform: 'uppercase',
    color: theme.palette.grey4.main
  })),
  FormControl: styled(FormControl)({
    minWidth: '100px',
    ['@media (max-width:599px)']: {
      minWidth: 'unset'
    }
  })
};

const SeatManagementTabContent: React.FC = () => {
  const featureFlags = useFeatureFlags();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [openDialog, setOpenDialog] = React.useState(false);
  const [idForDelete, setIdForDelete] = React.useState<number>();
  const [idxForDelete, setIdxForDelete] = React.useState<number>();
  const [executeDelete, setExecuteDelete] = React.useState(false);
  const [dialogContent, setDialogContent] = React.useState({});

  const {
    companyId,
    fetchSentInvites,
    companySettings,
    setCompanySettings,
    openToast,
    glrcSeatsRemaining,
    esourceGroups,
    glrcSeats
  } = useCompanySettingsContext();

  const { seats } = companySettings;
  const history = useHistory();

  // For bulk role/esource group assignment
  const [bulkRoleSelection, setBulkRoleSelection] = React.useState<string>(
    allSeatOptions[0].value
  );
  const [bulkEsourceSelection, setBulkEsourceSelection] =
    React.useState<string>(UNASSIGNED_ESOURCE_GROUP_VALUE);
  const [savingBulkAssign, setSavingBulkAssign] =
    React.useState<boolean>(false);

  // For MUI Data-Grid
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  const isLastAdmin = (seat: Seat): boolean => {
    const adminSeats = seats.filter((s) =>
      s.role?.some((s) => s.name === AppRoles.EMPLOYER)
    );

    if (
      adminSeats.length === 1 &&
      seat.email.toLowerCase() === adminSeats[0].email.toLowerCase()
    ) {
      return true;
    }

    return false;
  };

  const handleDelete = async (employerId: number): Promise<void> => {
    const seatIdx = seats.findIndex((s) => s.employerId === employerId);

    if (isLastAdmin(seats[seatIdx])) {
      setDialogContent(lastAdminDeleteDialog);
    } else {
      setDialogContent(confirmDeleteDialog);
    }

    setOpenDialog(true);
    setIdForDelete(employerId);
    setIdxForDelete(seatIdx);
  };

  const handleConfirm = (): void => {
    setOpenDialog(false);
    setExecuteDelete(true);
  };

  const handleClose = (): void => {
    setOpenDialog(false);
  };

  const handleRoleChange = async (
    e: SelectChangeEvent<any>,
    employerId: number
  ): Promise<void> => {
    const idx = seats.findIndex((s) => s.employerId === employerId);
    const seatToUpdate = seats[idx];

    if (isLastAdmin(seatToUpdate)) {
      setDialogContent(lastAdminRoleDialog);
      setOpenDialog(true);
      return;
    }

    if (e.target.value.includes(roles.GLRC_USER) && glrcSeatsRemaining() <= 0) {
      setDialogContent(glrcSeatsUnavailableDialog);
      setOpenDialog(true);
      return;
    }

    const newRoles: string = e.target.value;

    const postBody = {
      employerId: employerId,
      name: newRoles
    };

    try {
      await SettingsApi.updateUserRole(postBody);
      const seat = seats.find((s) => s.employerId === employerId);

      if (!seat?.role.map((r) => r.name).includes(AppRoles.ESOURCE_USER)) {
        await GlrcContentApi.assignESourceGroupToUser({
          employerId,
          groupId: undefined
        });
      }
      openToast('Successfully Updated Permissions');
    } catch (error: any) {
      console.error('Error for SettingsApi.updateUserRole()', error);
    }

    // get updated values for user
    // TODO get better component update flow
    try {
      const response = await SettingsApi.getUser(employerId);
      const seatRole = response.data.roles;
      const updatedSeats = [...seats];
      updatedSeats[idx].role = seatRole!;

      setCompanySettings({ ...companySettings, seats: updatedSeats });

      history.push(history.location.pathname);
    } catch (error: any) {
      console.error('Error for SettingsApi.getUser()', error);
    }
  };

  const handleESourceGroupChange = async (
    e: SelectChangeEvent<any>,
    employerId: number
  ): Promise<void> => {
    const seatIdx = seats.findIndex((s) => s.employerId === employerId);
    const groupId = e.target.value;

    const postBody: AssignUserToESourceGroupDto = {
      employerId,
      groupId: groupId === UNASSIGNED_ESOURCE_GROUP_VALUE ? undefined : groupId
    };
    try {
      const updatedEmployer = await GlrcContentApi.assignESourceGroupToUser(
        postBody
      );

      // Update UI with new role
      const updatedSeat = convertEmployersToSeats([updatedEmployer])[0];
      const updatedSeats = [...seats];
      updatedSeats[seatIdx] = updatedSeat;

      setCompanySettings({ ...companySettings, seats: updatedSeats });
      openToast('Successfully assigned ESource group');
    } catch (error) {
      console.error(
        'Error SeatManagementTabContent.handleESourceGroupChange()'
      );
    }
  };

  const handleBulkAssign = React.useCallback(async (): Promise<void> => {
    const employerIds = rowSelectionModel.map((rowId) => rowId as number);
    if (
      bulkRoleSelection !== roles.EMPLOYER &&
      employerIds.length === companySettings.seats.length
    ) {
      setDialogContent(oneAdminRequiredDialog);
      setOpenDialog(true);
    } else {
      const rolePostBody: BulkUpdateUserRoleDto = {
        name: bulkRoleSelection,
        employerIds
      };

      const groupPostBody: BulkAssignUserToGroupDto = {
        groupId:
          bulkEsourceSelection === UNASSIGNED_ESOURCE_GROUP_VALUE
            ? undefined
            : Number(bulkEsourceSelection),
        employerIds
      };

      try {
        setSavingBulkAssign(true);
        await SettingsApi.updateUserRolesBulk(rolePostBody);
        const updatedEmployers =
          await GlrcContentApi.bulkAssignESourceGroupToEmployers(groupPostBody);

        const updatedSeats = convertEmployersToSeats(updatedEmployers);

        const newCompanySettings = {
          ...companySettings,
          seats: [...seats]
        };

        updatedSeats.forEach((seat) => {
          const index = seats.findIndex(
            (s) => s.employerId === seat.employerId
          );
          newCompanySettings.seats[index] = seat;
        });

        setCompanySettings(newCompanySettings);
        setRowSelectionModel([]);
      } catch (error) {
        console.error('Error: SeatManagementTabContent.handleBulkAssign()');
      } finally {
        setSavingBulkAssign(false);
      }
    }
  }, [bulkRoleSelection, bulkEsourceSelection, rowSelectionModel]);

  const confirmDeleteDialog = {
    content:
      'In order to remove this user from the account, you must first confirm this dialogue.',
    confirmActionText: 'Delete user',
    handleConfirm
  };

  const oneAdminRequiredDialog = {
    content:
      'At least one user must remain an admin. In order to continue, unselect an admin.',
    confirmActionText: 'Manage Seats',
    handleConfirm: handleClose
  };

  const lastAdminDeleteDialog = {
    content:
      'In order to remove this admin from the account, you must first assign admin permissions to another user.',
    confirmActionText: 'Manage Seats',
    handleConfirm: handleClose
  };

  const lastAdminRoleDialog = {
    content:
      'In order to update this admin to a recruiter, you must first assign admin permissions to another user.',
    confirmActionText: 'Manage Seats',
    handleConfirm: handleClose
  };

  const glrcSeatsUnavailableDialog = {
    content:
      'In order to update this user to a glrc-user, you must remove access from another user or request more seats as there are zero glrc seats remaining at this time.',
    confirmActionText: 'Manage Seats',
    handleConfirm: handleClose
  };

  const hasESourceRole = (seat: Seat): boolean => {
    if (!seat) {
      return false;
    }

    return seat.role?.some((r) => r.name === AppRoles.ESOURCE_USER);
  };

  // In order to match option value the roles must be sorted
  const sortRolesString = (roles: string): string => {
    return roles?.split(';').sort().join(';') || '';
  };

  const getRoleValue = (seat: Seat): string => {
    const roleNames = seat.role?.map((r) => r.name.replace('app-', '')) || [];

    if (roleNames.length === 1) {
      return roleNames[0];
    }

    if (roleNames.length > 1) {
      // sorting so we match a menu option
      return roleNames.sort().join(';');
    }

    return '';
  };

  const currentSeatColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'role',
      headerName: 'Role',
      align: 'center',
      width: 170,
      flex: 1,
      filterOperators: allowedGridFilterOperators,
      valueGetter: (params): string => {
        const roles = sortRolesString(
          params.row.role?.map((r) => r.name.replace('app-', '')).join(';')
        );
        const seat = allSeatOptions.find((s) => s.value === roles);
        return seat?.label || '';
      },
      renderCell: (params): JSX.Element => {
        return (
          <Styled.Select
            onChange={(e: SelectChangeEvent<any>): Promise<void> =>
              handleRoleChange(e, params.row.employerId)
            }
            value={params.row.roleValue}
          >
            {allSeatOptions.map((opt) => (
              <MenuItem key={opt.label} value={sortRolesString(opt.value)}>
                {opt.label}
              </MenuItem>
            ))}
          </Styled.Select>
        );
      }
    },
    {
      field: 'esource',
      headerName: 'ESource',
      align: 'center',
      width: 170,
      flex: 1,
      filterOperators: allowedGridFilterOperators,
      valueGetter: (params): string => {
        return params.row.e_source_group?.name || '';
      },
      renderCell: (params): JSX.Element => {
        if (!hasESourceRole(params.row)) {
          return <></>;
        }

        return (
          <Styled.Select
            onChange={(e: SelectChangeEvent<any>): Promise<void> =>
              handleESourceGroupChange(e, params.row.employerId)
            }
            value={params.row.esourceValue}
          >
            <MenuItem value={UNASSIGNED_ESOURCE_GROUP_VALUE}>
              {UNASSIGNED_ESOURCE_GROUP_VALUE}
            </MenuItem>
            {esourceGroups.map((opt) => (
              <MenuItem key={opt.id} value={opt.id}>
                {opt.name} {opt.id}
              </MenuItem>
            ))}
          </Styled.Select>
        );
      }
    },
    {
      field: 'actions',
      type: 'actions',
      width: 10,
      filterOperators: allowedGridFilterOperators,
      getActions: (params): JSX.Element[] => [
        <GridActionsCellItem
          key={params.id}
          icon={<Icon className="ri-delete-bin-6-line" />}
          label="Delete"
          onClick={(): Promise<void> => handleDelete(Number(params.id))}
        />
      ]
    }
  ];

  React.useEffect(() => {
    if (executeDelete) {
      const postBody: SettingsPutReq = {
        employerId: idForDelete ?? 0
      };
      try {
        SettingsApi.deleteUser(postBody);

        // upon successful delete update companySettings state
        const updatedSeats = [...seats];
        updatedSeats.splice(idxForDelete ?? 0, 1);

        setCompanySettings({ ...companySettings, seats: updatedSeats });
        openToast('Successfully Deleted Seat');
        setExecuteDelete(false);
      } catch (error: any) {
        console.error('Error for SeatManagementTabContent.deleteUser()', error);
      }
    }
  }, [executeDelete]);

  React.useEffect(() => {
    if (!bulkRoleSelection.includes(roles.GLRC_USER)) {
      setBulkEsourceSelection(UNASSIGNED_ESOURCE_GROUP_VALUE);
    }
  }, [bulkRoleSelection]);

  return (
    <>
      <BottomMarginContentWrapper bottomMargin="48px">
        <InviteUserBox
          openToast={openToast}
          companyId={companyId}
          fetchSentInvites={fetchSentInvites}
        />
        <Box>
          {featureFlags.PARTNER_EXPERIENCE && (
            <Styled.GlrcSeatInfoWrapper>
              <Typography component={'p'} variant="EC_TYPE_XS">
                Total ESource Seats: {glrcSeats}
              </Typography>
              <Typography component={'p'} variant="EC_TYPE_XS">
                Available ESource Seats: {glrcSeatsRemaining()}
              </Typography>
            </Styled.GlrcSeatInfoWrapper>
          )}
          {!!rowSelectionModel.length && (
            <Styled.BulkAssignWrapper visible={!!rowSelectionModel.length}>
              <Styled.FormControl fullWidth={isMobile}>
                <Styled.BulkAssignLabel variant="EC_TYPE_XS">
                  Role
                </Styled.BulkAssignLabel>
                <Select
                  value={bulkRoleSelection}
                  onChange={(e: SelectChangeEvent<any>): void => {
                    setBulkRoleSelection(e.target.value);
                  }}
                >
                  {allSeatOptions.map((opt) => (
                    <MenuItem key={opt.value} value={opt.value}>
                      {opt.label}
                    </MenuItem>
                  ))}
                </Select>
              </Styled.FormControl>
              {bulkRoleSelection.includes(roles.GLRC_USER) && (
                <Styled.FormControl fullWidth={isMobile}>
                  <Styled.BulkAssignLabel variant="EC_TYPE_XS">
                    ESource
                  </Styled.BulkAssignLabel>
                  <Select
                    value={bulkEsourceSelection}
                    onChange={(e: SelectChangeEvent<any>): void => {
                      setBulkEsourceSelection(e.target.value);
                    }}
                  >
                    <MenuItem value={UNASSIGNED_ESOURCE_GROUP_VALUE}>
                      {UNASSIGNED_ESOURCE_GROUP_VALUE}
                    </MenuItem>
                    {esourceGroups.map((opt) => (
                      <MenuItem key={opt.id} value={opt.id}>
                        {opt.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Styled.FormControl>
              )}
              <Button
                disabled={savingBulkAssign}
                variant="contained"
                color="primary"
                sx={{ height: '45px', typography: 'EC_TYPE_SM', color: WHITE }}
                fullWidth={isMobile}
                onClick={handleBulkAssign}
              >
                Assign
              </Button>
            </Styled.BulkAssignWrapper>
          )}
          <EcDataGrid
            checkboxSelection
            autoHeight={true}
            disableRowSelectionOnClick
            columns={currentSeatColumns}
            onRowSelectionModelChange={(newRowSelectionModel): void => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rows={companySettings.seats.map((s) => ({
              ...s,
              id: s.employerId,
              roleValue: getRoleValue(s),
              esourceValue:
                s.e_source_group?.id || UNASSIGNED_ESOURCE_GROUP_VALUE
            }))}
            slots={{
              toolbar: EcGridTableToolbar
            }}
          />
        </Box>
      </BottomMarginContentWrapper>
      <EcDialog
        dataTestId="manage-seats-dialog"
        title="Seat Management"
        open={openDialog}
        handleClose={handleClose}
        {...dialogContent}
      />
    </>
  );
};

export default SeatManagementTabContent;
