import * as React from 'react';
import { KeycloakUserRole } from '@api/models/userApi.models';
import TagManager from 'react-gtm-module';
import { useKeycloak } from '@react-keycloak/web';
import { useStoreValue } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import { CompanyProfile } from '@api/models/companyApi.models';

type PageProps = {
  children: React.ReactNode;
};

const GoogleAnalyticsWrapper: React.FC<PageProps> = ({ children }) => {
  const { keycloak } = useKeycloak();
  const managedCompanies :CompanyProfile[] = useStoreValue<CompanyProfile[]>(UseStoreKeys.MANAGED_COMPANIES);
  const companyId = managedCompanies?.length > 0 ? managedCompanies[0]?.id : -1;
  const candidateId = useStoreValue<number>(UseStoreKeys.CANDIDATE_ID);

  const getUserInfo = (): { userId?: number; role?: KeycloakUserRole } => {
    const isEmployer = keycloak.hasResourceRole(KeycloakUserRole.EMPLOYER);
    const isCandidate = keycloak.hasResourceRole(KeycloakUserRole.CANDIDATE);

    if (isEmployer) {
      return { userId: companyId, role: KeycloakUserRole.EMPLOYER };
    }

    if (isCandidate) {
      return { userId: candidateId, role: KeycloakUserRole.CANDIDATE };
    }

    return { userId: undefined, role: undefined };
  };

  React.useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID,
      auth: process.env.REACT_APP_GTM_AUTH,
      preview: process.env.REACT_APP_GTM_PREVIEW,
      dataLayer: {
        ...getUserInfo()
      }
    });
  }, []);

  return <>{children}</>;
};

export default GoogleAnalyticsWrapper;
