import { useEffect, useState } from 'react';
import { CompanyApi } from '@api/Company.api';
import { EmployerProfile } from '@api/models/employerApi.models';

export type FetchAllEmployers = {
  employers?: EmployerProfile[];
  loaded: boolean;
  loading: boolean;
};

export const useFetchEmployers = (companyId: number): FetchAllEmployers => {
  const [employers, setEmployer] = useState<EmployerProfile[]>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchAllEmployers = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await CompanyApi.getEmployersByCompanyId(companyId);

      setEmployer(res.data);
    } catch (error: any) {
      console.error(
        'Error for useFetchEmployers -> CompanyApi.fetchAllEmployers()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  useEffect(() => {
    fetchAllEmployers();
  }, []);

  return {
    employers,
    loaded,
    loading
  };
};
