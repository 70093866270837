import * as React from 'react';
import facebookIcon from '@assets/svg/facebookIcon.svg';
import Grid from '@mui/material/Grid';
import instagramIcon from '@assets/svg/instagramIcon.svg';
import linkedinIcon from '@assets/svg/linkedinIcon.svg';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import StepSection from '@components/StepSection';
import { urlPattern } from '@common/regex/url';
import websiteIcon from '@assets/svg/websiteIcon.svg';

const SocialMediaSection = (): React.ReactElement => {
  return (
    <StepSection title="Social Profiles">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <ReactHookFormsInput
            name="websiteUrl"
            label="Website"
            placeholder="Enter website URL"
            icon={<img src={websiteIcon} width="16px" height="16px" />}
            rules={{
              pattern: {
                value: urlPattern,
                message: 'Please enter a valid URL'
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReactHookFormsInput
            name="instagramUrl"
            label="Instagram"
            placeholder="Enter profile URL"
            icon={<img src={instagramIcon} width="16px" height="16px" />}
            rules={{
              pattern: {
                value: urlPattern,
                message: 'Please enter a valid URL'
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReactHookFormsInput
            name="facebookUrl"
            label="Facebook"
            placeholder="Enter profile URL"
            icon={<img src={facebookIcon} width="16px" height="16px" />}
            rules={{
              pattern: {
                value: urlPattern,
                message: 'Please enter a valid URL'
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReactHookFormsInput
            name="linkedInUrl"
            label="Linkedin"
            placeholder="Enter profile URL"
            icon={<img src={linkedinIcon} width="16px" height="16px" />}
            rules={{
              pattern: {
                value: urlPattern,
                message: 'Please enter a valid URL'
              }
            }}
          />
        </Grid>
      </Grid>
    </StepSection>
  );
};

export default SocialMediaSection;
