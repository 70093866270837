import * as React from 'react';
import { Badge } from '@common/mappings/BadgeMappings';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const Styled = {
  Badge: styled(Box)({
    borderRadius: '21px',
    padding: '4px 5.5px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    display: 'inline-flex',
    verticalAlign: 'middle',
    transitionDuration: '350ms',
    transitionProperty: 'width',
    transitionTimingFunction: 'ease-in',
    alignItems: 'center',
    justifyItems: 'center',
    overflow: 'hidden',
    '&:hover': {
      width: '105%'
    }
  }),
  Icon: styled('i')({
    typography: 'EC_TYPE_3XS',
    fontSize: '10px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '13px',
    letterSpacing: '0em'
  }),
  SvgWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'color' && prop !== 'preventFill'
  })<{ color: string; preventFill: boolean }>(({ color, preventFill }) => ({
    width: '10px',
    height: '13px',
    svg: {
      width: '10px',
      height: '13px',
      path: {
        fill: preventFill ? 'default' : color
      }
    }
  })),
  Label: styled(Box)({
    marginLeft: '5px',
    whiteSpace: 'nowrap'
  })
};

export interface IconBadgeProps extends Badge {
  expandable?: boolean;
}

const IconBadge: React.FC<IconBadgeProps> = ({
  label,
  remixIcon,
  bgColor = theme.palette.red2.main,
  textColor = 'white',
  expandable = false,
  svgIcon: SvgIcon,
  preventFill = false
}) => {
  return (
    <Styled.Badge
      sx={{
        backgroundColor: bgColor,
        width: expandable ? '21px' : '105%',
        typography: 'EC_TYPE_3XS',
        color: textColor
      }}
    >
      {remixIcon && (
        <Styled.Icon
          data-testid={`remixIcon-${remixIcon}`}
          className={remixIcon}
          sx={{ typography: 'EC_TYPE_3XS', color: textColor }}
        />
      )}
      {SvgIcon && (
        <Styled.SvgWrapper
          data-testid={`svgIcon-${label}`}
          color={textColor}
          preventFill={preventFill}
        >
          <SvgIcon />
        </Styled.SvgWrapper>
      )}
      <Styled.Label>{label}</Styled.Label>
    </Styled.Badge>
  );
};

export default IconBadge;
