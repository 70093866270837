import * as React from 'react';
import { Box, Typography } from '@mui/material';
import BasicModal from '@components/BasicModal';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { styled } from '@mui/system';
import { UpdateCompanyLocationRequest } from '@api/models/companyApi.models';
import { useFormContext } from 'react-hook-form';

const Styled = {
  PhoneRow: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'selected'
  })<{ selected: boolean }>(({ selected, theme }) => ({
    border: `1px solid ${theme.palette.OUTLINE.main}`,
    borderRadius: '4px',
    padding: '12px 24px',
    maxWidth: 'max-content',
    margin: '0 auto',
    cursor: 'pointer',
    ...(selected && {
      backgroundColor: theme.palette.GRAY_1.main,
      outline: `3px solid ${theme.palette.GOLD_1.main}`
    })
  }))
};

export interface PhoneModalProps {
  open: boolean;
  data?: Partial<UpdateCompanyLocationRequest>;
}

interface Props {
  modalProps: PhoneModalProps;
  setPhoneModalProps: React.Dispatch<React.SetStateAction<PhoneModalProps>>;
  onSubmit: (data: UpdateCompanyLocationRequest) => Promise<void>;
}

const UpdateCompanyPhoneModal = ({
  modalProps,
  setPhoneModalProps,
  onSubmit
}: Props): React.ReactElement => {
  const { open, data } = modalProps;
  const [selected, setSelected] = React.useState<string>();

  const { setValue, getValues } = useFormContext();

  const handleClickPhoneNumber = (e: React.MouseEvent<HTMLElement>): void => {
    const phone = e.currentTarget.textContent ?? '';

    setSelected(phone);
    setValue('phone', phone);
  };

  const handleCloseModal = React.useCallback(() => {
    setSelected(undefined);
    setPhoneModalProps((prev) => ({
      ...prev,
      open: false
    }));
  }, [setPhoneModalProps]);

  const handleSubmit = async (): Promise<void> => {
    if (data?.location) {
      const request: UpdateCompanyLocationRequest = {
        location: data.location,
        phone: getValues('phone')
      };

      await onSubmit(request as UpdateCompanyLocationRequest).then(() => {
        handleCloseModal();
      });
    }
  };

  return (
    <>
      {!!data?.phone && !!data.location?.phone && (
        <BasicModal
          open={open}
          primaryAction={{
            label: 'Submit',
            action: handleSubmit,
            disabled: !selected
          }}
          title="Verify Company Phone Number"
          subTitle={
            <>
              <Typography variant="EC_TYPE_BASE" mb="24px">
                We found a phone number for this location that differs from the
                one you have entered.
              </Typography>
              <Typography variant="EC_TYPE_BASE">
                Please select the correct phone number below:
              </Typography>
            </>
          }
        >
          <BottomMarginContentWrapper
            bottomMargin="16px"
            wrapperTopMargin="16px"
          >
            <Styled.PhoneRow
              onClick={handleClickPhoneNumber}
              selected={selected === data.phone}
            >
              <Typography variant="EC_TYPE_2XL">{data.phone}</Typography>
            </Styled.PhoneRow>
            <Styled.PhoneRow
              onClick={handleClickPhoneNumber}
              selected={selected === data?.location?.phone}
            >
              <Typography variant="EC_TYPE_2XL">
                {data?.location?.phone}
              </Typography>
            </Styled.PhoneRow>
          </BottomMarginContentWrapper>
        </BasicModal>
      )}
    </>
  );
};

export default React.memo(UpdateCompanyPhoneModal);
