import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AdminDashboardApi } from '@api/AdminDashboard.api';
import { CompanyPossibleSub } from '@api/models/adminDashboardApi.models';

interface FetchPossibleSubsidiaries {
  possibleSubsidiaries?: CompanyPossibleSub[];
  loading: boolean;
  setPossibleSubsidiaries: Dispatch<
    SetStateAction<CompanyPossibleSub[] | undefined> // eslint-disable-line @typescript-eslint/indent
  >;
  fetchPossibleSubsidiaries: (isEcAdmin: boolean) => Promise<void>;
}

export const useFetchPossibleSubsidiaries = (
  isEcAdmin: boolean
): FetchPossibleSubsidiaries => {
  const [possibleSubsidiaries, setPossibleSubsidiaries] =
    useState<CompanyPossibleSub[]>();
  const [loading, setLoading] = useState(false);

  const fetchPossibleSubsidiaries = async (
    isEcAdmin: boolean
  ): Promise<void> => {
    if (isEcAdmin) {
      try {
        setLoading(true);

        const res = await AdminDashboardApi.getPossibleSubsidiaries();

        setPossibleSubsidiaries(res);
      } catch (error: any) {
        console.error(`Error for useFetchPossibleSubsidiaries(): ${error}`);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchPossibleSubsidiaries(isEcAdmin);
  }, []);

  return {
    possibleSubsidiaries,
    loading,
    setPossibleSubsidiaries,
    fetchPossibleSubsidiaries
  };
};
