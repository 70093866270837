/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { ExternshipApi } from '@api/Externships.api';

export type FetchAllExternshipCourses = {
  allExternshipCourses: any[];
  loaded: boolean;
  loading: boolean;
};

export const useFetchAllExternshipCourses = (): FetchAllExternshipCourses => {
  const [allExternshipCourses, setAllExternshipCourses] = React.useState<any[]>(
    []
  );
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchAllExternshipCourses = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await ExternshipApi.getAllExternshipCourses();

      setAllExternshipCourses(res);
      setLoaded(true);
    } catch (error: any) {
      console.error(
        'Error for useFetchAllExternshipCourses -> ExternshipApi.getAllExternshipCourses()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    fetchAllExternshipCourses();
  }, []);

  return {
    allExternshipCourses,
    loaded,
    loading
  };
};
