/**
 * Returns the number of empty rows in a table to account for cases where
 * pagination leads to a page with data less than rowsPerPage
 * @param page the current page the table is on
 * @param rowsPerPage the rows displayed on each table page
 * @param dataLength the length of the array of data displayed by the table
 * @returns the number of empty rows
 */
export const getEmptyTableRows = (
  page: number,
  rowsPerPage: number,
  dataLength: number
): number => {
  if (page > 0) {
    return Math.max(0, (1 + page) * rowsPerPage - dataLength);
  } else {
    return 0;
  }
};