import * as React from 'react';
import {
  esourceUserFooterBlacklist,
  footerBlacklist,
  HeaderFooterBannerProvider
} from '@common/context/headerFooterBannerContext';
import EcAlertBanner from '@components/AlertBanners/EcAlertBanner';
import Footer from '@components/Layout/Footer/Footer';
import { Footnote } from '@common/models/common.models';
import Header from '@components/Layout/Header/Header';
import { ToastProvider } from '@components/Toast';
import { useFetchUserBanners } from '@common/fetches/useFetchUserBanners';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { useLocation } from 'react-router-dom';

interface Props {
  children?: React.ReactNode;
}

const HeaderFooter: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const { keycloakUser, isGlrcUser, isGlrcRecruiter } = useKeycloakContext();
  // TODO Use markBannerAsRead from useFetchUserBanners
  const { banners, closeBannerByBannerId } = useFetchUserBanners(
    keycloakUser?.sub
  );

  const [footnotes, setFootnotes] = React.useState<Footnote[]>([]);
  const [hideFooter, setHideFooter] = React.useState<boolean>(false);
  const supRef = React.useRef<HTMLDivElement | undefined>(undefined);

  const onSupClick = React.useCallback(() => {
    const { current } = supRef;
    if (current) {
      current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [supRef]);

  React.useEffect(() => {
    let shouldHideFooter: boolean;

    if (isGlrcUser && !isGlrcRecruiter) {
      shouldHideFooter = esourceUserFooterBlacklist.some((path) =>
        pathname.includes(path)
      );
    } else {
      shouldHideFooter = footerBlacklist.some((path) =>
        pathname.includes(path)
      );
    }

    setHideFooter(shouldHideFooter);
  }, [pathname]);

  return (
    <HeaderFooterBannerProvider
      value={{
        banners,
        closeBannerByBannerId,
        footnotes,
        setFootnotes,
        supRef,
        onSupClick
      }}
    >
      <ToastProvider>
        <Header />
        <EcAlertBanner />
        {children}
        {!hideFooter && <Footer />}
      </ToastProvider>
    </HeaderFooterBannerProvider>
  );
};

export default HeaderFooter;
