import * as React from 'react';
import { Codebook } from '@api/models/codebook.models';
import { CodebookApi } from '@api/Codebook.api';

export type FetchSegmentsCodebooks = {
  segments: Codebook[];
  loading: boolean;
  loaded: boolean;
};
export const useFetchSegmentsCodebooks = (): FetchSegmentsCodebooks => {
  const [segments, setSegments] = React.useState<Codebook[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        setLoading(true);
        const res = await CodebookApi.getAllSegments();
        setSegments(res.data);
        setLoaded(true);
      } catch (error) {
        console.error(
          'Error for useFetchSegmentsCodebooks -> CodebookApi.getAllSegments()',
          error
        );
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    };
    fetchData();
  }, []);
  return { segments, loading, loaded };
};
