import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { Codebook, SkillCodebook } from '@api/models/codebook.models';
import Skill from '@components/Skill';
import { styled } from '@mui/system';

const Styled = {
  Content: styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '16px',
    marginTop: '12px'
  }),
  SkillBox: styled(Box)({
    width: 'max-content'
  })
};

interface CareerGoalSkillProps {
  skills: SkillCodebook[];
  prereqs: Codebook[];
  candidateSkills?: SkillCodebook[];
  candidatePrereqs?: Codebook[];
  candidateCertifications?: Codebook[];
}

const CareerGoalReqSkills = ({
  skills,
  prereqs,
  candidateSkills,
  candidatePrereqs,
  candidateCertifications
}: CareerGoalSkillProps): React.ReactElement => {
  const candidateDetails = [
    ...(candidateSkills ?? []),
    ...(candidatePrereqs ?? []),
    ...(candidateCertifications ?? [])
  ];

  const isSelected = (value: number): boolean =>
    candidateDetails.some((item) => item.value === value);

  return (
    <Box data-testid="career-goal-skills-root">
      {skills && !!skills.length && (
        <>
          <Typography variant="EC_TYPE_XL">REQUIRED SKILLS</Typography>
          <Styled.Content>
            {skills.map((skill, idx) => {
              const selected = isSelected(skill.value);

              return (
                <Styled.SkillBox key={idx} data-testid="skill-box">
                  <Skill skill={skill.label} checked={selected} />
                </Styled.SkillBox>
              );
            })}
          </Styled.Content>
        </>
      )}
      {prereqs && !!prereqs.length && (
        <>
          <Typography variant="EC_TYPE_XL" sx={{ marginTop: '24px' }}>
            COMMON PREREQUISITES & CERTIFICATIONS
          </Typography>
          <Styled.Content>
            {prereqs.map((item, idx) => {
              const selected = isSelected(item.value);

              return (
                <Styled.SkillBox key={idx} data-testid="prereq-box">
                  <Skill skill={item.label} checked={selected} />
                </Styled.SkillBox>
              );
            })}
          </Styled.Content>
        </>
      )}
    </Box>
  );
};

export default CareerGoalReqSkills;
