import * as React from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '@themes/ui/variables';
import SearchBar, { SearchBarProps } from '@components/SearchBar';
import CtaButton from '@components/CtaButton';
import EcCrownWithLines from '@components/EcCrownWithLines';
import ECrownLogoWithTitle from '@components/Layout/Common/ECrownLogoWithTitle';
import ResponsiveLayout from '@components/Layout/ResponsiveLayout';
import { styled } from '@mui/system';
import { useMobileSearchFilterOverlayContext } from '@common/context/mobileSearchFilterOverlayContext';

const Styled = {
  Main: styled(Box)({
    margin: '0 auto',
    maxWidth: '1820px',
    padding: 0,
    display: 'flex'
  }),
  Sidebar: styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey1.main,
    minWidth: '335px',
    padding: '65px 48px 25px',
    minHeight: `calc(100vh - (${FOOTER_HEIGHT}px + ${HEADER_HEIGHT}px))`,
    ['@media (max-width:1200px)']: {
      padding: '65px 16px 25px'
    },
    ['@media (max-width:970px)']: {
      minWidth: '300px'
    }
  })),
  SelectBox: styled(Box)({
    width: 'auto'
  }),
  Content: styled(Box, {
    shouldForwardProp: (prop) =>
      prop !== 'mobileBreakpoint' && prop !== 'hasBreadcrumb'
  })<{ mobileBreakpoint: number; hasBreadcrumb?: boolean }>(
    ({ mobileBreakpoint, hasBreadcrumb }) => ({
      width: '100%',
      position: 'relative',
      padding: `${hasBreadcrumb ? '32px' : '65px'} 25px 25px 48px`,
      ['@media (max-width:1200px)']: {
        paddingLeft: '16px',
        paddingRight: '16px'
      },
      [`@media (max-width:${mobileBreakpoint}px)`]: {
        padding: 0
      }
    })
  ),
  DesktopTitleBox: styled(Box)({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }),
  DesktopTitle: styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.WHITE.main,
    paddingRight: '20px',
    whiteSpace: 'nowrap',
    zIndex: 1
  })),
  CrownWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'breakpoint'
  })<{ breakpoint: number }>(({ breakpoint }) => ({
    marginBottom: '30px',
    [`@media (min-width:${breakpoint + 1}px)`]: {
      position: 'absolute',
      marginBottom: 'unset',
      left: '0px',
      transform: 'translateY(-6px)',
      width: '100%'
    }
  })),
  FilterIcon: styled(Box)(({ theme }) => ({
    position: 'absolute',
    color: theme.palette.GRAY_3.main,
    transform: 'translateY(40%)',
    left: 8,
    cursor: 'pointer',
    '& i': {
      fontSize: '24px',
      lineHeight: '19px'
    }
  })),
  FilterOverlay: styled(Box)(({ theme }) => ({
    position: 'relative',
    padding: '20px 42px 90px',
    backgroundColor: theme.palette.GRAY_1.main,
    height: '100%',
    width: '100%',
    ['@media (max-width:390px)']: {
      padding: '20px 20px 90px'
    }
  })),
  FilterHeader: styled(Stack)({
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
    '& i': {
      cursor: 'pointer',
      fontSize: '19px',
      lineHeight: '19px'
    }
  })
};

interface Breadcrumb {
  label: string;
  path: string;
}

interface PageProps {
  loading?: boolean;
  sidebar: React.ReactNode;
  pageTitle: string;
  content: React.ReactNode;
  header?: React.ReactNode;
  breadcrumb?: Breadcrumb;
  searchBar?: SearchBarProps;
  eCrownPageTitle?: boolean;
}

const PageLayoutLeftSidebar: React.FC<PageProps> = ({
  breadcrumb,
  sidebar,
  pageTitle,
  content,
  header,
  loading,
  searchBar,
  eCrownPageTitle = false
}) => {
  const theme = useTheme();

  const {
    filterOverlayOpen,
    openMobileFilter,
    closeMobileFilter,
    mobileBreakpoint
  } = useMobileSearchFilterOverlayContext();

  const overlayDisplay = filterOverlayOpen ? undefined : { display: 'none' };
  const hasBreadcrumb = !!breadcrumb;
  const displaySearch = !!searchBar;

  const Breadcrumb: React.FC<Breadcrumb> = ({ label, path }) => {
    return (
      <CtaButton
        label={label}
        to={path}
        variant="text"
        textColor={theme.palette.GRAY_3.main}
        color="GRAY_3"
        startIcon={<i className="ri-arrow-left-line" />}
        dataTestId="page-layout-left-sidebar-breadcrumb-cta"
        btnSx={{
          width: 'max-content',
          marginBottom: '16px',
          padding: 0
        }}
      />
    );
  };

  const headerDecoration = displaySearch ? (
    <SearchBar
      name="f-SearchBar"
      value={searchBar.value}
      onChange={searchBar.onChange}
      onSubmit={searchBar.onSubmit}
      clearSearch={searchBar.clearSearch}
      placeholder={searchBar.placeholder}
      customStyle={{
        width: '100%',
        maxWidth: 'unset',
        marginBottom: '24px',
        marginLeft: 'auto',
        [theme.breakpoints.up('md')]: {
          maxWidth: '310px',
          marginBottom: 0
        }
      }}
    />
  ) : (
    <Styled.CrownWrapper
      breakpoint={mobileBreakpoint}
      data-testid="page-layout-left-sidebar-crown-wrapper"
    >
      <EcCrownWithLines opacity={0.5} />
    </Styled.CrownWrapper>
  );

  return (
    <ResponsiveLayout
      customBreakpoint={mobileBreakpoint}
      displayGutters={!filterOverlayOpen}
      useMobileContainer={true}
      desktopComponent={
        <Styled.Main>
          <Styled.Sidebar data-testid="page-layout-left-sidebar-sidebar">
            <Styled.SelectBox>{sidebar}</Styled.SelectBox>
          </Styled.Sidebar>
          <Styled.Content
            hasBreadcrumb={hasBreadcrumb}
            mobileBreakpoint={mobileBreakpoint}
            data-testid="page-layout-left-sidebar-content"
          >
            {hasBreadcrumb && (
              <Breadcrumb label={breadcrumb.label} path={breadcrumb.path} />
            )}
            <Styled.DesktopTitleBox>
              {eCrownPageTitle ? (
                <ECrownLogoWithTitle
                  text={pageTitle}
                  textVariant="EC_TYPE_4XL"
                />
              ) : (
                <Styled.DesktopTitle
                  variant="EC_TYPE_4XL"
                  data-testid="page-layout-left-sidebar-pageTitle"
                >
                  {pageTitle}
                </Styled.DesktopTitle>
              )}
              {headerDecoration}
            </Styled.DesktopTitleBox>
            {header}
            {content}
          </Styled.Content>
        </Styled.Main>
      }
      mobileComponent={
        <>
          {!filterOverlayOpen ? (
            <Styled.Content
              hasBreadcrumb={hasBreadcrumb}
              mobileBreakpoint={mobileBreakpoint}
              data-testid="page-layout-left-sidebar-content-mobile"
            >
              {hasBreadcrumb && (
                <Breadcrumb label={breadcrumb.label} path={breadcrumb.path} />
              )}
              <Typography variant="EC_TYPE_4XL">{pageTitle}</Typography>
              {headerDecoration}
              {header}
              {!loading && (
                <Styled.FilterIcon
                  onClick={openMobileFilter}
                  data-testid="page-layout-left-sidebar-open-filter-btn"
                >
                  <i className="ri-filter-3-line" />
                </Styled.FilterIcon>
              )}
              {content}
            </Styled.Content>
          ) : (
            <Styled.FilterOverlay
              sx={overlayDisplay}
              data-testid="page-layout-left-sidebar-filter-overlay"
            >
              <Styled.FilterHeader direction="row">
                <Typography variant="EC_TYPE_2XL">FILTER RESULTS</Typography>
                <i
                  className="ri-close-line"
                  onClick={closeMobileFilter}
                  data-testid="page-layout-left-sidebar-close-filter-btn"
                />
              </Styled.FilterHeader>
              {sidebar}
            </Styled.FilterOverlay>
          )}
        </>
      }
    />
  );
};

export default PageLayoutLeftSidebar;
