import {
  GlrcCheckAccessReq,
  GlrcCompanyAccess
} from '@api/models/glrcAccess.models';
import { api } from '@api/configs/base';
import { apiConfig } from '@api/configs/api.config';
import { ApiResponse } from '@api/models/base.models';

export class GlrcAccessApi {
  static async checkAccess(
    userId: string,
    data: GlrcCheckAccessReq
  ): Promise<boolean> {
    try {
      const res = await api.post<ApiResponse<boolean>>(
        `${apiConfig.partnersUrl}/glrc-access/user/${userId}/check`,
        data
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for GlrcAccessApi.checkAccess(): ${error}`);
      throw error;
    }
  }

  /**
   * Updates company level glrc accesses.
   * @returns GlrcCompanyAccess[].
   */
  static async updateGlrcCompanyAccesses(
    companyId: number,
    data: GlrcCompanyAccess
  ): Promise<GlrcCompanyAccess[]> {
    try {
      const response = await api.post<ApiResponse<GlrcCompanyAccess[]>>(
        `${apiConfig.partnersUrl}/glrc-access/company/${companyId}`,
        data
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for GlrcAccessApi.updateGlrcCompanyAccesses(): ${error}`
      );
      throw error;
    }
  }
}
