export const notMatchEmail = (email): ((value) => boolean | string) => {
  return (value): boolean | string => {
    const username = email.substring(0, email.indexOf('@')).toLowerCase();

    return (
      !username ||
      !value.toLowerCase().includes(username) ||
      'Password cannot contain your email.'
    );
  };
};

export const minLength = (value): boolean | string => {
  if (!value.length) {
    return true;
  }
  return value.length >= 6 || 'Password must be at least 6 characters';
};

export const includesNumber = (value): boolean | string => {
  if (!value.length) {
    return true;
  }
  return /.*[0-9].*/.test(value) || 'Password must include a number.';
};

export const includesSpecialChar = (value): boolean | string => {
  if (!value.length) {
    return true;
  }
  /* eslint-disable */
  return (
    /.*[~`!@#$%^\-&*()_+={}|[\]:";'<>?,\/.].*/.test(value) ||
    'Password must include a special character.'
  );
  /* eslint-enable */
};

export const includesLetter = (value): boolean | string => {
  if (!value.length) {
    return true;
  }
  return /.*[a-zA-Z].*/.test(value) || 'Password must include a letter.';
};
