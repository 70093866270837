import {
  clearRedirectStores,
  redirectStorageMessages,
  redirectStores
} from '@common/helpers/storage';

export const loginWithRedirect = (storage, kcLogin): void => {
  if (storage && storage.message === redirectStorageMessages.NO_USER) {
    const redirectValue = storage.redirect!;
    clearRedirectStores();
    kcLogin({
      redirectUri: `${window.location.origin}${redirectValue}`
    });
  } else {
    kcLogin({
      redirectUri: `${window.location.origin}/user/dashboard`
    });
  }
};

// Ideally only one storageKey would be active at a time, and we would properly remove keys,
// but this should be monitored.
/**
 * Login with keycloak.login and any redirects kept in localStorage
 * @param kcLogin keycloak.login from useKeycloak()
 */
export const loginWithStorageRedirect = (kcLogin): void => {
  const activeStore = redirectStores.find((store) => store !== null);

  if (activeStore) {
    loginWithRedirect(activeStore, kcLogin);
  } else {
    loginWithRedirect(null, kcLogin);
  }
};
