import { Banner, BannerType } from '@api/models/bannersApi.models';
import { useEffect, useState } from 'react';
import { BannersApi } from '@api/Banners.api';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';

interface FetchUserBanners {
  banners: Banner[] | undefined;
  loading: boolean;
  loaded: true | null;
  setBanners: (banners: Banner[] | undefined) => void;
  getActiveBannerByType: (type: BannerType) => Banner | undefined;
  markBannerAsRead: (bannerId: number) => Promise<void>;
  closeBannerByBannerId: (bannerId: number) => void;
}

export const useFetchUserBanners = (
  userId?: string | null
): FetchUserBanners => {
  const [banners, setBanners] = useStore<Banner[] | undefined>(
    UseStoreKeys.BANNERS
  );
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  const fetchBanners = async (userId: string): Promise<void> => {
    try {
      setLoading(true);
      const res = await BannersApi.getBannersForUser(userId);
      setBanners(res.data);
    } catch (error: any) {
      console.error(
        'Error for useFetchUserBanners -> BannerApi.getBannersForUser()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  const markBannerAsRead = async (bannerId: number): Promise<void> => {
    await BannersApi.markBannerAsRead(bannerId);
  };

  const closeBannerByBannerId = (bannerId: number): void => {
    const filterBanners = banners?.filter((banner) => banner.id !== bannerId);

    setBanners(filterBanners);
  };

  const getActiveBannerByType = (type: BannerType): Banner | undefined => {
    return banners?.find((banner) => banner.type === type);
  };

  useEffect(() => {
    if (userId) {
      fetchBanners(userId);
    } else {
      setLoaded(true);
    }
  }, [userId]);

  return {
    banners,
    getActiveBannerByType,
    setBanners,
    markBannerAsRead,
    closeBannerByBannerId,
    loading,
    loaded
  };
};
