import { allowedGridFilterOperators } from '@common/fetches/useFetchGridTableByType';
import { AttestationStatus } from '@interfaces/attestation.interface';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { getAttestationStatusLabel } from '@common/helpers/externshipHelpers/getAttestationStatusLabel';
import { GridColDef } from '@mui/x-data-grid-pro';

export const attestationDataGridColumns: GridColDef[] = [
  {
    field: 'studentId',
    headerName: 'student number',
    flex: 1,
    valueGetter: (params): string => params.row.studentNumber,
    filterOperators: allowedGridFilterOperators
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    valueGetter: (params): string => params.row.studentName,
    filterOperators: allowedGridFilterOperators
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    valueGetter: (params): string => params.row.studentEmail,
    filterOperators: allowedGridFilterOperators
  },
  {
    field: 'phone',
    headerName: 'Phone',
    flex: 1,
    valueGetter: (params): string => params.row.studentPhone,
    filterOperators: allowedGridFilterOperators
  },
  {
    field: 'companyName',
    headerName: 'Company',
    flex: 1,
    valueGetter: (params): string => params.row.company,
    filterOperators: allowedGridFilterOperators
  },
  {
    field: 'program',
    headerName: 'Program',
    flex: 1,
    minWidth: 100,
    valueGetter: (params): string => params.row.programName,
    filterOperators: allowedGridFilterOperators
  },
  {
    field: 'employmentStartDate',
    headerName: 'Employment Start Date',
    flex: 1,
    valueGetter: (params): string => formatDate(params.row.startDate),
    filterOperators: allowedGridFilterOperators
  },
  {
    field: 'graduationDate',
    headerName: 'Grad Date',
    flex: 1,
    valueGetter: (params): string => formatDate(params.value),
    filterOperators: allowedGridFilterOperators
  },
  {
    field: 'chefInstructor.name',
    headerName: 'Assigned to',
    flex: 1,
    valueGetter: (params): string => params.row.chefInstructor,
    filterOperators: allowedGridFilterOperators
  },
  {
    field: 'attestationStatus',
    headerName: 'Status',
    flex: 1,
    valueGetter: (params): string =>
      getAttestationStatusLabel(params.value as AttestationStatus),
    filterOperators: allowedGridFilterOperators
  },
  {
    field: 'attestationType.label',
    headerName: 'Type',
    flex: 1,
    valueGetter: (params): string => params.row.type ?? 'NONE',
    filterOperators: allowedGridFilterOperators
  }
];
