import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Typography
} from '@mui/material';
import BackgroundImage from '@assets/global_solutions_modal_background.png';
import { styled } from '@mui/system';

const Styled = {
  Dialog: styled(Dialog)({
    ['@media (max-width: 350px)']: {
      '& .MuiDialog-paper': {
        margin: 0
      }
    }
  }),
  DialogTitle: styled(DialogTitle)({
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    padding: '31.5px 50px',
    textAlign: 'center',
    ['@media (max-width: 768px)']: {
      padding: '30px 50px'
    },
    ['@media (max-width: 350px)']: {
      padding: '30px 16px'
    }
  }),
  DialogContent: styled(DialogContent)({
    padding: '25px 35px 13px',
    ['@media (max-width: 768px)']: {
      flexDirection: 'column',
      padding: '25px 20px 13px'
    }
  }),
  DialogActions: styled(DialogActions)({
    padding: '13px 35px 34px',
    paddingBottom: '34px',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: '13px',
    columnGap: '8px',
    ['@media (max-width: 768px)']: {
      flexDirection: 'column',
      padding: '13px 20px 34px'
    }
  }),
  IconButton: styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.WHITE.main,
    fontSize: '8px',
    position: 'absolute',
    width: '32px',
    height: '32px',
    right: 18,
    top: 18,
    border: `2px solid ${theme.palette.WHITE.main}`
  })),
  Icon: styled(Icon)(({ theme }) => ({
    color: theme.palette.primary.main,
    borderRadius: '50%',
    ':hover': {
      color: theme.palette.WHITE.main
    }
  })),
  Title: styled(Typography)({
    ['@media (max-width: 768px)']: {
      fontSize: '24px',
      lineHeight: '30px'
    }
  }),
  Body: styled(Typography)({
    ['@media (max-width: 768px)']: {
      fontSize: '16px',
      lineHeight: '22px'
    }
  }),
  Button: styled(Button)(({ theme }) => ({
    padding: '14px 23px',
    position: 'relative',
    lineHeight: 1,
    fontFamily: 'trade-gothic-next-condensed',
    borderRadius: '30px',
    border: `2px solid ${theme.palette.primary.main}`,
    height: 'unset',
    fontSize: '20px',
    fontWeight: 800,
    ['@media (max-width: 768px)']: {
      width: '100%'
    }
  }))
};

interface Props {
  open: boolean;
  handleClose: () => void;
  externalLink: string;
}

const GlobalSolutionsModal: React.FC<Props> = ({
  open,
  handleClose,
  externalLink
}) => {
  const handleConfirm = (): void => {
    window.open(externalLink, '_blank');
    handleClose();
  };

  return (
    <Styled.Dialog
      onClose={handleClose}
      aria-labelledby="leaving-escoffier-global-dialog"
      open={open}
    >
      <Styled.DialogTitle id="leaving-escoffier-global-title">
        <Styled.Title variant="EC_TYPE_GS_TITLE" color="WHITE.main">
          Leaving EscoffierGlobal.com
        </Styled.Title>
      </Styled.DialogTitle>
      <Styled.IconButton aria-label="close" onClick={handleClose} size="small">
        <Styled.Icon className="ri-close-line" />
      </Styled.IconButton>
      <Styled.DialogContent>
        <Styled.Body variant="EC_TYPE_GS_BODY">
          Please be aware you are transitioning from EscoffierGlobal.com to
          Escoffier.edu to learn more about products and services offered
          through Auguste Escoffier School of Culinary Arts.
        </Styled.Body>
      </Styled.DialogContent>
      <Styled.DialogActions>
        <Styled.Button
          fullWidth
          variant="contained"
          disableElevation
          className="ga-cta"
          onClick={handleConfirm}
        >
          Continue to Escoffier.edu
        </Styled.Button>
        <Styled.Button
          fullWidth
          variant="outlined"
          disableElevation
          className="ga-cta"
          onClick={handleClose}
        >
          Stay on EscoffierGlobal.com
        </Styled.Button>
      </Styled.DialogActions>
    </Styled.Dialog>
  );
};

export default GlobalSolutionsModal;
