/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import {
  PromotedCompany,
  PromotedCompanyReq
} from '@api/models/adminContentManagementApi.models';
import { AdminContentManagementApi } from '@api/AdminContentManagement.api ';
import { AutoCompleteOption } from '@components/Forms/ReactHookFormsAutoComplete';
import BasicModal from '@components/BasicModal';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import ImageUploadWell from '@components/ImageUpload/ImageUploadWell';
import ReactHookFormsAutoCompleteSingle from '@components/Forms/ReactHookFormsAutoCompleteSingle';
import ReactHookFormsTextarea from '@components/Forms/ReactHookFormsTextarea';
import { useFetchAdminDashboardCompanyStatus } from '@common/fetches/useFetchAdminDashboardCompanyStatus';

enum SpotlightOption {
  DESCRIPTION = 10,
  IMAGE = 20
}

interface FormValues {
  companyId: number;
  description?: string;
  spotlightImageId?: number;
}

interface Props {
  open: boolean;
  handleClose: (spotlightSaved: boolean) => void;
  promotedCompanies: PromotedCompany[];
  currSpotlight?: PromotedCompany;
}

const EmployerSpotlightModal: React.FC<Props> = ({
  open,
  handleClose,
  promotedCompanies,
  currSpotlight
}) => {
  const theme = useTheme();

  const { companies: allCompanies } = useFetchAdminDashboardCompanyStatus({
    permission: 'EMPLOYER_SPOTLIGHT_AVAILABLE',
    active: true,
    profileComplete: true
  });
  const [radioSelection, setRadioSelection] = React.useState<
    SpotlightOption | ''
  >('');

  const methods = useForm<FormValues>({
    defaultValues: {
      companyId: undefined,
      description: '',
      spotlightImageId: undefined
    }
  });

  const { handleSubmit, reset, getValues } = methods;

  React.useEffect(() => {
    reset({
      companyId: currSpotlight?.company.id || undefined,
      description: currSpotlight?.description || ''
    });

    if (currSpotlight?.spotlightImage) {
      setRadioSelection(SpotlightOption.IMAGE);
    } else if (currSpotlight?.description) {
      setRadioSelection(SpotlightOption.DESCRIPTION);
    }
  }, [currSpotlight]);

  const options = React.useMemo((): AutoCompleteOption[] => {
    const autocompleteOptions =
      allCompanies
        ?.sort((a, b) => a.companyName.localeCompare(b.companyName))
        .map((company) => ({
          label: `${company.id} - ${company.companyName}`,
          value: company.id,
          disable: promotedCompanies.some((pc) => pc.company.id === company.id)
        })) || [];

    return autocompleteOptions;
  }, [promotedCompanies, allCompanies]);

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.value;

    if (SpotlightOption.DESCRIPTION === parseInt(newValue)) {
      reset({ ...getValues(), spotlightImageId: undefined });
    }

    if (SpotlightOption.IMAGE === parseInt(newValue)) {
      reset({ ...getValues(), description: '' });
    }

    setRadioSelection(parseInt(newValue) as SpotlightOption);
  };

  const addSpotlight = async (data: PromotedCompanyReq): Promise<void> => {
    try {
      await AdminContentManagementApi.addPromotedCompany(data);
      handleClose(true);
    } catch (error) {
      console.error(
        'Error for EmployerSpotlightContent -> AdminContentManagementApi.addPromotedCompany()',
        error
      );
    }
  };

  const editSpotlight = async (data: PromotedCompanyReq): Promise<void> => {
    try {
      // TODO: Pass spotlight id not company id
      await AdminContentManagementApi.editPromotedCompany(data);
      handleClose(true);
    } catch (error) {
      console.error(
        'Error for EmployerSpotlightContent -> AdminContentManagementApi.addPromotedCompany()',
        error
      );
    }
  };

  const handleValidForm = async (data: FormValues): Promise<void> => {
    const postBody = {
      companyId: data.companyId,
      description: data.description,
      spotlightImageId: data.spotlightImageId
    };

    if (SpotlightOption.DESCRIPTION === radioSelection) {
      postBody.spotlightImageId = undefined;
    }

    if (SpotlightOption.IMAGE === radioSelection) {
      postBody.description = '';
    }

    if (currSpotlight) {
      await editSpotlight(postBody);
    } else {
      await addSpotlight(postBody);
    }
  };

  const handleSave = async (): Promise<void> => {
    await handleSubmit(handleValidForm)();
  };

  const RadioButton: React.FC<{
    value: SpotlightOption;
    label: string;
    sublabel: string;
  }> = ({ value, label, sublabel }) => {
    return (
      <FormControlLabel
        value={value}
        control={<Radio />}
        label={
          <Box>
            <Typography variant="EC_TYPE_SM" sx={{ marginBottom: '4px' }}>
              {label}
            </Typography>
            <Typography variant="EC_TYPE_2XS" color={theme.palette.GRAY_3.main}>
              {sublabel}
            </Typography>
          </Box>
        }
      />
    );
  };

  return (
    <BasicModal
      title={`${currSpotlight ? 'Edit' : 'Add'} Employer Spotlight`}
      open={open}
      handleClose={(): void => handleClose(false)}
      maxWidth="sm"
      primaryAction={{ label: 'Save', action: handleSave }}
      secondaryAction={{
        label: 'Cancel',
        action: (): void => handleClose(false)
      }}
    >
      <FormProvider {...methods}>
        <BottomMarginContentWrapper bottomMargin="20px">
          <ReactHookFormsAutoCompleteSingle
            label="Select company"
            labelVariant="EC_TYPE_SM"
            subTitle="Type in the name of the company you want to make a post"
            name="companyId"
            options={options}
            rules={{ required: 'Company selection is required' }}
          />
          <FormControl>
            <RadioGroup
              value={radioSelection}
              onChange={handleRadioChange}
              aria-labelledby="spotlight-option-radio-buttons-group-label"
              name="spotlightOption"
            >
              <BottomMarginContentWrapper bottomMargin="20px">
                <RadioButton
                  value={SpotlightOption.DESCRIPTION}
                  label="Add a description (optional)"
                  sublabel="Include details to make the post stand out"
                />
                <Collapse in={radioSelection === SpotlightOption.DESCRIPTION}>
                  <ReactHookFormsTextarea
                    labelVariant="EC_TYPE_SM"
                    name="description"
                    rows={3}
                  />
                </Collapse>
                <RadioButton
                  value={SpotlightOption.IMAGE}
                  label="Add post image (optional)"
                  sublabel="Design your own layout for the spotlight. The maximum file size is 5 mb. If you add an image, the description will
                    not be displayed."
                />
                <Collapse in={radioSelection === SpotlightOption.IMAGE}>
                  <ImageUploadWell
                    name="spotlightImageId"
                    displayText="Drop your images here"
                    validTypes={['image/jpg', 'image/jpeg', 'image/png']}
                    files={
                      currSpotlight?.spotlightImage
                        ? [currSpotlight?.spotlightImage]
                        : []
                    }
                  />
                </Collapse>
              </BottomMarginContentWrapper>
            </RadioGroup>
          </FormControl>
        </BottomMarginContentWrapper>
      </FormProvider>
    </BasicModal>
  );
};

export default EmployerSpotlightModal;
