import {
  CandidateSignupRequest,
  CandidateSSOSignupRequest,
  EmployerSignupRequest,
  EmployerSSOSignupRequest,
  ExternshipManagerJoinRequestAccount,
  ExternshipManagerSignupRequest,
  JoinCompanyRequest,
  JoinCompanyRequestBase,
  RecruiterSignupRequest,
  SignupWithInvitePayload
} from './models/authApi.models';
import { api } from './configs/base';
import { apiConfig } from './configs/api.config';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export class AuthApi {
  static async candidateSignUp(data: CandidateSignupRequest): Promise<void> {
    try {
      await api.post(`${baseUrl}${apiConfig.candidateSignupUrl}`, data);
      return;
    } catch (error: any) {
      console.error('Error for AuthApi.candidateSignUp', error);
      throw error;
    }
  }

  static async employerSignup(data: EmployerSignupRequest): Promise<void> {
    try {
      await api.post(`${baseUrl}${apiConfig.employerSignupUrl}`, data);
      return;
    } catch (error: any) {
      console.error('Error for AuthApi.employerSignup', error);
      throw error;
    }
  }

  static async recruiterSignup(data: RecruiterSignupRequest): Promise<void> {
    try {
      await api.post(`${baseUrl}${apiConfig.recruiterSignupUrl}`, data);
      return;
    } catch (error: any) {
      console.error('Error for AuthApi.recruiterSignup', error);
      throw error;
    }
  }

  static async externshipManagerSignup(
    data: ExternshipManagerSignupRequest
  ): Promise<void> {
    try {
      await api.post(`${baseUrl}${apiConfig.externshipManagerSignupUrl}`, data);
      return;
    } catch (error: any) {
      console.error('Error for AuthApi.externshipManagerSignup', error);
      throw error;
    }
  }

  static async signupWithInvite(
    data: SignupWithInvitePayload
  ): Promise<void> {
    try {
      await api.post(`${baseUrl}${apiConfig.signupWithInviteUrl}`, data);
      return;
    } catch (error: any) {
      console.error('Error for AuthApi.signupWithInvite', error);
      throw error;
    }
  }

  static async candidateSSOSignup(
    data: CandidateSSOSignupRequest
  ): Promise<void> {
    try {
      await api.post(`${baseUrl}${apiConfig.candidateSSOSignupUrl}`, data);
      return;
    } catch (error: any) {
      console.error('Error for AuthApi.candidateSSOSignup', error);
      throw error;
    }
  }

  static async employerSSOSignup(
    data: EmployerSSOSignupRequest
  ): Promise<void> {
    try {
      await api.post(`${baseUrl}${apiConfig.employerSSOSignupUrl}`, data);
      return;
    } catch (error: any) {
      console.error('Error for AuthApi.employerSSOSignup', error);
      throw error;
    }
  }

  static async pendingUserSignup(
    data: EmployerSSOSignupRequest
  ): Promise<void> {
    try {
      await api.post(
        `${baseUrl}${apiConfig.employerUrl}/pending-user-signup`,
        data
      );
      return;
    } catch (error: any) {
      console.error('Error for AuthApi.pendingUserSignup', error);
      throw error;
    }
  }

  static async joinRequestSignUp(data: JoinCompanyRequest): Promise<void> {
    try {
      await api.post(`${baseUrl}${apiConfig.employerUrl}/join-request`, data);
      return;
    } catch (error: any) {
      console.error('Error for AuthApi.joinRequestSignUp', error);
      throw error;
    }
  }

  static async externshipManagerExistingCompanySignUp(
    data: ExternshipManagerJoinRequestAccount
  ): Promise<void> {
    try {
      await api.post(
        `${baseUrl}/externship-manager/existing-company-signup`,
        data
      );
      return;
    } catch (error: any) {
      console.error('Error for AuthApi.externshipManagerExistingCompanySignUp', error);
      throw error;
    }
  }

  static async joinRequestSsoSignUp(
    data: JoinCompanyRequestBase
  ): Promise<void> {
    try {
      await api.post(
        `${baseUrl}${apiConfig.employerUrl}/sso-join-request`,
        data
      );
      return;
    } catch (error: any) {
      console.error('Error for AuthApi.joinRequestSsoSignUp', error);
      throw error;
    }
  }
}
