import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { Answers } from '@api/models/quizApi.models';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useQuizContext } from '@components/Quiz/EcQuiz';

const Styled = {
  Root: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'selected'
  })<{ selected: boolean }>(({ selected }) => ({
    display: 'flex',
    minHeight: '75px',
    flexShrink: 1,
    padding: '25px 16px 25px 46px',
    alignItems: 'center',
    backgroundColor: selected ? theme.palette.gold1.main : '#FFFFFF',
    border: `3px solid ${theme.palette.GOLD_1.main}`,
    borderRadius: '6px',
    overflow: 'hidden',
    cursor: 'pointer',
    '& .text': {
      color: theme.palette.GOLD_1.main
    }
  }))
};

interface QuizAnswerProps {
  answer: Answers;
  selected: boolean;
}

const QuizAnswer = (props: QuizAnswerProps): React.ReactElement => {
  const { answer, selected } = props;
  const { currQuestion, handleSelectAnswer } = useQuizContext();
  const { ordinalNumber } = currQuestion;

  const handleClick = React.useCallback(() => {
    handleSelectAnswer(answer.answerCode, ordinalNumber);
  }, [handleSelectAnswer]);

  return (
    <Styled.Root
      data-testid="quiz-answer-root"
      selected={selected}
      onClick={handleClick}
    >
      <Typography
        data-testid="answer-text"
        variant="EC_TYPE_BASE"
        className="text"
      >
        {answer.answerDesc}
      </Typography>
    </Styled.Root>
  );
};

export default QuizAnswer;
