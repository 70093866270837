import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import { availabilityMap } from '@common/select-field-labels/availability';
import { BadgeMapping } from '@common/mappings/BadgeMappings';
import { CandidateProfile } from '@api/models/candidateApi.models';
import CandidateProfilePage from '@pages/Candidate/CandidateProfilePage';
import chefHat from '@assets/svg/chef_hat.round.gold4.svg';
import CrownIcon from '@assets/svg/crown.svg';
import { getCityState } from '@common/helpers/location';
import IconWithLabel from '@components/IconWithLabel';
import ImageWithBadge from '@components/ImageWithBadge';
import InviteModal from '@components/InviteModal';
import { Location } from '@interfaces/location.interfaces';
import { styled } from '@mui/system';
import { useToast } from '@components/Toast';

const Styled = {
  CandidateCard: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isEcCandidate'
  })<{ isEcCandidate: boolean }>(({ isEcCandidate, theme }) => ({
    border: `1px solid ${
      isEcCandidate ? theme.palette.GOLD_1.main : theme.palette.OUTLINE.main
    }`,
    borderRadius: '4px 4px 0px 0px',
    padding: '16px',
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.GRAY_2.main
    }
  })),
  CardInfoSection: styled(Box)({
    width: '100%'
  }),
  CardTopDetailsSection: styled(Box)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.GRAY_1.main}`,
    width: '100%',
    paddingLeft: '18px',
    paddingBottom: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  })),
  InviteButton: styled(Button)(({ theme }) => ({
    '&.MuiButtonBase-root': {
      color: theme.palette.WHITE.main
    },
    width: '50px',
    height: '23px',
    padding: '3.86px 0',
    textTransform: 'uppercase',
    minWidth: 'unset',
    marginLeft: '16px'
  })),
  CardBottomDetailsSection: styled(Box)({
    width: '100%',
    paddingLeft: '18px',
    paddingTop: '7px'
  }),
  InfoIconContainer: styled(Box)({
    display: 'flex',
    columnGap: '16px',
    rowGap: '4px',
    flexWrap: 'wrap'
  }),
  Dialog: styled(Dialog)({
    '.MuiDialog-paper': {
      padding: '0px',
      margin: '0px'
    }
  }),
  DialogContent: styled(DialogContent)({
    '&.MuiDialogContent-root': {
      padding: '0px'
    }
  }),
  IconButton: styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: 16,
    fontSize: '16px',
    lineHeight: '16px',
    top: 16,
    color: theme.palette.GRAY_3.main
  })),
  CloseIcon: styled('i')(({ theme }) => ({
    color: theme.palette.GRAY_3.main
  }))
};

interface Props {
  candidate: CandidateProfile;
}

const NON_EC_GRAD_VAL = 99;
const DO_NOT_CONTACT_VAL = '30';

const CandidateMarketplaceCard: React.FC<Props> = ({ candidate }) => {
  const theme = useTheme();

  const {
    id,
    firstName,
    lastName,
    locations,
    availability,
    photo,
    badge,
    relocation,
    graduationType,
    currentJobTitle,
    yearsOfExperience
  } = candidate;

  const [openProfile, setOpenProfile] = React.useState(false);
  const [openInvite, setOpenInvite] = React.useState(false);
  const { openToast } = useToast();

  const badgeMap = BadgeMapping(badge);

  const doNotContact = availability === DO_NOT_CONTACT_VAL;

  const isEcCandidate =
    !!graduationType && graduationType.value !== NON_EC_GRAD_VAL;

  const getLocationLabel = (locations: Location[]): string => {
    if (locations.length > 3) {
      return 'Multiple Locations';
    }

    return locations.map(getCityState).join(' ; ');
  };

  const infoIcons = [
    {
      label: getLocationLabel(locations),
      icon: `ri-map-pin-2-${relocation === 'YES' ? 'line' : 'fill'}`,
      hide: !locations.length
    },
    {
      label: currentJobTitle, // TODO: OBSOLETE - to be removed
      icon: 'ri-folder-user-fill',
      hide: !currentJobTitle
    },
    {
      label: yearsOfExperience?.label, // TODO: OBSOLETE - to be removed
      icon: 'ri-briefcase-fill',
      hide: !yearsOfExperience
    },
    {
      label: availabilityMap[availability],
      icon: 'ri-user-search-fill',
      hide: !availability
    },
    {
      label: graduationType?.label,
      svg: CrownIcon,
      hide: !graduationType || graduationType.value === NON_EC_GRAD_VAL
    }
  ];

  const handleCardClick = (): void => setOpenProfile(true);

  const handleCloseProfileModal = (): void => {
    setOpenProfile(false);
  };

  const handleInviteClick = (e): void => {
    e.stopPropagation();
    setOpenInvite(true);
  };

  const handleCloseInviteModal = (): void => setOpenInvite(false);

  const onSuccessfulInvite = (): void => {
    setOpenInvite(false);
    openToast('Your invite to apply has been sent successfully!');
  };

  const CandidateModal: React.FC = () => {
    return (
      <Styled.Dialog
        fullWidth
        maxWidth="lg"
        open={openProfile}
        scroll="paper"
        onClose={handleCloseProfileModal}
      >
        <Styled.IconButton aria-label="close" onClick={handleCloseProfileModal}>
          <Styled.CloseIcon className="ri-close-line" />
        </Styled.IconButton>
        <Styled.DialogContent>
          <CandidateProfilePage
            isEmployerViewing
            candidateIdFromProps={Number(id)}
          />
        </Styled.DialogContent>
      </Styled.Dialog>
    );
  };

  return (
    <>
      <Styled.CandidateCard
        data-testid={`candidate-card-${id}`}
        onClick={handleCardClick}
        isEcCandidate={isEcCandidate}
      >
        <ImageWithBadge
          dataTestId={`candidate-card-photo-${id}`}
          src={photo ? photo.fullPath : chefHat}
          height="60px"
          width="60px"
          borderColor={theme.palette.GRAY_2.main}
          badge={badge ? badgeMap?.[badge?.value] : undefined}
          expandableBadge
          noBorder={!photo}
        />
        <Styled.CardInfoSection>
          <Styled.CardTopDetailsSection>
            <Typography variant="EC_TYPE_SM" color="primary">
              {firstName} {lastName}
            </Typography>
            {!doNotContact && (
              <Styled.InviteButton
                data-testid={`candidate-card-invite-btn-${id}`}
                variant="contained"
                onClick={handleInviteClick}
                sx={{ typography: 'EC_TYPE_3XS' }}
              >
                Invite
              </Styled.InviteButton>
            )}
          </Styled.CardTopDetailsSection>
          <Styled.CardBottomDetailsSection>
            <Styled.InfoIconContainer
              data-testid={`candidate-card-info-container-${id}`}
            >
              {infoIcons.map((info, idx) => {
                if (info.hide) return;

                return (
                  <IconWithLabel
                    key={info.label + idx}
                    title={info.label}
                    icon={info.icon}
                    svg={info.svg}
                    wrapText
                    smallest
                  />
                );
              })}
            </Styled.InfoIconContainer>
          </Styled.CardBottomDetailsSection>
        </Styled.CardInfoSection>
      </Styled.CandidateCard>
      {openProfile && <CandidateModal />}
      {openInvite && (
        <InviteModal
          open={openInvite}
          handleClose={handleCloseInviteModal}
          candidateId={Number(id)}
          onSuccess={onSuccessfulInvite}
        />
      )}
    </>
  );
};

export default CandidateMarketplaceCard;
