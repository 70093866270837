/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  Box,
  Container,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import { BannerVariant } from '@api/models/bannersApi.models';
import BasicModal from '@components/BasicModal';
import CtaButton from '@components/CtaButton';
import { styled } from '@mui/system';
import { useHeaderFooterBannerContext } from '@common/context/headerFooterBannerContext';
import { useHistory } from 'react-router-dom';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const mediaQuery = '@media (min-width:762px)';

const Styled = {
  Root: styled(Box)({
    position: 'sticky',
    top: 75,
    zIndex: 100
  }),
  Banner: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'bgColor'
  })<{ bgColor: string }>(({ bgColor }) => ({
    backgroundColor: bgColor
  })),
  Container: styled(Container)({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    padding: '36px 15px 16px',
    boxShadow: '0 4px 2px -2px rgba(0,0,0,0.15)',
    [mediaQuery]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '18px 32px 18px 45px'
    }
  }),
  TextBox: styled(Box)({
    display: 'block',
    marginBottom: '4px',
    [mediaQuery]: {
      marginBottom: 0,
      marginRight: '14px'
    }
  }),
  Text: styled(Typography)(({ theme }) => ({
    color: theme.palette.GRAY_1.main,
    marginBottom: '8px'
  })),
  ModalText: styled(Typography)(({ theme }) => ({
    color: theme.palette.BLACK.main,
    '&:not(:last-of-type)': {
      marginBottom: '24px'
    }
  })),
  ButtonBox: styled(Box)({
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center'
  }),
  Close: styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: '15px',
    color: theme.palette.GRAY_1.main,
    [mediaQuery]: {
      position: 'unset',
      marginLeft: '40px'
    }
  }))
};

const EcAlertBanner = (): React.ReactElement | null => {
  const history = useHistory();
  const theme = useTheme();
  // TODO figure out exactly which colors should be returned
  const bannerColorMap = {
    [BannerVariant.NOTIFY]: theme.palette.GRAY_4.main,
    [BannerVariant.WARNING]: theme.palette.GRAY_3.main,
    [BannerVariant.URGENT]: theme.palette.red2.main
  };

  // TODO Use markBannerAsRead from useFetchUserBanners
  const { banners, closeBannerByBannerId } = useHeaderFooterBannerContext();

  const { width } = useWindowDimensions();
  const isSmall = width < 501;

  if (banners) {
    return (
      <Styled.Root>
        {banners.map((banner, idx) => {
          const {
            ctaLabel,
            ctaLink,
            clearable,
            id,
            message,
            title,
            type,
            variant,
            hideAtCtaLinkLocation
          } = banner;
          const bgColor = bannerColorMap[variant];
          const isModal = variant === BannerVariant.MODAL;
          const hideModalBanner: boolean =
            !!hideAtCtaLinkLocation &&
            history.location.pathname.includes(ctaLink);

          if (!isModal) {
            return (
              <Styled.Banner
                key={`${type}_${idx}`}
                bgColor={bgColor}
                data-testid="ec-alert-banner-root"
              >
                <Styled.Container maxWidth={false} sx={{ maxWidth: '1820px' }}>
                  <Styled.TextBox data-testid="ec-alert-banner-text-box">
                    <Styled.Text variant="EC_TYPE_LG">{title}</Styled.Text>
                    {message && (
                      <>
                        {message.split('\n').map((newlineStr, idx) => (
                          <Styled.Text
                            variant="EC_TYPE_BASE"
                            key={`${newlineStr}_${idx}`}
                          >
                            {newlineStr}
                          </Styled.Text>
                        ))}
                      </>
                    )}
                  </Styled.TextBox>
                  <Styled.ButtonBox>
                    {ctaLabel && ctaLink && (
                      <CtaButton
                        label={ctaLabel}
                        to={ctaLink}
                        sx={{
                          width: isSmall ? '100%' : 'auto'
                        }}
                        fullWidth={isSmall}
                        dataTestId="ec-alert-banner-cta-btn"
                      />
                    )}
                    {clearable && (
                      <Styled.Close
                        className="alert-banner-close-btn"
                        data-testid="ec-alert-banner-close-btn"
                        onClick={(): void => closeBannerByBannerId(id)}
                      >
                        <i className="ri-close-line" />
                      </Styled.Close>
                    )}
                  </Styled.ButtonBox>
                </Styled.Container>
              </Styled.Banner>
            );
          } else {
            return (
              <BasicModal
                key={`${type}_${id}.${idx}`}
                open={isModal && !hideModalBanner}
                primaryAction={{
                  label: ctaLabel || '',
                  action: (): void => history.push(ctaLink)
                }}
                title={title}
                subTitle={
                  <>
                    {message?.split('\n').map((newLineStr, idx) => (
                      <Styled.ModalText
                        key={`${newLineStr}_${idx}`}
                        variant="EC_TYPE_BASE"
                      >
                        {newLineStr}
                      </Styled.ModalText>
                    ))}
                  </>
                }
              />
            );
          }
        })}
      </Styled.Root>
    );
  }

  return null;
};

export default EcAlertBanner;
