import * as React from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 16px 12px 32px',
    gap: '20px',
    overflow: 'hidden'
  })),
  Text: styled(Typography)(({ theme }) => ({
    color: theme.palette.WHITE.main
  })),
  ButtonBox: styled(Box)(() => ({
    display: 'flex',
    gap: '20px'
  })),

  CtaBtn: styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.WHITE.main,
    color: theme.palette.primary.main,
    width: '100px'
  })),
  DismissBtn: styled(IconButton)(({ theme }) => ({
    color: theme.palette.WHITE.main
  }))
};

interface CtaProps {
  text: string;
  onClick: () => void;
}

interface Props {
  title: string;
  ctaPropsSend?: CtaProps;
  ctaPropsReject?: CtaProps;
  subtitle?: string;
  onDismissClick?: () => void;
}

const SimpleBannerWithCta: React.FC<Props> = ({
  title,
  subtitle,
  ctaPropsSend,
  ctaPropsReject,
  onDismissClick
}) => {
  const [disableBtn, setDisablePrimaryButton] = React.useState(false);

  const handleClick = (onClick?: () => void): void => {
    if (onClick) {
      setDisablePrimaryButton(true);
      onClick();
    }
  };

  return (
    <Styled.Root>
      <Box>
        <Styled.Text
          variant="EC_TYPE_SM"
          data-testid="simple-banner-title"
          mb={1}
        >
          {title}
        </Styled.Text>
        {subtitle && (
          <Typography
            component="p"
            variant="EC_TYPE_XS"
            data-testid="simple-banner-subtitle"
            sx={{ color: 'white', lineHeight: '18px' }}
          >
            {subtitle}
          </Typography>
        )}
      </Box>
      {onDismissClick ? (
        <Styled.DismissBtn aria-label="dismiss" onClick={onDismissClick}>
          <i className="ri-close-line" />
        </Styled.DismissBtn>
      ) : (
        <Styled.ButtonBox>
          <>
            {!!ctaPropsReject && (
              <Button
                variant="contained"
                onClick={(): void => handleClick(ctaPropsReject?.onClick)}
                sx={{
                  width: '100px',
                  height: 'unset',
                  border: '1px solid white'
                }}
                data-testid="simple-banner-cta-button-reject"
                disabled={disableBtn}
              >
                {ctaPropsReject?.text}
              </Button>
            )}
          </>
          {!!ctaPropsSend && (
            <Button
              color="WHITE"
              variant="contained"
              onClick={(): void => handleClick(ctaPropsSend?.onClick)}
              sx={{ minWidth: '100px', height: 'unset' }}
              data-testid="simple-banner-cta-button"
              disabled={disableBtn}
            >
              {ctaPropsSend?.text}
            </Button>
          )}
        </Styled.ButtonBox>
      )}
    </Styled.Root>
  );
};

export default SimpleBannerWithCta;
