import * as React from 'react';
import { Box, Button, Link, Skeleton, Theme, Typography } from '@mui/material';
import ExternshipStartDateFilter, {
  useExternshipStartDateFilter
} from '@pages/CareerServices/shared/ExternshipStartDateFilter';
import { allowedGridFilterOperators } from '@common/fetches/useFetchGridTableByType';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import EcDataGrid from '@components/DataGrid/EcDataGrid';
import EcGridTableToolbar from '@components/DataGrid/EcGridTableToolbar';
import EcGridTopFilterSelect from '@components/DataGrid/EcGridTopFilterSelect';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { getTimesheetStatusLabel } from '@common/helpers/externshipHelpers/getTimesheetStatusLabel';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import { TimesheetStatus } from '@api/models/externshipApi.models';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchAllExternshipCourses } from '@common/fetches/useFetchAllExternshipCourses';
import { useFetchDeprecatedChefInstructorTimesheets } from '@common/fetches/useFetchDeprecatedChefInstructorTimesheets';
import { useFetchTimesheetStatuses } from '@common/fetches/useFetchTimesheetStatuses';

const Styled = {
  Title: styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: (theme as Theme).typography.EC_TYPE_5XL.fontSize,
    lineHeight: (theme as Theme).typography.EC_TYPE_5XL.lineHeight
  })),
  TopFiltersWrapper: styled(Box)({
    display: 'flex',
    alignItems: 'flex-end',
    gap: '24px'
  })
};

const DeprecatedChefInstructorDashboardPage: React.FC = () => {
  const featureFlags = useFeatureFlags();

  const [initialTimesheetsFetched, setInitialTimesheetsFetched] =
    React.useState(false);

  const [course, setCourse] = React.useState<string>();
  const [timesheetStatus, setTimesheetStatus] = React.useState<TimesheetStatus>(
    TimesheetStatus.PENDING_INSTRUCTOR_APPROVAL
  );

  const { year, setYear, currDate, setCurrDate } =
    useExternshipStartDateFilter();

  const {
    chefInstructorTimesheets,
    fetchChefInstructorTimesheets,
    loaded: timesheetLoaded,
    loading: timesheetsLoading
  } = useFetchDeprecatedChefInstructorTimesheets();

  const { timesheetStatuses, loaded: timesheetStatusesLoaded } =
    useFetchTimesheetStatuses();
  const { allExternshipCourses, loaded: externshipCoursesLoaded } =
    useFetchAllExternshipCourses();

  React.useEffect(() => {
    if (externshipCoursesLoaded) {
      setCourse(allExternshipCourses[0].Code);
    }
  }, [externshipCoursesLoaded]);

  // On initial load we will auto fetch timesheets once currDate, status and course is set

  console.log('currDate', currDate);

  React.useEffect(() => {
    if (currDate && timesheetStatus && course && !initialTimesheetsFetched) {
      fetchChefInstructorTimesheets({
        externshipBeginDate: currDate,
        courseCode: course,
        status: timesheetStatus
      });
      setInitialTimesheetsFetched(true);
    }
  }, [currDate, timesheetStatus, course]);

  const columns = [
    {
      field: 'studentId',
      headerName: 'Student Id',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'studentName',
      headerName: 'Name',
      flex: 1,
      renderCell: (params): JSX.Element => (
        <Link
          component={RouterLink}
          to={
            featureFlags.MULTIPLE_EXTERNSHIPS_PER_COURSE
              ? `/candidate/${params.row.candidateId}/course/${params.row.courseScheduleId}/timesheets`
              : `/externship/${params.row.externshipId}/timesheets`
          }
          underline="none"
        >
          {params.row.studentName}
        </Link>
      ),
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'chefInstructor',
      headerName: 'Chef Instructor',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'company',
      headerName: 'Company',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'weekEnding',
      headerName: 'Week Ending',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'approveBy',
      headerName: 'Approve By',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    }
  ];

  const handleApplyFilters = (): void => {
    if (currDate && course && timesheetStatus) {
      fetchChefInstructorTimesheets({
        externshipBeginDate: currDate,
        courseCode: course,
        status: timesheetStatus
      });
    }
  };

  const displayTopFilters = externshipCoursesLoaded && timesheetStatusesLoaded;

  return (
    <RootPageLayout muiMaxWidth={false} bgImgVariant={2}>
      <BottomMarginContentWrapper bottomMargin="48px">
        <Styled.Title variant="EC_TYPE_3XL">
          Chef Instructor Timesheet Approvals
        </Styled.Title>
        {displayTopFilters && (
          <Styled.TopFiltersWrapper>
            <ExternshipStartDateFilter
              year={year}
              setYear={setYear}
              currDate={currDate}
              setCurrDate={setCurrDate}
            />
            {course && (
              <EcGridTopFilterSelect
                label="Course"
                onChange={(e): void => setCourse(String(e.target.value))}
                value={course}
                options={allExternshipCourses.map((c) => ({
                  label: c.Code,
                  value: c.Code
                }))}
              />
            )}
            <EcGridTopFilterSelect
              label="Status"
              onChange={(e): void => setTimesheetStatus(Number(e.target.value))}
              value={timesheetStatus}
              options={timesheetStatuses
                .map((s) => ({
                  label: getTimesheetStatusLabel(s, true),
                  value: s
                }))
                .filter((opt) => opt.value !== 0)}
            />
            <Box>
              <Button
                variant="outlined"
                sx={{ height: '45px', backgroundColor: 'white' }}
                onClick={handleApplyFilters}
              >
                Apply
              </Button>
            </Box>
          </Styled.TopFiltersWrapper>
        )}

        {!timesheetLoaded || timesheetsLoading ? (
          <Skeleton variant="rectangular" height="400px" />
        ) : (
          <EcDataGrid
            autoHeight={true}
            rowSelection
            columns={columns}
            rows={chefInstructorTimesheets?.map((t, idx) => ({
              ...t,
              id: idx,
              approveBy: formatDate(t.approveBy, 'MM/dd/yyyy'),
              weekEnding: formatDate(t.weekEnding, 'MM/dd/yyyy'),
              externshipBeginDate: formatDate(
                t.externshipBeginDate,
                'MM/dd/yyyy'
              ),
              status: getTimesheetStatusLabel(t.status)
            }))}
            slots={{
              toolbar: EcGridTableToolbar
            }}
          />
        )}
      </BottomMarginContentWrapper>
    </RootPageLayout>
  );
};

export default DeprecatedChefInstructorDashboardPage;
