import {
  Box,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

export const Styled = {
  DownloadWrapper: styled(Box)({
    textAlign: 'right',
    margin: '0px 0px 16px'
  }),
  DownloadBtn: styled(Typography)({
    color: theme.palette.red2.main,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.red4.main
    }
  }),
  TableContainer: styled(TableContainer)(({ theme }) => ({
    border: `1px solid ${theme.palette.GOLD_1.main}`,
    maxHeight: '50vh'
  })),
  TableRow: styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.gold1.main
    }
  })),
  ExpandableTableRow: styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(4n+1), &:nth-of-type(4n+2)': {
      backgroundColor: theme.palette.gold1.main
    }
  })),
  HeaderTableCell: styled(TableCell, {
    shouldForwardProp: (prop) => prop !== 'withinSubTable'
  })<{ withinSubTable?: boolean }>(({ withinSubTable, theme }) => ({
    padding: '6px 10px',
    fontWeight: '700',
    fontSize: '14px',
    borderBottom: 'none',
    color: withinSubTable ? theme.palette.GRAY_4.main : 'white',
    backgroundColor: withinSubTable ? 'none' : theme.palette.GOLD_1.main
  })),
  TableCell: styled(TableCell, {
    shouldForwardProp: (prop) => prop !== 'clickable' && prop !== 'bottomBorder'
  })<{ clickable?: boolean; bottomBorder?: boolean }>(
    ({ clickable, bottomBorder, theme }) => ({
      padding: '6px 10px',
      color: theme.palette.GRAY_4.main,
      fontWeight: '400',
      fontSize: '12px',
      '&:hover': {
        cursor: clickable ? 'pointer' : 'default',
        color: clickable
          ? theme.palette.primary.main
          : theme.palette.GRAY_4.main,
        textDecoration: clickable ? 'underline' : 'none'
      },
      borderBottom: bottomBorder
        ? `1px solid ${theme.palette.GRAY_1.main}`
        : 'none'
    })
  ),
  ExpandableCell: styled(TableCell, {
    shouldForwardProp: (prop) => prop !== 'expanded'
  })<{ expanded?: boolean }>(({ expanded }) => ({
    padding: expanded ? '20px 100px' : '0px',
    borderBottom: 'none'
  }))
};
