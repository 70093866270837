import * as React from 'react';
import BakingBasicsProductImg from '@assets/LearningCenter/Products/glrc-product.baking_and_pastry.png';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import CulinaryProductImg from '@assets/LearningCenter/Products/glrc-product.culinary.png';
import GlrcProductCard from '@pages/Partner/GlrcProductCard';
import RootPageLayout from '@components/Layout/RootPageLayout';

const glrcProducts = [
  {
    id: 1,
    title: 'Culinary',
    description:
      'Whether you want to hone your skills, start your own business or move up at your employer, Escoffier can help you get there faster with the flexibility of online or on-campus learning.',
    image: CulinaryProductImg,
    link: '/contact-us'
  },
  {
    id: 2,
    title: 'Baking & Pastry',
    description:
      'Whether you want to hone your skills, start your own business or move up at your employer, Escoffier can help you get there faster with the flexibility of online or on-campus learning.',
    image: BakingBasicsProductImg,
    link: '/contact-us'
  }
];

const GlrcProductCardComponentPage: React.FC = () => {
  return (
    <RootPageLayout muiMaxWidth={false}>
      <BottomMarginContentWrapper bottomMargin="40px">
        {glrcProducts.map((product) => (
          <GlrcProductCard key={product.id} {...product} />
        ))}
      </BottomMarginContentWrapper>
    </RootPageLayout>
  );
};

export default GlrcProductCardComponentPage;
