import * as React from 'react';
import { FieldValues, UseFormSetError } from 'react-hook-form';
import { FileType } from '@api/models/fileApi.models';
import ImageUploadWell from '@components/ImageUpload/ImageUploadWell';
import StepSection from '@components/StepSection';
import StepSubSection from '@components/StepSubSection';

interface Props<T extends FieldValues> {
  culinaryPhotos: FileType[];
  photoLimit: number;
  setImgUploadError: UseFormSetError<T>;
}

const PortfolioSection = <T extends FieldValues>({
  culinaryPhotos,
  photoLimit,
  setImgUploadError
}: Props<T>): React.ReactElement => {
  return (
    <StepSection title="Portfolio" dataTestId="candidate-portfolio-section">
      <StepSubSection title="What dishes are you proud of making?">
        <ImageUploadWell
          name="culinaryPhotos"
          label="Upload up to sixteen images to showcase your culinary work in a portfolio"
          displayText="Drop photos of your work"
          validTypes={['image/jpg', 'image/jpeg', 'image/png']}
          multiple={true}
          files={culinaryPhotos}
          photoLimit={photoLimit}
          setRhfError={setImgUploadError}
        />
      </StepSubSection>
    </StepSection>
  );
};

export default PortfolioSection;
