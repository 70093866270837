import * as React from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  Fade,
  IconButton,
  List,
  Paper,
  Popper,
  PopperPlacementType,
  Typography
} from '@mui/material';
import {
  ProfileProp,
  ResumeComponentOptions
} from '@pages/Candidate/ResumePreviewModal/resumePreviewModal.models';
import EditSectionButton from '@components/EditControls/EditSectionButton';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const btnStyle: React.CSSProperties = {
  position: 'absolute',
  left: '100%',
  transform: 'translateX(calc(50% + 20px))'
};

const Styled = {
  Paper: styled(Paper)({
    padding: '8px 12px 8px 11px'
  }),
  TitleBox: styled(Box)({
    display: 'flex',
    width: 'calc(100% + 7px)',
    alignItems: 'center',
    justifyContent: 'space-between'
  }),
  TitleBtnBox: styled(Box)({
    display: 'flex',
    marginLeft: '32px'
  }),
  OptionBox: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '6px',
    margin: '4px 0'
  }),
  OptionBtn: styled(Button)({
    display: 'flex',
    padding: 0,
    width: '100%',
    justifyContent: 'space-between'
  }),
  OptionText: styled(Typography)({
    marginRight: '10px'
  }),
  MoveIcon: styled('i')({
    fontSize: '16px',
    color: theme.palette.GRAY_4.main
  }),
  EyeIcon: styled('i')({
    fontSize: '14px'
  }),
  Thumbnail: styled('img', {
    shouldForwardProp: (prop) => prop !== 'hidden'
  })<{ hidden: boolean }>(({ hidden }) => ({
    width: '32px',
    height: '32px',
    objectFit: 'contain',
    objectPosition: 'center center',
    opacity: hidden ? 0.45 : 1
  }))
};

interface Props {
  anchorEl: HTMLElement | null;
  open: boolean;
  popperId: string;
  popperPlacement: PopperPlacementType;
  options: ResumeComponentOptions;
  sectionHidden: boolean;
  disableHeaderBtns: boolean;
  disableMoveUp: boolean;
  disableMoveDown: boolean;
  moveUp: (id: string) => void;
  moveDown: (id: string) => void;
  handleHideItem: (
    key: string,
    displayValue: string | string[],
    value: any
  ) => void;
  hideSection: (id: string) => void;
  handleOpen: (id: string) => (event: React.MouseEvent<HTMLElement>) => void;
  handleClickAway: () => void;
  disableSubSection?: boolean;
  buttonStyle?: React.CSSProperties;
}

interface MenuListProps {
  prop: ProfileProp<any>;
  propAtDisplayValue: any;
  profileKey: string;
  displayValue: string;
  handleHideItem: (
    key: string,
    displayValue: string | string[],
    value: any
  ) => void;
}

const MenuListItem = ({
  prop,
  propAtDisplayValue,
  profileKey,
  displayValue,
  handleHideItem
}: MenuListProps): JSX.Element => {
  const hidden = prop.hidden && prop.hidden === true;
  const eyeIcon = hidden ? 'ri-eye-off-line' : 'ri-eye-fill';
  const color = hidden ? theme.palette.GRAY_2.main : theme.palette.GRAY_4.main;

  return (
    <Styled.OptionBox>
      {!!propAtDisplayValue && (
        <Styled.OptionBtn
          variant="text"
          fullWidth
          onClick={(): void =>
            handleHideItem(profileKey, displayValue, propAtDisplayValue)
          }
        >
          {displayValue === 'fullPath' ? (
            <Styled.Thumbnail src={propAtDisplayValue} hidden={!!hidden} />
          ) : (
            <Styled.OptionText variant="EC_TYPE_2XS" color={color}>
              {propAtDisplayValue}
            </Styled.OptionText>
          )}
          <Styled.EyeIcon className={eyeIcon} sx={{ fontWeight: 400, color }} />
        </Styled.OptionBtn>
      )}
    </Styled.OptionBox>
  );
};

const ResumeEditOptions: React.FC<Props> = ({
  anchorEl,
  open,
  popperId,
  popperPlacement,
  options,
  sectionHidden,
  disableHeaderBtns,
  disableMoveUp,
  disableMoveDown,
  moveUp,
  moveDown,
  handleHideItem,
  hideSection,
  handleOpen,
  handleClickAway,
  disableSubSection = false,
  buttonStyle = btnStyle
}) => {
  const handleMoveUp = React.useCallback(() => {
    moveUp(popperId);
  }, [moveUp, popperId]);

  const handleMoveDown = React.useCallback(() => {
    moveDown(popperId);
  }, [moveDown, popperId]);

  const handleHideSection = React.useCallback(() => {
    hideSection(popperId);
  }, [hideSection, popperId]);

  const headerEyeIcon = sectionHidden ? 'ri-eye-off-line' : 'ri-eye-fill';

  const itemArray = Array.isArray(options.items) ? options.items : undefined;
  const itemObj = !Array.isArray(options.items) ? options.items : undefined;

  return (
    <>
      <EditSectionButton
        onClick={handleOpen(popperId)}
        btnColor={sectionHidden ? theme.palette.GOLD_1.main : undefined}
        sx={buttonStyle}
        dataTestId={`resume-edit-options-${popperId}`}
      />
      <Popper
        id={popperId}
        anchorEl={anchorEl}
        open={open}
        placement={popperPlacement}
        transition
        sx={{ zIndex: 1300 }}
        data-testid={`resume-edit-options-popper-${popperId}`}
      >
        {({ TransitionProps }): JSX.Element => (
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={handleClickAway}
          >
            <Fade {...TransitionProps} timeout={350}>
              <Styled.Paper>
                <Styled.TitleBox>
                  <Typography variant="EC_TYPE_2XL">{options.title}</Typography>
                  {!disableHeaderBtns && (
                    <Styled.TitleBtnBox>
                      {!disableMoveUp && (
                        <IconButton onClick={handleMoveUp}>
                          <Styled.MoveIcon className="ri-arrow-up-s-fill" />
                        </IconButton>
                      )}
                      {!disableMoveDown && (
                        <IconButton onClick={handleMoveDown}>
                          <Styled.MoveIcon className="ri-arrow-down-s-fill" />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={handleHideSection}
                        data-testid="resume-edit-options-hide-section-btn"
                      >
                        <Styled.EyeIcon className={headerEyeIcon} />
                      </IconButton>
                    </Styled.TitleBtnBox>
                  )}
                </Styled.TitleBox>

                {!disableSubSection && (
                  <>
                    <hr
                      style={{ border: '0.5px solid rgba(166, 176, 191, 0.8)' }}
                    />
                    <List disablePadding sx={{ paddingBottom: '6px' }}>
                      {itemArray &&
                        itemArray.map((item, index) => {
                          const {
                            displayValue,
                            profileKey,
                            props: _props
                          } = item;

                          return (
                            <React.Fragment key={`${displayValue}-${index}`}>
                              {_props.map((prop) => (
                                <MenuListItem
                                  key={prop[displayValue]}
                                  prop={prop}
                                  propAtDisplayValue={prop[displayValue]}
                                  displayValue={displayValue}
                                  profileKey={profileKey}
                                  handleHideItem={handleHideItem}
                                />
                              ))}
                            </React.Fragment>
                          );
                        })}

                      {itemObj &&
                        itemObj.props.map((prop) => {
                          const { displayValue, profileKey } = itemObj;

                          return (
                            <MenuListItem
                              key={prop[displayValue]}
                              prop={prop}
                              propAtDisplayValue={prop[displayValue]}
                              displayValue={displayValue}
                              profileKey={profileKey}
                              handleHideItem={handleHideItem}
                            />
                          );
                        })}
                    </List>
                  </>
                )}
              </Styled.Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default ResumeEditOptions;
