/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Toolbar,
  useTheme
} from '@mui/material';
import EsourceNavSearch from '@components/Layout/Header/EsourceNavSearch';
import { HEADER_HEIGHT } from '@themes/ui/variables';
import HeaderLink from '@components/Layout/Header/HeaderLink';
import HeaderLogo from '@components/Layout/Header/HeaderLogo';
import NavAvatarMenu from './NavAvatarMenu';
import { NavigationHeader } from './Header.models';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import { useTagManager } from '@common/hooks/useTagManager';

const Styled = {
  AppBar: styled(AppBar)({
    backgroundColor: 'white',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)'
  }),
  Container: styled(Container)({
    padding: '0px 65px',
    maxWidth: '1820px'
  }),
  Toolbar: styled(Toolbar)({
    height: `${HEADER_HEIGHT}px`,
    justifyContent: 'space-between'
  }),
  SignInBtn: styled(Button)(({ theme }) => ({
    color: theme.palette.BLACK.main,
    marginRight: '20.5px',
    padding: '9.5px 22.5px'
  }))
};

interface Props {
  nav: NavigationHeader;
  login: () => void;
  isAuthenticated: boolean;
  logout: () => void;
}

const DesktopNav: React.FC<Props> = ({
  nav,
  login,
  isAuthenticated,
  logout
}) => {
  const { sendToGtm } = useTagManager();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const theme = useTheme();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (): void => {
    setAnchorElUser(null);
  };

  const handleClick = (): void => {
    sendToGtm('navigation', {
      navigate_to: '/create-account',
      component: 'header',
      link_text: 'Create Account'
    });
  };

  return (
    <Styled.AppBar position="sticky">
      <Styled.Container maxWidth={false} disableGutters>
        <Styled.Toolbar disableGutters>
          <HeaderLogo />
          {isAuthenticated ? (
            <>
              <Box
                sx={{ flexGrow: 1 }}
                data-testid="primary-nav-items-container"
              >
                {nav.primaryLinks.map((navItem) => (
                  <HeaderLink
                    key={navItem.label}
                    title={navItem.label}
                    link={navItem.path}
                  />
                ))}
              </Box>
              <Box sx={{ flexGrow: 0, gap: '20px' }}>
                <EsourceNavSearch />
                <IconButton
                  data-testid="profile-menu-icon"
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0, color: theme.palette.GOLD_1.main }}
                >
                  <i className="ri-account-circle-fill"></i>
                </IconButton>
                <NavAvatarMenu
                  anchorEl={anchorElUser}
                  handleClose={handleCloseUserMenu}
                  menuItems={nav.avatarLinks}
                  logout={logout}
                />
              </Box>
            </>
          ) : (
            <Box sx={{ flexGrow: 0 }}>
              <Styled.SignInBtn onClick={login} data-testid="nav-sign-in-btn">
                Sign In
              </Styled.SignInBtn>
              <Button
                component={RouterLink}
                to="/create-account"
                onClick={handleClick}
                variant="contained"
                data-testid="nav-create-account-btn"
                sx={{
                  padding: '9.5px 22.5px'
                }}
              >
                Create Account
              </Button>
            </Box>
          )}
        </Styled.Toolbar>
      </Styled.Container>
    </Styled.AppBar>
  );
};

export default DesktopNav;
