import {
  Timesheet,
  TimesheetsWithExternship
} from '@api/models/externshipApi.models';
import { useEffect, useState } from 'react';
import { CourseData } from '@api/models/enrollmentApi.models';
import { ExternshipApi } from '@api/Externships.api';

type CourseRes = CourseData & { programName: string };

interface FetchTimesheetsByCandidateAndCourse {
  timesheetsWithExternship: TimesheetsWithExternship[];
  allTimesheets: Timesheet[];
  course?: CourseRes;
  loaded: true | null;
  loading: boolean;
  setTimesheetsWithExternship: React.Dispatch<
    React.SetStateAction<TimesheetsWithExternship[]> // eslint-disable-line @typescript-eslint/indent
  >;
  setAllTimesheets: React.Dispatch<React.SetStateAction<Timesheet[]>>;
}

export const useFetchTimesheetsByCandidateAndCourse = (
  candidateId?: string,
  courseId?: string
): FetchTimesheetsByCandidateAndCourse => {
  const [timesheetsWithExternship, setTimesheetsWithExternship] = useState<
    TimesheetsWithExternship[] // eslint-disable-line @typescript-eslint/indent
  >([]);
  const [allTimesheets, setAllTimesheets] = useState<Timesheet[]>([]);
  const [course, setCourse] = useState<CourseRes>();
  const [loaded, setLoaded] = useState<true | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async (
    candidateId: number,
    courseId: number
  ): Promise<void> => {
    try {
      setLoading(true);
      const res = await ExternshipApi.getTimesheetsByCandidateAndCourse(
        candidateId,
        courseId
      );

      setTimesheetsWithExternship(res);

      if (res.length) {
        const timesheets = res.flatMap((r) => r.timesheets);
        setAllTimesheets(timesheets);

        // Fetching using courseId so all data will have externships with same course
        setCourse({
          ...res[0].externship.course,
          programName: res[0].externship.currentProgram.label
        });
      }
    } catch (error: any) {
      console.error(
        'Error for useFetchTimesheetsByCandidateAndCourse -> ExternshipApi.getTimesheetsByCandidateAndCourse',
        error
      );
    } finally {
      setLoaded(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (candidateId && courseId) {
      fetchData(+candidateId, +courseId);
    }
  }, [candidateId, courseId]);

  return {
    timesheetsWithExternship,
    allTimesheets,
    course,
    loaded,
    loading,
    setTimesheetsWithExternship,
    setAllTimesheets
  };
};
