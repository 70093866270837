import * as serviceWorker from './serviceWorker';
import App from './App';
import { IStateTypes } from '@typings/StateTypes';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import React from 'react';
import ReactDOM from 'react-dom';
import { withStore } from 'react-context-hook';

const initialValue: IStateTypes = {
  candidateId: null,
  companyId: null,
  role: null,
  isParentCompany: null
};

const AppWithStore = withStore(App, initialValue);
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY || '');

ReactDOM.render(
  <React.StrictMode>
    <AppWithStore />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
