import * as React from 'react';
import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'useMaxWidth'
  })<{ useMaxWidth: boolean }>(({ useMaxWidth }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    ...(useMaxWidth && {
      width: '100%'
    })
  })),
  ArrowWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'displayArrows'
  })<{ displayArrows: boolean }>(({ displayArrows }) => ({
    display: 'flex',
    alignItems: 'center',
    ...(!displayArrows && {
      width: '1.75rem' // default MUI IconButton width
    })
  })),
  ItemsWrapper: styled(Box)({
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    rowGap: '32px',
    columnGap: '16px'
  }),
  ArrowButton: styled(IconButton, {
    shouldForwardProp: (prop) => prop !== 'useMaxWidth'
  })<{ useMaxWidth: boolean }>(({ useMaxWidth }) => ({
    ...(useMaxWidth && {
      padding: 0
    })
  }))
};

interface Props {
  items: React.ReactNode[];
  itemsPerSlide?: number;
  timer?: number; // milliseconds
  useMaxWidth?: boolean;
}

const Carousel: React.FC<Props> = ({
  items,
  itemsPerSlide = 1,
  timer,
  useMaxWidth = false
}) => {
  const [currPage, setCurrPage] = React.useState(1);
  const [currItems, setCurrItems] = React.useState(
    items.slice(0, itemsPerSlide)
  );

  const slides = Math.ceil(items.length / itemsPerSlide);

  const displayArrows = items.length > 1;

  const setItemsByPage = (page: number): void => {
    const newItems = items.slice(
      page * itemsPerSlide - itemsPerSlide,
      page * itemsPerSlide
    );

    setCurrPage(page);
    setCurrItems(newItems);
  };

  const handleLeftArrow = (): void => {
    if (displayArrows) {
      const nextPage = currPage === 1 ? slides : currPage - 1;
      setItemsByPage(nextPage);
    }
  };

  const handleRightArrow = (): void => {
    if (displayArrows) {
      const nextPage = currPage === slides ? 1 : currPage + 1;
      setItemsByPage(nextPage);
    }
  };

  React.useEffect(() => {
    setCurrItems(items.slice(0, itemsPerSlide));
  }, [items]);

  if (timer) {
    React.useEffect(() => {
      const interval = setInterval(() => {
        handleRightArrow();
      }, timer);

      return (): void => clearInterval(interval);
    }, [currItems, currPage]);
  }

  return (
    <Styled.Root data-testid="carousel" useMaxWidth={useMaxWidth}>
      {items.length > 1 && (
        <Styled.ArrowWrapper
          onClick={handleLeftArrow}
          displayArrows={displayArrows}
          data-testid="carousel-left-arrow"
        >
          {displayArrows && (
            <Styled.ArrowButton size="large" useMaxWidth={useMaxWidth}>
              <i className="ri-arrow-left-s-fill"></i>
            </Styled.ArrowButton>
          )}
        </Styled.ArrowWrapper>
      )}
      <Styled.ItemsWrapper data-testid="carousel-items-wrapper">
        {currItems.map((item, idx) => {
          return <React.Fragment key={idx}>{item}</React.Fragment>;
        })}
      </Styled.ItemsWrapper>
      {items.length > 1 && (
        <Styled.ArrowWrapper
          onClick={handleRightArrow}
          displayArrows={displayArrows}
          data-testid="carousel-right-arrow"
        >
          {displayArrows && (
            <Styled.ArrowButton size="large" useMaxWidth={useMaxWidth}>
              <i className="ri-arrow-right-s-fill"></i>
            </Styled.ArrowButton>
          )}
        </Styled.ArrowWrapper>
      )}
    </Styled.Root>
  );
};

export default Carousel;
