import * as React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';

const CARD_COUNT = 6;

const EmployerProfileJobPostsSkeleton = (): React.ReactElement => {
  return (
    <Box>
      <Skeleton width={200} sx={{ marginBottom: '20px' }} />
      <Grid container spacing={2}>
        {Array(CARD_COUNT)
          .fill(0)
          .map((_, idx: number) => (
            <Grid item key={idx} xl={3} lg={4} md={6} sm={6} xs={12}>
              <Skeleton height="245px" sx={{ transform: 'none' }} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default EmployerProfileJobPostsSkeleton;
