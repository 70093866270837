import * as React from 'react';
import {
  TabsPanelsBenefitsProps,
  TabsPanelsCompanyProps
} from '@pages/Employer/PreviewPage/employerPreviewPageComponentProps';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import theme from '@themes/ui/escoffier';

const Styled = {
  TabContent: styled(Box)({
    flexShrink: 1,
    fontSize: '15px',
    color: theme.palette.grey4.main
  }),
  TabList: styled(TabList)({
    width: '100%',
    maxWidth: '420px',
    marginBottom: '30px',
    '& .MuiTab-root': {
      whiteSpace: 'nowrap',
      marginLeft: 0,
      marginRight: 'auto',
      flexShrink: 1,
      columns: 4
    }
  }),
  Tab: styled(Tab)({
    color: theme.palette.GRAY_3.main,
    '&.Mui-selected': {
      color: theme.palette.GOLD_1.main
    }
  }),
  TabPanel: styled(TabPanel)({
    padding: 0
  }),
  Items: styled(Box)({
    margin: '15px 32px 15px 0',
    typography: 'EC_TYPE_BASE',
    '& .tab-item-ul': {
      margin: '5px 0 15px 0'
    },
    '& .tab-item-div': {
      marginLeft: '2px'
    },
    '&:last-child': {
      marginBottom: 0
    }
  })
};

const tabContent = [
  {
    title: 'About',
    tabLabel: 'About',
    tabValue: 'one'
  },
  {
    title: 'Benefits',
    tabLabel: 'Benefits',
    tabValue: 'two'
  },
  {
    title: 'Culture',
    tabLabel: 'Culture',
    tabValue: 'three'
  },
  {
    title: 'Health & Safety',
    tabLabel: 'Health & Safety',
    tabValue: 'four'
  }
];

interface ContentType {
  label: string;
  content?: string | string[];
}

interface TabSectionProps {
  data: ContentType[];
}

type OwnProps = TabsPanelsCompanyProps & TabsPanelsBenefitsProps;

const TabSection = ({ data }: TabSectionProps): JSX.Element => {
  return (
    <>
      {data.map((item, idx) => {
        const hasContent = Boolean(item?.content?.length);

        return hasContent ? (
          <Styled.TabContent key={idx} data-testid={`tab-content-${idx}`}>
            <Styled.Items>
              <strong>{item.label}</strong>
              {Array.isArray(item.content) ? (
                <ul className="tab-item-ul">
                  {item.content.map((listItem, idx) => (
                    <li key={idx} className="tab-item-li">
                      {listItem}
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="tab-item-div">{item.content}</div>
              )}
            </Styled.Items>
          </Styled.TabContent>
        ) : null;
      })}
    </>
  );
};

const TabsPanelsEmployer: React.FC<OwnProps> = (props): JSX.Element => {
  const {
    companyCulture,
    companyHealthSafeties,
    companyMission,
    companyOverview,
    companyValues,
    companyVision,
    healthWellnessBenefits,
    retirementBenefits,
    professionalDevBenefits
  } = props;
  const [value, setValue] = React.useState('one');

  const about: ContentType[] = [
    {
      label: 'Overview',
      content: companyOverview
    },
    {
      label: 'Mission',
      content: companyMission
    },
    {
      label: 'Vision',
      content: companyVision
    },
    {
      label: 'Values',
      content: companyValues
    }
  ];

  const benefits: ContentType[] = [
    {
      label: 'Health Insurance & Wellness Benefits',
      content: healthWellnessBenefits
    },
    {
      label: 'Retirement',
      content: retirementBenefits
    },
    {
      label: 'Professional Development',
      content: professionalDevBenefits
    }
  ];

  const culture: ContentType[] = [
    {
      label: 'Company Culture',
      content: companyCulture?.map((item) => item.type) ?? ''
    }
  ];

  const health: ContentType[] = [
    {
      label: 'Health & Safety',
      content: companyHealthSafeties?.map((item) => item.type) ?? ''
    }
  ];

  const tabArray = [about, benefits, culture, health];

  const handleChange = (event, newValue): void => {
    setValue(newValue);
  };

  return (
    <Box data-testid="tabsPanelsContainer" sx={{ width: '100%' }}>
      <TabContext value={value}>
        <Styled.TabList
          onChange={handleChange}
          data-testid="individualTab"
          aria-label="tab panels label"
        >
          {tabContent.map((item, index) => (
            <Styled.Tab
              key={index}
              label={item.tabLabel}
              value={item.tabValue}
            />
          ))}
        </Styled.TabList>
        {tabContent.map((item, idx) => (
          <Styled.TabPanel key={idx} value={item.tabValue}>
            <TabSection data={tabArray[idx]} />
          </Styled.TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default TabsPanelsEmployer;
