import { useEffect, useState } from 'react';
import { SearchApi } from '@api/Search.api';
import { WorkLearnPartnerSearchRes } from '@interfaces/workLearnPartner.interfaces';

interface FetchWorkLearnPartners {
  workLearnPartnersSearchRes?: WorkLearnPartnerSearchRes[];
  loading: boolean;
  loaded: true | null;
}

export const useFetchWorkLearnPartners = (): FetchWorkLearnPartners => {
  const [workLearnPartnersSearchRes, setWorkLearnPartnersSerchRes] =
    useState<WorkLearnPartnerSearchRes[]>();

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  const fetchSearchRes = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await SearchApi.getWorkLearnPartners();

      setWorkLearnPartnersSerchRes(res.data);
    } catch (error: any) {
      console.error(
        'Error for useFetchWorkLearnPartners -> SearchApi.getWorkLearnPartners()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  useEffect(() => {
    fetchSearchRes();
  }, []);

  return { workLearnPartnersSearchRes, loading, loaded };
};
