import * as React from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const GridLink = (link: string, label: string): JSX.Element => {
  return (
    <Link
      component={RouterLink}
      to={link}
      target="_blank"
      rel="noopener noreferrer"
      underline="none"
    >
      {label}
    </Link>
  );
};

export const chefInstructorGridColumns: GridColDef[] = [
  { field: 'externshipStatus', headerName: 'Externship Status', flex: 1 },
  {
    field: 'studentNumber',
    headerName: 'Student Number',
    flex: 1
    // filterOperators: allowedGridFilterOperators
  },
  {
    field: 'studentName',
    headerName: 'Name',
    flex: 1,
    // filterOperators: allowedGridFilterOperators
    renderCell: (params): JSX.Element =>
      GridLink(
        `/career-services/candidate/${params.row.candidateId}/site-information`,
        params.row.studentName
      )
  },
  {
    field: 'studentPhone',
    headerName: 'Phone',
    flex: 1
    // filterOperators: allowedGridFilterOperators
  },
  {
    field: 'schoolStatusName',
    headerName: 'School Status',
    flex: 1
    // filterOperators: allowedGridFilterOperators
  },
  {
    field: 'timesheetStatus',
    headerName: 'Timesheet Status',
    flex: 1,
    renderCell: (params): JSX.Element =>
      GridLink(
        `/candidate/${params.row.candidateId}/course/${params.row.courseScheduleId}/timesheets`,
        params.row.timesheetStatus
      )

    // filterOperators: allowedGridFilterOperators
  },
  {
    field: 'employmentStartDate',
    headerName: 'Employment Start Date',
    flex: 1
    // filterOperators: allowedGridFilterOperators
  },
  {
    field: 'externshipBeginDate',
    headerName: 'Externship Begin Date',
    flex: 1
    // filterOperators: allowedGridFilterOperators
  },
  {
    field: 'totalHours',
    headerName: 'Hours (HH:MM)',
    flex: 1
    // filterOperators: allowedGridFilterOperators
  },
  {
    field: 'totalChefApprovedCourseHours',
    headerName: 'Total Approved Hours',
    flex: 1
    // filterOperators: allowedGridFilterOperators
  },
  {
    field: 'approverName',
    headerName: 'Approved by',
    flex: 1
    // filterOperators: allowedGridFilterOperators
  },
  {
    field: 'approveByDate',
    headerName: 'Approve by Date',
    flex: 1
    // filterOperators: allowedGridFilterOperators
  },
  {
    field: 'lda',
    headerName: 'LDA',
    flex: 1
    // filterOperators: allowedGridFilterOperators
  },
  {
    field: 'companyName',
    headerName: 'Company',
    flex: 1
    // filterOperators: allowedGridFilterOperators
  },
  {
    field: 'instructorName',
    headerName: 'Chef Instructor',
    flex: 1
    // filterOperators: allowedGridFilterOperators
  },
  {
    field: 'courseCode',
    headerName: 'Course Code',
    flex: 1
    // filterOperators: allowedGridFilterOperators
  },
  {
    field: 'weekStartDate',
    headerName: 'Week Starting',
    flex: 1
    // filterOperators: allowedGridFilterOperators
  }
];
