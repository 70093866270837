import * as React from 'react';
import { Box, Card, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Styled = {
  Card: styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    height: '230px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'unset',
      maxWidth: '280px'
    }
  })),
  Image: styled(Box)(({ theme }) => ({
    minWidth: '280px',
    '& .image': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      height: '150px'
    }
  })),
  Content: styled(Box)(({ theme }) => ({
    flexShrink: 1,
    minWidth: '280px',
    padding: '24px 32px 20px 24px',
    '& .title': {
      marginBottom: '15px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '273px'
    }
  })),
  Text: styled(Typography)({
    fontSize: '16px',
    lineHeight: '20px'
  })
};

interface CardProps {
  image: string;
  title: string;
  text: string;
  bgColor?: string;
}

const BasicCardWithLeftImage: React.FC<CardProps> = ({
  image,
  title,
  text,
  bgColor = 'white'
}) => {
  return (
    <Styled.Card data-testid="basic-card-root">
      <Styled.Image>
        <img src={image} alt={`${title} card`} className="image" />
      </Styled.Image>
      <Styled.Content sx={{ backgroundColor: bgColor }}>
        <Typography
          variant="EC_TYPE_XL"
          data-testid="card-title"
          className="title"
        >
          {title.toUpperCase()}
        </Typography>
        <Styled.Text variant="EC_TYPE_BASE" data-testid="card-test">
          {text}
        </Styled.Text>
      </Styled.Content>
    </Styled.Card>
  );
};

export default BasicCardWithLeftImage;
