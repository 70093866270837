import * as React from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  List,
  Popper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import SearchBar, { SearchBarProps } from '@components/SearchBar';
import {
  SortButton,
  TableSortBreadcrumbProps
} from '@interfaces/search.interfaces';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import TableSortBreadcrumb from '@components/TableComponents/TableSortBreadcrumb';
import TableSortButton from '@components/TableComponents/TableSortButton';
import { useTableSortContext } from '@common/context/tableSortContext';

const Styled = {
  EmptyRowsBox: styled(Box)({
    position: 'absolute',
    minWidth: '360px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }),
  Header: styled(Stack, {
    shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'styledHeader'
  })<{ disabled: boolean; styledHeader: boolean }>(
    ({ disabled, styledHeader, theme }) => ({
      justifyContent: 'space-between',
      alignItems: 'center',
      '& i': {
        color: disabled ? theme.palette.GRAY_2.main : theme.palette.BLACK.main
      },
      '& .error-text': {
        marginLeft: '30px',
        color: theme.palette.error.main
      },
      ...(styledHeader && {
        backgroundColor: theme.palette.gold1.main,
        border: `1px solid ${theme.palette.GRAY_1.main}`,
        borderBottom: 'none',
        padding: '10px 18px'
      })
    })
  ),
  FilterIcon: styled(FilterListIcon)({
    cursor: 'pointer'
  }),
  PopperBox: styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.WHITE.main,
    border: `1px solid ${theme.palette.GRAY_2.main}`,
    borderRadius: '4px',
    overflow: 'hidden',
    padding: '16px'
  }))
};

export interface TableSortHeaderCta {
  text: string;
  path: string;
  startIcon: string;
  dataTestId: string;
}

interface Props {
  title?: string;
  buttons?: SortButton[];
  disabled: boolean;
  breadcrumb?: TableSortBreadcrumbProps;
  searchBarProps?: SearchBarProps;
  styledHeader?: boolean;
  errorText?: string;
  cta?: TableSortHeaderCta;
}

const TableSortHeader: React.FC<Props> = ({
  title,
  buttons,
  disabled,
  breadcrumb,
  searchBarProps,
  styledHeader = false,
  errorText,
  cta
}) => {
  const {
    sortBy,
    sortOrder,
    sortMenuOpen,
    sortMenuAnchor,
    handleSortMenuOpen,
    handleClickAway,
    handleSortClick
  } = useTableSortContext();

  const theme = useTheme();

  const popperId = sortMenuOpen ? 'sort-menu' : undefined;
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {cta && isSmall && <HeaderCta cta={cta} isMobile={isSmall} />}
      <Styled.Header
        direction="row"
        data-testid="title-section"
        disabled={disabled}
        styledHeader={styledHeader}
      >
        {breadcrumb ? (
          <TableSortBreadcrumb
            location={breadcrumb.location}
            prevLocation={breadcrumb.prevLocation}
            href={breadcrumb.href}
          />
        ) : title ? (
          <Typography variant="EC_TYPE_LG" className="title">
            {title}
          </Typography>
        ) : null}
        {errorText && (
          <Typography variant="EC_TYPE_2XS" className="error-text">
            {errorText}
          </Typography>
        )}
        {searchBarProps && <SearchBar {...searchBarProps} />}
        <Box sx={{ marginLeft: 'auto' }}>
          {cta && !isSmall && <HeaderCta cta={cta} />}
          {buttons && (
            <IconButton
              aria-describedby={popperId}
              data-testid="sort-btn"
              onClick={handleSortMenuOpen}
              disabled={disabled}
              sx={{ marginLeft: 'auto' }}
            >
              <i className="ri-arrow-up-down-line" />
            </IconButton>
          )}
        </Box>
      </Styled.Header>
      {buttons && (
        <Popper
          id={popperId}
          open={sortMenuOpen}
          anchorEl={sortMenuAnchor}
          placement="bottom-end"
          data-testid="sort-menu"
        >
          <ClickAwayListener onClickAway={handleClickAway}>
            <Styled.PopperBox data-testid="popper-box">
              <Typography variant="EC_TYPE_2XL">Sort by</Typography>
              <List
                data-testid="sort-menu-list"
                disablePadding
                aria-label="sort-options"
                sx={{ width: '100%', maxWidth: '200px' }}
              >
                {buttons.map((btn) => (
                  <TableSortButton
                    key={btn.id}
                    label={btn.label}
                    id={btn.id}
                    onSortClick={handleSortClick}
                    isActive={sortBy === btn.id}
                    sortOrder={sortOrder}
                  />
                ))}
              </List>
            </Styled.PopperBox>
          </ClickAwayListener>
        </Popper>
      )}
    </>
  );
};

export default TableSortHeader;

const HeaderCta: React.FC<{ cta: TableSortHeaderCta; isMobile?: boolean }> = ({
  cta,
  isMobile
}) => {
  return (
    <Button
      className="ga-cta"
      variant="contained"
      startIcon={<i className={cta?.startIcon} />}
      data-testid={cta?.dataTestId}
      to={cta?.path}
      component={RouterLink}
      sx={{
        marginBottom: isMobile ? '16px' : '0px',
        marginRight: isMobile ? '0px' : '16px',
        '& .MuiButton-startIcon > i': { color: 'white' },
        padding: isMobile ? undefined : '6px 12px'
      }}
    >
      {cta?.text}
    </Button>
  );
};
