import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes, styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Styled = {
  Card: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'selected'
  })<{ selected: boolean }>(({ selected }) => ({
    display: 'flex',
    width: '100%',
    height: '111px',
    maxHeight: '111px',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    borderRadius: '4px',
    overflow: 'hidden',
    animation: `${fade} 1s linear`,
    outline: !selected
      ? `1px solid ${theme.palette.GRAY_3.main}`
      : `3.5px solid ${theme.palette.GOLD_1.main}`,
    transition: 'box-shadow 0.2s linear',
    '&:hover': {
      boxShadow: '0 0 12px #333333'
    }
  })),
  TitleBox: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'selected'
  })<{ selected: boolean }>(({ selected }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '32px',
    paddingRight: '6px',
    overflow: 'hidden',
    width: '100%',
    height: '111px',
    backgroundColor: selected ? '#FFFFFF' : theme.palette.GRAY_1.main,
    whiteSpace: 'normal',
    ['@media (max-width:1000px)']: {
      paddingLeft: '18px'
    },
    '& .result': {
      color: theme.palette.GOLD_1.main
    },
    '& .result-logo': {
      position: 'relative',
      top: '3px',
      marginRight: '16px'
    }
  })),
  PercentBox: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'bgColor'
  })<{ bgColor: string }>(({ bgColor }) => ({
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    width: '124px',
    flexShrink: 0,
    height: '100%',
    backgroundColor: bgColor,
    '& .percent, & .percent-number': {
      lineHeight: '90px',
      color: '#FFFFFF'
    },
    '& .percent-number': {
      fontSize: '70px'
    },
    '& .percent': {
      fontSize: '36px',
      fontWeight: 700
    }
  }))
};

export interface QuizResultCardProps {
  result: string;
  percentage: number;
  maxPercent: number;
  selected: boolean;
  handleSelect: () => void;
}

const QuizResultCard = (props: QuizResultCardProps): React.ReactElement => {
  const { result, percentage, maxPercent, selected, handleSelect } = props;

  const displayPercent = Math.floor(percentage);

  const bgColor = (value: number): string => {
    if (selected) return theme.palette.GOLD_1.main;
    const highest = maxPercent - maxPercent * 0.2;
    const mid = maxPercent - maxPercent * 0.8;
    // greater than 15% of max
    if (value >= highest) {
      return theme.palette.gold3.main;
    } else if (value < highest && value >= mid) {
      return theme.palette.gold2.main;
    } else {
      return '#E4CA9C';
    }
  };

  return (
    <Styled.Card
      data-testid="result-card-root"
      onClick={handleSelect}
      selected={selected}
    >
      <Styled.TitleBox selected={selected}>
        <Typography
          data-testid="card-result"
          variant="EC_TYPE_2XL"
          className="result"
        >
          {result}
        </Typography>
      </Styled.TitleBox>
      <Styled.PercentBox
        data-testid="result-percentage"
        bgColor={bgColor(displayPercent)}
      >
        <Typography variant="EC_TYPE_4XL" className="percent-number">
          {displayPercent}
        </Typography>
        <Typography variant="EC_TYPE_2XL" className="percent">
          %
        </Typography>
      </Styled.PercentBox>
    </Styled.Card>
  );
};

export default QuizResultCard;
