import { api } from '@api/configs/base';
import { apiConfig } from '@api/configs/api.config';
import { ApiResponse } from '@api/models/base.models';
import { CareerProfile } from '@interfaces/careerProfile.interfaces';
import { Partner } from '@api/models/partnerApi.models';
import { PartnerEmployeeCareerInfo } from '@interfaces/careerJourney.interfaces';
import { PartnerEmployeeProfileRequest } from './models/partnerEmployeeApi.models';

export class PartnerApi {
  static async getPartners(): Promise<Partner[]> {
    try {
      const response = await api.get<ApiResponse<Partner[]>>(
        `${apiConfig.partnersUrl}/sign-in/`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for PartnerApi.getPartners(): ${error}`);
      throw error;
    }
  }

  static async createPartnerEmployee(
    data: Partial<PartnerEmployeeProfileRequest>
  ): Promise<void> {
    try {
      await api.post<ApiResponse<PartnerEmployeeProfileRequest>>(
        `${apiConfig.partnersUrl}/employees`,
        data
      );
      return;
    } catch (error: any) {
      console.error(`Error for PartnerApi.createPartnerEmployee(): ${error}`);
      throw error;
    }
  }

  static async getAllPartnerEmployees(): Promise<CareerProfile[]> {
    try {
      const response = await api.get(`${apiConfig.partnersUrl}/employees`);
      return response.data.data;
    } catch (error: any) {
      console.error(`Error for PartnerApi.getAllPartnerEmployees(): ${error}`);
      throw error;
    }
  }

  static async getPartnerEmployeeByKeycloakId(
    userId: string
  ): Promise<ApiResponse<CareerProfile>> {
    try {
      const response = await api.get(
        `${apiConfig.partnersUrl}/employees/user/${userId}`
      );
      return response.data;
    } catch (error: any) {
      console.error(
        `Error for PartnerApi.getPartnerEmployeeByKeycloakId(): ${error}`
      );
      throw error;
    }
  }

  static async getPartnerEmployeeById(
    id: number
  ): Promise<ApiResponse<CareerProfile>> {
    try {
      const response = await api.get(
        `${apiConfig.partnersUrl}/employees/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.error(`Error for PartnerApi.getPartnerEmployeeById(): ${error}`);
      throw error;
    }
  }

  static async updatePartnerEmployee(
    partnerEmployeeId: number,
    data: PartnerEmployeeProfileRequest
  ): Promise<ApiResponse<PartnerEmployeeProfileRequest>> {
    try {
      const response = await api.put(
        `${apiConfig.partnersUrl}/employees/${partnerEmployeeId}`,
        data
      );
      return response.data;
    } catch (error: any) {
      console.error(`Error for PartnerApi.updatePartnerEmployee(): ${error}`);
      throw error;
    }
  }

  static async getCareerInfo(
    partnerEmployeeId: number
  ): Promise<ApiResponse<PartnerEmployeeCareerInfo>> {
    try {
      const response = await api.get(
        `${apiConfig.partnersUrl}/employees/${partnerEmployeeId}/careers`
      );
      return response.data;
    } catch (error: any) {
      console.error(`Error for PartnerApi.getCareerInfo(): ${error}`);
      throw error;
    }
  }

  static async setCareerGoal(
    partnerEmployeeId: number,
    careerData: { careerRoleId: number }
  ): Promise<ApiResponse<{ partnerEmployeeId: number }>> {
    try {
      const response = await api.put(
        `${apiConfig.partnersUrl}/employees/${partnerEmployeeId}/goal`,
        careerData
      );
      return response.data;
    } catch (error: any) {
      console.error(`Error for PartnerApi.setCareerGoal(): ${error}`);
      throw error;
    }
  }

  static async removeCareerGoal(
    partnerEmployeeId: number
  ): Promise<ApiResponse<{ partnerEmployeeId: number }>> {
    try {
      const response = await api.put(
        `${apiConfig.partnersUrl}/employees/${partnerEmployeeId}/goal/remove`
      );
      return response.data;
    } catch (error: any) {
      console.error(`Error for PartnerApi.removeCareerGoal(): ${error}`);
      throw error;
    }
  }

  static async saveGlrcVideo(videoId: number): Promise<void> {
    try {
      console.log('save videoId', videoId);

      // const response = await api.post<ApiResponse<void>>(
      // `${apiConfig.partnersUrl}/glrc/list/${videoId}`,
      // );

      // return response.data.data;

      return;
    } catch (error: any) {
      console.error(`Error for PartnerApi.saveGlrcVideo(): ${error}`);
      throw error;
    }
  }

  static async removeGlrcVideo(videoId: number): Promise<void> {
    try {
      console.log('delete videoId', videoId);

      // const response = await api.delete<ApiResponse<void>>(
      // `${apiConfig.partnersUrl}/glrc/list/${videoId}`,
      // );

      // return response.data.data;

      return;
    } catch (error: any) {
      console.error(`Error for PartnerApi.removeGlrcVideo(): ${error}`);
      throw error;
    }
  }
}
