import * as React from 'react';
import { Box } from '@mui/material';

interface Props {
  bottomMargin: string;
  wrapperTopMargin?: string;
  dataTestId?: string;
}

const BottomMarginContentWrapper: React.FC<Props> = ({
  bottomMargin,
  wrapperTopMargin,
  children,
  dataTestId
}) => {
  return (
    <Box
      sx={{
        '& > *:not(:last-child)': {
          marginBottom: bottomMargin,
          marginTop: wrapperTopMargin ?? 0
        }
      }}
      data-testid={dataTestId}
    >
      {children}
    </Box>
  );
};

export default BottomMarginContentWrapper;
