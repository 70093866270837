import * as React from 'react';
import {
  Approver,
  ExternshipStatus,
  ExternshipWithoutTimesheets,
  StudentViewExternship
} from '@api/models/externshipApi.models';
import {
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { FormProvider, useForm, ValidateResult } from 'react-hook-form';
import { AddManagerOrSupervisorPayload } from '@api/models/externshipProposalApi.models';
import BasicModal from '@components/BasicModal';
import { emailPattern } from '@common/regex/email';
import { EmployerProfile } from '@api/models/employerApi.models';
import { ExternshipApi } from '@api/Externships.api';
import FormFieldErrorText from '@components/Forms/FormFieldErrorText';
import ReactHookFormsAutoCompleteSingle from '@components/Forms/ReactHookFormsAutoCompleteSingle';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import ReactHookFormsInputPhoneMask from '@components/Forms/ReactHookFormsInputPhoneMask';
import { useFetchEmployers } from '@common/fetches/useFetchEmployers';
import { useStudentViewContext } from '@common/context/studentViewContext';
import { useToast } from '@components/Toast';

const getEmployers = (externship: ExternshipWithoutTimesheets, existingSupervisorsEmails?: string[]): EmployerProfile[] => {
  if (externship.company) {
    const { employers: companyEmployers } = useFetchEmployers(
      externship.company.id
    );

    // Removing any supervisor if already assigned to the externship
    return companyEmployers?.filter((companyEmployer: EmployerProfile) => {
      return !existingSupervisorsEmails?.includes(companyEmployer.email.toLowerCase()) &&
        externship.manager?.email.toLowerCase() !== companyEmployer.email.toLowerCase();
    }) || [];
  }

  return [];
};
interface Props {
  open: boolean;
  closeModal: () => void;
  externship: ExternshipWithoutTimesheets;
}

const AddApprover: React.FC<Props> = ({ open, externship, closeModal }) => {
  const { setExternships } = useStudentViewContext();

  const defaultValues: Approver = {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    jobTitle: '',
  };

  const methods = useForm<Approver>({
    defaultValues, mode: 'onChange'
  });

  const {
    watch,
    setValue,
    clearErrors,
  } = methods;

  const [isManager, setIsManager] = React.useState<boolean>(false);
  const [disableActions, setDisableActions] = React.useState(false);
  const [errorMsg, setErrMsg] = React.useState('');
  const [existingEmployerSelected, setExistingEmployerSelected] = React.useState<EmployerProfile | null>();
  const [externshipManagerExists, setExternshipManagerExists ] = React.useState<boolean>(false);

  const emailInput = watch('email');
  const { openToast } = useToast();

  const existingSupervisorsEmails = externship?.supervisors
    ?.filter((s) => !s.removeDate)
    .map((supervisor) => supervisor.employer.email.toLowerCase());

  // Companies that are brand new to EC will not have a company_entity during PENDING status
  // since we are waiting for the manager to create their account/company
  const employers: EmployerProfile[] = getEmployers(externship, existingSupervisorsEmails);

  // Employers list for the autocomplete dropdown
  const employersOptions = React.useMemo(() => {
    if (!employers) return []; // Return an empty array if employers is undefined
    return employers.map((employer) => ({
      label: `${employer.firstName} ${employer.lastName} - ${employer.email}`,
      value: employer.email
    }));
  }, [employers]);

  // Setting form fields if approver is existing employer
  React.useEffect(() => {
    const employerFound = employers.find((employer: EmployerProfile) => {
      return employer.email.toLowerCase() === emailInput;
    });

    if (employerFound) {
      clearErrors();
      setExistingEmployerSelected(employerFound);

    } else {
      setExistingEmployerSelected(null);
    }

    setValue('firstName', employerFound?.firstName || '');
    setValue('lastName', employerFound?.lastName || '');
    setValue('jobTitle', employerFound?.jobTitle || '');
    setValue('phoneNumber', employerFound?.phone || '');

  }, [emailInput, existingEmployerSelected, setExistingEmployerSelected]);

  // Checking if externship has a manager
  React.useEffect(() => {
    setExternshipManagerExists(!!externship.manager || !!externship.proposedCompany.manager);
  }, [externship]);

  const clearModal = (): void => {
    setErrMsg('');
    closeModal();
  };

  const onValid = async (data: Approver): Promise<void> => {
    setDisableActions(true);
    setErrMsg('');

    // Start Request
    const payload: AddManagerOrSupervisorPayload = data;
    const externshipId = externship.id.toString();
    try {
      if (isManager) {
        const res = await ExternshipApi.addManager(externshipId, payload);

        const externshipMapperForManager = (
          e: StudentViewExternship
        ): StudentViewExternship => {
          if (e.id === res.id) {
            return {
              ...e,
              status: res.status,
              proposedCompany: {
                ...e.proposedCompany,
                manager: res.proposedCompany.manager
              },
              manager: res.manager,
              managerActive: res.managerActive
            };
          }
          return { ...e };
        };

        setExternships((prev) => prev.map(externshipMapperForManager));
      } else {
        const res = await ExternshipApi.addSupervisor(externshipId, payload);

        const externshipMapperForSupervisors = (
          e: StudentViewExternship
        ): StudentViewExternship => {
          if (e.proposedCompany?.id === res.proposedCompany.id) {
            // Set eligibleCoursesForRenewal to [] to prevent renewing externship with incomplete proxy
            return {
              ...e,
              status: res.externshipStatus,
              proposedCompany: res.proposedCompany,
              supervisors: res.supervisors,
              eligibleCoursesForRenewal: []
            };
          }
          return { ...e };
        };

        setExternships((prev) => prev.map(externshipMapperForSupervisors));
      }
      const successMsg =
        externship.status === ExternshipStatus.PENDING
          ? 'Approver added successfully.'
          : `Invite for ${data.email} was sent successfully.`;
      openToast(successMsg);
      closeModal();
    } catch (error: any) {
      console.error('Error: AddSupervisor -> ExternshipApi.addSupervisor()');
      if (error?.response?.data?.message) {
        setErrMsg(error?.response?.data?.message);
      }
    } finally {
      setDisableActions(false);
      setExistingEmployerSelected(null);
    }
  };

  return (
    <BasicModal
      open={open}
      handleClose={clearModal}
      title="Add an Approver"
      primaryAction={{
        label: 'Send',
        action: methods.handleSubmit(onValid),
        disabled: disableActions
      }}
      secondaryAction={{
        label: 'Cancel',
        action: clearModal,
        disabled: disableActions
      }}
      maxWidth="sm"
    >
      <FormProvider {...methods}>
        <Typography variant="EC_TYPE_BASE" mb={4}>
          Add an existing user or enter an email to send an invite to join
          Escoffier.
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ReactHookFormsAutoCompleteSingle
              labelVariant="EC_TYPE_SM"
              name="email"
              openOnFocus={false}
              disablePortal
              freeSolo
              selectOnFocus
              blurOnSelect
              options={employersOptions}
              placeholder="Search Approver or type a email"
              rules={{
                validate: (value: string): ValidateResult => {
                  // Equal to `required`
                  if (!value) return 'Email is required';

                  // Equal to `pattern`
                  if (!emailPattern.test(value)) return 'Please enter a valid email';

                  // Custom validator
                  const existingSupervisor = existingSupervisorsEmails?.includes(value.toLowerCase());
                  const existingManager = externship.manager?.email.toLowerCase() === value.toLowerCase();
                  if (existingSupervisor || existingManager) {
                    return `Email is already assigned to the externship's ${existingManager ? 'manager' : 'supervisor'}`;
                  }
                  return true;
                },
              }}
            />
          </Grid>
          <React.Fragment>
            <Grid item xs='auto' alignContent={'center'}>
              <FormLabel id="add-approver-is-manager-radio-buttons-group">Approver type</FormLabel>
            </Grid>
            <Grid item xs={6} textAlign={'left'}>
              <RadioGroup
                row
                aria-labelledby="add-approver-is-manager-radio-buttons-group"
                name='isManager'
                value={isManager ? 'manager' : 'supervisor'}
                onChange={(_event, selectedValue): void => {
                  setIsManager(selectedValue === 'manager' ? true : false);
                }}
              >
                <FormControlLabel
                  label="Supervisor"
                  value="supervisor"
                  control={<Radio />}
                />
                <FormControlLabel
                  label="Manager"
                  value="manager"
                  disabled={!!externshipManagerExists}
                  control={<Radio />}
                />
              </RadioGroup>
            </Grid>
          </React.Fragment>
          <React.Fragment>
            <Grid item xs={12} sm={6}>
              <ReactHookFormsInput
                disabled={!!existingEmployerSelected}
                dataTestId="add-approver-first-name-input"
                placeholder="First Name"
                name="firstName"
                rules={{
                  required: 'First Name is required'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ReactHookFormsInput
                disabled={!!existingEmployerSelected}
                dataTestId="add-approver-last-name-input"
                placeholder="Last Name"
                name="lastName"
                rules={{
                  required: 'Last Name is required'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ReactHookFormsInputPhoneMask
                name="phoneNumber"
                disabled={!!existingEmployerSelected}
                placeholder="Phone Number"
                dataTestId="add-approver-personal-phone-number-input"
                rules={{
                  required: 'Phone number is required'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ReactHookFormsInput
                disabled={!!existingEmployerSelected}
                dataTestId="add-approver-job-title-input"
                placeholder="Job Title"
                name="jobTitle"
                rules={{
                  required: 'Job title is required'
                }}
              />
            </Grid>
          </React.Fragment>
          {errorMsg && (
            <FormFieldErrorText
              message={errorMsg}
              disableAbsolutePosition
              name={`add-approver-${externship.id}`}
            />
          )}
        </Grid>
      </FormProvider>
    </BasicModal>
  );
};

export default AddApprover;
