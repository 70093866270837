import * as React from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import FormActionsComponent, {
  FormActionProps
} from '@components/Forms/FormActionsComponent';
import FormWrapper from '@components/FormWrapper';
import PageDescriptionComponent from '@components/Layout/Common/PageDescriptionComponent';
import PageFormLayoutComponent from '@components/Layout/PageFormLayoutComponent';

interface Props<FormValues extends FieldValues> {
  content: React.ReactNode;
  formActions: FormActionProps;
  methods: UseFormReturn<FormValues, any>;
}

const ExternshipProposalStepLayout = <FormValues extends FieldValues>({
  content,
  formActions,
  methods
}: Props<FormValues>): React.ReactElement => {
  return (
    <PageFormLayoutComponent
      sideContent={
        <PageDescriptionComponent title="Externship Proposal">
          Tell us about your potential employer. When they verify the
          information, you can start logging your hours.
          <br />
          <br />
          If your&apos;e unable to find your externship company by name, please
          search by street address and enter the company name in the Externship
          Site Name field.
        </PageDescriptionComponent>
      }
      content={
        <FormWrapper>
          <FormProvider {...methods}>{content}</FormProvider>
        </FormWrapper>
      }
      actions={<FormActionsComponent {...formActions} />}
    />
  );
};

export default ExternshipProposalStepLayout;
