/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  Button,
  ButtonProps,
  Link,
  SxProps,
  Theme,
  Typography,
  TypographyProps,
  useTheme
} from '@mui/material';
import GlobalSolutionsModal from './GlobalSolutionsModal';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'iconSize' && prop !== 'iconStyle'
})<{ iconSize?: string; iconStyle?: React.CSSProperties }>(
  ({ iconSize, iconStyle }) => ({
    '& .MuiButton-startIcon>*:nth-of-type(1), & .MuiButton-endIcon>*:nth-of-type(1)':
      {
        fontSize: iconSize,
        lineHeight: iconSize
      },
    '& .MuiButton-startIcon': {
      ...iconStyle
    }
  })
);

export interface CtaButtonProps extends ButtonProps {
  to?: string;
  label: string;
  externalLink?: string;
  routeState?: any;
  underline?: 'none' | 'hover' | 'always';
  btnVariant?: 'text' | 'contained' | 'outlined';
  textVariant?: TypographyProps['variant'];
  textColor?: string;
  sx?: SxProps<Theme>;
  btnSx?: React.CSSProperties;
  textSx?: SxProps<Theme>;
  startIconClass?: string;
  endIconClass?: string;
  leftArrow?: boolean;
  rightArrow?: boolean;
  iconSize?: string;
  iconStyle?: React.CSSProperties;
  dataTestId?: string;
  fontWeight?: number;
}

const CtaButton = ({
  to = '',
  label,
  routeState,
  underline = 'none',
  btnVariant = 'contained',
  textVariant = 'EC_TYPE_BASE',
  textColor,
  sx,
  btnSx,
  textSx,
  startIconClass,
  endIconClass,
  leftArrow = false,
  rightArrow = false,
  iconSize,
  iconStyle,
  externalLink,
  dataTestId = 'cta-button-default',
  fontWeight = 700,
  ...buttonProps
}: CtaButtonProps): React.ReactElement => {
  const theme = useTheme();

  const [externalEduLink, setExternalEduLink] = React.useState<string>();

  const getTextColor: string =
    btnVariant === 'contained'
      ? theme.palette.primary.contrastText
      : theme.palette.primary.main;

  const buttonStyle: React.CSSProperties =
    btnVariant === 'text' || buttonProps.variant === 'text'
      ? {
          padding: '0 4px',
          width: 'max-content',
          height: 'unset',
          transform: 'translateX(-4px)'
        }
      : { padding: '12px 24px' };

  const startIcon = leftArrow
    ? 'ri-arrow-left-line'
    : startIconClass ?? undefined;

  const endIcon = rightArrow
    ? 'ri-arrow-right-line'
    : endIconClass ?? undefined;

  const getExternalLinkProps = (link: string): any => {
    if (link.includes('www.escoffier.edu')) {
      return {
        onClick: (): void => setExternalEduLink(link)
      };
    } else {
      return {
        href: externalLink,
        target: '_blank',
        rel: 'noopener noreferrer'
      };
    }
  };

  const linkProps = externalLink
    ? getExternalLinkProps(externalLink)
    : { component: RouterLink, to: { pathname: to, state: routeState } };

  return (
    <>
      <Link
        {...linkProps}
        underline={underline}
        sx={sx}
        data-testid={dataTestId}
      >
        <StyledButton
          variant={btnVariant}
          sx={{ ...buttonStyle, ...btnSx, ':hover': { cursor: 'pointer' } }}
          startIcon={startIcon ? <i className={startIcon} /> : undefined}
          endIcon={endIcon ? <i className={endIcon} /> : undefined}
          iconSize={iconSize}
          iconStyle={iconStyle}
          className="ga-cta"
          {...buttonProps}
        >
          <Typography
            variant={textVariant}
            color={textColor ?? getTextColor}
            fontWeight={fontWeight}
            sx={textSx}
          >
            {label}
          </Typography>
        </StyledButton>
      </Link>
      <GlobalSolutionsModal
        open={!!externalEduLink}
        handleClose={(): void => setExternalEduLink(undefined)}
        externalLink={externalEduLink || ''}
      />
    </>
  );
};

export default CtaButton;
