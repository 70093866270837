import * as React from 'react';
import CareerPathNode from '@pages/Candidate/CareerPathPage/MyCareerGoal/CareerPathNode';
import { Grid } from '@mui/material';
import { mediaQueries } from '@themes/ui/variables';
import { styled } from '@mui/system';
import TabPanel from '@mui/lab/TabPanel';
import { useExploreMoreContext } from '@pages/Candidate/CareerPathPage/ExploreMore/CareerPathExploreMoreCard';

const Styled = {
  Container: styled(Grid)({
    marginTop: '24px',
    padding: '0 12px 32px 24px',
    [mediaQueries.MIN_WIDTH_481]: {
      paddingLeft: '32px'
    },
    [mediaQueries.MIN_WIDTH_601]: {
      marginTop: 0,
      paddingLeft: '24px'
    }
  }),
  TabPanel: styled(TabPanel)({
    padding: '0 4px 0 20px'
  })
};

const CareerPathExploreMoreListLayout = (): React.ReactElement => {
  const {
    careerRoles,
    savedGoal,
    expandedTrackRole,
    getStatus,
    setExpandedTrackRole,
    setCareerGoal
  } = useExploreMoreContext();

  return (
    <Styled.TabPanel value="1">
      <Styled.Container
        data-testid="explore-more-list-layout-container"
        container
        spacing={2}
      >
        {careerRoles.map((role, idx) => {
          const status = getStatus(role);
          const isSavedGoal: boolean = savedGoal
            ? savedGoal.id === role.id
            : false;

          return (
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              key={idx}
              data-testid="explore-more-list-node-box"
            >
              <CareerPathNode
                smallNodes
                displayVertical
                index={idx}
                status={status}
                role={role}
                isSavedGoal={isSavedGoal}
                expandedRole={expandedTrackRole}
                setExpandedRole={setExpandedTrackRole}
                setCareerGoal={setCareerGoal}
              />
            </Grid>
          );
        })}
      </Styled.Container>
    </Styled.TabPanel>
  );
};

export default CareerPathExploreMoreListLayout;
