import * as React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { JobCard, JobPostType } from '@api/models/singleJobPostApi.models';
import { Badge } from '@common/mappings/BadgeMappings';
import { getCityState } from '@common/helpers/location';
import IconWithLabel from '@components/IconWithLabel';
import { Link } from 'react-router-dom';
import { Location } from '@interfaces/location.interfaces';
import MainCardContent from '@components/JobMarketplace/JobMarketplaceCard/MainCardContent';
import { styled } from '@mui/system';
import WorkLearnIcon from '@components/WorkLearnIcon';

interface LocationSectionProps {
  locations: Location[];
  isDirectConnectPost: boolean;
}

const Styled = {
  Link: styled(Link)({
    textDecoration: 'none'
  }),
  Card: styled(Box, {
    shouldForwardProp: (prop) =>
      prop !== 'isDirectConnectPost' && prop !== 'useBoxShadow'
  })<{ isDirectConnectPost?: boolean; useBoxShadow: boolean }>(
    ({ useBoxShadow, theme }) => ({
      display: 'flex',
      flexDirection: 'column',
      height: '245px',
      border: `1px solid ${theme.palette.GOLD_1.main}`,
      borderRadius: '4px',
      overflow: 'hidden',
      cursor: 'pointer',
      backgroundColor: theme.palette.WHITE.main,
      ...(useBoxShadow && {
        boxShadow: '0 4px 4px 0 rgba(0,0,0,0.25)'
      })
    })
  ),
  TopSection: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isDirectConnectPost'
  })<{ isDirectConnectPost?: boolean }>(({ isDirectConnectPost, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: isDirectConnectPost
      ? theme.palette.GOLD_1.main
      : theme.palette.GRAY_1.main,
    padding: '7px 20px 9px',
    borderBottom: `1px solid ${theme.palette.GOLD_1.main}`,
    minHeight: '57px',
    '& .title-section': {
      display: 'flex',
      overflow: 'hidden',
      alignItems: 'center',
      '& .job-title': {
        color: isDirectConnectPost
          ? theme.palette.WHITE.main
          : theme.palette.primary.main,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    }
  })),
  LocationsWrappper: styled(Box)({
    display: 'flex',
    gap: '8px'
  })
};

const LocationSection = ({
  locations,
  isDirectConnectPost
}: LocationSectionProps): React.ReactElement => {
  const theme = useTheme();

  if (isDirectConnectPost) {
    return (
      <IconWithLabel
        color={theme.palette.WHITE.main}
        icon="ri-map-pin-2-fill"
        title={
          locations.length > 1
            ? 'Multiple Locations'
            : getCityState(locations[0])
        }
        smallest
      />
    );
  }

  // Non Direct Connect Post
  if (locations.length > 2) {
    return (
      <IconWithLabel
        icon="ri-map-pin-2-fill"
        title="Multiple Locations"
        smallest
      />
    );
  } else {
    return (
      <>
        {locations.map((loc, idx) => (
          <IconWithLabel
            key={idx}
            icon="ri-map-pin-2-fill"
            title={getCityState(loc)}
            smallest
          />
        ))}
      </>
    );
  }
};

interface Props extends JobCard {
  badge?: Badge;
  useBoxShadow?: boolean;
}

const JobMarketplaceCard = (props: Props): JSX.Element => {
  const theme = useTheme();

  const {
    id,
    jobTitle,
    locations,
    companyName,
    workLearnEligible,
    jobPostType,
    useBoxShadow = false
  } = props;

  const isDirectConnectPost = jobPostType === JobPostType.DIRECT_CONNECT;

  const getCardTitle = (): string => {
    if (isDirectConnectPost) {
      return companyName;
    }

    return jobTitle;
  };

  return (
    <Styled.Link to={`/job/${id}`} target="_blank">
      <Styled.Card
        data-testid="jobSingleCardContainer"
        isDirectConnectPost={isDirectConnectPost}
        useBoxShadow={useBoxShadow}
      >
        <Styled.TopSection isDirectConnectPost={isDirectConnectPost}>
          <Box className="title-section">
            <Typography variant="EC_TYPE_SM" className="job-title">
              {getCardTitle()}
            </Typography>
            <WorkLearnIcon
              display={workLearnEligible}
              sx={{
                width: '20px',
                height: '20px',
                marginLeft: '6px'
              }}
            />
          </Box>
          <Styled.LocationsWrappper>
            {isDirectConnectPost && (
              <Typography
                variant="EC_TYPE_2XS"
                color={theme.palette.GRAY_1.main}
              >
                DIRECT CONNECT{!!locations.length && ':'}
              </Typography>
            )}
            {!!locations.length && (
              <LocationSection
                locations={locations}
                isDirectConnectPost={isDirectConnectPost}
              />
            )}
          </Styled.LocationsWrappper>
        </Styled.TopSection>
        <MainCardContent isDirectConnectPost={isDirectConnectPost} {...props} />
      </Styled.Card>
    </Styled.Link>
  );
};

export default JobMarketplaceCard;
