import * as React from 'react';
import { Box, styled } from '@mui/system';
import { Typography } from '@mui/material';

const Styled = {
  Title: styled(Typography)((props: { required?: boolean }) => ({
    '&:after': {
      content: props.required ? '"*"' : '""',
      color: '#D83933',
      marginLeft: '4px'
    }
  }))
};

interface Props {
  title?: string;
  subtext?: string;
  required?: boolean;
  disableMargin?: boolean;
  wrapperId?: string;
}

const StepSubSection: React.FC<Props> = ({
  children,
  title,
  subtext,
  required,
  disableMargin = false,
  wrapperId
}) => {
  return (
    <Box id={wrapperId}>
      {title && (
        <Styled.Title
          variant="EC_TYPE_LG"
          sx={{ marginBottom: disableMargin ? 0 : '30px' }}
          required={required}
        >
          {title}
        </Styled.Title>
      )}
      {subtext && (
        <Typography
          variant="EC_TYPE_BASE"
          sx={{ marginBottom: disableMargin ? 0 : '16px' }}
        >
          {subtext}
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default StepSubSection;
