import * as React from 'react';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';

const CharCountText = styled(Typography)(({ theme }) => ({
  color: theme.palette.GRAY_3.main,
  alignSelf: 'end',
  marginTop: '5px'
}));

interface Props {
  charCount: number;
  maxCharCount: number;
}

const InputCharCount: React.FC<Props> = ({ charCount, maxCharCount }) => {
  return (
    <CharCountText variant="EC_TYPE_2XS" data-testid="input-char-count">
      &nbsp;{charCount}/{maxCharCount}
    </CharCountText>
  );
};

export default InputCharCount;
