/* eslint-disable @typescript-eslint/indent */
import { TimesheetStatus } from '@api/models/externshipApi.models';

export const getTimesheetStatusLabel = (
  status: TimesheetStatus,
  isEcAdmin?: boolean,
  displayCVueError?: boolean
): string => {
  switch (status) {
    case TimesheetStatus.CREATED:
      return 'Created';
    case TimesheetStatus.DRAFT:
      return 'Draft';
    case TimesheetStatus.ACCEPTED:
      return 'Accepted';
    case TimesheetStatus.REJECTED_BY_SUPERVISOR:
      return 'Rejected by Supervisor';
    case TimesheetStatus.REJECTED_BY_INSTRUCTOR:
      return 'Rejected by Instructor';
    case TimesheetStatus.SENT_FAILED:
      return isEcAdmin
        ? 'Sent Failed'
        : displayCVueError
        ? 'Accepted (Pending CVue Submission)'
        : 'Accepted';
    case TimesheetStatus.SENT_SUCCESSFULLY:
      return isEcAdmin ? 'Sent Successfully' : 'Accepted';
    case TimesheetStatus.SENT_UNKNOWN:
      return isEcAdmin
        ? 'Sent Unknown'
        : displayCVueError
        ? 'Accepted (Pending CVue Submission)'
        : 'Accepted';
    case TimesheetStatus.PENDING_SUPERVISOR_APPROVAL:
      return 'Pending Supervisor Approval';
    case TimesheetStatus.PENDING_INSTRUCTOR_APPROVAL:
      return 'Pending Instructor Approval';
    case TimesheetStatus.SENT_ACCEPTED_FAILURE:
      return displayCVueError ? 'Accepted (Not Sent to CVue)' : 'Accepted';
  }
};
