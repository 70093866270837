import { Location } from '@interfaces/location.interfaces';

/**
 * @param {Location} The location to convert to city state zip
 * @returns Returns a location as city state zip
 */
export const getCityStateZip = (location: Location): string => {
  let label = '';

  if (location.city) {
    label += `${label.length ? ', ' : ''}${location.city}`;
  }

  if (location.shortState) {
    label += `${label.length ? ', ' : ''}${location.shortState}`;
  }

  if (location.zip) {
    label += `${label.length ? ' ' : ''}${location.zip}`;
  }

  return label;
};
