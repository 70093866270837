export enum PdfTypes {
  SITE_AGREEMENT = 'pdf-site-agreement',
  EXTERNSHIP_AGREEMENT = 'pdf-externship-agreement',
  PROXY_AGREEMENT = 'pdf-proxy-agreement',
  STUDENT_ATTESTATION = 'pdf-student-attestation'
}

export interface PdfPostRequest {
  html: string;
}
