/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import DashboardTableLayout, {
  DashboardTableLayoutProps
} from '@components/TableComponents/DashboardTableLayout';
import {
  getCompanyPath,
  getEmployerPathWithParamsId
} from '@common/helpers/getPathWithParamsId';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import TileNavItem, {
  TileNavItemProps
} from '@components/TileNavItem/TileNavItem';
import { Toast, useToast } from '@components/Toast';
import ActionItemsSection from './ActionItemsSection';
import Box from '@mui/material/Box';
import companyDefault from '@assets/svg/employer-def.svg';
import ContactUsSection from './ContactUsSection';
import DashboardLoading from '@components/TableComponents/DashboardLoading';
import EmployerDashExternshipsSection from '@pages/Employer/EmployerDashboard/EmployerDashExternshipsSection';
import ExternshipWelcomeModal from './ExternshipWelcomeModal';
import GlrcTileImg from '@assets/EmployerDashboardPage/glrc_tile.jpg';
import { JobPost } from '@api/models/singleJobPostApi.models';
import { JobPostApi } from '@api/JobPost.api';
import JobPostingsSection from '@pages/Employer/EmployerDashboard/JobPostingsSection';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { styled } from '@mui/system';
import TalentSearchTileImg from '@assets/EmployerDashboardPage/talent_search_tile.jpg';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchCompanyProfile } from '@common/fetches/useFetchCompanyProfile';
import { useFetchExternshipsForEmployer } from '@common/fetches/useFetchExternshipsForEmployer';
import { useFetchJobPostsByCompanyId } from '@common/fetches/useFetchJobPostsByCompanyId';
import { useFetchSiteAgreementData } from '@common/fetches/useFetchSiteAgreementData';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';

const Styled = {
  ExternshipBox: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '80px'
  }),
  JoblistBox: styled(Box)({
    display: 'flex',
    flexDirection: 'column'
  })
};

const EmployerDashboard: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const featureFlags = useFeatureFlags();

  const { companyId, paramsId } = useParamsOrStoreCompanyId();
  const { isEcAdmin, isRecruiter, isEmployer } = useKeycloakContext();

  const [isEnabledCompany] = useStore(UseStoreKeys.COMPANY_ENABLED);
  const [employerId] = useStore<number>(UseStoreKeys.EMPLOYER_ID);

  const {
    jobPosts: _jobPosts,
    applicantsPerJobId,
    loading: jobPostLoading
  } = useFetchJobPostsByCompanyId(companyId, true);

  const { companyProfile, loading: profileLoading } =
    useFetchCompanyProfile(companyId);

  const { externships, setExternships } = useFetchExternshipsForEmployer(
    companyId,
    employerId,
    isEmployer,
    isRecruiter
  );

  // We should retrieve site agreement info if it should be displayed
  // should not matter if user is admin or recruiter
  const { siteAgreementData } = useFetchSiteAgreementData(
    employerId,
    isEmployer || isRecruiter
  );

  const [jobPosts, setJobPosts] = React.useState<JobPost[]>([]);

  const { openToast, ...toastProps } = useToast();

  const breadcrumb = isEcAdmin
    ? {
        href: '/admin/manage-companies',
        location: `${companyProfile?.name}'s Dashboard`
      }
    : undefined;

  const hasTalentSearchPerm =
    !!companyProfile?.partnershipLevelPermissions.TALENT_SEARCH_ENABLED &&
    featureFlags.COMPANY_PARTNERSHIP_LEVELS;

  // Set local jobPosts state since visibility updates require a state update
  React.useEffect(() => {
    if (_jobPosts) {
      setJobPosts(_jobPosts);
    }
  }, [_jobPosts]);

  const handleVisibility = async (id: number): Promise<void> => {
    // These will be defined since the only way a user would click to call this
    // function would be if there are job posts saved.
    const posts = [...jobPosts!];
    const isJobPostVisible = posts.find((post) => post.id === id)!
      .visibleOnMarketplace as boolean;

    const updatedVisibility: Pick<JobPost, 'visibleOnMarketplace'> =
      isJobPostVisible === true
        ? { visibleOnMarketplace: false }
        : { visibleOnMarketplace: true };

    try {
      await JobPostApi.updateJobPostVisibility(id, updatedVisibility);
      openToast(
        `Job number ${id} updated to ${
          updatedVisibility.visibleOnMarketplace ? 'Public' : 'Hidden'
        }`
      );
      setJobPosts((prevState: JobPost[]) =>
        prevState?.map((post: JobPost) => {
          if (post.id === id) {
            return Object.assign({}, post, {
              visibleOnMarketplace: updatedVisibility.visibleOnMarketplace
            });
          } else {
            return post;
          }
        })
      );
    } catch (error: any) {
      console.error(
        'Error for UpdateJobPostVisibility at Employer Dashboard',
        error
      );
    }
  };

  const tableLayoutProps: DashboardTableLayoutProps = {
    pageTitle: companyProfile?.name || 'Welcome',
    logo: companyProfile?.logo?.fullPath || companyDefault,
    companyEnabled: companyProfile?.enabled,
    ...(isEcAdmin && {
      ctaButton: {
        href: `/employer/${companyId}/profile-setup`,
        label: 'Edit company profile',
        icon: ''
      }
    })
  };

  const displayTalentSearch =
    featureFlags.CANDIDATE_MARKETPLACE &&
    isEnabledCompany &&
    hasTalentSearchPerm;

  const displayGlrcTile = isEcAdmin || !!companyProfile?.hasEsourceAccess;

  const talentSearchTile = {
    img: TalentSearchTileImg,
    text: 'Talent Search',
    path: getCompanyPath('/talent-search', paramsId)
  };

  const glrcTile = {
    img: GlrcTileImg,
    text: 'ESource Dashboard',
    path: getCompanyPath('/learning-center', paramsId)
  };

  const tileNavItems = React.useMemo(() => {
    const nav: TileNavItemProps[] = [];

    if (displayTalentSearch) {
      nav.push(talentSearchTile);
    }

    if (displayGlrcTile) {
      nav.push(glrcTile);
    }

    return nav;
  }, [displayTalentSearch, displayGlrcTile]);

  return (
    <RootPageLayout bgImgVariant={1}>
      {!jobPostLoading && !profileLoading ? (
        <>
          <DashboardTableLayout {...tableLayoutProps}>
            {!!tileNavItems.length && (
              <Grid
                container
                columnSpacing={4}
                rowSpacing={1}
                alignItems="stretch"
                mb="32px"
              >
                {tileNavItems.map((item) => (
                  <Grid item key={item.path} xs={12} sm={6}>
                    <TileNavItem {...item} isMobile={isMobile} />
                  </Grid>
                ))}
              </Grid>
            )}
            {companyProfile?.isExternship && <ActionItemsSection />}
            {!!externships?.length && featureFlags.EXTERNSHIPS && (
              <Styled.ExternshipBox>
                <EmployerDashExternshipsSection
                  employerId={employerId}
                  externships={externships}
                  siteAgreementData={siteAgreementData}
                  setExternships={setExternships}
                />
              </Styled.ExternshipBox>
            )}
            <Styled.JoblistBox>
              <JobPostingsSection
                ctaHref={getEmployerPathWithParamsId(
                  '/create-job-post',
                  paramsId
                )}
                tableHeader={isEcAdmin ? 'Manage Companies' : 'Job Management'}
                jobPosts={jobPosts}
                applicantsPerJobId={applicantsPerJobId}
                companyName={companyProfile?.name}
                breadcrumb={breadcrumb}
                handleVisibility={handleVisibility}
              />
            </Styled.JoblistBox>
            <ExternshipWelcomeModal />
            {companyProfile?.isExternship && <ContactUsSection />}
            <Toast {...toastProps} />
          </DashboardTableLayout>
        </>
      ) : (
        <DashboardLoading />
      )}
    </RootPageLayout>
  );
};

export default EmployerDashboard;
