import { api } from './configs/base';
import { apiConfig } from './configs/api.config';
import { ApiResponse } from './models/base.models';
import { StudentWithSchoolStatus } from './models/studentApi.models';

export class StudentApi {
  static async getStudentWithSchoolStatusByCandidateId(
    candidateId: number
  ): Promise<StudentWithSchoolStatus> {
    try {
      const response = await api.get<ApiResponse<StudentWithSchoolStatus>>(
        `${apiConfig.studentUrl}/candidate/${candidateId}`
      );

      return response.data.data;
    } catch (error) {
      console.error(
        `Error for StudentApi.getStudentWithSchoolStatusByCandidateId(): ${error}`
      );
      throw error;
    }
  }
}
