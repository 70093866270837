import * as React from 'react';
import { Grid } from '@mui/material';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import StepSection from '@components/StepSection';
import StepSubSection from '@components/StepSubSection';
import { urlPattern } from '@common/regex/url';

const SocialProfilesSection: React.FC = () => {
  return (
    <StepSection title="Social Profiles">
      <StepSubSection>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <ReactHookFormsInput
              name="websiteUrl"
              label="Website"
              placeholder="Enter website URL"
              icon={<i className="ri-global-line"></i>}
              rules={{
                pattern: {
                  value: urlPattern,
                  message: 'Please enter valid URL'
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReactHookFormsInput
              name="instagramUrl"
              label="Instagram"
              placeholder="Enter Username"
              icon={<i className="ri-instagram-fill"></i>}
              rules={{
                pattern: {
                  value: urlPattern,
                  message: 'Please enter valid URL'
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReactHookFormsInput
              name="facebookUrl"
              label="Facebook"
              placeholder="Enter URL"
              icon={<i className="ri-facebook-box-fill"></i>}
              rules={{
                pattern: {
                  value: urlPattern,
                  message: 'Please enter valid URL'
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReactHookFormsInput
              name="linkedInUrl"
              label="LinkedIn"
              placeholder="Enter profile URL"
              icon={<i className="ri-linkedin-box-fill"></i>}
              rules={{
                pattern: {
                  value: urlPattern,
                  message: 'Please enter valid URL'
                }
              }}
            />
          </Grid>
        </Grid>
      </StepSubSection>
    </StepSection>
  );
};

export default SocialProfilesSection;
