import { useEffect, useState } from 'react';
import { AdminContentManagementApi } from '@api/AdminContentManagement.api ';
import { convertGlrcContentToGlrcVideo } from '@pages/Partner/GlrcContent/glrc.helpers';
import { GlrcVideo } from '@interfaces/glrc.interfaces';

interface FetchGlrcContent {
  glrcVideos?: GlrcVideo[];
  loading: boolean;
  loaded: true | null;
  setGlrcVideos: React.Dispatch<React.SetStateAction<GlrcVideo[] | undefined>>;
  fetchGlrcVideos: () => Promise<void>;
}

export const useFetchGlrcVideosForEcAdmin = (): FetchGlrcContent => {
  const [glrcVideos, setGlrcVideos] = useState<GlrcVideo[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  const fetchGlrcVideos = async (): Promise<void> => {
    try {
      setLoading(true);

      const res = await AdminContentManagementApi.getVideosForEcAdmin();

      const videoArray = res.map((v) => convertGlrcContentToGlrcVideo(v));

      setGlrcVideos(videoArray);
    } catch (error: any) {
      console.error(
        `Error for useFetchGlrcVideos -> GlrcContentApi.getPrepared(): ${error}`
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  useEffect(() => {
    fetchGlrcVideos();
  }, []);

  return { glrcVideos, loading, loaded, setGlrcVideos, fetchGlrcVideos };
};
