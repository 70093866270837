import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const Styled = {
  Root: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'breakpoint'
  })<{ breakpoint?: number }>(({ breakpoint }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '167px',
    height: '77px',
    border: `1px solid ${theme.palette.GOLD_1.main}`,
    borderRadius: '10px',
    '& .title-text': {
      textTransform: 'uppercase',
      marginRight: '12px',
      alignSelf: 'end'
    },
    '& .counter-text': {
      color: theme.palette.GOLD_1.main,
      marginRight: '12px',
      alignSelf: 'end'
    },
    [`@media (max-width:${breakpoint}px)`]: {
      width: '125px',
      height: '58px',
      borderRadius: '4px',
      '& .title-text': {
        fontSize: theme.typography.EC_TYPE_XL.fontSize,
        lineHeight: theme.typography.EC_TYPE_XL.lineHeight
      },
      '& .counter-text': {
        fontSize: theme.typography.EC_TYPE_2XL.fontSize,
        lineHeight: theme.typography.EC_TYPE_2XL.lineHeight
      }
    }
  }))
};

export interface CounterBoxProps {
  title: string;
  count: number;
  resizeBreakpoint?: number;
}

const CounterBox: React.FC<CounterBoxProps> = ({
  title,
  count,
  resizeBreakpoint
}) => {
  return (
    <Styled.Root breakpoint={resizeBreakpoint}>
      <Typography variant="EC_TYPE_2XL" className="title-text">
        {title}
      </Typography>
      <Typography variant="EC_TYPE_4XL" className="counter-text">
        {count}
      </Typography>
    </Styled.Root>
  );
};

export default CounterBox;
