const SUB_STR = '.com';

const URL_REGEX = /([^/,\s]+\.[^/,\s]+?)(?=\/|,|\s|$|\?|#)/g;

export const getLinkedinAlias = (url?: string | null): string | undefined => {
  if (url) {
    const index = url.indexOf(SUB_STR) + 1;
    return url.slice(index + SUB_STR.length, url.lastIndexOf('/'));
  }
};

export const getFacebookInstagramAlias = (
  url?: string | null
): string | undefined => {
  if (url) {
    const index = url.indexOf(SUB_STR) + 1;
    return '@' + url.slice(index + SUB_STR.length, url.lastIndexOf('/'));
  }
};

export const getWebsiteAlias = (
  url?: string | null
): string | undefined | null => {
  if (url) {
    const arr = url.match(URL_REGEX) || [];

    return arr[0];
  }
};
