import * as React from 'react';
import { Box } from '@mui/material';
import { ReactComponent as CrownIcon } from '@assets/resumeIcons/crown.svg';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const Styled = {
  ContentWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'bgColor'
  })<{ bgColor: string }>(({ bgColor }) => ({
    backgroundColor: bgColor
  })),
  CrownWrapper: styled(Box)({
    display: 'flex',
    justifyContent: 'center'
  }),
  CrownIcon: styled(CrownIcon, {
    shouldForwardProp: (prop) => prop !== 'bgColor'
  })<{ bgColor: string }>(({ bgColor }) => ({
    width: '189.01px',
    height: '103.92px',
    display: 'block',
    marginBottom: '-3px',
    path: {
      fill: bgColor
    },
    '@media (max-width: 600px)': {
      height: '45.57px',
      width: '100px'
    }
  }))
};

interface Props {
  bgColor?: string;
}

const CrownSection: React.FC<Props> = ({
  children,
  bgColor = theme.palette.GRAY_4.main
}) => {
  return (
    <Box>
      <Styled.CrownWrapper>
        <Styled.CrownIcon bgColor={bgColor} />
      </Styled.CrownWrapper>
      <Styled.ContentWrapper bgColor={bgColor}>
        {children}
      </Styled.ContentWrapper>
    </Box>
  );
};

export default CrownSection;
