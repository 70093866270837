/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Box, Icon, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import { TableSortBreadcrumbProps } from '@interfaces/search.interfaces';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const Styled = {
  Root: styled(Box)({
    display: 'flex',
    alignItems: 'center'
  }),
  LinkText: styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    color: theme.palette.primary.main
  })),
  LocationText: styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    color: theme.palette.BLACK.main
  })),
  Arrow: styled(Icon)(({ theme }) => ({
    color: theme.palette.primary.main
  }))
};

const TableSortBreadcrumb = ({
  href,
  location,
  prevLocation
}: TableSortBreadcrumbProps): React.ReactElement => {
  const { width } = useWindowDimensions();
  const fontVariant =
    width > 900
      ? 'LG'
      : width <= 900 && width > 768
      ? 'BASE'
      : width <= 768 && width > 460
      ? 'SM'
      : 'XS';

  const iconMargin = width > 768 ? '10px' : '2px';

  return (
    <Styled.Root>
      <Link
        component={RouterLink}
        to={href}
        underline="hover"
        data-testid="table-sort-breadcrumb"
        sx={{ flexShrink: 0 }}
      >
        <Styled.LinkText variant={`EC_TYPE_${fontVariant}`}>
          {prevLocation}
        </Styled.LinkText>
      </Link>
      <Styled.Arrow
        baseClassName="ri-arrow-right-s-line"
        sx={{ marginRight: iconMargin }}
      />
      <Styled.LocationText variant={`EC_TYPE_${fontVariant}`}>
        {location}
      </Styled.LocationText>
    </Styled.Root>
  );
};

export default TableSortBreadcrumb;
