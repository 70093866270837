import * as React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import ExternshipManagerForm, {
  useExternshipManagerForm
} from '@pages/Candidate/ExternshipProposalPage/ManagerStep/ExternshipManagerForm';
import { useHistory, useParams } from 'react-router-dom';
import { ExternshipApi } from '@api/Externships.api';
import { ExternshipStatus } from '@api/models/externshipApi.models';
import { FormProvider } from 'react-hook-form';
import NotFoundPage from '@pages/Errors/NotFoundPage';
import PageTitle from '@components/PageTitle';
import { ProposedManagerAndSupervisorsPutReq } from '@api/models/externshipProposalApi.models';
import RootPageLayout from '@components/Layout/RootPageLayout';
import theme from '@themes/ui/escoffier';
import { useFetchExternships } from '@common/fetches/useFetchExternships';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';

const ExternshipManagerVerificationPage: React.FC = () => {
  const history = useHistory();
  const { externshipId } = useParams<{ externshipId?: string }>();
  const [verifyClicked, setVerifyClicked] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [employerId] = useStore<number>(UseStoreKeys.EMPLOYER_ID);

  const { externshipById: externship, loaded } = useFetchExternships(
    null,
    externshipId
  );

  const handleValidFormRequest = async (
    postBody: ProposedManagerAndSupervisorsPutReq,
    onSuccess?: () => void
  ): Promise<void> => {
    setVerifyClicked(true);
    setErrorMessage('');

    if (externship) {
      try {
        await ExternshipApi.managerVerification(externship.id, postBody);
        history.push('/employer/dashboard');
        !!onSuccess && onSuccess();
      } catch (error: any) {
        console.error(
          `Error for ExternshipManagerVerification.handleValidFormRequest(): ${error}`
        );
        setErrorMessage(error.response.data.message);
      } finally {
        setVerifyClicked(false);
      }
    }
  };

  const {
    methods,
    supervisorsRequired,
    setSupervisorsRequired,
    handleFormSubmit
  } = useExternshipManagerForm(handleValidFormRequest);

  // A manager will not need to view the verification page if there are no supervisors propose
  // for the externship
  const hasSupervisors = externship?.proposedCompany.supervisors?.length;
  const authorizedManager = externship?.manager?.id === employerId;

  if (
    (loaded &&
      (!externship ||
        externship.status !== ExternshipStatus.PENDING ||
        !hasSupervisors)) ||
    !authorizedManager
  ) {
    return <NotFoundPage />;
  }

  return (
    <RootPageLayout muiMaxWidth="xl">
      <PageTitle
        color={theme.palette.primary.main}
        text="Verify Manager and Supervisor Information"
      />
      <Typography sx={{ margin: '32px 0px' }}>
        Verify that the information proposed by the potential extern is
        accurate. If there are additional supervisors that can approve a time
        card, add them here. Once all managers and supervisors are approved,
        externs can start logging hours.
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <Box sx={{ '& > *': { marginBottom: '56px' } }}>
            <FormProvider {...methods}>
              <ExternshipManagerForm
                methods={methods}
                externship={externship}
                supervisorsRequired={supervisorsRequired}
                setSupervisorsRequired={setSupervisorsRequired}
                verificationPage
              />
            </FormProvider>
          </Box>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={12} lg={3}>
          <Button
            fullWidth
            variant="contained"
            disabled={verifyClicked}
            onClick={(): void => handleFormSubmit()}
          >
            Verify
          </Button>
        </Grid>
        <Grid item xs={12}>
          {errorMessage && (
            <Typography
              variant="EC_TYPE_2XS"
              data-testid={`${name}-error-message`}
              color={'error'}
            >
              {errorMessage}
            </Typography>
          )}
        </Grid>
      </Grid>
    </RootPageLayout>
  );
};

export default ExternshipManagerVerificationPage;
