import * as React from 'react';
import {
  Box,
  Button,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import CompanyCardWithActions, {
  Action
} from '@pages/EcAdmin/ContentManagementPage/EmployerSpotlightContent/CompanyCardWithActions';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import DirectConnectModal from './DirectConnectModal';
import { DirectConnectPost } from '@api/models/adminContentManagementApi.models';
import IconWithLabel from '@components/IconWithLabel';
import SimpleSelect from '@components/Forms/SimpleSelect';
import StepSection from '@components/StepSection';
import { styled } from '@mui/system';
import { useFetchAdminDashboardCompanyStatus } from '@common/fetches/useFetchAdminDashboardCompanyStatus';
import { useFetchDirectConnectPosts } from '@common/fetches/useFetchDirectConnectPosts';

const Styled = {
  Content: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isMobile'
  })<{ isMobile: boolean }>(({ isMobile }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: isMobile ? 2 : 6,
    WebkitBoxOrient: 'vertical'
  }))
};

enum DirectConnectFilter {
  ALL = 10,
  ACTIVE = 20,
  INACTIVE = 30
}

const DirectConnectContent: React.FC = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [filter, setFilter] = React.useState(DirectConnectFilter.ALL);
  const [filteredPosts, setFilteredPosts] = React.useState<DirectConnectPost[]>(
    []
  );

  const [openModal, setOpenModal] = React.useState(false);
  const [currDirectConnectPost, setCurrDirectConnectPost] =
    React.useState<DirectConnectPost>();
  const [directConnectSaved, setDirectConnectSaved] =
    React.useState<boolean>(false);

  const { directConnectPosts, fetchDirectConnectPosts, loading } =
    useFetchDirectConnectPosts();

  const { companies: allCompanies } = useFetchAdminDashboardCompanyStatus({
    permission: 'DIRECT_CONNECT_POSTS_AVAILABLE',
    active: true,
    profileComplete: true
  });

  React.useEffect(() => {
    if (directConnectSaved) {
      fetchDirectConnectPosts();
      setDirectConnectSaved(false);
    }
  }, [directConnectSaved]);

  React.useEffect(() => {
    setFilteredPosts(directConnectPosts);
  }, [directConnectPosts]);

  const handleEdit = (post: DirectConnectPost): void => {
    setCurrDirectConnectPost(post);
    setOpenModal(true);
  };

  const handleOpenModal = (): void => {
    setOpenModal(true);
  };

  const handleCloseModal = (directConnectSaved: boolean): void => {
    setOpenModal(false);
    setDirectConnectSaved(directConnectSaved);
    setCurrDirectConnectPost(undefined);
  };

  const getDateLabel = (dateString: string): string => {
    return `Posted: ${dateString}`;
  };

  const getActions = (post: DirectConnectPost): Action[] => {
    return [
      {
        icon: 'ri-edit-2-line',
        label: 'edit',
        onClick: (): void => handleEdit(post)
      }
    ];
  };

  const handleFilterChange = (e): void => {
    setFilter(e.target.value);
  };

  const sortedDirectConnectPosts = directConnectPosts.sort((x, y) => {
    // Place posts not visibile on marketplace towards end of array
    return x.companyEnabled === y.visibleOnMarketplace
      ? 0
      : x.visibleOnMarketplace
      ? -1 // eslint-disable-line @typescript-eslint/indent
      : 1; // eslint-disable-line @typescript-eslint/indent
  });

  const applyFilter = (filter: DirectConnectFilter): void => {
    switch (filter) {
      case DirectConnectFilter.ALL:
        setFilteredPosts(sortedDirectConnectPosts);
        break;
      case DirectConnectFilter.ACTIVE:
        setFilteredPosts(
          directConnectPosts.filter((p) => p.visibleOnMarketplace)
        );
        break;
      case DirectConnectFilter.INACTIVE:
        setFilteredPosts(
          directConnectPosts.filter((p) => !p.visibleOnMarketplace)
        );
        break;
      default:
        setFilteredPosts([]);
        break;
    }
  };

  React.useEffect(() => {
    applyFilter(filter);
  }, [filter]);

  const filterOptions = [
    { label: 'Show All', value: DirectConnectFilter.ALL },
    { label: 'Show Active', value: DirectConnectFilter.ACTIVE },
    { label: 'Show Inactive', value: DirectConnectFilter.INACTIVE }
  ];

  const Content: React.FC = () => (
    <BottomMarginContentWrapper bottomMargin="16px">
      <SimpleSelect
        options={filterOptions}
        handleChange={handleFilterChange}
        value={filter}
        label="Filter by"
      />
      <Box>
        {filteredPosts.map((post) => (
          <CompanyCardWithActions
            large
            key={post.id}
            id={post.companyId}
            name={post.companyName}
            image={post.logoUrl}
            inactive={!post.visibleOnMarketplace}
            content={
              <Styled.Content isMobile={isSmall}>
                <IconWithLabel
                  smallest
                  title={getDateLabel(post.createdAt)}
                  icon="ri-calendar-event-fill"
                />
                <Typography
                  variant="EC_TYPE_3XS"
                  component={'p'}
                  sx={{ color: theme.palette.GRAY_3.main, marginTop: '2px' }}
                >
                  {post.jobCardDescription}
                </Typography>
              </Styled.Content>
            }
            actions={getActions(post)}
          />
        ))}
      </Box>
    </BottomMarginContentWrapper>
  );

  return (
    <StepSection>
      <Typography variant="EC_TYPE_4XL">Direct Connect</Typography>
      <Typography variant="EC_TYPE_BASE">
        Create a post for candidates to reach out to Gold Crown companies
        directly. Edit or remove the post by clicking on the icons.
      </Typography>
      <Button variant="contained" fullWidth onClick={handleOpenModal}>
        Add Post
      </Button>
      {loading ? <Skeleton variant="rectangular" height={300} /> : <Content />}
      {openModal && (
        <DirectConnectModal
          open={openModal}
          directConnectPosts={directConnectPosts}
          currDirectConnectPost={currDirectConnectPost}
          handleClose={handleCloseModal}
          allCompanies={allCompanies}
        />
      )}
    </StepSection>
  );
};

export default DirectConnectContent;
