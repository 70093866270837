import * as React from 'react';
import { Box, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { BadgeCodebook } from '@api/models/codebook.models';
import { BadgeMapping } from '@common/mappings/BadgeMappings';
import defaultEmployerLogo from '@assets/svg/employer-def.svg';
import facebookIcon from '@assets/svg/facebookIcon.svg';
import ImageWithBadge from '@components/ImageWithBadge';
import instagramIcon from '@assets/svg/instagramIcon.svg';
import linkedinIcon from '@assets/svg/linkedinIcon.svg';
import { PreviewPageCompanyDetailsProps } from '@pages/Employer/PreviewPage/employerPreviewPageComponentProps';
import { styled } from '@mui/system';
import websiteIcon from '@assets/svg/websiteIcon.svg';

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '50px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column-reverse',
      alignItems: 'flex-start'
    }
  })),
  LogoWrapper: styled(Box)(({ theme }) => ({
    img: {
      maxHeight: '200px',
      maxWidth: '200px',
      objectFit: 'scale-down',
      padding: '8px'
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 'unset',
      marginBottom: '32px'
    }
  })),
  CompanyName: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'breakpoint'
  })<{ breakpoint: boolean }>(({ breakpoint, theme }) => ({
    fontSize: breakpoint
      ? (theme as Theme).typography.EC_TYPE_5XL.fontSize
      : (theme as Theme).typography.EC_TYPE_6XL.fontSize
  })),
  Section: styled(Box)({
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100px',
    justifyContent: 'space-between'
  }),
  Content: styled(Box)({
    display: 'flex'
  }),
  LinkBox: styled(Box)({
    marginTop: '16px'
  }),
  SocialIcon: styled('img')(({ theme }) => ({
    width: '18px',
    marginRight: '20px',
    color: (theme as Theme).typography.EC_TYPE_XS.color
  }))
};

interface LinkProps {
  websiteUrl: string;
  linkedInUrl: string;
  instagramUrl: string;
  facebookUrl: string;
}

const Links = ({
  websiteUrl,
  linkedInUrl,
  instagramUrl,
  facebookUrl
}: LinkProps): JSX.Element => {
  const linkArray = [
    {
      dataTestId: 'socialLink-website',
      src: websiteIcon,
      alt: 'website-icon',
      href: websiteUrl
    },
    {
      dataTestId: 'socialLink-linkedin',
      src: linkedinIcon,
      alt: 'linkedin-icon',
      href: linkedInUrl
    },
    {
      dataTestId: 'socialLink-instagram',
      src: instagramIcon,
      alt: 'instagram-icon',
      href: instagramUrl
    },
    {
      dataTestId: 'socialLink-facebook',
      src: facebookIcon,
      alt: 'facebook-icon',
      href: facebookUrl
    }
  ];

  return (
    <>
      {linkArray.map(
        (link) =>
          link.href && (
            <a
              key={link.alt}
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Styled.SocialIcon
                data-testid={link.dataTestId}
                src={link.src}
                alt={link.alt}
              />
            </a>
          )
      )}
    </>
  );
};

const EmployerProfileCompanyDetails = (
  props: PreviewPageCompanyDetailsProps
): React.ReactElement => {
  const theme = useTheme();
  const customBreakpointReached = useMediaQuery('(max-width:800px)');

  const { name, logo, badge, ...links } = props;

  const badgeMap = BadgeMapping(badge as BadgeCodebook);

  const typographyVariant = customBreakpointReached
    ? 'EC_TYPE_2XL'
    : 'EC_TYPE_3XL';

  return (
    <Styled.Root data-testid="company-details-root">
      <Styled.Section>
        <Styled.CompanyName
          data-testid="company-name"
          variant={typographyVariant}
          breakpoint={customBreakpointReached}
          color={theme.palette.GRAY_4.main}
        >
          {name}
        </Styled.CompanyName>
        <Styled.LinkBox>
          <Links {...links} />
        </Styled.LinkBox>
      </Styled.Section>
      <Styled.LogoWrapper>
        <ImageWithBadge
          dataTestId="company-logo"
          src={logo ? logo.fullPath : defaultEmployerLogo}
          height="100%"
          width="100%"
          badge={badge && badgeMap?.[badge?.value]}
          expandableBadge
          noBorder
          noBorderRadius
        />
      </Styled.LogoWrapper>
    </Styled.Root>
  );
};

export default EmployerProfileCompanyDetails;
