import { Box, Typography } from '@mui/material';
import contactUsImg from '@assets/contact-us.jpg';
import React from 'react';
import { styled } from '@mui/system';

const Styled = {
  BannerBox: styled(Box)({
    position: 'relative',
    backgroundColor: '#000000',
    maxHeight: '735px',
    '& .contact-us-banner-img': {
      opacity: 0.35,
      display: 'block',
      width: '100%'
    }
  }),
  TextBox: styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    '& .contact-us-banner-title': {
      display: 'block',
      color: theme.palette.WHITE.main,
      textTransform: 'uppercase',
      ['@media (max-width:550px)']: {
        fontSize: '45px',
        lineHeight: '56px',
        marginTop: '12px'
      }
    },
    '& .contact-us-banner-subtitle': {
      color: theme.palette.WHITE.main,
      marginTop: '22px',
      ['@media (max-width:550px)']: {
        marginTop: 0,
        fontSize: '20px',
        lineHeight: '25px'
      }
    }
  }))
};

const ContactUsBanner = (): React.ReactElement => (
  <Styled.BannerBox>
    <img
      src={contactUsImg}
      alt="contact us"
      className="contact-us-banner-img"
    />
    <Styled.TextBox>
      <Typography variant="EC_TYPE_6XL" className="contact-us-banner-title">
        contact us
      </Typography>
      <Typography variant="EC_TYPE_3XL" className="contact-us-banner-subtitle">
        We want to hear from you.
      </Typography>
    </Styled.TextBox>
  </Styled.BannerBox>
);

export default ContactUsBanner;
