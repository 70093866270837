import { useParams } from 'react-router-dom';
import { useStoreValue } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import { CompanyProfile } from '@api/models/companyApi.models';

interface RouteParams {
  companyId: string;
}

interface ReturnIds {
  companyId: number;
  storeId: number;
  paramsId: string;
  isViewingAsEmployer: boolean;
}

export const useParamsOrStoreCompanyId = (): ReturnIds => {
  const companies = useStoreValue<CompanyProfile[]>(UseStoreKeys.MANAGED_COMPANIES) ?? [];
  const storeId = companies?.length > 0 ? companies[0].id : 0;
  const paramsId = useParams<RouteParams>().companyId;

  const companyId = paramsId ? parseInt(paramsId) : storeId;

  return { companyId, storeId, paramsId, isViewingAsEmployer: !!paramsId };
};
