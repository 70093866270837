import * as React from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import AboutSection from '@pages/Employer/ProfileSetupPage/AboutStep/AboutSection';
import { Codebook } from '@api/models/codebook.models';
import { CompanyApi } from '@api/Company.api';
import CompanyLogoSection from '@pages/Employer/ProfileSetupPage/AboutStep/CompanyLogoSection';
import { CompanyPutRequest } from '@api/models/companyApi.models';
import { FileType } from '@api/models/fileApi.models';
import FormActionsComponent from '@components/Forms/FormActionsComponent';
import FormWrapper from '@components/FormWrapper';
import PageDescriptionComponent from '@components/Layout/Common/PageDescriptionComponent';
import PageFormLayoutComponent from '@components/Layout/PageFormLayoutComponent';
import { scrollToError } from '@common/helpers/scrollToError';
import SocialProfilesSection from '@pages/Employer/ProfileSetupPage/AboutStep/SocialProfilesSection';
import { useFetchCompanyProfile } from '@common/fetches/useFetchCompanyProfile';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';

interface FormValues {
  phone: string;
  size: string;
  logoId?: number | null;
  badge: number;
  websiteUrl: string;
  linkedInUrl: string;
  instagramUrl: string;
  facebookUrl: string;
  companySegments: Codebook[];
  companyHealthSafeties: string[];
}

interface Props {
  handleStepper: (next: boolean) => void;
}

const AboutStep: React.FC<Props> = ({ handleStepper }) => {
  const { companyId } = useParamsOrStoreCompanyId();
  const { companyProfile } = useFetchCompanyProfile(companyId);
  const [files, setFiles] = React.useState<FileType[]>([]);

  const methods = useForm<FormValues>({
    defaultValues: {
      phone: '',
      size: '',
      logoId: undefined,
      badge: undefined,
      websiteUrl: '',
      linkedInUrl: '',
      instagramUrl: '',
      facebookUrl: '',
      companySegments: [],
      companyHealthSafeties: []
    }
  });

  const formOrder = {
    phone: 10,
    size: 20,
    companySegments: 30,
    companyHealthSafeties: 40,
    logoId: 50,
    badge: 60,
    websiteUrl: 70,
    instagramUrl: 80,
    facebookUrl: 90,
    linkedInUrl: 100
  };

  const { handleSubmit, setError, reset } = methods;

  React.useEffect(() => {
    if (companyProfile && companyProfile.logo) {
      setFiles([companyProfile.logo]);
    }
  }, [companyProfile]);

  React.useEffect(() => {
    if (companyProfile) {
      const {
        companyHealthSafeties,
        companySegments,
        facebookUrl,
        instagramUrl,
        linkedInUrl,
        logo,
        phone,
        size,
        websiteUrl,
        badge
      } = companyProfile;
      reset({
        phone: phone || '',
        size: size?.toString() || '',
        logoId: logo?.id,
        websiteUrl: websiteUrl || '',
        linkedInUrl: linkedInUrl || '',
        instagramUrl: instagramUrl || '',
        facebookUrl: facebookUrl || '',
        companySegments: companySegments || '',
        companyHealthSafeties: companyHealthSafeties.map(
          (safeties) => safeties.type
        ),
        badge: badge?.value
      });
    }
  }, [companyProfile]);

  const handleInvalidForm = (errors: FieldErrors): void => {
    scrollToError(errors, formOrder);
  };

  const handleValidForm = async (
    data: FormValues,
    onSuccess: () => void
  ): Promise<void> => {
    const postBody: CompanyPutRequest = {
      phone: data.phone,
      size: data.size ? parseInt(data.size) : null,
      logoId: data.logoId || null,
      websiteUrl: data.websiteUrl || null,
      linkedInUrl: data.linkedInUrl || null,
      instagramUrl: data.instagramUrl || null,
      facebookUrl: data.facebookUrl || null,
      companySegments: data.companySegments.map((segment) => segment.value),
      companyHealthSafeties: data.companyHealthSafeties,
      badge: data.badge
    };

    try {
      await CompanyApi.updateCompany(companyId, postBody);
      onSuccess();
    } catch (error: any) {
      console.error('Error for AboutStep.handleValidForm()', error);

      const errData = error.response.data.data;

      if (errData?.length) {
        errData.forEach(({ field, message }) =>
          setError(field, { type: 'manual', message })
        );
      }

      scrollToError(methods.formState.errors, formOrder);
    }
  };

  const handleFormSubmit = (onSuccess: () => void): void => {
    const onValid = async (data: FormValues): Promise<void> =>
      handleValidForm(data, onSuccess);
    handleSubmit(onValid, handleInvalidForm)();
  };

  return (
    <PageFormLayoutComponent
      sideContent={
        <PageDescriptionComponent title="Company Profile">
          Please fill out this information to surface your company and openings
          in the job marketplace for candidates.
        </PageDescriptionComponent>
      }
      content={
        <FormWrapper>
          <FormProvider {...methods}>
            <AboutSection companyName={companyProfile?.name || ''} />
            <CompanyLogoSection
              files={files}
              workLearnPartner={companyProfile?.workLearnPartner}
            />
            <SocialProfilesSection />
          </FormProvider>
        </FormWrapper>
      }
      actions={
        <FormActionsComponent
          hidePreviousBtn
          onSubmit={handleFormSubmit}
          submitBtnText="Save and Continue"
          handleStepper={handleStepper}
        />
      }
    />
  );
};

export default AboutStep;
