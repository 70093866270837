import * as React from 'react';
import {
  ExternshipEntity,
  SiteAgreementData
} from '@api/models/externshipApi.models';
import Box from '@mui/material/Box';
import { ExternshipApi } from '@api/Externships.api';
import ExternshipsTableRowRedesign from './ExternshipsTableRowRedesign';
import SiteAgreement from '@components/ExternshipAgreements/SiteAgreement';
import { sortTableData } from '@common/helpers/sortByCategory';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useTableSortContext } from '@common/context/tableSortContext';
import { EmployerProfile } from '@api/models/employerApi.models';
import { EmployerApi } from '@api/Employer.api';

const Styled = {
  TableRoot: styled(Box)({
    backgroundColor: theme.palette.WHITE.main,
    border: `1px solid ${theme.palette.GRAY_1.main}`,
    borderBottom: 'none',
    borderRadius: '0 0 4px 4px',
    overflow: 'hidden'
  }),
  RowBox: styled(Box)({
    borderBottom: `1px solid ${theme.palette.GRAY_1.main}`,
    ':hover': {
      backgroundColor: theme.palette.gold1.main
    }
  })
};

interface Props {
  employer: EmployerProfile;
  externships: ExternshipEntity[];
  setExternships: React.Dispatch<
    React.SetStateAction<ExternshipEntity[]> // eslint-disable-line @typescript-eslint/indent
  >;
  siteAgreementData?: SiteAgreementData[];
  setSiteAgreementData?: React.Dispatch<
    React.SetStateAction<SiteAgreementData[]> // eslint-disable-line @typescript-eslint/indent
  >;
}

const ExternshipsTable: React.FC<Props> = ({
  employer,
  externships,
  setExternships,
  siteAgreementData,
  setSiteAgreementData,
}) => {
  const { sortBy, sortOrder, page, rowsPerPage } = useTableSortContext();
  const [siteAgreementModalOpen, setSiteAgreementModalOpen] = React.useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = React.useState<number | null>(null);

  const handleOpenSiteAgreementModal = (companyId: number): void => {
    setSelectedCompanyId(companyId);
    setSiteAgreementModalOpen(true);
  };

  const handleCloseModal = (): void => {
    setSiteAgreementModalOpen(false);
    setSelectedCompanyId(null);
  };

  const handleRejectExternship = async (
    externshipId: number
  ): Promise<void> => {
    try {
      await ExternshipApi.decline(externshipId);

      const updatedExternships = externships.filter(
        (ex) => ex.id !== externshipId
      );

      setExternships(updatedExternships);
    } catch (error: any) {
      console.error(
        `Error for ExternshipsTable -> ExternshipApi.decline(): ${error}`
      );
    }
  };

  const handleSetSiteAgreementData = async (updatedData: Partial<SiteAgreementData>): Promise<void> => {
    const fetchedExternships = await EmployerApi.getExternshipsForEmployer(employer.id);

    setExternships((prev) => {
      return prev.map((externship) => {
        const fetchedExternship = fetchedExternships.find((fetchedExternship) => {
          return externship.id === fetchedExternship?.id;
        });

        if (fetchedExternship) {
          return fetchedExternship;
        }

        return externship;
      });
    });

    setSiteAgreementData && setSiteAgreementData(prev => {
      return prev?.map(siteAgreementData =>
        siteAgreementData.companyId === selectedCompanyId
          ? { ...siteAgreementData, ...updatedData }
          : siteAgreementData
      );
    });
  };

  return (
    <>
      <Styled.TableRoot data-testid="externships-table-root">
        {sortTableData(externships, sortOrder, sortBy as keyof ExternshipEntity)
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((externship) => {
            const siteAgreement = siteAgreementData?.find(
              agreement => agreement.companyId === externship.company?.id
            );
            return (
              <Styled.RowBox key={externship.id}>
                <ExternshipsTableRowRedesign
                  externship={externship}
                  employer={employer}
                  siteAgreementData={siteAgreement}
                  setExternships={setExternships}
                  handleRejectExternship={handleRejectExternship}
                  handleOpenSiteAgreementModal={() => handleOpenSiteAgreementModal(externship.company?.id)}
                />
              </Styled.RowBox>
            );
          })}
      </Styled.TableRoot>
      {siteAgreementModalOpen && selectedCompanyId && siteAgreementData && (
        <SiteAgreement
          siteAgreementData={siteAgreementData?.find(sa => sa.companyId === selectedCompanyId)}
          employerId={employer.id}
          agreementTitle="Site Agreement"
          open={siteAgreementModalOpen}
          handleClose={handleCloseModal}
          setSiteAgreementData={handleSetSiteAgreementData}
        />
      )}
    </>
  );
};

export default ExternshipsTable;
