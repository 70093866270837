import * as React from 'react';
import { IconButton, TablePagination, TableRow } from '@mui/material';
import { keyframes, styled } from '@mui/system';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import theme from '@themes/ui/escoffier';

export interface ResponsivePaginationProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event, newPage: number) => void;
  displayMobile: boolean;
  rowsPerPageOptions?: number[];
  handleRowsPerPageChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  centerPagination?: boolean;
}

export const ResponsivePagination = (
  props: ResponsivePaginationProps
): React.ReactElement => {
  const {
    count,
    page,
    rowsPerPage,
    onPageChange,
    displayMobile,
    rowsPerPageOptions,
    handleRowsPerPageChange,
    centerPagination
  } = props;
  const [rowsPerPageOpts, setRowsPerPageOpts] = React.useState<number[]>([
    rowsPerPage
  ]);

  React.useEffect(() => {
    if (rowsPerPageOptions) {
      setRowsPerPageOpts(rowsPerPageOptions);
    }
  }, [rowsPerPage, rowsPerPageOptions]);

  const useRowsPerPageOptions = Boolean(rowsPerPageOptions);

  const handlePrevBtnClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    onPageChange(event, page - 1);
  };

  const handleNextBtnClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    onPageChange(event, page + 1);
  };

  const displayedRowsPosition = !useRowsPerPageOptions ? 50 : 15;

  const actionStyles = React.useMemo(() => {
    if (displayMobile) {
      return {
        left: {
          position: 'absolute',
          left: 0
        },
        right: {
          position: 'absolute',
          right: 0
        },
        displayRows: {
          '& .MuiTablePagination-displayedRows': {
            position: 'absolute',
            left: `${displayedRowsPosition}%`,
            transform: `translateX(-${displayedRowsPosition}%)`
          }
        }
      };
    } else {
      return {
        ...(centerPagination
          ? { displayRows: { display: 'flex', justifyContent: 'center' } }
          : {})
      };
    }
  }, [displayMobile]);

  return (
    <TablePagination
      component="div"
      count={count}
      page={page}
      rowsPerPageOptions={rowsPerPageOpts}
      rowsPerPage={rowsPerPage}
      onPageChange={onPageChange}
      onRowsPerPageChange={handleRowsPerPageChange ?? undefined}
      ActionsComponent={(): JSX.Element => (
        <>
          <IconButton
            onClick={handlePrevBtnClick}
            disabled={page === 0}
            sx={actionStyles?.left}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton
            onClick={handleNextBtnClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            sx={actionStyles?.right}
          >
            <KeyboardArrowRight />
          </IconButton>
        </>
      )}
      sx={actionStyles?.displayRows}
    />
  );
};

export const ECTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'statusUpdate' && prop !== 'selected'
})<{ statusUpdate: boolean; selected: boolean }>(
  ({ statusUpdate, selected }) => {
    const toColor = selected ? theme.palette.gold1.main : 'initial';

    const keyFrame = keyframes`
      from {
        background-color: #D4AD64;
      }
      to {
        background-color: ${toColor};
      }
    `;

    return {
      backgroundColor: selected ? theme.palette.GRAY_2.main : 'inherit',
      animation: statusUpdate ? `${keyFrame} 1.5s ease-out` : 'unset'
    };
  }
);
