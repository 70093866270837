import { Country, Location } from '@interfaces/location.interfaces';

/**
 * @param location
 * @returns string with city and state
 */
export const getCityState = (location: Location): string => {
  let text = '';

  if (location?.city) {
    text += `${text.length ? ', ' : ''}${location.city}`;
  }

  if (location?.country === Country.PUERTO_RICO) {
    text += `${text.length ? ', ' : ''}${location.country}`;
  } else {
    if (location?.state) {
      text += `${text.length ? ', ' : ''}${location.state}`;
    }
  }
  return text;
};
