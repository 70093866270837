import * as React from 'react';
import BasicModal from '@components/BasicModal';
import { CandidateDashboardContextProvider } from '@common/context/candidateDashboardContext';
import EmployerSpotlight from '@pages/Candidate/CandidateDashboard/EmployerSpotlightSection/EmployerSpotlight';
import { ResponsiveLayoutProvider } from '@components/Layout/ResponsiveLayout';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchCandidateProfile } from '@common/fetches/useFetchCandidateProfile';
import { useFetchExternships } from '@common/fetches/useFetchExternships';
import { useFetchJobApplicationsByCandidateId } from '@common/fetches/useFetchJobApplicationsByCandidateId';
import { useKeycloak } from '@react-keycloak/web';
import { useSetCandidateEnabled } from '@common/putRequests/useSetCandidateEnabled';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import WelcomeSection from '@pages/Candidate/CandidateDashboard/WelcomeSection/WelcomeSection';
import WorkLearnSection from '@pages/Candidate/CandidateDashboard/WorkLearnSection/WorkLearnSection';

const MOBILE_BREAKPOINT = 920;

const CandidateDashboard: React.FC = () => {
  const featureFlags = useFeatureFlags();
  const [studentId] = useStore<string>(UseStoreKeys.CANDIDATE_STUDENT_ID);
  const [candidateId] = useStore<number>(UseStoreKeys.CANDIDATE_ID);
  const [candidateEnabled] = useStore<boolean>(UseStoreKeys.CANDIDATE_ENABLED);

  const [modalOpen, setModalOpen] = React.useState<boolean>(!candidateEnabled);

  const { candidateProfile, loaded: profileLoaded } =
    useFetchCandidateProfile(candidateId);
  const { jobApplications, loaded: jobAppsLoaded } =
    useFetchJobApplicationsByCandidateId(candidateId);

  const fetchExternshipsId =
    featureFlags.MULTIPLE_EXTERNSHIPS_PER_COURSE && studentId
      ? studentId
      : candidateId;

  const {
    externshipsByCandidate, // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove when flag is updated
    candidateEnrollments,
    coursesAssignable, // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove when flag is updated
    hasAvailableExternshipCourses, // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove when flag is updated
    fetchByCandidateId,
    setExternshipsByCandidate, // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove when flag is updated
    // setCandidateEnrollments,
    addExternshipToState,
    removeExternshipFromState,
    // hasAvailableExternshipCourses,
    loaded: externshipsLoaded
  } = useFetchExternships(fetchExternshipsId, null);

  const loaded = !!profileLoaded && !!jobAppsLoaded && !!externshipsLoaded;

  const { keycloak } = useKeycloak();

  const { setEnabled } = useSetCandidateEnabled();
  const handleEnableAccount = (): void => {
    setEnabled(candidateId);
    setModalOpen(false);
  };

  const handleCloseModal = (): void => {
    keycloak.logout({ redirectUri: `${window.location.origin}/logout` });
  };

  React.useEffect(() => {
    if (!candidateEnabled) {
      setModalOpen(true);
    }
  }, [candidateEnabled]);

  const primaryAction = {
    label: 'Reactivate',
    action: handleEnableAccount
  };

  const secondaryAction = {
    label: 'Cancel',
    action: handleCloseModal
  };

  return (
    <>
      <ResponsiveLayoutProvider customBreakpoint={MOBILE_BREAKPOINT}>
        <RootPageLayout
          data-testid="candidate-dashboard"
          muiMaxWidth={false}
          padding="0px 0px 72px"
          bgImgVariant={6}
        >
          <CandidateDashboardContextProvider
            value={{
              candidateProfile,
              candidateEnrollments,
              fetchByCandidateId,
              addExternshipToState,
              removeExternshipFromState,
              jobApplications,
              loaded,
              externships: externshipsByCandidate, // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove when flag is updated
              setExternshipsByCandidate, // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove when flag is updated
              coursesAssignable, // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove when flag is updated
              hasAvailableExternshipCourses // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove when flag is updated
            }}
          >
            <WelcomeSection />
          </CandidateDashboardContextProvider>
          <EmployerSpotlight />
          <WorkLearnSection />
        </RootPageLayout>
      </ResponsiveLayoutProvider>
      <BasicModal
        open={modalOpen}
        handleClose={handleCloseModal}
        title="Your account is inactive."
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
        maxWidth="sm"
      >
        Would you like to reactivate your account?
      </BasicModal>
    </>
  );
};

export default CandidateDashboard;
