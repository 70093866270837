import * as React from 'react';
import { Box, Grid, Tab, Typography } from '@mui/material';
import { Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import EcBarChart from '@components/Graphs/EcBarChart';
import { styled } from '@mui/system';

const Styled = {
  ContentContainer: styled(Grid)({
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  }),
  TabPanel: styled(TabPanel)({
    '&.MuiTabPanel-root': {
      padding: '24px 0px'
    }
  })
};

interface Props {
  pieData: { name: string; value: number; fill?: string }[];
  notHiredData?: any[];
  content: React.ReactNode;
  label?: string;
  dataTestId?: string;
}

const ApplicationStatusOverview: React.FC<Props> = ({
  pieData,
  content,
  label,
  dataTestId,
  notHiredData
}) => {
  const [value, setValue] = React.useState('1');

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: string
  ): void => {
    setValue(newValue);
  };

  return (
    <Grid container rowSpacing={5} data-testid={dataTestId}>
      <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleTabChange}
              aria-label="candidate accounts tabs"
            >
              <Tab label="All Statuses" value="1" />
              {!!notHiredData?.length && <Tab label="Not Hired" value="2" />}
            </TabList>
          </Box>
          <Styled.TabPanel value="1">
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={pieData}
                  dataKey="value"
                  nameKey="name"
                  label={true}
                />
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Styled.TabPanel>
          {!!notHiredData?.length && (
            <Styled.TabPanel value="2">
              <EcBarChart
                height={300}
                data={notHiredData}
                xAxisDataKey={'label'}
                bars={[{ dataKey: 'count', tooltipName: 'Applicants' }]}
              />
            </Styled.TabPanel>
          )}
        </TabContext>
        {label && <Typography variant="EC_TYPE_SM">{label}</Typography>}
      </Grid>
      <Styled.ContentContainer item xs={12} md={6}>
        <Box>{content}</Box>
      </Styled.ContentContainer>
    </Grid>
  );
};

export default ApplicationStatusOverview;
