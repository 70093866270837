import {
  PromotedContentRes,
  SearchCriteria,
  SearchEsourceResult,
  SearchResult
} from '@api/models/searchApi.models';
import { api } from '@api/configs/base';
import { apiConfig } from '@api/configs/api.config';
import { ApiResponse } from '@api/models/base.models';
import { CandidateProfile } from '@api/models/candidateApi.models';
import { JobPost } from '@api/models/singleJobPostApi.models';
import { PremierPartner } from './models/adminContentManagementApi.models';
import { WorkLearnPartnerSearchRes } from '@interfaces/workLearnPartner.interfaces';

export class SearchApi {
  static async getJobPosts(
    data: SearchCriteria
  ): Promise<ApiResponse<SearchResult<JobPost>>> {
    try {
      const response = await api.post<ApiResponse<SearchResult<JobPost>>>(
        `${apiConfig.searchUrl}/job-posts`,
        data
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for SearchApi.getJobPosts(): ${error}`);
      throw error;
    }
  }

  static async getCandidates(
    data: SearchCriteria
  ): Promise<ApiResponse<SearchResult<CandidateProfile>>> {
    try {
      const res = await api.post<ApiResponse<SearchResult<CandidateProfile>>>(
        `${apiConfig.searchUrl}/candidates`,
        data
      );

      return res.data;
    } catch (error: any) {
      console.error(`Error for SearchApi.getCandidates(): ${error}`);
      throw error;
    }
  }

  static async getPromotedContent(): Promise<ApiResponse<PromotedContentRes>> {
    try {
      const res = await api.get<ApiResponse<PromotedContentRes>>(
        `${apiConfig.searchUrl}/promoted-content`
      );

      return res.data;
    } catch (error: any) {
      console.error(`Error for searchApi.getPromotedContent(): ${error}`);
      throw error;
    }
  }

  static async getRandomPremierPartners(): Promise<PremierPartner[]> {
    try {
      const response = await api.get<ApiResponse<PremierPartner[]>>(
        `${apiConfig.searchUrl}/promoted-partners`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for searchApi.getRandomPremierPartners(): ${error}`);
      throw error;
    }
  }

  static async getWorkLearnPartners(): Promise<
    ApiResponse<WorkLearnPartnerSearchRes[]> // eslint-disable-line @typescript-eslint/indent
  > {
    try {
      const res = await api.get<ApiResponse<WorkLearnPartnerSearchRes[]>>(
        `${apiConfig.searchUrl}/work-learn-partners`
      );

      return res.data;
    } catch (error: any) {
      console.error(`Error for searchApi.getWorkLearnPartners(): ${error}`);
      throw error;
    }
  }

  static async getEsourceContent(
    searchValue: string,
    page: number,
    selectedTypes: string[],
    companyId: number
  ): Promise<ApiResponse<SearchEsourceResult>> {
    // eslint-disable-line @typescript-eslint/indent
    try {
      const res = await api.post<ApiResponse<SearchEsourceResult>>(
        `${apiConfig.searchUrl}/esource-content`,
        { searchValue, page, selectedTypes, companyId }
      );

      return res.data;
    } catch (error: any) {
      console.error(`Error for searchApi.getWorkLearnPartners(): ${error}`);
      throw error;
    }
  }
}
