import * as React from 'react';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { Toast, useToast } from '@components/Toast';
import BasicModal from '@components/BasicModal';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import EcChip from '@components/EcChip';
import PdfLoadingOverlay from '@components/PdfLoadingOverlay';
import { styled } from '@mui/system';
import { useBatchActionsContext } from '@common/context/batchActionsContext';

const Styled = {
  InputLabel: styled(Typography)(({ theme }) => ({
    marginBottom: '5px',
    textTransform: 'uppercase',
    color: theme.palette.grey4.main
  })),
  BoxWrapper: styled(Box)(({ theme }) => ({
    display: 'flex',
    border: `0.5px solid ${theme.palette.OUTLINE.main}`,
    borderRadius: '5px',
    flexWrap: 'wrap',
    padding: '5px',
    gap: '5px',
    width: '100%',
    maxHeight: '304px',
    overflowY: 'scroll'
  }))
};

export interface SelectedRowExternshipEntity {
  candidateId: number;
  externshipId: number;
  studentEmail: string;
  studentName: string;
}

export interface SelectedRowEnrollmentEntity {
  studentName: string;
  studentId: string;
  enrollmentId: number;
}

type SelectedRow = SelectedRowExternshipEntity | SelectedRowEnrollmentEntity;

interface Props<T extends SelectedRow> {
  open: boolean;
  modalTitle: string;
  modalSubtext?: string;
  primaryBtnText: string;
  loading?: boolean;
  toastMessage?: string;
  selectedRows: T[];
  setSelectedRows: React.Dispatch<React.SetStateAction<T[]>>;
  handleClose: () => void;
  maxTagsToDisplay?: number;
  children?: React.ReactNode;
  handleSubmitClick: () => Promise<void>;
}

const BatchActionModalLayout = <T extends SelectedRow>({
  open,
  modalTitle,
  modalSubtext,
  primaryBtnText,
  loading = false,
  toastMessage,
  selectedRows,
  setSelectedRows,
  handleClose,
  maxTagsToDisplay = 10,
  children,
  handleSubmitClick
}: Props<T>): React.ReactElement => {
  const theme = useTheme();

  const { openToast, ...toastProps } = useToast();
  const { rowSelectionModel } = useBatchActionsContext();

  const [showAll, setShowAll] = React.useState(false);
  const toDisplay = showAll
    ? selectedRows
    : selectedRows.slice(0, maxTagsToDisplay);
  const remaining =
    selectedRows.length > maxTagsToDisplay
      ? selectedRows.length - maxTagsToDisplay
      : null;

  React.useEffect(() => {
    const rowData = rowSelectionModel.map((row) =>
      JSON.parse(row as string)
    ) as unknown as T[];

    setSelectedRows(rowData);
  }, [rowSelectionModel]);

  const handleShowAll = (): void => {
    setShowAll(true);
  };

  const handleRemove = React.useCallback(
    (id: number | string): void => {
      setSelectedRows((prev) =>
        prev.filter((s) =>
          'studentId' in s ? s.studentId !== id : s.externshipId !== id
        )
      );
    },
    [setSelectedRows]
  );

  const handleSubmit = async (): Promise<void> => {
    await handleSubmitClick().then(() => {
      if (toastMessage) openToast(toastMessage);
    });
  };

  return (
    <>
      <BasicModal
        open={open}
        handleClose={handleClose}
        title={modalTitle}
        primaryAction={{
          label: primaryBtnText,
          action: handleSubmit
        }}
        secondaryAction={{ label: 'Cancel', action: handleClose }}
        maxWidth="sm"
      >
        <BottomMarginContentWrapper bottomMargin="24px">
          {modalSubtext && (
            <Typography variant="EC_TYPE_BASE">{modalSubtext}</Typography>
          )}
          <Styled.InputLabel variant="EC_TYPE_XS">TO:</Styled.InputLabel>
          <Styled.BoxWrapper>
            <Grid container spacing={1} sx={{ padding: '7px' }}>
              {toDisplay.map((s, i) => {
                const selectedId =
                  'externshipId' in s ? s.externshipId : s.studentId;

                return (
                  <Grid item key={`${selectedId}-${i}`}>
                    <EcChip
                      dataTestId={`${s.studentName}-chip`}
                      label={s.studentName}
                      handleDelete={
                        selectedRows.length > 1
                          ? (): void => handleRemove(selectedId)
                          : undefined
                      }
                      typography="EC_TYPE_2XS"
                      chipColor={theme.palette.GOLD_LIGHT.main}
                      labelColor={theme.palette.BLACK.main}
                      customSx={{
                        fontSize: '10px'
                      }}
                    />
                  </Grid>
                );
              })}
              {!!remaining && !showAll && (
                <Grid item>
                  <Button variant="text" onClick={handleShowAll}>
                    +{remaining} more...
                  </Button>
                </Grid>
              )}
            </Grid>
          </Styled.BoxWrapper>
          {children}
        </BottomMarginContentWrapper>
        <PdfLoadingOverlay
          loading={loading}
          dataTestId="assign-modal-loading-overlay"
        />
      </BasicModal>
      <Toast {...toastProps} />
    </>
  );
};

export default BatchActionModalLayout;
