import * as React from 'react';
import { Box, Theme, Typography } from '@mui/material';
import CtaButton from '@components/CtaButton';
import { mediaQueries } from '@themes/ui/variables';
import { styled } from '@mui/system';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

export const BREAKPOINT = 641;
export const MEDIA_QUERY = `@media (min-width:${BREAKPOINT}px)`;

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    display: 'flex',
    '& .skill-title': {
      marginRight: '10px',
      fontSize: (theme as Theme).typography.EC_TYPE_4XL.fontSize,
      lineHeight: (theme as Theme).typography.EC_TYPE_4XL.lineHeight,
      color: theme.palette.BLACK.main
    },
    [MEDIA_QUERY]: {
      justifyContent: 'space-between'
    }
  })),
  Divider: styled(Box)(({ theme }) => ({
    width: '100',
    margin: '24px -20px 24px',
    borderBottom: `1px solid ${theme.palette.GRAY_2.main}`,
    [mediaQueries.MIN_WIDTH_481]: {
      marginLeft: '-32px',
      marginRight: '-32px'
    },
    [mediaQueries.MIN_WIDTH_601]: {
      margin: '24px 0'
    }
  })),
  BtnDiv: styled(Box)({
    position: 'absolute',
    width: '94%',
    bottom: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
    [mediaQueries.MIN_WIDTH_601]: {
      position: 'unset',
      transform: 'none',
      maxWidth: 'max-content'
    }
  })
};

interface MySkillsHeader {
  title: string;
  showCTA: boolean;
  isPartnerEmployee: boolean;
}

const MySkillsCardHeader = ({
  title,
  showCTA,
  isPartnerEmployee
}: MySkillsHeader): React.ReactElement => {
  const { width } = useWindowDimensions();
  const fullBtnWidth = width < BREAKPOINT;

  const ctaPath = isPartnerEmployee ? '/partner-employee' : '/candidate';

  return (
    <>
      <Styled.Root data-testid="career-goal-skill-card-header-root">
        <Typography variant="EC_TYPE_3XL" className="skill-title">
          {title}
        </Typography>
        {showCTA && (
          <Styled.BtnDiv>
            <CtaButton
              to={`${ctaPath}/profile-setup`}
              label="Update my profile"
              fullWidth={fullBtnWidth}
            />
          </Styled.BtnDiv>
        )}
      </Styled.Root>
      <Styled.Divider />
    </>
  );
};

export default MySkillsCardHeader;
