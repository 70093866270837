import * as React from 'react';
import {
  CandidateGalleryMediaType,
  isPhotoType,
  isVideoType
} from '@api/models/mediaApi.models';
import AdditionalImagesCard from '@components/AdditionalImagesCard';
import { Box } from '@mui/material';
import EcCarousel from '@components/EcCarousel';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const MAX_DISPLAYED_IMAGES = 5;

const Styled = {
  Root: styled(Box)({
    display: 'grid',
    gridTemplateColumns: 'repeat(5, minmax(70px, 160px))',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(3, minmax(70px, 160px))'
    }
  }),
  ImageDiv: styled(Box)({
    position: 'relative',
    boxSizing: 'border-box',
    borderRadius: '4px',
    overflow: 'hidden',
    cursor: 'pointer',
    '&:before': {
      content: '""',
      paddingTop: '100%',
      display: 'block'
    },
    '& .gallery-img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectPosition: '50% 50%',
      objectFit: 'cover'
    },
    '&:hover': {
      opacity: 0.5,
      transition: '0.5s'
    }
  }),
  StashedCard: styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.GOLD_1.main}`,
    cursor: 'pointer',
    '& .more-photos': {
      color: theme.palette.GRAY_3.main,
      '& i': {
        marginBottom: '6px',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '18px'
      }
    }
  }))
};

interface Props {
  media: CandidateGalleryMediaType[];
  maxDisplayed?: number;
}

const CandidateProfileGallery: React.FC<Props> = ({
  media,
  maxDisplayed = MAX_DISPLAYED_IMAGES
}) => {
  const { width } = useWindowDimensions();
  const displayedMedia = React.useRef<CandidateGalleryMediaType[] | null>(null);
  const stashedMedia = React.useRef<CandidateGalleryMediaType[] | null>(null);
  const _maxDisplayed = width < 600 ? maxDisplayed + 1 : maxDisplayed;

  const [currentImgIdx, setCurrentImgIdx] = React.useState<number | null>(null);

  const handleOpenCarousel = (index: number): void => {
    setCurrentImgIdx(index);
  };

  const handleCloseCarousel = React.useCallback((): void => {
    setCurrentImgIdx(null);
  }, [setCurrentImgIdx]);

  React.useEffect(() => {
    if (media.length > _maxDisplayed) {
      displayedMedia.current = media.slice(0, _maxDisplayed - 1);
      stashedMedia.current = media.slice(_maxDisplayed - 1, media.length);
    } else {
      displayedMedia.current = media.slice(0, _maxDisplayed);
      stashedMedia.current = media.slice(_maxDisplayed, media.length);
    }
  }, [media, displayedMedia.current, stashedMedia.current]);

  const carouselMedia = React.useMemo(
    () =>
      media.map((m) => {
        if (isPhotoType(m)) {
          return m.file;
        }

        return m;
      }),
    [media]
  );

  return (
    <>
      {media.length > 0 && (
        <>
          <Styled.Root data-testid="preview-page-image-gallery">
            {displayedMedia.current &&
              displayedMedia.current.map((item, index) => {
                const src = isVideoType(item)
                  ? item.thumbnailUrl
                  : (item.thumbnail ?? item.file).fullPath;
                const alt = isVideoType(item)
                  ? item.title
                  : (item.thumbnail ?? item.file).originalname;
                return (
                  <Styled.ImageDiv
                    key={index}
                    onClick={(): void => handleOpenCarousel(index)}
                  >
                    <img src={src} alt={alt} className="gallery-img" />
                  </Styled.ImageDiv>
                );
              })}
            {stashedMedia.current && stashedMedia.current.length > 0 && (
              <AdditionalImagesCard
                count={stashedMedia.current.length}
                maxDisplayCount={_maxDisplayed}
                handleOpen={handleOpenCarousel}
              />
            )}
          </Styled.Root>
        </>
      )}
      <EcCarousel
        media={carouselMedia}
        selectedIdx={currentImgIdx}
        handleClose={handleCloseCarousel}
      />
    </>
  );
};

export default CandidateProfileGallery;
