import * as React from 'react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import TermsAndConditionsContent from '@pages/Public/TermsOfUsePage/TermsAndConditionsContent';
import Typography from '@mui/material/Typography';

const Styled = {
  DialogTitleBox: styled(DialogTitle)({
    ['@media (max-width:320px)']: {
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'flex-end'
    }
  }),
  IconButton: styled(IconButton)({
    position: 'absolute',
    right: '16px',
    top: '16px',
    color: '#5C6B80',
    ['@media (max-width:567px)']: {
      top: '23px'
    },
    ['@media (max-width:320px)']: {
      position: 'relative',
      right: 'unset',
      top: 'unset',
      padding: 'unset'
    }
  }),
  Dialog: styled(Dialog)({
    a: {
      color: '#BB1133'
    },
    zIndex: 0,
    top: '80px',
    ['@media (max-width:567px)']: {
      maxWidth: '100%'
    }
  }),
  AcceptButton: styled(Button)({
    marginTop: '32px',
    marginBottom: '32px',
    padding: '12px 20px',
    ['@media (max-width:567px)']: {
      marginTop: '16px',
      marginBottom: 0,
      width: '100%'
    }
  }),
  Title: styled(Typography)({
    ['@media (max-width:320px)']: {
      alignSelf: 'flex-start'
    }
  })
};

interface InputProps {
  open: boolean;
  onClose: () => void;
  onAccept: () => void;
}

const TermsAndConditionsDialog: React.FC<InputProps> = ({
  open,
  onClose,
  onAccept
}) => {
  return (
    <Styled.Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      maxWidth="md"
    >
      <Styled.DialogTitleBox title="terms-and-conditions-modal">
        <Styled.Title variant="EC_TYPE_3XL">Terms and Conditions</Styled.Title>
        <Styled.IconButton aria-label="close" onClick={onClose}>
          <i className="ri-close-line"></i>
        </Styled.IconButton>
      </Styled.DialogTitleBox>
      <DialogContent>
        <TermsAndConditionsContent />
      </DialogContent>
      <Box
        display="flex"
        justifyContent="center"
        sx={{ borderTop: '1px solid #A6B0BF', marginTop: '44px' }}
      >
        <Styled.AcceptButton variant="contained" autoFocus onClick={onAccept}>
          Accept Terms and Conditions
        </Styled.AcceptButton>
      </Box>
    </Styled.Dialog>
  );
};

export default TermsAndConditionsDialog;
