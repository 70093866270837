export const scrollToId = (id: string): void => {
  const element = document.getElementById(id);
  if (element) {
    const yOffset = -85;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};
