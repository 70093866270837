import { useEffect, useState } from 'react';
import { JobApplicationApi } from '@api/JobApplication.api';
import { JobApplicationCandidates } from '@api/models/jobApplicationApi.models';
import { JobPost } from '@api/models/singleJobPostApi.models';
import { JobPostApi } from '@api/JobPost.api';

type FetchJobPostsByCompanyId = {
  jobPosts: JobPost[] | null;
  loading: boolean;
  applicantsPerJobId?: JobApplicationCandidates[];
};

/**
 * Fetch `JobPost[]` from JobPostApi.getJobPostByCompanyId()
 *
 * @param {number} id companyId
 * @returns `{ jobPosts: JobPost[]; loading: boolean; }`
 */
export const useFetchJobPostsByCompanyId = (
  id?: number,
  getApplicants?: true | undefined
): FetchJobPostsByCompanyId => {
  const [jobPosts, setJobPosts] = useState<JobPost[] | null>(null);
  const [applicantsPerJobId, setApplicantsPerJobId] = useState<
    JobApplicationCandidates[] | undefined // eslint-disable-line @typescript-eslint/indent
  >();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      const fetchJobPosts = async (): Promise<void> => {
        try {
          setLoading(true);
          const res = await JobPostApi.getJobPostByCompanyId(id);
          setJobPosts(res.data);
        } catch (error: any) {
          console.error(
            'Error for useFetchJobPostsByCompanyId -> JobPostApi.getJobPostByCompanyId()',
            error
          );
        } finally {
          setLoading(false);
        }
      };

      if (getApplicants) {
        const fetchApplicants = async (): Promise<void> => {
          try {
            setLoading(true);
            const res = await JobApplicationApi.getCandidatesByCompanyId(id);
            setApplicantsPerJobId(res.data);
          } catch (error: any) {
            console.error(
              'Error for useFetchJobPostsByCompanyId -> JobApplicationApi.getCandidatesByCompanyId()',
              error
            );
          } finally {
            setLoading(false);
          }
        };
        fetchApplicants();
      }

      fetchJobPosts();
    }
  }, [id]);

  return { jobPosts, applicantsPerJobId, loading };
};
