import * as React from 'react';
import {
  ChefInstructorTimesheetInterface,
  GetTimesheetsForChefInstructorDto
} from '@api/models/careerServicesApi.models';
import { CareerServicesApi } from '@api/CareerService.api';

export type FetchChefInstructorTimesheets = {
  chefInstructorTimesheets: ChefInstructorTimesheetInterface[];
  setChefInstructorTimesheets: React.Dispatch<
    React.SetStateAction<ChefInstructorTimesheetInterface[]> // eslint-disable-line @typescript-eslint/indent
  >;
  fetchChefInstructorTimesheets: (
    data: GetTimesheetsForChefInstructorDto
  ) => Promise<void>;
  loaded: boolean;
  loading: boolean;
};

export const useFetchChefInstructorTimesheets =
  (): FetchChefInstructorTimesheets => {
    const [chefInstructorTimesheets, setChefInstructorTimesheets] =
      React.useState<ChefInstructorTimesheetInterface[]>([]);
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const fetchChefInstructorTimesheets = async (
      data: GetTimesheetsForChefInstructorDto
    ): Promise<void> => {
      try {
        setLoading(true);
        const res = await CareerServicesApi.getTimesheetsForChefInstructor(
          data
        );

        setChefInstructorTimesheets(res);
        setLoaded(true);
      } catch (error: any) {
        console.error(
          'Error for useFetchChefInstructorTimesheets -> ChefInstructorTimesheetApi.getChefInstructorTimesheets()',
          error
        );
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    };

    return {
      chefInstructorTimesheets,
      setChefInstructorTimesheets,
      fetchChefInstructorTimesheets,
      loaded,
      loading
    };
  };
