import * as React from 'react';
import { Box, Skeleton, Typography, useTheme } from '@mui/material';
import BasicModal from '@components/BasicModal';
import { CompanyApi } from '@api/Company.api';
import CompanyLogo from '@components/CompanyLogo';
import { CompanyPossibleSub } from '@api/models/adminDashboardApi.models';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { getFilteredSearchResults } from '@common/helpers/getFilteredSearchResults';
import IconWithLabel from '@components/IconWithLabel';
import SearchBar from '@components/SearchBar';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)({
    width: '100%',
    overflow: 'scroll'
  }),
  ListBox: styled(Box)({
    width: '100%',
    maxHeight: '336px'
  }),
  Row: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'selected'
  })<{ selected: boolean }>(({ selected, theme }) => ({
    ...(selected && {
      backgroundColor: theme.palette.GRAY_1.main
    }),
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    padding: '5px 0 5px 5px',
    borderRadius: '2px',
    border: selected
      ? `1px solid ${theme.palette.GOLD_1.main}`
      : `1px solid ${theme.palette.OUTLINE.main}`,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: theme.palette.GRAY_2.main
    }
  })),
  FlexRow: styled(Box)({
    display: 'flex',
    columnGap: '10px'
  }),
  CompanyName: styled(Typography)({
    textTransform: 'uppercase'
  })
};

interface Props {
  parentId: number;
  open: boolean;
  possibleSubsidiaries: CompanyPossibleSub[];
  loading: boolean;
  fetchSubsidiaries: (companyId: number) => Promise<void>;
  handleClose: () => void;
  setPossibleSubsidiaries: (selectedIds: number[]) => void;
}

interface RowProps {
  company: CompanyPossibleSub;
  isSelected: boolean;
}

const CompanyRow = ({ company, isSelected }: RowProps): React.ReactElement => {
  const theme = useTheme();
  const dateJoined = formatDate(company.createdDate, 'LLL dd, yyyy');

  return (
    <Styled.Row
      selected={isSelected}
      data-testid={`subsidiary-row-${company.id}`}
    >
      <CompanyLogo
        name={company.companyName}
        logo={company.logoUrl}
        tableDisplay
        disableElevation
        customStyles={{ height: '40px', width: '70px' }}
      />
      <Box ml="20px">
        <Styled.CompanyName
          variant="EC_TYPE_XS"
          data-testid="subsidiary-company-name"
        >
          {company.companyName} (ID {company.id})
        </Styled.CompanyName>
        <Styled.FlexRow>
          {!!company.admins[0]?.email && (
            <IconWithLabel
              title={company.admins[0]?.email}
              icon="ri-account-circle-fill"
              color={theme.palette.GRAY_3.main}
              smallest
              dataTestId="subsidiary-admin-icon-label"
            />
          )}
          <IconWithLabel
            title={`Joined: ${dateJoined}`}
            icon="ri-calendar-event-fill"
            color={theme.palette.GRAY_3.main}
            smallest
            dataTestId="subsidiary-joined-date"
          />
        </Styled.FlexRow>
      </Box>
    </Styled.Row>
  );
};

const AddSubsidiaryModal: React.FC<Props> = ({
  parentId,
  open,
  possibleSubsidiaries: _subsidiaries,
  loading,
  handleClose,
  fetchSubsidiaries,
  setPossibleSubsidiaries
}) => {
  const [subsidiaries, setSubsidiaries] = React.useState<CompanyPossibleSub[]>(
    []
  );
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [selected, setSelected] = React.useState<number[]>([]);
  const [error, setError] = React.useState<string | false>(false);

  React.useEffect(() => {
    setSubsidiaries(_subsidiaries);
  }, [_subsidiaries]);

  const searchParams: (keyof CompanyPossibleSub)[] = ['companyName', 'id'];

  const handleCloseAndClear = React.useCallback(() => {
    handleClose();
    setError(false);
    setSelected([]);
  }, [handleClose]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
  };

  const clearSearch = (): void => setSearchValue('');

  const handleSelectRow = (subId: number): void => {
    setSelected((prev) => {
      if (prev.includes(subId)) {
        return prev.filter((id) => id !== subId);
      } else {
        return [...prev, subId];
      }
    });
  };

  const handleAdd = async (): Promise<void> => {
    if (selected?.length) {
      await CompanyApi.addSubsidiariesToParent(parentId, selected).then(() => {
        setPossibleSubsidiaries(selected);
      });
    } else {
      setError('Please select at least one company to be a subsidiary');
    }

    if (!error) {
      handleCloseAndClear();
      fetchSubsidiaries(parentId);
    }
  };

  React.useEffect(() => {
    if (searchValue === '') {
      setSubsidiaries(_subsidiaries);
    }

    const filteredSubsidiaries = getFilteredSearchResults(
      _subsidiaries,
      searchParams,
      searchValue
    );

    setSubsidiaries(filteredSubsidiaries);
  }, [searchValue]);

  return (
    <BasicModal
      open={open}
      handleClose={handleCloseAndClear}
      maxWidth="sm"
      title="Add a subsidiary company"
      subTitle="Select the companies to join this parent."
      titleMarginBottom="8px"
      primaryAction={{ label: 'Add', action: handleAdd }}
      secondaryAction={{ label: 'Cancel', action: handleCloseAndClear }}
    >
      <Box sx={{ margin: '16px 0' }}>
        <SearchBar
          value={searchValue}
          onChange={handleSearch}
          clearSearch={clearSearch}
        />
      </Box>
      <Styled.Root>
        {!loading ? (
          <Styled.ListBox>
            {!!subsidiaries?.length &&
              subsidiaries?.map((c) => {
                const isSelected = selected.includes(c.id);

                return (
                  <Box key={c.id} onClick={(): void => handleSelectRow(c.id)}>
                    <CompanyRow company={c} isSelected={isSelected} />
                  </Box>
                );
              })}
          </Styled.ListBox>
        ) : (
          <Skeleton sx={{ transform: 'none' }} width="100%" height="336px" />
        )}
      </Styled.Root>
    </BasicModal>
  );
};

export default AddSubsidiaryModal;
