// Returns HH:MM by default
// If withHoursAndMinText is true then it will return X hours Y minutes
export const convertMinutesToHoursAndMinutes = (
  minutes: number,
  withHoursAndMinText?: boolean
): string => {
  const hours = Math.floor(minutes / 60); // Get the whole hours
  const remainingMinutes = minutes % 60; // Get the remaining minutes after removing the whole hours

  if (withHoursAndMinText) {
    let timeToReturn = '';

    if (hours) {
      timeToReturn += `${hours} hour${hours > 1 ? 's' : ''}`;
    }

    if (remainingMinutes) {
      timeToReturn += ` ${remainingMinutes} minute${
        remainingMinutes > 1 ? 's' : ''
      }`;
    }

    return timeToReturn;
  }

  if (!remainingMinutes) {
    return `${hours}:00`;
  }

  if (remainingMinutes < 10) {
    return `${hours}:0${remainingMinutes}`;
  }

  return `${hours}:${remainingMinutes}`;
};
