import * as React from 'react';
import QuizResultsPage from '@components/Quiz/QuizResults/QuizResultsPage';
import { useParams } from 'react-router-dom';

const CareerQuizResults = (): React.ReactElement => {
  const { id } = useParams<{ id: string }>();
  return (
    <QuizResultsPage
      quizId={parseInt(id)}
      pageTitle="your culinary personality"
      pageSubtitle="The results from your culinary personality assessment reveal which segments of the culinary and hospitality industry you may find the greatest success. Within each segment there are many different options to explore. Learn more about your top matches and get started on a culinary career path!"
      resultLabel="Your top career matches"
    />
  );
};

export default CareerQuizResults;
