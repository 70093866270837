import { useEffect, useState } from 'react';
import { AttestationType } from '@interfaces/attestation.interface';
import { Codebook } from '@api/models/codebook.models';
import { CodebookApi } from '@api/Codebook.api';

interface FetchAttestationTypeCodebooks {
  attestationTypes: Codebook<AttestationType>[];
  loading: boolean;
}

export const useFetchAttestationTypesCodebooks =
  (): FetchAttestationTypeCodebooks => {
    const [attestationTypes, setAttestationTypes] = useState<
      Codebook<AttestationType>[] // eslint-disable-line @typescript-eslint/indent
    >([]);
    const [loading, setLoading] = useState(false);

    const fetchData = async (): Promise<void> => {
      try {
        setLoading(true);
        const res = await CodebookApi.getAttestationTypes();
        setAttestationTypes(res.data);
      } catch (error: any) {
        console.error(
          'Error for useFetchAttestationTypesCodebooks -> CodebookApi.getAttestationTypes()',
          error
        );
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      fetchData();
    }, []);

    return { attestationTypes, loading };
  };
