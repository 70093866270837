import * as React from 'react';
import BasicModal from '@components/BasicModal';
import { ExternshipStatus } from '@api/models/externshipApi.models';
import { Typography } from '@mui/material';

interface Props {
  status: ExternshipStatus | null;
  closeModal: () => void;
  handleStatusChange: (status: ExternshipStatus) => Promise<void>;
}

const ConfirmExternshipStatusChangeModal: React.FC<Props> = ({
  status,
  closeModal,
  handleStatusChange
}) => {
  const [disableActions, setDisableActions] = React.useState(false);

  const isArchiving = status === ExternshipStatus.ARCHIVED;
  const title = isArchiving ? 'Archive Site' : 'Complete Site';
  const confirmText = isArchiving ? 'Archive' : 'Complete';

  const isArchivingText =
    'When you archive a site, timesheets will no longer be sent out to externs.';
  const isCompletingText =
    'When you complete a site, timesheets will no longer be sent out to externs.';

  const handleConfirm = async (): Promise<void> => {
    if (status) {
      setDisableActions(true);
      try {
        await handleStatusChange(status);
      } catch (error) {
        setDisableActions(false);
      }
    }
  };

  return (
    <BasicModal
      open={!!status}
      handleClose={closeModal}
      title={title}
      primaryAction={{
        label: confirmText,
        action: handleConfirm,
        disabled: disableActions
      }}
      secondaryAction={{
        label: 'Cancel',
        action: closeModal,
        disabled: disableActions
      }}
      maxWidth="sm"
    >
      <Typography variant="EC_TYPE_BASE">
        {isArchiving ? isArchivingText : isCompletingText}
      </Typography>
    </BasicModal>
  );
};

export default ConfirmExternshipStatusChangeModal;
