/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import ExternalEduLink from './ExternalEduLink';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const Styled = {
  PromoBox: styled(Box)({
    display: 'flex',
    height: 'max-content',
    backgroundColor: theme.palette.WHITE.main,
    borderRadius: '4px',
    borderLeft: `3px solid ${theme.palette.GOLD_1.main}`,
    boxShadow: '0px 0px 5px rgba(51, 51, 51, 0.4)',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      minHeight: '413px'
    }
  }),
  LeftSide: styled(Box)({
    padding: '32px 52px 44px',
    [theme.breakpoints.up('md')]: {
      flex: '1 1 583px',
      paddingBottom: '32px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '32px'
    },
    '& .promo-subtitle': {
      color: theme.palette.GOLD_1.main,
      margin: '32px 0 22px'
    }
  }),
  LeftContent: styled(Box)({
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }),
  Button: styled(Button)({
    marginTop: '32px',
    height: 'unset'
  }),
  RightSide: styled(Box)({
    '& .promo-img': {
      display: 'block',
      objectFit: 'cover',
      objectPosition: '50% 50%',
      height: '100%',
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      flex: '1 1 552px'
    }
  }),
  Image: styled('img')({
    marginBottom: '24px',
    width: '100%',
    maxWidth: '300px',
    height: 'auto'
  })
};

interface PromoBoxCta {
  text: string;
  path: string;
  handleClick?: any;
}
interface Props {
  title?: string;
  subTitle?: string;
  text: string;
  img: string;
  imgAlt: string;
  cta?: PromoBoxCta;
  contentImg?: string;
}

const PromoBox: React.FC<Props> = ({
  title,
  subTitle,
  text,
  img,
  imgAlt,
  cta,
  contentImg
}) => {
  return (
    <Styled.PromoBox data-testid="promo-box-root">
      <Styled.LeftSide>
        <Styled.LeftContent>
          <Box>
            {title && (
              <Typography variant="EC_TYPE_4XL" className="promo-title">
                {title}
              </Typography>
            )}
            {contentImg && (
              <Box sx={{ textAlign: 'center' }}>
                <Styled.Image src={contentImg} />
              </Box>
            )}
            {subTitle && (
              <Typography variant="EC_TYPE_LG" className="promo-subtitle">
                {subTitle}
              </Typography>
            )}
            <Typography
              variant="EC_TYPE_BASE"
              className="promo-info"
              textAlign={'center'}
            >
              {text}
            </Typography>
          </Box>
          {cta && (
            <>
              {cta.path.includes('www.escoffier.edu') ? (
                <ExternalEduLink link={cta.path}>
                  <Styled.Button
                    variant="contained"
                    className="ga-cta"
                    fullWidth
                  >
                    {cta.text}
                  </Styled.Button>
                </ExternalEduLink>
              ) : (
                <Link
                  component={RouterLink}
                  to={{ pathname: cta?.path }}
                  onClick={cta?.handleClick}
                  underline="none"
                >
                  <Styled.Button
                    variant="contained"
                    className="ga-cta"
                    fullWidth
                  >
                    {cta.text}
                  </Styled.Button>
                </Link>
              )}
            </>
          )}
        </Styled.LeftContent>
      </Styled.LeftSide>
      <Styled.RightSide>
        <img src={img} alt={imgAlt} className="promo-img" />
      </Styled.RightSide>
    </Styled.PromoBox>
  );
};

export default PromoBox;
