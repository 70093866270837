import * as React from 'react';
import { Box } from '@mui/material';
import { IExpandedFilters } from '@common/fetches/useFetchMarketplaceSearchResults';
import IndividualFilter from '@components/Filters/IndividualFilter';
import { styled } from '@mui/system';

const Styled = {
  Box: styled(Box)({
    marginBottom: '10px',
    '&:last-child': {
      marginBottom: 0
    }
  })
};

interface IndividualFilterWrapperProps {
  filterTitle: string;
  filterContent: React.ReactNode;
  expandedFilters: IExpandedFilters;
  setExpandedFilters: React.Dispatch<React.SetStateAction<IExpandedFilters>>;
}

const IndividualFilterWrapper: React.FC<IndividualFilterWrapperProps> = ({
  filterTitle,
  filterContent,
  expandedFilters,
  setExpandedFilters
}): JSX.Element => {
  return (
    <Styled.Box>
      <IndividualFilter
        filterTitle={filterTitle}
        filterContent={filterContent}
        expandedFilters={expandedFilters}
        setExpandedFilters={setExpandedFilters}
      />
    </Styled.Box>
  );
};

export default IndividualFilterWrapper;
