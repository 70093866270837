/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  Box,
  Button,
  Grid,
  Icon,
  Paper,
  Theme,
  Typography
} from '@mui/material';
import {
  ExternshipEntity,
  ExternshipStatus,
  StudentViewExternship
} from '@api/models/externshipApi.models';
import {
  formatDate,
  newFormatDate
} from '@common/helpers/dateHelpers/formatDate';
import { FormProvider, useForm } from 'react-hook-form';
import {
  INACTIVE_STATUSES,
  studentViewFilterMap
} from '@pages/CareerServices/StudentPage/studentViewFilters.constants';
import {
  SiteInfoEditableSections,
  StudentViewSiteInfoProvider
} from '@common/context/studentViewSiteInfoContext';
import AddApprover from './AddApprover';
import { CareerServicesApi } from '@api/CareerService.api';
import DetailsCard from '@pages/CareerServices/StudentPage/StudentSiteInfo/DetailsCard';
import { ExternshipApi } from '@api/Externships.api';
import { Location } from '@interfaces/location.interfaces';
import NoDataMessage from '@components/NoDataMessage';
import { ExternshipProposalApproversUpsertPayload, ExternshipProposalApprover } from '@api/models/externshipProposalApi.models';
import SimpleBannerWithCta from '@components/AlertBanners/SimpleBannerWithCta';
import StatusUpdateMenu from '@pages/CareerServices/StudentPage/StatusUpdateMenu';
import StudentPageCardHeader from '@pages/CareerServices/StudentPage/StudentPageCardHeader';
import StudentSiteInfoDetail from '@pages/CareerServices/StudentPage/StudentSiteInfo/StudentSiteInfoDetail';
import { styled } from '@mui/system';
import { useStudentViewContext } from '@common/context/studentViewContext';
import { useToast } from '@components/Toast';
import ExternshipApproversDetails from './ExternshipApproversDetails';
import { sortExternshipProposalApprovers } from '@common/helpers/externshipHelpers/sortExternshipProposalApprovers';

export interface SiteInfoCardFormValues {
  companyName: string;
  jobTitle: string;
  jobDescription: string;
  approvers: ExternshipProposalApprover[];
  location: Location;
  customLocation: boolean;
  customLocationObject: Location;
  phone?: string;
  streetName?: string;
  streetNumber?: string;
  addressLineTwo?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
};

const Styled = {
  CardRoot: styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.WHITE.main,
    overflow: 'hidden',
    position: 'relative'
  })),
  PaddingBox: styled(Box)({
    padding: '32px'
  }),
  Line: styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.GRAY_5.main,
    height: '1px',
    width: '100%',
    margin: '16px 0px 24px'
  })),
  FlexRowBox: styled(Box)({
    display: 'flex',
    gap: '16px',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  }),
  Icon: styled(Icon, {
    shouldForwardProp: (prop) => prop !== 'iconColor'
  })<{ iconColor?: string }>(({ theme, iconColor }) => ({
    ...(iconColor ? { color: theme.palette[iconColor].main } : {})
  })),
  DetailsCard: styled(Box)(({ theme }) => ({
    padding: '0px 8px 16px',
    border: `1px solid ${theme.palette.GRAY_5.main}`,
    borderRadius: '4px',
    height: '100%'
  })),
  DetailsCardTitle: styled(Typography)(({ theme }) => ({
    padding: '16px 16px 8px 16px',
    borderBottom: `1px solid ${theme.palette.GRAY_5.main}`,
    marginBottom: '8px'
  })),
  ApproversWrapper: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px'
  }),
  ApproverTitle: styled(Typography)({
    textTransform: 'uppercase',
    marginBottom: '16px'
  }),
  AddApproverWrapper: styled(Box)(({ theme }) => ({
    paddingTop: '10px',
    marginTop: '20px',
    borderTop: `1px solid ${theme.palette.GRAY_5.main}`,
    width: '100%'
  })),
  AddApproverBtn: styled(Button)(({ theme }) => ({
    ...(theme as Theme).typography.EC_TYPE_SM,
    '&.MuiButtonBase-root:hover': {
      bgcolor: 'transparent'
    },
    marginTop: '5px',
    color: theme.palette.primary.main
  })),
  AddSupervisorIcon: styled(Icon)({
    marginLeft: '2px',
    marginRight: '-6px'
  })
};

interface SiteInfoCardProps {
  externship: StudentViewExternship;
}

const SiteInfoCard: React.FC<SiteInfoCardProps> = ({ externship: _externship }) => {
  const { openToast } = useToast();
  const {
    allExternships,
    setAllExternships,
    externships,
    setExternships,
    currentFilter
  } = useStudentViewContext();

  const [editingSections, setEditingSections] = React.useState<SiteInfoEditableSections[]>([]);
  const [addApproverModal, setAddApproverModal] = React.useState<boolean>(false);
  const [approversErrorMessage, setApproversErrorMessage] = React.useState<string | undefined>();

  // Used to track edits made during soft verification step
  const externshipRef = React.useRef<StudentViewExternship>(_externship);
  const externship = externshipRef.current;

  const getIsEditingSection = (sec: SiteInfoEditableSections): boolean => {
    return editingSections.includes(sec);
  };

  const {
    proposedCompany,
    jobTitle,
    jobDescription,
    currentProgram,
    course,
    scheduledStartDate,
    externshipAgreement,
    eligibleCoursesForRenewal
  } = externship;

  const unassignedExternship = !course.StudentEnrollmentPeriodId;

  const courseStartDateChanged = externship.courseScheduleChange;

  const isSoftVerificationStatus =
    externship.status === ExternshipStatus.PENDING_SOFT_VERIFICATION;

  const showSoftVerifyBanner =
    isSoftVerificationStatus &&
    !editingSections.length &&
    !unassignedExternship &&
    !courseStartDateChanged;

  const displayStartDateChangedBanner =
    courseStartDateChanged && !unassignedExternship;

  const methods = useForm<SiteInfoCardFormValues>({
    defaultValues: {
      companyName: '',
      jobTitle,
      jobDescription: jobDescription ?? '',
      approvers: sortExternshipProposalApprovers(externship.approvers),
    }
  });

  const handleSetExternshipsState = (
    responseExternship: ExternshipEntity | StudentViewExternship,
    section?: SiteInfoEditableSections
  ): void => {
    // Keep any properties from the current externship that are not updated
    // Override any with the response values as needed
    const newExternship = {
      ...externshipRef.current,
      ...responseExternship,
      course: externshipRef.current.course, // The initial course info has more data than the response. Keep it.
    };
    externshipRef.current = newExternship;

    if (section) {
      setEditingSections((prev) => [...prev].filter((s) => s !== section));
      openToast(`${section} updated`);
    }
  };

  const handleSavePositionInfo = async (data: SiteInfoCardFormValues): Promise<void> => {
    const reqBody = {
      jobTitle: data.jobTitle,
      jobDescription: data.jobDescription
    };

    const response = await ExternshipApi.updateStudentPositionInfo(
      externship.id,
      reqBody
    );

    handleSetExternshipsState(response, 'Position');
  };

  const handleSaveExternshipProposalApprovers = async (data: SiteInfoCardFormValues): Promise<void> => {
    const payload: ExternshipProposalApproversUpsertPayload = {
      approvers: data.approvers
    };

    setApproversErrorMessage(undefined);

    try {
      const response = await ExternshipApi.upsertExternshipProposalApprovers(externship.id, payload);

      handleSetExternshipsState(response, 'Approvers');

      setExternships((prev) => {
        return [...prev].map((e) => {
          if (e.id === externship.id) {
            return {
              ...response,
              hasValidSiteAgreement: e.hasValidSiteAgreement,
              eligibleCoursesForRenewal: e.eligibleCoursesForRenewal,
              courseScheduleChange: e.courseScheduleChange,
              course: e.course
            };
          }
          return { ...e };
        });
      });
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setApproversErrorMessage(error.response.data.message);
      }
    }
  };

  const handleSaveCompanyName = async (data: SiteInfoCardFormValues): Promise<void> => {
    let mainLocation: Location;

    if (!data.customLocation) {
      const { location } = data;
      mainLocation = { ...location, name: data.companyName };
    } else {
      const location: Location = {
        lat: 0,
        lng: 0,
        name: data.companyName,
        description: `${data.companyName}, ${data.streetNumber} ${
          data.streetName
        },${data.addressLineTwo && ` ${data.addressLineTwo},`} ${data.city}, ${
          data.state
        }, ${data.country}`,
        streetNumber: data.streetNumber,
        addressLineTwo: data.addressLineTwo,
        city: data.city,
        streetName: data.streetName,
        state: data.state,
        zip: data.postalCode,
        country: data.country,
        phone: data.phone,
        placeId: null
      };
      mainLocation = location;
    }

    const reqBody: { location: Location } = {
      location: mainLocation
    };

    const response = await ExternshipApi.updateProposedLocation(
      externship.id,
      reqBody
    );

    handleSetExternshipsState(response, 'Company');

    setExternships((prev) => {
      return [...prev].map((e) => {
        if (e.id === externship.id) {
          const newExternshipInfo = {
            ...e,
            ...response,
            course: e.course, // The initial course info has more data than the response. Keep it.
          };
          return newExternshipInfo;
        }
        return { ...e };
      });
    });
  };

  const handleSoftVerifySend = async (): Promise<void> => {
    try {
      const res = await ExternshipApi.softVerifyExternshipProposal(
        externship.id
      );

      const externshipMapperForStatusUpdate = (
        e: StudentViewExternship
      ): StudentViewExternship => (e.id === res.id ? res : e);

      const updatedExternships = externships
        .map(externshipMapperForStatusUpdate)
        .filter((e) => studentViewFilterMap[currentFilter].includes(e.status));

      const updatedAllExternships = allExternships.map(
        externshipMapperForStatusUpdate
      );

      setExternships(updatedExternships);
      setAllExternships(updatedAllExternships);

      handleSetExternshipsState(res);

      openToast(
        `Externship proposal for ${proposedCompany?.location.name} verified`
      );
    } catch (error: any) {
      console.error(
        `Error for SiteInfoCard -> ExternshipApi.softVerifyExternshipProposal: ${error}`
      );
    }
  };

  const handleSoftVerifyReject = async (): Promise<void> => {
    try {
      await ExternshipApi.softVerifyExternshipProposalReject(externship.id);

      const newExternship = { ...externshipRef.current };

      const externshipMapperForStatusUpdate = (
        e: StudentViewExternship
      ): StudentViewExternship =>
        e.id === newExternship.id
          ? { ...e, status: ExternshipStatus.CS_REJECTED }
          : e;

      const updatedExternships = externships
        .map(externshipMapperForStatusUpdate)
        .filter((e) => studentViewFilterMap[currentFilter].includes(e.status));

      const updatedAllExternships = allExternships.map(
        externshipMapperForStatusUpdate
      );

      setExternships(updatedExternships);
      setAllExternships(updatedAllExternships);
    } catch (error: any) {
      console.error(
        `Error for SiteInfoCard -> ExternshipApi.softVerifyExternshipProposalReject: ${error}`
      );
    } finally {
      openToast(
        `Externship proposal for ${proposedCompany?.location.name} rejected`
      );
    }
  };

  const handleRenewExternship = async (): Promise<void> => {
    const { Id, CourseCode } = eligibleCoursesForRenewal[0];

    try {
      const res = await CareerServicesApi.renewExternship(externship.id, Id);

      // Should this be sorted by date?
      setExternships((prev) => {
        return [...prev, res].map((e) => {
          if (e.id === externship.id) {
            e.eligibleCoursesForRenewal.shift();
          }
          return { ...e };
        });
      });
    } catch (error: any) {
      console.error(
        `Error for SiteInfoCard -> CareerServicesApi.renewExternship: ${error}`
      );
    } finally {
      openToast(
        `Site for ${externship.company.name} renewed for ${CourseCode}`
      );
    }
  };

  const handleStartDateChangeAcknowledgement = async (): Promise<void> => {
    try {
      const res = await CareerServicesApi.acknowledgeStartDateChange(
        externship.id
      );

      const externshipMapperForStatusUpdate = (
        e: StudentViewExternship
      ): StudentViewExternship => (e.id === res.id ? res : e);

      const updatedExternships = externships
        .map(externshipMapperForStatusUpdate)
        .filter((e) => studentViewFilterMap[currentFilter].includes(e.status));

      const updatedAllExternships = allExternships.map(
        externshipMapperForStatusUpdate
      );
      setExternships(updatedExternships);
      setAllExternships(updatedAllExternships);
    } catch (error: any) {
      console.error(
        `Error for SiteInfoCard -> ExternshipApi.acknowledgeStartDateChange: ${error}`
      );
    }
  };

  const getCompensation = (): string => {
    if (!externshipAgreement) {
      return '';
    }

    if (externshipAgreement?.compensationType === 'unpaid') {
      return 'UNPAID';
    }

    return `$${externshipAgreement.payRate}/hr`;
  };

  const asUtc = true;
  const courseInfo = [
    { label: 'Program', value: currentProgram.label },
    { label: 'Course', value: course.CourseCode },
    { label: 'Req Hrs', value: Number(course.ClockHours) },
    {
      label: 'Ext. Start Date',
      value: formatDate(scheduledStartDate, 'MM/dd/yy', false, asUtc)
    },
    {
      label: 'Site Agreement',
      value: externship.hasValidSiteAgreement ? (
        <Styled.Icon
          fontSize="small"
          className="ri-checkbox-circle-fill"
          iconColor="GREEN_1"
        />
      ) : (
        <Styled.Icon
          fontSize="small"
          className="ri-close-circle-fill"
          iconColor="error"
        />
      )
    }
  ];

  const getStartDate = (): string => {
    const startDate =
      externship.externshipAgreement?.revisedEmploymentStartDate ||
      externship.externshipAgreement?.employmentStartDate ||
      '';

    if (!startDate) {
      return '';
    }

    const asUtc = true;
    return formatDate(startDate, 'MM/dd/yy', false, asUtc);
  };

  const positionDetails = [
    {
      label: 'Title',
      value: jobTitle,
      inputName: 'jobTitle'
    },
    {
      label: 'Start Date',
      value: getStartDate()
    },
    {
      label: 'Compensation',
      value: getCompensation()
    },
    {
      label: 'Job Description Duties',
      value: externship.jobDescription,
      inputName: 'jobDescription'
    }
  ];

  const eligbleStatusesForAddingApprovers = [
    ExternshipStatus.PENDING,
    ExternshipStatus.PENDING_EXT_MNG_AGREEMENT,
    ExternshipStatus.PENDING_EXT_STD_AGREEMENT,
    ExternshipStatus.PENDING_EXT_CS_AGREEMENT,
    ExternshipStatus.PENDING_PROXY_AGREEMENT,
    ExternshipStatus.ACTIVE
  ];

  return (
    <Styled.CardRoot elevation={3}>
      {addApproverModal && (
        <AddApprover
          open={addApproverModal}
          closeModal={(): void => {
            setAddApproverModal(false);
          }}
          externship={externship}
        />
      )}
      <StatusUpdateMenu externship={externship} />
      <Styled.PaddingBox>
        <FormProvider {...methods}>
          <StudentViewSiteInfoProvider
            value={{
              editingSections,
              setEditingSections,
              getIsEditing: getIsEditingSection
            }}
          >
            <StudentPageCardHeader
              variant={2}
              externship={externship}
              displayMap
              showEditCompanyName
              methods={methods}
              editingSections={editingSections}
              setEditingSections={setEditingSections}
              handleValidForm={handleSaveCompanyName}
            />
            <DetailsCard title="Externship" disableBorder>
              <Styled.FlexRowBox>
                {/* COURSE INFO SECTION */}
                {courseInfo.map((i) => (
                  <StudentSiteInfoDetail
                    key={i.label}
                    label={i.label}
                    value={i.value}
                  />
                ))}
              </Styled.FlexRowBox>
            </DetailsCard>
            {!unassignedExternship && (
              <Grid container spacing={2} mt={2}>
                {/* POSITION SECTION */}
                <Grid item xs={12} sm={6}>
                  <DetailsCard
                    title="Position"
                    allowEditing={isSoftVerificationStatus}
                    handleValidForm={handleSavePositionInfo}
                  >
                    <Grid container spacing={2}>
                      {positionDetails.map((i, idx) => (
                        <StudentSiteInfoDetail
                          key={i.label}
                          label={i.label}
                          value={i.value}
                          inputName={i.inputName}
                          useTextArea={idx === 3}
                        />
                      ))}
                    </Grid>
                  </DetailsCard>
                </Grid>
                {/* APPROVERS SECTION */}
                <Grid item xs={12} sm={6}>
                  <DetailsCard
                    title="Approvers"
                    allowEditing={isSoftVerificationStatus}
                    handleValidForm={handleSaveExternshipProposalApprovers}
                    errorMessage={approversErrorMessage}
                    cancelEditCallback={() => setApproversErrorMessage(undefined)}
                  >
                    {!externship.approvers.length ? (
                      <NoDataMessage title={'No Approvers'} />
                    ) : (
                      <Styled.ApproversWrapper>
                        <ExternshipApproversDetails externship={externship} />
                      </Styled.ApproversWrapper>
                    )}
                    {eligbleStatusesForAddingApprovers.includes(
                      externship.status
                    ) && (
                        <Styled.AddApproverWrapper>
                          <Styled.AddApproverBtn
                            variant="text"
                            startIcon={
                              <Styled.AddSupervisorIcon
                                style={{ fontSize: 19 }}
                                className="ri-user-add-line"
                              />
                            }
                            onClick={(): void => {
                              setAddApproverModal(true);
                            }}
                          >
                            Add Approver
                          </Styled.AddApproverBtn>
                        </Styled.AddApproverWrapper>
                      )}
                  </DetailsCard>
                </Grid>
              </Grid>
            )}
          </StudentViewSiteInfoProvider>
        </FormProvider>
      </Styled.PaddingBox>
      {displayStartDateChangedBanner && (
        <SimpleBannerWithCta
          title="Start Date Change"
          subtitle={`This externship course now starts on ${newFormatDate(
            scheduledStartDate
          )}`}
          ctaPropsSend={{
            text: 'Okay',
            onClick: handleStartDateChangeAcknowledgement
          }}
        />
      )}
      {unassignedExternship && (
        <SimpleBannerWithCta
          title="Outdated Proposal"
          subtitle={`The student is no longer enrolled in course ${course.Code
            } (${newFormatDate(
              scheduledStartDate
            )}) for which this site was originally proposed and has not been re-enrolled for ${course.Code
            } starting on a dfferent date.`}
        />
      )}
      {showSoftVerifyBanner && (
        <SimpleBannerWithCta
          title="Approve Soft Verification"
          ctaPropsSend={{
            text: 'Send',
            onClick: handleSoftVerifySend
          }}
          ctaPropsReject={{
            text: 'Reject',
            onClick: handleSoftVerifyReject
          }}
        />
      )}
      {/* Only allow extension for the next eligible course - will either be a retake OR 2nd externship course */}
      {!unassignedExternship &&
        !!eligibleCoursesForRenewal?.length &&
        !courseStartDateChanged && (
          <SimpleBannerWithCta
            title={'Site Extension'}
            subtitle={`Duplicate this site information and use the existing agreements for ${eligibleCoursesForRenewal[0].CourseCode
              } starting ${formatDate(
                eligibleCoursesForRenewal[0].StartDate,
                undefined,
                undefined,
                true
              )}.`}
            ctaPropsSend={{
              text: 'Renew Site',
              onClick: handleRenewExternship
            }}
          />
        )}
    </Styled.CardRoot>
  );
};

const StudentSiteInfo: React.FC = () => {
  const { externships } = useStudentViewContext();

  return (
    <Box>
      <Typography variant="EC_TYPE_3XL">Site Information</Typography>
      {!!externships?.length &&
        externships.map((externship) => (
          <Box
            key={externship.id}
            sx={{
              marginBottom: '50px',
              filter: INACTIVE_STATUSES.includes(externship.status)
                ? 'grayscale(100%)'
                : 'none'
            }}
          >
            <SiteInfoCard externship={externship} />
          </Box>
        ))}
    </Box>
  );
};

export default StudentSiteInfo;
