import * as React from 'react';
import { IKeycloakUser, roles } from '@api/models/userApi.models';
import { useSetStoreValue, useStore } from 'react-context-hook';
import { ApiResponse } from '@api/models/base.models';
import { AuthClientTokens } from '@react-keycloak/core/lib/types';
import { CandidateApi } from '@api/Candidate.api';
import { CandidateProfile } from '@api/models/candidateApi.models';
import { CompanyApi } from '@api/Company.api';
import { EmployerProfile } from '@api/models/employerApi.models';
import { GraduationTypes } from '@api/models/adminDashboardApi.models';
import keycloak from '../../keycloak';
import { KeycloakContextProvider } from '@common/context/keycloakContext';
import { PartnerApi } from '@api/Partner.api';
import { PermissionsApi } from '@api/Permissions.api';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { SettingsApi } from '@api/Settings.api';
import { useExternshipPilotEnabled } from '@common/helpers/externshipHelpers/useExternshipPilotEnabled';
import { useFetchSubsidiariesByCompanyId } from '@common/fetches/useFetchSubsidiariesByCompanyId';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';

type PageProps = {
  children: React.ReactNode;
};

const KeycloakProviderWrapper: React.FC<PageProps> = ({ children }) => {
  const { setIsPilotEnabled } = useExternshipPilotEnabled();

  const [keycloakInitialized, setKeycloakInitialized] = React.useState(false);
  const [dataFetched, setDataFetched] = React.useState(false);

  const isAdmin = keycloak.hasResourceRole(roles.ADMIN);
  const isCandidate = keycloak.hasResourceRole(roles.CANDIDATE);
  const isEcAdmin = keycloak.hasResourceRole(roles.ESCOFFIER_ADMIN);
  const isEcCareerService = keycloak.hasResourceRole(roles.EC_CAREER_SERVICE);
  const isEcApprover = keycloak.hasResourceRole(roles.ESCOFFIER_APPROVER);
  const isChefInstructor = keycloak.hasResourceRole(roles.CHEF_INSTRUCTOR);
  const isEmployer = keycloak.hasResourceRole(roles.EMPLOYER);
  const isRecruiter = keycloak.hasResourceRole(roles.RECRUITER);
  const isPartner = keycloak.hasResourceRole(roles.PARTNER);
  const isGlrcUser = keycloak.hasResourceRole(roles.GLRC_USER);
  const isPendingUser = keycloak.hasResourceRole(roles.PENDING_USER);
  const isGlrcRecruiter = isGlrcUser && isRecruiter;
  const hasValidRoleOtherThanEcApprover =
    isAdmin ||
    isCandidate ||
    isEcAdmin ||
    isEcCareerService ||
    isChefInstructor ||
    isEmployer ||
    isRecruiter ||
    isPartner ||
    isGlrcUser ||
    isGlrcRecruiter ||
    isPendingUser;

  const hasValidRole =
    hasValidRoleOtherThanEcApprover ||
    isEcApprover;

  const isAuthenticated = keycloak.authenticated;
  const keycloakUser = keycloak.userInfo as IKeycloakUser;

  const [companyId, setCompanyId] = useStore<number>(UseStoreKeys.COMPANY_ID);
  const setCompanyLogo = useSetStoreValue<string | undefined>(
    UseStoreKeys.COMPANY_LOGO
  );
  const setCandidateId = useSetStoreValue<number>(UseStoreKeys.CANDIDATE_ID);
  const setCandidateEnabled = useSetStoreValue<boolean>(
    UseStoreKeys.CANDIDATE_ENABLED
  );
  const setPartnerEmployeeId = useSetStoreValue<number | undefined>(
    UseStoreKeys.PARTNER_EMPLOYEE_ID
  );
  const setEmployerId = useSetStoreValue<number>(UseStoreKeys.EMPLOYER_ID);
  const setIsEnabledCompany = useSetStoreValue(UseStoreKeys.COMPANY_ENABLED);
  const setIsParentCompany = useSetStoreValue(UseStoreKeys.IS_PARENT_COMPANY);
  const setRole = useSetStoreValue(UseStoreKeys.ROLE);
  const [permissions, setPermissions] = useStore(UseStoreKeys.PERMISSIONS);
  const setUserIdpArray = useSetStoreValue(UseStoreKeys.USER_IDP_ARRAY);
  const setCandidateStudentId = useSetStoreValue<string | undefined | null>(
    UseStoreKeys.CANDIDATE_STUDENT_ID
  );
  const setIsEcStudent = useSetStoreValue(UseStoreKeys.IS_EC_STUDENT);

  // Handle user token expiration (time out)
  const setTokenExpired = useSetStoreValue<boolean>(UseStoreKeys.TOKEN_EXPIRED);
  keycloak.onTokenExpired = (): void => {
    setTokenExpired(true);
  };

  const { subsidiaries, loaded } = useFetchSubsidiariesByCompanyId(companyId);

  const fetchPermissions = async (): Promise<void> => {
    const permissions = await PermissionsApi.getPermissions();
    setPermissions(permissions);
  };

  React.useEffect(() => {
    if (keycloakInitialized) {
      fetchPermissions();
    }
  }, [keycloakInitialized]);

  React.useEffect(() => {
    setIsParentCompany(!!subsidiaries?.length && isEmployer);
  }, [subsidiaries, isEmployer]);

  const fetchData = async (): Promise<void> => {
    if (keycloakInitialized) {
      const user = await keycloak.loadUserInfo().catch(() => {
        setDataFetched(true);
      });
      const keycloakUser: IKeycloakUser = user as IKeycloakUser;
      const keycloakUserId = keycloakUser?.sub ?? null;

      if (keycloakUserId) {
        const userIdpRes = await SettingsApi.getUserIdp(keycloakUserId);
        if (userIdpRes.data) {
          setUserIdpArray(userIdpRes.data.map((d) => d.identityProvider));
        }
      }

      if (isEmployer || isRecruiter) {
        const employer: ApiResponse<EmployerProfile> =
          await CompanyApi.getByKeycloakId(keycloakUserId);
        setEmployerId(employer.data.id);
        setCompanyId(employer.data.company.id);
        setIsEnabledCompany(employer.data.company.enabled);
        setCompanyLogo(employer.data.company?.logo?.fullPath);
        if (isEmployer) {
          setRole(roles.EMPLOYER);
        }
        if (isRecruiter) {
          setRole(roles.RECRUITER);
        }
      } else if (isCandidate) {
        const candidate: ApiResponse<CandidateProfile> =
          await CandidateApi.getByKeycloakId(keycloakUserId);

        setCandidateId(candidate.data.id);
        setCandidateStudentId(candidate?.data?.studentId);
        setIsPilotEnabled(!!candidate?.data?.externshipPilotEnabled);
        setCandidateEnabled(!!candidate?.data.enabled);
        setIsEcStudent(
          candidate?.data?.graduationType?.value === GraduationTypes.EC_STUDENT
        );
        setRole(roles.CANDIDATE);
      } else if (isPartner) {
        const partnerEmployee = await PartnerApi.getPartnerEmployeeByKeycloakId(
          keycloakUserId
        );

        setPartnerEmployeeId(partnerEmployee?.data?.id);
      } else if (isGlrcUser) {
        const employer = await CompanyApi.getByKeycloakId(keycloakUserId);

        setCompanyLogo(employer?.data?.company?.logo?.fullPath);
        setCompanyId(employer?.data?.company.id);
        setRole(roles.GLRC_USER);
      } else if (isEcAdmin) {
        setRole(roles.ESCOFFIER_ADMIN);
      } else if (isEcCareerService) {
        setRole(roles.EC_CAREER_SERVICE);
      } else if (isChefInstructor) {
        setRole(roles.CHEF_INSTRUCTOR);
      } else if (isPendingUser) {
        setRole(roles.PENDING_USER);
      }

      setDataFetched(true);
    }
  };

  React.useEffect(() => {
    if (keycloakInitialized) {
      fetchData();
    }
  }, [keycloakInitialized]);

  // If data has been fetched and companyId is not defined then we do not have an employer logged in
  // This means isParentCompany will be false
  // If loaded is true it means companyId was found and subsidiaries were retrieved so
  // isParentCompany will be true if array is not empty.
  const isParentSet = (dataFetched && !companyId) || loaded;

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: 'check-sso' }}
      onTokens={(tokens: AuthClientTokens): void => {
        localStorage.setItem('token', tokens.token ?? '');

        setKeycloakInitialized(true);
      }}
    >
      {keycloakInitialized && dataFetched && isParentSet && !!permissions && (
        <KeycloakContextProvider
          value={{
            isAdmin,
            isCandidate,
            isEcAdmin,
            isEcCareerService,
            isEcApprover,
            isChefInstructor,
            isEmployer,
            isRecruiter,
            isPartner,
            isGlrcUser,
            isGlrcRecruiter,
            isPendingUser,
            keycloakUser,
            hasValidRole,
            isAuthenticated,
            hasValidRoleOtherThanEcApprover,
          }}
        >
          {children}
        </KeycloakContextProvider>
      )}
    </ReactKeycloakProvider>
  );
};

export default KeycloakProviderWrapper;
