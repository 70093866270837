/* eslint-disable @typescript-eslint/indent */
import {
  CreateMorePartnerDto,
  MorePartners
} from '@interfaces/morePartners.interfaces';
import {
  DirectConnectPost,
  DirectConnectPostCreateReq,
  HotJob,
  PremierPartner,
  PromotedCompany,
  PromotedCompanyReq
} from '@api/models/adminContentManagementApi.models';
import { GlrcContent, GlrcContentPutReq } from '@interfaces/glrc.interfaces';
import {
  WorkLearnPartnerEntity,
  WorkLearnPartnerPutReq
} from '@interfaces/workLearnPartner.interfaces';
import { api } from '@api/configs/base';
import { apiConfig } from '@api/configs/api.config';
import { ApiResponse } from '@api/models/base.models';
import { CompanyProfile } from './models/companyApi.models';
import { JobPost } from '@api/models/singleJobPostApi.models';

export class AdminContentManagementApi {
  static async getHotJobs(): Promise<HotJob[]> {
    try {
      const response = await api.get<ApiResponse<HotJob[]>>(
        `${apiConfig.adminContentManagementUrl}/promoted-job-posts`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for ContentManagementApi.getHotJobs(): ${error}`);
      throw error;
    }
  }

  static async addHotJob(id: number): Promise<ApiResponse<JobPost>> {
    try {
      const response = await api.post<ApiResponse<JobPost>>(
        `${apiConfig.adminContentManagementUrl}/promoted-job-posts/${id}`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for ContentManagementApi.addHotJob(): ${error}`);
      throw error;
    }
  }

  static async deleteHotJob(id: number): Promise<HotJob[]> {
    try {
      const response = await api.delete<ApiResponse<HotJob[]>>(
        `${apiConfig.adminContentManagementUrl}/promoted-job-posts/${id}`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for ContentManagementApi.deleteHotJob(): ${error}`);
      throw error;
    }
  }

  static async moveHotJobUp(id: number): Promise<void> {
    try {
      await api.post(
        `${apiConfig.adminContentManagementUrl}/promoted-job-posts/${id}/up`
      );

      return;
    } catch (error: any) {
      console.error(`Error for ContentManagementApi.moveHotJobUp(): ${error}`);
    }
  }

  static async moveHotJobDown(id: number): Promise<void> {
    try {
      await api.post(
        `${apiConfig.adminContentManagementUrl}/promoted-job-posts/${id}/down`
      );

      return;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.moveHotJobDown(): ${error}`
      );
    }
  }

  static async getPromotedCompanies(): Promise<PromotedCompany[]> {
    try {
      const response = await api.get<ApiResponse<PromotedCompany[]>>(
        `${apiConfig.adminContentManagementUrl}/promoted-companies`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.getPromotedCompanies(): ${error}`
      );
      throw error;
    }
  }

  static async addPromotedCompany(
    data: PromotedCompanyReq
  ): Promise<CompanyProfile> {
    console.log('addPromotedCompany', data);

    try {
      const response = await api.post<ApiResponse<CompanyProfile>>(
        `${apiConfig.adminContentManagementUrl}/promoted-companies`,
        data
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.addPromotedCompany(): ${error}`
      );
      throw error;
    }
  }

  static async editPromotedCompany(
    data: PromotedCompanyReq
  ): Promise<PromotedCompany> {
    try {
      const response = await api.put<ApiResponse<PromotedCompany>>(
        `${apiConfig.adminContentManagementUrl}/promoted-companies/${data.companyId}`,
        data
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.editPromotedCompany(): ${error}`
      );
      throw error;
    }
  }

  static async deletePromotedCompany(id: number): Promise<CompanyProfile> {
    try {
      const response = await api.delete<ApiResponse<CompanyProfile>>(
        `${apiConfig.adminContentManagementUrl}/promoted-companies/${id}`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.deletePromotedCompany(): ${error}`
      );
      throw error;
    }
  }

  static async createDirectConnectPost(
    data: DirectConnectPostCreateReq
  ): Promise<void> {
    console.log('createDirectConnectPost', data);

    try {
      await api.post<ApiResponse<void>>(
        `${apiConfig.adminContentManagementUrl}/direct-connect-job-posts`,
        data
      );

      return;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.createDirectConnectPost(): ${error}`
      );
      throw error;
    }
  }

  static async getDirectConnectPosts(): Promise<DirectConnectPost[]> {
    try {
      const response = await api.get<ApiResponse<DirectConnectPost[]>>(
        `${apiConfig.adminContentManagementUrl}/direct-connect-job-posts`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.getDirectConnectPosts(): ${error}`
      );
      throw error;
    }
  }

  static async updateDirectConnectPost(
    id: number,
    data: DirectConnectPostCreateReq
  ): Promise<void> {
    console.log(`updateDirectConnectPost with id ${id}`, data);

    try {
      await api.put<ApiResponse<void>>(
        `${apiConfig.adminContentManagementUrl}/direct-connect-job-posts/${id}`,
        data
      );

      return;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.updateDirectConnectPost(): ${error}`
      );
      throw error;
    }
  }

  static async deleteDirectConnectPost(id: number): Promise<void> {
    console.log(`deleteDirectConnectPost with id ${id}`);

    try {
      await api.delete<ApiResponse<void>>(
        `${apiConfig.adminContentManagementUrl}/direct-connect-job-posts/${id}`
      );

      return;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.deleteDirectConnectPost(): ${error}`
      );
      throw error;
    }
  }

  static async addPromotedPartner(id: number): Promise<PremierPartner[]> {
    try {
      const response = await api.post<ApiResponse<PremierPartner[]>>(
        `${apiConfig.adminContentManagementUrl}/promoted-partners/${id}`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.addPromotedPartner(): ${error}`
      );
      throw error;
    }
  }

  static async getPremierPartners(): Promise<PremierPartner[]> {
    try {
      const response = await api.get<ApiResponse<PremierPartner[]>>(
        `${apiConfig.adminContentManagementUrl}/promoted-partners`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.getPremierPartners(): ${error}`
      );
      throw error;
    }
  }

  static async deletePremierPartner(id: number): Promise<PremierPartner[]> {
    try {
      const response = await api.delete<ApiResponse<PremierPartner[]>>(
        `${apiConfig.adminContentManagementUrl}/promoted-partners/${id}`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.deletePremierPartner(): ${error}`
      );
      throw error;
    }
  }

  // Work & learn partners
  static async getWorkLearnPartners(): Promise<WorkLearnPartnerEntity[]> {
    try {
      const response = await api.get<ApiResponse<WorkLearnPartnerEntity[]>>(
        `${apiConfig.adminContentManagementUrl}/work-learn-partners`
      );
      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.getWorkLearnPartners(): ${error}`
      );
      throw error;
    }
  }

  static async createWorkLearnPartner(
    companyId: number
  ): Promise<WorkLearnPartnerEntity[]> {
    try {
      const response = await api.post<ApiResponse<WorkLearnPartnerEntity[]>>(
        `${apiConfig.adminContentManagementUrl}/work-learn-partners/${companyId}`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.createWorkLearnPartner(): ${error}`
      );
      throw error;
    }
  }

  static async moveWorkLearnPartnerUp(companyId: number): Promise<void> {
    try {
      await api.post<ApiResponse<WorkLearnPartnerEntity>>(
        `${apiConfig.adminContentManagementUrl}/work-learn-partners/${companyId}/up`
      );

      return;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.moveWorkLearnPartnerUp(): ${error}`
      );
      throw error;
    }
  }

  static async moveWorkLearnPartnerDown(companyId: number): Promise<void> {
    try {
      await api.post<ApiResponse<WorkLearnPartnerEntity>>(
        `${apiConfig.adminContentManagementUrl}/work-learn-partners/${companyId}/down`
      );

      return;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.moveWorkLearnPartnerDown(): ${error}`
      );
      throw error;
    }
  }

  static async updateWorkLearnPartner(
    data: WorkLearnPartnerPutReq
  ): Promise<void> {
    const { companyId } = data;

    try {
      await api.put<ApiResponse<void>>(
        `${apiConfig.adminContentManagementUrl}/work-learn-partners/${companyId}`,
        data
      );

      return;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.updateWorkLearnPartner(): ${error}`
      );
      throw error;
    }
  }

  static async deleteWorkLearnPartner(companyId: number): Promise<void> {
    try {
      await api.delete<ApiResponse<void>>(
        `${apiConfig.adminContentManagementUrl}/work-learn-partners/${companyId}`
      );
      return;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.deleteWorkLearnPartner(): ${error}`
      );
      throw error;
    }
  }

  // More Partners - Displayed on Candidate work and learn page
  static async getMorePartners(): Promise<MorePartners[]> {
    try {
      const response = await api.get<ApiResponse<MorePartners[]>>(
        `${apiConfig.adminContentManagementUrl}/more-partners`
      );
      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.getMorePartners(): ${error}`
      );
      throw error;
    }
  }

  static async createMorePartner(
    data: CreateMorePartnerDto
  ): Promise<MorePartners> {
    try {
      const response = await api.post<ApiResponse<MorePartners>>(
        `${apiConfig.adminContentManagementUrl}/more-partners`,
        data
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.createMorePartner(): ${error}`
      );
      throw error;
    }
  }

  static async deleteMorePartner(partnerId: number): Promise<MorePartners> {
    try {
      const response = await api.delete<ApiResponse<MorePartners>>(
        `${apiConfig.adminContentManagementUrl}/more-partners/${partnerId}`
      );
      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.deleteMorePartner(): ${error}`
      );
      throw error;
    }
  }

  // ESource (GLRC) Content Section
  static async getVideosForEcAdmin(): Promise<GlrcContent[]> {
    try {
      const res = await api.get<ApiResponse<GlrcContent[]>>(
        `${apiConfig.adminContentManagementUrl}/glrc-content/videos`
      );

      return res.data.data;
    } catch (error: any) {
      console.error(
        `Error for ContentManagementApi.getVideosForEcAdmin(): ${error}`
      );
      throw error;
    }
  }

  static async getVimeoDataByUrl(videoId: string): Promise<GlrcContent> {
    try {
      const res = await api.get<ApiResponse<any>>(
        `${apiConfig.adminContentManagementUrl}/glrc-content/vimeoUrl/${videoId}`
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for GlrcContentApi.getVimeoDataByUrl(): ${error}`);
      throw error;
    }
  }

  static async createGlrcContent(
    content: GlrcContentPutReq
  ): Promise<GlrcContent> {
    try {
      const res = await api.post<ApiResponse<GlrcContent>>(
        `${apiConfig.adminContentManagementUrl}/glrc-content`,
        content
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for GlrcContentApi.createGlrcContent(): ${error}`);
      throw error;
    }
  }

  static async updateGlrcContent(
    contentId: number,
    content: GlrcContentPutReq
  ): Promise<GlrcContent> {
    try {
      const res = await api.put<ApiResponse<GlrcContent>>(
        `${apiConfig.adminContentManagementUrl}/glrc-content/${contentId}`,
        content
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for GlrcContentApi.updateGlrcContent(): ${error}`);
      throw error;
    }
  }

  static async deleteGlrcContent(contentId: number): Promise<boolean> {
    try {
      const res = await api.delete<ApiResponse<boolean>>(
        `${apiConfig.adminContentManagementUrl}/glrc-content/${contentId}`
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for GlrcContentApi.deleteGlrcContent(): ${error}`);
      throw error;
    }
  }
}
