import { useEffect, useState } from 'react';
import { PromotedCompanySpotlight } from '@api/models/searchApi.models';
import { PromotedJob } from '@api/models/singleJobPostApi.models';
import { SearchApi } from '@api/Search.api';

interface FetchPromotedContent {
  promotedJobs: PromotedJob[];
  employerSpotlight: PromotedCompanySpotlight[];
  loading: boolean;
  loaded: true | null;
}

const shuffleArray = (jobArray: PromotedJob[]): PromotedJob[] => {
  return jobArray
    .map((job) => ({ job, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ job }) => job);
};

export const useFetchPromotedContent = (
  randomizeJobs: boolean | undefined = false
): FetchPromotedContent => {
  const [promotedJobs, setPromotedJobs] = useState<PromotedJob[]>([]);
  const [employerSpotlight, setEmployerSpotlight] = useState<
    PromotedCompanySpotlight[] // eslint-disable-line @typescript-eslint/indent
  >([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  const fetchPromotedContent = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await SearchApi.getPromotedContent();

      const jobArray = randomizeJobs
        ? shuffleArray(res.data.promotedJobs)
        : res.data.promotedJobs;

      setPromotedJobs(jobArray);
      setEmployerSpotlight(res.data.employerSpotlight);
    } catch (error: any) {
      console.error(
        'Error for useFetchPromotedContent -> SearchApi.getPromotedContent()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  useEffect(() => {
    fetchPromotedContent();
  }, []);

  return { promotedJobs, employerSpotlight, loading, loaded };
};
