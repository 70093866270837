import * as React from 'react';
import {
  Box,
  ContainerProps,
  SxProps,
  Theme,
  Typography,
  TypographyProps
} from '@mui/material';
import EmptyPlate from '@assets/empty-plate.png';
import EscoffierLogo from '@assets/png/escoffier-global-solutions-logo.png';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)({
    display: 'flex',
    marginBottom: '150px',
    justifyContent: 'center'
  }),
  WrapReverseWrapper: styled(Box)({
    display: 'flex',
    flexWrap: 'wrap-reverse',
    alignContent: 'center',
    justifyContent: 'center'
  }),
  Image: styled('img')({
    display: 'block',
    objectPosition: 'center',
    maxWidth: '420px',
    width: '100%',
    minWidth: '250px',
    objectFit: 'scale-down'
  }),
  TextWrapper: styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '60px 0px',
    flexBasis: '310px',
    flexGrow: 1
  }),
  SubText: styled(Typography)(({ theme }) => ({
    color: theme.palette.GRAY_4.main,
    marginTop: '12px'
  })),
  Logo: styled('img')({
    opacity: '0.25',
    maxWidth: '277px',
    position: 'absolute',
    bottom: '0px',
    left: '50%',
    transform: 'translateX(-50%)',
    marginBottom: '48px'
  })
};

interface Props {
  header: string;
  subtext: string | JSX.Element;
  disableImage?: boolean;
  headerVariant?: TypographyProps['variant'];
  headerTextStyle?: SxProps<Theme>;
  rootMaxWidth?: ContainerProps['maxWidth'];
}

const ErrorPageLayout: React.FC<Props> = ({
  header,
  subtext,
  disableImage = false,
  headerVariant = 'EC_TYPE_5XL',
  headerTextStyle,
  rootMaxWidth = 'xl'
}) => {
  return (
    <RootPageLayout muiMaxWidth={rootMaxWidth}>
      <Styled.Root>
        <Styled.WrapReverseWrapper>
          {!disableImage && (
            <Styled.Image
              src={EmptyPlate}
              alt="empty-plate"
              data-testid="error-page-layout-img"
            />
          )}
          <Styled.TextWrapper
            sx={{ ...(disableImage && { textAlign: 'center' }) }}
          >
            <Typography
              variant={headerVariant}
              sx={headerTextStyle}
              data-testid="error-page-layout-header"
            >
              {header}
            </Typography>
            <Styled.SubText
              data-testid="error-page-layout-subtext"
              variant="EC_TYPE_3XL"
            >
              {subtext}
            </Styled.SubText>
          </Styled.TextWrapper>
        </Styled.WrapReverseWrapper>
        <Styled.Logo src={EscoffierLogo} alt="escoffier-connect-logo" />
      </Styled.Root>
    </RootPageLayout>
  );
};

export default ErrorPageLayout;
