import { CompanyTheme } from '@api/models/companyApi.models';
import { createCustomContext } from '@common/context/customContext';

/**
 * CustomThemeContextProvider is used in CustomThemeWrapper.tsx to provide
 * the app with CompanyTheme data for the GLRC.
 *
 * useCustomThemeContext is to gain access to
 * CompanyTheme data.
 */
export const [useCustomThemeContext, CustomThemeContextProvider] =
  createCustomContext<CompanyTheme | undefined>();
