import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SxProps,
  Theme,
  Typography
} from '@mui/material';

type PromiseOrVoid = Promise<void> | void;

type HandleConfirm =
  | (() => PromiseOrVoid)
  | ((e: React.MouseEvent<HTMLButtonElement>) => PromiseOrVoid);

export interface EcDialogProps {
  title: string;
  content?: string;
  confirmActionText?: string;
  denyActionText?: string;
  open: boolean;
  handleClose: () => void;
  handleConfirm?: HandleConfirm;
  disableConfirmAction?: boolean;
  dataTestId?: string;
  contentStyle?: SxProps<Theme>;
  fullWidth?: boolean;
  children?: React.ReactNode;
}

const EcDialog: React.FC<EcDialogProps> = ({
  title,
  content,
  confirmActionText,
  denyActionText,
  open,
  handleClose,
  handleConfirm,
  disableConfirmAction = false,
  dataTestId,
  contentStyle,
  fullWidth = true,
  children
}) => {
  return (
    <Dialog
      fullWidth={fullWidth}
      open={open}
      onClose={handleClose}
      data-testid={dataTestId}
    >
      <DialogTitle id="alert-dialog-title" data-testid="dialog-title">
        {title}
        <IconButton
          data-testid="dialog-close-icon"
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: (theme): string => theme.palette.GRAY_3.main
          }}
        >
          <i className="ri-close-line"></i>
        </IconButton>
      </DialogTitle>
      <DialogContent data-testid="dialog-content" sx={{ ...contentStyle }}>
        {content && (
          <Typography variant="EC_TYPE_BASE" sx={{ marginTop: '24px' }}>
            {content}
          </Typography>
        )}
        {children}
      </DialogContent>
      {confirmActionText && (
        <DialogActions>
          {denyActionText && (
            <Button
              data-testid="dialog-deny-button"
              onClick={handleClose}
              variant="outlined"
              sx={{ ...(fullWidth && { minWidth: '116px' }) }}
            >
              {denyActionText}
            </Button>
          )}
          <Button
            data-testid="dialog-close-button"
            onClick={handleConfirm}
            variant="contained"
            disabled={disableConfirmAction}
            autoFocus
            sx={{ ...(fullWidth && { minWidth: '116px' }) }}
          >
            {confirmActionText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default EcDialog;
