/* eslint-disable @typescript-eslint/indent */
import {
  ExternshipActivityType,
  ExternshipEntity,
  ExternshipProgressType,
  ExternshipWithActivityAndProgress
} from '@api/models/externshipApi.models';
import { useEffect, useState } from 'react';
import { ActivityLogEntity } from '@api/models/careerServicesApi.models';
import { CareerServicesApi } from '@api/CareerService.api';

export type ExternshipWithActivity = Omit<
  ExternshipWithActivityAndProgress,
  'progress'
>;
export type ExternshipWithProgress = Omit<
  ExternshipWithActivityAndProgress,
  'activity'
>;
type ExternshipWithActivityOrProgress =
  | ExternshipWithActivity
  | ExternshipWithProgress;

interface FetchExternshipsWithActivity<
  T extends ExternshipWithActivityOrProgress
> {
  externships: T[];
  setExternships: React.Dispatch<React.SetStateAction<T[]>>;
}

const defaultProgress = [
  {
    checked: false,
    date: '',
    description: ExternshipProgressType.SITE_VERIFIED,
    logType: ExternshipActivityType.VERIFICATION,
    progressType: ExternshipProgressType.SITE_VERIFIED,
    user: '',
    userId: '',
    comments: [] as ActivityLogEntity[]
  },
  {
    checked: false,
    date: '',
    description: ExternshipProgressType.SUPERVISOR_VERIFIED,
    logType: ExternshipActivityType.VERIFICATION,
    progressType: ExternshipProgressType.SUPERVISOR_VERIFIED,
    user: '',
    userId: '',
    comments: [] as ActivityLogEntity[]
  },
  {
    checked: false,
    date: '',
    description: ExternshipProgressType.EMAIL_VERIFIED,
    logType: ExternshipActivityType.VERIFICATION,
    progressType: ExternshipProgressType.EMAIL_VERIFIED,
    user: '',
    userId: '',
    comments: [] as ActivityLogEntity[]
  },
  {
    checked: false,
    date: '',
    description: ExternshipProgressType.JOB_DESC_VERIFIED,
    logType: ExternshipActivityType.VERIFICATION,
    progressType: ExternshipProgressType.JOB_DESC_VERIFIED,
    user: '',
    userId: '',
    comments: [] as ActivityLogEntity[]
  },
  {
    checked: false,
    date: '',
    description: ExternshipProgressType.EMPLOYMENT_VERIFIED,
    logType: ExternshipActivityType.VERIFICATION,
    progressType: ExternshipProgressType.EMPLOYMENT_VERIFIED,
    user: '',
    userId: '',
    comments: [] as ActivityLogEntity[]
  }
];

export const fetchExternshipActivity = async (
  externshipId: number
): Promise<ActivityLogEntity[]> => {
  const res = await CareerServicesApi.getExternshipActivity(externshipId);

  return res.data;
};

export const fetchExternshipProgress = async (
  externshipId: number
): Promise<ActivityLogEntity[]> => {
  const res = await CareerServicesApi.getExternshipProgress(externshipId);

  const progressTypes: string[] = Object.values(ExternshipProgressType);

  let progressArray: ActivityLogEntity[] = [];

  if (!res?.data?.length) {
    progressArray = defaultProgress as ActivityLogEntity[];
  } else {
    progressTypes.forEach((pt) => {
      if (res?.data) {
        const findItem = res.data.find((item) => item.progressType === pt);

        if (findItem) {
          progressArray.push(findItem);
        } else {
          const defaultItem = defaultProgress.find(
            (item) => item.progressType === pt
          ) as ActivityLogEntity;

          progressArray.push(defaultItem);
        }
      }
    });
  }

  // EXTENSION_VERIFIED is not a checkbox so filter out the undefined defaultItem
  return progressArray.filter((p) => !!p);
};

export const useFetchExternshipsWithActivityOrProgress = <
  T extends ExternshipWithActivityOrProgress
>(
  _externships: ExternshipEntity[],
  externshipType: 'activity' | 'progress'
): FetchExternshipsWithActivity<T> => {
  const [externships, setExternships] = useState<T[]>([]);

  useEffect(() => {
    let isMounted = true;

    const createExternshipsWithActivityOrProgress = async (): Promise<void> => {
      const retArray: T[] = [];

      if (_externships.length) {
        for (const externship of _externships) {
          if (externshipType === 'activity') {
            const activity = await fetchExternshipActivity(externship.id);

            const externshipWithActivity: ExternshipWithActivity = {
              ...externship,
              activity
            };

            retArray.push(externshipWithActivity as T);
          }

          if (externshipType === 'progress') {
            // remove externsion verified since we do not want cs to uncheck this box
            const progress = (
              await fetchExternshipProgress(externship.id)
            ).filter(
              (p) =>
                p.progressType !== ExternshipProgressType.EXTENSION_VERIFIED
            );

            if (externship.id === 220) console.log('progress', progress);

            const externshipWithProgress: ExternshipWithProgress = {
              ...externship,
              progress
            };

            retArray.push(externshipWithProgress as T);
          }
        }

        if (isMounted) {
          setExternships(retArray);
        }
      }
    };

    createExternshipsWithActivityOrProgress();

    return (): void => {
      isMounted = false;
    };
  }, [_externships]);

  return { externships, setExternships };
};
