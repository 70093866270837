import * as React from 'react';
import BottomPagination from '@components/BottomPagination';
import { Box } from '@mui/material';
import CandidateMarketplaceCard from './CandidateMarketplaceCard';
import { CandidateProfile } from '@api/models/candidateApi.models';
import FiltersResultsBar from '@components/FiltersResultsBar';
import { IPageInfoRes } from '@interfaces/pageInfo.interfaces';
import { styled } from '@mui/system';

const Styled = {
  CandidateRowContainer: styled(Box)({
    '& > :not(:last-child)': {
      marginBottom: '10px'
    }
  })
};

interface Props {
  candidates: CandidateProfile[];
  pageInfo: IPageInfoRes;
  defaultPageNumber: number;
  goToPage: (pageNumber: number) => void;
}

const CandidateMarketplaceMainContent: React.FC<Props> = ({
  candidates,
  pageInfo,
  defaultPageNumber,
  goToPage
}) => {
  const handleChange = (event, value: number): void => {
    goToPage(value);
  };

  return (
    <Box>
      <Box data-testid="jobMarketplaceCardsWrapper">
        <FiltersResultsBar availableItems="Candidates" pageInfo={pageInfo} />
        <Styled.CandidateRowContainer>
          {candidates.map((candidate, idx) => {
            return (
              <CandidateMarketplaceCard
                key={`${candidate.id}-${idx}`}
                candidate={candidate}
              />
            );
          })}
        </Styled.CandidateRowContainer>
      </Box>
      <BottomPagination
        count={pageInfo?.totalPages}
        page={pageInfo?.currentPage}
        onChange={handleChange}
        defaultPage={defaultPageNumber}
      />
    </Box>
  );
};

export default CandidateMarketplaceMainContent;
