/**
 * Prepends a path string to a base URL if an ID exists in route params.
 * Useful for getting a route for an EC Admin
 * @param {string} baseUrl base URL to prepend `paramsId` to, or return if no paramsId
 * @param {string | undefined | null} paramsId ID from route params
 * @returns {string} URL with appropriate path prepended, or base URL if no paramsId
 * @example const url = getCompanyPath(`/job/${10}/edit`, 20);
 * // '/employer/10/job/20/edit'
 * const otherUrl = getCompanyPath(`/job/${10}/edit`, undefined);
 * // '/job/10/edit'
 */
export const getCompanyPath = (
  baseUrl: string,
  paramsId?: string | number | null
): string => {
  if (paramsId) {
    return `/employer/${paramsId}${baseUrl}`;
  }

  return baseUrl;
};

/**
 * Prepends '/employer/paramsId' if paramsId exists, '/employer' if not.
 * @param baseUrl
 * @param paramsId
 * @returns
 */
export const getEmployerPathWithParamsId = (
  baseUrl: string,
  paramsId?: string | number | null
): string => {
  if (paramsId) {
    return `/employer/${paramsId}${baseUrl}`;
  }

  return `/employer${baseUrl}`;
};
