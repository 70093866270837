import * as React from 'react';
import { Box, Typography } from '@mui/material';
import TimeCardCourseCard, {
  TimeCardCourseCardProps
} from './TimeCardCourseCard';
import { ExternshipEntity } from '@api/models/externshipApi.models';

interface Props {
  externships?: ExternshipEntity[];
}

const StudentPageTimeCards: React.FC<Props> = ({ externships }) => {
  const externshipsByCourse = React.useMemo(() => {
    const courseMap: { [key: string]: TimeCardCourseCardProps } = {};
    externships?.forEach((e) => {
      const { course } = e;

      if (!courseMap[course.Id]) {
        courseMap[course.Id] = { course, externships: [] };
      }

      courseMap[course.Id].externships.push(e);
    });

    return Object.values(courseMap);
  }, [externships]);

  return (
    <Box>
      <Typography variant="EC_TYPE_3XL">Time Card</Typography>
      {!!externshipsByCourse?.length &&
        externshipsByCourse.map((externship) => (
          <Box key={externship.course.Id} sx={{ mb: '50px' }}>
            <TimeCardCourseCard {...externship} />
          </Box>
        ))}
    </Box>
  );
};

export default StudentPageTimeCards;
