import * as React from 'react';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import { useResponsiveLayoutContext } from '@components/Layout/ResponsiveLayout';

const Styled = {
  Link: styled(Link)({
    margin: '15px 0',
    textDecoration: 'none'
  }),
  Root: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'customBreakpointReached'
  })<{ customBreakpointReached: boolean }>(({ customBreakpointReached }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    marginBottom: customBreakpointReached ? '20px' : '35px'
  })),
  TopBarIcon: styled('i')({
    marginRight: '12px'
  })
};

interface TopBarProps {
  topBarProps: {
    iconName?: string;
    buttonText: string;
  };
  isCandidatePage: boolean;
  onClick?: () => void;
  hideBtn?: boolean;
  buttonStatus?: boolean;
}

const PreviewPageTopBar: React.FC<TopBarProps> = (props) => {
  const { topBarProps, hideBtn, onClick, buttonStatus } = props;
  const { customBreakpointReached } = useResponsiveLayoutContext();

  return (
    <>
      {!props.isCandidatePage && (
        <Styled.Root
          data-testid="topBarContainer"
          customBreakpointReached={customBreakpointReached}
        >
          {!hideBtn && (
            <Button
              variant={customBreakpointReached ? 'text' : 'outlined'}
              onClick={onClick}
              disabled={buttonStatus ? true : false}
              data-testid="top-bar-btn"
            >
              <Styled.TopBarIcon className={`${topBarProps.iconName}`} />
              {topBarProps.buttonText}
            </Button>
          )}
        </Styled.Root>
      )}
    </>
  );
};

export default PreviewPageTopBar;
