import * as React from 'react';
import { Box, Card, Grid, Link, Typography, useTheme } from '@mui/material';
import {
  convertGlrcContentToGlrcVideo,
  formatSecondsToTime
} from '../GlrcContent/glrc.helpers';
import { GlrcContent } from '@interfaces/glrc.interfaces';
import GlrcEmbeddedVideo from '../GlrcEmbeddedVideo';
import { styled } from '@mui/system';
import { useTagManager } from '@common/hooks/useTagManager';

const Styled = {
  CourseImage: styled('img')({
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'left'
  })
};

interface Props {
  esourceContent: GlrcContent;
}

export enum EsourceCourseTypes {
  FULL = 1,
  MICRO = 2
}

const SearchResultItem: React.FC<Props> = ({ esourceContent }) => {
  const theme = useTheme();
  const { sendToGtm } = useTagManager();

  const image =
    esourceContent.courseType && esourceContent.thumbnail
      ? require(`@assets/LearningCenter/Courses/${esourceContent.thumbnail}`)
          .default // eslint-disable-line @typescript-eslint/indent
      : '';

  const handleCourseClick = (): void => {
    console.log('object');
    sendToGtm('glrc_full_course_click', {
      glrc_course_id: esourceContent.id,
      glrc_course_title: esourceContent.title
    });
  };

  const coursesProps = {
    component: Link,
    target: '_blank',
    rel: 'noopener noreferrer',
    href: esourceContent.link,
    onClick: handleCourseClick
  };

  const conditionalProps = esourceContent.courseType ? coursesProps : {};
  return (
    <Card sx={{ marginBottom: '20px', padding: '18px' }}>
      <Box
        sx={{
          textDecoration: 'none',
          cursor: esourceContent.courseType ? 'pointer' : 'default'
        }}
        {...conditionalProps}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} md={4} lg={3}>
            <Box sx={{ height: '100%' }}>
              {!esourceContent.courseType ? (
                <Box>
                  <GlrcEmbeddedVideo
                    {...convertGlrcContentToGlrcVideo(esourceContent)}
                    bookmarked={false}
                    userId={'userId'}
                    withFooter={false}
                    smScreenHeight="150px"
                  />
                </Box>
              ) : (
                <Card sx={{ height: '100%' }}>
                  <Styled.CourseImage
                    src={image}
                    alt={`${esourceContent.title} image`}
                    data-testid="full-course-card-img"
                  />
                </Card>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="EC_TYPE_2XS"
                  color={theme.palette.GOLD_1.main}
                >
                  TITLE
                </Typography>
                <Typography variant="EC_TYPE_2XL" paddingBottom={3}>
                  {esourceContent.title}
                </Typography>
              </Grid>

              <Grid item xs={6} md={3}>
                <Typography
                  variant="EC_TYPE_2XS"
                  color={theme.palette.GOLD_1.main}
                >
                  CONTENT TYPE
                </Typography>
                <Typography variant="EC_TYPE_2XL" paddingBottom={3}>
                  {
                    esourceContent.courseType === EsourceCourseTypes.FULL
                      ? 'Course'
                      : esourceContent.courseType === EsourceCourseTypes.MICRO
                      ? 'QuickCourse' // eslint-disable-line @typescript-eslint/indent
                      : !esourceContent.courseType // eslint-disable-line @typescript-eslint/indent
                      ? 'QuickSkill' // eslint-disable-line @typescript-eslint/indent
                      : '' // eslint-disable-line @typescript-eslint/indent
                  }
                </Typography>
              </Grid>

              <Grid item xs={6} md={3} textAlign={'end'}>
                <Typography
                  variant="EC_TYPE_2XS"
                  color={theme.palette.GOLD_1.main}
                >
                  {!esourceContent.courseType ? 'LENGTH' : 'LESSONS'}
                </Typography>
                <Typography variant="EC_TYPE_2XL" paddingBottom={3}>
                  {!esourceContent.courseType
                    ? formatSecondsToTime(Number(esourceContent.lengthOfVideo))
                    : esourceContent.numberOfLessons}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                {esourceContent.description ? (
                  <>
                    <Typography
                      variant="EC_TYPE_2XS"
                      color={theme.palette.GOLD_1.main}
                    >
                      DESCRIPTION
                    </Typography>
                    <Typography width={'95%'} variant="EC_TYPE_2XS">
                      {esourceContent.description}
                    </Typography>
                  </>
                ) : (
                  <>
                    {esourceContent?.tags && (
                      <>
                        <Typography
                          variant="EC_TYPE_2XS"
                          color={theme.palette.GOLD_1.main}
                        >
                          TAGS
                        </Typography>
                        <Typography
                          variant="EC_TYPE_LG"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {esourceContent?.tags?.replace(/,/g, ';')}
                        </Typography>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default SearchResultItem;
