import * as React from 'react';
import {
  ExternshipEntity,
  SiteAgreementData
} from '@api/models/externshipApi.models';
import ExternshipsTable from '@pages/Employer/EmployerDashboard/ExternshipsTable/ExternshipsTable';
import TableWrapperWithSort from '@components/TableWrapperWithSort';

const sortButtons = [
  { label: 'Created At', id: 'id' },
  { label: 'Status', id: 'status' },
  { label: 'Externship Start', id: 'scheduledStartDate' }
];

interface Props {
  employerId: number;
  externships: ExternshipEntity[];
  siteAgreementData?: SiteAgreementData;
  setExternships: React.Dispatch<
    React.SetStateAction<ExternshipEntity[] | undefined> // eslint-disable-line @typescript-eslint/indent
  >;
}

const EmployerDashExternshipsSection: React.FC<Props> = ({
  employerId,
  externships,
  siteAgreementData,
  setExternships
}) => {
  return (
    <>
      <TableWrapperWithSort
        headerTitle="Externships"
        sortButtons={sortButtons}
        initialSortOrder="DESC"
        initialSortCategory="scheduledStartDate"
        displayMobile={false}
        disabled={externships.length < 2}
        initialRowsPerPage={externships.length}
        styledHeader
      >
        <ExternshipsTable
          employerId={employerId}
          externships={externships}
          setExternships={setExternships}
          siteAgreementData={siteAgreementData}
        />
      </TableWrapperWithSort>
    </>
  );
};

export default EmployerDashExternshipsSection;
