import * as React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import FormFieldErrorText from './Forms/FormFieldErrorText';
import { styled } from '@mui/system';

const Styled = {
  Title: styled(Typography)(({ theme }) => ({
    color: theme.palette.GRAY_4.main
  })),
  IconButton: styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: 16,
    fontSize: '16px',
    lineHeight: '16px',
    top: 16,
    color: theme.palette.GRAY_3.main,
    ['@media (max-width: 599px)']: {
      top: 10,
      right: 10
    }
  })),
  CloseIcon: styled('i')(({ theme }) => ({
    color: theme.palette.GRAY_3.main
  })),
  ButtonWrapper: styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    flexWrap: 'wrap-reverse',
    width: '100%'
  })
};

export interface Action {
  label: string;
  action: (e?) => void;
  disabled?: boolean;
}

export enum BasicModalActionStyle {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

interface Props {
  title?: string;
  subTitle?: string | JSX.Element;
  open: boolean;
  handleClose?: (e?) => void;
  maxWidth?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  primaryAction?: Action;
  secondaryAction?: Action;
  titleMarginBottom?: string;
  actionStyle?: BasicModalActionStyle;
  errorMsg?: string;
}

const BasicModal: React.FC<Props> = ({
  title,
  subTitle,
  open,
  handleClose,
  children,
  maxWidth = 'sm',
  primaryAction,
  secondaryAction,
  titleMarginBottom = '24px',
  actionStyle = 'primary',
  errorMsg = ''
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  interface ActionButtonProps extends ButtonProps {
    actionItem: Action;
    primaryBtn?: boolean;
  }

  const ActionButton: React.FC<ActionButtonProps> = ({
    primaryBtn,
    actionItem,
    variant,
    color = 'primary'
  }) => {
    const btnAction = primaryBtn ? 'primary' : 'secondary';

    return (
      <Button
        sx={{ height: '42px', minWidth: '142px' }}
        fullWidth={isSmall}
        data-testid={`${btnAction}-btn`}
        onClick={actionItem.action}
        variant={variant}
        color={color}
        className={btnAction}
        disabled={actionItem.disabled}
      >
        {actionItem.label}
      </Button>
    );
  };

  const BasicDialogActions: React.FC = () => {
    if (actionStyle === BasicModalActionStyle.SECONDARY) {
      return (
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          {primaryAction && (
            <ActionButton
              primaryBtn
              actionItem={primaryAction}
              variant="contained"
            />
          )}
          {secondaryAction && (
            <ActionButton actionItem={secondaryAction} variant="text" />
          )}
        </DialogActions>
      );
    }

    return (
      <DialogActions>
        <Styled.ButtonWrapper>
          {secondaryAction && (
            <ActionButton
              actionItem={secondaryAction}
              variant="outlined"
              color="neutral"
            />
          )}
          {primaryAction && (
            <ActionButton
              primaryBtn
              actionItem={primaryAction}
              variant="contained"
            />
          )}
        </Styled.ButtonWrapper>
      </DialogActions>
    );
  };

  return (
    <Dialog
      data-testid={`${title}-modal`}
      fullWidth
      maxWidth={maxWidth}
      open={open}
      scroll="paper"
      onClose={handleClose ?? undefined}
    >
      {!!handleClose && (
        <Styled.IconButton aria-label="close" onClick={handleClose}>
          <Styled.CloseIcon className="ri-close-line" />
        </Styled.IconButton>
      )}
      <DialogContent>
        <>
          {title && (
            <Styled.Title variant="EC_TYPE_3XL" mb={titleMarginBottom}>
              {title}
            </Styled.Title>
          )}
          {typeof subTitle === 'string' ? (
            <Typography variant="EC_TYPE_BASE">{subTitle}</Typography>
          ) : (
            subTitle
          )}
          {children}
        </>
      </DialogContent>
      {errorMsg && (
        <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <FormFieldErrorText
            message={errorMsg}
            disableAbsolutePosition
            name={'undo-timesheet'}
          />
        </DialogContent>
      )}
      {(primaryAction || secondaryAction) && <BasicDialogActions />}
    </Dialog>
  );
};

export default BasicModal;
