import { EmployerProfile } from './employerApi.models';

export const frequencyOptions = [
  { id: 0, label: 'When Received', name: 'Immediately' },
  { id: 3, label: 'Daily Digest', name: 'Daily' },
  { id: 6, label: 'Weekly Digest', name: 'Weekly' }
];

export interface FrequencyPutReq {
  frequencyId: number;
  employerId?: number;
  isEnabled: boolean;
  userId?: string;
}

export interface GetFrequency {
  id: number;
  frequencyId: number;
  employer?: EmployerProfile;
  userId?: string;
  isEnabled: boolean;
}
