/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import { CompanyTheme } from '@api/models/companyApi.models';
import { CustomThemeContextProvider } from '@common/context/customThemeContext';
import theme from '@themes/ui/escoffier';
import { useFetchCompanyTheme } from '@common/fetches/useFetchCompanyTheme';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';

const CompanyThemeProviderWrapper: React.FC = ({ children }) => {
  const { companyId } = useParamsOrStoreCompanyId();
  const { companyTheme, loaded } = useFetchCompanyTheme(companyId);

  if (!loaded) {
    return <></>;
  }

  const customTheme = createTheme({
    ...theme,
    ...(companyTheme
      ? {
          palette: {
            ...theme.palette,
            primary: {
              main: companyTheme?.primaryColor || theme.palette.primary.main
            },
            secondary: {
              main: companyTheme?.secondaryColor || theme.palette.secondary.main
            }
          }
        }
      : {})
  });

  return (
    <CustomThemeContextProvider
      value={companyTheme ? { ...companyTheme } : ({} as CompanyTheme)}
    >
      <ThemeProvider theme={customTheme}>{children}</ThemeProvider>
    </CustomThemeContextProvider>
  );
};

export default CompanyThemeProviderWrapper;
