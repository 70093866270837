import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import { CompanyApi } from '@api/Company.api';
import { CompanyMediaPutRequest } from '@api/models/companyApi.models';
import FormActionsComponent from '@components/Forms/FormActionsComponent';
import FormWrapper from '@components/FormWrapper';
import ImageUploadWell from '@components/ImageUpload/ImageUploadWell';
import { MediaType } from '@api/models/mediaApi.models';
import PageDescriptionComponent from '@components/Layout/Common/PageDescriptionComponent';
import PageFormLayoutComponent from '@components/Layout/PageFormLayoutComponent';
import StepSection from '@components/StepSection';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';

const IMAGE_UPLOAD_LIMIT = 16;

interface FormValues {
  photoIds: number[];
  videoIds: number[];
}

interface Props {
  handleStepper: (next: boolean) => void;
}

const PhotosStep: React.FC<Props> = ({ handleStepper }) => {
  const { companyId } = useParamsOrStoreCompanyId();

  const [media, setMedia] = React.useState<MediaType[]>([]);
  const [initialPhotos, setInitialPhotos] = React.useState<number[]>([]);
  const [initialVideos, setInitialVideos] = React.useState<number[]>([]);

  const methods = useForm<FormValues>({
    defaultValues: {
      photoIds: [],
      videoIds: []
    }
  });

  const { setError, handleSubmit, watch } = methods;

  React.useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const res = await CompanyApi.getCompanyDetailsById(companyId);
        const companyPhotos = res.data.companyPhotos;
        const companyVideos = res.data.companyVideos;
        const photos = companyPhotos.map((photo) => photo.file);
        const videos = companyVideos.map((video) => video.video);
        const photoIdValues = photos.map((photo) => photo.id);
        const videoIdValues = videos.map((video) => video.id);

        setMedia([...photos, ...videos]);
        setInitialPhotos(photoIdValues);
        setInitialVideos(videoIdValues);
      } catch (error) {
        console.error('Error for PhotosStep.getById()', error);
      }
    };

    fetchData();
  }, []);

  const handleValidForm = async (
    data: FormValues,
    onSuccess: () => void
  ): Promise<void> => {
    const postBody: CompanyMediaPutRequest = {
      companyPhotos: data.photoIds,
      companyVideos: data.videoIds
    };

    try {
      await CompanyApi.updateCompanyPhotos(companyId, postBody);
      onSuccess();
    } catch (error: any) {
      console.error('Error for PhotosStep.handleValidForm()', error);

      const errData = error.response.data.data;

      if (errData?.length) {
        errData.forEach(({ field, message }) =>
          setError(field, { type: 'manual', message })
        );
      }
    }

    window.scrollTo(0, 0);
  };

  const handleFormSubmit = (onSuccess: () => void): void => {
    const onValid = async (data: FormValues): Promise<void> =>
      handleValidForm(data, onSuccess);
    handleSubmit(onValid)();
  };

  const photosUploadedAfterDrag = watch('photoIds').length;
  const videosUploaded = watch('videoIds').length;

  const handleChangeDetected =
    initialPhotos.length !== photosUploadedAfterDrag ||
    initialVideos.length !== videosUploaded;

  return (
    <PageFormLayoutComponent
      sideContent={
        <PageDescriptionComponent title="Share Your Team Photos">
          Showcase your team activities, workplace, and company culture.
        </PageDescriptionComponent>
      }
      pageTitle="Add media:"
      content={
        <FormWrapper>
          <FormProvider {...methods}>
            <StepSection>
              <Typography variant="EC_TYPE_LG">Add Photos</Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ImageUploadWell
                    name="photoIds"
                    displayText="Drop your images here"
                    validTypes={['image/jpg', 'image/jpeg', 'image/png']}
                    multiple={true}
                    files={media}
                    photoLimit={IMAGE_UPLOAD_LIMIT}
                    videoFieldName="videoIds"
                  />
                </Grid>
              </Grid>
            </StepSection>
          </FormProvider>
        </FormWrapper>
      }
      actions={
        <FormActionsComponent
          onSubmit={handleFormSubmit}
          submitBtnText="Save and Continue"
          handleStepper={handleStepper}
          changeDetected={handleChangeDetected}
        />
      }
    />
  );
};

export default PhotosStep;
