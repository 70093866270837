/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import AgreementModal, {
  AgreementModalProps
} from '@components/ExternshipAgreements/AgreementModal';
import {
  CareerServicesStep,
  ManagerStepOne,
  ManagerStepTwo,
  StudentStep
} from './ExternshipAgreementSteps';
import {
  ExternshipAgreementPostReq,
  ExternshipEntity,
  ExternshipWithPhaseAndStatus,
  ReviseExternshipAgreementPostReq
} from '@api/models/externshipApi.models';
import { FormProvider, useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { ExternshipApi } from '@api/Externships.api';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { getPdfHtmlByElementId } from '@common/helpers/pdfHelpers';
import { PdfTypes } from '@api/models/pdfApi.models';
import { useFetchExternshipAgreementData } from '@common/fetches/useFetchExternshipAgreementData';

const getSignDate = (
  date?: string | null,
  revisedEmploymentStartDate?: string | null
): string | null => {
  if (revisedEmploymentStartDate) {
    return null;
  }

  if (date) {
    return formatDate(date, 'LLLL dd, yyyy', undefined, true);
  } else {
    return null;
  }
};

enum FormSteps {
  MANAGER_ONE = 0,
  MANAGER_TWO = 1,
  STUDENT = 2,
  CAREER_SERVICES = 3
}

interface FormValues {
  studentJobTitle: string;
  employmentStartDate: string;
  revisedEmploymentStartDate: string;
  compensationType: string;
  payRate: string | undefined;
}

interface Props extends AgreementModalProps {
  externshipId: number;
  studentSigning?: boolean;
  careerServicesSigning?: boolean;
  updateExternship: (
    externship: ExternshipEntity | ExternshipWithPhaseAndStatus
  ) => void;
  handleOpenRevisionModal?: () => void;
  isRevising?: boolean;
}

const ExternshipAgreement: React.FC<Props> = ({
  externshipId,
  studentSigning = false,
  careerServicesSigning = false,
  updateExternship,
  handleOpenRevisionModal,
  isRevising,
  ...modalProps
}) => {
  const { externshipAgreement, loading } =
    useFetchExternshipAgreementData(externshipId);

  const [activeStep, setActiveStep] = React.useState(isRevising ? 1 : 0);
  const [saving, setSaving] = React.useState(false);

  const methods = useForm<FormValues>({
    defaultValues: {
      studentJobTitle: externshipAgreement?.studentJobTitle,
      employmentStartDate: '',
      compensationType: '',
      payRate: undefined
    }
  });

  const { handleSubmit, register, setValue, watch } = methods;

  const studentJobTitle = watch('studentJobTitle');
  const employmentStartDate = watch('employmentStartDate');
  const revisedEmploymentStartDate = watch('revisedEmploymentStartDate');
  const compensationType = watch('compensationType');
  const payRate = watch('payRate');

  const empStartDateFormatted = externshipAgreement?.employmentStartDate
    ? formatDate(
        externshipAgreement.employmentStartDate,
        'MM-dd-yyyy',
        undefined,
        true
      )
    : '';

  const dateNow = DateTime.now().toFormat('LLLL dd, yyyy');
  const managerSignDate =
    getSignDate(
      externshipAgreement?.managerSignDate,
      externshipAgreement?.revisedEmploymentStartDate
    ) ?? dateNow;

  const isManagerSigning = !studentSigning && !careerServicesSigning;

  const candidateSignDate = isManagerSigning
    ? null
    : getSignDate(
        externshipAgreement?.candidateSignDate,
        externshipAgreement?.revisedEmploymentStartDate
      ) ?? dateNow;
  const careerServiceSignDate = !careerServicesSigning
    ? null
    : getSignDate(
        externshipAgreement?.careerServiceSignDate,
        externshipAgreement?.revisedEmploymentStartDate
      ) ?? dateNow;

  React.useEffect(() => {
    if (externshipAgreement) {
      setValue('studentJobTitle', externshipAgreement.studentJobTitle);

      if (externshipAgreement?.compensationType) {
        setValue('compensationType', externshipAgreement.compensationType);
      }
      if (externshipAgreement?.payRate) {
        setValue('payRate', externshipAgreement.payRate.toString());
      }
      if (externshipAgreement?.employmentStartDate) {
        const empStartDate = formatDate(
          externshipAgreement.employmentStartDate,
          'LLLL dd, yyyy'
        );
        setValue('employmentStartDate', empStartDate);
      }

      if (externshipAgreement?.revisedEmploymentStartDate) {
        const formattedRevisedEmploymentStartDate = formatDate(
          externshipAgreement.revisedEmploymentStartDate,
          'MM-dd-yyyy',
          true
        );
        setValue(
          'revisedEmploymentStartDate',
          formattedRevisedEmploymentStartDate
        );
      }
    }
  }, [externshipAgreement]);

  const handleStepper = (next: boolean): void => {
    const move = next ? 1 : -1;
    setActiveStep((prevActiveStep) => prevActiveStep + move);
  };

  const handleAcceptClick = async (): Promise<void> => {
    setSaving(true);
  };

  const handleUpdateExternships = React.useCallback(
    (updatedExternship: ExternshipEntity) => {
      updateExternship(updatedExternship);
    },
    [updateExternship]
  );

  const signExternshipAgreement = async (data: FormValues): Promise<void> => {
    try {
      const html = getPdfHtmlByElementId(PdfTypes.EXTERNSHIP_AGREEMENT);

      if (isManagerSigning) {
        const postBody: ExternshipAgreementPostReq = {
          html,
          studentJobTitle: data.studentJobTitle,
          employmentStartDate: new Date(data.employmentStartDate),
          compensationType: data.compensationType,
          payRate: data.payRate ?? null
        };

        const updatedExternship =
          await ExternshipApi.signExternshipAgreementManager(
            externshipId,
            postBody
          );

        handleUpdateExternships(updatedExternship.data);
      } else if (studentSigning) {
        const updatedExternship =
          await ExternshipApi.signExternshipAgreementStudent(externshipId, {
            html
          });

        handleUpdateExternships(updatedExternship.data);
      } else if (careerServicesSigning) {
        const updatedExternship =
          await ExternshipApi.signExternshipAgreementCareerServices(
            externshipId,
            { html }
          );

        handleUpdateExternships(updatedExternship.data);
      }
    } catch (error: any) {
      console.error(
        `Error for ExternshipAgreement -> ExternshipApi.signExternshipAgreement(): ${error}`
      );
    }
  };

  // Only Career Services is able to revise and resend at this time
  // Only able to edit the employment start date
  const reviseAndResendExternshipAgreement = async (
    data: FormValues
  ): Promise<void> => {
    try {
      const html = getPdfHtmlByElementId(PdfTypes.EXTERNSHIP_AGREEMENT);

      if (careerServicesSigning) {
        const postBody: ReviseExternshipAgreementPostReq = {
          html,
          revisedEmploymentStartDate: new Date(data.revisedEmploymentStartDate)
        };

        const updatedExternship =
          await ExternshipApi.reviseAndResendExternshipAgreement(
            externshipId,
            postBody
          );

        handleUpdateExternships(updatedExternship.data);
      }
    } catch (error: any) {
      console.error(
        `Error for ExternshipAgreement -> ExternshipApi.reviseAndResendExternshipAgreement(): ${error}`
      );
    }
  };

  const cleanup = (): void => {
    setSaving(false);
    modalProps.handleClose();
  };

  React.useEffect(() => {
    if (saving) {
      if (isRevising) {
        const onValid = async (data: FormValues): Promise<void> => {
          await reviseAndResendExternshipAgreement(data);
        };

        handleSubmit(onValid)().finally(cleanup);
      } else {
        const onValid = async (data: FormValues): Promise<void> => {
          await signExternshipAgreement(data);
        };

        handleSubmit(onValid)().finally(cleanup);
      }
    }
  }, [saving]);

  const lastStep = careerServicesSigning
    ? FormSteps.CAREER_SERVICES
    : FormSteps.STUDENT;

  const stepAction = (): void | Promise<void> => {
    if (activeStep === lastStep) {
      return handleAcceptClick();
    }

    return handleStepper(true);
  };

  const handlePrevious = (): void => {
    handleStepper(false);
  };

  const disableNextBtn = React.useMemo(() => {
    switch (activeStep) {
      case FormSteps.MANAGER_TWO:
        return !studentJobTitle || !compensationType || !employmentStartDate;
      default:
        return false;
    }
  }, [activeStep, studentJobTitle, employmentStartDate, compensationType]);

  const careerServicesName = externshipAgreement?.careerServicesUserName || '';

  const displayRevisionAction =
    !!handleOpenRevisionModal &&
    activeStep === FormSteps.MANAGER_TWO &&
    careerServicesSigning;

  const getAcceptBtnText = (): string => {
    if (activeStep === lastStep) {
      return isRevising ? 'Resend' : 'Accept Agreement';
    }

    return 'Next';
  };

  const _managerSignDate = isRevising ? '' : managerSignDate;
  const _candidateSignDate = isRevising ? '' : candidateSignDate;
  const _careerServicesSignDate = isRevising ? '' : careerServiceSignDate;

  const stepContent = (step: number): React.ReactElement => {
    let content: React.ReactElement = <></>;

    if (externshipAgreement) {
      switch (step) {
        case FormSteps.MANAGER_ONE:
          content = (
            <ManagerStepOne
              {...externshipAgreement}
              signDate={_managerSignDate}
              isRevising={isRevising}
            />
          );
          break;
        case FormSteps.MANAGER_TWO:
          content = (
            <ManagerStepTwo
              {...externshipAgreement}
              register={register}
              signDate={_managerSignDate}
              studentJobTitle={studentJobTitle}
              employmentStartDate={empStartDateFormatted}
              revisedEmploymentStartDate={revisedEmploymentStartDate}
              compensationType={compensationType as string}
              payRate={payRate ?? null}
              saving={saving}
              disabled={
                !!externshipAgreement?.managerSignDate ||
                !!externshipAgreement?.revisedEmploymentStartDate
              }
              isRevising={isRevising}
              handleOpenRevisionModal={handleOpenRevisionModal}
              careerServicesSigning={careerServicesSigning}
            />
          );
          break;
        case FormSteps.STUDENT:
          content = (
            <StudentStep
              {...externshipAgreement}
              isRevising={isRevising}
              candidateSignDate={_candidateSignDate}
            />
          );
          break;
        case FormSteps.CAREER_SERVICES:
          content = (
            <CareerServicesStep
              name={careerServicesName}
              isRevising={isRevising}
              signDate={_careerServicesSignDate}
            />
          );
          break;
        default:
          content = <></>;
      }
    }
    return content;
  };

  return (
    <AgreementModal
      {...modalProps}
      open={!loading && modalProps.open}
      htmlWrapperId={PdfTypes.EXTERNSHIP_AGREEMENT}
      handleAccept={stepAction}
      handleRevision={
        displayRevisionAction ? handleOpenRevisionModal : undefined
      }
      isRevising={isRevising}
      handleDecline={
        activeStep > FormSteps.MANAGER_ONE ? handlePrevious : undefined
      }
      acceptBtnText={getAcceptBtnText()}
      declineBtnText="Previous"
      disableAcceptBtn={disableNextBtn}
      pdfLoading={saving}
    >
      {!saving ? (
        <FormProvider {...methods}>
          <form>{stepContent(activeStep)}</form>
        </FormProvider>
      ) : (
        <>
          {externshipAgreement && (
            <div>
              <ManagerStepOne
                {...externshipAgreement}
                signDate={_managerSignDate}
                isRevising={isRevising}
              />
              <div style={{ height: '8px' }} />
              <ManagerStepTwo
                {...externshipAgreement}
                register={register}
                signDate={_managerSignDate}
                studentJobTitle={studentJobTitle}
                employmentStartDate={empStartDateFormatted}
                revisedEmploymentStartDate={revisedEmploymentStartDate}
                compensationType={compensationType as string}
                payRate={payRate ?? null}
                saving={true}
                isRevising={isRevising}
                disabled={!!externshipAgreement?.managerSignDate}
                handleOpenRevisionModal={handleOpenRevisionModal}
                careerServicesSigning={careerServicesSigning}
              />
              <div style={{ height: '8px' }} />
              <StudentStep
                {...externshipAgreement}
                isRevising={isRevising}
                candidateSignDate={_candidateSignDate}
              />
              {careerServicesSigning && (
                <>
                  <div style={{ height: '8px' }} />
                  <CareerServicesStep
                    name={careerServicesName}
                    signDate={_careerServicesSignDate}
                    isRevising={isRevising}
                  />
                </>
              )}
            </div>
          )}
        </>
      )}
    </AgreementModal>
  );
};

export default ExternshipAgreement;
