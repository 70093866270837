import * as React from 'react';
import { Box, Button, Grid, Theme, Typography } from '@mui/material';
import {
  ExternshipEntity,
  ExternshipStatus,
  ExternshipWithActivityAndProgress
} from '@api/models/externshipApi.models';
import RhfLocationSearch, {
  PlaceType
} from '@components/Forms/RhfLocationSearch';
import CompanyDefaultLogo from '@assets/svg/employer-def.svg';
import EcGoogleMap from '@components/EcGoogleMap';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { getExternshipStatusLabel } from '@common/helpers/externshipHelpers/getExternshipStatusLabel';
import { getTwoLineAddress } from '@common/helpers/addressHelpers/getTwoLineAddress';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import ReactHookFormsInputPhoneMask from '@components/Forms/ReactHookFormsInputPhoneMask';
import RhfCheckbox from '@components/Forms/RhfCheckbox';
import RhfLocationCustom from '@components/Forms/RhfLocationCustom';
import { SiteInfoEditableSections } from '@common/context/studentViewSiteInfoContext';
import { styled } from '@mui/system';
import { UseFormReturn } from 'react-hook-form';

const Styled = {
  Root: styled(Box)({
    width: '100%'
  }),
  FlexBox: styled(Box)({
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between'
  }),
  CompanyBox: styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    '& .company-name': {
      color: theme.palette.GRAY_4.main,
      marginBottom: '10px'
    }
  })),
  LogoBox: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end'
  }),
  Logo: styled('img')({
    maxWidth: '107px',
    objectPosition: 'center',
    objectFit: 'contain',
    marginBottom: '10px'
  }),
  StatusWrapper: styled(Box)({
    display: 'flex',
    gap: '4px',
    marginTop: '16px'
  }),
  EditBtn: styled(Button)(({ theme }) => ({
    ...(theme as Theme).typography.EC_TYPE_XS,
    color: theme.palette.primary.main,
    marginTop: '8px',
    marginLeft: '4px'
  }))
};

interface Props {
  externship: ExternshipWithActivityAndProgress | ExternshipEntity;
  pdfLinks?: React.ReactElement;
  extraButton?: React.ReactElement;
  displayMap?: boolean;
  variant?: 1 | 2;
  showEditCompanyName?: boolean;
  editingSections?: SiteInfoEditableSections[];
  setEditingSections?: React.Dispatch<
    React.SetStateAction<SiteInfoEditableSections[]> // eslint-disable-line @typescript-eslint/indent
  >;
  methods?: UseFormReturn<any, any>;
  handleValidForm?: (data: any) => void;
}

const StudentPageCardHeader: React.FC<Props> = ({
  externship,
  pdfLinks,
  extraButton,
  displayMap = false,
  variant = 1,
  showEditCompanyName = false,
  editingSections,
  setEditingSections,
  methods,
  handleValidForm
}) => {
  const unassignedExternship = !externship?.course?.StudentEnrollmentPeriodId;

  const showEditButton =
    showEditCompanyName &&
    externship.status === ExternshipStatus.PENDING_SOFT_VERIFICATION &&
    !unassignedExternship;
  const isInEditMode = showEditButton && editingSections?.includes('Company');

  const { company, proposedCompany, scheduledStartDate } = externship;

  const customLocation = methods && methods.watch('customLocation');
  const selectedCountry = methods && methods.watch('country');
  const internationalAddress =
    selectedCountry !== undefined && selectedCountry !== 'United States';

  const asUtc = true;
  const formattedDate = formatDate(
    scheduledStartDate,
    'LLL dd, yyyy',
    undefined,
    asUtc
  );

  const _name = company?.name || proposedCompany.location.name || '';
  const _companyLogo = company?.logo?.fullPath || CompanyDefaultLogo;
  const _companyPhone = company?.phone || proposedCompany.location.phone;
  const location = React.useMemo(() => {
    return company?.locations?.[0] ?? proposedCompany.location;
  }, [company, proposedCompany]);

  const toggleEditMode = React.useCallback((): void => {
    if (!editingSections?.includes('Company')) {
      setEditingSections && setEditingSections((prev) => [...prev, 'Company']);
      methods &&
        methods.reset({
          ...methods.getValues(),
          companyName: _name,
          location: location.placeId ? location : null,
          customLocation: location.placeId ? false : true,
          addressLineTwo: location.addressLineTwo,
          city: location.city,
          state: location.state,
          streetName: location.streetName,
          streetNumber: location.streetNumber,
          postalCode: location.zip,
          country: location.country,
          phone: location.phone
        });
    }
  }, [methods, editingSections, setEditingSections]);

  const handleEditCompanySave = (): void => {
    if (handleValidForm && methods?.handleSubmit) {
      methods.handleSubmit(handleValidForm)();
    }
  };

  React.useEffect(() => {
    if (isInEditMode && methods) {
      methods.reset({
        ...methods.getValues(),
        companyName: _name,
        location: location.placeId ? location : null,
        customLocation: location.placeId ? false : true,
        city: location.city,
        state: location.state,
        streetName: location.streetName,
        streetNumber: location.streetNumber,
        addressLineTwo: location.addressLineTwo,
        postalCode: location.zip,
        country: location.country,
        phone: location.phone
      });
    }
  }, [isInEditMode, methods]);

  return (
    <Styled.Root>
      <Styled.FlexBox>
        <Styled.CompanyBox>
          {!isInEditMode ? (
            <>
              <Typography
                variant="EC_TYPE_3XL"
                className="company-name"
                data-testid="student-view-company-name"
              >
                {_name}
                {showEditButton && (
                  <span>
                    <Styled.EditBtn
                      variant="text"
                      onClick={toggleEditMode}
                      data-testid="student-page-header-edit-company-btn"
                    >
                      EDIT
                    </Styled.EditBtn>
                  </span>
                )}
              </Typography>
              {!location.placeId && (
                <Typography variant="EC_TYPE_2XS" paddingBottom={'5px'}>
                  CUSTOM ADDRESS
                </Typography>
              )}

              {variant === 1 ? (
                <Typography variant="EC_TYPE_3XS">
                  {location.description}
                </Typography>
              ) : (
                <>{getTwoLineAddress(location, 'EC_TYPE_3XS')}</>
              )}
              <Typography variant="EC_TYPE_3XS">{_companyPhone}</Typography>
              {pdfLinks}
            </>
          ) : (
            <Box>
              <Grid container spacing={3}>
                {customLocation ? (
                  <>
                    <Grid item xs={10}>
                      <ReactHookFormsInput
                        name="companyName"
                        label="Externship Site Name"
                        rules={{ required: 'Externship Site Name is required' }}
                        dataTestId="externship-proposed-company-name"
                      />
                    </Grid>
                    <Styled.EditBtn
                      variant="text"
                      onClick={handleEditCompanySave}
                      data-testid="student-page-header-save-company-btn"
                      sx={{
                        marginTop: '50px'
                      }}
                    >
                      SAVE
                    </Styled.EditBtn>
                    <Box maxWidth={'450px'} padding={'25px'}>
                      <Grid item xs={12} paddingBottom={'15px'}>
                        <RhfLocationCustom methods={methods} />
                      </Grid>
                      <Grid item xs={12}>
                        {internationalAddress ? (
                          <ReactHookFormsInput
                            name="phone"
                            label="Business Phone"
                            rules={{ required: 'Phone number is required' }}
                            dataTestId="externship-phone-input-free-form"
                          />
                        ) : (
                          <ReactHookFormsInputPhoneMask
                            name="phone"
                            label="Business Phone"
                            rules={{ required: 'Phone number is required' }}
                            dataTestId="externship-phone-input"
                          />
                        )}
                      </Grid>
                    </Box>
                  </>
                ) : (
                  <>
                    <Grid
                      container
                      alignItems="start"
                      padding={'25px 25px 0 25px'}
                    >
                      <Grid item xs={10}>
                        <RhfLocationSearch
                          name="location"
                          label="Company"
                          placeType={PlaceType.ALL}
                          displayMap={true}
                          rules={{ required: 'Company is required' }}
                          placeholder="Enter a company name"
                          dataTestId="externship-location-input"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Styled.EditBtn
                          variant="text"
                          onClick={handleEditCompanySave}
                          data-testid="student-page-header-save-company-btn"
                          sx={{
                            marginTop: '50px'
                          }}
                        >
                          SAVE
                        </Styled.EditBtn>
                      </Grid>
                    </Grid>
                  </>
                )}
                {!customLocation && (
                  <Grid item xs={12}>
                    <ReactHookFormsInput
                      name="companyName"
                      label="Externship Site Name"
                      rules={{ required: 'Externship Site Name is required' }}
                      dataTestId="externship-proposed-company-name"
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <RhfCheckbox
                    name="customLocation"
                    label="Use custom address"
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Styled.CompanyBox>
        <Styled.LogoBox>
          <Styled.Logo
            src={_companyLogo}
            alt="company logo"
            data-testid="company-logo"
          />
          {variant === 1 ? (
            <>
              <Typography variant="EC_TYPE_3XS">
                {getExternshipStatusLabel(externship.status)}
              </Typography>
              <Typography variant="EC_TYPE_3XS">
                Start Date: {formattedDate}
              </Typography>
              <Typography variant="EC_TYPE_3XS">
                {externship.course.CourseCode}
              </Typography>
            </>
          ) : (
            <Styled.StatusWrapper>
              <Typography variant="EC_TYPE_2XS">STATUS:</Typography>
              <Typography variant="EC_TYPE_2XS" sx={{ fontWeight: 400 }}>
                {getExternshipStatusLabel(externship.status)}
              </Typography>
            </Styled.StatusWrapper>
          )}
        </Styled.LogoBox>
      </Styled.FlexBox>
      {location && displayMap && !isInEditMode && (
        <EcGoogleMap
          locations={[location]}
          containerStyle={{
            marginTop: '20px',
            height: '300px',
            borderRadius: '10px'
          }}
        />
      )}
      {extraButton}
    </Styled.Root>
  );
};

export default StudentPageCardHeader;
