/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { CareerServicesApi } from '@api/CareerService.api';
import KeycloakUser from '@interfaces/keycloak.interfaces';

export type FetchCareerServicesUsers = {
  careerServicesUsers: KeycloakUser[];
  loaded: boolean;
  loading: boolean;
};

export const useFetchCareerServicesUsers = (): FetchCareerServicesUsers => {
  const [careerServicesUsers, setCareerServicesUsers] = React.useState<
    KeycloakUser[]
  >([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchCareerServicesUsers = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await CareerServicesApi.getCareerServicesUsers();

      setCareerServicesUsers(res);
      setLoaded(true);
    } catch (error: any) {
      console.error(
        'Error for useFetchCareerServicesUsers -> CareerServicesApi.getCareerServicesUsers()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    fetchCareerServicesUsers();
  }, []);

  return {
    careerServicesUsers,
    loaded,
    loading
  };
};
