import * as React from 'react';
import { Box, SxProps } from '@mui/material';
import { ReactComponent as CrownIcon } from '@assets/resumeIcons/crown.svg';
import { OUTLINE_GRAY } from '@themes/ui/escoffier';
import { styled } from '@mui/system';

const Styled = {
  CrownWithLinesWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'opacity' && prop !== 'maxWidth'
  })<{ opacity: number; maxWidth?: string }>(({ opacity, maxWidth }) => ({
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: opacity,
    ...(maxWidth && {
      maxWidth,
      margin: '0 auto'
    })
  })),
  Line: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'color'
  })<{ color: string }>(({ color }) => ({
    height: '1px',
    backgroundColor: color,
    flexGrow: 1
  })),
  CrownWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'color'
  })<{ color: string }>(({ color }) => ({
    margin: '0px 25px',
    svg: {
      height: '55px',
      width: '100px',
      path: { fill: color }
    },
    '@media (max-width:1100px)': {
      margin: '0px 5px',
      svg: { transform: 'scale(0.7)' }
    }
  }))
};

interface Props {
  color?: string;
  opacity?: number;
  maxWidth?: string;
  wrapperStyle?: React.CSSProperties | SxProps;
}

const EcCrownWithLines: React.FC<Props> = ({
  color = OUTLINE_GRAY,
  opacity = 1,
  maxWidth,
  wrapperStyle
}) => {
  return (
    <Styled.CrownWithLinesWrapper
      opacity={opacity}
      maxWidth={maxWidth}
      sx={{ ...wrapperStyle }}
    >
      <Styled.Line color={color} />
      <Styled.CrownWrapper color={color}>
        <CrownIcon />
      </Styled.CrownWrapper>
      <Styled.Line color={color} />
    </Styled.CrownWithLinesWrapper>
  );
};

export default EcCrownWithLines;
