import * as React from 'react';
import {
  Box,
  Button,
  FormControl,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import {
  ExternshipTrackerView,
  ViewCounts
} from '@common/fetches/useFetchGridTableByType';
import { GridTable, GridTableView } from '@api/models/gridTableApi.models';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { GridTableApi } from '@api/GridTable.api';
import { styled } from '@mui/system';

const Styled = {
  TopFiltersWrapper: styled(Box)({
    display: 'flex',
    alignItems: 'flex-end',
    gap: '24px'
  }),
  TopFilterFormControl: styled(FormControl)({
    minWidth: '150px'
  }),
  CustomViewMenuItem: styled(MenuItem)({
    display: 'flex',
    marginRight: '-16px',
    justifyContent: 'space-between',
    '& > .right-arrow-icon': {
      visibility: 'hidden'
    },
    '&:hover': {
      '& > .right-arrow-icon': {
        visibility: 'visible'
      }
    }
  })
};

interface Props {
  gridTable?: GridTable;
  currView?: ExternshipTrackerView;
  year?: string;
  years: string[];
  dateString?: string;
  externshipDatesByYear: { [key: string]: Date[] };
  viewCounts: ViewCounts;
  displayViewSelect: boolean;
  pageLoading?: boolean;
  isGradDateSelect?: boolean;
  customFilterBtnText?: string;
  setViewToRename: (view?: GridTableView) => void;
  setShowViewModal: (value: boolean) => void;
  setOpenDeleteViewModal: (view?: number) => void;
  setGridTable: (table?: GridTable) => void;
  setYear: (year?: string) => void;
  setCurrDate: (date?: Date) => void;
  handleRetrieveEnrollments: () => void;
  handleViewChange: (e: SelectChangeEvent<string>) => void;
  sessionValue?: string;
}

const CSDashboardTopFilters: React.FC<Props> = ({
  gridTable,
  currView,
  year,
  years,
  dateString,
  externshipDatesByYear,
  viewCounts,
  displayViewSelect,
  pageLoading = false,
  isGradDateSelect = false,
  customFilterBtnText,
  setViewToRename,
  setShowViewModal,
  setGridTable,
  setOpenDeleteViewModal,
  setYear,
  setCurrDate,
  handleRetrieveEnrollments,
  handleViewChange,
  sessionValue
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [viewIdMenu, setViewIdMenu] = React.useState<number>();

  const allViews = [
    ...(gridTable?.defaultViews || []),
    ...(gridTable?.customViews || [])
  ];

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    view: GridTableView
  ): void => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setViewIdMenu(view.id);
  };

  const handleCloseViewMenu = (e): void => {
    e.stopPropagation();
    setAnchorEl(null);
    setViewIdMenu(undefined);
  };

  const handleRenameView = (e, view: GridTableView): void => {
    e.stopPropagation();
    setViewToRename(view);
    setShowViewModal(true);
  };

  const handleSaveCurrView = (e): void => {
    e.stopPropagation();
    setShowViewModal(true);
  };

  const handleYearChange = (e: SelectChangeEvent<string>): void => {
    const selectedYear = e.target.value;
    const datesInYear = externshipDatesByYear[selectedYear];

    setYear(selectedYear);
    setCurrDate(datesInYear[datesInYear.length - 1]);
  };

  const handleDateChange = (e: SelectChangeEvent<string>): void => {
    setCurrDate(new Date(e.target.value));
  };

  const handleSetDefaultView = async (
    e,
    view: GridTableView
  ): Promise<void> => {
    e.stopPropagation();
    if (gridTable) {
      await GridTableApi.setDefaultGridTableView(gridTable?.id, view.id);
      setGridTable({ ...gridTable, defaultViewId: view.id });
    }
    setAnchorEl(null);
  };

  const getSelectViewValue = sessionValue
    ? 'SESSION'
    : String(currView?.currView) ?? '';

  return (
    <Styled.TopFiltersWrapper>
      <Box>
        <Typography
          variant="EC_TYPE_XS"
          component={'p'}
          color={'GRAY_4.main'}
          mb={'11px'}
        >
          SELECT AN EXTERNSHIP {isGradDateSelect ? 'GRADUATION' : 'START'} DATE
        </Typography>
        <Select value={year ?? ''} onChange={handleYearChange}>
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
        <Styled.TopFilterFormControl>
          <Select value={dateString ?? ''} onChange={handleDateChange}>
            {year &&
              externshipDatesByYear[year].map((date, i) => {
                return (
                  <MenuItem key={i} value={date.toISOString() ?? ''}>
                    {formatDate(date.toISOString(), 'MMMM d', false, true)}
                  </MenuItem>
                );
              })}
          </Select>
        </Styled.TopFilterFormControl>
      </Box>
      <Box>
        <Button
          variant="outlined"
          disabled={pageLoading}
          sx={{ height: '45px' }}
          onClick={handleRetrieveEnrollments}
        >
          {customFilterBtnText ?? 'Retrieve Enrollments'}
        </Button>
      </Box>
      {displayViewSelect && (
        <Box>
          <Typography
            variant="EC_TYPE_XS"
            component={'p'}
            color={'GRAY_4.main'}
            mb={'11px'}
          >
            VIEW
          </Typography>
          <Styled.TopFilterFormControl>
            <Select
              displayEmpty
              value={getSelectViewValue}
              onChange={handleViewChange}
              renderValue={(value): JSX.Element => {
                return (
                  <>
                    {value === 'SESSION' ? (
                      ''
                    ) : (
                      <>
                        {allViews.find((v) => String(v.id) === value)?.name} (
                        {viewCounts[value]}){' '}
                        {String(gridTable?.defaultViewId) === value && '*'}
                      </>
                    )}
                  </>
                );
              }}
            >
              {allViews.map((view) => (
                <Styled.CustomViewMenuItem key={view.id} value={view.id}>
                  {view.name} ({viewCounts[view.id]}){' '}
                  {gridTable?.defaultViewId === view.id && '*'}
                  <IconButton
                    className="right-arrow-icon"
                    size="small"
                    onClick={(e): void => handleClick(e, view)}
                  >
                    <Icon className="ri-arrow-right-s-line" />
                  </IconButton>
                  <Menu
                    id="view-menu"
                    anchorEl={anchorEl}
                    open={!!anchorEl && viewIdMenu === view.id}
                    onClose={handleCloseViewMenu}
                  >
                    {!!view.userId && (
                      <MenuItem
                        onClick={(e): void => handleRenameView(e, view)}
                      >
                        Rename
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={(e): Promise<void> =>
                        handleSetDefaultView(e, view)
                      }
                    >
                      Set As Default
                    </MenuItem>
                    {!!view.userId && (
                      <MenuItem
                        onClick={(e): void => {
                          e.stopPropagation();
                          setOpenDeleteViewModal(view.id);
                        }}
                      >
                        Delete
                      </MenuItem>
                    )}
                  </Menu>
                </Styled.CustomViewMenuItem>
              ))}
              <MenuItem value="SAVE" onClick={handleSaveCurrView}>
                Save current view...
              </MenuItem>
              <MenuItem disabled value="SESSION" />
            </Select>
          </Styled.TopFilterFormControl>
        </Box>
      )}
    </Styled.TopFiltersWrapper>
  );
};

export default React.memo(CSDashboardTopFilters);
