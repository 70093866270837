import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  redirectStorageKeys,
  redirectStorageMessages,
  useStorageRedirectWithExpiration
} from '@common/helpers/storage';
import { benefits } from '@common/tags/tagStrings';
import { CompanyApi } from '@api/Company.api';
import { CompanyBenefitsPutRequest } from '@api/models/companyApi.models';
import FormActionsComponent from '@components/Forms/FormActionsComponent';
import FormWrapper from '@components/FormWrapper';
import PageDescriptionComponent from '@components/Layout/Common/PageDescriptionComponent';
import PageFormLayoutComponent from '@components/Layout/PageFormLayoutComponent';
import ReactHookFormsTagSelection from '@components/Forms/ReactHookFormsTagSelection';
import { roles } from '@api/models/userApi.models';
import StepSection from '@components/StepSection';
import StepSubSection from '@components/StepSubSection';
import { useFetchCompanyProfile } from '@common/fetches/useFetchCompanyProfile';
import { useHistory } from 'react-router';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';
import { useTagManager } from '@common/hooks/useTagManager';

interface FormValues {
  companyHealthInsurance: string[];
  companyRetirement: string[];
  companyProfDevelopment: string[];
}

interface Props {
  handleStepper: (next: boolean) => void;
}

const BenefitsStep: React.FC<Props> = ({ handleStepper }) => {
  const { sendToGtm } = useTagManager();
  const { companyId } = useParamsOrStoreCompanyId();

  const { companyProfile, profileComplete } = useFetchCompanyProfile(companyId);

  const methods = useForm<FormValues>({
    defaultValues: {
      companyHealthInsurance: [],
      companyRetirement: [],
      companyProfDevelopment: []
    }
  });

  const { handleSubmit, reset } = methods;

  const history = useHistory();

  const storage = useStorageRedirectWithExpiration(
    redirectStorageKeys.CREATE_JOB_POST
  );

  React.useEffect(() => {
    if (companyProfile) {
      const { companyBenefits } = companyProfile;
      const filterGroups = (group: string): string[] =>
        companyBenefits
          .filter((b) => b.benefitsGroup === group)
          .map((b) => b.benefitsValue);

      reset({
        companyHealthInsurance: filterGroups('companyHealthInsurance'),
        companyRetirement: filterGroups('companyRetirement'),
        companyProfDevelopment: filterGroups('companyProfDevelopment')
      });
    } else {
      window.scroll(0, 0);
    }
  }, [companyProfile]);

  const handleValidForm = async (
    data: FormValues,
    onSuccess: () => void
  ): Promise<void> => {
    const postBody: CompanyBenefitsPutRequest = {
      companyHealthInsurance: data.companyHealthInsurance,
      companyRetirement: data.companyRetirement,
      companyProfDevelopment: data.companyProfDevelopment
    };

    let successfullyUpdated;

    try {
      await CompanyApi.updateCompanyBenefits(companyId, postBody);
      successfullyUpdated = true;
      onSuccess();
    } catch (error: any) {
      successfullyUpdated = false;
      console.error('Error for BenefitsStep', error);
    } finally {
      if (!profileComplete) {
        sendToGtm('profile_completed', {
          role: roles.EMPLOYER,
          success: successfullyUpdated
        });
      }
    }
  };

  const handleFormSubmit = (onSuccess: () => void): void => {
    const onValid = async (data: FormValues): Promise<void> =>
      handleValidForm(data, onSuccess);
    handleSubmit(onValid)();
  };

  const handleLastStepAction = React.useCallback((): void => {
    if (
      storage &&
      storage?.message === redirectStorageMessages.INCOMPLETE_PROFILE_EMPLOYER
    ) {
      history.push(storage.redirect, { shouldClearSession: true });
    } else {
      history.push(`/employer/${companyId}/profile`);
    }
    window.scrollTo(0, 0);
  }, [storage]);

  return (
    <PageFormLayoutComponent
      sideContent={
        <PageDescriptionComponent title="Employee Benefits">
          Select any benefits available to your employees.
        </PageDescriptionComponent>
      }
      pageTitle="Select all that apply:"
      content={
        <FormWrapper>
          <FormProvider {...methods}>
            <StepSection>
              <StepSubSection>
                <ReactHookFormsTagSelection
                  name="companyHealthInsurance"
                  title="Health Insurance & Wellness Benefits"
                  tags={benefits.healthWellnessBenefits}
                />
              </StepSubSection>
              <StepSubSection>
                <ReactHookFormsTagSelection
                  name="companyRetirement"
                  title="Retirement"
                  tags={benefits.retirementBenefits}
                />
              </StepSubSection>
              <StepSubSection>
                <ReactHookFormsTagSelection
                  name="companyProfDevelopment"
                  title="Professional Development"
                  tags={benefits.professionalDevBenefits}
                />
              </StepSubSection>
            </StepSection>
          </FormProvider>
        </FormWrapper>
      }
      actions={
        <FormActionsComponent
          lastStepAction={handleLastStepAction}
          onSubmit={handleFormSubmit}
          submitBtnText="Save and Finish"
          handleStepper={handleStepper}
          changeDetected={methods.formState.isDirty}
        />
      }
    />
  );
};

export default BenefitsStep;
