import * as React from 'react';
import { Button, Grid } from '@mui/material';
import {
  ExternshipManagerJoinRequestAccount,
  ExternshipManagerSignupRequest
} from '@api/models/authApi.models';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import {
  includesLetter,
  includesNumber,
  minLength,
  notMatchEmail
} from '@common/rhf-validators/passwordValidators';
import RhfLocationSearch, {
  PlaceType
} from '@components/Forms/RhfLocationSearch';
import { AuthApi } from '@api/Auth.api';
import Box from '@mui/material/Box';
import { emailPattern } from '@common/regex/email';
import { InviteExternshipManager } from '@api/models/inviteApi.models';
import { Location } from '@interfaces/location.interfaces';
import { notBeEmptyString } from '@common/rhf-validators/emptyString.validator';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import ReactHookFormsInputPhoneMask from '@components/Forms/ReactHookFormsInputPhoneMask';
import ReactHookFormsPassword from '@components/Forms/ReactHookFormsPassword';
import RhfAcceptTermsCheckbox from '@components/Forms/RhfAcceptTermsCheckbox';
import { scrollToError } from '@common/helpers/scrollToError';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)({
    margin: '0 auto',
    maxWidth: '474px'
  }),
  GridItem: styled(Grid)({
    marginBottom: '34px'
  }),
  AcceptTermsBox: styled(Box)({
    position: 'relative',
    ['@media (max-width:340px)']: {
      marginBottom: '20px'
    }
  }),
  CreateBtn: styled(Button)({
    marginTop: '52px',
    height: '49px',
    // EC-Type-lg
    fontSize: '20px',
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '25px',
    letterSpacing: '0em',
    ['@media (max-width:600px)']: {
      width: '100%'
    }
  })
};

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  companyPhone: string;
  jobTitle: string;
  password: string;
  confirmPassword: string;
  location: Location;
  phone: string;
  companyName: string;
  acceptTerms: boolean;
}

interface Props {
  setEmailConfirmationSent: React.Dispatch<React.SetStateAction<boolean>>;
  inviteInfo?: InviteExternshipManager;
}

const CreateExternshipManagerAccountForm: React.FC<Props> = ({
  inviteInfo,
  setEmailConfirmationSent
}) => {
  const isJoinRequest = !!inviteInfo?.existingCompany;
  const [modalOpen, setModalOpen] = React.useState(false);

  const methods = useForm<FormValues>({
    defaultValues: {
      firstName: inviteInfo?.proposedCompany.manager.firstName || '',
      lastName: inviteInfo?.proposedCompany.manager.lastName || '',
      email: inviteInfo?.proposedCompany.manager.email || '',
      phone: inviteInfo?.proposedCompany.manager.phoneNumber || '',
      jobTitle: inviteInfo?.proposedCompany.manager.jobTitle || '',
      password: '',
      confirmPassword: '',
      location: inviteInfo?.proposedCompany.location || undefined,
      companyName: inviteInfo?.proposedCompany.location.name || '',
      companyPhone: inviteInfo?.proposedCompany.phone || '',
      acceptTerms: false
    }
  });

  const formOrder = {
    firstName: 1,
    lastName: 2,
    email: 3,
    phone: 4,
    jobTitle: 5,
    password: 6,
    confirmPassword: 7,
    location: 8,
    companyName: 9,
    companyPhone: 10,
    acceptTerms: 11
  };

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors }
  } = methods;

  React.useEffect(() => {
    if (inviteInfo) {
      const { proposedCompany } = inviteInfo;
      const { manager } = proposedCompany;

      reset({
        firstName: manager.firstName,
        lastName: manager.lastName,
        email: manager.email,
        phone: manager.phoneNumber,
        jobTitle: manager.jobTitle,
        location: proposedCompany.location,
        companyName:
          inviteInfo.existingCompany?.name ?? proposedCompany.location.name,
        companyPhone: proposedCompany.phone,
        acceptTerms: false
      });
    }
  }, [inviteInfo]);

  const currPassword = watch('password');
  const termsAccepted = watch('acceptTerms');
  const email = watch('email');

  const handleModalOpen = (): void => {
    setModalOpen(true);
  };

  const handleModalClose = (): void => {
    setModalOpen(false);
  };

  const handleAcceptTerms = (): void => {
    setValue('acceptTerms', true, { shouldValidate: true });
    handleModalClose();
  };

  const handleInvalidForm = (errors: FieldErrors): void => {
    scrollToError(errors, formOrder);
  };

  const handleCreateAccount = async (data: FormValues): Promise<void> => {
    const location = Array.isArray(data.location)
      ? data.location[0]
      : data.location;

    if (!location.streetNumber || !location.streetName || !location.zip) {
      setError('location', {
        type: 'manual',
        message:
          'This location is missing address information. Please enter a new location.'
      });
      return;
    }

    if (inviteInfo) {
      const postBody: ExternshipManagerSignupRequest = {
        inviteCode: inviteInfo.inviteCode,
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        email: data.email,
        password: data.password,
        confirm_password: data.confirmPassword,
        locations: [data.location],
        companyName: data.companyName,
        jobTitle: data.jobTitle,
        phone: data.phone,
        companyPhone: data.companyPhone,
        externshipId: inviteInfo.externshipId,
        offerings: []
      };

      try {
        await AuthApi.externshipManagerSignup(postBody);
        setEmailConfirmationSent(true);
      } catch (error: any) {
        console.error(
          `Error for AuthApi.externshipManagerSignup: ${JSON.stringify(error)}`
        );

        const errData = error.response.data.data;

        if (errData?.length) {
          errData.forEach(({ field, message }) =>
            setError(field, { type: 'manual', message })
          );

          scrollToError(errors, formOrder);
        }
      }
    }
  };

  const handleJoinRequest = async (data: FormValues): Promise<void> => {
    if (inviteInfo?.existingCompany) {
      const postBody: ExternshipManagerJoinRequestAccount = {
        inviteCode: inviteInfo.inviteCode,
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        email: data.email,
        password: data.password,
        confirm_password: data.confirmPassword,
        companyId: inviteInfo.existingCompany.id,
        jobTitle: data.jobTitle,
        phone: data.phone,
        externshipId: inviteInfo.externshipId
      };

      try {
        await AuthApi.externshipManagerExistingCompanySignUp(postBody);
        setEmailConfirmationSent(true);
      } catch (error: any) {
        console.error(
          `Error for AuthApi.externshipManagerExistingCompanySignUp: ${JSON.stringify(
            error
          )}`
        );

        const errData = error.response.data.data;

        if (errData?.length) {
          errData.forEach(({ field, message }) =>
            setError(field, { type: 'manual', message })
          );

          scrollToError(errors, formOrder);
        }
      }
    }
  };

  const handleFormSubmit = (): void => {
    const onValid = async (data: FormValues): Promise<void> => {
      if (isJoinRequest) {
        return await handleJoinRequest(data);
      }

      return await handleCreateAccount(data);
    };

    handleSubmit(onValid, handleInvalidForm)();
  };

  return (
    <Styled.Root>
      <FormProvider {...methods}>
        <form>
          <Grid
            container
            columnSpacing={3}
            alignItems="flex-end"
            sx={{ marginBottom: '14px' }}
          >
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsInput
                name="firstName"
                label="First Name"
                autocomplete="given-name"
                dataTestId="create-extern-manager-firstName"
                rules={{
                  required: 'First name is required',
                  validate: { notBeEmptyString: notBeEmptyString('First name') }
                }}
              />
            </Styled.GridItem>
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsInput
                name="lastName"
                label="Last Name"
                autocomplete="family-name"
                dataTestId="create-extern-manager-lastName"
                rules={{
                  required: 'Last name is required',
                  validate: { notBeEmptyString: notBeEmptyString('Last name') }
                }}
              />
            </Styled.GridItem>
            <Styled.GridItem item xs={12} sm={12}>
              <ReactHookFormsInput
                name="email"
                disabled
                label="Email"
                dataTestId="create-extern-manager-email"
                autocomplete="email"
                rules={{
                  required: 'Email is required.',
                  pattern: {
                    value: emailPattern,
                    message: 'Please enter a valid email'
                  }
                }}
              />
            </Styled.GridItem>
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsInputPhoneMask
                name="phone"
                label="Personal Phone Number"
                dataTestId="create-extern-manager-personal-phone-number-input"
                rules={{
                  required: 'Phone number is required'
                }}
              />
            </Styled.GridItem>
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsInput
                name="jobTitle"
                label="Job Title"
                dataTestId="create-extern-manager-jobTitle"
                rules={{
                  required: 'Job Title is required.'
                }}
              />
            </Styled.GridItem>
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsPassword
                name="password"
                label="Create Password"
                dataTestId="create-extern-manager-password"
                autocomplete="new-password"
                displayTooltip
                rules={{
                  required: 'Password is required',
                  validate: {
                    notMatchEmail: notMatchEmail(email),
                    minLength,
                    includesNumber,
                    includesLetter
                  }
                }}
              />
            </Styled.GridItem>
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsPassword
                name="confirmPassword"
                label="Confirm Password"
                autocomplete="new-password"
                dataTestId="create-extern-manager-confirmPassword"
                rules={{
                  required: 'Confirm password is required',
                  validate: (value): boolean | string =>
                    value === currPassword || 'Passwords do not match'
                }}
              />
            </Styled.GridItem>
            <Styled.GridItem item xs={12} sm={12}>
              <RhfLocationSearch
                name="location"
                label="Company Location"
                readOnly
                placeholder="Find your location"
                placeType={PlaceType.ALL}
                displayMap
                rules={{ required: 'Location is required' }}
              />
            </Styled.GridItem>
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsInput
                name="companyName"
                disabled={isJoinRequest}
                label="Company Name"
                dataTestId="create-extern-manager-companyName"
                rules={{ required: 'Company Name is required' }}
              />
            </Styled.GridItem>
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsInputPhoneMask
                name="companyPhone"
                label="Company Phone Number"
                disabled={isJoinRequest}
                dataTestId="create-extern-manager-companyPhone"
                rules={{
                  required: 'Phone number is required'
                }}
              />
            </Styled.GridItem>
          </Grid>
          <RhfAcceptTermsCheckbox
            name="acceptTerms"
            termsAccepted={termsAccepted}
            modalOpen={modalOpen}
            setModalOpen={handleModalOpen}
            setModalClosed={handleModalClose}
            handleAcceptTermsAndConditions={handleAcceptTerms}
          />
          <Styled.CreateBtn
            variant="contained"
            onClick={handleFormSubmit}
            data-testid="create-extern-manager-submit-btn"
          >
            Create Account
          </Styled.CreateBtn>
        </form>
      </FormProvider>
    </Styled.Root>
  );
};

export default CreateExternshipManagerAccountForm;
