import { ContentState, convertFromHTML, convertToRaw } from 'draft-js';

export const getSavedRTEContent = (storedString: string): string => {
  if (!storedString) return '';

  const fromHTML = convertFromHTML(storedString);
  const state = ContentState.createFromBlockArray(
    fromHTML.contentBlocks,
    fromHTML.entityMap
  );

  return JSON.stringify(convertToRaw(state));
};
