import * as React from 'react';
import { Box, Button, Theme } from '@mui/material';
import EcDialog from '@components/EcDialog';
import { ExternshipApi } from '@api/Externships.api';
import { ExternshipStatus } from '@api/models/externshipApi.models';
import { styled } from '@mui/system';
import { useCandidateDashboardContext } from '@common/context/candidateDashboardContext';
import { useHistory } from 'react-router-dom';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    display: 'flex',
    marginTop: '8px',
    marginLeft: 'auto',
    alignItems: 'center',
    justifyContent: 'start',
    columnGap: '16px',
    [theme.breakpoints.down('sm')]: {
      columnGap: '64px'
    }
  })),
  Button: styled(Button)(({ theme }) => ({
    ...(theme as Theme).typography.EC_TYPE_2XS,
    color: theme.palette.primary.main,
    padding: '8px',
    whiteSpace: 'nowrap',
    '& i': {
      fontSize: '16px !important',
      fontWeight: 100
    }
  })),
  SecondaryButton: styled(Button)(({ theme }) => ({
    ...(theme as Theme).typography.EC_TYPE_2XS,
    color: theme.palette.primary.main,
    padding: '0 8px 0 4px',
    whiteSpace: 'nowrap',
    '& i': {
      fontSize: '16px !important',
      fontWeight: 100
    }
  }))
};

type ActionType = {
  label: string;
  icon: string | null;
  variant?: string;
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

type SecondaryAction = Omit<ActionType, 'icon'> & { icon: string };

interface Props {
  status: ExternshipStatus;
  externshipId: number;
  enrollmentId: number;
  courseId: number;
  companyName?: string;
  handleOpenAgreementModal: () => void;
  revisedEmploymentStartDate?: string | null;
}

const CourseExternshipAction: React.FC<Props> = ({
  status,
  externshipId,
  enrollmentId,
  courseId,
  companyName,
  handleOpenAgreementModal,
  revisedEmploymentStartDate
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const confirmText = React.useRef<string | undefined>();

  const [studentId] = useStore<string>(UseStoreKeys.CANDIDATE_STUDENT_ID);

  const {
    fetchByCandidateId,
    removeExternshipFromState
    // hasAvailableExternshipCourses
  } = useCandidateDashboardContext();

  let action: ActionType;
  let secondaryAction: SecondaryAction | undefined = undefined;

  const history = useHistory();

  const getConfirmModalActionText = (actionString: string): string => {
    if (actionString.includes('cancel')) {
      return 'YES';
    }

    return actionString.split(' ')[0].toUpperCase();
  };

  const handleRemoveExternshipFromState = React.useCallback(() => {
    removeExternshipFromState(externshipId);
  }, [externshipId, removeExternshipFromState]);

  const handleOpenConfirmModal = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    e.stopPropagation();

    if (e.currentTarget.ariaLabel?.includes('Delete')) {
      confirmText.current = `delete your draft for ${companyName}?`;
    }

    if (e.currentTarget.ariaLabel?.includes('Cancel')) {
      confirmText.current = `cancel your proposal for ${companyName}?`;
    }

    if (e.currentTarget.ariaLabel?.includes('Remove')) {
      confirmText.current = 'remove this externship?';
    }

    setConfirmModalOpen(true);
  };

  const handleCloseConfirmModal = (): void => {
    confirmText.current = undefined;
    setConfirmModalOpen(false);
  };

  const handleDraftClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    history.push(
      `/enrollment/${enrollmentId}/course/${courseId}/externship/${externshipId}/proposal`
    );
  };

  const handleDelete = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    e.stopPropagation();
    await ExternshipApi.delete(externshipId).then(() => {
      handleRemoveExternshipFromState();
    });
  };

  const handleCancelProposal = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    e.stopPropagation();
    await ExternshipApi.cancel(externshipId).then(() => {
      fetchByCandidateId(studentId);
    });
  };

  const handleRemoveProposal = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    e.stopPropagation();
    await ExternshipApi.archive(externshipId).then(() => {
      handleRemoveExternshipFromState();
    });
  };

  const handleConfirmModalClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (confirmText.current?.includes('delete')) {
        return handleDelete(e);
      } else if (confirmText.current?.includes('cancel')) {
        return handleCancelProposal(e);
      } else if (confirmText.current?.includes('remove')) {
        return handleRemoveProposal(e);
      } else {
        return handleCloseConfirmModal();
      }
    },
    [
      confirmText,
      handleDelete,
      handleCancelProposal,
      handleRemoveProposal,
      handleCloseConfirmModal
    ]
  );

  switch (status) {
    case ExternshipStatus.DRAFT:
      action = {
        label: 'Continue',
        icon: null,
        variant: 'outlined',
        handleClick: handleDraftClick
      };
      secondaryAction = {
        label: 'Delete Draft',
        icon: 'ri-delete-bin-2-line',
        handleClick: handleOpenConfirmModal
      };
      break;
    case ExternshipStatus.PENDING_SOFT_VERIFICATION:
    case ExternshipStatus.PENDING:
    case ExternshipStatus.PENDING_SITE_AGREEMENT:
    case ExternshipStatus.PENDING_EXT_MNG_AGREEMENT:
      action = {
        label: 'Cancel Proposal',
        icon: 'ri-close-circle-line',
        handleClick: handleOpenConfirmModal
      };
      break;
    case ExternshipStatus.PENDING_EXT_STD_AGREEMENT:
      action = {
        label: revisedEmploymentStartDate
          ? 'Review Changes'
          : 'Sign Externship Agreement',
        icon: 'ri-edit-fill',
        variant: 'contained',
        handleClick: handleOpenAgreementModal
      };
      break;
    case ExternshipStatus.CANCELED:
    case ExternshipStatus.DECLINED:
    case ExternshipStatus.CS_REJECTED:
      action = {
        label: 'Remove',
        icon: 'ri-delete-bin-2-line',
        handleClick: handleOpenConfirmModal
      };
      break;
    default:
      action = {
        label: '',
        icon: '',
        handleClick: (e): void => {
          e.stopPropagation();
        }
      };
  }

  return (
    <Styled.Root>
      {secondaryAction && (
        <Styled.Button
          variant="text"
          startIcon={<i className={`${secondaryAction.icon}`} />}
          onClick={secondaryAction.handleClick}
          aria-label={secondaryAction.label}
        >
          {secondaryAction.label}
        </Styled.Button>
      )}
      <Styled.Button
        variant="text"
        startIcon={action.icon ? <i className={action.icon} /> : null}
        onClick={action.handleClick}
        aria-label={action.label}
      >
        {action.label}
      </Styled.Button>
      {confirmModalOpen && !!confirmText.current && (
        <EcDialog
          title={`Are you sure you want to ${confirmText.current}`}
          content="If you do this the externship will be permanently removed."
          open={confirmModalOpen}
          handleClose={handleCloseConfirmModal}
          handleConfirm={handleConfirmModalClick}
          confirmActionText={getConfirmModalActionText(confirmText.current)}
          denyActionText="Cancel"
        />
      )}
    </Styled.Root>
  );
};

export default CourseExternshipAction;
