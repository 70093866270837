import {
  GlrcContent,
  GlrcVideo,
  TopicTypeIds,
  TopicTypes
} from '@interfaces/glrc.interfaces';
import { Duration } from 'luxon';

export const getTopicIdFromString = (topic: TopicTypes): TopicTypeIds => {
  switch (topic) {
    case TopicTypes.KITCHEN_BASICS:
      return TopicTypeIds.KITCHEN_BASICS;
    case TopicTypes.CULINARY:
      return TopicTypeIds.CULINARY;
    case TopicTypes.PASTRY:
      return TopicTypeIds.PASTRY;
    case TopicTypes.PLANT_BASED:
      return TopicTypeIds.PLANT_BASED;
    case TopicTypes.HOLISTIC:
      return TopicTypeIds.HOLISTIC;
  }
};

export const getGlrcTopicPath = (name: string): string => {
  const nameLowerCase = name.toLowerCase();

  if (nameLowerCase.includes('kitchen')) {
    return TopicTypes.KITCHEN_BASICS;
  } else if (nameLowerCase.includes('culinary')) {
    return TopicTypes.CULINARY;
  } else if (nameLowerCase.includes('baking')) {
    return TopicTypes.PASTRY;
  } else if (nameLowerCase.includes('plant')) {
    return TopicTypes.PLANT_BASED;
  } else {
    return TopicTypes.HOLISTIC;
  }
};

const topicTitleMap = {
  [TopicTypes.KITCHEN_BASICS]: 'Kitchen Skills and Techniques',
  [TopicTypes.CULINARY]: 'Culinary',
  [TopicTypes.PASTRY]: 'Pastry',
  [TopicTypes.PLANT_BASED]: 'Plant-Based',
  [TopicTypes.HOLISTIC]: 'Holistic Wellness'
};

export const getTopicTitle = (topic: TopicTypes): string => {
  return topicTitleMap[topic] ?? (topic as string);
};

export const getVideoLength = (time: number): string => {
  const mins = Math.floor(time / 60);
  const secs = (time % 60).toString().padStart(2, '0');
  return `${mins}:${secs}`;
};

export const convertGlrcContentToGlrcVideo = (
  glrcContent: GlrcContent
): GlrcVideo => {
  return {
    id: glrcContent.id,
    url: glrcContent.link,
    title: glrcContent.title,
    length: glrcContent?.lengthOfVideo
      ? getVideoLength(glrcContent?.lengthOfVideo)
      : '',
    lengthNumber: glrcContent.lengthOfVideo,
    imgUrl: glrcContent.thumbnail,
    bookmarked: glrcContent.bookmarked,
    topicLabel: glrcContent.contentTopic.label,
    topicCodebook: glrcContent.contentTopic,
    section: glrcContent?.section ?? ''
  };
};

const fetchYouTubeData = async (videos: GlrcVideo[]): Promise<GlrcVideo[]> => {
  return await Promise.all(
    videos.map(async (v) => {
      try {
        const videoId = v.url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)[2];
        const res = await fetch(
          `https://youtube.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails%2Cstatistics&id=${videoId}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
        );

        const data = await res.json();
        const formattedDuration = Duration.fromISO(
          data.items[0].contentDetails.duration
        ).toFormat('mm:ss');

        return {
          ...v,
          title: data.items[0].snippet.title,
          imgUrl: data.items[0].snippet.thumbnails.medium.url,
          length: formattedDuration,
          bookmarked: v.bookmarked
        };
      } catch (error) {
        console.error('Error fetchYouTubeData', error);
        return v;
      }
    })
  );
};

export const fetchVideoData = async (
  videos: GlrcContent[]
): Promise<GlrcVideo[]> => {
  const glrcVideos = videos.map((v) => convertGlrcContentToGlrcVideo(v));

  const allVimeoVideos = glrcVideos.filter((v) => v.url.includes('vimeo'));
  const allYouTubeVideos = glrcVideos.filter((v) => v.url.includes('youtube'));

  const youtubeVideosWithMetadata = await fetchYouTubeData(allYouTubeVideos);

  return [...youtubeVideosWithMetadata, ...allVimeoVideos];
};

export const convertGlrcContentToGlrcVideoWithMetadata = async (
  video: GlrcContent
): Promise<GlrcVideo> => {
  const videoWithMetadata = (await fetchVideoData([video]))[0];
  return videoWithMetadata;
};

export const getGlrcSectionsByTopic = (
  data: GlrcVideo[],
  topic: number
): { label: string; inputValue?: string }[] => {
  const contentByTopic = data.filter(
    (item) => item.topicCodebook.value === topic
  );

  const sectionLabels: string[] = [
    ...new Set(contentByTopic.map((item) => item.section))
  ];

  const labelMap = sectionLabels.map((sl) => ({
    label: sl
  }));

  return labelMap;
};

export const formatSecondsToTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Use template literals to format the result as "m:ss"
  const formattedTime = `${minutes}:${
    remainingSeconds < 10 ? '0' : ''
  }${remainingSeconds}`;

  return formattedTime;
};
