import * as React from 'react';
import ImageUploadWell, {
  ImageUploadWellProps
} from './ImageUpload/ImageUploadWell';
import { BadgeMapping } from '@common/mappings/BadgeMappings';
import { BadgeType } from '@api/models/codebook.models';
import chefHat from '@assets/svg/chef_hat.round.gold4.svg';
import companyDefault from '@assets/svg/employer-def.svg';
import { Grid } from '@mui/material';
import ImageWithBadge from './ImageWithBadge';
import ReactHookFormsSelect from './Forms/ReactHookFormsSelect';
import { styled } from '@mui/system';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchBadgeCodebooks } from '@common/fetches/useFetchBadgeCodebooks';
import { useFormContext } from 'react-hook-form';
import { useKeycloakContext } from '@common/context/keycloakContext';

const Styled = {
  GridWithCenterContent: styled(Grid, {
    shouldForwardProp: (prop) => prop !== 'scaleDown'
  })<{ scaleDown?: boolean }>(({ scaleDown }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    img: {
      maxHeight: '100px',
      objectFit: scaleDown ? 'scale-down' : 'cover',
      width: scaleDown ? '100%' : '100px',
      padding: scaleDown ? '8px' : '0px'
    }
  }))
};

interface Props {
  uploadWellProps: ImageUploadWellProps;
  badgeType: BadgeType;
  badgeName: string;
  employerUpload?: boolean;
  disableBadge?: boolean;
  workLearnPartner?: boolean;
}

const ProfileImageUpload: React.FC<Props> = ({
  uploadWellProps,
  badgeType,
  badgeName,
  employerUpload,
  disableBadge = false,
  workLearnPartner
}) => {
  const featureFlags = useFeatureFlags();
  const { isCandidate } = useKeycloakContext();

  const { files } = uploadWellProps;
  const [currentFiles, setCurrentFiles] = React.useState(files);

  const { watch } = useFormContext();
  const badge = watch(badgeName);

  const { candidateBadges, employerBadges } = useFetchBadgeCodebooks();

  const badgeMap = BadgeMapping(badge);

  const badgeOptions =
    badgeType === BadgeType.CANDIDATE_BADGE
      ? candidateBadges
      : workLearnPartner /* eslint-disable @typescript-eslint/indent */
      ? employerBadges /* eslint-disable @typescript-eslint/indent */
      : employerBadges.filter((item) => !item.workAndLearn);

  const profileUploaded = !!currentFiles?.length;
  const defaultImage = employerUpload ? companyDefault : chefHat;
  const scaleDown = profileUploaded && employerUpload;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ImageUploadWell
          {...uploadWellProps}
          setPhotosOnParent={setCurrentFiles}
        />
      </Grid>
      {(featureFlags.PROFILE_BADGE || isCandidate) && !disableBadge && (
        <>
          <Grid item xs={12} md={6}>
            <ReactHookFormsSelect
              name={badgeName}
              label="Add a Frame"
              placeholder="Select a badge"
              options={badgeOptions}
            />
          </Grid>

          <Styled.GridWithCenterContent
            item
            xs={12}
            md={6}
            scaleDown={scaleDown}
          >
            <ImageWithBadge
              src={profileUploaded ? currentFiles[0].fullPath : defaultImage}
              badge={badgeMap?.[badge]}
              noBorder={!profileUploaded}
              height="100px"
              width="100px"
            />
          </Styled.GridWithCenterContent>
        </>
      )}
    </Grid>
  );
};

export default ProfileImageUpload;
