import * as React from 'react';
import { benefits, cultureHealthSafety } from '@common/tags/tagStrings';
import Box from '@mui/material/Box';
import ReactHookFormsAutoComplete from '@components/Forms/ReactHookFormsAutoComplete';
import StepSection from '@components/StepSection';
import StepSubSection from '@components/StepSubSection';

const CultureAndBenefitsSection: React.FC = () => {
  return (
    <StepSection title="Culture and Benefits">
      <StepSubSection title="Which company culture and benefits are important to you?">
        <ReactHookFormsAutoComplete
          required
          multipleSelect
          options={cultureHealthSafety.culture}
          name="attributes"
          title="Culture"
          inputPlaceholder="Select all that apply"
          rules={{ required: true }}
        />
        <Box sx={{ marginTop: '62px' }} />
        <ReactHookFormsAutoComplete
          required
          multipleSelect
          options={[
            ...benefits.healthWellnessBenefits,
            ...benefits.professionalDevBenefits,
            ...benefits.retirementBenefits
          ]}
          name="benefits"
          title="Benefits"
          inputPlaceholder="Select all that apply"
          rules={{ required: true }}
        />
      </StepSubSection>
    </StepSection>
  );
};

export default CultureAndBenefitsSection;
