import * as React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { loginWithStorageRedirect } from '@common/helpers/login';
import { styled } from '@mui/system';
import { useKeycloak } from '@react-keycloak/web';
import { useLocation } from 'react-router-dom';

const Styled = {
  Container: styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '100px'
  })
};

enum Actions {
  LOGIN = 'login'
}

const KeycloakReroute: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const { keycloak } = useKeycloak();

  const search = useLocation().search;
  const actionQueryParam = new URLSearchParams(search).get('action');

  const login = React.useCallback(() => {
    loginWithStorageRedirect(keycloak.login);
  }, [loginWithStorageRedirect, keycloak.login]);

  React.useEffect(() => {
    if (actionQueryParam === Actions.LOGIN) {
      login();
    } else {
      setIsLoading(false);
    }
  }, [actionQueryParam]);

  if (isLoading) {
    return (
      <Styled.Container>
        <CircularProgress color="secondary" size={80} />
      </Styled.Container>
    );
  }

  return (
    <Styled.Container>
      <Typography>An error has occured</Typography>
    </Styled.Container>
  );
};

export default KeycloakReroute;
