import * as React from 'react';
import { DashboardJobPost, JobPost } from '@api/models/singleJobPostApi.models';
import { DateTime } from 'luxon';
import { JobApplicationCandidates } from '@api/models/jobApplicationApi.models';
import JobPostingsTable from '@pages/Employer/EmployerDashboard/JobPostingsTable/JobPostingsTable';
import TableWrapperWithSort from '@components/TableWrapperWithSort';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const sortButtons = [
  {
    label: 'Location',
    id: 'locations'
  },
  {
    label: 'Job ID',
    id: 'id'
  },
  {
    label: 'Job Title',
    id: 'jobTitle'
  },
  {
    label: 'Number of Candidates',
    id: 'candidates'
  }
];

interface Props {
  ctaHref: string;
  tableHeader: string;
  jobPosts: JobPost[];
  applicantsPerJobId?: JobApplicationCandidates[];
  companyName?: string;
  breadcrumb?: { href: string; location: string };
  handleVisibility: (id: number) => Promise<void>;
}

const JobPostingsSection: React.FC<Props> = ({
  ctaHref,
  tableHeader,
  jobPosts,
  applicantsPerJobId,
  companyName,
  breadcrumb,
  handleVisibility
}) => {
  const { width } = useWindowDimensions();

  const formattedJobs: DashboardJobPost[] = React.useMemo(() => {
    if (!!jobPosts?.length && !!applicantsPerJobId?.length) {
      return jobPosts.map((job) => {
        const candidates = applicantsPerJobId.find(
          (application: { jobId: number }) => application.jobId === job.id
        );

        const locations = job.locations.map((loc) => loc.description);

        return {
          ...job,
          locations,
          candidates: candidates?.applicationCount ?? 0,
          createdAt: DateTime.fromISO(job.createdAt).toFormat('MM/dd/yyyy')
        };
      });
    }

    return [];
  }, [jobPosts, applicantsPerJobId]);

  return (
    <TableWrapperWithSort
      headerTitle={tableHeader}
      sortButtons={sortButtons}
      totalCount={jobPosts.length}
      initialSortCategory="id"
      initialRowsPerPage={10}
      rowsPerPageOptions={[10, 25, 50, 100]}
      breadcrumb={breadcrumb}
      disabled={!jobPosts || jobPosts.length === 0}
      displayMobile={width < 460}
      styledHeader
      cta={{
        text: 'New Job Post',
        path: ctaHref,
        startIcon: 'ri-add-line',
        dataTestId: 'createNewJobPostButton'
      }}
    >
      <JobPostingsTable
        jobList={formattedJobs}
        companyName={companyName}
        handleVisibility={handleVisibility}
      />
    </TableWrapperWithSort>
  );
};

export default JobPostingsSection;
