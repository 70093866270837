import React, { useEffect, useState } from 'react';
import { Codebook } from '@api/models/codebook.models';
import { CodebookApi } from '@api/Codebook.api';

type FetchCampusCodebook = {
  campuses: Codebook[];
  loading: boolean;
  loaded: boolean;
};

/**
 * Fetch campus Codebook, used for CandidateAboutMeSection within account creation
 *
 * @returns `{ campuses: Codebook[]; loading: boolean; loaded: boolean; }`
 */
export const useFetchCampusCodebook = (): FetchCampusCodebook => {
  const [campuses, setCampuses] = useState<Codebook[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        setLoading(true);

        const res = await CodebookApi.getCampuses();
        setCampuses(res.data);
        setLoaded(true);
      } catch (error: any) {
        console.error(
          'Error for useFetchCampusCodebook -> CodebookApi.getCampuses()',
          error
        );
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    };
    fetchData();
  }, []);

  return { campuses, loading, loaded };
};
