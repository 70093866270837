import * as React from 'react';
import { Applicant, ApplicantsReq } from '@api/models/adminDashboardApi.models';
import { AdminDashboardApi } from '@api/AdminDashboard.api';
import { CompanyApi } from '@api/Company.api';
import { IPageInfoRes } from '@interfaces/pageInfo.interfaces';

export type FetchApplicants = {
  applicants: Applicant[];
  loaded: boolean;
  pageInfo: IPageInfoRes | null;
};

export const useFetchApplicants = (
  req: ApplicantsReq,
  companyId?: number,
  parentCompanyId?: number
): FetchApplicants => {
  const [applicants, setApplicants] = React.useState<Applicant[]>([]);
  const [pageInfo, setPageInfo] = React.useState<IPageInfoRes | null>(null);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const fetchApplicants = async (): Promise<void> => {
    try {
      let res;
      if (parentCompanyId) {
        if (companyId) {
          res = await CompanyApi.getSubsidiaryCompanyApplicants(
            parentCompanyId,
            companyId,
            req
          );
        } else {
          res = await CompanyApi.getParentCompanyApplicants(
            parentCompanyId,
            req
          );
        }
      } else {
        if (companyId) {
          res = await AdminDashboardApi.getApplicantsByCompanyId(
            companyId,
            req
          );
        } else {
          res = await AdminDashboardApi.getApplicants(req);
        }
      }

      setApplicants(res.data.applicants);
      setPageInfo(res.data.pageInfo);

      setLoaded(true);
    } catch (error: any) {
      console.error(
        'Error for useFetchApplicants -> AdminDashboardApi.getApplicants()',
        error
      );
    } finally {
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    fetchApplicants();
  }, [req]);

  return { applicants, pageInfo, loaded };
};
