import * as React from 'react';
import { Box, Typography } from '@mui/material';
import PremierPartnersCarousel from '@components/PremierPartnerCarousel';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)({
    width: '100%',
    padding: '72px 16px 40px'
  }),
  Content: styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1120px',
    margin: '0 auto',
    '& .grid-header': {
      marginBottom: '70px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '40px'
      }
    }
  }))
};

const EmployerSpotlight: React.FC = () => {
  const [hide, setHide] = React.useState<boolean>(false);

  return (
    <>
      {!hide ? (
        <Styled.Root>
          <Styled.Content>
            <Typography variant="EC_TYPE_4XL" className="grid-header">
              Employer Spotlight
            </Typography>
            <PremierPartnersCarousel
              imagesPerSlide={3}
              setHideWrapper={setHide}
            />
          </Styled.Content>
        </Styled.Root>
      ) : (
        <Box p="20px" />
      )}
    </>
  );
};

export default EmployerSpotlight;
