export const workAndLearnMap = {
  YES: 'Yes, I\'m interested in pursuing education while working',
  NO: 'No, I\'m not interested in pursuing education while working',
  LEARN_MORE: 'I\'m interested in learning more about this'
};

export const mobileWorkAndLearnMap = {
  YES: 'Yes, I\'m interested',
  NO: 'No, I\'m not interested',
  LEARN_MORE: 'I\'m interested in learning more'
};
