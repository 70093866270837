import * as React from 'react';
import { BadgeMapping, getBadge } from '@common/mappings/BadgeMappings';
import { Box, Grid } from '@mui/material';
import {
  getJobCardProps,
  JobCard,
  JobPost
} from '@api/models/singleJobPostApi.models';
import { BadgeCodebook } from '@api/models/codebook.models';
import BottomPagination from '@components/BottomPagination';
import FiltersResultsBar from '@components/FiltersResultsBar';
import { IPageInfoRes } from '@interfaces/pageInfo.interfaces';
import JobMarketplaceCard from '@components/JobMarketplace/JobMarketplaceCard/JobMarketplaceCard';

interface CardsWrapperProps {
  cardsDetails: JobPost[];
  pageInfo: IPageInfoRes;
  defaultPageNumber: number;
  goToPage: (pageNumber: number) => void;
}

const JobPostingCardsWrapper: React.FC<CardsWrapperProps> = (
  props
): JSX.Element => {
  const { cardsDetails, pageInfo, defaultPageNumber, goToPage } = props;

  const handleChange = (event, value: number): void => {
    goToPage(value);
  };

  const jobCards: JobCard[] = React.useMemo(
    () => getJobCardProps(cardsDetails),
    [cardsDetails]
  );

  return (
    <>
      <Box data-testid="jobMarketplaceCardsWrapper">
        <FiltersResultsBar pageInfo={pageInfo} availableItems="Jobs" />
        <Grid container spacing={2}>
          {jobCards.map((jobCard, idx) => {
            const badgeMap = BadgeMapping(
              jobCard.companyBadge as BadgeCodebook
            );
            const badge = getBadge(badgeMap, jobCard.companyBadge?.value);
            return (
              <Grid item key={idx} xl={3} lg={4} md={6} sm={6} xs={12}>
                <JobMarketplaceCard {...jobCard} badge={badge} />
              </Grid>
            );
          })}
        </Grid>
        <BottomPagination
          wrapperSx={{ marginTop: '32px' }}
          count={pageInfo?.totalPages}
          page={pageInfo?.currentPage}
          onChange={handleChange}
          defaultPage={defaultPageNumber}
        />
      </Box>
    </>
  );
};

export default JobPostingCardsWrapper;
