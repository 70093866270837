import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme
} from '@mui/material';
import { EmployerApi } from '@api/Employer.api';
import React from 'react';
import { styled } from '@mui/system';
import { useFetchEmployerByKeycloakUserId } from '@common/fetches/useFetchEmployerByKeycloakUserId';
import { useKeycloakContext } from '@common/context/keycloakContext';

const Styled = {
  Title: styled(Typography)(({ theme }) => ({
    color: theme.palette.GOLD_1.main,
    marginBottom: '20px'
  }))
};

const ExternshipWelcomeModal: React.FC = () => {
  const theme = useTheme();
  const { keycloakUser } = useKeycloakContext();
  const { employer } = useFetchEmployerByKeycloakUserId(keycloakUser.sub);

  const [openModal, setOpenModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (employer?.externshipWelcomeModalShown === false) {
      setOpenModal(true);
    }
  }, [employer]);

  const handleContinueClick = async (): Promise<void> => {
    if (!employer) return;
    try {
      await EmployerApi.markExternshipWelcomeModalAsViewed(employer.id);
      setOpenModal(false);
    } catch (error: any) {
      console.error(
        'Error for ExternshipWelcomeModal at Employer Dashboard',
        error
      );
    }
  };

  return (
    <Dialog fullWidth open={openModal} scroll="paper" onClose={(): void => {}}>
      <DialogContent>
        <>
          <Styled.Title variant="EC_TYPE_3XL">
            Welcome To Escoffier!
          </Styled.Title>

          <Typography variant="EC_TYPE_LG">
            A talented culinary student has proposed your culinary business as
            an externship site.
          </Typography>

          <Typography
            variant="AEGS_TYPE_PARAGRAPH"
            sx={{ marginY: '25px' }}
            color={theme.palette.BLACK.main}
          >
            Hosting an externship is easy with the Escoffier Externship
            Management System powered by E-Connect. Students take the Industry
            Externship as the last class of their program and are ready for
            employment immediately upon completion of the course.
          </Typography>

          <Typography
            variant="AEGS_TYPE_PARAGRAPH"
            color={theme.palette.BLACK.main}
          >
            Our staff will call you at your culinary business to verify the
            opportunity. You will be notified of the approval and receive a full
            copy of the documents for your records. The externs&apos; weekly
            timesheets will be available in this system for approval by the
            assigned supervisor.
          </Typography>
        </>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          sx={{
            padding: '22px 25px 25px 25px',
            typography: 'EC_TYPE_SM',
            color: 'white'
          }}
          onClick={handleContinueClick}
          variant="contained"
        >
          Get Started
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExternshipWelcomeModal;
