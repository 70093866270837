import TagManager from 'react-gtm-module';

interface ReturnValue {
  sendToGtm: (e: string, dataLayer: any) => void;
}

export const useTagManager = (): ReturnValue => {
  const sendToGtm = (event: string, dataLayer: any): void => {
    TagManager.dataLayer({
      dataLayer: {
        event,
        ...dataLayer
      }
    });
  };

  return { sendToGtm };
};
