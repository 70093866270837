import * as React from 'react';
import BasicModal, { BasicModalActionStyle } from '@components/BasicModal';
import { Box, Typography } from '@mui/material';
import EcGoogleMap from '@components/EcGoogleMap';
import { getTwoLineAddress } from '@common/helpers/addressHelpers/getTwoLineAddress';
import { Location } from '@interfaces/location.interfaces';
import theme from '@themes/ui/escoffier';

export interface ExistingCompany {
  location: Location;
  companyId: number;
  email: string;
  users: number;
}

interface Props {
  open: boolean;
  handleClose: () => void;
  company: ExistingCompany;
  handleRemoveLocation: (location: Location) => void;
  handleJoinGroup: () => void;
}

const CompanyAlreadyExistsModal: React.FC<Props> = ({
  open,
  handleClose,
  handleRemoveLocation,
  handleJoinGroup,
  company
}) => {
  const handleJoin = (): void => {
    handleJoinGroup();
    handleClose();
  };

  const handleRemove = (): void => {
    handleRemoveLocation(company.location);
    handleClose();
  };

  return (
    <BasicModal
      title="A company at this location already exists"
      open={open}
      handleClose={handleClose}
      maxWidth="md"
      actionStyle={BasicModalActionStyle.SECONDARY}
      primaryAction={{
        label: 'Join this group',
        action: handleJoin
      }}
      secondaryAction={{
        label: 'Not you? Remove company',
        action: handleRemove
      }}
    >
      <Box>
        <Typography variant="EC_TYPE_BASE">
          The location you selected is already claimed by an Escoffier Connect
          account. You can request to join this account, or create a new account
          at a different location.
        </Typography>
        <Box mt="24px" mb="24px">
          <Typography
            variant="EC_TYPE_4XL"
            sx={{
              color: theme.palette.GOLD_1.main,
              marginBottom: 0
            }}
          >
            {company.location.name}
          </Typography>
          {getTwoLineAddress(company.location)}
        </Box>
        <EcGoogleMap locations={[company.location]} />
      </Box>
    </BasicModal>
  );
};

export default CompanyAlreadyExistsModal;
