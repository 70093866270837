import * as React from 'react';
import { Button, Theme, Typography } from '@mui/material';
import { ActivityLogEntity } from '@api/models/careerServicesApi.models';
import EcAccordion from '@components/EcAccordion';
import { styled } from '@mui/system';

interface Props {
  emailLogActivity: ActivityLogEntity;
}

const Styled = {
  ExpandBtn: styled(Button)(({ theme }) => ({
    ...(theme as Theme).typography.EC_TYPE_3XS,
    color: theme.palette.primary.main,
    padding: '4px 0'
  }))
};

const EmailActivityHistoryItem: React.FC<Props> = ({ emailLogActivity }) => {
  const [expandedEmailIds, setExpandedEmailIds] = React.useState<number[]>([]);

  return (
    <div>
      {' '}
      <>
        <EcAccordion
          style={{
            marginTop: '-16px',
            marginBottom: '-16px'
          }}
          expanded={expandedEmailIds.includes(emailLogActivity.id)}
          summaryComponent={
            <>
              <Typography
                style={{
                  whiteSpace: expandedEmailIds.includes(emailLogActivity.id)
                    ? 'normal'
                    : 'nowrap',

                  maxWidth: expandedEmailIds.includes(emailLogActivity.id)
                    ? 'unset'
                    : '540px',
                  maxHeight: expandedEmailIds.includes(emailLogActivity.id)
                    ? 'unset'
                    : '40px',
                  paddingTop: expandedEmailIds.includes(emailLogActivity.id)
                    ? '13px'
                    : '0'
                }}
              >
                <>
                  <Typography
                    style={{
                      display: 'block',
                      lineHeight: 1.2,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden'
                    }}
                    fontSize={'13px'}
                  >
                    <Typography
                      variant="EC_TYPE_2XS"
                      style={{ display: 'inline' }}
                    >
                      Subject:{' '}
                    </Typography>
                    {emailLogActivity.description
                      .split('Subject: ')[1]
                      ?.split(' Message:')[0]
                      ?.trim()}{' '}
                  </Typography>
                </>
              </Typography>
            </>
          }
        >
          <>
            <Typography
              style={{
                display: 'inline'
              }}
            >
              <Typography
                style={{
                  display: 'block',
                  lineHeight: 1.2,
                  paddingBottom: '10px',
                  paddingTop: '10px'
                }}
                fontSize={'13px'}
              >
                <Typography variant="EC_TYPE_2XS" style={{ display: 'inline' }}>
                  Message:{' '}
                </Typography>
                {emailLogActivity.description.split('Message: ')[1]}
              </Typography>
            </Typography>
          </>
        </EcAccordion>
        <Styled.ExpandBtn
          variant="text"
          onClick={(): void =>
            setExpandedEmailIds(
              expandedEmailIds.includes(emailLogActivity.id)
                ? expandedEmailIds.filter(
                    (item) => item !== emailLogActivity.id // eslint-disable-line @typescript-eslint/indent
                  ) // eslint-disable-line @typescript-eslint/indent
                : [...expandedEmailIds, emailLogActivity.id]
            )
          }
        >
          {expandedEmailIds.includes(emailLogActivity.id)
            ? 'Hide message'
            : 'Show message'}
        </Styled.ExpandBtn>
      </>
    </div>
  );
};

export default EmailActivityHistoryItem;
