/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  Accordion,
  AccordionSummary,
  Box,
  Tab,
  Typography,
  useTheme
} from '@mui/material';
import {
  CareerPathRole,
  CareerRole
} from '@interfaces/careerJourney.interfaces';
import { TabContext, TabList } from '@mui/lab';
import { CareerNodeStatus } from '@pages/Candidate/CareerPathPage/MyCareerGoal/CareerPathNode';
import CareerPathExploreMoreListLayout from './CareerPathExploreMoreListLayout';
import CareerPathExploreMoreSubwayLayout from './CareerPathExploreMoreSubwayLayout';
import { createCustomContext } from '@common/context/customContext';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { mediaQueries } from '@themes/ui/variables';
import { styled } from '@mui/system';
import { useWindowDimensions } from '@common/hooks/uiHelpers';
import { WorkExperience } from '@api/models/profileSetupApi.models';

const FONT_SIZE_BREAK = 430;
const FONT_SIZE_MEDIA_QUERY = `@media (min-width:${FONT_SIZE_BREAK + 1}px)`;
const MIN_750 = '@media (min-width:750px)';

const Styled = {
  Root: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'expanded'
  })<{ expanded: boolean }>(({ expanded, theme }) => ({
    backgroundColor: theme.palette.WHITE.main,
    borderRadius: '4px',
    '& .accordion': {
      backgroundColor: 'transparent',
      border: 'none'
    },
    '& .accordion-summary': {
      flexShrink: 0,
      flexDirection: 'row-reverse',
      maxWidth: '96%',
      borderRadius: '4px',
      border: !expanded ? `3px solid ${theme.palette.GOLD_1.main}` : 'none',
      margin: '0 auto',
      [mediaQueries.MIN_WIDTH_601]: {
        margin: 'unset'
      }
    },
    '& .accordion-icon': {
      color: theme.palette.GRAY_4.main,
      fontSize: '2em'
    },
    '& .accordion-text': {
      marginLeft: '4px',
      whiteSpace: 'nowrap',
      [FONT_SIZE_MEDIA_QUERY]: {
        marginLeft: '16px',
        transform: 'translateY(4px)'
      }
    },
    [mediaQueries.MIN_WIDTH_601]: {
      border: `3px solid ${theme.palette.GOLD_1.main}`,
      borderRadius: '10px',
      '& .accordion': {
        border: 'none'
      },
      '& .accordion-summary': {
        border: 'none',
        borderRadius: '10px',
        height: '135px'
      }
    }
  })),
  SummaryBox: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    [MIN_750]: {
      flexDirection: 'row',
      columnGap: '18px'
    }
  }),
  TabBox: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'expanded'
  })<{ expanded: boolean }>(({ expanded }) => ({
    ...(!expanded && {
      '& .MuiTab-root, Mui-selected, i': {
        color: 'white',
        backgroundColor: 'white',
        pointerEvents: 'none'
      }
    }),
    width: '96%',
    padding: '0 40px',
    marginTop: '24px',
    [MIN_750]: {
      marginTop: 0,
      marginRight: '56px',
      width: 'max-content',
      padding: '0 24px'
    },
    '& .MuiTab-root': {
      flex: 1,
      flexShrink: 1,
      width: 'max-content',
      justifyContent: 'center'
    }
  })),
  DisclaimerBox: styled(Box)({
    paddingLeft: '24px',
    paddingBottom: '48px'
  })
};

const a11yProps = (index: number): { id: string; 'aria-controls': string } => {
  return {
    id: `career-path-explore-more-tab-${index}`,
    'aria-controls': `career-path-explore-more-tab-${index}`
  };
};

interface ExploreMoreCardProps {
  careerRoles: CareerRole[];
  currentJobIds: number[];
  workExperience?: WorkExperience[];
  expandOnScroll: boolean;
  savedGoal?: CareerPathRole;
  tabValue: string;
  setTabValue: (event: React.SyntheticEvent, newVal: string) => void;
  setExpandOnScroll: (val: boolean) => void;
  setCareerGoal: (careerGoalId: number) => Promise<void>;
}

interface ExploreMoreContext {
  careerRoles: CareerRole[];
  getStatus: (role: CareerRole) => CareerNodeStatus;
  getRoleByName: (name: string) => CareerRole;
  setExpandedTrackRole: React.Dispatch<React.SetStateAction<CareerRole | null>>;
  setCareerGoal: (careerGoalId: number) => Promise<void>;
  expandedTrackRole: CareerRole | null;
  savedGoal?: CareerPathRole;
}

export const [useExploreMoreContext, ExploreMoreContextProvider] =
  createCustomContext<ExploreMoreContext>();

const CareerPathExploreMoreCard = ({
  careerRoles,
  currentJobIds,
  workExperience,
  expandOnScroll,
  savedGoal,
  tabValue,
  setTabValue,
  setExpandOnScroll,
  setCareerGoal
}: ExploreMoreCardProps): React.ReactElement => {
  const theme = useTheme();

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [expandedTrackRole, setExpandedTrackRole] =
    React.useState<CareerRole | null>(null);

  const isExpanded = !!expanded || expandOnScroll;

  // Filter out 'Other' and 'Dishwasher'
  const filterRoles = careerRoles.filter(
    (role) => role.id !== 999 && role.id !== 33
  );

  const getRoleByName = (name: string): CareerRole => {
    return (
      filterRoles.find((role) => role.name.toLowerCase() === name) ??
      ({} as CareerRole)
    );
  };

  const { width } = useWindowDimensions();
  const variantBreakpoint = width > 430;

  const typographyVariant = React.useMemo(
    () => (variantBreakpoint ? 'EC_TYPE_3XL' : 'EC_TYPE_LG'),
    [variantBreakpoint]
  );

  const handleExpand =
    (panel: string) =>
    (event, isExpanded: boolean): void => {
      setExpanded(isExpanded ? panel : false);
      setExpandOnScroll(!expandOnScroll);
    };

  const getStatus = (career: CareerRole): CareerNodeStatus => {
    if (currentJobIds.includes(career.id)) {
      return 'current';
    } else if (
      workExperience?.some((exp) => exp.position.value === career.id)
    ) {
      return 'complete';
    } else {
      return 'incomplete';
    }
  };

  return (
    <Styled.Root data-testid="explore-more-card-root" expanded={isExpanded}>
      <TabContext value={tabValue}>
        <Accordion
          data-testid="explore-more-accordion"
          expanded={isExpanded}
          onChange={handleExpand('exploreMore')}
          className="accordion"
        >
          <Styled.SummaryBox>
            <AccordionSummary
              data-testid="explore-more-accordion-summary"
              expandIcon={<ExpandMore className="accordion-icon" />}
              aria-controls="explore-more-content"
              className="accordion-summary"
              id="exploreMore-header"
            >
              <Typography
                variant={typographyVariant}
                className="accordion-text"
                color={theme.palette.BLACK.main}
              >
                Explore more career paths
              </Typography>
            </AccordionSummary>
            <Styled.TabBox expanded={isExpanded}>
              <TabList
                onChange={setTabValue}
                aria-label="explore more careers tabs"
                TabIndicatorProps={{
                  sx: { ...(!isExpanded && { backgroundColor: 'white' }) }
                }}
              >
                <Tab
                  data-testid="career-path-explore-more-tab-1"
                  icon={<i className="ri-menu-line" />}
                  value="1"
                  {...a11yProps(0)}
                />
                <Tab
                  data-testid="career-path-explore-more-tab-2"
                  icon={<i className="ri-map-line" />}
                  value="2"
                  {...a11yProps(1)}
                />
              </TabList>
            </Styled.TabBox>
          </Styled.SummaryBox>
          <ExploreMoreContextProvider
            value={{
              careerRoles: filterRoles,
              savedGoal,
              expandedTrackRole,
              getStatus,
              setCareerGoal,
              getRoleByName,
              setExpandedTrackRole
            }}
          >
            <CareerPathExploreMoreListLayout />
            <CareerPathExploreMoreSubwayLayout />
          </ExploreMoreContextProvider>
          <Styled.DisclaimerBox>
            <Typography variant="EC_TYPE_3XS">
              These are common career pathways that many people in the culinary
              industry have taken. They are not universal nor guaranteed to
              advance from one to another.
            </Typography>
          </Styled.DisclaimerBox>
        </Accordion>
      </TabContext>
    </Styled.Root>
  );
};

export default CareerPathExploreMoreCard;
