import { CandidateApi } from '@api/Candidate.api';
import { useSetStoreValue } from 'react-context-hook';

interface SetCandidateEnabled {
  /**
   * Sets candidate enabled status to true
   */
  setEnabled: (candidateId: number) => Promise<void>;
  /**
   * Sets candidate enabled status to false
   */
  setDisabled: (candidateId: number) => Promise<void>;
}

export const useSetCandidateEnabled = (): SetCandidateEnabled => {
  const setCandidateEnabled = useSetStoreValue<boolean>('candidateEnabled');

  const setEnabled = async (candidateId: number): Promise<void> => {
    try {
      await CandidateApi.setEnabled(candidateId, true).then(() => {
        setCandidateEnabled(true);
      });
    } catch (error: any) {
      console.error(
        'Error for useSetCandidateEnabled -> CandidateApi.setEnabled(true)',
        error
      );
    }
  };

  const setDisabled = async (candidateId: number): Promise<void> => {
    try {
      await CandidateApi.setEnabled(candidateId, false).then(() => {
        setCandidateEnabled(false);
      });
    } catch (error: any) {
      console.error(
        'Error for useSetCandidateEnabled -> CandidateApi.setEnabled(false)',
        error
      );
    }
  };

  return { setEnabled, setDisabled };
};
