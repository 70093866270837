import * as React from 'react';
import { Box, Theme, Typography, useTheme } from '@mui/material';
import { GRAY_1 } from '@themes/ui/escoffier';
import { styled } from '@mui/system';

interface BoxProps {
  disableMargin: boolean;
  outlined: boolean;
}

const SkillBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'disableMargin' && prop !== 'outlined'
})<BoxProps>(({ disableMargin, outlined, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '4px',
  padding: '5px 10px',
  marginRight: disableMargin ? 0 : '13px',
  marginBottom: disableMargin ? 0 : '12px',
  fontSize: (theme as Theme).typography.EC_TYPE_2XS.fontSize,
  fontWeight: (theme as Theme).typography.EC_TYPE_2XS.fontWeight,
  textAlign: 'center',
  ...(outlined && {
    // outline: `1px solid ${theme.palette.GOLD_1.main}`,
    overflow: 'hidden'
  }),
  '&:last-child': {
    marginRight: 0
  },
  '& .skill-tag-text': {
    margin: 'auto'
  },
  '& .skill-start-icon': {
    marginRight: '4px',
    lineHeight: '14px'
  }
}));

type SkillVariant = 'DARK_GOLD' | 'GRAY';

interface SkillProps {
  skill: string;
  bgColor?: string;
  disableMargin?: boolean;
  checked?: boolean;
  sx?: React.CSSProperties;
  variant?: SkillVariant;
  startIcon?: string;
}

const Skill = ({
  disableMargin = false,
  bgColor = GRAY_1,
  skill,
  checked = false,
  sx,
  variant,
  startIcon
}: SkillProps): React.ReactElement => {
  const theme = useTheme();

  // Add check mark symbol to skill string if checked
  const skillText = checked ? `\u2713 ${skill}` : skill;

  const gold = theme.palette.GOLD_1.main;
  const gray1 = theme.palette.GRAY_1.main;
  const black = theme.palette.GRAY_1.contrastText;
  const white = theme.palette.GOLD_1.contrastText;

  const _bgColor = React.useMemo(() => {
    if (checked) return gold;

    if (variant) {
      switch (variant) {
        case 'DARK_GOLD':
          return gold;
        case 'GRAY':
          return gray1;
      }
    }

    return bgColor;
  }, [checked, bgColor, variant]);

  const textColor = React.useMemo(() => {
    switch (_bgColor) {
      case gold:
        return white;
      case gray1:
        return black;
    }
  }, [_bgColor]);

  return (
    <SkillBox
      data-testid={`skill-container-${skill}`}
      disableMargin={disableMargin}
      outlined={_bgColor === gray1}
      sx={{
        backgroundColor: _bgColor,
        color: textColor,
        ...sx
      }}
    >
      <Typography
        data-testid={`skill-typography-${skill}`}
        variant="EC_TYPE_2XS"
        color={textColor}
        className="skill-tag-text"
      >
        {startIcon && <i className={`${startIcon} skill-start-icon`} />}
        {skillText}
      </Typography>
    </SkillBox>
  );
};

export default Skill;
