/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  CompanyProfile,
//  CompanyTheme,
  CompanyThemePutRequest
} from '@api/models/companyApi.models';
import { FormProvider, useForm } from 'react-hook-form';
import { Toast, ToastProps } from '@components/Toast';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { Button } from '@mui/material';
import { CompanyApi } from '@api/Company.api';
import ReactHookFormsColorPicker from '@components/Forms/ReactHookFormsColorPicker';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import StepSection from '@components/StepSection';
import StepSubSection from '@components/StepSubSection';
import theme from '@themes/ui/escoffier';
import { useFetchCompanyTheme } from '@common/fetches/useFetchCompanyTheme';

interface FormValues {
  glrcHeaderText: string;
  primaryColor: string;
  secondaryColor: string;
  tileColor: string;
}

interface Props {
  company: CompanyProfile;
  // companyTheme?: CompanyTheme;
  // setCompanyTheme: React.Dispatch<
  //   React.SetStateAction<CompanyTheme | undefined>
  // >;
  toastProps: ToastProps;
}

const CustomizationSettings: React.FC<Props> = ({
  company,
  toastProps
}) => {
  const methods = useForm<FormValues>({
    defaultValues: {
      glrcHeaderText: 'Esource',
      primaryColor: theme.palette.primary.main,
      secondaryColor: theme.palette.secondary.main,
      tileColor: theme.palette.gold1.main
    }
  });

  const { handleSubmit, reset } = methods;
    const {
      companyTheme,
      setCompanyTheme,
//      loading: companyThemeLoaded
    } = useFetchCompanyTheme(company.id);

  React.useEffect(() => {
    if (companyTheme) {
      reset({
        ...companyTheme
      });
    }
  }, [companyTheme]);

  const handleValidForm = async (data: FormValues): Promise<void> => {
    const postBody: CompanyThemePutRequest = {
      id: companyTheme?.id,
      glrcHeaderText: data.glrcHeaderText,
      primaryColor: data.primaryColor,
      secondaryColor: data.secondaryColor,
      tileColor: data.tileColor
    };

    try {
      const updatedTheme = await CompanyApi.updateCompanyTheme(
        company.id,
        postBody
      );
      toastProps.openToast('Your changes have been updated successfully');
      setCompanyTheme(updatedTheme);
    } catch (error) {
      console.error(
        'Error for CustomizationSettings -> CompanyApi.updateCompanyTheme()',
        error
      );
    }
  };

  const handleSave = async (): Promise<void> => {
    await handleSubmit(handleValidForm)();
  };

  return (
    <StepSection title="Customize">
      <FormProvider {...methods}>
        <ReactHookFormsInput
          name="glrcHeaderText"
          label="Site Title"
          labelVariant="EC_TYPE_LG"
        />
        <StepSubSection title="My Colors:">
          <BottomMarginContentWrapper bottomMargin="16px">
            <ReactHookFormsColorPicker name="primaryColor" label="Primary" />
            <ReactHookFormsColorPicker name="tileColor" label="Tile Color" />
            <ReactHookFormsColorPicker
              name="secondaryColor"
              label="Secondary"
            />
          </BottomMarginContentWrapper>
        </StepSubSection>
      </FormProvider>
      <Button
        variant="contained"
        onClick={handleSave}
        sx={{ marginTop: '64px' }}
      >
        Update
      </Button>
      <Toast {...toastProps} />
    </StepSection>
  );
};

export default CustomizationSettings;
