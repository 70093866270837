import * as React from 'react';
import { Box, Button } from '@mui/material';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    margin: '32px 0 22px',
    '& .control-add-btn': {
      color: theme.palette.red2.main
    },
    '& .control-na-btn': {
      color: theme.palette.GRAY_3.main,
      marginLeft: '24px'
    }
  })),
  Btn: styled(Button)({
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    padding: 0,
    width: 'max-content',
    '& .MuiButton-startIcon': {
      fontWeight: 'normal',
      marginRight: '6px'
    }
  })
};

interface AddBtnProps {
  name: string;
  addBtnText: string;
  notApplicable: boolean;
  onClickAdd: () => void;
  onClickNA: () => void;
  register: UseFormRegister<FieldValues>;
}

const FormFieldControlButtons: React.FC<AddBtnProps> = ({
  name,
  addBtnText,
  notApplicable,
  onClickAdd,
  onClickNA,
  register
}) => {
  const iconClassName = notApplicable
    ? 'ri-checkbox-line'
    : 'ri-checkbox-blank-line';

  return (
    <Styled.Root>
      <Styled.Btn
        {...register(name)}
        name={name}
        variant="text"
        startIcon={<i className="ri-add-circle-fill" />}
        onClick={onClickAdd}
        data-testid={`${name}-add-btn`}
      >
        {addBtnText}
      </Styled.Btn>
      <Styled.Btn
        className="control-na-btn"
        onClick={onClickNA}
        variant="text"
        startIcon={<i className={iconClassName} />}
        data-testid={`${name}-na-btn`}
      >
        Not Applicable
      </Styled.Btn>
    </Styled.Root>
  );
};

export default FormFieldControlButtons;
