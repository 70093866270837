import * as React from 'react';
import { Box, Button, ButtonBase, ButtonProps } from '@mui/material';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)({
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  }),
  AddItemBox: styled(Box)(({ theme }) => ({
    position: 'relative',
    border: `1px dashed ${theme.palette.OUTLINE.main}`,
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.5s ease',
    i: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '30px',
      lineHeight: '30px',
      color: theme.palette.OUTLINE.main
    },
    '&:hover': {
      backgroundColor: theme.palette.GRAY_2.main
    }
  })),
  Button: styled(Button)({
    padding: '0 3px',
    height: '16px',
    marginTop: '12px',
    marginRight: 'auto'
  })
};

interface Props extends ButtonProps {
  buttonText: string;
  width?: string;
  height?: string;
  bgColor?: string;
  boxStyle?: React.CSSProperties;
}

const AddItemButton: React.FC<Props> = ({
  buttonText,
  width = '200px',
  height = '100px',
  bgColor = 'transparent',
  boxStyle,
  onClick
}) => {
  return (
    <Styled.Root>
      <ButtonBase onClick={onClick}>
        <Styled.AddItemBox
          sx={{
            ...boxStyle,
            width,
            height,
            backgroundColor: bgColor
          }}
        >
          <i className="ri-add-circle-fill" />
        </Styled.AddItemBox>
      </ButtonBase>
      <Styled.Button size="small" variant="text" onClick={onClick}>
        {buttonText}
      </Styled.Button>
    </Styled.Root>
  );
};

export default AddItemButton;
