import * as React from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { getTwoLineAddressObject } from './getTwoLineAddressObject';
import { Location } from '@interfaces/location.interfaces';

/**
 * @param {Location} The location to format into a two line address
 * @returns Returns a location as two line address
 */
export const getTwoLineAddress = (
  location: Location,
  textVariant: TypographyProps['variant'] = 'EC_TYPE_BASE'
): JSX.Element => {
  const address = getTwoLineAddressObject(location);

  return (
    <>
      <Typography variant={textVariant}>
        {address.line1}
        {location.addressLineTwo && `, ${location.addressLineTwo}`}
      </Typography>
      <Typography variant={textVariant}>{address.line2}</Typography>
    </>
  );
};
