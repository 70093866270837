/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  Box,
  Container,
  ContainerProps,
  Skeleton,
  Typography
} from '@mui/material';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '@themes/ui/variables';
import BlobLeftCorner from '@assets/BackgroundImages/blob.left-corner.png';
import BlobLeftMd from '@assets/BackgroundImages/blob.left-md.png';
import BlobLeftSm from '@assets/BackgroundImages/blob.left-sm.png';
import BlobRightLg from '@assets/BackgroundImages/blob.right-lg.png';
import BlobRightMd from '@assets/BackgroundImages/blob.right-md.png';
import BlobRightMd2 from '@assets/BackgroundImages/blob.right-md2.png';
// import BlobRightSm from '@assets/BackgroundImages/blob.right-sm.png';
import LinesLeft from '@assets/BackgroundImages/lines.left.png';
import LinesLeft2 from '@assets/BackgroundImages/lines.left2.png';
import LinesRight from '@assets/BackgroundImages/lines.right.png';
import LinesRightCorner from '@assets/BackgroundImages/lines.right-corner.png';
import ScrollToTopOnMount from '@components/ScrollToTopOnMount';
import { styled } from '@mui/system';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const Styled = {
  Img: styled('img')({
    position: 'absolute',
    zIndex: -100,
    objectFit: 'cover',
    objectPosition: 'center',
    maxWidth: 'unset',
    overflow: 'hidden'
  })
};

interface BackgroundProps {
  imgs: {
    src: string;
    style: React.CSSProperties;
  }[];
}

const BackgroundImgs = ({ imgs }: BackgroundProps): React.ReactElement => {
  return (
    <>
      {imgs.map((img) => (
        <Styled.Img src={img.src} sx={{ ...img.style }} key={img.src} />
      ))}
    </>
  );
};

interface Props {
  children: React.ReactNode;
  pageTitle?: string;
  bgColor?: string;
  customWidth?: string;
  maxWidth?: string;
  muiMaxWidth?: ContainerProps['maxWidth'];
  disableGutters?: ContainerProps['disableGutters'];
  padding?: string;
  pt?: string;
  pr?: string;
  pb?: string;
  pl?: string;
  margin?: string;
  mt?: string;
  mr?: string;
  mb?: string;
  ml?: string;
  boxShadow?: string;
  zIndex?: number;
  dataTestId?: string;
  disableMinHeight?: boolean;
  loading?: boolean;
  bgImgVariant?: 1 | 2 | 3 | 4 | 5 | 6;
}

const RootPageLayout: React.FC<Props> = (props) => {
  const { width } = useWindowDimensions();
  const horizontalPadding =
    width >= 600
      ? '65px'
      : width <= 600 && width > 480
      ? '32px'
      : width <= 480 && width > 360
      ? '16px'
      : '10px';

  const {
    children,
    pageTitle,
    bgColor = 'inherit',
    bgImgVariant,
    maxWidth = '1820px',
    muiMaxWidth = 'lg',
    customWidth = '100%',
    disableGutters = true,
    padding,
    pt = '38px',
    pr = horizontalPadding,
    pb = '72px',
    pl = horizontalPadding,
    margin,
    mt = '0px',
    mr = 'auto',
    mb = '0px',
    ml = 'auto',
    boxShadow,
    zIndex,
    disableMinHeight = false,
    loading = false
  } = props;

  const _padding = padding ?? `${pt} ${pr} ${pb} ${pl}`;
  const _margin = margin ?? `${mt} ${mr} ${mb} ${ml}`;

  const bgImgs1 = [
    { src: LinesLeft, style: { top: -350, left: 0 } },
    { src: BlobLeftMd, style: { top: '60%', left: 0 } },
    { src: BlobRightMd2, style: { right: 0 } }
  ];

  const bgImgs2 = [
    { src: BlobLeftCorner, style: { top: 0, left: 0 } },
    { src: LinesRightCorner, style: { top: 0, right: 0 } },
    { src: BlobRightMd2, style: { bottom: -150, right: 0 } }
  ];

  const bgImgs3 = [
    { src: LinesLeft2, style: { top: -80, left: 0 } },
    { src: BlobRightMd, style: { top: -340, right: 0 } }
  ];

  const bgImgs4 = [
    { src: BlobLeftSm, style: { top: 0, left: 0 } },
    { src: LinesRightCorner, style: { top: 0, right: 0 } },
    { src: LinesRight, style: { right: 0, bottom: -300 } }
  ];

  const bgImgs5 = [
    { src: BlobRightLg, style: { top: 150, right: 0 } },
    {
      src: LinesRightCorner,
      style: { top: 0, left: 0, transform: 'scaleX(-1)' }
    }
  ];

  const bgImgs6 = [
    { src: LinesLeft, style: { top: -50 } },
    { src: BlobRightLg, style: { right: 0, top: 500 } },
    { src: BlobLeftSm, style: { left: 0, top: 1700 } }
  ];

  const bgImgLayout = React.useMemo(() => {
    switch (bgImgVariant) {
      case 1:
        return bgImgs1;
      case 2:
        return bgImgs2;
      case 3:
        return bgImgs3;
      case 4:
        return bgImgs4;
      case 5:
        return bgImgs5;
      case 6:
        return bgImgs6;
      default:
        return undefined;
    }
  }, [bgImgVariant]);

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        ...(zIndex && { zIndex }),
        ...(boxShadow && { boxShadow })
      }}
    >
      {!!bgImgLayout && <BackgroundImgs imgs={bgImgLayout} />}
      <Container
        disableGutters={disableGutters}
        maxWidth={muiMaxWidth}
        sx={{
          position: 'relative',
          width: customWidth,
          maxWidth: maxWidth,
          minHeight: !disableMinHeight
            ? `calc(100vh - (${FOOTER_HEIGHT}px + ${HEADER_HEIGHT}px))`
            : undefined,
          margin: _margin,
          padding: _padding,
          bgcolor: bgColor,
          overflowX: 'hidden'
        }}
        data-testid={props.dataTestId}
      >
        <ScrollToTopOnMount />
        {pageTitle && (
          <Typography variant="EC_TYPE_3XL">{pageTitle}</Typography>
        )}
        {loading ? (
          <Skeleton
            variant="rectangular"
            sx={{
              minHeight: !disableMinHeight
                ? `calc(100vh - (${FOOTER_HEIGHT}px + ${HEADER_HEIGHT}px))`
                : undefined
            }}
          />
        ) : (
          <>{children}</>
        )}
      </Container>
    </Box>
  );
};

export default RootPageLayout;
