import { useEffect, useState } from 'react';
import { Codebook } from '@api/models/codebook.models';
import { CodebookApi } from '@api/Codebook.api';

export enum ExternshipCodebookType {
  COURSES,
  START_DATES,
  PROGRAMS,
  CURRENT_PROGRAMS,
  COURSES_BY_PROGRAM
}

interface FetchExternshipCodebooks {
  supportedPrograms?: Codebook[];
  loading: boolean;
  loaded: true | null;
}

// TODO: Leaving this for now until we know what campus data will be stored as Codebooks
export const useFetchExternshipCodebooks = (
  codebookType?: ExternshipCodebookType
): FetchExternshipCodebooks => {
  const [supportedPrograms, setSupportedPrograms] = useState<Codebook[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  const fetchProgramCodebooks = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await CodebookApi.getExternshipSupportedPrograms();

      setSupportedPrograms(res.data);
    } catch (error: any) {
      console.error(
        `Error for useFetchExternshipCodebooks -> fetchProgramCodebooks(): ${error}`
      );
    } finally {
      setLoaded(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    switch (codebookType) {
      case ExternshipCodebookType.PROGRAMS:
        fetchProgramCodebooks();
        break;
      default:
        fetchProgramCodebooks();
    }
  }, [codebookType]);

  return {
    supportedPrograms,
    loading,
    loaded
  };
};
