import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@mui/material';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  onDiscard: any;
  onSave: any;
}

const StepperDialog: React.FC<Props> = ({
  open,
  onDiscard,
  setOpen,
  onSave
}) => {
  return (
    <Dialog fullWidth open={open} onClose={(): void => setOpen(false)}>
      <DialogTitle>
        Save your changes?
        <IconButton
          aria-label="close"
          onClick={(): void => setOpen(false)}
          sx={{
            position: 'absolute',
            right: '16px',
            top: '16px',
            color: '#5C6B80'
          }}
        >
          <i className="ri-close-line"></i>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Changes have been made on this page. You may discard your changes or
          save them.
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          '&.MuiDialogActions-root>:not(:first-of-type)': {
            marginLeft: '16px'
          }
        }}
      >
        <Button variant="outlined" color="neutral" onClick={onDiscard}>
          Discard Changes
        </Button>
        <Button variant="contained" onClick={onSave}>
          Save Changes &amp; Leave
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StepperDialog;
