export const handleMoveUp = async <T>(
  itemId: number,
  currItemIdx: number,
  itemArray: T[],
  setArray: (array: T[]) => void,
  apiCall: (id: number) => Promise<void>,
  refObject?: T
): Promise<void> => {
  if (currItemIdx > 0) {
    await apiCall(itemId).then(() => {
      const updatedArray = [...itemArray];
      const newIdx = currItemIdx - 1;
      const tempItem = updatedArray[currItemIdx];

      updatedArray[currItemIdx] = updatedArray[newIdx];
      updatedArray[newIdx] = tempItem;

      if (refObject) {
        refObject = tempItem;
      }

      setArray(updatedArray);
    });
  }
};

export const handleMoveDown = async <T>(
  itemId: number,
  currItemIdx: number,
  itemArray: T[],
  setArray: (array: T[]) => void,
  apiCall: (id: number) => Promise<void>,
  refObject?: T
): Promise<void> => {
  if (currItemIdx < itemArray.length - 1) {
    await apiCall(itemId).then(() => {
      const updatedArray = [...itemArray];
      const newIdx = currItemIdx + 1;
      const tempItem = updatedArray[currItemIdx];

      updatedArray[currItemIdx] = updatedArray[newIdx];
      updatedArray[newIdx] = tempItem;

      if (refObject) {
        refObject = tempItem;
      }

      setArray(updatedArray);
    });
  }
};
