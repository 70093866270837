import { useEffect, useState } from 'react';
import { AdminContentManagementApi } from '@api/AdminContentManagement.api ';
import { PremierPartner } from '@api/models/adminContentManagementApi.models';

export type FetchBadgeCodebooks = {
  partners: PremierPartner[];
  setPartners: React.Dispatch<React.SetStateAction<PremierPartner[]>>;
  fetchPartners: () => Promise<void>;
  loading: boolean;
  loaded: boolean;
};

export const useFetchPremierPartners = (): FetchBadgeCodebooks => {
  const [partners, setPartners] = useState<PremierPartner[]>([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const fetchPartners = async (): Promise<void> => {
    try {
      setLoading(true);

      const res = await AdminContentManagementApi.getPremierPartners();

      setPartners(res);
      setLoaded(true);
    } catch (error: any) {
      console.error(
        'Error for useFetchPremierPartners -> CompanyApi.getPremierPartners()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  useEffect(() => {
    fetchPartners();
  }, []);

  return {
    partners,
    setPartners,
    fetchPartners,
    loading,
    loaded
  };
};
