import * as React from 'react';
import {
  Box,
  LinearProgress,
  linearProgressClasses,
  Typography
} from '@mui/material';
import { ExternshipStatus } from '@api/models/externshipApi.models';
import { styled } from '@mui/system';

const Styled = {
  ProgressBarWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'maxWidth'
  })<{ maxWidth?: string }>(({ maxWidth }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    ...(maxWidth && {
      maxWidth
    })
  })),
  ProgressBar: styled(LinearProgress)(({ theme }) => ({
    height: '24px',
    borderRadius: '20px',
    border: `1px solid ${theme.palette.GOLD_1.main}`,
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: '20px',
      backgroundColor: theme.palette.GOLD_1.main
    },
    [`& .${linearProgressClasses.bar2Buffer}`]: {
      borderRadius: '20px',
      backgroundColor: theme.palette.GRAY_1.main
    },
    [`& .${linearProgressClasses.dashed}`]: {
      backgroundImage: 'none',
      animation: 'none'
    }
  })),
  ProgressPercentage: styled(Typography)(({ theme }) => ({
    color: theme.palette.GRAY_4.main,
    position: 'absolute',
    top: '3px',
    right: 8
  })),
  ProgressSubtext: styled(Typography)({
    marginTop: '4px',
    alignSelf: 'end'
  })
};

interface Props {
  percentComplete: number;
  pendingProgress?: number;
  subtext?: string;
  maxWidth?: string;
  status?: ExternshipStatus;
  hideInactive?: boolean;
}

const EcProgressBar: React.FC<Props> = ({
  percentComplete,
  pendingProgress,
  subtext,
  maxWidth,
  status,
  hideInactive = false
}) => {
  // Hide if hideInactive is true and status is not ACTIVE or COMPLETED
  const hideProgressBar =
    hideInactive &&
    status !== ExternshipStatus.ACTIVE &&
    status !== ExternshipStatus.COMPLETED;

  // display 100% if more than 100% is complete
  const _percentComplete = percentComplete > 100 ? 100 : percentComplete;

  return (
    <>
      {!hideProgressBar && (
        <Styled.ProgressBarWrapper maxWidth={maxWidth}>
          <Styled.ProgressBar
            variant="buffer"
            value={_percentComplete}
            valueBuffer={pendingProgress}
          />
          <Styled.ProgressPercentage variant="EC_TYPE_XS">
            {Math.round(_percentComplete)}%
          </Styled.ProgressPercentage>
          {subtext && (
            <Styled.ProgressSubtext variant="EC_TYPE_3XS">
              {subtext}
            </Styled.ProgressSubtext>
          )}
        </Styled.ProgressBarWrapper>
      )}
    </>
  );
};

export default EcProgressBar;
