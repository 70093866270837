import * as React from 'react';
import { Box, Typography } from '@mui/material';
import QuizButton from '@components/Quiz/QuizButton';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useQuizContext } from '@components/Quiz/EcQuiz';

const Styled = {
  Root: styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '45px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column',
      rowGap: '15px',
      '& .prev-btn': {
        order: 2,
        width: '100%'
      },
      '& .count-div': {
        marginTop: '5px',
        order: 3
      },
      '& .next-btn': {
        order: 1,
        width: '100%'
      }
    }
  }),
  Info: styled(Box)({
    justifySelf: 'center',
    '& .count-text': {
      textShadow: '1px 0 #FFF, 0 1px #FFF'
    }
  })
};

const QuizButtonLayout = (): React.ReactElement => {
  const {
    totalQuestionCount,
    currAnswers,
    currQuestion,
    nextQuestion,
    prevQuestion,
    selectedAnswers
  } = useQuizContext();

  const { ordinalNumber } = currQuestion;

  const isSelected = selectedAnswers.some(
    (item) =>
      item.question === ordinalNumber &&
      currAnswers.find((item) => item.answerCode)
  );

  const GOLD_1 = theme.palette.GOLD_1.main;
  const gold3 = theme.palette.gold3.main;
  const gold1 = theme.palette.gold1.main;
  const prevBtnDisabled = ordinalNumber === 1;
  const prevBtnTextColor = prevBtnDisabled ? 'unset' : GOLD_1;
  const btnWidth = '164px';

  const isLastQuestion = ordinalNumber === totalQuestionCount;

  return (
    <Styled.Root data-testid="quiz-btnlayout-root">
      {!prevBtnDisabled ? (
        <QuizButton
          dataTestId="quiz-prev-btn"
          label="Previous"
          onClick={prevQuestion}
          textColor={prevBtnTextColor}
          disabled={prevBtnDisabled}
          className="prev-btn"
          sx={{
            minWidth: btnWidth,
            background: gold1,
            ':hover': { backgroundColor: gold3 }
          }}
        />
      ) : (
        <Box data-testid="hidden-prev-btn" sx={{ minWidth: btnWidth }} />
      )}
      <Styled.Info data-testid="question-count" className="count-div">
        <Typography variant="EC_TYPE_SM" color={GOLD_1} className="count-text">
          {ordinalNumber} of {totalQuestionCount}
        </Typography>
      </Styled.Info>
      <QuizButton
        dataTestId="quiz-next-btn"
        disabled={!isSelected}
        label={!isLastQuestion ? 'Continue' : 'Submit'}
        onClick={nextQuestion}
        className="next-btn"
        sx={{ minWidth: btnWidth }}
      />
    </Styled.Root>
  );
};

export default QuizButtonLayout;
