import {
  CandidateCsv,
  CandidatesCsvReq
} from '@api/models/adminDashboardApi.models';
import { AdminDashboardApi } from '@api/AdminDashboard.api';

export type FetchCandidateAccountsCsv = {
  fetchCandidateAccounts: () => Promise<CandidateCsv[]>;
};

export const useFetchCandidateAccountsCsv = (
  req: CandidatesCsvReq,
  completedOnly: boolean
): FetchCandidateAccountsCsv => {
  const fetchCandidateAccounts = async (): Promise<CandidateCsv[]> => {
    let data: CandidateCsv[] = [];

    try {
      if (completedOnly) {
        data = await AdminDashboardApi.getCompletedCandidatesDetailsCsv(req);
      } else {
        data = await AdminDashboardApi.getCandidatesDetailsCsv(req);
      }
    } catch (error: any) {
      console.error(
        'Error for useFetchCandidateAccountsCsv -> AdminDashboardApi.getCandidatesDetailsCsv()',
        error
      );
    }

    return data;
  };

  return { fetchCandidateAccounts };
};
