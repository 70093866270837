import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import mailSentImg from '@assets/contact_us.mail_sent.png';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useHistory } from 'react-router-dom';

const TEXT_BREAKPOINT = '@media (min-width:500px)';

const Styled = {
  Root: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 0 32px',
    maxWidth: '1100px',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      padding: '56px 0'
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '72px'
    }
  }),
  TextBox: styled(Box)({
    width: '100%',
    maxWidth: 'max-content',
    '& .msg-sent-subtext': {
      margin: '16px 0 32px',
      [theme.breakpoints.up('sm')]: {
        marginTop: 0
      }
    }
  }),
  Text: styled(Typography)({
    textTransform: 'uppercase',
    color: theme.palette.GRAY_3.main,
    fontSize: '25px',
    lineHeight: '31px',
    [TEXT_BREAKPOINT]: {
      fontSize: '32px',
      lineHeight: '40px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '45px',
      lineHeight: '56px'
    }
  }),
  BackBtn: styled(Button)({
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    padding: 0,
    '& .back-btn-text': {
      color: 'inherit'
    }
  }),
  ImgBox: styled(Box)({
    maxWidth: '820px',
    marginTop: '46px',
    '& .msg-sent-img': {
      float: 'right',
      width: '100%',
      maxWidth: '507px',
      objectFit: 'cover'
    }
  })
};

const ContactUsMessageSent = (): React.ReactElement => {
  const history = useHistory();
  const handleBackBtnClick = React.useCallback((): void => {
    history.goBack();
  }, [history]);

  return (
    <Styled.Root>
      <Styled.TextBox>
        <Styled.Text variant="EC_TYPE_5XL">
          thanks for reaching out.
        </Styled.Text>
        <Styled.Text variant="EC_TYPE_5XL" className="msg-sent-subtext">
          we will get back to you shortly.
        </Styled.Text>
        <Styled.BackBtn onClick={handleBackBtnClick} variant="text">
          <Typography variant="EC_TYPE_2XL" className="back-btn-text">
            go back.
          </Typography>
        </Styled.BackBtn>
      </Styled.TextBox>
      <Styled.ImgBox>
        <img
          src={mailSentImg}
          alt="message sent envelope"
          className="msg-sent-img"
        />
      </Styled.ImgBox>
    </Styled.Root>
  );
};

export default ContactUsMessageSent;
