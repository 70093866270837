import * as React from 'react';
import {
  ExternshipStatus,
  StudentViewExternship
} from '@api/models/externshipApi.models';
import {
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material';
import ConfirmExternshipStatusChangeModal from './StudentSiteInfo/ConfirmExternshipStatusChangeModal';
import { ExternshipApi } from '@api/Externships.api';
import { getExternshipStatusLabel } from '@common/helpers/externshipHelpers/getExternshipStatusLabel';
import { studentViewFilterMap } from '@pages/CareerServices/StudentPage/studentViewFilters.constants';
import { styled } from '@mui/system';
import { useStudentViewContext } from '@common/context/studentViewContext';
import { useToast } from '@components/Toast';

const Styled = {
  MoreIcon: styled(IconButton)({
    position: 'absolute',
    right: '8px',
    top: '8px'
  })
};

interface Props {
  externship: StudentViewExternship;
}

const StatusUpdateMenu: React.FC<Props> = ({ externship }) => {
  const { openToast } = useToast();
  const {
    externships,
    setExternships,
    currentFilter,
    allExternships,
    setAllExternships
  } = useStudentViewContext();

  // This is the status we are updating to
  // Should be set when we click on "archive" or "complete"
  const [statusUpdate, setStatusUpdate] =
    React.useState<ExternshipStatus | null>(null);
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const openMoreMenu = Boolean(moreMenuAnchorEl);

  const ARCHIVABLE_STATUSES = [
    ExternshipStatus.DRAFT,
    ExternshipStatus.PENDING,
    ExternshipStatus.PENDING_SOFT_VERIFICATION,
    ExternshipStatus.CS_REJECTED,
    ExternshipStatus.PENDING_EXT_MNG_AGREEMENT,
    ExternshipStatus.PENDING_EXT_STD_AGREEMENT,
    ExternshipStatus.PENDING_EXT_CS_AGREEMENT,
    ExternshipStatus.PENDING_PROXY_AGREEMENT,
    ExternshipStatus.ACTIVE,
    ExternshipStatus.DECLINED,
    ExternshipStatus.CANCELED
  ];

  // Should not have any timesheets approved by CS in order to archive
  const isArchivable =
    ARCHIVABLE_STATUSES.includes(externship.status) &&
    externship.weeklyTimeSheets.every((t) => !t.instructorAcceptedBy);

  const isCompletable =
    externship.status === ExternshipStatus.ACTIVE &&
    externship.weeklyTimeSheets.some((t) => t.instructorAcceptedBy);

  const handleMoreMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    setMoreMenuAnchorEl(event.currentTarget);
  };

  const handleStatusChange = async (
    status: ExternshipStatus
  ): Promise<void> => {
    setMoreMenuAnchorEl(null);

    try {
      await ExternshipApi.updateExternshipStatus(externship.id, status);

      const externshipMapperForStatusUpdate = (
        e: StudentViewExternship
      ): StudentViewExternship =>
        e.id === externship.id ? { ...e, status } : e;

      const updatedExternships = externships
        .map(externshipMapperForStatusUpdate)
        .filter((e) => studentViewFilterMap[currentFilter].includes(e.status));

      const updatedAllExternships = allExternships.map(
        externshipMapperForStatusUpdate
      );

      setExternships(updatedExternships);
      setAllExternships(updatedAllExternships);
      // no need to setStatusUpdate(null) since setExternships will reset component
      openToast(
        `Externship status updated: ${getExternshipStatusLabel(status)}`
      );
    } catch (error: any) {
      console.error(
        `Error for SiteInfoCard -> ExternshipApi.updateExternshipStatus: ${error}`
      );
    }
  };

  return (
    <>
      <Styled.MoreIcon size="small" onClick={handleMoreMenuClick}>
        <Icon fontSize="small" color="primary" className="ri-more-2-fill" />
      </Styled.MoreIcon>
      <Menu
        open={openMoreMenu}
        anchorEl={moreMenuAnchorEl}
        onClick={(): void => setMoreMenuAnchorEl(null)}
      >
        <MenuItem
          disabled={!isCompletable}
          onClick={(): void => setStatusUpdate(ExternshipStatus.COMPLETED)}
        >
          <ListItemIcon>
            <Icon color="primary" className="ri-checkbox-circle-fill" />
          </ListItemIcon>
          <ListItemText>Complete</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={!isArchivable}
          onClick={(): void => setStatusUpdate(ExternshipStatus.ARCHIVED)}
        >
          <ListItemIcon>
            <Icon color="primary" className="ri-archive-2-fill" />
          </ListItemIcon>
          <ListItemText>Archive</ListItemText>
        </MenuItem>
      </Menu>
      <ConfirmExternshipStatusChangeModal
        status={statusUpdate}
        handleStatusChange={handleStatusChange}
        closeModal={(): void => setStatusUpdate(null)}
      />
    </>
  );
};

export default StatusUpdateMenu;
