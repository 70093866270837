import * as React from 'react';
import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import { Application } from '@api/models/adminDashboardApi.models';
import { Styled } from './CandidateAccountsSection/StyledTabledComponents';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';

interface Props {
  applications: Application[];
}

const CandidateApplicationsTable: React.FC<Props> = ({ applications }) => {
  const featureFlags = useFeatureFlags();

  const handleJobClick = (jobId): void => {
    window.open(`/job/${jobId}`, '_blank');
  };

  const headerCells = [
    { label: 'Job' },
    { label: 'Company' },
    { label: 'Apply Date' },
    { label: 'Status' },
    ...(featureFlags.ATS_WORKAROUND ? [{ label: 'External Link' }] : [])
  ];

  return (
    <Table size="small" aria-label="purchases">
      <TableHead>
        <TableRow>
          {headerCells.map((cell) => (
            <Styled.HeaderTableCell key={cell.label} withinSubTable>
              {cell.label}
            </Styled.HeaderTableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {applications.map((application) => (
          <TableRow key={application.id}>
            <Styled.TableCell
              clickable
              onClick={(): void => handleJobClick(application.jobId)}
              component="th"
              scope="row"
            >
              {application.jobTitle}
            </Styled.TableCell>
            <Styled.TableCell>{application.company}</Styled.TableCell>
            <Styled.TableCell>{application.applyDate}</Styled.TableCell>
            <Styled.TableCell>{application.status}</Styled.TableCell>
            {featureFlags.ATS_WORKAROUND && (
              <Styled.TableCell>
                {application.externalApplicationStatus}
              </Styled.TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CandidateApplicationsTable;
