import { useEffect, useState } from 'react';
// TODO educationType Codebook: uncomment once API is set up
// import { CandidateApi } from '@api/Candidate.api';
// import { Codebook } from '@api/models/codebook.models';

export type FetchEducationOptions = {
  // TODO educationType Codebook: educationTypes will be a Codebook[] once the database is updated
  // educationTypes: Codebook[];
  educationTypes: TempEduCodebook[];
  loading: boolean;
};

// TODO educationType Codebook: temporary type until educationType is set up as a Codebook
type TempEduCodebook = {
  value: string;
  label: string;
};

// const eduTypeArray = [
//   'High School Diploma or GED',
//   'Associate Degree',
//   'Diploma',
//   "Bachelor's Degree",
//   "Master's Degree",
//   'Doctorate Degree',
//   'Escoffier Student',
//   'Escoffier Alumni'
// ];

// TODO educationType Codebook: The database still expects a string for educationType,
// but ReactHookFormsFieldArray.tsx expects Codebook-like data.
// Using this until the database is set up for educationType to be a Codebook
const eduTypesCodebook: TempEduCodebook[] = [
  { value: 'High School Diploma or GED', label: 'High School Diploma or GED' },
  { value: 'Associate Degree', label: 'Associate Degree' },
  { value: 'Diploma', label: 'Diploma' },
  { value: "Bachelor's Degree", label: "Bachelor's Degree" }, // eslint-disable-line quotes
  { value: "Master's Degree", label: "Master's Degree" }, // eslint-disable-line quotes
  { value: 'Doctorate Degree', label: 'Doctorate Degree' },
  { value: 'Escoffier Student', label: 'Escoffier Student' },
  { value: 'Escoffier Alumni', label: 'Escoffier Alumni' }
];

export const useFetchEducationTypes = (): FetchEducationOptions => {
  const [educationTypes, setEducationTypes] = useState<TempEduCodebook[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        setLoading(true);

        // TODO educationType Codebook: uncomment and change setEducationType() value once API is set up
        // const res = await CandidateApi.getEducationTypes();
        setEducationTypes(eduTypesCodebook);
      } catch (error: any) {
        console.error(
          'Error for useFetchEducationOptions -> CandidateApi.getEducationTypes()',
          error
        );
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return { educationTypes, loading };
};
