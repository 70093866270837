import * as React from 'react';
import {
  redirectStorageKeys,
  useStorageRedirectWithExpiration
} from '@common/helpers/storage';
import ErrorPageLayout from '@pages/Errors/ErrorPageLayout';
import { Link } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';

const TimedOutPage: React.FC = () => {
  const { keycloak } = useKeycloak();
  const storage = useStorageRedirectWithExpiration(
    redirectStorageKeys.USER_TIMEOUT
  );

  const login = (): void => {
    if (storage) {
      keycloak.login({ redirectUri: storage.redirect });
    }
  };

  return (
    <ErrorPageLayout
      header="TIMED OUT"
      subtext={
        <>
          You have been signed out because of inactivity. You&apos;ll need to{' '}
          <Link onClick={login} underline="none" sx={{ cursor: 'pointer' }}>
            sign in
          </Link>{' '}
          again.
        </>
      }
    />
  );
};

export default TimedOutPage;
