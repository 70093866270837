import { useEffect, useState } from 'react';
import { JobApplicationApi } from '@api/JobApplication.api';
import { JobApplied } from '@api/models/jobApplicationApi.models';

interface JobApplicationsByCandidateId {
  jobApplications: JobApplied[];
  loading: boolean;
  loaded: true | null;
}

export const useFetchJobApplicationsByCandidateId = (
  candidateId: number
): JobApplicationsByCandidateId => {
  const [jobApplications, setJobApplications] = useState<JobApplied[]>([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        setLoading(true);
        const res = await JobApplicationApi.getJobApplicationsByCandidateId(
          candidateId
        );

        const jobData: JobApplied[] = res.data.map((job) => ({
          id: job.id,
          jobId: job.jobPost.id,
          companyName: job.jobPost.company.name,
          jobTitle: job.jobPost.jobTitle,
          dateApplied: job.submittedDate,
          dateAppliedString: new Date(job.submittedDate).toLocaleDateString(
            'en-US'
          ),
          status: job.status
        }));

        setJobApplications(jobData);
      } catch (error: any) {
        console.error(
          'Error for useFetchJobApplicationsByCandidateId -> JobApplicationApi.getJobApplicationsByCandidateId()',
          error
        );
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    };

    fetchData();
  }, []);

  return { jobApplications, loading, loaded };
};
