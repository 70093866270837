import * as React from 'react';
import { Box, Button, Stack, Theme, Typography } from '@mui/material';
import {
  SiteInfoEditableSections,
  useStudentViewSiteInfoContext
} from '@common/context/studentViewSiteInfoContext';
import FormFieldErrorText from '@components/Forms/FormFieldErrorText';
import { styled } from '@mui/system';
import { useFormContext } from 'react-hook-form';

const Styled = {
  DetailsCard: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'disableBorder'
  })<{ disableBorder: boolean }>(({ disableBorder, theme }) => ({
    height: '100%',
    ...(!disableBorder && {
      padding: '0px 8px 16px',
      border: `1px solid ${theme.palette.GRAY_5.main}`,
      borderRadius: '4px'
    }),
    '& .details-card-title-stack': {
      justifyContent: 'space-between',
      borderBottom: `1px solid ${theme.palette.GRAY_5.main}`,
      padding: !disableBorder ? '16px 16px 8px 16px' : '16px 0'
    },
    '& .details-card-title': {
      marginBottom: '8px'
    },
    '& .details-card-edit-toggle': {
      ...(theme as Theme).typography.EC_TYPE_XS,
      color: theme.palette.primary.main,
      minWidth: 0,
      padding: '0 4px'
    },
    '& .details-card-details': {
      padding: !disableBorder ? '8px 16px' : '8px 0'
    }
  }))
};

interface Props {
  children: React.ReactNode;
  title: SiteInfoEditableSections;
  allowEditing?: boolean;
  disableBorder?: boolean;
  errorMessage?: string;
  handleValidForm?: (data: any) => void;
}

const DetailsCard: React.FC<Props> = ({
  children,
  title,
  allowEditing = false,
  disableBorder = false,
  errorMessage,
  handleValidForm
}) => {
  const { handleSubmit } = useFormContext();
  const { editingSections, setEditingSections } =
    useStudentViewSiteInfoContext();
  const showEditButton = Boolean(allowEditing && handleValidForm);
  const isInEditMode = showEditButton && editingSections.includes(title);

  const handleToggleEditMode = React.useCallback(() => {
    if (allowEditing && !editingSections.includes(title)) {
      setEditingSections((prev) => [...prev, title]);
    }
  }, [editingSections, setEditingSections]);

  const handleFormSubmit = (): void => {
    handleValidForm && handleSubmit(handleValidForm)();
  };

  const handleClick = (): void => {
    !isInEditMode ? handleToggleEditMode() : handleFormSubmit();
  };

  const titleSlug = title.toLocaleLowerCase().replace(/[^a-zA-Z0-9\s-]+/, '-');

  return (
    <Styled.DetailsCard disableBorder={disableBorder}>
      <Stack direction="row" className="details-card-title-stack">
        <Typography variant="EC_TYPE_LG" className="details-card-title">
          {title}
        </Typography>
        {showEditButton && (
          <Button
            variant="text"
            className="details-card-edit-toggle"
            onClick={handleClick}
            data-testid={`site-info-details-card-${title}-edit-btn`}
          >
            {isInEditMode ? 'SAVE' : 'EDIT'}
          </Button>
        )}
      </Stack>
      {errorMessage && (
        <Box className="details-card-details">
          <FormFieldErrorText
            name={titleSlug}
            message={errorMessage}
            disableAbsolutePosition={true}
          />
        </Box>
      )}
      <Box className="details-card-details">{children}</Box>
    </Styled.DetailsCard>
  );
};

export default React.memo(DetailsCard);
