import * as React from 'react';
import { Box } from '@mui/material';
import CandidateProfileContentSection from '@pages/Candidate/CandidateProfileComponents/CandidateProfileContentSection';
import CareerGoalCard from '@pages/Candidate/CareerPathPage/MyCareerGoal/CareerGoalCard';
import CareerPathExploreMoreCard from '@pages/Candidate/CareerPathPage/ExploreMore/CareerPathExploreMoreCard';
import CareerPathLayout from '@pages/Candidate/CareerPathPage/CareerPathLayout';
import { mediaQueries } from '@themes/ui/variables';
import MySkillsCard from '@pages/Candidate/CareerPathPage/MySkills/MySkillsCard';
import { styled } from '@mui/system';
import { useFetchCareerJourney } from '@common/fetches/useFetchCareerJourney';
import { useFetchCareerRoles } from '@common/fetches/useFetchCareerRoles';
import { useFetchPartnerEmployeeCareerInfo } from '@common/fetches/useFetchPartnerEmployeeCareerInfo';
import { useFetchPartnerEmployeeProfile } from '@common/fetches/useFetchPartnerEmployeeProfile';
import { usePutPartnerEmployeeCareerGoal } from '@common/putRequests/usePutPartnerEmployeeCareerGoal';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';

const Styled = {
  Root: styled(Box)({
    marginTop: '24px'
  }),
  PaddingBox: styled(Box)({
    padding: '0 10px',
    [mediaQueries.MIN_WIDTH_361]: {
      padding: '0 16px'
    },
    [mediaQueries.MIN_WIDTH_481]: {
      padding: '0 32px'
    },
    [mediaQueries.MIN_WIDTH_601]: {
      padding: '0 65px'
    }
  }),
  SectionBox: styled(Box)({
    padding: 0,
    marginTop: '36px',
    [mediaQueries.MIN_WIDTH_601]: {
      padding: '0 65px'
    }
  })
};

const PartnerEmployeeCareerJourneyPage: React.FC = () => {
  const [partnerEmployeeId] = useStore<number>(UseStoreKeys.PARTNER_EMPLOYEE_ID);

  const { partnerEmployeeProfile: profile } =
    useFetchPartnerEmployeeProfile(partnerEmployeeId);
  const { partnerEmployeeCareerInfo: careerInfo, fetchCareerInfo } =
    useFetchPartnerEmployeeCareerInfo(partnerEmployeeId);
  const { careerTrack, careerPathRole } = useFetchCareerJourney(careerInfo);
  const { careerRoles } = useFetchCareerRoles('careerRoles');
  const { setCareerGoal, removeCareerGoal } = usePutPartnerEmployeeCareerGoal();

  const exploreMoreRef = React.useRef<HTMLDivElement | null>(null);

  const [goalSelected, setGoalSelected] = React.useState(false);
  const [expandExploreMoreOnScroll, setExpandExploreMoreOnScroll] =
    React.useState(false);
  const [exploreMoreTabValue, setExploreMoreTabValue] =
    React.useState<string>('1');

  const handleRemoveGoal = async (): Promise<void> => {
    await removeCareerGoal(partnerEmployeeId).then(() => {
      fetchCareerInfo();
    });
  };

  const handleSetGoal = async (careerRoleId: number): Promise<void> => {
    if (careerInfo?.savedGoal?.id === careerRoleId) {
      handleRemoveGoal();
    } else {
      setGoalSelected(true);
      await setCareerGoal(partnerEmployeeId, careerRoleId).then(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        fetchCareerInfo();
      });
    }
  };

  const handleScrollToExploreMore = React.useCallback(() => {
    const { current } = exploreMoreRef;
    if (current) {
      setExpandExploreMoreOnScroll(true);
      setTimeout(() => {
        current.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }
  }, [exploreMoreRef]);

  const handleExploreMoreTabChange = (
    event: React.SyntheticEvent,
    newVal: string
  ): void => {
    setExploreMoreTabValue(newVal);
  };

  const currentJobIds =
    careerInfo?.workExperience
      .filter((exp) => exp.currentPosition)
      .map((exp) => exp.position.value) || [];

  return (
    <CareerPathLayout disableQuizBtn>
      <Styled.Root>
        <Styled.PaddingBox>
          <CareerGoalCard
            careerTrack={careerTrack}
            currentJobIds={currentJobIds}
            careerInfo={careerInfo}
            careerPathRole={careerPathRole}
            goalSelected={goalSelected}
            setCareerGoal={handleSetGoal}
            removeCareerGoal={handleRemoveGoal}
            handleScrollToExploreMore={handleScrollToExploreMore}
          />
        </Styled.PaddingBox>
        {careerRoles && (
          <Styled.SectionBox sx={{ marginTop: '36px' }} ref={exploreMoreRef}>
            <CareerPathExploreMoreCard
              careerRoles={careerRoles}
              setCareerGoal={handleSetGoal}
              currentJobIds={currentJobIds}
              workExperience={careerInfo?.workExperience}
              savedGoal={careerInfo?.savedGoal}
              expandOnScroll={expandExploreMoreOnScroll}
              setExpandOnScroll={setExpandExploreMoreOnScroll}
              tabValue={exploreMoreTabValue}
              setTabValue={handleExploreMoreTabChange}
            />
          </Styled.SectionBox>
        )}
        <Styled.SectionBox>
          <MySkillsCard
            profileComplete={true} // TODO: Update API to get this information
            careerInfo={careerInfo}
            requiredSkills={careerPathRole?.requiredSkills ?? []}
            prerequisites={careerPathRole?.prerequisites ?? []}
            certifications={careerPathRole?.certifications ?? []}
            isPartnerEmployee
          />
        </Styled.SectionBox>
        {profile?.workExperience && (
          <Styled.SectionBox>
            <CandidateProfileContentSection
              sectionTitle="my experience"
              content={profile.workExperience}
              contentType="workExperience"
              wideBorder
            />
          </Styled.SectionBox>
        )}
        {profile?.education && (
          <Styled.SectionBox>
            <CandidateProfileContentSection
              sectionTitle="my education"
              content={profile.education}
              contentType="education"
              wideBorder
            />
          </Styled.SectionBox>
        )}
      </Styled.Root>
    </CareerPathLayout>
  );
};

export default PartnerEmployeeCareerJourneyPage;
