export enum roles {
  ADMIN = 'admin',
  CANDIDATE = 'candidate',
  EMPLOYER = 'employer',
  RECRUITER = 'recruiter',
  ESCOFFIER_ADMIN = 'escoffier-admin',
  EC_CAREER_SERVICE = 'escoffier-career-service',
  ESCOFFIER_APPROVER = 'escoffier-approver',
  CHEF_INSTRUCTOR = 'chef-instructor',
  PARTNER = 'partner',
  GLRC_USER = 'glrc-user',
  // TODO: esource-user is currently front end only due to GLRC name change.
  ESOURCE_USER = 'esource-user',
  PENDING_USER = 'pending-user',
  // Only used in CTA from email sent to proposed manager to create account
  PROPOSED_EXTERNSHIP_MANAGER = 'proposed-externship-manager'
}

export enum AppRoles {
  EMPLOYER = 'app-employer',
  RECRUITER = 'app-recruiter',
  ESOURCE_USER = 'app-glrc-user',
  PENDING_USER = 'app-pending-user',
  APPROVER = 'app-approver'
}

export const IdentityProviders = {
  STUDENT_PORTAL:
    process.env.REACT_APP_STUDENT_PORTAL_IDP_ALIAS || 'StudentPortalSSO'
};

export interface SeatRoleSelectOption<T extends roles | AppRoles> {
  label: roles;
  value: T;
}

export interface UserRole {
  clientRole: boolean;
  composite: boolean;
  containerId: string;
  id: string;
  name: string;
}

export interface IKeycloakUser {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  preferred_username: string;
  roles: string[];
  sub: string;
}

// Matches EC.API/src/interfaces/users.interface.ts
export interface User {
  id: number;
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface UserIdp {
  identityProvider: string;
  userId: string;
  userName: string;
}
