import * as React from 'react';
import {
  ExternshipWithoutTimesheets,
  Timesheet
} from '@api/models/externshipApi.models';
import { ExternshipApi } from '@api/Externships.api';

export type FetchExternshipById = {
  externship?: ExternshipWithoutTimesheets;
  timesheets: Timesheet[];
  setTimesheets: React.Dispatch<React.SetStateAction<Timesheet[]>>;
  loaded: boolean;
  loading: boolean;
};

export const useFetchTimesheetsByExternship = (
  id: number
): FetchExternshipById => {
  const [externship, setExternship] =
    React.useState<ExternshipWithoutTimesheets>();
  const [timesheets, setTimesheets] = React.useState<Timesheet[]>([]);

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchExternships = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await ExternshipApi.getTimesheetsByExternshipId(id);

      setExternship(res.externship);
      setTimesheets(res.timesheets);
      setLoaded(true);
    } catch (error: any) {
      console.error(
        'Error for useFetchExternships -> ExternshipApi.getTimesheetsByExternshipId()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    fetchExternships();
  }, []);

  return {
    externship,
    timesheets,
    setTimesheets,
    loaded,
    loading
  };
};
