import * as React from 'react';
import {
  Box,
  Button,
  Icon,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import EcDialog from '@components/EcDialog';
import { ExternshipApi } from '@api/Externships.api';
import { ExternshipStatus } from '@api/models/externshipApi.models';
import { styled } from '@mui/system';
import { useCandidateDashboardContext } from '@common/context/candidateDashboardContext';
import { useHistory } from 'react-router-dom';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
    columnGap: '16px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '20px auto 0',
      flexDirection: 'column',
      rowGap: '8px'
    }
  })),
  Button: styled(Button)(({ theme }) => ({
    marginLeft: 'auto',
    padding: '8px',
    whiteSpace: 'nowrap',
    minWidth: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  })),
  Icon: styled(Icon)({
    fontSize: 'inherit',
    fontWeight: 100
  }),
  SecondaryButton: styled(Button)(({ theme }) => ({
    padding: '0 8px 0 4px',
    marginLeft: 'auto',
    whiteSpace: 'nowrap',
    minWidth: 'auto',
    fontSize: '12px',
    lineHeight: '12px',
    '& .secondary-icon': {
      fontSize: '12px',
      lineHeight: '12px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '8px'
    }
  }))
};

type ActionType = {
  label: string;
  icon: string | null;
  variant?: string;
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

type SecondaryAction = Omit<ActionType, 'icon'> & { icon: string };

interface Props {
  status: ExternshipStatus;
  externshipId: number;
  companyName?: string;
  handleOpenAgreementModal: () => void;
  revisedEmploymentStartDate?: string | null;
}

// TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - delete this file when flag is updated
const ExternshipAction: React.FC<Props> = ({
  status,
  externshipId,
  companyName,
  handleOpenAgreementModal,
  revisedEmploymentStartDate
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const confirmText = React.useRef<string | undefined>();

  const [candidateId] = useStore<number>(UseStoreKeys.CANDIDATE_ID);

  const { fetchByCandidateId, setExternshipsByCandidate } =
    useCandidateDashboardContext();

  let action: ActionType;
  let secondaryAction: SecondaryAction | undefined = undefined;

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();

  const getConfirmModalActionText = (actionString: string): string => {
    if (actionString.includes('cancel')) {
      return 'YES';
    }

    return actionString.split(' ')[0].toUpperCase();
  };

  const handleRemoveExternshipFromState = React.useCallback(() => {
    setExternshipsByCandidate(
      (prev) => prev?.filter((e) => e.id !== externshipId) ?? prev
    );
  }, [externshipId, setExternshipsByCandidate]);

  const handleOpenConfirmModal = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    e.stopPropagation();

    if (e.currentTarget.ariaLabel?.includes('Delete')) {
      confirmText.current = `delete your draft for ${companyName}?`;
    }

    if (e.currentTarget.ariaLabel?.includes('Cancel')) {
      confirmText.current = `cancel your proposal for ${companyName}?`;
    }

    if (e.currentTarget.ariaLabel?.includes('Remove')) {
      confirmText.current = 'remove this externship?';
    }

    setConfirmModalOpen(true);
  };

  const handleCloseConfirmModal = (): void => {
    confirmText.current = undefined;
    setConfirmModalOpen(false);
  };

  const handleDraftClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    history.push(`/externship/${externshipId}/proposal`);
  };

  const handleDelete = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    e.stopPropagation();
    await ExternshipApi.delete(externshipId).then(() => {
      handleRemoveExternshipFromState();
    });
  };

  const handleCancelProposal = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    e.stopPropagation();
    await ExternshipApi.cancel(externshipId).then(() => {
      fetchByCandidateId(candidateId);
    });
  };

  const handleRemoveProposal = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    e.stopPropagation();
    await ExternshipApi.archive(externshipId).then(() => {
      handleRemoveExternshipFromState();
    });
  };

  const handleTimesheetNav = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    history.push(`/externship/${externshipId}/timesheets`);
  };

  const handleConfirmModalClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (confirmText.current?.includes('delete')) {
        return handleDelete(e);
      } else if (confirmText.current?.includes('cancel')) {
        return handleCancelProposal(e);
      } else if (confirmText.current?.includes('remove')) {
        return handleRemoveProposal(e);
      } else {
        return handleCloseConfirmModal();
      }
    },
    [
      confirmText,
      handleDelete,
      handleCancelProposal,
      handleRemoveProposal,
      handleCloseConfirmModal
    ]
  );

  switch (status) {
    case ExternshipStatus.DRAFT:
      action = {
        label: 'Continue',
        icon: null,
        variant: 'outlined',
        handleClick: handleDraftClick
      };
      secondaryAction = {
        label: 'Delete Draft',
        icon: 'ri-delete-bin-2-line',
        handleClick: handleOpenConfirmModal
      };
      break;
    case ExternshipStatus.ACTIVE:
      action = {
        label: 'Submit Hours',
        icon: 'ri-upload-2-line',
        handleClick: handleTimesheetNav
      };
      break;
    case ExternshipStatus.PENDING_SOFT_VERIFICATION:
    case ExternshipStatus.PENDING:
    case ExternshipStatus.PENDING_SITE_AGREEMENT:
    case ExternshipStatus.PENDING_EXT_MNG_AGREEMENT:
      action = {
        label: 'Cancel Proposal',
        icon: 'ri-close-circle-line',
        handleClick: handleOpenConfirmModal
      };
      break;
    case ExternshipStatus.PENDING_EXT_STD_AGREEMENT:
      action = {
        label: revisedEmploymentStartDate
          ? 'Review Changes'
          : 'Sign Externship Agreement',
        icon: null,
        variant: 'contained',
        handleClick: handleOpenAgreementModal
      };
      break;
    case ExternshipStatus.CANCELED:
    case ExternshipStatus.DECLINED:
    case ExternshipStatus.CS_REJECTED:
      action = {
        label: 'Remove',
        icon: 'ri-delete-bin-2-line',
        handleClick: handleOpenConfirmModal
      };
      break;
    case ExternshipStatus.COMPLETED:
      action = {
        label: 'View Timesheets',
        icon: '',
        handleClick: handleTimesheetNav
      };
      break;
    default:
      action = {
        label: '',
        icon: '',
        handleClick: (e): void => {
          e.stopPropagation();
        }
      };
  }

  return (
    <Styled.Root>
      {secondaryAction && (
        <Styled.SecondaryButton
          variant={secondaryAction.variant ?? isSmall ? 'outlined' : 'text'}
          endIcon={
            <Styled.Icon className={`${secondaryAction.icon} secondary-icon`} />
          }
          onClick={secondaryAction.handleClick}
          aria-label={secondaryAction.label}
        >
          <Typography variant="EC_TYPE_2XS" color="inherit">
            {secondaryAction.label}
          </Typography>
        </Styled.SecondaryButton>
      )}
      <Styled.Button
        variant={action.variant ?? isSmall ? 'outlined' : 'text'}
        endIcon={action.icon ? <Styled.Icon className={action.icon} /> : null}
        onClick={action.handleClick}
        aria-label={action.label}
      >
        <Typography variant="EC_TYPE_2XS" color="inherit">
          {action.label}
        </Typography>
      </Styled.Button>
      {confirmModalOpen && !!confirmText.current && (
        <EcDialog
          title={`Are you sure you want to ${confirmText.current}`}
          content="If you do this the externship will be permanently removed."
          open={confirmModalOpen}
          handleClose={handleCloseConfirmModal}
          handleConfirm={handleConfirmModalClick}
          confirmActionText={getConfirmModalActionText(confirmText.current)}
          denyActionText="Cancel"
        />
      )}
    </Styled.Root>
  );
};

export default ExternshipAction;
