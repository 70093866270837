import * as React from 'react';
import {
  includesLetter,
  includesNumber,
  minLength,
  notMatchEmail
} from '@common/rhf-validators/passwordValidators';
import { emailPattern } from '@common/regex/email';
import { Grid } from '@mui/material';
import { notBeEmptyString } from '@common/rhf-validators/emptyString.validator';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import ReactHookFormsPassword from '@components/Forms/ReactHookFormsPassword';
import { styled } from '@mui/system';

const Styled = {
  GridItem: styled(Grid)({
    marginBottom: '34px'
  })
};

interface CandidateSignUpProps {
  email: string;
  currPassword: string;
  user: any;
}

const CandidateSignUpSection = (
  props: CandidateSignUpProps
): React.ReactElement => {
  const { email, currPassword, user } = props;
  return (
    <>
      <Grid container columnSpacing={3}>
        <Styled.GridItem item xs={12} sm={6}>
          <ReactHookFormsInput
            name="firstName"
            label="First Name"
            autocomplete="given-name"
            disabled={!!user}
            rules={{
              required: 'First name is required',
              validate: {
                notBeEmptyString: notBeEmptyString('First name')
              }
            }}
          />
        </Styled.GridItem>

        <Styled.GridItem item xs={12} sm={6}>
          <ReactHookFormsInput
            name="lastName"
            label="Last Name"
            autocomplete="family-name"
            disabled={!!user}
            rules={{
              required: 'Last name is required',
              validate: {
                notBeEmptyString: notBeEmptyString('Last name')
              }
            }}
          />
        </Styled.GridItem>

        <Styled.GridItem item xs={12} sm={12}>
          <ReactHookFormsInput
            name="email"
            label="Email"
            autocomplete="email"
            disabled={!!user}
            rules={{
              required: 'Email is required',
              pattern: {
                value: emailPattern,
                message: 'Please enter a valid email'
              }
            }}
          />
        </Styled.GridItem>

        {!user && (
          <>
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsPassword
                name="password"
                label="Create Password"
                autocomplete="new-password"
                displayTooltip
                rules={{
                  required: 'Password is required',
                  minLength: {
                    value: 6,
                    message: 'Password must be at least 6 characters'
                  },
                  validate: {
                    notMatchEmail: notMatchEmail(email),
                    minLength,
                    includesNumber,
                    includesLetter
                  }
                }}
              />
            </Styled.GridItem>

            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsPassword
                name="confirmPassword"
                label="Confirm Password"
                autocomplete="new-password"
                rules={{
                  required: 'Confirm password is required',
                  validate: (value): boolean | string =>
                    value === currPassword || 'Passwords do not match'
                }}
              />
            </Styled.GridItem>
          </>
        )}
      </Grid>
    </>
  );
};

export default CandidateSignUpSection;
