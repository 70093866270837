import * as React from 'react';
import { Box, Typography } from '@mui/material';
import QuizAnswer from '@components/Quiz/QuizAnswer';
import QuizButtonLayout from '@components/Quiz/QuizButtonLayout';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useQuizContext } from '@components/Quiz/EcQuiz';

const Styled = {
  Root: styled(Box)({
    flex: '1 1 550px',
    display: 'flex',
    maxWidth: '550px'
  }),
  Content: styled(Box)({
    '& .question-text': {
      color: theme.palette.GOLD_1.main,
      marginBottom: '28px'
    }
  }),
  Answers: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '18px'
  })
};

const QuizQuestions = (): React.ReactElement => {
  const { currAnswers, currQuestion, selectedAnswers } = useQuizContext();
  const { ordinalNumber } = currQuestion;

  const isSelected = (answerCode: string): boolean =>
    selectedAnswers.some(
      (item) => item.question === ordinalNumber && item.answer === answerCode
    );

  return (
    <Styled.Root data-testid="quiz-questions-root">
      <Styled.Content>
        <Typography
          data-testid="question-text"
          variant="EC_TYPE_LGG"
          className="question-text"
        >
          {currQuestion.question}
        </Typography>
        <Styled.Answers>
          {currAnswers.map((answer) => {
            const selected = isSelected(answer.answerCode);
            return (
              <QuizAnswer key={answer.id} answer={answer} selected={selected} />
            );
          })}
        </Styled.Answers>
        <QuizButtonLayout />
      </Styled.Content>
    </Styled.Root>
  );
};

export default QuizQuestions;
