import {
  AdminDashJobPostCsv,
  AdminDashJobPostsRes,
  AdminStatsResponse,
  ApplicantCsv,
  ApplicantsCsvReq,
  ApplicantsRes,
  CandidateAccountsReq,
  CandidateAccountsRes,
  CandidateCsv,
  CandidatesCsvReq,
  CompanyPossibleSub,
  CompanyStatus,
  CompanyUser,
  PageInfoSearchReq
} from '@api/models/adminDashboardApi.models';
import { api } from '@api/configs/base';
import { apiConfig } from '@api/configs/api.config';
import { ApiResponse } from '@api/models/base.models';
import { DateRange } from '@common/models/common.models';

export class AdminDashboardApi {
  static async getStats(
    req?: DateRange
  ): Promise<ApiResponse<AdminStatsResponse>> {
    try {
      const response = await api.post<ApiResponse<AdminStatsResponse>>(
        `${apiConfig.adminDashboardUrl}`,
        req
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for AdminDashboardApi.getStats(): ${error}`);
      throw error;
    }
  }

  static async getCompanies(): Promise<CompanyStatus[]> {
    try {
      const response = await api.get<ApiResponse<CompanyStatus[]>>(
        `${apiConfig.adminDashboardUrl}/companies`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for AdminDashboardApi.getCompanies(): ${error}`);
      throw error;
    }
  }

  static async getPossibleSubsidiaries(): Promise<CompanyPossibleSub[]> {
    try {
      const response = await api.get<ApiResponse<CompanyPossibleSub[]>>(
        `${apiConfig.adminDashboardUrl}/companies/possible-subsidiaries`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for AdminDashboardApi.getPossibleSubsidiaries(): ${error}`
      );
      throw error;
    }
  }

  static async getPossibleParents(): Promise<CompanyPossibleSub[]> {
    try {
      const response = await api.get<ApiResponse<CompanyPossibleSub[]>>(
        `${apiConfig.adminDashboardUrl}/companies/possible-parents`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for AdminDashboardApi.getPossibleParents(): ${error}`
      );
      throw error;
    }
  }

  static async getCompaniesCsv(): Promise<CompanyStatus[]> {
    try {
      const response = await api.get<ApiResponse<CompanyStatus[]>>(
        `${apiConfig.adminDashboardUrl}/companies/csv`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for AdminDashboardApi.getCompaniesCsv(): ${error}`);
      throw error;
    }
  }

  static async getCompaniesUsersCsv(): Promise<CompanyUser[]> {
    try {
      const response = await api.get<ApiResponse<CompanyUser[]>>(
        `${apiConfig.adminDashboardUrl}/companies/users/csv`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for AdminDashboardApi.getCompaniesUsersCsv(): ${error}`
      );
      throw error;
    }
  }

  static async updateCompanyEnabledStatus(
    id: number,
    enabled: boolean
  ): Promise<CompanyStatus> {
    try {
      const response = await api.put<ApiResponse<CompanyStatus>>(
        `${apiConfig.adminDashboardUrl}/companies/${id}`,
        { enabled: enabled }
      );
      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for AdminDashboardApi.updateEmployerStatus(): ${error}`
      );
      throw error;
    }
  }

  static async updateCompanyWorkLearn(
    companyId: number,
    workLearn: boolean
  ): Promise<CompanyStatus> {
    try {
      const response = await api.put<ApiResponse<CompanyStatus>>(
        `${apiConfig.adminDashboardUrl}/companies/${companyId}/work-learn-partner`,
        { workLearnPartner: workLearn }
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for AdminDashboardApi.updateCompanyWorkLearn: ${error}`
      );
      throw error;
    }
  }

  static async updateCompanyPartnershipLevel(
    id: number,
    partnershipLevel: number
  ): Promise<number> {
    try {
      const response = await api.put<ApiResponse<number>>(
        `${apiConfig.adminDashboardUrl}/companies/${id}/partnership-level`,
        { partnershipLevel }
      );
      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for AdminDashboardApi.updateCompanyPartnershipLevel(): ${error}`
      );
      throw error;
    }
  }

  static async updateCompanyGlrcSeats(
    id: number,
    glrcSeats: number
  ): Promise<number> {
    try {
      const response = await api.put<ApiResponse<number>>(
        `${apiConfig.adminDashboardUrl}/companies/${id}/glrc-seats`,
        { glrcSeats }
      );
      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for AdminDashboardApi.updateCompanyGlrcSeats(): ${error}`
      );
      throw error;
    }
  }

  static async markCompanyPossibleParent(
    companyId: number,
    mark: boolean
  ): Promise<void> {
    try {
      await api.put(
        `${apiConfig.adminDashboardUrl}/companies/${companyId}/mark-possible-parent`,
        { mark }
      );
    } catch (error: any) {
      console.error(
        `Error for AdminDashboardApi.markCompanyPossibleParent(): ${error}`
      );
      throw error;
    }
  }

  static async getCandidates(
    req: CandidateAccountsReq,
    getCompletedProfiles: boolean
  ): Promise<ApiResponse<CandidateAccountsRes>> {
    try {
      const response = await api.post<ApiResponse<CandidateAccountsRes>>(
        `${apiConfig.adminDashboardUrl}/candidates${
          getCompletedProfiles ? '/completed' : ''
        }`,
        req
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for AdminDashboardApi.getAccounts(): ${error}`);
      throw error;
    }
  }

  static async getApplicants(
    req: PageInfoSearchReq
  ): Promise<ApiResponse<ApplicantsRes>> {
    try {
      const response = await api.post<ApiResponse<ApplicantsRes>>(
        `${apiConfig.adminDashboardUrl}/applicants`,
        req
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for AdminDashboardApi.getAccounts(): ${error}`);
      throw error;
    }
  }

  static async getApplicantsByCompanyId(
    companyId: number,
    req: PageInfoSearchReq
  ): Promise<ApiResponse<ApplicantsRes>> {
    try {
      const response = await api.post<ApiResponse<ApplicantsRes>>(
        `${apiConfig.adminDashboardUrl}/companies/${companyId}/applicants`,
        req
      );

      return response.data;
    } catch (error: any) {
      console.error(
        `Error for AdminDashboardApi.getApplicantsByCompanyId(): ${error}`
      );
      throw error;
    }
  }

  static async getApplicantsDetailsCsv(
    req: ApplicantsCsvReq
  ): Promise<ApiResponse<ApplicantCsv[]>> {
    try {
      const res = await api.post<ApiResponse<ApplicantCsv[]>>(
        `${apiConfig.adminDashboardUrl}/applicants/csv`,
        req
      );
      return res.data;
    } catch (error: any) {
      console.error(
        `Error for AdminDashboardApi.getApplicantsDetailsCsv(): ${error}`
      );
      throw error;
    }
  }

  static async getApplicantsDetailsCsvByCompanyId(
    companyId: number,
    req: ApplicantsCsvReq
  ): Promise<ApiResponse<ApplicantCsv[]>> {
    try {
      const res = await api.post<ApiResponse<ApplicantCsv[]>>(
        `${apiConfig.adminDashboardUrl}/companies/${companyId}/applicants/csv`,
        req
      );
      return res.data;
    } catch (error: any) {
      console.error(
        `Error for AdminDashboardApi.getApplicantsDetailsCsv(): ${error}`
      );
      throw error;
    }
  }

  static async getJobPostsByCompanyId(
    companyId: number,
    req: PageInfoSearchReq
  ): Promise<ApiResponse<AdminDashJobPostsRes>> {
    try {
      const response = await api.post<ApiResponse<AdminDashJobPostsRes>>(
        `${apiConfig.adminDashboardUrl}/companies/${companyId}/job-posts`,
        req
      );

      return response.data;
    } catch (error: any) {
      console.error(
        'Error for AdminDashboardApi.getJobPostsByCompanyId():',
        error
      );
      throw error;
    }
  }

  static async getJobPostsCsvByCompanyId(
    companyId: number
  ): Promise<ApiResponse<AdminDashJobPostCsv[]>> {
    try {
      const response = await api.post<ApiResponse<AdminDashJobPostCsv[]>>(
        `${apiConfig.adminDashboardUrl}/companies/${companyId}/job-posts/csv`
      );

      return response.data;
    } catch (error: any) {
      console.error(
        'Error for AdminDashboardApi.getJobPostsCsvByCompanyId():',
        error
      );
      throw error;
    }
  }

  static async getCandidatesDetailsCsv(
    req: CandidatesCsvReq
  ): Promise<CandidateCsv[]> {
    try {
      const res = await api.post<ApiResponse<CandidateCsv[]>>(
        `${apiConfig.adminDashboardUrl}/candidates/csv`,
        req
      );

      return res.data.data;
    } catch (error: any) {
      console.error(
        `Error for AdminDashboardApi.getCandidatesDetailsCsv(): ${error}`
      );
      throw error;
    }
  }

  static async getCompletedCandidatesDetailsCsv(
    req: DateRange
  ): Promise<CandidateCsv[]> {
    try {
      const res = await api.post<ApiResponse<CandidateCsv[]>>(
        `${apiConfig.adminDashboardUrl}/candidates/completed/csv`,
        req
      );

      return res.data.data;
    } catch (error: any) {
      console.error(
        `Error for AdminDashboardApi.getCompletedCandidatesDetailsCsv(): ${error}`
      );
      throw error;
    }
  }
}
