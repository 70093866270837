import * as React from 'react';
import {
  AppRoles,
  roles,
  SeatRoleSelectOption
} from '@api/models/userApi.models';
import { Button, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { emailPattern } from '@common/regex/email';
import { InviteApi } from '@api/Invite.api';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
// TODO: use multi-select instead of select once we are using multiple roles
// import ReactHookFormsMultiSelect from '@components/Forms/ReactHookFormsMultiSelect';
import ReactHookFormsSelect from '@components/Forms/ReactHookFormsSelect';
import { scrollToError } from '@common/helpers/scrollToError';
import { SendInviteRequest } from '@api/models/inviteApi.models';
import { styled } from '@mui/system';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';

const Styled = {
  Button: styled(Button)(({ theme }) => ({
    whiteSpace: 'nowrap',
    height: '45px',
    width: '118px',
    '& .invite-btn-text': {
      color: theme.palette.WHITE.main,
      fontWeight: 600
    },
    ['@media (max-width:600px)']: {
      width: '100%'
    }
  }))
};

interface FormValues extends Omit<SendInviteRequest, 'roles'> {
  roles: AppRoles;
}

interface Props {
  companyId: number;
  openToast: (msg: string) => void;
  fetchSentInvites: () => void;
}

const InviteUserBox: React.FC<Props> = ({
  companyId,
  openToast,
  fetchSentInvites
}) => {
  const [inviteSent, setInviteSent] = React.useState<boolean>(false);

  const featureFlags = useFeatureFlags();

  const defaultRoles = featureFlags.PARTNER_EXPERIENCE
    ? undefined
    : AppRoles.RECRUITER;

  const methods = useForm<FormValues>({
    defaultValues: { companyId, email: '', roles: defaultRoles }
  });

  const { handleSubmit, reset, setError } = methods;

  const formOrder = {
    email: 1,
    roles: 2
  };

  const roleOptions: SeatRoleSelectOption<AppRoles>[] = [
    { label: roles.RECRUITER, value: AppRoles.RECRUITER },
    { label: roles.ESOURCE_USER, value: AppRoles.ESOURCE_USER }
  ];

  // TODO: data will be Type SendInviteResponse once multi-select is used
  const onValid = async (data): Promise<void> => {
    const postBody = {
      companyId,
      email: data.email,
      // TODO: replace with commented code when roles becomes multi-select
      // roles: data.roles
      roles: featureFlags.PARTNER_EXPERIENCE
        ? [data.roles]
        : [AppRoles.RECRUITER]
    };

    let exceptionThrown = false;
    try {
      await InviteApi.sendInvite(postBody);
      fetchSentInvites();
      setInviteSent(true);
    } catch (error: any) {
      exceptionThrown = true;
      console.error('Error for InviteApi.sendInvite()', error);
      const errData = error.response.data;
      const message = errData.message;
      setError(errData.data.field, { type: 'manual', message });
      scrollToError(methods.formState.errors, formOrder);
    } finally {
      if (!exceptionThrown) {
        openToast('Invite Sent');
      }
    }
  };

  React.useEffect(() => {
    if (inviteSent) {
      reset();
      setInviteSent(false);
    }
  }, [inviteSent]);

  return (
    <FormProvider {...methods}>
      <Grid container spacing={1} data-testid="invite-user-box-root">
        <Grid item xs={8} sm={6}>
          <ReactHookFormsInput
            name="email"
            placeholder="Add user email"
            dataTestId="invite-user-box-input"
            disableRequiredAsterisk
            rules={{
              required: true,
              pattern: {
                value: emailPattern,
                message: 'Please enter a valid email'
              }
            }}
          />
        </Grid>
        {featureFlags.PARTNER_EXPERIENCE && (
          <Grid item xs={4} sm={3}>
            <ReactHookFormsSelect
              name="roles"
              options={roleOptions}
              placeholder="Role"
              rules={{ required: true }}
              dataTestId="invite-user-box-select"
              disableRequiredAstrisk
            />
            {/* <ReactHookFormsMultiSelect name="roles" options={roleOptions} /> */}
          </Grid>
        )}
        <Grid item xs={12} sm={3}>
          <Styled.Button
            variant="contained"
            onClick={handleSubmit(onValid)}
            data-testid="invite-user-box-button"
            fullWidth
          >
            <Typography variant="EC_TYPE_2XS" className="invite-btn-text">
              Invite New User
            </Typography>
          </Styled.Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default InviteUserBox;
