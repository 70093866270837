import React, { useEffect, useState } from 'react';
import { Codebook } from '@api/models/codebook.models';
import { CodebookApi } from '@api/Codebook.api';

type FetchGlrcContentTopicsCodebook = {
  glrcContentTopics: Codebook[];
  loading: boolean;
  loaded: boolean;
};

/**
 * Fetch GLRC Content Topics Codebook, used for Company GLRC Access Modal
 *
 * @returns `{ glrcContentTopics: Codebook[]; loading: boolean; loaded: boolean; }`
 */
export const useFetchGlrcContentTopicsCodebook =
  (): FetchGlrcContentTopicsCodebook => {
    const [glrcContentTopics, setGlrcContentTopics] = useState<Codebook[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      const fetchData = async (): Promise<void> => {
        try {
          setLoading(true);

          const res = await CodebookApi.getGlrcContentTopics();
          setGlrcContentTopics(res.data);
          setLoaded(true);
        } catch (error: any) {
          console.error(
            'Error for useFetchGlrcContentTopicsCodebook -> CodebookApi.getGlrcContentTopics()',
            error
          );
        } finally {
          setLoading(false);
          setLoaded(true);
        }
      };
      fetchData();
    }, []);

    return { glrcContentTopics, loading, loaded };
  };
