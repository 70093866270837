/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useSetStoreValue, useStore } from 'react-context-hook';
import { Box } from '@mui/material';
import { CandidateAboutPutReq } from '@api/models/candidateApi.models';
import { CandidateApi } from '@api/Candidate.api';
import { Codebook } from '@api/models/codebook.models';
import CultureAndBenefitsSection from '@pages/Candidate/CandidateProfileSetupPage/CandidateAboutStep/CultureAndBenefitsSection';
import { FileType } from '@api/models/fileApi.models';
import FormActionsComponent from '@components/Forms/FormActionsComponent';
import { GraduationTypes } from '@api/models/adminDashboardApi.models';
import PageDescriptionComponent from '@components/Layout/Common/PageDescriptionComponent';
import PageFormLayoutComponent from '@components/Layout/PageFormLayoutComponent';
import PersonalInfoSection from '@pages/Shared/ProfileSetup/PersonalInfoSection';
import { scrollToError } from '@common/helpers/scrollToError';
import { styled } from '@mui/system';
import { Tag } from '@api/models/common.models';
import { useFetchCandidateProfile } from '@common/fetches/useFetchCandidateProfile';
import { useFetchKeycloakUserProfile } from '@common/fetches/useFetchKeycloakUserProfile';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import WorkPreferencesSection from '@pages/Candidate/CandidateProfileSetupPage/CandidateAboutStep/WorkPreferencesSection';

const Styled = {
  FormWrapper: styled(Box)({
    '& > *': { marginBottom: '75px' }
  })
};

interface FormValues
  extends Omit<
    CandidateAboutPutReq,
    'segments' | 'specialties' | 'program' | 'campus' | 'graduationType'
  > {
  segments: Codebook[];
  specialties: Codebook[];
  graduationType: number;
  program: number;
  campus: number;
}

interface Props {
  handleStepper: (next: boolean) => void;
}

const CandidateAboutStep: React.FC<Props> = ({ handleStepper }) => {
  const [candidateId] = useStore<number>(UseStoreKeys.CANDIDATE_ID);
  const setStoreStudentId = useSetStoreValue(UseStoreKeys.CANDIDATE_STUDENT_ID);
  const [files, setFiles] = React.useState<FileType[]>([]);

  const { candidateProfile } = useFetchCandidateProfile(candidateId);
  const { keycloakUserProfile } = useFetchKeycloakUserProfile();

  const keycloakStudentId = keycloakUserProfile?.attributes?.studentnumber?.[0];

  const methods = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      locations: [],
      relocation: '',
      availability: '',
      employmentType: [],
      segments: [],
      specialties: [],
      benefits: [],
      attributes: [],
      photoId: undefined,
      badge: undefined,
      graduationType: undefined,
      campus: undefined,
      program: undefined,
      studentId: ''
    },
    shouldUnregister: true
  });

  const formOrder = {
    firstName: 0,
    lastName: 3,
    phone: 5,
    locations: 10,
    relocation: 20,
    availability: 30,
    studentId: 31,
    graduationType: 32,
    campus: 33,
    program: 34,
    badge: 35,
    employmentType: 40,
    segments: 50,
    specialties: 60,
    benefits: 70,
    attributes: 80,
    photoId: 90
  };

  const { handleSubmit, reset, setError } = methods;

  React.useEffect(() => {
    if (candidateProfile && candidateProfile.photo) {
      setFiles([candidateProfile.photo]);
    }
  }, [candidateProfile]);

  const extractTagLabels = (tags: Tag[]): string[] => tags.map((t) => t.type);

  React.useEffect(() => {
    if (candidateProfile) {
      const {
        availability,
        attributes,
        benefits,
        employmentType,
        relocation,
        segments,
        specialties,
        badge,
        graduationType,
        program,
        campus,
        studentId
      } = candidateProfile;

      reset({
        ...candidateProfile,
        relocation: relocation || '',
        availability: availability || '',
        employmentType: extractTagLabels(employmentType),
        segments: segments || '',
        specialties: specialties || '',
        benefits: extractTagLabels(benefits),
        attributes: extractTagLabels(attributes),
        badge: badge?.value,
        graduationType: graduationType?.value ?? GraduationTypes.NEITHER,
        // TODO: Fix studentID in keycloak to be 10 digit ID (currently pulling in 6 digit ID)
        // If student id exists in keycloak profile it will take priority
        studentId: keycloakStudentId || studentId || '',
        program: program?.value || undefined,
        campus: campus?.value || undefined
      });
    }
  }, [candidateProfile, keycloakStudentId]);

  const handleInvalidForm = (errors: FieldErrors): void => {
    scrollToError(errors, formOrder);
  };

  const handleValidForm = async (
    data: FormValues,
    onSuccess: () => void
  ): Promise<void> => {
    const postBody: CandidateAboutPutReq = {
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      phone: data.phone,
      locations: data.locations,
      relocation: data.relocation,
      availability: data.availability,
      photoId: data.photoId,
      employmentType: data.employmentType,
      segments: data.segments.map((segment) => segment.value),
      specialties: data.specialties.map((specialty) => specialty.value),
      benefits: data.benefits,
      attributes: data.attributes,
      badge: data.badge,
      graduationType: data.graduationType,
      ...(data.graduationType !== GraduationTypes.NEITHER
        ? {
            campus: data.campus,
            program: data.program,
            studentId: data.studentId
          }
        : {
            campus: null,
            program: null,
            studentId: null
          })
    };

    try {
      // set useStore studentId value in case they move on to externship proposal
      if (data.studentId) {
        setStoreStudentId(data.studentId);
      }

      await CandidateApi.updateCandidateAbout(candidateId, postBody);
      onSuccess();
    } catch (error: any) {
      console.error(
        'Error for CandidateAboutStep.updateCandidateAbout()',
        error
      );

      const errData = error.response.data.data;

      if (errData?.length) {
        errData.forEach(({ field, message }) =>
          setError(field, { type: 'manual', message })
        );
      }

      scrollToError(methods.formState.errors, formOrder);
    }
  };

  const handleFormSubmit = (onSuccess: () => void): void => {
    const onValid = async (data: FormValues): Promise<void> =>
      handleValidForm(data, onSuccess);
    handleSubmit(onValid, handleInvalidForm)();
  };

  return (
    <PageFormLayoutComponent
      sideContent={
        <PageDescriptionComponent title="About You">
          Tell us what you want out of a job placement by indicating your work
          preferences, benefits that are important to you, and how you would
          describe your ideal workplace. The more specific you are, the better
          your employer match.
        </PageDescriptionComponent>
      }
      content={
        <FormProvider {...methods}>
          <Styled.FormWrapper data-testid="candidate-about-step">
            <PersonalInfoSection files={files} />
            <WorkPreferencesSection />
            <CultureAndBenefitsSection />
          </Styled.FormWrapper>
        </FormProvider>
      }
      actions={
        <FormActionsComponent
          hidePreviousBtn
          onSubmit={handleFormSubmit}
          submitBtnText="Save and Continue"
          handleStepper={handleStepper}
        />
      }
    />
  );
};

export default CandidateAboutStep;
