import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import { useFormContext } from 'react-hook-form';
import { useStudentViewSiteInfoContext } from '@common/context/studentViewSiteInfoContext';

interface Props {
  label: string;
  value: any;
  inputName?: string;
  useTextArea?: boolean;
}

const StudentSiteInfoDetail: React.FC<Props> = ({
  label,
  value,
  inputName,
  useTextArea = false
}) => {
  const { getIsEditing } = useStudentViewSiteInfoContext();
  const isEditing = getIsEditing('Position');
  const editMode = !!inputName && isEditing;
  const { getValues, reset } = useFormContext();

  React.useEffect(() => {
    if (editMode && value && inputName) {
      reset({
        ...getValues(),
        [inputName]: value
      });
    }
  }, [editMode, value, inputName]);

  if (!value) return <></>;

  return (
    <Grid item xs={inputName ? 12 : undefined}>
      <Typography
        variant="EC_TYPE_2XS"
        sx={{ textTransform: 'uppercase', marginBottom: '2px' }}
      >
        {label}
      </Typography>
      {editMode ? (
        <ReactHookFormsInput
          name={inputName}
          fullWidth
          multiline={useTextArea}
          rules={{ required: 'Required' }}
        />
      ) : (
        <Typography variant="EC_TYPE_3XS" sx={{ lineHeight: '18px' }}>
          {value}
        </Typography>
      )}
    </Grid>
  );
};

export default StudentSiteInfoDetail;
