import * as React from 'react';
import {
  Box,
  Button,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { getCompanyPath } from '@common/helpers/getPathWithParamsId';
import { JobPost } from '@api/models/singleJobPostApi.models';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';

const Styled = {
  Root: styled(Box)({
    padding: 0
  }),
  Text: styled(Typography)({
    transform: 'translateY(-25%)',
    fontSize: 18,
    color: '#000000',
    letterSpacing: '3px',
    fontWeight: 600
  }),
  Button: styled(Button)({
    minWidth: 0,
    minHeight: 0,
    width: 'unset',
    height: 'unset',
    padding: 0
  })
};

interface IJobPostingsOptionsMenu {
  jobId: number;
  handleVisibility: (jobId: number) => Promise<void>;
  visibleOnMarketplace: JobPost['visibleOnMarketplace'];
}

const JobPostingsOptionsMenu: React.FC<IJobPostingsOptionsMenu> = ({
  jobId,
  visibleOnMarketplace,
  handleVisibility
}) => {
  const { paramsId } = useParamsOrStoreCompanyId();

  const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);
  const menuOpen = Boolean(anchor);

  const handleClick = (e: React.BaseSyntheticEvent<MouseEvent>): void => {
    setAnchor(e.currentTarget);
  };

  const handleUpdateVisibility = (): void => {
    handleVisibility && handleVisibility(jobId);
    setAnchor(null);
  };

  const handleClose = (): void => {
    setAnchor(null);
  };

  return (
    <Styled.Root>
      <Styled.Button
        id="ellipses-button"
        aria-controls="ellipses-menu"
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleClick}
        size="small"
        variant="text"
      >
        <Styled.Text>...</Styled.Text>
      </Styled.Button>
      <Menu
        id="ellipses-menu"
        anchorEl={anchor}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'ellipses-button' }}
      >
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <i className="ri-edit-box-line"></i>
          </ListItemIcon>
          <Link
            component={RouterLink}
            to={getCompanyPath(`/job/${jobId}/edit`, paramsId)}
            underline="none"
          >
            <ListItemText>Edit Job Post</ListItemText>
          </Link>
        </MenuItem>
        <MenuItem onClick={handleUpdateVisibility}>
          {visibleOnMarketplace ? (
            <>
              <ListItemIcon>
                <i className="ri-eye-off-line"></i>
              </ListItemIcon>
              <ListItemText>Hide from Marketplace</ListItemText>
            </>
          ) : (
            <>
              <ListItemIcon>
                <i className="ri-eye-line"></i>
              </ListItemIcon>
              <ListItemText>Visible on Marketplace</ListItemText>
            </>
          )}
        </MenuItem>
      </Menu>
    </Styled.Root>
  );
};

export default JobPostingsOptionsMenu;
