import * as React from 'react';
import { Box, Typography } from '@mui/material';
import DocumentRepositoryCard from './DocumentRepositoryCard';
import { ExternshipEntity } from '@api/models/externshipApi.models';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)({
    display: 'flex',
    flexDirection: 'column'
  })
};

interface Props {
  externships: ExternshipEntity[];
}

const DocumentRepository: React.FC<Props> = ({ externships }) => {
  return (
    <Styled.Root>
      <Typography variant="EC_TYPE_3XL">Documents</Typography>
      {externships.map((e) => (
        <DocumentRepositoryCard externship={e} key={e.id} />
      ))}
    </Styled.Root>
  );
};

export default DocumentRepository;
