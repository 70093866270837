import * as React from 'react';
import { Partner } from '@api/models/partnerApi.models';
import { PartnerApi } from '@api/Partner.api';

export type FetchPartners = {
  partners: Partner[];
  loaded: boolean;
  loading: boolean;
};

export const useFetchPartners = (): FetchPartners => {
  const [partners, setPartners] = React.useState<Partner[]>([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchPartners = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await PartnerApi.getPartners();

      setPartners(res);
      setLoaded(true);
    } catch (error: any) {
      console.error(
        'Error for useFetchPartners -> PartnerApi.getPartners()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    fetchPartners();
  }, []);

  return {
    partners,
    loaded,
    loading
  };
};
