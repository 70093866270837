import * as React from 'react';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { convertMinutesToHoursAndMinutes } from '@common/helpers/timesheetHelpers/convertMinsToHoursAndMins';
import { durationMask } from '@common/masks/durationMask';
import { FieldArrayWithId } from 'react-hook-form';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { TimesheetDay } from '@api/models/externshipApi.models';

const Styled = {
  Root: styled(Box)({
    border: `1px solid ${theme.palette.GOLD_1.main}`,
  }),
  Table: styled(Table, {
    shouldForwardProp: (prop) => prop !== 'smallerTableSize'
  })<{ smallerTableSize?: boolean }>(({ smallerTableSize }) => ({
    minWidth: smallerTableSize ? 'unset' : '700px'
  })),
  GoldTableRow: styled(TableRow)({
    backgroundColor: theme.palette.GOLD_1.main
  }),
  TableHeaderCell: styled(TableCell)({
    textAlign: 'center',
    padding: '8px',
    border: `1px solid ${theme.palette.GOLD_1.main}`
  }),
  HeaderLabel: styled(Typography)({
    color: theme.palette.GRAY_1.main
  }),
  TableBodyCell: styled(TableCell)({
    '&:last-child': {
      borderRight: 'none',
    },
    textAlign: 'center',
    padding: '16px',
    borderRight: `1px solid ${theme.palette.GOLD_1.main}`,
    borderBottom: 'none',
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0
    }
  }),
  MobileDate: styled(Typography)({
    color: theme.palette.WHITE.main,
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 16px',
    backgroundColor: theme.palette.GOLD_1.main,
    height: '100%',
    minHeight: '60px'
  }),
  MobileHours: styled(Typography)({
    color: theme.palette.GRAY_4.main,
    height: '100%',
    border: `1px solid ${theme.palette.GRAY_4.main}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  })
};

const mobileInputStyles = {
  fieldset: {
    borderColor: theme.palette.GRAY_4.main,
    borderRadius: '0px'
  },
  ...theme.typography.EC_TYPE_3XL,
  color: theme.palette.GRAY_4.main,
  lineHeight: 'unset',
  marginBottom: 0,
  '&.MuiOutlinedInput-root': {
    height: 'auto'
  },
  '& .MuiOutlinedInput-input': {
    textAlign: 'center',
    height: 'auto'
  }
};

const inputStyles = {
  ...theme.typography.EC_TYPE_XL,
  color: theme.palette.GRAY_4.main,
  lineHeight: 'unset',
  marginBottom: 0,
  paddingBottom: '8px',
  '&.MuiOutlinedInput-root': {
    height: 'auto'
  },
  '& .MuiOutlinedInput-input': {
    textAlign: 'center',
    height: 'auto'
  }
};

interface Props {
  dailyEntries: TimesheetDay[];
  fields: FieldArrayWithId[];
  uneditable?: boolean;
  isMobile: boolean;
  careerServiceView?: boolean;
}

const TimesheetForm: React.FC<Props> = ({
  dailyEntries,
  fields,
  uneditable,
  isMobile,
  careerServiceView
}) => {
  const preventInputNumberScroll = (e): void =>
    e.target.addEventListener(
      'wheel',
      (e) => {
        e.preventDefault();
      },
      { passive: false }
    );

  const getHours = (idx: number): string => {
    idx;
    return convertMinutesToHoursAndMinutes(dailyEntries[idx].minutes); //roundToSecondDecimal(dailyEntries[idx].minutes / 60);
  };

  if (isMobile) {
    return (
      <BottomMarginContentWrapper bottomMargin="8px">
        {fields.map((field, idx) => (
          <Grid
            container
            key={field.id}
            onFocus={preventInputNumberScroll}
            columnSpacing={3}
          >
            <Grid item xs={5} md={3.5}>
              <Styled.MobileDate variant="EC_TYPE_XL">
                {formatDate(
                  new Date(dailyEntries[idx].date).toISOString(),
                  'ccc (M/d)'
                )}
              </Styled.MobileDate>
            </Grid>
            <Grid item xs={7} md={8.5}>
              {uneditable ? (
                <Styled.MobileHours variant="EC_TYPE_3XL">
                  {getHours(idx)}
                </Styled.MobileHours>
              ) : (
                <ReactHookFormsInput
                  name={`dailyEntries.${idx}.time`}
                  inputStyles={{ ...mobileInputStyles }}
                  mask={durationMask}
                  placeholder="HH:MM"
                />
              )}
            </Grid>
          </Grid>
        ))}
      </BottomMarginContentWrapper>
    );
  }

  return (
    <TableContainer component={Styled.Root}>
      <Styled.Table smallerTableSize={careerServiceView}>
        <TableHead>
          <Styled.GoldTableRow>
            {dailyEntries.map((d) => (
              <Styled.TableHeaderCell key={new Date(d.date).toISOString()}>
                <Styled.HeaderLabel variant="EC_TYPE_SM">
                  {formatDate(new Date(d.date).toISOString(), 'ccc')}
                  <br />
                  {formatDate(new Date(d.date).toISOString(), '(M/d)')}
                </Styled.HeaderLabel>
              </Styled.TableHeaderCell>
            ))}
          </Styled.GoldTableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {fields.map((field, idx) => (
              <Styled.TableBodyCell
                key={field.id}
                onFocus={preventInputNumberScroll}
              >
                {uneditable ? (
                  <Typography
                    variant="EC_TYPE_2XL"
                    mb={0}
                    sx={{ color: theme.palette.GRAY_4.main }}
                  >
                    {getHours(idx)}
                  </Typography>
                ) : (
                  <>
                    <Typography
                      variant="EC_TYPE_2XS"
                      sx={{ color: theme.palette.GRAY_3.main }}
                    >
                      HR:MIN
                    </Typography>
                    <ReactHookFormsInput
                      name={`dailyEntries.${idx}.time`}
                      inputStyles={inputStyles}
                      mask={durationMask}
                      placeholder="HH:MM"
                    />
                  </>
                )}
              </Styled.TableBodyCell>
            ))}
          </TableRow>
        </TableBody>
      </Styled.Table>
    </TableContainer>
  );
};

export default TimesheetForm;
