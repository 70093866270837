/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Box, IconButton, Link } from '@mui/material';
import chefHat from '@assets/svg/chef_hat.round.gold4.svg';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';

const Styled = {
  Card: styled(Box, {
    shouldForwardProp: (prop) =>
      prop !== 'tableDisplay' &&
      prop !== 'maxWidth' &&
      prop !== 'disableElevation' &&
      prop !== 'boxShadow'
  })<{
    tableDisplay: boolean;
    maxWidth?: string;
    disableElevation: boolean;
    boxShadow?: string;
  }>(({ tableDisplay, maxWidth, disableElevation, theme, boxShadow }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.WHITE.main,
    width: tableDisplay ? '134px' : '100%',
    height: tableDisplay ? '77px' : '100px',
    borderRadius: tableDisplay ? '10px' : '4px',
    border: tableDisplay ? `1px solid ${theme.palette.GOLD_1.main}` : '',
    overflow: tableDisplay ? 'hidden' : '',
    ...(boxShadow
      ? { boxShadow }
      : {
          boxShadow: !disableElevation
            ? '0px 0px 5px rgba(51,51,51,0.4)'
            : 'none'
        }),
    ...(maxWidth && {
      maxWidth
    })
  })),
  Logo: styled('img', {
    shouldForwardProp: (prop) => prop !== 'tableDisplay'
  })<{ tableDisplay: boolean }>(({ tableDisplay }) => ({
    display: 'block',
    maxHeight: tableDisplay ? '77px' : '92px',
    width: '100%',
    objectFit: 'scale-down'
  })),
  RemoveBtn: styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    color: theme.palette.OUTLINE.main,
    padding: '1px 0 0 1px',
    '& i': {
      fontSize: '20px'
    }
  }))
};

type PromiseOrVoid = Promise<void> | void;

interface Props {
  logo?: string;
  name: string;
  path?: string;
  tableDisplay?: boolean;
  disableElevation?: boolean;
  maxWidth?: string;
  customStyles?: React.CSSProperties;
  boxShadow?: string;
  handleRemoveClick?: (...args: any[]) => PromiseOrVoid;
}

const LinkLogo: React.FC<Pick<Props, 'path'>> = ({ path, children }) => {
  return (
    <Link component={RouterLink} to={path} underline="none">
      {children}
    </Link>
  );
};

const Logo: React.FC<Omit<Props, 'path'>> = ({
  logo,
  name,
  tableDisplay = false,
  maxWidth,
  customStyles,
  disableElevation = false,
  handleRemoveClick,
  boxShadow
}) => {
  const handleClick = React.useCallback(
    (e: React.BaseSyntheticEvent<MouseEvent>) => {
      e.stopPropagation();
      if (handleRemoveClick) {
        handleRemoveClick();
      }
    },
    [handleRemoveClick]
  );

  return (
    <Styled.Card
      boxShadow={boxShadow}
      tableDisplay={tableDisplay}
      maxWidth={maxWidth}
      disableElevation={disableElevation}
      sx={customStyles}
    >
      <Styled.Logo
        src={logo || chefHat}
        alt={`${name} logo`}
        tableDisplay={tableDisplay}
        data-testid={`${name}-company-logo`}
        sx={customStyles}
      />
      {handleRemoveClick && (
        <Styled.RemoveBtn onClick={handleClick}>
          <i className="ri-close-circle-line" />
        </Styled.RemoveBtn>
      )}
    </Styled.Card>
  );
};

const CompanyLogo: React.FC<Props> = ({
  logo,
  name,
  path,
  tableDisplay,
  maxWidth,
  customStyles,
  disableElevation,
  boxShadow,
  handleRemoveClick
}) => {
  if (path) {
    return (
      <LinkLogo path={path}>
        <Logo
          logo={logo}
          name={name}
          tableDisplay={tableDisplay}
          disableElevation={disableElevation}
          maxWidth={maxWidth}
          customStyles={customStyles}
          handleRemoveClick={handleRemoveClick}
          boxShadow={boxShadow}
        />
      </LinkLogo>
    );
  } else {
    return (
      <Logo
        logo={logo}
        name={name}
        tableDisplay={tableDisplay}
        disableElevation={disableElevation}
        maxWidth={maxWidth}
        customStyles={customStyles}
        handleRemoveClick={handleRemoveClick}
        boxShadow={boxShadow}
      />
    );
  }
};

export default CompanyLogo;
