import {
  CANDIDATE_STATUS,
  CandidateProfilePublic
} from '@api/models/candidateApi.models';
import { useEffect, useMemo, useState } from 'react';
import { CandidateApi } from '@api/Candidate.api';

type FetchCandidateProfilePublic = {
  candidateProfile: CandidateProfilePublic | null;
  profileComplete: boolean;
  loading: boolean;
  loaded: true | null;
};

/**
 * Fetch CandidateProfilePublic from CandidateApi.getByIdPublic()
 *
 * @param {number} candidateId
 * @returns `{ candidateProfile: CandidateProfilePublic; profileComplete: boolean; loading: boolean; }`
 */
export const useFetchCandidateProfilePublic = (
  candidateId?: number
): FetchCandidateProfilePublic => {
  const [candidateProfile, setCandidateProfile] =
    useState<CandidateProfilePublic | null>(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  useEffect(() => {
    if (candidateId) {
      const fetchProfile = async (): Promise<void> => {
        try {
          setLoading(true);
          const res = await CandidateApi.getByIdPublic(candidateId);
          setCandidateProfile(res.data);
        } catch (error) {
          setLoading(false);
          setLoaded(true);
          console.error(
            'Error for useFetchCandidateProfilePublic -> CandidateApi.getByIdPublic()',
            error
          );
        } finally {
          setLoading(false);
          setLoaded(true);
        }
      };

      fetchProfile();
    } else {
      setLoaded(true);
    }
  }, [candidateId]);

  const profileComplete = useMemo(() => {
    if (candidateProfile) {
      return candidateProfile.status === CANDIDATE_STATUS.COMPLETED;
    }

    return false;
  }, [candidateProfile]);

  return { candidateProfile, profileComplete, loading, loaded };
};
