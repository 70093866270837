import * as React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    '& .empty-goal-text': {
      color: theme.palette.GRAY_3.main
    }
  })),
  SectionTitle: styled(Typography)({
    textTransform: 'uppercase'
  })
};

interface Props {
  handleScrollToExploreMore: () => void;
}

const EmptySavedGoal = ({
  handleScrollToExploreMore
}: Props): React.ReactElement => {
  return (
    <Styled.Root>
      <Styled.SectionTitle variant="EC_TYPE_2XL" className="empty-goal-text">
        you haven&apos;t selected a career goal yet.
      </Styled.SectionTitle>
      <Typography variant="EC_TYPE_BASE" className="empty-goal-text">
        Select one from the{' '}
        <Link
          underline="none"
          onClick={handleScrollToExploreMore}
          sx={{ cursor: 'pointer' }}
        >
          Example career paths
        </Link>{' '}
        section below. If you are not sure which path you want to pursue, take
        the{' '}
        <Link component={RouterLink} to="/quiz/1" underline="none">
          Culinary Personality Quiz.
        </Link>
      </Typography>
    </Styled.Root>
  );
};

export default EmptySavedGoal;
