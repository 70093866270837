/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Theme,
  Typography,
  useTheme
} from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Education, WorkExperience } from '@api/models/profileSetupApi.models';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { Codebook } from '@api/models/codebook.models';
import { DateTime } from 'luxon';
import FormFieldArrayDivider from '@components/FormFieldArrayDivider';
import FormFieldControlButtons from '@components/FormFieldControlButtons';
import FormFieldErrorText from '@components/Forms/FormFieldErrorText';
import { styled } from '@mui/system';

const Styled = {
  GridContainer: styled(Grid)({
    alignItems: 'start',
    justifyContent: 'space-between',
    marginBottom: '10px'
  }),
  FieldContainer: styled(Grid)({
    minHeight: '105px'
  }),
  InputLabel: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'required' && prop !== 'disabled'
  })<{ required?: boolean; disabled?: boolean }>(
    ({ required, disabled, theme }) => ({
      marginBottom: '12px',
      color: disabled ? '#AAAAAA' : '',
      ...(required && {
        '&:after': {
          content: '"*"',
          color: theme.palette.error.main,
          marginLeft: '4px',
          marginRight: '5px'
        }
      })
    })
  ),
  Textfield: styled(TextField)({
    '& .MuiOutlinedInput-root': {
      padding: '0px',
      height: 'auto',
      fieldset: {
        borderRadius: '4px 4px 0 0'
      }
    }
  }),
  Title: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'required'
  })<{ required?: boolean }>(({ required, theme }) => ({
    marginBottom: '14px',
    '&:after': {
      content: required ? '"*"' : '""',
      color: theme.palette.error.main,
      marginLeft: '4px'
    }
  })),
  CheckboxBtn: styled(Button)(({ theme }) => ({
    color: theme.palette.GRAY_3.main,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    padding: '0 0 0 4px',
    width: 'max-content',
    '& .MuiButton-startIcon': {
      fontWeight: 'normal',
      marginRight: '6px'
    }
  })),
  ErrorText: styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    marginTop: '5px'
  }))
};

type InputType =
  | 'outlined_input'
  | 'select'
  | 'text_area'
  | 'date_picker'
  | 'check_box';

type FieldArrayType = Education<Codebook> | WorkExperience;

// TODO educationType Codebook: Temp types are being used because educationType is not yet a Codebook type.
// Once educationType is a Codebook, delete all Temp types as value will always be number.
type TempSelectValue = string | number;
type TempCodebook<T extends TempSelectValue> = {
  value: T;
  label: string;
};

// TODO educationType Codebook: Will change to Codebook | number once educationType is updated to Codebook
// type MUISelectType = Codebook | number
type MUISelectedType =
  | {
      value: TempSelectValue; // eslint-disable-line @typescript-eslint/indent
      label: string; // eslint-disable-line @typescript-eslint/indent
    } // eslint-disable-line @typescript-eslint/indent
  | TempSelectValue;

export type FieldArrayFieldProps<T extends FieldArrayType> = {
  fieldName: Extract<keyof T, string>;
  inputType: InputType;
  inputLabel?: string;
  // TODO educationType Codebook: this will only be Codebook[] type once educationType is set up as a Codebook.
  // Using this type in order to maintain functionality until that change is made.
  selectOptions?:
    | TempCodebook<TempSelectValue>[]
    | ((field: FieldArrayType) => Codebook[]);
  displayAsterisk?: boolean;
  disabledDisplay?: boolean;
  gridXSCols?: number;
  gridSMCols?: number;
  rules?: any;
  fieldStyle?: React.CSSProperties;
  datePickerRequired?: boolean;
  datePickerNotRequiredText?: string;
  datePickerMinDate?: DateTime;
  datePickerFormat?: string;
  checkBoxText?: string;
  onSelectMenuClose?: (idx: number) => void;
  getDependentFieldDisabled?: (field: FieldArrayType) => boolean;
  getDependentFieldRequired?: (field: FieldArrayType) => boolean;
  getMinEndDate?: (idx: number) => DateTime;
  onCheckboxChecked?: (idx: number) => void;
  getIsHidden?: (field: FieldArrayType) => boolean;
};

type ControlledFieldType<T extends FieldArrayType> = {
  id: string;
} & T;

interface RHFFieldArrayProps<T extends FieldArrayType> {
  fieldArrayName: string;
  fieldMap: FieldArrayFieldProps<T>[];
  addBtnText: string;
  fieldArrayRequired: boolean;
  setFieldArrayRequired: (required: boolean) => void;
  defaultValues?: {} | null;
  sectionTitle?: string;
  fieldError?: boolean;
  fieldErrorText?: string;
  fullWidthField?: boolean;
  alwaysDisplayTitleAsterisk?: boolean;
}

const ReactHookFormsFieldArray = <T extends FieldArrayType>({
  fieldArrayName,
  fieldMap,
  addBtnText,
  fieldArrayRequired,
  setFieldArrayRequired,
  sectionTitle,
  fieldError,
  alwaysDisplayTitleAsterisk,
  defaultValues = null
}: RHFFieldArrayProps<T>): React.ReactElement => {
  const theme = useTheme();

  const {
    control,
    register,
    reset,
    getValues,
    clearErrors,
    watch,
    formState: { errors }
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    name: fieldArrayName,
    control
  });

  const defaultFields =
    defaultValues ??
    Object.assign({}, ...fieldMap.map((item) => ({ [item.fieldName]: '' })));

  const watchFieldArray = watch(fieldArrayName);
  const controlledFields: ControlledFieldType<T>[] = fields.map(
    (field, idx) => ({
      ...field,
      ...watchFieldArray[idx]
    })
  );

  const requiredRule: { required: string | false } = {
    required: fieldArrayRequired ? 'Required' : false
  };

  const getSelectLabel = (
    inValue: TempSelectValue,
    options: TempCodebook<TempSelectValue>[]
  ): string => {
    const option = options.find((opt) => opt.value === inValue);
    if (option) {
      return option.label;
    }
    return '';
  };

  const getSelectValue = (inValue?: MUISelectedType): TempSelectValue => {
    if (inValue || inValue === 0) {
      return typeof inValue === 'object' ? inValue.value : inValue;
    }
    return '';
  };

  const addNewFieldObject = React.useCallback((): void => {
    setFieldArrayRequired(true);
    append(defaultFields);
  }, [setFieldArrayRequired, append, defaultFields]);

  const handleRemoveFieldObject = React.useCallback(
    (idx: number): void => {
      remove(idx);
    },
    [remove]
  );

  const handleNotApplicableClick = (): void => {
    reset({ ...getValues(), [fieldArrayName]: [] as T[] });
    setFieldArrayRequired(!fieldArrayRequired);
    clearErrors(fieldArrayName);
  };

  React.useEffect(() => {
    if (
      getValues()[fieldArrayName]?.length === 1 &&
      errors[fieldArrayName]?.type === 'minLength'
    ) {
      clearErrors(fieldArrayName);
    }
  }, [fieldArrayName, getValues()[fieldArrayName]?.length]);

  return (
    <Box data-testid={`${fieldArrayName}-field-array-root`}>
      {sectionTitle && (
        <Styled.Title
          variant="EC_TYPE_BASE"
          required={alwaysDisplayTitleAsterisk || fieldArrayRequired}
        >
          {sectionTitle}
        </Styled.Title>
      )}

      {errors[fieldArrayName]?.type === 'minLength' && (
        <FormFieldErrorText
          name={fieldArrayName}
          message={errors[fieldArrayName].message}
          disableAbsolutePosition
        />
      )}

      {controlledFields.map((field, controlIndex: number) => (
        <Styled.GridContainer container spacing={3} key={field.id}>
          <FormFieldArrayDivider
            count={controlIndex + 1}
            handleDeleteField={(): void =>
              handleRemoveFieldObject(controlIndex)
            }
          />
          {fieldMap.map((fieldItem, fieldMapIdx: number) => {
            const {
              getIsHidden = undefined,
              fieldName,
              inputType,
              inputLabel,
              selectOptions = [],
              displayAsterisk = false,
              gridXSCols = 12,
              gridSMCols = 6,
              datePickerRequired = false,
              datePickerNotRequiredText,
              datePickerMinDate,
              datePickerFormat = 'MMM yyyy',
              checkBoxText,
              onSelectMenuClose = undefined,
              onCheckboxChecked = undefined,
              getDependentFieldRequired = undefined,
              getDependentFieldDisabled = undefined,
              getMinEndDate = undefined,
              fieldStyle,
              disabledDisplay = undefined
            } = fieldItem;

            let _selectOptions;

            if (!Array.isArray(selectOptions)) {
              _selectOptions = selectOptions(field);
            } else {
              _selectOptions = selectOptions;
            }

            const disabledField: boolean | undefined =
              getDependentFieldDisabled && getDependentFieldDisabled(field);
            const dependentFieldRequired: boolean | undefined =
              getDependentFieldRequired && getDependentFieldRequired(field);

            const isHiddenField: boolean | undefined =
              getIsHidden && getIsHidden(field);

            // special cases, ex. areaOfFocus not being required if educationType is Highschool Diploma
            const fieldRule = (): { required: string | false } => {
              if (getDependentFieldRequired !== undefined) {
                return {
                  required:
                    dependentFieldRequired && fieldArrayRequired
                      ? 'Required'
                      : false
                };
              } else {
                return requiredRule;
              }
            };

            if (isHiddenField) {
              return (
                <React.Fragment
                  key={`${fieldName}-${fieldMapIdx}`}
                ></React.Fragment>
              );
            }

            return (
              <Controller
                key={`${fieldName}-${fieldMapIdx}`}
                control={control}
                name={`${fieldArrayName}.${controlIndex}.${fieldName}`}
                render={({ field: { value, ...rest } }): JSX.Element => {
                  const selectValue = getSelectValue(value);

                  return (
                    <Styled.FieldContainer
                      item
                      xs={gridXSCols}
                      sm={gridSMCols}
                      sx={fieldStyle}
                    >
                      {inputLabel && (
                        <Styled.InputLabel
                          variant="EC_TYPE_BASE"
                          required={dependentFieldRequired ?? displayAsterisk}
                          disabled={
                            disabledDisplay ?? dependentFieldRequired === false
                          }
                        >
                          {inputLabel}
                        </Styled.InputLabel>
                      )}
                      {inputType === 'outlined_input' && (
                        <OutlinedInput
                          {...register(
                            `${fieldArrayName}.${controlIndex}.${fieldName}` as const,
                            {
                              pattern: fieldItem?.rules,
                              required: fieldRule().required
                            }
                          )}
                          {...rest}
                          value={value}
                          name={`${fieldArrayName}[${controlIndex}]${fieldName}`}
                          error={fieldError}
                          disabled={
                            disabledDisplay ?? dependentFieldRequired === false
                          }
                          fullWidth
                          data-testid={`${fieldArrayName}-${fieldName}-${controlIndex}`}
                        />
                      )}
                      {inputType === 'text_area' && (
                        <Styled.Textfield
                          {...register(
                            `${fieldArrayName}.${controlIndex}.${fieldName}` as const,
                            fieldRule()
                          )}
                          {...rest}
                          value={value}
                          name={`${fieldArrayName}[${controlIndex}]${fieldName}`}
                          error={fieldError}
                          multiline
                          fullWidth
                          sx={{
                            height: 'auto'
                          }}
                          data-testid={`${fieldArrayName}-${fieldName}-${controlIndex}`}
                        />
                      )}
                      {inputType === 'select' && (
                        <Select
                          {...register(
                            `${fieldArrayName}.${controlIndex}.${fieldName}` as const,
                            fieldRule()
                          )}
                          {...rest}
                          disabled={disabledField}
                          value={selectValue}
                          name={`${fieldArrayName}[${controlIndex}]${fieldName}`}
                          defaultValue=""
                          fullWidth
                          displayEmpty
                          error={fieldError}
                          sx={{
                            typography: (theme as Theme).typography.EC_TYPE_BASE
                          }}
                          onClose={
                            onSelectMenuClose &&
                            ((): void => onSelectMenuClose(controlIndex))
                          }
                          data-testid={`${fieldArrayName}-${fieldName}-${controlIndex}`}
                          renderValue={(selected): React.ReactNode => {
                            const selectedValue = getSelectValue(selected);

                            return (
                              <>
                                {getSelectLabel(selectedValue, _selectOptions)}
                              </>
                            );
                          }}
                        >
                          {_selectOptions.map((item) => (
                            <MenuItem
                              key={item.label}
                              value={item.value}
                              data-testid={`${fieldName}-dropdown`}
                            >
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                      {inputType === 'date_picker' && (
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                          {!datePickerRequired &&
                          datePickerNotRequiredText &&
                          dependentFieldRequired === false ? (
                            <OutlinedInput
                              {...register(
                                `${fieldArrayName}.${controlIndex}.${fieldName}` as const,
                                fieldRule()
                              )}
                              {...rest}
                              data-testid={`${fieldArrayName}-${fieldName}-disabledPlaceholder-${controlIndex}`}
                              name={`${fieldArrayName}[${controlIndex}]${fieldName}`}
                              disabled={true}
                              placeholder={datePickerNotRequiredText}
                              error={fieldError}
                              fullWidth
                            />
                          ) : (
                            <DesktopDatePicker
                              {...register(
                                `${fieldArrayName}.${controlIndex}.${fieldName}` as const,
                                fieldRule()
                              )}
                              {...rest}
                              disableFuture
                              data-testid={`${fieldArrayName}-${fieldName}-${controlIndex}`}
                              value={value ?? null}
                              inputFormat={datePickerFormat}
                              views={['year', 'month']}
                              minDate={
                                getMinEndDate
                                  ? getMinEndDate(controlIndex)
                                  : datePickerMinDate
                              }
                              renderInput={(params): JSX.Element => (
                                <TextField
                                  inputProps={{
                                    'data-testid': `${fieldArrayName}-${fieldName}-textField-${controlIndex}`
                                  }}
                                  {...params}
                                  autoComplete="off"
                                  error={fieldError}
                                  fullWidth
                                />
                              )}
                            />
                          )}
                        </LocalizationProvider>
                      )}
                      {inputType === 'check_box' && !!onCheckboxChecked && (
                        <Styled.CheckboxBtn
                          {...register(
                            `${fieldArrayName}.${controlIndex}.${fieldName}` as const
                          )}
                          {...rest}
                          data-testid={`${fieldArrayName}-${fieldName}-${controlIndex}`}
                          name={`${fieldArrayName}[${controlIndex}]${fieldName}`}
                          variant="text"
                          startIcon={
                            <i
                              className={
                                !value
                                  ? 'ri-checkbox-blank-line'
                                  : 'ri-checkbox-line'
                              }
                            />
                          }
                          onClick={(): void => onCheckboxChecked(controlIndex)}
                        >
                          {checkBoxText}
                        </Styled.CheckboxBtn>
                      )}
                      {errors?.[fieldArrayName]?.[controlIndex]?.[fieldName]
                        ?.message && (
                        <Styled.ErrorText
                          variant="EC_TYPE_2XS"
                          data-testid={`${fieldArrayName}-${fieldName}-error-message`}
                        >
                          {
                            errors[fieldArrayName][controlIndex][fieldName]
                              .message
                          }
                        </Styled.ErrorText>
                      )}
                    </Styled.FieldContainer>
                  );
                }}
              />
            );
          })}
        </Styled.GridContainer>
      ))}
      <FormFieldControlButtons
        name={`${fieldArrayName}Count`}
        addBtnText={addBtnText}
        onClickAdd={addNewFieldObject}
        onClickNA={handleNotApplicableClick}
        register={register}
        notApplicable={!fieldArrayRequired}
      />
    </Box>
  );
};

export default ReactHookFormsFieldArray;
