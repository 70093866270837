import * as React from 'react';
import { Box, ClickAwayListener, IconButton, Typography } from '@mui/material';
import { keyframes, styled } from '@mui/system';
import { DateTime } from 'luxon';
import EllipseButton from '@components/EllipseButton';
import { getCityState } from '@common/helpers/location';
import { GRAY_1 } from '@themes/ui/escoffier';
import IconWithLabel from '@components/IconWithLabel';
import { JobPostBase } from '@api/models/singleJobPostApi.models';
import { Location } from '@interfaces/location.interfaces';

const moveAnimation = keyframes`
  from {
    background-color: ${GRAY_1};
  }
  to {
    background-color: white;
  }
`;

const Styled = {
  Root: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'moved'
  })<{ moved: boolean }>(({ moved, theme }) => ({
    display: 'flex',
    columnGap: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 8px 16px 12px',
    animation: moved ? `${moveAnimation} 1.2s` : 'none',
    border: `1px solid ${theme.palette.GRAY_1.main}`,
    ':hover': {
      backgroundColor: theme.palette.GRAY_2.main,
      transition: 'background-color 0.5s ease'
    }
  })),
  MainContent: styled(Box)({
    minWidth: '40px'
  }),
  CardTitle: styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    marginBottom: '8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  })),
  JobDetailsWrapper: styled(Box)({
    display: 'flex',
    columnGap: '16px'
  })
};

type MoreOptions = {
  ariaLabel: string;
  icon: string;
  onClick: () => void;
  disabled?: boolean;
};

interface Props {
  job: JobPostBase;
  hideMoveDown: boolean;
  hideMoveUp: boolean;
  hasMoved: boolean;
  handleMoveDown: (id: number) => Promise<void>;
  handleMoveUp: (id: number) => Promise<void>;
  handleDelete: (id: number) => Promise<void>;
}

const HotJobItem: React.FC<Props> = ({
  job,
  hideMoveDown,
  hideMoveUp,
  hasMoved,
  handleMoveDown,
  handleMoveUp,
  handleDelete
}) => {
  const [showOptions, setShowOptions] = React.useState<boolean>(false);
  const { id, jobTitle, locations, company, createdAt } = job;

  const getLocationsLabel = (locations: Location[]): string => {
    if (locations.length > 1) {
      return 'Multiple Locations';
    }

    return getCityState(locations[0]);
  };

  const moreOptionClick = (handleClick: any): (() => void) => {
    return (): void => {
      handleClick(id);
      setShowOptions(false);
    };
  };

  const handleClickAway = (): void => {
    setShowOptions(false);
  };

  const jobDetails = [
    { icon: 'ri-map-pin-2-fill', label: getLocationsLabel(locations) },
    {
      icon: 'ri-calendar-event-fill',
      label: DateTime.fromISO(createdAt).toLocaleString(DateTime.DATE_MED)
    }
  ];

  const moreOptions: MoreOptions[] = [
    {
      ariaLabel: 'delete',
      icon: 'ri-arrow-down-line',
      onClick: moreOptionClick(handleMoveDown),
      disabled: hideMoveDown
    },
    {
      ariaLabel: 'delete',
      icon: 'ri-arrow-up-line',
      onClick: moreOptionClick(handleMoveUp),
      disabled: hideMoveUp
    },
    {
      ariaLabel: 'delete',
      icon: 'ri-delete-bin-6-line',
      onClick: moreOptionClick(handleDelete)
    }
  ];

  return (
    <Styled.Root moved={hasMoved}>
      <Styled.MainContent>
        <Styled.CardTitle variant="EC_TYPE_SM">
          {jobTitle} - {company.name}
        </Styled.CardTitle>
        <Styled.JobDetailsWrapper>
          {jobDetails.map((detail) => (
            <React.Fragment key={detail.label}>
              {detail.label && (
                <IconWithLabel
                  key={detail.label}
                  smallest
                  title={detail.label}
                  icon={detail.icon}
                />
              )}
            </React.Fragment>
          ))}
        </Styled.JobDetailsWrapper>
      </Styled.MainContent>
      <Box>
        {showOptions ? (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box sx={{ width: hideMoveDown || hideMoveUp ? '81px' : '121px' }}>
              {moreOptions.map(
                (opt) =>
                  !opt?.disabled && (
                    <IconButton
                      key={opt.icon}
                      aria-label={opt.ariaLabel}
                      onClick={opt.onClick}
                      disabled={!!opt?.disabled}
                    >
                      <i className={opt.icon} />
                    </IconButton>
                  )
              )}
            </Box>
          </ClickAwayListener>
        ) : (
          <Box sx={{ minWidth: '40px' }}>
            <EllipseButton
              iconClassName="ri-more-2-line"
              onClick={(): void => setShowOptions(!showOptions)}
            />
          </Box>
        )}
      </Box>
    </Styled.Root>
  );
};

export default HotJobItem;
