/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  redirectStorageMessages,
  useStateWithLocalStorage
} from '@common/helpers/storage';
import PrimaryModal from '@components/PrimaryModal';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

export interface ModalProps {
  open: boolean;
  title: string;
  subtitle: string;
  handleClose: () => void;
  secondaryClick: () => void;
  localStorageMessage: string;
  localStorageKey: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  primaryClickLocation: string | (() => void);
  secondarySubtitle?: string;
  isCandidateProfileComplete?: boolean;
  isEmployerProfileComplete?: boolean;
  ignoreIncompleteProfile?: boolean;
  keepModalOpen?: boolean;
  customRedirectRoute?: string;
  tertiaryCtaPath?: string;
  tertiaryButtonText?: string;
}

type LocalStorageData = {
  redirect: string;
  message: string;
  expiration: number;
};

const MINUTE = 60 * 1000; // 1 minute

const IncompleteProfileModal: React.FC<ModalProps> = ({
  open,
  handleClose,
  title,
  subtitle,
  secondarySubtitle,
  isCandidateProfileComplete,
  isEmployerProfileComplete,
  ignoreIncompleteProfile = false,
  keepModalOpen = false,
  localStorageMessage,
  localStorageKey,
  primaryButtonText,
  secondaryButtonText,
  primaryClickLocation,
  secondaryClick,
  customRedirectRoute,
  tertiaryButtonText,
  tertiaryCtaPath
}) => {
  const history = useHistory();

  const profileIncomplete =
    !isCandidateProfileComplete || !isEmployerProfileComplete;

  const { setStoredValue } = useStateWithLocalStorage(localStorageKey);
  // Expiration & time to live for localStorage data
  const expiry = new Date();

  const timeToLive: number =
    localStorageMessage === redirectStorageMessages.NO_USER
      ? 5 * MINUTE
      : 15 * MINUTE;

  const localStorageData: LocalStorageData = {
    redirect: customRedirectRoute ?? window.location.pathname,
    message: localStorageMessage,
    expiration: expiry.getTime() + timeToLive
  };

  React.useEffect(() => {
    if (open) {
      if (ignoreIncompleteProfile) {
        setStoredValue(JSON.stringify(localStorageData));
      }

      if (profileIncomplete) {
        setStoredValue(JSON.stringify(localStorageData));
      } else {
        localStorage.removeItem(localStorageKey);
      }
    }
  }, [open]);

  const handleCloseModal = (): void => {
    if (keepModalOpen) {
      return;
    }
    handleClose();
  };

  const handlePrimaryClick = (): void => {
    if (typeof primaryClickLocation === 'string') {
      history.push(primaryClickLocation);
    } else {
      primaryClickLocation();
    }
  };

  return (
    <PrimaryModal
      dataTestId="incomplete-profile-modal"
      keepModalOpen={keepModalOpen}
      handleClose={handleCloseModal}
      open={open}
      title={title}
      handlePrimaryAction={handlePrimaryClick}
      primaryCtaLabel={primaryButtonText}
      handleSecondaryAction={secondaryClick}
      secondaryCtaLabel={secondaryButtonText}
      tertiaryCtaPath={tertiaryCtaPath}
      tertiaryCtaLabel={tertiaryButtonText}
    >
      <Typography variant="EC_TYPE_BASE">{subtitle}</Typography>
      {secondarySubtitle && (
        <Typography
          sx={{ marginTop: '12px' }}
          variant="EC_TYPE_BASE"
          className="subtext"
        >
          {secondarySubtitle}
        </Typography>
      )}
    </PrimaryModal>
  );
};

export default IncompleteProfileModal;
