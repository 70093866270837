/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { CandidateNotesInterface } from '@api/models/careerServicesApi.models';
import { CareerServicesApi } from '@api/CareerService.api';

export type FetchCandidateNotes = {
  candidateNotes: CandidateNotesInterface;
  loaded: boolean;
  loading: boolean;
  fetchCandidateNotes: () => {};
};

export const useFetchCandidateNotes = (
  candidateId: string
): FetchCandidateNotes => {
  const [candidateNotes, setCandidateNotes] =
    React.useState<CandidateNotesInterface>({} as CandidateNotesInterface);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchCandidateNotes = async (): Promise<void> => {
    if (!candidateId) {
      return;
    }

    try {
      setLoading(true);
      const res = await CareerServicesApi.getCandidateNotes(candidateId);
      setCandidateNotes(res);
      setLoaded(true);
    } catch (error: any) {
      console.error(
        'Error for useFetchCandidateNotes -> CareerServicesApi.getCandidateNotes(candidateId)',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    fetchCandidateNotes();
  }, []);

  return {
    candidateNotes,
    loaded,
    loading,
    fetchCandidateNotes
  };
};
