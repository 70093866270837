import { useEffect, useState } from 'react';
import { JobPost } from '@api/models/singleJobPostApi.models';
import { JobPostApi } from '@api/JobPost.api';

type FetchJobPostById = {
  jobPost: JobPost | null;
  isOwner: boolean;
  loading: boolean;
  loaded: true | null;
};

/**
 * Fetch JobPost from JobPostApi.getJobPostById()
 *
 * @param {string} id
 * @returns `{ jobPost: JobPost; isOwner: boolean; loading: boolean; }`
 */
export const useFetchJobPostById = (jobId: string): FetchJobPostById => {
  const [jobPost, setJobPost] = useState<JobPost | null>(null);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  useEffect(() => {
    const fetchJobPost = async (): Promise<void> => {
      try {
        setLoading(true);
        const res = await JobPostApi.getJobPostById(jobId);

        setJobPost(res.data);
        setIsOwner(!!res.owner);
      } catch (error: any) {
        console.error(
          'Error for useFetchJobPostById -> JobPostApi.getJobPostById()',
          error
        );
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    };

    fetchJobPost();
  }, []);

  return { jobPost, isOwner, loading, loaded };
};
