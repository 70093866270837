import * as React from 'react';
import { FileType } from '@api/models/fileApi.models';
import ResumeSection from './ResumeSection';

const styles = {
  photosWrapper: {
    display: 'flex',
    columnGap: '16px',
    rowGap: '16px',
    flexWrap: 'wrap' as 'wrap'
  },
  photo: {
    height: '70px',
    width: '70px',
    borderRadius: '4px',
    objectFit: 'cover' as 'cover',
    objectPosition: 'center'
  }
};

interface Props {
  candidateCulinaryPhotos: FileType[];
}

const ResumePhotoSection: React.FC<Props> = ({ candidateCulinaryPhotos }) => {
  const truncatedPhotos = candidateCulinaryPhotos.slice(0, 16);

  if (!truncatedPhotos.length) {
    return <></>;
  }
  return (
    <ResumeSection title="Photos" indent>
      <div style={styles.photosWrapper}>
        {truncatedPhotos.map((photo) => {
          const hidden = photo['hidden'] === true;

          if (!hidden) {
            return (
              <img
                key={photo.id}
                src={photo.fullPath}
                style={styles.photo}
                data-testid="resume-photo-section-img"
              />
            );
          }
        })}
      </div>
    </ResumeSection>
  );
};

export default ResumePhotoSection;
