import * as React from 'react';
import { Box, Skeleton } from '@mui/material';
import { mediaQueries } from '@themes/ui/variables';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)({
    marginTop: '24px'
  }),
  PaddingCardBox: styled(Box)({
    padding: '0 10px',
    [mediaQueries.MIN_WIDTH_361]: {
      padding: '0 16px'
    },
    [mediaQueries.MIN_WIDTH_481]: {
      padding: '0 32px'
    },
    [mediaQueries.MIN_WIDTH_601]: {
      padding: '0 65px'
    }
  }),
  NoPaddingCardBox: styled(Box)({
    padding: 0,
    marginTop: '36px',
    [mediaQueries.MIN_WIDTH_601]: {
      padding: '0 65px'
    }
  })
};

const CareerPathSkeleton = (): React.ReactElement => {
  return (
    <>
      <Styled.PaddingCardBox>
        <Skeleton height="500px" sx={{ transform: 'none' }} />
      </Styled.PaddingCardBox>
      <Styled.NoPaddingCardBox>
        <Skeleton height="400px" sx={{ transform: 'none' }} />
      </Styled.NoPaddingCardBox>
      <Styled.NoPaddingCardBox>
        <Skeleton height="135px" sx={{ transform: 'none' }} />
      </Styled.NoPaddingCardBox>
    </>
  );
};

export default CareerPathSkeleton;
