import * as React from 'react';
import Box from '@mui/material/Box';
import { createCustomContext } from '@common/context/customContext';
import { styled } from '@mui/system';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

interface ContainerProps {
  displayGutters: boolean;
  customBreakpoint: number;
}

const MobileContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'displayGutters' && prop !== 'customBreakpoint'
})(({ displayGutters, customBreakpoint }: ContainerProps) => ({
  maxWidth: `${customBreakpoint}px`,
  margin: '0 auto',
  height: '100%',
  padding: displayGutters ? '20px 20px' : 0
}));

interface Props {
  mobileComponent: React.ReactNode;
  desktopComponent: React.ReactNode;
  customBreakpoint: number;
  displayGutters?: boolean;
  useMobileContainer?: boolean;
}

interface ProviderProps {
  children: React.ReactNode;
  customBreakpoint: number;
}

export type ResponsiveContextType = {
  customBreakpointReached: boolean;
  customBreakpoint: number;
};

/**
 * This context can be used with useResponsiveLayoutContext()
 * Useful for getting customBreakpoint value and customBreakpointReached in nested components
 */
export const [useResponsiveLayoutContext, ResponsiveContextProvider] =
  createCustomContext<ResponsiveContextType>();

/**
 * Simple wrapper used for passing customBreakpointReached and customBreakpoint from parent to children components.
 */
export const ResponsiveLayoutProvider: React.FC<ProviderProps> = ({
  children,
  customBreakpoint
}) => {
  const { width } = useWindowDimensions();
  const customBreakpointReached: boolean = width <= customBreakpoint;

  return (
    <ResponsiveContextProvider
      value={{ customBreakpointReached, customBreakpoint }}
    >
      {children}
    </ResponsiveContextProvider>
  );
};

/**
 * Renders a component based on the width of the window
 * @param mobileComponent component rendered when window is less than or equal to customBreakpoint
 * @param desktopComponent component rendered when window is greater than customBreakpoint
 * @param customBreakpoint the window width at which the mobileComponent should render (360 by default)
 * @param mobileGutters optional boolean to toggle horizontal padding on or off. Default true.
 * @param useMobileContainer optional booelan to toggle use of mobile container styles
 * @returns The component dependant on window width
 */
const ResponsiveLayout: React.FC<Props> = ({
  mobileComponent,
  desktopComponent,
  customBreakpoint,
  displayGutters = true,
  useMobileContainer = false
}) => {
  const { width } = useWindowDimensions();
  const customBreakpointReached: boolean = width <= customBreakpoint;

  return (
    <ResponsiveContextProvider
      value={{ customBreakpointReached, customBreakpoint }}
    >
      {customBreakpointReached ? (
        <>
          {useMobileContainer ? (
            <MobileContainer
              data-testid="mobile-container"
              customBreakpoint={customBreakpoint}
              displayGutters={displayGutters}
            >
              {mobileComponent}
            </MobileContainer>
          ) : (
            <>{mobileComponent}</>
          )}
        </>
      ) : (
        <React.Fragment>{desktopComponent}</React.Fragment>
      )}
    </ResponsiveContextProvider>
  );
};

export default React.memo(ResponsiveLayout);
