/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Box, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  FeatureFlagConfig,
  useFeatureFlags
} from '@common/hooks/useFeatureFlags';
import NextStepCard, { NextStep } from './NextStepCard';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import CareerMarketplaceImage from '@assets/CareerPathPage/career_marketplace.jpg';
import EscoffierCulinaryEducationImage from '@assets/CareerPathPage/escoffier_culinary_education.jpg';
import { styled } from '@mui/system';
import WorkAndLearnImage from '@assets/CareerPathPage/work_and_learn.jpg';

const Styled = {
  Root: styled(Box)({
    marginBottom: '120px'
  }),
  TopContent: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isMobile'
  })<{ isMobile: boolean }>(({ isMobile }) => ({
    marginBottom: '54px',
    padding: isMobile ? '28px' : 'unset'
  })),
  SectionTitle: styled(Typography)(({ theme }) => ({
    fontSize: (theme as Theme).typography.EC_TYPE_5XL.fontSize,
    lineHeight: (theme as Theme).typography.EC_TYPE_5XL.lineHeight,
    color: theme.palette.BLACK.main,
    textTransform: 'uppercase',
    marginBottom: '46px'
  }))
};

const nextStepsContent = (featureFlags: FeatureFlagConfig): NextStep[] => [
  {
    backgroundImage: EscoffierCulinaryEducationImage,
    title: 'Escoffier Culinary Education',
    content: (
      <div>
        <p>
          Whatever your culinary or hospitality goals, Escoffier can help you
          get you there faster with the flexibility of online or on-campus
          learning. Our online programs are built for working adults with
          flexible, one-on-one access to our esteemed Chef Instructors. You can
          also attend in-person at one of our campuses in Austin, Texas, or
          Boulder, Colorado.
        </p>
        <p>
          How do you learn the culinary arts online? Each week you will be
          assigned reading for a featured technique. Step-by-step videos will be
          available for you to see how to do the technique. You will interact
          with chef instructors and other students in weekly live sessions, as
          well as in online discussion boards. Finally, you will execute the
          technique, taking pictures and submitting required documents for
          grading. You will complete the program with an externship in a
          professional kitchen.
        </p>
      </div>
    ),
    cta: {
      label: 'Get Started',
      externalLink: 'https://www.escoffier.edu/'
    }
  },
  {
    backgroundImage: WorkAndLearnImage,
    title: 'Work & Learn',
    subtitle: 'Get an education WHILE you work',
    content: (
      <div>
        <p>
          Work for an employer who knows the value of education. Escoffier has
          employer partners across the country that will help you get hands on
          learning and a degree. Our online Work & Learn program teaches
          everything from fundamentals to modern culinary innovation and
          business skills. Plus, it&apos;s designed to fit into your busy
          schedules, so you won&apos;t miss a beat.
        </p>
      </div>
    ),
    cta: featureFlags.WORK_LEARN_CANDIDATE_PAGE
      ? {
          label: 'Learn more',
          path: '/candidate/work-and-learn'
        }
      : undefined
  },
  {
    backgroundImage: CareerMarketplaceImage,
    title: 'EConnect',
    subtitle: 'Find a job with one of our premier partners',
    content: (
      <div>
        <p>
          Escoffier has employer partners across the country that are looking
          for candidates in the culinary industry. Use the resume builder to
          create a profissional resume from your user profile. Search and filter
          jobs and apply directly to top employers that value culinary
          education.
        </p>
      </div>
    ),
    cta: {
      label: 'Learn more',
      path: '/econnect'
    }
  }
];

const NextStepsSection: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const featureFlags = useFeatureFlags();

  return (
    <Styled.Root>
      <Styled.TopContent isMobile={isMobile}>
        <Styled.SectionTitle variant="EC_TYPE_3XL">
          Take the Next Steps
        </Styled.SectionTitle>
        <Typography variant="EC_TYPE_BASE">
          There are many ways to kickstart your culinary career. You could jump
          into the industry by applying for a job right away or try a more
          methodical approach by getting a formal education. Click the options
          below to learn more about career path options available through
          Escoffier and start on your culinary career path!
        </Typography>
      </Styled.TopContent>
      <BottomMarginContentWrapper bottomMargin={'60px'}>
        {nextStepsContent(featureFlags).map((step) => (
          <React.Fragment key={step.title}>
            <NextStepCard step={step} isMobile={isMobile} />
          </React.Fragment>
        ))}
      </BottomMarginContentWrapper>
    </Styled.Root>
  );
};

export default NextStepsSection;
