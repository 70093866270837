/* eslint-disable @typescript-eslint/indent  */
import * as React from 'react';
import {
  DataGridProProps,
  GridColDef,
  GridRowsProp,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';
import { allowedGridFilterOperators } from '@common/fetches/useFetchGridTableByType';
import ApplicantsModal from '@pages/EcAdmin/AdminDashboardPage/ApplicantsModal/ApplicantsModal';
import { Box } from '@mui/material';
import CompaniesStatusDetailPanel from '@pages/EcAdmin/ManageCompaniesPage/CompaniesStatusDetailPanel';
import CompanyGlrcAccessModal from './CompanyGlrcAccessModal';
import { CompanyStatus } from '@api/models/adminDashboardApi.models';
import CsvButton from '@components/CsvButton';
import { DateRange } from '@common/models/common.models';
import EcDataGrid from '@components/DataGrid/EcDataGrid';
import { GlrcAccesses } from '@api/models/glrcAccess.models';
import JobPostsModal from '@pages/EcAdmin/AdminDashboardPage/JobPostsModal/JobPostsModal';
import { PartnershipLevel } from '@api/permissions/companyPartnershipLevel.permissions';
import { renderCellValueButton } from '@components/DataGrid/CellRenderers/renderCellValueButton';
import { renderCompanyProfileLink } from '@components/DataGrid/CellRenderers/renderCompanyProfileLink';
import { renderDropdown } from '@components/DataGrid/CellRenderers/renderDropdown';
import { renderOptionsMenu } from '@components/DataGrid/CellRenderers/renderOptionsMenu';
import { renderParentCompanyCell } from '@components/DataGrid/CellRenderers/renderParentCompanyCell';
import { styled } from '@mui/system';
import { updatePartnershipLevel } from '@common/helpers/manageCompaniesTableHelpers';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchAdminStats } from '@common/fetches/useFetchAdminStats';
import { useFetchCompaniesCsv } from '@common/fetches/csv/useFetchCompaniesCsv';
import { useFetchCompaniesUsersCsv } from '@common/fetches/csv/useFetchCompaniesUsersCsv';

const Styled = {
  CsvButtonsContainer: styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '1rem',
    '& > *:not(:last-child)': {
      marginRight: '1rem'
    }
  })
};

const dateRange: DateRange = {
  startDate: null,
  endDate: null
};

const initModalState = {
  companyId: null,
  companyName: null,
  modalType: null
};

type CompanyStatusRowModalType =
  | 'applicants'
  | 'jobPosts'
  | 'glrcAccess'
  | null;

export interface GlrcAccessData {
  glrcAccess: GlrcAccesses[];
  customThemeAccess: boolean;
}

type ModalState = {
  companyId: number | null;
  companyName: string | null;
  modalType: CompanyStatusRowModalType;
};

interface Props {
  companies: CompanyStatus[];
}

const CustomToolbar = (): JSX.Element => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          allColumns: true
        }}
        printOptions={{ disableToolbarButton: true }}
      />
    </GridToolbarContainer>
  );
};

const CompaniesStatusTable: React.FC<Props> = ({ companies: _companies }) => {
  const { stats } = useFetchAdminStats();
  const applicationsByEmployers = stats?.applications.applicationsByEmployers;

  const [companies, setCompanies] = React.useState<CompanyStatus[]>(_companies);
  const [glrcAccessData, setGlrcAccessData] = React.useState<GlrcAccessData>();
  const [glrcSeats, setGlrcSeats] = React.useState<number>();
  const [modalState, setModalState] =
    React.useState<ModalState>(initModalState);
  const applicantModalOpen = Boolean(
    modalState.companyId &&
      modalState.companyName &&
      modalState.modalType === 'applicants'
  );
  const jobPostsModalOpen = Boolean(
    modalState.companyId &&
      modalState.companyName &&
      modalState.modalType === 'jobPosts'
  );
  const glrcAccessModalOpen = Boolean(
    modalState.companyId &&
      modalState.companyName &&
      modalState.modalType === 'glrcAccess'
  );

  const { fetchCompaniesCsv } = useFetchCompaniesCsv();
  const { fetchCompaniesUsersCsv } = useFetchCompaniesUsersCsv();

  const featureFlags = useFeatureFlags();

  const handleOpenModal = (
    companyId: number,
    companyName: string,
    modalType: CompanyStatusRowModalType
  ): void => {
    setModalState({
      companyId,
      companyName,
      modalType
    });
  };

  const handleOpenGlrcAccessModal = (companyId: number): void => {
    const company = companies.find((c) => c.id === companyId);
    setGlrcAccessData({
      glrcAccess: company?.glrcAccess ?? [],
      customThemeAccess: !!company?.customThemeAccess
    });
    setGlrcSeats(company?.glrcSeats);
    handleOpenModal(companyId, company?.companyName ?? '', 'glrcAccess');
  };

  const handleCloseGlrcAccessModal = (companyId?: number): void => {
    if (companyId) {
      setCompanies((prev) => {
        return prev.map((company) => {
          if (company.id === companyId) {
            return {
              ...company,
              customThemeAccess:
                glrcAccessData?.customThemeAccess ?? company.customThemeAccess,
              glrcAccess: glrcAccessData?.glrcAccess ?? company.glrcAccess,
              glrcSeats: glrcSeats ?? company.glrcSeats
            };
          }

          return company;
        });
      });
    }
    setGlrcAccessData(undefined);
    setGlrcSeats(undefined);
    setModalState(initModalState);
  };

  const handleCloseApplicantModal = (): void => {
    setModalState(initModalState);
  };

  const getGlrcAccessColumns = (): { label: string; key: string }[] => {
    if (companies.length) {
      return Object.keys(companies[0].csvGlrcAccess).map((key) => ({
        label: key,
        key: `csvGlrcAccess.${key}`
      }));
    }
    return [];
  };

  // TODO: are these csv arrays needed now that DataGrid has an Export button?
  const csvHeaders = [
    { label: 'Company Id', key: 'id' },
    { label: 'Company Name', key: 'companyName' },
    { label: 'Company Phone', key: 'phone' },
    { label: 'Admin Email', key: 'adminEmail' },
    { label: 'Admin Phone', key: 'adminPhone' },
    { label: 'Job Posts', key: 'activeJobs' },
    { label: 'Applicants', key: 'applicants' },
    { label: 'Primary', key: 'primary' },
    { label: 'Work & Learn', key: 'workLearnPartner' },
    { label: 'Active', key: 'enabled' },
    { label: 'Date Joined', key: 'createdAt' },
    { label: 'Partnership Level', key: 'partnershipLevelName' },
    { label: 'GLRC Seats Purchased', key: 'glrcSeats' },
    { label: 'GLRC Seats Remaining', key: 'glrcSeatsRemaining' },
    ...getGlrcAccessColumns()
  ];

  const csvHeadersUsers = [
    { label: 'Company Id', key: 'companyId' },
    { label: 'Company Name', key: 'companyName' },
    { label: 'Company Enabled', key: 'companyEnabled' },
    { label: 'Company Phone', key: 'companyPhone' },
    { label: 'User Id', key: 'userId' },
    { label: 'User First Name', key: 'userFirstName' },
    { label: 'User Last Name', key: 'userLastName' },
    { label: 'User Email', key: 'userEmail' },
    { label: 'User Phone', key: 'userPhone' },
    { label: 'User Role', key: 'userRole' },
    { label: 'Partnership Level', key: 'companyPartnershipLevel' }
  ];

  const columns: GridColDef[] = [
    {
      field: 'companyName',
      headerName: 'Company',
      minWidth: 150,
      flex: 1.25,
      renderCell: (params): JSX.Element => renderCompanyProfileLink(params)
    },
    {
      field: 'id',
      headerName: 'ID',
      width: 60,
      flex: 0
    },
    {
      field: 'activeJobs',
      headerName: 'Posts',
      minWidth: 75,
      maxWidth: 90,
      flex: 1,
      renderCell: (params): JSX.Element =>
        renderCellValueButton(params, (): void =>
          handleOpenModal(
            params.id as number,
            params.row.companyName,
            'jobPosts'
          )
        )
    },
    {
      field: 'applicants',
      headerName: 'Applicants',
      minWidth: 75,
      maxWidth: 110,
      flex: 1,
      renderCell: (params): JSX.Element =>
        renderCellValueButton(params, (): void =>
          handleOpenModal(
            params.id as number,
            params.row.companyName,
            'applicants'
          )
        )
    },
    {
      field: 'glrcSeats',
      headerName: 'ESource Access',
      minWidth: 75,
      maxWidth: 155,
      flex: 1,
      renderCell: (params): JSX.Element =>
        renderCellValueButton(
          params,
          (): void => handleOpenGlrcAccessModal(params.id as number),
          true,
          true
        )
    },
    {
      field: 'workLearnPartner',
      headerName: 'Work&Learn',
      minWidth: 75,
      maxWidth: 155,
      flex: 1,
      valueGetter: (params): string =>
        params.row.workLearnPartner ? 'Partner' : ''
    },
    {
      field: 'enabled',
      headerName: 'Activity',
      valueGetter: (params): string =>
        params.value === true ? 'Active' : 'Inactive',
      minWidth: 80,
      maxWidth: 100,
      flex: 1
    },
    {
      field: 'settings',
      headerName: 'Settings',
      minWidth: 130,
      flex: 1,
      valueGetter: (params): string | undefined => {
        if (params.row.value === PartnershipLevel.FreeConnect) {
          return 'Free Connect';
        }
        if (params.row.value === PartnershipLevel.GoldCrown) {
          return 'Gold Crown';
        }
      },
      renderCell: (params): JSX.Element =>
        renderDropdown(updatePartnershipLevel, setCompanies, params)
    },
    {
      field: '',
      headerName: '',
      align: 'center',
      minWidth: 28,
      flex: 0,
      renderCell: (params): JSX.Element =>
        renderOptionsMenu(params, setCompanies)
    }
  ];

  const parentCompanyColumn: GridColDef = {
    field: 'parentCompany',
    headerName: 'Primary',
    minWidth: 150,
    flex: 1.25,
    renderCell: (params): JSX.Element => renderParentCompanyCell(params)
  };

  const renderColumns = React.useMemo(() => {
    const cols = [...columns];
    if (featureFlags.PARENT_CHILD_RELATION) {
      cols.splice(5, 0, parentCompanyColumn);
    }

    const columnsWithOperators = cols.map((c) => ({
      ...c,
      filterOperators: allowedGridFilterOperators
    }));

    return columnsWithOperators;
  }, [featureFlags.PARENT_CHILD_RELATION, columns]);

  const companyHasSubsidiariesOtherThanSelf = (
    company: CompanyStatus
  ): boolean => {
    if (company.subsidiaries?.length && company.subsidiaries?.length > 1) {
      return true;
    }

    if (company.subsidiaries?.length === 1) {
      return company.subsidiaries[0].id !== company.id;
    }

    return false;
  };

  const companyMap = {};

  const rows: GridRowsProp = React.useMemo(() => {
    return companies.map((c) => {
      const row: { [key: string]: any } = {
        id: c.id,
        value: '',
        settings: '',
        company: c,
        applications:
          applicationsByEmployers?.find((a) => a.companyId === c.id) ?? null,
        workLearnPartner: c.workLearnPartner
      };

      renderColumns.forEach((col) => {
        row[col.field as string] = c[col.field];
        row.value = c.partnershipLevel;
        row.isParent = companyHasSubsidiariesOtherThanSelf(c);
        row.possibleParent = c.possibleParent;

        if (col.field === 'settings') {
          row.settings = [
            { label: 'Free Connect', value: PartnershipLevel.FreeConnect },
            { label: 'Gold Crown', value: PartnershipLevel.GoldCrown }
          ];
        }
      });

      companyMap[c.id] = row;
      return row;
    });
  }, [companies, applicationsByEmployers, renderColumns]);

  const getDetailsPanelContent = React.useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(({ row }): React.ReactNode => {
    const { company, applications } = row;
    return (
      <CompaniesStatusDetailPanel
        company={company}
        applications={applications}
      />
    );
  }, []);

  return (
    <>
      {featureFlags.REPORTING_DASH && (
        <Styled.CsvButtonsContainer>
          <CsvButton
            headers={csvHeadersUsers}
            fileName={'companiesUsers.csv'}
            buttonText="Export emails"
            fetchData={fetchCompaniesUsersCsv}
          />
          <CsvButton
            headers={csvHeaders}
            fileName={'companies.csv'}
            fetchData={fetchCompaniesCsv}
          />
        </Styled.CsvButtonsContainer>
      )}
      <EcDataGrid
        disableVirtualization
        rowHeight={36}
        columnHeaderHeight={36}
        rows={rows}
        columns={renderColumns}
        pagination
        pageSizeOptions={[10, 25, 50, 100]}
        slots={{
          toolbar: CustomToolbar
        }}
        getDetailPanelContent={getDetailsPanelContent}
        getDetailPanelHeight={(): 'auto' => 'auto'}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 25, page: 0 }
          }
        }}
      />
      {applicantModalOpen && (
        <ApplicantsModal
          open={applicantModalOpen}
          dateRange={dateRange}
          handleClose={handleCloseApplicantModal}
          companyId={modalState.companyId!}
          companyName={modalState.companyName!}
        />
      )}
      {jobPostsModalOpen && (
        <JobPostsModal
          open={jobPostsModalOpen}
          dateRange={dateRange}
          handleClose={handleCloseApplicantModal}
          companyId={modalState.companyId!}
          companyName={modalState.companyName!}
        />
      )}
      {glrcAccessModalOpen && (
        <CompanyGlrcAccessModal
          open={glrcAccessModalOpen}
          handleClose={handleCloseGlrcAccessModal}
          companyId={modalState.companyId!}
          glrcAccessData={glrcAccessData!}
          setGlrcAccessData={setGlrcAccessData}
          glrcSeats={glrcSeats!}
          setGlrcSeats={setGlrcSeats}
        />
      )}
    </>
  );
};

export default CompaniesStatusTable;
