import * as React from 'react';
import { Box, Skeleton, Theme, Typography } from '@mui/material';
import EcDataGrid, { CustomToolbar } from '@components/DataGrid/EcDataGrid';
import ExternshipStartDateFilter, {
  useExternshipStartDateFilter
} from '@pages/CareerServices/shared/ExternshipStartDateFilter';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { chefInstructorGridColumns } from '@pages/ChefInstructor/ChefInstructoGridColumns';
import { getTimesheetGridRows } from './ChefIntructorGridRows';
import { GridType } from '@api/models/gridTableApi.models';
import { GridViewContextProvider } from '@common/context/gridVIewContext';
import GridViewSelectAndSave from '@pages/CareerServices/shared/GridViewSelectAndSave';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { styled } from '@mui/system';
import { useFetchChefInstructorTimesheets } from '@common/fetches/useFetchChefInstructorTimesheet';
import { useFetchGridViewsByType } from '@common/fetches/useFetchGridViewByType';
import { useGridApiRef } from '@mui/x-data-grid-pro';

const Styled = {
  Title: styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: (theme as Theme).typography.EC_TYPE_5XL.fontSize,
    lineHeight: (theme as Theme).typography.EC_TYPE_5XL.lineHeight
  })),
  TopFiltersWrapper: styled(Box)({
    display: 'flex',
    alignItems: 'flex-end',
    gap: '24px'
  })
};

const ChefInstructorDashboardPage: React.FC = () => {
  const apiRef = useGridApiRef();
  const {
    gridViews,
    setGridViews,
    loaded: gridViewsLoaded,
    calculateViewCount,
    viewCounts
  } = useFetchGridViewsByType(GridType.CHEF_TIMESHEETS);

  const { year, setYear, currDate, setCurrDate } =
    useExternshipStartDateFilter();

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 0
  });

  const { loading, chefInstructorTimesheets, fetchChefInstructorTimesheets } =
    useFetchChefInstructorTimesheets();

  React.useEffect(() => {
    if (currDate) {
      fetchChefInstructorTimesheets({
        externshipBeginDate: currDate
      });
    }
  }, [currDate]);

  React.useEffect(() => {
    if (gridViewsLoaded) {
      apiRef.current.restoreState(gridViews[0].gridState);
    }
  }, [gridViewsLoaded]);

  React.useEffect(() => {
    if (chefInstructorTimesheets && gridViews) {
      calculateViewCount(getTimesheetGridRows(chefInstructorTimesheets));
    }
  }, [chefInstructorTimesheets, gridViews]);

  return (
    <RootPageLayout muiMaxWidth={false} bgImgVariant={2}>
      <BottomMarginContentWrapper bottomMargin="48px">
        <Styled.Title variant="EC_TYPE_3XL">
          Chef Instructor Timesheet Approvals
        </Styled.Title>
        <Styled.TopFiltersWrapper>
          <ExternshipStartDateFilter
            year={year}
            setYear={setYear}
            currDate={currDate}
            setCurrDate={setCurrDate}
          />
          <GridViewContextProvider
            value={{
              gridViews,
              setGridViews,
              gridType: GridType.CHEF_TIMESHEETS,
              viewCounts,
              items: chefInstructorTimesheets
            }}
          >
            <GridViewSelectAndSave
              exportState={apiRef.current.exportState}
              restoreState={apiRef.current.restoreState}
            />
          </GridViewContextProvider>
        </Styled.TopFiltersWrapper>
        {!gridViewsLoaded ? (
          <Skeleton variant="rectangular" width="100%" height="600px" />
        ) : (
          <EcDataGrid
            apiRef={apiRef}
            rowHeight={35}
            columnHeaderHeight={35}
            rows={getTimesheetGridRows(chefInstructorTimesheets)}
            columns={chefInstructorGridColumns}
            pagination
            getRowClassName={(params): string =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[25, 50, 100]}
            slots={{ toolbar: CustomToolbar }}
            containerHeight="80vh"
            loading={loading}
          />
        )}
      </BottomMarginContentWrapper>
    </RootPageLayout>
  );
};

export default ChefInstructorDashboardPage;
