import * as React from 'react';
import {
  Box,
  FormControl,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import DeleteGridViewModal from './DeleteGridViewModal';
import { GridTableApi } from '@api/GridTable.api';
import { GridViewDto } from '@api/models/gridTableApi.models';
import SaveGridViewModal from './SaveGridViewModal';
import { styled } from '@mui/system';
import { useGridViewContext } from '@common/context/gridVIewContext';

const Styled = {
  TopFiltersWrapper: styled(Box)({
    display: 'flex',
    alignItems: 'flex-end',
    gap: '24px'
  }),
  TopFilterFormControl: styled(FormControl)({
    minWidth: '150px'
  }),
  CustomViewMenuItem: styled(MenuItem)({
    display: 'flex',
    marginRight: '-16px',
    justifyContent: 'space-between',
    '& > .right-arrow-icon': {
      visibility: 'hidden'
    },
    '&:hover': {
      '& > .right-arrow-icon': {
        visibility: 'visible'
      }
    }
  })
};

interface Props {
  exportState: () => any;
  restoreState: (gridState: any) => any;
}

const GridViewSelectAndSave: React.FC<Props> = ({
  exportState,
  restoreState
}) => {
  const { gridViews, setGridViews, gridType, viewCounts } =
    useGridViewContext();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [viewIdMenu, setViewIdMenu] = React.useState<number>();
  const [currViewId, setCurrViewId] = React.useState<number>();

  // For renaming view modal
  const [viewToRename, setViewToRename] = React.useState<GridViewDto>();

  // For deleting view modal
  const [viewToDelete, setViewToDelete] = React.useState<number>();

  // For Delete View Modal
  const [isDeleting, setIsDeleting] = React.useState(false);

  // For displaying the "Save View" Modal
  const [showViewModal, setShowViewModal] = React.useState(false);

  React.useEffect(() => {
    if (gridViews?.length && restoreState) {
      const defaultView = gridViews.find((v) => v.defaultView);

      if (!gridViews.find((v) => v.id === currViewId)) {
        setCurrViewId(defaultView?.id);
        restoreState(defaultView?.gridState);
      }
    }
  }, [gridViews, restoreState]);

  const handleViewChange = (e: SelectChangeEvent<number>): void => {
    const selectedView = e.target.value;

    if (selectedView && selectedView !== 'SAVE') {
      const currView = gridViews.find((v) => v.id === selectedView);
      setCurrViewId(Number(selectedView));
      restoreState(currView?.gridState);
    }
  };

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    view: GridViewDto
  ): void => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setViewIdMenu(view.id);
  };

  const handleCloseViewMenu = (e): void => {
    e.stopPropagation();
    setAnchorEl(null);
    setViewIdMenu(undefined);
  };

  const handleRenameView = (e, view: GridViewDto): void => {
    e.stopPropagation();
    setViewToRename(view);
    setShowViewModal(true);
  };

  const handleSaveCurrView = (e): void => {
    e.stopPropagation();
    setShowViewModal(true);
  };

  const handleSetDefaultView = async (e, view: GridViewDto): Promise<void> => {
    e.stopPropagation();

    const allViews = await GridTableApi.setGridViewDefault({
      viewId: view.id,
      type: view.type
    });

    setGridViews(allViews);
  };

  const handleDeleteViewModalClose = (e): void => {
    e.stopPropagation();
    setViewToDelete(undefined);
  };

  const handleDeleteView = async (e, viewId?: number): Promise<void> => {
    e.stopPropagation();

    if (!viewId) return;

    setIsDeleting(true);

    const allViews = await GridTableApi.delete(viewId);
    setViewToDelete(undefined);
    setIsDeleting(false);
    setGridViews(allViews);
  };

  if (!currViewId) {
    return <></>;
  }

  return (
    <Box>
      <Typography
        variant="EC_TYPE_XS"
        component={'p'}
        color={'GRAY_4.main'}
        mb={'11px'}
      >
        VIEW
      </Typography>
      <Styled.TopFilterFormControl>
        <Select
          displayEmpty
          value={currViewId}
          onChange={handleViewChange}
          renderValue={(value): JSX.Element => {
            const selectedView = gridViews.find((v) => v.id === value);

            return (
              <>
                {selectedView?.name} (
                {selectedView ? viewCounts[selectedView.id] : 0}){' '}
                {selectedView?.defaultView && ' *'}
              </>
            );
          }}
        >
          {gridViews.map((view) => (
            <Styled.CustomViewMenuItem key={view.id} value={view.id}>
              {view.name} ({viewCounts[view.id]}) {view.defaultView && ' *'}
              <IconButton
                className="right-arrow-icon"
                size="small"
                onClick={(e): void => handleClick(e, view)}
              >
                <Icon className="ri-arrow-right-s-line" />
              </IconButton>
              <Menu
                id="view-menu"
                anchorEl={anchorEl}
                open={!!anchorEl && viewIdMenu === view.id}
                onClose={handleCloseViewMenu}
              >
                <MenuItem
                  onClick={(e): Promise<void> => handleSetDefaultView(e, view)}
                >
                  Set As Default
                </MenuItem>
                {!!view.userId && (
                  <MenuItem onClick={(e): void => handleRenameView(e, view)}>
                    Rename
                  </MenuItem>
                )}
                {!!view.userId && (
                  <MenuItem
                    onClick={(e): void => {
                      e.stopPropagation();
                      setViewToDelete(view.id);
                    }}
                  >
                    Delete
                  </MenuItem>
                )}
              </Menu>
            </Styled.CustomViewMenuItem>
          ))}
          <MenuItem value="SAVE" onClick={handleSaveCurrView}>
            Save current view...
          </MenuItem>
          <MenuItem disabled value="SESSION" />
        </Select>
      </Styled.TopFilterFormControl>
      <SaveGridViewModal
        open={showViewModal}
        handleClose={(): void => {
          setShowViewModal(false);
          setViewToRename(undefined);
          setAnchorEl(null);
        }}
        viewToRename={viewToRename}
        exportState={exportState}
        gridType={gridType}
      />
      <DeleteGridViewModal
        viewToDelete={viewToDelete}
        handleClose={(e): void => handleDeleteViewModalClose(e)}
        handleDelete={(e): Promise<void> => handleDeleteView(e, viewToDelete)}
        isDeleting={isDeleting}
      />
    </Box>
  );
};

export default GridViewSelectAndSave;
