import * as React from 'react';
import {
  authorizedUserSettingsNav,
  candidateSettingsNav,
  careerServicesSettingsNav,
  companySettingsNavItem,
  employerSettingsNav,
  escoffierAdminSettingsNav,
  recruiterSettingsNav,
  timesheetApproverSettingsNav
} from '@pages/Shared/SettingsPage/SettingsNavLinks';
import { Switch, useRouteMatch } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import AccountSettings from '@pages/Shared/SettingsPage/AccountSettings/AccountSettings';
import CompanySettingsRedesign from './CompanySettingsRedesign/CompanySettingsRedesign';
import EcRoute from '@common/utilities/EcRoute';
import NestedLinkList from '@components/NestedLinkList';
import NotificationSettings from '@pages/Shared/SettingsPage/NotificationSettings/NotificationSettings';
import PageFormLayoutComponent from '@components/Layout/PageFormLayoutComponent';
import PrivateRoute from '@common/utilities/PrivateRoute';
import { KeycloakUserRole } from '@api/models/userApi.models';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchSettings } from '@common/fetches/useFetchSettings';
import { useFetchUserIdpData } from '@common/fetches/useFetchUserIdpData';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { useStore, useStoreValue } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import { NavItem } from '@components/Layout/Header/Header.models';
import { CompanyProfile } from '@api/models/companyApi.models';

const SettingsPage: React.FC = () => {

  const managedCompanies: CompanyProfile[] = useStoreValue<CompanyProfile[]>(UseStoreKeys.MANAGED_COMPANIES) ?? [];

  const featureFlags = useFeatureFlags();
  const { path } = useRouteMatch();
  const theme = useTheme();
  const isLessThanHeaderBreakpoint = useMediaQuery(
    theme.breakpoints.down('headerMobile')
  );

  const {
    isCandidate,
    isEcCareerService,
    isEmployer,
    isRecruiter,
    isEcAdmin,
    isGlrcUser,
    isChefInstructor,
    keycloakUser
  } = useKeycloakContext();

  const { userIdp, loading: idpLoading } = useFetchUserIdpData(
    keycloakUser.sub
  );

  const {
    loginSettings,
    setLoginSettings,
    loading: settingsLoading
  } = useFetchSettings();
  
  const [allKeycloakRoles] = useStore<string>(UseStoreKeys.ALL_KEYCLOAK_ROLES);
  const [navItems, setNavItems] = React.useState<Set<NavItem>>(new Set());

  React.useEffect(() => {
    let newNavItems: Set<NavItem> = new Set();

    console.debug('SettingsPage: allKeycloakRoles set to', allKeycloakRoles);
    // TODO: Adjust Account Settings and Notifications to work with
    // an EC ADMIN or Parent Company viewing children settings
    // if (isViewingAsEmployer) {
    //   const navItemsToAdd = ecAdminViewingAsEmployerNav(
    //     featureFlags,
    //     !!companyProfile?.customThemeAccess
    //   );
    //   navItems = new Set([...navItems, ...navItemsToAdd]);
    // }
    if (allKeycloakRoles.includes(KeycloakUserRole.EMPLOYER)) {
      const navItemsToAdd = employerSettingsNav();
      newNavItems = new Set([...newNavItems, ...navItemsToAdd]);
    }
    if (allKeycloakRoles.includes(KeycloakUserRole.RECRUITER)) {
      newNavItems = new Set([...newNavItems, ...recruiterSettingsNav]);
    }
    if (allKeycloakRoles.includes(KeycloakUserRole.CANDIDATE)) {
      newNavItems = new Set([...newNavItems, ...candidateSettingsNav]);
    }
    if (allKeycloakRoles.includes(KeycloakUserRole.ESCOFFIER_ADMIN)) {
      newNavItems = new Set([...newNavItems, ...escoffierAdminSettingsNav]);
    }
    if (allKeycloakRoles.includes(KeycloakUserRole.EC_CAREER_SERVICE)) {
      newNavItems = new Set([...newNavItems, ...careerServicesSettingsNav]);
    }
    if (allKeycloakRoles.includes(KeycloakUserRole.EXTERNSHIP_TIMESHEET_APPROVER)) {
      newNavItems = new Set([...newNavItems, ...timesheetApproverSettingsNav]);
    }
    if (!allKeycloakRoles.includes(KeycloakUserRole.EMPLOYER) &&
      !allKeycloakRoles.includes(KeycloakUserRole.RECRUITER) &&
      !allKeycloakRoles.includes(KeycloakUserRole.CANDIDATE) &&
      !allKeycloakRoles.includes(KeycloakUserRole.ESCOFFIER_ADMIN) &&
      !allKeycloakRoles.includes(KeycloakUserRole.EC_CAREER_SERVICE) &&
      !allKeycloakRoles.includes(KeycloakUserRole.EXTERNSHIP_TIMESHEET_APPROVER)) {
      newNavItems = new Set([...newNavItems, ...authorizedUserSettingsNav]);
    }

    // Remove Company Settings if there are no companies the current user is an admin of
    if (managedCompanies.length < 1) {
      newNavItems.delete(companySettingsNavItem);
    }

    console.debug('SettingsPage: navItems set to', newNavItems);
    setNavItems(newNavItems);
  }, [allKeycloakRoles]);

  const userHasIdp = userIdp ? userIdp.length > 0 : false;
  const isCompanyAdmin = isEmployer || isRecruiter;

  const EcAccountSettings = (): JSX.Element | null =>
    !idpLoading && !settingsLoading ? (
      <AccountSettings
        user={keycloakUser}
        isUserSSO={userHasIdp}
        isCandidate={isCandidate}
        isCompanyAdmin={isCompanyAdmin}
        isGlrcUser={isGlrcUser}
        isChefInstructor={isChefInstructor}
        isEcAdmin={isEcAdmin}
        isEcCareerService={isEcCareerService}
        loginSettings={loginSettings}
        setLoginSettings={setLoginSettings}
      />
    ) : null;

  const EcCompanySettings = (): JSX.Element | null => {
    if (!managedCompanies || managedCompanies.length === 0) {
      return <div>No Managed Companies Found</div>;
    }
    return (
      <>
        {managedCompanies.map(company => (
          <>
            <CompanySettingsRedesign
              key={company.id}
              company={company}
            />
          </>
        ))}
      </>
    );
  };

  const Settings = (
    <Switch >
      <EcRoute
        path={`${path}/account`}
        title="Account Settings"
        component={EcAccountSettings}
      />
      {managedCompanies?.length > 0 && 
        <PrivateRoute
          exact
          allowedRoles={[KeycloakUserRole.EMPLOYER, KeycloakUserRole.RECRUITER, KeycloakUserRole.ESCOFFIER_ADMIN]}
          path={`${path}/company`}
          component={EcCompanySettings}
          title="Company Settings"
        />
      }
      {featureFlags.NOTIFICATION_SETTINGS && (
        <EcRoute
          exact
          path={`${path}/notifications`}
          title="Notification Settings"
          component={NotificationSettings}
        />
      )}
      {/* {featureFlags.COMPANY_LOCATION && ( // TODO: Move Locations to CompanySettings
        <EcRoute
          exact
          path={`${path}/locations`}
          title="Manage Locations"
          component={LocationsSettings}
        />
      )} */}
    </Switch>
  );

  return (
    <PageFormLayoutComponent
      headerContent={<></>}
      sideContent={<NestedLinkList links={Array.from(navItems)} />}
      hideSideContent={isLessThanHeaderBreakpoint}
      content={Settings}
    />
  );
};

export default SettingsPage;
