import * as React from 'react';
import { Typography } from '@mui/material';

export const getTextWithNewLines = (text: string): React.ReactNode[] => {
  return text.split('\n').map((str) => (
    <Typography variant="EC_TYPE_BASE" key={str}>
      {str}
    </Typography>
  ));
};
