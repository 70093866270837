import * as React from 'react';
import { Box, Skeleton } from '@mui/material';

const StudentInfoPageCardSkeleton = (): React.ReactElement => {
  return (
    <Box>
      <Skeleton
        sx={{ transform: 'none', marginBottom: '16px' }}
        height="32px"
        width="220px"
      />
      <Skeleton height="645px" sx={{ transform: 'none' }} />
    </Box>
  );
};

export default StudentInfoPageCardSkeleton;
