import * as React from 'react';
import BasicModal from '@components/BasicModal';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { Typography } from '@mui/material';

interface Props {
  open: boolean;
  handleClose: () => void;
  approver: string;
  isManager?: boolean;
  handleRemoveApprover: () => void;
}

const RemoveApproverModal: React.FC<Props> = ({
  open,
  handleClose,
  approver,
  handleRemoveApprover,
  isManager
}) => {
  const [disableActions, setDisableActions] = React.useState(false);

  const handleRemove = (): void => {
    setDisableActions(true);
    handleRemoveApprover();
    setDisableActions(false);
  };

  return (
    <BasicModal
      open={open}
      handleClose={handleClose}
      title="Remove Approver"
      primaryAction={{
        label: 'Confirm',
        action: handleRemove,
        disabled: disableActions
      }}
      secondaryAction={{
        label: 'Cancel',
        action: handleClose,
        disabled: disableActions
      }}
      maxWidth="sm"
    >
      <BottomMarginContentWrapper bottomMargin={'24px'}>
        <Typography>
          You are about to remove the following{' '}
          <strong>{isManager ? 'manager' : 'supervisor'}</strong> from this
          externship:
        </Typography>

        <Typography variant="EC_TYPE_SM">{approver}</Typography>
        {isManager && (
          <Typography>
            If the externship agreement has been signed by career services it
            will remain with the site proposal and a new externship agreement
            will not be required to be signed by a new manager.
          </Typography>
        )}
      </BottomMarginContentWrapper>
    </BasicModal>
  );
};

export default RemoveApproverModal;
