import { useEffect, useState } from 'react';
import { CareerServicesApi } from '@api/CareerService.api';
import { CsCompanyProfile } from '@api/models/careerServicesApi.models';

interface FetchCsCompanies {
  companies: CsCompanyProfile[];
  loading: boolean;
}

export const useFetchCareerServiceCompanies = (): FetchCsCompanies => {
  const [companies, setCompanies] = useState<CsCompanyProfile[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchCompanies = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await CareerServicesApi.getCompanies();

      setCompanies(res);
    } catch (error) {
      console.error(
        `Error for useFetchCareerServiceCompanies -> CareerServicesApi.getCompanies: ${error}`
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  return { companies, loading };
};
