import { ApplicationOverview, DateRange } from '@common/models/common.models';
import {
  CompanyPartnershipPermissions,
  PartnershipLevel
} from '@api/permissions/companyPartnershipLevel.permissions';
import { IPageInfoRes, PageInfo } from '@interfaces/pageInfo.interfaces';
import { CodebookCountInterface } from '@api/models/codebook.models';
import { CompanyProfile } from '@api/models/companyApi.models';
import { GlrcAccesses } from '@api/models/glrcAccess.models';

export interface CityStats {
  city: string;
  profiles: number;
}
export interface ProfileLocation {
  state: string;
  profiles: number;
  cities: CityStats[];
}

export interface Intentions {
  apply: number;
  browse: number;
  learnMore: number;
  applyAndBrowse: number;
  applyAndLearnMore: number;
  browseAndLearnMore: number;
  applyBrowseAndLearnMore: number;
}

export interface DailyInsight {
  date: string;
  candidateAccounts: number;
  candidateProfiles: number;
  employerAccounts: number;
  jobsCreated: number;
}

export interface AdminStatsResponse {
  candidateAccounts: number;
  createdAccounts: {
    locations: ProfileLocation[];
    intentions: Intentions;
    selfIdentification: CodebookCountInterface[];
  };
  completedCandidateProfiles: number;
  completedProfileLocations: ProfileLocation[];
  applications: ApplicationOverview;
  dailyInsights: DailyInsight[];
}

export interface CompanyAdmin {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface CompanyStatus {
  id: number;
  companyName: string;
  enabled: boolean;
  createdAt: string;
  createdDate: string;
  admins: CompanyAdmin[];
  phone: string;
  activeJobs: number;
  applicants: number;
  logoUrl?: string;
  partnershipLevel: PartnershipLevel;
  partnershipLevelPermissions: CompanyPartnershipPermissions;
  glrcAccess?: GlrcAccesses[];
  glrcSeats: number;
  profileComplete: boolean;
  customThemeAccess: boolean;
  csvGlrcAccess: { [key: string]: boolean }[];
  // TODO: update type with more accurate type from API
  subsidiaries?: CompanyProfile[];
  parentCompany?: CompanyProfile | null;
  possibleParent: boolean | null;
  workLearnPartner: boolean;
}

export interface CompanyPossibleSub {
  id: number;
  companyName: string;
  logoUrl: string;
  createdDate: string;
  admins: CompanyAdmin[];
}

export interface CompanyUser {
  companyId: number;
  companyName: string;
  companyEnabled: boolean;
  companyPhone: string;
  userId: number;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  userPhone: string;
  userRole: string;
}

export interface CandidateAccount {
  id: number;
  profileComplete: boolean;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  createdAt: string;
  createdDate: string;
  graduationType: string;
  locations: { city: string; state: string; country: string }[] | null;
  campus: string;
  program: string;
}

export interface PageInfoSearchReq extends DateRange {
  pageInfo: PageInfo;
}

export interface CandidateAccountsReq extends PageInfoSearchReq {
  graduationTypeId?: GraduationTypes;
}

export interface CandidateAccountsRes {
  candidates: CandidateAccount[];
  pageInfo: IPageInfoRes;
}

export enum ApplicationStatus {
  INVITED = 'Invited',
  PENDING = 'Pending',
  REJECTED = 'Rejected',
  HIRED = 'Hired'
}

export enum CSVFileType {
  APPLICATIONS = 'applications.csv',
  CREATED_PROFILES = 'created_profiles.csv',
  COMPLETED_PROFILES = 'completed_profiles.csv',
  EC_STUDENT_ACCOUNTS = 'ec_student_accounts.csv',
  EC_ALUMNI_ACCOUNTS = 'ec_alumni_accounts.csv',
  NEITHER_ACCOUNTS = 'neither_accounts.csv'
}

export enum GraduationTypes {
  EC_STUDENT = 1,
  EC_ALUMNI = 2,
  NEITHER = 99
}

export interface Application {
  id: number;
  jobId: number;
  jobTitle: string;
  company: string;
  applyDate: string;
  status: ApplicationStatus;
  externalApplicationStatus: string;
}

export interface Applicant {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  totalApplications: number;
  createdDate: string;
  applications: Application[];
}

export interface ApplicantsReq extends DateRange {
  pageInfo: PageInfo;
  statusId?: number;
}

export interface ApplicantsRes {
  applicants: Applicant[];
  pageInfo: IPageInfoRes;
}

export interface ApplicantsCsvReq extends DateRange {
  statusId?: number;
}

export interface ApplicantCsv {
  candidateId: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  createdDate: string;
  createdAt: string;
  applicationId: number;
  jobId: number;
  jobTitle: string;
  companyId: number;
  companyName: string;
  applyDate: string;
  status: ApplicationStatus;
}

export interface AdminDashJobPost {
  jobPostId: number;
  jobTitle: string;
  locations: { city: string; state: string; country: string }[];
  visibleOnMarketplace: boolean;
  createdDate: string;
  applicants: number;
}

export interface AdminDashJobPostCsv {
  jobPostId: number;
  jobTitle: string;
  locations: string;
  visibleOnMarketplace: boolean;
  createdAt: string;
  applicants: number;
}

export interface AdminDashJobPostsReq extends DateRange {
  pageInfo: PageInfo;
}

export interface AdminDashJobPostsRes {
  jobPosts: AdminDashJobPost[];
  pageInfo: IPageInfoRes;
}

export interface CandidatesCsvReq extends DateRange {
  graduationTypeId?: GraduationTypes;
}

export interface CandidateCsv {
  id: number;
  profileComplete: boolean;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  createdDate: Date;
  graduationType: string;
  locations: { city: string; state: string }[] | null;
  campus: string;
  program: string;
}
