import * as React from 'react';
import BasicModal from '@components/BasicModal';
import { Typography } from '@mui/material';

interface Props {
  open: boolean;
  handleClose: () => void;
}

const SiteAgreementNotSignedModal: React.FC<Props> = ({
  open,
  handleClose
}) => {
  return (
    <BasicModal
      open={open}
      title="Site Agreement is Missing"
      handleClose={handleClose}
      maxWidth="sm"
      primaryAction={{
        label: 'OK',
        action: handleClose
      }}
    >
      <Typography>
        Please complete step 1 and sign the site agreement before proceeding to
        the externship agreement.
      </Typography>
    </BasicModal>
  );
};

export default SiteAgreementNotSignedModal;
