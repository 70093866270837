import * as React from 'react';
import { Box, Card, Typography } from '@mui/material';
import { PromotedCompanySpotlight } from '@api/models/searchApi.models';
import { styled } from '@mui/system';

const Styled = {
  Card: styled(Card)({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '8px'
  }),
  ImageWrapper: styled(Box)({
    height: '200px'
  }),
  Image: styled('img')({
    height: '100%',
    width: '100%',
    objectFit: 'scale-down',
    objectPosition: 'center'
  })
};

interface Props {
  companyCard: PromotedCompanySpotlight;
}

const MarketplaceSpotlightCard: React.FC<Props> = ({ companyCard }) => {
  const { imageUrl, description } = companyCard;

  return (
    <Styled.Card>
      <Styled.ImageWrapper>
        <Styled.Image src={imageUrl} />
      </Styled.ImageWrapper>
      {description && (
        <Typography textAlign={'center'} mb={'8px'} mt={'8px'}>
          {description}
        </Typography>
      )}
    </Styled.Card>
  );
};

export default MarketplaceSpotlightCard;
