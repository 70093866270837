import * as React from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import {
  DataGridProProps,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowsProp,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';
import { allowedGridFilterOperators } from '@common/fetches/useFetchGridTableByType';
import CsCompaniesExpandPanel from './CsCompaniesExpandPanel';
import { CsCompanyProfile } from '@api/models/careerServicesApi.models';
import EcDataGrid from '@components/DataGrid/EcDataGrid';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { Location } from '@interfaces/location.interfaces';
import NoDataMessage from '@components/NoDataMessage';
import { renderAddress } from '@components/DataGrid/CellRenderers/renderAddress';

interface Props {
  companies: CsCompanyProfile[];
}

interface CustomToolbarProps {
  rows: GridRowsProp;
  columns: GridColDef[];
}

const CustomToolbar: React.FC<CustomToolbarProps> = ({
  rows,
  columns
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleExport = (): void => {
    const csvContent = rows.reduce((csv, row) => {
      // Add company details
      const companyHeaders = columns.map((col) => col.headerName).join(',');
      const companyDetails = columns
        .map((col) => {
          // Render address
          if (col.field === 'address') {
            const location: Location = row.address;
            const streetName = location?.streetName;
            const streetNumber = location?.streetNumber;
            const city = location?.city;

            const address =
              !streetName || !streetNumber
                ? `${city ?? 'N/A'}`
                : `${streetNumber} ${streetName} ${city}`;
            return row?.address ? address : '';
          }
          return row[col.field];
        })
        .join(',');

      // Add user details headers
      const userHeaders = 'NAME,ROLE,EMAIL,PHONE,GRAD DATE,PROGRAM';
      let userDetails = '';

      // Add each user's details
      if (row.users && row.users.length > 0) {
        row.users.forEach((user) => {
          userDetails += `${user.firstName + ' ' + user.lastName},${
            user.role + (user.isCompanyAdmin ? ' (Admin)' : '')
          },${user.email},${user.phone},${
            user?.gradDate
              ? formatDate(new Date(user.gradDate).toDateString())
              : '\u00A0'
          },${user?.program || '\u00A0'}\n`;
        });
      } else {
        userDetails += 'No Users,,,\n';
      }

      // Combine company and user details
      csv += `${companyHeaders}\n${companyDetails}\n${userHeaders}\n${userDetails}\n`;

      return csv;
    }, '');

    // Create a blob and download the file
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExportClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Button
        color="primary"
        size="small"
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleExportClick}
      >
        <i
          style={{ fontSize: '17px', marginRight: '7px' }}
          className="ri-download-2-line"
        />
        Export
      </Button>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={(): void => {
            handleExport();
            handleClose();
          }}
        >
          Download as CSV
        </MenuItem>
      </Menu>
    </GridToolbarContainer>
  );
};

const CsCompaniesTable: React.FC<Props> = ({ companies }) => {
  const [colVisModel, setColVisModel] =
    React.useState<GridColumnVisibilityModel>({ logo: false });

  const companyMap = {};
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'COMPANY',
      flex: 2,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.5,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'address',
      headerName: 'ADDRESS',
      flex: 2,
      valueGetter: (params): string | undefined => renderAddress(params),
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'state',
      headerName: 'ST',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'phone',
      headerName: 'PHONE',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'zip',
      headerName: 'POSTAL CODE',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'numberOfExternships',
      headerName: 'NUMBER OF EXTERNSHIPS',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'managerName',
      /* eslint-disable-next-line quotes */
      headerName: "MANAGER'S NAME",
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'isGlobalSolutions',
      headerName: 'GLOBAL SOLUTIONS',
      flex: 1,
      align: 'center',
      valueGetter: (params): string | undefined =>
        params.value === true ? 'Yes' : 'No',
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'isExternship',
      headerName: 'EXTERNSHIP',
      flex: 1,
      align: 'center',
      valueGetter: (params): string | undefined =>
        params.value === true ? 'Yes' : 'No',
      filterOperators: allowedGridFilterOperators
    }
  ];

  const rows: GridRowsProp = React.useMemo(() => {
    return companies.map((c) => {
      const row: { [key: string]: any } = {
        id: c.id
      };

      columns.forEach((col) => {
        row[col.field as string] = c[col.field];
        row.address = c.locations?.[0];
        row.state = c.locations?.[0]?.state ?? '';
        row.users = c.users ?? [];
        row.zip = c.locations?.[0]?.zip ?? '';
      });

      companyMap[c.id] = row;

      return row;
    });
  }, [companies, columns]);

  const getDetailsPanelContent = React.useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']> // eslint-disable-line @typescript-eslint/indent
  >(({ row }): React.ReactNode => {
    const { users } = row;

    if (users.length === 0) {
      return (
        <Box p={2}>
          <NoDataMessage
            title={'No externship related users at this company'}
          />
        </Box>
      );
    }

    return <CsCompaniesExpandPanel users={users} />;
  }, []);

  return (
    <EcDataGrid
      disableVirtualization
      rowHeight={36}
      rows={rows}
      columns={columns}
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      slots={{
        toolbar: (): JSX.Element => (
          <CustomToolbar rows={rows} columns={columns} />
        )
      }}
      getDetailPanelContent={getDetailsPanelContent}
      getDetailPanelHeight={(): 'auto' => 'auto'}
      initialState={{
        pagination: {
          paginationModel: { pageSize: 25, page: 0 }
        }
      }}
      columnVisibilityModel={colVisModel}
      onColumnVisibilityModelChange={(columnVisibilityModel): void =>
        setColVisModel(columnVisibilityModel)
      }
    />
  );
};

export default CsCompaniesTable;
