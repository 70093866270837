/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  companyDetailsPropArray,
  PreviewPageCompanyDetailsProps,
  tabPanelCompanyPropArray,
  TabsPanelsBenefitsProps,
  TabsPanelsCompanyProps
} from '@pages/Employer/PreviewPage/employerPreviewPageComponentProps';
import {
  useFetchMarketplaceSearchResults,
  UseFetchSearchCriteria
} from '@common/fetches/useFetchMarketplaceSearchResults';
import { Box } from '@mui/material';
import EmployerProfileCompanyDetails from './EmployerProfileCompanyDetails';
import EmployerProfileJobPosts from './EmployerProfileJobPosts';
import { IPageInfoRes } from '@interfaces/pageInfo.interfaces';
import { JobPost } from '@api/models/singleJobPostApi.models';
import { MediaType } from '@api/models/mediaApi.models';
import NotFoundPage from '@pages/Errors/NotFoundPage';
import { pickProps } from '@common/helpers/pickProps';
import ProfileMediaGallery from '@components/ProfileMediaGallery';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { styled } from '@mui/system';
import TabsPanelsEmployer from '@components/TabsPanelsEmployer';
import { useFetchCompanyProfilePublic } from '@common/fetches/useFetchCompanyProfilePublic';
import { useParams } from 'react-router-dom';

const DEFAULT_PAGE = 1;
const PER_PAGE_COUNT = 9;

const Styled = {
  Content: styled(Box)({
    maxWidth: '975px',
    margin: '0 auto'
  })
};

interface IParams {
  id: string;
}

const EmployerProfilePage = (): React.ReactElement => {
  const { id } = useParams<IParams>();
  const parsedId = parseInt(id);
  const { companyPublicProfile, loading } =
    useFetchCompanyProfilePublic(parsedId);

  const searchCriteria: UseFetchSearchCriteria = {
    pageInfo: {
      currentPage: DEFAULT_PAGE,
      currentPageEndRow: 0,
      currentPageStartRow: 0,
      filteredRows: 0,
      rowsPerPage: PER_PAGE_COUNT,
      totalPages: 0,
      totalRows: 0,
      sortCategory: 'updatedAt',
      sortOrder: 'DESC'
    } as IPageInfoRes,
    filters: [
      {
        fieldName: 'jp."companyId"',
        selected: true,
        value: id
      }
    ],
    searchType: 'JobPost'
  };

  const { ...searchProps } = useFetchMarketplaceSearchResults<JobPost>(
    searchCriteria,
    DEFAULT_PAGE
  );

  const sectionProps = React.useMemo(() => {
    const companyDetailsProps = pickProps(
      companyDetailsPropArray,
      companyPublicProfile
    ) as PreviewPageCompanyDetailsProps;

    const companyTabProps = pickProps(
      tabPanelCompanyPropArray,
      companyPublicProfile
    ) as TabsPanelsCompanyProps;

    return { companyDetailsProps, companyTabProps };
  }, [companyPublicProfile]);

  const benefitsProps = React.useMemo(() => {
    const filterGroups = (group: string): string[] | null =>
      companyPublicProfile
        ? companyPublicProfile.companyBenefits
            .filter((benefit) => benefit.benefitsGroup === group)
            .map((benefit) => benefit.benefitsValue)
        : [];

    return {
      healthWellnessBenefits: filterGroups('companyHealthInsurance'),
      retirementBenefits: filterGroups('companyRetirement'),
      professionalDevBenefits: filterGroups('companyProfDevelopment')
    };
  }, [companyPublicProfile]);

  const tabProps = {
    ...sectionProps.companyTabProps,
    ...benefitsProps
  } as TabsPanelsCompanyProps & TabsPanelsBenefitsProps;

  const galleryProps: MediaType[] = React.useMemo(() => {
    if (companyPublicProfile) {
      return [
        ...companyPublicProfile.companyPhotos.map((item) => item.file),
        ...companyPublicProfile.companyVideos.map((item) => item.video)
      ];
    }
    return [];
  }, [companyPublicProfile]);

  const displayGallery: boolean = !galleryProps
    ? false
    : galleryProps.length > 0;

  return (
    <>
      <RootPageLayout
        bgImgVariant={3}
        muiMaxWidth={companyPublicProfile ? false : 'xl'}
        boxShadow={
          companyPublicProfile ? '0 4px 4px 0 rgba(0,0,0,0.25)' : undefined
        }
        zIndex={companyPublicProfile ? 10 : undefined}
      >
        {companyPublicProfile && (
          <Styled.Content>
            <EmployerProfileCompanyDetails
              {...sectionProps.companyDetailsProps}
            />
            <TabsPanelsEmployer {...tabProps} />
            {displayGallery && (
              <ProfileMediaGallery
                sectionTitle="photos and videos"
                media={galleryProps}
              />
            )}
          </Styled.Content>
        )}
        {!companyPublicProfile && !loading && <NotFoundPage />}
      </RootPageLayout>
      {companyPublicProfile && <EmployerProfileJobPosts {...searchProps} />}
    </>
  );
};

export default EmployerProfilePage;
