import * as React from 'react';
import ErrorPageLayout from '@pages/Errors/ErrorPageLayout';

const ExpiredInvitePage: React.FC = () => {
  return (
    <ErrorPageLayout
      header="YOUR INVITE HAS EXPIRED"
      subtext="Please contact your admin to extend another invitation."
    />
  );
};

export default ExpiredInvitePage;
