import { CandidateProfile } from '@api/models/candidateApi.models';
import { createCustomContext } from '@common/context/customContext';
import { EnrollmentCoursesExternships } from '@api/models/enrollmentApi.models';
import { ExternshipEntity } from '@api/models/externshipApi.models';
import { JobApplied } from '@api/models/jobApplicationApi.models';

type CandidateDashboardContext = {
  candidateProfile?: CandidateProfile;
  jobApplications?: JobApplied[];
  externships?: ExternshipEntity[]; // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove when flag is updated
  candidateEnrollments: EnrollmentCoursesExternships[];
  coursesAssignable: boolean; // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove when flag is updated
  hasAvailableExternshipCourses: (candidateId: number) => Promise<void>; // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove when flag is updated
  fetchByCandidateId: (candidateOrStudentId: number | string) => Promise<void>;

  // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove when flag is updated
  setExternshipsByCandidate: React.Dispatch<
    React.SetStateAction<ExternshipEntity[] | undefined> // eslint-disable-line @typescript-eslint/indent
  >;

  removeExternshipFromState: (externshipId: number) => void;
  addExternshipToState: (externship: ExternshipEntity) => void;
  loaded: boolean;
};

export const [useCandidateDashboardContext, CandidateDashboardContextProvider] =
  createCustomContext<CandidateDashboardContext>();
