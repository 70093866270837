import * as React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';
import { MobileSearchFilterOverlayProvider } from '@common/context/mobileSearchFilterOverlayContext';
import PageLayoutLeftSidebar from '@components/Layout/PageLayoutLeftSidebar';
import { styled } from '@mui/system';

const FILTER_COUNT = 6;
const CARD_COUNT = 12;

const Styled = {
  Sidebar: styled(Box)({
    display: 'flex',
    flexFlow: 'column',
    rowGap: '16px'
  }),
  FilterBox: styled(Box)({
    marginTop: '26px',
    marginBottom: '18px'
  })
};

const SidebarContent: JSX.Element = (
  <Styled.Sidebar>
    {Array(FILTER_COUNT)
      .fill(0)
      .map((_, idx: number) => (
        <Skeleton key={idx} height="50px" sx={{ transform: 'none' }} />
      ))}
    <Styled.FilterBox sx={{ marginTop: '32px' }}>
      <Skeleton height="50px" sx={{ transform: 'none' }} />
    </Styled.FilterBox>
    <Skeleton height={20} width={89} />
  </Styled.Sidebar>
);

export const MainContent = (
  <Box>
    <Skeleton width={200} sx={{ marginBottom: '20px' }} />
    <Grid container spacing={2}>
      {Array(CARD_COUNT)
        .fill(0)
        .map((_, idx: number) => (
          <Grid item key={idx} xl={3} lg={4} md={6} sm={6} xs={12}>
            <Skeleton height="245px" sx={{ transform: 'none' }} />
          </Grid>
        ))}
    </Grid>
  </Box>
);

interface Props {
  pageTitle: string;
  sideContent?: JSX.Element;
  mainContent?: JSX.Element;
}

const PageLayoutLeftSidebarSkeleton = ({
  pageTitle,
  sideContent = SidebarContent,
  mainContent = MainContent
}: Props): React.ReactElement => {
  // using empty function instead of making them optional in context type
  const emptyFunction = (): void => {
    return;
  };

  return (
    <MobileSearchFilterOverlayProvider
      value={{
        filterOverlayOpen: false,
        mobileBreakpoint: 900,
        openMobileFilter: emptyFunction,
        closeMobileFilter: emptyFunction
      }}
    >
      <PageLayoutLeftSidebar
        loading
        pageTitle={pageTitle}
        sidebar={sideContent}
        content={mainContent}
        searchBar={{
          value: '',
          onChange: emptyFunction,
          onSubmit: emptyFunction,
          clearSearch: emptyFunction
        }}
      />
    </MobileSearchFilterOverlayProvider>
  );
};

export default PageLayoutLeftSidebarSkeleton;
