import * as React from 'react';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid-pro';
import { allowedGridFilterOperators } from '@common/fetches/useFetchGridTableByType';
import { CompanyApi } from '@api/Company.api';
import EcDataGrid from '@components/DataGrid/EcDataGrid';
import EcGridTableToolbar from '@components/DataGrid/EcGridTableToolbar';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { JoinRequest } from '@api/models/companyApi.models';
import { roles } from '@api/models/userApi.models';
import { useCompanySettingsContext } from '@common/context/companySettingsContext';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';

export const allSeatOptions = [
  {
    label: 'Admin',
    value: `${roles.EMPLOYER}`
  },
  {
    label: 'Recruiter',
    value: `${roles.RECRUITER}`
  },
  {
    label: 'ESource User',
    value: `${roles.GLRC_USER}`
  },
  // Multiple roles should be in alphabetical order
  { label: 'ESource/Recruiter', value: `${roles.GLRC_USER};${roles.RECRUITER}` }
];

const SeatApprovalsTabContent: React.FC = () => {
  const featureFlags = useFeatureFlags();

  const {
    companyId,
    joinRequests,
    handleSeatRequestUpdate,
    glrcSeatsRemaining
  } = useCompanySettingsContext();

  const handleApproval = async (
    requestId: number,
    roles: string
  ): Promise<void> => {
    try {
      await CompanyApi.approveJoinRequest(companyId, requestId, {
        roles: roles
      });
      handleSeatRequestUpdate();
    } catch (error) {
      console.error(
        'Error approving JoinRequest in SeatApprovalsTabContent.handleApproval()',
        error
      );
    }
  };

  const handleReject = async (requestId: number): Promise<void> => {
    await CompanyApi.rejectJoinRequest(companyId, requestId);
    handleSeatRequestUpdate();
  };

  const filteredSeatOptions = (
    joinRequest: JoinRequest
  ): { label: string; value: string }[] => {
    return allSeatOptions.filter((opt) => {
      // Externship Managers will only be assigned the admin role
      if (joinRequest.externship) {
        return opt.value === roles.EMPLOYER;
      }

      if (!featureFlags.PARTNER_EXPERIENCE || glrcSeatsRemaining() <= 0) {
        return !opt.value.includes(roles.GLRC_USER);
      }

      // if (featureFlags.COMPANY_SETTINGS_REDESIGN) {
      //   return opt.value !== `${roles.GLRC_USER},${roles.RECRUITER}`;
      // }

      return true;
    });
  };

  const approvalsColumns: GridColDef[] = [
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'isExternshipManager',
      headerName: 'Externship',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'sentDate',
      headerName: 'Sent Date',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'Actions',
      type: 'actions',
      width: 80,
      getActions: (params): JSX.Element[] => [
        ...filteredSeatOptions(params.row as JoinRequest).map((r) => {
          console.log('MY ROGER PARAMS', params);

          return (
            <GridActionsCellItem
              key={r.value}
              label={r.label}
              onClick={(): Promise<void> =>
                handleApproval(Number(params.id), r.value)
              }
              showInMenu
            />
          );
        }),
        <GridActionsCellItem
          key={params.id}
          label="Reject"
          onClick={(): Promise<void> => handleReject(Number(params.id))}
          sx={{ color: 'error.main' }}
          showInMenu
        />
      ],
      filterOperators: allowedGridFilterOperators
    }
  ];

  return (
    <EcDataGrid
      autoHeight={true}
      rowSelection
      columns={approvalsColumns}
      rows={joinRequests.map((r) => ({
        ...r,
        sentDate: formatDate(r.submittedAt, 'M/dd/yyyy'),
        isExternshipManager: r.externship ? 'Y' : 'N'
      }))}
      slots={{
        toolbar: EcGridTableToolbar
      }}
    />
  );
};

export default SeatApprovalsTabContent;
