import * as React from 'react';
import { ApplicationOverview, DateRange } from '@common/models/common.models';
import { Box, Typography, useTheme } from '@mui/material';
import { ApiResponse } from '@api/models/base.models';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { CompanyApi } from '@api/Company.api';
import CompanyLogo from '@components/CompanyLogo';
import DateRangeForm from '@components/DateRangeForm';
import { getChartLabel } from '@common/helpers/getChartLabel';
import JobApplicationsSection from '@components/JobApplicationsSection';
import RootPageLayout from '@components/Layout/RootPageLayout';
import SubsidiariesSection from './SubsidiariesSection';
import { useFetchCompanyProfile } from '@common/fetches/useFetchCompanyProfile';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';

const ParentDashboardPage: React.FC = () => {
  const theme = useTheme();
  const { companyId: parentCompanyId, paramsId } = useParamsOrStoreCompanyId();
  const [chartLabel, setChartLabel] = React.useState<string>('All Time');
  const [stats, setStats] = React.useState<ApplicationOverview>();
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: null,
    endDate: null
  });

  const { companyProfile } = useFetchCompanyProfile(parentCompanyId);

  const fetchStats = async (req?: DateRange): Promise<void> => {
    try {
      const postBody = {
        startDate: req?.startDate || null,
        endDate: req?.endDate || null
      };

      const stats: ApiResponse<ApplicationOverview> =
        await CompanyApi.getParentStats(parentCompanyId, postBody);

      const label = getChartLabel(req);
      setChartLabel(label);
      setDateRange(postBody);
      setStats(stats.data);
    } catch (error) {
      console.error('Error in AdminDashboard.getStats', error);
    }
  };

  React.useEffect(() => {
    fetchStats();
  }, []);

  const companyName = paramsId
    ? `${companyProfile?.name}'s Dashboard`
    : companyProfile?.name;

  if (!stats) {
    return <></>;
  }

  return (
    <RootPageLayout muiMaxWidth="xl" bgColor={theme.palette.GRAY_F4.main}>
      <BottomMarginContentWrapper bottomMargin="32px">
        <Box display="flex" alignItems="center">
          <CompanyLogo
            name={companyProfile?.name ?? ''}
            logo={companyProfile?.logo?.fullPath}
            tableDisplay
          />
          <Typography variant="EC_TYPE_3XL" margin="auto 0 auto 16px">
            {companyName}
          </Typography>
        </Box>
        {parentCompanyId && <SubsidiariesSection companyId={parentCompanyId} />}
        <DateRangeForm
          title="Reporting"
          onSubmit={fetchStats}
          sessionKey="PARENT_DASH"
        />
        <JobApplicationsSection
          applicationOverview={stats}
          chartLabel={chartLabel}
          dateRange={dateRange}
          parentCompanyId={parentCompanyId}
          hideModals={false}
        />
      </BottomMarginContentWrapper>
    </RootPageLayout>
  );
};

export default ParentDashboardPage;
