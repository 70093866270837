import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

const Styled = {
  Wrapper: styled(Box)({
    '& > *': { marginBottom: '75px' }
  })
};

const FormWrapper: React.FC = ({ children }) => {
  return <Styled.Wrapper>{children}</Styled.Wrapper>;
};

export default FormWrapper;
