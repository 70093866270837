import * as React from 'react';
import { Box, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    position: 'relative',
    height: '36px',
    width: '36px',
    borderRadius: '50%',
    flexShrink: 0,
    backgroundColor: theme.palette.GRAY_85.main
  })),
  Initials: styled(Typography)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -54%)',
    cursor: 'default'
  })
};

interface Props {
  name: string;
  textVariant?: TypographyProps['variant'];
  style?: React.CSSProperties;
}

const InitialsCircle: React.FC<Props> = ({
  name,
  textVariant = 'EC_TYPE_BASE',
  style
}) => {
  const names = name.split(' ');
  const initials =
    names[0].substring(0, 1).toUpperCase() +
    names[1].substring(0, 1).toUpperCase();

  return (
    <Styled.Root sx={{ ...style }}>
      <Styled.Initials variant={textVariant}>{initials}</Styled.Initials>
    </Styled.Root>
  );
};

export default InitialsCircle;
