import * as React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { Box, Typography } from '@mui/material';
import SimpleSelect from '@components/Forms/SimpleSelect';
import theme from '@themes/ui/escoffier';

export interface BarProps {
  dataKey: string;
  tooltipName: string;
  color?: string;
}

export interface BarFilterProps extends BarProps {
  filters?: EcChartFilter[];
}

interface EcChartFilter {
  label: string;
  dataKey: string;
}

interface Props {
  title?: string;
  height?: number;
  data: any[];
  xAxisDataKey: string;
  bars: BarProps[] | BarFilterProps;
}

const EcBarChart: React.FC<Props> = ({
  title,
  height = 400,
  data,
  xAxisDataKey,
  bars
}) => {
  const isNotArrayOfBars = !Array.isArray(bars);

  const [selectedFilter, setSelectedFilter] = React.useState(
    isNotArrayOfBars ? bars.dataKey : ''
  );

  const handleFilterChange = (e): void => {
    setSelectedFilter(e.target.value);
  };

  return (
    <Box>
      {title && <Typography variant="EC_TYPE_3XL">{title}</Typography>}
      {isNotArrayOfBars && !!bars.filters?.length && (
        <Box mb={2}>
          <SimpleSelect
            handleChange={handleFilterChange}
            value={selectedFilter}
            options={bars.filters.map((f) => ({
              label: f.label,
              value: f.dataKey
            }))}
            label="Filter by"
          />
        </Box>
      )}
      <ResponsiveContainer width="100%" height={height}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxisDataKey} />
          <YAxis allowDecimals={false} />
          <Tooltip />
          {isNotArrayOfBars ? (
            <Bar
              key={bars.tooltipName}
              dataKey={selectedFilter}
              name={bars.tooltipName}
              fill={bars.color || theme.palette.red3.main}
            />
          ) : (
            <>
              {bars.map((bar) => {
                const color = bar.color || theme.palette.red3.main;

                return (
                  <Bar
                    key={bar.tooltipName}
                    dataKey={bar.dataKey}
                    name={bar.tooltipName}
                    fill={color}
                  />
                );
              })}
            </>
          )}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default EcBarChart;
