/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  getCompanyPath,
  getEmployerPathWithParamsId
} from '@common/helpers/getPathWithParamsId';
import { Grid, Typography, useMediaQuery, useTheme, Accordion, AccordionSummary, AccordionDetails, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TileNavItem, {
  TileNavItemProps
} from '@components/TileNavItem/TileNavItem';
import { Toast, useToast } from '@components/Toast';
import Box from '@mui/material/Box';
import defaultEmployerLogo from '@assets/svg/employer-def.svg';
import DashboardLoading from '@components/TableComponents/DashboardLoading';
import GlrcTileImg from '@assets/EmployerDashboardPage/glrc_tile.jpg';
import { JobPost } from '@api/models/singleJobPostApi.models';
import { JobPostApi } from '@api/JobPost.api';
import JobPostingsSection from '@pages/Employer/EmployerDashboard/JobPostingsSection';
import { styled } from '@mui/system';
import TalentSearchTileImg from '@assets/EmployerDashboardPage/talent_search_tile.jpg';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchCompanyProfile } from '@common/fetches/useFetchCompanyProfile';
import { useFetchJobPostsByCompanyId } from '@common/fetches/useFetchJobPostsByCompanyId';
import { EmployerRole } from '@api/models/employerApi.models';
import ImageWithBadge from '@components/ImageWithBadge';
import { CompanyProfile } from '@api/models/companyApi.models';

const Styled = {
  JoblistBox: styled(Box)({
    display: 'flex',
    flexDirection: 'column'
  }),
  CompanyHeaderTitle: styled(Typography)(({ theme }) => ({
    marginRight: '.5em',
    marginBottom: '1em',
    fontSize: '2rem',
    lineHeight: '44px',
    color: theme.palette.GOLD_1.main,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  })),
  LogoNameBox: styled(Box)({
    borderTop: '1px solid GOLD_1',
    display: 'flex',
    alignItems: 'center',
    columnGap: '20px',
    minWidth: '0px',
    overflow: 'hidden',
    marginBottom: '.5em',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    img: {
      objectFit: 'contain',
      padding: '8px'
    }
  }),
  AccordionContainer: styled(Box)({
    position: 'relative', // Add positioning context
    width: '100%'
  }),
  IconContainer: styled(Box)({
    position: 'absolute',
    left: '-3em', // Move icon into left margin
    marginTop: '0.75em',
    '& .MuiSvgIcon-root': {
      fontSize: '2em'
    }
  }),
  StyledAccordion: styled(Accordion)({
    '&.MuiAccordion-root': {
      padding: '1em 0',
      boxShadow: 'none',
      border: '1px solid #E0E0E0',
      '&:before': {
        display: 'none' // Removes the default border
      },
    }
  }),
};

export interface EmployerDashboardCompanyCardProps {
  company: CompanyProfile;
  employerRole: EmployerRole;
}
const EmployerDashboardCompanyCard: React.FC<EmployerDashboardCompanyCardProps> = ( {
  company,
  employerRole,
}) => {
  const { openToast, ...toastProps } = useToast();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    jobPosts: _jobPosts,
    applicantsPerJobId,
    loading: jobPostsLoading
  } = useFetchJobPostsByCompanyId(company.id, true);

  const [jobPosts, setJobPosts] = React.useState<JobPost[]>([]);

  const featureFlags = useFeatureFlags();

  const { companyProfile, loading: profileLoading } =
  useFetchCompanyProfile(company.id);

  const [expanded, setExpanded] = React.useState(true);

  const handleToggle = () : void => {
    setExpanded(!expanded);
  };

  const handleJobPostingVisibility = async (id: number): Promise<void> => {
    // These will be defined since the only way a user would click to call this
    // function would be if there are job posts saved.
    const posts = [...jobPosts!];
    const isJobPostVisible = posts.find((post) => post.id === id)!
      .visibleOnMarketplace as boolean;

    const updatedVisibility: Pick<JobPost, 'visibleOnMarketplace'> =
      isJobPostVisible === true
        ? { visibleOnMarketplace: false }
        : { visibleOnMarketplace: true };

    try {
      await JobPostApi.updateJobPostVisibility(id, updatedVisibility);
      openToast(
        `Job number ${id} updated to ${
          updatedVisibility.visibleOnMarketplace ? 'Public' : 'Hidden'
        }`
      );
      setJobPosts((prevState: JobPost[]) =>
        prevState?.map((post: JobPost) => {
          if (post.id === id) {
            return Object.assign({}, post, {
              visibleOnMarketplace: updatedVisibility.visibleOnMarketplace
            });
          } else {
            return post;
          }
        })
      );
    } catch (error: any) {
      console.error(
        'Error for UpdateJobPostVisibility at Employer Dashboard',
        error
      );
    }
  };

  const hasTalentSearchPerm =
    !!company?.partnershipLevelPermissions?.TALENT_SEARCH_ENABLED &&
    featureFlags.COMPANY_PARTNERSHIP_LEVELS;

  // Set local jobPosts state since visibility updates require a state update
  React.useEffect(() => {
    if (_jobPosts) {
      setJobPosts(_jobPosts);
    }
  }, [_jobPosts]);

  const displayTalentSearch =
    featureFlags.CANDIDATE_MARKETPLACE &&
    // isEnabledCompany &&
    hasTalentSearchPerm;

  const displayGlrcTile = employerRole === EmployerRole.COMPANY_ADMIN || !!companyProfile?.hasEsourceAccess;

  const talentSearchTile = {
    img: TalentSearchTileImg,
    text: 'Talent Search',
    path: getCompanyPath('/talent-search', company.id)
  };

  const glrcTile = {
    img: GlrcTileImg,
    text: 'ESource Dashboard',
    path: getCompanyPath('/learning-center', company.id)
  };

  const tileNavItems = React.useMemo(() => {
    const nav: TileNavItemProps[] = [];

    if (displayTalentSearch) {
      nav.push(talentSearchTile);
    }
    if (displayGlrcTile) {
      nav.push(glrcTile);
    }
    return nav;
  }, [displayTalentSearch, displayGlrcTile]);

  const breadcrumb = employerRole === EmployerRole.COMPANY_ADMIN
  ? {
      href: '/settings/company',
      location: `${companyProfile?.name}'s Dashboard`
    }
  : undefined;

  return (
    <>
      {jobPostsLoading || profileLoading ? (
        <DashboardLoading />
      ) : (
        <Styled.AccordionContainer key={company.id}>
          <Styled.IconContainer>
            <IconButton onClick={handleToggle} edge="start">
              <ExpandMoreIcon
                style={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
              />
            </IconButton>
          </Styled.IconContainer>
          <Styled.StyledAccordion expanded={expanded}>
            <AccordionSummary
              aria-controls={`company-panel-content-${company.id}`}
              id={`company-panel-header-${company.id}`}
            >
              <Styled.LogoNameBox>
                <ImageWithBadge src={company.logo?.fullPath ?? defaultEmployerLogo} height="100px" width="160px" />
                <Styled.CompanyHeaderTitle
                  variant="EC_TYPE_3XL"
                  data-testid="dashboard-page-title"
                >
                  {company.name}
                </Styled.CompanyHeaderTitle>
              </Styled.LogoNameBox>
            </AccordionSummary>
            <AccordionDetails>
              {!!tileNavItems.length && (
                <Grid
                  container
                  columnSpacing={4}
                  rowSpacing={1}
                  alignItems="stretch"
                  mb="32px"
                >
                  {tileNavItems.map((item) => (
                    <Grid item key={item.path} xs={12} sm={6}>
                      <TileNavItem {...item} isMobile={isMobile} />
                    </Grid>
                  ))}
                </Grid>
              )}
              <Styled.JoblistBox>
                <JobPostingsSection
                  ctaHref={getEmployerPathWithParamsId(
                    '/create-job-post',
                    company.id
                  )}
                  tableHeader={employerRole == EmployerRole.COMPANY_ADMIN ? 'Manage Companies' : 'Job Management'}
                  jobPosts={jobPosts}
                  applicantsPerJobId={applicantsPerJobId}
                  companyName={companyProfile?.name}
                  breadcrumb={breadcrumb}
                  handleVisibility={handleJobPostingVisibility}
                />
              </Styled.JoblistBox>
              <Toast {...toastProps} />
            </AccordionDetails>
          </Styled.StyledAccordion>
        </Styled.AccordionContainer>
      )}
    </>
  );
};

export default EmployerDashboardCompanyCard;