/* eslint-disable @typescript-eslint/indent */
import {
  ExternalJobsResponse,
  JobPost,
  JobPostCreateReq
} from './models/singleJobPostApi.models';
import { api } from './configs/base';
import { apiConfig } from './configs/api.config';
import { ApiResponse } from './models/base.models';
import { ExternalJobsSearchReq } from '@components/Filters/FiltersWrapper';

export class JobPostApi {
  static async createJobPost(
    data: JobPostCreateReq
  ): Promise<ApiResponse<JobPost>> {
    try {
      const response = await api.post<ApiResponse<JobPost>>(
        `${apiConfig.jobPostUrl}`,
        data
      );
      return response.data;
    } catch (error: any) {
      console.error(`Error for JobPostApi.createJobPost(): ${error}`);
      throw error;
    }
  }

  static async updateJobPost(
    jobId: number,
    data: JobPostCreateReq
  ): Promise<ApiResponse<JobPost>> {
    try {
      const response = await api.put<ApiResponse<JobPost>>(
        `${apiConfig.jobPostUrl}/${jobId}`,
        data
      );
      return response.data;
    } catch (error: any) {
      console.error(`Error for JobPostApi.updateJobPost(): ${error}`);
      throw error;
    }
  }

  static async updateJobPostVisibility(
    jobId: number,
    data: Pick<JobPostCreateReq, 'visibleOnMarketplace'>
  ): Promise<ApiResponse<Pick<JobPost, 'visibleOnMarketplace'>>> {
    try {
      const response = await api.put<
        ApiResponse<Pick<JobPost, 'visibleOnMarketplace'>>
      >(`${apiConfig.jobPostUrl}/${jobId}/visibility`, data);
      return response.data;
    } catch (error: any) {
      console.error(`Error for JobPostApi.updateJobPostVisibility(): ${error}`);
      throw error;
    }
  }

  static async getJobPostById(jobId: string): Promise<ApiResponse<JobPost>> {
    try {
      const response = await api.get<ApiResponse<JobPost>>(
        `${apiConfig.jobPostUrl}/${jobId}`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for JobPostApi.getJobPostById(): ${error}`);
      throw error;
    }
  }

  static async getJobPostByCompanyId(
    companyId: number
  ): Promise<ApiResponse<JobPost[]>> {
    try {
      const response = await api.get<ApiResponse<JobPost[]>>(
        `${apiConfig.jobPostUrl}/company/${companyId}`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for JobPostApi.getJobPostByCompanyId(): ${error}`);
      throw error;
    }
  }

  static async getExternalJobs(
    filterData?: ExternalJobsSearchReq
  ): Promise<ApiResponse<ExternalJobsResponse>> {
    try {
      const response = await api.post<ApiResponse<ExternalJobsResponse>>(
        `${apiConfig.jobPostUrl}/external`,
        filterData
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for JobPostApi.getExternalJobs(): ${error}`);
      throw error;
    }
  }
}
