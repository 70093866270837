import Box from '@mui/material/Box';
import { Country } from '@interfaces/location.interfaces';
import { HOVER } from '@themes/ui/escoffier';
import React from 'react';

interface Locations {
  city: string;
  state: string;
  country: string;
}

export const getMultipleLocationsDisplay = (
  val: 'city' | 'state',
  locations: Locations[]
): JSX.Element[] | string => {
  const returnArray = locations.map((loc, idx: number) => {
    const _val =
      val === 'state' && loc.country === Country.PUERTO_RICO ? 'country' : val;
    return <Box key={`${loc[_val]}-${idx}`}>{loc[_val]}</Box>;
  });

  if (returnArray.length > 2) {
    return `Multiple ${val === 'city' ? 'cities' : 'states'}`;
  } else {
    return returnArray;
  }
};

export const withCellClickHandler = (
  cellValue: string,
  onClick: (e: any) => void
): JSX.Element => {
  const handleClick = React.useCallback(
    (e): void => {
      onClick(e);
    },
    [onClick]
  );

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          display: 'inline-block',
          '&:hover': {
            cursor: 'pointer',
            color: HOVER,
            textDecoration: 'underline'
          }
        }}
      >
        {cellValue}
      </Box>
    </>
  );
};
