import { PartnerApi } from '@api/Partner.api';
import { PartnerEmployeeProfileRequest } from '@api/models/partnerEmployeeApi.models';

interface PostOrPutPartnerEmployeeProfile {
  createProfile: (postReq: PartnerEmployeeProfileRequest) => Promise<void>;
  updateProfile: (
    id: number,
    putReq: PartnerEmployeeProfileRequest
  ) => Promise<void>;
}

export const usePostOrPutPartnerEmployeeProfile =
  (): PostOrPutPartnerEmployeeProfile => {
    const createProfile = async (
      postReq: PartnerEmployeeProfileRequest
    ): Promise<void> => {
      try {
        await PartnerApi.createPartnerEmployee(postReq);
      } catch (error: any) {
        console.error(
          'Error for usePostOrPutPartnerEmployeeProfile -> PartnerApi.createPartnerEmployee',
          error
        );
      }
    };

    const updateProfile = async (
      id: number,
      putReq: PartnerEmployeeProfileRequest
    ): Promise<void> => {
      try {
        await PartnerApi.updatePartnerEmployee(id, putReq);
      } catch (error: any) {
        console.error(
          'Error for usePostOrPutPartnerEmployeeProfile -> PartnerApi.updatePartnerEmployee',
          error
        );
      }
    };

    return { createProfile, updateProfile };
  };
