/**
 * Function to get UI search results. Not related to SearchApi database searching.
 * @param {T[]} array The array of objects being filtered by searchValue
 * @param {(keyof T)[]} searchParams string array of parameters within the type `T` of `array`
 * @param {string} searchValue Value to search for
 * @returns A filtered array of the same type as `array` param. Filtered based on `searchValue` by `searchParams`
 */
export const getFilteredSearchResults = <T extends Record<string, any>>(
  array: T[],
  searchParams: (keyof T)[],
  searchValue: string
): T[] => {
  return [...array].filter((item) =>
    searchParams.some(
      (param) =>
        item[param]
          .toString()
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) > -1
    )
  );
};
