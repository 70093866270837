import * as React from 'react';
import EscoffierLogo from '@assets/png/escoffier-global-solutions-logo.png';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { useTagManager } from '@common/hooks/useTagManager';

const Styled = {
  Logo: styled('img')({
    width: '100px',
    marginRight: '20px'
  })
};

const HeaderLogo: React.FC = () => {
  const { sendToGtm } = useTagManager();

  const { isAuthenticated } = useKeycloakContext();
  const currDashboardPath = !isAuthenticated ? '/' : '/user/dashboard';

  const handleClick = (): void => {
    sendToGtm('navigation', {
      navigate_to: currDashboardPath,
      component: 'header',
      link_text: 'Escoffier Logo'
    });
  };

  return (
    <Link
      component={RouterLink}
      to={currDashboardPath}
      data-testid="nav-logo-link"
      onClick={handleClick}
    >
      <Styled.Logo src={EscoffierLogo} alt="escoffier-connect-logo" />
    </Link>
  );
};

export default HeaderLogo;
