import * as React from 'react';
import { Switch, useParams, useRouteMatch } from 'react-router-dom';
import DocumentRepository from '@pages/CareerServices/StudentPage/DocumentRepository/DocumentRepository';
import EcRoute from '@common/utilities/EcRoute';
import { ExternshipFilter } from './studentViewFilters.constants';
import NestedLinkList from '@components/NestedLinkList';
import NoDataMessage from '@components/NoDataMessage';
import NotesSection from '@pages/CareerServices/StudentPage/Notes/NotesSection';
import PageFormLayoutComponent from '@components/Layout/PageFormLayoutComponent';
import { Paper } from '@mui/material';
import ProgressSection from '@pages/CareerServices/StudentPage/Progress/ProgressSection';
// TODO: eventually use ProtectedRoute
// import ProtectedRoute from '@common/utilities/PrivateRoute';
import StudentActivityHistory from '@pages/CareerServices/StudentPage/ActivityHistory/StudentActivityHistory';
import StudentHeaderSkeleton from '@components/Skeletons/StudentHeaderSkeleton';
import StudentInfoPageCardSkeleton from '@components/Skeletons/StudentInfoPageCardSkeleton';
import StudentPageHeader from './StudentPageHeader';
import StudentPageTimeCards from './TimeCards/StudentPageTimeCards';
import StudentSiteInfo from '@pages/CareerServices/StudentPage/StudentSiteInfo/StudentSiteInfo';
import { StudentViewProvider } from '@common/context/studentViewContext';
import { useFetchCandidateNotes } from '@common/fetches/useFetchCandidateNotes';
import { useFetchCandidateProfilePublic } from '@common/fetches/useFetchCandidateProfilePublic';
import { useFetchStudentExternshipsForCareerServices } from '@common/fetches/useFetchStudentExternshipsForCareerServices';
import { useFetchStudentWithSchoolStatusByCandidateId } from '@common/fetches/useFetchStudentWithSchoolStatusByCandidateId';
import { useKeycloakContext } from '@common/context/keycloakContext';

const pageNav = [
  { label: 'SITE INFORMATION', path: '/site-information' },
  { label: 'TIME CARD', path: '/time-card' },
  { label: 'DOCUMENTS', path: '/documents' },
  { label: 'VERIFICATION', path: '/verification' },
  { label: 'ACTIVITY HISTORY', path: '/activity' },
  { label: 'NOTES', path: '/notes' }
];

// TODO: This and child components need unit tests
const StudentPage: React.FC = () => {
  const { candidateId } = useParams<{ candidateId: string }>();
  const { path } = useRouteMatch();

  const [currentFilter, setCurrentFilter] = React.useState(
    ExternshipFilter.ALL_STATUSES
  );

  const parseCandidateId = parseInt(candidateId);
  const { keycloakUser } = useKeycloakContext();
  const { candidateProfile, loaded: profileLoaded } =
    useFetchCandidateProfilePublic(parseInt(candidateId));
  const { studentWithSchoolStatus, loaded: studentLoaded } =
    useFetchStudentWithSchoolStatusByCandidateId(parseCandidateId);
  const {
    externships,
    loaded,
    setExternships,
    allExternships,
    setAllExternships
  } = useFetchStudentExternshipsForCareerServices(parseCandidateId);

  const {
    candidateNotes,
    loaded: notesLoaded,
    fetchCandidateNotes
  } = useFetchCandidateNotes(candidateId);

  const externshipsLen = !!externships?.length;
  const finishedLoading =
    loaded === true &&
    profileLoaded === true &&
    notesLoaded === true &&
    studentLoaded === true;
  const studentHasExternships = finishedLoading && !!externshipsLen;

  const SiteInfo = (): JSX.Element => <StudentSiteInfo />;

  const TimeCard = (): JSX.Element => (
    <StudentPageTimeCards externships={externships} />
  );

  const Documents = (): JSX.Element => (
    <DocumentRepository externships={externships} />
  );

  const ActivityHistory = (): JSX.Element => (
    <StudentActivityHistory
      externships={externships}
      keycloakUser={keycloakUser}
    />
  );

  const Progress = (): JSX.Element => (
    <ProgressSection externships={externships} />
  );

  const Notes = (): JSX.Element => (
    <NotesSection
      notes={candidateNotes}
      refetch={fetchCandidateNotes}
      candidateId={candidateId}
    />
  );

  const NoExternshipsMessage = (): JSX.Element => (
    <Paper elevation={3} sx={{ padding: '120px 0px' }}>
      <NoDataMessage
        title={
          'There are no externships with the selected status for this student'
        }
        subtitle="Return to Externship Tracker"
        path="/externship-tracker"
      />
    </Paper>
  );

  // TODO: use ProtectedRoute instead of EcRoute
  const Content = (
    <Switch>
      <EcRoute
        exact
        path={`${path}/site-information`}
        title="Site Information"
        component={!studentHasExternships ? NoExternshipsMessage : SiteInfo}
      />
      <EcRoute
        exact
        path={`${path}/time-card`}
        title="Time Card"
        component={!studentHasExternships ? NoExternshipsMessage : TimeCard}
      />
      <EcRoute
        exact
        path={`${path}/documents`}
        title="Documents"
        component={!studentHasExternships ? NoExternshipsMessage : Documents}
      />
      <EcRoute
        exact
        path={`${path}/activity`}
        title="Activity History"
        component={
          !studentHasExternships ? NoExternshipsMessage : ActivityHistory
        }
      />
      <EcRoute
        exact
        path={`${path}/verification`}
        title="Verification"
        component={!studentHasExternships ? NoExternshipsMessage : Progress}
      />
      <EcRoute exact path={`${path}/notes`} title="Notes" component={Notes} />
    </Switch>
  );

  return (
    <StudentViewProvider
      value={{
        externships,
        setExternships,
        allExternships,
        currentFilter,
        setCurrentFilter,
        setAllExternships
      }}
    >
      <PageFormLayoutComponent
        keepGutter
        headerContent={
          finishedLoading ? (
            <StudentPageHeader
              profile={candidateProfile!}
              studentWithSchoolStatus={studentWithSchoolStatus!}
            />
          ) : (
            <StudentHeaderSkeleton />
          )
        }
        sideContent={<NestedLinkList links={pageNav} />}
        content={!finishedLoading ? <StudentInfoPageCardSkeleton /> : Content}
      />
    </StudentViewProvider>
  );
};

export default StudentPage;
