import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  redirectStorageKeys,
  redirectStorageMessages,
  useStorageRedirectWithExpiration
} from '@common/helpers/storage';
import { BannerType } from '@api/models/bannersApi.models';
import Box from '@mui/material/Box';
import { CandidateApi } from '@api/Candidate.api';
import { CandidatePersonalizePutReq } from '@api/models/candidateApi.models';
import { FileType } from '@api/models/fileApi.models';
import FormActionsComponent from '@components/Forms/FormActionsComponent';
import FormWrapper from '@components/FormWrapper';
import { getSavedRTEContent } from '@components/Forms/getSavedRTEContent';
import PageDescriptionComponent from '@components/Layout/Common/PageDescriptionComponent';
import PageFormLayoutComponent from '@components/Layout/PageFormLayoutComponent';
import PersonalStatementSection from '@pages/Candidate/CandidateProfileSetupPage/CandidatePersonalizeStep/PersonalStatementSection';
import PortfolioSection from '@pages/Candidate/CandidateProfileSetupPage/CandidatePersonalizeStep/PortfolioSection';
import ProfileResumeSection from '@pages/Candidate/CandidateProfileSetupPage/CandidatePersonalizeStep/ProfileResumeSection';
import { KeycloakUserRole } from '@api/models/userApi.models';
import { scrollToError } from '@common/helpers/scrollToError';
import SocialMediaSection from '@pages/Candidate/CandidateProfileSetupPage/CandidatePersonalizeStep/SocialMediaSection';
import { strippedText } from '@common/helpers/strippedText';
import { useFetchCandidateProfile } from '@common/fetches/useFetchCandidateProfile';
import { useFetchUserBanners } from '@common/fetches/useFetchUserBanners';
import { useHistory } from 'react-router';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import { useTagManager } from '@common/hooks/useTagManager';

const CANDIDATE_CULINARY_PHOTOS_LIMIT = 16;

interface Props {
  handleStepper: (next: boolean) => void;
}

const CandidatePersonalizeStep: React.FC<Props> = ({ handleStepper }) => {
  const { sendToGtm } = useTagManager();
  const [candidateId] = useStore<number>(UseStoreKeys.CANDIDATE_ID);

  const { keycloakUser } = useKeycloakContext();
  const { getActiveBannerByType, closeBannerByBannerId } = useFetchUserBanners(
    keycloakUser.sub
  );
  const banner = getActiveBannerByType(BannerType.UPDATE_PROFILE);

  const history = useHistory();

  const { candidateProfile, profileComplete } =
    useFetchCandidateProfile(candidateId);

  const [files, setFiles] = React.useState<FileType[]>([]);
  const [resumes, setResumes] = React.useState<FileType[]>([]);

  const portfolioRef = React.useRef<HTMLElement | undefined>();

  const jobAppStorage = useStorageRedirectWithExpiration(
    redirectStorageKeys.JOB_APPLICATION
  );

  const careerPathStorage = useStorageRedirectWithExpiration(
    redirectStorageKeys.CAREER_PATH
  );

  const methods = useForm<CandidatePersonalizePutReq>({
    defaultValues: {
      culinaryPhotos: [],
      statement: '',
      websiteUrl: '',
      instagramUrl: '',
      facebookUrl: '',
      linkedInUrl: '',
      resumeId: null
    }
  });

  const formOrder = {
    culinaryPhotos: 10,
    statement: 20,
    websiteUrl: 30,
    instagramUrl: 35,
    facebookUrl: 40,
    linkedInUrl: 45,
    resumeId: 50
  };

  const {
    handleSubmit,
    reset,
    setError,
    getValues,
    formState: { errors }
  } = methods;

  const culinaryPhotosErrors = React.useRef(false);

  const handleInvalidForm = (): void => {
    const statement = getValues().statement;
    if (strippedText(statement) === '') {
      setError('statement', {
        type: 'manual',
        message: 'Personal statement is required'
      });
    }

    scrollToError(errors, formOrder);
  };

  const savedPersonalStatement = React.useMemo(() => {
    if (!candidateProfile) return '';

    return getSavedRTEContent(candidateProfile.statement);
  }, [candidateProfile]);

  const getCulinaryPhotoIds = (photos): number[] => {
    if (!photos) return [];

    const culinaryPhotos = photos.map((photo) => photo.file);
    setFiles(culinaryPhotos);

    return culinaryPhotos.map((photo) => photo.id);
  };

  const handleValidForm = async (
    data,
    onSuccess: () => void
  ): Promise<void> => {
    let extraErrors = false;

    if (strippedText(data.statement) === '') {
      extraErrors = true;
      setError('statement', {
        type: 'manual',
        message: 'Personal statement is required'
      });
    }

    if (extraErrors) {
      scrollToError(methods.formState.errors, formOrder);
    }

    if (culinaryPhotosErrors.current) {
      portfolioRef?.current?.scrollIntoView({ behavior: 'smooth' });
      culinaryPhotosErrors.current = !!errors['culinaryPhotos'];
    }

    const postBody: CandidatePersonalizePutReq = {
      culinaryPhotos: data.culinaryPhotos,
      statement: data.statement,
      websiteUrl: data.websiteUrl || null,
      instagramUrl: data.instagramUrl || null,
      facebookUrl: data.facebookUrl || null,
      linkedInUrl: data.linkedInUrl || null,
      resumeId: data.resumeId
    };

    if (
      postBody.culinaryPhotos.length <= CANDIDATE_CULINARY_PHOTOS_LIMIT &&
      !culinaryPhotosErrors.current
    ) {
      let successfullyUploaded;

      try {
        await CandidateApi.updateCandidatePersonalize(candidateId, postBody);
        successfullyUploaded = true;
        onSuccess();
      } catch (error: any) {
        successfullyUploaded = false;
        console.error(
          'Error for CandidatePersonalizeStep.updateCandidatePersonalize',
          error
        );

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      } finally {
        if (banner) {
          closeBannerByBannerId(banner.id);
        }
        if (!profileComplete) {
          sendToGtm('profile_completed', {
            role: KeycloakUserRole.CANDIDATE,
            success: successfullyUploaded
          });
        }
      }
    }
  };

  const handleFormSubmit = (onSuccess: () => void): void => {
    const onValid = async (data): Promise<void> =>
      handleValidForm(data, onSuccess);
    handleSubmit(onValid, handleInvalidForm)();
  };

  const handleLastStepAction = React.useCallback((): void => {
    if (
      jobAppStorage &&
      jobAppStorage?.message === redirectStorageMessages.INCOMPLETE_PROFILE
    ) {
      history.push(jobAppStorage.redirect, { shouldClearSession: true });
    } else if (
      careerPathStorage &&
      careerPathStorage?.message === redirectStorageMessages.INCOMPLETE_PROFILE
    ) {
      history.push(careerPathStorage.redirect, { shouldClearSession: true });
    } else {
      history.push(`/candidate/${candidateId}/profile`);
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [jobAppStorage, careerPathStorage]);

  React.useEffect(() => {
    if (candidateProfile) {
      const { candidateCulinaryPhotos, resume } = candidateProfile;

      reset({
        ...candidateProfile,
        culinaryPhotos: getCulinaryPhotoIds(candidateCulinaryPhotos),
        statement: savedPersonalStatement,
        resumeId: resume ? resume.id : null
      });

      if (resume) {
        setResumes([resume]);
      }
    }
  }, [candidateProfile]);

  React.useEffect(() => {
    if (errors['culinaryPhotos']) {
      culinaryPhotosErrors.current = true;
    }
  }, [errors['culinaryPhotos']]);

  return (
    <PageFormLayoutComponent
      sideContent={
        <PageDescriptionComponent title="Personalize">
          Add elements to your profile that will make you stand out. Your
          personal statement is an opportunity to set yourself apart and help us
          make a better match.
        </PageDescriptionComponent>
      }
      content={
        <FormWrapper>
          <FormProvider {...methods}>
            <Box ref={portfolioRef}>
              <PortfolioSection
                culinaryPhotos={files}
                photoLimit={CANDIDATE_CULINARY_PHOTOS_LIMIT}
                setImgUploadError={setError}
              />
            </Box>
            <PersonalStatementSection defaultValue={savedPersonalStatement} />
            <SocialMediaSection />
            <ProfileResumeSection resumes={resumes} />
          </FormProvider>
        </FormWrapper>
      }
      actions={
        <FormActionsComponent
          onSubmit={handleFormSubmit}
          submitBtnText="Save and Finish"
          changeDetected={methods.formState.isDirty}
          handleStepper={handleStepper}
          lastStepAction={handleLastStepAction}
        />
      }
    />
  );
};

export default CandidatePersonalizeStep;
