import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { Badge } from '@common/mappings/BadgeMappings';
import ImageWithBadge from '@components/ImageWithBadge';
import { styled } from '@mui/system';

const Styled = {
  CompanySection: styled(Box)({
    margin: '0 auto',
    padding: '14px 20px'
  }),
  LogoWrapper: styled(Box)({
    img: {
      objectFit: 'scale-down'
    }
  }),
  CompanyLogo: styled('img')({
    display: 'block',
    objectPosition: 'center',
    height: '53px',
    width: '100%',
    objectFit: 'scale-down'
  }),
  CompanyName: styled(Box)({
    height: '53px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: '16px'
  })
};

const JobCardLogoSection: React.FC<{
  logo?: string;
  name: string;
  badge?: Badge;
}> = ({ logo, name, badge }) => {
  return (
    <Styled.CompanySection>
      {logo ? (
        <Styled.LogoWrapper>
          <ImageWithBadge
            dataTestId="company-logo"
            src={logo}
            height="53px"
            width="100%"
            badge={badge}
            noBorder
            expandableBadge
            noBorderRadius
          />
        </Styled.LogoWrapper>
      ) : (
        <Styled.CompanyName>
          <Typography variant="EC_TYPE_2XS" data-testid="company-name">
            {name}
          </Typography>
        </Styled.CompanyName>
      )}
    </Styled.CompanySection>
  );
};

export default JobCardLogoSection;
