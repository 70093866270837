export enum KeycloakUserRole {
  ADMIN = 'admin',
  CANDIDATE = 'candidate',
  EMPLOYER = 'employer',
  EXTERNSHIP_TIMESHEET_APPROVER = 'externship-timesheet-approver',
  RECRUITER = 'recruiter',
  ESCOFFIER_ADMIN = 'escoffier-admin',
  EC_CAREER_SERVICE = 'escoffier-career-service',
  CHEF_INSTRUCTOR = 'chef-instructor',
  PARTNER = 'partner',
  GLRC_USER = 'glrc-user',
  // TODO: esource-user is currently front end only due to GLRC name change.
  ESOURCE_USER = 'esource-user',
  PENDING_USER = 'pending-user',
  // Only used in CTA from email sent to proposed manager to create account
  PROPOSED_EXTERNSHIP_MANAGER = 'proposed-externship-manager'
}

export enum KeycloakAppRole {
  EMPLOYER = 'app-employer',
  EXTERNSHIP_TIMESHEET_APPROVER = 'app-externship-timesheet-approver',
  RECRUITER = 'app-recruiter',
  ESOURCE_USER = 'app-glrc-user',
  PENDING_USER = 'app-pending-user',
}

export function getKeycloakUserRoleFromKeycloakAppRole (keycloakAppRole: KeycloakAppRole): KeycloakUserRoleDefinition {
  // Map app roles to user roles
  switch (keycloakAppRole) {
    case KeycloakAppRole.EMPLOYER:
      return { name: KeycloakUserRole.EMPLOYER, clientRole: true, id: KeycloakUserRole.EMPLOYER };
    case KeycloakAppRole.EXTERNSHIP_TIMESHEET_APPROVER:
      return { name: KeycloakUserRole.EXTERNSHIP_TIMESHEET_APPROVER, clientRole: true, id: KeycloakUserRole.EXTERNSHIP_TIMESHEET_APPROVER };
    case KeycloakAppRole.RECRUITER:
      return { name: KeycloakUserRole.RECRUITER, clientRole: true, id: KeycloakUserRole.RECRUITER };
    case KeycloakAppRole.ESOURCE_USER:
      return { name: KeycloakUserRole.GLRC_USER, clientRole: true, id: KeycloakUserRole.GLRC_USER };
    case KeycloakAppRole.PENDING_USER:
      return { name: KeycloakUserRole.PENDING_USER, clientRole: true, id: KeycloakUserRole.PENDING_USER };
    default:
      throw new Error(`Invalid KeycloakAppRole: ${keycloakAppRole}`);
  }
}

export const IdentityProviders = {
  STUDENT_PORTAL:
    process.env.REACT_APP_STUDENT_PORTAL_IDP_ALIAS || 'StudentPortalSSO'
};

export interface SeatRoleSelectOption<T extends KeycloakUserRole> {
  label: KeycloakUserRole;
  value: T;
}

export interface KeycloakUserToRoleLink {
  role: KeycloakUserRoleDefinition;
}

export interface KeycloakUserRoleDefinition {
  clientRole: boolean;
  id: string;
  name: string;
}

export interface IKeycloakUser {
  id: string;
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  preferred_username: string;
  roles: string[];
  sub: string;
}

// Matches EC.API/src/interfaces/users.interface.ts
export interface KeycloakUser {
  id: number;
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  userToRoles: KeycloakUserRoleDefinition[];
}

export interface UserIdp {
  identityProvider: string;
  userId: string;
  userName: string;
}
