import * as React from 'react';
import { Box, Stack } from '@mui/material';
import CtaButton from '@components/CtaButton';
import EcDialog from '@components/EcDialog';
import EllipseButton from '@components/EllipseButton';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const Styled = {
  Root: styled(Box)({
    position: 'relative',
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    zIndex: 1,
    [theme.breakpoints.up('sm')]: {
      boxShadow: '0 4px 2px -2px rgba(0,0,0,0.15)'
    }
  }),
  Inner: styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
    maxWidth: '1820px',
    padding: '15px 18px',
    [theme.breakpoints.up('sm')]: {
      padding: '15px 28px'
    },
    [theme.breakpoints.up('md')]: {
      padding: '15px 64px'
    }
  }),
  Left: styled(Stack)({
    flex: '1 1 840px',
    justifyContent: 'start'
  }),
  Right: styled(Stack)({
    flex: '1 0.5 601px',
    alignItems: 'center',
    justifyContent: 'end'
  }),
  CtaBox: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '45px',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginTop: 0,
      columnGap: '8px'
    },
    '& .top-bar-cta-btn': {
      width: '100%'
    },
    '& .cta-btn-divider': {
      width: '100%',
      marginTop: '11px',
      marginBottom: '31px',
      borderBottom: `1px solid ${theme.palette.GRAY_2.main}`,
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    }
  })
};

interface Props {
  openResumeModal: () => void;
}

interface CtaBtnProps extends Props {
  featureFlag: boolean;
}

const CtaButtons = ({
  openResumeModal,
  featureFlag
}: CtaBtnProps): JSX.Element => (
  <Styled.CtaBox>
    {featureFlag && (
      <>
        <CtaButton
          className="top-bar-cta-btn"
          onClick={openResumeModal}
          label="Download Printable Resume"
          textVariant="EC_TYPE_SM"
          btnSx={{ height: '40px' }}
          btnVariant="contained"
          startIcon={<i className="ri-external-link-line" />}
          dataTestId="candidate-top-bar-resume-btn"
        />
      </>
    )}
  </Styled.CtaBox>
);

const CandidateProfileTopBar = ({
  openResumeModal
}: Props): React.ReactElement => {
  const { width } = useWindowDimensions();
  const [modalOpen, setModalOpen] = React.useState(false);

  const featureFlags = useFeatureFlags();
  const resumeFeatureFlag = featureFlags.CANDIDATE_RESUME_BUILDER;

  const handleOpenDialog = React.useCallback((): void => {
    setModalOpen(true);
  }, [setModalOpen]);

  const handleCloseDialog = React.useCallback((): void => {
    setModalOpen(false);
  }, [setModalOpen]);

  React.useEffect(() => {
    if (width > 599 && modalOpen) {
      setModalOpen(false);
    }
  }, [width, modalOpen]);

  return (
    <>
      <Styled.Root data-testid="candidate-top-bar-root">
        <Styled.Inner>
          <Styled.Left direction="row">
            <CtaButton
              label="Candidate Dashboard"
              to="/candidate/dashboard"
              btnVariant="text"
              color="GRAY_3"
              textColor={theme.palette.GRAY_3.main}
              textVariant="EC_TYPE_SM"
              leftArrow
              btnSx={{ padding: 0 }}
            />
          </Styled.Left>
          <Styled.Right direction="row">
            {width > 599 ? (
              <CtaButtons
                featureFlag={resumeFeatureFlag}
                openResumeModal={openResumeModal}
              />
            ) : (
              <EllipseButton onClick={handleOpenDialog} />
            )}
          </Styled.Right>
        </Styled.Inner>
      </Styled.Root>
      <EcDialog
        title="Profile Options"
        open={modalOpen}
        handleClose={handleCloseDialog}
        contentStyle={{ minWidth: '200px' }}
        dataTestId="candidate-top-bar-dialog"
      >
        <CtaButtons
          featureFlag={resumeFeatureFlag}
          openResumeModal={openResumeModal}
        />
      </EcDialog>
    </>
  );
};

export default CandidateProfileTopBar;
