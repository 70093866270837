import * as React from 'react';
import {
  authorizedUserSettingsNav,
  candidateSettingsNav,
  careerServicesSettingsNav,
  ecAdminViewingAsEmployerNav,
  employerSettingsNav,
  escoffierAdminSettingsNav,
  recruiterSettingsNav
} from '@pages/Shared/SettingsPage/SettingsNavLinks';
import { Switch, useRouteMatch } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import AccountSettings from '@pages/Shared/SettingsPage/AccountSettings/AccountSettings';
import CompanyLogoHeader from '@components/CompanyLogoHeader';
import CompanySettings from '@pages/Shared/SettingsPage/CompanySettings/CompanySettings';
import CompanySettingsRedesign from './CompanySettingsRedesign/CompanySettingsRedesign';
import CustomizationSettings from './CustomizationSettings/CustomizationSettings';
import EcRoute from '@common/utilities/EcRoute';
import LocationsSettings from '@pages/Shared/SettingsPage/LocationsSettings/LocationsSettings';
import NestedLinkList from '@components/NestedLinkList';
import NotificationSettings from '@pages/Shared/SettingsPage/NotificationSettings/NotificationSettings';
import PageFormLayoutComponent from '@components/Layout/PageFormLayoutComponent';
import PrivateRoute from '@common/utilities/PrivateRoute';
import { roles } from '@api/models/userApi.models';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchCompanyProfile } from '@common/fetches/useFetchCompanyProfile';
import { useFetchCompanyTheme } from '@common/fetches/useFetchCompanyTheme';
import { useFetchESourceGroups } from '@common/fetches/useFetchESourceGroups';
import { useFetchSettings } from '@common/fetches/useFetchSettings';
import { useFetchUserIdpData } from '@common/fetches/useFetchUserIdpData';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import { useToast } from '@components/Toast';

const SettingsPage: React.FC = () => {
  const toastProps = useToast();

  const { companyId, isViewingAsEmployer } = useParamsOrStoreCompanyId();
  const { companyProfile, loading: companyLoading } =
    useFetchCompanyProfile(companyId);

  const featureFlags = useFeatureFlags();
  const { path } = useRouteMatch();
  const theme = useTheme();
  const isLessThanHeaderBreakpoint = useMediaQuery(
    theme.breakpoints.down('headerMobile')
  );
  const {
    isCandidate,
    isEcCareerService,
    isEmployer,
    isRecruiter,
    isEcAdmin,
    isGlrcUser,
    isChefInstructor,
    keycloakUser
  } = useKeycloakContext();
  const { userIdp, loading: idpLoading } = useFetchUserIdpData(
    keycloakUser.sub
  );

  // Set tab for company settings page
  const [tabValue, setTabValue] = React.useState('1');
  const { esourceGroups, setESourceGroups } = useFetchESourceGroups(companyId);

  const {
    loginSettings,
    companySettings,
    sentInvites,
    joinRequests,
    fetchCandidateSettings,
    fetchCompanyLoginSettings,
    fetchSentInvites,
    fetchJoinRequests,
    fetchCompanySettingsRequestsAndInvites,
    fetchCompanySettings,
    setLoginSettings,
    setCompanySettings,
    loading: settingsLoading
  } = useFetchSettings();

  const {
    companyTheme,
    setCompanyTheme,
    loading: companyThemeLoaded
  } = useFetchCompanyTheme(companyId);

  const userHasIdp = userIdp ? userIdp.length > 0 : false;
  const isCompany = isEmployer || isRecruiter;

  const [role] = useStore<string>(UseStoreKeys.ROLE);
  let nav;

  const handleSeatRequestUpdate = (): void => {
    fetchJoinRequests(companyId);
    fetchCompanySettings(companyId, companyProfile?.name);
  };

  // TODO: Adjust Account Settings and Notifications to work with
  // an EC ADMIN or Parent Company viewing children settings
  if (isViewingAsEmployer) {
    nav = ecAdminViewingAsEmployerNav(
      featureFlags,
      !!companyProfile?.customThemeAccess
    );
  } else {
    switch (role) {
      case roles.EMPLOYER:
        nav = employerSettingsNav(
          featureFlags,
          !!companyProfile?.customThemeAccess
        );
        break;
      case roles.RECRUITER:
        nav = recruiterSettingsNav;
        break;
      case roles.CANDIDATE:
        nav = candidateSettingsNav;
        break;
      case roles.ESCOFFIER_ADMIN:
        nav = escoffierAdminSettingsNav;
        break;
      case roles.EC_CAREER_SERVICE:
        nav = careerServicesSettingsNav;
        break;

      default:
        nav = authorizedUserSettingsNav;
    }
  }

  if (!featureFlags.NOTIFICATION_SETTINGS) {
    nav = nav.filter((setting) => setting.label !== 'Notifications');
  }

  const handleFetchSentInvites = React.useCallback((): void => {
    fetchSentInvites(companyId);
  }, [fetchSentInvites]);

  React.useEffect(() => {
    if ((isCompany && !!companyId && !!companyProfile) || isGlrcUser) {
      fetchCompanyLoginSettings(keycloakUser.sub);
    }
  }, [isCompany, companyProfile, companyId, keycloakUser]);

  React.useEffect(() => {
    if ((isEmployer || isEcAdmin) && !!companyId && !!companyProfile) {
      fetchCompanySettingsRequestsAndInvites(companyId, companyProfile.name);
    }
  }, [isEmployer, companyProfile, companyId, keycloakUser]);

  React.useEffect(() => {
    if (isCandidate) {
      fetchCandidateSettings(keycloakUser.sub);
    }

    if (!isCandidate && !isCompany) {
      setLoginSettings((prev) => ({
        ...prev,
        email: keycloakUser.email,
        firstName: keycloakUser.given_name,
        lastName: keycloakUser.family_name,
        phone: '',
        jobTitle: ''
      }));
    }
  }, [isCandidate, isCompany, keycloakUser]);

  const EcAccountSettings = (): JSX.Element | null =>
    !idpLoading && !settingsLoading ? (
      <AccountSettings
        user={keycloakUser}
        isUserSSO={userHasIdp}
        isCandidate={isCandidate}
        isCompany={isCompany}
        isGlrcUser={isGlrcUser}
        isChefInstructor={isChefInstructor}
        isEcAdmin={isEcAdmin}
        isEcCareerService={isEcCareerService}
        loginSettings={loginSettings}
        setLoginSettings={setLoginSettings}
      />
    ) : null;

  const EcCompanySettings = (): JSX.Element | null =>
    !companyLoading && !!companyId ? (
      <>
        {featureFlags.COMPANY_SETTINGS_REDESIGN ? (
          <CompanySettingsRedesign
            companyId={companyId}
            glrcSeats={companyProfile?.glrcSeats}
            glrcPermissions={companyProfile?.glrcPermissions}
            companySettings={companySettings}
            sentInvites={sentInvites}
            joinRequests={joinRequests}
            setCompanySettings={setCompanySettings}
            fetchSentInvites={handleFetchSentInvites}
            handleSeatRequestUpdate={handleSeatRequestUpdate}
            esourceGroups={esourceGroups}
            setESourceGroups={setESourceGroups}
            tabValue={tabValue}
            setTabValue={setTabValue}
          />
        ) : (
          <CompanySettings
            companyId={companyId}
            glrcSeats={companyProfile?.glrcSeats}
            companySettings={companySettings}
            sentInvites={sentInvites}
            joinRequests={joinRequests}
            setCompanySettings={setCompanySettings}
            fetchSentInvites={handleFetchSentInvites}
            handleSeatRequestUpdate={handleSeatRequestUpdate}
          />
        )}
      </>
    ) : null;

  const EcCustomizationSettings = (): JSX.Element =>
    !companyLoading && !companyThemeLoaded && companyId ? (
      <CustomizationSettings
        companyId={companyId}
        companyTheme={companyTheme}
        setCompanyTheme={setCompanyTheme}
        toastProps={toastProps}
      />
    ) : (
      <></>
    );

  const Settings = (
    <Switch>
      <EcRoute
        path={`${path}/account`}
        title="Account Settings"
        component={EcAccountSettings}
      />
      <PrivateRoute
        exact
        allowedRoles={[roles.EMPLOYER, roles.RECRUITER, roles.ESCOFFIER_ADMIN]}
        path={`${path}/company`}
        component={EcCompanySettings}
        title="Company Settings"
      />
      {featureFlags.NOTIFICATION_SETTINGS && (
        <EcRoute
          exact
          path={`${path}/notifications`}
          title="Notification Settings"
          component={NotificationSettings}
        />
      )}

      {featureFlags.COMPANY_LOCATION && (
        <EcRoute
          exact
          path={`${path}/locations`}
          title="Manage Locations"
          component={LocationsSettings}
        />
      )}
      {featureFlags.GLRC_CUSTOMIZATION && companyProfile?.customThemeAccess && (
        <EcRoute
          exact
          path={`${path}/customizations`}
          title="Customization Settings"
          render={EcCustomizationSettings}
        />
      )}
    </Switch>
  );

  return (
    <PageFormLayoutComponent
      headerContent={
        companyProfile && <CompanyLogoHeader company={companyProfile} />
      }
      sideContent={<NestedLinkList links={nav} />}
      hideSideContent={isLessThanHeaderBreakpoint}
      content={Settings}
    />
  );
};

export default SettingsPage;
