import { Timesheet, TimesheetStatus } from '@api/models/externshipApi.models';
import { convertMinutesToHoursAndMinutes } from '../timesheetHelpers/convertMinsToHoursAndMins';

type StatusHours = {
  approvedTime: string;
  submittedTime: string;
  pendingProgress: number;
  approvedMinutes: number;
};

export const getTimesheetStatusHours = (
  timesheets: Timesheet[],
  externshipReqHrs?: number
): StatusHours => {
  let approvedMinutes = 0;
  let submittedMinutes = 0;

  timesheets.forEach((ts) => {
    const minutes = ts.dailyEntries.reduce((a, b) => a + b.minutes, 0);

    const acceptedStatuses = [
      TimesheetStatus.ACCEPTED,
      TimesheetStatus.SENT_FAILED,
      TimesheetStatus.SENT_SUCCESSFULLY,
      TimesheetStatus.SENT_UNKNOWN
    ];

    const submittedStatuses = [
      TimesheetStatus.PENDING_SUPERVISOR_APPROVAL,
      TimesheetStatus.PENDING_INSTRUCTOR_APPROVAL
    ];

    if (acceptedStatuses.includes(ts.status)) {
      approvedMinutes += minutes;
    } else if (submittedStatuses.includes(ts.status)) {
      submittedMinutes += minutes;
    }
  });

  const progress = externshipReqHrs
    ? 100 * ((submittedMinutes + approvedMinutes) / (externshipReqHrs * 60))
    : 0;

  const pendingProgress = progress >= 100 ? 100 : progress;

  return {
    approvedTime: convertMinutesToHoursAndMinutes(approvedMinutes),
    submittedTime: convertMinutesToHoursAndMinutes(submittedMinutes),
    pendingProgress,
    approvedMinutes
  };
};
