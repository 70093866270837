import { useEffect, useState } from 'react';
import { CandidateApi } from '@api/Candidate.api';
import { JobApplicationApi } from '@api/JobApplication.api';
import { JobApplicationAppliedCandidate } from '@api/models/jobApplicationApi.models';

interface CandidatesByJobId {
  applicantData: JobApplicationAppliedCandidate[] | null;
  invitedCandidates: number[] | [];
  loading: boolean;
  loaded: true | null;
}

export const useFetchCandidatesByJobId = (jobId: string): CandidatesByJobId => {
  const jobNum = parseInt(jobId);
  const [applicantData, setApplicantData] = useState<
    JobApplicationAppliedCandidate[] | null // eslint-disable-line @typescript-eslint/indent
  >(null);
  const [invitedCandidates, setInvitedCandidates] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  const fetchCandidates = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await JobApplicationApi.getCandidatesByJobPostId(jobNum);
      setApplicantData(res.data);
    } catch (error: any) {
      console.error(
        'Error for useFetchCandidatesByJobId -> JobApplicationApi.getCandidatesByJobPostId()',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchInvitedCandidates = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await CandidateApi.findInvitedCandidatesByJobPostId(jobNum);
      setInvitedCandidates(res.data);
    } catch (error: any) {
      console.error(
        'Error for useFetchCandidatesByJobId -> CandidateApi.findInvitedCandidatesByJobPostId()',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    Promise.all([fetchCandidates(), fetchInvitedCandidates()]).finally(() => {
      setLoaded(true);
    });
  }, []);

  return { applicantData, invitedCandidates, loading, loaded };
};
