import * as React from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { Grid, Skeleton } from '@mui/material';
import RhfLocationSearch, {
  PlaceType
} from '@components/Forms/RhfLocationSearch';
import { ApiResponse } from '@api/models/base.models';
import { Codebook } from '@api/models/codebook.models';
import { ExternshipApi } from '@api/Externships.api';
import { ExternshipEntity } from '@api/models/externshipApi.models';
import { ExternshipLocationPutReq } from '@api/models/externshipProposalApi.models';
import ExternshipProposalStepLayout from '@pages/Candidate/ExternshipProposalPage/ExternshipProposalStepLayout';
import isEqual from 'lodash/isEqual';
import { Location } from '@interfaces/location.interfaces';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import ReactHookFormsInputPhoneMask from '@components/Forms/ReactHookFormsInputPhoneMask';
import ReactHookFormsMultiSelect from '@components/Forms/ReactHookFormsMultiSelect';
import RhfCheckbox from '@components/Forms/RhfCheckbox';
import RhfLocationCustom from '@components/Forms/RhfLocationCustom';
import { scrollToError } from '@common/helpers/scrollToError';
import StepSection from '@components/StepSection';
import { useFetchExternships } from '@common/fetches/useFetchExternships';
import { useParams } from 'react-router-dom';
import { useStepperContext } from '@common/context/stepperContext';

interface RouteParams {
  enrollmentId?: string;
  courseId?: string;
  externshipId?: string;
}

type FormValues = Omit<ExternshipLocationPutReq, 'candidateId'> & {
  companyName: string;
  customLocation: boolean;
  streetName?: string;
  streetNumber?: string;
  addressLineTwo?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  phone?: string;
};

interface Props {
  handleStepper: (next: boolean) => void;
  setProposedCompanyId: React.Dispatch<
    React.SetStateAction<number | undefined> // eslint-disable-line @typescript-eslint/indent
  >;
  candidateId: number;
  loaded: boolean;
  programOptions?: Codebook[];
  externshipId?: number;
  setExternshipId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const ExternshipProposalLocationStep: React.FC<Props> = ({
  handleStepper,
  setProposedCompanyId,
  candidateId,
  loaded,
  programOptions,
  externshipId,
  setExternshipId
}) => {
  const {
    enrollmentId,
    courseId,
    externshipId: paramsExternshipId
  } = useParams<RouteParams>();

  const { stepperLoading, setStepperLoading } = useStepperContext();

  const { externshipById: draftExternship } = useFetchExternships(
    null,
    paramsExternshipId ?? externshipId
  );

  const [draftPutReq, setDraftPutReq] =
    React.useState<Omit<ExternshipLocationPutReq, 'candidateId'>>();
  const methods = useForm<FormValues>({
    defaultValues: {
      companyName: '',
      location: undefined,
      phone: '',
      supportedPrograms: [],
      customLocation: false
    }
  });

  const formOrder = {
    location: 10,
    customLocation: 11,
    phone: 20,
    supportedPrograms: 30
  };

  const { handleSubmit, watch, setValue, setError, reset } = methods;
  const location = watch('location');
  const customLocation = watch('customLocation');
  const selectedCountry = watch('country');
  const internationalAddress =
    selectedCountry !== undefined && selectedCountry !== 'United States';

  React.useEffect(() => {
    if (draftExternship) {
      const programs = draftExternship.proposedCompany?.supportedPrograms?.map(
        (p) => p.value
      );

      const draft = {
        location: {
          ...draftExternship.proposedCompany.location
        },
        companyName: draftExternship?.proposedCompany?.location?.name ?? '',
        phone: draftExternship?.proposedCompany.phone,
        supportedPrograms: programs,
        customLocation: !draftExternship?.proposedCompany?.location?.placeId
      };

      reset(draft);
      setDraftPutReq(draft);
    }
  }, [draftExternship]);

  React.useEffect(() => {
    if (location) {
      if (location?.phone) {
        const companyPhone = location.phone || '';
        setValue('phone', companyPhone);
      }
      if (location?.name) {
        setValue('companyName', location.name || '');
      }
      if (location?.city) {
        setValue('city', location.city || '');
      }
      if (location?.state) {
        setValue('state', location.state || '');
      }
      if (location?.country) {
        setValue('country', location.country || '');
      }
      if (location?.streetName) {
        setValue('streetName', location.streetName || '');
      }
      if (location?.streetNumber) {
        setValue('streetNumber', location.streetNumber || '');
      }
      if (location?.addressLineTwo) {
        setValue('addressLineTwo', location.addressLineTwo || '');
      }
      if (location?.zip) {
        setValue('postalCode', location.zip || '');
      }
    }
  }, [location]);

  const handleSetProposedCompanyId = React.useCallback(
    (id: number) => {
      setProposedCompanyId(id);
    },
    [setProposedCompanyId]
  );

  const handleInvalidForm = (errors: FieldErrors): void => {
    scrollToError(errors, formOrder);
  };

  const handleValidForm = async (
    data: FormValues,
    onSuccess: () => void
  ): Promise<void> => {
    let mainLocation: Location;

    if (!data.customLocation) {
      const { location } = data;
      mainLocation = location;
    } else {
      const location: Location = {
        lat: 0,
        lng: 0,
        description: `${data.companyName}, ${data.streetNumber} ${data.streetName}, ${data.city}, ${data.state}, ${data.country}`,
        streetNumber: data.streetNumber,
        addressLineTwo: data.addressLineTwo,
        city: data.city,
        streetName: data.streetName,
        state: data.state,
        zip: data.postalCode,
        country: data.country,
        phone: data.phone
      };
      mainLocation = location;
    }

    const postBody: Omit<ExternshipLocationPutReq, 'companyName'> = {
      candidateId,
      location: { ...mainLocation, name: data.companyName },
      phone: data.phone,
      supportedPrograms: data.supportedPrograms,
      enrollmentId: enrollmentId ?? '',
      courseId: courseId ?? ''
    };

    let missingAddressInfoError = false;

    if (
      !mainLocation.streetNumber ||
      !mainLocation.streetName ||
      !mainLocation.zip
    ) {
      setError('location', {
        type: 'manual',
        message:
          'This location is missing address information. Please enter a new location.'
      });
      missingAddressInfoError = true;
    }

    if (missingAddressInfoError) return;

    try {
      setStepperLoading(true);
      let res: ApiResponse<ExternshipEntity>;

      if (!draftExternship) {
        res = await ExternshipApi.create(postBody);

        setExternshipId(res.data.id);
        handleSetProposedCompanyId(res.data.proposedCompany.id);
        onSuccess();
      } else {
        if (isEqual(draftPutReq, data)) {
          handleSetProposedCompanyId(draftExternship.proposedCompany.id);
          onSuccess();
        } else {
          res = await ExternshipApi.updateCompanyInfo(
            draftExternship.id,
            postBody
          );

          handleSetProposedCompanyId(res.data.proposedCompany.id);
          onSuccess();
        }
      }
    } catch (error: any) {
      console.error(
        `Error for ExternshipProposalLocationStep.handleValidForm(): ${error}`
      );

      const errData = error.response.data.data;

      if (errData?.length) {
        errData.forEach(({ field, message }) =>
          setError(field, { type: 'manual', message })
        );
      }

      scrollToError(methods.formState.errors, formOrder);
    } finally {
      setStepperLoading(false);
    }
  };

  const handleFormSubmit = (onSuccess: () => void): void => {
    const onValid = async (data: FormValues): Promise<void> =>
      handleValidForm(data, onSuccess);
    handleSubmit(onValid, handleInvalidForm)();
  };

  const handleLocationDelete = (): void => {
    setValue('location', '' as unknown as Location);
    setValue('phone', '');
  };

  return (
    <ExternshipProposalStepLayout
      methods={methods}
      content={
        <StepSection
          title="Location Information"
          dataTestId="externship-location-step"
        >
          {programOptions && loaded ? (
            <Grid container spacing={3}>
              {customLocation ? (
                <Grid item xs={12}>
                  <ReactHookFormsInput
                    name="companyName"
                    label="Externship Site Name"
                    rules={{ required: 'Externship Site Name is required' }}
                    dataTestId="externship-proposed-company-name"
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <RhfLocationSearch
                    name="location"
                    label="Company"
                    placeType={PlaceType.ALL}
                    displayMap={true}
                    rules={{ required: 'Company is required' }}
                    placeholder="Enter a company name"
                    dataTestId="externship-location-input"
                    onDeleteCallback={handleLocationDelete}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <RhfCheckbox name="customLocation" label="Use custom address" />
              </Grid>
              {customLocation && (
                <Grid item xs={12}>
                  <RhfLocationCustom methods={methods} />
                </Grid>
              )}
              {!customLocation && (
                <Grid item xs={12}>
                  <ReactHookFormsInput
                    name="companyName"
                    label="Externship Site Name"
                    rules={{ required: 'Company name is required' }}
                    dataTestId="externship-proposed-company-name"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                {internationalAddress ? (
                  <ReactHookFormsInput
                    name="phone"
                    label="Business Phone"
                    rules={{ required: 'Phone number is required' }}
                    dataTestId="externship-phone-input-free-form"
                  />
                ) : (
                  <ReactHookFormsInputPhoneMask
                    name="phone"
                    label="Business Phone"
                    rules={{ required: 'Phone number is required' }}
                    dataTestId="externship-phone-input"
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <ReactHookFormsMultiSelect
                  name="supportedPrograms"
                  label="Supported Programs"
                  options={programOptions}
                  rules={{ required: 'Select at least one program' }}
                  dataTestId="externship-supported-programs-select"
                />
              </Grid>
            </Grid>
          ) : (
            <Skeleton height="150px" sx={{ transform: 'none', padding: 0 }} />
          )}
        </StepSection>
      }
      formActions={{
        hidePreviousBtn: true,
        onSubmit: handleFormSubmit,
        submitBtnText: 'Save and Continue',
        handleStepper: handleStepper,
        disableSubmit: stepperLoading
      }}
    />
  );
};

export default ExternshipProposalLocationStep;
