import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PdfLoadingOverlay from '@components/PdfLoadingOverlay';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const Styled = {
  Dialog: styled(Dialog)({
    '& .MuiDialog-paper': {
      padding: 0,
      borderRadius: '10px',
      ['@media (max-width:567px)']: {
        margin: 0
      }
    }
  }),
  Content: styled(DialogContent)({
    overflow: 'auto',
    padding: 0
  }),
  PdfRoot: styled(Box)({
    position: 'relative' as 'relative',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.GRAY_1.main,
    padding: '16px'
  }),
  PdfWrapper: styled(Box)({
    transformOrigin: 'top center',
    // flexShrink: 0,
    backgroundColor: theme.palette.WHITE.main,
    width: '8.5in',
    padding: '48px'
  }),
  BtnRow: styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    padding: '16px 48px 32px 48px',
    columnGap: '20px'
  }),
  CloseButton: styled(IconButton)({
    position: 'absolute',
    right: '48px',
    top: '16px'
  }),
  ActionButton: styled(Button)({
    minWidth: '130px'
  }),
  ErrorText: styled(Typography)({
    color: theme.palette.primary.main
  })
};

const htmlStyles = {
  title: {
    ...theme.typography.EC_TYPE_LG,
    fontSize: '20px',
    color: theme.palette.GOLD_1.main
  }
};

export interface AgreementModalProps {
  open: boolean;
  agreementTitle: string;
  handleClose: () => void;
  handleDecline?: (() => void) | undefined;
}

interface Props extends AgreementModalProps {
  htmlWrapperId: string;
  handleAccept: (() => Promise<void>) | (() => void);
  acceptBtnText?: string;
  declineBtnText?: string;
  errorText?: string;
  pdfLoading?: boolean;
  disableAcceptBtn?: boolean;
  handleRevision?: () => void;
  isRevising?: boolean;
}

const AgreementModal: React.FC<Props> = ({
  open,
  agreementTitle,
  htmlWrapperId,
  acceptBtnText = 'Accept Agreement',
  declineBtnText = 'Decline Agreement',
  handleClose,
  handleAccept,
  handleDecline,
  isRevising,
  errorText,
  pdfLoading = false,
  disableAcceptBtn = false,
  handleRevision,
  children
}) => {
  return (
    <Styled.Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      data-testid="agreement-modal-dialog"
    >
      <Styled.Content>
        <Styled.PdfRoot>
          <Styled.CloseButton onClick={handleClose} aria-label="close">
            <CloseIcon />
          </Styled.CloseButton>
          <Styled.PdfWrapper id={htmlWrapperId}>
            <div>
              <h3 style={htmlStyles.title}>{agreementTitle}</h3>
            </div>
            {children}
          </Styled.PdfWrapper>
        </Styled.PdfRoot>
        <Styled.BtnRow>
          {handleDecline && (
            <Styled.ActionButton
              variant="outlined"
              onClick={handleDecline}
              sx={{ marginRight: 'auto' }}
            >
              {declineBtnText}
            </Styled.ActionButton>
          )}
          {errorText && (
            <Styled.ErrorText variant="EC_TYPE_2XS">
              {errorText}
            </Styled.ErrorText>
          )}
          {!!handleRevision && !isRevising && (
            <Styled.ActionButton
              variant="outlined"
              onClick={handleRevision}
              data-testid="agreement-modal-accept-btn"
            >
              Revise
            </Styled.ActionButton>
          )}
          <Styled.ActionButton
            variant="contained"
            onClick={handleAccept}
            disabled={pdfLoading || disableAcceptBtn}
            data-testid="agreement-modal-accept-btn"
          >
            {acceptBtnText}
          </Styled.ActionButton>
        </Styled.BtnRow>
      </Styled.Content>
      <PdfLoadingOverlay
        loading={pdfLoading}
        dataTestId="agreement-modal-loading-overlay"
      />
    </Styled.Dialog>
  );
};

export default AgreementModal;
