import * as React from 'react';
import { Box, Switch, Typography } from '@mui/material';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const Styled = {
  Wrapper: styled(Box)({
    display: 'flex',
    alignItems: 'center'
  })
};

interface Props {
  isChecked: boolean;
  disabled?: boolean;
  handleVisibility: (value: boolean) => Promise<void>;
  displayText: {
    checked: string;
    unchecked: string;
  };
}

const ToggleButton: React.FC<Props> = ({
  isChecked,
  disabled = false,
  handleVisibility,
  displayText
}) => {
  const handleChange = React.useCallback(() => {
    handleVisibility(!isChecked);
  }, [handleVisibility]);

  return (
    <Styled.Wrapper>
      <Switch
        checked={isChecked}
        disabled={disabled}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Typography variant="EC_TYPE_SM" color={theme.palette.GOLD_1.main}>
        {isChecked ? displayText.checked : displayText.unchecked}
      </Typography>
    </Styled.Wrapper>
  );
};

export default ToggleButton;
