import { SkillCodebook, SkillType } from '@api/models/codebook.models';
import { useEffect, useState } from 'react';
import { CodebookApi } from '@api/Codebook.api';

export type FetchSkillCodebooks = {
  technicalSkills: SkillCodebook[];
  softSkills: SkillCodebook[];
  loading: boolean;
  loaded: boolean;
};

export const useFetchSkillsCodebooks = (): FetchSkillCodebooks => {
  const [technicalSkills, setTechSkills] = useState<SkillCodebook[]>([]);
  const [softSkills, setSoftSkills] = useState<SkillCodebook[]>([]);

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        setLoading(true);
        const res = await CodebookApi.getAllSkills();

        setTechSkills(
          res.data.filter((skill) => skill.type === SkillType.TECHNICAL_SKILL)
        );
        setSoftSkills(
          res.data.filter((skill) => skill.type === SkillType.SOFT_SKILL)
        );

        setLoaded(true);
      } catch (error: any) {
        console.error(
          'Error for useFetchSkillsCodebooks -> CodebookApi.getAllSkills()',
          error
        );
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    };

    fetchData();
  }, []);

  return { technicalSkills, softSkills, loading, loaded };
};
