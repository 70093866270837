import * as React from 'react';
import { createCustomContext } from '@common/context/customContext';
import { SortOrderType } from '@interfaces/search.interfaces';

export type SortMenuButton = {
  label: string;
  id: string;
};

export type SortMenuContextProps = {
  sortCategory: string;
  sortOrder: SortOrderType;
  sortMenuButtons: SortMenuButton[];
  onSortClick: (event: React.MouseEvent<unknown>, category: string) => void;
};

export const [useSortMenuContext, SortMenuContextProvider] =
  createCustomContext<SortMenuContextProps>();
