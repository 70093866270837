import * as React from 'react';
import { Box, Typography } from '@mui/material';
import Badge from '@assets/svg/escoffier-heritage-badge.svg';
import { IPageInfoRes } from '@interfaces/pageInfo.interfaces';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useResponsiveLayoutContext } from '@components/Layout/ResponsiveLayout';

const Styled = {
  Box: styled(Box)({
    display: 'flex',
    flexDirection: 'column'
  }),
  Results: styled(Typography)({
    marginBottom: '25px',
    color: theme.palette.grey3.main
  }),
  PlaceholderBox: styled(Box)({
    padding: '8px 0px'
  }),
  PlaceholderContent: styled(Box)({
    display: 'flex',
    alignItems: 'center'
  }),
  TextBox: styled(Box)({
    marginLeft: '15px'
  }),
  Title: styled(Typography)({
    textTransform: 'uppercase',
    marginBottom: '5px'
  }),
  SubTitle: styled(Typography)({ color: theme.palette.GRAY_3.main })
};

interface InputProps {
  pageInfo: IPageInfoRes;
}

const JobMarketplacePlaceholder: React.FC<InputProps> = ({ pageInfo }) => {
  const { customBreakpointReached } = useResponsiveLayoutContext();

  const titleText: string = React.useMemo(() => {
    if (!customBreakpointReached) {
      return `Showing ${pageInfo?.filteredRows} Available Jobs`;
    } else {
      return 'Recently Posted';
    }
  }, [customBreakpointReached, pageInfo?.filteredRows]);

  return (
    <Styled.Box>
      <Styled.Results variant="EC_TYPE_SM" className="results">
        {titleText}
      </Styled.Results>
      <Styled.PlaceholderBox>
        <Styled.PlaceholderContent>
          <img src={Badge} alt="badge" />
          <Styled.TextBox>
            <Styled.Title variant="EC_TYPE_2XL">
              No matching results
            </Styled.Title>
            <Styled.SubTitle variant="EC_TYPE_SM">
              There were no matching jobs from our Escoffier partners. Edit or
              remove filters for better results, or check for more jobs from
              around the web below.
            </Styled.SubTitle>
          </Styled.TextBox>
        </Styled.PlaceholderContent>
      </Styled.PlaceholderBox>
    </Styled.Box>
  );
};

export default JobMarketplacePlaceholder;
