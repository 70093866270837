import * as React from 'react';
import { Box, Typography } from '@mui/material';
import RootPageLayout from './RootPageLayout';
import { styled } from '@mui/system';

const Styled = {
  MainContent: styled(Box)({
    margin: '60px auto'
  }),
  Title: styled(Typography)(({ theme }) => ({
    color: theme.palette.BLACK.main,
    textAlign: 'center',
    marginBottom: '6px'
  }))
};

interface Props {
  title: string;
  children: React.ReactNode;
}

const BasePageOne: React.FC<Props> = ({ children, title }) => {
  return (
    <RootPageLayout muiMaxWidth="xl" bgImgVariant={2}>
      <Styled.MainContent>
        <Styled.Title variant="EC_TYPE_3XL">{title}</Styled.Title>
        {children}
      </Styled.MainContent>
    </RootPageLayout>
  );
};

export default BasePageOne;
