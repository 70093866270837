import * as React from 'react';
import { Box, Typography } from '@mui/material';
import CtaButton from '@components/CtaButton';
import { styled } from '@mui/system';

const Styled = {
  Divider: styled(Box)(({ theme }) => ({
    width: '100%',
    margin: '16px 0 48px',
    borderBottom: '1px solid rgba(0,0,0,0.5)',
    [theme.breakpoints.up('md')]: {
      margin: '105px 0'
    }
  })),
  Root: styled(Box)(({ theme }) => ({
    maxWidth: '396px',
    paddingBottom: '32px',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '64px'
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '72px',
      paddingBottom: '132px'
    },
    '& .contact-us-footer-title': {
      color: theme.palette.GOLD_1.main
    },
    '& .contact-us-footer-text': {
      margin: '16px 0 24px'
    }
  }))
};

const ContactUsFooter = (): React.ReactElement => {
  return (
    <>
      <Styled.Divider />
      <Styled.Root>
        <Typography variant="EC_TYPE_LG" className="contact-us-footer-title">
          Find a job in the Culinary Industry
        </Typography>
        <Typography variant="EC_TYPE_BASE" className="contact-us-footer-text">
          Now more than ever, those interested in the culinary and hospitality
          industry are looking for employers that value them as individuals,
          support career growth, and respect their personal lives. With the
          right combination of passion, experience and education, we can help
          you find a career opportunity that aligns with what is most important
          to you.
        </Typography>
        <CtaButton
          to="/econnect"
          label="See jobs"
          btnVariant="text"
          textVariant="EC_TYPE_LG"
        />
      </Styled.Root>
    </>
  );
};

export default ContactUsFooter;
