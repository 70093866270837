import * as React from 'react';
import { Box, Link, Typography, useTheme } from '@mui/material';
import {
  DashboardJobPost,
  JobPostType
} from '@api/models/singleJobPostApi.models';
import { getCompanyPath } from '@common/helpers/getPathWithParamsId';
import JobPostingsOptionsMenu from '@pages/Employer/EmployerDashboard/JobPostingsTable/JobPostingsOptionsMenu';
import { Link as RouterLink } from 'react-router-dom';
import Skill from '@components/Skill';
import { styled } from '@mui/system';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';
import WorkLearnIcon from '@components/WorkLearnIcon';

const BREAKPOINT = 460;
const MEDIA_QUERY = `@media (max-width:${BREAKPOINT}px)`;

const Styled = {
  Root: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    padding: '10px 18px'
  }),
  TopBox: styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& .title-box': {
      overflow: 'hidden',
      '& .job-title': {
        color: theme.palette.primary.main,
        whiteSpace: 'wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
    '& .options-box': {
      marginLeft: 'auto'
    }
  })),
  MidBox: styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: '6px',
    marginBottom: '10px',
    '& .location': {
      marginRight: '16px'
    }
  }),
  BottomBox: styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& .info-text': {
      fontWeight: 400
    },
    '& .info-span': {
      fontWeight: 700,
      [MEDIA_QUERY]: {
        display: 'block',
        textAlign: 'center'
      }
    }
  })
};

interface JobRowProps extends DashboardJobPost {
  handleVisibility: (id: number) => Promise<void>;
  companyName?: string;
}

const JobTableRow = ({
  id,
  jobTitle,
  workLearnEligible,
  visibleOnMarketplace,
  locations,
  createdAt,
  candidates,
  handleVisibility,
  companyName,
  jobPostType
}: JobRowProps): React.ReactElement => {
  const { paramsId } = useParamsOrStoreCompanyId();

  const theme = useTheme();

  const isDirectConnectPost = jobPostType === JobPostType.DIRECT_CONNECT;

  const locList =
    locations.length > 1
      ? 'Multiple locations'
      : locations.map((loc) => loc).join(', ');

  const getTitle = (): string => {
    if (isDirectConnectPost) {
      return 'Direct Connect';
    }

    return jobTitle;
  };

  return (
    <Styled.Root data-testid="job-table-row-root">
      <Styled.TopBox data-testid="title-section">
        <Box className="title-box">
          <Link component={RouterLink} to={`/job/${id}`} underline="none">
            <Typography variant="EC_TYPE_LG" className="job-title">
              {id} - {getTitle()}
            </Typography>
          </Link>
        </Box>
        <WorkLearnIcon
          display={workLearnEligible}
          sx={{
            marginLeft: '4px',
            marginRight: '10px',
            height: '20px',
            width: 'auto'
          }}
        />
        {!isDirectConnectPost && (
          <Box className="options-box">
            <JobPostingsOptionsMenu
              jobId={id}
              visibleOnMarketplace={visibleOnMarketplace}
              handleVisibility={handleVisibility}
            />
          </Box>
        )}
      </Styled.TopBox>
      <Styled.MidBox data-testid="location-section" className="location-div">
        <Typography variant="EC_TYPE_SM" className="location">
          {locList}
        </Typography>
        {!visibleOnMarketplace && (
          <Skill skill="Hidden" variant="GRAY" disableMargin />
        )}
      </Styled.MidBox>
      <Styled.BottomBox data-testid="info-section">
        <Typography variant="EC_TYPE_2XS" className="info-text">
          CREATED: <span className="info-span">{createdAt}</span>
        </Typography>
        {!candidates || candidates < 1 ? (
          <Typography
            variant="EC_TYPE_2XS"
            className="info-text"
            data-testid="candidates-text"
          >
            CANDIDATES: <span className="info-span">{candidates}</span>
          </Typography>
        ) : (
          <Link
            component={RouterLink}
            to={{
              pathname: getCompanyPath(`/job/${id}/applicants`, paramsId),
              state: { jobTitle, companyName }
            }}
            underline="none"
          >
            <Typography
              data-testid="candidates-link"
              variant="EC_TYPE_2XS"
              className="info-text"
              color={theme.palette.primary.main}
            >
              CANDIDATES: <span className="info-span">{candidates}</span>
            </Typography>
          </Link>
        )}
      </Styled.BottomBox>
    </Styled.Root>
  );
};

export default JobTableRow;
