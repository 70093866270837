import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import ReactHookFormsAutoComplete, {
  AutoCompleteOption
} from '@components/Forms/ReactHookFormsAutoComplete';
import { DateTime } from 'luxon';
import { getApplicationStatusLabel } from '@common/helpers/getApplicationStatusLabel';
import { getCityState } from '@common/helpers/location';
import { InviteApi } from '@api/Invite.api';
import { JobApplicationApi } from '@api/JobApplication.api';
import { JobPost } from '@api/models/singleJobPostApi.models';
import { JobPostApi } from '@api/JobPost.api';
import ReactHookFormsTextarea from '@components/Forms/ReactHookFormsTextarea';
import { styled } from '@mui/system';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';

const Styled = {
  IconButton: styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: 16,
    fontSize: '16px',
    lineHeight: '16px',
    top: 16,
    color: theme.palette.GRAY_3.main
  })),
  CloseIcon: styled('i')(({ theme }) => ({
    color: theme.palette.GRAY_3.main
  })),
  Dialog: styled(Dialog)({
    '.MuiDialog-paper': {
      padding: '0px',
      margin: '0px'
    }
  }),
  DialogContent: styled(DialogContent)({
    padding: '0px'
  }),
  InviteWrapper: styled(Box)({
    padding: '58px',
    ['@media (max-width:600px)']: {
      padding: '32px'
    }
  }),
  InviteModalTitle: styled(Typography)({
    marginBottom: '12px'
  }),
  InviteModalText: styled(Typography)({
    marginBottom: '32px'
  }),
  ButtonWrapper: styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: '16px',
    marginTop: '52px'
  }),
  ReactHookFormsAutoComplete: styled(Box)({
    marginBottom: '32px'
  })
};

interface FormValues {
  jobs: AutoCompleteOption[];
  note: string;
}

interface Props {
  open: boolean;
  candidateId: number;
  handleClose: () => void;
  onSuccess: () => void;
}

const InviteModal: React.FC<Props> = ({
  open,
  candidateId,
  handleClose,
  onSuccess
}) => {
  const { companyId } = useParamsOrStoreCompanyId();
  const [jobs, setJobs] = React.useState<AutoCompleteOption[]>([]);

  const methods = useForm<FormValues>({
    defaultValues: {
      jobs: [],
      note: ''
    }
  });

  const { handleSubmit } = methods;

  const getJobLabel = (job: JobPost): string => {
    return `${job.id} ${job.jobTitle} - ${
      job.locations.length > 1
        ? 'Multiple Locations'
        : getCityState(job.locations[0])
    }`;
  };

  const getAppStatusByJobId = async (): Promise<{}> => {
    const appStatusByJobId = {};

    try {
      const candidateApplications =
        await JobApplicationApi.getCandidateApplicationsForCompanyId(
          companyId,
          candidateId
        );

      candidateApplications.forEach((app) => {
        appStatusByJobId[app.jobPost.id] = `${getApplicationStatusLabel(
          app.status,
          true
        ).toUpperCase()} ${DateTime.fromISO(
          app.submittedDate.toString()
        ).toFormat('MM/dd/yyyy')}`;
      });
    } catch (error) {
      console.error(
        'Error in InviteModal while fetching candidateApplications',
        error
      );
    }

    return appStatusByJobId;
  };

  const fetchCompanyJobPosts = async (): Promise<void> => {
    try {
      const companyJobs = await JobPostApi.getJobPostByCompanyId(companyId);
      const appStatusByJobId = await getAppStatusByJobId();

      const autocompleteJobs = companyJobs.data.map((job) => ({
        label: getJobLabel(job),
        value: job.id,
        secondLabel: appStatusByJobId[job.id],
        disable: appStatusByJobId[job.id]
      }));

      setJobs(autocompleteJobs);
    } catch (error: any) {
      console.error('Error in InviteModal while fetching jobs', error);
      window.scrollTo(0, 0);
    }
  };

  React.useEffect(() => {
    fetchCompanyJobPosts();
  }, []);

  const handleValidForm = async (data: FormValues): Promise<void> => {
    const postBody = {
      companyId: companyId,
      candidateId,
      jobId: data.jobs.map((job) => job.value),
      note: data.note
    };

    try {
      await InviteApi.inviteCandidate(postBody);
      onSuccess();
    } catch (error) {
      console.error('Error in InviteModal.inviteCandidate', error);
    }
  };

  const handleInviteClick = (): void => {
    handleSubmit(handleValidForm)();
  };

  return (
    <Styled.Dialog
      fullWidth
      maxWidth="md"
      open={open}
      scroll="paper"
      onClose={handleClose}
    >
      <Styled.IconButton aria-label="close" onClick={handleClose}>
        <Styled.CloseIcon className="ri-close-line" />
      </Styled.IconButton>
      <Styled.DialogContent>
        <Styled.InviteWrapper>
          <Styled.InviteModalTitle variant="EC_TYPE_LG">
            Invite to Apply
          </Styled.InviteModalTitle>
          <Styled.InviteModalText variant="EC_TYPE_BASE">
            Let this user know that they are on the shortlist to apply for the
            jobs selected below.
          </Styled.InviteModalText>
          <FormProvider {...methods}>
            <Styled.ReactHookFormsAutoComplete>
              <ReactHookFormsAutoComplete
                name="jobs"
                options={jobs}
                withoutCheckbox
                hasGreyChip
                multipleSelect
                inputPlaceholder="Select jobs to invite"
                rules={{ required: 'At least one job must be selected' }}
              />
            </Styled.ReactHookFormsAutoComplete>
            <ReactHookFormsTextarea
              name="note"
              label="Add a note (optional)"
              placeholder="Customize your insight"
              rows={5}
            />
          </FormProvider>
          <Styled.ButtonWrapper>
            <Button
              variant="outlined"
              color="neutral"
              sx={{ padding: '15px 30px' }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ padding: '15px 38px' }}
              onClick={handleInviteClick}
            >
              Invite
            </Button>
          </Styled.ButtonWrapper>
        </Styled.InviteWrapper>
      </Styled.DialogContent>
    </Styled.Dialog>
  );
};

export default InviteModal;
