/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  allowedGridFilterOperators,
  useFetchGridTable
} from '@common/fetches/useFetchGridTableByType';
import {
  BatchActionModalType,
  BatchActionsContextProvider,
  handleUpdateGridData
} from '@common/context/batchActionsContext';
import {
  ExternshipEntity,
  ExternshipStatus,
  ExternshipWithPhaseAndStatus
} from '@api/models/externshipApi.models';
import {
  GridColDef,
  GridFilterModel,
  GridRowSelectionModel,
  GridRowsProp,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';
import { Link, SelectChangeEvent, Skeleton } from '@mui/material';
import BatchActionsPageLayout from '@components/Layout/BatchActionsPageLayout';
import { CareerServicesExtern } from '@api/models/careerServicesApi.models';
import { Codebook } from '@api/models/codebook.models';
import EcDataGrid from '@components/DataGrid/EcDataGrid';
import ExternshipAgreementButton from '@pages/CareerServices/ExternshipTrackerPage/ExternshipAgreementButton';
import { GridTableType } from '@api/models/gridTableApi.models';
import { Link as RouterLink } from 'react-router-dom';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchCareerServicesExterns } from '@common/fetches/useFetchCareerServicesExterns';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { useToast } from '@components/Toast';

const CustomToolbar: React.FC = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          allColumns: true
        }}
        printOptions={{ disableToolbarButton: true }}
      />
    </GridToolbarContainer>
  );
};

const ExternshipTrackerPage: React.FC = () => {
  const featureFlags = useFeatureFlags();
  const { openToast } = useToast();
  const batchActions = [
    { label: BatchActionModalType.ASSIGN, value: 1 },
    { label: BatchActionModalType.EMAIL, value: 2 },
    // { label: BatchActionModalType.DOWNLOAD, value: 3 }
    // TODO: Not using survey yet
    // { label: BatchActionModalType.SEND_SURVEY, value: 4 }
    ...(featureFlags.PILOT_EXTERNSHIPS
      ? [{ label: BatchActionModalType.PILOT_PROGRAM, value: 5 }]
      : [])
  ];

  // TODO: Use career-services user profile instead of keycloak data
  const { keycloakUser } = useKeycloakContext();

  // On initial load enrollements will be fetch
  // When initialExternsFetched is true user will need to click "Retrieve Enrollments" when date is changed
  const [initialExternsFetched, setInitialExternsFetched] =
    React.useState(false);

  const [currDate, setCurrDate] = React.useState<Date>();

  // For batch actions
  const [modalOpen, setModalOpen] = React.useState<BatchActionModalType>();
  const [selectedAction, setSelectedAction] = React.useState<Codebook>();

  // For MUI Data-Grid
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 0
  });

  // This returns all the columns, default views and customs views for the CS_DASHBOARD
  const { ...gridTableProps } = useFetchGridTable(GridTableType.CS_DASHBOARD);
  const {
    gridTable,
    filterModel,
    setFilterModel,
    columnVisibilityModel,
    setColumnVisibilityModel,
    setColumnOrderByIds,
    loading: loadingGridTable,
    customColumnOrder,
    sessionValue,
    setSessionValue,
    sortGridTableColumnInCustomOrder
  } = gridTableProps;

  // Retrieve enrollments for currDate
  // We will fetchExterns (enrollments) on initial load automatically but subsequent
  // fetches will come from clicking "Retrieve Enrollments"
  const doNotFetchOnLoad = true;
  const {
    externs,
    lastFetch,
    loading: loadingExterns,
    fetchExterns,
    setExterns
  } = useFetchCareerServicesExterns(currDate, doNotFetchOnLoad);

  const handleUpdateExterns = (
    updatedExterns: CareerServicesExtern[]
  ): void => {
    handleUpdateGridData(updatedExterns, setExterns);
  };

  const handleUpdateExternshipStatus = (
    externship: ExternshipEntity | ExternshipWithPhaseAndStatus
  ): void => {
    setExterns((prev) => {
      return prev.map((item) => {
        if (item.externshipId === externship.id) {
          if (externship['externshipTrackerPhaseAndStatus']) {
            const { status, phase } = (
              externship as ExternshipWithPhaseAndStatus
            ).externshipTrackerPhaseAndStatus;
            return {
              ...item,
              externshipStatus: externship.status,
              status: status,
              phase: phase
            };
          }
        }

        return item;
      });
    });
  };

  // On initial load we will auto fetch enrollments once currDate is set
  React.useEffect(() => {
    if (currDate && !initialExternsFetched) {
      fetchExterns(currDate);
      setInitialExternsFetched(true);
    }
  }, [currDate]);

  const getLink = (link: string, row): string => {
    // Find field for dynamic link
    const field = link.substring(link.indexOf('[') + 1, link.lastIndexOf(']'));

    const value = row[field];

    return value ? link.replace(`[${field}]`, value) : '';
  };

  // Columns are updated on reorder so that we can maintain order
  const columns: GridColDef[] = React.useMemo(() => {
    return (
      gridTable?.columns.map((c) => ({
        ...c,
        filterOperators: allowedGridFilterOperators,
        flex: 1,
        renderCell: (params): JSX.Element => {
          if (!c.link) {
            if (c.field === 'externshipProposalEnabled') {
              return params.row.externshipProposalEnabled ? (
                <p>Enabled</p>
              ) : (
                <p>Disabled</p>
              );
            } else if (
              c.field === 'status' &&
              params.row.externshipStatus ===
                ExternshipStatus.PENDING_EXT_CS_AGREEMENT
            ) {
              return (
                <ExternshipAgreementButton
                  externshipId={params.row.externshipId}
                  label={params.row[c.field]}
                  handleUpdateExternshipStatus={handleUpdateExternshipStatus}
                />
              );
            } else {
              return params.row[c.field];
            }
          }

          const link = getLink(c.link, params.row);

          if (!link) {
            return params.row[c.field];
          }

          return (
            <Link
              component={RouterLink}
              to={link}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
            >
              {params.row[c.field]}
            </Link>
          );
        }
      })) || []
    );
  }, [gridTable?.columns]);

  const studentMap = {};

  const rows: GridRowsProp = React.useMemo(() => {
    return externs.map((e) => {
      const row = {
        id: JSON.stringify({
          studentName: e.name,
          studentId: e.studentId,
          enrollmentId: e.enrollmentId,
          courseCode: e.courseCode,
          externshipId: e.externshipId
        }),
        candidateId: e.candidateId,
        externshipId: e.externshipId,
        externshipStatus: e.externshipStatus
      };
      columns.forEach((c) => {
        row[c.field as string] = e[c.field];
      });

      studentMap[e.studentId] = row;
      return row;
    });
  }, [externs, columns]);

  const handleFilterModelChange = (filterModel: GridFilterModel): void => {
    setFilterModel(filterModel);

    const externshipState = JSON.stringify({
      columns: sortGridTableColumnInCustomOrder(customColumnOrder),
      columnVisibilityModel,
      customColumnOrder,
      filterModel
    });
    setSessionValue(externshipState);
  };

  const handleRefresh = async (
    refresh: boolean = true
  ): Promise<void | null> => {
    const res = await fetchExterns(currDate, refresh);

    // Show message if fetch data fails
    if (res === null) {
      openToast('Error while fetching data. Please try again.');
    }
  };

  const handleOpenModal = (): void => {
    if (selectedAction) {
      setModalOpen(selectedAction.label as BatchActionModalType);
    }
  };
  const handleCloseModal = (): void => {
    setModalOpen(undefined);
  };

  const handleSetSelectedAction = (e?: SelectChangeEvent<number>): void => {
    const action = batchActions.find((a) => a.value === e?.target?.value);
    setSelectedAction(action);
  };

  const handleClearSelectedRows = (): void => {
    setRowSelectionModel([]);
  };

  return (
    <BatchActionsContextProvider
      value={{
        actions: batchActions,
        actionSelectLabel: 'batch actions',
        rowSelectionModel,
        actionBtnText: 'Go',
        modalOpen,
        handleOpenModal,
        handleCloseModal,
        selectedAction,
        handleSetSelectedAction,
        refetch: handleRefresh,
        onSubmitCallback: handleClearSelectedRows,
        updateDataGridData: handleUpdateExterns
      }}
    >
      <BatchActionsPageLayout
        displayViewSelect
        pageTitle={`Welcome, ${keycloakUser.given_name}`}
        lastRefresh={lastFetch}
        externs={externs}
        loadingExterns={loadingExterns}
        currDate={currDate}
        setCurrDate={setCurrDate}
        gridTableProps={gridTableProps}
        gridTableColumns={columns}
        sessionValue={sessionValue}
      >
        <>
          {loadingExterns || loadingGridTable ? (
            <Skeleton variant="rectangular" width="100%" height="600px" />
          ) : (
            <EcDataGrid
              checkboxSelectionVisibleOnly
              checkboxSelection
              rowHeight={35}
              columnHeaderHeight={35}
              rows={rows}
              columns={columns}
              pagination
              getRowClassName={(params): string =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              paginationModel={paginationModel}
              onRowSelectionModelChange={(newRowSelectionModel): void => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={[25, 50, 100]}
              onColumnOrderChange={setColumnOrderByIds}
              slots={{ toolbar: CustomToolbar }}
              filterModel={filterModel}
              onFilterModelChange={handleFilterModelChange}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(columnVisibilityModel): void => {
                setColumnVisibilityModel(columnVisibilityModel);
                const externshipState = JSON.stringify({
                  columns: sortGridTableColumnInCustomOrder(customColumnOrder),
                  columnVisibilityModel,
                  customColumnOrder,
                  filterModel
                });
                setSessionValue(externshipState);
              }}
              containerHeight="80vh"
            />
          )}
        </>
      </BatchActionsPageLayout>
    </BatchActionsContextProvider>
  );
};

export default ExternshipTrackerPage;
