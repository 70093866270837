import * as React from 'react';
import { Grid, OutlinedInput, Typography } from '@mui/material';
import { cultureHealthSafety } from '@common/tags/tagStrings';
import ReactHookFormsInputPhoneMask from '@components/Forms/ReactHookFormsInputPhoneMask';
import ReactHookFormsSelect from '@components/Forms/ReactHookFormsSelect';
import ReactHookFormsTagSelection from '@components/Forms/ReactHookFormsTagSelection';
import StepSection from '@components/StepSection';
import StepSubSection from '@components/StepSubSection';
import { styled } from '@mui/system';
import { useFetchSegmentsCodebooks } from '@common/fetches/useFetchSegmentsCodebooks';

const Styled = {
  CompanyNameLabel: styled(Typography)({
    marginBottom: '11px'
  })
};

const companySizeOptions = [
  { label: '1-1000', value: '10' },
  { label: '1001-5000', value: '20' },
  { label: '5001-10000', value: '30' },
  { label: '10001+', value: '40' }
];

interface Props {
  companyName: string;
}

const AboutSection: React.FC<Props> = ({ companyName }) => {
  const { segments } = useFetchSegmentsCodebooks();

  return (
    <StepSection title="About" dataTestId="about-section">
      <StepSubSection>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Styled.CompanyNameLabel
              variant="EC_TYPE_BASE"
              data-testid="company-name-label"
            >
              Company Name
            </Styled.CompanyNameLabel>
            <OutlinedInput
              data-testid="company-name"
              disabled
              fullWidth
              value={companyName}
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReactHookFormsInputPhoneMask
              name="phone"
              label="Phone"
              rules={{ required: 'Phone is required' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReactHookFormsSelect
              name="size"
              label="Company Size"
              options={companySizeOptions}
            />
          </Grid>
        </Grid>
      </StepSubSection>
      <StepSubSection>
        <ReactHookFormsTagSelection
          name="companySegments"
          title="Segment"
          // filter!=99 is TEMP solution to remove "Unsure" value for companies
          // If we decide to delete "Unsure" from db, filter can be removed
          // If we decide to keep it, we should add flag in codebook table
          tags={segments.filter((segment) => segment.value !== 99)}
          subtitle="(Select one or more)"
          rules={{ required: true }}
        />
      </StepSubSection>
      <StepSubSection>
        <ReactHookFormsTagSelection
          name="companyHealthSafeties"
          title="Health &amp; Safety"
          tags={cultureHealthSafety.healthAndSafety}
          subtitle="(Select one or more)"
          rules={{ required: true }}
        />
      </StepSubSection>
    </StepSection>
  );
};

export default AboutSection;
