import { useEffect, useState } from 'react';
import { CodebookApi } from '@api/Codebook.api';
import { PublicCodebooks } from '@api/models/codebook.models';

type FetchCodebooks = {
  codebooks: PublicCodebooks;
  loading: boolean;
  loaded: boolean;
};

/**
 * Fetch all public codebooks from CodebookApi.getPublicCodebooks()
 *
 * @returns `{ codebooks: PublicCodebooks; loading: boolean; loaded: boolean }`
 */
export const useFetchPublicCodebooks = (): FetchCodebooks => {
  const [codebooks, setCodebooks] = useState<PublicCodebooks>(
    {} as PublicCodebooks
  );
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        setLoading(true);
        const res = await CodebookApi.getPublicCodebooks();

        setCodebooks(res.data);
        setLoaded(true);
      } catch (error: any) {
        console.error(
          'Error for useFetchPublicCodebooks -> CodebookApi.getPublicCodebooks()',
          error
        );
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    };

    fetchData();
  }, []);

  return { codebooks, loading, loaded };
};
