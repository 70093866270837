/* eslint-disable @typescript-eslint/indent */
import {
  SentInvite,
} from '@api/models/settingsApi.models';
import { createCustomContext } from '@common/context/customContext';
import { ESourceGroupWithAccessDto } from '@interfaces/glrc.interfaces';
import { GlrcPermissions } from '@api/models/glrcAccess.models';
import { JoinRequest } from '@api/models/companyApi.models';

export interface CompanySettingsContextType {
  companyId: number;
  sentInvites: SentInvite[];
  joinRequests: JoinRequest[];
  setLoading: (loading: boolean) => void;
  fetchSentInvites: (companyId: number) => void;
  glrcSeats: number;
  glrcPermissions?: GlrcPermissions;
  handleSeatRequestUpdate: () => void;
  esourceGroups: ESourceGroupWithAccessDto[];
  setESourceGroups: React.Dispatch<
    React.SetStateAction<ESourceGroupWithAccessDto[]>
  >;
  openToast: (msg: string) => void;
  glrcSeatsRemaining: number;
}

/**
 * CompanySettingsContextProvider is used in CompanySettingsRedesign.tsx to provide
 * the app with data for the company settings (seat management, company name).
 *
 * useCompanySettingsContext to gain access to
 * CompanySettings data.
 */
export const [useCompanySettingsContext, CompanySettingsContextProvider] =
  createCustomContext<CompanySettingsContextType>();
