import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';

const Styled = {
  TagContainer: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'selected'
  })<{ selected?: boolean }>(({ selected = false, theme }) => ({
    border: `1px solid ${theme.palette.GOLD_1.main}`,
    display: 'inline-block',
    padding: '10px 15px',
    borderRadius: '4px',
    boxSizing: 'border-box',
    backgroundColor: selected
      ? theme.palette.GOLD_1.main
      : theme.palette.WHITE.main,
    color: selected ? theme.palette.WHITE.main : theme.palette.GOLD_1.main,

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: selected
        ? theme.palette.GOLD_1.main
        : theme.palette.GRAY_1.main,
      color: selected ? theme.palette.WHITE.main : theme.palette.GOLD_1.main,

      '.MuiTypography-root': {
        color: selected ? theme.palette.WHITE.main : theme.palette.GOLD_1.main
      },

      borderColor: selected
        ? theme.palette.GOLD_1.main
        : theme.palette.GRAY_1.main
    }
  })),
  TagLabel: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'selected'
  })<{ selected?: boolean }>(({ selected = false, theme }) => ({
    color: selected ? theme.palette.WHITE.main : theme.palette.GOLD_1.main
  }))
};

interface Props {
  label: string;
  selected?: boolean;
  handleSelection: (label: string) => void;
}

const SelectableTag: React.FC<Props> = ({
  selected = false,
  handleSelection,
  label
}) => {
  return (
    <Styled.TagContainer
      selected={selected}
      sx={{ display: 'inline-flex' }}
      onClick={(): void => handleSelection(label)}
      data-testid={`${label}-selectable-tag`}
    >
      <Styled.TagLabel
        selected={selected}
        variant="EC_TYPE_SM"
        sx={{
          fontSize: '20px',
          marginRight: '5px',
          fontWeight: 'normal'
        }}
      >
        <i className={selected ? 'ri-check-line' : 'ri-add-line'}></i>
      </Styled.TagLabel>
      <Styled.TagLabel selected={selected} variant="EC_TYPE_SM">
        {label}
      </Styled.TagLabel>
    </Styled.TagContainer>
  );
};

export default SelectableTag;
