/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { GridType, GridViewDto } from '@api/models/gridTableApi.models';
import { GridFilterOperators } from '@common/fetches/useFetchGridTableByType';
import { GridLogicOperator } from '@mui/x-data-grid-pro';
import { GridTableApi } from '@api/GridTable.api';

export interface ViewCounts {
  [key: string]: number;
}

export type FetchGridViewByType = {
  gridViews: GridViewDto[];
  setGridViews: React.Dispatch<React.SetStateAction<any[]>>;
  calculateViewCount: (items: any[]) => void;
  viewCounts: ViewCounts;
  loaded: boolean;
  loading: boolean;
};

export const useFetchGridViewsByType = (
  gridType: GridType
): FetchGridViewByType => {
  const [gridViews, setGridViews] = React.useState<any[]>([]);
  const [viewCounts, setViewCounts] = React.useState<ViewCounts>({});

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchGridViews = async (gridType: GridType): Promise<void> => {
    try {
      setLoading(true);
      const res = await GridTableApi.getGridViewsByTypeAndUserId(gridType);

      setGridViews(res);
      setLoaded(true);
    } catch (error: any) {
      console.error(
        'Error for useFetchGridTable -> GridTableApi.getGridViewsByTypeAndUserId()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  const calculateViewCount = (items: any[]): void => {
    const viewCountsMap = {};

    gridViews.forEach((view) => {
      const filters = view.gridState.filter.filterModel.items;

      if (!filters.length) {
        viewCountsMap[view.id] = items.length;
      } else {
        const filteredItems = items.filter((item) => {
          const results: boolean[] = [];

          const logicalOperator =
            view.gridState.filter.filterModel.logicOperator;

          filters.forEach((filter) => {
            const fieldValue = String(item[filter.field]).toLowerCase();
            const filterValue = String(filter.value).toLowerCase();

            if (filter.operator === GridFilterOperators.EQUALS) {
              results.push(fieldValue === filterValue);
            }

            if (filter.operator === GridFilterOperators.CONTAINS) {
              results.push(fieldValue.includes(filterValue));
            }

            if (filter.operator === GridFilterOperators.IS_EMPTY) {
              results.push(fieldValue.length === 0);
            }

            if (filter.operator === GridFilterOperators.IS_ANY_OF) {
              const filterValues = filter.value
                ? (filter.value as string[])
                : [''];

              const isAnyOfResults: boolean[] = [];

              filterValues.forEach((val) => {
                isAnyOfResults.push(fieldValue === String(val).toLowerCase());
              });

              results.push(isAnyOfResults.some((r) => r === true));
            }

            // Removed operators. Left as commented in case we want to add them back.

            // if (filter.operator === GridFilterOperators.ENDS_WITH) {
            //   results.push(fieldValue.endsWith(filterValue));
            // }

            // if (filter.operator === GridFilterOperators.STARTS_WITH) {
            //   results.push(fieldValue.startsWith(filterValue));
            // }

            // if (filter.operator === GridFilterOperators.IS_NOT_EMPTY) {
            //   results.push(fieldValue.length > 0);
            // }
          });

          if (logicalOperator === GridLogicOperator.And) {
            return results.every((r) => r === true);
          }

          if (logicalOperator === GridLogicOperator.Or) {
            return results.some((r) => r === true);
          }
        });

        viewCountsMap[view.id] = filteredItems.length;
      }
    });

    setViewCounts(viewCountsMap);
  };

  React.useEffect(() => {
    fetchGridViews(gridType);
  }, []);

  return {
    gridViews,
    setGridViews,
    calculateViewCount,
    viewCounts,
    loaded,
    loading
  };
};
