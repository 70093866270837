import { useParams } from 'react-router-dom';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';

interface RouteParams {
  companyId: string;
}

interface ReturnIds {
  companyId: number;
  storeId: number;
  paramsId: string;
  isViewingAsEmployer: boolean;
}

export const useParamsOrStoreCompanyId = (): ReturnIds => {
  const [storeId] = useStore<number>(UseStoreKeys.COMPANY_ID);
  const paramsId = useParams<RouteParams>().companyId;

  const companyId = paramsId ? parseInt(paramsId) : storeId;

  return { companyId, storeId, paramsId, isViewingAsEmployer: !!paramsId };
};
