import * as React from 'react';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import StepperDialog from '@components/StepperDialog';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const WIDTH = 599;
const MEDIA_QUERY = `@media (max-width:${WIDTH}px)`;

const Styled = {
  Section: styled(Box)({
    borderTop: '1px solid #5C6B80',
    paddingTop: '44px',
    textAlign: 'right',
    [MEDIA_QUERY]: {
      paddingLeft: '16px',
      paddingRight: '16px'
    }
  }),
  Buttons: styled(Button)({
    minWidth: '200px'
  })
};

export interface FormActionProps {
  hidePreviousBtn?: boolean;
  onSubmit: (onSuccess: () => void) => void;
  submitBtnText: string;
  handleStepper?: (next: boolean) => void;
  changeDetected?: boolean;
  lastStepAction?: () => void;
  errorMessage?: string;
  disableSubmit?: boolean;
}

const FormActionsComponent: React.FC<FormActionProps> = ({
  hidePreviousBtn,
  onSubmit,
  submitBtnText,
  handleStepper,
  changeDetected,
  lastStepAction,
  errorMessage,
  disableSubmit
}) => {
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState<boolean>(false);

  const stepBackward = (): void => handleStepper && handleStepper(false);
  const stepForward = (): void => handleStepper && handleStepper(true);

  const handleDialogDiscard = (): void => {
    setOpen(false);
    stepBackward();
  };

  const handleDialogSave = (): void => {
    setOpen(false);
    onSubmit(stepBackward);
  };

  const handlePrevious = (): void => {
    changeDetected ? setOpen(true) : stepBackward();
  };

  const handleSave = (): void => {
    onSubmit(lastStepAction ? lastStepAction : stepForward);
  };

  return (
    <Styled.Section>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Grid item xs={12} sm="auto">
          {!hidePreviousBtn && (
            <Styled.Buttons
              fullWidth
              variant="outlined"
              color="neutral"
              onClick={handlePrevious}
              sx={{ minWidth: '110px' }}
            >
              Previous
            </Styled.Buttons>
          )}
        </Grid>
        <Grid item xs={12} sm="auto">
          <Styled.Buttons
            fullWidth
            variant="contained"
            onClick={handleSave}
            disabled={disableSubmit}
            data-testid="submit-step-btn"
          >
            {submitBtnText}
          </Styled.Buttons>
        </Grid>
        {errorMessage && (
          <Grid item xs={12} textAlign={isMobileView ? 'center' : 'unset'}>
            <Typography variant="EC_TYPE_2XS" color={theme.palette.error.main}>
              {errorMessage}
            </Typography>
          </Grid>
        )}
      </Grid>
      <StepperDialog
        open={open}
        setOpen={setOpen}
        onDiscard={handleDialogDiscard}
        onSave={handleDialogSave}
      />
    </Styled.Section>
  );
};

export default FormActionsComponent;
