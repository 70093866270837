import * as React from 'react';
import { Box, Typography } from '@mui/material';
import CtaButton from '@components/CtaButton';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '24px',
    paddingBottom: '16px',
    ['@media (min-width:740px)']: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      columnGap: '20px',
      paddingBottom: 'unset'
    }
  })
};

const IncompleteProfileCTA = (): React.ReactElement => {
  return (
    <Styled.Root>
      <Typography variant="EC_TYPE_2XL">
        To see how your skills and experience match your career goal, please
        update your profile.
      </Typography>
      <CtaButton
        to="/candidate/profile-setup"
        label="Finish my profile"
        sx={{ flexShrink: 0 }}
      />
    </Styled.Root>
  );
};

export default IncompleteProfileCTA;
