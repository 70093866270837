/* eslint-disable @typescript-eslint/indent */
import {
  ActivateApproverReq,
  AddExternshipApproverPayload,
  AgreementEntity,
  ApproveTimesheetReq,
  ExternshipAgreementData,
  ExternshipAgreementPostReq,
  ExternshipApproverEntity,
  ExternshipDatesRes,
  ExternshipEntity,
  ExternshipStatus,
  RejectTimesheetReq,
  ReviseExternshipAgreementPostReq,
  SaveTimesheet,
  SiteAgreementData,
  SiteAgreementPostReq,
  StudentViewExternship,
  Timesheet,
  TimesheetPerformanceItem,
  TimesheetStatus,
  TimesheetsWithExternship
} from '@api/models/externshipApi.models';
import {
  ExternshipProposalApproversUpsertPayload,
  ExternshipLocationPutReq,
  ExternshipProgramPutReq,
  ProposedManagerAndSupervisorsPutReq,
} from '@api/models/externshipProposalApi.models';
import { api } from '@api/configs/base';
import { apiConfig } from '@api/configs/api.config';
import { ApiResponse } from '@api/models/base.models';
import { EnrollmentCoursesExternships } from '@api/models/enrollmentApi.models';
import { Location } from '@interfaces/location.interfaces';
import { PdfPostRequest } from './models/pdfApi.models';
import { LocalStorageCache } from '@common/utilities/LocalStorageCache';

export class ExternshipApi {
  static async create(
    data: ExternshipLocationPutReq
  ): Promise<ApiResponse<ExternshipEntity>> {
    try {
      const res = await api.post<ApiResponse<ExternshipEntity>>(
        `${apiConfig.externshipsUrl}/externship`,
        data
      );

      return res.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.create(): ${error}`);
      throw error;
    }
  }

  static async updateCompanyInfo(
    externshipId: number,
    data: ExternshipLocationPutReq
  ): Promise<ApiResponse<ExternshipEntity>> {
    try {
      const res = await api.put<ApiResponse<ExternshipEntity>>(
        `${apiConfig.externshipsUrl}/externship/${externshipId}`,
        data
      );

      return res.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.updateCompanyInfo(): ${error}`);
      throw error;
    }
  }

  static async upsertExternshipProposalApprovers(
    externshipId: number,
    payload: ExternshipProposalApproversUpsertPayload,
  ): Promise<ExternshipEntity> {
    try {
      const apiEndpoint = `${apiConfig.externshipsUrl}/externship/${externshipId}/approvers`;

      const res = await api.put<ApiResponse<ExternshipEntity>>(
        apiEndpoint,
        payload
      );

      return res.data.data;
    } catch (error: any) {
      console.error(
        `Error for ExternshipApi.updateManagerAndSupervisors(): ${error}`
      );
      throw error;
    }
  }

  static async updateProgramInfo(
    candidateId: number,
    proposedCompanyId: number,
    data: ExternshipProgramPutReq
  ): Promise<void> {
    try {
      await api.put(
        `${apiConfig.externshipsUrl}/candidate/${candidateId}/proposed-company/${proposedCompanyId}/update-program`,
        data
      );

      return;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.updateProgramInfo(): ${error}`);
      throw error;
    }
  }

  /* Career Services Site Info Methods */
  static async updateStudentPositionInfo(
    externshipId: number,
    data: { jobTitle: string; jobDescription: string }
  ): Promise<ExternshipEntity> {
    try {
      const res = await api.put<ApiResponse<ExternshipEntity>>(
        `${apiConfig.externshipsUrl}/externship/${externshipId}/site-info/position`,
        data
      );

      return res.data.data;
    } catch (error: any) {
      console.error(
        `Error for ExternshipApi.updateStudentPositionInfo: ${error}`
      );
      throw error;
    }
  }

  static async updateProposedLocation(
    externshipId: number,
    data: { location: Location }
  ): Promise<ExternshipEntity> {
    try {
      const res = await api.put<ApiResponse<ExternshipEntity>>(
        `${apiConfig.externshipsUrl}/externship/${externshipId}/site-info/update-location`,
        data
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.updateProposedLocation: ${error}`);
      throw error;
    }
  }

  static async sendExternshipApproverInvite(
    externshipId: number,
    approverId: number,
  ): Promise<ExternshipApproverEntity> {
    try {
      const res = await api.post<ApiResponse<ExternshipApproverEntity>>(
        `${apiConfig.externshipsUrl}/${externshipId}/approvers/${approverId}/send-invite`
      );

      return res.data.data;

    } catch (error: any) {
      console.error(`Error for ExternshipApi.resendExternshipApproverInvite: ${error}`);
      throw error;
    }
  }

  static async softVerifyExternshipProposal(
    externshipId: number
  ): Promise<StudentViewExternship> {
    try {
      const res = await api.put<ApiResponse<StudentViewExternship>>(
        `${apiConfig.externshipsUrl}/externship/${externshipId}/soft-verification`
      );

      return res.data.data;
    } catch (error: any) {
      console.error(
        `Error for ExternshipApi.softVerifyExternshipProposal: ${error}`
      );
      throw error;
    }
  }

  static async softVerifyExternshipProposalReject(
    externshipId: number
  ): Promise<StudentViewExternship> {
    try {
      const res = await api.put<ApiResponse<StudentViewExternship>>(
        `${apiConfig.externshipsUrl}/externship/${externshipId}/soft-verification/reject`
      );

      return res.data.data;
    } catch (error: any) {
      console.error(
        `Error for ExternshipApi.softVerifyExternshipProposalReject: ${error}`
      );
      throw error;
    }
  }

  static async toggleApproverActiveStatus(
    data: ActivateApproverReq
  ): Promise<ExternshipEntity> {
    try {
      const res = await api.put<ApiResponse<ExternshipEntity>>(
        `${apiConfig.externshipsUrl}/toggle-approver-active-status`,
        data
      );

      return res.data.data;
    } catch (error: any) {
      console.error(
        `Error for ExternshipApi.toggleApproverActiveStatus: ${error}`
      );
      throw error;
    }
  }

  // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove this and only use getByStudentNumber once flag is updated
  static async getByCandidateId(
    candidateId: number
  ): Promise<ApiResponse<ExternshipEntity[] | EnrollmentCoursesExternships[]>> {
    try {
      const res = await api.get<
        ApiResponse<ExternshipEntity[] | EnrollmentCoursesExternships[]>
      >(`${apiConfig.externshipsUrl}/candidates/${candidateId}`);

      return res.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.getByCandidateId(): ${error}`);
      throw error;
    }
  }

  static async getByStudentNumber(
    studentNumber: string
  ): Promise<ApiResponse<ExternshipEntity[] | EnrollmentCoursesExternships[]>> {
    try {
      const res = await api.get<
        ApiResponse<ExternshipEntity[] | EnrollmentCoursesExternships[]>
      >(`${apiConfig.externshipsUrl}/students/${studentNumber}`);

      return res.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.getByCandidateId(): ${error}`);
      throw error;
    }
  }

  static async getById(
    externshipId: number
  ): Promise<ApiResponse<ExternshipEntity>> {
    try {
      const response = await api.get<ApiResponse<ExternshipEntity>>(
        `${apiConfig.externshipsUrl}/${externshipId}`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.getById(): ${error}`);
      throw error;
    }
  }

  static async getByStatus(
    externshipStatus: ExternshipStatus
  ): Promise<ExternshipEntity[]> {
    try {
      const response = await api.get<ApiResponse<ExternshipEntity[]>>(
        `${apiConfig.externshipsUrl}/status/${externshipStatus}`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.getByStatus(): ${error}`);
      throw error;
    }
  }

  static async getByCompanyId(
    companyId: number
  ): Promise<ApiResponse<ExternshipEntity[]>> {
    try {
      const response = await api.get<ApiResponse<ExternshipEntity[]>>(
        `${apiConfig.externshipsUrl}/company/${companyId}`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.getByCompanyId(): ${error}`);
      throw error;
    }
  }

  static async getForRecruiter(
    companyId: number,
    employerId: number
  ): Promise<ApiResponse<ExternshipEntity[]>> {
    try {
      const response = await api.get<ApiResponse<ExternshipEntity[]>>(
        `${apiConfig.externshipsUrl}/company/${companyId}/employer/${employerId}`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.getForRecruiter(): ${error}`);
      throw error;
    }
  }

  static async getByGraduationDate(
    graduationDate: Date
  ): Promise<ExternshipEntity[]> {
    try {
      const response = await api.get<ApiResponse<ExternshipEntity[]>>(
        `${apiConfig.externshipsUrl}/graduation-date/${graduationDate.toISOString()}`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.getByGraduationDate: ${error}`);
      throw error;
    }
  }

  // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove this when flag is updated
  static async hasAvailableExternshipCourses(
    candidateId: number
  ): Promise<boolean> {
    try {
      const response = await api.get<ApiResponse<boolean>>(
        `${apiConfig.externshipsUrl}/candidates/${candidateId}/student-course-availability`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ExternshipApi.hasAvailableExternshipCourses: ${error}`
      );
      throw error;
    }
  }

  /**
   * Set externship status to CANCELED (32)
   */
  static async cancel(externshipId: number): Promise<ExternshipEntity> {
    try {
      const response = await api.put<ApiResponse<ExternshipEntity>>(
        `${apiConfig.externshipsUrl}/${externshipId}/cancel`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.cancel(): ${error}`);
      throw error;
    }
  }

  /**
   * Set externship status to DECLINED (3)
   */
  static async decline(externshipId: number): Promise<ExternshipEntity> {
    try {
      const response = await api.put<ApiResponse<ExternshipEntity>>(
        `${apiConfig.externshipsUrl}/${externshipId}/decline`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.decline(): ${error}`);
      throw error;
    }
  }

  /**
   * Set externship status to ARCHIVED (4)
   */
  static async archive(externshipId: number): Promise<ExternshipEntity> {
    try {
      const response = await api.put<ApiResponse<ExternshipEntity>>(
        `${apiConfig.externshipsUrl}/${externshipId}/archive`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.archive(): ${error}`);
      throw error;
    }
  }

  static async delete(externshipId: number): Promise<ExternshipEntity> {
    try {
      const response = await api.delete<ApiResponse<ExternshipEntity>>(
        `${apiConfig.externshipsUrl}/${externshipId}/delete`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.delete(): ${error}`);
      throw error;
    }
  }

  static async managerVerification(
    externshipId: number,
    data: ProposedManagerAndSupervisorsPutReq
  ): Promise<void> {
    try {
      await api.post<ApiResponse<void>>(
        `${apiConfig.externshipsUrl}/${externshipId}/verify`,
        data
      );

      return;
    } catch (error: any) {
      console.error(
        `Error for ExternshipApi.getTimesheetsByExternshipId(): ${error}`
      );
      throw error;
    }
  }

  static async removeExternshipApprover(
    externshipId: number,
    approverId: number
  ): Promise<ExternshipEntity> {
    try {
      const res = await api.delete<ApiResponse<ExternshipEntity>>(
        `${apiConfig.externshipsUrl}/${externshipId}/approvers/${approverId}`
      );
      return res.data.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.removeExternshipApprover: ${error}`);
      throw error;
    }
  }

  static async addExternshipApprover(
    externshipId: string,
    payload: AddExternshipApproverPayload
  ): Promise<ExternshipEntity> {
    try {
      const res = await api.post<ApiResponse<ExternshipEntity>>(
        `${apiConfig.externshipsUrl}/${externshipId}/approvers`,
        payload
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.addSupervisor(): ${error}`);
      throw error;
    }
  }

  // ************
  // Timesheets
  // ************
  static async getTimesheetsByExternshipId(
    id: number
  ): Promise<TimesheetsWithExternship> {
    try {
      const response = await api.get<ApiResponse<TimesheetsWithExternship>>(
        `${apiConfig.externshipsUrl}/${id}/timesheets`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ExternshipApi.getTimesheetsByExternshipId(): ${error}`
      );
      throw error;
    }
  }

  static async getTimesheetsByCandidateAndCourse(
    candidateId: number,
    courseId: number
  ): Promise<TimesheetsWithExternship[]> {
    try {
      const response = await api.get<ApiResponse<TimesheetsWithExternship[]>>(
        `${apiConfig.externshipsUrl}/candidate/${candidateId}/course/${courseId}/timesheets`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ExternshipApi.getTimesheetsByExternshipId(): ${error}`
      );
      throw error;
    }
  }

  static async saveTimesheet(
    externshipId: number,
    data: SaveTimesheet
  ): Promise<Timesheet> {
    try {
      const response = await api.post<ApiResponse<Timesheet>>(
        `${apiConfig.externshipsUrl}/${externshipId}/timesheets`,
        data
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.saveTimesheet(): ${error}`);
      throw error;
    }
  }

  static async submitTimesheet(
    externshipId: number,
    timesheetId: number,
    data: SaveTimesheet
  ): Promise<Timesheet> {
    try {
      const response = await api.put<ApiResponse<Timesheet>>(
        `${apiConfig.externshipsUrl}/${externshipId}/timesheets/${timesheetId}/submit`,
        data
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.submitTimesheet(): ${error}`);
      throw error;
    }
  }

  static async approveTimesheet(
    externshipId: number,
    timesheetId: number,
    data: ApproveTimesheetReq
  ): Promise<Timesheet> {
    try {
      const response = await api.put<ApiResponse<Timesheet>>(
        `${apiConfig.externshipsUrl}/${externshipId}/timesheets/${timesheetId}/approve`,
        data
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.approveTimesheet(): ${error}`);
      throw error;
    }
  }

  static async resendToCampus(
    externshipId: number,
    timesheetId: number,
    data: ApproveTimesheetReq
  ): Promise<Timesheet> {
    try {
      const response = await api.put<ApiResponse<Timesheet>>(
        `${apiConfig.externshipsUrl}/${externshipId}/timesheets/${timesheetId}/resend-to-campus`,
        data
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.resendToCampus(): ${error}`);
      throw error;
    }
  }

  static async rejectTimesheet(
    externshipId: number,
    timesheetId: number,
    data: RejectTimesheetReq
  ): Promise<Timesheet> {
    try {
      const response = await api.post<ApiResponse<Timesheet>>(
        `${apiConfig.externshipsUrl}/${externshipId}/timesheets/${timesheetId}/reject`,
        data
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.rejectTimesheet(): ${error}`);
      throw error;
    }
  }

  static async undoChefTimesheetApproval(
    externshipId: number,
    timesheetId: number
  ): Promise<Timesheet> {
    try {
      const response = await api.put<ApiResponse<Timesheet>>(
        `${apiConfig.externshipsUrl}/${externshipId}/timesheets/${timesheetId}/undo`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ExternshipApi.undoChefTimesheetApproval(): ${error}`
      );
      throw error;
    }
  }

  static async getTimesheetPerformanceReviewQuestions(): Promise<
    TimesheetPerformanceItem[]
  > {
    const CACHE_KEY = 'timesheet_performance_questions_cache';
    const cache = new LocalStorageCache<TimesheetPerformanceItem[]>(CACHE_KEY);
    
    try {
      // Check if cache exists and is valid
      const cachedData = cache.get();
      if (cachedData) {
        return cachedData;
      }

      // If cache doesn't exist or is expired, make the API call
      const response = await api.get<ApiResponse<TimesheetPerformanceItem[]>>(
        `${apiConfig.externshipsUrl}/timesheets/review-questions`
      );

      // Store the new data in cache
      cache.set(response.data.data);

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ExternshipApi.getTimesheetPerformanceReviewQuestions(): ${error}`
      );
      throw error;
    }
  }

  // ************
  // Agreements
  // ************
  static async getSiteAgreementData(
    employerId: number
  ): Promise<SiteAgreementData[]> {
    try {
      const response = await api.get<ApiResponse<SiteAgreementData[]>>(
        `${apiConfig.externshipsUrl}/manager/${employerId}/site-agreement-data/`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.getSiteAgreementData(): ${error}`);
      throw error;
    }
  }

  static async getExternshipAgreementData(
    externshipId: number
  ): Promise<ApiResponse<ExternshipAgreementData>> {
    try {
      const response = await api.get<ApiResponse<ExternshipAgreementData>>(
        `${apiConfig.externshipsUrl}/${externshipId}/externship-agreement-manager-data/`
      );

      return response.data;
    } catch (error: any) {
      console.error(
        `Error for ExternshipApi.getExternshipAgreementData(): ${error}`
      );
      throw error;
    }
  }

  static async signSiteAgreement(
    employerId: number,
    data: SiteAgreementPostReq
  ): Promise<ApiResponse<AgreementEntity>> {
    try {
      const response = await api.post<ApiResponse<AgreementEntity>>(
        `${apiConfig.externshipsUrl}/manager/${employerId}/sign-site-agreement/`,
        data
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.signSiteAgreement(): ${error}`);
      throw error;
    }
  }

  static async signExternshipAgreementManager(
    externshipId: number,
    data: ExternshipAgreementPostReq
  ): Promise<ApiResponse<ExternshipEntity>> {
    try {
      const response = await api.post<ApiResponse<ExternshipEntity>>(
        `${apiConfig.externshipsUrl}/${externshipId}/sign-externship-agreement-manager/`,
        data
      );

      return response.data;
    } catch (error: any) {
      console.error(
        `Error for ExternshipApi.signExternshipAgreementManager(): ${error}`
      );
      throw error;
    }
  }

  static async signExternshipAgreementStudent(
    externshipId: number,
    data: { html: string }
  ): Promise<ApiResponse<ExternshipEntity>> {
    try {
      const response = await api.post<ApiResponse<ExternshipEntity>>(
        `${apiConfig.externshipsUrl}/${externshipId}/sign-externship-agreement-student/`,
        data
      );

      return response.data;
    } catch (error: any) {
      console.error(
        `Error for ExternshipApi.signExternshipAgreementStudent(): ${error}`
      );
      throw error;
    }
  }

  static async signExternshipAgreementCareerServices(
    externshipId: number,
    data: { html: string }
  ): Promise<ApiResponse<ExternshipEntity>> {
    try {
      const response = await api.post<ApiResponse<ExternshipEntity>>(
        `${apiConfig.externshipsUrl}/${externshipId}/sign-externship-agreement-career-services/`,
        data
      );

      return response.data;
    } catch (error: any) {
      console.error(
        `Error for ExternshipApi.signExternshipAgreementStudent(): ${error}`
      );
      throw error;
    }
  }

  static async signProxyAgreement(
    externshipId: number,
    employerId: number,
    data: PdfPostRequest
  ): Promise<ExternshipEntity> {
    try {
      const response = await api.post<ApiResponse<ExternshipEntity>>(
        `${apiConfig.externshipsUrl}/${externshipId}/supervisor/${employerId}/sign-proxy-agreement`,
        data
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.signProxyAgreement(): ${error}`);
      throw error;
    }
  }

  static async reviseAndResendExternshipAgreement(
    externshipId: number,
    data: ReviseExternshipAgreementPostReq
  ): Promise<ApiResponse<ExternshipEntity>> {
    try {
      const response = await api.post<ApiResponse<ExternshipEntity>>(
        `${apiConfig.externshipsUrl}/${externshipId}/revise-externship-agreement`,
        data
      );

      return response.data;
    } catch (error: any) {
      console.error(
        `Error for ExternshipApi.reviseAndResendExternshipAgreement(): ${error}`
      );
      throw error;
    }
  }

  static async getExternshipStartDates(): Promise<ExternshipDatesRes> {
    try {
      const response = await api.get<ApiResponse<ExternshipDatesRes>>(
        `${apiConfig.externshipsUrl}/dates`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ExternshipApi.getExternshipStartDates(): ${error}`
      );
      throw error;
    }
  }

  static async getExternshipGradDates(): Promise<string[]> {
    try {
      const response = await api.get<ApiResponse<string[]>>(
        `${apiConfig.externshipsUrl}/graduation-dates`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ExternshipApi.getExternshipGradDates(): ${error}`
      );
      throw error;
    }
  }

  static async getAllExternshipCourses(): Promise<string[]> {
    try {
      const response = await api.get<ApiResponse<string[]>>(
        `${apiConfig.externshipsUrl}/courses`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ExternshipApi.getAllExternshipCourses(): ${error}`
      );
      throw error;
    }
  }

  static async getTimesheetStatuses(): Promise<TimesheetStatus[]> {
    try {
      const response = await api.get<ApiResponse<TimesheetStatus[]>>(
        `${apiConfig.externshipsUrl}/timesheets/get-statuses`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for ExternshipApi.getTimesheetStatuses(): ${error}`);
      throw error;
    }
  }

  static async updateExternshipStatus(
    externshipId: number,
    status: ExternshipStatus
  ): Promise<ExternshipEntity> {
    try {
      const response = await api.put<ApiResponse<ExternshipEntity>>(
        `${apiConfig.externshipsUrl}/${externshipId}/status`,
        { status }
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ExternshipApi.updateExternshipStatus(): ${error}`
      );
      throw error;
    }
  }

  static async searchPotentialApproverByEmail(
    externshipId: number,
    query: string,
  ): Promise<ExternshipApproverEntity[]> {
    try {
      const response = await api.get<ApiResponse<ExternshipApproverEntity[]>>(
        `${apiConfig.externshipsUrl}/${externshipId}/search-potential-approver?query=${query}`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for ExternshipApi.updateExternshipStatus(): ${error}`
      );
      throw error;
    }
  }
}
