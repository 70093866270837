import {
  coursesDescription,
  quickCoursesDescription,
  quickSkillsDescription
} from '@pages/Partner/GlrcContent/glrcContentText';
import { GlrcContentType, GlrcContentTypes } from '@interfaces/glrc.interfaces';

export const glrcContentTypes: GlrcContentType[] = [
  {
    contentType: GlrcContentTypes.VIDEO,
    name: 'QuickSkills',
    path: 'videos',
    imgUrl: 'glrc.content.videos.jpg',
    icon: 'ri-slideshow-3-line',
    description: quickSkillsDescription
  },
  {
    contentType: GlrcContentTypes.COURSE,
    name: 'QuickCourses',
    path: 'quick-courses',
    imgUrl: 'glrc.content.micro_courses.jpg',
    icon: 'ri-slideshow-line',
    description: quickCoursesDescription
  },
  {
    contentType: GlrcContentTypes.COURSE,
    name: 'Courses',
    path: 'courses',
    imgUrl: 'glrc.content.full_courses.jpg',
    icon: 'ri-stack-line',
    description: coursesDescription
  }
];
