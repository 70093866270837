import * as React from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import { getEcLinks, socialLinks } from './FooterLinks';
import EscoffierLogo from '@assets/png/escoffier-global-solutions-logo.png';
import FooterLink from './FooterLink';
import GlobalSolutionsModal from '@components/GlobalSolutionsModal';
import { styled } from '@mui/system';
import { useHeaderFooterBannerContext } from '@common/context/headerFooterBannerContext';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import { useTagManager } from '@common/hooks/useTagManager';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const WIDTH = 900;
const MEDIA_QUERY = `@media (max-width:${WIDTH}px)`;

const Styled = {
  UnauthRoot: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'footerHeight'
  })<{ footerHeight?: string }>(({ footerHeight }) => ({
    display: 'flex',
    position: 'absolute',
    top: `100% - ${footerHeight}`,
    height: 'max-content',
    width: '100%',
    backgroundColor: '#FFFFFF',
    justifyContent: 'center',
    paddingTop: '40px',
    paddingBottom: '50px',
    [MEDIA_QUERY]: {
      paddingBottom: '80px'
    }
  })),
  FooterWrapper: styled(Box)({
    maxWidth: '1080px',
    padding: '0px 22px'
  }),
  MainFooterContent: styled(Box)({
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    [MEDIA_QUERY]: {
      rowGap: '44px',
      flexDirection: 'column'
    }
  }),
  LogoWrapper: styled(Box)({
    flexBasis: '145px',
    [MEDIA_QUERY]: { flexBasis: 'unset' }
  }),
  Logo: styled('img')({
    width: '171px'
  }),
  EscoffierLinksWrapper: styled(Box)({
    display: 'flex',
    columnGap: '60px',
    justifyContent: 'center',
    [MEDIA_QUERY]: {
      flexDirection: 'column',
      rowGap: '36px'
    }
  }),
  SocialLinksWrapper: styled(Box)({
    display: 'flex',
    alignItems: 'center',
    '& i': {
      fontSize: '21px',
      lineHeight: '21px',
      '&:hover': {}
    }
  }),
  Footnotes: styled(Grid)({
    height: 'max-content',
    maxWidth: '1000px',
    marginTop: '40px',
    textAlign: 'left',
    '& .footnote-box': {
      minHeight: '18px',
      lineHeight: '13px',
      [MEDIA_QUERY]: {
        marginBottom: '18px'
      }
    },
    '& a': {
      textDecoration: 'underline',
      color: '#333333'
    }
  }),
  Copyright: styled(Box)({
    marginTop: '40px',
    lineHeight: '18px',
    [MEDIA_QUERY]: {
      marginTop: '32px',
      textAlign: 'left'
    }
  })
};

interface Props {
  handleOpenModal: () => void;
}

const MarketingContent = ({ handleOpenModal }: Props): React.ReactElement => {
  const [isEcStudent] = useStore<boolean>(UseStoreKeys.IS_EC_STUDENT);
  const { sendToGtm } = useTagManager();
  const { footnotes, supRef } = useHeaderFooterBannerContext();

  const fireFooterEvent = (url: string, text: string): void => {
    sendToGtm('footer', {
      navigate_to: url,
      link_text: text
    });
  };

  const escoffierLinks = getEcLinks(isEcStudent);

  return (
    <Styled.FooterWrapper>
      <Styled.MainFooterContent>
        <Styled.LogoWrapper>
          <Link
            component="a"
            href="https://escoffierglobal.com/"
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
          >
            <Styled.Logo
              data-testid="footer-logo"
              src={EscoffierLogo}
              alt="escoffier-connect-logo"
            />
          </Link>
        </Styled.LogoWrapper>
        <Styled.EscoffierLinksWrapper>
          {escoffierLinks.map((link) => (
            <Box key={link.section}>
              <Typography
                variant="EC_TYPE_SM"
                textTransform="uppercase"
                mb="12px"
              >
                {link.section}
              </Typography>
              {link.links.map((l) => {
                return (
                  <FooterLink
                    key={l.title}
                    link={l}
                    fireFooterEvent={fireFooterEvent}
                    callBack={l.openModalOnClick ? handleOpenModal : undefined}
                  />
                );
              })}
            </Box>
          ))}
        </Styled.EscoffierLinksWrapper>
        <Styled.SocialLinksWrapper>
          {socialLinks.map((link) => (
            <Box key={link.url}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                onClick={(): void =>
                  fireFooterEvent(link.url, `${link.gtmName} icon`)
                }
                data-testid="footer-social-link"
                href={link.url}
                underline="none"
              >
                {link.icon}
              </Link>
            </Box>
          ))}
        </Styled.SocialLinksWrapper>
      </Styled.MainFooterContent>
      {footnotes && (
        <Styled.Footnotes item sm={12}>
          {footnotes.map((note, idx) => (
            <Box
              key={idx}
              className="footnote-box"
              ref={supRef}
              textAlign="center"
            >
              {note.url ? (
                <Typography variant="copyright">
                  <sup>{note.numbers.map((number) => number).join(' ')}</sup>{' '}
                  <a
                    href={note.url}
                    onClick={(): void => fireFooterEvent(note.url!, note.text)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {note.text}
                  </a>
                </Typography>
              ) : (
                <Typography variant="copyright">
                  <sup>{note.numbers.map((number) => number).join(' ')}</sup>{' '}
                  {note.text}
                </Typography>
              )}
            </Box>
          ))}
        </Styled.Footnotes>
      )}
      <Styled.Copyright>
        <Typography variant="copyright" data-testid="footer-unauth-copyright">
          Copyright © Auguste Escoffier Global Solutions™. All rights reserved.
          Auguste Escoffier Global Solutions™ and the Auguste Escoffier School
          of Culinary Arts logo are trademarks of Triumph Higher Education Group
          LLC. Auguste Escoffier School of Culinary Arts is a registered
          trademark of Triumph Higher Education Group LLC.&nbsp;&nbsp;
          <span>
            <Link
              href="https://escoffierglobal.com/privacy/"
              onClick={(): void =>
                fireFooterEvent(
                  'https://escoffierglobal.com/privacy/',
                  'Privacy Policy'
                )
              }
              underline="always"
              typography="copyright"
              sx={{ textDecorationColor: '#000000' }}
            >
              Privacy Policy
            </Link>
            &nbsp;|&nbsp;
            <Link
              href="https://escoffierglobal.com/terms/"
              onClick={(): void =>
                fireFooterEvent(
                  'https://escoffierglobal.com/terms/',
                  'Terms of Use'
                )
              }
              underline="always"
              typography="copyright"
              sx={{ textDecorationColor: '#000000' }}
            >
              Terms of Use
            </Link>
          </span>
        </Typography>
      </Styled.Copyright>
    </Styled.FooterWrapper>
  );
};

const Footer: React.FC = () => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const { width } = useWindowDimensions();
  const footerHeight = width <= 600 ? '497.76px' : '268.26px';

  const handleOpenModal = (): void => {
    setModalOpen(true);
  };

  const handleCloseModal = (): void => {
    setModalOpen(false);
  };

  return (
    <>
      <Styled.UnauthRoot
        data-testid="footer-unauth-root"
        footerHeight={footerHeight}
      >
        <MarketingContent handleOpenModal={handleOpenModal} />
      </Styled.UnauthRoot>
      <GlobalSolutionsModal
        open={modalOpen}
        handleClose={handleCloseModal}
        externalLink="https://www.escoffier.edu/"
      />
    </>
  );
};

export default Footer;
