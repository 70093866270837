import * as React from 'react';
import { Box } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const Styled = {
  Wrapper: styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: '150px',
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: 'rgba(0, 0, 0, 0.09)'
      },
      color: theme.palette.GRAY_3.main,
      fontWeight: 700
    }
  })
};

interface BottomPaginationProps {
  count: number;
  page?: number;
  onChange?: (event, value: number) => void;
  defaultPage?: number;
  disabled?: boolean;
  wrapperSx?: React.CSSProperties;
}

const BottomPagination: React.FC<BottomPaginationProps> = (props) => {
  const {
    count,
    page,
    onChange,
    defaultPage,
    disabled = false,
    wrapperSx
  } = props;

  return (
    <Styled.Wrapper sx={{ ...wrapperSx }}>
      <Pagination
        count={count}
        page={page}
        onChange={onChange}
        defaultPage={defaultPage}
        disabled={disabled}
      />
    </Styled.Wrapper>
  );
};

export default BottomPagination;
