/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  CandidateResume,
  ResumeComponentArray
} from '@pages/Candidate/ResumePreviewModal/resumePreviewModal.models';
import { Box } from '@mui/material';
import { CandidateProfile } from '@api/models/candidateApi.models';
import { getResumeComponentArray } from '@pages/Candidate/ResumePreviewModal/ResumePreview/resumeComponentArray';
import { getResumeProfile } from '../getResumeProfile';
import PdfLoadingOverlay from '@components/PdfLoadingOverlay';
import ResumeEditOptions from '@pages/Candidate/ResumePreviewModal/ResumePreview/ResumeEditOptions';
import { ResumePopperState } from '@pages/Candidate/ResumePreviewModal/ResumePreviewModal';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const Styled = {
  Root: styled(Box)({
    position: 'relative' as 'relative',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.GRAY_1.main,
    border: `3px solid ${theme.palette.GOLD_1.main}`,
    borderTop: 'none',
    padding: '26px'
  }),
  PdfWrapper: styled(Box)({
    transformOrigin: 'top center',
    border: `1px solid ${theme.palette.GRAY_2.main}`,
    flexShrink: 0,
    backgroundColor: 'white',
    width: '8.5in', // 816 px
    padding: '48px'
  }),
  Hidden: styled(Box)({
    width: '100%',
    height: '30px'
  })
};

interface Props {
  candidateProfile: CandidateProfile;
  displayEditButtons: boolean;
  popperState: ResumePopperState;
  loading: boolean;
  handleOptionClick: (
    id: string
  ) => (event: React.MouseEvent<HTMLElement>) => void;
  handleClosePopper: () => void;
  setDisableActionBar: (disable: boolean) => void;
}

const ResumePreview: React.FC<Props> = ({
  candidateProfile,
  displayEditButtons,
  popperState,
  loading,
  handleOptionClick,
  handleClosePopper,
  setDisableActionBar
}) => {
  // CandidateResume
  const initProfile = getResumeProfile(candidateProfile);
  // ResumeComponentArray[] -> passes props (from profile) to component array
  const initComponentArray = getResumeComponentArray(initProfile);

  const [profile, setProfile] = React.useState<CandidateResume>(initProfile);
  const [components, setComponents] =
    React.useState<ResumeComponentArray[]>(initComponentArray);

  const rootRef = React.useRef<HTMLDivElement | null>(null);
  const pdfRef = React.useRef<HTMLDivElement | null>(null);
  const { width } = useWindowDimensions();

  const allSectionsHidden = (): boolean => {
    return components.every((c) => {
      if (c.hidden) {
        return true;
      }

      const items = c.options.items;
      const isItemArray = Array.isArray(items);

      if (!items) {
        return true;
      }

      if (!isItemArray) {
        return !items.props.length || items.props.every((p) => p.hidden);
      }

      if (isItemArray) {
        return (
          !items.length || items.every((i) => i.props.every((p) => p.hidden))
        );
      }
      return false;
    });
  };

  React.useEffect(() => {
    setDisableActionBar(allSectionsHidden());
  }, [components]);

  const { anchorEl, open } = popperState;

  const handleResize = (
    rootDiv: HTMLDivElement,
    pdfDiv: HTMLDivElement
  ): void => {
    const pdfHeight = pdfDiv.scrollHeight;

    const scale = Math.min(
      rootDiv.offsetWidth / (pdfDiv.offsetWidth + 52),
      rootDiv.offsetHeight / (pdfDiv.offsetHeight + 52)
    );

    pdfDiv.style.transform = `scale(${scale})`;
    pdfDiv.style.height = `${pdfHeight}px`;
    rootDiv.style.height = `${pdfHeight * scale + 52}px`;
  };

  React.useLayoutEffect(() => {
    if (rootRef.current && pdfRef.current) {
      handleResize(rootRef.current, pdfRef.current);
    }
  }, [width, pdfRef.current, rootRef.current]);

  // Update and sort component array when ever profile state is updated
  React.useEffect(() => {
    const newProfile = profile;
    const newArr = getResumeComponentArray(newProfile).sort(
      (a, b) => a.order - b.order
    );
    setComponents(newArr);
  }, [profile]);

  const moveUp = (id: string): void => {
    const prof: CandidateResume = { ...profile };
    const el = prof[id];
    const prevEl = Object.values(prof).find(
      (item) => item.order === el.order - 1
    );

    const currOrder = el['order'];
    el['order'] = prevEl['order'];
    prevEl['order'] = currOrder;

    setProfile(prof);
  };

  const moveDown = (id: string): void => {
    const prof: CandidateResume = { ...profile };
    const el = prof[id];
    const nextEl = Object.values(prof).find(
      (item) => item.order === el.order + 1
    );

    const currOrder = el['order'];
    el['order'] = nextEl['order'];
    nextEl['order'] = currOrder;

    setProfile(prof);
  };

  const getPopperHeaderStyle = (
    id: string
  ): React.CSSProperties | undefined => {
    if (id === initComponentArray[0].id) {
      return {
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translate(-50%, -62px)'
      };
    }
  };

  const handleHideItem = (
    key: string,
    displayValue: string[] | string,
    value: string
  ): void => {
    const prof = { ...profile };
    const profObj =
      key === 'overviewItems'
        ? prof['overview'].overviewItems
        : key === 'contactItems'
        ? prof['overview'].contactItems
        : prof[key];

    let index: number;

    // special case for candidateCulinaryPhotos
    if (Array.isArray(displayValue)) {
      index = profObj.props.findIndex(
        (item) => item[displayValue[0]][displayValue[1]] === value
      );
    } else {
      index = profObj.props.findIndex((item) => item[displayValue] === value);
    }

    const item = profObj.props[index];

    if (item['hidden'] === undefined) {
      item['hidden'] = true;
    } else {
      item['hidden'] = !item.hidden;
      if (profObj['hidden'] === true) {
        profObj['hidden'] = false;
      }
    }

    // if all props of a section are hidden, set section to hidden
    const allHidden =
      profObj.props.findIndex((item) => item['hidden'] !== true) === -1;
    if (allHidden) {
      profObj['hidden'] = true;
    }

    setProfile(prof);
  };

  const handleHideSection = (id: string): void => {
    const prof: CandidateResume = { ...profile };
    const profObj = prof[id];
    const { props: _props } = profObj;

    if (id === 'statement') {
      profObj['hidden'] = !profObj['hidden'];

      setProfile(prof);
    } else {
      if (profObj['hidden'] !== undefined) {
        profObj['hidden'] = !profObj['hidden'];
        _props.forEach((item) => (item['hidden'] = profObj['hidden']));
      } else {
        profObj['hidden'] = true;
        _props.forEach((item) => (item['hidden'] = profObj['hidden']));
      }
      setProfile(prof);
    }
  };

  return (
    <Styled.Root data-testid="resume-preview-root" ref={rootRef}>
      <Styled.PdfWrapper id="printable-resume" ref={pdfRef}>
        <div>
          {components.map((obj, idx) => {
            const isHidden = obj.hidden === true;
            const isHeader = obj.id === initComponentArray[0].id;
            const btnStyle = getPopperHeaderStyle(obj.id);
            const isLastItem = obj.id === components[components.length - 1].id;
            const emptyProps = Array.isArray(obj.options.items)
              ? obj.options.items.some((item) => item.props.length === 0)
              : obj.options.items.props.length === 0;

            return (
              <div key={obj.id} style={{ position: 'relative' }}>
                {displayEditButtons && !emptyProps && (
                  <ResumeEditOptions
                    popperId={obj.id}
                    anchorEl={anchorEl?.[obj.id] ?? null}
                    open={open?.[obj.id] ?? false}
                    options={obj.options}
                    sectionHidden={isHidden}
                    handleOpen={handleOptionClick}
                    handleClickAway={handleClosePopper}
                    buttonStyle={btnStyle}
                    popperPlacement={isHeader ? 'bottom' : 'left-start'}
                    moveUp={moveUp}
                    moveDown={moveDown}
                    handleHideItem={handleHideItem}
                    hideSection={handleHideSection}
                    disableHeaderBtns={isHeader}
                    disableMoveUp={idx === 1}
                    disableMoveDown={isLastItem}
                    disableSubSection={obj.id === 'statement'}
                  />
                )}
                {!isHidden ? (
                  obj.component
                ) : displayEditButtons ? (
                  <Styled.Hidden />
                ) : null}
              </div>
            );
          })}
        </div>
      </Styled.PdfWrapper>
      <PdfLoadingOverlay
        loading={loading}
        dataTestId="resume-loading-overlay"
      />
    </Styled.Root>
  );
};

export default ResumePreview;
