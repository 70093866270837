/* eslint-disable @typescript-eslint/indent */
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Location } from '@interfaces/location.interfaces';

export const renderAddress = (params: GridRenderCellParams): string => {
  const location: Location = params.row.address;
  const streetName = location?.streetName;
  const streetNumber = location?.streetNumber;
  const city = location?.city;

  const address =
    !streetName || !streetNumber
      ? `${city ?? 'N/A'}`
      : `${streetNumber} ${streetName}, ${city}`;

  return address;
};
