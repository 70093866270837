/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Box, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { GlrcContentType, GlrcTopic } from '@interfaces/glrc.interfaces';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { displayTextWithSelectLettersLarger } from '@common/helpers/displayTextWithSelectLettersLarger';
import { getCompanyPath } from '@common/helpers/getPathWithParamsId';
import { getGlrcTopicPath } from '@pages/Partner/GlrcContent/glrc.helpers';
import GlrcSection from '@pages/Partner/GlrcSection';
import { styled } from '@mui/system';
import { useHistory } from 'react-router-dom';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';
import { useTagManager } from '@common/hooks/useTagManager';

const MAX_TILE_HEIGHT = 450;

const Styled = {
  TileRowContainer: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'height'
  })<{ height: string }>(({ height }) => ({
    height,
    display: 'flex',
    flexDirection: 'column'
  })),
  TopicsWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'flex'
  })<{ isMobile: boolean; flex: number | string }>(({ isMobile, flex }) => ({
    flex: `${flex} 1 auto`,
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    transition: 'flex-grow .5s ease-in-out'
  })),
  NoAccessText: styled(Typography)(({ theme }) => ({
    width: '100%',
    color: theme.palette.GRAY_3.main,
    margin: '48px 16px',
    textAlign: 'center'
  })),
  Tile: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'flex'
  })<{ flex: number | string }>(({ flex }) => ({
    flex,
    transition: 'all .5s ease-in-out',
    display: 'flex',
    padding: '24px',
    minHeight: '150px',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      background: 'black',
      opacity: '0.5'
    }
  })),
  ContentTileTitleBox: styled(Box)({
    ':hover': {
      textAlign: 'left'
    }
  }),
  Icon: styled('i')(({ theme }) => ({
    color: theme.palette.GRAY_2.main,
    fontSize: '45px',
    lineHeight: '56px',
    height: '56px'
  })),
  Title: styled(Typography)(({ theme }) => ({
    fontSize: (theme as Theme).typography.EC_TYPE_4XL.fontSize,
    lineHeight: (theme as Theme).typography.EC_TYPE_4XL.lineHeight,
    zIndex: 11,
    color: theme.palette.GRAY_2.main,
    textTransform: 'uppercase'
  })),
  TileContent: styled(Box)({
    zIndex: 1,
    ':hover': {
      cursor: 'pointer'
    }
  }),
  Content: styled(Box)({
    maxWidth: '680px',
    margin: '50px'
  }),
  TileContentWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'justifyContent'
  })<{ justifyContent: string }>(({ justifyContent }) => ({
    zIndex: 1,
    flex: 1,
    display: 'flex',
    justifyContent,
    alignItems: 'center'
  }))
};

type TileType = GlrcTopic | GlrcContentType;

interface ContentProps<T extends TileType> {
  content: T;
  path?: string;
  noneActive: boolean;
  active: boolean;
  isMobile: boolean;
}

interface Props<T extends TileType> {
  tileContents: T[];
  sectionTitle: string;
}

const TileContent: React.FC<ContentProps<TileType>> = ({
  content,
  path,
  noneActive,
  active,
  isMobile
}) => {
  const { sendToGtm } = useTagManager();
  const history = useHistory();
  const isTopicType = 'description' in content;

  const Title = (): JSX.Element => {
    // If contentType
    if ('icon' in content) {
      const name =
        content.name === 'QuickSkills'
          ? '[Q]uick[S]kills'
          : content.name === 'QuickCourses'
          ? '[Q]uick[C]ourses'
          : content.name;

      return (
        <Styled.ContentTileTitleBox
          sx={{
            textAlign: noneActive || isMobile ? 'center' : 'left'
          }}
        >
          <Styled.Icon className={content.icon} />
          <Styled.Title
            variant="EC_TYPE_3XL"
            textAlign={isMobile || noneActive ? 'center' : 'unset'}
          >
            {displayTextWithSelectLettersLarger(name, 50)}
          </Styled.Title>
        </Styled.ContentTileTitleBox>
      );
    } else {
      const name = content.name === 'Baking & Pastry' ? 'Pastry' : content.name;

      return (
        <Styled.Title
          variant="EC_TYPE_3XL"
          textAlign={isMobile || noneActive ? 'center' : 'unset'}
        >
          {name}
        </Styled.Title>
      );
    }
  };

  const description = content.description.split('\n').map((str) => (
    <Typography variant="EC_TYPE_BASE" key={str} sx={{ color: 'GRAY_1.main' }}>
      {str}
    </Typography>
  ));

  const handleNavigation = (): void => {
    sendToGtm('navigation', {
      navigate_to: path,
      component: 'learning_center_dashboard',
      link_text: content.name
    });

    history.push({ pathname: path });
  };

  return (
    <Styled.TileContent onClick={handleNavigation}>
      {active ? (
        <Styled.Content>
          <BottomMarginContentWrapper bottomMargin="24px">
            <Title />
            {isTopicType && (
              <BottomMarginContentWrapper bottomMargin="16px">
                {description}
              </BottomMarginContentWrapper>
            )}
          </BottomMarginContentWrapper>
        </Styled.Content>
      ) : (
        <>
          {isMobile || noneActive ? (
            <Title />
          ) : (
            <>
              {!noneActive && (
                <Box
                  sx={{
                    width: '50px'
                  }}
                />
              )}
            </>
          )}
        </>
      )}
    </Styled.TileContent>
  );
};

const getTileGroups = (tiles: TileType[]): TileType[][] => {
  // If there are more than 4 tiles, split tile array into groups of three to create rows
  let groups: TileType[][] = [];
  if (tiles.length > 4) {
    for (let i = 0; i < tiles.length; i += 3) {
      groups.push(tiles.slice(i, i + 3));
    }
  } else {
    groups = [tiles];
  }

  return groups;
};

type ActiveType = {
  name: string;
  groupIdx: number;
};

const GlrcTiles: React.FC<Props<TileType>> = ({
  tileContents,
  sectionTitle
}) => {
  const tileContentGroups = getTileGroups(tileContents);
  const theme = useTheme();
  const { paramsId } = useParamsOrStoreCompanyId();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [activeTopic, setActiveTopic] = React.useState<ActiveType>({
    name: '',
    groupIdx: -1
  });

  const getFlexValueX = (name: string, groupIdx: number): number | string => {
    if (activeTopic.name === undefined || activeTopic.name === name) {
      return 1;
    }

    return groupIdx === activeTopic.groupIdx ? 'unset' : 1;
  };

  const getFlexValueY = (groupIdx: number): number | string => {
    if (activeTopic.groupIdx === -1 || activeTopic.groupIdx === groupIdx) {
      return 1;
    }

    return 'unset';
  };

  const tileContainerHeight = isMobile
    ? 'fit-content'
    : `${MAX_TILE_HEIGHT * tileContentGroups.length}px`;

  return (
    <GlrcSection title={sectionTitle}>
      <>
        {!tileContentGroups.length && (
          <Styled.NoAccessText variant="EC_TYPE_LG">
            You don&apos;t have any access to this content. Contact your
            administrator for help.
          </Styled.NoAccessText>
        )}

        <Styled.TileRowContainer height={tileContainerHeight}>
          {tileContentGroups.map((group, groupIdx) => (
            <Styled.TopicsWrapper
              key={groupIdx}
              isMobile={isMobile}
              flex={getFlexValueY(groupIdx)}
              onMouseLeave={(): void =>
                setActiveTopic({ name: '', groupIdx: -1 })
              }
            >
              {group.map((content) => {
                const handleSetTopic = (): void =>
                  setActiveTopic({ name: content.name, groupIdx });
                const path =
                  'path' in content
                    ? getCompanyPath(
                        `/learning-center/${content.path}`,
                        paramsId
                      )
                    : getCompanyPath(
                        `/learning-center/${getGlrcTopicPath(content.name)}`,
                        paramsId
                      );

                const image =
                  require(`@assets/PartnerDashboardPage/${content.imgUrl}`).default;

                return (
                  <Styled.Tile
                    key={content.name}
                    flex={getFlexValueX(content.name, groupIdx)}
                    sx={{
                      backgroundImage: `url(${image})`
                    }}
                  >
                    <Styled.TileContentWrapper
                      onMouseEnter={handleSetTopic}
                      justifyContent={
                        activeTopic.name === content.name ? 'left' : 'center'
                      }
                    >
                      <TileContent
                        noneActive={activeTopic.name === ''}
                        active={activeTopic.name === content.name}
                        content={content}
                        path={path}
                        isMobile={isMobile}
                      />
                    </Styled.TileContentWrapper>
                  </Styled.Tile>
                );
              })}
            </Styled.TopicsWrapper>
          ))}
        </Styled.TileRowContainer>
      </>
    </GlrcSection>
  );
};

export default GlrcTiles;
