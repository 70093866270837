import * as React from 'react';
import { Box, Skeleton } from '@mui/material';
import LayoutRightSidebar from '@components/Layout/LayoutRightSidebar';

const randomIntFromInterval = (min, max): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const PrimaryContent: React.FC = () => {
  return (
    <Box>
      <Skeleton variant="rectangular" height={278} sx={{ mb: '30px' }} />
      <Skeleton variant="rectangular" height={15} width={50} />
      <Box
        sx={{
          display: 'flex',
          columnGap: '13px',
          rowGap: '12px',
          flexWrap: 'wrap',
          marginTop: '10px'
        }}
      >
        {Array(12)
          .fill(0)
          .map((_, idx) => (
            <Skeleton
              key={idx}
              variant="rectangular"
              height={34}
              width={randomIntFromInterval(80, 140)}
            />
          ))}
      </Box>
      {Array(3)
        .fill(0)
        .map((_, idx) => (
          <Box key={idx} sx={{ mt: '32px' }}>
            <Skeleton
              variant="rectangular"
              height={15}
              width={50}
              sx={{ mb: '10px' }}
            />
            <Skeleton variant="rectangular" height={200} />
          </Box>
        ))}
    </Box>
  );
};

const SecondaryContent: React.FC = () => {
  return (
    <Box>
      <Skeleton
        variant="rectangular"
        height={25}
        width={200}
        sx={{ mb: '32px' }}
      />
      <Box sx={{ display: 'flex', columnGap: '25px', mb: '30px' }}>
        {Array(3)
          .fill(0)
          .map((_, idx) => (
            <Skeleton key={idx} variant="rectangular" height={25} width={60} />
          ))}
      </Box>
      <Skeleton variant="rectangular" height={19} width={55} />
      <Skeleton variant="rectangular" height={150} sx={{ mt: '10px' }} />
    </Box>
  );
};

const CandidatePreviewPageSkeleton: React.FC = () => {
  return (
    <LayoutRightSidebar
      primaryContent={<PrimaryContent />}
      secondaryContent={<SecondaryContent />}
    />
  );
};

export default CandidatePreviewPageSkeleton;
