import * as React from 'react';
import BasicModal from '@components/BasicModal';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { Typography } from '@mui/material';
import { ApproverRole, ExternshipApproverEntity } from '@api/models/externshipApi.models';

const getApproverLabel = (approver: ExternshipApproverEntity): string => {
  let label = '';

  if (approver.firstName && approver.lastName) {
    label += `${approver.firstName} ${approver.lastName}`;
  }

  if (approver.user?.email || approver.invite?.email) {
    label += `${label.length ? ' - ' : ''}${approver.user?.email || approver.invite?.email}`;
  }

  return label;
};

interface Props {
  open: boolean;
  approver: ExternshipApproverEntity;
  handleClose: () => void;
  handleRemoveApprover: (approver: ExternshipApproverEntity) => void;
}

const RemoveApproverModal: React.FC<Props> = ({
  open,
  approver,
  handleClose,
  handleRemoveApprover,
}) => {
  const [disableActions, setDisableActions] = React.useState(false);

  const handleRemove = (): void => {
    setDisableActions(true);
    handleRemoveApprover(approver);
    setDisableActions(false);
  };

  const isManager = approver.approverRole === ApproverRole.MANAGER;

  return (
    <BasicModal
      open={open}
      handleClose={handleClose}
      title="Remove Approver"
      primaryAction={{
        label: 'Confirm',
        action: handleRemove,
        disabled: disableActions
      }}
      secondaryAction={{
        label: 'Cancel',
        action: handleClose,
        disabled: disableActions
      }}
      maxWidth="sm"
    >
      <BottomMarginContentWrapper bottomMargin={'24px'}>
        <Typography>
          You are about to remove the following{' '}
          <strong>{approver.approverRole}</strong> from this
          externship:
        </Typography>

        <Typography variant="EC_TYPE_SM">{getApproverLabel(approver)}</Typography>
        {isManager && (
          <Typography>
            If the externship agreement has been signed by career services it
            will remain with the site proposal and a new externship agreement
            will not be required to be signed by a new manager.
          </Typography>
        )}
      </BottomMarginContentWrapper>
    </BasicModal>
  );
};

export default RemoveApproverModal;
