import * as React from 'react';
import ResumeSection from './ResumeSection';
import { ResumeSkills } from '../resumePreviewModal.models';
import theme from '@themes/ui/escoffier';

const styles = {
  skillsWrapper: {
    display: 'flex',
    columnGap: '5px',
    flexWrap: 'wrap' as 'wrap',
    rowGap: '10px'
  },
  skillTag: {
    padding: '2.5px 10px',
    border: `1px solid ${theme.palette.GRAY_3.main}`,
    display: 'inline-block',
    borderRadius: '4px',
    ...theme.typography.EC_TYPE_3XS,
    color: theme.palette.GRAY_3.main
  }
};

interface Props {
  skills: ResumeSkills[];
}

const ResumeSkillsSection: React.FC<Props> = ({ skills }) => {
  const SkillTag: React.FC<{ label: string }> = ({ label }) => {
    return (
      <div style={styles.skillTag} data-testid="resume-skill-tag">
        {label}
      </div>
    );
  };

  return (
    <ResumeSection title="Skills">
      <div style={styles.skillsWrapper}>
        {skills.map((skill) => {
          const hidden = skill.hidden === true;

          if (!hidden) {
            return <SkillTag key={skill.label} label={skill.label} />;
          }
        })}
      </div>
    </ResumeSection>
  );
};

export default ResumeSkillsSection;
