/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import IncompleteProfileModal, {
  ModalProps
} from '@components/PreviewPageComponents/IncompleteProfileModal';
import {
  redirectStorageKeys,
  redirectStorageMessages
} from '@common/helpers/storage';
import CareerMarketplacePage from '@pages/Shared/CareerMarketplace/CareerMarketplacePage';
import PageLayoutLeftSidebarSkeleton from '@components/Skeletons/PageLayoutLeftSidebarSkeleton';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useKeycloakContext } from '@common/context/keycloakContext';

type MarketplaceModalProps = Omit<ModalProps, 'open' | 'handleClose'>;

const CareerMarketplaceLanding = (): React.ReactElement => {
  const [showModal, setShowModal] = React.useState(false);

  const { keycloak } = useKeycloak();
  const { isAuthenticated, keycloakUser } = useKeycloakContext();

  const history = useHistory();

  const closeSigninModal = (): void => {
    history.goBack();
    setShowModal(false);
  };

  const loginFromModal = React.useCallback((): void => {
    const redirect = window.location.href;

    localStorage.removeItem(redirectStorageKeys.CAREER_MARKETPLACE);
    if (!isAuthenticated) {
      keycloak.login({ redirectUri: redirect });
    } else {
      history.push('/create-account');
    }
  }, [keycloak]);

  const modalProps: MarketplaceModalProps = {
    title: 'Have you joined Escoffier Connect?',
    subtitle: !isAuthenticated
      ? 'You must be signed in to view EConnect.'
      : 'You must create account to can navigate trough the application.',
    secondarySubtitle: !isAuthenticated
      ? 'Please sign in or create an account to continue, or cancel to be redirected back.'
      : 'Please create an account to continue, or cancel to be redirected back.',
    ignoreIncompleteProfile: true,
    localStorageMessage: !isAuthenticated
      ? redirectStorageMessages.NO_USER
      : '',
    localStorageKey: redirectStorageKeys.CAREER_MARKETPLACE,
    primaryButtonText: !isAuthenticated ? 'Sign In' : 'Create Account',
    secondaryButtonText: 'Cancel',
    primaryClickLocation: loginFromModal,
    secondaryClick: closeSigninModal,
    ...(!isAuthenticated
      ? {
          tertiaryCtaPath: '/create-account',
          tertiaryButtonText: 'Need an account? Register here'
        }
      : {})
  };

  React.useEffect(() => {
    if (!isAuthenticated || (keycloakUser && !keycloakUser.roles?.length)) {
      setShowModal(true);
    }
  }, [isAuthenticated]);

  const readyToRender: boolean =
    !!isAuthenticated && !!keycloakUser && !!keycloakUser.roles?.length;

  return (
    <>
      {readyToRender ? (
        <CareerMarketplacePage />
      ) : (
        <PageLayoutLeftSidebarSkeleton pageTitle="EConnect" />
      )}
      <IncompleteProfileModal
        open={showModal}
        handleClose={closeSigninModal}
        {...modalProps}
      />
    </>
  );
};

export default CareerMarketplaceLanding;
