import * as React from 'react';
import AccountsModal from '@pages/EcAdmin/AdminDashboardPage/CandidateAccountsSection/AccountsModal';
import { AdminStatsResponse } from '@api/models/adminDashboardApi.models';
import BarChartWithSideContent from '@pages/EcAdmin/AdminDashboardPage/CandidateAccountsSection/BarChartWithSideContent';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { Box } from '@mui/material';
import { CodebookCountInterface } from '@api/models/codebook.models';
import { DateRange } from '@common/models/common.models';
import LabelWithValue from '@components/LabelWithValue';

interface Props {
  stats: AdminStatsResponse;
  chartLabel: string;
  dateRange: DateRange;
}

enum GraduationTypeBarLabel {
  ESCOFFIER_STUDENT = 1,
  ESCOFFIER_GRADUATE = 2,
  NOT_AFFILIATED = 99
}

const OverviewTabPanel: React.FC<Props> = ({
  stats,
  chartLabel,
  dateRange
}) => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [graduationType, setGraduationType] =
    React.useState<CodebookCountInterface>();
  const [completeProfiles, setCompleteProfiles] =
    React.useState<boolean>(false);

  const { candidateAccounts, completedCandidateProfiles } = stats;
  const graduationTypes = stats.createdAccounts.selfIdentification;

  const handleLabelClick = (
    completeProfiles?: boolean,
    graduationType?: CodebookCountInterface
  ): void => {
    setGraduationType(graduationType);
    setCompleteProfiles(!!completeProfiles);
    setOpenModal(true);
  };

  const candidateAcctsContent = [
    {
      label: 'Accounts Created',
      value: candidateAccounts,
      onClick: (): void => handleLabelClick()
    },
    {
      label: 'Completed Profiles',
      value: completedCandidateProfiles,
      onClick: (): void => handleLabelClick(true)
    }
  ];

  const getBarLabel = (stat: CodebookCountInterface): string => {
    switch (stat.value) {
      case GraduationTypeBarLabel.ESCOFFIER_GRADUATE:
        return 'Graduate';
      case GraduationTypeBarLabel.ESCOFFIER_STUDENT:
        return 'Student';
      case GraduationTypeBarLabel.NOT_AFFILIATED:
        return 'Not Affiliated';
      default:
        return '';
    }
  };

  const barChartData: { name: string; value: number }[] = [];

  graduationTypes.map((type) => {
    candidateAcctsContent.push({
      label: type.label,
      value: type.count,
      onClick: (): void => handleLabelClick(false, type)
    });
    barChartData.push({
      name: getBarLabel(type),
      value: type.count
    });
  });

  return (
    <BarChartWithSideContent
      barData={barChartData}
      barName="Accounts"
      label={chartLabel}
      content={
        <>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ display: 'inline-block' }}>
              <BottomMarginContentWrapper bottomMargin="16px">
                {candidateAcctsContent.map((data) => (
                  <LabelWithValue
                    onClick={data.onClick}
                    key={data.label}
                    label={data.label}
                    value={data.value}
                  />
                ))}
              </BottomMarginContentWrapper>
            </Box>
          </Box>
          {openModal && (
            <AccountsModal
              open={openModal}
              graduationType={graduationType}
              dateRange={dateRange}
              handleClose={(): void => setOpenModal(false)}
              getCompletedProfiles={completeProfiles}
            />
          )}
        </>
      }
    />
  );
};

export default OverviewTabPanel;
