import * as React from 'react';
import { Box, Icon, Link, Typography } from '@mui/material';
import {
  getFacebookInstagramAlias,
  getLinkedinAlias,
  getWebsiteAlias
} from '@common/helpers/getSocialUrlAlias';
import JobCandidateInfoSection, {
  DisplayData
} from '@pages/Employer/JobCandidates/JobCandidateInfoSection';
import { AppliedCandidate } from '@api/models/jobApplicationApi.models';
import facebookIcon from '@assets/svg/facebookIcon.svg';
import instagramIcon from '@assets/svg/instagramIcon.svg';
import linkedinIcon from '@assets/svg/linkedinIcon.svg';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useResponsiveLayoutContext } from '@components/Layout/ResponsiveLayout';
import VettedQuestionAnswerSection from './VettedQuestionAnswerSection';
import { VettedQuestionsAnswers } from '@api/models/jobApplicationApi.models';
import websiteIcon from '@assets/svg/websiteIcon.svg';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const Styled = {
  Root: styled(Box, {
    shouldForwardProp: (prop) =>
      prop !== 'breakpointReached' && prop !== 'shouldDisplay'
  })<{ breakpointReached: boolean; shouldDisplay: boolean }>(
    ({ breakpointReached, shouldDisplay }) => ({
      display: !shouldDisplay ? 'none' : 'flex',
      flexDirection: 'column',
      flex: !breakpointReached ? '1 1 700px' : 'unset',
      width: breakpointReached ? '98%' : '100%',
      margin: breakpointReached ? 'auto' : 0,
      overflowX: 'hidden',
      overflowY: 'scroll',
      maxHeight: breakpointReached ? '80vh' : 'unset'
    })
  ),
  TitleBox: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'breakpointReached'
  })<{ breakpointReached: boolean }>(({ breakpointReached }) => ({
    display: 'flex',
    width: '100%',
    minHeight: !breakpointReached ? '71px' : 'unset',
    paddingLeft: '42px',
    borderBottom: !breakpointReached
      ? `1px solid ${theme.palette.GRAY_2.main}`
      : 'none',
    borderLeft: !breakpointReached
      ? `1px solid ${theme.palette.GRAY_2.main}`
      : 'none',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '24px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      width: '95%',
      margin: '0 auto'
    }
  })),
  Name: styled(Typography)({
    color: theme.palette.GRAY_4.main,
    margin: 'auto 0',
    whiteSpace: 'initial',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }),
  ContentBox: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'breakpointReached'
  })<{ breakpointReached: boolean }>(({ breakpointReached }) => ({
    paddingRight: '24px',
    paddingLeft: '42px',
    borderLeft: !breakpointReached
      ? `1px solid ${theme.palette.GRAY_2.main}`
      : 'none',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      paddingRight: '12px',
      paddingLeft: '24px'
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      width: '95%',
      margin: '0 auto'
    }
  })),
  ExternalLink: styled(Link)({
    color: theme.palette.primary.main,
    marginLeft: '8px',
    position: 'relative',
    top: '1.5px'
  }),
  LinkIcon: styled(Icon)({
    fontSize: '18px',
    lineHeight: '18px'
  })
};

interface IJobCandidateInfo {
  candidate: AppliedCandidate | null;
  vettedQA?: VettedQuestionsAnswers;
}

const JobCandidateInfo: React.FC<IJobCandidateInfo> = (props) => {
  const { candidate, vettedQA } = props;
  const { customBreakpointReached } = useResponsiveLayoutContext();
  const shouldDisplay = Boolean(candidate);

  const contactData: DisplayData[] = [
    {
      icon: 'ri-mail-fill',
      label: 'Email',
      value: candidate?.email ?? ''
    },
    {
      icon: 'ri-phone-fill',
      label: 'Phone',
      value: candidate?.phone ?? ''
    },
    {
      icon: 'ri-map-pin-2-fill',
      label: 'Address',
      value: candidate?.address ?? ''
    },
    {
      icon: { src: websiteIcon },
      label: 'Website',
      value: getWebsiteAlias(candidate?.websiteUrl),
      socialLink: candidate?.websiteUrl
    },
    {
      icon: { src: linkedinIcon },
      label: 'Linkedin',
      value: getLinkedinAlias(candidate?.linkedInUrl),
      socialLink: candidate?.linkedInUrl
    },
    {
      icon: { src: instagramIcon },
      label: 'Instagram',
      value: getFacebookInstagramAlias(candidate?.instagramUrl),
      socialLink: candidate?.instagramUrl
    },
    {
      icon: { src: facebookIcon },
      label: 'Facebook',
      value: getFacebookInstagramAlias(candidate?.facebookUrl),
      socialLink: candidate?.facebookUrl
    }
  ];

  const profileData = [
    {
      icon: 'ri-profile-fill',
      label: 'Profile',
      value: 'View Profile Link',
      href: `/candidate/${candidate?.candidateId}/profile`
    }
  ];

  const resumeData = [
    {
      icon: 'ri-file-text-fill',
      label: 'Resume',
      value: 'View Résumé Here',
      href: `${baseUrl}/files/${candidate?.resume?.fullName}`
    }
  ];

  return (
    <Styled.Root
      data-testid="candidate-info-root"
      breakpointReached={customBreakpointReached}
      shouldDisplay={shouldDisplay}
    >
      {candidate && (
        <>
          <Styled.TitleBox breakpointReached={customBreakpointReached}>
            <Styled.Name data-testid="candidate-name" variant="EC_TYPE_3XL">
              {candidate.firstName} {candidate.lastName}
            </Styled.Name>
          </Styled.TitleBox>
          <Styled.ContentBox breakpointReached={customBreakpointReached}>
            <JobCandidateInfoSection
              sectionTitle="contact information"
              displayData={contactData}
            />
            <JobCandidateInfoSection
              sectionTitle="application"
              displayData={profileData}
            >
              <Styled.ExternalLink
                href={profileData[0].href}
                target="_blank"
                rel="noopener noreferrer"
                underline="none"
                className="ri-external-link-fill"
              />
            </JobCandidateInfoSection>
            {candidate?.resume && (
              <JobCandidateInfoSection displayData={resumeData}>
                <Styled.ExternalLink
                  href={resumeData[0].href}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="none"
                  className="ri-external-link-fill"
                />
              </JobCandidateInfoSection>
            )}
            {vettedQA && <VettedQuestionAnswerSection {...vettedQA} />}
          </Styled.ContentBox>
        </>
      )}
    </Styled.Root>
  );
};

export default JobCandidateInfo;
