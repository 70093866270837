import * as React from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import KitchenBasicsProductImg from '@assets/LearningCenter/Products/glrc-product.kitchen-basics.png';
import KitchenBasicsProductIncludedImg from '@assets/LearningCenter/Products/glrc-product.kitchen-basics-included.png';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';

const Styled = {
  TitleSection: styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '28px',
    flexWrap: 'wrap',
    marginBottom: '32px',
    marginTop: '16px'
  }),
  Image: styled('img')({
    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'contain',
    objectPosition: 'center'
  }),
  MainSection: styled(Box)({
    padding: '90px',
    ['@media (max-width: 1100px)']: {
      padding: 'unset',
      paddingTop: '90px'
    }
  }),
  IncludedContainer: styled(Box)({
    marginTop: '90px',
    display: 'flex',
    columnGap: '32px',
    rowGap: '32px',
    ['@media (max-width: 900px)']: {
      flexDirection: 'column'
    }
  }),
  Ul: styled('ul')({
    paddingLeft: '16px'
  })
};

const catalogPageData = {
  title: 'Kitchen Basics',
  image: KitchenBasicsProductImg,
  subtitle: 'Learn the fundamentals',
  description:
    'The Culinary Arts & Operations diploma program prepares students for careers in the hospitality industry through a 54-week course of 6- and 12-week classes, which includes a 6-week externship. The curriculum covers both cooking techniques and professional skills, such as menu planning, safety, and food cost control. The program is delivered online with both synchronous and asynchronous lessons, and emphasizes hands-on experience through an externship in a foodservice establishment.',
  includedDesc:
    'Whether you want to hone your skills, start your own business or move up at your employer, Escoffier can help you get there faster with the flexibility of online or on-campus learning.',
  included: [
    'Classes from different disciplines to help you gain skills for your culinary career',
    'Learn online from anywhere and at your pace',
    '1:1 support from Escoffier',
    'Tuition assistance from Escoffier and our employer partners'
  ],
  includedImage: KitchenBasicsProductIncludedImg
};

const GlrcCatalogPage: React.FC = () => {
  const {
    title,
    image,
    subtitle,
    description,
    includedDesc,
    included,
    includedImage
  } = catalogPageData;

  return (
    <RootPageLayout muiMaxWidth={false}>
      <Link
        component={RouterLink}
        to="/contact-us"
        variant="EC_TYPE_4XL"
        underline="none"
        sx={{ color: 'GRAY_4.main' }}
      >
        Back
      </Link>
      <Styled.TitleSection>
        <Typography variant="EC_TYPE_5XL" sx={{ textTransform: 'uppercase' }}>
          {title}
        </Typography>
        <Button
          variant="contained"
          endIcon={<i className="ri-arrow-right-line" />}
        >
          Add to cart
        </Button>
      </Styled.TitleSection>
      <Box>
        <Styled.Image src={image} />
      </Box>
      <Styled.MainSection>
        <Box>
          <Typography variant="EC_TYPE_4XL" sx={{ marginBottom: '30px' }}>
            {subtitle}
          </Typography>
          <Typography variant="EC_TYPE_BASE">{description}</Typography>
        </Box>
        <Styled.IncludedContainer>
          <Box sx={{ flex: 2 }}>
            <Typography variant="EC_TYPE_4XL" sx={{ marginBottom: '30px' }}>
              What&apos;s Included
            </Typography>
            <Typography variant="EC_TYPE_BASE">{includedDesc}</Typography>
            <Styled.Ul>
              {included.map((i) => (
                <li key={i}>{i}</li>
              ))}
            </Styled.Ul>
          </Box>
          <Box sx={{ flex: 3 }}>
            <Styled.Image src={includedImage} />
          </Box>
        </Styled.IncludedContainer>
      </Styled.MainSection>
    </RootPageLayout>
  );
};

export default GlrcCatalogPage;
