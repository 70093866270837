export enum UseStoreKeys {
  BANNERS = 'banners',
  CANDIDATE_ENABLED = 'candidateEnabled',
  CANDIDATE_ID = 'candidateId',
  CANDIDATE_STUDENT_ID = 'studentId',
  CANDIDATE_EXTERNSHIP_PILOT = 'candidateExternshipPilotEnabled',
  IS_EC_STUDENT = 'isCandidateEcStudent',
  COMPANY_ENABLED = 'isEnabledCompany',
  MANAGED_COMPANIES = 'managedCompanies',
  COMPANY_LOGO = 'companyLogo',
  EMPLOYER_ID = 'employerId',
  IS_PARENT_COMPANY = 'isParentCompany',
  PARTNER_EMPLOYEE_ID = 'partnerEmployeeId',
  PERMISSIONS = 'permissions',
  ALL_KEYCLOAK_ROLES = 'allKeycloakRoles',
  TOKEN_EXPIRED = 'tokenExpired',
  USER_IDP_ARRAY = 'userIdpArray',
  STUDENT_ENROLLMENT_STORE = 'STUDENT_ENROLLMENT_STORE'
}
