import * as React from 'react';
import {
  Box,
  Button,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import {
  handleMoveDown,
  handleMoveUp
} from '@common/helpers/handleMoveUpOrDown';
import { AdminContentManagementApi } from '@api/AdminContentManagement.api ';
import { HotJob } from '@api/models/adminContentManagementApi.models';
import HotJobItem from '@pages/EcAdmin/ContentManagementPage/HotJobsContent/HotJobItem';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import { StatusCodes } from 'http-status-codes';
import StepSection from '@components/StepSection';
import { styled } from '@mui/system';
import { useFetchAdminHotJobs } from '@common/fetches/useFetchAdminHotJobs';

const MAX_HOT_JOBS = 12;

const Styled = {
  FormWrapper: styled(Box)({
    display: 'flex',
    columnGap: '24px'
  }),
  InputWrapper: styled(Box)({
    flex: 1
  }),
  ButtonWrapper: styled(Box)({
    paddingTop: '30px',
    ['@media (max-width:480px)']: {
      paddingTop: '24px'
    }
  }),
  HotJobsWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'removeBorder'
  })<{ removeBorder: boolean }>(({ removeBorder, theme }) => ({
    padding: removeBorder ? 'unset' : '36px 28px',
    border: removeBorder ? 'unset' : `1px solid ${theme.palette.OUTLINE.main}`,
    borderRadius: removeBorder ? 'unset' : '5px'
  }))
};

interface FormValues {
  jobId: string;
}

const HotJobsContent: React.FC = () => {
  const { hotJobs, setHotJobs, fetchHotJobs, loading } = useFetchAdminHotJobs();

  const movedJob = React.useRef<HotJob | null>(null);

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const methods = useForm<FormValues>({
    defaultValues: {
      jobId: ''
    }
  });

  const { handleSubmit, setError, reset } = methods;

  const moveJobUp = React.useCallback(
    async (jobId: number) => {
      const currIdx = hotJobs.findIndex((j) => j.jobPost.id === jobId);
      const move = AdminContentManagementApi.moveHotJobUp;

      await handleMoveUp(jobId, currIdx, hotJobs, setHotJobs, move);
    },
    [handleMoveUp, hotJobs]
  );

  const moveJobDown = React.useCallback(
    async (jobId: number) => {
      const currIdx = hotJobs.findIndex((j) => j.jobPost.id === jobId);
      const move = AdminContentManagementApi.moveHotJobDown;

      await handleMoveDown(jobId, currIdx, hotJobs, setHotJobs, move);
    },
    [handleMoveDown, hotJobs]
  );

  const handleDelete = async (id: number): Promise<void> => {
    try {
      await AdminContentManagementApi.deleteHotJob(id);
      const updatedHotJobs = hotJobs.filter((hj) => hj.jobPost.id !== id);
      setHotJobs(updatedHotJobs);
    } catch (error: any) {
      console.error(`Error for HotJobItem.deleteHotJob(): ${error}`);
    }
  };

  const handleValidForm = async (data: FormValues): Promise<void> => {
    if (hotJobs.length === MAX_HOT_JOBS) {
      setError('jobId', {
        type: 'manual',
        message: `You have reached the max limit of ${MAX_HOT_JOBS} hot jobs`
      });

      return;
    }

    const jobId = data.jobId;
    try {
      await AdminContentManagementApi.addHotJob(parseInt(jobId));
      reset();
      fetchHotJobs();
    } catch (error: any) {
      console.error('Error for HotJobContent.addHotJob()', error);

      const status = error.response.status;
      const errMsg =
        status === StatusCodes.NOT_FOUND
          ? 'Job ID not found'
          : error.response.data.message;

      setError('jobId', { type: 'manual', message: errMsg });
    }
  };

  React.useEffect(() => {
    if (movedJob.current) {
      setTimeout(() => {
        movedJob.current = null;
      }, 500);
    }
  }, [movedJob.current]);

  return (
    <StepSection>
      <Typography variant="EC_TYPE_4XL">Hot Jobs</Typography>
      <Typography variant="EC_TYPE_BASE">
        Promote up to twelve new or urgent positions for partner employers.
        Enter a job ID you want to be displayed in the header region of
        EConnect.
      </Typography>
      <FormProvider {...methods}>
        <Styled.FormWrapper>
          <Styled.InputWrapper>
            <ReactHookFormsInput
              name={'jobId'}
              label="Job ID"
              labelVariant="EC_TYPE_SM"
              placeholder="Enter a job ID"
              rules={{
                required: 'Job ID is required',
                pattern: {
                  value: /^\d+$/,
                  message: 'Job ID must be a number'
                }
              }}
            />
          </Styled.InputWrapper>
          <Styled.ButtonWrapper>
            <Button variant="contained" onClick={handleSubmit(handleValidForm)}>
              Add Hot Job
            </Button>
          </Styled.ButtonWrapper>
        </Styled.FormWrapper>
      </FormProvider>
      {loading ? (
        <Skeleton variant="rectangular" height={300} />
      ) : (
        <>
          {!!hotJobs.length && (
            <Styled.HotJobsWrapper removeBorder={isMobileView}>
              {hotJobs?.map((hotJob, idx) => {
                const jobPost = hotJob.jobPost;
                const hideMoveUp: boolean = idx === 0;
                const hideMoveDown: boolean = idx === hotJobs.length - 1;
                const hasMoved = movedJob.current?.id === hotJob.id;

                return (
                  <HotJobItem
                    key={hotJob.id}
                    job={jobPost}
                    hideMoveDown={hideMoveDown}
                    hideMoveUp={hideMoveUp}
                    hasMoved={hasMoved}
                    handleMoveDown={moveJobDown}
                    handleMoveUp={moveJobUp}
                    handleDelete={handleDelete}
                  />
                );
              })}
            </Styled.HotJobsWrapper>
          )}
        </>
      )}
    </StepSection>
  );
};

export default HotJobsContent;
