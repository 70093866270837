/* eslint-disable quotes */
import * as React from 'react';
import EcQuiz from '@components/Quiz/EcQuiz';
import ScrollToTopOnMount from '@components/ScrollToTopOnMount';
import { useParams } from 'react-router-dom';

const careerQuizIntroProps = {
  cardSubheader:
    "Do you have a passion for food but don't know how to build a fulfilling long-term career?",
  cardText: [
    "We'll walk you through how to determine which career tracks, educational courses, and jobs suit your personal interests and strengths.",
    "You'll answer questions about your personality, and previous culinary experience, and what you look for in an employer to find the best career path for you and your ideal lifestyle."
  ],
  buttonLabel: 'Begin Career Assessment'
};

const CareerQuizPage = (): React.ReactElement => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <ScrollToTopOnMount />
      <EcQuiz quizId={parseInt(id)} quizIntroProps={careerQuizIntroProps} />;
    </>
  );
};

export default CareerQuizPage;
