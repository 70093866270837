import { AppRoles } from '@api/models/userApi.models';

export const getAppRoleLabel = (appRole: AppRoles): string => {
  switch (appRole) {
    case AppRoles.EMPLOYER:
      return 'Admin';
    case AppRoles.RECRUITER:
      return 'Recruiter';
    case AppRoles.ESOURCE_USER:
      return 'ESource';
    default:
      return '';
  }
};
