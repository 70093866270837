import { FieldErrors } from 'react-hook-form';

export interface FormOrder {
  [key: string]: number;
}

/**
 * Converts FieldErrors object with both flat and nested fields into dot notation path strings, eg
 *
 * {
 *  email: {}
 *  supervisors: [{
 *    email: {}
 *  }, {
 *    email: {}
 *  }]
 * }
 *
 * into
 *
 *  [
 *    'email',
 *    'supervisors.0.email',
 *    'supervisors.1.email',
 *  ]
 *
 * @param fieldErrors
 * @returns
 */
const getFormFieldIds = (fieldErrors: FieldErrors): string[] => {
  const ids: string[] = [];

  for (const fieldName in fieldErrors) {
    const fieldError = fieldErrors[fieldName];

    if (Array.isArray(fieldError)) {
      fieldError.forEach((el, i) => {
        const _fieldName = fieldName + '.' + i + '.' + getFormFieldIds(el);
        ids.push(_fieldName);
      });

    } else {
      ids.push(fieldName);
    }
  }

  return ids;
};

/**
 * Returns weight for a form field by it's name, including top level and nested fields:
 * `email` - top level field
 * `supervisor.0.email` - nested field
 *
 * @param fieldName
 * @param formOrder
 * @returns
 */
const getFormFieldWeight = (fieldName: string, formOrder: FormOrder): number => {
  if (formOrder[fieldName]) {
    return formOrder[fieldName];

  } else if (fieldName.includes('.')) {
    return formOrder[fieldName.split('.')[0]] + parseFloat('0.' + fieldName.replace(/\D/g, ''));

  }

  return 0;
};

export const scrollToError = (errors: FieldErrors, formOrder: FormOrder): void => {
  const elements = getFormFieldIds(errors)
    .sort((a, b) => getFormFieldWeight(a, formOrder) - getFormFieldWeight(b, formOrder))
    .map((name) => document.getElementById(name))
    .filter((el) => !!el) as HTMLElement[];

  // Prevent page from freezing when element is not found
  if (!elements[0]) {
    console.error('scrollToError: element does not exist');
    return;
  }

  // Height of header plus some padding
  const yOffset = -85;
  const y =
    elements[0].getBoundingClientRect().top + window.pageYOffset + yOffset;

  window.scrollTo({ top: y, behavior: 'smooth' });
};
