import * as React from 'react';
import {
  Answers,
  OneAnswer,
  Question,
  QuestionWithAnswers,
  QuizInfo,
  QuizPostReq
} from '@api/models/quizApi.models';
import { useHistory, useLocation } from 'react-router-dom';
import { createCustomContext } from '@common/context/customContext';
import { QuizApi } from '@api/Quiz.api';
import QuizController from '@components/Quiz/QuizController';
import { QuizIntroProps } from '@components/Quiz/QuizIntroCard';
import QuizLayout from '@components/Quiz/QuizLayout';
import { useFetchQuizInfo } from '@common/fetches/useFetchQuizInfo';

interface LayoutProps {
  quizId: number;
  quizIntroProps: QuizIntroProps;
}

export type QuizContext = {
  quizInfo: QuizInfo;
  currQuestion: Question;
  currAnswers: Answers[];
  totalQuestionCount: number;
  beginQuiz: () => void;
  nextQuestion: () => void;
  prevQuestion: () => void;
  handleSelectAnswer: (answerCode: string, questionNumber: number) => void;
  handleSubmit: () => Promise<void>;
  selectedAnswers: OneAnswer[];
};

export const [useQuizContext, QuizContextProvider] =
  createCustomContext<QuizContext>();

const EcQuiz: React.FC<LayoutProps> = (props) => {
  const { quizId, quizIntroProps } = props;
  const history = useHistory();
  const { state } = useLocation();
  const { quizInfo } = useFetchQuizInfo(quizId);

  const [activeQuestion, setActiveQuestion] =
    React.useState<QuestionWithAnswers>();
  const [selectedAnswers, setSelectedAnswers] = React.useState<OneAnswer[]>([]);
  const [questionId, setQuestionId] = React.useState<number>(1);
  const [quizStarted, setQuizStarted] = React.useState<boolean>(false);

  const beginQuiz = (): void => {
    setQuizStarted(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSubmit = async (): Promise<void> => {
    if (selectedAnswers.length > 0) {
      try {
        const postReq: QuizPostReq = { answers: selectedAnswers };
        const res = await QuizApi.postResults(quizId, postReq);

        const quizResults = res.data;
        history.push({
          pathname: `/quiz/${quizId}/results`,
          state: { results: quizResults }
        });
      } catch (error: any) {
        console.error('Error for EcQuiz => QuizApi.postResults()', error);
      }
    }
  };

  const nextQuestion = (): void => {
    if (quizInfo) {
      // if not on last question
      if (questionId !== quizInfo.questions) {
        setQuestionId((curr) => curr + 1);
      } else {
        handleSubmit();
      }
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const prevQuestion = (): void => {
    if (questionId > 0) {
      setQuestionId((curr) => curr - 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleSelectAnswer = (
    answerCode: string,
    questionNumber: number
  ): void => {
    const selected = [...selectedAnswers].filter(
      (item) => item.question !== questionNumber
    );
    const updateAnswers = [
      ...selected,
      { answer: answerCode, question: questionNumber }
    ];
    setSelectedAnswers(updateAnswers);
  };

  // fetch question and answer if current questionId is within bounds of quiz
  React.useEffect(() => {
    if (quizInfo && questionId > 0 && questionId <= quizInfo.questions) {
      const fetchQuestion = async (): Promise<void> => {
        try {
          const res = await QuizApi.getQuestionWithAnswers(quizId, questionId);
          setActiveQuestion(res.data);
        } catch (error: any) {
          console.error(
            'Error for EcQuiz => QuizApi.getQuestionWithAnswers()',
            error
          );
        }
      };
      fetchQuestion();
    }
  }, [questionId, quizInfo]);

  // check if the user is retaking the quiz from the results page
  React.useEffect(() => {
    if (state && state.beginQuiz === true) beginQuiz();
  }, [state]);

  const Content = (): JSX.Element | null => {
    if (quizInfo && activeQuestion) {
      // destructuring and renaming properties for better readability
      const { question: currQuestion, answers: currAnswers } = activeQuestion;
      const { questions: totalQuestionCount } = quizInfo;

      return (
        <QuizContextProvider
          value={{
            beginQuiz,
            quizInfo,
            currQuestion,
            currAnswers,
            totalQuestionCount,
            nextQuestion,
            prevQuestion,
            handleSelectAnswer,
            selectedAnswers,
            handleSubmit
          }}
        >
          <QuizController
            quizStarted={quizStarted}
            quizIntroProps={quizIntroProps}
          />
        </QuizContextProvider>
      );
    }
    return null;
  };

  return (
    <QuizLayout description={quizInfo?.description}>
      <Content />
    </QuizLayout>
  );
};

export default EcQuiz;
