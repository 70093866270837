import * as React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import EcToolTip from '@components/EcToolTip';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import ReactHookFormsMultiSelect from '@components/Forms/ReactHookFormsMultiSelect';
import ReactHookFormsRichText from '@components/Forms/ReactHookFormsRichText';
import ReactHookFormsSelect from '@components/Forms/ReactHookFormsSelect';
import ReactHookFormsTagSelection from '@components/Forms/ReactHookFormsTagSelection';
import ReactHookFormsToggle from '@components/Forms/ReactHookFormsToggle';
import RhfLocationSearch from '@components/Forms/RhfLocationSearch';
import StepSection from '@components/StepSection';
import { styled } from '@mui/system';
import { urlPattern } from '@common/regex/url';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchSegmentsCodebooks } from '@common/fetches/useFetchSegmentsCodebooks';
import { useFetchSpecialtiesCodebooks } from '@common/fetches/useFetchSpecialtiesCodebooks';

const Styled = {
  Section: styled(Box)({
    marginBottom: '80px'
  }),
  SubSection: styled(Box)({
    marginBottom: '60px'
  }),
  SectionTitle: styled(Typography)({
    marginBottom: '24px'
  }),
  TagSection: styled(Box)({
    '& > *': {
      marginTop: '49px'
    }
  }),
  CheckboxContainer: styled(Stack)({
    alignItems: 'center',
    transform: 'translateX(-9px)'
  }),
  Grid: styled(Grid)({
    ['@media (max-width:1200px)']: {
      marginBottom: '-24px'
    }
  })
};

interface Props {
  defaultJobDescription?: string;
  workLearnPartner?: boolean;
}

const MAX_CHAR_LIMIT = 32;

const jobTypeOptions = [
  { label: 'Full Time', value: 'Full Time' },
  { label: 'Part Time', value: 'Part Time' },
  { label: 'Seasonal', value: 'Seasonal' },
  { label: 'On Call', value: 'On Call' }
];

const workLearnOptions = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' }
];

const compensationOptions = ['Hourly', 'Salary'];

const JobDetailsStep: React.FC<Props> = ({
  defaultJobDescription,
  workLearnPartner
}) => {
  const { segments } = useFetchSegmentsCodebooks();
  const { specialties } = useFetchSpecialtiesCodebooks();

  const featureFlags = useFeatureFlags();

  return (
    <Styled.Section data-testid="employer-job-details-section">
      <StepSection title="Job Details">
        <Styled.SectionTitle variant="EC_TYPE_LG">
          What job are you hiring for?
        </Styled.SectionTitle>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <ReactHookFormsInput
              name="jobTitle"
              label="Job Title"
              rules={{
                required: 'Job title is required',
                maxLength: {
                  value: MAX_CHAR_LIMIT,
                  message: `${MAX_CHAR_LIMIT} Characters Maximum`
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReactHookFormsMultiSelect
              name="jobType"
              label="Job Type"
              options={jobTypeOptions}
              rules={{ required: 'Job type is required' }}
            />
          </Grid>
          {workLearnPartner && (
            <Grid item xs={12} sm={6}>
              <ReactHookFormsSelect
                name="workLearnEligible"
                label="Work & Learn Job"
                options={workLearnOptions}
                rules={{ required: 'Select eligibility' }}
                tooltip={
                  <EcToolTip message="You are registered in Escoffier's Work and Learn tuition reduction program. Please select yes if this job is a Work & Learn opportunity" />
                }
              />
            </Grid>
          )}
          <Grid item sm={12} xs={12}>
            <RhfLocationSearch
              name="locations"
              label="Locations"
              rules={{ required: 'At least one location is required' }}
            />
          </Grid>
          <Styled.Grid item xs={12} lg={3.35}>
            <ReactHookFormsToggle
              name="compensationType"
              label="Compensation"
              exclusive={false}
              options={compensationOptions}
            />
          </Styled.Grid>
          <Grid item xs={12} lg={8.65}>
            <ReactHookFormsInput
              name="compensationDetails"
              icon={<i className="ri-money-dollar-box-line" />}
              placeholder="Add additional details"
              tooltip={
                <EcToolTip message="Use this field to include details like a specific pay rate, if based on experience, plus tips, or sign-on bonuses." />
              }
            />
          </Grid>
        </Grid>
        <Styled.TagSection>
          <ReactHookFormsTagSelection
            name="jobSegments"
            // filter!=99 is TEMP solution to remove "Unsure" value for companies
            // If we decide to delete "Unsure" from db, filter can be removed
            // If we decide to keep it, we should add flag in codebook table
            tags={segments.filter((segment) => segment.value !== 99)}
            title="Segment"
            subtitle="(Select one or more)"
            rules={{ required: 'At least one segment is required' }}
          />
          <ReactHookFormsTagSelection
            name="jobSpecialties"
            // filter!=99 is TEMP solution to remove "Unsure" value for companies
            // If we decide to delete "Unsure" from db, filter can be removed
            // If we decide to keep it, we should add flag in codebook table
            tags={specialties.filter((spec) => spec.value !== 99)}
            title="Specialty"
            subtitle="(Select one or more)"
            rules={{ required: 'At least one specialty is required' }}
          />
        </Styled.TagSection>
        <Grid container spacing={3} sx={{ marginTop: '40px' }}>
          <Grid item xs={12} sx={{ marginBottom: '20px' }}>
            <ReactHookFormsInput
              name="jobCardDescription"
              label="Job Card Description"
              rules={{ required: 'Job Card Description is required' }}
              maxCharCount={150}
              subLabel="This brief description appears on EConnect to entice candidates to read the full job post."
            />
          </Grid>
          <Grid item xs={12}>
            <ReactHookFormsRichText
              defaultValue={defaultJobDescription}
              name="jobDescription"
              label="Job Description"
              rules={{ required: 'Job Description is required' }}
            />
          </Grid>
          {featureFlags.ATS_WORKAROUND && (
            <Grid item xs={12} mt="24px">
              <ReactHookFormsInput
                name="externalJobPost"
                label="External job post (optional)"
                subLabel="Candidates will be prompted to continue the application on this page of your own site."
                rules={{
                  pattern: {
                    value: urlPattern,
                    message: 'Please enter a valid URL'
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
      </StepSection>
    </Styled.Section>
  );
};

export default JobDetailsStep;
