import * as React from 'react';
import AutocompleteSearch, {
  AutocompleteSearchProps
} from '@components/AutocompleteSearch';
import {
  Box,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import CompanyCardWithActions, {
  Action
} from '@pages/EcAdmin/ContentManagementPage/EmployerSpotlightContent/CompanyCardWithActions';
import IconWithLabel from '@components/IconWithLabel';
import { PremierPartner } from '@api/models/adminContentManagementApi.models';
import StepSection from '@components/StepSection';
import { styled } from '@mui/system';
import { WorkLearnPartnerEntity } from '@interfaces/workLearnPartner.interfaces';

const Styled = {
  CompanyDetailsWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isMobileView'
  })<{ isMobileView: boolean }>(({ isMobileView }) => ({
    display: 'flex',
    columnGap: '16px',
    rowGap: '8px',
    flexDirection: isMobileView ? 'column' : 'row'
  }))
};

type PartnerType = PremierPartner | WorkLearnPartnerEntity;

type CompanyDetails = { icon: string; label: string };

interface Props<T extends PartnerType> {
  pageTitle: string;
  pageDescription: string;
  searchProps: Omit<AutocompleteSearchProps, 'isMobileView'>;
  loading: boolean;
  getCardActions: (partner: T, idx?: number) => Action[];
  getCompanyDetails: (partner: T) => CompanyDetails[];
  partners: T[];
  useExpandableActionsMenu?: boolean;
}

const PartnerSearchAndList = <T extends PartnerType>({
  pageTitle,
  pageDescription,
  searchProps,
  loading,
  getCardActions,
  getCompanyDetails,
  partners,
  useExpandableActionsMenu = false
}: Props<T>): React.ReactElement => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StepSection>
      <Typography variant="EC_TYPE_4XL">{pageTitle}</Typography>
      <Typography variant="EC_TYPE_BASE">{pageDescription}</Typography>
      <AutocompleteSearch {...searchProps} isMobileView={isMobileView} />
      {loading ? (
        <Skeleton variant="rectangular" height={300} />
      ) : (
        <Box>
          {!!partners.length &&
            partners.map((partner, idx) => {
              const companyDetails = getCompanyDetails(partner);

              return (
                <CompanyCardWithActions
                  key={`${partner.companyId}-${idx}`}
                  id={partner.companyId}
                  name={partner.companyName}
                  image={partner.logoUrl}
                  useExpandableActionsMenu={useExpandableActionsMenu}
                  actions={getCardActions(partner, idx)}
                  content={
                    <Styled.CompanyDetailsWrapper isMobileView={isMobileView}>
                      {companyDetails.map((detail) => (
                        <IconWithLabel
                          smallest
                          key={`${detail.label}-${idx}`}
                          title={detail.label}
                          icon={detail.icon}
                          color={theme.palette.GRAY_3.main}
                        />
                      ))}
                    </Styled.CompanyDetailsWrapper>
                  }
                />
              );
            })}
        </Box>
      )}
    </StepSection>
  );
};

export default PartnerSearchAndList;
