import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';

const Styled = {
  Caret: styled(ExpandMoreIcon, {
    shouldForwardProp: (prop) => prop !== 'expanded'
  })<{ expanded: boolean }>(({ expanded }) => ({
    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.2s linear',
    ':hover': {
      cursor: 'pointer'
    }
  }))
};

interface Props {
  expanded: boolean;
  onClick: () => void;
}

const RotatingCaret: React.FC<Props> = ({ expanded, onClick }) => {
  return <Styled.Caret expanded={expanded} onClick={onClick} />;
};

export default RotatingCaret;
