import * as React from 'react';
import { Box, Typography } from '@mui/material';
import {
  ExternshipApprover,
  ExternshipEntity
} from '@api/models/externshipApi.models';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.GRAY_5.main}`,
    padding: '8px',
    '& .detail-title': {
      marginBottom: '8px'
    }
  })),
  Row: styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'start'
  }),
  DetailBox: styled(Box)({
    display: 'flex',
    columnGap: '48px'
  }),
  SupervisorBox: styled(Box)({
    display: 'flex',
    columnGap: '12px',
    flexWrap: 'wrap',
    '& .supervisor-info:not(:last-of-type)': {
      marginBottom: '8px'
    }
  })
};

interface Props {
  externship: Omit<ExternshipEntity, 'course'>;
  approvers: ExternshipApprover[];
}

interface ContactProps {
  name: string;
  phone: string;
  email: string;
}

const ContactInfo = ({ name, phone, email }: ContactProps): JSX.Element => {
  return (
    <>
      <Typography variant="EC_TYPE_3XS">{name}</Typography>
      <Typography variant="EC_TYPE_3XS">{phone}</Typography>
      <Typography variant="EC_TYPE_3XS">{email}</Typography>
    </>
  );
};

const CourseExternshipDetails: React.FC<Props> = ({ approvers }) => {
  const manager = approvers.find((a) => a.jobCategory === 'Manager');
  const supervisors = approvers.filter((a) => a.jobCategory === 'Supervisor');

  return (
    <Styled.Root>
      <Typography variant="EC_TYPE_2XS" className="detail-title">
        APPROVER(S):
      </Typography>
      <Styled.Row>
        <Styled.DetailBox>
          {!!manager && (
            <Box>
              <Typography variant="EC_TYPE_3XS" fontWeight={700} mb="4px">
                Manager Contact
              </Typography>
              <ContactInfo
                name={`${manager.firstName} ${manager.lastName}`}
                phone={'phone' in manager ? manager.phone : manager.phoneNumber}
                email={manager.email}
              />
            </Box>
          )}
          {!!supervisors?.length && (
            <Box>
              <Typography variant="EC_TYPE_3XS" fontWeight={700} mb="4px">
                Supervisor Contact(s)
              </Typography>
              <Styled.SupervisorBox>
                {supervisors.map((supervisor) => (
                  <Box key={supervisor.email} className="supervisor-info">
                    <ContactInfo
                      name={`${supervisor.firstName} ${supervisor.lastName}`}
                      phone={
                        'phone' in supervisor
                          ? supervisor.phone
                          : supervisor.phoneNumber
                      }
                      email={supervisor.email}
                    />
                  </Box>
                ))}
              </Styled.SupervisorBox>
            </Box>
          )}
        </Styled.DetailBox>
      </Styled.Row>
    </Styled.Root>
  );
};
export default CourseExternshipDetails;
