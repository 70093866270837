import * as React from 'react';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import {
  Box,
  Checkbox,
  Grid,
  InputAdornment,
  Popper,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import EcChip from '@components/EcChip';
import FormFieldErrorText from './FormFieldErrorText';
import { styled } from '@mui/system';

const Styled = {
  Label: styled(Typography)({
    marginBottom: '24px'
  }),
  Title: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'required'
  })<{ required?: boolean }>(({ required, theme }) => ({
    marginBottom: '14px',
    '&:after': {
      content: required ? '"*"' : '""',
      color: theme.palette.error.main,
      marginLeft: '4px'
    }
  })),
  Subtitle: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'error'
  })<{ error: boolean }>(({ error, theme }) => ({
    color: error ? theme.palette.error.main : theme.palette.GRAY_3.main,
    marginBottom: '22px'
  })),
  Option: styled('li')({
    display: 'flex',
    justifyContent: 'space-between'
  }),
  MaxSelectableText: styled(Typography)(({ theme }) => ({
    color: theme.palette.GRAY_3.main,
    marginTop: '5px'
  })),
  Popper: styled(Popper)(({ theme }) => ({
    [`& .${autocompleteClasses.listbox}`]: {
      [`& .${autocompleteClasses.option}`]: {
        '&[aria-selected="true"]': {
          backgroundColor: theme.palette.GRAY_1.main,
          '&:hover': {
            backgroundColor: theme.palette.GRAY_1.main
          }
        },
        '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
          backgroundColor: theme.palette.GOLD_1.main
        }
      }
    }
  }))
};

const uncheckedIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface AutoCompleteOption {
  label: string;
  value: any;
  secondLabel?: string;
  disable?: boolean;
}

interface Props {
  name: string;
  options: AutoCompleteOption[] | string[];
  title?: string;
  subtitle?: string;
  label?: string;
  required?: boolean;
  rules?: RegisterOptions;
  multipleSelect?: boolean;
  withoutCheckbox?: boolean;
  maxSelectable?: number;
  inputPlaceholder?: string;
  hasGreyChip?: boolean;
  titleStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
}

interface PopperProps {
  anchorEl?: any; // per MUI docs
  disablePortal?: boolean;
  open: boolean;
}

const PopperComponent = (props: PopperProps): JSX.Element => {
  return <Styled.Popper {...props} />;
};

const ReactHookFormsAutoComplete: React.FC<Props> = (props) => {
  const {
    name,
    label,
    options = [],
    title,
    titleStyle,
    subtitle,
    required,
    rules,
    multipleSelect = false,
    maxSelectable = options.length,
    inputPlaceholder,
    hasGreyChip,
    withoutCheckbox,
    inputStyle
  } = props;

  const theme = useTheme();

  const isStrArr = typeof options[0] === 'string';
  const {
    control,
    getValues,
    setValue,
    formState: { errors }
  } = useFormContext();

  React.useEffect(() => {
    // remove a selection if it is no longer an option
    const currSelections = getValues()[name] || [];

    const newValue = (currSelections as any).filter((selection) => {
      return typeof options[0] === 'string'
        ? options.includes(selection)
        : options.map((opt) => opt.value).includes(selection.value);
    });

    setValue(name, newValue);
  }, [options.length]);

  const handleChange = (
    selected: any[],
    newValue: AutoCompleteOption[],
    onChange: (...event: any[]) => void
  ): void => {
    const newSelected = newValue;

    if (newSelected.length > maxSelectable) {
      newSelected.splice(-1);
    }

    onChange(newSelected);
  };

  const handleDelete = (
    tags: AutoCompleteOption[] | string[],
    tag: AutoCompleteOption | string,
    onChange: (...event: any[]) => void
  ): void => {
    // cast to any to resolve typescript error with filter
    const output: AutoCompleteOption[] | string[] = (tags as any).filter(
      (_tag) => _tag != tag
    );
    onChange(output);
  };

  const displayGoldChips = hasGreyChip ? false : Boolean(multipleSelect);

  const selectedChips = (
    tags: AutoCompleteOption[] | string[],
    onChange: (tagList: AutoCompleteOption[] | string[]) => void,
    handleDelete: (
      tags: AutoCompleteOption[] | string[],
      tag: AutoCompleteOption | string,
      onChange: (...event: any[]) => void
    ) => void,
    goldColor: boolean
  ): JSX.Element => {
    const chipColor = !goldColor
      ? theme.palette.GOLD_1.main
      : theme.palette.GRAY_3.main;

    return (
      <Grid container spacing={1} mt={0.5} data-testid="rhfAutoComplete-chips">
        {tags.map((tag, idx) => {
          const label = isStrArr ? tag : tag.label;

          return (
            <Grid item key={idx}>
              <EcChip
                data-testid={`${label}-chip`}
                label={label}
                handleDelete={(): void => handleDelete(tags, tag, onChange)}
                chipColor={chipColor}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const renderOption = (props, option, selected): React.ReactNode => {
    const label = isStrArr ? option : option.label;

    if (!multipleSelect) {
      return (
        <Styled.Option {...props} data-testid={`standard-list-item-${label}`}>
          <Typography>{label}</Typography>
          {option.secondLabel && <Typography>{option.secondLabel}</Typography>}
        </Styled.Option>
      );
    } else {
      return (
        <Styled.Option {...props} data-testid={`checkbox-list-item-${label}`}>
          {!withoutCheckbox && (
            <Checkbox
              icon={uncheckedIcon}
              checkedIcon={checkedIcon}
              style={{ marginRight: '8px' }}
              checked={selected}
            />
          )}
          <Typography
            sx={{
              typography: withoutCheckbox ? 'EC_TYPE_2XS' : 'EC_TYPE_BASE',
              color: theme.palette.GRAY_3.main
            }}
          >
            {label}
          </Typography>
          {option.secondLabel && (
            <Typography
              sx={{
                typography: withoutCheckbox ? 'EC_TYPE_2XS' : 'EC_TYPE_BASE',
                color: theme.palette.GRAY_3.main
              }}
            >
              &nbsp;- {option.secondLabel}
            </Typography>
          )}
        </Styled.Option>
      );
    }
  };

  return (
    <Box id={name} position="relative">
      {label && <Styled.Label variant="EC_TYPE_LG">{label}</Styled.Label>}
      {title && (
        <Styled.Title
          variant="EC_TYPE_BASE"
          required={required}
          data-testid={'rhfAutoComplete-input-label'}
          sx={{ ...titleStyle }}
        >
          {title}
        </Styled.Title>
      )}
      {subtitle && (
        <Styled.Subtitle
          data-testid={
            errors[name] ? `${name}-subtitle-error` : `${name}-subtitle`
          }
          error={!!errors[name]}
        >
          {subtitle}
        </Styled.Subtitle>
      )}
      <Controller
        control={control}
        name={name}
        rules={{ ...rules }}
        render={({ field: { value, onChange } }): JSX.Element => {
          return (
            <>
              <Autocomplete
                id="tags-outlined"
                autoComplete
                multiple
                disableClearable
                value={value ?? []}
                options={options}
                defaultValue={[]}
                disableCloseOnSelect={multipleSelect === true}
                filterSelectedOptions={!multipleSelect}
                getOptionDisabled={(option): boolean => !!option.disable}
                isOptionEqualToValue={(option, value): boolean => {
                  return typeof option === 'string'
                    ? option === value
                    : option.value === value.value;
                }}
                onChange={(event, newValue): void =>
                  handleChange(value, newValue, onChange)
                }
                PopperComponent={PopperComponent}
                renderInput={(params): React.ReactNode => (
                  <TextField
                    {...params}
                    placeholder={inputPlaceholder || ''}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <i className="ri-add-circle-fill" />
                        </InputAdornment>
                      )
                    }}
                  />
                )}
                renderOption={(props, option, { selected }): React.ReactNode =>
                  renderOption(props, option, selected)
                }
                sx={{ ...inputStyle }}
              />
              {value?.length === maxSelectable && (
                <Styled.MaxSelectableText
                  variant="EC_TYPE_2XS"
                  data-testid={`${name}-maxSelectable-message`}
                >
                  You may only select {maxSelectable}. Please delete a selection
                  to select another option.
                </Styled.MaxSelectableText>
              )}
              {errors[name] && (
                <FormFieldErrorText
                  name={name}
                  message={errors[name].message}
                />
              )}
              {value?.length
                ? selectedChips(value, onChange, handleDelete, displayGoldChips)
                : null}
            </>
          );
        }}
      />
    </Box>
  );
};

export default ReactHookFormsAutoComplete;
