import {
  AssignUserToESourceGroupDto,
  BulkAssignUserToGroupDto,
  CreateESourceGroupDto,
  ESourceGroupWithAccessDto,
  GlrcContent,
  GlrcTopic
} from '@interfaces/glrc.interfaces';
import { api } from '@api/configs/base';
import { apiConfig } from '@api/configs/api.config';
import { ApiResponse } from '@api/models/base.models';
import { EmployerProfile } from './models/employerApi.models';

export class GlrcContentApi {
  static async getAll(): Promise<GlrcContent[]> {
    try {
      const res = await api.get<ApiResponse<GlrcContent[]>>(
        `${apiConfig.partnersUrl}/glrc-content`
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for GlrcContentApi.getAll(): ${error}`);
      throw error;
    }
  }

  static async getPrepared(companyId: number): Promise<GlrcTopic[]> {
    try {
      const res = await api.get<ApiResponse<GlrcTopic[]>>(
        `${apiConfig.partnersUrl}/glrc-content-prepared/company/${companyId}`
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for GlrcContentApi.getPrepared(): ${error}`);
      throw error;
    }
  }

  static async getByTopicId(topicId: number): Promise<GlrcTopic[]> {
    try {
      const res = await api.get<ApiResponse<GlrcTopic[]>>(
        `${apiConfig.partnersUrl}/glrc-content/topic/${topicId}`
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for GlrcContentApi.getByTopicId(): ${error}`);
      throw error;
    }
  }

  static async createESourceGroup(
    data: CreateESourceGroupDto
  ): Promise<ESourceGroupWithAccessDto> {
    try {
      const res = await api.post<ApiResponse<ESourceGroupWithAccessDto>>(
        `${apiConfig.partnersUrl}/glrc-group`,
        data
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for GlrcContentApi.createESourceGroup(): ${error}`);
      throw error;
    }
  }

  static async getESourceGroupsByCompany(
    companyId: number
  ): Promise<ESourceGroupWithAccessDto[]> {
    try {
      const res = await api.get<ApiResponse<ESourceGroupWithAccessDto[]>>(
        `${apiConfig.partnersUrl}/glrc-group/get-groups/company/${companyId}`
      );

      return res.data.data;
    } catch (error: any) {
      console.error(
        `Error for GlrcContentApi.getESourceGroupsByCompany(): ${error}`
      );
      throw error;
    }
  }

  static async deleteESourceGroupById(groupId: number): Promise<void> {
    try {
      const res = await api.delete<ApiResponse<void>>(
        `${apiConfig.partnersUrl}/glrc-group/group/${groupId}`
      );

      return res.data.data;
    } catch (error: any) {
      console.error(
        `Error for GlrcContentApi.deleteESourceGroupById(): ${error}`
      );
      throw error;
    }
  }

  static async updateESourceGroup(
    groupId: number,
    data: CreateESourceGroupDto
  ): Promise<ESourceGroupWithAccessDto> {
    try {
      const res = await api.put<ApiResponse<ESourceGroupWithAccessDto>>(
        `${apiConfig.partnersUrl}/glrc-group/group/${groupId}`,
        data
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for GlrcContentApi.updateESourceGroup(): ${error}`);
      throw error;
    }
  }

  static async assignESourceGroupToUser(
    data: AssignUserToESourceGroupDto
  ): Promise<EmployerProfile> {
    try {
      const res = await api.put<ApiResponse<EmployerProfile>>(
        `${apiConfig.partnersUrl}/glrc-group/assign-user`,
        data
      );

      return res.data.data;
    } catch (error: any) {
      console.error(
        `Error for GlrcContentApi.assignESourceGroupToUser(): ${error}`
      );
      throw error;
    }
  }

  static async bulkAssignESourceGroupToEmployers(
    data: BulkAssignUserToGroupDto
  ): Promise<EmployerProfile[]> {
    try {
      const res = await api.put<ApiResponse<EmployerProfile[]>>(
        `${apiConfig.partnersUrl}/glrc-group/assign-user/bulk`,
        data
      );

      return res.data.data;
    } catch (error: any) {
      console.error(
        `Error for GlrcContentApi.bulkAssignESourceGroupToEmployers(): ${error}`
      );
      throw error;
    }
  }
}
