import * as React from 'react';
import { Box, createFilterOptions, TextField, Typography } from '@mui/material';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import FormFieldErrorText from './FormFieldErrorText';
import { styled } from '@mui/system';

const Styled = {
  Label: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'required'
  })<{ required: boolean }>(({ required, theme }) => ({
    marginBottom: '11px',
    position: 'relative',
    '&:after': {
      content: required ? '"*"' : '""',
      color: theme.palette.error.main,
      marginLeft: '4px'
    }
  }))
};

type OptionType = {
  label: string;
  inputValue?: string;
};

interface Props {
  name: string;
  options: OptionType[];
  label: string;
  placeholder?: string;
  rules?: RegisterOptions;
  freeSolo?: boolean;
}

const ReactHookFormsBasicComboBox: React.FC<Props> = ({
  name,
  options,
  label,
  placeholder,
  rules,
  freeSolo = false
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const filter = createFilterOptions<OptionType>();

  return (
    <Box id={name} position="relative">
      <Styled.Label variant="EC_TYPE_BASE" required={!!rules?.required}>
        {label}
      </Styled.Label>
      <Controller
        control={control}
        name={name}
        rules={{ ...rules }}
        render={({ field }): JSX.Element => {
          return (
            <Autocomplete
              {...field}
              selectOnFocus
              clearOnBlur
              value={field.value?.label ?? ''}
              onChange={(e, newVal): void => {
                if (typeof newVal === 'string') {
                  field.onChange({
                    label: newVal
                  });
                } else if (newVal && newVal.inputValue) {
                  field.onChange({ label: newVal.inputValue });
                } else {
                  field.onChange(newVal);
                }
              }}
              filterOptions={(options, params): OptionType[] => {
                const filtered = filter(options, params);

                const { inputValue } = params;

                const isExisting = options.some(
                  (option) => inputValue === option.label
                );

                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    label: `Add "${inputValue}"`
                  });
                }

                return filtered;
              }}
              options={options}
              getOptionLabel={(option): string => {
                if (typeof option === 'string') {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }

                return option.label;
              }}
              renderOption={(props, option): JSX.Element => (
                <li {...props}>{option.label}</li>
              )}
              freeSolo={freeSolo}
              renderInput={(params): React.ReactNode => (
                <TextField
                  {...params}
                  placeholder={placeholder}
                  InputProps={{ ...params.InputProps }}
                />
              )}
            />
          );
        }}
      />
      {!!errors[name] && (
        <FormFieldErrorText name={name} message={errors[name]?.message} />
      )}
    </Box>
  );
};

export default ReactHookFormsBasicComboBox;
