import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '@themes/ui/variables';
import { JobApplicationApi } from '@api/JobApplication.api';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { styled } from '@mui/system';
import { useLocation } from 'react-router-dom';

const Styled = {
  ProgressWrapper: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    minHeight: `calc(100vh - (${FOOTER_HEIGHT}px + ${HEADER_HEIGHT}px))`,
    justifyContent: 'center',
    alignItems: 'center'
  })
};

const ExternalJobRedirect: React.FC = () => {
  const search = useLocation().search;

  const redirectUrl = new URLSearchParams(search).get('redirect');
  const jobApplicationId = new URLSearchParams(search).get('id');

  const updateExternalStatus = async (): Promise<void> => {
    if (!jobApplicationId || !redirectUrl) return;

    try {
      await JobApplicationApi.updateJobApplicationExternalStatus(
        parseInt(jobApplicationId),
        true
      );
    } catch (error) {
      console.error(
        'Error for ExternalJobRedirect -> JobApplicationApi.updateJobApplicationExternalStatus()',
        error
      );
    }

    window.location.href = redirectUrl;
  };

  React.useEffect(() => {
    updateExternalStatus();
  }, [redirectUrl, jobApplicationId]);

  return (
    <RootPageLayout>
      <Styled.ProgressWrapper>
        <CircularProgress size={80} />
      </Styled.ProgressWrapper>
    </RootPageLayout>
  );
};

export default ExternalJobRedirect;
