import * as React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import {
  Stack,
  TextField,
  Typography,
  TypographyProps,
  useTheme
} from '@mui/material';
import FormFieldErrorText from '@components/Forms/FormFieldErrorText';
import InputCharCount from '@components/InputCharCount';
import { styled } from '@mui/system';

const Styled = {
  TextFieldContainer: styled(Stack)({
    position: 'relative'
  }),
  Textfield: styled(TextField)(({ theme }) => ({
    '.MuiOutlinedInput-root': {
      padding: '0px',
      height: '100%',

      fieldset: {
        borderRadius: '4px 4px 0 0',
        borderBottom: `2px solid ${theme.palette.GOLD_1.main}`
      }
    }
  })),
  Label: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'required'
  })<{ required?: boolean }>(({ required, theme }) => ({
    marginBottom: '11px',
    position: 'relative',

    '&:after': {
      content: required ? '"*"' : '""',
      color: theme.palette.error.main,
      marginLeft: '4px'
    }
  }))
};

interface Props {
  name: string;
  label?: string;
  autocomplete?: string;
  rules?: RegisterOptions;
  maxCharCount?: number;
  dataTestId?: string;
  required?: boolean;
  placeholder?: string;
  rows?: number;
  labelVariant?: TypographyProps['variant'];
  subTitle?: string;
}

const ReactHookFormsTextarea: React.FC<Props> = (props) => {
  const [charCount, setCharCount] = React.useState<number>(0);
  const theme = useTheme();
  const {
    label,
    name,
    autocomplete,
    rules,
    maxCharCount,
    dataTestId,
    required,
    placeholder,
    rows,
    labelVariant = 'EC_TYPE_BASE',
    subTitle
  } = props;

  const {
    control,
    formState: { errors }
  } = useFormContext();

  const handleCharCount = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setCharCount(event.target.value.length);
  };

  return (
    <Styled.TextFieldContainer id={name}>
      {label && (
        <Styled.Label
          data-testid={`${name}-input-label`}
          variant={labelVariant}
          required={required || !!rules?.required}
        >
          {label}
        </Styled.Label>
      )}
      {subTitle && (
        <Typography
          variant="EC_TYPE_2XS"
          sx={{
            color: theme.palette.GRAY_3.main,
            marginBottom: '12px',
            marginTop: '-8px'
          }}
        >
          {subTitle}
        </Typography>
      )}
      <Controller
        name={name}
        control={control}
        rules={{ ...rules }}
        render={({ field }): JSX.Element => {
          return (
            <>
              <Styled.Textfield
                {...field}
                variant="outlined"
                error={!!errors[name]}
                autoComplete={autocomplete}
                placeholder={placeholder}
                multiline={true}
                rows={rows}
                inputProps={{
                  'data-testid': dataTestId,
                  maxLength: maxCharCount && maxCharCount
                }}
                onChange={(
                  event: React.ChangeEvent<HTMLTextAreaElement>
                ): void => {
                  maxCharCount && handleCharCount(event);
                  field.onChange(event);
                }}
              />
              {maxCharCount && (
                <InputCharCount
                  charCount={charCount}
                  maxCharCount={maxCharCount}
                />
              )}
            </>
          );
        }}
      />
      {errors[name] && (
        <FormFieldErrorText name={name} message={errors[name].message} />
      )}
    </Styled.TextFieldContainer>
  );
};

export default ReactHookFormsTextarea;
