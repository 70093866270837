import * as React from 'react';
import { Button, Grid } from '@mui/material';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { CompanyApi } from '@api/Company.api';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import { scrollToError } from '@common/helpers/scrollToError';
import StepSection from '@components/StepSection';
import StepSubSection from '@components/StepSubSection';
import { styled } from '@mui/system';
import { CompanyProfile } from '@api/models/companyApi.models';

const Styled = {
  SaveBtn: styled(Button)({
    marginTop: '30px',
    color: 'white'
  })
};

interface CompanyFormValues {
  companyName: string;
}

interface Props {
  company: CompanyProfile;
  openToast: (msg: string) => void;
}

const CompanyNameChangeForm: React.FC<Props> = ({
  company,
  openToast
}) => {
  const [companyName, setCompanyName] = React.useState<string>(company?.name || '');
  const settingsSaved = React.useRef<boolean>(false);
  
  const setSettingsSaved = (val: boolean): void => {
    settingsSaved.current = val;
  };

  const defaultValues: CompanyFormValues = { 
    companyName: companyName || '',
  };

  const methods = useForm<CompanyFormValues>({
    defaultValues,
  });

  const formOrder = {
    companyName: 1,
  };

  const {
    handleSubmit,
    getValues,
    setError,
    reset,
    formState: { isDirty }
  } = methods;

  const onInvalid = (errors: FieldErrors): void => {
    scrollToError(errors, formOrder);
  };

  const onValid = async (data: CompanyFormValues): Promise<void> => {
    try {
      await CompanyApi.updateCompanySettings(company.id, {
        name: data.companyName
      });

      // Reset the form with new values to update the "dirty" state
      reset({
        companyName: data.companyName
      }, {
        keepDirty: false
      });
      
      setCompanyName(data.companyName);
      setSettingsSaved(true);
      openToast('Account Settings Updated Successfully');
    } catch (error: any) {
      console.error(
        'Error for CompanySettings -> SettingsApi.updateUser()',
        error
      );

      const errData = error.response?.data;
      if (errData?.field) {
        setError(errData.field, {
          type: 'manual',
          message: errData.message || 'An error occurred'
        });
        scrollToError(methods.formState.errors, formOrder);
      }
    } finally {
      // Update settings with only the form data we need
      setCompanyName(data.companyName);
      setSettingsSaved(true);
    }
  };

  /* 
      This will reset the state of the form once it is successfully submitted
      Save Changes button will return to disabled state until form isDirty again
    */
  React.useEffect(() => {
    if (settingsSaved.current && !open) {
      const submittedForm = getValues();
      setCompanyName(submittedForm.companyName);
      setSettingsSaved(false);
    }
  }, [settingsSaved.current, open]);

  return (
    <StepSection title="Manage Company Settings">
      <FormProvider {...methods}>
        <StepSubSection title="Company Registration">
          <Grid container>
            <Grid item xs={12} md={6}>
              <ReactHookFormsInput
                dataTestId="company-name-input"
                label="Name"
                name="companyName"
                rules={{
                  required: 'Company name is required'
                }}
              />
            </Grid>
          </Grid>
          <Styled.SaveBtn
            data-testid="save-company-settings-btn"
            variant="contained"
            onClick={handleSubmit(onValid, onInvalid)}
            disabled={!isDirty}
          >
            Save Changes
          </Styled.SaveBtn>
        </StepSubSection>
      </FormProvider>
    </StepSection>
  );
};

export default CompanyNameChangeForm;
