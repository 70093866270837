import * as React from 'react';
import { Box, Theme, Typography } from '@mui/material';
import CtaButton from '@components/CtaButton';
import { JobApplicationApi } from '@api/JobApplication.api';
import { styled } from '@mui/system';

const Styled = {
  ContentBox: styled(Box)({
    padding: '35px',
    backgroundColor: '#fff',
    ['@media (max-width:900px)']: {
      padding: '35px 0'
    }
  }),
  TextWrapper: styled(Box)(({ theme }) => ({
    margin: '35px 0px 70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    '& .job-app-submitted-text': {
      marginBottom: 0,
      fontFamily: (theme as Theme).typography.EC_TYPE_3XL.fontFamily
    }
  }))
};

interface Props {
  externalJobPost?: string;
  jobApplicationId: number;
}

const JobApplicationSubmittedScreen: React.FC<Props> = ({
  externalJobPost,
  jobApplicationId
}) => {
  const handleClick = (): void => {
    JobApplicationApi.updateJobApplicationExternalStatus(
      jobApplicationId,
      true
    );
  };

  return (
    <Styled.ContentBox>
      <Styled.TextWrapper>
        <Typography variant="EC_TYPE_4XL" className="job-app-submitted-text">
          Your application was submitted!
        </Typography>
      </Styled.TextWrapper>
      {externalJobPost && (
        <Box sx={{ mt: '35px' }} data-testid="next-steps-container">
          <Typography variant="EC_TYPE_XL" sx={{ textTransform: 'uppercase' }}>
            Next Steps
          </Typography>
          <Typography sx={{ mt: '12px', mb: '24px' }}>
            The employer needs additional information on their website.
          </Typography>
          <Box onClick={handleClick} data-testid="external-job-cta">
            <CtaButton
              label="Go to site"
              externalLink={externalJobPost}
              textVariant="EC_TYPE_SM"
              endIconClass="ri-external-link-fill"
              iconSize="15px"
              fullWidth
            />
          </Box>
        </Box>
      )}
    </Styled.ContentBox>
  );
};

export default JobApplicationSubmittedScreen;
