/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import {
  GlrcContent,
  GlrcContentTypes,
  GlrcVideo
} from '@interfaces/glrc.interfaces';
import GlrcVideoForm, {
  GlrcVideoFormValues
} from '@pages/EcAdmin/ContentManagementPage/VideoContent/GlrcVideoForm';
import { Toast, ToastProps } from '@components/Toast';
import { AdminContentManagementApi } from '@api/AdminContentManagement.api ';
import { Codebook } from '@api/models/codebook.models';
import { convertGlrcContentToGlrcVideo } from '@pages/Partner/GlrcContent/glrc.helpers';
import CsvButton from '@components/CsvButton';
import EcDialog from '@components/EcDialog';
import { getFilteredSearchResults } from '@common/helpers/getFilteredSearchResults';
import GlrcAdminVideoTable from '@pages/EcAdmin/ContentManagementPage/VideoContent/GlrcAdminVideoTable';
import { SortButton } from '@interfaces/search.interfaces';
import StepSection from '@components/StepSection';
import { styled } from '@mui/system';
import TableWrapperWithSort from '@components/TableWrapperWithSort';
import { useFetchGlrcContentCsv } from '@common/fetches/csv/useFetchGlrcContentCsv';

const sortButtons: SortButton[] = [
  { label: 'Topic', id: 'topicLabel' },
  { label: 'Title', id: 'title' },
  { label: 'Section', id: 'section' }
];

const Styled = {
  Root: styled(Box)({
    display: 'flex',
    flexDirection: 'column'
  }),
  TitleRow: styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }),
  Button: styled(Button)({
    height: '40px',
    marginTop: '16px',
    marginBottom: '32px',
    marginLeft: 'auto'
  })
};

const csvHeaders = [
  { label: 'topic', key: 'topicLabel' },
  { label: 'Title', key: 'title' },
  { label: 'Section', key: 'section' },
  { label: 'Time', key: 'length' }
];

interface Props {
  glrcVideos: GlrcVideo[];
  glrcContentTopics: Codebook[];
  toastProps: ToastProps;
  // Parent values used to maintain state when this component re-renders
  parentSearchVal?: string;
  setParentVideos: (videos: GlrcVideo[]) => void;
  setParentSearchVal: (str?: string) => void;
}

const VideoContent: React.FC<Props> = ({
  glrcVideos,
  glrcContentTopics,
  toastProps,
  parentSearchVal,
  setParentVideos,
  setParentSearchVal
}) => {
  const { fetchGlrcContentCsv } = useFetchGlrcContentCsv();

  const [videos, setVideos] = React.useState<GlrcVideo[]>(glrcVideos);
  const [errorMsg, setErrorMsg] = React.useState<string>();
  const [searchValue, setSearchValue] = React.useState<string | undefined>(
    parentSearchVal
  );
  const [selectedVideo, setSelectedVideo] = React.useState<
    GlrcVideo | undefined // eslint-disable-line @typescript-eslint/indent
  >(undefined);
  const [addModalId, setAddModalId] = React.useState(false);
  const [editModalId, setEditModalId] = React.useState<number | null>(null);
  const [deleteModalId, setDeleteModalId] = React.useState<number | null>(null);

  const { openToast, open, ...restToastProps } = toastProps;

  const searchParams: (keyof GlrcVideo)[] = ['title', 'topicLabel', 'section'];

  const handleOpenAddModal = (): void => {
    setAddModalId(true);
  };

  const handleOpenEditModal = (videoId: number): void => {
    setEditModalId(videoId);
  };

  const handleOpenDeleteModal = (videoId: number): void => {
    setDeleteModalId(videoId);
  };

  const handleCloseModal = (): void => {
    setAddModalId(false);
    !!editModalId && setEditModalId(null);
    !!deleteModalId && setDeleteModalId(null);
    !!selectedVideo && setSelectedVideo(undefined);
    !!errorMsg && setErrorMsg(undefined);
  };

  const handleDelete = async (): Promise<void> => {
    if (deleteModalId) {
      try {
        const updatedVideos = glrcVideos.filter((v) => v.id !== deleteModalId);

        await AdminContentManagementApi.deleteGlrcContent(deleteModalId);

        openToast('Video deleted successfully!');
        setParentVideos(updatedVideos);
      } catch (error: any) {
        console.error(
          'Error for VideoContent -> AdminContentManagementApi.deleteGlrcContent()',
          error
        );
      }
    }
  };

  const handleCreateOrEdit = async (
    data: GlrcVideoFormValues
  ): Promise<void> => {
    const requestBody = {
      contentTypeId: GlrcContentTypes.VIDEO,
      contentTopicId: data.topic,
      section: data.section.label,
      link: data.videoUrl,
      numberOfLessons: null,
      description: null,
      courseId: null,
      courseType: null,
      ...(selectedVideo
        ? {
            title: selectedVideo.title,
            thumbnail: selectedVideo.imgUrl,
            lengthOfVideo: selectedVideo.lengthNumber
          }
        : {
            title: data.title,
            thumbnail: data.thumbnail,
            lengthOfVideo: data.lengthOfVideo
          })
    };

    const toastMsg = selectedVideo ? 'updated' : 'created';

    let videoArray: GlrcVideo[]; // Array that will update UI without needing to re-fetch
    let newContent: GlrcContent;

    // save current searchValue for re-render
    setParentSearchVal(searchValue);

    try {
      if (selectedVideo) {
        newContent = await AdminContentManagementApi.updateGlrcContent(
          selectedVideo.id,
          requestBody
        );

        videoArray = glrcVideos.filter((v) => v.id !== selectedVideo.id);
      } else {
        newContent = await AdminContentManagementApi.createGlrcContent(
          requestBody
        );

        videoArray = [...videos];
      }

      const convertedContent = convertGlrcContentToGlrcVideo(newContent);

      setParentVideos([...videoArray, convertedContent]);
      openToast(`Video ${toastMsg} successfully!`);
    } catch (error: any) {
      console.error(
        'Error for VideoContent -> AdminContentManagementApi',
        error
      );
      if (error.response?.data?.message === 'Video has already been created.') {
        setErrorMsg(error.response.data.message);
      }
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
  };

  const clearSearch = (): void => {
    setSearchValue(undefined);
    setParentSearchVal(undefined);
  };

  const searchProps = {
    onChange: handleSearchChange,
    clearSearch,
    value: searchValue,
    placeholder: 'Search by Topic, Title, or Section'
  };

  React.useEffect(() => {
    if (!!videos.length && (!!editModalId || !!deleteModalId)) {
      const findVideo = videos.find(
        (v) => v.id === editModalId || v.id === deleteModalId
      );

      setSelectedVideo(findVideo);
    }
  }, [editModalId, deleteModalId, videos]);

  React.useEffect(() => {
    if (searchValue === '' || !searchValue) {
      setVideos(glrcVideos); // set this state to parent state
    } else {
      const filteredVideos = getFilteredSearchResults(
        glrcVideos,
        searchParams,
        searchValue
      );

      setVideos(filteredVideos);
    }
  }, [searchValue]);

  return (
    <>
      <StepSection>
        <Styled.Root>
          <Styled.TitleRow>
            <Typography variant="EC_TYPE_4XL">ESource Video Content</Typography>
            <CsvButton
              headers={csvHeaders}
              fileName="GLRC_content.csv"
              buttonText="Export to CSV"
              fetchData={fetchGlrcContentCsv}
            />
          </Styled.TitleRow>
          <Styled.Button
            variant="contained"
            onClick={handleOpenAddModal}
            aria-label="Add Content"
            data-testid="add-content-btn"
          >
            Add Content
          </Styled.Button>
          <TableWrapperWithSort
            sessionKey="EC_ADMIN_VIDEO_CONTENT_MGMT"
            initialSortCategory="topicLabel"
            initialSortOrder="ASC"
            sortButtons={sortButtons}
            searchBarProps={searchProps}
            rowsPerPageOptions={[25, 50, 100]}
            initialRowsPerPage={25}
            totalCount={videos.length}
            displayMobile={false}
          >
            <GlrcAdminVideoTable
              videos={videos}
              handleOpenEditModal={handleOpenEditModal}
              handleOpenDeleteModal={handleOpenDeleteModal}
            />
          </TableWrapperWithSort>
        </Styled.Root>
      </StepSection>
      <Toast open={open} {...restToastProps} />
      <EcDialog
        title="Are you sure you want to delete this video?"
        open={!!deleteModalId}
        handleConfirm={handleDelete}
        handleClose={handleCloseModal}
        denyActionText="Cancel"
        confirmActionText="Delete"
        fullWidth={false}
        dataTestId="admin-glrc-content-delete-modal"
      />
      <GlrcVideoForm
        video={selectedVideo}
        videoList={glrcVideos}
        errorMsg={errorMsg}
        setCustomError={setErrorMsg}
        glrcContentTopics={glrcContentTopics}
        handleCreateOrEdit={handleCreateOrEdit}
        title={editModalId ? 'Edit Content' : addModalId ? 'Add Content' : ''}
        open={!!editModalId || !!addModalId}
        handleClose={handleCloseModal}
        denyActionText="Cancel"
        confirmActionText={editModalId ? 'Save' : addModalId ? 'Add' : ''}
      />
    </>
  );
};

export default VideoContent;
