import { BadgeCodebook } from '@api/models/codebook.models';
import { ReactComponent as DebtFreeTag } from '@assets/badgeIcons/freeTag_red2_grey0.svg';
import { ReactComponent as EcCrown } from '@assets/badgeIcons/crown_grey4.svg';
import { ReactComponent as GradCap } from '@assets/badgeIcons/gradCap_gold1_grey4.svg';
import { useTheme } from '@mui/material';
import { ReactComponent as WhiteChefHat } from '@assets/badgeIcons/chefHat_white.svg';
import { ReactComponent as WorkAndLearnBadge } from '@assets/badgeIcons/workAndLearn.svg';

export interface Badge {
  remixIcon?: string;
  svgIcon?: any;
  label: string;
  bgColor?: string;
  textColor?: string;
  preventFill?: boolean;
}

export interface BadgeMap {
  [key: number]: Badge | undefined;
}

/**
 * Function to replace the default labels with the labels from
 * the DB Codebooks so that badges will always be up-to-date.
 *
 * @param {BadgeCodebook[]} badge list of codebooks
 * @returns `BadgeMap`
 */
export const BadgeMapping = (badge: BadgeCodebook): BadgeMap | {} => {
  const theme = useTheme();
  const map = {
    // Undefined means a badge will not be displayed
    99: undefined,
    // Candidate Badges
    1: {
      remixIcon: 'ri-map-pin-user-fill',
      label: 'Willing to Relocate',
      bgColor: theme.palette.grey4.main
    },
    2: {
      remixIcon: 'ri-briefcase-3-fill',
      label: 'Seeking Career',
      bgColor: theme.palette.primary.main
    },
    3: {
      svgIcon: EcCrown,
      label: 'Escoffier Graduate',
      bgColor: theme.palette.GOLD_1.main
    },
    4: {
      svgIcon: EcCrown,
      label: 'Escoffier Student',
      bgColor: theme.palette.GRAY_1.main,
      textColor: theme.palette.grey4.main
    },
    5: {
      svgIcon: WhiteChefHat,
      label: 'Seeking Externship',
      bgColor: theme.palette.grey1.main,
      textColor: theme.palette.primary.main
    },
    // Employer Badges
    199: undefined,
    101: {
      svgIcon: DebtFreeTag,
      label: 'Debt Free Education',
      bgColor: theme.palette.primary.main,
      preventFill: true
    },
    102: {
      svgIcon: WhiteChefHat,
      label: 'Externship Approved',
      bgColor: theme.palette.grey4.main
    },
    103: {
      svgIcon: WorkAndLearnBadge,
      label: 'Work & Learn Employer',
      bgColor: theme.palette.grey1.main,
      textColor: theme.palette.primary.main,
      preventFill: true
    },
    104: {
      svgIcon: GradCap,
      label: 'Scholarship Available',
      bgColor: theme.palette.GRAY_1.main,
      textColor: theme.palette.grey4.main,
      preventFill: true
    }
  };

  if (map[badge?.value]) {
    map[badge?.value].label = badge?.label;
  }

  return map;
};

export const getBadge = (
  badgeMap: BadgeMap | undefined,
  userBadge: number | undefined
): Badge | undefined => {
  if (!!badgeMap && !!userBadge) {
    return badgeMap?.[userBadge] ?? undefined;
  }
};
