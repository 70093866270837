import { AdminDashboardApi } from '@api/AdminDashboard.api';
import { CompanyUser } from '@api/models/adminDashboardApi.models';

export type FetchCompaniesUsersCsv = {
  fetchCompaniesUsersCsv: () => Promise<CompanyUser[]>;
};

/**
 * Fetch CompanyUser[] from AdminDashboardApi.getCompaniesUsersCsv()
 *
 * @returns `{ fetchCompaniesUsersCsv: ()=> Promise<CompanyUser[]> }`
 */
export const useFetchCompaniesUsersCsv = (): FetchCompaniesUsersCsv => {
  const fetchCompaniesUsersCsv = async (): Promise<CompanyUser[]> => {
    let data: CompanyUser[] = [];
    try {
      data = await AdminDashboardApi.getCompaniesUsersCsv();
    } catch (error: any) {
      console.error(
        'Error for useFetchCompaniesCsv -> AdminDashboardApi.getJobPostsCsvByCompanyId()',
        error
      );
    }

    return data;
  };

  return { fetchCompaniesUsersCsv };
};
