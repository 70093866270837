import * as React from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { CompanyApi } from '@api/Company.api';
import { CompanyEthosPutRequest } from '@api/models/companyApi.models';
import FormActionsComponent from '@components/Forms/FormActionsComponent';
import FormWrapper from '@components/FormWrapper';
import { Grid } from '@mui/material';
import PageDescriptionComponent from '@components/Layout/Common/PageDescriptionComponent';
import PageFormLayoutComponent from '@components/Layout/PageFormLayoutComponent';
import ReactHookFormsTextarea from '@components/Forms/ReactHookFormsTextarea';
import { scrollToError } from '@common/helpers/scrollToError';
import StepSection from '@components/StepSection';
import { useFetchCompanyProfile } from '@common/fetches/useFetchCompanyProfile';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';

interface IMaxLengthRule {
  value: number;
  message: string;
}

interface FormValues {
  overview: string;
  mission?: string;
  vision?: string;
  values?: string;
}

interface Props {
  handleStepper: (next: boolean) => void;
}

const EthosStep: React.FC<Props> = ({ handleStepper }) => {
  const { companyId } = useParamsOrStoreCompanyId();
  const { companyProfile } = useFetchCompanyProfile(companyId);

  const methods = useForm<FormValues>({
    defaultValues: {
      overview: '',
      mission: '',
      vision: '',
      values: ''
    }
  });

  const formOrder = {
    overview: 0,
    mission: 1,
    vision: 2,
    values: 3
  };

  const maxLengthRule: IMaxLengthRule = {
    value: 600,
    message: 'Max length exceeds 600 characters'
  };

  const { handleSubmit, setError, reset } = methods;

  React.useEffect(() => {
    return;
  }, []);

  React.useEffect(() => {
    if (companyProfile) {
      const { companyOverview, companyMission, companyVision, companyValues } =
        companyProfile;
      reset({
        overview: companyOverview || '',
        mission: companyMission || '',
        vision: companyVision || '',
        values: companyValues || ''
      });
    }
  }, [companyProfile]);

  const handleInvalidForm = (errors: FieldErrors): void => {
    scrollToError(errors, formOrder);
  };

  const handleValidForm = async (
    data: FormValues,
    onSuccess: () => void
  ): Promise<void> => {
    const postBody: CompanyEthosPutRequest = {
      companyOverview: data.overview || null,
      companyMission: data.mission || null,
      companyVision: data.vision || null,
      companyValues: data.values || null
    };

    try {
      await CompanyApi.updateCompanyEthos(companyId, postBody);
      onSuccess();
    } catch (error: any) {
      console.error('Error for EthosStep.handleValidForm()', error);

      const errData = error.response.data.data;

      if (errData?.length) {
        errData.forEach(({ field, message }) =>
          setError(field, { type: 'manual', message })
        );
      }

      scrollToError(methods.formState.errors, formOrder);
    }
  };

  const handleFormSubmit = (onSuccess: () => void): void => {
    const onValid = async (data: FormValues): Promise<void> =>
      handleValidForm(data, onSuccess);
    handleSubmit(onValid, handleInvalidForm)();
  };

  return (
    <PageFormLayoutComponent
      sideContent={
        <PageDescriptionComponent title="Your Ethos">
          For better matchmaking, advertise what makes your company unique and a
          great place to work with these dedicated fields.
        </PageDescriptionComponent>
      }
      content={
        <FormWrapper>
          <FormProvider {...methods}>
            <StepSection>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ReactHookFormsTextarea
                    name="overview"
                    label="Company Overview"
                    placeholder="Our history began in..."
                    required
                    rules={{
                      required: 'Company Overview is required',
                      maxLength: maxLengthRule
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ReactHookFormsTextarea
                    name="mission"
                    label="Company Mission"
                    placeholder="We believe in..."
                    rules={{
                      maxLength: maxLengthRule
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ReactHookFormsTextarea
                    name="vision"
                    label="Company Vision"
                    placeholder="We strive to be..."
                    rules={{
                      maxLength: maxLengthRule
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ReactHookFormsTextarea
                    name="values"
                    label="Company Values"
                    placeholder="Our community..."
                    rules={{
                      maxLength: maxLengthRule
                    }}
                  />
                </Grid>
              </Grid>
            </StepSection>
          </FormProvider>
        </FormWrapper>
      }
      actions={
        <FormActionsComponent
          onSubmit={handleFormSubmit}
          submitBtnText="Save and Continue"
          handleStepper={handleStepper}
          changeDetected={methods.formState.isDirty}
        />
      }
    />
  );
};

export default EthosStep;
