import * as React from 'react';
import { Box, Typography } from '@mui/material';
import IconWithLabel from '@components/IconWithLabel';
import { PromotedJob } from '@api/models/singleJobPostApi.models';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useTagManager } from '@common/hooks/useTagManager';

const Styled = {
  PromotedJobWrapper: styled(Box)({
    display: 'flex',
    ':not(:last-child)': { marginBottom: '18px' },
    ':hover': {
      cursor: 'pointer'
    }
  }),
  JobInfoSection: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }),
  CompanyLogoSection: styled(Box)({
    flex: 3,
    border: `1px solid ${theme.palette.GOLD_1.main}`,
    borderRadius: '0px 4px 4px 0px',
    borderLeft: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70px',
    img: {
      maxHeight: '55px'
    }
  })
};

const PromotedJobItem: React.FC<{ job: PromotedJob }> = ({ job }) => {
  const { sendToGtm } = useTagManager();

  const handleClick = (): void => {
    sendToGtm('hot_job_click', {
      job_id: job.id,
      job_title: job.title
    });

    window.open(`/job/${job.id}`, '_blank');
  };

  return (
    <Styled.PromotedJobWrapper>
      <Styled.JobInfoSection onClick={handleClick}>
        <Typography variant="EC_TYPE_SM" color="primary">
          {job.title} - {job.company}
        </Typography>
        {job.location && (
          <IconWithLabel title={job.location} icon="ri-map-pin-2-fill" />
        )}
      </Styled.JobInfoSection>
    </Styled.PromotedJobWrapper>
  );
};

export default PromotedJobItem;
