import * as React from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import AddItemButton from '@components/AddItemButton';
import AddSubsidiaryModal from '@pages/ParentCompany/AddSubsidiaryModal';
import { CompanyApi } from '@api/Company.api';
import CompanyLogo from '@components/CompanyLogo';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchPossibleSubsidiaries } from '@common/fetches/useFetchPossibleSubsidiaries';
import { useFetchSubsidiariesByCompanyId } from '@common/fetches/useFetchSubsidiariesByCompanyId';
import { useKeycloakContext } from '@common/context/keycloakContext';

const Styled = {
  FlexBox: styled(Box)({
    display: 'flex',
    flexFlow: 'row wrap',
    gap: '16px',
    alignItems: 'stretch',
    justifyContent: 'stretch'
  }),
  FlexItem: styled(Box)({
    flex: '0 0 200px',
    maxWidth: '200px'
  }),
  Button: styled(Button)({
    padding: '0 3px',
    height: '16px',
    marginTop: '12px'
  })
};

interface Props {
  companyId: number;
}

const SubsidiariesSection: React.FC<Props> = ({ companyId }) => {
  const featureFlags = useFeatureFlags();
  const { isEcAdmin } = useKeycloakContext();

  const { subsidiaries, fetchSubsidiaries, setSubsidiaries } =
    useFetchSubsidiariesByCompanyId(companyId);
  const {
    possibleSubsidiaries,
    loading,
    setPossibleSubsidiaries,
    fetchPossibleSubsidiaries
  } = useFetchPossibleSubsidiaries(isEcAdmin);
  const [addSubModalOpen, setAddSubModalOpen] = React.useState(false);

  const handleOpenModal = (): void => {
    fetchPossibleSubsidiaries(isEcAdmin).then(() => {
      setAddSubModalOpen(true);
    });
  };

  const handleCloseModal = (): void => {
    setAddSubModalOpen(false);
  };

  const handleSetPossibleSubs = (selectedIds: number[]): void => {
    setPossibleSubsidiaries((prev) => {
      return prev?.filter((sub) => !selectedIds.includes(sub.id));
    });
  };

  const handleRemoveSubsidiary = async (
    subCompanyId: number
  ): Promise<void> => {
    await CompanyApi.removeParentFromSubsidiary(subCompanyId).then(() => {
      setSubsidiaries((prev) => prev?.filter((sub) => sub.id !== subCompanyId));
    });
  };

  return (
    <>
      <Box data-testid="subsidiaries-section" marginTop="54px">
        <Typography variant="EC_TYPE_3XL">My Company Dashboards</Typography>
        <Styled.FlexBox>
          {subsidiaries?.map((sub) => (
            <Styled.FlexItem key={sub.id}>
              <CompanyLogo
                name={sub.name}
                logo={sub.logo?.fullPath}
                maxWidth="200px"
                handleRemoveClick={
                  companyId === sub.id
                    ? undefined
                    : (): Promise<void> => handleRemoveSubsidiary(sub.id)
                }
              />
              <Link
                component={RouterLink}
                to={`/employer/${sub.id}/dashboard`}
                underline="none"
              >
                <Styled.Button
                  size="small"
                  variant="text"
                  data-testid={`subsidiary-link-${sub.id}`}
                >
                  {sub.name} - {sub.id}
                </Styled.Button>
              </Link>
            </Styled.FlexItem>
          ))}
          {featureFlags.PARENT_CHILD_RELATION && isEcAdmin && (
            <AddItemButton
              buttonText="Add a company"
              onClick={handleOpenModal}
            />
          )}
        </Styled.FlexBox>
      </Box>
      {possibleSubsidiaries && (
        <AddSubsidiaryModal
          parentId={companyId}
          open={addSubModalOpen}
          possibleSubsidiaries={possibleSubsidiaries}
          loading={loading}
          fetchSubsidiaries={fetchSubsidiaries}
          handleClose={handleCloseModal}
          setPossibleSubsidiaries={handleSetPossibleSubs}
        />
      )}
    </>
  );
};

export default SubsidiariesSection;
