import * as React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { BadgeCodebook } from '@api/models/codebook.models';
import { BadgeMapping } from '@common/mappings/BadgeMappings';
import defaultEmployerLogo from '@assets/svg/employer-def.svg';
import facebookIcon from '@assets/svg/facebookIcon.svg';
import ImageWithBadge from './ImageWithBadge';
import instagramIcon from '@assets/svg/instagramIcon.svg';
import linkedinIcon from '@assets/svg/linkedinIcon.svg';
import { PreviewPageCompanyDetailsProps } from '@pages/Employer/PreviewPage/employerPreviewPageComponentProps';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useResponsiveLayoutContext } from '@components/Layout/ResponsiveLayout';
import websiteIcon from '@assets/svg/websiteIcon.svg';

const Styled = {
  Root: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'customBreakpointReached'
  })<{ customBreakpointReached: boolean }>(({ customBreakpointReached }) => ({
    display: 'flex',
    flexDirection: customBreakpointReached ? 'column' : 'row',
    marginBottom: '50px'
  })),
  LogoWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'customBreakpointReached'
  })<{ customBreakpointReached: boolean }>(({ customBreakpointReached }) => ({
    marginBottom: customBreakpointReached ? '12px' : 0,
    marginRight: customBreakpointReached ? 0 : '35px',

    img: {
      width: '100%',
      maxWidth: customBreakpointReached ? '340px' : '131px',
      height: customBreakpointReached ? '136px' : '67px',
      objectFit: 'scale-down'
    }
  })),
  Content: styled(Box)({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '67px', // in case theres no logo
    justifyContent: 'space-between',
    '& .heading': {
      marginBottom: 0
    }
  }),
  SocialIcon: styled('img')({
    width: '18px',
    marginRight: '20px',
    color: theme.typography.EC_TYPE_XS.color
  })
};

interface LinkProps {
  websiteUrl: string;
  linkedInUrl: string;
  instagramUrl: string;
  facebookUrl: string;
}

const Links = ({
  websiteUrl,
  linkedInUrl,
  instagramUrl,
  facebookUrl
}: LinkProps): JSX.Element => {
  const linkArray = [
    {
      dataTestId: 'socialLink-website',
      src: websiteIcon,
      alt: 'website-icon',
      href: websiteUrl
    },
    {
      dataTestId: 'socialLink-linkedin',
      src: linkedinIcon,
      alt: 'linkedin-icon',
      href: linkedInUrl
    },
    {
      dataTestId: 'socialLink-instagram',
      src: instagramIcon,
      alt: 'instagram-icon',
      href: instagramUrl
    },
    {
      dataTestId: 'socialLink-facebook',
      src: facebookIcon,
      alt: 'facebook-icon',
      href: facebookUrl
    }
  ];

  return (
    <>
      {linkArray.map(
        (link) =>
          link.href && (
            <a
              key={link.alt}
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Styled.SocialIcon
                data-testid={link.dataTestId}
                src={link.src}
                alt={link.alt}
              />
            </a>
          )
      )}
    </>
  );
};

interface Props extends PreviewPageCompanyDetailsProps {
  companyId?: number;
}

const PreviewPageCompanyDetails: React.FC<Props> = (props) => {
  const { companyId, name, logo, badge, ...links } = props;
  const { customBreakpointReached } = useResponsiveLayoutContext();

  const badgeMap = BadgeMapping(badge as BadgeCodebook);

  return (
    <Styled.Root
      data-testid="previewPageCompanyDetailsRoot"
      customBreakpointReached={customBreakpointReached}
    >
      <Link
        component={RouterLink}
        to={`/employer/${companyId}/profile`}
        underline="none"
      >
        <Styled.LogoWrapper customBreakpointReached={customBreakpointReached}>
          <ImageWithBadge
            dataTestId="restaurantLogo"
            src={logo ? logo.fullPath : defaultEmployerLogo}
            height="150px"
            width="150px"
            badge={badge && badgeMap?.[badge?.value]}
            noBorder
            noBorderRadius
            expandableBadge
          />
        </Styled.LogoWrapper>
      </Link>
      <Styled.Content data-testid="companyInformationContainer">
        <Link
          component={RouterLink}
          to={`/employer/${companyId}/profile`}
          underline="none"
        >
          <Typography
            variant={customBreakpointReached ? 'EC_TYPE_5XL' : 'EC_TYPE_LG'}
            sx={{
              color: theme.palette.GRAY_4.main,
              marginBottom: customBreakpointReached ? '16px' : 0
            }}
          >
            {name}
          </Typography>
        </Link>
        <Box>
          <Links {...links} />
        </Box>
      </Styled.Content>
    </Styled.Root>
  );
};

export default PreviewPageCompanyDetails;
