import { featureFlags } from '../../featureFlags';

export interface FeatureFlagConfig {
  ATS_WORKAROUND: boolean;
  CANDIDATE_ACCOUNT_UPDATES: boolean; // Per https://escoffier.atlassian.net/browse/EC1-2586
  CANDIDATE_DEACTIVATE: boolean;
  CANDIDATE_MARKETPLACE: boolean;
  CANDIDATE_RESUME_BUILDER: boolean;
  CANDIDATE_RESUME_BUILDER_PDF: boolean;
  CAREER_PATH: boolean;
  CAREER_QUIZ: boolean;
  CAREER_QUIZ_RESULTS: boolean;
  // EC1-4305: Employer Join Account - Self Service Google Locations
  COMPANY_LOCATION: boolean;
  COMPANY_PARTNERSHIP_LEVELS: boolean;
  COMPANY_SETTINGS_REDESIGN: boolean;
  CONTENT_MANAGEMENT: boolean;
  DIRECT_CONNECT_MANAGEMENT: boolean;
  EC_CAMPUS_PROGRAM: boolean;
  EMPLOYER_MARKETING_METRICS: boolean;
  EXTERNSHIPS: boolean;
  MULTIPLE_EXTERNSHIPS_PER_COURSE: boolean;
  // PILOT_EXTERNSHIPS can be set to false once the Pilot has ended and EXTERNSHIPS is true
  PILOT_EXTERNSHIPS: boolean;
  // EXTERNAL_JOB_POSTS: Flag to display external job posts retrieved from adzuna on the career marketplace
  EXTERNAL_JOB_POSTS: boolean;
  GLRC_CUSTOMIZATION: boolean;
  GOOGLE_SSO: boolean;
  JOB_RECOMMENDATIONS: boolean;
  NOTIFICATION_SETTINGS: boolean;
  PARENT_CHILD_RELATION: boolean;
  PARENT_DASH: boolean;
  PARTNER_EXPERIENCE: boolean;
  PREMIER_PARTNERS_MANAGEMENT: boolean;
  PREVIOUS_EXPERIENCE: boolean;
  PROFILE_BADGE: boolean;
  REPORTING_DASH: boolean;
  STUDENT_PORTAL_SSO: boolean;
  WORK_LEARN_CANDIDATE_PAGE: boolean;
  WORK_AND_LEARN_MANAGEMENT: boolean;
  ESOURCE_SEARCH: boolean;
}

export const useFeatureFlags = (): FeatureFlagConfig => {
  const FEATURE_FLAG_ENV = process.env.REACT_APP_FEATURE_FLAG_ENV;
  const flags = featureFlags(FEATURE_FLAG_ENV);

  return flags;
};
