import * as React from 'react';
import { Alert } from '@mui/material';

const TimesheetAccetStatement: React.FC = () => {
  return (
    <Alert severity="info" sx={{ typography: 'EC_TYPE_XL' }}>
      NOTE: Students cannot work for more than eight hours per day for five
      consecutive days.
    </Alert>
  );
};

export default TimesheetAccetStatement;
