import * as React from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  Icon,
  Input,
  Stack,
  TextField,
  Theme,
  Typography,
  TypographyProps
} from '@mui/material';
import {
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
  useFormContext,
  UseFormSetError
} from 'react-hook-form';
import { isVideoType, MediaType } from '@api/models/mediaApi.models';
import { FilesApi } from '@api/Files.api';
import { FileType } from '@api/models/fileApi.models';
import { styled } from '@mui/system';
import UploadedFileDisplay from '@components/ImageUpload/UploadedFileDisplay';
import { VideosApi } from '@api/Videos.api';
import { VideoType } from '@api/models/videoApi.models';

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    display: 'inline-block',
    position: 'relative',
    '& .video-section-label': {
      marginTop: '40px',
      marginBottom: '10px'
    },
    '& .video-section-sublabel': {
      color: theme.palette.GRAY_3.main,
      marginBottom: '10px'
    }
  })),
  LabelSection: styled(Box)({
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px'
  }),
  UploadBox: styled(Box)(({ theme }) => ({
    height: '173px',
    backgroundColor: theme.palette.GRAY_2.main,
    border: `2px dashed ${theme.palette.OUTLINE.main}`,
    borderRadius: '7px'
  })),
  GridContainer: styled(Grid)({
    alignItems: 'center',
    width: '100%',
    marginTop: '35px'
  }),
  FileTypeText: styled(Typography)(({ theme }) => ({
    color: theme.palette.GRAY_3.main,
    '& .file-types:before': {}
  })),
  OuterHoverBox: styled(Box)(({ theme }) => ({
    border: `4px dashed ${theme.palette.GOLD_1.main}`,
    borderRadius: '7px',
    backgroundColor: '#EBDFCD',
    color: theme.palette.GOLD_1.main,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center'
  })),
  AddIcon: styled(Icon)({
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    alignSelf: 'center',
    fontSize: '31.67px',
    lineHeight: '31.67px'
  }),
  CloudIcon: styled(Box)(({ theme }) => ({
    fontSize: '50px',
    color: theme.palette.GRAY_3.main
  })),
  Browse: styled('span')(({ theme }) => ({
    color: theme.palette.GOLD_1.main,
    cursor: 'pointer'
  })),
  ErrorText: styled(Typography)({
    color: '#BB1133',
    marginBottom: '11px'
  }),
  Textfield: styled(TextField)(({ theme }) => ({
    // marginTop: '16px',
    '.MuiOutlinedInput-root': {
      padding: '0px',
      fieldset: {
        borderRadius: '4px 4px 0 0',
        borderBottom: `2px solid ${theme.palette.GOLD_1.main}`
      }
    }
  })),
  AddVideoBtn: styled(Button)(({ theme }) => ({
    // color: theme.palette.primary.main,
    fontSize: (theme as Theme).typography.EC_TYPE_SM.fontSize,
    padding: '12px 20px',
    marginTop: '10px'
    // '& .add-video-btn-text': {
    //   color: theme.palette.primary.main
    // },
    // '&:disabled': {
    //   color: 'white'
    //   // backgroundColor: theme.palette.GRAY_1.main,
    //   // color: theme.palette.GRAY_2.main
    // }
  }))
};

const DEFAULT_MAX_FILE_SIZE_BYTES = 5000000;
const KB_PER_B = 1000;
const MB_PER_B = KB_PER_B * 1000;

export const convertFileSize = (fileSizeInBytes: number): string => {
  if (fileSizeInBytes < KB_PER_B) {
    return `${fileSizeInBytes} bytes`;
  } else if (fileSizeInBytes >= KB_PER_B && fileSizeInBytes < MB_PER_B) {
    return `${(fileSizeInBytes / KB_PER_B).toFixed(1)} kb`;
  } else {
    return `${(fileSizeInBytes / MB_PER_B).toFixed(1)} mb`;
  }
};

export interface ImageUploadWellProps<T extends FieldValues = {}>
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  displayText: string;
  label?: string;
  rules?: RegisterOptions;
  maxFileSizeBytes?: number;
  validTypes?: string[];
  files: any[];
  secure?: boolean;
  photoLimit?: number;
  videoFieldName?: string;
  labelVariant?: TypographyProps['variant'];
  disableUploadedFileDisplay?: boolean;
  customUploadSectionTitle?: string;
  clickableFiles?: boolean;
  uploadCallback?: (...args: any) => Promise<void>;
  uploadedResume?: (data: boolean) => void;
  setPhotosOnParent?: React.Dispatch<React.SetStateAction<any[]>>;
  setRhfError?: UseFormSetError<T>;
}

const ImageUploadWell = <T extends FieldValues = {}>({
  name,
  displayText,
  label,
  rules,
  maxFileSizeBytes = DEFAULT_MAX_FILE_SIZE_BYTES,
  validTypes = ['image/*'],
  files,
  secure,
  labelVariant = 'EC_TYPE_BASE',
  photoLimit = 99,
  videoFieldName,
  disableUploadedFileDisplay = false,
  customUploadSectionTitle,
  clickableFiles = false,
  uploadCallback,
  uploadedResume,
  setPhotosOnParent,
  setRhfError,
  ...otherProps
}: ImageUploadWellProps<T>): React.ReactElement => {
  const { multiple = false } = otherProps;
  const [dragging, setDragging] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState<string>('');
  const [videoErrorMsg, setVideoErrorMsg] = React.useState<string>('');
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [videoUrl, setVideoUrl] = React.useState<string>('');

  // TODO: videosUploaded and photosUploaded could be refactored into mediaUploaded,
  // in order to go from three useStates down to one
  const [videosUploaded, setVideosUploaded] = React.useState<VideoType[]>([]);
  const [mediaUploaded, setMediaUploaded] = React.useState<MediaType[]>([]);
  const [photosUploaded, setPhotosUploaded] = React.useState<FileType[]>([]);

  const inputFileRef = React.useRef<HTMLInputElement | null>(null);
  const dropCount = React.useRef(mediaUploaded.length);

  const {
    control,
    setValue,
    clearErrors,
    formState: { errors }
  } = useFormContext();

  let dragCounter = 0;

  const supportedFileTypes = validTypes.map((type, index) => (
    <span key={index} data-testid={`valid-file-type-${index}`}>
      {type.substring(type.lastIndexOf('/') + 1, type.length).toUpperCase()}
      {index != validTypes.length - 1 ? ', ' : ''}
    </span>
  ));

  const handleBrowseBtnClick = (): void => {
    const inputBtn = inputFileRef.current!;
    inputBtn.click();
  };

  const isDuplicatePhoto = (photoName: string): boolean =>
    mediaUploaded.some(
      (media) => 'originalname' in media && media.originalname === photoName
    );

  const validatedArray = (filesToValidate: File[]): boolean => {
    const fileCount = filesToValidate.length + mediaUploaded.length;
    let aboveLimit = false;
    let invalidFile = false;

    if (fileCount > photoLimit) {
      setErrorMsg(`Upload only up to ${photoLimit} images`);
      aboveLimit = true;
    }

    filesToValidate.forEach((file) => {
      if (isDuplicatePhoto(file.name)) {
        setErrorMsg('Duplicate photo');
        invalidFile = true;
      }

      if (validTypes.indexOf(file.type) === -1) {
        file['invalid'] = true;
        setErrorMsg(
          multiple
            ? 'One or more of your files is an unsupported file type'
            : 'Invalid file type'
        );
        invalidFile = true;
      }

      if (file.size > maxFileSizeBytes) {
        setErrorMsg(
          `File size must be less than ${convertFileSize(maxFileSizeBytes)}`
        );
        invalidFile = true;
      }
    });

    if (aboveLimit || invalidFile) {
      return false;
    } else {
      setErrorMsg('');
      clearErrors();
      return true;
    }
  };

  const handleUploadFiles = async (files: FileList): Promise<void> => {
    // set state if candidate upload resume when applying to job
    // this will prevent Use resume from profile box from page displaying
    if (uploadedResume) uploadedResume(true);

    const fileArray: File[] = Array.from(files);
    const { length } = fileArray;

    const isValidArray = validatedArray(fileArray);

    if (isValidArray) {
      dropCount.current += length;

      const uploadedFiles = await Promise.all(
        fileArray.map((file) => FilesApi.upload(file, secure))
      );

      let updatedPhotos = photosUploaded;

      if (!multiple) {
        // Set only one element of the array, as a single file upload
        updatedPhotos = [uploadedFiles[0].data];
        setPhotosUploaded(() => updatedPhotos);
      } else {
        setPhotosUploaded((prev) => {
          updatedPhotos = [...prev, ...uploadedFiles.map((file) => file.data)];
          return updatedPhotos;
        });
      }

      if (uploadCallback) {
        uploadCallback(updatedPhotos.map((f) => f.id));
      }
    }
  };

  const isDuplicateVideo = (videoUrl: string): boolean => {
    const urlMap = videosUploaded.map((item) => item.originalUrl);
    if (urlMap.includes(videoUrl)) {
      return true;
    }

    return false;
  };

  const handleUploadVideo = async (videoUrl: string): Promise<void> => {
    const url = videoUrl.trim();
    if (!isDuplicateVideo(url)) {
      try {
        const videoData = await VideosApi.uploadVideo({ video: url });

        if (videoData) {
          dropCount.current++;
          setVideosUploaded((prev) => [...prev, videoData.data]);
          setVideoUrl('');
          setVideoErrorMsg('');
        }
      } catch (error: any) {
        console.error('Error for ImageUploadWell.uploadVideo()', error);
        setVideoErrorMsg('Please enter a valid YouTube video URL');
      }
    } else {
      setVideoErrorMsg('That video has already been uploaded');
    }
  };

  // User uploads via "browse" button
  const handleFilesSelected = (): void => {
    if (inputFileRef.current?.files?.length) {
      setUploading(true);
      handleUploadFiles(inputFileRef.current.files);
    }
  };

  const handleRemoveMedia = (mediaItem: MediaType): void => {
    // set state if candidate doesn't upload resume when applying to job
    if (uploadedResume) uploadedResume(false);

    if (mediaUploaded.length > 0) {
      if (isVideoType(mediaItem)) {
        const vidArr = videosUploaded.filter((item) => item !== mediaItem);
        setVideosUploaded(vidArr);
        dropCount.current = vidArr.length + photosUploaded.length;
      } else if (!isVideoType(mediaItem)) {
        const photoArr = photosUploaded.filter((item) => item !== mediaItem);
        setPhotosUploaded(photoArr);
        if (uploadCallback) uploadCallback(photoArr.map((p) => p.id));

        dropCount.current = photoArr.length + videosUploaded.length;
        if (inputFileRef?.current?.value) {
          inputFileRef.current.value = '';
        }
      }
    }
  };

  const handleDragStart = (e: DragEvent | React.DragEvent): void => {
    if (e.dataTransfer) e.dataTransfer.clearData();
  };

  const handleDrag = (e: DragEvent | React.DragEvent): void => {
    e.preventDefault();
  };

  const handleDragEnter = (e: DragEvent | React.DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter++;
    if (e.dataTransfer?.items && e.dataTransfer?.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragExit = React.useCallback(
    (e: DragEvent | React.DragEvent): void => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter--;
      if (dragCounter === 0) {
        setDragging(false);
      }
    },
    []
  );

  // User uploads via drag and drop
  const handleDrop = (e: DragEvent | React.DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    const dropFiles = e.dataTransfer?.files;
    if (dropFiles && dropFiles.length > 0) {
      setUploading(true);
      handleUploadFiles(dropFiles);
      dragCounter = 0;
    }
  };

  // sends current list of photos to the parent component
  React.useEffect(() => {
    if (setPhotosOnParent) {
      setPhotosOnParent(photosUploaded);
    }
  }, [photosUploaded]);

  // ensure that mediaUploaded is up to date with all videos and photos uploaded
  React.useEffect(() => {
    setMediaUploaded([...videosUploaded, ...photosUploaded]);
  }, [photosUploaded, videosUploaded]);

  // set media to files passed as prop - photos and videos
  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const videos = files.filter((file) => isVideoType(file));
      const photos = files.filter((file) => !isVideoType(file));
      setMediaUploaded(files);
      setVideosUploaded(videos);
      setPhotosUploaded(photos);

      dropCount.current = files.length;
    }
    return (): void => {
      isMounted = false;
    };
  }, [files]);

  React.useEffect(() => {
    if (photosUploaded.length > 0) {
      setUploading(false);
      if (!multiple) {
        setValue(name, photosUploaded[0].id);
      } else {
        setValue(
          name,
          photosUploaded.map((file) => file.id)
        );
      }
    } else {
      setValue(name, !multiple ? null : []);
    }
  }, [photosUploaded]);

  React.useEffect(() => {
    if (videosUploaded.length > 0) {
      setValue(
        'videoIds',
        videosUploaded.map((video) => video.id)
      );
    } else {
      setValue('videoIds', []);
    }
  }, [videosUploaded]);

  React.useEffect(() => {
    if (errorMsg) {
      setUploading(false);
      if (setRhfError) {
        setRhfError(name as Path<T>, {
          type: 'manual',
          message: errorMsg
        });
      }
    }
  }, [errorMsg, setRhfError]);

  // Check the number of uploaded files
  React.useEffect(() => {
    if (photosUploaded && photoLimit && photosUploaded.length > photoLimit) {
      setErrorMsg(`Upload up to ${photoLimit} images`);
    } else {
      setErrorMsg('');
    }
  }, [photosUploaded, photoLimit]);

  const displayLabelSection = label || errorMsg || errors[name];

  return (
    <FormControl
      fullWidth
      data-testid={`${name}-image-upload-form-control`}
      id={name}
    >
      {displayLabelSection && (
        <Styled.LabelSection>
          {label && (
            <Typography
              variant={labelVariant}
              data-testid={`${name}-image-upload-label`}
            >
              {label}
            </Typography>
          )}
          {errorMsg && (
            <Styled.ErrorText variant="EC_TYPE_2XS" data-testid="EC-error">
              {errorMsg}
            </Styled.ErrorText>
          )}
          {errors[name] && (
            <Styled.ErrorText
              variant="EC_TYPE_2XS"
              data-testid={`${name}-error-message`}
            >
              {errors[name].message}
            </Styled.ErrorText>
          )}
        </Styled.LabelSection>
      )}
      <Controller
        name={name}
        control={control}
        rules={{ ...rules }}
        render={({ field: { value, ...rest } }): JSX.Element => {
          return (
            <Input
              {...rest}
              type="hidden"
              sx={{ display: 'none' }}
              error={!!errors[name]}
              value={value ?? ''}
            />
          );
        }}
      />
      <Controller
        name="file"
        control={control}
        rules={{ ...rules }}
        render={({ field: { value, ...rest } }): JSX.Element => {
          return (
            <Styled.Root
              onDragStart={handleDragStart}
              onDragOver={handleDrag}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragExit}
              onDrop={handleDrop}
              data-testid={`${name}-image-upload-box`}
            >
              <Styled.UploadBox>
                <Styled.GridContainer container spacing={0} direction="column">
                  <Grid item xs={12}>
                    <Styled.CloudIcon>
                      <i className="ri-upload-cloud-fill" />
                    </Styled.CloudIcon>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ marginTop: '20px', marginBottom: '8px' }}
                  >
                    <Typography data-testid="display-text" variant="EC_TYPE_SM">
                      {displayText},&nbsp;or&nbsp;
                      <Styled.Browse onClick={handleBrowseBtnClick}>
                        browse
                        <input
                          {...rest}
                          data-testid="upload-input"
                          type="file"
                          ref={inputFileRef}
                          onChange={handleFilesSelected}
                          title=""
                          value={value}
                          style={{ display: 'none' }}
                          {...otherProps}
                        />
                      </Styled.Browse>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row">
                      <Styled.FileTypeText
                        data-testid="supported-files"
                        variant="EC_TYPE_2XS"
                      >
                        Supports:&nbsp;{supportedFileTypes}
                      </Styled.FileTypeText>
                    </Stack>
                  </Grid>
                </Styled.GridContainer>
              </Styled.UploadBox>
              {dragging && (
                <Styled.OuterHoverBox data-testid="hover-box">
                  <Styled.AddIcon className="ri-add-circle-fill" />
                </Styled.OuterHoverBox>
              )}
            </Styled.Root>
          );
        }}
      />
      {videoFieldName !== undefined && (
        <Styled.Root>
          <Typography variant="EC_TYPE_LG" className="video-section-label">
            Add YouTube Video
          </Typography>
          <Typography variant="EC_TYPE_2XS" className="video-section-sublabel">
            Paste link to your hosted YouTube video
          </Typography>

          {videoErrorMsg && (
            <Styled.ErrorText variant="EC_TYPE_2XS" data-testid="EC-error">
              {videoErrorMsg}
            </Styled.ErrorText>
          )}

          <Styled.Textfield
            data-testid="video-text-field"
            onChange={(e): void => setVideoUrl(e.target.value)}
            placeholder="URL"
            value={videoUrl}
            fullWidth
          />
          <Styled.AddVideoBtn
            data-testid="video-add-button"
            variant="outlined"
            startIcon={<i className="ri-add-line" />}
            onClick={(): Promise<void> => handleUploadVideo(videoUrl)}
            disabled={!videoUrl}
          >
            Add video
          </Styled.AddVideoBtn>

          <Controller
            name={videoFieldName}
            control={control}
            rules={{ ...rules }}
            render={({ field }): JSX.Element => {
              return <Styled.Textfield {...field} sx={{ display: 'none' }} />;
            }}
          />
        </Styled.Root>
      )}
      {!disableUploadedFileDisplay && (
        <UploadedFileDisplay
          dropCount={dropCount.current}
          mediaArray={mediaUploaded}
          uploading={uploading}
          remove={handleRemoveMedia}
          multiple={multiple}
          customSectionTitle={customUploadSectionTitle}
          clickableFiles={clickableFiles}
        />
      )}
    </FormControl>
  );
};

export default ImageUploadWell;
