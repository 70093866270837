import { ExternshipEntity } from '@api/models/externshipApi.models';
import { getCityStateZip } from '@common/helpers/addressHelpers/getCityStateZip';

export const getExternshipLocation = (
  externship: ExternshipEntity | Omit<ExternshipEntity, 'course'>
): string => {
  if (externship.company?.locations) {
    return getCityStateZip(externship.company.locations[0]);
  }

  if (externship.proposedCompany?.location) {
    return getCityStateZip(externship.proposedCompany.location);
  }

  return '';
};
