import {
  BadgeCodebook,
  Codebook,
  SkillCodebook
} from '@api/models/codebook.models';
import {
  Education,
  EducationNotApplicable,
  EducationPutReq,
  WorkExperience,
  WorkExperienceNotApplicable,
  WorkExperiencePutReq
} from '@api/models/profileSetupApi.models';
import { ExternshipEntity } from '@api/models/externshipApi.models';
import { FileType } from '@api/models/fileApi.models';
import { Location } from '@interfaces/location.interfaces';
import { Tag } from '@api/models/common.models';
import { KeycloakUser } from '@api/models/userApi.models';
import { KeycloakUserEntity } from './employerApi.models';

export enum CANDIDATE_STATUS {
  CREATED = 0,
  COMPLETED = 3
}

// Matches EC.API/src/interfaces/candidates.interface.ts
export interface Candidate extends KeycloakUser {
  availability: string;
  createdAt: Date;
  currentJobTitle?: string | null;
  educationNotApplicable: number;
  facebookUrl?: string | null;
  instagramUrl?: string | null;
  isDemo: boolean;
  linkedInUrl?: string | null;
  locations: Location[];
  phone: string;
  relocation?: string;
  statement: string;
  status: CANDIDATE_STATUS;
  updatedAt?: Date;
  websiteUrl?: string | null;
  workAndLearn?: string;
  studentId?: string;
}

export interface Photo {
  id: number;
  file: FileType;
  thumbnail: FileType;
}

export interface CandidatePrivateInfo {
  email: string;
  phone: string;
  resume?: FileType | null;
  websiteUrl?: string;
  linkedInUrl?: string;
  instagramUrl?: string;
  facebookUrl?: string;
}

// TODO use CareerPath interface in CandidateProfile
export interface CandidateProfilePublic {
  id: number;
  userId: string;
  user?: KeycloakUserEntity;
  attributes: Tag[];
  availability: string;
  badge: BadgeCodebook;
  benefits: Tag[];
  candidateCulinaryPhotos: Photo[];
  certificates: Codebook[];
  createdAt: Date;
  culinaryPhotoIds: number[];
  education: Education<Codebook>[];
  educationNotApplicable: EducationNotApplicable;
  email: string;
  employmentType: Tag[];
  enabled: boolean;
  externships: ExternshipEntity[];
  externshipPilotEnabled?: boolean;
  facebookUrl?: string;
  firstName: string;
  graduationType: Codebook;
  program: Codebook;
  campus: Codebook;
  isDemo: boolean;
  lastName: string;
  locations: Location[];
  phone: string;
  photo?: FileType | null;
  resumeId?: number | null;
  relocation: string;
  segments: Codebook[];
  softSkills: SkillCodebook[];
  specialties: Codebook[];
  statement: string;
  status: CANDIDATE_STATUS;
  studentId?: string | null;
  technicalSkills: SkillCodebook[];
  updatedAt: Date;
  workAndLearn: string;
  workExperience: WorkExperience[];
  workExperienceNotApplicable: WorkExperienceNotApplicable;

  currentJobTitle: string | null; // TODO: OBSOLETE - to be removed eventually
  yearsOfExperience: Codebook | null; // TODO: OBSOLETE - to be removed eventually
}

export type CandidateProfile = CandidateProfilePublic & CandidatePrivateInfo;

export interface CandidateAboutPutReq {
  firstName: string;
  lastName: string;
  attributes: string[];
  availability: string;
  badge: number;
  benefits: string[];
  employmentType: string[];
  locations: Location[];
  phone: string;
  photoId?: number | null;
  relocation: string;
  segments: number[];
  specialties: number[];
  graduationType: number;
  campus?: number | null;
  program?: number | null;
  studentId?: string | null;
}

export interface CandidateExperiencePutReq {
  certificates: number[];
  education: EducationPutReq[];
  educationNotApplicable: EducationNotApplicable;
  softSkills: number[];
  technicalSkills: number[];
  workAndLearn: string;
  workExperience: WorkExperiencePutReq[];
  workExperienceNotApplicable: WorkExperienceNotApplicable;
}

export interface CandidatePersonalizePutReq {
  culinaryPhotos: number[];
  statement: string;
  facebookUrl?: string;
  instagramUrl?: string;
  linkedInUrl?: string;
  resumeId?: number | null;
  websiteUrl?: string;
}

export interface CandidateCareerGoalPutReq {
  careerRoleId: number;
}

export interface SaveResumePutReq {
  html: string;
  overwrite: boolean;
}
