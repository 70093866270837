import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CheckboxSingle from '@components/CheckboxSingle';
import { FileType } from '@api/models/fileApi.models';
import ImageUploadWell from '@components/ImageUpload/ImageUploadWell';
import { JobApplicationApi } from '@api/JobApplication.api';
import { JobApplicationPostReq } from '@api/models/jobApplicationApi.models';
import JobApplicationSubmittedScreen from './JobApplicationSubmittedScreen';
import ReactHookFormsSelect from '@components/Forms/ReactHookFormsSelect';
import ReactHookFormsTagSelection from '@components/Forms/ReactHookFormsTagSelection';
import { scrollToError } from '@common/helpers/scrollToError';
import { SkillCodebook } from '@api/models/codebook.models';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import { useTagManager } from '@common/hooks/useTagManager';

const Styled = {
  WrapperBox: styled(Box)({
    ['@media (max-width:992px)']: {
      padding: '40px 16px',
      backgroundColor: '#fff',
      borderRadius: '5px'
    },
    ['@media (max-width:480px)']: {
      padding: '20px 10px 40px 10px'
    }
  }),
  MainTitleBox: styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px'
  }),
  Heading: styled(Typography)({
    marginBottom: 0
  }),
  CloseBox: styled(Box)({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }),
  CloseText: styled(Typography)({
    color: theme.palette.GRAY_3.main
  }),
  CloseIcon: styled('i')({
    marginLeft: '5px',
    fontSize: '20px',
    lineHeight: '20px',
    color: theme.palette.GRAY_3.main
  }),
  ContentBox: styled(Box)({
    padding: '35px 53px 35px 53px',
    backgroundColor: '#fff',
    ['@media (max-width:900px)']: {
      padding: '35px 0 35px 0'
    }
  }),
  Box: styled(Box)({
    marginBottom: '40px',
    '&:last-child': {
      marginBottom: 0
    }
  }),
  SubmitButton: styled(Button)({
    padding: '35px 0',
    width: '100%',
    borderRadius: 0,
    ['@media (max-width:480px)']: {
      padding: '25px 0',
      borderBottomRightRadius: '5px',
      borderBottomLeftRadius: '5px'
    }
  }),
  SubmitButtonText: styled(Typography)({
    color: '#fff'
  }),
  ResumeBox: styled(Box)({
    marginTop: '30px',
    marginBottom: '20px',
    height: '50px',
    border: `2px solid ${theme.palette.GRAY_2.main}`,
    borderRadius: '7px',
    paddingLeft: '10px'
  }),
  InnerHeading: styled(Typography)({
    marginBottom: '25px'
  })
};

const workAndLearnOptions = [
  { label: 'Yes', value: 'YES' },
  { label: 'No', value: 'NO' }
];

const educationOptions = [
  { label: 'Yes, I am interested in pursuing my education', value: 'YES' },
  { label: 'No, not interested', value: 'NO' },
  { label: 'I am interested to learn more', value: 'LEARN_MORE' }
];

interface JobApplicationProps {
  isDirectConnectPost: boolean;
  jobPostId: number;
  workAndLearn: string;
  jobPostYearsOfExperience: string;
  savedResume?: FileType | null;
  companyId: number;
  techSkills: SkillCodebook[];
  softSkills: SkillCodebook[];
  onClick: () => void;
  onSuccessfulApplication: (jobApplicationId: number) => void;
  externalJobPost?: string;
  submittedApplicationId?: number;
}

interface FormValues {
  experience: string;
  education: string;
  technicalSkills: SkillCodebook[];
  softSkills: SkillCodebook[];
  resumeId: number | null;
  jobPostYearsOfExperience: string;
  useResume: boolean;
}

const JobApplication: React.FC<JobApplicationProps> = ({
  isDirectConnectPost,
  jobPostId,
  workAndLearn,
  jobPostYearsOfExperience,
  savedResume,
  companyId,
  techSkills,
  softSkills,
  onClick,
  onSuccessfulApplication,
  externalJobPost,
  submittedApplicationId
}) => {
  const { sendToGtm } = useTagManager();
  const [files] = React.useState<FileType[]>([]);
  const [candidateId] = useStore<number>(UseStoreKeys.CANDIDATE_ID);
  const [useResumeFromProfile, setUseResumeFromProfile] =
    React.useState<boolean>(false);
  const [uploadedResume, setUploadedResume] = React.useState<boolean>(false);
  const [submittingForm, setSubmittingForm] = React.useState(false);

  const methods = useForm<FormValues>({
    defaultValues: {
      experience: '',
      education: workAndLearn,
      technicalSkills: [],
      softSkills: [],
      resumeId: undefined
    }
  });

  const formOrder = {
    experience: 1,
    education: 2,
    technicalSkills: 3,
    softSkills: 4,
    resumeId: 5
  };

  const { handleSubmit, setError } = methods;

  const handleVisibilityOfResumeField = (data: boolean): void => {
    setUploadedResume(data);
  };

  const handleInvalidForm = (errors: FieldErrors): void => {
    scrollToError(errors, formOrder);
  };

  const handleValidForm = async (data: FormValues): Promise<void> => {
    setSubmittingForm(true);

    const postData: JobApplicationPostReq = {
      jobPostId: jobPostId,
      candidateId: candidateId,
      education: data.education,
      experience: data.experience,
      resumeId: useResumeFromProfile ? savedResume?.id : data.resumeId,
      softSkills: data.softSkills.map((skill) => skill.value),
      technicalSkills: data.technicalSkills.map((skill) => skill.value),
      statusId: 2
    };

    try {
      const res = await JobApplicationApi.createJobApplication(postData);
      onSuccessfulApplication(res.data.id);
    } catch (error: any) {
      console.error('Error for JobApplication', error);
      const errData = error.response.data.data;
      if (errData.length) {
        errData.forEach(({ field, message }) =>
          setError(field, { type: 'manual', message })
        );
      }

      scrollToError(methods.formState.errors, formOrder);
    } finally {
      setSubmittingForm(false);
      sendToGtm('job_application_submitted', {
        success: submittedApplicationId,
        company_id: companyId,
        job_id: jobPostId,
        work_and_learn: data.education
      });
    }
  };

  const handleResumeChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setUseResumeFromProfile(event.target.checked);
  };

  return (
    <Styled.WrapperBox data-testid="jobApplicationContainer">
      <Styled.MainTitleBox>
        <Styled.Heading variant="EC_TYPE_3XL">Apply to this job</Styled.Heading>
        <Styled.CloseBox>
          <Styled.CloseText variant="EC_TYPE_2XS" onClick={onClick}>
            Close
          </Styled.CloseText>
          <Styled.CloseIcon className="ri-close-fill" onClick={onClick} />
        </Styled.CloseBox>
      </Styled.MainTitleBox>
      {submittedApplicationId ? (
        <JobApplicationSubmittedScreen
          externalJobPost={externalJobPost}
          jobApplicationId={submittedApplicationId}
        />
      ) : (
        <FormProvider {...methods}>
          <form>
            <Styled.ContentBox>
              <Styled.Box>
                <Typography variant="EC_TYPE_LG">Select a Resume</Typography>
                {savedResume && !uploadedResume && (
                  <Styled.ResumeBox>
                    <CheckboxSingle
                      key="jobApplicationCheckbox"
                      control={<Checkbox />}
                      label="Use résumé from profile"
                      onChange={handleResumeChange}
                      name="useResume"
                    />
                  </Styled.ResumeBox>
                )}
                {!useResumeFromProfile && (
                  <ImageUploadWell
                    name="resumeId"
                    displayText={'Drop your Résumé here'}
                    validTypes={['application/pdf']}
                    files={files}
                    uploadedResume={handleVisibilityOfResumeField}
                    secure={true}
                  />
                )}
              </Styled.Box>
              {!isDirectConnectPost && (
                <Box>
                  <Styled.InnerHeading variant="EC_TYPE_LG">
                    Highlight Your Qualifications
                  </Styled.InnerHeading>
                  {jobPostYearsOfExperience !== 'NONE' && (
                    <Styled.Box>
                      <ReactHookFormsSelect
                        name="experience"
                        label={`Do you have ${jobPostYearsOfExperience} years of experience?`}
                        placeholder="Select Your Experience Level"
                        rules={{ required: 'This field is required' }}
                        options={workAndLearnOptions}
                      />
                    </Styled.Box>
                  )}
                  <Styled.Box>
                    <ReactHookFormsSelect
                      name="education"
                      label="Are you interested in further pursuing your education while you work?"
                      rules={{ required: 'This field is required' }}
                      options={educationOptions}
                    />
                  </Styled.Box>
                  <Styled.Box>
                    <ReactHookFormsTagSelection
                      tags={techSkills}
                      name="technicalSkills"
                      title="In which technical skills are you confident?"
                    />
                  </Styled.Box>
                  <Styled.Box>
                    <ReactHookFormsTagSelection
                      tags={softSkills}
                      name="softSkills"
                      title="In which soft skills are you confident?"
                    />
                  </Styled.Box>
                </Box>
              )}
            </Styled.ContentBox>
            <Styled.SubmitButton
              variant="contained"
              disabled={submittingForm}
              onClick={handleSubmit(handleValidForm, handleInvalidForm)}
            >
              <Styled.SubmitButtonText variant="EC_TYPE_SM">
                Submit Application
              </Styled.SubmitButtonText>
            </Styled.SubmitButton>
          </form>
        </FormProvider>
      )}
    </Styled.WrapperBox>
  );
};

export default JobApplication;
