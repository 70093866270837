export const enum Country {
  PUERTO_RICO = 'Puerto Rico'
}

export interface ExtractedAddressDetails {
  streetNumber?: string;
  streetName?: string;
  addressLineTwo?: string;
  city?: string;
  state?: string;
  shortState?: string;
  country?: string;
  zip?: string;
}

export interface Location extends ExtractedAddressDetails {
  id?: number;
  name?: string;
  description: string;
  placeId?: string | null;
  lat: number;
  lng: number;
  phone?: string;
}
