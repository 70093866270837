import * as React from 'react';
import { Box, Stack } from '@mui/material';
import {
  getCompanyPath,
  getEmployerPathWithParamsId
} from '@common/helpers/getPathWithParamsId';
import { Toast, useToast } from '@components/Toast';
import CtaButton from '@components/CtaButton';
import { JobPostApi } from '@api/JobPost.api';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import ToggleButton from '@components/ToggleButton';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const MOBILE_BREAKPOINT = 552;
const MOBILE_QUERY = `@media (max-width:${MOBILE_BREAKPOINT}px)`;

const Styled = {
  Root: styled(Box)({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    margin: '0 auto',
    paddingLeft: '65px',
    width: '100%',
    maxWidth: '1820px',
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    zIndex: 1,
    boxShadow: '0 4px 2px -2px rgba(0, 0, 0, 0.15)',
    ['@media (max-width:680px)']: {
      paddingLeft: '28px'
    },
    ['@media (max-width:480px)']: {
      paddingLeft: '16px'
    },
    ['@media (max-width:360px)']: {
      paddingLeft: '10px'
    }
  }),
  Left: styled(Stack)({
    flex: '1 1 840px',
    justifyContent: 'start',
    [MOBILE_QUERY]: {
      alignSelf: 'end',
      paddingBottom: '10px',
      flex: 1
    }
  }),
  Right: styled(Stack)({
    flex: '1 0.5 601px',
    alignItems: 'center',
    justifyContent: 'space-between',
    [MOBILE_QUERY]: {
      flexDirection: 'column-reverse',
      flex: 1.5,
      alignItems: 'flex-end'
    }
  }),
  ToggleBox: styled(Box)({
    marginRight: '16px'
  })
};

interface Props {
  jobId: number | null;
  companyId: number | null;
  isVisible: boolean;
}

const EmployerPreviewPageTopBar: React.FC<Props> = ({
  isVisible,
  jobId,
  companyId
}) => {
  const { width } = useWindowDimensions();

  const [isVis, setIsVis] = React.useState<boolean>(isVisible);

  const { openToast, ...toastProps } = useToast();

  const handleVisibility = async (value: boolean): Promise<void> => {
    if (jobId) {
      try {
        await JobPostApi.updateJobPostVisibility(jobId, {
          visibleOnMarketplace: value
        });
      } catch (error: any) {
        console.error(
          'Error for UpdateJobPostVisibility at Employer Preview Top Banner',
          error
        );
      } finally {
        setIsVis(!isVis);
        openToast(
          `Job number ${jobId} updated to ${isVis ? 'Hidden' : 'Public'}`
        );
      }
    }
  };

  return (
    <>
      <Styled.Root>
        <Styled.Left direction="row">
          <CtaButton
            label="Edit Job Post"
            to={getCompanyPath(`/job/${jobId}/edit`, companyId)}
            variant="text"
            color="GRAY_3"
            textColor={theme.palette.GRAY_3.main}
            textVariant="EC_TYPE_SM"
            startIcon={<i className="ri-arrow-left-line" />}
            btnSx={{ padding: 0 }}
          />
        </Styled.Left>
        <Styled.Right direction="row">
          <Styled.ToggleBox>
            <ToggleButton
              isChecked={isVis}
              handleVisibility={handleVisibility}
              displayText={{ checked: 'Public', unchecked: 'Hidden' }}
            />
          </Styled.ToggleBox>
          {width > MOBILE_BREAKPOINT ? (
            <CtaButton
              label="Save and Finish"
              to={getEmployerPathWithParamsId('/dashboard', companyId)}
              variant="contained"
              textVariant="EC_TYPE_SM"
              endIcon={<i className="ri-arrow-right-line" />}
              btnSx={{ borderRadius: 0 }}
            />
          ) : (
            <CtaButton
              label="Save and Finish"
              to={getEmployerPathWithParamsId('/dashboard', companyId)}
              variant="text"
              color="error"
              textColor={theme.palette.red2.main}
              textVariant="EC_TYPE_SM"
              endIcon={<i className="ri-arrow-right-line" />}
              btnSx={{ padding: 0, marginRight: '16px' }}
            />
          )}
        </Styled.Right>
      </Styled.Root>
      <Toast {...toastProps} />
    </>
  );
};

export default EmployerPreviewPageTopBar;
