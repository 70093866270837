export const testFeatureFlags = {
  ATS_WORKAROUND: true,
  CANDIDATE_ACCOUNT_UPDATES: true,
  CANDIDATE_DEACTIVATE: true,
  CANDIDATE_MARKETPLACE: true,
  CANDIDATE_RESUME_BUILDER: true,
  CANDIDATE_RESUME_BUILDER_PDF: true,
  CAREER_PATH: true,
  CAREER_QUIZ: true,
  CAREER_QUIZ_TEST_RESULTS: false,
  COMPANY_LOCATION: false,
  COMPANY_PARTNERSHIP_LEVELS: true,
  COMPANY_SETTINGS_REDESIGN: true,
  CONTENT_MANAGEMENT: true,
  DIRECT_CONNECT_MANAGEMENT: true,
  EC_CAMPUS_PROGRAM: true,
  EMPLOYER_MARKETING_METRICS: false,
  EXTERNSHIPS: true,
  MULTIPLE_EXTERNSHIPS_PER_COURSE: true,
  PILOT_EXTERNSHIPS: false,
  EXTERNAL_JOB_POSTS: true,
  GLRC_CUSTOMIZATION: true,
  GOOGLE_SSO: false,
  JOB_RECOMMENDATIONS: true,
  NOTIFICATION_SETTINGS: true,
  PARENT_CHILD_RELATION: true,
  PARENT_DASH: true,
  PARTNER_EXPERIENCE: true,
  PREMIER_PARTNERS_MANAGEMENT: true,
  PREVIOUS_EXPERIENCE: false,
  PROFILE_BADGE: true,
  REPORTING_DASH: true,
  STUDENT_PORTAL_SSO: true,
  WORK_LEARN_CANDIDATE_PAGE: true,
  WORK_AND_LEARN_MANAGEMENT: true,
  ESOURCE_SEARCH: true
};
