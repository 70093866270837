import { useEffect, useState } from 'react';
import { ExternalJobsResponse } from '@api/models/singleJobPostApi.models';
import { ExternalJobsSearchReq } from '@components/Filters/FiltersWrapper';
import { JobPostApi } from '@api/JobPost.api';

type FetchExternalJobs = {
  externalJobsResponse?: ExternalJobsResponse;
  loading: boolean;
  fetchExternalJobs: (filterData?: ExternalJobsSearchReq) => void;
};

/**
 * Fetch `ExternalJobsResponse` from JobPostApi.getExternalJobs()
 *
 * @returns `{ externalJobsResponse: ExternalJobsResponse; loading: boolean; filterExternalJobs: (filterData?: ExternalJobSearchReq) => void;}`
 */
export const useFetchExternalJobs = (): FetchExternalJobs => {
  const [externalJobsResponse, setExternalJobsResponse] =
    useState<ExternalJobsResponse>();
  const [loading, setLoading] = useState(false);

  const fetchExternalJobs = async (
    filterData?: ExternalJobsSearchReq
  ): Promise<void> => {
    try {
      setLoading(true);
      const res = await JobPostApi.getExternalJobs(filterData);

      setExternalJobsResponse(res.data);
    } catch (error: any) {
      console.error(
        'Error for useFetchExternalJobs -> JobPostApi.getExternalJobs()',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExternalJobs();
  }, []);

  return {
    externalJobsResponse,
    loading,
    fetchExternalJobs
  };
};
