import { phoneNumberPattern } from '@common/regex/phoneNumber';
/**
 *
 * @param phoneNumber 14 character string passed by react-input-mask component using `mask="(999) 999-9999"`
 */
export const validatePhoneNumber = (phoneNumber: string): boolean => {
  if (phoneNumber.length !== 14) return false;

  if (!phoneNumberPattern.test(phoneNumber)) return false;

  return true;
};
