/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  AgreementEntity,
  SiteAgreementData
} from '@api/models/externshipApi.models';
import AgreementModal, {
  AgreementModalProps
} from '@components/ExternshipAgreements/AgreementModal';
import { FormProvider, useForm } from 'react-hook-form';
import { ApiResponse } from '@api/models/base.models';
import { BLUE_1 } from '@themes/ui/escoffier';
import { ExternshipApi } from '@api/Externships.api';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { getPdfHtmlByElementId } from '@common/helpers/pdfHelpers';
import { PdfTypes } from '@api/models/pdfApi.models';
import { siteAgreementText } from './agreementText';
import { useKeycloakContext } from '@common/context/keycloakContext';

const styles = {
  dataDisplay: {
    margin: '24px 0'
  },
  noMargin: {
    margin: '0px'
  },
  inputStyle: {
    width: '132px',
    border: `1px solid ${BLUE_1}`
  }
};

interface FormValues {
  externshipsNumber: string;
}

interface Props extends AgreementModalProps {
  employerId: number;
  siteAgreementData?: SiteAgreementData;
  setSiteAgreementData?: (updatedData: Partial<SiteAgreementData>) => void;
}

const SiteAgreement: React.FC<Props> = ({
  employerId,
  siteAgreementData,
  setSiteAgreementData,
  handleClose,
  ...modalProps
}) => {
  const [errorText, setErrorText] = React.useState<string>();
  const [saving, setSaving] = React.useState(false);

  const { keycloakUser } = useKeycloakContext();
  const signedBy = `${keycloakUser.given_name} ${keycloakUser.family_name}`;

  const methods = useForm<FormValues>({
    defaultValues: {
      externshipsNumber: ''
    }
  });

  const { register, handleSubmit, watch } = methods;
  const externshipsNumber = watch('externshipsNumber');

  const handleCloseModal = (): void => {
    setErrorText(undefined);
    handleClose();
  };

  const handleAcceptClick = (): void => {
    setSaving(true);
  };

  const signSiteAgreement = async (formData: FormValues): Promise<void> => {
    let errText: string = '';
    let res: ApiResponse<AgreementEntity> | undefined = undefined;

    try {
      if (externshipsNumber && siteAgreementData?.companyId) {
        const html = getPdfHtmlByElementId(PdfTypes.SITE_AGREEMENT);

        const postBody = {
          companyId: siteAgreementData.companyId,
          externshipsNumber: parseInt(formData.externshipsNumber),
          html,
        };

        res = await ExternshipApi.signSiteAgreement(employerId, postBody);
      } else {
        errText = 'Please enter number of externships';
        setErrorText(errText);
      }
    } catch (error: any) {
      console.error(`Error saving site agreement: ${error}`);
    } finally {
      setSaving(false);

      if (!errText && !!res) {
        const fullName = res?.data?.file?.fullName;
        const signDate = res?.data?.managerSignDate;
        
        if (fullName && signDate) {
          setSiteAgreementData && setSiteAgreementData({
            agreementFullName: fullName,
            signDate: new Date(signDate).toISOString(),
            agreementNeeded: false
          });
        }
        handleClose();
      }
    }
  };

  React.useEffect(() => {
    if (saving) {
      const onValid = async (data: FormValues): Promise<void> => {
        signSiteAgreement(data);
      };

      handleSubmit(onValid)();
    }
  }, [saving]);

  return (
    <>
      {siteAgreementData && (
        <AgreementModal
          {...modalProps}
          htmlWrapperId={PdfTypes.SITE_AGREEMENT}
          handleClose={handleCloseModal}
          handleAccept={handleAcceptClick}
          errorText={errorText}
          pdfLoading={saving}
        >
          <div dangerouslySetInnerHTML={{ __html: siteAgreementText }} />
          <span>At any point, my business/site can host&nbsp;</span>
          {!saving ? (
            <FormProvider {...methods}>
              <input
                {...register('externshipsNumber', {
                  required: 'Please enter number of externships'
                })}
                type="number"
                min="1"
                placeholder="no. of externships"
                style={styles.inputStyle}
              />
            </FormProvider>
          ) : (
            <span>{externshipsNumber}</span>
          )}
          <span>
            &nbsp;externship students at the property that are actively
            completing the externship course. This number does not include any
            graduates of the school that are employed at the establishment.
          </span>
          <div style={styles.dataDisplay}>
            <div>
              <p style={styles.noMargin}>
                <strong>Business Name:</strong> {siteAgreementData.companyName}
              </p>
              <p style={styles.noMargin}>
                <strong>Business Phone:</strong> {siteAgreementData.companyPhone}
              </p>
              <p style={styles.noMargin}>
                <strong>Business Address:</strong> {siteAgreementData.companyAddress}
              </p>
            </div>
            <br />
            <div>
              <p style={styles.noMargin}>
                <strong>Site Manager Name:</strong> {siteAgreementData.managerName}
              </p>
              <p style={styles.noMargin}>
                <strong>Phone:</strong> {siteAgreementData.managerPhone}
              </p>
              <p style={styles.noMargin}>
                <strong>Title:</strong> {siteAgreementData.managerJobTitle}
              </p>
            </div>
          </div>
          <p>
            Upon review of the externship site agreement and upon favorable
            electronic or phone review of the location, a representative of the
            Career Services department will add an electronic copy of this
            completed form to the employer database. These actions will
            demonstrate institutional acceptance of the agreement.
          </p>
          <p style={{ fontWeight: 800 }}>
            SIGNED BY: <span style={{ fontWeight: 400 }}>{signedBy}</span>
          </p>
          <p style={{ fontWeight: 800 }}>
            DATE SIGNED:{' '}
            <span style={{ fontWeight: 400 }}>
              {formatDate(new Date().toISOString())}
            </span>
          </p>
        </AgreementModal>
      )}
    </>
  );
};

export default SiteAgreement;
