import * as React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const MEDIA_1024 = '@media (max-width:1024px)';
const MEDIA_480 = '@media (max-width:480px)';

const Styled = {
  Root: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'imgUrl' && prop !== 'height'
  })<{ imgUrl: string; height: string }>(({ imgUrl, height }) => ({
    padding: '32px 70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${imgUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center',
    height: height,
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      background: 'black',
      opacity: '0.65'
    },
    ['@media (max-width:1200px)']: {
      padding: '24px'
    }
  })),
  Title: styled(Typography)({
    zIndex: 1,
    textTransform: 'uppercase',
    color: 'white',
    [MEDIA_1024]: {
      fontSize: theme.typography.EC_TYPE_5XL.fontSize,
      lineHeight: theme.typography.EC_TYPE_5XL.lineHeight
    },
    [MEDIA_480]: {
      fontSize: theme.typography.EC_TYPE_4XL.fontSize,
      lineHeight: theme.typography.EC_TYPE_4XL.lineHeight
    }
  }),
  Pretitle: styled(Typography)({
    xIndex: 1,
    textTransform: 'uppercase',
    color: 'white',
    [MEDIA_1024]: {
      fontSize: theme.typography.EC_TYPE_4XL.fontSize,
      lineHeight: theme.typography.EC_TYPE_4XL.lineHeight
    },
    [MEDIA_480]: {
      fontSize: theme.typography.EC_TYPE_3XL.fontSize,
      lineHeight: theme.typography.EC_TYPE_3XL.lineHeight
    }
  }),
  Subtitle: styled(Typography)({
    color: 'white',
    marginTop: '24px'
  })
};

export enum ComponentSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small'
}

interface Props {
  size?: ComponentSize;
  imgUrl: string;
  title: string;
  pretitle?: string;
  subtitle?: string;
  loading?: boolean;
}

const Hero: React.FC<Props> = ({
  imgUrl,
  title,
  pretitle,
  subtitle,
  loading,
  size = ComponentSize.MEDIUM
}) => {
  const height = React.useMemo((): string => {
    switch (size) {
      case ComponentSize.LARGE:
        return '500px';
      case ComponentSize.MEDIUM:
        return '350px';
      case ComponentSize.SMALL:
        return '270px';
      default:
        return '350px';
    }
  }, [size]);

  return (
    <>
      {loading ? (
        <Skeleton height={height} sx={{ transform: 'none', padding: 0 }} />
      ) : (
        <Styled.Root imgUrl={encodeURI(imgUrl)} height={height}>
          <Box sx={{ zIndex: 1 }}>
            {pretitle && (
              <Styled.Pretitle variant="EC_TYPE_5XL">
                {pretitle}
              </Styled.Pretitle>
            )}
            <Styled.Title variant="EC_TYPE_6XL">{title}</Styled.Title>
            {subtitle && (
              <Styled.Subtitle variant="EC_TYPE_LG">{subtitle}</Styled.Subtitle>
            )}
          </Box>
        </Styled.Root>
      )}
    </>
  );
};

export default Hero;
