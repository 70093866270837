import * as React from 'react';
import { ExternshipApi } from '@api/Externships.api';
import { TimesheetPerformanceItem } from '@api/models/externshipApi.models';

export type FetchTimesheetPerformanceQuestions = {
  timesheetPerformanceItems: TimesheetPerformanceItem[];
  loaded: boolean;
  loading: boolean;
};

export const useFetchTimesheetPerformanceQuestions =
  (): FetchTimesheetPerformanceQuestions => {
    const [timesheetPerformanceItems, setTimesheetPerformanceQuestions] =
      React.useState<TimesheetPerformanceItem[]>([]);
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const fetchTimesheetPerformanceQuestions = async (): Promise<void> => {
      try {
        setLoading(true);
        const res =
          await ExternshipApi.getTimesheetPerformanceReviewQuestions();

        setTimesheetPerformanceQuestions(res);
        setLoaded(true);
      } catch (error: any) {
        console.error(
          'Error for useFetchTimesheetPerformanceQuestions -> ExternshipApi.getTimesheetPerformanceReviewQuestions()',
          error
        );
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    };

    React.useEffect(() => {
      fetchTimesheetPerformanceQuestions();
    }, []);

    return {
      timesheetPerformanceItems,
      loaded,
      loading
    };
  };
