import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_BASE_URL,
  realm: 'ec',
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? 'ec_web'
});

export default keycloak;
