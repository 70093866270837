import * as React from 'react';
import { Box, Typography } from '@mui/material';
import ReactHookFormsAutoComplete from '@components/Forms/ReactHookFormsAutoComplete';
import ReactHookFormsTagSelection from '@components/Forms/ReactHookFormsTagSelection';
import StepSection from '@components/StepSection';
import { styled } from '@mui/system';

const Styled = {
  Section: styled(Box)({
    marginBottom: '80px'
  }),
  SectionTitle: styled(Typography)({
    marginBottom: '24px'
  }),
  TagSection: styled(Box)({
    '& > *': {
      marginTop: '49px'
    }
  })
};

interface IProps {
  healthWellnessOptions: string[];
  retirementOptions: string[];
  professionalDevOptions: string[];
  threeKeyBenefitsOptions: string[];
  threeBenefits?: string[];
}

type BenefitSection = {
  name: string;
  tags: string[];
  title: string;
};

const JobBenefitsStep: React.FC<IProps> = (props) => {
  const {
    healthWellnessOptions,
    retirementOptions,
    professionalDevOptions,
    threeKeyBenefitsOptions
  } = props;

  const benefitSections: BenefitSection[] = [
    {
      name: 'healthWellnessBenefits',
      tags: healthWellnessOptions,
      title: 'Health Insurance & Wellness Benefits'
    },
    {
      name: 'retirementBenefits',
      tags: retirementOptions,
      title: 'Retirement'
    },
    {
      name: 'professionalDevBenefits',
      tags: professionalDevOptions,
      title: 'Professional Development'
    }
  ];

  return (
    <Styled.Section data-testid="employer-job-benefits-section">
      <StepSection title="Job Benefits">
        <Styled.SectionTitle variant="EC_TYPE_LG">
          Review and remove any company benefits that do not apply to this
          position.
        </Styled.SectionTitle>
        <Styled.TagSection>
          {benefitSections.map(
            (section) =>
              section.tags.length > 0 && (
                <ReactHookFormsTagSelection
                  key={section.name}
                  name={section.name}
                  tags={section.tags}
                  title={section.title}
                />
              )
          )}
          <ReactHookFormsAutoComplete
            name="threeKeyBenefits"
            label="Highlight up to three benefits that differentiate your company."
            title="Three key benefits"
            inputPlaceholder="Search from your company benefits"
            options={threeKeyBenefitsOptions}
            maxSelectable={3}
          />
        </Styled.TagSection>
      </StepSection>
    </Styled.Section>
  );
};

export default JobBenefitsStep;
