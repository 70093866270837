import {
  BulkUpdateUserRoleDto,
  ChangePasswordPostReq,
  RemoveEmployerFromCompanyReq
} from './models/settingsApi.models';
import { api } from './configs/base';
import { apiConfig } from './configs/api.config';
import { ApiResponse } from './models/base.models';
import { SettingsEmployerSeat } from './models/settingsApi.models';
import { UpdateUserRoleReq } from './models/settingsApi.models';
import { UpdateUserPostReq } from './models/settingsApi.models';
import { UserIdp } from './models/userApi.models';

export class SettingsApi {
  static async getUser(employerId: number): Promise<ApiResponse<SettingsEmployerSeat>> {
    try {
      const res = await api.get<ApiResponse<SettingsEmployerSeat>>(`${apiConfig.settingsUrl}/user/${employerId}`);

      return res.data;
    } catch (error: any) {
      console.error(`Error for SettingsApi.getUser(): ${error}`);
      throw error;
    }
  }

  static async getUserIdp(id: string): Promise<ApiResponse<UserIdp[] | null>> {
    try {
      const res = await api.get<ApiResponse<UserIdp[] | null>>(`${apiConfig.settingsUrl}/user/${id}/idp`);

      return res.data;
    } catch (error: any) {
      console.error(`Error for SettingsApi.getUserIDP(): ${error}`);
      throw error;
    }
  }

  static async updateUser(data: UpdateUserPostReq): Promise<void> {
    try {
      await api.post(`${apiConfig.settingsUrl}`, data);

      return;
    } catch (error: any) {
      console.error(`Error for SettingsApi.updateUserLoginOptions(): ${error}`);
      throw error;
    }
  }

  static async changePassword(data: ChangePasswordPostReq): Promise<void> {
    try {
      await api.put(`${apiConfig.settingsUrl}/changePassword`, data);

      return;
    } catch (error) {
      console.error(`Error for SettingsApi.changeUserPassword(): ${error}`);
      throw error;
    }
  }

  static async updateUserRoles(data: UpdateUserRoleReq): Promise<ApiResponse<UpdateUserRoleReq>> {
    try {
      const res = await api.post<ApiResponse<UpdateUserRoleReq>>(`${apiConfig.settingsUrl}/change-role`, data);

      return res.data;
    } catch (error: any) {
      console.error(`Error for SettingsApi.updateUserRole(): ${error}`);
      throw error;
    }
  }

  static async removeEmployerFromCompany(data: RemoveEmployerFromCompanyReq): Promise<void> {
    try {
      await api.post(`${apiConfig.settingsUrl}/remove-from-company`, data);

      return;
    } catch (error: any) {
      console.error(`Error for SettingsApi.removeEmploerFromCompany(): ${error}`);
      throw error;
    }
  }

  static async updateUserRolesBulk(data: BulkUpdateUserRoleDto): Promise<void> {
    try {
      await api.post(`${apiConfig.settingsUrl}/change-role/bulk`, data);

      return;
    } catch (error: any) {
      console.error(`Error for SettingsApi.updateUserRolesBulk(): ${error}`);
      throw error;
    }
  }
}
