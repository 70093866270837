import {
  ApproverInviteInfo,
  InviteCandidate,
  InviteExternshipManager,
  InviteInfo,
  SendInviteRequest,
  SendInviteResponse
} from '@api/models/inviteApi.models';
import { api } from '@api/configs/base';
import { apiConfig } from '@api/configs/api.config';
import { ApiResponse } from '@api/models/base.models';
import { roles } from '@api/models/userApi.models';
import { SentInvite } from '@api/models/settingsApi.models';

export class InviteApi {
  static async sendInvite(
    invite: SendInviteRequest
  ): Promise<ApiResponse<SendInviteResponse>> {
    try {
      const response = await api.post<ApiResponse<SendInviteResponse>>(
        `${apiConfig.inviteUrl}`,
        invite
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for InviteApi.sendInvite(): ${error}`);
      throw error;
    }
  }

  static async getInviteInfo(
    inviteCode: string,
    role: roles
  ): Promise<ApiResponse<InviteInfo | InviteExternshipManager>> {
    try {
      let endpoint: string = '';

      if (role === roles.RECRUITER) {
        endpoint = `${apiConfig.inviteUrl}/${inviteCode}`;
      }

      if (role === roles.PROPOSED_EXTERNSHIP_MANAGER) {
        endpoint = `${apiConfig.inviteUrl}/proposed-externship-manager/${inviteCode}`;
      }

      const response = await api.get<
        ApiResponse<InviteInfo | InviteExternshipManager> // eslint-disable-line @typescript-eslint/indent
      >(endpoint);

      return response.data;
    } catch (error: any) {
      console.error(`Error for InviteApi.getInviteInfo(): ${error}`);
      throw error;
    }
  }

  static async getSentInvitesByCompanyId(
    companyId: number
  ): Promise<ApiResponse<SentInvite[]>> {
    try {
      const response = await api.get<ApiResponse<SentInvite[]>>(
        `${apiConfig.inviteUrl}/${companyId}/sent-invites`
      );

      return response.data;
    } catch (error: any) {
      console.error(
        `Error for InviteApi.getSentInvitesByCompanyId(): ${error}`
      );
      throw error;
    }
  }

  static async inviteCandidate(
    data: InviteCandidate
  ): Promise<ApiResponse<InviteCandidate>> {
    try {
      const response = await api.post<ApiResponse<InviteCandidate>>(
        `${apiConfig.inviteUrl}/candidate`,
        data
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for InviteApi.inviteCandidate(): ${error}`);
      throw error;
    }
  }

  static async getApproverInviteInfo(
    approverEmail: string,
    externshipId: number,
    isSupervisor: boolean
  ): Promise<ApiResponse<ApproverInviteInfo>> {
    try {
      const response = await api.post<ApiResponse<ApproverInviteInfo>>(
        `${apiConfig.inviteUrl}/get-approver-invite-info`,
        { approverEmail, externshipId, isSupervisor }
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for InviteApi.getManagerInviteInfo(): ${error}`);
      throw error;
    }
  }
}
