import * as React from 'react';
import { allowedGridFilterOperators } from '@common/fetches/useFetchGridTableByType';
import { AppRoles } from '@api/models/userApi.models';
import EcDataGrid from '@components/DataGrid/EcDataGrid';
import EcGridTableToolbar from '@components/DataGrid/EcGridTableToolbar';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { getAppRoleLabel } from '@common/helpers/getRoleLabel';
import { useCompanySettingsContext } from '@common/context/companySettingsContext';

const InvitesTabContent: React.FC = () => {
  const { sentInvites } = useCompanySettingsContext();

  const _sentInvites = sentInvites.map((invite) => ({
    ...invite,
    role: getAppRoleLabel(invite.roles as AppRoles)
  }));

  const inviteColumns = [
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'sentDate',
      headerName: 'Sent Date',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'expirationDate',
      headerName: 'Expiration',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    }
  ];
  return (
    <EcDataGrid
      autoHeight={true}
      rowSelection
      columns={inviteColumns}
      rows={_sentInvites.map((r) => ({
        ...r,
        sentDate: formatDate(r.dateSent, 'M/dd/yyyy'),
        expirationDate: formatDate(r.expiryDate, 'M/dd/yyyy')
      }))}
      slots={{
        toolbar: EcGridTableToolbar
      }}
    />
  );
};

export default InvitesTabContent;
