import { Dispatch, SetStateAction } from 'react';
import { CareerServicesExtern } from '@api/models/careerServicesApi.models';
import { Codebook } from '@api/models/codebook.models';
import { createCustomContext } from '@common/context/customContext';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { SelectChangeEvent } from '@mui/material';

export enum BatchActionModalType {
  ASSIGN = 'Assign',
  EMAIL = 'Email',
  DOWNLOAD = 'Download',
  SEND_SURVEY = 'Send Survey',
  ATTESTATION = 'Attestation',
  PILOT_PROGRAM = 'Enable Pilot Program'
}

export type DataGridDataType = CareerServicesExtern;

export const handleUpdateGridData = <T extends DataGridDataType>(
  updatedData: T[],
  setStateFn: Dispatch<SetStateAction<T[]>>
): void => {
  setStateFn((prev) => {
    return prev.map((item) => {
      const updatedExtern = updatedData.find((newExtern) => {
        // Enrollment rows will appear more than once when the student is a 12 weeker
        // Student can have 2 externship courses for the same enrollment externship begin date
        // The course start date will differ but externship start date that is tied to the student
        // will be based on the first externshp course for the enrollment
        return (
          newExtern.enrollmentId === item.enrollmentId &&
          newExtern.courseCode === item.courseCode &&
          newExtern.externshipId === item.externshipId
        );
      });

      return updatedExtern ?? item;
    });
  });
};

interface BatchActionsContext {
  actions: Codebook[];
  actionSelectLabel: string;
  rowSelectionModel: GridRowSelectionModel;
  actionBtnText: string;
  visible?: boolean;
  modalOpen?: BatchActionModalType;
  selectedAction?: Codebook;
  refetchOnModalClose?: boolean;
  handleOpenModal: (actionVal?: number) => void;
  handleCloseModal: () => void;
  handleSetSelectedAction: (e?: SelectChangeEvent<number>) => void;
  refetch: (refresh?: boolean) => void;
  updateDataGridData: (data: any[]) => void;
  // Used for optional callback when submitting from modal
  onSubmitCallback?: () => void;
}

export const [useBatchActionsContext, BatchActionsContextProvider] =
  createCustomContext<BatchActionsContext>();
