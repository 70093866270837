import * as React from 'react';
import ResumeSection from './ResumeSection';
import theme from '@themes/ui/escoffier';

interface Props {
  label: string;
  hidden?: boolean;
}

const ResumeStatementSection: React.FC<Props> = (props) => {
  const { label, hidden } = props;
  return (
    <ResumeSection title="Personal Statement" indent>
      {!hidden && (
        <div
          style={{ ...theme.typography.EC_TYPE_3XS }}
          dangerouslySetInnerHTML={{
            __html: label
          }}
          data-testid="resume-statement-section-statement"
        />
      )}
    </ResumeSection>
  );
};

export default ResumeStatementSection;
