import * as React from 'react';
import {
  CompanyBenefitGroups,
  CompanyBenefitsGetRequest
} from '@api/models/companyApi.models';
import { FormProvider, useForm } from 'react-hook-form';
import IncompleteProfileModal, {
  ModalProps
} from '@components/PreviewPageComponents/IncompleteProfileModal';
import {
  redirectStorageKeys,
  redirectStorageMessages
} from '@common/helpers/storage';
import { useHistory, useParams } from 'react-router-dom';
import { ApiResponse } from '@api/models/base.models';
import { CompanyApi } from '@api/Company.api';
import CompanyLogoHeader from '@components/CompanyLogoHeader';
import FormActionsComponent from '@components/Forms/FormActionsComponent';
import { getEmployerPathWithParamsId } from '@common/helpers/getPathWithParamsId';
import { getSavedRTEContent } from '@components/Forms/getSavedRTEContent';
import IdealCandidateStep from '@pages/Employer/CreateJobPostPage/IdealCandidateStep';
import JobBenefitsStep from '@pages/Employer/CreateJobPostPage/JobBenefitsStep';
import JobDetailsStep from '@pages/Employer/CreateJobPostPage/JobDetailsStep';
import { JobPost } from '@api/models/singleJobPostApi.models';
import { JobPostApi } from '@api/JobPost.api';
import { JobPostCreateReq } from '@api/models/singleJobPostApi.models';
import PageDescriptionComponent from '@components/Layout/Common/PageDescriptionComponent';
import PageFormLayoutComponent from '@components/Layout/PageFormLayoutComponent';
import { scrollToError } from '@common/helpers/scrollToError';
import ScrollToTopOnMount from '@components/ScrollToTopOnMount';
import { strippedText } from '@common/helpers/strippedText';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchCompanyProfile } from '@common/fetches/useFetchCompanyProfile';
import { useFetchJobPostById } from '@common/fetches/useFetchJobPostById';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';
import { useTagManager } from '@common/hooks/useTagManager';

const EMPLOYER_MODAL_SUBTITLE =
  'Your organization needs to create their company profile before posting a new job!';
const ADMIN_MODAL_SUBTITLE =
  'This organization has an incomplete profile. Please create their company profile before posting a new job.';

interface IParams {
  jobId: string;
  companyId: string;
}

type GroupedBenefits = {
  companyHealthInsurance?: string[];
  companyProfDevelopment?: string[];
  companyRetirement?: string[];
};

type CreateJobPostModalProps = Omit<ModalProps, 'open' | 'handleClose'>;

const _formOrder = {
  jobTitle: 1,
  jobType: 2,
  workLearnEligible: 3,
  locations: 4,
  compensationType: 5,
  compensationDetails: 6,
  jobSegments: 7,
  jobSpecialties: 8,
  jobCardDescription: 9,
  jobDescription: 10,
  healthWellnessBenefits: 11,
  retirementBenefits: 12,
  professionalDevBenefits: 13,
  threeKeyBenefits: 14,
  experienceLevel: 15,
  technicalSkills: 16,
  softSkills: 17
};

const formOrderAtsWorkaround = {
  jobTitle: 1,
  jobType: 2,
  workLearnEligible: 3,
  locations: 4,
  compensationType: 5,
  compensationDetails: 6,
  jobSegments: 7,
  jobSpecialties: 8,
  jobCardDescription: 9,
  jobDescription: 10,
  externalJobPost: 11,
  healthWellnessBenefits: 12,
  retirementBenefits: 13,
  professionalDevBenefits: 14,
  threeKeyBenefits: 15,
  experienceLevel: 16,
  technicalSkills: 17,
  softSkills: 18
};

const CreateJobPostPage: React.FC = () => {
  const { sendToGtm } = useTagManager();
  const history = useHistory();
  const { isEcAdmin, isAdmin, isEmployer: _isEmployer } = useKeycloakContext();

  const { companyId, paramsId } = useParamsOrStoreCompanyId();
  const { jobId } = useParams<IParams>();

  const { companyProfile, profileComplete } = useFetchCompanyProfile(companyId);
  const [savedBenefits, setSavedBenefits] =
    React.useState<CompanyBenefitsGetRequest>([]);

  const [showIncompleteProfileModal, setShowIncompleteProfileModal] =
    React.useState<boolean>(false);
  const [incompleteProfileModalProps, setIncompleteProfileModalProps] =
    React.useState<CreateJobPostModalProps>();

  const featureFlags = useFeatureFlags();

  const isEditingJobPost: boolean = React.useMemo(() => {
    return window.location.pathname.includes('/edit');
  }, [window.location.pathname]);

  // Fetch saved job post if editing
  const jobPost = isEditingJobPost ? useFetchJobPostById(jobId).jobPost : null;

  const isEmployer = _isEmployer || isAdmin || isEcAdmin;

  const modalElements = [
    {
      title: 'Ready to create a job post?',
      subtitle: paramsId ? ADMIN_MODAL_SUBTITLE : EMPLOYER_MODAL_SUBTITLE,
      primaryButtonText: 'Create Profile',
      primaryClickLocation: getEmployerPathWithParamsId(
        '/profile-setup',
        paramsId
      )
    },
    {
      title: 'Ready to create a job post?',
      subtitle:
        'Your organization needs to complete their company profile. Contact your administrator to get started!',
      primaryButtonText: 'Return to EConnect',
      primaryClickLocation: '/econnect'
    }
  ];

  const handleCloseIncompleteProfileModal = React.useCallback((): void => {
    const path = getEmployerPathWithParamsId('/dashboard', paramsId);
    setShowIncompleteProfileModal(false);
    history.push(path);
  }, [setShowIncompleteProfileModal]);

  React.useEffect(() => {
    // User signed in with incomplete profile
    if (!profileComplete) {
      setShowIncompleteProfileModal(true);
      setIncompleteProfileModalProps({
        title: isEmployer ? modalElements[0].title : modalElements[1].title,
        subtitle: isEmployer
          ? modalElements[0].subtitle
          : modalElements[1].subtitle,
        isEmployerProfileComplete: profileComplete ?? false,
        localStorageMessage:
          redirectStorageMessages.INCOMPLETE_PROFILE_EMPLOYER,
        localStorageKey: redirectStorageKeys.CREATE_JOB_POST,
        primaryButtonText: isEmployer
          ? modalElements[0].primaryButtonText
          : modalElements[1].primaryButtonText,
        secondaryButtonText: 'Cancel',
        primaryClickLocation: isEmployer
          ? modalElements[0].primaryClickLocation
          : modalElements[1].primaryClickLocation,
        secondaryClick: handleCloseIncompleteProfileModal
      });
    } else {
      setShowIncompleteProfileModal(false);
      localStorage.removeItem(redirectStorageKeys.CREATE_JOB_POST);
    }
  }, [profileComplete, companyProfile]);

  // Fetch and set the employers specified benefits
  React.useEffect(() => {
    if (companyId) {
      const fetchBenefits = async (): Promise<void> => {
        try {
          const res: ApiResponse<CompanyBenefitsGetRequest> =
            await CompanyApi.getCompanyBenefitsById(companyId);

          setSavedBenefits(res.data);
        } catch (error: any) {
          console.error(
            'Error for CompanyApi.getCompanyBenefitsById() at Create Job Post Page',
            error
          );
        }
      };
      fetchBenefits();
    }
  }, [companyId]);

  const filterBenefits = React.useMemo(() => {
    const benefits: GroupedBenefits = Object.values(
      CompanyBenefitGroups
    ).reduce((obj, group) => {
      obj[group] = savedBenefits
        .filter((benefit) => benefit.benefitsGroup === group)
        .map((benefit) => benefit.benefitsValue);
      return obj;
    }, {});
    return {
      companyHealthInsurance: benefits?.companyHealthInsurance || [],
      companyProfDevelopment: benefits?.companyProfDevelopment || [],
      companyRetirement: benefits?.companyRetirement || []
    };
  }, [savedBenefits]);

  const { companyHealthInsurance, companyProfDevelopment, companyRetirement } =
    filterBenefits;

  const jobDescriptionContent: string = React.useMemo(() => {
    if (!jobPost) return '';

    return getSavedRTEContent(jobPost.jobDescription);
  }, [jobPost]);

  const methods = useForm<JobPost>({
    defaultValues: {
      jobTitle: '',
      jobType: [],
      workLearnEligible: '',
      expirationDate: null,
      keepOpen: true,
      locations: [],
      compensationType: [],
      compensationDetails: '',
      jobSegments: [],
      jobSpecialties: [],
      jobCardDescription: '',
      jobDescription: '',
      externalJobPost: '',
      healthWellnessBenefits: companyHealthInsurance,
      retirementBenefits: companyRetirement,
      professionalDevBenefits: companyProfDevelopment,
      experienceLevel: '',
      technicalSkills: [],
      softSkills: [],
      threeKeyBenefits: []
    }
  });

  const formOrder = featureFlags.ATS_WORKAROUND
    ? formOrderAtsWorkaround
    : _formOrder;

  const { handleSubmit, reset, setValue, getValues, watch, setError, ...rest } =
    methods;

  const allDetailsWatch = watch();
  const threeKeyBenefitsOptions = allDetailsWatch.healthWellnessBenefits
    .concat(allDetailsWatch.professionalDevBenefits)
    .concat(allDetailsWatch.retirementBenefits);

  // Fill inputs with data from back end when editing
  React.useEffect(() => {
    if (jobPost) {
      const { jobType, jobSegments, jobSpecialties } = jobPost;

      reset({
        ...jobPost,
        jobType: [...jobType],
        jobSegments: jobSegments || '',
        jobSpecialties: jobSpecialties,
        jobDescription: jobDescriptionContent
      });
    }
  }, [jobPost]);

  // Handle benefits tags from employer profile
  React.useEffect(() => {
    if (!isEditingJobPost && savedBenefits) {
      const {
        companyHealthInsurance,
        companyRetirement,
        companyProfDevelopment
      } = filterBenefits;
      // @ts-ignore
      // there is a circular dependency due to TS not be able to compute deep types/interfaces
      // https://github.com/microsoft/TypeScript/issues/30188#issuecomment-478938437
      setValue('healthWellnessBenefits', companyHealthInsurance);

      setValue('retirementBenefits', companyRetirement);
      setValue('professionalDevBenefits', companyProfDevelopment);
    }
  }, [savedBenefits, isEditingJobPost]);

  const pageTitle: string = React.useMemo(() => {
    if (isEditingJobPost && jobPost) {
      return `Editing Job Post: ${jobPost.jobTitle}`;
    } else {
      return 'Create a Job Post';
    }
  }, [isEditingJobPost, jobPost]);

  const setCustomError = (field, message): void => {
    setError(field, {
      type: 'manual',
      message: message
    });
  };

  const handleInvalidForm = (): void => {
    if (strippedText(getValues().jobDescription) === '') {
      setCustomError('jobDescription', 'Job Description is required');
    }
    scrollToError(methods.formState.errors, formOrder);
  };

  const removeFromArray = (original, remove): any => {
    return original.filter((value) => !remove.includes(value));
  };

  const expirationData = React.useMemo(() => {
    let keepOpen: boolean = true;
    let expiration: Date | null = null;
    if (featureFlags.COMPANY_PARTNERSHIP_LEVELS && companyProfile) {
      const { partnershipLevelPermissions: perms } = companyProfile;

      if (perms.JOB_POST_EXPIRATION_DAYS > 0) {
        const date = new Date();
        date.setDate(date.getDate() + perms.JOB_POST_EXPIRATION_DAYS);

        expiration = date;
        keepOpen = false;
      }
    }

    return { keepOpen, expiration };
  }, [companyProfile, featureFlags.COMPANY_PARTNERSHIP_LEVELS]);

  const handleValidForm = async (
    data: JobPost,
    onSuccess: () => void
  ): Promise<void> => {
    let extraErrors = false;

    if (strippedText(data.jobDescription) === '') {
      extraErrors = true;
      setCustomError('jobDescription', 'Job Description is required');
    }

    if (extraErrors) {
      scrollToError(methods.formState.errors, formOrder);
      return;
    }

    const filteringBenefits = data.threeKeyBenefits.filter(
      (item) => !threeKeyBenefitsOptions.includes(item)
    );

    const threeKeyBenefitsFiltered = removeFromArray(
      data.threeKeyBenefits,
      filteringBenefits
    );

    const postBody: JobPostCreateReq = {
      companyId,
      jobTitle: data.jobTitle,
      jobType: data.jobType,
      workLearnEligible:
        data.workLearnEligible !== '' ? data.workLearnEligible : 'No',
      // TODO: passing null for expirationDate and true for keepOpen. These are no longer used
      expirationDate: expirationData.expiration,
      keepOpen: expirationData.keepOpen,
      locations: data.locations,
      compensationType: data.compensationType,
      compensationDetails: data.compensationDetails,
      jobSegments: data.jobSegments.map((segment) => segment.value),
      jobSpecialties: data.jobSpecialties.map((specialty) => specialty.value),
      jobCardDescription: data.jobCardDescription,
      externalJobPost: data.externalJobPost,
      jobDescription: data.jobDescription,
      healthWellnessBenefits: data.healthWellnessBenefits,
      retirementBenefits: data.retirementBenefits,
      professionalDevBenefits: data.professionalDevBenefits,
      experienceLevel: data.experienceLevel,
      technicalSkills: data.technicalSkills?.map((skill) => skill.value),
      softSkills: data.softSkills?.map((skill) => skill.value),
      visibleOnMarketplace: true,
      threeKeyBenefits: threeKeyBenefitsFiltered
    };

    try {
      let jobPostResponse;
      if (isEditingJobPost) {
        jobPostResponse = await JobPostApi.updateJobPost(
          parseInt(jobId),
          postBody
        );
      } else {
        jobPostResponse = await JobPostApi.createJobPost(postBody);

        sendToGtm('job_post_created', {
          work_and_learn: data.workLearnEligible,
          experience_level: data.experienceLevel
        });
      }

      const currentCreatedJobPostId = jobPostResponse.data.id;
      history.push(`/job/${currentCreatedJobPostId}`);
      onSuccess();
    } catch (error: any) {
      console.error('Error for CreateJobPost', error);
      window.scrollTo(0, 0);
    }
  };

  const handleFormSubmit = (onSuccess: () => void): void => {
    const onValid = async (data): Promise<void> =>
      handleValidForm(data, onSuccess);
    handleSubmit(onValid, handleInvalidForm)();
  };

  return (
    <>
      <ScrollToTopOnMount />
      <PageFormLayoutComponent
        headerContent={<CompanyLogoHeader company={companyProfile} />}
        sideContent={
          <>
            <PageDescriptionComponent title={pageTitle}>
              Provide basic information about the role, and identify which
              technical and soft skills that your ideal candidate should have.
            </PageDescriptionComponent>
            <PageDescriptionComponent>
              To learn more about bulk upload items, please reach out to us at{' '}
              <a href="mailto:partnerships@escoffier.edu">
                partnerships@escoffier.edu
              </a>
            </PageDescriptionComponent>
          </>
        }
        content={
          <>
            <FormProvider {...methods} {...rest}>
              <JobDetailsStep
                defaultJobDescription={jobDescriptionContent}
                workLearnPartner={companyProfile?.workLearnPartner}
              />
              <JobBenefitsStep
                healthWellnessOptions={companyHealthInsurance}
                retirementOptions={companyRetirement}
                professionalDevOptions={companyProfDevelopment}
                threeKeyBenefitsOptions={threeKeyBenefitsOptions}
              />
              <IdealCandidateStep />
            </FormProvider>
          </>
        }
        actions={
          <FormActionsComponent
            hidePreviousBtn
            onSubmit={handleFormSubmit}
            submitBtnText="Save and Preview"
          />
        }
      />
      {showIncompleteProfileModal && companyProfile && (
        <IncompleteProfileModal
          open={showIncompleteProfileModal}
          handleClose={handleCloseIncompleteProfileModal}
          {...incompleteProfileModalProps!}
        />
      )}
    </>
  );
};

export default CreateJobPostPage;
