import * as React from 'react';
import { Box } from '@mui/material';

const ColoredBox: React.FC<{ color: string }> = ({ color = 'blue' }) => {
  return (
    <Box
      sx={{
        height: '25px',
        width: '25px',
        backgroundColor: color,
        borderRadius: '4px',
        marginRight: '8px'
      }}
    />
  );
};

export default ColoredBox;
