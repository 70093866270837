/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  Box,
  ClickAwayListener,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
  Typography,
  useTheme
} from '@mui/material';
import { SortOrderType } from '@interfaces/search.interfaces';
import { styled } from '@mui/system';

// TODO: Add unit tests

const Styled = {
  PopperBox: styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.WHITE.main,
    boxShadow: '0px 2px 2px 0px #474747',
    border: `1px solid ${theme.palette.GRAY_2.main}`,
    borderRadius: '4px',
    overflow: 'hidden',
    padding: '16px'
  }))
};

type SortMenuButton = {
  label: string;
  id: string;
};

interface MenuButtonProps {
  label: string;
  id: string;
  isActive: boolean;
  onSortClick: (event: React.MouseEvent<unknown>, value: string) => void;
  closeMenu: () => void;
  sortOrder: SortOrderType;
}

const MenuButton = ({
  label,
  id,
  isActive,
  onSortClick,
  closeMenu,
  sortOrder
}: MenuButtonProps): React.ReactElement => {
  const theme = useTheme();

  const iconClass = isActive
    ? sortOrder === 'ASC'
      ? 'ri-arrow-up-line'
      : 'ri-arrow-down-line'
    : '';

  const handleSortClick =
    (category: string) =>
    (event: React.MouseEvent<unknown>): void => {
      onSortClick(event, category);
      closeMenu();
    };

  return (
    <ListItem
      button
      disablePadding
      onClick={handleSortClick(id)}
      alignItems="center"
      sx={{
        '&:hover': {
          backgroundColor: theme.palette.GRAY_2.main
        }
      }}
    >
      <ListItemButton>
        <ListItemIcon sx={{ minWidth: '30px' }}>
          <i className={iconClass} />
        </ListItemIcon>
        <ListItemText primary={label} sx={{ paddingLeft: 0 }} />
      </ListItemButton>
    </ListItem>
  );
};

interface Props {
  anchorEl: HTMLElement | null;
  buttons: SortMenuButton[];
  menuOpen: boolean;
  handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void;
  onSortClick: (event: React.MouseEvent<unknown>, category: string) => void;
  handleClickAway: () => void;
  sortCategory: string;
  sortOrder: SortOrderType;
  disabled?: boolean;
}

const SortMenuPopper = (props: Props): React.ReactElement => {
  const {
    anchorEl,
    buttons,
    menuOpen,
    handleClickAway,
    onSortClick,
    handleOpenMenu,
    sortCategory,
    sortOrder,
    disabled
  } = props;

  const theme = useTheme();

  const popperId = menuOpen ? 'sort-menu-popper' : undefined;

  return (
    <>
      <IconButton
        onClick={handleOpenMenu}
        disabled={disabled}
        sx={{ color: theme.palette.GRAY_3.main }}
      >
        <i className="ri-arrow-up-down-line" />
      </IconButton>
      <Popper
        id={popperId}
        open={menuOpen}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Styled.PopperBox>
            <Typography variant="EC_TYPE_2XL">Sort by</Typography>
            <List
              disablePadding
              aria-label="sort options"
              sx={{ width: '100%', maxWidth: '200px' }}
            >
              {buttons.map((btn) => (
                <MenuButton
                  key={btn.id}
                  label={btn.label}
                  id={btn.id}
                  onSortClick={onSortClick}
                  isActive={sortCategory === btn.id}
                  sortOrder={sortOrder}
                  closeMenu={handleClickAway}
                />
              ))}
            </List>
          </Styled.PopperBox>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default SortMenuPopper;
