import * as React from 'react';
import { Box, Link, LinkProps, TypographyProps, useTheme } from '@mui/material';
import { EscoffierLink } from './FooterLinks';
import { Link as RouterLink } from 'react-router-dom';

interface Props extends LinkProps {
  link: EscoffierLink;
  fireFooterEvent: (url: string, text: string) => void;
  callBack?: () => void;
  typography?: TypographyProps['variant'];
  fontSize?: string;
  lineHeight?: string;
}

const FooterLink: React.FC<Props> = ({
  link,
  fireFooterEvent,
  callBack,
  typography = 'EC_TYPE_BASE',
  fontSize = '16px',
  lineHeight = '21px',
  ...linkProps
}) => {
  const theme = useTheme();

  const handleClick = React.useCallback(() => {
    fireFooterEvent(link.url ?? '', link.title);

    if (callBack) callBack();
  }, [fireFooterEvent, callBack, link]);

  const LinkStyles = {
    typography,
    fontSize,
    lineHeight,
    '&:hover': {
      color: theme.palette.primary.main
    }
  };

  const RenderLink = React.useMemo(() => {
    if (link.url === null) {
      return (
        <Link
          {...linkProps}
          data-testid="footer-escoffier-link"
          onClick={handleClick}
          underline={linkProps.underline ?? 'none'}
          sx={{ ...LinkStyles, cursor: 'pointer' }}
        >
          {link.title}
        </Link>
      );
    }

    if (link.internal) {
      return (
        <Link
          {...linkProps}
          data-testid="footer-escoffier-link"
          component={RouterLink}
          to={link.url}
          onClick={handleClick}
          underline={linkProps.underline ?? 'none'}
          sx={LinkStyles}
        >
          {link.title}
        </Link>
      );
    } else {
      return (
        <Link
          {...linkProps}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClick}
          data-testid="footer-escoffier-link"
          component={'a'}
          underline={linkProps.underline ?? 'none'}
          href={link.url}
          sx={LinkStyles}
        >
          {link.title}
        </Link>
      );
    }
  }, [link, linkProps]);

  return <Box>{RenderLink}</Box>;
};

export default FooterLink;
