import * as React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';
import PageLayoutLeftSidebarSkeleton from '@components/Skeletons/PageLayoutLeftSidebarSkeleton';

const CARD_COUNT = 5;

const MainContent: JSX.Element = (
  <Box>
    <Skeleton sx={{ margin: '20px 0' }} />
    <Grid container spacing={2}>
      {Array(CARD_COUNT)
        .fill(0)
        .map((_, idx: number) => (
          <Grid item key={idx} xs={12}>
            <Skeleton height="90px" sx={{ transform: 'none' }} />
          </Grid>
        ))}
    </Grid>
  </Box>
);

const CandidateMarketplaceSkeleton = (): React.ReactElement => {
  return (
    <PageLayoutLeftSidebarSkeleton
      pageTitle="Talent Search"
      mainContent={MainContent}
    />
  );
};

export default CandidateMarketplaceSkeleton;
