import {
  FetchSearchResults,
  IFilterData,
  IFilterGroup,
  IFilterState,
  SearchCriteria,
  SearchResultType
} from '@api/models/searchApi.models';
import { useEffect, useState } from 'react';
import { IPageInfoRes } from '@interfaces/pageInfo.interfaces';
import { SearchApi } from '@api/Search.api';

const searchTypes = ['CandidateProfile', 'JobPost'] as const;
type SearchType = (typeof searchTypes)[number];

export type UseFetchSearchCriteria = {
  searchType: SearchType;
} & SearchCriteria;

export interface IExpandedFilters {
  [key: string]: boolean;
}

export const useFetchMarketplaceSearchResults = <T extends SearchResultType>(
  criteria: UseFetchSearchCriteria,
  DEFAULT_PAGE_NUMBER: number
): FetchSearchResults<T> => {
  const [searchData, setSearchData] = useState<T[]>([]);
  const [pageInfo, setPageInfo] = useState<IPageInfoRes>(criteria.pageInfo);
  const [filters, setFilters] = useState<IFilterGroup[]>([]);
  const [currentFilters, setCurrentFilters] = useState<IFilterData[]>([]);
  const [expandedFilters, setExpandedFilters] = useState<IExpandedFilters>({});
  const [filterFormState, setFilterFormState] = useState<IFilterState>({});
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const fetchSearchData = async <DataType extends T>(
    filterData: IFilterData[],
    pageInfo: IPageInfoRes | null = null
  ): Promise<void> => {
    const { searchType, ...searchCriteria } = criteria;

    if (searchType === 'CandidateProfile') {
      try {
        setLoading(true);
        searchCriteria.filters = filterData;
        if (pageInfo) searchCriteria.pageInfo = pageInfo;

        const res = await SearchApi.getCandidates(searchCriteria);
        setSearchData(res.data.results as DataType[]);
        setFilters(res.data.filterGroups);
        setPageInfo(res.data.pageInfo);
        setCurrentFilters(filterData);
        setLoaded(true);
      } catch (error: any) {
        console.error('Error for useFetchMarketplaceSearchResults()', error);
      } finally {
        setLoading(false);
      }
    } else if (searchType === 'JobPost') {
      try {
        setLoading(true);
        searchCriteria.filters = filterData;
        if (pageInfo) searchCriteria.pageInfo = pageInfo;

        const res = await SearchApi.getJobPosts(searchCriteria);
        setSearchData(res.data.results as T[]);
        setFilters(res.data.filterGroups);
        setPageInfo(res.data.pageInfo);
        setCurrentFilters(filterData);
        setLoaded(true);
      } catch (error: any) {
        console.error('Error for useFetchMarketplaceSearchResults()', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const resetSearchData = (): void => {
    fetchSearchData([]);
  };

  const goToPage = (pageNumber: number): void => {
    const newPageInfo = { ...pageInfo, currentPage: pageNumber };
    fetchSearchData(currentFilters, newPageInfo);
  };

  const sortResults = (event, category: string): void => {
    const isAsc =
      pageInfo.sortCategory === category && pageInfo.sortOrder === 'ASC';
    const data: IPageInfoRes = {
      ...pageInfo,
      currentPage: DEFAULT_PAGE_NUMBER,
      sortCategory: category,
      sortOrder: isAsc ? 'DESC' : 'ASC'
    };
    fetchSearchData(currentFilters, data);
  };

  const filterMarketplaceResults = (
    filterData: IFilterData[],
    resetExpandedFilters?: boolean
  ): void => {
    if (resetExpandedFilters) {
      setExpandedFilters({});
    }

    fetchSearchData(filterData);
  };

  const resetMarketplaceResults = (resetExpandedFilters?: boolean): void => {
    if (resetExpandedFilters) {
      setExpandedFilters({});
    }

    resetSearchData();
  };

  useEffect(() => {
    fetchSearchData(criteria.filters, criteria.pageInfo);
  }, []);

  return {
    searchData,
    pageInfo,
    filters,
    loading,
    loaded,
    fetchSearchData,
    filterMarketplaceResults,
    resetMarketplaceResults,
    expandedFilters,
    setExpandedFilters,
    filterFormState,
    setFilterFormState,
    sortResults,
    goToPage
  };
};
