import * as React from 'react';
import { useKeycloak } from '@react-keycloak/web';

export type FetchKeycloakUserProfile = {
  keycloakUserProfile: any;
  loaded: boolean;
  loading: boolean;
};

export const useFetchKeycloakUserProfile = (): FetchKeycloakUserProfile => {
  const { keycloak } = useKeycloak();

  const [keycloakUserProfile, setKeycloakUserProfile] = React.useState<any>([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchKeycloakUserProfile = async (): Promise<void> => {
    try {
      setLoading(true);
      const userProfile: any = await keycloak.loadUserProfile();

      setKeycloakUserProfile(userProfile);
      setLoaded(true);
    } catch (error: any) {
      console.error(
        'Error for useFetchKeycloakUserProfile -> keycloak.loadUserProfile()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    if (keycloak.authenticated) {
      fetchKeycloakUserProfile();
    }
  }, []);

  return {
    keycloakUserProfile,
    loaded,
    loading
  };
};
