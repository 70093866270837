import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';

const Styled = {
  SearchIcon: styled(IconButton)(({ theme }) => ({
    padding: 0,
    margin: '24px',
    color: theme.palette.GOLD_1.main
  }))
};

const EsourceNavSearch: React.FC = () => {
  const featureFlags = useFeatureFlags();

  const history = useHistory();
  const location = useLocation();

  const handleSearchClick = (): void => {
    // Might need fixing, could not navigate with sendToGtm
    if (history.location.pathname.includes('learning-center/')) {
      const currentPath = history.location.pathname;
      const newPath = currentPath.replace(
        /learning-center\/(.*)/,
        'learning-center' + '-search'
      );

      history.push(newPath);
    } else {
      history.push('learning-center-search');
    }
  };

  if (
    featureFlags.ESOURCE_SEARCH &&
    location.pathname.includes('learning-center')
  ) {
    return (
      <Styled.SearchIcon
        data-testid="search-menu-icon"
        onClick={handleSearchClick}
      >
        <i className="ri-search-line"></i>
      </Styled.SearchIcon>
    );
  } else {
    return <></>;
  }
};

export default EsourceNavSearch;
