/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { ExternshipApi } from '@api/Externships.api';

export type FetchExternshipStartDates = {
  externshipDates: Date[];
  externshipDatesByYear: { [key: string]: Date[] };
  years: string[];
  savedExternshipDate?: Date;
  loaded: boolean;
  loading: boolean;
};

export type DateFetchType = 'START_DATES' | 'GRAD_DATES';

export const useFetchExternshipDates = (
  dateFetchType: DateFetchType | undefined = 'START_DATES'
): FetchExternshipStartDates => {
  const [externshipDates, setExternshipDates] = React.useState<Date[]>([]);
  const [externshipDatesByYear, setExternshipDatesByYear] = React.useState<{
    [key: string]: Date[];
  }>({});
  const [years, setYears] = React.useState<string[]>([]);

  const [savedExternshipDate, setSavedExternshipDate] = React.useState<Date>();

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const getStartDateMapByYearAndSetYears = (
    dates: Date[]
  ): { [key: string]: Date[] } => {
    const startDateMapByYear = {};

    dates.forEach((date) => {
      const year = date.getFullYear();
      if (startDateMapByYear[year]) {
        startDateMapByYear[year].push(date);
      } else {
        startDateMapByYear[year] = [date];
      }
    });

    const years = Object.keys(startDateMapByYear).sort();
    setYears(years);

    return startDateMapByYear;
  };

  const fetchExternshipStartDates = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await ExternshipApi.getExternshipStartDates();

      const dates = res.dates.map((d) => new Date(d));
      setExternshipDates(dates);
      setExternshipDatesByYear(getStartDateMapByYearAndSetYears(dates));

      if (res.externshipTrackerDatePreference) {
        setSavedExternshipDate(new Date(res.externshipTrackerDatePreference));
      }

      setLoaded(true);
    } catch (error: any) {
      console.error(
        'Error for useFetchExternshipDates -> ExternshipApi.getExternshipStartDates()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  const getGradDateMapByYearAndSetYears = (
    dates: Date[]
  ): { [key: string]: Date[] } => {
    const gradDateMapByYear = {};

    dates.forEach((date) => {
      const year = date.getFullYear();
      if (gradDateMapByYear[year]) {
        gradDateMapByYear[year].push(date);
      } else {
        gradDateMapByYear[year] = [date];
      }
    });

    const years = Object.keys(gradDateMapByYear).sort();
    setYears(years);

    return gradDateMapByYear;
  };

  const fetchExternshipGradDates = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await ExternshipApi.getExternshipGradDates();

      const dates = res.map((d) => new Date(d));
      setExternshipDates(dates);
      setExternshipDatesByYear(getGradDateMapByYearAndSetYears(dates));
    } catch (error: any) {
      console.error(
        'Error for useFetchExternshipDates -> ExternshipApi.getExternshipGradDates()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    if (dateFetchType === 'START_DATES') {
      fetchExternshipStartDates();
    } else if (dateFetchType === 'GRAD_DATES') {
      fetchExternshipGradDates();
    }
  }, [dateFetchType]);

  return {
    externshipDates,
    externshipDatesByYear,
    savedExternshipDate,
    years,
    loaded,
    loading
  };
};
