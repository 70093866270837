import * as React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import country from 'country-list-js';
import { Grid } from '@mui/material';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import ReactHookFormsSelect from './ReactHookFormsSelect';

interface Props {
  methods?: UseFormReturn<any, any>;
}

const RhfLocationCustom: React.FC<Props> = ({ methods }) => {
  const selectedCountry = methods && methods.watch('country');
  const internationalAddress =
    selectedCountry !== undefined && selectedCountry !== 'United States';

  const countryNames = country.names().sort();
  const countryArray = countryNames.map((item) => ({
    value: item,
    label: item
  }));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <ReactHookFormsInput
          name="streetNumber"
          label="Street Number"
          rules={{ required: 'Street Number is required' }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ReactHookFormsInput
          name="streetName"
          label="Street Name"
          rules={{ required: 'Street Name is required' }}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <ReactHookFormsInput
          name="addressLineTwo"
          label="Suite or Unit (Optional)"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ReactHookFormsInput
          name="city"
          label="City"
          rules={{ required: 'City is required' }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ReactHookFormsInput
          name="state"
          label={internationalAddress ? 'Province' : 'State'}
          rules={{
            required: !internationalAddress ? 'State is required' : false
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ReactHookFormsInput
          name="postalCode"
          label="Postal Code"
          rules={{ required: 'Postal Code is required' }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="country"
          defaultValue="United States"
          render={({ field }): JSX.Element => (
            <ReactHookFormsSelect
              {...field}
              label="Country"
              options={countryArray}
              placeholder="Select a country"
            />
          )}
          rules={{ required: 'Country is required' }}
        />
      </Grid>
    </Grid>
  );
};

export default RhfLocationCustom;
