import * as React from 'react';
import { Box, Typography } from '@mui/material';
import {
  CandidateCareerInfo,
  CareerPathTag,
  CareerRole,
  PartnerEmployeeCareerInfo
} from '@interfaces/careerJourney.interfaces';
import { Codebook, SkillCodebook } from '@api/models/codebook.models';
import { getTagArray } from '@common/helpers/getCareerPathTagArray';
import IncompleteProfileCTA from '@pages/Candidate/CareerPathPage/MySkills/IncompleteProfileCTA';
import { mediaQueries } from '@themes/ui/variables';
import MySkillsCardHeader from '@pages/Candidate/CareerPathPage/MySkills/MySkillsCardHeader';
import Skill from '@components/Skill';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    backgroundColor: theme.palette.WHITE.main,
    padding: '0 10px',
    overflow: 'hidden',
    boxShadow: '0 4px 4px rgba(0,0,0,0.25)',
    borderRadius: '4px',
    [mediaQueries.MIN_WIDTH_361]: {
      padding: '0 16px'
    },
    [mediaQueries.MIN_WIDTH_481]: {
      padding: '0 32px'
    },
    [mediaQueries.MIN_WIDTH_601]: {
      boxShadow: 'none',
      border: `3px solid ${theme.palette.GOLD_1.main}`,
      borderRadius: '10px',
      padding: '32px'
    }
  })),
  Layout: styled(Box)({
    display: 'flex',
    flexFlow: 'column wrap',
    paddingBottom: '60px',
    width: '100%',
    columnGap: '24px',
    ['@media (min-width:521px)']: {
      flexFlow: 'row wrap'
    },
    [mediaQueries.MIN_WIDTH_601]: {
      paddingBottom: 'unset'
    }
  }),
  Content: styled(Box)({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginTop: '16px',
    '& .section-title': {
      marginBottom: '16px'
    }
  }),
  Section: styled(Box)({
    display: 'block'
  }),
  SkillsContainer: styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '16px',
    margin: '10px 0',
    ['@media (min-width:521px)']: {
      marginTop: '20px',
      marginBottom: '24px'
    }
  }),
  SkillBox: styled(Box)({
    width: 'max-content'
  })
};

interface MySkillsCardProps {
  profileComplete: boolean;
  careerInfo?: CandidateCareerInfo | PartnerEmployeeCareerInfo;
  requiredSkills: SkillCodebook[];
  prerequisites: CareerRole[];
  certifications: Codebook[];
  isPartnerEmployee?: boolean;
}

interface TagSectionProps {
  sectionTitle: string;
  tags: CareerPathTag[];
}

const TagSection = ({ sectionTitle, tags }: TagSectionProps): JSX.Element => {
  return (
    <>
      {!!tags.length && (
        <Styled.Section>
          <Typography variant="EC_TYPE_XL" className="section-title">
            {sectionTitle}
          </Typography>
          <Styled.SkillsContainer>
            {tags.map((tag, idx) => (
              <Styled.SkillBox
                key={idx}
                data-testid="career-goal-skills-container"
              >
                <Skill skill={tag.label} variant="GRAY" />
              </Styled.SkillBox>
            ))}
          </Styled.SkillsContainer>
        </Styled.Section>
      )}
    </>
  );
};

const MySkillsCard = ({
  profileComplete,
  careerInfo,
  requiredSkills,
  prerequisites,
  certifications,
  isPartnerEmployee = false
}: MySkillsCardProps): React.ReactElement => {
  const skillSections = [
    {
      sectionTitle: 'TECHNICAL SKILLS',
      tags: getTagArray(requiredSkills, careerInfo?.technicalSkills)
    },
    {
      sectionTitle: 'SOFT SKILLS',
      tags: getTagArray(requiredSkills, careerInfo?.softSkills)
    }
  ];

  const experienceSections = [
    {
      sectionTitle: 'EXPERIENCE',
      tags: getTagArray(
        prerequisites?.map((prereq) => ({
          value: prereq.id,
          label: prereq.name
        })),
        careerInfo?.workExperience.map((exp) => exp.position)
      )
    },
    {
      sectionTitle: 'EXPERIENCE',
      tags: getTagArray([], [])
    },
    {
      sectionTitle: 'CERTIFICATIONS',
      tags: getTagArray(certifications, careerInfo?.certifications)
    }
  ];

  return (
    <Styled.Root data-testid="career-goal-skill-card-root">
      <MySkillsCardHeader
        title="My Skills & Experience"
        showCTA={profileComplete}
        isPartnerEmployee={isPartnerEmployee}
      />
      {profileComplete ? (
        <Styled.Layout>
          <Styled.Content>
            {skillSections.map((section, idx) => (
              <React.Fragment key={idx}>
                <TagSection {...section} />
              </React.Fragment>
            ))}
          </Styled.Content>
          <Styled.Content>
            {experienceSections.map((section, idx) => (
              <React.Fragment key={idx}>
                <TagSection {...section} />
              </React.Fragment>
            ))}
          </Styled.Content>
        </Styled.Layout>
      ) : (
        <IncompleteProfileCTA />
      )}
    </Styled.Root>
  );
};

export default MySkillsCard;
