import * as React from 'react';
import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const Styled = {
  Text: styled(Typography)({
    zIndex: 20,
    textTransform: 'uppercase',
    marginBottom: 0
  })
};

const linkStyles = {
  padding: '24px 16px',
  display: 'flex',
  justifyContent: 'right',
  alignItems: 'flex-end',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  minHeight: '150px',
  position: 'relative',
  height: '100%',
  textAlign: 'right',
  '&:before': {
    content: '""',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)'
  }
};

export interface TileNavItemProps {
  img: string;
  text: string;
  path: string;
  isMobile?: boolean;
}

const TileNavItem: React.FC<TileNavItemProps> = ({
  img,
  text,
  path,
  isMobile
}) => {
  return (
    <Link
      component={RouterLink}
      to={path}
      underline="none"
      sx={{ ...linkStyles, backgroundImage: `url(${img})` }}
    >
      <Styled.Text
        variant={isMobile ? 'EC_TYPE_2XL' : 'EC_TYPE_3XL'}
        color={theme.palette.WHITE.main}
      >
        {text}
      </Styled.Text>
    </Link>
  );
};

export default TileNavItem;
