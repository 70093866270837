/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  AttestationStatus,
  AttestationType
} from '@interfaces/attestation.interface';
import { Box, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { AttestationApi } from '@api/Attestations.api';
import AttestationBase from './AttestationBase';
import AttestationEmploymentInfoSection from './AttestationEmploymentInfoSection';
import AttestationHtmlForPdf from '@pages/Candidate/ExternshipAttestationPage/AttestationHtmlForPdf';
import { getPdfHtmlByElementId } from '@common/helpers/pdfHelpers';
import { GRAY_F4 } from '@themes/ui/escoffier';
import NotFoundPage from '@pages/Errors/NotFoundPage';
import { PdfTypes } from '@api/models/pdfApi.models';
import ReactHookFormsSelect from '@components/Forms/ReactHookFormsSelect';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { styled } from '@mui/system';
import { SubmitAttestationReq } from '@api/models/attestationApi.models';
import { useFetchAttestationEmploymentTypesCodebooks } from '@common/fetches/useFetchAttestationEmploymentTypesCodebooks';
import { useFetchAttestationWaiverTypesCodebooks } from '@common/fetches/useFetchAttestationWaiverTypesCodebooks';
import { useFetchExternships } from '@common/fetches/useFetchExternships';

const Styled = {
  SavingBox: styled(Box)({
    position: 'relative' as 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: '540px',
    margin: '0 auto',
    borderRadius: '4px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
  })
};

export interface AttestationFormValues {
  studentNumber: string;
  firstName: string;
  lastName: string;
  companyName?: string;
  jobTitle?: string;
  employmentStartDate?: Date;
  employmentType?: number;
  waiverType?: number;
  signature: string;
}

type FormValueState = SubmitAttestationReq & { html?: string };

const ExternshipAttestationPage: React.FC = () => {
  const [saving, setSaving] = React.useState(false);
  const [formValues, setFormValues] =
    React.useState<Omit<FormValueState, 'completedAt'>>();
  const [signatureError, setSignatureError] = React.useState<string>();
  const sigRef = React.useRef<any>();

  const history = useHistory();

  const { externshipId } = useParams<{
    externshipId?: string;
  }>();

  const {
    externshipById: externship,
    loaded: externshipLoaded,
    loading: externshipLoading
  } = useFetchExternships(undefined, externshipId);

  const { attestationEmploymentTypes } =
    useFetchAttestationEmploymentTypesCodebooks();

  const { attestationWaiverTypes } = useFetchAttestationWaiverTypesCodebooks();

  const methods = useForm<AttestationFormValues>({
    defaultValues: {
      studentNumber: '',
      firstName: '',
      lastName: '',
      signature: ''
    }
  });

  const { reset, handleSubmit } = methods;

  const attestationTitleMap = {
    [AttestationType.FULL_TIME]: 'Full-Time Placement Attestation',
    [AttestationType.PART_TIME]: 'Part-time or Temporary Placement Attestation',
    [AttestationType.SELF_EMPLOYMENT]: 'Self-Employment Attestation',
    [AttestationType.PLACEMENT_WAIVER]: 'Placement Assistance Waiver'
  };

  const getAttestationTitle = (attestationType?: AttestationType): string =>
    attestationTitleMap[attestationType || ''] || '';

  const hasEmploymentInfoSection = [
    AttestationType.FULL_TIME,
    AttestationType.PART_TIME,
    AttestationType.SELF_EMPLOYMENT
  ];

  const attestationComplete =
    externship?.attestationStatus === AttestationStatus.COMPLETED;

  const attestationType = externship?.attestation?.type.value;

  const includeEmploymentInfoSection = hasEmploymentInfoSection.includes(
    attestationType as AttestationType
  );

  const includeEmploymentType = attestationType === AttestationType.PART_TIME;

  const includeWaiverTypeField =
    attestationType === AttestationType.PLACEMENT_WAIVER;

  const isSelfEmployed = attestationType === AttestationType.SELF_EMPLOYMENT;

  const setInitialState = (): void => {
    reset({
      firstName: externship?.candidate.firstName || '',
      lastName: externship?.candidate.lastName || '',
      studentNumber: externship?.candidate.studentId || '',
      signature: externship?.attestation?.signature || '',
      ...(includeEmploymentInfoSection
        ? {
            companyName: externship?.company.name || '',
            jobTitle: externship?.jobTitle || ''
          }
        : {}),
      ...(includeEmploymentType
        ? {
            employmentType: externship?.attestation?.employmentType?.value
          }
        : {}),
      ...(includeWaiverTypeField
        ? {
            waiverType: externship?.attestation?.waiverType?.value
          }
        : {})
    });
  };

  // Set Default Values
  React.useEffect(() => {
    if (externship && externship.attestationStatus === AttestationStatus.SENT) {
      AttestationApi.markAsOpened(externship.id);
    }

    setInitialState();
  }, [externship]);

  // onValid sets saving to true which triggers submitAttestation() to call API
  const onValid = (data: AttestationFormValues): void => {
    const isSignatureEmpty: boolean = sigRef.current?.isEmpty();

    const formValues: Omit<SubmitAttestationReq, 'completedAt'> = {
      companyName: data.companyName,
      jobTitle: data.jobTitle,
      employmentStartDate: data.employmentStartDate,
      employmentType: data.employmentType,
      waiverType: data.waiverType
    };

    if (isSignatureEmpty) {
      setSignatureError('Signature is required');
    } else {
      const sigImg = sigRef.current?.getTrimmedCanvas().toDataURL('image/png');

      setSaving(true);
      setSignatureError('');
      setFormValues({ ...formValues, signature: sigImg });
    }
  };

  const submitAttestation = async (
    data: SubmitAttestationReq
  ): Promise<void> => {
    try {
      await AttestationApi.submitAttestation(Number(externshipId), data);
    } catch (error) {
      console.error(
        'Error for ExternshipAttestationPage -> AttestationsApi.submitAttestation()',
        error
      );
    } finally {
      setSaving(false);
      history.push('/candidate/dashboard');
    }
  };

  // Call api if the form is submitting
  React.useEffect(() => {
    if (saving && formValues) {
      const html = getPdfHtmlByElementId(PdfTypes.STUDENT_ATTESTATION);
      const completedAt = new Date().toISOString();

      submitAttestation({ ...formValues, html, completedAt });
    }
  }, [saving, formValues]);

  if (
    externshipLoaded &&
    !externshipLoading &&
    (!externship || !attestationType)
  ) {
    return <NotFoundPage />;
  }

  const attestationTitle = getAttestationTitle(attestationType);
  const signatureImage = sigRef.current?.getCanvas().toDataURL('image/png');

  return (
    <>
      <RootPageLayout
        bgColor={GRAY_F4}
        muiMaxWidth={false}
        loading={!externshipLoaded && externshipLoading}
      >
        <FormProvider {...methods}>
          {saving ? (
            <Styled.SavingBox>
              <AttestationHtmlForPdf
                attestationTitle={attestationTitle}
                attestationType={attestationType}
                signature={signatureImage}
              />
            </Styled.SavingBox>
          ) : (
            <AttestationBase
              title={attestationTitle}
              onSubmit={handleSubmit(onValid)}
              attestationType={attestationType}
              sigRef={sigRef}
              attestationComplete={attestationComplete}
              signatureImage={signatureImage}
              signatureError={signatureError}
            >
              {includeEmploymentInfoSection && (
                <AttestationEmploymentInfoSection
                  isSelfEmployed={isSelfEmployed}
                />
              )}
              {includeEmploymentType && (
                <Grid item xs={12}>
                  <ReactHookFormsSelect
                    name="employmentType"
                    label="Part time or temporary"
                    rules={{ required: 'Employment type is required.' }}
                    options={attestationEmploymentTypes}
                  />
                </Grid>
              )}
              {includeWaiverTypeField && (
                <>
                  <Grid item>
                    <Typography variant="AEGS_TYPE_PARAGRAPH">
                      For the reasons noted below, I have elected not to utilize
                      the services of the Career Services Department and have
                      waived my right to placement assistance.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ReactHookFormsSelect
                      name="waiverType"
                      label="Reason for waiver"
                      rules={{ required: 'Reason is required.' }}
                      options={attestationWaiverTypes}
                    />
                  </Grid>
                </>
              )}
            </AttestationBase>
          )}
        </FormProvider>
      </RootPageLayout>
    </>
  );
};

export default ExternshipAttestationPage;
