/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Button, Grid, Icon, Typography } from '@mui/material';
import {
  convertGlrcContentToGlrcVideo,
  convertGlrcContentToGlrcVideoWithMetadata
} from '@pages/Partner/GlrcContent/glrc.helpers';
import {
  GlrcContent,
  GlrcContentTypes,
  GlrcVideo
} from '@interfaces/glrc.interfaces';
import { Bookmark } from '@api/models/bookmarkApi.models';
import CourseBookmark from '@pages/Partner/CourseBookmark';
import GlrcEmbeddedVideo from '@pages/Partner/GlrcEmbeddedVideo';
import GlrcSection from '@pages/Partner/GlrcSection';
import { styled } from '@mui/system';
import { useFetchBookmarks } from '@common/fetches/useFetchBookmarks';

const Styled = {
  ContentWrapper: styled(Grid)({
    ['@media (max-width:600px)']: {
      margin: '0px 24px'
    }
  }),
  NoAccessText: styled(Typography)(({ theme }) => ({
    width: '100%',
    color: theme.palette.GRAY_3.main,
    margin: '48px 16px',
    textAlign: 'center'
  }))
};

interface Props {
  companyName?: string;
  userId: string;
  companyId: number;
}

interface GlrcEmbeddedVideoWrapperProps extends Props {
  video: GlrcContent;
  onBookmarkDeleted: (contentId: number) => void;
}

const GlrcEmbeddedVideoWrapper: React.FC<GlrcEmbeddedVideoWrapperProps> = ({
  video,
  companyName,
  userId,
  onBookmarkDeleted
}) => {
  const [_video, setVideo] = React.useState<GlrcVideo>(
    convertGlrcContentToGlrcVideo(video)
  );

  React.useEffect(() => {
    const getMetadata = async (): Promise<void> => {
      const videoWithMedatData =
        await convertGlrcContentToGlrcVideoWithMetadata(video);

      setVideo(videoWithMedatData);
    };
    getMetadata();
  }, []);

  return (
    <GlrcEmbeddedVideo
      {..._video}
      bookmarked
      bookmarkView
      companyName={companyName}
      userId={userId}
      onBookmarkDeleted={onBookmarkDeleted}
    />
  );
};

const MyBookmarksSection: React.FC<Props> = ({
  companyName,
  userId,
  companyId
}) => {
  const { bookmarks, setBookmarks } = useFetchBookmarks(userId, companyId);
  const [viewMore, setViewMore] = React.useState(false);
  const [displayedBookmarks, setDisplayedBookmarks] = React.useState<
    Bookmark[]
  >([]);

  React.useEffect(() => {
    if (viewMore) {
      setDisplayedBookmarks(bookmarks);
    } else {
      setDisplayedBookmarks(bookmarks.slice(0, 4));
    }
  }, [bookmarks, viewMore]);

  const onBookmarkDeleted = (contentId: number): void => {
    const updatedBookmarks = bookmarks.filter(
      (b) => b.content.id !== contentId
    );
    setBookmarks(updatedBookmarks);
  };

  const handleViewClick = (): void => {
    setViewMore(!viewMore);
  };

  const getViewMoreText = (): string => {
    if (viewMore) {
      return 'View Less';
    }

    return 'View More';
  };

  return (
    <GlrcSection
      title="My List"
      border
      action={
        <>
          {bookmarks.length > 4 && (
            <Button variant="text" color={'primary'} onClick={handleViewClick}>
              {getViewMoreText()}
            </Button>
          )}
        </>
      }
    >
      <Styled.ContentWrapper>
        <Grid container spacing={2}>
          {!displayedBookmarks.length && (
            <Styled.NoAccessText variant="EC_TYPE_LG">
              You don&apos;t have any content saved. Click on the{' '}
              <Icon fontSize="small" className="ri-bookmark-line" /> icon to
              bookmark a video or course for quick access here.
            </Styled.NoAccessText>
          )}
          {displayedBookmarks.map((b) => (
            <Grid key={b.id} item xs={12} sm={6} md={4} lg={3}>
              {b.content.contentType.value === GlrcContentTypes.VIDEO && (
                <GlrcEmbeddedVideoWrapper
                  video={b.content}
                  userId={userId}
                  companyName={companyName}
                  onBookmarkDeleted={onBookmarkDeleted}
                  companyId={companyId}
                />
              )}
              {b.content.contentType.value === GlrcContentTypes.COURSE && (
                <CourseBookmark
                  content={b.content}
                  userId={userId}
                  onBookmarkDeleted={onBookmarkDeleted}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Styled.ContentWrapper>
    </GlrcSection>
  );
};

export default MyBookmarksSection;
