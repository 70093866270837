import * as React from 'react';
import {
  ApproveTimesheetReq,
  ExternshipStatus,
  SaveTimesheet,
  Timesheet,
  TimesheetPerformanceItem,
  TimesheetPerformanceRating,
  TimesheetStatus
} from '@api/models/externshipApi.models';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import BasicModal from '@components/BasicModal';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { convertHoursAndMinsToMins } from '@common/helpers/timesheetHelpers/convertHoursAndMinsToMins';
import { convertMinutesToHoursAndMinutes } from '@common/helpers/timesheetHelpers/convertMinsToHoursAndMins';
import { ExternshipApi } from '@api/Externships.api';
import FormFieldErrorText from '@components/Forms/FormFieldErrorText';
import GoldenInfoBox from '@pages/Shared/TimesheetPage/GoldenInfoBox';
import ReactHookFormsRatingSelection from '@components/Forms/ReactHookFormsRatingSelection';
import ReactHookFormsTextarea from '@components/Forms/ReactHookFormsTextarea';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import TimesheetForm from '@pages/Shared/TimesheetPage/TimesheetForm';
import UndoChefTimesheetApprovalModal from '@pages/Shared/TimesheetPage/UndoChefTimesheetApprovalModal';
import { useKeycloakContext } from '@common/context/keycloakContext';

const Styled = {
  Root: styled(Box)({
    display: 'flex',
    gap: '16px',
    ['@media (max-width: 1100px)']: {
      flexDirection: 'column'
    }
  }),
  RightContent: styled(Box)({
    display: 'flex',
    columnGap: '16px',
    ['@media (max-width: 1100px)']: {
      justifyContent: 'flex-end'
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between'
    }
  }),
  ButtonWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'justifyContentCenter'
  })<{ justifyContentCenter?: boolean }>(({ justifyContentCenter }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: justifyContentCenter ? 'center' : 'space-between',
    width: '90px'
  })),
  TimesheetWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isMobile'
  })<{ isMobile: boolean }>(({ isMobile }) => ({
    display: 'flex',
    gap: '16px',
    flexDirection: isMobile ? 'column' : 'row'
  })),
  GridItem: styled(Grid)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  })
};

interface TimesheetAccordionProps {
  timesheet: Timesheet;
  updateTimesheets: (timesheet: Timesheet) => void;
  setTotalHours: React.Dispatch<React.SetStateAction<string>>;
  isApproverViewing: boolean;
  isCandidateViewing: boolean;
  timesheetPerformanceItems: TimesheetPerformanceItem[];
  careerServiceView?: boolean;
  externshipComplete?: boolean;
}

interface TimesheetAccordionFormValues {
  submitNotes: string;
  comments: string;
  instructorComments: string;
  dailyEntries: { date: string; time: string }[];
  performanceReview: TimesheetPerformanceRating[];
}

const TimesheetAccordionContent: React.FC<TimesheetAccordionProps> = ({
  timesheet,
  setTotalHours,
  updateTimesheets,
  isApproverViewing,
  isCandidateViewing,
  timesheetPerformanceItems,
  careerServiceView
}) => {
  const { isEcAdmin, isEmployer, isRecruiter, isChefInstructor, isCandidate } = useKeycloakContext();
  const [openSubmitModal, setOpenSubmitModal] = React.useState(false);
  const [openUndoChefApprovalModal, setOpenUndoChefApprovalModal] = React.useState(false);

  // When formError is true it will display network error below the form actions
  const [formError, setFormError] = React.useState('');
  const [modifyClicked, setModifyClicked] = React.useState(false);
  const [disableFormActions, setDisableFormActions] = React.useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { dailyEntries, status: timesheetStatus } = timesheet;

  const performanceReview = React.useMemo(() => {
    if (timesheet.performanceReview.length) {
      return timesheet.performanceReview;
    }

    return timesheetPerformanceItems.map((timesheetItem) => ({
      timesheetPerformanceItem: { ...timesheetItem },
      rating: 5,
    }));
  }, [timesheet.performanceReview, timesheetPerformanceItems]);

  const methods = useForm<TimesheetAccordionFormValues>({
    defaultValues: {
      submitNotes: timesheet.submitNotes || '',
      comments: timesheet.comments || '',
      instructorComments: timesheet.instructorComments || '',
      performanceReview: performanceReview, 
      dailyEntries: timesheet.dailyEntries.map((d) => ({
        ...d,
        time: d.minutes ? convertMinutesToHoursAndMinutes(d.minutes) : ''
      }))
    }
  });

  const { control, handleSubmit, watch, setError, getValues, reset } = methods;

  // `performanceReview` is sometimes empty on firstn render. The useForm() above does not get updated on re-render
  // So, instead, we call the `reset()` function that useForm returns to reset the form values when performanceReview changes
  React.useEffect(() => {
    reset({
      submitNotes: timesheet.submitNotes || '',
      comments: timesheet.comments || '',
      instructorComments: timesheet.instructorComments || '',
      performanceReview: performanceReview,
      dailyEntries: timesheet.dailyEntries.map((d) => ({
        ...d,
        time: d.minutes ? convertMinutesToHoursAndMinutes(d.minutes) : ''
      }))
    });
  }, [timesheet, performanceReview, reset]);

  const { fields: performanceReviewFields } = useFieldArray({
    name: 'performanceReview',
    control
  });

  const { fields: dailyEntryFields } = useFieldArray({
    name: 'dailyEntries',
    control
  });
  const watchedDays = watch('dailyEntries');

  const totalHours = React.useMemo(() => {
    let mins = 0;

    watchedDays.forEach((d) => {
      const totalMinsForDay = convertHoursAndMinsToMins(d.time);
      mins += totalMinsForDay;
    });

    return convertMinutesToHoursAndMinutes(mins);
  }, [JSON.stringify(watchedDays)]);

  const watchedPerformanceReview = watch('performanceReview');

  const averageScore = React.useMemo(() => {
    const totalScore = watchedPerformanceReview.reduce(
      (a, b) => a + b.rating,
      0
    );

    const averageScore = totalScore / watchedPerformanceReview.length;

    return averageScore;
  }, [JSON.stringify(watchedPerformanceReview)]);

  React.useEffect(() => {
    setTotalHours(totalHours);
  }, [totalHours]);

  React.useEffect(() => {
    const subscription = watch(
      (value) =>
        (value.comments !== '' || value.instructorComments !== '') &&
        setDisableFormActions(false)
    );
    return (): void => subscription.unsubscribe();
  }, [watch]);

  const getSaveTimesheet = (data: TimesheetAccordionFormValues): SaveTimesheet => {
    const dailyEntries = data.dailyEntries.map((d) => ({
      date: d.date,
      minutes: convertHoursAndMinsToMins(d.time)
    }));

    const postBody: SaveTimesheet = {
      timesheetId: timesheet.id,
      externshipId: timesheet.externship.id,
      weekStartDate: timesheet.weekStartDate,
      weekEndDate: timesheet.weekEndDate,
      submitNotes: data.submitNotes,
      dailyEntries
    };

    return postBody;
  };

  // Returns error message if invalid time is found
  const validateDailyEntries = (hourMinuteEntries: string[]): string => {
    const hasInvalidMins = hourMinuteEntries.some((entry) => {
      const [, mins] = entry.split(':');
      return Number(mins) > 59;
    });

    if (hasInvalidMins) {
      return 'Minutes cannot exceed 59 minutes.';
    }

    const exceeds24Hours = hourMinuteEntries.some((entry) => {
      const [hours] = entry.split(':');
      const totalMins = convertHoursAndMinsToMins(entry);

      return Number(hours) > 24 || totalMins > 60 * 24;
    });

    if (exceeds24Hours) {
      return 'Time submission cannot exceed 24 hours in a day.';
    }

    return '';
  };

  const handleValidSubmit = async (data: TimesheetAccordionFormValues): Promise<void> => {
    setDisableFormActions(true);

    try {
      const timeEntries = data.dailyEntries.map((d) => d.time);
      const invalidTimeMessage = validateDailyEntries(timeEntries);

      if (invalidTimeMessage) {
        setFormError(invalidTimeMessage);
      } else {
        const postBody = getSaveTimesheet(data);

        const res = await ExternshipApi.submitTimesheet(
          timesheet.externship.id,
          timesheet.id,
          postBody
        );

        updateTimesheets(res);
        setFormError('');
        setModifyClicked(false);
      }
    } catch (error: any) {
      console.error(
        `Error for TimesheetPage.handleValidSubmit.submitTimesheet(): ${error}`
      );

      const errMessage = error?.response?.data?.message;
      if (errMessage) {
        setFormError(errMessage);
      } else {
        setFormError('An error occurred.');
      }
    } finally {
      setOpenSubmitModal(false);
      setDisableFormActions(false);
    }
  };

  const handleValidSave = async (data: TimesheetAccordionFormValues): Promise<void> => {
    setDisableFormActions(true);

    try {
      const timeEntries = data.dailyEntries.map((d) => d.time);
      const invalidTimeMessage = validateDailyEntries(timeEntries);

      if (invalidTimeMessage) {
        setFormError(invalidTimeMessage);
      } else {
        const postBody = getSaveTimesheet(data);

        const res = await ExternshipApi.saveTimesheet(
          timesheet.externship.id,
          postBody
        );
        updateTimesheets(res);
        setFormError('');
      }
    } catch (error: any) {
      console.error(
        `Error for TimesheetPage.handleValidSave.saveTimesheet(): ${error}`
      );

      const errMessage = error?.response?.data?.message;
      if (errMessage) {
        setFormError(errMessage);
      } else {
        setFormError('An error occurred.');
      }
    } finally {
      setDisableFormActions(false);
      setOpenSubmitModal(false);
    }
  };

  const handleValidApproval = async (data: TimesheetAccordionFormValues): Promise<void> => {
    setDisableFormActions(true);
    const saveTimesheet = getSaveTimesheet(data);
    const postBody: ApproveTimesheetReq = {
      ...saveTimesheet,
      comments: data.comments,
      instructorComments: data.instructorComments,
      performanceReview: data.performanceReview
    };

    try {
      const res = await ExternshipApi.approveTimesheet(
        timesheet.externship.id,
        timesheet.id,
        postBody
      );

      updateTimesheets(res);
    } catch (error: any) {
      console.error(
        `Error for TimesheetPage.handleValidSave.handleValidApprove(): ${error}`
      );

      if (error) {
        setFormError('An error occurred.');
      }
    } finally {
      setDisableFormActions(false);
    }
  };

  const handleValidRejection = async (data: TimesheetAccordionFormValues): Promise<void> => {
    setDisableFormActions(true);

    if ((isEmployer || isRecruiter) && !data.comments) {
      setError('comments', {
        type: 'manual',
        message: 'Please provide a reason for rejecting this timesheet.'
      });

      return;
    }

    if (isChefInstructor && !data.instructorComments) {
      setError('instructorComments', {
        type: 'manual',
        message: 'Please provide a reason for rejecting this timesheet.'
      });

      return;
    }

    const postBody = {
      comments: data.comments,
      instructorComments: data.instructorComments
    };

    try {
      const res = await ExternshipApi.rejectTimesheet(
        timesheet.externship.id,
        timesheet.id,
        postBody
      );

      updateTimesheets(res);
    } catch (error: any) {
      console.error(`Error for TimesheetPage.handleValidReject(): ${error}`);
    } finally {
      setDisableFormActions(false);
    }
  };

  const handleResendTimesheetToCampus = async (
    data: TimesheetAccordionFormValues
  ): Promise<void> => {
    setDisableFormActions(true);

    const saveTimesheet = getSaveTimesheet(data);
    const postBody: ApproveTimesheetReq = {
      ...saveTimesheet,
      comments: data.comments,
      instructorComments: data.instructorComments,
      performanceReview: data.performanceReview
    };

    try {
      const res = await ExternshipApi.resendToCampus(
        timesheet.externship.id,
        timesheet.id,
        postBody
      );

      updateTimesheets(res);
    } catch (error: any) {
      console.error(
        `Error for TimesheetPage.handleResendTimesheetToCampus(): ${error}`
      );
    } finally {
      setDisableFormActions(false);
    }
  };

  const handleUndoChefApproval = async (): Promise<void> => {
    try {
      const res = await ExternshipApi.undoChefTimesheetApproval(
        timesheet.externship.id,
        timesheet.id
      );

      updateTimesheets(res);
      setOpenUndoChefApprovalModal(false);
    } catch (error: any) {
      console.error(
        `Error for TimesheetPage.handleUndoChefApproval(): ${error}`
      );
      throw error;
    }
  };

  const handleModifyClick = (): void => {
    setModifyClicked(true);
  };

  const handleSubmitConfirmationModal = (): void => setOpenSubmitModal(true);

  const displayEdit =
    (timesheetStatus === TimesheetStatus.PENDING_SUPERVISOR_APPROVAL ||
      timesheetStatus === TimesheetStatus.REJECTED_BY_SUPERVISOR ||
      timesheetStatus === TimesheetStatus.REJECTED_BY_INSTRUCTOR) &&
    !modifyClicked;

  const acceptedStatuses = [
    TimesheetStatus.SENT_SUCCESSFULLY,
    TimesheetStatus.SENT_FAILED,
    TimesheetStatus.SENT_UNKNOWN,
    TimesheetStatus.ACCEPTED
  ];

  // List of timesheet statuses that can be submitted
  const submittableTimesheetStatuses = [
    TimesheetStatus.CREATED,
    TimesheetStatus.DRAFT,
    TimesheetStatus.REJECTED_BY_SUPERVISOR,
    TimesheetStatus.REJECTED_BY_INSTRUCTOR,
    TimesheetStatus.PENDING_SUPERVISOR_APPROVAL
  ];

  const isSubmittableByStudent = React.useMemo((): boolean => {
    const EXTERNSHIP_COMPLETED_STATUSES = [
      ExternshipStatus.COMPLETED,
      ExternshipStatus.NO_PASS
    ];

    const TIMESHEET_STATUSES_FOR_COMPLETED_EXTERNSHIPS = [
      TimesheetStatus.CREATED,
      TimesheetStatus.DRAFT,
      TimesheetStatus.REJECTED_BY_SUPERVISOR,
      TimesheetStatus.REJECTED_BY_INSTRUCTOR,
      TimesheetStatus.PENDING_SUPERVISOR_APPROVAL
    ];

    const externshipStatus = timesheet.externship?.status;

    if (externshipStatus === ExternshipStatus.ACTIVE) {
      return submittableTimesheetStatuses.includes(timesheetStatus);
    }

    if (EXTERNSHIP_COMPLETED_STATUSES.includes(externshipStatus)) {
      return TIMESHEET_STATUSES_FOR_COMPLETED_EXTERNSHIPS.includes(timesheetStatus);
    }

    return false;
  }, [timesheet.externship?.status, timesheetStatus]);

  const isAccepted = acceptedStatuses.includes(timesheetStatus);

  const isPendingInstructorApproval =
    timesheetStatus === TimesheetStatus.PENDING_INSTRUCTOR_APPROVAL;

  const isTimesheetUneditable =
    !isSubmittableByStudent ||
    careerServiceView ||
    displayEdit ||
    isAccepted ||
    isPendingInstructorApproval ||
    isApproverViewing || 
    !isCandidateViewing;

  const editableRatingsStatuses = [
    TimesheetStatus.REJECTED_BY_SUPERVISOR,
    TimesheetStatus.REJECTED_BY_INSTRUCTOR,
    TimesheetStatus.PENDING_SUPERVISOR_APPROVAL
  ];

  const hideRatingsStatuses = [TimesheetStatus.CREATED, TimesheetStatus.DRAFT];
  const candidateHideRatingsStatuses = [
    TimesheetStatus.PENDING_SUPERVISOR_APPROVAL,
    TimesheetStatus.REJECTED_BY_INSTRUCTOR,
    TimesheetStatus.REJECTED_BY_SUPERVISOR
  ];

  const displayPerformanceReview = (): boolean => {
    if (careerServiceView) {
      return false;
    }

    if (hideRatingsStatuses.includes(timesheetStatus)) {
      return false;
    }

    if (
      isChefInstructor &&
      timesheetStatus !== TimesheetStatus.PENDING_SUPERVISOR_APPROVAL
    ) {
      return true;
    }

    if (isApproverViewing) {
      return true;
    }

    if (isCandidate && !candidateHideRatingsStatuses.includes(timesheetStatus)) {
      return true;
    }

    return false;
  };

  // List of statuses that can be approved by managers/supervisors
  const supervisorApproveableStatuses = [
    TimesheetStatus.PENDING_SUPERVISOR_APPROVAL,
    TimesheetStatus.REJECTED_BY_SUPERVISOR
  ];

  const Comments: React.FC = () => {
    // Determine which comment fields should be editable based on user role and timesheet status
    const showChefInstructorInput = isChefInstructor && 
      (timesheetStatus === TimesheetStatus.PENDING_INSTRUCTOR_APPROVAL ||
      timesheetStatus === TimesheetStatus.REJECTED_BY_INSTRUCTOR);
      
    const showEmployerInput = isApproverViewing && 
      supervisorApproveableStatuses.includes(timesheetStatus);
    
    const showCandidateInput = isCandidate && !displayEdit && isSubmittableByStudent;
    
    return (
      <>
        {timesheet.submitNotes && !showCandidateInput && (
          <Grid item xs={12} data-testid="submit-notes-read-only">
            <Typography variant="EC_TYPE_SM">Student Comments:</Typography>
            <Typography variant="EC_TYPE_BASE">
              {timesheet.submitNotes}
            </Typography>
          </Grid>
        )}
        {timesheet.comments && !showEmployerInput && (
          <Grid item xs={12} data-testid="comments-read-only">
            <Typography variant="EC_TYPE_SM">Manager Comments:</Typography>
            <Typography variant="EC_TYPE_BASE">{timesheet.comments}</Typography>
          </Grid>
        )}
        {timesheet.instructorComments && !showChefInstructorInput && (
          <Grid item xs={12} data-testid="instructor-comments-read-only">
            <Typography variant="EC_TYPE_SM">Instructor Comments:</Typography>
            <Typography variant="EC_TYPE_BASE">
              {timesheet.instructorComments}
            </Typography>
          </Grid>
        )}
      </>
    );
  };

  const FormComments: React.FC = () => {
    // Only show editable comment fields based on user role and timesheet status
    const showChefInstructorInput = isChefInstructor && 
      (timesheetStatus === TimesheetStatus.PENDING_INSTRUCTOR_APPROVAL ||
      timesheetStatus === TimesheetStatus.REJECTED_BY_INSTRUCTOR);
      
    const showEmployerInput = isApproverViewing && 
      supervisorApproveableStatuses.includes(timesheetStatus);
    
    const showCandidateInput = isCandidate && !displayEdit && isSubmittableByStudent;
    
    return (
      <>
        {showChefInstructorInput && (
          <Grid item xs={12} data-testid="instructor-comments-input">
            <ReactHookFormsTextarea
              name="instructorComments"
              label="Instructor Comments:"
              labelVariant="EC_TYPE_SM"
              rows={5}
            />
          </Grid>
        )}
        {(showEmployerInput || (isChefInstructor && supervisorApproveableStatuses.includes(timesheetStatus))) && (
          <Grid item xs={12} data-testid="comments-input">
            <ReactHookFormsTextarea
              name="comments"
              label="Manager Comments:"
              labelVariant="EC_TYPE_SM"
              rows={5}
            />
          </Grid>
        )}
        {showCandidateInput && (
          <Grid item xs={12} data-testid="submit-notes-input">
            <ReactHookFormsTextarea
              name="submitNotes"
              label="Student Comments:"
              labelVariant="EC_TYPE_SM"
              rows={5}
            />
          </Grid>
        )}
      </>
    );
  };

  const ApprovalActions: React.FC = () => {
    return (
      <>
        <Grid item xs={6} data-testid="timesheet-approval-actions">
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit(handleValidApproval)}
            disabled={disableFormActions}
          >
            Approve
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="outlined"
            onClick={handleSubmit(handleValidRejection)}
            disabled={disableFormActions}
          >
            Reject
          </Button>
        </Grid>
      </>
    );
  };

  const FormActions: React.FC = () => {
    // isCandidate
    //    - Modify
    //    - Submit and Save
    if (isCandidate) {
      const modifiableStatuses = [
        TimesheetStatus.PENDING_SUPERVISOR_APPROVAL,
        TimesheetStatus.REJECTED_BY_SUPERVISOR,
        TimesheetStatus.REJECTED_BY_INSTRUCTOR
      ];

      if (modifiableStatuses.includes(timesheetStatus) && !modifyClicked) {
        return (
          <Grid item xs={6} data-testid="timesheet-modify-actions">
            <Button
              fullWidth
              variant="outlined"
              onClick={handleModifyClick}
              disabled={disableFormActions}
            >
              Modify
            </Button>
          </Grid>
        );
      }

      if (isSubmittableByStudent) {
        return (
          <>
            <Grid item xs={6} data-testid="timesheet-submit-actions">
              <Button
                fullWidth
                variant="contained"
                onClick={handleSubmitConfirmationModal}
                disabled={disableFormActions}
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={handleSubmit(handleValidSave)}
                disabled={disableFormActions}
              >
                Save
              </Button>
            </Grid>
          </>
        );
      }
    }

    // isEmployer || isRecruiter
    //    - Approve and Reject
    if (isApproverViewing) {
      if (supervisorApproveableStatuses.includes(timesheetStatus)) {
        return <ApprovalActions />;
      }
    }

    // isChefInstructor
    //    - Approve and Reject
    if (isChefInstructor) {
      const UNDO_APPROVAL_STATUSES = [
        TimesheetStatus.SENT_SUCCESSFULLY,
        TimesheetStatus.SENT_FAILED,
        TimesheetStatus.SENT_UNKNOWN
      ];

      if (
        timesheetStatus === TimesheetStatus.PENDING_INSTRUCTOR_APPROVAL ||
        timesheetStatus === TimesheetStatus.REJECTED_BY_INSTRUCTOR ||
        supervisorApproveableStatuses.includes(timesheetStatus)
      ) {
        return <ApprovalActions />;
      }

      if (UNDO_APPROVAL_STATUSES.includes(timesheetStatus)) {
        return (
          <>
            <Grid item xs={6} data-testid="timesheet-undo-accept">
              <Button
                fullWidth
                variant="outlined"
                onClick={(): void => setOpenUndoChefApprovalModal(true)}
              >
                Undo Approval
              </Button>
            </Grid>
            <UndoChefTimesheetApprovalModal
              open={openUndoChefApprovalModal}
              handleClose={(): void => setOpenUndoChefApprovalModal(false)}
              handleUndoChefApproval={handleUndoChefApproval}
            />
          </>
        );
      }
    }

    // isEcAdmin
    //    - Resend Timesheet
    if (isEcAdmin) {
      if (
        timesheetStatus === TimesheetStatus.SENT_FAILED ||
        timesheetStatus === TimesheetStatus.SENT_UNKNOWN
      ) {
        return (
          <Grid item xs={6} data-testid="timesheet-resend-actions">
            <Button
              fullWidth
              variant="contained"
              onClick={handleSubmit(handleResendTimesheetToCampus)}
              disabled={disableFormActions}
            >
              Resend Timesheet to Campus
            </Button>
          </Grid>
        );
      }
    }

    return <></>;
  };

  return (
    <Styled.Root>
      <FormProvider {...methods}>
        <Grid container columnSpacing={2} rowSpacing={4}>
          <Grid item xs={12}>
            <Styled.TimesheetWrapper isMobile={isMobile}>
              <TimesheetForm
                fields={dailyEntryFields}
                dailyEntries={dailyEntries}
                uneditable={isTimesheetUneditable}
                isMobile={isMobile}
                careerServiceView={careerServiceView}
              />
              {isMobile ? (
                <Grid container columnSpacing={3}>
                  <Styled.GridItem item xs={5} md={3.5}>
                    <Typography
                      variant="EC_TYPE_2XL"
                      color={theme.palette.GOLD_1.main}
                    >
                      TOTAL
                    </Typography>
                  </Styled.GridItem>
                  <Styled.GridItem item xs={7} md={8.5}>
                    <Typography
                      variant="EC_TYPE_4XL"
                      mb={0}
                      color={theme.palette.GRAY_4.main}
                    >
                      {totalHours}
                    </Typography>
                  </Styled.GridItem>
                </Grid>
              ) : (
                <GoldenInfoBox title="Total" text={totalHours} />
              )}
            </Styled.TimesheetWrapper>
          </Grid>
          <Comments />
          {timesheet.acceptedBy && (
            <Grid item xs={12}>
              <Typography variant="EC_TYPE_LG">
                Accepted By:{' '}
                <Typography component="span">
                  {timesheet.acceptedBy?.user ? 
                    `${timesheet.acceptedBy.user.firstName} ${timesheet.acceptedBy.user.lastName}` 
                    : timesheet.approverSignature }
                </Typography>
              </Typography>
            </Grid>
          )}
          {displayPerformanceReview() && (
            <>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {performanceReviewFields.map((field, idx) => (
                    <Grid item xs={12} key={field.id}>
                      <ReactHookFormsRatingSelection
                        name={`performanceReview.${idx}.rating`}
                        label={
                          getValues().performanceReview[idx]
                            .timesheetPerformanceItem.question
                        }
                        ratings={[1, 2, 3, 4, 5]}
                        uneditable={
                          (!isEmployer && !isRecruiter) ||
                          !editableRatingsStatuses.includes(timesheetStatus)
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3.75}>
                    <GoldenInfoBox
                      title="Weekly Total"
                      text={`${totalHours}`}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3.75}>
                    <GoldenInfoBox
                      title="Average Score"
                      text={`${averageScore}`}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {!careerServiceView && (
            <>
              <FormComments />
              <FormActions />
              {formError && (
                <Box mt="8px" ml="16px">
                  <FormFieldErrorText
                    message={formError}
                    name={'timesheet-error-message'}
                    disableAbsolutePosition
                  />
                </Box>
              )}
            </>
          )}
        </Grid>
      </FormProvider>
      <BasicModal
        open={openSubmitModal}
        title="Submit Your Final Timesheet"
        handleClose={(): void => setOpenSubmitModal(false)}
        maxWidth="sm"
        primaryAction={{
          label: 'Submit Timesheet',
          action: handleSubmit(handleValidSubmit),
          disabled: disableFormActions
        }}
        secondaryAction={{
          label: 'Save',
          action: handleSubmit(handleValidSave),
          disabled: disableFormActions
        }}
      >
        <BottomMarginContentWrapper bottomMargin="16px">
          <Typography>
            When your manager approves this timesheet, you will no longer be
            able to make changes to it.
          </Typography>
          <Typography>
            If you plan on submitting additional hours this week, save your work
            and submit the final timesheet after you have finished those hours.
          </Typography>
        </BottomMarginContentWrapper>
      </BasicModal>
    </Styled.Root>
  );
};

export default TimesheetAccordionContent;
