// Returns incorrect time locally but works fine in deployed environment
export const getCurrentTimezoneDateTime = (universalDate: string): string => {
  // convert to date
  const utcDate = new Date(universalDate);

  // Display Local Time
  const localTimeString = utcDate.toLocaleString();

  const usersTimezone = new Date()
    .toLocaleTimeString(undefined, { timeZoneName: 'short' })
    .split(' ')[2];

  return localTimeString + ' ' + usersTimezone;
};
