/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import BatchActionModalLayout, {
  SelectedRowEnrollmentEntity
} from '@components/Layout/BatchActionModalLayout';
import {
  Box,
  OutlinedInput,
  TextareaAutosize,
  Typography,
  useTheme
} from '@mui/material';
import { CareerServicesApi } from '@api/CareerService.api';
import { SendEmailBatchReq } from '@api/models/careerServicesApi.models';
import { styled } from '@mui/system';
import { useBatchActionsContext } from '@common/context/batchActionsContext';

const Styled = {
  EmailBatchToLabel: styled(Typography)(({ theme }) => ({
    marginBottom: '5px',
    textTransform: 'uppercase',
    color: theme.palette.grey4.main
  }))
};

interface Props {
  open: boolean;
}

const EmailBatchModal: React.FC<Props> = ({ open }) => {
  const { handleCloseModal } = useBatchActionsContext();
  const theme = useTheme();

  const [sendingEmail, setSendingEmail] = React.useState(false);
  const [subject, setSubject] = React.useState<string>('');
  const [emailBody, setEmailBody] = React.useState<string>('');
  const [selectedRows, setSelectedRows] = React.useState<
    SelectedRowEnrollmentEntity[]
  >([]);

  const handleEmailBatchBtn = async (): Promise<void> => {
    const postBody: SendEmailBatchReq = {
      emailBody: emailBody,
      subject: subject,
      studentIds: selectedRows.map((row) => row.studentId)
    };

    try {
      setSendingEmail(true);
      await CareerServicesApi.sendEmailToStudents(postBody);
    } catch (error) {
      console.error('Error: CareerServicesDashboardPage.handleEmailBatchBtn()');
    } finally {
      setSendingEmail(false);
      handleCloseModal();
    }
  };

  // TODO: use toast instead of confirmation modal
  return (
    <BatchActionModalLayout
      open={open}
      modalTitle="Send An Email"
      primaryBtnText="Send"
      maxTagsToDisplay={27}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      handleSubmitClick={handleEmailBatchBtn}
      handleClose={handleCloseModal}
      loading={sendingEmail}
      toastMessage="Email sent to selected student(s)"
    >
      <Box display="grid">
        <Styled.EmailBatchToLabel variant="EC_TYPE_XS">
          RE:
        </Styled.EmailBatchToLabel>
        <OutlinedInput
          value={subject}
          onChange={(e): void => setSubject(e.target.value)}
          placeholder="Subject"
        />

        <Styled.EmailBatchToLabel variant="EC_TYPE_XS" paddingTop={'25px'}>
          EMAIL BODY:
        </Styled.EmailBatchToLabel>
        <TextareaAutosize
          value={emailBody}
          onChange={(e): void => setEmailBody(e.target.value)}
          minRows={3}
          style={{
            borderColor: theme.palette.OUTLINE.main,
            borderRadius: '5px',
            padding: '10px',
            font: 'inherit'
          }}
        />
      </Box>
    </BatchActionModalLayout>
  );
};

export default EmailBatchModal;
