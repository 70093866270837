import * as React from 'react';
import { Button } from '@mui/material';
import QuizResultCard from '@components/Quiz/QuizResults/QuizResultCard';
import { QuizResults } from '@api/models/quizApi.models';
import theme from '@themes/ui/escoffier';

interface QuizResultCardDisplayProps {
  results: QuizResults[];
  maxPercent: number;
  isSelected: (id: number) => boolean;
  handleSelectResult: (result: QuizResults) => void;
}

type DisplayRange = [num1: number, num2: number];

const QuizResultCardDisplay = (
  props: QuizResultCardDisplayProps
): React.ReactElement => {
  const { maxPercent, handleSelectResult, isSelected, results } = props;

  // dr = display range. Starting with 1, 4 because the result at index 0 is put into the init state
  const initDr: DisplayRange = [1, 4];
  const [dr, setDR] = React.useState<DisplayRange>(initDr);
  const [resultArray, setResultArray] = React.useState<QuizResults[]>([
    results[0]
  ]);

  const filterArray = (arr: QuizResults[]): QuizResults[] =>
    arr.filter(
      (value, idx, array) =>
        idx === array.findIndex((result) => result.id === value.id)
    );

  // enable display of one card at a time for fade in animation
  React.useEffect(() => {
    if (resultArray.length <= dr[1]) {
      results.slice(dr[0], dr[1]).forEach((result, i) => {
        if (dr[1] <= results.length) {
          setTimeout(() => {
            setResultArray((prevArr) => [...prevArr, result]);
          }, (i + 1) * 100);
        }
      });
    }
  }, [results, dr]);

  // ensure that no duplicate results end up being displayed,
  // only once first render has occurred.
  React.useEffect(() => {
    if (resultArray.length >= 4) {
      setResultArray((prevArray) => [...filterArray(prevArray)]);
    }
  }, [resultArray.length]);

  const handleIncreaseDisplayRange = (): void => {
    if (dr[0] < results.length) {
      const dr0 = dr[1];
      let dr1 = dr[1] + 5;
      if (dr1 > results.length) {
        dr1 = results.length;
      }
      setDR([dr0, dr1]);
    }
  };

  const disableShowMoreBtn: boolean = resultArray.length >= results.length;

  return (
    <>
      {resultArray.map((result) => {
        const selected = isSelected(result.id);
        return (
          <React.Fragment key={result.id}>
            <QuizResultCard
              result={result.result}
              percentage={result.percentage}
              maxPercent={maxPercent}
              selected={selected}
              handleSelect={(): void => handleSelectResult(result)}
            />
          </React.Fragment>
        );
      })}
      {!disableShowMoreBtn && (
        <Button
          variant="text"
          color="primary"
          onClick={handleIncreaseDisplayRange}
          aria-label="show more results"
          sx={{
            color: theme.palette.GRAY_3.main,
            width: 'max-content',
            alignSelf: 'center'
          }}
        >
          Show more
        </Button>
      )}
    </>
  );
};

export default QuizResultCardDisplay;
