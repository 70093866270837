import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const Styled = {
  Root: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'imgUrl'
  })<{ imgUrl: string }>(({ imgUrl }) => ({
    minHeight: '700px',
    backgroundImage: `url(${imgUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      minHeight: 'unset',
      padding: '48px 24px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '48px 8px'
    }
  })),
  Card: styled(Box)({
    position: 'absolute',
    right: -24,
    top: 50,
    backgroundColor: '#FFFFFF',
    padding: '22px 22px 36px 36px',
    maxWidth: '550px',
    boxShadow: '0px 4px 4px rgba(0,0,0,0.25)',
    [theme.breakpoints.down('lg')]: {
      position: 'static',
      maxWidth: 'unset',
      margin: '0 auto',
      top: 'unset',
      right: 'unset'
    }
  })
};

interface Props {
  image: string;
  cardTitle: string;
  cardContent: JSX.Element | string;
}

const ImageWithOffsetCard = ({
  image,
  cardTitle,
  cardContent
}: Props): React.ReactElement => {
  return (
    <Styled.Root imgUrl={image} data-testid="image-with-offset-card-img">
      <Styled.Card data-testid="image-with-offset-card-card">
        <Typography variant="EC_TYPE_3XL" color="GRAY_4.main">
          {cardTitle}
        </Typography>
        {cardContent}
      </Styled.Card>
    </Styled.Root>
  );
};

export default ImageWithOffsetCard;
