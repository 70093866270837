import { AdminDashboardApi } from '@api/AdminDashboard.api';
import { CompanyStatus } from '@api/models/adminDashboardApi.models';

interface CompanyCsvData extends CompanyStatus {
  adminEmail: string;
  adminPhone: string;
  primary: string;
}

export type FetchCompaniesCsv = {
  fetchCompaniesCsv: () => Promise<CompanyCsvData[]>;
};

/**
 * Fetch CompanyStatus[] from AdminDashboardApi.getCompaniesCsv()
 *
 * @returns `{ fetchCompaniesCsv: () => Promise<CompanyCsvData[]> }`
 */
export const useFetchCompaniesCsv = (): FetchCompaniesCsv => {
  const getPrimaryString = (company: CompanyStatus): string => {
    if (!!company.subsidiaries?.length || company.possibleParent) {
      return `${company.companyName} *`;
    } else if (!company.parentCompany) {
      return '';
    } else {
      return company.parentCompany.name;
    }
  };

  const convertCompanyStatusToCompaniesCsvData = (
    companies: CompanyStatus[]
  ): CompanyCsvData[] => {
    const data: CompanyCsvData[] = [];

    companies.forEach((c) => {
      c.admins.forEach((a) => {
        data.push({
          ...c,
          adminEmail: a.email,
          adminPhone: a.phone,
          primary: getPrimaryString(c)
        });
      });
    });

    return data;
  };

  const fetchCompaniesCsv = async (): Promise<CompanyCsvData[]> => {
    let data: CompanyCsvData[] = [];

    try {
      const res = await AdminDashboardApi.getCompaniesCsv();
      data = convertCompanyStatusToCompaniesCsvData(res);
    } catch (error: any) {
      console.error(
        'Error for useFetchCompaniesCsv -> AdminDashboardApi.getJobPostsCsvByCompanyId()',
        error
      );
    }

    return data;
  };

  return { fetchCompaniesCsv };
};
