import { Codebook } from '@api/models/codebook.models';
import { ExternshipEntity } from '@api/models/externshipApi.models';

interface OdataResponse {
  '@odata.context': string;
  '@odata.count': number;
  value: any[];
}

export interface StudentEnrollmentEntity {
  id: number;
  campusFetchEntity: any; // TODO: create this
  enrollmentId: number;
  expectStartDate: string;
  externshipBeginDate: string;
  graduationDate: string;
  campusStudentId: number;
  studentFirstName: string;
  studentLastName: string;
  studentNumber: string;
  studentEmailAddress: string;
}

export interface EnrollmentResponse extends OdataResponse {
  value: EnrollmentData[];
}

export enum SchoolStatus {
  GRADUATE = 'GRAD',
  TRANSFER_TO_OTHER_CAMPUS = 'TOCAMP',
  RE_ENROLL_CANCEL = 'RECXL', // Re-Enroll Cancel
  CANCEL = 'CANCEL',
  DECEASED = 'DEA',
  ENROLLMENT = 'FUT',
  TRANSFER_TO_OTHER_PROGRAM = 'TOPROG',
  // "Re-Enroll (Cancel)"	"REENROLL" - how does this differ from RECXL
  TRANSFER_FROM_OTHER_PROGRAM = 'FROMPROG',
  WITHDRAW = 'DROP',
  DISMISSED = 'DISMISS',

  // ELIGIBLE TO PROPOSE - course and start date exists
  ACTIVE = 'ATT',
  EXTERNSHIP = 'EXTERN',
  LEAVE_OF_ABSENCE = 'LOA',
  PROBATION = 'PROB',
  CONDITIONALLY_ACTIVE = 'CND-ACT',
  RE_ENTRY = 'REENTRY',
  REN365 = 'REN365', // RE-Enroll (Over 365)
  EXTERNSHIP_PROBABTION = 'EXTPROB',
  REN_PREVIOUSLY_ACTIVE = 'REOVR365'
}

export const eligibleExternshipEnrollmentStatuses = [
  SchoolStatus.ACTIVE,
  SchoolStatus.EXTERNSHIP,
  SchoolStatus.LEAVE_OF_ABSENCE,
  SchoolStatus.RE_ENTRY,
  SchoolStatus.PROBATION,
  SchoolStatus.CONDITIONALLY_ACTIVE,
  SchoolStatus.REN365,
  SchoolStatus.EXTERNSHIP_PROBABTION,
  SchoolStatus.REN_PREVIOUSLY_ACTIVE
];

export interface EnrollmentSchoolStatus {
  Code: SchoolStatus;
  Name: string;
}

export interface StudentData {
  Id: number;
  FirstName: string;
  LastName: string;
  StudentNumber: string;
  EmailAddress: string;
  PhoneNumber: string;
  CurrentLda: Date;
  StreetAddress: string;
  City: string;
  State: string;
}

export enum SchoolStatusIds {
  ACTIVE = 13
}

export interface EnrollmentData {
  DropDate: Date | null;
  Id: number;
  ExpectedStartDate: Date;
  ExternshipBeginDate: Date;
  GraduationDate: string;
  Lda: Date;
  ProgramVersionName: string;
  SchoolStatus: EnrollmentSchoolStatus;
  SchoolStatusId: SchoolStatusIds;
  Student: StudentData;
}

export interface StudentResponse extends OdataResponse {
  value: EnrollmentData[];
}

export interface CourseData {
  // CampusGroupId: number;
  ClockHours: number;
  // Code: string;
  CourseCode: string;
  CourseId: number;
  // CourseTypeId: number;
  // CreatedDateTime: string;
  CreditHours: number;
  EndDate: string | null;
  ExpectedEndDate: string;
  GradePostedDate: string;
  Id: number;
  InstructorCode: string;
  InstructorId: number;
  LastModifiedDateTime: Date;
  Lda: string | null;
  LetterGrade: string | null;
  Name: string;
  ProgramName: string;
  ProgramVersionCode: string;
  ProgramVersionName: string;
  StartDate: string;
  Code: string;
  Status: string;
  StatusName: string;
  StudentEnrollmentPeriodId: number;
  StudentId: number;
  TermCode: string;

  // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove disabled when flag is updated
  disabled?: boolean | null;
  // campusFetchEntity: CampusFetchEntity;
}

export interface CampusCourseEntity {
  Id: number;
  Code: string;
  Name: string;
  CourseTypeId: number;
  ClockHours: number;
  CreditHours: number;
  CreatedDateTime: string;
  CampusGroupId: number;
}

interface EcEnrollmentEntity {
  dropDate: string | null;
  expectedStartDate: string;
  externshipBeginDate: string;
  externshipProposalEnabled: boolean;
  graduationDate: string;
  id: number; // enrollmentId
  lda: string;
  programName: string;
  programVersionName: string;
  schoolStatusCode: string;
  schoolStatusName: string;
}

export interface CourseWithExternships extends CourseData {
  externships: ExternshipEntity[];
}

export interface EnrollmentCoursesExternships extends EcEnrollmentEntity {
  student: StudentEnrollmentEntity;
  courses: CourseWithExternships[];
}

export enum CampusFetchType {
  SchoolDates = 'school_dates',
  Courses = 'courses',
  Enrollments = 'enrollments',
  EnrollmentsPerDate = 'enrollments_per_date'
}

export interface CampusFetchEntity {
  count: number;
  date: Date;
  externshipBeginDate: Date | null;
  fetchType: CampusFetchType;
}

export interface CampusJobtitles extends Codebook<string> {
  code: string;
}

export enum CampusCourseStatus {
  COMPLETE = 'P',
  DROPPED = 'D',

  // Eligible To Propose Externship Site
  FUTURE = 'F',
  SCHEDULED = 'S',
  CURRENT = 'C',
  LOA = 'L'
}

export const CourseStatusesIneligibleForSiteProposal = [
  CampusCourseStatus.COMPLETE,
  CampusCourseStatus.DROPPED
];
