import * as React from 'react';
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TypographyVariant
} from '@mui/material';
import { styled } from '@mui/system';

const Styled = {
  TableRow: styled(TableRow)({
    '&:nth-of-type(odd)': {
      backgroundColor: '#F9F5F1'
    }
  })
};

const StyleGuidePage: React.FC = () => {
  const typography = [
    {
      variant: 'EC_TYPE_6XL',
      label: 'H1-HEADLINE',
      fontFamily: 'Condensed',
      fontWeight: '800',
      fontSize: '70px',
      letterSpacing: '0.03em'
    },
    {
      variant: 'EC_TYPE_5XL',
      label: 'H2-HEADLINE',
      fontFamily: 'Condensed',
      fontWeight: '700',
      fontSize: '45px',
      letterSpacing: 'Default'
    },
    {
      variant: 'EC_TYPE_4XL',
      label: 'h3-Title',
      fontFamily: 'Condensed',
      fontWeight: '700',
      fontSize: '32px',
      letterSpacing: '0.01em'
    },
    {
      variant: 'EC_TYPE_3XL',
      label: 'h4-Subhead',
      fontFamily: 'Regular',
      fontWeight: '700',
      fontSize: '25px',
      letterSpacing: 'Default'
    },
    {
      variant: 'EC_TYPE_2XL',
      label: 'H5-INTRO',
      fontFamily: 'Condensed',
      fontWeight: '700',
      fontSize: '22px',
      letterSpacing: 'Default'
    },
    {
      variant: 'EC_TYPE_XL',
      label: 'H6-LABELS',
      fontFamily: 'Condensed',
      fontWeight: '800',
      fontSize: '18px',
      letterSpacing: '0.05em'
    },
    {
      variant: 'EC_TYPE_LG',
      label: 'Section Title',
      fontFamily: 'Regular',
      fontWeight: '700',
      fontSize: '18px',
      letterSpacing: 'Default'
    },
    {
      variant: 'EC_TYPE_BASE',
      label: 'Body Default',
      fontFamily: 'Regular',
      fontWeight: '400',
      fontSize: '16px',
      letterSpacing: 'Default'
    },
    {
      variant: 'EC_TYPE_SM',
      label: 'Body Small',
      fontFamily: 'Regular',
      fontWeight: '700',
      fontSize: '15px',
      letterSpacing: 'Default'
    },
    {
      variant: 'EC_TYPE_XS',
      label: 'SMALL TITLE',
      fontFamily: 'Condensed',
      fontWeight: '800',
      fontSize: '12px',
      letterSpacing: '0.05em'
    },
    {
      variant: 'EC_TYPE_2XS',
      label: 'Xsmall Title',
      fontFamily: 'Regular',
      fontWeight: '700',
      fontSize: '12px',
      letterSpacing: 'Default'
    },
    {
      variant: 'EC_TYPE_3XS',
      label: 'Tags',
      fontFamily: 'Regular',
      fontWeight: '400',
      fontSize: '10px',
      letterSpacing: 'Default'
    }
  ];
  return (
    <Container sx={{ paddingTop: '100px', paddingBottom: '100px' }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ typography: 'EC_TYPE_BASE', color: '#333333' }}>
                Variant
              </TableCell>
              <TableCell
                sx={{ typography: 'EC_TYPE_BASE', color: '#333333' }}
                align="left"
              >
                Style
              </TableCell>
              <TableCell
                sx={{ typography: 'EC_TYPE_BASE', color: '#333333' }}
                align="center"
              >
                Font Family
              </TableCell>
              <TableCell
                sx={{ typography: 'EC_TYPE_BASE', color: '#333333' }}
                align="center"
              >
                Font Weight
              </TableCell>
              <TableCell
                sx={{ typography: 'EC_TYPE_BASE', color: '#333333' }}
                align="center"
              >
                Font Size
              </TableCell>
              <TableCell
                sx={{ typography: 'EC_TYPE_BASE', color: '#333333' }}
                align="center"
              >
                Letter Spacing
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {typography.map((row) => (
              <Styled.TableRow key={row.variant}>
                <TableCell
                  sx={{ typography: 'EC_TYPE_XL' }}
                  component="th"
                  scope="row"
                >
                  {row.variant}
                </TableCell>
                <TableCell align="left">
                  <Typography variant={row.variant as TypographyVariant}>
                    {row.label}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{ typography: 'EC_TYPE_XL', color: '#5C6B80' }}
                  align="center"
                >
                  {row.fontFamily}
                </TableCell>
                <TableCell
                  sx={{ typography: 'EC_TYPE_XL', color: '#5C6B80' }}
                  align="center"
                >
                  {row.fontWeight}
                </TableCell>
                <TableCell
                  sx={{ typography: 'EC_TYPE_XL', color: '#5C6B80' }}
                  align="center"
                >
                  {row.fontSize}
                </TableCell>
                <TableCell
                  sx={{ typography: 'EC_TYPE_XL', color: '#5C6B80' }}
                  align="center"
                >
                  {row.letterSpacing}
                </TableCell>
              </Styled.TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default StyleGuidePage;
