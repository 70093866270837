import { NavItem } from '@components/Layout/Header/Header.models';

export const accountSettingsNavItem = { label: 'Account Settings', path: '/account' };
export const notificationSettingsNavItem = { label: 'Notifications', path: '/notifications' };
export const companySettingsNavItem = { label: 'Company Settings', path: '/company' };
//export const customizationSettingsNavItem = { label: 'Customizations', path: '/customizations' };
//export const locationsSettingsNavItem = { label: 'Locations', path: '/locations' };

export const candidateSettingsNav = [
  accountSettingsNavItem,
  // { label: 'Notifications', path: '/notifications' } -- Candidate Notifications has not been implemented yet
];

export const ecAdminViewingAsEmployerNav = ( ): Set<NavItem> => new Set([
  companySettingsNavItem,
]);

export const employerSettingsNav = ( ): Set<NavItem> => new Set([
  accountSettingsNavItem,
  companySettingsNavItem,
  accountSettingsNavItem,
]);

export const recruiterSettingsNav: Set<NavItem> = new Set([
  accountSettingsNavItem,
  notificationSettingsNavItem,
]);

export const escoffierAdminSettingsNav: Set<NavItem> = new Set([
  accountSettingsNavItem,
  notificationSettingsNavItem,
]);

export const partnerSettingsNav: Set<NavItem> = new Set([]);

export const careerServicesSettingsNav: Set<NavItem> = new Set([
  accountSettingsNavItem
]);

export const authorizedUserSettingsNav: Set<NavItem> = new Set([
  accountSettingsNavItem
]);

export const timesheetApproverSettingsNav: Set<NavItem> = new Set([
  accountSettingsNavItem
]);