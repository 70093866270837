import * as React from 'react';
import { Box, Typography } from '@mui/material';
import {
  ExternshipApprover,
  ExternshipEntity
} from '@api/models/externshipApi.models';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import EcProgressBar from '@components/EcProgressBar';
import { getTimesheetStatusHours } from '@common/helpers/externshipHelpers/getTimesheetStatusHours';
import { styled } from '@mui/system';

const Styled = {
  Row: styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'start',
    '& .detail-title': {
      margin: '8px 0 4px'
    }
  }),
  Column: styled(Box)({
    flex: 1
  }),
  DetailBox: styled(Box)(({ theme }) => ({
    display: 'flex',
    columnGap: '48px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      rowGap: '8px'
    }
  })),
  SupervisorBox: styled(Box)({
    marginTop: '24px',
    '& .supervisor-info:not(:last-of-type)': {
      marginBottom: '8px'
    }
  })
};

interface Props {
  externship: ExternshipEntity;
  approvers: ExternshipApprover[];
}

interface ContactProps {
  name: string;
  phone: string;
  email: string;
}

const ContactInfo = ({ name, phone, email }: ContactProps): JSX.Element => {
  return (
    <>
      <Typography variant="EC_TYPE_3XS">{name}</Typography>
      <Typography variant="EC_TYPE_3XS">{phone}</Typography>
      <Typography variant="EC_TYPE_3XS">{email}</Typography>
    </>
  );
};

// TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - delete this file when flag is updated
const ExternshipDetails: React.FC<Props> = ({ externship, approvers }) => {
  const weeklyTimeSheets = externship.weeklyTimeSheets;
  const course = externship.course;
  const hours = course?.ClockHours || 0;

  const { approvedTime, submittedTime, approvedMinutes, pendingProgress } =
    getTimesheetStatusHours(weeklyTimeSheets, hours);

  const manager = approvers.find((a) => a.jobCategory === 'Manager');
  const supervisors = approvers.filter((a) => a.jobCategory === 'Supervisor');
  const percentComplete = hours ? 100 * (approvedMinutes / (hours & 60)) : 0;
  const progressSubtext = `Approved ${approvedTime} / Submitted ${submittedTime} / of ${hours}`;

  return (
    <BottomMarginContentWrapper bottomMargin="8px">
      <EcProgressBar
        percentComplete={percentComplete}
        pendingProgress={pendingProgress}
        subtext={progressSubtext}
        maxWidth="315px"
        status={externship.status}
        hideInactive
      />
      <Styled.Row>
        <Styled.Column>
          <Typography variant="EC_TYPE_2XS" className="detail-title">
            APPROVER(S):
          </Typography>
          <Styled.DetailBox>
            <Box>
              {!!manager && (
                <Box>
                  <Typography variant="EC_TYPE_3XS" fontWeight={700}>
                    Manager Contact
                  </Typography>
                  <ContactInfo
                    name={`${manager.firstName} ${manager.lastName}`}
                    phone={
                      'phone' in manager ? manager.phone : manager.phoneNumber
                    }
                    email={manager.email}
                  />
                </Box>
              )}
              {!!supervisors?.length && (
                <Styled.SupervisorBox>
                  <Typography variant="EC_TYPE_3XS" fontWeight={700}>
                    Supervisor Contact(s)
                  </Typography>
                  {supervisors.map((supervisor) => (
                    <Box key={supervisor.email} className="supervisor-info">
                      <ContactInfo
                        name={`${supervisor.firstName} ${supervisor.lastName}`}
                        phone={
                          'phone' in supervisor
                            ? supervisor.phone
                            : supervisor.phoneNumber
                        }
                        email={supervisor.email}
                      />
                    </Box>
                  ))}
                </Styled.SupervisorBox>
              )}
            </Box>
          </Styled.DetailBox>
        </Styled.Column>
        {!!course && (
          <Styled.Column>
            <Typography variant="EC_TYPE_2XS" className="detail-title">
              COURSE:
            </Typography>
            <Typography variant="EC_TYPE_3XS" fontWeight={700}>
              {course.Code}
            </Typography>
            <Typography variant="EC_TYPE_3XS">{course.Name}</Typography>
          </Styled.Column>
        )}
      </Styled.Row>
    </BottomMarginContentWrapper>
  );
};
export default ExternshipDetails;
