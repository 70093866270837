import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { onDownload, onPrint, onSave } from '@common/helpers/pdfHelpers';
import IconWithLabel from '@components/IconWithLabel';
import PrimaryModal from '@components/PrimaryModal';
import ResponsiveLayout from '@components/Layout/ResponsiveLayout';
import { KeycloakUserRole } from '@api/models/userApi.models';
import { StatusCodes } from 'http-status-codes';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import { useTagManager } from '@common/hooks/useTagManager';

const Styled = {
  Root: styled(Box)({
    position: 'sticky',
    top: 0,
    zIndex: 1000
  }),
  ActionBar: styled(Box)({
    backgroundColor: theme.palette.GOLD_1.main,
    display: 'flex',
    padding: '4px 12px',
    columnGap: '12px',
    borderBottom: `3px solid ${theme.palette.GOLD_1.main}`,

    '> *': {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      columnGap: '12px'
    }
  }),
  LeftContainer: styled(Box)({}),
  CenterContainer: styled(Box)({
    justifyContent: 'center'
  }),
  RightContainer: styled(Box)({
    justifyContent: 'right'
  }),
  MobileActionBar: styled(Box)({
    padding: '4px 12px',
    borderBottom: `3px solid ${theme.palette.GOLD_1.main}`,
    backgroundColor: theme.palette.GOLD_1.main
  }),
  MobileTitle: styled(Typography)({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '8px',
    color: 'white',
    textTransform: 'uppercase'
  }),
  MobileActionsContainer: styled(Box)({
    display: 'flex',
    columnGap: '12px',
    justifyContent: 'space-between'
  })
};

interface Props {
  onClose: () => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
  setDisplayEditButtons: (val: boolean) => void;
  disableActionBar: boolean;
}

const ResumePreviewActionBar: React.FC<Props> = ({
  onClose,
  loading,
  setLoading,
  setDisplayEditButtons,
  disableActionBar
}) => {
  const { sendToGtm } = useTagManager();
  const featureFlags = useFeatureFlags();

  const [candidateId] = useStore<number>(UseStoreKeys.CANDIDATE_ID);
  const [showModal, setShowModal] = React.useState(false);

  const handleLoading = React.useCallback(
    (val: boolean) => {
      setLoading(val);
    },
    [setLoading]
  );

  const handleDisplayEditButtons = React.useCallback(
    async (val: boolean) => {
      setDisplayEditButtons(val);
    },
    [setDisplayEditButtons]
  );

  const handlePrint = async (): Promise<void> => {
    try {
      handleLoading(true);
      await handleDisplayEditButtons(false);
      await onPrint();
      await handleDisplayEditButtons(true);

      sendToGtm('resume_builder', {
        action: 'print',
        role: KeycloakUserRole.CANDIDATE
      });
    } catch (error) {
      console.error('Error printing resume', error);
    } finally {
      handleLoading(false);
    }
  };

  const handleDownload = async (): Promise<void> => {
    try {
      handleLoading(true);
      await handleDisplayEditButtons(false);
      await onDownload();
      await handleDisplayEditButtons(true);

      sendToGtm('resume_builder', {
        action: 'download',
        role: KeycloakUserRole.CANDIDATE
      });
    } catch (error) {
      console.error('Error downloading resume', error);
    } finally {
      handleLoading(false);
    }
  };

  const handleSave = async (overwrite: boolean): Promise<void> => {
    try {
      handleLoading(true);
      await handleDisplayEditButtons(false);
      await onSave(candidateId, overwrite);
      await handleDisplayEditButtons(true);

      sendToGtm('resume_builder', {
        action: 'save',
        role: KeycloakUserRole.CANDIDATE
      });
    } catch (error: any) {
      console.error('Error saving resume', error);

      const { status } = error.response;

      if (status === StatusCodes.CONFLICT) {
        setShowModal(true);
      }
    } finally {
      handleDisplayEditButtons(true);
      handleLoading(false);
    }
  };

  const handleSaveConfirmation = async (): Promise<void> => {
    setShowModal(false);
    await handleSave(true);
  };

  const MobileActionBar: React.FC = () => {
    return (
      <Styled.MobileActionBar>
        <Styled.MobileTitle variant="EC_TYPE_XS">Preview</Styled.MobileTitle>
        <Styled.MobileActionsContainer>
          <IconWithLabel
            smallest
            title="Back"
            icon="ri-arrow-left-line"
            color="white"
            disabled={loading}
            onClick={onClose}
            dataTestId="resume-action-bar-back-btn"
          />
          {featureFlags.CANDIDATE_RESUME_BUILDER_PDF && (
            <>
              <IconWithLabel
                smallest
                title="Save to My Profile"
                icon="ri-save-line"
                color="white"
                disabled={loading || disableActionBar}
                onClick={(): Promise<void> => handleSave(false)}
                dataTestId="resume-action-bar-save-btn"
              />
              <IconWithLabel
                smallest
                title="Download"
                icon="ri-download-2-line"
                color="white"
                disabled={loading || disableActionBar}
                onClick={handleDownload}
                dataTestId="resume-action-bar-download-btn"
              />
              <IconWithLabel
                smallest
                title="Print"
                icon="ri-printer-line"
                color="white"
                disabled={loading || disableActionBar}
                onClick={handlePrint}
                dataTestId="resume-action-bar-print-btn"
              />
            </>
          )}
        </Styled.MobileActionsContainer>
      </Styled.MobileActionBar>
    );
  };

  const DesktopActionBar: React.FC = () => {
    return (
      <Styled.ActionBar>
        <Styled.LeftContainer>
          <IconWithLabel
            smallest
            title="Back"
            icon="ri-arrow-left-line"
            color="white"
            disabled={loading}
            onClick={onClose}
            dataTestId="resume-action-bar-back-btn"
          />
        </Styled.LeftContainer>
        <Styled.CenterContainer>
          <Typography
            variant="EC_TYPE_XS"
            sx={{ color: 'white', textTransform: 'uppercase' }}
          >
            Preview
          </Typography>
        </Styled.CenterContainer>
        {featureFlags.CANDIDATE_RESUME_BUILDER_PDF && (
          <Styled.RightContainer>
            <IconWithLabel
              smallest
              title="Save to My Profile"
              icon="ri-save-line"
              color="white"
              disabled={loading || disableActionBar}
              onClick={(): Promise<void> => handleSave(false)}
              dataTestId="resume-action-bar-save-btn"
            />
            <IconWithLabel
              smallest
              title="Download"
              icon="ri-download-2-line"
              color="white"
              disabled={loading || disableActionBar}
              onClick={handleDownload}
              dataTestId="resume-action-bar-download-btn"
            />
            <IconWithLabel
              smallest
              title="Print"
              icon="ri-printer-line"
              color="white"
              disabled={loading || disableActionBar}
              onClick={handlePrint}
              dataTestId="resume-action-bar-print-btn"
            />
          </Styled.RightContainer>
        )}
      </Styled.ActionBar>
    );
  };

  return (
    <Styled.Root data-testid="resume-action-bar-root">
      <ResponsiveLayout
        useMobileContainer={false}
        customBreakpoint={725}
        desktopComponent={<DesktopActionBar />}
        mobileComponent={<MobileActionBar />}
      />
      {showModal && (
        <PrimaryModal
          open={showModal}
          handleClose={(): void => setShowModal(false)}
          title="Overwrite your existing resume?"
          handlePrimaryAction={handleSaveConfirmation}
          primaryCtaLabel="Save this Resume"
          handleSecondaryAction={(): void => setShowModal(false)}
          secondaryCtaLabel="Cancel"
        >
          <Typography variant="EC_TYPE_BASE">
            Looks like you have a resume uploaded already. Do you want to save
            this generated resume over the existing resume on file?
          </Typography>
        </PrimaryModal>
      )}
    </Styled.Root>
  );
};

export default ResumePreviewActionBar;
