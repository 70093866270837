import * as React from 'react';
import {
  ApplicationStatus,
  ApplicationStatusCodebook
} from '@api/models/codebook.models';
import {
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import { AppliedCandidate } from '@api/models/jobApplicationApi.models';
import { ECTableRow } from '@components/TableComponents/CustomTableComponents';
import { getApplicationStatusLabel } from '@common/helpers/getApplicationStatusLabel';
import { getEmptyTableRows } from '@common/helpers/getEmptyTableRows';
import InvitedIcon from '@assets/svg/shortlist-icon.svg';
import JobCandidateInfo from '@pages/Employer/JobCandidates/JobCandidateInfo';
import JobCandidatesOptionsMenu from '@pages/Employer/JobCandidates/JobCandidatesOptionsMenu';
import { sortTableData } from '@common/helpers/sortByCategory';
import { styled } from '@mui/system';
import { useResponsiveLayoutContext } from '@components/Layout/ResponsiveLayout';
import { useTableSortContext } from '@common/context/tableSortContext';
import { VettedQuestionsAnswers } from '@api/models/jobApplicationApi.models';

const ROW_MIN_HEIGHT = 52;

const Styled = {
  Content: styled(Box)({
    display: 'flex',
    border: '1px solid #A6B0BF',
    borderRadius: '4px',
    overflow: 'hidden'
  }),
  TableContainer: styled(TableContainer, {
    shouldForwardProp: (prop) => prop !== 'breakpoint'
  })<{ breakpoint: number }>(({ breakpoint }) => ({
    flexGrow: 1,
    minHeight: '580px',
    minWidth: '420px',
    '& .MuiTableRow-root': {
      height: `${ROW_MIN_HEIGHT}px !important`
    },
    [`@media (max-width:${breakpoint})`]: {
      minHeight: 'unset'
    },
    ['@media (max-width:440px)']: {
      minWidth: '1px'
    }
  })),
  TableHead: styled(TableHead)(({ theme }) => ({
    height: '70px',
    backgroundColor: theme.palette.GRAY_1.main
  })),
  InvitedCell: styled(TableCell)({
    padding: '18px 0px 12px 12px'
  }),
  NameCell: styled(TableCell)(({ theme }) => ({
    padding: '0 0 0 30px',
    maxWidth: '200px',
    overflow: 'hidden',
    width: '40%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '180px',
      paddingLeft: '12px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '33%',
      maxWidth: '150px'
    },
    ['@media (max-width:385px)']: {
      maxWidth: '80px'
    }
  })),
  Cell: styled(TableCell)(({ theme }) => ({
    width: '34%',
    padding: '0 10px',
    [theme.breakpoints.down('sm')]: {
      width: '33%'
    },
    ['@media (max-width:385px)']: {
      maxWidth: '20px'
    }
  })),
  OptionsCell: styled(TableCell)({
    minWidth: '50px',
    width: '2%',
    padding: 0,
    ['@media (max-width:385px)']: {
      maxWidth: '1px'
    }
  }),
  Name: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'breakpoint'
  })<{ breakpoint: number }>(({ breakpoint }) => ({
    width: '100%',
    whiteSpace: 'initial',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [`@media (max-width:${breakpoint}px)`]: {
      fontSize: '14px'
    },
    ['@media (max-width:560px)']: {
      fontSize: '12px'
    }
  })),
  Text: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'breakpoint'
  })<{ breakpoint: number }>(({ breakpoint }) => ({
    width: '100%',
    [`@media (max-width:${breakpoint}px)`]: {
      fontSize: '14px'
    },
    ['@media (max-width:560px)']: {
      fontSize: '12px'
    }
  })),
  ModalBox: styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    width: '94%',
    maxWidth: '400px',
    padding: '46px 0 32px',
    borderRadius: '4px',
    overflow: 'hidden',
    '& i': {
      position: 'absolute',
      top: '22px',
      right: '16px',
      cursor: 'pointer',
      width: '13px',
      height: '13px',
      color: '#000000'
    }
  })
};

interface JobCandidateTableProps {
  candidates: AppliedCandidate[];
  vettedQuestionsAnswers?: VettedQuestionsAnswers[];
  handleCandidateStatusChange: (
    id: AppliedCandidate['jobApplicationId'],
    status: ApplicationStatusCodebook,
    candidate: AppliedCandidate
  ) => Promise<void>;
  updatedCandidate: AppliedCandidate | null;
  statuses: ApplicationStatusCodebook[];
  invitedCandidates?: number[] | null;
}

const JobCandidateTable: React.FC<JobCandidateTableProps> = ({
  candidates,
  vettedQuestionsAnswers,
  handleCandidateStatusChange,
  updatedCandidate,
  statuses,
  invitedCandidates
}) => {
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const history = useHistory();

  const { sortBy, sortOrder, page, rowsPerPage } = useTableSortContext();
  const { customBreakpoint, customBreakpointReached } =
    useResponsiveLayoutContext();

  const [vettedQA, setVettedQA] = React.useState<VettedQuestionsAnswers>();
  const [openCandidateModal, setOpenCandidateModal] =
    React.useState<boolean>(false);
  const [selectedCandidate, setSelectedCandidate] =
    React.useState<AppliedCandidate | null>(null);

  const emptyRows = getEmptyTableRows(page, rowsPerPage, candidates.length);

  const sortedCandidates: AppliedCandidate[] = React.useMemo(
    () =>
      sortTableData(
        candidates,
        sortOrder,
        sortBy as keyof Omit<AppliedCandidate, 'isInvited'>
      ),
    [candidates, sortOrder, sortBy]
  );

  React.useEffect(() => {
    const urlHasCandidateId =
      /\/job\/[A-Za-z0-9]+\/applicants\/[A-Za-z0-9]+/.test(location.pathname);

    if (!urlHasCandidateId && sortedCandidates.length) {
      history.replace(`${url}/${sortedCandidates[0].candidateId}`);
    }
  }, [sortedCandidates]);

  const handleRowClick = (
    event: React.MouseEvent<unknown>,
    candidate: AppliedCandidate
  ): void => {
    setSelectedCandidate(candidate);
    setOpenCandidateModal(true);
    history.push(`${url}/${candidate.candidateId}`);
  };

  const handleCloseModal = (): void => {
    setOpenCandidateModal(false);
  };

  const isSelected = (candidate: AppliedCandidate): boolean =>
    selectedCandidate?.candidateId === candidate.candidateId;

  const isUpdated = React.useCallback(
    (id: AppliedCandidate['candidateId']): boolean => {
      if (updatedCandidate && updatedCandidate.candidateId === id) {
        return updatedCandidate.candidateId === id;
      }
      return false;
    },
    [updatedCandidate]
  );

  const isInvited = (id: number): boolean => {
    const invitedCandidate = invitedCandidates?.some((c: any) => c === id);
    if (invitedCandidate) return true;
    return false;
  };

  React.useEffect(() => {
    if (selectedCandidate && vettedQuestionsAnswers) {
      const findVettedQA = vettedQuestionsAnswers.find(
        (item) => item.candidateId === selectedCandidate.candidateId
      );

      setVettedQA(findVettedQA);
    }
  }, [selectedCandidate, vettedQuestionsAnswers]);

  const JobCandidatePreview: React.FC = () => {
    interface RouteParams {
      candidateId: string;
    }

    const { candidateId } = useParams<RouteParams>();

    React.useEffect(() => {
      if (!selectedCandidate && candidateId && sortedCandidates.length) {
        const candidate = candidates.find(
          (c) => c.candidateId === parseInt(candidateId)
        );

        if (candidate) {
          setSelectedCandidate(candidate);
          setOpenCandidateModal(true);
        } else {
          history.replace(`${url}/${sortedCandidates[0].candidateId}`);
        }
      }
    }, [selectedCandidate, sortedCandidates]);

    React.useEffect(() => {
      if (selectedCandidate && vettedQuestionsAnswers) {
        const findVettedQA = vettedQuestionsAnswers.find(
          (item) => item.candidateId === selectedCandidate.candidateId
        );

        setVettedQA(findVettedQA);
      }
    }, [selectedCandidate, vettedQuestionsAnswers]);

    return (
      <>
        {!customBreakpointReached ? (
          <JobCandidateInfo candidate={selectedCandidate} vettedQA={vettedQA} />
        ) : (
          <Modal open={openCandidateModal} onClose={handleCloseModal}>
            <Styled.ModalBox>
              <i className="ri-close-line" onClick={handleCloseModal} />
              <JobCandidateInfo
                candidate={selectedCandidate}
                vettedQA={vettedQA}
              />
            </Styled.ModalBox>
          </Modal>
        )}
      </>
    );
  };

  return (
    <Styled.Content data-testid="job-candidate-table-content">
      <Styled.TableContainer breakpoint={customBreakpoint}>
        <Table>
          <Styled.TableHead>
            <TableRow>
              <Styled.InvitedCell />
              <Styled.NameCell>
                <Typography
                  data-testid="table-header-name"
                  variant="EC_TYPE_SM"
                >
                  Candidate Name
                </Typography>
              </Styled.NameCell>
              <Styled.Cell>
                <Typography
                  data-testid="table-header-status"
                  variant="EC_TYPE_SM"
                >
                  Status
                </Typography>
              </Styled.Cell>
              <Styled.Cell>
                <Typography
                  data-testid="table-header-date"
                  variant="EC_TYPE_SM"
                >
                  Submitted Date
                </Typography>
              </Styled.Cell>
              <Styled.OptionsCell />
            </TableRow>
          </Styled.TableHead>
          <TableBody>
            {sortedCandidates
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((candidate, idx) => {
                const isRowSelected = isSelected(candidate);
                const isRowUpdated = isUpdated(candidate.candidateId);
                candidate.isInvited = isInvited(candidate.candidateId);

                return (
                  <ECTableRow
                    key={candidate.candidateId}
                    hover={!isRowSelected}
                    role="radio"
                    tabIndex={-1}
                    selected={isRowSelected}
                    statusUpdate={isRowUpdated}
                  >
                    <Styled.InvitedCell>
                      {candidate.isInvited ? (
                        <img src={InvitedIcon} height="22" width="18" />
                      ) : null}
                    </Styled.InvitedCell>

                    <Styled.NameCell
                      onClick={(event): void =>
                        handleRowClick(event, candidate)
                      }
                    >
                      <Styled.Name
                        data-testid={`candidate-name-${idx}`}
                        variant="EC_TYPE_BASE"
                        breakpoint={customBreakpoint}
                      >
                        {candidate.firstName} {candidate.lastName}
                      </Styled.Name>
                    </Styled.NameCell>
                    <Styled.Cell
                      onClick={(event): void =>
                        handleRowClick(event, candidate)
                      }
                    >
                      <Styled.Text
                        data-testid={`candidate-status-${idx}`}
                        variant="EC_TYPE_BASE"
                        breakpoint={customBreakpoint}
                      >
                        {getApplicationStatusLabel(candidate.status)}
                      </Styled.Text>
                    </Styled.Cell>
                    <Styled.Cell
                      onClick={(event): void =>
                        handleRowClick(event, candidate)
                      }
                    >
                      <Styled.Text
                        data-testid={`candidate-submitted-date-${idx}`}
                        variant="EC_TYPE_BASE"
                        breakpoint={customBreakpoint}
                        sx={{
                          minWidth: !customBreakpointReached ? '85px' : 0
                        }}
                      >
                        {candidate.submittedDate}
                      </Styled.Text>
                    </Styled.Cell>

                    <Styled.OptionsCell>
                      {candidate.status.value !== ApplicationStatus.INVITED && (
                        <JobCandidatesOptionsMenu
                          id={candidate.jobApplicationId}
                          candidate={candidate}
                          statuses={statuses}
                          handleCandidateStatusChange={
                            handleCandidateStatusChange
                          }
                        />
                      )}
                    </Styled.OptionsCell>
                  </ECTableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow sx={{ height: ROW_MIN_HEIGHT * emptyRows }}>
                <Styled.Cell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Styled.TableContainer>
      <Switch>
        <Route path={`${path}/:candidateId`} component={JobCandidatePreview} />
      </Switch>
    </Styled.Content>
  );
};

export default JobCandidateTable;
