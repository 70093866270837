import * as React from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import Carousel from '@components/Carousel';
import MarketplaceSpotlightCard from '@components/MarketplaceSpotlightCard';
import { PromotedCompanySpotlight } from '@api/models/searchApi.models';
import { PromotedJob } from '@api/models/singleJobPostApi.models';
import PromotedJobItem from './PromotedJobItem';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useFetchPromotedContent } from '@common/fetches/useFetchPromotedContent';
import { useTagManager } from '@common/hooks/useTagManager';

const MIN_PROMO_JOBS = 4;

const MEDIA_QUERY = '@media (min-width:714px)';

const Styled = {
  Root: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'emptyJobsSection'
  })<{ emptyJobsSection: boolean }>(({ emptyJobsSection }) => ({
    margin: '0 0 40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '0 0 24px',
    rowGap: '16px',
    flexWrap: 'wrap',
    [MEDIA_QUERY]: {
      margin: '40px 0',
      rowGap: 'unset',
      columnGap: '32px',
      flexDirection: emptyJobsSection ? 'row-reverse' : 'row'
    },
    ':before, :after': {
      content: '""',
      width: '100%',
      borderBottom: `1px solid ${theme.palette.GRAY_2.main}`,
      opacity: 0.5
    },
    ':after': {
      height: '16px'
    }
  })),
  SectionTitle: styled(Typography)({
    textTransform: 'uppercase',
    lineHeight: '32px',
    color: theme.palette.GRAY_4.main
  }),
  JobRecommendationSection: styled(Box)({
    flex: 1,
    minWidth: '200px'
  }),
  SpotlightLogoWrapper: styled(Box)({
    flex: 1
  }),
  CarouselWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'negativeLeftMargin'
  })<{ negativeLeftMargin: boolean }>(({ negativeLeftMargin }) => ({
    [MEDIA_QUERY]: {
      marginLeft: negativeLeftMargin ? '-28px' : '0px'
    }
  }))
};

const LinkStyles = {
  borderRadius: '4px',
  outline: '1px solid #997755',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

const JobRecommendationSection: React.FC = () => {
  const { sendToGtm } = useTagManager();
  const { promotedJobs, employerSpotlight } = useFetchPromotedContent();

  const [displayedJobs, setDisplayedJobs] = React.useState<PromotedJob[]>([]);
  const [showMore, setShowMore] = React.useState(false);

  React.useEffect(() => {
    if (promotedJobs.length > 4) {
      setDisplayedJobs(promotedJobs.slice(0, MIN_PROMO_JOBS));
    } else {
      setDisplayedJobs(promotedJobs);
    }
  }, [promotedJobs]);

  const handleViewMoreClick = (): void => {
    if (showMore) {
      setDisplayedJobs(promotedJobs.slice(0, MIN_PROMO_JOBS));
      setShowMore(false);
    } else {
      setDisplayedJobs(promotedJobs);
      setShowMore(true);
    }
  };

  const handleEmployerClick = (emp: PromotedCompanySpotlight): void => {
    sendToGtm('promoted_company_click', {
      company_id: emp.id,
      company_name: emp.name
    });
  };

  const carouselItems = employerSpotlight?.map((emp) => (
    <Link
      onClick={(): void => handleEmployerClick(emp)}
      key={emp.id}
      component={RouterLink}
      to={`/employer/${emp.id}/profile`}
      underline="none"
      target="_blank"
      sx={LinkStyles}
    >
      <MarketplaceSpotlightCard companyCard={emp} />
    </Link>
  ));

  if (!promotedJobs.length && !employerSpotlight.length) {
    return <></>;
  }

  return (
    <Styled.Root emptyJobsSection={!promotedJobs.length}>
      <Styled.JobRecommendationSection>
        {!!displayedJobs.length && (
          <>
            <Styled.SectionTitle variant="EC_TYPE_XS">
              Hot Jobs
            </Styled.SectionTitle>
            {displayedJobs.map((job) => {
              return <PromotedJobItem key={job.id} job={job} />;
            })}
            {promotedJobs.length > 4 && (
              <Button onClick={handleViewMoreClick} sx={{ padding: 0 }}>
                <Typography
                  variant="EC_TYPE_2XS"
                  color={theme.palette.primary.main}
                >
                  {showMore ? 'Show less' : 'View more'}
                </Typography>
              </Button>
            )}
          </>
        )}
      </Styled.JobRecommendationSection>
      {!!employerSpotlight.length && (
        <Styled.SpotlightLogoWrapper>
          <Styled.SectionTitle variant="EC_TYPE_XS">
            Employer Spotlight
          </Styled.SectionTitle>
          <Styled.CarouselWrapper negativeLeftMargin={carouselItems.length > 1}>
            <Carousel timer={5000} items={carouselItems} useMaxWidth />
          </Styled.CarouselWrapper>
        </Styled.SpotlightLogoWrapper>
      )}
    </Styled.Root>
  );
};

export default JobRecommendationSection;
