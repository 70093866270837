import * as React from 'react';
import Image from '@assets/CandidateDashboardPage/work_and_learn_image.jpg';
import PromoBox from '@components/PromoBox';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';

const WorkLearnDescriptionCard: React.FC = () => {
  const featureFlags = useFeatureFlags();

  return (
    <PromoBox
      title="Work & Learn"
      subTitle="Work with our partner employers while you earn your diploma or degree through Auguste Escoffier School of Culinary Arts' convenient online programs."
      text="Work & Learn participants may be eligible for scholarships from Auguste Escoffier School of Culinary Arts, financial aid for those who apply and qualify, and tuition assistance from their employer."
      img={Image}
      imgAlt="escoffier students"
      cta={
        featureFlags.WORK_LEARN_CANDIDATE_PAGE
          ? { text: 'Learn More', path: '/candidate/work-and-learn' }
          : undefined
      }
    />
  );
};

export default WorkLearnDescriptionCard;
