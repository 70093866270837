import * as React from 'react';

type WindowDimensionProps = {
  height: number;
  width: number;
};

const getWindowDimensions = (): WindowDimensionProps => {
  const { innerHeight: height, innerWidth: width } = window;
  return {
    height,
    width
  };
};

/**
 * Use in component to get window inner width and height.
 * ex.: const { height, width } = useWindowDimensions();
 * @returns Window width and height.
 */
export const useWindowDimensions = (): WindowDimensionProps => {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  React.useEffect(() => {
    const handleResize = (): void => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', handleResize);
    return (): void => window.removeEventListener('resize', handleResize);
  }, []);
  return windowDimensions;
};
