import { convertMinutesToHoursAndMinutes } from './convertMinsToHoursAndMins';
import { TimesheetDay } from '@api/models/externshipApi.models';

export const getTimesheetTotalAsHoursAndMins = (
  dailyEntries: TimesheetDay[]
): string => {
  const totalMins = dailyEntries.reduce((a, b) => a + b.minutes, 0);
  const hoursAndMins = convertMinutesToHoursAndMinutes(totalMins);

  return hoursAndMins;
};
