import * as React from 'react';
import { Box, Grid, Icon, Link, Typography } from '@mui/material';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { BOX_SHADOW_1 } from '@themes/ui/escoffier';
import ExternalEduLink from '@components/ExternalEduLink';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';

const Styled = {
  Circle: styled(Box)(({ theme }) => ({
    height: '185px',
    width: '185px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `10px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    backgroundColor: theme.palette.WHITE.main,
    position: 'absolute',
    top: '-100px',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    lineHeight: '30px'
  })),
  Card: styled(Box)({
    padding: '0px 24px 40px',
    paddingTop: '90px',
    height: '100%',
    position: 'relative',
    boxShadow: BOX_SHADOW_1
  }),
  Icon: styled(Icon)(({ theme }) => ({
    color: theme.palette.GOLD_1.main
  }))
};

interface Props {
  scrollToContact: () => void;
}

const HowItWorksSection: React.FC<Props> = ({ scrollToContact }) => {
  const steps = [
    {
      icon: 'ri-briefcase-3-line',
      title: 'Get A Job With A Partner',
      content: (
        <BottomMarginContentWrapper
          bottomMargin="36px"
          dataTestId="work-learn-how-it-works-step-1"
        >
          <Typography variant="EC_TYPE_BASE">
            Search for jobs on EConnect with an approved Work & Learn employer
            partner.
          </Typography>
          <Typography variant="EC_TYPE_BASE">
            Check out jobs on{' '}
            <Link component={RouterLink} to="/econnect" underline="none">
              EConnect
            </Link>
          </Typography>
        </BottomMarginContentWrapper>
      )
    },
    {
      icon: 'ri-file-edit-line',
      title: 'Register For Work & Learn',
      content: (
        <BottomMarginContentWrapper
          bottomMargin="36px"
          dataTestId="work-learn-how-it-works-step-2"
        >
          <Typography variant="EC_TYPE_BASE">
            When you&apos;re ready to enroll at Escoffier, contact an admissions
            representative. Your team of Escoffier advisors will help you with
            your application, enrollment, setting up financial aid, scheduling
            classes, and answering any questions you may have.
          </Typography>
          <Typography variant="EC_TYPE_BASE">
            To get started,{' '}
            <Link
              onClick={scrollToContact}
              underline="none"
              sx={{ cursor: 'pointer' }}
            >
              Contact Us
            </Link>
          </Typography>
        </BottomMarginContentWrapper>
      )
    },
    {
      icon: 'ri-graduation-cap-fill',
      title: 'Earn Your Diploma or Degree',
      content: (
        <BottomMarginContentWrapper
          bottomMargin="36px"
          dataTestId="work-learn-how-it-works-step-3"
        >
          <Typography variant="EC_TYPE_BASE">
            Join the ranks of fellow aspiring culinary professionals here at
            Escoffier and earn credentials that will last a lifetime. Upon
            graduation, our alumni association is excited to welcome you to a
            community of culinarians to rely on for years to come.
          </Typography>
          <Typography variant="EC_TYPE_BASE">
            Check out available courses at{' '}
            <ExternalEduLink
              link="https://www.escoffier.edu/"
              text={'Escoffier.edu'}
            />
          </Typography>
        </BottomMarginContentWrapper>
      )
    }
  ];

  return (
    <Box>
      <Typography variant="EC_TYPE_3XL" color="primary" mb={'36px'}>
        HOW IT WORKS:
      </Typography>
      <Grid container columnSpacing={3} rowSpacing={5}>
        {steps.map((step, idx) => (
          <Grid md={12} lg={4} xl={4} item key={step.title} mt={14}>
            <Styled.Card key={step.title}>
              <Styled.Circle>
                <Typography variant="EC_TYPE_3XL" color="GRAY_4.main" mb={1}>
                  STEP {idx + 1}
                </Typography>
                <Styled.Icon fontSize="large" className={step.icon} />
              </Styled.Circle>
              <Typography variant="EC_TYPE_2XL" mb={3} mt={3}>
                {step.title}
              </Typography>
              {step.content}
            </Styled.Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HowItWorksSection;
