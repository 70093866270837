import * as React from 'react';
import { Box, IconButton, Paper, Popper, Typography } from '@mui/material';
import { BookmarkApi } from '@api/Bookmark.api';
import { GlrcVideo } from '@interfaces/glrc.interfaces';
import { styled } from '@mui/system';
import { useTagManager } from '@common/hooks/useTagManager';
import { VideoPlayerModal } from '@components/EmbeddedVideo';

const Styled = {
  Root: styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'hidden'
  })<{ hidden?: boolean }>(({ hidden = false }) => ({
    ...(!hidden && {
      overflow: 'hidden'
    }),
    ':hover': {
      position: 'relative',
      zIndex: 1,
      '.image-wrapper': {
        '&:before': {
          transition: 'opacity 0.5s',
          opacity: '0'
        },
        cursor: 'pointer'
      }
    }
  })),
  ImageWrapper: styled(Box)({
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      background: 'black',
      opacity: '0.5'
    }
  }),
  Image: styled('img')<{ withFooter?: boolean; smScreenHeight?: string }>(
    ({ withFooter, smScreenHeight }) => ({
      display: 'block',
      width: '100%',
      height: withFooter ? '200px' : '150px',
      objectFit: 'cover',
      '@media (max-width: 600px)': {
        height: smScreenHeight || '250px'
      }
    })
  ),
  PlayButton: styled(IconButton)(({ theme }) => ({
    color: theme.palette.WHITE.main,
    fontSize: '40px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  })),
  VideoLength: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'absolutePosition'
  })<{ absolutePosition?: boolean }>(({ absolutePosition = false, theme }) => ({
    color: theme.palette.GRAY_1.main,
    marginBottom: 0,
    ...(absolutePosition && {
      position: 'absolute',
      bottom: '16px',
      left: '16px'
    })
  })),
  BookmarkTitleWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'hidden'
  })<{ hidden?: boolean }>(({ hidden = false }) => ({
    margin: '16px 16px 16px 0px',
    visibility: hidden ? 'hidden' : 'visible'
  })),
  Title: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'hidden' && prop !== 'bookmarkView'
  })<{ hidden?: boolean; bookmarkView?: boolean }>(
    ({ hidden = false, bookmarkView = false, theme }) => ({
      paddingLeft: '12px',
      whiteSpace: hidden ? 'normal' : 'nowrap',
      textOverflow: hidden ? 'unset' : 'ellipsis',
      overflow: hidden ? 'visible' : 'hidden',
      color: theme.palette.secondary.main,
      margin: bookmarkView ? '0px' : '16px 16px 16px 0px',
      textTransform: 'uppercase'
    })
  ),
  Popper: styled(Popper)(({ theme }) => ({
    backgroundColor: theme.palette.GRAY_4.main,
    width: '100%'
  })),
  PopperContent: styled(Box)(({ theme }) => ({
    padding: '16px',
    backgroundColor: theme.palette.GRAY_4.main
  })),
  TopContent: styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }),
  IconButton: styled(IconButton)(({ theme }) => ({
    color: theme.palette.GRAY_1.main
  }))
};

interface Props extends GlrcVideo {
  bookmarked: boolean;
  companyName?: string;
  userId: string;
  onBookmarkDeleted?: (contentId: number) => void;
  bookmarkView?: boolean;
  withFooter?: boolean;
  smScreenHeight?: string;
}

const GlrcEmbeddedVideo: React.FC<Props> = ({
  id,
  url,
  bookmarked,
  imgUrl,
  title,
  length,
  companyName,
  userId,
  onBookmarkDeleted,
  bookmarkView,
  withFooter = true,
  smScreenHeight
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [_bookmarked, setBookmarked] = React.useState(bookmarked);

  const { sendToGtm } = useTagManager();

  const handleMouseOver = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseOut = (): void => {
    setAnchorEl(null);
  };

  const handleOpenModal = (): void => {
    setOpenModal(true);
    sendToGtm('video_player', {
      action: 'open',
      video_url: url,
      video_title: title,
      role: 'glrc-user',
      company_name: companyName
    });
  };

  const handleCloseModal = (): void => {
    setOpenModal(false);
    sendToGtm('video_player', {
      action: 'close',
      video_url: url,
      video_title: title,
      role: 'glrc-user',
      company_name: companyName
    });
  };

  const handleBookmarkClick = async (e): Promise<void> => {
    e.stopPropagation();
    try {
      if (_bookmarked) {
        await BookmarkApi.removeBookmark(userId, id);
        setBookmarked(false);
        onBookmarkDeleted && onBookmarkDeleted(id);
      } else {
        await BookmarkApi.addBookmark(userId, { contentId: id });
        setBookmarked(true);
      }
    } catch (error) {
      console.error('Error for GlrcEmbeddedVideo.handleBookmarkClick()', error);
    }
  };

  const getAriaLabel = (): string => {
    return _bookmarked ? 'bookmark video' : 'remove bookmarked video';
  };

  return (
    <>
      <Styled.Root
        onMouseLeave={handleMouseOut}
        elevation={3}
        hidden={!!anchorEl}
      >
        <Styled.ImageWrapper
          onMouseEnter={handleMouseOver}
          onClick={!anchorEl ? undefined : handleOpenModal}
          className="image-wrapper"
        >
          <Styled.Image
            src={imgUrl ?? ''}
            alt={`${title} thumbnail`}
            withFooter={withFooter}
            smScreenHeight={smScreenHeight}
          />
          {!anchorEl && (
            <Styled.PlayButton aria-label="play video">
              <i className="ri-play-circle-line"></i>
            </Styled.PlayButton>
          )}
        </Styled.ImageWrapper>
        {bookmarkView ? (
          <Styled.BookmarkTitleWrapper hidden={!!anchorEl}>
            <Styled.Title
              bookmarkView
              variant="EC_TYPE_2XL"
              sx={{
                textTransform: 'none'
              }}
            >
              QuickSkill
            </Styled.Title>
            <Styled.Title variant="EC_TYPE_LG" sx={{ color: 'BLACK.main' }}>
              {title}
            </Styled.Title>
          </Styled.BookmarkTitleWrapper>
        ) : (
          <>
            {/* By default the component is with Footer */}
            {withFooter && (
              <Styled.Title variant="EC_TYPE_2XL" hidden={!!anchorEl}>
                {title}
              </Styled.Title>
            )}
          </>
        )}
        {
          <>
            {withFooter && (
              <Styled.Popper
                open={!!anchorEl}
                anchorEl={anchorEl}
                disablePortal
              >
                <Styled.PopperContent>
                  <Styled.TopContent>
                    <Styled.VideoLength variant="EC_TYPE_2XL">
                      {length}
                    </Styled.VideoLength>
                    <Styled.IconButton
                      onClick={handleBookmarkClick}
                      aria-label={getAriaLabel()}
                    >
                      <i
                        className={`ri-bookmark-${
                          _bookmarked ? 'fill' : 'line'
                        }`}
                      />
                    </Styled.IconButton>
                  </Styled.TopContent>
                  <Styled.Title
                    variant="EC_TYPE_2XL"
                    sx={{ color: 'GRAY_2.main' }}
                    hidden={!!anchorEl}
                  >
                    {title}
                  </Styled.Title>
                </Styled.PopperContent>
              </Styled.Popper>
            )}
          </>
        }
      </Styled.Root>
      <VideoPlayerModal
        open={openModal}
        url={url}
        handleClose={handleCloseModal}
      />
    </>
  );
};

export default GlrcEmbeddedVideo;
