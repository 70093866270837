import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ExternshipApi } from '@api/Externships.api';
import { ExternshipEntity } from '@api/models/externshipApi.models';
import { getDisplayedExternships } from '@common/helpers/externshipHelpers/getDisplayedExternships';

interface FetchExternshipsForEmployer {
  externships?: ExternshipEntity[];
  loaded: true | null;
  setExternships: Dispatch<SetStateAction<ExternshipEntity[] | undefined>>;
}

export const useFetchExternshipsForEmployer = (
  companyId: number,
  employerId: number,
  isEmployer: boolean,
  isRecruiter: boolean
): FetchExternshipsForEmployer => {
  const [externships, setExternships] = useState<ExternshipEntity[]>();
  const [loaded, setLoaded] = useState<true | null>(null);

  const fetchForAdmin = async (): Promise<void> => {
    try {
      const res = await ExternshipApi.getByCompanyId(companyId);

      const displayedExternships = getDisplayedExternships(res.data);

      setExternships(displayedExternships);
    } catch (error: any) {
      console.error(
        `Error for useFetchExternshipsForEmployer -> ExternshipApi.getByCompanyId(): ${error}`
      );
    } finally {
      setLoaded(true);
    }
  };

  const fetchForRecruiter = async (): Promise<void> => {
    try {
      const res = await ExternshipApi.getForRecruiter(companyId, employerId);

      const displayedExternships = getDisplayedExternships(res.data);

      setExternships(displayedExternships);
    } catch (error: any) {
      console.error(
        `Error for useFetchExternshipsForEmployer -> ExternshipApi.getForRecruiter(): ${error}`
      );
    } finally {
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (isEmployer) {
      fetchForAdmin();
    }

    if (isRecruiter) {
      fetchForRecruiter();
    }
  }, [isEmployer, isRecruiter]);

  return { externships, loaded, setExternships };
};
