import {
  ApplicantCsv,
  ApplicantsCsvReq
} from '@api/models/adminDashboardApi.models';
import { AdminDashboardApi } from '@api/AdminDashboard.api';
import { CompanyApi } from '@api/Company.api';

export type FetchApplicantsCsv = {
  fetchApplicantsCsv: () => Promise<ApplicantCsv[]>;
};

export const useFetchApplicantsCsv = (
  req: ApplicantsCsvReq,
  companyId?: number,
  parentCompanyId?: number
): FetchApplicantsCsv => {
  const fetchApplicantsCsv = async (): Promise<ApplicantCsv[]> => {
    let data = [];

    try {
      let res;
      if (parentCompanyId) {
        if (companyId) {
          res = await CompanyApi.getSubsidiaryCompanyApplicantsCsv(
            parentCompanyId,
            companyId,
            req
          );
        } else {
          res = await CompanyApi.getParentCompanyApplicantsCsv(
            parentCompanyId,
            req
          );
        }
      } else {
        if (companyId) {
          res = await AdminDashboardApi.getApplicantsDetailsCsvByCompanyId(
            companyId,
            req
          );
        } else {
          res = await AdminDashboardApi.getApplicantsDetailsCsv(req);
        }
      }

      data = res.data;
    } catch (error: any) {
      console.error(
        'Error for useFetchApplicantsCsv -> AdminDashboardApi.getApplicantsDetailsCsv()',
        error
      );
    }

    return data;
  };

  return { fetchApplicantsCsv };
};
