import { Dispatch, SetStateAction } from 'react';
import { AdminDashboardApi } from '@api/AdminDashboard.api';
import { CompanyProfile } from '@api/models/companyApi.models';
import { CompanyStatus } from '@api/models/adminDashboardApi.models';
import { PartnershipLevel } from '@api/permissions/companyPartnershipLevel.permissions';

type SetCompanies = Dispatch<SetStateAction<CompanyStatus[]>>;

export const updatePartnershipLevel = async (
  companyId: number,
  value: PartnershipLevel,
  setCompanies: SetCompanies
): Promise<void> => {
  await AdminDashboardApi.updateCompanyPartnershipLevel(companyId, value).then(
    () => {
      setCompanies((prev) => {
        return prev.map((company) => {
          if (company.id === companyId) {
            return {
              ...company,
              partnershipLevel: value
            };
          }

          return company;
        });
      });
    }
  );
};

export const updateWorkLearnPartner = async (
  companyId: number,
  workLearn: boolean,
  setCompanies: SetCompanies
): Promise<void> => {
  const workLearnVal = !workLearn;

  await AdminDashboardApi.updateCompanyWorkLearn(companyId, workLearnVal).then(
    () => {
      setCompanies((prev) => {
        return prev.map((company) => {
          if (company.id === companyId) {
            return {
              ...company,
              workLearnPartner: !workLearn
            };
          }

          return company;
        });
      });
    }
  );
};

export const handleActivityToggle = async (
  companyId: number,
  status: boolean,
  setCompanies: SetCompanies
): Promise<void> => {
  await AdminDashboardApi.updateCompanyEnabledStatus(companyId, !status).then(
    () => {
      setCompanies((prev) => {
        return prev.map((company) => {
          if (company.id === companyId) {
            return {
              ...company,
              enabled: !status
            };
          }

          return company;
        });
      });
    }
  );
};

export const handleMarkCompanyPossibleParent = async (
  companyId: number,
  possibleParent: boolean,
  setCompanies: SetCompanies
): Promise<void> => {
  await AdminDashboardApi.markCompanyPossibleParent(
    companyId,
    !possibleParent
  ).then(() => {
    setCompanies((prev) => {
      return prev.map((company) => {
        if (company.id === companyId) {
          const updatedCompany = {
            ...company,
            possibleParent: !possibleParent
          };

          if (possibleParent) {
            updatedCompany.subsidiaries = [];
            updatedCompany.parentCompany = null;
          } else {
            updatedCompany.subsidiaries = [
              { id: company.id, name: company.companyName } as CompanyProfile
            ];
          }

          return updatedCompany;
        }

        return company;
      });
    });
  });
};
