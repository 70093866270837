import * as React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import CtaButton from '@components/CtaButton';
import { mediaQueries } from '@themes/ui/variables';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { styled } from '@mui/system';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const Styled = {
  Root: styled(Box)({
    maxWidth: '1200px',
    margin: '0 auto',
    '& .title-box': {
      padding: '0 10px',
      [mediaQueries.MIN_WIDTH_361]: {
        padding: '0 16px'
      },
      [mediaQueries.MIN_WIDTH_481]: {
        padding: '0 32px'
      },
      [mediaQueries.MIN_WIDTH_601]: {
        padding: '0 65px'
      }
    },
    '& .title': {
      textTransform: 'uppercase',
      marginBottom: '10px'
    }
  })
};

interface CareerPathLayoutProps {
  disableQuizBtn?: boolean;
  disableSubtitle?: boolean;
}

const CareerPathLayout: React.FC<CareerPathLayoutProps> = ({
  disableQuizBtn = false,
  disableSubtitle = false,
  children
}) => {
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const bgColor =
    width < 671 ? theme.palette.WHITE.main : theme.palette.GRAY_1.main;

  return (
    <RootPageLayout muiMaxWidth={false} bgColor={bgColor} pr="0px" pl="0px">
      <Styled.Root>
        <Box className="title-box">
          {!disableQuizBtn && (
            <CtaButton
              to="/quiz/1/results"
              label="Retake Quiz"
              variant="text"
              color="GOLD_1"
              textColor={theme.palette.GOLD_1.main}
              leftArrow
            />
          )}
          <Typography variant="EC_TYPE_4XL" className="title">
            my career journey
          </Typography>
          {!disableSubtitle && (
            <Typography variant="EC_TYPE_BASE" className="subtitle">
              Every career journey is different, but many jobs in the culinary
              industry follow predictable career track. Click on a job bubble to
              learn more details about what it takes to be qualified for the
              role.
            </Typography>
          )}
        </Box>
        {children}
      </Styled.Root>
    </RootPageLayout>
  );
};

export default CareerPathLayout;
