import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { Badge } from '@common/mappings/BadgeMappings';
import { JobCard } from '@api/models/singleJobPostApi.models';
import JobCardLogoSection from './JobCardLogoSection';
import { styled } from '@mui/system';

const Styled = {
  DirectConnectWrapper: styled(Box)({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }),
  DirectConnectDescription: styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    color: theme.palette.GRAY_3.main,
    textAlign: 'center',
    margin: '0px 20px 14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '4',
    WebkitBoxOrient: 'vertical'
  })),
  JobCardDescription: styled('div')(({ theme }) => ({
    padding: '18px 20px 14px',
    '& p': {
      color: theme.palette.GRAY_3.main,
      fontWeight: 400,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '3',
      WebkitBoxOrient: 'vertical'
    }
  })),
  BenefitsBox: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'threeBenefits'
  })<{ threeBenefits: boolean }>(({ threeBenefits, theme }) => ({
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: threeBenefits ? 0 : '0px 20px',
    minHeight: threeBenefits ? '18px' : '28px',
    '& i': {
      color: theme.palette.primary.main,
      fontSize: '4.17px',
      marginLeft: '8px',
      marginRight: '8px'
    }
  }))
};

interface BenefitsSectionProps {
  benefits: string[];
}

const BenefitsSection = ({ benefits }: BenefitsSectionProps): JSX.Element => {
  return (
    <>
      {benefits.length === 3 ? (
        <>
          <Styled.BenefitsBox
            sx={{ typography: 'EC_TYPE_3XS' }}
            threeBenefits={benefits.length === 3}
          >
            <>
              <Typography variant="EC_TYPE_3XS" sx={{ marginLeft: 'auto' }}>
                {benefits[0]}
              </Typography>
              <i className="ri-checkbox-blank-circle-fill" />
              <Typography variant="EC_TYPE_3XS" sx={{ marginRight: 'auto' }}>
                {benefits[1]}
              </Typography>
            </>
          </Styled.BenefitsBox>
          <Box sx={{ margin: '0 auto' }}>
            <Typography variant="EC_TYPE_3XS">{benefits[2]}</Typography>
          </Box>
        </>
      ) : (
        <Styled.BenefitsBox
          threeBenefits={false}
          sx={{ typography: 'EC_TYPE_3XS' }}
        >
          {benefits.map((benefit, idx) => (
            <React.Fragment key={benefit}>
              <Typography key={benefit} variant="EC_TYPE_3XS">
                {benefit}
              </Typography>
              {idx !== benefits.length - 1 && (
                <i className="ri-checkbox-blank-circle-fill" />
              )}
            </React.Fragment>
          ))}
        </Styled.BenefitsBox>
      )}
    </>
  );
};

interface Props extends JobCard {
  isDirectConnectPost: boolean;
  badge?: Badge;
}

const MainCardContent: React.FC<Props> = ({
  isDirectConnectPost,
  companyName,
  threeKeyBenefits,
  companyLogo,
  jobCardDescription,
  badge
}) => {
  if (isDirectConnectPost) {
    return (
      <Styled.DirectConnectWrapper>
        <JobCardLogoSection logo={companyLogo} name={companyName} />
        <Styled.DirectConnectDescription variant="EC_TYPE_2XS">
          {jobCardDescription}
        </Styled.DirectConnectDescription>
      </Styled.DirectConnectWrapper>
    );
  }

  return (
    <>
      <Styled.JobCardDescription>
        <Typography variant="EC_TYPE_2XS">{jobCardDescription}</Typography>
      </Styled.JobCardDescription>
      <Box sx={{ height: '100%' }}></Box>
      {threeKeyBenefits && <BenefitsSection benefits={threeKeyBenefits} />}
      <JobCardLogoSection logo={companyLogo} name={companyName} badge={badge} />
    </>
  );
};

export default MainCardContent;
