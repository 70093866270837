/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Box, Icon, Typography, useTheme } from '@mui/material';
import {
  CareerPathRole,
  CareerRole
} from '@interfaces/careerJourney.interfaces';
import NodeCircle, { NodeCircleColors } from '@components/NodeCircle';
import CareerNodePopover from './CareerNodePopover';
import { styled } from '@mui/system';
import { useTagManager } from '@common/hooks/useTagManager';

const REG_NODE_SIZE = 55;
const SM_NODE_SIZE = 34;

const REG_LINE_SIZE = 10;
const SM_LINE_SIZE = REG_LINE_SIZE / 2;

export type CareerNodeStatus = 'incomplete' | 'complete' | 'current';

export type NodeConnectorPosition = {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
};

interface NodeConnector extends NodeConnectorPosition {
  solid: boolean;
  smallNodes: boolean;
}

interface CareerPathNodeProps<T extends CareerPathRole | CareerRole> {
  index: number;
  status: CareerNodeStatus;
  smallNodes: boolean;
  displayVertical: boolean;
  role: T;
  isSavedGoal: boolean;
  expandedRole: CareerPathRole | CareerRole | null;
  setExpandedRole: React.Dispatch<React.SetStateAction<T | null>>;
  setCareerGoal: (careerRoleId: number) => Promise<void>;
  solidConnector?: boolean;
  setLoaded?: React.Dispatch<React.SetStateAction<boolean>>;
  nodePositions?: NodeConnectorPosition;
  disableMapPin?: boolean;
}

const NodeContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'displayVertical'
})<{ displayVertical: boolean }>(({ displayVertical, theme }) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: displayVertical ? 'row' : 'column',
  maxWidth: displayVertical ? 'max-content' : `${REG_NODE_SIZE}px`,
  maxHeight: displayVertical ? `${SM_NODE_SIZE}px` : 'max-content',
  alignItems: 'center',
  justifyContent: 'start',
  columnGap: '11px',
  textAlign: displayVertical ? 'left' : 'center',
  '& .node-name-text': {
    textTransform: 'uppercase',
    color: theme.palette.GOLD_1.main
  }
}));

const NodeConnector = (props: NodeConnector): JSX.Element => {
  const theme = useTheme();
  const { smallNodes, solid, x1, y1, x2, y2 } = props;
  const lineWidth = smallNodes ? SM_LINE_SIZE : REG_LINE_SIZE;
  const dashArray = solid ? '' : '5 5';

  return (
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      id="node-svg"
    >
      <line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke={theme.palette.primary.main}
        strokeWidth={lineWidth}
        strokeDasharray={dashArray}
        id="svg-line"
        data-testid="career-path-svg-line"
      />
    </svg>
  );
};

const MapPin = styled(Icon, {
  shouldForwardProp: (prop) =>
    prop !== 'displayVertical' && prop !== 'smallNodes'
})<{ displayVertical: boolean; smallNodes: boolean }>(
  ({ theme, displayVertical, smallNodes }) => ({
    position: 'absolute',
    transform: 'translateY(-36px)',
    color: theme.palette.red2.main,
    fontSize: '30px',
    lineHeight: '30px',
    ...(displayVertical && {
      transform: 'translateX(-32px) rotate(-90deg)'
    }),
    ...(displayVertical &&
      smallNodes && {
        fontSize: '18px',
        lineHeight: '18px',
        transform: 'translateX(-20px) rotate(-90deg)'
      })
  })
);

const CareerPathNode = <T extends CareerPathRole | CareerRole>({
  index,
  role,
  status,
  smallNodes,
  nodePositions,
  isSavedGoal,
  expandedRole,
  setExpandedRole,
  setLoaded,
  setCareerGoal,
  solidConnector = false,
  displayVertical,
  disableMapPin = false
}: CareerPathNodeProps<T>): React.ReactElement => {
  // used to force a render on initial load to display connector lines
  React.useEffect(() => {
    setLoaded && setLoaded(true);
  }, []);

  const { sendToGtm } = useTagManager();
  const nodeRef = React.useRef<HTMLElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [popoverExpanded, setPopoverExpanded] = React.useState<string | false>(
    false
  );
  const [nodeHovering, setNodeHovering] = React.useState(false);
  const [menuHovering, setMenuHovering] = React.useState(false);
  const open = Boolean(anchorEl);

  // True if an anchorEl exists and the user is hovering over a node or a menu
  const isOpen =
    (nodeHovering || menuHovering || !!popoverExpanded) && Boolean(anchorEl);

  const handleSetExpandedRole = React.useCallback(
    (role: T | null) => {
      setExpandedRole(role);
    },
    [setExpandedRole]
  );

  const handleNodeClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (!open) {
      setAnchorEl(event.currentTarget);
      setNodeHovering(true);
    }
  };

  const handlePopoverClose = (): void => {
    setExpandedRole(null);
    setNodeHovering(false);
    setMenuHovering(false);
    setPopoverExpanded(false);
    setAnchorEl(null);
  };

  const handleExpandPopover =
    (panel: string, role: T) =>
    // eslint-disable-next-line @typescript-eslint/indent
    (event: React.SyntheticEvent, isExpanded: boolean): void => {
      setPopoverExpanded(isExpanded ? panel : false); // eslint-disable-line @typescript-eslint/indent
      handleSetExpandedRole(role); // eslint-disable-line @typescript-eslint/indent
    }; // eslint-disable-line @typescript-eslint/indent

  const handleNodeEnter = (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ): void => {
    if (expandedRole === null) {
      setAnchorEl(event.currentTarget);
      setNodeHovering(true);
    } else {
      return;
    }
  };

  const handleNodeLeave = (): void => {
    // Small delay to give the user time to move the mouse into the popper element
    setTimeout(() => {
      setNodeHovering(false);
    }, 150);
  };

  const handleMenuEnter = (): void => {
    setMenuHovering(true);
  };

  const handleMenuLeave = (): void => {
    setMenuHovering(false);
  };

  const handleEscPress = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      handlePopoverClose();
    }
  };

  // TODO: Troubleshoot tabbing between nodes for accessibility
  const handleNodeKeyPress = (
    event: React.KeyboardEvent<HTMLElement>
  ): void => {
    if (event.code === 'Space') {
      event.stopPropagation();
      event.preventDefault();
      if (!open) {
        setAnchorEl(!open ? event.currentTarget : null);
        setNodeHovering(true);
      }
    }
  };

  const handleSetGoal = React.useCallback(
    async (careerRoleId: number): Promise<void> => {
      await setCareerGoal(careerRoleId).then(() => {
        sendToGtm('set_career_goal', {
          career_id: role.id,
          career_title: role.name
        });
      });
    },
    [setCareerGoal, role]
  );

  const bgColor: NodeCircleColors = React.useMemo(() => {
    switch (status) {
      case 'current':
      case 'complete':
        return NodeCircleColors.BG_COMPLETE;
      case 'incomplete':
        return NodeCircleColors.BG_INCOMPLETE;
    }
  }, [status]);

  // Handle escape key press
  React.useEffect(() => {
    if (!isOpen && !!expandedRole) {
      setTimeout(() => {
        setPopoverExpanded(false);
      }, 300);
    }

    if (isOpen || !!expandedRole) {
      document.addEventListener('keydown', handleEscPress, true);

      return (): void => {
        document.removeEventListener('keydown', handleEscPress, true);
      };
    }
  }, [isOpen, expandedRole]);

  return (
    <>
      {nodePositions && (
        <NodeConnector
          solid={solidConnector}
          smallNodes={smallNodes}
          {...nodePositions}
        />
      )}
      <NodeContainer
        displayVertical={displayVertical}
        data-testid="career-track-node"
        id="node-container"
        onMouseLeave={handleNodeLeave}
      >
        {status === 'current' && !disableMapPin && (
          <MapPin
            className="ri-map-pin-2-fill"
            data-testid="current-position-marker"
            displayVertical={displayVertical}
            smallNodes={smallNodes}
          />
        )}
        <NodeCircle
          dataTestId="career-track-node-circle"
          iconDataTestId="current-job-icon"
          bgColor={bgColor}
          smallNodes={smallNodes}
          id={`path-node-${index}`}
          ref={nodeRef}
          tabIndex={index + 1}
          checked={status === 'complete'}
          onMouseOver={handleNodeEnter}
          onClick={handleNodeClick}
          onKeyDown={handleNodeKeyPress}
          enableHover
        />
        <Typography
          data-testid="career-track-node-label"
          variant="EC_TYPE_XL"
          className="node-name-text"
        >
          {role.name}
        </Typography>
      </NodeContainer>
      {isOpen && (
        <CareerNodePopover
          index={index + 1}
          open={isOpen}
          role={role}
          anchorEl={anchorEl}
          isSavedGoal={isSavedGoal}
          expanded={popoverExpanded}
          handleExpand={handleExpandPopover}
          handlePopoverClose={handlePopoverClose}
          handleMenuEnter={handleMenuEnter}
          handleMenuLeave={handleMenuLeave}
          setCareerGoal={handleSetGoal}
        />
      )}
    </>
  );
};

export default CareerPathNode;
