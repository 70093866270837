import * as React from 'react';
import ExternshipProposalApproversForm, { useExternshipProposalApproverForm } from './ExternshipProposalApproversForm';
import { ExternshipApi } from '@api/Externships.api';
import ExternshipProposalStepLayout from '@pages/Candidate/ExternshipProposalPage/ExternshipProposalStepLayout';
import { ExternshipProposalApproversUpsertPayload } from '@api/models/externshipProposalApi.models';
import { useFetchExternships } from '@common/fetches/useFetchExternships';
import { useParams } from 'react-router-dom';
import { useStepperContext } from '@common/context/stepperContext';

interface Props {
  externshipId?: number;
  handleStepper: (next: boolean) => void;
}

const ExternshipProposalApproversStep: React.FC<Props> = ({
  externshipId,
  handleStepper,
}) => {
  const { stepperLoading, setStepperLoading } = useStepperContext();
  const [errMsg, setErrMsg] = React.useState<string>();

  const { externshipId: paramsExternshipId } = useParams<{
    externshipId?: string;
  }>();

  const { externshipById: draftExternship } = useFetchExternships(
    null,
    paramsExternshipId ?? externshipId
  );

  const handleValidExternshipApproversFormRequest = async (
    payload: ExternshipProposalApproversUpsertPayload,
    onSuccess?: () => void
  ): Promise<void> => {
    try {
      setStepperLoading(true);

      if (!draftExternship) {
        setErrMsg('Missing Draft Externship');
      } else {
        await ExternshipApi.upsertExternshipProposalApprovers(draftExternship.id, payload);
        !!onSuccess && onSuccess();
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setErrMsg(error.response.data.message);
      } else {
        setErrMsg(error?.message);
      }
    } finally {
      setStepperLoading(false);
    }
  };

  const {
    methods,
    handleFormSubmit: handleApproversFormSubmit
  } = useExternshipProposalApproverForm(handleValidExternshipApproversFormRequest);

  return (
    <ExternshipProposalStepLayout
      methods={methods}
      content={
        <ExternshipProposalApproversForm
          methods={methods}
          externship={draftExternship}
        />
      }
      formActions={{
        onSubmit: handleApproversFormSubmit,
        submitBtnText: 'Save and Continue',
        handleStepper: handleStepper,
        errorMessage: errMsg,
        disableSubmit: stepperLoading
      }}
    />
  );
};

export default ExternshipProposalApproversStep;