export const siteAgreementText = `
<p>Our establishment agrees to host and monitor students from the Auguste Escoffier School of Culinary Arts during their Industry Externship course(s). We ensure that externship students will be exposed to a wide variety of techniques consistent with the program objectives, as well as the opportunity to demonstrate and improve upon food service industry skills and overall employment potential. We will determine a schedule conducive for our establishment and the student. Externship sites, as extended providers of culinary education, agree to the following guidelines:</p>
<ul>
<li>The training, even though it includes actual operation of the facilities of the employer, is similar to that which would be given in a vocational school.</li>
<li>The students do not replace regular employees but work under close supervision.</li>
<li>The students are not entitled to a job at the conclusion of the training period.</li>
<li>If unpaid, the students are not entitled to wages for the time spent in training.</li>
<li>The student will be provided with enough hours to fulfill the externship commitment within the course start and end dates specified on the Externship Agreement.</li>
<li>Student time will be verified and approved weekly by the Externship Site Supervisor for submission to the school.</li>
<li>The Externship Site Supervisor will complete and submit evaluations with appropriate feedback at minimum, at the conclusion of the externship course.</li>
<li>As approved, the externship course does not require a student to work more than 8 hours per day for 5 consecutive days and sites agree to abide by this in addition to complying with any applicable labor laws.</li>
<li>The externship site will work with the campus for any visits to the property, verification, updates, and communication calls.</li>
</ul>
<h3>Externship opportunities at our property will be:</h3>
`;
