import { FileType } from '@api/models/fileApi.models';
import { Photo } from './candidateApi.models';
import { VideoType } from '@api/models/videoApi.models';

export type CandidateGalleryMediaType = VideoType | Photo;

export type MediaType = VideoType | FileType;

/**
 * Function for determining if a media item is of VideoType type or not
 * @param item the media object of type VideoType or FileType or Photo
 * @returns true or false based on type of item parameter
 */
export const isVideoType = (item): item is VideoType => {
  return (item as VideoType).videoUrl !== undefined;
};

/**
 * Function for determining if a media item is of Photo type or not
 * @param item the media object of type VideoType or FileType or Photo
 * @returns true or false based on type of item parameter
 */
export const isPhotoType = (item): item is Photo => {
  return (item as Photo).thumbnail !== undefined;
};
