/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { CodebookCountInterface } from '@api/models/codebook.models';
import { NotHiredStatusValues } from '@api/models/jobApplicationApi.models';
import theme from '@themes/ui/escoffier';

const { palette } = theme;

const AppStatusPieColors = {
  1: palette.red2.main, // Invited
  2: palette.GRAY_2.main, // New Application
  3: palette.gold2.main, // Screening
  4: palette.red3.main, // Contacted
  5: palette.GRAY_3.main, // Sent to Hiring Manager
  6: palette.GOLD_1.main, // Actively Interviewing
  7: palette.red4.main, // Hired
  8: palette.GRAY_4.main // Not Hired
};

export interface AppStatusPieData {
  name: string;
  value: number;
  onClick?: () => void;
  fill?: string;
}

interface ReturnValue {
  appStatusPieData: AppStatusPieData[];
  notHiredBarData: CodebookCountInterface[];
  status?: CodebookCountInterface | CodebookCountInterface[];
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useApplicationStatusData = (
  appStatusStats: CodebookCountInterface[],
  totalNumOfApplications: number,
  hideModals?: boolean
): ReturnValue => {
  const [status, setStatus] = React.useState<
    CodebookCountInterface | CodebookCountInterface[]
  >();
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const handleClickAppStatusStat = (
    status?: CodebookCountInterface | CodebookCountInterface[]
  ): (() => void) | undefined => {
    if (hideModals) {
      return undefined;
    }

    return (): void => {
      setStatus(status);
      setOpenModal(true);
    };
  };

  const notHiredAppStatusStats = appStatusStats.filter((s) =>
    NotHiredStatusValues.includes(s.value)
  );

  const numNotHiredApps = notHiredAppStatusStats
    .map((s) => s.count)
    .reduce((a, b) => a + b, 0);

  const appStatusPieData: AppStatusPieData[] = [
    {
      name: 'Total Applications',
      value: totalNumOfApplications,
      onClick: handleClickAppStatusStat()
    },
    ...appStatusStats
      .filter((s) => !NotHiredStatusValues.includes(s.value))
      .map((s) => ({
        name: s.label,
        value: s.count,
        onClick: handleClickAppStatusStat(s),
        fill: AppStatusPieColors[s.value]
      }))
  ];

  if (numNotHiredApps > 0) {
    appStatusPieData.push({
      name: 'Not Hired',
      value: numNotHiredApps,
      fill: AppStatusPieColors['8'],
      onClick: handleClickAppStatusStat(notHiredAppStatusStats)
    });
  }

  return {
    appStatusPieData,
    notHiredBarData: notHiredAppStatusStats,
    status,
    openModal,
    setOpenModal
  };
};
