import * as React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { QuizApi } from '@api/Quiz.api';
import QuizButton from '@components/Quiz/QuizButton';
import { QuizPostReq } from '@api/models/quizApi.models';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useHistory } from 'react-router-dom';
import { useQuizContext } from '@components/Quiz/EcQuiz';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const Styled = {
  Card: styled(Box)({
    flex: '1 1 548px',
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 45px 37px 45px',
    height: 'max-content',
    backgroundColor: theme.palette.GRAY_1.main,
    maxWidth: '548px',
    borderRadius: '10px',
    overflow: 'hidden',
    '& .sub-header': {
      fontWeight: 700
    },
    '& .body-text': {
      margin: '16px 0',
      '&:last-of-type': {
        marginBottom: '80px'
      }
    },
    [theme.breakpoints.down('md')]: {
      flex: 'unset'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 28px'
    }
  })
};

const testAnswers = [
  { answer: 'C', question: 1 },
  { answer: 'C', question: 2 },
  { answer: 'C', question: 3 },
  { answer: 'F', question: 4 },
  { answer: 'C', question: 5 },
  { answer: 'A', question: 6 },
  { answer: 'B', question: 7 },
  { answer: 'A', question: 8 },
  { answer: 'E', question: 9 },
  { answer: 'D', question: 10 },
  { answer: 'D', question: 11 }
];

export interface QuizIntroProps {
  cardSubheader: string;
  cardText: string[];
  buttonLabel: string;
}

const QuizIntroCard = (props: QuizIntroProps): React.ReactElement => {
  const { beginQuiz, quizInfo } = useQuizContext();
  const { cardSubheader, cardText, buttonLabel } = props;
  const { title } = quizInfo;

  const history = useHistory();
  const featureFlags = useFeatureFlags();

  const { width } = useWindowDimensions();

  const isSmall = width < theme.breakpoints.values.sm;

  const handleDevQuizSkip = async (): Promise<void> => {
    if (featureFlags.CAREER_QUIZ_RESULTS) {
      try {
        const postReq: QuizPostReq = { answers: testAnswers };
        const res = await QuizApi.postResults(1, postReq);
        history.push({
          pathname: '/quiz/1/results',
          state: { results: res.data }
        });
      } catch (error: any) {
        console.error(
          'Error for dev quiz skip -> QuizApi.postResults()',
          error
        );
      }
    }
  };

  return (
    <>
      <Styled.Card data-testid="quiz-intro-card-root">
        <Typography data-testid="quiz-title" variant="EC_TYPE_3XL">
          {title}
        </Typography>
        <Typography
          data-testid="quiz-subheader"
          variant="EC_TYPE_BASE"
          className="sub-header"
        >
          {cardSubheader}
        </Typography>
        {cardText.map((text, idx) => (
          <Typography
            key={idx}
            data-testid={`card-text-${idx}`}
            variant="EC_TYPE_BASE"
            className="body-text"
          >
            {text}
          </Typography>
        ))}
        {!isSmall && (
          <QuizButton
            dataTestId="intro-btn-reg"
            label={buttonLabel}
            onClick={beginQuiz}
            sx={{
              alignSelf: 'end',
              marginTop: '36px'
            }}
          />
        )}
        {featureFlags.CAREER_QUIZ_RESULTS && (
          <Link
            underline="none"
            onClick={handleDevQuizSkip}
            sx={{ alignSelf: 'end', marginTop: '16px', cursor: 'pointer' }}
          >
            Skip Quiz (Dev Only)
          </Link>
        )}
      </Styled.Card>
      {isSmall && (
        <QuizButton
          dataTestId="intro-btn-sm"
          label={buttonLabel}
          onClick={beginQuiz}
          fullWidth
          sx={{
            marginTop: '36px',
            width: '100%'
          }}
        />
      )}
    </>
  );
};

export default QuizIntroCard;
