import * as React from 'react';
import { Button, Icon } from '@mui/material';
import { allowedGridFilterOperators } from '@common/fetches/useFetchGridTableByType';
import EcDataGrid from '@components/DataGrid/EcDataGrid';
import EcGridTableToolbar from '@components/DataGrid/EcGridTableToolbar';
import ESourceGroupAccessForm from './ESourceGroupAccessForm';
import { ESourceGroupWithAccessDto } from '@interfaces/glrc.interfaces';
import { GlrcContentApi } from '@api/GlrcContent.api';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { useCompanySettingsContext } from '@common/context/companySettingsContext';

const ESourceTabContent: React.FC = () => {
  const [showESourceForm, setShowESourceForm] = React.useState(false);
  const [currGroup, setCurrGroup] = React.useState<
    ESourceGroupWithAccessDto | undefined // eslint-disable-line @typescript-eslint/indent
  >();
  const { esourceGroups, setESourceGroups, companySettings } =
    useCompanySettingsContext();

  const handleDeleteGroup = async (groupId: number): Promise<void> => {
    await GlrcContentApi.deleteESourceGroupById(groupId);
    const newList = [...esourceGroups].filter((g) => g.id !== groupId);
    setESourceGroups(newList);
  };

  const handleEditGroup = (groupId: number): void => {
    const groupToEdit = esourceGroups.find((g) => g.id === groupId);

    if (groupToEdit) {
      setCurrGroup(groupToEdit);
      setShowESourceForm(true);
    }
  };

  const getNumberOfUsersForGroup = (esourceGroupId: number): number => {
    return companySettings.seats.filter(
      (s) => s.e_source_group?.id === esourceGroupId
    ).length;
  };

  if (showESourceForm) {
    return (
      <ESourceGroupAccessForm
        closeForm={(): void => {
          setCurrGroup(undefined);
          setShowESourceForm(false);
        }}
        currGroup={currGroup}
      />
    );
  }

  const esourceGroupColumns = [
    {
      field: 'name',
      headerName: 'Group Name',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'userCount',
      headerName: 'Users',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'accessCount',
      headerName: 'Access',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'customization',
      headerName: 'Colors',
      flex: 1,
      filterOperators: allowedGridFilterOperators
    },
    {
      field: 'Actions',
      type: 'actions',
      width: 80,
      getActions: (params): JSX.Element[] => {
        return [
          <GridActionsCellItem
            key="delete"
            disabled={getNumberOfUsersForGroup(params.row.id) > 0}
            label="Delete"
            icon={<Icon className="ri-delete-bin-6-line" />}
            onClick={(): Promise<void> => handleDeleteGroup(Number(params.id))}
            showInMenu
          />,
          <GridActionsCellItem
            key="edit"
            label="Edit"
            icon={<Icon className="ri-edit-2-fill" />}
            onClick={(): void => handleEditGroup(Number(params.id))}
            showInMenu
          />
        ];
      },
      filterOperators: allowedGridFilterOperators
    }
  ];

  return (
    <>
      <Button
        variant="contained"
        fullWidth
        onClick={(): void => setShowESourceForm(true)}
        sx={{ marginBottom: '24px' }}
      >
        Create New Group
      </Button>
      <EcDataGrid
        autoHeight={true}
        rowSelection
        columns={esourceGroupColumns}
        rows={esourceGroups.map((g) => ({
          ...g,
          customization: g.customization ? 'Yes' : 'No',
          userCount: getNumberOfUsersForGroup(g.id),
          accessCount: g.accesses.filter((a) => a.hasAccess).length || 0
        }))}
        slots={{
          toolbar: EcGridTableToolbar
        }}
      />
    </>
  );
};

export default ESourceTabContent;
