/* eslint-disable @typescript-eslint/indent */
import { CandidateProfile } from '@api/models/candidateApi.models';

// CandidateProfileDetails
export const candidatePersonalInfoArray: (keyof CandidateProfile)[] = [
  'firstName',
  'lastName',
  'photo',
  'badge'
];

// TODO: rename to CandidatePersonalInfoType
type CandidatePersonalInfoType = typeof candidatePersonalInfoArray;
export type CandidatePersonalInfoProps = Pick<
  CandidateProfile,
  CandidatePersonalInfoType[number]
>;

export const candidateContentArray = [
  'education',
  'workExperience',
  'certificates',
  'segments',
  'specialties',
  'statement',
  'candidateCulinaryPhotos'
] as const;

type CandidateContentType = typeof candidateContentArray;
export type CandidateContentProps = Pick<
  CandidateProfile,
  CandidateContentType[number]
>;
