import * as React from 'react';
import { Button, Grid } from '@mui/material';
import { SignupWithInvitePayload } from '@api/models/authApi.models';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import {
  includesLetter,
  includesNumber,
  minLength,
  notMatchEmail
} from '@common/rhf-validators/passwordValidators';
import { AuthApi } from '@api/Auth.api';
import Box from '@mui/material/Box';
import { emailPattern } from '@common/regex/email';
import { InviteInfo } from '@api/models/inviteApi.models';
import { notBeEmptyString } from '@common/rhf-validators/emptyString.validator';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import ReactHookFormsPassword from '@components/Forms/ReactHookFormsPassword';
import RhfAcceptTermsCheckbox from '@components/Forms/RhfAcceptTermsCheckbox';
import { scrollToError } from '@common/helpers/scrollToError';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)({
    margin: '0 auto',
    maxWidth: '474px'
  }),
  GridItem: styled(Grid)({
    marginBottom: '34px'
  }),
  AcceptTermsBox: styled(Box)({
    position: 'relative',
    ['@media (max-width:340px)']: {
      marginBottom: '20px'
    }
  }),
  CreateBtn: styled(Button)({
    marginTop: '52px',
    height: '49px',
    // EC-Type-lg
    fontSize: '20px',
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '25px',
    letterSpacing: '0em',
    ['@media (max-width:600px)']: {
      width: '100%'
    }
  })
};

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  acceptTerms: boolean;
}

interface Props {
  setEmailConfirmationSent: React.Dispatch<React.SetStateAction<boolean>>;
  inviteInfo: InviteInfo;
}

const CreateAccountWithInviteForm: React.FC<Props> = ({
  inviteInfo,
  setEmailConfirmationSent
}) => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const methods = useForm<FormValues>({
    defaultValues: {
      firstName: inviteInfo.firstName,
      lastName: inviteInfo.lastName,
      email: inviteInfo.email,
      password: '',
      confirmPassword: '',
      acceptTerms: false
    }
  });

  const formOrder = {
    firstName: 1,
    lastName: 2,
    email: 3,
    password: 4,
    confirmPassword: 5,
    acceptTerms: 6
  };

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors }
  } = methods;

  React.useEffect(() => {
    if (inviteInfo) {
      reset({
        firstName: inviteInfo.firstName,
        lastName: inviteInfo.lastName,
        email: inviteInfo.email,
        acceptTerms: false
      });
    }
  }, [inviteInfo]);

  const currPassword = watch('password');
  const termsAccepted = watch('acceptTerms');
  const email = watch('email');

  const handleModalOpen = (): void => {
    setModalOpen(true);
  };

  const handleModalClose = (): void => {
    setModalOpen(false);
  };

  const handleAcceptTerms = (): void => {
    setValue('acceptTerms', true, { shouldValidate: true });
    handleModalClose();
  };

  const handleInvalidForm = (errors: FieldErrors): void => {
    scrollToError(errors, formOrder);
  };

  const handleCreateAccount = async (data: FormValues): Promise<void> => {
    const payload: SignupWithInvitePayload = {
      inviteCode: inviteInfo.inviteCode,
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      email: data.email,
      password: data.password,
      confirm_password: data.confirmPassword,
    };

    try {
      await AuthApi.signupWithInvite(payload);
      setEmailConfirmationSent(true);
    } catch (error: any) {
      console.error('Error for CreateAccountWithInviteForm->handleCreateAccount', error);
      if (error.response?.data?.data?.length) {
        error.response?.data?.data.forEach(({ field, message }) =>
          setError(field, { type: 'manual', message })
        );
      } else {
        setError('acceptTerms', { type: 'manual', message: error.message });
      }
      scrollToError(errors, formOrder);
    }
  };

  const handleFormSubmit = (): void => {
    const onValid = async (data: FormValues): Promise<void> => {
      return await handleCreateAccount(data);
    };

    handleSubmit(onValid, handleInvalidForm)();
  };

  return (
    <Styled.Root>
      <FormProvider {...methods}>
        <form>
          <Grid
            container
            columnSpacing={3}
            alignItems="flex-end"
            sx={{ marginBottom: '14px' }}
          >
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsInput
                name="firstName"
                label="First Name"
                autocomplete="given-name"
                dataTestId="create-account-with-invite-first-name"
                rules={{
                  required: 'First name is required',
                  validate: { notBeEmptyString: notBeEmptyString('First name') }
                }}
              />
            </Styled.GridItem>
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsInput
                name="lastName"
                label="Last Name"
                autocomplete="family-name"
                dataTestId="create-account-with-invite-last-name"
                rules={{
                  required: 'Last name is required',
                  validate: { notBeEmptyString: notBeEmptyString('Last name') }
                }}
              />
            </Styled.GridItem>
            <Styled.GridItem item xs={12} sm={12}>
              <ReactHookFormsInput
                name="email"
                disabled
                label="Email"
                dataTestId="create-account-with-invite-email"
                autocomplete="email"
                rules={{
                  required: 'Email is required.',
                  pattern: {
                    value: emailPattern,
                    message: 'Please enter a valid email'
                  }
                }}
              />
            </Styled.GridItem>
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsPassword
                name="password"
                label="Create Password"
                dataTestId="create-account-with-invite-password"
                autocomplete="new-password"
                displayTooltip
                rules={{
                  required: 'Password is required',
                  validate: {
                    notMatchEmail: notMatchEmail(email),
                    minLength,
                    includesNumber,
                    includesLetter
                  }
                }}
              />
            </Styled.GridItem>
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsPassword
                name="confirmPassword"
                label="Confirm Password"
                autocomplete="new-password"
                dataTestId="create-account-with-invite-confirm-password"
                rules={{
                  required: 'Confirm password is required',
                  validate: (value): boolean | string =>
                    value === currPassword || 'Passwords do not match'
                }}
              />
            </Styled.GridItem>
          </Grid>
          <RhfAcceptTermsCheckbox
            name="acceptTerms"
            termsAccepted={termsAccepted}
            modalOpen={modalOpen}
            setModalOpen={handleModalOpen}
            setModalClosed={handleModalClose}
            handleAcceptTermsAndConditions={handleAcceptTerms}
          />
          <Styled.CreateBtn
            variant="contained"
            onClick={handleFormSubmit}
            data-testid="create-account-with-invite-submit-btn"
          >
            Create Account
          </Styled.CreateBtn>
        </form>
      </FormProvider>
    </Styled.Root>
  );
};

export default CreateAccountWithInviteForm;
