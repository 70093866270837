import * as React from 'react';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { SkillCodebook, SkillType } from '@api/models/codebook.models';
import Skill from '@components/Skill';
import { styled } from '@mui/system';
// TODO: store and fetch images for this card from database
import tempImg from '@assets/temp-results-img.jpg';
import tempImg2 from '@assets/temp-results-img-2.jpg';
import tempImg3 from '@assets/temp-results-img-3.jpg';
import tempImg4 from '@assets/temp-results-img-4.jpg';
import theme from '@themes/ui/escoffier';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { useResponsiveLayoutContext } from '@components/Layout/ResponsiveLayout';

const Styled = {
  Card: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxHeight: 'max-content',
    padding: '42px',
    backgroundColor: '#FFFFFF',
    border: `1px solid ${theme.palette.GRAY_2.main}`,
    borderRadius: '4px',
    overflowX: 'hidden',
    overflowY: 'hidden',
    ['@media (max-width:1036px)']: {
      padding: '16px',
      overflowY: 'scroll'
    },
    '& .section-title': {
      marginTop: '22px'
    },
    '& .skills': {
      fontWeight: 800,
      color: theme.palette.GRAY_4.main,
      margin: '18px 0'
    },
    '& .result-image': {
      width: '100%',
      maxWidth: '597px',
      maxHeight: '236px',
      objectFit: 'cover',
      objectPosition: 'center center'
    }
  }),
  Top: styled(Box)({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '28px',
    '& .result': {
      textTransform: 'uppercase',
      color: theme.palette.GOLD_1.main,
      '& .chef-hat': {
        position: 'relative',
        top: '3px',
        marginRight: '16px'
      }
    },
    ['@media (max-width:980px)']: {
      flexFlow: 'column wrap',
      alignItems: 'flex-start',
      '& .result': {
        marginBottom: '16px'
      }
    }
  }),
  SkillsBox: styled(Box)({
    display: 'flex',
    flexFlow: 'row wrap',
    columnGap: '6px'
  }),
  Overlay: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'show'
  })<{ show: boolean }>(({ show }) => ({
    '&.overlay': {
      position: 'absolute',
      display: 'block',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      opacity: show ? 1 : 0,
      background:
        'linear-gradient(to top, rgba(0,0,0,0.35), 18%, rgba(255,255,255,0))',
      transition: 'opacity 0.1s ease',
      pointerEvents: 'none'
    }
  }))
};

// TODO: Remove once tags and images are stored in database
const tempImgs = [tempImg, tempImg2, tempImg3, tempImg4];

interface QuizResultDescription {
  handleCtaClick: () => void;
  description?: string;
  personality?: string;
  result?: string;
  careerRole?: { skills: SkillCodebook[] };
}

const QuizResultDescription = ({
  handleCtaClick,
  description,
  personality,
  result,
  careerRole
}: QuizResultDescription): React.ReactElement => {
  const { customBreakpointReached } = useResponsiveLayoutContext();
  const { isAuthenticated, isCandidate } = useKeycloakContext();
  const displayCreateBtn = !isAuthenticated || isCandidate;

  const [displayOverlay, setDisplayOverlay] = React.useState<boolean>(
    customBreakpointReached
  );

  // TODO: Images will be updated in a future task, for now they are hidden as a reminder to update.
  const hideImages = true;
  const randomImg: string = tempImgs[Math.floor(Math.random() * 4)];

  const hideOverlay = (): void => setDisplayOverlay(false);

  React.useEffect(() => {
    const card = document.querySelector('.small-card');
    if (card) {
      card.addEventListener('scroll', hideOverlay, false);
      return (): void => {
        card.removeEventListener('scroll', hideOverlay, false);
      };
    }
  }, [customBreakpointReached]);

  return (
    <>
      {description && result && personality ? (
        <Styled.Card
          data-testid="quiz-result-description-card"
          className="card"
        >
          <Styled.Top>
            <Typography
              data-testid="result-text"
              variant="EC_TYPE_2XL"
              className="result"
            >
              {result}
            </Typography>
            {!customBreakpointReached && displayCreateBtn && (
              <Button variant="contained" onClick={handleCtaClick}>
                Create Career Path
              </Button>
            )}
          </Styled.Top>
          {!hideImages && (
            <Box
              component="img"
              src={randomImg}
              alt="temp results image"
              className="result-image"
              data-testid="quiz-result-card-img"
            />
          )}
          <Typography variant="EC_TYPE_LG" className="section-title">
            Personality
          </Typography>
          <Typography
            data-testid="quiz-result-personality-text"
            variant="EC_TYPE_BASE"
            dangerouslySetInnerHTML={{
              __html: personality
            }}
          />
          <Typography variant="EC_TYPE_LG" className="section-title">
            Description
          </Typography>
          <Typography
            data-testid="quiz-result-description-text"
            variant="EC_TYPE_BASE"
            className="description"
            dangerouslySetInnerHTML={{
              __html: description
            }}
          />
          <Typography variant="EC_TYPE_SM" className="skills">
            TECHNICAL SKILLS
          </Typography>
          <Styled.SkillsBox data-testid="technical-skills-box">
            {(
              careerRole?.skills.filter(
                (skill) => skill.type == SkillType.TECHNICAL_SKILL
              ) ?? []
            ).map((skill, idx) => (
              <Box key={idx} sx={{ width: 'max-content' }}>
                <Skill skill={skill.label} variant="DARK_GOLD" />
              </Box>
            ))}
          </Styled.SkillsBox>
          <Typography variant="EC_TYPE_SM" className="skills">
            SOFT SKILLS
          </Typography>
          <Styled.SkillsBox data-testid="soft-skills-box">
            {(
              careerRole?.skills.filter(
                (skill) => skill.type == SkillType.SOFT_SKILL
              ) ?? []
            ).map((skill, idx) => (
              <Box key={idx} sx={{ width: 'max-content' }}>
                <Skill skill={skill.label} variant="DARK_GOLD" />
              </Box>
            ))}
          </Styled.SkillsBox>
          {customBreakpointReached && displayCreateBtn && (
            <Button variant="contained" onClick={handleCtaClick}>
              Create Career Path
            </Button>
          )}
          <Styled.Overlay className="overlay" show={displayOverlay} />
        </Styled.Card>
      ) : (
        <Skeleton variant="rectangular" height="100%" width="100%" />
      )}
    </>
  );
};

export default QuizResultDescription;
