import { useEffect, useState } from 'react';
import { CandidateApi } from '@api/Candidate.api';
import { CandidateCareerInfo } from '@interfaces/careerJourney.interfaces';

interface FetchCandidateCareerInfo {
  candidateCareerInfo: CandidateCareerInfo | undefined;
  fetchCareerInfo: () => Promise<void>;
  loading: boolean;
  loaded: true | null;
}

export const useFetchCandidateCareerInfo = (
  candidateId: number
): FetchCandidateCareerInfo => {
  const [candidateCareerInfo, setCandidateCareerInfo] =
    useState<CandidateCareerInfo>();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  const fetchCareerInfo = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await CandidateApi.getCareerInfo(candidateId);
      setCandidateCareerInfo(res.data);
    } catch (error: any) {
      setLoading(false);
      setLoaded(true);
      console.error(
        'Error for useFetchCandidateCareerInfo -> CandidateApi.getCareerInfo',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  useEffect(() => {
    fetchCareerInfo();
  }, []);

  return { candidateCareerInfo, fetchCareerInfo, loading, loaded };
};
