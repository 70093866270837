import {
  GridTable,
  GridTableView,
  GridType,
  GridViewDto,
  SaveGridTableViewDto,
  SaveGridViewDto,
  SetGridViewDefaultDto
} from './models/gridTableApi.models';
import { api } from '@api/configs/base';
import { apiConfig } from '@api/configs/api.config';
import { ApiResponse } from '@api/models/base.models';

export class GridTableApi {
  static async getTableByType(gridTableType: number): Promise<GridTable> {
    try {
      const res = await api.get<ApiResponse<GridTable>>(
        `${apiConfig.gridTableUrl}/${gridTableType}`
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for GridTableApi.getTableByType(): ${error}`);
      throw error;
    }
  }

  static async saveGridTableView(
    gridTableId: number,
    userId: string,
    data: SaveGridTableViewDto
  ): Promise<GridTableView> {
    try {
      const res = await api.post<ApiResponse<GridTableView>>(
        `${apiConfig.gridTableUrl}/${gridTableId}/user/${userId}`,
        data
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for GridTableApi.saveGridTableView(): ${error}`);
      throw error;
    }
  }

  static async updateGridTableView(
    gridTableViewId: number,
    name: string
  ): Promise<GridTableView> {
    try {
      const res = await api.put<ApiResponse<GridTableView>>(
        `${apiConfig.gridTableUrl}/view/${gridTableViewId}`,
        { name }
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for GridTableApi.updateGridTableView(): ${error}`);
      throw error;
    }
  }

  static async deleteGridTableView(
    gridTableId: number,
    gridTableViewId: number
  ): Promise<GridTableView> {
    try {
      const res = await api.delete<ApiResponse<GridTableView>>(
        `${apiConfig.gridTableUrl}/${gridTableId}/grid-table-view/${gridTableViewId}`
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for GridTableApi.deleteGridTableView(): ${error}`);
      throw error;
    }
  }

  static async setDefaultGridTableView(
    gridTableId: number,
    gridTableViewId: number
  ): Promise<GridTableView> {
    try {
      const res = await api.post<ApiResponse<GridTableView>>(
        `${apiConfig.gridTableUrl}/${gridTableId}/view/${gridTableViewId}`,
        { name }
      );

      return res.data.data;
    } catch (error: any) {
      console.error(
        `Error for GridTableApi.setDefaultGridTableView(): ${error}`
      );
      throw error;
    }
  }

  // New methods using exportState
  static async getGridViewsByTypeAndUserId(
    gridType: GridType
  ): Promise<GridViewDto[]> {
    try {
      const res = await api.get<ApiResponse<GridViewDto[]>>(
        `${apiConfig.gridTableUrl}/${gridType}/views`
      );

      return res.data.data;
    } catch (error: any) {
      console.error(
        `Error for GridTableApi.getGridViewsByTypeAndUserId(): ${error}`
      );
      throw error;
    }
  }

  static async save(data: SaveGridViewDto): Promise<GridViewDto[]> {
    try {
      const res = await api.post<ApiResponse<GridViewDto[]>>(
        `${apiConfig.gridTableUrl}`,
        data
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for GridTableApi.save(): ${error}`);
      throw error;
    }
  }

  static async setGridViewDefault(
    data: SetGridViewDefaultDto
  ): Promise<GridViewDto[]> {
    try {
      const res = await api.post<ApiResponse<GridViewDto[]>>(
        `${apiConfig.gridTableUrl}/set-as-default`,
        data
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for GridTableApi.setGridViewDefault(): ${error}`);
      throw error;
    }
  }

  static async renameGridView(
    gridViewId: number,
    name: string
  ): Promise<GridViewDto[]> {
    try {
      const res = await api.put<ApiResponse<GridViewDto[]>>(
        `${apiConfig.gridTableUrl}/view/${gridViewId}/rename`,
        { name }
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for GridTableApi.renameGridView(): ${error}`);
      throw error;
    }
  }

  static async delete(gridViewId: number): Promise<GridViewDto[]> {
    try {
      const res = await api.delete<ApiResponse<GridViewDto[]>>(
        `${apiConfig.gridTableUrl}/view/${gridViewId}`
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for GridTableApi.delete(): ${error}`);
      throw error;
    }
  }
}
