/* eslint-disable @typescript-eslint/indent */
import {
  ContactUsPartnershipPostRequest,
  ContactUsPostReq,
  ContactUsPublicPostRequest,
  ContactUsWorkAndLearnPostRequest
} from './models/contactUsApi.models';
import { api } from './configs/base';
import { apiConfig } from './configs/api.config';
import { ApiResponse } from './models/base.models';

export class ContactUsApi {
  static async sendPartnershipForm(
    data: ContactUsPartnershipPostRequest
  ): Promise<ApiResponse<ContactUsPartnershipPostRequest>> {
    try {
      const response = await api.post<
        ApiResponse<ContactUsPartnershipPostRequest>
      >(`${apiConfig.contactUsUrl}/partnership`, data);

      return response.data;
    } catch (error: any) {
      console.error(`Error for ContactUsApi.sendPartnershipForm(): ${error}`);
      throw error;
    }
  }

  static async sendPublicForm(
    data: ContactUsPublicPostRequest
  ): Promise<ApiResponse<ContactUsPublicPostRequest>> {
    try {
      const response = await api.post<ApiResponse<ContactUsPublicPostRequest>>(
        `${apiConfig.contactUsUrl}/public`,
        data
      );

      return response.data;
    } catch (error: any) {
      console.error('Error for ContactUsApi.sendPublicForm()', error);
      throw error;
    }
  }

  static async sendWorkAndLearnForm(
    data: ContactUsWorkAndLearnPostRequest | ContactUsPostReq
  ): Promise<ApiResponse<ContactUsWorkAndLearnPostRequest>> {
    try {
      // If data has the 'interestedIn' field, remove it
      if ('interestedIn' in data) {
        const { interestedIn, ...rest } = data;
        data = rest as ContactUsWorkAndLearnPostRequest;
        void interestedIn; // Type assertion to tell TypeScript/EsLint that interestedIn will not be used
      }

      const response = await api.post<
        ApiResponse<ContactUsWorkAndLearnPostRequest>
      >(`${apiConfig.contactUsUrl}/work-and-learn`, data);

      return response.data;
    } catch (error: any) {
      console.error('Error for ContactUsApi.sendWorkAndLearnForm()', error);
      throw error;
    }
  }
}
