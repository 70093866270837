import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { IPageInfoRes } from '@interfaces/pageInfo.interfaces';
import Pagination from '@mui/material/Pagination';
import SortMenuPopper from '@components/SortMenuPopper';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useResponsiveLayoutContext } from '@components/Layout/ResponsiveLayout';
import { useSortMenuContext } from '@common/context/sortMenuContext';

const Styled = {
  Box: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'customBreakpointReached'
  })(({ customBreakpointReached }: { customBreakpointReached: boolean }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: customBreakpointReached ? '20px' : '10px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    },
    '& .results': {
      color: theme.palette.grey3.main,
      ...(customBreakpointReached && {
        paddingLeft: '8px',
        paddingRight: '8px'
      })
    }
  })),
  TopPagination: styled(Box)({
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between'
    }
  }),
  TopPaginationNumbers: styled(Box)({
    marginRight: '20px',
    display: 'flex',
    alignItems: 'center'
  }),
  Pagination: styled(Pagination)({
    '& .MuiPagination-ul': {
      '& li': {
        '&:not(:first-of-type):not(:last-of-type)': {
          display: 'none'
        }
      }
    }
  })
};

interface Props {
  pageInfo: IPageInfoRes;
  availableItems: string;
}

const FiltersResultsBar: React.FC<Props> = (props) => {
  const { pageInfo, availableItems } = props;

  const { customBreakpointReached } = useResponsiveLayoutContext();
  const { sortCategory, sortOrder, sortMenuButtons, onSortClick } =
    useSortMenuContext();
  const [sortMenuAnchor, setSortMenuAnchor] =
    React.useState<HTMLElement | null>(null);
  const sortMenuOpen = Boolean(sortMenuAnchor);

  const handleSortMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setSortMenuAnchor(sortMenuAnchor ? null : event.currentTarget);
  };

  const handleSortMenuClickAway = (): void => {
    sortMenuAnchor && setSortMenuAnchor(null);
  };

  const titleText: string = React.useMemo(() => {
    return `Showing ${pageInfo?.currentPageStartRow} - ${pageInfo.currentPageEndRow} of ${pageInfo.filteredRows} Available ${availableItems}`;
  }, [pageInfo]);

  return (
    <Styled.Box
      data-testid="filtersResultsBarContainer"
      customBreakpointReached={customBreakpointReached}
    >
      {customBreakpointReached && <Box sx={{ width: 40 }} />}
      <Typography variant="EC_TYPE_SM" className="results">
        {titleText}
      </Typography>
      <SortMenuPopper
        anchorEl={sortMenuAnchor}
        handleOpenMenu={handleSortMenuOpen}
        menuOpen={sortMenuOpen}
        buttons={sortMenuButtons}
        handleClickAway={handleSortMenuClickAway}
        onSortClick={onSortClick}
        sortCategory={sortCategory}
        sortOrder={sortOrder}
      />
    </Styled.Box>
  );
};

export default FiltersResultsBar;
