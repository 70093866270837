import * as React from 'react';
import {
  AgreementEntity,
  AgreementType,
  ApproverRole,
  ExternshipApproverEntity,
  ExternshipEntity
} from '@api/models/externshipApi.models';
import {
  AttestationEntity,
  AttestationStatus
} from '@interfaces/attestation.interface';
import { Box, Button, Link, Paper, Theme, Typography } from '@mui/material';
import {
  CareerServiceExternshipFile,
  UploadFilesDto
} from '@api/models/careerServicesApi.models';
import { FormProvider, useForm } from 'react-hook-form';
import { CareerServicesApi } from '@api/CareerService.api';
import { downloadAll } from '@common/helpers/pdfHelpers';
import { FileType } from '@api/models/fileApi.models';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { getFilePath } from '@common/helpers/getFilePath';
import ImageUploadWell from '@components/ImageUpload/ImageUploadWell';
import StudentPageCardHeader from '@pages/CareerServices/StudentPage/StudentPageCardHeader';
import { styled } from '@mui/system';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { CandidateProfile } from '@api/models/candidateApi.models';
import { EmployerProfile } from '@api/models/employerApi.models';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Styled = {
  CardRoot: styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.WHITE.main,
    padding: '2em',
    '&:not(:last-child)': {
      marginBottom: '3em'
    }
  })),
  FlexBoxHorizontal: styled(Box)({
    display: 'flex',
    columnGap: '3em'
  }),
  NameDateGrid: styled(Box)({
    display: 'grid',
    gridTemplateColumns: '26ch 20ch',
    justifyContent: 'start',
    marginLeft: '1.6em',
    gap: '0.2em 1em',
  }),
  DocumentBox: styled(Box)({
    '&:not(:last-child)': {
      marginBottom: '1em'
    }
  }),
  DownloadAllBtn: styled(Button)(({ theme }) => ({
    padding: 0,
    typography: (theme as Theme).typography.EC_TYPE_3XS,
    color: theme.palette.primary.main,
    marginTop: '12px',
    marginBottom: '1.5em'
  })),
  DocBtn: styled(Button)(({ theme }) => ({
    padding: 0,
    typography: (theme as Theme).typography.EC_TYPE_SM,
    color: theme.palette.primary.main,
    textTransform: 'capitalize'
  }))
};

interface DocumentRowProps {
  documentType: string;
  document: AgreementEntity | AttestationEntity;
  signersNamesAndDates?: { name: string, date: (Date | undefined) }[];
  managerName?: string;
}

const DocumentRow = ({
  documentType,
  document,
  signersNamesAndDates,
}: DocumentRowProps): React.ReactElement => {
  const isAgreement = !!document && 'agreementType' in document;
  const filePath = getFilePath(document?.file?.fullName);

  return (
    <Styled.DocumentBox>
      <Link
        href={`${BASE_URL}/${filePath}`}
        target="_blank"
        rel="noopener noreferrer"
        underline="none"
      >
        <Styled.DocBtn
          variant="text"
          startIcon={<i className="ri-file-text-fill" />}
          size="medium"
        >
          {documentType}
        </Styled.DocBtn>
      </Link>
      <Styled.FlexBoxHorizontal>
        <Styled.NameDateGrid>
          {signersNamesAndDates?.map((signerNameAndDate, index) => {
            return (
              <>
                <Typography variant="EC_TYPE_2XS" key={index}>
                  SIGNED BY: {signerNameAndDate.name || 'N/A'}
                </Typography>
                <Typography variant="EC_TYPE_2XS">
                  {formatDate(String(signerNameAndDate.date), 'LLL dd, yyyy')}
                </Typography>
              </>
            );
          })}
        </Styled.NameDateGrid>
        <Typography variant="EC_TYPE_2XS">
          {!!document?.file?.createdAt && (
            isAgreement && document.managerSignDate && 'LAST UPDATED: '
            + formatDate(document.file.createdAt, 'LLL dd, yyyy')
          )}
          &nbsp;
        </Typography>
      </Styled.FlexBoxHorizontal>
    </Styled.DocumentBox>
  );
};

const getFileArray = (externship: ExternshipEntity): (string | undefined)[] => {
  const urlArray: string[] = [];

  // siteAgreements[0] is the most recent document
  // For details see src/entity/externships/externship.entity.ts#_findMany()
  const siteAgreementFile = externship?.company?.siteAgreements?.[0]?.file;
  if (siteAgreementFile) {
    urlArray.push(getFilePath(siteAgreementFile.fullName));
  }

  const externshipAgreementFile = externship.externshipAgreement?.file;
  if (externshipAgreementFile) {
    urlArray.push(getFilePath(externshipAgreementFile.fullName));
  }

  const attestationFile = externship.attestation?.file;
  if (attestationFile) urlArray.push(getFilePath(attestationFile.fullName));

  externship.approvers?.map(approver => {
    const file = approver.proxyAgreement?.file;
    if (file) {
      urlArray.push(getFilePath(file.fullName));
    }
  });

  return urlArray;
};

interface UploadFormValues {
  files: number[];
}

interface DocumentRepositoryCardProps {
  externship: ExternshipEntity;
}

const DocumentRepositoryCard: React.FC<DocumentRepositoryCardProps> = ({ externship }) => {
  const [uploadedFiles, setUploadedFiles] = React.useState<FileType[]>([]);
  const [currentUserName, setCurrentUserName] = React.useState<string>('');

  const [manager, setManager] = React.useState<EmployerProfile | undefined>(undefined);
  const [approverWithProxyAgreementLinks, setApproverWithProxyAgreementLinks] = React.useState<ExternshipApproverEntity[]>([]);
  const [allFilesToDownload, setAllFilesToDownload] = React.useState<string[]>([]);

  const { keycloakUser } = useKeycloakContext();

  React.useEffect(() => {
    setCurrentUserName(`${keycloakUser.given_name} ${keycloakUser.family_name}`);
  }, [keycloakUser]);

  React.useEffect(() => {
    const newManagerLink = externship?.approvers?.find(link => link.approverRole === ApproverRole.MANAGER);
    setManager(newManagerLink?.user?.employer);

    const newApproverWithProxyAgreementLinks = externship?.approvers?.filter(link => {
      return !!link.proxyAgreement;
    }).sort((a: ExternshipApproverEntity, b: ExternshipApproverEntity) => {
      return new Date(a.proxyAgreement?.supervisorSignDate || 0).getTime() - new Date(b.proxyAgreement?.supervisorSignDate || 0).getTime();
    }) || [];
    setApproverWithProxyAgreementLinks(newApproverWithProxyAgreementLinks);

    const fileArray = getFileArray(externship) ?? [];
    const safeFileArray = fileArray.filter((file): file is string => file !== undefined);
    setAllFilesToDownload([
      ...safeFileArray,
      ...(uploadedFiles?.map(file => file.fullName) ?? [])
    ]);
  }, [externship, uploadedFiles]);

  const formMethods = useForm<UploadFormValues>({
    defaultValues: {
      files: []
    }
  });

  const getFileIds = (files?: CareerServiceExternshipFile[]): number[] => {
    if (!files) return [];

    const newFiles = files.map((f) => f.file);
    setUploadedFiles(newFiles);

    return newFiles.map((f) => f.id);
  };

  const handleValidForm = async (data: number[]): Promise<void> => {
    const reqBody: UploadFilesDto = {
      files: data,
      uploadedBy: currentUserName
    };

    try {
      const res = await CareerServicesApi.uploadFiles(externship.id, reqBody);

      if (res?.length) {
        setUploadedFiles(res.map((r) => r.file));
      }
    } catch (error: any) {
      console.error(
        'Error for DocumentRepository -> CareerServicesApi.uploadFiles',
        error
      );
    }
  };

  const handleDownloadAllClick = (): void => {
    allFilesToDownload.forEach((url) => downloadAll(url));
  };

  React.useEffect(() => {
    formMethods.reset({
      files: getFileIds(externship.careerServiceFiles)
    });
  }, [externship]);

  const attestationComplete =
    externship.attestationStatus === AttestationStatus.COMPLETED;

  const noProxy =
    approverWithProxyAgreementLinks?.length === 0 ||
    !!approverWithProxyAgreementLinks?.some((s) => s.proxyAgreement === null);
  const noSiteAgreements =
    !externship.company?.siteAgreements ||
    externship.company?.siteAgreements?.length === 0;

  const noDocuments =
    noSiteAgreements &&
    !externship.externshipAgreement &&
    noProxy &&
    !externship.attestation &&
    uploadedFiles?.length === 0;

  const getFullNameFromLink = (
    link: (ExternshipApproverEntity | CandidateProfile | EmployerProfile | undefined)
  ): (string | null) => {
    if (!link) {
      return '';
    }
    const firstName = link?.user?.firstName ?? null;
    const lastName = link?.user?.lastName ?? null;
    const fullName = (!!firstName && !!lastName) ? `${firstName} ${lastName}` : null;
    return fullName;
  };

  const getAgreementSignerNamesAndDates = (agreement: AgreementEntity): { name: string, date: Date | undefined }[] => {
    if (agreement.agreementType === AgreementType.PROXY && agreement.supervisorSignatory) {
      const supervisorLink = externship.approvers.find(link => link.user?.id === agreement.supervisorSignatory?.userId);
      const supervisorFullName = getFullNameFromLink(supervisorLink);
      if (supervisorFullName) {
        return [{ name: supervisorFullName, date: agreement.supervisorSignDate }];
      }
      return [];
    }
    if (agreement.agreementType === AgreementType.SITE && agreement.managerSignatory) {
      const managerFullName = getFullNameFromLink(agreement.managerSignatory);
      if (managerFullName) {
        return [{ name: managerFullName, date: agreement.managerSignDate }];
      }
      return [];
    }
    if (agreement.agreementType === AgreementType.EXTERNSHIP && agreement.managerSignatory) {
      const signatoryFullNames: { name: string, date: (Date | undefined) }[] = [];
      if (agreement.managerSignatory) {
        const managerFullName = getFullNameFromLink(agreement.managerSignatory);
        !!managerFullName && signatoryFullNames.push({ name: managerFullName, date: agreement.managerSignDate });
      }
      if (agreement.candidateSignDate) {
        const candidateFullName = getFullNameFromLink(externship.candidate);
        !!candidateFullName && signatoryFullNames.push({ name: candidateFullName, date: agreement.candidateSignDate });
      }
      // TODO? Add Career Services signatory?
      return signatoryFullNames;
    }
    return [];
  };

  return (
    <Styled.CardRoot elevation={3} key={externship.id}>
      <StudentPageCardHeader externship={externship} />
      <>
        {noDocuments ? (
          <Box>
            <Typography variant="EC_TYPE_2XL" padding="24px 0">
              No documents to display
            </Typography>
            <Typography variant="EC_TYPE_BASE">
              Once documents are created, they will appear here, or you may
              upload files.
            </Typography>
          </Box>
        ) : (
          <>
            <Styled.DownloadAllBtn
              variant="text"
              size="small"
              onClick={handleDownloadAllClick}
            >
              Download All
            </Styled.DownloadAllBtn>
            {!!externship.company?.siteAgreements?.length && (
              <DocumentRow
                documentType="Site Agreement"
                document={
                  externship.company.siteAgreements[0]
                }
                signersNamesAndDates={getAgreementSignerNamesAndDates(
                  externship.company.siteAgreements[0]
                )}
              />
            )}
            {externship.externshipAgreement && (
              <DocumentRow
                key="externshipAgreement"
                documentType="Externship Agreement"
                document={externship.externshipAgreement}
                signersNamesAndDates={getAgreementSignerNamesAndDates(
                  externship.externshipAgreement
                )}
              />
            )}
            {!!approverWithProxyAgreementLinks?.length &&
              approverWithProxyAgreementLinks.map((approver) => {
                if (approver.proxyAgreement) {
                  return (
                    <DocumentRow
                      key={`approverProxyAgreement_${approver.id}`}
                      documentType={`${approver.approverRole} Proxy Agreement`}
                      document={approver.proxyAgreement}
                      signersNamesAndDates={getAgreementSignerNamesAndDates(approver.proxyAgreement)}
                    />
                  );
                }
              })}
            {!!externship.attestation && attestationComplete && (
              <DocumentRow
                key="attestationSurvey"
                documentType="Attestation Survey"
                document={externship.attestation}
                managerName={`${manager?.user?.firstName} ${manager?.user?.lastName}`}
              />
            )}
          </>
        )}
        <Box mt="36px">
          <FormProvider {...formMethods}>
            <ImageUploadWell
              name="careerServiceFiles"
              displayText="Upload Documents"
              multiple
              clickableFiles
              disableUploadedFileDisplay={uploadedFiles.length < 1}
              secure={true}
              files={uploadedFiles}
              validTypes={['application/pdf']}
              maxFileSizeBytes={10000000}
              uploadCallback={handleValidForm}
              customUploadSectionTitle="Uploaded Files"
            />
          </FormProvider>
        </Box>
      </>
    </Styled.CardRoot>
  );
};

export default DocumentRepositoryCard;
