/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Box, Button, Grid, useTheme } from '@mui/material';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { ContactUsApi } from '@api/ContactUs.api';
import ContactUsBanner from './ContactUsBanner';
import ContactUsFooter from './ContactUsFooter';
import ContactUsMessageSent from './ContactUsMessageSent';
import { ContactUsPublicPostRequest } from '@api/models/contactUsApi.models';
import { emailPattern } from '@common/regex/email';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import ReactHookFormsSelect from '@components/Forms/ReactHookFormsSelect';
import ReactHookFormsTextarea from '@components/Forms/ReactHookFormsTextarea';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { scrollToError } from '@common/helpers/scrollToError';
import { styled } from '@mui/system';
import { useFetchMessageTypeCodebook } from '@common/fetches/useFetchMessageTypeCodebook';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { useLocation } from 'react-router-dom';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    padding: '16px 0 32px',
    maxWidth: '1680px',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      padding: '96px 0 0'
    }
  })),
  GridContainer: styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
      maxWidth: '60%',
      minWidth: '610px',
      marginLeft: '48px'
    }
  })),
  SendBtn: styled(Button)({
    marginTop: '22px',
    minWidth: '192px'
  })
};

const formOrder = {
  name: 1,
  email: 2,
  messageType: 3,
  subject: 4,
  message: 5
};

export interface ContactUsRouteState {
  subject: string;
  message: string;
  messageType: number;
}

const ContactUsPage = (): React.ReactElement => {
  const theme = useTheme();
  const { messageTypes } = useFetchMessageTypeCodebook();
  const { isGlrcUser, isCandidate } = useKeycloakContext();

  const messages = isGlrcUser
    ? messageTypes.filter(
        (m) => m.value !== 2 && m.value !== 3 && m.value !== 5
      )
    : isCandidate
    ? messageTypes.filter((m) => m.value !== 3 && m.value !== 4) // eslint-disable-line @typescript-eslint/indent
    : messageTypes; // eslint-disable-line @typescript-eslint/indent

  const [formSent, setFormSent] = React.useState(false);

  const location = useLocation();
  const state: ContactUsRouteState = location.state;

  const { width } = useWindowDimensions();

  const methods = useForm<ContactUsPublicPostRequest>({
    defaultValues: {
      name: '',
      email: '',
      messageType: undefined,
      subject: state?.subject || '',
      message: state?.message || ''
    }
  });

  const { handleSubmit, setError, reset } = methods;

  React.useEffect(() => {
    if (messageTypes) {
      reset({
        messageType: state?.messageType || undefined
      });
    }
  }, [messageTypes]);

  const handleInvalidForm = (errors: FieldErrors): void => {
    scrollToError(errors, formOrder);
  };

  const handleValidForm = (data: ContactUsPublicPostRequest): void => {
    const postBody: ContactUsPublicPostRequest = {
      name: data.name,
      email: data.email,
      messageType: data.messageType,
      subject: data.subject,
      message: data.message
    };

    ContactUsApi.sendPublicForm(postBody)
      .then(() => {
        setFormSent(true);
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      })
      .catch((error: any) => {
        const errData = error.response.data.data;

        if (errData.length) {
          errData.forEach(({ field, message }) =>
            setError(field, { type: 'manual', message })
          );
        }

        scrollToError(methods.formState.errors, formOrder);
      });
  };

  const textAreaRows = width < 600 ? 2 : 8;

  return (
    <>
      <ContactUsBanner />
      <RootPageLayout
        bgColor={theme.palette.GRAY_5.main}
        muiMaxWidth={false}
        maxWidth="100vw"
      >
        <Styled.Root data-testid="contact-us-page-root">
          {!formSent ? (
            <FormProvider {...methods}>
              <form>
                <Styled.GridContainer container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <ReactHookFormsInput
                      name="name"
                      label="Your Name"
                      rules={{ required: 'Name is required' }}
                      dataTestId="contact-us-input-name"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={0}
                    sm={6}
                    sx={{ [theme.breakpoints.down('sm')]: { display: 'none' } }}
                  />
                  <Grid item xs={12} sm={6}>
                    <ReactHookFormsInput
                      name="email"
                      label="Email Address"
                      autocomplete="email"
                      dataTestId="contact-us-input-email"
                      rules={{
                        required: 'Email is required',
                        pattern: {
                          value: emailPattern,
                          message: 'Please enter a valid email'
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ReactHookFormsSelect
                      name="messageType"
                      label="I need help with..."
                      options={messages}
                      rules={{ required: 'Message Type is required' }}
                      dataTestId="contact-us-select-messageType"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ReactHookFormsInput
                      name="subject"
                      label="Subject"
                      rules={{ required: 'Subject is required' }}
                      dataTestId="contact-us-input-subject"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ReactHookFormsTextarea
                      name="message"
                      label="Message"
                      required
                      rules={{ required: 'Message is required' }}
                      rows={textAreaRows}
                      dataTestId="contact-us-input-message"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Styled.SendBtn
                      data-testid="contact-us-send-btn"
                      onClick={handleSubmit(handleValidForm, handleInvalidForm)}
                      variant="contained"
                      fullWidth={width < 500}
                    >
                      Send
                    </Styled.SendBtn>
                  </Grid>
                </Styled.GridContainer>
              </form>
            </FormProvider>
          ) : (
            <ContactUsMessageSent />
          )}
          {!isGlrcUser && <ContactUsFooter />}
        </Styled.Root>
      </RootPageLayout>
    </>
  );
};

export default ContactUsPage;
