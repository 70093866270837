import * as React from 'react';
import { Box, Popover, Typography } from '@mui/material';
import { ChromePicker, ColorResult } from 'react-color';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import FormFieldErrorText from '@components/Forms/FormFieldErrorText';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const Styled = {
  Label: styled(Typography)({
    marginBottom: '8px'
  }),
  ColorBox: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'color'
  })<{ color: string }>(({ color }) => ({
    width: '100%',
    height: '30px',
    backgroundColor: color,
    border: `1px solid ${theme.palette.GRAY_4.main}`,
    borderRadius: '4px'
  }))
};

interface Props {
  name: string;
  label: string;
  rules?: RegisterOptions;
}
const ReactHookFormsColorPicker: React.FC<Props> = ({ name, label, rules }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...rules }}
      render={({ field: { onChange, value } }): JSX.Element => {
        return (
          <Box position="relative">
            <Styled.Label variant="EC_TYPE_BASE">{label}:</Styled.Label>
            <Styled.ColorBox onClick={handleClick} color={value} />
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left'
              }}
            >
              <ChromePicker
                disableAlpha
                color={value}
                onChange={(color: ColorResult): void => onChange(color.hex)}
              />
            </Popover>
            {errors[name] && (
              <FormFieldErrorText name={name} message={errors[name].message} />
            )}
          </Box>
        );
      }}
    />
  );
};

export default ReactHookFormsColorPicker;
