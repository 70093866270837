import { Country, Location } from '@interfaces/location.interfaces';

/**
 * @param {Location} The location to format into a single line address
 * @returns Returns a location as single line address
 */
export const getOneLineAddress = (location: Location): string => {
  let label = '';

  if (location.streetNumber) {
    label += location.streetNumber;
  }

  if (location.streetName) {
    label += `${label.length ? ' ' : ''}${location.streetName}`;
  }

  if (location.addressLineTwo) {
    label += `${label.length ? ', ' : ''}${location.addressLineTwo}`;
  }

  if (location.city) {
    label += `${label.length ? ', ' : ''}${location.city}`;
  }

  if (location.country === Country.PUERTO_RICO) {
    label += `${label.length ? ', ' : ''}${location.country}`;
  } else {
    if (location.state) {
      label += `${label.length ? ', ' : ''}${location.state}`;
    }
  }

  return label;
};
