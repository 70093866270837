import * as React from 'react';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Box,
  TextField,
  Typography,
  TypographyProps,
  useTheme
} from '@mui/material';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { AutoCompleteOption } from './ReactHookFormsAutoComplete';
import FormFieldErrorText from './FormFieldErrorText';
import { styled } from '@mui/system';

const Styled = {
  Label: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'required'
  })<{ required?: boolean }>(({ required, theme }) => ({
    marginBottom: '11px',
    position: 'relative',

    '&:after': {
      content: required ? '"*"' : '""',
      color: theme.palette.error.main,
      marginLeft: '4px'
    }
  }))
};

interface Props {
  label?: string;
  labelVariant?: TypographyProps['variant'];
  subTitle?: string;
  name: string;
  placeholder?: string;
  disablePortal?: boolean;
  freeSolo?: boolean;
  selectOnFocus?: boolean;
  openOnFocus?: boolean;
  blurOnSelect?: boolean;
  options: AutoCompleteOption[];
  rules?: RegisterOptions;
}

const ReactHookFormsAutoComplete: React.FC<Props> = (props) => {
  const {
    name,
    options = [],
    rules,
    label,
    disablePortal,
    freeSolo,
    selectOnFocus,
    openOnFocus = true,
    blurOnSelect,
    placeholder,
    labelVariant = 'EC_TYPE_BASE',
    subTitle,
  } = props;

  const {
    control,
    formState: { errors }
  } = useFormContext();

  const theme = useTheme();

  const [inputChangeReason, setInputChangeReason] = React.useState<AutocompleteInputChangeReason | AutocompleteChangeReason | null>();
  const [freeSoloValue, setFreeSoloValue] = React.useState<string>('');

  return (
    <Box id={name} position="relative">
      {label && (
        <Styled.Label variant={labelVariant} required={!!rules?.required}>
          {label}
        </Styled.Label>
      )}
      {subTitle && (
        <Typography
          variant="EC_TYPE_2XS"
          component={'p'}
          sx={{
            color: theme.palette.GRAY_3.main,
            marginBottom: '12px',
            marginTop: '-8px'
          }}
        >
          {subTitle}
        </Typography>
      )}
      <Controller
        control={control}
        name={name}
        rules={{ ...rules }}
        render={({ field: { value, onChange } }): JSX.Element => {
          return (
            <Autocomplete
              openOnFocus={openOnFocus}
              disablePortal={disablePortal}
              freeSolo={freeSolo}
              blurOnSelect={blurOnSelect}
              selectOnFocus={selectOnFocus}
              options={options}
              value={value ?? null}
              renderInput={(params): React.ReactNode => (
                <TextField 
                  {...params}  
                  placeholder={placeholder}
                  onBlur={(): void => {
                    // onChange(value);
                    // setValue(name, value);
                    // onBlur();
                  }}
                />
              )}
              getOptionDisabled={(option): boolean => !!option.disable}
              onInputChange={async (event, value, reason): Promise<void> => {
                // Tracking the most recent reason for the input change
                await setInputChangeReason(reason);

                // To be able to support free form input 
                // the current value is stored separately from Autocomplete Component and 
                // will be passed to the onChange() handler with onBlur() bellow
                if (freeSolo && reason === 'input') {
                  setFreeSoloValue(value);
                }
              }}
              onBlur={(): void => {
                // Once focus is leaving input text field, onChange() with previously stored freeSoloValue 
                // will be triggered to initiate value change for Autocomplete Component since freeSoloValue
                // is not a part of the options set
                if (freeSolo && inputChangeReason === 'input') {
                  onChange(freeSoloValue);
                }
              }}
              onChange={async (
                _event,
                newValue: AutoCompleteOption | null,
                reason: AutocompleteChangeReason,
              ): Promise<void> => {
                await setInputChangeReason(reason);

                if (typeof newValue === 'number' || typeof newValue === 'string') {
                  onChange(newValue || null);
                } else {
                  onChange(newValue?.value || null);
                }
              }}
              getOptionLabel={(option): string => {
                if (typeof option === 'number' || typeof option === 'string') {
                  const foundLabel = options.find((op) => op.value === option)?.label;
                  // In freeSolo case return the option to clearing the label
                  if (freeSolo && !foundLabel) {
                    return option.toString();
                  }

                  return foundLabel || '';
                }

                return option.label;
              }}
              isOptionEqualToValue={(option, value): boolean => {
                return option.value === value || option.value === value.value;
              }}
            />
          );
        }}
      />
      {errors[name] && (
        <FormFieldErrorText name={name} message={errors[name].message} />
      )}
    </Box>
  );
};

export default ReactHookFormsAutoComplete;
