/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Accordion, AccordionSummary, Box, Typography } from '@mui/material';
import EcChip from '@components/EcChip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Skill from '@components/Skill';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { VettedQuestionsAnswers } from '@api/models/jobApplicationApi.models';

const Styled = {
  Root: styled(Box)({
    margin: '35px 0'
  }),
  Title: styled(Box)({
    margin: '32px 0 24px',
    '& .header': {
      // color: theme.palette.GRAY_4.main,
      textTransform: 'uppercase'
    },
    '& .subheader': {
      display: 'block',
      // color: theme.palette.GRAY_3.main,
      fontSize: '11px'
    }
  }),
  Content: styled(Box)({
    margin: '0 auto',
    '& .question': {
      // color: theme.palette.GRAY_3.main
    },
    '& .answer': {
      // color: theme.palette.GRAY_3.main,
      fontSize: '11px',
      marginLeft: '15px'
    },
    '& .accordion-toggle': {
      color: theme.palette.primary.main,
      marginRight: '4px'
    },
    '& .MuiPaper-root': {
      display: 'flex',
      flexDirection: 'column',
      ':before': {
        backgroundColor: 'transparent'
      }
    },
    '& .MuiAccordionSummary-root': {
      order: 1,
      width: 'max-content',
      minHeight: 0,
      whiteSpace: 'nowrap'
    }
  }),
  QuestionBox: styled(Box)({
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    columnGap: '10px'
  }),
  SkillsBox: styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '10px',
    paddingLeft: '15px',
    marginTop: '12px'
  })
};

interface ChipProps {
  maxCount: number;
  count: number;
}

interface QAProps {
  question: string;
  answer: string | string[];
  chipProps?: ChipProps;
  candidateSkills?: string[];
}

const QuestionAndAnswer = ({
  question,
  answer,
  chipProps,
  candidateSkills
}: QAProps): JSX.Element => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleExpand =
    (panel: string) =>
    (event: React.SyntheticEvent, isExpanded: boolean): void => {
      setExpanded(isExpanded ? panel : false);
    };

  const isSelected = (ans: string): boolean => candidateSkills!.includes(ans);

  const skillArray = React.useMemo(() => {
    if (Array.isArray(answer)) {
      const array = answer as string[];
      const selectedSkills = array.filter((item) => isSelected(item));
      const unselectedSkills = array.filter((item) => !isSelected(item));
      return { selectedSkills, unselectedSkills };
    }
  }, [candidateSkills, answer]);

  return (
    <>
      <Styled.QuestionBox data-testid="vetted-qa-question-box">
        <Typography variant="EC_TYPE_SM" className="question">
          {question}
        </Typography>
        {chipProps && (
          <EcChip
            label={`${chipProps.count}/${chipProps.maxCount}`}
            typography="EC_TYPE_BASE"
            customSx={{ padding: 0 }}
            chipColor={theme.palette.primary.main}
            rounded
          />
        )}
      </Styled.QuestionBox>
      {Array.isArray(answer) ? (
        <Accordion
          expanded={expanded === 'skillPanel'}
          onChange={handleExpand('skillPanel')}
          sx={{ border: 'none' }}
          square
        >
          <AccordionSummary
            aria-controls="candidate-skills"
            data-testid="vetted-qa-accordion-summary"
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: theme.palette.primary.main,
                  width: '18px'
                }}
              />
            }
          >
            <Typography variant="EC_TYPE_2XS" className="accordion-toggle">
              {!expanded ? 'Show skills' : 'Hide skills'}
            </Typography>
          </AccordionSummary>
          <Styled.SkillsBox>
            {skillArray?.selectedSkills.map((ans: string) => (
              <Box
                key={ans}
                sx={{ width: 'max-content' }}
                data-testid="vetted-qa-skills-box-selected"
              >
                <Skill skill={ans} checked />
              </Box>
            ))}
            {skillArray?.unselectedSkills.map((ans: string) => (
              <Box
                key={ans}
                sx={{ width: 'max-content' }}
                data-testid="vetted-qa-skills-box-unselected"
              >
                <Skill skill={ans} />
              </Box>
            ))}
          </Styled.SkillsBox>
        </Accordion>
      ) : (
        <Typography
          variant="EC_TYPE_3XS"
          className="answer"
          data-testid="vetted-qa-single-answer"
        >
          {answer === 'YES' ? 'Yes' : 'No'}
        </Typography>
      )}
    </>
  );
};

const VettedQuestionAnswerSection = (
  props: VettedQuestionsAnswers
): React.ReactElement => {
  const {
    jobYearsExp,
    jobTechSkills,
    jobSoftSkills,
    answerYearsExp,
    answerTechSkills,
    answerSoftSkills
  } = props;

  const sectionData: QAProps[] = [
    {
      question:
        jobYearsExp !== 'NONE'
          ? `Do you have ${jobYearsExp} years of experience?`
          : '',
      answer: answerYearsExp
    },
    {
      question: 'In which technical skills are you confident?',
      answer: jobTechSkills,
      candidateSkills: answerTechSkills,
      chipProps: {
        maxCount: jobTechSkills.length,
        count: answerTechSkills.length
      }
    },
    {
      question: 'In which soft skills are you confident?',
      answer: jobSoftSkills,
      candidateSkills: answerSoftSkills,
      chipProps: {
        maxCount: jobSoftSkills.length,
        count: answerSoftSkills.length
      }
    }
  ];

  return (
    <Styled.Root>
      <Styled.Title>
        <Typography variant="EC_TYPE_XS" className="header">
          supplemental questions
        </Typography>
        <Typography variant="EC_TYPE_3XS" className="subheader">
          The candidate answered the following questions when applying for this
          job:
        </Typography>
      </Styled.Title>
      <Styled.Content data-testid="vetted-qa-content">
        {sectionData.map((item, idx) => {
          return item.question && <QuestionAndAnswer key={idx} {...item} />;
        })}
      </Styled.Content>
    </Styled.Root>
  );
};

export default VettedQuestionAnswerSection;
