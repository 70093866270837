import { api } from './configs/base';
import { apiConfig } from './configs/api.config';
import { ApiResponse } from './models/base.models';
import { PermissionsConfig } from 'src/permissions/permissions.config';
import { roles } from './models/userApi.models';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export class PermissionsApi {
  static async getPermissions(): Promise<PermissionsConfig> {
    try {
      const res = await api.get<ApiResponse<PermissionsConfig>>(
        `${baseUrl}${apiConfig.permissionsUrl}`
      );
      return res.data.data;
    } catch (error: any) {
      console.error(`Error for PermissionsApi.getPermissions(): ${error}`);
      throw error;
    }
  }

  static async getPermissionsByRole(role: roles): Promise<PermissionsConfig> {
    try {
      const res = await api.get<ApiResponse<PermissionsConfig>>(
        `${baseUrl}${apiConfig.permissionsUrl}/roles/${role}`
      );
      return res.data.data;
    } catch (error: any) {
      console.error(
        `Error for PermissionsApi.getPermissionsByRole(): ${error}`
      );
      throw error;
    }
  }
}
