import * as React from 'react';
import {
  Box,
  Divider,
  Icon,
  Link,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { AvatarMenuItem } from './Header.models';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useTagManager } from '@common/hooks/useTagManager';

const Styled = {
  Menu: styled(Menu)({
    '.MuiList-padding': {
      padding: '12.5px 0px'
    },

    '.MuiMenu-paper': {
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)'
    }
  }),
  MenuItem: styled(MenuItem)({
    '&.MuiMenuItem-gutters': {
      padding: '7.5px 20px'
    },

    '&:hover': {
      backgroundColor: 'transparent',

      '*': {
        color: theme.palette.primary.main,
        backgroundColor: 'transparent'
      }
    }
  }),
  MenuItemIcon: styled(Icon)({
    marginRight: '12px'
  }),
  MenuItemText: styled(Typography)({
    color: theme.palette.GRAY_4.main,
    textTransform: 'uppercase'
  })
};

interface Props {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  menuItems: Set<AvatarMenuItem> | undefined;
  logout: () => void;
}

const NavAvatarMenu: React.FC<Props> = ({
  anchorEl,
  handleClose,
  menuItems,
  logout
}) => {
  const { sendToGtm } = useTagManager();

  const hasMenuItems = menuItems && menuItems.size > 0;

  const handleClick = (item: AvatarMenuItem): void => {
    sendToGtm('navigation', {
      navigate_to: item.path,
      component: 'header',
      link_text: item.label
    });
  };

  return (
    <Styled.Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {Array.from(menuItems ?? []).map((item) => (
        <Link
          key={item.label}
          component={RouterLink}
          to={item.path}
          underline="none"
          onClick={(): void => handleClick(item)}
        >
          <Styled.MenuItem
            onClick={handleClose}
            disableRipple
            sx={{
              typography: 'EC_TYPE_XS',
              marginBottom: '7.5px',
            }}
          >
            <Box>
              <Styled.MenuItemIcon
                sx={{
                  typography: 'EC_TYPE_XS',
                  color: theme.palette.GRAY_4.main
                }}
                className={item.remixicon}
              />
              <Styled.MenuItemText variant="EC_TYPE_XS">
                {item.label}
              </Styled.MenuItemText>
            </Box>
          </Styled.MenuItem>
        </Link>
      ))}
      {hasMenuItems && (
        <Divider sx={{ border: `1px solid ${theme.palette.GRAY_1.main}` }} />
      )}
      <Link onClick={logout} underline="none">
        <Styled.MenuItem
          onClick={handleClose}
          disableRipple
          sx={{
            typography: 'EC_TYPE_XS',
            ...(hasMenuItems && {
              marginTop: '7.5px'
            })
          }}
        >
          <Box>
            <Styled.MenuItemIcon
              sx={{
                typography: 'EC_TYPE_XS',
                color: theme.palette.GRAY_4.main
              }}
              className="ri-external-link-line"
            />
            <Styled.MenuItemText variant="EC_TYPE_XS">
              Sign Out
            </Styled.MenuItemText>
          </Box>
        </Styled.MenuItem>
      </Link>
    </Styled.Menu>
  );
};

export default NavAvatarMenu;
