import {
  SendAttestationReq,
  SubmitAttestationReq
} from '@api/models/attestationApi.models';
import { api } from '@api/configs/base';
import { apiConfig } from '@api/configs/api.config';
import { ApiResponse } from '@api/models/base.models';
import { AttestationEntity } from '@interfaces/attestation.interface';
import { ExternshipEntity } from '@api/models/externshipApi.models';

export class AttestationApi {
  static async createAttestation(
    data: SendAttestationReq
  ): Promise<ApiResponse<ExternshipEntity[]>> {
    try {
      const res = await api.post<ApiResponse<ExternshipEntity[]>>(
        `${apiConfig.attestationsUrl}`,
        data
      );

      return res.data;
    } catch (error: any) {
      console.error(`Error for AttestationApi.createAttestation(): ${error}`);
      throw error;
    }
  }

  static async markAsOpened(
    externshipId: number
  ): Promise<ApiResponse<ExternshipEntity>> {
    try {
      const res = await api.put<ApiResponse<ExternshipEntity>>(
        `${apiConfig.attestationsUrl}/externship/${externshipId}/opened`
      );

      return res.data;
    } catch (error: any) {
      console.error(`Error for AttestationApi.markAsOpened(): ${error}`);
      throw error;
    }
  }

  static async submitAttestation(
    externshipId: number,
    data: SubmitAttestationReq
  ): Promise<ApiResponse<AttestationEntity>> {
    try {
      const res = await api.put<ApiResponse<AttestationEntity>>(
        `${apiConfig.attestationsUrl}/externship/${externshipId}`,
        data
      );

      return res.data;
    } catch (error: any) {
      console.error(`Error for AttestationApi.submitAttestation(): ${error}`);
      throw error;
    }
  }
}
