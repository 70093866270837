import {
  CareerPathRole,
  CareerPathTag,
  CareerRole
} from '@interfaces/careerJourney.interfaces';
import { Codebook, SkillCodebook } from '@api/models/codebook.models';

type ArrayType = CareerPathRole | CareerRole | Codebook | SkillCodebook;

const getStringArray = <T extends ArrayType>(arr?: T[]): string[] => {
  return (
    arr?.map((item) => {
      if ('name' in item) {
        return item.name;
      }

      return item.label;
    }) ?? []
  );
};

export const getTagArray = <T extends ArrayType>(
  careerArray?: T[],
  candidateArray?: T[]
): CareerPathTag[] => {
  const careerStrings = getStringArray(careerArray);
  const candidateStrings = getStringArray(candidateArray);

  return candidateStrings.map((candidateItem) => ({
    label: candidateItem,
    selected: careerStrings.includes(candidateItem)
  }));
};
