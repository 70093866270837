import * as React from 'react';
import { Box, Button, Grid } from '@mui/material';
import {
  ExternalJobsSearchReq,
  getExternalJobPostBody,
  getSearchPostBody
} from '@components/Filters/FiltersWrapper';
import {
  FilterDistanceValue,
  IFilterData,
  IFilterInput,
  IFilterState
} from '@api/models/searchApi.models';
import { FormProvider, useForm } from 'react-hook-form';
import { IExpandedFilters } from '@common/fetches/useFetchMarketplaceSearchResults';
import ReactHookFormsSelect from '@components/Forms/ReactHookFormsSelect';
import RhfLocationSearch from '@components/Forms/RhfLocationSearch';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)({
    margin: '8px 0px 24px'
  }),
  ButtonWrapper: styled(Box)({
    display: 'flex',
    justifyContent: 'space-between'
  }),
  Button: styled(Button)({
    padding: '4px 0px',
    margin: '8px 0px'
  })
};

const distanceOptions = [
  { label: '5 mi', value: 5 },
  { label: '25 mi', value: 25 },
  { label: '50 mi', value: 50 },
  { label: '100 mi', value: 100 },
  { label: '200 mi', value: 200 }
];

interface FormValues {
  location: any;
  distance: number;
}

interface Props {
  group: string;
  filter: IFilterInput;
  state: IFilterState;
  setState: React.Dispatch<React.SetStateAction<IFilterState>>;
  filterResults: (
    filterData: IFilterData[],
    resetExpandedFilters?: boolean
  ) => void;
  expandedFilters: IExpandedFilters;
  setExpandedFilters: React.Dispatch<React.SetStateAction<IExpandedFilters>>;
  filterExternalJobs?: (filterData?: ExternalJobsSearchReq) => void;
}

const DistanceFilter: React.FC<Props> = ({
  group,
  state,
  filterResults,
  filter,
  setState,
  expandedFilters,
  setExpandedFilters,
  filterExternalJobs
}) => {
  const defaultValues = {
    location: undefined,
    distance: undefined
  };

  const methods = useForm<FormValues>({
    defaultValues,
    mode: 'onChange'
  });

  const {
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { isValid }
  } = methods;

  const location = watch('location');
  const distance = watch('distance');

  React.useEffect(() => {
    if (filter.value.distance) {
      reset({ ...filter.value });
    } else {
      reset({ ...defaultValues });
    }
  }, [filter]);

  React.useEffect(() => {
    if (isValid) {
      setState({
        ...state,
        [filter.fieldName]: {
          value: { ...getValues() }
        }
      });
    } else {
      const newState = { ...state };
      delete newState[filter.fieldName];
      setState(newState);
    }
  }, [location, distance, isValid]);

  const addDistanceFilter = (
    data: IFilterData[],
    distanceFormValues: FilterDistanceValue
  ): void => {
    const distanceFilter = {
      fieldName: filter.fieldName,
      value: distanceFormValues,
      selected: true
    };

    const indexOfDistanceFilter = data.findIndex(
      (f) => f.fieldName === filter.fieldName
    );

    if (indexOfDistanceFilter >= 0) {
      data[indexOfDistanceFilter] = distanceFilter;
    } else {
      data.push({
        fieldName: filter.fieldName,
        value: distanceFormValues,
        selected: true
      });
    }
  };

  const onSubmit = (d: FormValues): void => {
    const data = getSearchPostBody(state);
    const externalJobFilter = getExternalJobPostBody(state, data);

    const distanceFormValues = {
      location: d.location,
      distance: d.distance
    };
    addDistanceFilter(data, distanceFormValues);

    setState({
      ...state,
      [filter.fieldName]: {
        value: { ...distanceFormValues }
      }
    });

    setExpandedFilters({ ...expandedFilters, [group]: true });

    filterResults(data, false);
    !!filterExternalJobs && filterExternalJobs(externalJobFilter);
  };

  const onClear = (): void => {
    const newState = { ...state };
    delete newState[filter.fieldName];

    const data = getSearchPostBody(newState);
    const externalJobFilter = getExternalJobPostBody(newState, data);

    setState(newState);
    filterResults(data, false);
    !!filterExternalJobs && filterExternalJobs(externalJobFilter);
  };

  return (
    <Styled.Root>
      <FormProvider {...methods}>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <RhfLocationSearch
              label="Zipcode"
              name="location"
              small
              zipOnly
              rules={{ required: 'Required' }}
            />
          </Grid>
          <Grid item xs={5}>
            <ReactHookFormsSelect
              name="distance"
              label="Distance"
              options={distanceOptions}
              small
              rules={{ required: 'Required' }}
            />
          </Grid>
        </Grid>
        <Styled.ButtonWrapper>
          <Styled.Button onClick={onClear}>Clear</Styled.Button>
          <Styled.Button onClick={handleSubmit(onSubmit)} disabled={!isValid}>
            Filter
          </Styled.Button>
        </Styled.ButtonWrapper>
      </FormProvider>
    </Styled.Root>
  );
};

export default DistanceFilter;
