import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

const Styled = {
  Overlay: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'loading'
  })<{ loading: boolean }>(({ loading }) => ({
    position: 'absolute',
    display: loading ? 'flex' : 'none',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.3)',
    zIndex: 1000,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%'
  }))
};

interface Props {
  loading: boolean;
  dataTestId?: string;
}

const PdfLoadingOverlay = ({
  loading,
  dataTestId
}: Props): React.ReactElement => {
  return (
    <Styled.Overlay loading={loading} data-testid={dataTestId}>
      <CircularProgress sx={{ color: '#997755' }} />
    </Styled.Overlay>
  );
};

export default PdfLoadingOverlay;
