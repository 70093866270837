import * as React from 'react';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import CandidateAccountsSection from './CandidateAccountsSection/CandidateAccountsSection';
import DailyInsightsSection from './DailyInsightsSection';
import DateRangeForm from '@components/DateRangeForm';
import JobApplicationsSection from '@components/JobApplicationsSection';
import LocationsSection from './LocationsSection';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { useFetchAdminStats } from '@common/fetches/useFetchAdminStats';
import { useTheme } from '@mui/material';

const AdminDashboardPage: React.FC = () => {
  const theme = useTheme();

  const { stats, chartLabel, dateRange, fetchStats, loaded } =
    useFetchAdminStats();

  return (
    <RootPageLayout
      muiMaxWidth="xl"
      pageTitle="Reporting Dashboard"
      bgColor={theme.palette.GRAY_F4.main}
      loading={!loaded}
    >
      {stats && (
        <BottomMarginContentWrapper bottomMargin="32px">
          <DateRangeForm
            onSubmit={fetchStats}
            sessionKey="ADMIN_DASH"
            showEcStaffFilter
          />
          <CandidateAccountsSection
            stats={stats}
            chartLabel={chartLabel}
            dateRange={dateRange}
          />
          <DailyInsightsSection data={stats.dailyInsights} />
          <JobApplicationsSection
            applicationOverview={stats.applications}
            chartLabel={chartLabel}
            dateRange={dateRange}
          />
          <LocationsSection locations={stats.completedProfileLocations} />
        </BottomMarginContentWrapper>
      )}
    </RootPageLayout>
  );
};

export default AdminDashboardPage;
