import {
  ApplicationStatusCodebook,
  SkillCodebook
} from '@api/models/codebook.models';
import { Candidate } from '@api/models/candidateApi.models';
import { FileType } from '@api/models/fileApi.models';
import { JobPost } from '@api/models/singleJobPostApi.models';

export const NotHiredStatusValues = [81, 82, 83, 84];

export interface JobApplicationPostReq {
  jobPostId: number;
  candidateId: number;
  education: string;
  experience: string;
  resumeId?: number | null;
  technicalSkills: number[];
  softSkills: number[];
  statusId: number;
}

export interface JobApplicationStatusPutReq {
  statusId: number;
}

export interface JobApplicationStatusPutRes {
  id: number;
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  updatedAt: string;
  status: ApplicationStatusCodebook;
  jobTitle: string;
}

export interface JobApplication {
  id: number;
  education: string;
  experience: string;
  status: ApplicationStatusCodebook;
  submittedDate: Date;
  resume?: number | null;
  technicalSkills: SkillCodebook[];
  softSkills: SkillCodebook[];
  jobPost: JobPost;
  externalApplicationStatus: boolean | null;
}

export interface JobApplicationAppliedCandidate
  extends Omit<JobApplication, 'resume'> {
  resume?: FileType;
  candidate: Candidate;
}

// Used for JobCandidateTable
export interface AppliedCandidate {
  candidateId: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  status: ApplicationStatusCodebook;
  submittedDate: string;
  jobApplicationId: number;
  resume?: FileType;
  interestInWorkAndLearn: string;
  jobOffersWorksAndLearn: string;
  isInvited?: boolean;
  facebookUrl?: string | null;
  instagramUrl?: string | null;
  linkedInUrl?: string | null;
  websiteUrl?: string | null;
}

export type JobApplied = {
  id: number;
  jobId: number;
  companyName: string;
  jobTitle: string;
  dateApplied: Date;
  dateAppliedString: string;
  status: ApplicationStatusCodebook;
};

export interface JobApplicationCandidates {
  applicationCount: number;
  jobId: number;
}

export interface VettedQuestionsAnswers {
  candidateId: number;
  jobPostId: number;
  jobYearsExp: string;
  jobTechSkills: string[];
  jobSoftSkills: string[];
  answerYearsExp: string;
  answerWorkLearn: string;
  answerTechSkills: string[];
  answerSoftSkills: string[];
}
