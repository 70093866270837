import { VideoPutRequest,  VideoType } from './models/videoApi.models';
import { api } from './configs/base';
import { apiConfig } from './configs/api.config';
import { ApiResponse } from './models/base.models';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export class VideosApi {
  static async uploadVideo(data: VideoPutRequest): Promise<ApiResponse<VideoType>> {
    try {
      const res = await api.post<ApiResponse<VideoType>>(`${baseUrl}${apiConfig.videosUrl}`, data);
      return res.data;
    } catch (error: any) {
      console.error(`Error for VideoApi.uploadVideo(): ${error}`);
      throw error;
    }
  }
}
