import * as React from 'react';
import { Box, Typography } from '@mui/material';
import HeaderLink from './HeaderLink';
import { NavItem } from './Header.models';
import { styled } from '@mui/system';

const Styled = {
  MenuLabel: styled(Typography)({
    padding: '18px 10px'
  })
};
interface Props {
  label: string;
  items: NavItem[];
}
const MobileSubMenu: React.FC<Props> = ({ label, items }) => {
  return (
    <Box>
      <Styled.MenuLabel variant="EC_TYPE_SM">{label}</Styled.MenuLabel>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: '28px'
        }}
      >
        {items.map((navItem) => (
          <HeaderLink
            key={navItem.label}
            title={navItem.label}
            link={navItem.path}
          />
        ))}
      </Box>
    </Box>
  );
};

export default MobileSubMenu;
