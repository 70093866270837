import * as React from 'react';
import { Button, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import RhfLocationSearch, {
  PlaceType
} from '@components/Forms/RhfLocationSearch';
import { Toast, useToast } from '@components/Toast';
import UpdateCompanyPhoneModal, {
  PhoneModalProps
} from '@pages/Shared/SettingsPage/LocationsSettings/UpdateCompanyPhoneModal';
import { BannerType } from '@api/models/bannersApi.models';
import { CompanyApi } from '@api/Company.api';
import FormWrapper from '@components/FormWrapper';
import ReactHookFormsInputPhoneMask from '@components/Forms/ReactHookFormsInputPhoneMask';
import StepSection from '@components/StepSection';
import { UpdateCompanyLocationRequest } from '@api/models/companyApi.models';
import { useFetchCompanyProfile } from '@common/fetches/useFetchCompanyProfile';
import { useHeaderFooterBannerContext } from '@common/context/headerFooterBannerContext';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';
import { validatePhoneNumber } from '@common/rhf-validators/phoneNumber.validator';

const LocationsSettings: React.FC = () => {
  const { storeId } = useParamsOrStoreCompanyId();
  const { companyProfile, setCompanyProfile } = useFetchCompanyProfile(storeId);
  const { openToast, ...toastProps } = useToast();

  // For clearing the related banner once the location is updated
  const { banners, closeBannerByBannerId } = useHeaderFooterBannerContext();
  const activeBannerId = banners?.find(
    (banner) => banner.type === BannerType.UPDATE_COMPANY_LOCATION
  )?.id;

  // For telling the API calling function whether to submit from this component, or open the modal to submit
  const updatingPhoneFromModal = React.useRef<boolean>(false);

  const [phoneModalProps, setPhoneModalProps] = React.useState<PhoneModalProps>(
    { open: false }
  );

  const methods = useForm<UpdateCompanyLocationRequest>({
    defaultValues: {
      location: undefined,
      phone: ''
    }
  });

  const { handleSubmit, watch, setValue, setError, clearErrors } = methods;
  const location = watch('location');
  const watchPhone = watch('phone');

  React.useEffect(() => {
    if (companyProfile) {
      setValue('location', companyProfile?.locations[0]);
      setValue('phone', companyProfile.phone);
      setPhoneModalProps((prev) => ({
        ...prev,
        data: { ...prev.data, phone: companyProfile.phone }
      }));
    }
  }, [companyProfile]);

  React.useEffect(() => {
    if (location) {
      setPhoneModalProps((prev) => ({
        ...prev,
        data: { ...prev.data, location }
      }));
    }
  }, [location]);

  const handleValidForm = async (
    data: UpdateCompanyLocationRequest
  ): Promise<void> => {
    const companyId = companyProfile?.id;

    try {
      const isPhoneNumberValid = validatePhoneNumber(data.phone);
      if (!isPhoneNumberValid) {
        setError('phone', {
          type: 'manual',
          message: 'Please enter a valid phone number'
        });

        return;
      } else {
        clearErrors('phone');
      }

      // If not updating phone from modal and saved phone number does not match google location phone number
      // Need to open modal and have user select which phone number to use.
      if (
        !updatingPhoneFromModal.current &&
        data.location.phone !== data.phone
      ) {
        setPhoneModalProps({
          open: true,
          data
        });

        // Set updatingFromModal to true since modal has been opened.
        // Next time this function is called, the else block will be reached.
        updatingPhoneFromModal.current = true;

        return;
      } else {
        const updatedCompany = await CompanyApi.updateCompanyLocation(
          companyId,
          data
        );

        setCompanyProfile(updatedCompany);
        if (activeBannerId) closeBannerByBannerId(activeBannerId);

        // Set updatingFromModal back to false so phone numbers can be checked again if user resubmits
        updatingPhoneFromModal.current = false;
        openToast('Company location updated successfully');
      }
    } catch (error: any) {
      console.error(
        'Error for LocationSettings -> CompanyApi.updateCompanyLocation',
        error
      );

      const errData = error.response.data.data;
      setError(errData.field, { type: 'manual', message: errData.message });
    }
  };

  const handleClick = (): void => {
    handleSubmit(handleValidForm)();
  };

  // Disable Save button if form data matches stored data
  const isLocationSameAsCompany = companyProfile?.locations.some(
    (loc) => loc.id === location?.id
  );
  const existingPhone =
    watchPhone === companyProfile?.phone || watchPhone === location?.phone;
  const emptyLocationOrPhone = !location || !watchPhone;
  const disableSaveBtn =
    (isLocationSameAsCompany && existingPhone) || emptyLocationOrPhone;

  return (
    <>
      <FormWrapper>
        <FormProvider {...methods}>
          <StepSection title="Manage Locations">
            <Grid container spacing={3} marginBottom={0}>
              <Grid item xs={12}>
                <RhfLocationSearch
                  name="location"
                  label="Company"
                  placeType={PlaceType.ESTABLISHMENTS}
                  displayMap={true}
                  rules={{ required: 'Company is required' }}
                  dataTestId="externship-location-input"
                />
              </Grid>
              <Grid item xs={12}>
                <ReactHookFormsInputPhoneMask
                  name="phone"
                  label="Business Phone"
                  rules={{ required: 'Phone number is required' }}
                  dataTestId="externship-phone-input"
                />
              </Grid>
            </Grid>
            <Button
              sx={{ paddingX: '80px', marginTop: '14px' }}
              variant="contained"
              onClick={handleClick}
              disabled={disableSaveBtn}
            >
              Save
            </Button>
          </StepSection>
          <UpdateCompanyPhoneModal
            modalProps={phoneModalProps}
            setPhoneModalProps={setPhoneModalProps}
            onSubmit={handleValidForm}
          />
        </FormProvider>
      </FormWrapper>
      <Toast {...toastProps} />
    </>
  );
};

export default LocationsSettings;
