import { useEffect, useState } from 'react';
import { AdminDashboardApi } from '@api/AdminDashboard.api';
import { CompanyPartnershipPermissions } from '@api/permissions/companyPartnershipLevel.permissions';
import { CompanyStatus } from '@api/models/adminDashboardApi.models';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';

type Permission = Omit<
  CompanyPartnershipPermissions, // eslint-disable-line @typescript-eslint/indent
  'JOB_POST_EXPIRATION_DAYS' // eslint-disable-line @typescript-eslint/indent
>;

type Perm = keyof Permission;

interface FetchCompanyStatus {
  companies?: CompanyStatus[];
  loading: boolean;
  loaded: true | null;
}

export const useFetchAdminDashboardCompanyStatus = (options?: {
  permission?: Perm;
  active?: boolean;
  profileComplete?: boolean;
  workAndLearnPartner?: boolean;
}): FetchCompanyStatus => {
  const featureFlags = useFeatureFlags();

  const [companies, setCompanies] = useState<CompanyStatus[]>();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        setLoading(true);
        const res = await AdminDashboardApi.getCompanies();

        let companies = res;

        if (options) {
          const { permission, active, profileComplete, workAndLearnPartner } =
            options;

          if (permission && featureFlags.COMPANY_PARTNERSHIP_LEVELS) {
            const data = res.filter(
              (c) => c.partnershipLevelPermissions[permission] === true
            );

            companies = data;
          }

          if (active !== undefined) {
            const companiesFilteredByActiveStatus = companies.filter(
              (c) => c.enabled === active
            );

            companies = companiesFilteredByActiveStatus;
          }

          if (profileComplete !== undefined) {
            const companiesFilteredByProfileStatus = companies.filter(
              (c) => c.profileComplete === profileComplete
            );

            companies = companiesFilteredByProfileStatus;
          }

          if (workAndLearnPartner !== undefined) {
            const companiesFilteredByWorkLearnStatus = companies.filter(
              (c) => c.workLearnPartner === workAndLearnPartner
            );

            companies = companiesFilteredByWorkLearnStatus;
          }
        }

        setCompanies(companies);
      } catch (error: any) {
        console.error(
          'Error for useFetchAdminDashboardCompanyStatus -> AdminDashboardApi.getCompanies()',
          error
        );
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    };

    fetchData();
  }, []);

  return { companies, loading, loaded };
};
