import * as React from 'react';
import { Button, Grid } from '@mui/material';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import {
  includesNumber,
  minLength,
  notMatchEmail
} from '@common/rhf-validators/passwordValidators';
import ReactHookFormsPassword from '@components/Forms/ReactHookFormsPassword';
import { scrollToError } from '@common/helpers/scrollToError';
import { SettingsApi } from '@api/Settings.api';
import StepSubSection from '@components/StepSubSection';
import { styled } from '@mui/system';
import { useToast } from '@components/Toast';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const Styled = {
  ChangePasswordBtn: styled(Button)(({ theme }) => ({
    marginTop: '30px',
    marginBottom: '13px',
    color: theme.palette.WHITE.main
  })),
  GridContainer: styled(Grid)({
    alignItems: 'start'
  })
};

interface ChangePasswordFormValues {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface Props {
  user: any;
}

const ChangePassword: React.FC<Props> = ({ user }) => {
  const { openToast } = useToast();
  const { width } = useWindowDimensions();

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };

  const methods = useForm<ChangePasswordFormValues>({
    defaultValues
  });

  const formOrder = {
    oldPassword: 1,
    newPassword: 2,
    confirmPassword: 3
  };

  const {
    handleSubmit,
    watch,
    reset,
    setError,
    formState: { isDirty }
  } = methods;

  const oldPassword = watch('oldPassword');
  const newPassword = watch('newPassword');
  const confirmPassword = watch('confirmPassword');

  const onInvalid = (errors: FieldErrors): void => {
    scrollToError(errors, formOrder);
  };

  const onValid = async (data: ChangePasswordFormValues): Promise<void> => {
    try {
      const postBody = {
        userId: user.sub,
        username: user.preferred_username,
        newPassword: data.newPassword,
        oldPassword: data.oldPassword,
        confirmPassword: data.confirmPassword
      };
      await SettingsApi.changePassword(postBody);
      reset(defaultValues);

      openToast('Password Updated Successfully');
    } catch (error: any) {
      console.error('Error for SettingsApi.changePassword()', error);
      const errData = error.response.data;
      const message = errData.message;
      setError(errData.data.field, { type: 'manual', message });
      scrollToError(methods.formState.errors, formOrder);
    }
  };

  return (
    <form>
      <FormProvider {...methods}>
        <StepSubSection title="Change Password">
          <Styled.GridContainer container spacing={4}>
            <Grid item xs={12} sm={6}>
              <ReactHookFormsPassword
                dataTestId="account-settings-old-password-input"
                name="oldPassword"
                label="Old Password"
                autocomplete="password"
                rules={{
                  validate: {
                    required: (value): boolean | string => {
                      if (newPassword && !value) {
                        return 'Old password is required';
                      }

                      return true;
                    }
                  }
                }}
              />
            </Grid>
            {width > 599 && <Grid item sm={6} xs={0} />}
            <Grid item xs={12} sm={6}>
              <ReactHookFormsPassword
                dataTestId="account-settings-new-password-input"
                name="newPassword"
                label="New Password"
                autocomplete="password"
                rules={{
                  validate: {
                    required: (value): boolean | string => {
                      if (oldPassword && !value) {
                        return 'Password is required';
                      }

                      return true;
                    },
                    notMatchEmail: notMatchEmail(user.email),
                    minLength,
                    includesNumber
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} alignSelf="end">
              <ReactHookFormsPassword
                dataTestId="account-settings-confirm-password-input"
                name="confirmPassword"
                label="Confirm Password"
                autocomplete="password"
                displayTooltip
                rules={{
                  validate: {
                    required: (): boolean | string => {
                      if (newPassword !== confirmPassword) {
                        return 'The passwords do not match';
                      }

                      return true;
                    }
                  }
                }}
              />
            </Grid>
          </Styled.GridContainer>
          <Styled.ChangePasswordBtn
            data-testid="change-password-btn"
            variant="contained"
            onClick={handleSubmit(onValid, onInvalid)}
            disabled={!isDirty}
          >
            Change Password
          </Styled.ChangePasswordBtn>
        </StepSubSection>
      </FormProvider>
    </form>
  );
};
export default ChangePassword;
