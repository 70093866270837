/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import FiltersWrapper, {
  getSearchPostBody
} from '@components/Filters/FiltersWrapper';
import {
  redirectStorageKeys,
  useStorageRedirectWithExpiration
} from '@common/helpers/storage';
import {
  useFetchMarketplaceSearchResults,
  UseFetchSearchCriteria
} from '@common/fetches/useFetchMarketplaceSearchResults';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import ExternalJobsSection from './ExternalJobsSection';
import { IPageInfoRes } from '@interfaces/pageInfo.interfaces';
import JobMarketplaceCardsWrapper from '@components/JobMarketplace/JobMarketplaceCardsWrapper';
import JobMarketplacePlaceholder from '@components/JobMarketplace/JobMarketplacePlaceholder';
import { JobPost } from '@api/models/singleJobPostApi.models';
import JobRecommendationSection from '@pages/Shared/CareerMarketplace/JobRecommendationSection/JobRecommendationSection';
import { MobileSearchFilterOverlayProvider } from '@common/context/mobileSearchFilterOverlayContext';
import PageLayoutLeftSidebar from '@components/Layout/PageLayoutLeftSidebar';
import PageLayoutLeftSidebarSkeleton from '@components/Skeletons/PageLayoutLeftSidebarSkeleton'; // MainContent
import { SortMenuContextProvider } from '@common/context/sortMenuContext';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchExternalJobs } from '@common/fetches/useFetchExternalJobs';

const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_PER_PAGE_COUNT = 24;
const MOBILE_BREAKPOINT = 900;

const sortMenuButtons = [
  {
    label: 'Date Updated',
    id: 'updatedAt'
  },
  {
    label: 'Date Created',
    id: 'createdAt'
  },
  {
    label: 'Alphabetical',
    id: 'jobTitle'
  }
];

const searchCriteria: UseFetchSearchCriteria = {
  pageInfo: {
    currentPage: DEFAULT_PAGE_NUMBER,
    currentPageEndRow: 0,
    currentPageStartRow: 0,
    filteredRows: 0,
    rowsPerPage: DEFAULT_PER_PAGE_COUNT,
    totalPages: 0,
    totalRows: 0,
    sortCategory: 'updatedAt',
    sortOrder: 'DESC'
  } as IPageInfoRes,
  filters: [],
  searchType: 'JobPost'
};

const CareerMarketplacePage: React.FC = () => {
  const {
    externalJobsResponse,
    fetchExternalJobs: filterExternalJobs,
    loading: externalJobsResLoading
  } = useFetchExternalJobs();

  const { ...searchData } = useFetchMarketplaceSearchResults<JobPost>(
    searchCriteria,
    DEFAULT_PAGE_NUMBER
  );

  const {
    searchData: jobPosts,
    pageInfo,
    filters,
    loading,
    loaded,
    filterFormState,
    setFilterFormState,
    filterMarketplaceResults: filterResults,
    resetMarketplaceResults: resetResults,
    sortResults,
    goToPage,
    expandedFilters,
    setExpandedFilters
  } = searchData;

  const featureFlags = useFeatureFlags();

  const storage = useStorageRedirectWithExpiration(
    redirectStorageKeys.CAREER_MARKETPLACE
  );

  const [filterOverlayOpen, setFilterOverlayOpen] =
    React.useState<boolean>(false);

  const openMobileFilter = (): void => {
    setFilterOverlayOpen(true);
  };

  const closeMobileFilter = (): void => {
    setFilterOverlayOpen(false);
  };

  const handleSearchChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setFilterFormState((prev) => ({
        ...prev,
        [event.target.name]: event.target.value
      }));
    },
    [setFilterFormState]
  );

  const handleSearchSubmit = (): void => {
    const postBody = getSearchPostBody(filterFormState);
    filterResults(postBody, false);
    filterOverlayOpen && closeMobileFilter();
  };

  const handleSearchClear = (): void => {
    const temp = { ...filterFormState };
    if (temp['f-SearchBar']) delete temp['f-SearchBar'];

    setFilterFormState(temp);

    const postBody = getSearchPostBody(temp);
    filterResults(postBody, false);
  };

  React.useEffect(() => {
    if (storage) {
      localStorage.removeItem(redirectStorageKeys.CAREER_MARKETPLACE);
    }
  }, [storage]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageInfo.currentPage]);

  const Content = (
    <BottomMarginContentWrapper bottomMargin="64px">
      {!loading && jobPosts.length < 1 ? (
        <JobMarketplacePlaceholder pageInfo={pageInfo} />
      ) : (
        <SortMenuContextProvider
          value={{
            sortCategory: pageInfo.sortCategory,
            sortOrder: pageInfo.sortOrder,
            sortMenuButtons,
            onSortClick: sortResults
          }}
        >
          <JobMarketplaceCardsWrapper
            cardsDetails={jobPosts}
            pageInfo={pageInfo}
            defaultPageNumber={DEFAULT_PAGE_NUMBER}
            goToPage={goToPage}
          />
        </SortMenuContextProvider>
      )}
      <ExternalJobsSection
        externalJobsResponse={externalJobsResponse}
        filterExternalJobs={filterExternalJobs}
        loading={externalJobsResLoading}
        filterFormState={filterFormState}
      />
    </BottomMarginContentWrapper>
  );

  return (
    <>
      {loading || !loaded ? (
        <PageLayoutLeftSidebarSkeleton pageTitle="EConnect" />
      ) : (
        <MobileSearchFilterOverlayProvider
          value={{
            filterOverlayOpen,
            openMobileFilter,
            closeMobileFilter,
            mobileBreakpoint: MOBILE_BREAKPOINT
          }}
        >
          <PageLayoutLeftSidebar
            eCrownPageTitle
            pageTitle="EConnect"
            searchBar={{
              value: filterFormState['f-SearchBar'],
              placeholder: 'Search jobs and descriptions',
              onChange: handleSearchChange,
              onSubmit: handleSearchSubmit,
              clearSearch: handleSearchClear
            }}
            header={
              featureFlags.JOB_RECOMMENDATIONS && <JobRecommendationSection />
            }
            sidebar={
              <FiltersWrapper
                filtersData={filters}
                filterResults={filterResults}
                filterExternalJobs={filterExternalJobs}
                resetResults={resetResults}
                state={filterFormState}
                setState={setFilterFormState}
                expandedFilters={expandedFilters}
                setExpandedFilters={setExpandedFilters}
              />
            }
            content={Content}
          />
        </MobileSearchFilterOverlayProvider>
      )}
    </>
  );
};

export default CareerMarketplacePage;
