/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  getGridStringOperators,
  GridColDef,
  GridColumnOrderChangeParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridLogicOperator
} from '@mui/x-data-grid-pro';
import {
  GridTable,
  GridTableColumn,
  GridTableView,
  SaveGridTableViewColumnsDto,
  SaveGridTableViewFilterItemDto,
  SaveGridTableViewFiltersDto
} from '@api/models/gridTableApi.models';
import {
  SessionStorageKeys,
  useStateWithSessionStorage
} from '@common/helpers/storage';
import { GridTableApi } from '@api/GridTable.api';
import { SelectChangeEvent } from '@mui/material';

export interface ViewCounts {
  [key: string]: number;
}
export interface SessionView {
  columns: GridTableColumn[];
  columnVisibilityModel: GridColumnVisibilityModel;
  customColumnOrder: number[];
  filterModel: GridFilterModel;
}

export interface ExternshipTrackerView {
  currView: number;
  sessionView?: SessionView | null;
}

export type FetchGridTable = {
  gridTable?: GridTable;
  setGridTable: React.Dispatch<React.SetStateAction<GridTable | undefined>>;
  currView?: ExternshipTrackerView;
  setCurrView: React.Dispatch<
    React.SetStateAction<ExternshipTrackerView | undefined>
  >;
  customColumnOrder: number[];
  filterModel?: GridFilterModel;
  setFilterModel: React.Dispatch<
    React.SetStateAction<GridFilterModel | undefined> // eslint-disable-line @typescript-eslint/indent
  >;
  setColumnOrderByIds: (columnOrder: GridColumnOrderChangeParams) => void;
  columnVisibilityModel?: GridColumnVisibilityModel;
  setColumnVisibilityModel: React.Dispatch<
    React.SetStateAction<GridColumnVisibilityModel | undefined> // eslint-disable-line @typescript-eslint/indent
  >;
  getFilterColumns: (columns: GridColDef[]) => SaveGridTableViewFiltersDto;
  getSaveGridTableViewColumns: () => SaveGridTableViewColumnsDto[];
  handleViewChange: (e: SelectChangeEvent<string>) => void;
  calculateViewCounts: (items: any[]) => void;
  viewCounts: ViewCounts;
  loaded: boolean;
  loading: boolean;
  sessionValue: string;
  setSessionValue: React.Dispatch<React.SetStateAction<string>>;
  parsedSessionValue: SessionView | null;
  sortGridTableColumnInCustomOrder: (
    columnOrderByIds: number[]
  ) => GridTableColumn[];
};

export enum GridFilterOperators {
  EQUALS = 'equals',
  CONTAINS = 'contains',
  IS_EMPTY = 'isEmpty',
  IS_ANY_OF = 'isAnyOf'
}

export const allowedGridFilterOperators = getGridStringOperators().filter(
  (op) =>
    Object.values(GridFilterOperators).includes(op.value as GridFilterOperators)
);

export const useFetchGridTable = (gridTableType: number): FetchGridTable => {
  const [gridTable, setGridTable] = React.useState<GridTable>();
  const [currView, setCurrView] = React.useState<ExternshipTrackerView>();
  const [viewCounts, setViewCounts] = React.useState<ViewCounts>({});

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  // MUI Data Grid State
  const [customColumnOrder, setCustomColumnOrder] = React.useState<number[]>(
    []
  );
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>();
  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>();

  const {
    parsedSessionValue,
    setSessionValue,
    removeSessionValue,
    sessionValue
  } = useStateWithSessionStorage<SessionView>(
    SessionStorageKeys.EXTERNSHIP_TRACKER_STATE
  );

  const fetchGridTable = async (gridTableType: number): Promise<void> => {
    try {
      setLoading(true);
      const res = await GridTableApi.getTableByType(gridTableType);

      const defaultView = res.defaultViewId
        ? res.defaultViewId
        : res.defaultViews[0].id;

      // On initial load sessionView will be used if present
      setGridTable(res);
      setCurrView({
        currView: defaultView,
        sessionView: parsedSessionValue
      });

      setLoaded(true);
    } catch (error: any) {
      console.error(
        'Error for useFetchGridTable -> GridTableApi.getTableByType()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  const calculateViewCounts = (items: any[]): void => {
    const viewCountsMap = {};

    const allViews: GridTableView[] = [
      ...(gridTable?.defaultViews || []),
      ...(gridTable?.customViews || [])
    ];

    allViews.forEach((view) => {
      if (!view.filters?.length) {
        viewCountsMap[view.id] = items.length;
      } else {
        const filteredItems = items.filter((item) => {
          const results: boolean[] = [];

          view.filters.forEach((filter) => {
            const fieldValue = String(item[filter.column.field]).toLowerCase();
            const filterValue = String(filter.value).toLowerCase();

            if (filter.operator === GridFilterOperators.EQUALS) {
              results.push(fieldValue === filterValue);
            }

            if (filter.operator === GridFilterOperators.CONTAINS) {
              results.push(fieldValue.includes(filterValue));
            }

            // Removed operators. Left as commented in case we want to add them back.

            // if (filter.operator === GridFilterOperators.ENDS_WITH) {
            //   results.push(fieldValue.endsWith(filterValue));
            // }

            // if (filter.operator === GridFilterOperators.STARTS_WITH) {
            //   results.push(fieldValue.startsWith(filterValue));
            // }

            if (filter.operator === GridFilterOperators.IS_EMPTY) {
              results.push(fieldValue.length === 0);
            }

            // if (filter.operator === GridFilterOperators.IS_NOT_EMPTY) {
            //   results.push(fieldValue.length > 0);
            // }

            if (filter.operator === GridFilterOperators.IS_ANY_OF) {
              const filterValues = filter.value
                ? (filter.value as string[])
                : [''];

              const isAnyOfResults: boolean[] = [];

              filterValues.forEach((val) => {
                isAnyOfResults.push(fieldValue === String(val).toLowerCase());
              });

              results.push(isAnyOfResults.some((r) => r === true));
            }
          });

          if (view.filterLogicalOperator === GridLogicOperator.And) {
            return results.every((r) => r === true);
          }

          if (view.filterLogicalOperator === GridLogicOperator.Or) {
            return results.some((r) => r === true);
          }
        });

        viewCountsMap[view.id] = filteredItems.length;
      }
    });

    setViewCounts(viewCountsMap);
  };

  // Update the filters and columns when view is updated
  const setViewFiltersAndColumns = (currView: ExternshipTrackerView): void => {
    if (!gridTable) {
      return;
    }

    if (currView.sessionView) {
      const { customColumnOrder, columns, filterModel, columnVisibilityModel } =
        currView.sessionView;
      // GET SESSIONS STORAGE
      // Reorder columns based on currView
      setCustomColumnOrder(customColumnOrder);

      const newGridTable = {
        ...gridTable,
        columns: columns || []
      };
      setGridTable(newGridTable);

      // Set grid filters based on currView
      setFilterModel(filterModel);

      // Set column visibility based on currView
      setColumnVisibilityModel(columnVisibilityModel);

      return;
    }

    const defaultViews = gridTable.defaultViews || [];
    const customViews = gridTable.customViews || [];
    const allViews = [...defaultViews, ...customViews];

    const currGridTableView = allViews.find(
      (view) => view.id === currView.currView
    );
    const filters = currGridTableView?.filters || [];
    const viewColumns = currGridTableView?.viewColumns || [];

    const newFilterModel = {
      items: filters.map((f) => ({
        id: f.ordinalNumber,
        field: f.column.field,
        operator: f.operator,
        value: f.value
      })),
      logicOperator: currGridTableView?.filterLogicalOperator
    };

    const newGridTable = {
      ...gridTable,
      columns: viewColumns.map((vc) => vc.column)
    };

    const newColumnVisibilityModel = {};

    viewColumns.forEach((c) => {
      newColumnVisibilityModel[c.column.field] = c.isVisible;
    });

    // Reorder columns based on currView
    setCustomColumnOrder(viewColumns.map((vc) => vc.column.id));
    setGridTable(newGridTable);

    // Set grid filters based on currView
    setFilterModel(newFilterModel);

    // Set column visibility based on currView
    setColumnVisibilityModel(newColumnVisibilityModel);
  };

  const setColumnOrderByIds = (
    columnOrder: GridColumnOrderChangeParams
  ): void => {
    const { targetIndex, oldIndex } = columnOrder;
    const newCustomColumnOrder = [...customColumnOrder];

    // Minus 1 because the checkbox column is index
    // zero so to reset to our column index we need to subtract 1
    const columnToMove = newCustomColumnOrder.splice(oldIndex - 1, 1)[0];

    newCustomColumnOrder.splice(targetIndex - 1, 0, columnToMove);

    setCustomColumnOrder(newCustomColumnOrder);

    const externshipState = JSON.stringify({
      // sort the columns according to the new sort order
      columns: sortGridTableColumnInCustomOrder(newCustomColumnOrder),
      columnVisibilityModel,
      customColumnOrder: newCustomColumnOrder,
      filterModel
    });
    setSessionValue(externshipState);
  };

  const sortGridTableColumnInCustomOrder = (
    columnOrder: number[]
  ): GridTableColumn[] => {
    return (
      gridTable?.columns.sort((a, b) => {
        return columnOrder.indexOf(a.id) - columnOrder.indexOf(b.id);
      }) || []
    );
  };

  const getSaveGridTableViewColumns = (): SaveGridTableViewColumnsDto[] => {
    // only return columns that should appear in view
    const viewColumns: SaveGridTableViewColumnsDto[] = [];

    gridTable?.columns.forEach((c) => {
      if (columnVisibilityModel) {
        viewColumns.push({
          columnId: (c as GridTableColumn).id,
          isVisible: columnVisibilityModel[c.field] === false ? false : true,
          ordinalNumber: customColumnOrder.findIndex((_c) => _c === c.id) + 1
        });
      }
    });

    return viewColumns;
  };

  const getFilterColumns = (
    columns: GridColDef[]
  ): SaveGridTableViewFiltersDto => {
    const viewFilterModel = { ...filterModel } as SaveGridTableViewFiltersDto;
    const filters: SaveGridTableViewFilterItemDto[] = [];

    viewFilterModel?.items?.forEach((filter, i) => {
      const column = columns.find((c) => c.field === filter.field);
      if (column) {
        filters.push({
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
          columnId: (column as GridTableColumn).id,
          ordinalNumber: i
        });
      }
    });
    viewFilterModel.items = filters;

    return viewFilterModel;
  };

  const handleViewChange = (e: SelectChangeEvent<string>): void => {
    const selectedView = e.target.value;

    if (selectedView !== 'SAVE') {
      setCurrView({ currView: Number(selectedView) });
    }
  };

  React.useEffect(() => {
    fetchGridTable(gridTableType);
  }, []);

  // When currView is changed filters a columns will be updated
  React.useEffect(() => {
    if (currView) {
      // Delete the sessionView since this means a default view was selected
      if (!currView.sessionView) {
        removeSessionValue();
      }
      setViewFiltersAndColumns(currView);
    }
  }, [currView]);

  return {
    gridTable,
    currView,
    setCurrView,
    viewCounts,
    setGridTable,
    customColumnOrder,
    filterModel,
    setFilterModel,
    columnVisibilityModel,
    setColumnVisibilityModel,
    setColumnOrderByIds,
    getFilterColumns,
    getSaveGridTableViewColumns,
    handleViewChange,
    calculateViewCounts,
    loaded,
    loading,
    sessionValue,
    setSessionValue,
    parsedSessionValue,
    sortGridTableColumnInCustomOrder
  };
};
