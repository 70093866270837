import * as React from 'react';
import { Box, Card, Link, Typography } from '@mui/material';
import Carousel from '@components/Carousel';
import ChefHat from '@assets/svg/chef_hat.round.gray1.svg';
import { CompaniesSpotlight } from '@api/models/companyApi.models';
import { CompanyApi } from '@api/Company.api';
import { GRAY_1 } from '@themes/ui/escoffier';
import ImageWithBadge from './ImageWithBadge';
import { PremierPartner } from '@api/models/adminContentManagementApi.models';
import { Link as RouterLink } from 'react-router-dom';
import { SearchApi } from '@api/Search.api';
import { styled } from '@mui/system';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const Styled = {
  Card: styled(Card)({
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    height: '100%',
    width: '100%'
  }),
  EmployerNameWrapper: styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.GRAY_1.main,
    padding: '8px',
    textAlign: 'center'
  })),
  EmployerName: styled(Typography)({
    textTransform: 'uppercase',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  }),
  LogoWrapper: styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: '136px',
    img: {
      objectFit: 'scale-down'
    }
  })
};

const LinkStyles = {
  flex: 1,
  backgroundColor: GRAY_1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '@media (max-width: 499px)': {
    minWidth: '200px',
    height: 'unset'
  },
  img: {
    width: '100%',
    height: '150px',
    objectFit: 'scale-down',
    objectPosition: 'center',
    '@media (max-width: 1200px)': {
      height: '100px'
    }
  }
};

interface Props {
  imagesPerSlide?: number;
  setHideWrapper?: React.Dispatch<React.SetStateAction<boolean>>;
}

const PremierPartnersCarousel: React.FC<Props> = ({
  imagesPerSlide = 4,
  setHideWrapper
}) => {
  const { width } = useWindowDimensions();
  const [partners, setPartners] = React.useState<PremierPartner[]>([]);

  const featureFlags = useFeatureFlags();

  // TODO: Remove when feature flag has been turned on for PREMIER_PARTNERS_MANAGEMENT
  const convertCompaniesSpotlightToPremierPartner = (
    companySpotlights: CompaniesSpotlight[]
  ): PremierPartner[] => {
    return companySpotlights.map((cs) => ({
      id: cs.id,
      companyId: cs.id,
      companyName: cs.name,
      logoUrl: cs.logoUrl,
      // Below are not present on company spotlight but not needed for this component
      createdAt: '',
      createdDate: '',
      email: ''
    }));
  };

  React.useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        if (featureFlags.PREMIER_PARTNERS_MANAGEMENT) {
          const res = await SearchApi.getRandomPremierPartners();
          setHideWrapper && setHideWrapper(!res.length);
          setPartners(res);
        } else {
          const res = await CompanyApi.getRandomCompanies(20);
          const partners = convertCompaniesSpotlightToPremierPartner(res.data);
          setPartners(partners);
        }
      } catch (error) {
        console.error('Error for CompanyApi.getRandomCompanies()', error);
      }
    };

    fetchData();
  }, []);

  const imagePerSlide = (): number => {
    if (width < 901 && width >= 500) {
      return 2;
    }

    if (width <= 499) {
      return 1;
    }

    return imagesPerSlide;
  };

  const carouselItems = partners.map((partner) => {
    return (
      <Link
        key={partner.id}
        component={RouterLink}
        to={`/employer/${partner.companyId}/profile`}
        underline="none"
        sx={LinkStyles}
      >
        <Styled.Card>
          <Styled.EmployerNameWrapper>
            <Styled.EmployerName variant="EC_TYPE_2XL">
              {partner.companyName}
            </Styled.EmployerName>
          </Styled.EmployerNameWrapper>
          <Styled.LogoWrapper>
            <ImageWithBadge
              src={partner.logoUrl || ChefHat}
              noBorder
              expandableBadge
              noBorderRadius
            />
          </Styled.LogoWrapper>
        </Styled.Card>
      </Link>
    );
  });

  return (
    <Carousel
      timer={5000}
      itemsPerSlide={imagePerSlide()}
      items={carouselItems}
    />
  );
};

export default PremierPartnersCarousel;
