export const convertHoursAndMinsToMins = (hoursAndMins: string): number => {
  const [hours, mins] = hoursAndMins.split(':');

  let totalMins = 0;

  if (Number(hours)) {
    totalMins += Number(hours) * 60;
  }

  if (Number(mins)) {
    totalMins += Number(mins);
  }

  return totalMins;
};
