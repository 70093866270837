import * as React from 'react';
import { Codebook, EcCampus } from '@api/models/codebook.models';
import {
  mobileWorkAndLearnMap,
  workAndLearnMap
} from '@common/select-field-labels/workAndLearn';
import ReactHookFormsFieldArray, {
  FieldArrayFieldProps
} from '@components/Forms/ReactHookFormsFieldArray';
import { Education } from '@api/models/profileSetupApi.models';
import { Grid } from '@mui/material';
import ReactHookFormsSelect from '@components/Forms/ReactHookFormsSelect';
import ReactHookFormsTagSelection from '@components/Forms/ReactHookFormsTagSelection';
import StepSection from '@components/StepSection';
import StepSubSection from '@components/StepSubSection';
import { useFetchCampusCodebook } from '@common/fetches/useFetchCampusCodebook';
import { useFetchCampusProgramsCodebook } from '@common/fetches/useFetchCampusProgramCodebook';
import { useFetchCandidateCodebooks } from '@common/fetches/useFetchCandidateCodebooks';
import { useFetchEducationTypes } from '@common/fetches/useFetchEducationTypes';
import { useFormContext } from 'react-hook-form';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

interface Props {
  educationRequired: boolean;
  setEducationRequired: (required: boolean) => void;
}

type EducationFields = {
  id: string;
} & Education<Codebook>;

const EducationSection: React.FC<Props> = ({
  educationRequired,
  setEducationRequired
}) => {
  const { width } = useWindowDimensions();
  const { campuses } = useFetchCampusCodebook();
  const { certificates } = useFetchCandidateCodebooks();
  const { educationTypes } = useFetchEducationTypes();
  const { ...formContext } = useFormContext();

  const { campusPrograms: austinPrograms } = useFetchCampusProgramsCodebook(
    EcCampus.AUSTIN
  );
  const { campusPrograms: boulderPrograms } = useFetchCampusProgramsCodebook(
    EcCampus.BOULDER
  );
  const { campusPrograms: onlineProgams } = useFetchCampusProgramsCodebook(
    EcCampus.ONLINE
  );

  const {
    setValue,
    clearErrors,
    watch,
    formState: { errors }
  } = formContext;

  const optionMap = width < 500 ? mobileWorkAndLearnMap : workAndLearnMap;
  const workAndLearnOptions = Object.keys(optionMap).map((key) => ({
    label: optionMap[key],
    value: key
  }));

  const getProgramsBasedOnCampus = (field): Codebook[] => {
    const { campus } = field;
    let campusPrograms: Codebook[] = [];

    if (!campus) {
      return [];
    }

    switch (campus) {
      case EcCampus.AUSTIN:
        campusPrograms = austinPrograms;
        break;
      case EcCampus.BOULDER:
        campusPrograms = boulderPrograms;
        break;
      case EcCampus.ONLINE:
        campusPrograms = onlineProgams;
        break;
      default:
        campusPrograms = [];
        break;
    }

    return campusPrograms;
  };

  const isAreaOfFocusRequired = (field): boolean => {
    if (field['educationType']) {
      const { educationType } = field;

      return educationType !== educationTypes[0].value;
      // TODO: use this return once back educationType is a Codebook
      // return educationType.value !== educationTypes[0].value
    }

    return true;
  };

  const isProgramDisabled = (field): any => {
    if (field['campus']) {
      return false;
    }

    return true;
  };

  const isEscoffierStudentOrAlumni = (field): boolean => {
    if (field['educationType']) {
      const { educationType } = field;

      return (
        educationType === educationTypes[6].value ||
        educationType === educationTypes[7].value
      );
    }

    return false;
  };

  const isNonEscoffierStudentOrAlumni = (field): boolean => {
    if (field['educationType']) {
      const { educationType } = field;

      return (
        educationType !== educationTypes[6].value &&
        educationType !== educationTypes[7].value
      );
    }

    return true;
  };

  const handleEducationTypeSelect = (idx: number): void => {
    const eduType = watch(`education.${idx}.educationType`);

    if (eduType === educationTypes[0].value) {
      const fieldToUpdate = `education.${idx}.areaOfFocus`;

      setValue(fieldToUpdate, '');
      if (errors.education?.[idx]?.areaOfFocus) {
        clearErrors(fieldToUpdate);
      }
    }

    if (
      eduType === educationTypes[6].value ||
      eduType === educationTypes[7].value
    ) {
      const institutionField = `education.${idx}.institution`;
      const areaOfFocusField = `education.${idx}.areaOfFocus`;
      setValue(institutionField, '');
      setValue(areaOfFocusField, '');
      clearErrors([institutionField, areaOfFocusField]);
    } else {
      const campusField = `education.${idx}.campus`;
      const programField = `education.${idx}.program`;
      setValue(campusField, '');
      setValue(programField, '');
      clearErrors([campusField, programField]);
    }
  };

  const handleCampusSelect = (idx: number): void => {
    const fieldToUpdate = `education.${idx}.program`;

    setValue(fieldToUpdate, '');
    if (errors.education?.[idx]?.program) {
      clearErrors(fieldToUpdate);
    }
  };

  const fieldArray: FieldArrayFieldProps<EducationFields>[] = [
    {
      fieldName: 'educationType',
      inputType: 'select',
      inputLabel: 'Education Type',
      selectOptions: educationTypes,
      displayAsterisk: true,
      onSelectMenuClose: handleEducationTypeSelect
    },
    {
      fieldName: 'graduationYear',
      inputType: 'outlined_input',
      inputLabel: 'Graduation Year',
      displayAsterisk: true,
      rules: {
        value: /^[0-9]+$/,
        message: 'Please enter a number'
      }
    },
    {
      fieldName: 'institution',
      inputType: 'outlined_input',
      inputLabel: 'Institution',
      displayAsterisk: true,
      getIsHidden: isEscoffierStudentOrAlumni
    },
    {
      fieldName: 'areaOfFocus',
      inputType: 'outlined_input',
      inputLabel: 'Area of Focus',
      getDependentFieldRequired: isAreaOfFocusRequired,
      getIsHidden: isEscoffierStudentOrAlumni
    },
    {
      fieldName: 'campus',
      inputType: 'select',
      inputLabel: 'Select your campus',
      selectOptions: campuses,
      displayAsterisk: true,
      onSelectMenuClose: handleCampusSelect,
      getIsHidden: isNonEscoffierStudentOrAlumni
    },
    {
      fieldName: 'program',
      inputType: 'select',
      inputLabel: 'Select your program',
      getDependentFieldDisabled: isProgramDisabled,
      selectOptions: getProgramsBasedOnCampus,
      displayAsterisk: true,
      getIsHidden: isNonEscoffierStudentOrAlumni
    }
  ];

  return (
    <StepSection
      title="Education Experience"
      dataTestId="candidate-education-section"
    >
      <StepSubSection
        title="What is your educational background?"
        wrapperId="education"
      >
        <Grid container>
          <Grid item xs={12}>
            <ReactHookFormsFieldArray
              fieldArrayName="education"
              fieldMap={fieldArray}
              fieldArrayRequired={educationRequired}
              setFieldArrayRequired={setEducationRequired}
              addBtnText="Add Education"
              sectionTitle="Add your educational accomplishments"
              alwaysDisplayTitleAsterisk={true}
            />
          </Grid>
          <Grid item mt="20px">
            <ReactHookFormsTagSelection
              tags={certificates}
              name="certificates"
              title="Select any certificates you have:"
            />
          </Grid>
          <Grid item mt="40px" xs={12}>
            <ReactHookFormsSelect
              name="workAndLearn"
              label="Are you interested in further pursuing your education while you work?"
              options={workAndLearnOptions}
              rules={{ required: 'Required' }}
            />
          </Grid>
        </Grid>
      </StepSubSection>
    </StepSection>
  );
};

export default EducationSection;
