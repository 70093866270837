import * as React from 'react';
import { Box, Skeleton, Theme, Typography, useTheme } from '@mui/material';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import CsApprovalsTable from './CsApprovalsTable';
import { ExternshipStatus } from '@api/models/externshipApi.models';
import RootPageLayout from '@components/Layout/RootPageLayout';
import TableWrapperWithSort from '@components/TableWrapperWithSort';
import { useFetchExternshipsByStatus } from '@common/fetches/useFetchExternshipsByStatus';

const ApprovalsPage: React.FC = () => {
  const theme = useTheme();
  const { externships, loading } = useFetchExternshipsByStatus(
    ExternshipStatus.PENDING_EXT_CS_AGREEMENT
  );

  const sortButtons = [
    { label: 'Created At', id: 'id' },
    { label: 'Status', id: 'status' }
  ];

  return (
    <RootPageLayout muiMaxWidth={false} bgColor={theme.palette.GRAY_1.main}>
      <BottomMarginContentWrapper bottomMargin={'64px'}>
        <Typography
          variant="EC_TYPE_3XL"
          sx={{
            color: theme.palette.primary.main,
            textTransform: 'uppercase',
            fontSize: (theme as Theme).typography.EC_TYPE_5XL.fontSize,
            lineHeight: (theme as Theme).typography.EC_TYPE_5XL.lineHeight
          }}
        >
          Externship Agreement Approval
        </Typography>
        <Box>
          {loading ? (
            <Skeleton variant="rectangular" height={200} />
          ) : (
            <TableWrapperWithSort
              headerTitle="Externship Agreements Awaiting Approval"
              sortButtons={sortButtons}
              initialSortOrder="ASC"
              initialSortCategory="id"
              displayMobile={false}
              disabled={externships.length < 2}
              styledHeader
            >
              <CsApprovalsTable externships={externships} />
            </TableWrapperWithSort>
          )}
        </Box>
      </BottomMarginContentWrapper>
    </RootPageLayout>
  );
};

export default ApprovalsPage;
