import React, { useEffect, useState } from 'react';
import { Codebook } from '@api/models/codebook.models';
import { CodebookApi } from '@api/Codebook.api';

type FetchGlrcContentTypesCodebook = {
  glrcContentTypes: Codebook[];
  loading: boolean;
  loaded: boolean;
};

/**
 * Fetch Glrc Content Types Codebook, used for Company GLRC Access Modal
 *
 * @returns `{ glrcContentTypes: Codebook[]; loading: boolean; loaded: boolean; }`
 */
export const useFetchGlrcContentTypesCodebook =
  (): FetchGlrcContentTypesCodebook => {
    const [glrcContentTypes, setGlrcContentTypes] = useState<Codebook[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      const fetchData = async (): Promise<void> => {
        try {
          setLoading(true);

          const res = await CodebookApi.getGlrcContentTypes();
          setGlrcContentTypes(res.data);
          setLoaded(true);
        } catch (error: any) {
          console.error(
            'Error for useFetchGlrcContentTypesCodebook -> CodebookApi.getGlrcContentTypes()',
            error
          );
        } finally {
          setLoading(false);
          setLoaded(true);
        }
      };
      fetchData();
    }, []);

    return { glrcContentTypes, loading, loaded };
  };
