import * as React from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Styled = {
  TitleBox: styled(Box)({
    marginTop: '32px',
    marginBottom: '24px',
    '& .subtitle': {
      display: 'block'
      // color: theme.palette.GRAY_3.main
    }
  }),
  InfoBox: styled(Box)({
    margin: '0 auto',
    '& .info-icon': {
      position: 'relative',
      // color: theme.palette.GRAY_3.main,
      marginRight: '7.25px',
      top: '1.5px',
      left: 0,
      maxWidth: '15px'
    },
    '& .social-link': {
      // color: theme.palette.GRAY_3.main
    }
  })
};

export type DisplayData = {
  icon: string | { src: string };
  label: string;
  value?: string | null;
  href?: string;
  socialLink?: string | null;
};

interface CandidateInformationProps {
  sectionTitle?: string;
  sectionSubtitle?: string;
  displayData?: DisplayData[];
  children?: React.ReactNode;
}

const JobCandidateInfoSection = ({
  sectionTitle,
  sectionSubtitle,
  displayData,
  children
}: CandidateInformationProps): React.ReactElement => {
  // const theme = useTheme();

  return (
    <Box sx={{ margin: '35px 0' }}>
      <Styled.TitleBox>
        <Typography variant="EC_TYPE_XS">
          {sectionTitle?.toUpperCase()}
        </Typography>
        {sectionSubtitle && (
          <Typography variant="EC_TYPE_3XS" className="subtitle">
            {sectionSubtitle}
          </Typography>
        )}
      </Styled.TitleBox>
      <Styled.InfoBox>
        <Grid
          container
          rowSpacing={{ md: '30px', sm: '8px', xs: '8px' }}
          alignItems="center"
        >
          {displayData?.map((data, idx) => {
            if (data.value) {
              return (
                <React.Fragment key={data.label}>
                  <Grid item md={4} sm={4} xs={4} sx={{ minWidth: '86px' }}>
                    <Typography
                      data-testid={`candidate-info-item-label-${idx}`}
                      variant="EC_TYPE_SM"
                    >
                      {typeof data.icon === 'string' ? (
                        <i className={`${data.icon} info-icon`} />
                      ) : (
                        <img
                          src={data.icon.src}
                          alt={`${data.label} Icon`}
                          className="info-icon"
                        />
                      )}
                      {data.label}:
                    </Typography>
                  </Grid>
                  <Grid item md={8} sm={8} xs={8} zeroMinWidth>
                    <Typography
                      data-testid={`candidate-info-item-${idx}`}
                      // color={theme.palette.GRAY_3.main}
                      variant="EC_TYPE_BASE"
                    >
                      {data?.socialLink ? (
                        <Link
                          href={data.socialLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          underline="hover"
                          className="social-link"
                        >
                          {data.value}
                        </Link>
                      ) : (
                        data.value
                      )}
                      {children}
                    </Typography>
                  </Grid>
                </React.Fragment>
              );
            }
          })}
        </Grid>
      </Styled.InfoBox>
    </Box>
  );
};

export default JobCandidateInfoSection;
