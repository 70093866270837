import { ExternshipStatus } from '@api/models/externshipApi.models';

const externshipStatusMap = {
  [ExternshipStatus.DRAFT]: 'DRAFT',
  [ExternshipStatus.PENDING_SOFT_VERIFICATION]: 'PENDING SOFT VERIFICATION',
  [ExternshipStatus.PENDING]: 'PENDING',
  [ExternshipStatus.PENDING_SITE_AGREEMENT]: 'PENDING SITE AGREEMENT',
  [ExternshipStatus.PENDING_EXT_MNG_AGREEMENT]:
    'PENDING MANAGER EXTERNSHIP AGREEMENT',
  [ExternshipStatus.PENDING_EXT_STD_AGREEMENT]:
    'PENDING STUDENT EXTERNSHIP AGREEMENT',
  [ExternshipStatus.PENDING_EXT_CS_AGREEMENT]:
    'PENDING CAREER SERVICES EXTERNSHIP AGREEMENT',
  [ExternshipStatus.PENDING_PROXY_AGREEMENT]: 'PENDING PROXY AGREEMENT',
  [ExternshipStatus.ACTIVE]: 'ACTIVE',
  [ExternshipStatus.DECLINED]: 'DECLINED',
  [ExternshipStatus.CS_REJECTED]: 'CS REJECTED',
  [ExternshipStatus.CANCELED]: 'CANCELED',
  [ExternshipStatus.ARCHIVED]: 'ARCHIVED',
  [ExternshipStatus.COMPLETED]: 'COMPLETED',
  [ExternshipStatus.NO_PASS]: 'NO PASS'
};

export const getExternshipStatusLabel = (status: ExternshipStatus): string => {
  return externshipStatusMap[status];
};
