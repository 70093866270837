import * as React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const Styled = {
  ContentContainer: styled(Grid)({
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  })
};

const CustomXAxisTick = ({ x, y, payload }: any): JSX.Element => {
  if (payload && payload.value) {
    return (
      <Text
        width={'12px'}
        x={x}
        y={y}
        textAnchor="middle"
        verticalAnchor="start"
      >
        {payload.value}
      </Text>
    );
  }
  return <></>;
};

interface Props {
  barData: { name: string; value: number }[];
  barName: string;
  content: React.ReactNode;
  label: string;
  dataTestId?: string;
}

const BarChartWithSideContent: React.FC<Props> = ({
  barData,
  barName,
  content,
  label,
  dataTestId
}) => {
  return (
    <Grid container spacing={5} data-testid={dataTestId}>
      <Grid item xs={12} md={6} sx={{ textAlign: 'center', marginTop: '24px' }}>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={barData}
            margin={{ top: -36, left: 0, right: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              height={40}
              interval={0}
              tick={<CustomXAxisTick />}
            />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            <Bar
              dataKey="value"
              name={barName}
              fill={theme.palette.GOLD_1.main}
            />
          </BarChart>
        </ResponsiveContainer>
        <Typography variant="EC_TYPE_SM" sx={{ marginTop: '32px' }}>
          {label}
        </Typography>
      </Grid>
      <Styled.ContentContainer item xs={12} md={6}>
        <Box>{content}</Box>
      </Styled.ContentContainer>
    </Grid>
  );
};

export default BarChartWithSideContent;
