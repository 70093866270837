/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import BatchActionModalLayout, {
  SelectedRowEnrollmentEntity
} from '@components/Layout/BatchActionModalLayout';
import { CareerServicesApi } from '@api/CareerService.api';
import { useBatchActionsContext } from '@common/context/batchActionsContext';

interface Props {
  open: boolean;
}

const PilotProgramModal: React.FC<Props> = ({ open }) => {
  const { handleCloseModal, updateDataGridData } = useBatchActionsContext();

  const [selectedRows, setSelectedRows] = React.useState<
    SelectedRowEnrollmentEntity[]
  >([]);

  const handleEnablePilotProgram = async (): Promise<void> => {
    try {
      const studentIds = selectedRows.map((r) => r.studentId);
      const res = await CareerServicesApi.enableExternshipPilotForStudents({
        studentIds
      });

      updateDataGridData(res);
    } catch (error) {
      console.error(
        'Error: CareerServicesDashboardPage.handleEnablePilotProgram()'
      );
    } finally {
      handleCloseModal();
    }
  };

  return (
    <BatchActionModalLayout
      open={open}
      modalTitle="Enable Externship Pilot Program"
      modalSubtext="Allow the following student(s) to access the externship pilot program"
      primaryBtnText="Enable"
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      handleSubmitClick={handleEnablePilotProgram}
      handleClose={handleCloseModal}
      toastMessage="Enabled Pilot Externship Program for selected students"
    />
  );
};

export default PilotProgramModal;
