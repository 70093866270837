import {
  CampusCourseStatus,
  CourseData,
  CourseStatusesIneligibleForSiteProposal
} from '@api/models/enrollmentApi.models';
import { useEffect, useState } from 'react';
import { EnrollmentApi } from '@api/Enrollment.api';

interface FetchExternshipCourses {
  courseData?: CourseData[];
  loading: boolean;
  fetchExternshipCourses: (
    enrollId?: number | null,
    studentId?: number | null
  ) => Promise<void>;
}

// Used to get externship courses for proposal so only allow courses with an eligible status
export const useFetchExternshipCourses = (
  enrollmentId?: number,
  enrollmentStudentId?: number,
  ecCandidateId?: number
): FetchExternshipCourses => {
  const [courseData, setCourseData] = useState<CourseData[]>();
  const [loading, setLoading] = useState(false);

  const fetchExternshipCourses = async (
    enrollId?: number | null,
    studentId?: number | null,
    candidateId?: number | null
  ): Promise<void> => {
    if (!!enrollId && !!studentId && !!candidateId) {
      try {
        setLoading(true);

        const res = await EnrollmentApi.getStudentExternshipCourses(
          enrollId,
          studentId,
          candidateId
        );

        // Get courses that are eligible to propose site
        const scheduledCourses = res.data.filter(
          (item) =>
            item.StudentEnrollmentPeriodId === enrollId &&
            item.StartDate !== null &&
            !CourseStatusesIneligibleForSiteProposal.includes(
              item.Status as CampusCourseStatus
            )
        );

        setCourseData(scheduledCourses);
      } catch (error) {
        console.error(
          'Error for useFetchExternshipCourses -> EnrollmentApi.getExternshipCourses()',
          error
        );
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (enrollmentId && enrollmentStudentId && ecCandidateId) {
      fetchExternshipCourses(enrollmentId, enrollmentStudentId, ecCandidateId);
    }
  }, [enrollmentId, enrollmentStudentId, ecCandidateId]);

  return {
    courseData,
    loading,
    fetchExternshipCourses
  };
};
