import * as React from 'react';
import { Button, useTheme } from '@mui/material';
import { BLUE_1 } from '@themes/ui/escoffier';
import { ExternshipAgreementData } from '@api/models/externshipApi.models';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { UseFormRegister } from 'react-hook-form';

const styles = {
  inputBase: {
    border: `2px solid ${BLUE_1}`
  },
  smallWidth: {
    width: '94px'
  },
  marginDiv: {
    margin: '24px 0'
  },
  topMargin: {
    marginTop: '32px'
  },
  noMargin: {
    margin: 0
  },
  reviseBtn: {
    marginLeft: '16px'
  }
};

interface StepOneProps extends ExternshipAgreementData {
  signDate: string;
  isRevising?: boolean;
}

export const ManagerStepOne = ({
  signDate,
  managerName,
  coursesForAgreement,
  isRevising = false
}: StepOneProps): React.ReactElement => {
  const managerSignature = isRevising || !signDate ? '' : managerName;

  return (
    <div>
      <h3 style={{ borderBottom: '1px solid black' }}>OVERVIEW</h3>
      <p>
        The Externship is a required course, in which the student gains relevant
        experience working in an approved food service establishment under the
        supervision of an industry professional.
      </p>
      <p>
        It is the Student&apos;s responsibility to secure an externship site,
        and the school&apos;s responsibility to facilitate this by providing the
        student with information, resources and guidance. Students complete the
        following course(s) in order to fulfill their externship commitment as
        part of their graduation requirement:
      </p>
      {coursesForAgreement.map((c) => (
        <div key={c.courseCode}>
          <div style={styles.marginDiv}>
            <span>
              <strong>
                {c.courseCode} {c.courseName}*
              </strong>
              &nbsp;requires a minimum of&nbsp;
              <strong>{c.workHours}</strong>
              &nbsp;work hours over 6 weeks.
            </span>
          </div>
          <div style={styles.marginDiv}>
            <p style={{ margin: 0 }}>
              <strong>Externship Course Start Date:</strong>&nbsp;&nbsp;
              {formatDate(
                new Date(c.startDate).toISOString(),
                'MM-dd-yyyy',
                undefined,
                true
              )}
            </p>
            <p style={{ margin: 0 }}>
              <strong>Expected Completion Date:</strong>&nbsp;&nbsp;
              {formatDate(
                new Date(c.endDate).toISOString(),
                'MM-dd-yyyy',
                undefined,
                true
              )}{' '}
            </p>
          </div>
        </div>
      ))}
      <div style={{ margin: '32px 0px' }}>
        <p>
          <strong>SIGNED BY MANAGER:&nbsp;</strong>
          {managerSignature}
        </p>
        <p>
          <strong>DATE SIGNED:&nbsp;</strong>
          {signDate}
        </p>
      </div>
      <p style={{ fontSize: '13px' }}>
        * Externship course lecture hours and classroom discussion may be
        required in addition to the work hours required at the externship site.
        Please refer to the Escoffier Catalog or course syllabus in the Moodle
        classroom for additional course requirements.
      </p>
    </div>
  );
};

interface StepTwoProps extends ExternshipAgreementData {
  signDate: string;
  compensationType: string | null;
  saving: boolean;
  disabled: boolean;
  register: UseFormRegister<any>;
  isRevising?: boolean;
  handleOpenRevisionModal?: () => void;
  careerServicesSigning?: boolean;
}

export const ManagerStepTwo = ({
  companyName,
  companyPhone,
  companyAddress,
  managerName,
  managerPhone,
  managerJobTitle,
  managerEmail,
  studentJobTitle,
  employmentStartDate,
  revisedEmploymentStartDate,
  signDate,
  compensationType,
  payRate,
  saving,
  disabled,
  register,
  isRevising = false,
  handleOpenRevisionModal,
  careerServicesSigning = false,
  studentName,
  coursesForAgreement
}: StepTwoProps): React.ReactElement => {
  const theme = useTheme();
  const managerSignature = isRevising || !signDate ? '' : managerName;

  return (
    <div>
      <h3 style={{ borderBottom: '1px solid black' }}>
        SECTION I - EXTERNSHIP SITE INFORMATION
      </h3>
      <p style={styles.noMargin}>
        <strong>BUSINESS NAME:&nbsp;</strong>
        {companyName}
      </p>
      <p style={styles.noMargin}>
        <strong>PHONE:&nbsp;</strong>
        {companyPhone}
      </p>
      <p style={styles.noMargin}>
        <strong>BUSINESS ADDRESS:&nbsp;</strong>
        {companyAddress}
      </p>
      <br />
      <p style={styles.noMargin}>
        <strong>MANAGER&apos;S NAME:&nbsp;</strong>
        {managerName}
      </p>
      <p style={styles.noMargin}>
        <strong>PHONE:&nbsp;</strong>
        {managerPhone}
      </p>
      <p style={styles.noMargin}>
        <strong>TITLE:&nbsp;</strong>
        {managerJobTitle}
      </p>
      <p style={styles.noMargin}>
        <strong>WORK EMAIL:&nbsp;</strong>
        {managerEmail}
      </p>
      <br />
      <p style={styles.noMargin}>
        <strong>STUDENT&apos;S NAME:&nbsp;</strong>
        {studentName}
      </p>
      <p style={styles.noMargin}>
        <label htmlFor="studentJobTitle">
          <strong>STUDENT&apos;S POSITION:&nbsp;</strong>
        </label>
        {saving || disabled ? (
          <span>{studentJobTitle}</span>
        ) : (
          <input {...register('studentJobTitle')} style={styles.inputBase} />
        )}
      </p>
      <p style={styles.noMargin}>
        <strong>EMPLOYMENT START DATE:&nbsp;</strong>
        {saving || disabled ? (
          <span
            style={{
              textDecoration:
                revisedEmploymentStartDate || isRevising
                  ? 'line-through'
                  : 'none'
            }}
          >
            {employmentStartDate}
          </span>
        ) : (
          <input
            {...register('employmentStartDate')}
            type="date"
            style={styles.inputBase}
          />
        )}
        {careerServicesSigning && !saving && !isRevising && signDate && (
          <Button
            style={styles.reviseBtn}
            variant="text"
            startIcon={<i className="ri-edit-fill" />}
            onClick={handleOpenRevisionModal}
          >
            Revise
          </Button>
        )}
      </p>

      {(isRevising || revisedEmploymentStartDate) && (
        <p style={styles.noMargin}>
          <strong>
            <span style={{ color: theme.palette.error.main }}>*</span>REVISED
            EMPLOYMENT START DATE:&nbsp;
          </strong>
          {saving || !isRevising ? (
            <span>
              {revisedEmploymentStartDate
                ? formatDate(revisedEmploymentStartDate, 'MM-dd-yyyy', true)
                : ''}
            </span>
          ) : (
            <input
              {...register('revisedEmploymentStartDate')}
              type="date"
              style={styles.inputBase}
            />
          )}
        </p>
      )}
      <p style={styles.noMargin}>
        <label htmlFor="compensationType">
          <strong>COMPENSATION STRUCTURE:&nbsp;</strong>
        </label>
        {saving || disabled ? (
          <span>{compensationType}</span>
        ) : (
          <select
            {...register('compensationType')}
            style={{ border: `2px solid ${BLUE_1}` }}
          >
            <option value="" disabled>
              select compensation
            </option>
            <option value="unpaid">Unpaid</option>
            <option value="paid">Paid</option>
          </select>
        )}
      </p>
      {compensationType === 'paid' && (
        <p style={styles.noMargin}>
          <label htmlFor="payRate">
            <strong>
              HOURLY RATE OF PAY&#40;$&#41;:&nbsp;&#40;IF KNOWN&#41;&nbsp;
            </strong>
          </label>
          {saving || disabled ? (
            <span>${payRate}</span>
          ) : (
            <>
              $
              <input
                type="number"
                step=".01"
                {...register('payRate')}
                style={styles.smallWidth}
              />
            </>
          )}
        </p>
      )}
      <div style={styles.marginDiv}>
        <strong>THE EXTERNSHIP SITE AGREES TO:</strong>
        <ol type="a">
          <li>
            Provide training that is similar to that which would be given in a
            vocational school environment.
          </li>
          <li>Provide guidance and direction to the student.</li>
          <li>
            Work with the student to develop a schedule that includes rotational
            and progressive tasks.
          </li>
          {coursesForAgreement.map((c) => (
            <React.Fragment key={c.courseCode}>
              <li>
                Provide the student {c.workHours} hours over 6 weeks for{' '}
                {c.courseCode}.
              </li>
            </React.Fragment>
          ))}
          <li>
            Sign the student&apos;s timesheet{' '}
            <div
              style={{
                display: 'inline-block',
                borderBottom: '1px solid black'
              }}
            >
              weekly
            </div>{' '}
            verifying the dates and hours worked that week.
          </li>
          <li>Complete evaluations and review them with student.</li>
          <li>
            Contact the school immediately if the student quits or is terminated
            during the externship.
          </li>
          <li>
            Be an equal opportunity employer and comply with all labor laws.
          </li>
          <li>
            Not require the student to work more than 8 hours per day for 5
            consecutive days.
          </li>
        </ol>
      </div>
      <div>
        <strong> Externship Manager/Supervisor:</strong> I hereby verify that I
        have accepted the student listed above as an extern at my establishment,
        and I am aware of my responsibilities as the designated manager or
        supervisor of this student during his/her externship, including
        verifying weekly attendance reporting and the assessment process for
        completing the required student evaluations. If I am unable to verify
        time, I authorize the persons listed below to do so as my proxy. I
        attest that all information provided on this agreement is true and
        correct.
      </div>
      <p style={styles.topMargin}>
        <strong>SIGNED BY MANAGER:&nbsp;</strong>
        {managerSignature}
      </p>
      <p>
        <strong>DATE SIGNED:&nbsp;</strong>
        {signDate}
      </p>
    </div>
  );
};

interface StudentStepProps extends ExternshipAgreementData {
  isRevising?: boolean;
}

export const StudentStep = ({
  studentName,
  studentPhone,
  studentAddress,
  studentEmail,
  candidateSignDate,
  studentProgram,
  isRevising = false
}: StudentStepProps): React.ReactElement => {
  const candidateSignature =
    isRevising || !candidateSignDate ? '' : studentName;

  return (
    <div>
      <h3 style={{ borderBottom: '1px solid black' }}>
        SECTION II - STUDENT INFORMATION
      </h3>
      <p style={styles.noMargin}>
        <strong>STUDENT NAME:&nbsp;</strong>
        {studentName}
      </p>
      <p style={styles.noMargin}>
        <strong>PHONE:&nbsp;</strong>
        {studentPhone}
      </p>
      <p style={styles.noMargin}>
        <strong>ADDRESS:&nbsp;</strong>
        {studentAddress}
      </p>
      <p style={styles.noMargin}>
        <strong>EMAIL:&nbsp;</strong>
        {studentEmail}
      </p>
      <p style={styles.noMargin}>
        <strong>PROGRAM:&nbsp;</strong>
        {studentProgram}
      </p>
      <div style={styles.marginDiv}>
        <strong>THE STUDENT AGREES TO:</strong>
        <ol type="a">
          <li>
            Complete the required minimum externship hours for the appropriate
            program Industry Externship course listed above,
          </li>
          <li>
            Follow all of the policies, rules, and regulations established by
            the externship site,
          </li>
          <li>
            Recognize that the externship does not constitute an employment
            relationship, and that the externship site is not obligated to hire
            the student upon completion of the externship,
          </li>
          <li>
            Submit this Externship Agreement{' '}
            <div
              style={{
                display: 'inline-block',
                borderBottom: '1px solid black'
              }}
            >
              prior
            </div>{' '}
            to the start of the externship,
          </li>
          <li>
            Submit weekly timesheets via electronic timesheet, in the Externship
            Management System, by Tuesday &#40;11:59pm&#41;,
          </li>
          <li>Submit any additional required documentation,</li>
          <li>
            Contact the Career Services Office to ensure all documentation has
            been received during and upon completion of the externship,
          </li>
          <li>
            Conduct oneself in a professional manner in appearance and
            performance at all times, remembering that they are representatives
            of Auguste Escoffier School of Culinary Arts and are subject to the
            guidelines of student conduct outlined in the school catalog.
          </li>
        </ol>
      </div>
      <strong>STUDENT EXTERN:</strong>
      <br />I have been provided guidance outlining the requirements for the
      externship course and I am aware of the weekly attendance reporting
      requirements. I understand this agreement confirms my externship course
      location. Should I, for any reason be terminated from or leave this site,
      it is my responsibility to notify my Externship Site Supervisor and Career
      Services Office immediately. Failure to provide timely notification may
      prohibit my ability to successfully complete the course. All changes must
      be submitted in writing to the Externship office at the school.
      <br />
      <p style={styles.topMargin}>
        <strong>SIGNED BY STUDENT:&nbsp;</strong>
        {candidateSignature}
      </p>
      <p>
        <strong>DATE SIGNED:&nbsp;</strong>
        {candidateSignDate}
      </p>
    </div>
  );
};

export const CareerServicesStep = ({
  signDate,
  name,
  isRevising = false
}: {
  signDate: string | null;
  name: string;
  isRevising?: boolean;
}): React.ReactElement => {
  const csSignature = isRevising || !signDate ? '' : name;

  return (
    <div>
      <h3 style={{ borderBottom: '1px solid black' }}>
        SECTION III - CAREER SERVICES OFFICE INFORMATION
      </h3>
      <p>
        The School agrees to post attendance to the student record for time
        verified by the externship supervisor or approved proxy, post final
        grades based on the feedback and evaluations provided by the externship
        supervisor, facilitate discussion sessions using the Online Campus, and
        participate in active communication with the externship supervisor and
        the student.
      </p>
      <p>
        Upon review of this agreement and evaluation and approval of the site, a
        representative of the Career Services Department will add a copy of this
        completed form to the student file therefore acknowledging the school
        acceptance of the agreement.
      </p>
      <p>
        <strong>SIGNED BY CAREER SERVICES STAFF:&nbsp;</strong>
        {csSignature}
      </p>
      <p>
        <strong>DATE SIGNED:&nbsp;</strong>
        {signDate}
      </p>
    </div>
  );
};
