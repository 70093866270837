import * as React from 'react';
import {
  Button,
  ButtonProps,
  Link,
  SxProps,
  TypographyProps,
  useTheme
} from '@mui/material';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface Props {
  pdfPath: string;
  buttonText: string;
  icon?: string;
  buttonStyle?: SxProps;
  btnSize?: ButtonProps['size'];
  textVariant?: TypographyProps['variant'];
  dataTestId?: string;
}

const PdfLink: React.FC<Props> = ({
  pdfPath,
  buttonText,
  icon = 'ri-file-text-fill',
  buttonStyle,
  btnSize = 'medium',
  textVariant = 'EC_TYPE_BASE',
  dataTestId = 'pdf-link'
}) => {
  const theme = useTheme();

  return (
    <Link
      href={`${BASE_URL}/${pdfPath}`}
      target="_blank"
      rel="noopener noreferrer"
      underline="none"
      data-testid={dataTestId}
    >
      <Button
        variant="text"
        startIcon={<i className={icon} />}
        size={btnSize}
        sx={{
          ...buttonStyle,
          padding: 0,
          typography: textVariant,
          color: theme.palette.primary.main
        }}
      >
        {buttonText}
      </Button>
    </Link>
  );
};

export default PdfLink;
