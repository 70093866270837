import * as React from 'react';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  TypographyProps
} from '@mui/material';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const Styled = {
  FilterWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'displayFlex'
  })<{ displayFlex: boolean }>(({ displayFlex }) => ({
    ...(displayFlex && {
      display: 'flex',
      justifyContent: 'right'
    }),
    columnGap: '8px',
    alignItems: 'center'
  }))
};

interface Props {
  label?: string;
  labelTypography?: TypographyProps['variant'];
  value: any;
  handleChange: (e) => void;
  options: { label: string; value: any }[];
  fullWidth?: boolean;
  placeholder?: string;
  padding?: string;
  disabled?: boolean;
  useOptionValueOnly?: boolean;
  multiple?: boolean;
}

const SimpleSelect: React.FC<Props> = ({
  value,
  options,
  handleChange,
  label,
  fullWidth = false,
  placeholder,
  padding,
  disabled = false,
  useOptionValueOnly = false,
  multiple = false,
  labelTypography = 'EC_TYPE_2XS'
}) => {
  const getLabel = (value: any): string => {
    const option = options.find((opt) => opt.value === value);

    if (option) {
      return option.label;
    }

    return '';
  };

  return (
    <Styled.FilterWrapper displayFlex={!!label}>
      {label && <Typography variant={labelTypography}>{label}</Typography>}
      <FormControl fullWidth={fullWidth} size="small" sx={{ minWidth: 100 }}>
        <Select
          sx={{
            '.MuiSelect-select': {
              padding,
              typography: 'EC_TYPE_2XS',
              color: theme.palette.GRAY_3.main
            }
          }}
          multiple={multiple}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          displayEmpty
          fullWidth
          renderValue={(selected): React.ReactNode => {
            if (placeholder && selected === '') {
              return <>{placeholder}</>;
            }

            if (Array.isArray(selected)) {
              return <>{selected.join(', ')}</>;
            }

            return <>{getLabel(selected)}</>;
          }}
        >
          {options.map((opt) => (
            <MenuItem key={opt.value} value={opt.value}>
              {useOptionValueOnly ? opt.value : opt.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Styled.FilterWrapper>
  );
};

export default SimpleSelect;
