import { Box, Icon, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';

const Styled = {
  Wrapper: styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    margin: '20px 0',
    border: `1px solid ${theme.palette.GRAY_1.main}`,
    borderRadius: '0 0 4px 4px',
    padding: '10px'
  })),
  TextItem: styled(Typography)(({ theme }) => ({
    color: theme.palette.BLACK.main,
    marginLeft: '8px',
    lineHeight: '25px',
    fontWeight: 400
  })),
  Icon: styled(Icon)({
    fontSize: '75px',
    paddingTop: '51px',
    overflow: 'visible',
    marginLeft: '20px'
  })
};

const ActionItemsSection: React.FC = () => {
  return (
    <Styled.Wrapper>
      <Styled.Icon
        className="ri-checkbox-circle-fill"
        sx={{
          color: 'primary.main'
        }}
      />

      <Box paddingLeft="15px">
        <Typography
          variant="AEGS_TYPE_PARAGRAPH"
          sx={{
            fontSize: '20px',
            fontWeight: 700
          }}
        >
          Action Items:
        </Typography>

        <Styled.TextItem variant="AEGS_TYPE_PARAGRAPH">
          &bull; &nbsp; Open and sign the <strong>Site Agreement</strong> and
          confirm the business address, externship supervisor, and contact
          information.
        </Styled.TextItem>

        <Styled.TextItem variant="AEGS_TYPE_PARAGRAPH">
          &bull; &nbsp; Open and sign the <strong>Externship Agreement</strong>{' '}
          and confirm student details.
        </Styled.TextItem>

        <Styled.TextItem variant="AEGS_TYPE_PARAGRAPH">
          &bull; &nbsp; Sign timesheets and answer a few questions about
          performance.
        </Styled.TextItem>
        <Styled.TextItem variant="AEGS_TYPE_PARAGRAPH">
          &bull; &nbsp; Green agreement buttons indicate that the documents have
          been signed, while blue indicates that a signature is still required.
        </Styled.TextItem>
      </Box>
    </Styled.Wrapper>
  );
};

export default ActionItemsSection;
