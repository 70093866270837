import * as React from 'react';
import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import CheckboxSingle from '@components/CheckboxSingle';
import { DateRange } from '@common/models/common.models';
import { DateTime } from 'luxon';
import ReactHookFormsDatePicker from '@components/Forms/ReactHookFormsDatePicker';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    padding: '16px',
    backgroundColor: theme.palette.GRAY_1.main,
    border: `1px solid ${theme.palette.GOLD_1.main}`,
    borderRadius: '4px'
  })),
  ButtonWrapper: styled(Grid)({
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'end'
  })
};

interface FormValues {
  startDate?: string | null;
  endDate?: string | null;
  filterStaffEmails?: boolean | null;
}

interface Props {
  onSubmit: (dateRange: DateRange) => void;
  title?: string;
  sessionKey: string;
  showEcStaffFilter?: boolean;
}

const DateRangeForm: React.FC<Props> = ({
  onSubmit,
  title,
  sessionKey,
  showEcStaffFilter
}) => {
  const MIN_DATE = DateTime.local(2022, 3, 30);

  const [allTime, setAllTime] = React.useState(true);
  const [filterStaff, setFilterStaff] = React.useState<boolean>(false);

  const methods = useForm<FormValues>({
    defaultValues: {
      startDate: null,
      endDate: null,
      filterStaffEmails: false
    }
  });

  const { handleSubmit, reset, watch, getValues } = methods;

  const startDate = watch('startDate');
  const endDate = watch('endDate');

  React.useEffect(() => {
    const storedForm = sessionStorage.getItem(sessionKey);

    if (storedForm) {
      const parsedForm: FormValues = JSON.parse(storedForm);

      reset(parsedForm);
      setFilterStaff(parsedForm.filterStaffEmails as boolean);
      onSubmit(parsedForm);
    }
  }, []);

  React.useEffect(() => {
    if (startDate || endDate) {
      setAllTime(false);
    }

    const value = JSON.stringify({
      ...getValues(),
      filterStaffEmails: filterStaff
    });
    sessionStorage.setItem(sessionKey, value);
  }, [startDate, endDate, filterStaff]);

  const handleValidForm = async (data: FormValues): Promise<void> => {
    data.filterStaffEmails = filterStaff;
    onSubmit(data);
  };

  const handleClick = (): void => {
    handleSubmit(handleValidForm)();
  };

  const handleCheckAllTime = (): void => {
    if (!allTime) {
      reset({ startDate: null, endDate: null });
    }
    setAllTime(!allTime);
  };

  const handleCheckFilterOut = (): void => {
    setFilterStaff(!filterStaff);
  };

  return (
    <>
      {title && <Typography variant="EC_TYPE_3XL">{title}</Typography>}
      <Styled.Root data-testid="date-range-form">
        <FormProvider {...methods}>
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item>
              <Grid container spacing={3}>
                <Grid item>
                  <ReactHookFormsDatePicker
                    name="startDate"
                    label="Start Date"
                    dataTestId="admin-dash-start-date"
                    minDate={MIN_DATE}
                    disableFuture
                  />
                </Grid>
                <Grid item>
                  <ReactHookFormsDatePicker
                    name="endDate"
                    label="End Date"
                    dataTestId="admin-dash-end-date"
                    minDate={MIN_DATE}
                    disableFuture
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'end'
                  }}
                >
                  <CheckboxSingle
                    control={
                      <Checkbox
                        checked={allTime}
                        data-testid="retrieve-stats-checkbox"
                      />
                    }
                    label="All time"
                    onChange={handleCheckAllTime}
                    name={'allTime'}
                  />

                  {showEcStaffFilter && (
                    <CheckboxSingle
                      control={
                        <Checkbox checked={filterStaff} value={filterStaff} />
                      }
                      label="Filter Out EC Staff"
                      onChange={handleCheckFilterOut}
                      name="filterStaffEmails"
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Styled.ButtonWrapper item>
              <Button variant="contained" onClick={handleClick}>
                Retrieve Stats
              </Button>
            </Styled.ButtonWrapper>
          </Grid>
        </FormProvider>
      </Styled.Root>
    </>
  );
};

export default DateRangeForm;
