import * as React from 'react';
import { Checkbox, FormGroup } from '@mui/material';
import { ExternalJobsSearchReq, IFilterState } from './FiltersWrapper';
import {
  FilterType,
  IFilterData,
  IFilterInput
} from '@api/models/searchApi.models';
import CheckboxSingle from '@components/CheckboxSingle';
import DistanceFilter from './DistanceFilter';
import { IExpandedFilters } from '@common/fetches/useFetchMarketplaceSearchResults';

interface FiltersProps {
  group: string;
  filters: IFilterInput[];
  state: IFilterState;
  setState: React.Dispatch<React.SetStateAction<IFilterState>>;
  handleChange: (event) => void;
  filterResults: (
    filterData: IFilterData[],
    resetExpandedFilters?: boolean
  ) => void;
  expandedFilters: IExpandedFilters;
  setExpandedFilters: React.Dispatch<React.SetStateAction<IExpandedFilters>>;
  filterExternalJobs?: (filterData?: ExternalJobsSearchReq) => void;
}

const Filters: React.FC<FiltersProps> = ({
  group,
  filters,
  state,
  setState,
  handleChange,
  filterResults,
  expandedFilters,
  setExpandedFilters,
  filterExternalJobs
}) => {
  const getFilterComponent = (filter: IFilterInput): React.FC => {
    let filterComponent;

    switch (filter.type) {
      case FilterType.Distance:
        filterComponent = (
          <DistanceFilter
            group={group}
            filter={filter}
            state={state}
            filterResults={filterResults}
            filterExternalJobs={filterExternalJobs}
            setState={setState}
            expandedFilters={expandedFilters}
            setExpandedFilters={setExpandedFilters}
          />
        );
        break;
      case FilterType.SearchBar:
        // Handled separately in PageLayoutLeftSidebar.tsx
        filterComponent = null;
        break;
      default:
        filterComponent = (
          <React.Fragment>
            {filter.value !== undefined && filter.value !== null && (
              <CheckboxSingle
                control={
                  <Checkbox
                    checked={
                      state[`${filter.fieldName}___${filter.value}`]
                        ? true
                        : false
                    }
                  />
                }
                label={filter.displayValue}
                onChange={handleChange}
                name={`${filter.fieldName}___${filter.value}`}
              />
            )}
          </React.Fragment>
        );
        break;
    }

    return filterComponent;
  };

  return (
    <FormGroup>
      {filters.map((filter, index) => (
        <React.Fragment key={index}>
          {getFilterComponent(filter)}
        </React.Fragment>
      ))}
    </FormGroup>
  );
};

export default Filters;
