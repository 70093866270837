import {
  CandidateCareerInfo,
  CareerPathRole,
  CareerTrack,
  PartnerEmployeeCareerInfo
} from '@interfaces/careerJourney.interfaces';
import { useEffect, useState } from 'react';
import { CareerApi } from '@api/Career.api';

type FetchCareerJourney = {
  careerTrack: CareerTrack | undefined;
  careerPathRole: CareerPathRole | undefined;
  loading: boolean;
};

export const useFetchCareerJourney = (
  careerInfo?: CandidateCareerInfo | PartnerEmployeeCareerInfo
): FetchCareerJourney => {
  const [savedGoal, setSavedGoal] = useState<CareerPathRole | undefined | null>(
    undefined
  );
  const [careerTrack, setCareerTrack] = useState<CareerTrack>();
  const [careerPathRole, setCareerPathRole] = useState<CareerPathRole>();
  const [loading, setLoading] = useState(false);

  const fetchCareerTrack = async (trackId: number): Promise<void> => {
    try {
      setLoading(true);
      const res = await CareerApi.getCareerTrackById(trackId);
      setCareerTrack(res.data);
    } catch (error: any) {
      console.error(
        'Error for useFetchCareerTrack -> CareerApi.getCareerTrackById()',
        error
      );
    }
  };

  const fetchCareerPathRole = async (goalId: number): Promise<void> => {
    try {
      setLoading(true);
      const res = await CareerApi.getCareerRoleById(goalId);
      setCareerPathRole(res.data);
    } catch (error: any) {
      console.error(
        'Error for useFetchCareerPathRole -> CareerApi.getCareerRoleById',
        error
      );
    }
  };

  useEffect(() => {
    if (careerInfo) {
      setSavedGoal(careerInfo?.savedGoal ?? null);
    }
  }, [careerInfo]);

  useEffect(() => {
    if (savedGoal) {
      Promise.all([
        fetchCareerTrack(savedGoal.trackId),
        fetchCareerPathRole(savedGoal.id)
      ]).then(() => {
        setLoading(false);
      });
    }
  }, [savedGoal]);

  return { careerTrack, careerPathRole, loading };
};
