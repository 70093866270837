import * as React from 'react';
import {
  SortButton,
  SortOrderType,
  TableSortBreadcrumbProps
} from '@interfaces/search.interfaces';
import TableSortHeader, {
  TableSortHeaderCta
} from '@components/TableComponents/TableSortHeader';
import { ResponsivePagination } from '@components/TableComponents/CustomTableComponents';
import { SearchBarProps } from '@components/SearchBar';
import { TableSortProvider } from '@common/context/tableSortContext';

export interface TableSortWrapperProps {
  sortButtons?: SortButton[];
  initialRowsPerPage?: number;
  initialSortCategory: string;
  initialSortOrder?: SortOrderType;
  rowsPerPageOptions?: number[];
  totalCount?: number;
  displayMobile: boolean;
  disabled?: boolean;
  // Key for session storage where table selections are saved
  sessionKey?: string;
  searchBarProps?: SearchBarProps;
  centerPagination?: boolean;
  headerTitle?: string;
  errorText?: string;
  breadcrumb?: TableSortBreadcrumbProps;
  styledHeader?: boolean;
  cta?: TableSortHeaderCta;
}

const TableWrapperWithSort: React.FC<TableSortWrapperProps> = ({
  children,
  sortButtons,
  initialRowsPerPage = 10,
  initialSortCategory,
  initialSortOrder = 'DESC',
  rowsPerPageOptions,
  totalCount,
  displayMobile,
  disabled,
  sessionKey,
  searchBarProps,
  centerPagination = false,
  headerTitle,
  errorText,
  breadcrumb,
  styledHeader = false,
  cta
}) => {
  const [tableSelections, setTableSelections] = React.useState({
    sortBy: initialSortCategory,
    sortOrder: initialSortOrder,
    page: 0,
    rowsPerPage: initialRowsPerPage
  });

  const [sortMenuAnchor, setSortMenuAnchor] =
    React.useState<HTMLElement | null>(null);

  const sortMenuOpen = Boolean(sortMenuAnchor);

  const _breadcrumb = breadcrumb
    ? { ...breadcrumb, prevLocation: headerTitle }
    : undefined;

  React.useEffect(() => {
    if (sessionKey) {
      const tableSelections = sessionStorage.getItem(sessionKey);

      if (tableSelections) {
        const parsedSelections = JSON.parse(tableSelections);
        setTableSelections(parsedSelections);
      }
    }
  }, []);

  React.useEffect(() => {
    if (sessionKey) {
      sessionStorage.setItem(sessionKey, JSON.stringify(tableSelections));
    }
  }, [tableSelections]);

  const handleSortMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setSortMenuAnchor(sortMenuAnchor ? null : event.currentTarget);
  };

  const handleSortClick = <T,>(
    event: React.MouseEvent<unknown>,
    category: keyof T
  ): void => {
    const isAsc = sortBy === category && sortOrder === 'ASC';
    setTableSelections({
      ...tableSelections,
      sortOrder: isAsc ? 'DESC' : 'ASC',
      sortBy: category as string,
      page: 0
    });

    setTimeout(() => {
      setSortMenuAnchor(null);
    }, 100);
  };

  const handleClickAway = (): void => {
    sortMenuAnchor && setSortMenuAnchor(null);
  };

  const handlePageChange = (event, newPage: number): void => {
    setTableSelections({
      ...tableSelections,
      page: newPage
    });
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setTableSelections({
      ...tableSelections,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  const { sortBy, sortOrder, page, rowsPerPage } = tableSelections;

  return (
    <TableSortProvider
      value={{
        sortBy,
        sortOrder,
        sortMenuOpen,
        sortMenuAnchor,
        handleSortMenuOpen,
        handleClickAway,
        handleSortClick,
        page,
        rowsPerPage
      }}
    >
      <TableSortHeader
        buttons={sortButtons}
        disabled={!!disabled}
        searchBarProps={searchBarProps}
        title={headerTitle}
        errorText={errorText}
        breadcrumb={_breadcrumb}
        styledHeader={styledHeader}
        cta={cta}
      />
      {children}
      {!!totalCount && (
        <ResponsivePagination
          count={totalCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          rowsPerPageOptions={rowsPerPageOptions}
          handleRowsPerPageChange={handleRowsPerPageChange}
          displayMobile={displayMobile}
          centerPagination={centerPagination}
        />
      )}
    </TableSortProvider>
  );
};

export default TableWrapperWithSort;
