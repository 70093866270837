import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import ReactHookFormsAutoComplete from '@components/Forms/ReactHookFormsAutoComplete';
import ReactHookFormsSelect from '@components/Forms/ReactHookFormsSelect';
import StepSection from '@components/StepSection';
import { styled } from '@mui/system';
import { useFetchSkillsCodebooks } from '@common/fetches/useFetchSkillsCodebooks';

const Styled = {
  Section: styled(Box)({
    marginBottom: '80px'
  }),
  SubSection: styled(Box)({
    marginBottom: '60px'
  }),
  SectionTitle: styled(Typography)({
    marginBottom: '24px'
  }),
  TagSection: styled(Box)({
    '& > *': {
      marginTop: '49px'
    }
  })
};

const IdealCandidateStep: React.FC = () => {
  const { technicalSkills, softSkills, loaded } = useFetchSkillsCodebooks();
  const experienceOptions = [
    { label: 'None', value: 'NONE' },
    { label: '1-3 years', value: '1-3' },
    { label: '3-6 years', value: '3-6' },
    { label: '6-10 years', value: '6-10' },
    { label: '10+ years', value: '10+' }
  ];

  return (
    <>
      {loaded && (
        <Styled.Section data-testid="employer-ideal-candidate-section">
          <StepSection title="Ideal Candidate">
            <Styled.SectionTitle variant="EC_TYPE_LG">
              What attributes will make someone successful in this role?
            </Styled.SectionTitle>
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <ReactHookFormsSelect
                  name="experienceLevel"
                  label="Required Experience Level"
                  options={experienceOptions}
                  rules={{ required: 'Experience level required' }}
                />
              </Grid>
            </Grid>

            <Styled.TagSection>
              <ReactHookFormsAutoComplete
                name="technicalSkills"
                options={technicalSkills}
                required
                multipleSelect
                title="Important Candidate Technical Skills"
                subtitle="(Select one or more)"
                inputPlaceholder="Search for technical skills"
                rules={{ required: 'At least one technical skill is required' }}
              />
              <ReactHookFormsAutoComplete
                name="softSkills"
                options={softSkills}
                required
                multipleSelect
                title="Important Candidate Soft Skills"
                subtitle="(Select one or more)"
                inputPlaceholder="Search for soft skills"
                rules={{ required: 'At least one soft skill is required' }}
              />
            </Styled.TagSection>
          </StepSection>
        </Styled.Section>
      )}
    </>
  );
};

export default IdealCandidateStep;
