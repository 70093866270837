import * as React from 'react';
import { Box, Button, Stack, Theme, Typography } from '@mui/material';
import CareerGoalTooltipKey from '@pages/Candidate/CareerPathPage/MyCareerGoal/CareerGoalTooltipKey';
import EcToolTip from '@components/EcToolTip';
import { styled } from '@mui/system';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const BTN_BREAKPOINT = 486;
const BREAKPOINT = 801;
const MEDIA_QUERY = `@media (min-width:${BREAKPOINT}px)`;

const Styled = {
  Root: styled(Box)({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [MEDIA_QUERY]: {
      flexDirection: 'row'
    }
  }),
  HeaderBox: styled(Box)({
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  }),
  TitleBox: styled(Box)(({ theme }) => ({
    display: 'flex',
    '& .career-name': {
      fontSize: (theme as Theme).typography.EC_TYPE_4XL.fontSize,
      color: theme.palette.BLACK.main
    }
  })),
  TextBox: styled(Box)(({ theme }) => ({
    display: 'block',
    '& .career-name': {
      fontSize: (theme as Theme).typography.EC_TYPE_4XL.fontSize,
      color: theme.palette.BLACK.main
    }
  })),
  ChefImg: styled('img')({
    width: '50px',
    height: '50px',
    marginRight: '10px',
    [MEDIA_QUERY]: {
      width: '80px',
      height: '80px',
      marginRight: '16px'
    }
  }),
  RemoveBtn: styled(Button)({
    height: '40px',
    marginLeft: 'auto',
    maxWidth: '50px',
    [`@media (min-width:${BTN_BREAKPOINT}px)`]: {
      width: '115px',
      maxWidth: 'unset',
      padding: 0
    },
    '& .mobile-remove-btn': {
      fontSize: '18px'
    }
  })
};

interface CareerGoalHeaderProps {
  careerName: string;
  description: string;
  removeCareerGoal: () => Promise<void>;
  trackIcon: string;
  trackName: string;
}

interface RemoveBtnProps {
  hideLabel: boolean;
  removeCareerGoal: CareerGoalHeaderProps['removeCareerGoal'];
}

type SectionProps = CareerGoalHeaderProps & Pick<RemoveBtnProps, 'hideLabel'>;

type GoalImageProps = Pick<SectionProps, 'trackIcon' | 'trackName'>;

const GoalImage = ({ trackIcon, trackName }: GoalImageProps): JSX.Element => {
  const icon = require(`@assets/CareerTracks/${trackIcon}`).default;

  return (
    <Styled.ChefImg
      data-testid="career-goal-header-chef-hat-image"
      src={icon}
      alt={`${trackName} track icon`}
    />
  );
};

const RemoveBtn = ({
  hideLabel,
  removeCareerGoal
}: RemoveBtnProps): JSX.Element => (
  <Styled.RemoveBtn
    data-testid="career-goal-header-remove-btn"
    variant="outlined"
    color="GOLD_1"
    onClick={removeCareerGoal}
    startIcon={!hideLabel && <i className="ri-delete-bin-6-line" />}
  >
    {!hideLabel ? (
      'REMOVE'
    ) : (
      <i className="ri-delete-bin-6-line mobile-remove-btn" />
    )}
  </Styled.RemoveBtn>
);

const DesktopHeader = ({
  careerName,
  description,
  trackIcon,
  trackName,
  ...btnProps
}: SectionProps): JSX.Element => {
  return (
    <React.Fragment>
      <GoalImage trackIcon={trackIcon} trackName={trackName} />
      <Styled.HeaderBox data-testid="career-goal-headerbox">
        <Styled.TitleBox sx={{ alignItems: 'start' }}>
          <Stack direction="row" alignItems="center">
            <Typography variant="EC_TYPE_XL">MY CAREER GOAL</Typography>
            <EcToolTip
              message={<CareerGoalTooltipKey />}
              displayArrow={false}
              customTooltipStyles={{
                maxWidth: '343px',
                padding: '3px',
                boxShadow: '0px 4px 4px rgba(0,0,0,0.25)'
              }}
            />
          </Stack>
          <RemoveBtn {...btnProps} />
        </Styled.TitleBox>
        <Styled.TextBox>
          <Typography
            data-testid="career-goal-header-career"
            variant="EC_TYPE_3XL"
            className="career-name"
            sx={{ lineHeight: 'unset' }}
          >
            {careerName}
          </Typography>
          <Typography
            data-testid="career-goal-header-description"
            variant="EC_TYPE_BASE"
          >
            {description}
          </Typography>
        </Styled.TextBox>
      </Styled.HeaderBox>
    </React.Fragment>
  );
};

const MobileHeader = ({
  careerName,
  description,
  trackIcon,
  trackName,
  ...btnProps
}: SectionProps): JSX.Element => {
  return (
    <React.Fragment>
      <Styled.TitleBox>
        <Typography variant="EC_TYPE_XL">MY CAREER GOAL</Typography>
        <EcToolTip
          message={<CareerGoalTooltipKey />}
          displayArrow={false}
          placement="bottom-start"
          customTooltipStyles={{
            maxWidth: '100%',
            padding: '3px',
            boxShadow: '0px 4px 4px rgba(0,0,0,0.25)'
          }}
        />
        <RemoveBtn {...btnProps} />
      </Styled.TitleBox>
      <Styled.TitleBox>
        <GoalImage trackIcon={trackIcon} trackName={trackName} />
        <Typography
          data-testid="career-goal-header-career"
          variant="EC_TYPE_3XL"
          className="career-name"
        >
          {careerName}
        </Typography>
      </Styled.TitleBox>
      <Typography
        data-testid="career-goal-header-description"
        variant="EC_TYPE_BASE"
      >
        {description}
      </Typography>
    </React.Fragment>
  );
};

const CareerGoalHeader = (props: CareerGoalHeaderProps): React.ReactElement => {
  const { width } = useWindowDimensions();
  const hideBtnLabel = width < BTN_BREAKPOINT;
  const displayMobile = width < BREAKPOINT;

  return (
    <Styled.Root data-testid="career-goal-header-root">
      {displayMobile ? (
        <MobileHeader hideLabel={hideBtnLabel} {...props} />
      ) : (
        <DesktopHeader hideLabel={hideBtnLabel} {...props} />
      )}
    </Styled.Root>
  );
};

export default CareerGoalHeader;
