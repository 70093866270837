import * as React from 'react';
import { Box, Typography, TypographyProps } from '@mui/material';
import EWithCrownLogo from '@assets/png/ec-e_with_crown-logo.png';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)({
    display: 'flex',
    flex: 1
  }),
  Logo: styled('img')({
    marginRight: '6px',
    width: 'auto',
    height: '108px'
  }),
  Text: styled(Typography)({
    fontWeight: 400,
    fontStyle: 'normal'
  }),
  SubText: styled(Typography)({})
};

interface Props {
  text: string;
  textVariant?: TypographyProps['variant'];
  subText?: string;
}

const ECrownLogoWithTitle: React.FC<Props> = ({
  text,
  textVariant = 'EC_TYPE_6XL',
  subText
}) => {
  return (
    <Styled.Root>
      <Styled.Logo src={EWithCrownLogo} alt="Escoffier-E-Crown" />
      <Box margin="auto 0">
        <Styled.Text variant={textVariant} mb={0}>
          {text}
        </Styled.Text>
        {subText && (
          <Styled.SubText variant="EC_TYPE_BASE">{subText}</Styled.SubText>
        )}
      </Box>
    </Styled.Root>
  );
};

export default ECrownLogoWithTitle;
