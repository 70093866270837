import * as React from 'react';
import BatchActionModalLayout, {
  SelectedRowExternshipEntity
} from '@components/Layout/BatchActionModalLayout';
import { AttestationApi } from '@api/Attestations.api';
import { SendAttestationReq } from '@api/models/attestationApi.models';
import { useBatchActionsContext } from '@common/context/batchActionsContext';

interface Props {
  open: boolean;
}

const AttestationModal: React.FC<Props> = ({ open }) => {
  const {
    selectedAction: attestationType,
    handleSetSelectedAction,
    onSubmitCallback: clearSelectedRows,
    handleCloseModal,
    updateDataGridData
  } = useBatchActionsContext();

  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState<SelectedRowExternshipEntity[]>(
    []
  );

  const handleSendAttestation = async (): Promise<void> => {
    if (attestationType) {
      try {
        setLoading(true);
        const postReq: SendAttestationReq = {
          type: attestationType.value,
          externshipIds: selected.map((s) => s.externshipId)
        };

        const res = await AttestationApi.createAttestation(postReq);
        updateDataGridData(res.data);
      } catch (error: any) {
        console.error(
          'Error for AttestationModal -> AttestationApi.createAttestation()',
          error
        );
      } finally {
        setLoading(false);
        handleCloseModal();
        handleSetSelectedAction(undefined);
        clearSelectedRows!(); // This is defined since CareerServicesAttestationPage passes it
      }
    }
  };

  return (
    <BatchActionModalLayout
      open={open}
      modalTitle={`Sending ${attestationType?.label} Attestation`}
      modalSubtext={`Are you sure you want to send this ${attestationType?.label} to these students?`}
      primaryBtnText="Send"
      selectedRows={selected}
      setSelectedRows={setSelected}
      handleSubmitClick={handleSendAttestation}
      handleClose={handleCloseModal}
      loading={loading}
      toastMessage={`${attestationType?.label} attestation sent to student(s)`}
    />
  );
};

export default AttestationModal;
