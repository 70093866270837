import { DateTime } from 'luxon';

export const formatDate = (
  date: string,
  format: string = 'MM/dd/yyyy',
  removeTimezone = false,
  asUtc = false
): string => {
  if (!date) return ' '; // Date is not set in CVue

  const isoDate = new Date(date).toISOString();

  const _isoDate = removeTimezone ? isoDate.slice(0, -1) : isoDate;

  const dateToReturn = DateTime.fromISO(_isoDate, {
    zone: asUtc ? 'utc' : undefined
  }).toFormat(format);

  return dateToReturn;
};

export const newFormatDate = (
  date: string,
  format: string = 'MM/dd/yyyy'
): string => {
  if (!date) return ' '; // Date is not set in CVue

  const isoDate = new Date(date).toISOString();
  const dateToReturn = DateTime.fromISO(isoDate, {
    zone: 'utc'
  }).toFormat(format);

  return dateToReturn;
};
