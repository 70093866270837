import * as React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import {
  DatePickerProps,
  DesktopDatePicker,
  LocalizationProvider
} from '@mui/x-date-pickers';
import { OutlinedInput, Stack, TextField, Typography } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import FormFieldErrorText from '@components/Forms/FormFieldErrorText';
import { styled } from '@mui/system';

const Styled = {
  PickerFieldContainer: styled(Stack)({
    position: 'relative'
  }),
  Label: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'required'
  })<{ required?: boolean }>(({ required, theme }) => ({
    marginBottom: '11px',
    position: 'relative',

    '&:after': {
      content: required ? '"*"' : '""',
      color: theme.palette.error.main,
      marginLeft: '4px'
    }
  })),
  Sublabel: styled(Typography)(({ theme }) => ({
    marginBottom: '16px',
    color: theme.palette.GRAY_3.main,
    fontSize: '13px'
  }))
};

interface Props {
  name: string;
  label: string;
  required?: boolean;
  minDate?: DateTime;
  disabled?: boolean;
  allowPast?: boolean;
  disableFuture?: boolean;
  dataTestId?: string;
  rules?: RegisterOptions;
  notRequiredText?: string;
  views?: DatePickerProps['views'];
  sublabel?: string;
}

const ReactHookFormsDatePicker: React.FC<Props> = ({
  name,
  label,
  disabled,
  allowPast = false,
  disableFuture = false,
  dataTestId,
  minDate,
  required = false,
  rules,
  sublabel,
  notRequiredText,
  views = ['year', 'month', 'day']
}) => {
  const now = DateTime.local();

  const {
    control,
    getValues,
    setValue,
    trigger,
    formState: { errors, isSubmitted }
  } = useFormContext();

  const watchDate = getValues(name);

  React.useEffect(() => {
    if (!required) {
      setValue(name, '');
    } else {
      setValue(name, now);
    }
  }, [required]);

  const validateDate = React.useCallback((): boolean | string => {
    if (required && !allowPast && getValues(name) <= now) {
      return 'Must be a future date';
    } else {
      return true;
    }
  }, [required, watchDate]);

  React.useEffect(() => {
    if (isSubmitted) {
      trigger(name);
    }
  }, [getValues()[name]]);

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Styled.PickerFieldContainer id={name}>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Styled.Label
            data-testid={`${name}-input-label`}
            variant="EC_TYPE_BASE"
            required={required}
          >
            {label}
          </Styled.Label>
        </Stack>
        {sublabel && (
          <Styled.Sublabel variant="EC_TYPE_SM">{sublabel}</Styled.Sublabel>
        )}
        <Controller
          name={name}
          control={control}
          rules={{
            ...rules,
            validate: {
              validateDate
            }
          }}
          render={({ field }): JSX.Element => {
            return (
              <>
                {!required && notRequiredText ? (
                  <OutlinedInput
                    disabled={true}
                    placeholder={notRequiredText}
                  />
                ) : (
                  <DesktopDatePicker
                    {...field}
                    views={views}
                    disableFuture={disableFuture}
                    disabled={disabled}
                    minDate={minDate}
                    renderInput={(params): JSX.Element => (
                      <TextField
                        error={!!errors[name]}
                        inputProps={{ 'data-testid': dataTestId }}
                        {...params}
                        autoComplete="off"
                      />
                    )}
                  />
                )}
              </>
            );
          }}
        />
        {errors[name] && (
          <FormFieldErrorText name={name} message={errors[name].message} />
        )}
      </Styled.PickerFieldContainer>
    </LocalizationProvider>
  );
};

export default ReactHookFormsDatePicker;
