import { addDaysToDate } from '@common/helpers/dateHelpers/addDaysToDate';
import { ChefInstructorTimesheetInterface } from '@api/models/careerServicesApi.models';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { getExternshipStatusLabel } from '@common/helpers/externshipHelpers/getExternshipStatusLabel';
import { getTimesheetStatusLabel } from '@common/helpers/externshipHelpers/getTimesheetStatusLabel';

export const getTimesheetGridRows = (
  chefInstructorTimesheets: ChefInstructorTimesheetInterface[]
): any[] => {
  return chefInstructorTimesheets?.map((t) => ({
    ...t,
    id: t.timesheetId,
    externshipStatus: getExternshipStatusLabel(t.externshipStatus),
    lda: formatDate(t.lda, 'MM/dd/yyyy', undefined, true),
    weekStartDate: formatDate(t.weekStartDate, 'MM/dd/yyyy', undefined, true),
    externshipBeginDate: formatDate(
      t.externshipBeginDate,
      'MM/dd/yyyy',
      undefined,
      true
    ),
    employmentStartDate: formatDate(
      t.employmentStartDate,
      'MM/dd/yyyy',
      undefined,
      true
    ),
    approveByDate: formatDate(
      addDaysToDate(new Date(t.weekStartDate), 21).toISOString(),
      'MM/dd/yyyy',
      undefined,
      true
    ),
    timesheetStatus: getTimesheetStatusLabel(t.timesheetStatus, false, true)
  }));
};
