import * as React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '@themes/ui/variables';
import { styled } from '@mui/system';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const Styled = {
  Root: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'minHeight'
  })<{ minHeight: string }>(({ minHeight, theme }) => ({
    backgroundColor: theme.palette.GRAY_F4.main,
    paddingBottom: '44px',
    minHeight,
    ['@media (max-width:480px)']: {
      paddingBottom: '20px'
    }
  })),
  Container: styled(Container)({
    ['@media (max-width:480px)']: {
      padding: '0 16px 30px'
    }
  }),
  Section: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'removeBottomMargin'
  })<{ removeBottomMargin?: boolean }>(({ removeBottomMargin }) => ({
    marginBottom: removeBottomMargin ? 0 : '60px'
  }))
};

type PageProps = {
  sideContent: React.ReactNode;
  pageTitle?: string;
  headerContent?: React.ReactNode;
  content: React.ReactNode;
  actions?: React.ReactNode;
  removeBottomMargin?: boolean;
  hideSideContent?: boolean;
  minHeight?: string;
  keepGutter?: boolean;
};

const PageFormLayoutComponent: React.FC<PageProps> = ({
  sideContent,
  pageTitle,
  content,
  actions,
  removeBottomMargin,
  hideSideContent,
  headerContent,
  keepGutter = false,
  minHeight = `calc(100vh - (${FOOTER_HEIGHT}px + ${HEADER_HEIGHT}px))`
}) => {
  const { width } = useWindowDimensions();
  const paddingTop = width < 600 && !hideSideContent ? '20px' : '56px';
  const spacing = width < 900 ? 6 : 8;
  const margin = width < 600 ? '24px' : '0px 0px 24px 0px';

  return (
    <Styled.Root minHeight={minHeight}>
      <Container
        disableGutters={keepGutter ? false : width < 600}
        sx={{
          paddingTop: paddingTop,
          backgroundColor: '#F4F4F4'
        }}
        maxWidth="lg"
      >
        {headerContent && (
          <Box sx={{ marginBottom: '48px' }}>{headerContent}</Box>
        )}
        <Grid container spacing={spacing}>
          {!hideSideContent && (
            <Grid item xs>
              {sideContent}
            </Grid>
          )}
          <Grid item xs={12} headerMobile={9.5} lg={9.3}>
            <Styled.Section removeBottomMargin={removeBottomMargin}>
              <>
                {pageTitle && (
                  <Typography variant="EC_TYPE_3XL" sx={{ margin: margin }}>
                    {pageTitle}
                  </Typography>
                )}
              </>
              {content}
            </Styled.Section>
          </Grid>
        </Grid>
        {actions && (
          <Grid item xs={12}>
            {actions}
          </Grid>
        )}
      </Container>
    </Styled.Root>
  );
};

export default PageFormLayoutComponent;
