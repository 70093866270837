import { Location } from '@interfaces/location.interfaces';

export interface CandidateSignupRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirm_password: string;
  interests: number[];
  graduationType: number;
  locations: Location[];
  phone?: string;
  campus?: number;
  program?: number;
}

export enum offering {
  HIRE_TALENT = 'Hire Talent',
  WORK_AND_LEARN = 'Create Work & Learn Program',
  CUSTOM_TRAINING = 'Request Custom Training'
}

export interface EmployerSignupRequest {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  jobTitle: string;
  password: string;
  confirm_password: string;
  offerings: offering[];
  phone: string;
  companyPhone: string;
  // Optional for now while we have the feature flag for PARTNER_EXPERIENCE TURNED OFF
  locations?: Location[];
}

export interface ExternshipManagerSignupRequest extends EmployerSignupRequest {
  inviteCode: string;
  externshipId: number;
}

export interface RecruiterSignupRequest {
  firstName: string;
  lastName: string;
  email: string;
  companyId: number;
  jobTitle: string;
  password: string;
  confirm_password: string;
  inviteCode?: string;
  roles?: string;
  externshipId: number | null;
  phone: string;
}

export interface CandidateSSOSignupRequest {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  graduationType: number;
  locations: Location[];
  phone?: string;
}

export interface EmployerSSOSignupRequest {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  jobTitle: string;
  offerings?: offering[];
  phone?: string;
  companyPhone: string;
  // Optional for now while we have the feature flag for PARTNER_EXPERIENCE TURNED OFF
  locations?: Location[];
}

export interface JoinCompanyRequestBase {
  userId: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  companyId: number;
}

export interface JoinCompanyRequest
  extends Omit<JoinCompanyRequestBase, 'userId'> {
  password: string;
  confirm_password: string;
}

export interface ExternshipManagerJoinRequestAccount
  extends JoinCompanyRequest {
  inviteCode: string;
  externshipId: number;
}
