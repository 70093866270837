import * as React from 'react';
import AboutStep from '@pages/Employer/ProfileSetupPage/AboutStep/AboutStep';
import BenefitsStep from './BenefitsStep';
import CultureStep from './CultureStep';
import EthosStep from './EthosStep';
import PhotosStep from './PhotosStep';
import ProfileSetupStepper from '@components/ProfileSetupStepper';

const steps = ['ABOUT', 'ETHOS', 'PHOTOS', 'CULTURE', 'BENEFITS'];

const ProfileSetupPage: React.FC = () => {
  const [loadingStep, setLoadingStep] = React.useState(false);

  const stepContent = (handleStepper: (next: boolean) => void) => {
    return (step): React.ReactElement => {
      let content: React.ReactElement;

      switch (step) {
        case 0:
          content = <AboutStep handleStepper={handleStepper} />;
          break;
        case 1:
          content = <EthosStep handleStepper={handleStepper} />;
          break;
        case 2:
          content = <PhotosStep handleStepper={handleStepper} />;
          break;
        case 3:
          content = <CultureStep handleStepper={handleStepper} />;
          break;
        case 4:
          content = <BenefitsStep handleStepper={handleStepper} />;
          break;
        default:
          content = <>Oops!</>;
          break;
      }
      return content;
    };
  };

  return (
    <ProfileSetupStepper
      steps={steps}
      stepContent={stepContent}
      loading={loadingStep}
      setLoading={setLoadingStep}
    />
  );
};

export default ProfileSetupPage;
