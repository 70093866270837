import * as React from 'react';
import { Box, Button, Grid } from '@mui/material';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import {
  ICompanySettings,
  Seat,
  SentInvite,
  SetCompanySettings
} from '@api/models/settingsApi.models';
import { AppRoles } from '@api/models/userApi.models';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { CompanyApi } from '@api/Company.api';
import InviteUserBox from '@pages/Shared/SettingsPage/CompanySettings/InviteUserBox';
import { JoinRequest } from '@api/models/companyApi.models';
import ManageSeatsTable from '@pages/Shared/SettingsPage/CompanySettings/ManageSeatsTable';
import PendingApprovalsTable from '@pages/Shared/SettingsPage/CompanySettings/PendingApprovalsTable';
import PendingInvitesTable from '@pages/Shared/SettingsPage/CompanySettings/PendingInvitesTable';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import { scrollToError } from '@common/helpers/scrollToError';
import StepSection from '@components/StepSection';
import StepSubSection from '@components/StepSubSection';
import { styled } from '@mui/system';
import { useToast } from '@components/Toast';

const Styled = {
  SaveBtn: styled(Button)({
    marginTop: '30px',
    marginBottom: '13px',
    color: 'white'
  })
};

interface CompanyFormValues extends Omit<ICompanySettings, 'seats'> {
  seats: Seat[];
}

interface Props {
  companyId: number;
  companySettings: ICompanySettings;
  sentInvites?: SentInvite[];
  joinRequests?: JoinRequest[];
  setCompanySettings: SetCompanySettings;
  fetchSentInvites: () => void;
  glrcSeats?: number;
  handleSeatRequestUpdate: () => void;
}

const CompanySettings: React.FC<Props> = ({
  companyId,
  companySettings,
  sentInvites = [],
  joinRequests = [],
  setCompanySettings,
  fetchSentInvites,
  glrcSeats = 0,
  handleSeatRequestUpdate
}) => {
  const settingsSaved = React.useRef<boolean>(false);
  const setSettingsSaved = (val: boolean): void => {
    settingsSaved.current = val;
  };
  const { openToast } = useToast();

  const defaultValues = { companyName: '', seats: [] };

  const methods = useForm<CompanyFormValues>({
    defaultValues
  });

  const formOrder = {
    companyName: 1
  };

  const {
    handleSubmit,
    reset,
    getValues,
    setError,
    formState: { isDirty }
  } = methods;

  const setSettings = React.useCallback(
    (newSettings: ICompanySettings) => {
      setCompanySettings(newSettings);
    },
    [setCompanySettings]
  );

  const onInvalid = (errors: FieldErrors): void => {
    scrollToError(errors, formOrder);
  };

  const onValid = async (data: CompanyFormValues): Promise<void> => {
    try {
      await CompanyApi.updateCompanySettings(companyId, {
        name: data.companyName
      });

      openToast('Account Settings Updated Successfully');
    } catch (error: any) {
      console.error(
        'Error for CompanySettings -> SettingsApi.updateUser()',
        error
      );

      const errData = error.response.data;
      const message = errData.message;

      if (errData.data) {
        setError(errData.data.field, { type: 'manual', message });
        scrollToError(methods.formState.errors, formOrder);
      }
    } finally {
      reset(data);
      setSettingsSaved(true);
    }
  };

  const glrcSeatsRemaining = (): number => {
    const { seats } = companySettings;

    const activeGlrcUsers = seats.filter(
      (s) => s.role.filter((r) => r.name === AppRoles.ESOURCE_USER).length
    );

    const invitedGlrcUsers = sentInvites.filter(
      (i) => i.roles === AppRoles.ESOURCE_USER && !i.expired
    );

    const totalGlrcUsers = invitedGlrcUsers.length + activeGlrcUsers.length;

    return glrcSeats - totalGlrcUsers;
  };

  /* 
    Resets the form with defaultValues from database
    from initial render
  */
  React.useEffect(() => {
    if (companySettings) {
      reset(companySettings);
    }
  }, [companySettings]);

  /* 
    This will reset the state of the form once it is successfully submitted
    Save Changes button will return to disabled state until form isDirty again
  */
  React.useEffect(() => {
    if (settingsSaved.current && !open) {
      const submittedForm = {
        ...getValues()
      };

      setSettings(submittedForm);
      setSettingsSaved(false);
    }
  }, [settingsSaved.current, open]);

  return (
    <StepSection title="Manage Company Settings">
      <BottomMarginContentWrapper bottomMargin="64px">
        <FormProvider {...methods}>
          <StepSubSection title="Company Registration">
            <Grid container>
              <Grid item xs={12} md={6}>
                <ReactHookFormsInput
                  dataTestId="company-name-input"
                  label="Name"
                  name="companyName"
                  rules={{
                    required: 'Company name is required'
                  }}
                />
              </Grid>
            </Grid>
            <Styled.SaveBtn
              data-testid="save-company-settings-btn"
              variant="contained"
              onClick={handleSubmit(onValid, onInvalid)}
              disabled={!isDirty}
            >
              Save Changes
            </Styled.SaveBtn>
          </StepSubSection>
        </FormProvider>
        <Box>
          <StepSubSection title="Manage Seats">
            {companySettings && companySettings.companyName !== '' && (
              <ManageSeatsTable
                openToast={openToast}
                companySettings={companySettings}
                setCompanySettings={setSettings}
                glrcSeats={glrcSeats}
                glrcSeatsRemaining={glrcSeatsRemaining}
              />
            )}
          </StepSubSection>
          <Box sx={{ marginTop: '32px' }}>
            <InviteUserBox
              openToast={openToast}
              companyId={companyId}
              fetchSentInvites={fetchSentInvites}
            />
          </Box>
        </Box>
        {!!joinRequests.length && (
          <StepSubSection title="Approvals">
            <PendingApprovalsTable
              joinRequests={joinRequests}
              companyId={companyId}
              handleSeatRequestUpdate={handleSeatRequestUpdate}
              glrcSeatsRemaining={glrcSeatsRemaining}
            />
          </StepSubSection>
        )}
        {!!sentInvites?.length && (
          <StepSubSection title="Pending Invites">
            <PendingInvitesTable sentInvites={sentInvites} />
          </StepSubSection>
        )}
      </BottomMarginContentWrapper>
    </StepSection>
  );
};

export default CompanySettings;
