import * as React from 'react';
import JobListingPage from '@pages/Employer/PreviewPage/JobListingPage';
import JobPostSkeleton from '@components/Skeletons/JobPostSkeleton';
import { useKeycloakContext } from '@common/context/keycloakContext';

const JobListingLandingPage: React.FC = () => {
  const { isAuthenticated } = useKeycloakContext();

  if (!isAuthenticated) {
    return <JobPostSkeleton modalOpen={true} />;
  }

  return <JobListingPage />;
};

export default JobListingLandingPage;
