import * as React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Theme,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from '@mui/material';
import FormFieldErrorText from './FormFieldErrorText';
import { styled } from '@mui/system';

const Styled = {
  TextFieldContainer: styled(Stack)({
    position: 'relative'
  }),
  Label: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'required'
  })<{ required?: boolean }>(({ required, theme }) => ({
    marginBottom: '11px',
    position: 'relative',
    ['@media (max-width:480px)']: {
      marginBottom: '5px',
      fontSize: (theme as Theme).typography.EC_TYPE_SM.fontSize,
      lineHeight: (theme as Theme).typography.EC_TYPE_SM.lineHeight
    },

    '&:after': {
      content: required ? '"*"' : '""',
      color: theme.palette.error.main,
      marginLeft: '4px'
    }
  })),
  Icon: styled('i')(({ theme }) => ({
    color: theme.palette.GOLD_1.main,
    position: 'absolute',
    right: '0px',
    bottom: '-2px',

    '&:hover': {
      cursor: 'pointer'
    }
  })),
  Tooltip: styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.GOLD_1.main,
      padding: '12px',
      boxSizing: 'border-box',

      span: {
        color: theme.palette.GOLD_1.main
      }
    }
  })),
  List: styled('ul')({
    paddingLeft: '24px'
  })
};

interface Props {
  name: string;
  label: string;
  autocomplete?: string;
  rules?: RegisterOptions;
  displayTooltip?: boolean;
  dataTestId?: string;
}

const ReactHookFormsPassword: React.FC<Props> = ({
  label,
  name,
  autocomplete,
  rules,
  displayTooltip,
  dataTestId
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const [showText, setShowText] = React.useState(false);

  return (
    <Styled.TextFieldContainer>
      <Styled.Label
        required={!!rules?.required}
        variant="EC_TYPE_BASE"
        data-testid={`${name}-input-label`}
      >
        {label}
        {displayTooltip && (
          <Styled.Tooltip
            arrow
            placement="right-start"
            title={
              <Typography
                variant="EC_TYPE_2XS"
                sx={{ color: 'white' }}
                component={'div'}
                data-testid={`${name}-tooltip`}
              >
                Your password must:
                <Styled.List>
                  <li>Be at least 6 characters</li>
                  <li>Include a number</li>
                  <li>Include a letter</li>
                  <li>Not contain your email</li>
                </Styled.List>
              </Typography>
            }
          >
            <Styled.Icon
              className="ri-information-line"
              data-testid={`${name}-info-icon`}
            ></Styled.Icon>
          </Styled.Tooltip>
        )}
      </Styled.Label>
      <Controller
        name={name}
        control={control}
        rules={{ ...rules }}
        render={({ field }): JSX.Element => (
          <OutlinedInput
            id={name}
            error={!!errors[name]}
            autoComplete={autocomplete}
            {...field}
            type={showText ? 'text' : 'password'}
            inputProps={{
              'data-testid': dataTestId
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={(): void => setShowText(!showText)}
                >
                  {showText ? (
                    <i className="ri-eye-off-fill"></i>
                  ) : (
                    <i className="ri-eye-fill"></i>
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        )}
      />
      {errors[name] && (
        <FormFieldErrorText name={name} message={errors[name].message} />
      )}
    </Styled.TextFieldContainer>
  );
};

export default ReactHookFormsPassword;
