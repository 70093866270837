/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  Accordion,
  AccordionSummary,
  Box,
  Theme,
  Typography
} from '@mui/material';
import { GlrcSubTopic, GlrcTopic } from '@interfaces/glrc.interfaces';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GlrcVideoList from '@pages/Partner/GlrcVideoList';
import { styled } from '@mui/system';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const SCROLL_OFFSET = 80;

const Styled = {
  NavRoot: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'expanded'
  })<{ expanded: boolean }>(({ expanded }) => ({
    marginTop: '80px',
    paddingBottom: expanded ? '74px' : 0,
    '& .accordion': {
      backgroundColor: 'transparent',
      border: 'none'
    },
    '& .accordion-summary': {
      paddingLeft: 0
    }
  })),
  TopicBox: styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '36px'
  }),
  Section: styled(Box)({
    flex: '0.5 1 212px',
    minWidth: '10px',
    display: 'flex',
    flexDirection: 'column',
    '& .sub-topic-section': {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '16px'
    }
  }),
  BorderBox: styled(Box)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.BLACK.main}`,
    marginBottom: '24px'
  })),
  Title: styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: (theme as Theme).typography.EC_TYPE_5XL.fontSize,
    lineHeight: (theme as Theme).typography.EC_TYPE_5XL.lineHeight,
    marginBottom: '20px',
    textTransform: 'uppercase'
  })),
  NavSectionTitle: styled(Typography)({
    textTransform: 'uppercase',
    marginBottom: '30px'
  }),
  SubTitle: styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }))
};

interface Props {
  topics: GlrcTopic[];
}

interface NavProps {
  sectionTitle: string;
  subTopics: GlrcSubTopic[];
  handleClick: (name: string) => void;
}

const VideoNav = ({
  sectionTitle,
  subTopics,
  handleClick
}: NavProps): React.ReactElement => {
  return (
    <>
      {!!subTopics?.length && (
        <Styled.Section data-testid="glrc-video-nav-section">
          <Styled.NavSectionTitle variant="EC_TYPE_2XL">
            {sectionTitle}
          </Styled.NavSectionTitle>
          <Box className="sub-topic-section">
            {subTopics.map((st, idx) => (
              <Styled.SubTitle
                key={`subtopic-nav-${st.name}-${idx}`}
                data-testid="glrc-video-nav-subtopic"
                variant="EC_TYPE_LG"
                onClick={(): void => handleClick(st.name)}
              >
                {st.name}
              </Styled.SubTitle>
            ))}
          </Box>
        </Styled.Section>
      )}
    </>
  );
};

const GlrcVideoPage: React.FC<Props> = ({ topics }) => {
  const { keycloakUser } = useKeycloakContext();

  const { width } = useWindowDimensions();
  const flexBreakpoint = 1004;

  const defaultExpanded = width < flexBreakpoint ? false : 'topicsPanel';

  const [expanded, setExpanded] = React.useState<string | false>(
    defaultExpanded
  );

  const topicRefs = React.useRef<HTMLDivElement[]>([]);

  const handleExpandChange =
    (panel: string) =>
    (event: React.SyntheticEvent, isExpanded: boolean): void => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleClickNav = (name: string): void => {
    const ref = topicRefs.current[name] as HTMLDivElement;

    // get top of element with the offset of the section title and padding
    const y = ref.getBoundingClientRect().top + window.scrollY - SCROLL_OFFSET;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  return (
    <>
      <Styled.NavRoot
        data-testid="glrc-video-page-nav-root"
        expanded={expanded === 'topicsPanel'}
      >
        <Accordion
          data-testid="glrc-video-page-accordion"
          expanded={expanded === 'topicsPanel'}
          onChange={handleExpandChange('topicsPanel')}
          className="accordion"
        >
          <AccordionSummary
            data-testid="glrc-video-page-accordion-summary"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="topicsPanel-content"
            id="topicsPanel-summary"
            className="accordion-summary"
          >
            <Styled.Title variant="EC_TYPE_3XL">TOPICS</Styled.Title>
          </AccordionSummary>
          <Styled.BorderBox />
          <Styled.TopicBox>
            {topics.map((topic, idx) => (
              <React.Fragment key={`video-nav-${topic.name}-${idx}`}>
                <VideoNav
                  sectionTitle={topic.name}
                  subTopics={topic.subTopics}
                  handleClick={handleClickNav}
                />
              </React.Fragment>
            ))}
          </Styled.TopicBox>
        </Accordion>
      </Styled.NavRoot>
      {topics.map((topic, idx) => (
        <React.Fragment key={`topic-title-${topic.name}-${idx}`}>
          {!!topic.subTopics?.length && (
            <>
              <Styled.Title variant="EC_TYPE_3XL">{topic.name}</Styled.Title>
              {topic.subTopics.map((st, idx) => {
                const getRef = (ref: HTMLDivElement): HTMLDivElement =>
                  (topicRefs.current[st.name] = ref);

                return (
                  <Box key={`subtopic-box-${st.name}-${idx}`} ref={getRef}>
                    <GlrcVideoList
                      videos={st.videos}
                      subtitle={st.name}
                      showAllVideos
                      userId={keycloakUser.sub}
                    />
                  </Box>
                );
              })}
            </>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default GlrcVideoPage;
