import * as React from 'react';
import { IKeycloakUser, KeycloakUserRole,  } from '@api/models/userApi.models';
import { useSetStoreValue, useStore } from 'react-context-hook';
import { ApiResponse } from '@api/models/base.models';
import { AuthClientTokens } from '@react-keycloak/core/lib/types';
import { CandidateApi } from '@api/Candidate.api';
import { CandidateProfile } from '@api/models/candidateApi.models';
import { CompanyApi } from '@api/Company.api';
import { EmployerProfile } from '@api/models/employerApi.models';
import { GraduationTypes } from '@api/models/adminDashboardApi.models';
import keycloak from '../../keycloak';
import { KeycloakContextProvider } from '@common/context/keycloakContext';
import { PartnerApi } from '@api/Partner.api';
import { PermissionsApi } from '@api/Permissions.api';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { SettingsApi } from '@api/Settings.api';
import { useExternshipPilotEnabled } from '@common/helpers/externshipHelpers/useExternshipPilotEnabled';
//import { useFetchSubsidiariesByCompanyId } from '@common/fetches/useFetchSubsidiariesByCompanyId';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import { CompanyProfile } from '@api/models/companyApi.models';

type PageProps = {
  children: React.ReactNode;
};

const KeycloakProviderWrapper: React.FC<PageProps> = ({ children }) => {
  const { setIsPilotEnabled } = useExternshipPilotEnabled();

  const [keycloakInitialized, setKeycloakInitialized] = React.useState(false);
  const [dataFetched, setDataFetched] = React.useState(false);

  const isAdmin = keycloak.hasResourceRole(KeycloakUserRole.ADMIN);
  const isCandidate = keycloak.hasResourceRole(KeycloakUserRole.CANDIDATE);
  const isEcAdmin = keycloak.hasResourceRole(KeycloakUserRole.ESCOFFIER_ADMIN);
  const isEcCareerService = keycloak.hasResourceRole(KeycloakUserRole.EC_CAREER_SERVICE);
  const isExternshipTimesheetApprover = keycloak.hasResourceRole(KeycloakUserRole.EXTERNSHIP_TIMESHEET_APPROVER);
  const isChefInstructor = keycloak.hasResourceRole(KeycloakUserRole.CHEF_INSTRUCTOR);
  const isEmployer = keycloak.hasResourceRole(KeycloakUserRole.EMPLOYER);
  const isRecruiter = keycloak.hasResourceRole(KeycloakUserRole.RECRUITER);
  const isPartner = keycloak.hasResourceRole(KeycloakUserRole.PARTNER);
  const isGlrcUser = keycloak.hasResourceRole(KeycloakUserRole.GLRC_USER);
  const isPendingUser = keycloak.hasResourceRole(KeycloakUserRole.PENDING_USER);
  const isGlrcRecruiter = isGlrcUser && isRecruiter;
  const hasValidRoleOtherThanExternshipTimesheetApprover =
    isAdmin ||
    isCandidate ||
    isEcAdmin ||
    isEcCareerService ||
    isChefInstructor ||
    isEmployer ||
    isRecruiter ||
    isPartner ||
    isGlrcUser ||
    isGlrcRecruiter ||
    isPendingUser;

  const hasValidRole =
    hasValidRoleOtherThanExternshipTimesheetApprover ||
    isExternshipTimesheetApprover;

  const isAuthenticated = keycloak.authenticated;
  const keycloakUser = keycloak.userInfo as IKeycloakUser;

  //const [companyId, setCompanyId] = useStore<number>(UseStoreKeys.COMPANY_ID);
  const setManagedCompanies = useSetStoreValue<CompanyProfile[]>(UseStoreKeys.MANAGED_COMPANIES);

  const setCandidateId = useSetStoreValue<number>(UseStoreKeys.CANDIDATE_ID);
  const setCandidateEnabled = useSetStoreValue<boolean>(
    UseStoreKeys.CANDIDATE_ENABLED
  );
  const setPartnerEmployeeId = useSetStoreValue<number | undefined>(
    UseStoreKeys.PARTNER_EMPLOYEE_ID
  );
  const setEmployerId = useSetStoreValue<number>(UseStoreKeys.EMPLOYER_ID);
  const setAllKeycloakRoles = useSetStoreValue<string[]>(UseStoreKeys.ALL_KEYCLOAK_ROLES);
  const [permissions, setPermissions] = useStore(UseStoreKeys.PERMISSIONS);
  const setUserIdpArray = useSetStoreValue(UseStoreKeys.USER_IDP_ARRAY);
  const setCandidateStudentId = useSetStoreValue<string | undefined | null>(
    UseStoreKeys.CANDIDATE_STUDENT_ID
  );
  const setIsEcStudent = useSetStoreValue(UseStoreKeys.IS_EC_STUDENT);

  // Handle user token expiration (time out)
  const setTokenExpired = useSetStoreValue<boolean>(UseStoreKeys.TOKEN_EXPIRED);
  keycloak.onTokenExpired = (): void => {
    setTokenExpired(true);
  };

  //const { subsidiaries, loaded } = useFetchSubsidiariesByCompanyId(companyId);

  const fetchPermissions = async (): Promise<void> => {
    // Get token and decode it
    const token = keycloak.token;
    const decodedToken = token ? JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString()) : null;
    
    // Extract roles from realm_access
    const roles = decodedToken?.realm_access?.roles || [];
    
    // Get permissions and combine with roles
    const fetchedPermissions = await PermissionsApi.getPermissions();
    console.debug('KeycloakProviderWrapper: permissions:: ', fetchedPermissions);
    console.debug('KeycloakProviderWrapper: roles:: ', roles);
    setAllKeycloakRoles(roles.map(role => role.replace('app-', '')));

    // Combine permissions with roles if needed
    const combinedPermissions = {
      ...fetchedPermissions,
      roles: roles
    };
    
    setPermissions(combinedPermissions);
  };

  React.useEffect(() => {
    if (keycloakInitialized) {
      fetchPermissions();
      if (keycloak && keycloak.resourceAccess) {
        console.debug('KeycloakProviderWrapper: keycloak resource roles: ', keycloak.resourceAccess);
      }
    }
  }, [keycloakInitialized]);

  // React.useEffect(() => {
  //   setIsParentCompany(!!subsidiaries?.length && isEmployer);
  // }, [subsidiaries, isEmployer]);

  const fetchData = async (): Promise<void> => {
    if (keycloakInitialized) {
      const user = await keycloak.loadUserInfo().catch(() => {
        setDataFetched(true);
      });
      const keycloakUser: IKeycloakUser = user as IKeycloakUser;
      if (keycloakUser) {
        keycloakUser.id = keycloakUser?.sub ?? undefined;
      }
      const keycloakUserId = keycloakUser?.sub ?? null;

      if (keycloakUserId) {
        const userIdpRes = await SettingsApi.getUserIdp(keycloakUserId);
        if (userIdpRes.data) {
          setUserIdpArray(userIdpRes.data.map((d) => d.identityProvider));
        }
      }

      if (isEmployer || isRecruiter || isExternshipTimesheetApprover || isGlrcUser) {
        const employerResponse: ApiResponse<EmployerProfile> =
          await CompanyApi.getEmployerByKeycloakId(keycloakUserId);
        setEmployerId(employerResponse.data.id);
        console.debug('KeycloakProviderWrapper: employerResponse: ', employerResponse);

        const companyLinks = employerResponse.data?.companyToEmployers;
        if (companyLinks) {
          setManagedCompanies(companyLinks.map((link) => link.company));
        }
      } 
      if (isCandidate) {
        const candidate: ApiResponse<CandidateProfile> =
          await CandidateApi.getByKeycloakId(keycloakUserId);

        setCandidateId(candidate.data.id);
        setCandidateStudentId(candidate?.data?.studentId);
        setIsPilotEnabled(!!candidate?.data?.externshipPilotEnabled);
        setCandidateEnabled(!!candidate?.data.enabled);
        setIsEcStudent(
          candidate?.data?.graduationType?.value === GraduationTypes.EC_STUDENT
        );
      } 
      if (isPartner) {
        const partnerEmployee = await PartnerApi.getPartnerEmployeeByKeycloakId(
          keycloakUserId
        );

        setPartnerEmployeeId(partnerEmployee?.data?.id);
      } 
      setDataFetched(true);
    }
  };

  React.useEffect(() => {
    if (keycloakInitialized) {
      fetchData();
    }
  }, [keycloakInitialized]);

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: 'check-sso' }}
      onTokens={(tokens: AuthClientTokens): void => {
        localStorage.setItem('token', tokens.token ?? '');

        setKeycloakInitialized(true);
      }}
    >
      {keycloakInitialized && dataFetched && !!permissions && (
        <KeycloakContextProvider
          value={{
            isAdmin,
            isCandidate,
            isEcAdmin,
            isEcCareerService,
            isExternshipTimesheetApprover,
            isChefInstructor,
            isEmployer,
            isRecruiter,
            isPartner,
            isGlrcUser,
            isGlrcRecruiter,
            isPendingUser,
            keycloakUser,
            hasValidRole,
            isAuthenticated,
            hasValidRoleOtherThanExternshipTimesheetApprover,
          }}
        >
          {children}
        </KeycloakContextProvider>
      )}
    </ReactKeycloakProvider>
  );
};

export default KeycloakProviderWrapper;
