import { CandidateApi } from '@api/Candidate.api';
import { PdfApi } from '@api/Pdf.api';
import { PdfTypes } from '@api/models/pdfApi.models';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getPdfHtmlByElementId = (id: PdfTypes): string => {
  let html = document.getElementById(id)?.innerHTML;

  if (html) {
    html =
      html +
      '<style>@import url("https://use.typekit.net/ahd3hqk.css");</style>';
  }

  return html || '';
};

const getResumeHtml = (): string => {
  let html = document.getElementById('printable-resume')?.innerHTML;

  if (html) {
    html =
      html +
      '<style>@import url("https://use.typekit.net/ahd3hqk.css");</style>';
  }

  return html || '';
};

const fetchPdf = async (): Promise<Blob | void> => {
  const html = getResumeHtml();

  if (html) {
    const pdf: Blob = await PdfApi.generate({ html });
    return pdf;
  }
};

export const onSave = async (
  candidateId: number,
  overwrite: boolean
): Promise<void> => {
  const html = getResumeHtml();

  const putBody = {
    html,
    overwrite
  };
  await CandidateApi.saveResume(candidateId, putBody);
};

export const onPrint = async (): Promise<void> => {
  const pdf = await fetchPdf();

  if (pdf) {
    // Create a Blob from the PDF Stream
    const file = new Blob([pdf], { type: 'application/pdf' });
    // Build a URL from the file
    const fileURL = URL.createObjectURL(file);

    /* Open the URL on new tab
     * Creating a tag in order to be compatable for Safari
     * per https://developer.mozilla.org/en-US/docs/Web/API/Window/open#browser_compatibility
     */
    const a = document.createElement('a');
    a.setAttribute('href', fileURL);
    a.setAttribute('target', '_blank');
    a.setAttribute('rel', 'noopener noreferrer');
    a.click();
  }
};

export const onDownload = async (): Promise<void> => {
  const pdf = await fetchPdf();

  if (pdf) {
    // Create a Blob from the PDF Stream
    const file = new Blob([pdf], { type: 'application/pdf' });
    // Create link
    const a = document.createElement('a');
    // Build a URL from the file and set link's href to point to the Blob URL
    a.href = URL.createObjectURL(file);
    // File Name
    a.download = 'resume.pdf';
    // Append link to the body
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

export const downloadAll = async (fileUrl?: string): Promise<void> => {
  if (fileUrl) {
    // Get just 'files/filename.pdf' string
    const filePart = fileUrl.split('/').slice(-2).join('/');
    const newUrl = `${BASE_URL}/${filePart}`;

    const res = await fetch(newUrl);
    const data = await res.blob();
    const blob = new Blob([data], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileUrl.split('/').pop() ?? '';
    link.click();
  }
};
