import { createCustomContext } from '@common/context/customContext';
import { MouseEvent } from 'react';
import { SortOrderType } from '@interfaces/search.interfaces';

interface DashboardTableContextType<T> {
  sortBy: keyof { [key: string]: T };
  sortOrder: SortOrderType;
  sortMenuOpen: boolean;
  sortMenuAnchor: HTMLElement | null;
  handleSortMenuOpen: (event: MouseEvent<HTMLElement>) => void;
  handleClickAway: () => void;
  handleSortClick: <S>(event: MouseEvent<unknown>, sortBy: keyof S) => void;
  page: number;
  rowsPerPage: number;
}

export const [useTableSortContext, TableSortProvider] =
  createCustomContext<DashboardTableContextType<unknown>>();
