import * as React from 'react';
import {
  Box,
  createTheme,
  SxProps,
  Theme,
  ThemeProvider,
  useTheme
} from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';
import { GOLD_1, TABLE_ROW_ALT } from '@themes/ui/escoffier';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

interface Props extends DataGridProProps {
  customHeaderColor?: string;
  customRowAltColor?: string;
  containerHeight?: string;
  styles?: SxProps<Theme>;
}

export const CustomToolbar: React.FC = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          allColumns: true
        }}
        printOptions={{ disableToolbarButton: true }}
      />
    </GridToolbarContainer>
  );
};

const EcDataGrid: React.FC<Props> = ({
  customHeaderColor = GOLD_1,
  customRowAltColor = TABLE_ROW_ALT,
  containerHeight = 'auto',
  styles,
  ...dataGridProps
}) => {
  const theme = useTheme();

  return (
    <ThemeProvider
      theme={createTheme({
        palette: theme.palette,
        typography: theme.typography
      })}
    >
      <Box sx={{ width: '100%', height: containerHeight }}>
        <DataGridPro
          {...dataGridProps}
          disableRowSelectionOnClick
          getRowClassName={(params): string =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          slots={{
            ...dataGridProps.slots,
            detailPanelExpandIcon: KeyboardArrowRight,
            detailPanelCollapseIcon: KeyboardArrowDown
          }}
          sx={{
            ...styles,
            '& .MuiCheckbox-root svg': {
              color: theme.palette.GRAY_3.main
            },
            '.MuiDataGrid-columnHeader': {
              backgroundColor: customHeaderColor
            },
            '.MuiDataGrid-columnHeaderTitle': {
              ...(theme as Theme).typography.EC_TYPE_XL,
              color: theme.palette.WHITE.main,
              fontWeight: 800,
              textTransform: 'uppercase'
            },
            '& .MuiDataGrid-sortIcon, .MuiDataGrid-menuIconButton': {
              opacity: 'inherit !important',
              color: theme.palette.WHITE.main,
              ':hover': {
                opacity: 1
              }
            },
            [`& .${gridClasses.row}.odd`]: {
              backgroundColor: customRowAltColor,
              '&.Mui-selected': {
                backgroundColor: 'rgba(187, 17, 51, 0.08)'
              },
              ':hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }
          }}
          data-testid="ec-data-grid"
        />
      </Box>
    </ThemeProvider>
  );
};

export default EcDataGrid;
