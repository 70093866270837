import * as React from 'react';
import { Box, Typography } from '@mui/material';
import CareerPathNode, {
  CareerNodeStatus,
  NodeConnectorPosition
} from '@pages/Candidate/CareerPathPage/MyCareerGoal/CareerPathNode';
import {
  CareerPathRole,
  CareerTrack
} from '@interfaces/careerJourney.interfaces';
import { styled } from '@mui/system';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const PATH_MAX_WIDTH = 805;
const VERTICAL_DISPLAY_BREAKPOINT = 769;
const SMALL_NODES_BREAKPOINT = 451;

const Styled = {
  Root: styled(Box)({
    marginTop: '24px',
    '& .career-track-name': {
      textTransform: 'uppercase'
    }
  }),
  Path: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'hasCurrentPosition'
  })<{ hasCurrentPosition: boolean }>(({ hasCurrentPosition }) => ({
    position: 'relative',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'start',
    justifyContent: 'space-between',
    margin: hasCurrentPosition ? '36px auto 0 5px' : '36px auto 0',
    rowGap: '18px',
    paddingRight: '10px',
    '& svg': {
      position: 'absolute'
    },
    [`@media (min-width:${SMALL_NODES_BREAKPOINT}px)`]: {
      rowGap: '54px',
      ...(hasCurrentPosition && {
        marginLeft: '18px'
      })
    },
    [`@media (min-width:${VERTICAL_DISPLAY_BREAKPOINT}px)`]: {
      flexFlow: 'row nowrap',
      maxWidth: `${PATH_MAX_WIDTH}px`,
      minHeight: 'unset',
      ...(hasCurrentPosition && {
        marginTop: '48px'
      })
    }
  }))
};

interface CareerTrackProps {
  careerTrack: CareerTrack;
  previousJobIds?: number[];
  currentJobIds: number[];
  savedGoal?: CareerPathRole;
  setCareerGoal: (careerRoleId: number) => Promise<void>;
}

const getNode = (index: number): HTMLElement | null =>
  document.getElementById(`path-node-${index}`);

const CareerTrackComponent = ({
  careerTrack,
  previousJobIds,
  currentJobIds,
  savedGoal,
  setCareerGoal
}: CareerTrackProps): React.ReactElement => {
  const { width } = useWindowDimensions();

  const displayVertical = width < VERTICAL_DISPLAY_BREAKPOINT;
  const displaySmallNodes = width < SMALL_NODES_BREAKPOINT;
  const [, setLoaded] = React.useState(false);
  const [expandedTrackRole, setExpandedTrackRole] =
    React.useState<CareerPathRole | null>(null);

  const getStatus = (career: CareerPathRole): CareerNodeStatus => {
    if (currentJobIds.includes(career.id)) {
      return 'current';
    } else if (previousJobIds?.includes(career.id)) {
      return 'complete';
    } else {
      return 'incomplete';
    }
  };

  const box = document.getElementById('career-track-node-container');

  const getConnectorPosition = (
    idx: number
  ): NodeConnectorPosition | undefined => {
    const node = getNode(idx);
    const prevNode = getNode(idx - 1);
    if (node && prevNode && box) {
      const rect = box.getBoundingClientRect();
      const pos1 = node.getBoundingClientRect();
      const pos2 = prevNode.getBoundingClientRect();
      const x1 = pos1.x + pos1.width / 2 - rect.x;
      const y1 = pos1.y + pos1.height / 2 - rect.y;
      const x2 = pos2.x + pos2.width / 2 - rect.x;
      const y2 = pos2.y + pos2.height / 2 - rect.y;

      return { x1, y1, x2, y2 };
    }
  };

  const hasCurrentPosition = React.useMemo(
    () => careerTrack.path.some((role) => getStatus(role) === 'current'),
    [careerTrack]
  );

  const ordinalCareerTrack = careerTrack.path.sort(
    (a, b) => a.ordinalNumber - b.ordinalNumber
  );

  const goalIndex = ordinalCareerTrack.findIndex((role) =>
    savedGoal ? role.id === savedGoal?.id : undefined
  );

  return (
    <Styled.Root data-testid="career-track-root">
      <Typography
        variant="EC_TYPE_XL"
        className="career-track-name"
      >{`${careerTrack.name} career track`}</Typography>
      <Styled.Path
        data-testid="career-track-node-container"
        id="career-track-node-container"
        hasCurrentPosition={!!hasCurrentPosition}
      >
        {ordinalCareerTrack
          .slice(0, goalIndex + 1)
          .map((pathRole: CareerPathRole, idx: number) => {
            const status = getStatus(pathRole);
            const linePos = idx > 0 ? getConnectorPosition(idx) : undefined;
            const isSavedGoal: boolean = savedGoal
              ? savedGoal.id === pathRole.id
              : false;

            const solidConnector =
              idx > 0
                ? status !== 'incomplete' &&
                  getStatus(ordinalCareerTrack[idx - 1]) !== 'incomplete'
                  ? true
                  : false
                : false;

            return (
              <React.Fragment key={idx}>
                <CareerPathNode
                  index={idx}
                  status={status}
                  solidConnector={solidConnector}
                  displayVertical={displayVertical}
                  smallNodes={displaySmallNodes}
                  nodePositions={linePos}
                  setCareerGoal={setCareerGoal}
                  setLoaded={setLoaded}
                  role={pathRole}
                  isSavedGoal={isSavedGoal}
                  expandedRole={expandedTrackRole}
                  setExpandedRole={setExpandedTrackRole}
                />
              </React.Fragment>
            );
          })}
      </Styled.Path>
    </Styled.Root>
  );
};

export default CareerTrackComponent;
