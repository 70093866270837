import * as React from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const Styled = {
  Root: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'width' && prop !== 'height'
  })<{ width?: string; height?: string }>(({ width, height }) => ({
    border: `1px solid ${theme.palette.GOLD_1.main}`,
    padding: '8px 24px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height,
    width
  })),
  Title: styled(Typography)({
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    color: theme.palette.GOLD_1.main,
    marginBottom: '0px'
  }),
  Text: styled(Typography)(({ theme }) => ({
    marginBottom: '0px',
    '& .total-hours-text': {
      ...(theme as Theme).typography.EC_TYPE_3XS
    }
  }))
};

interface Props {
  title: string;
  text: string;
  height?: string;
  width?: string;
  totalHours?: number;
}

const GoldenInfoBox: React.FC<Props> = ({
  title,
  text,
  height,
  width,
  totalHours
}) => {
  return (
    <Styled.Root height={height} width={width}>
      <Styled.Title variant="EC_TYPE_XL">{title}</Styled.Title>
      <Styled.Text variant="EC_TYPE_4XL">{text}</Styled.Text>
      {totalHours && (
        <Typography className="total-hours-text">
          of {Math.floor(totalHours)}
        </Typography>
      )}
    </Styled.Root>
  );
};

export default GoldenInfoBox;
