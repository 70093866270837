import * as React from 'react';
import { BLUE_1 } from '@themes/ui/escoffier';
import Button from '@mui/material/Button';
import ExternalEduLink from './ExternalEduLink';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';

const ButtonStyles = {
  backgroundColor: BLUE_1,
  marginRight: '25px',
  '&:last-child': {
    marginRight: 0
  },
  ['@media (max-width:480px)']: {
    padding: '10px'
  }
};

const Styled = {
  Arrow: styled('i')({
    marginLeft: '10px'
  })
};

interface ButtonArrowProps {
  buttonText: string;
  buttonClick?: () => void;
  buttonLink?: string;
  external?: boolean;
  fullWidth?: boolean;
}

const ButtonArrow: React.FC<ButtonArrowProps> = (props) => {
  const { buttonText, buttonClick, buttonLink, external, fullWidth } = props;

  const ExternalButton: React.FC = () => (
    <Button
      variant="contained"
      fullWidth={fullWidth}
      target="_blank"
      rel="noopener noreferrer"
      className="ga-cta"
      component={Link}
      sx={{
        ':hover': {
          cursor: 'pointer'
        },
        ['@media (max-width:480px)']: {
          padding: '10px'
        }
      }}
    >
      {buttonText}
      <Styled.Arrow className="ri-arrow-right-line"></Styled.Arrow>
    </Button>
  );

  if (external) {
    if (buttonLink?.includes('www.escoffier.edu')) {
      return (
        <ExternalEduLink link={buttonLink}>
          <ExternalButton />
        </ExternalEduLink>
      );
    }

    return <ExternalButton />;
  }

  return (
    <Button
      component={RouterLink}
      to={buttonLink}
      fullWidth={fullWidth}
      onClick={buttonClick}
      variant="contained"
      data-testid="carouselButton"
      className="ga-cta"
      sx={{ ...ButtonStyles }}
    >
      {buttonText}
      <Styled.Arrow className="ri-arrow-right-line"></Styled.Arrow>
    </Button>
  );
};

export default ButtonArrow;
