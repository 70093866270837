/* eslint-disable @typescript-eslint/indent */
import { useEffect, useState } from 'react';
import { AttestationWaiverType } from '@interfaces/attestation.interface';
import { Codebook } from '@api/models/codebook.models';
import { CodebookApi } from '@api/Codebook.api';

export type FetchAttestationWaiverTypeCodebooks = {
  attestationWaiverTypes: Codebook<AttestationWaiverType>[];
  loading: boolean;
  loaded: boolean;
};

export const useFetchAttestationWaiverTypesCodebooks =
  (): FetchAttestationWaiverTypeCodebooks => {
    const [attestationWaiverTypes, setAttestationWaiverTypes] = useState<
      Codebook<AttestationWaiverType>[]
    >([]);

    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      const fetchData = async (): Promise<void> => {
        try {
          setLoading(true);
          const res = await CodebookApi.getAttestationWaiverTypes();
          setAttestationWaiverTypes(res.data);
          setLoaded(true);
        } catch (error: any) {
          console.error(
            'Error for useFetchAttestationWaiverTypesCodebooks -> CodebookApi.getAttestationWaiverTypes()',
            error
          );
        } finally {
          setLoading(false);
          setLoaded(true);
        }
      };

      fetchData();
    }, []);

    return {
      attestationWaiverTypes,
      loading,
      loaded
    };
  };
