/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Box, Button } from '@mui/material';
import {
  FieldErrors,
  useFieldArray,
  useForm,
  UseFormReturn
} from 'react-hook-form';
import { FormOrder, scrollToError } from '@common/helpers/scrollToError';
import { ApproverRole, ExternshipEntity } from '@api/models/externshipApi.models';
import ExternshipProposalApproverFieldArray from '@components/Forms/ExternshipProposalApproverFieldArray';
import StepSection from '@components/StepSection';
import { styled } from '@mui/system';
import { ExternshipProposalApproversUpsertPayload, ExternshipProposalApprover } from '@api/models/externshipProposalApi.models';
import { sortExternshipProposalApprovers } from '@common/helpers/externshipHelpers/sortExternshipProposalApprovers';

const Styled = {
  Button: styled(Button, {
    shouldForwardProp: (prop) => prop !== 'verificationPage'
  })<{ verificationPage: boolean }>(({ verificationPage, theme }) => ({
    padding: 0,
    ...(!verificationPage && {
      transform: 'translate(24px, -32px)',
      [theme.breakpoints.up('sm')]: {
        transform: 'translate(74px, -32px)'
      }
    })
  })),
  BtnIcon: styled('i')({
    fontSize: '15px',
    lineHeight: '15px'
  })
};

export interface ExternshipProposalApproverFormValues {
  approvers: ExternshipProposalApprover[];
  allowExtraApprovers: 0 | 1;
}

export const useExternshipProposalApproverForm = (
  handleValidFormRequest: (
    payload: ExternshipProposalApproversUpsertPayload,
    onSuccess?: () => void
  ) => Promise<void>
): {
  methods: UseFormReturn<ExternshipProposalApproverFormValues, any>;
  handleFormSubmit: (onSuccess?: () => void) => void;
} => {
  const methods = useForm<ExternshipProposalApproverFormValues>({
    defaultValues: {
      allowExtraApprovers: 0,
      approvers: [
        {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          jobTitle: '',
          approverRole: ApproverRole.MANAGER,
        }
      ]
    }
  });

  const formOrder: FormOrder = {
    firstName: 10,
    lastName: 20,
    email: 30,
    phone: 40,
    jobTitle: 50,
    role: 60,
    approvers: 70
  };

  const { formState, handleSubmit, setError } = methods;

  const handleInvalidForm = (errors: FieldErrors): void => {
    scrollToError(errors, formOrder);
  };

  const handleValidForm = async (
    data: ExternshipProposalApproverFormValues,
    onSuccess?: () => void
  ): Promise<void> => {
    const payload: ExternshipProposalApproversUpsertPayload = {
      approvers: data.approvers
    };

    try {
      handleValidFormRequest(payload, onSuccess);
    } catch (error: any) {
      const errorData = error.response?.data?.data;
      if (errorData?.length) {
        errorData.forEach(({ field, message }) =>
          setError(field, { type: 'manual', message })
        );

        scrollToError(formState.errors, formOrder);
      }
    }
  };

  const handleFormSubmit = (onSuccess?: () => void): void => {
    const onValid = async (data: ExternshipProposalApproverFormValues): Promise<void> =>
      handleValidForm(data, onSuccess);
    handleSubmit(onValid, handleInvalidForm)();
  };

  return {
    methods,
    handleFormSubmit
  };
};

interface ApproversFormProps {
  methods: UseFormReturn<ExternshipProposalApproverFormValues, any>;
  externship?: ExternshipEntity;
  verificationPage?: boolean;
}

const ExternshipProposalApproversForm: React.FC<ApproversFormProps> = ({
  methods,
  externship: draftExternship,
  verificationPage
}) => {
  const defaultApproverFields: ExternshipProposalApprover = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    jobTitle: '',
    approverRole: ApproverRole.SUPERVISOR
  };

  const { control, reset, watch, setValue } = methods;

  const { fields, append, remove } = useFieldArray({
    name: 'approvers',
    control
  });

  const watchAllowExtraApprovers = watch('allowExtraApprovers');
  React.useEffect(() => {
    if (watchAllowExtraApprovers) {
      // Only append when select is set for extra approvers to prevent
      // adding extra empty approver when loading form the back-end
      if (fields.length === 1) {
        append(defaultApproverFields);
      }
    } else {
      removeAllExtraApprovers();
    }
  }, [watchAllowExtraApprovers]);

  React.useEffect(() => {
    if (draftExternship && draftExternship.approvers.length) {
      reset({
        approvers: sortExternshipProposalApprovers(draftExternship.approvers),
        allowExtraApprovers: draftExternship.approvers.length > 1 ? 1 : 0,
      });
    }
  }, [reset, draftExternship]);

  const addExtraApprover = (): void => {
    append(defaultApproverFields);
  };

  const removeExtraApprover = (idx: number): void => {
    if (fields[idx].approverRole !== ApproverRole.MANAGER) {
      remove(idx);
    }

    if (fields.length === 1) {
      setValue('allowExtraApprovers', 0);
    }
  };

  const removeAllExtraApprovers = (): void => {
    const idsToRemove: number[] = [];

    fields.forEach((field, i) => {
      if (field.approverRole !== ApproverRole.MANAGER) {
        idsToRemove.push(i);
      }
    });

    remove(idsToRemove);
  };

  const padding = verificationPage ? '0px' : undefined;

  const getSectionTitleByApproverRole = (idx: number, approverRole: ApproverRole): string => {
    if (approverRole === ApproverRole.MANAGER) {
      return 'Manager Information';
    }

    return `Supervisor #${idx} Information`;
  };

  const showAddSupervisorButton = (idx: number): boolean => {
    return (idx === fields.length - 1) && (fields.length > 1);
  };

  return (
    <>
      {
        fields.map((field, idx) => (
          <Box key={idx}>
            <StepSection
              title={getSectionTitleByApproverRole(idx, field.approverRole)}
              dataTestId="externship-proposed-manager-form"
              padding={padding}
              disableBoxShadow
            >
              <ExternshipProposalApproverFieldArray
                disableApproverRoleSelect
                approverRole={field.approverRole}
                fieldArrayIndex={idx}
                handleRemoveFieldArrayItem={removeExtraApprover}
              />
            </StepSection>
            {showAddSupervisorButton(idx) && (
              <Styled.Button
                variant="text"
                onClick={addExtraApprover}
                startIcon={<Styled.BtnIcon className="ri-add-box-line" />}
                verificationPage={!!verificationPage}
              >
                Add a supervisor
              </Styled.Button>
            )}
          </Box>
        ))
      }
    </>
  );
};

export default ExternshipProposalApproversForm;