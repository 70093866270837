/* eslint-disable quotes */
/**
 * Benefits
 */
const healthWellnessBenefits = [
  'Dental Insurance',
  'Disability Insurance',
  'Health Insurance',
  'Health Savings Account',
  'Family Medical Leave',
  'Life Insurance',
  'Mental Health Benefits',
  'Paid Time Off',
  'Sick Leave',
  'Vision Insurance'
];

const retirementBenefits = ['401(k)', '401(k) Matching'];

const professionalDevBenefits = [
  'Continuing Education',
  'Cross Functional Training',
  'Mentorship Program',
  'Tuition Assistance'
];

export const benefits = {
  healthWellnessBenefits,
  retirementBenefits,
  professionalDevBenefits
};

/**
 * Culture and health & safety
 */
const culture = [
  'Inclusion',
  'Integrity',
  'Diversity',
  'Quality',
  'Teamwork',
  'Passion',
  'Collaboration',
  'Accountability',
  'Customer Commitment',
  'Growth',
  'Innovation',
  'Respect',
  'Fun',
  'Trust',
  'Environmental',
  'Simplicity'
];

const healthAndSafety = [
  'Guest Masks Required',
  'Staff Temperature Checks',
  'Sanitizing Between Customers',
  'Vaccination Required',
  'Staff Wear Masks'
];

export const cultureHealthSafety = { culture, healthAndSafety };

/**
 * Skills
 */
const softSkillsUnsorted = [
  'Adaptable',
  'Creative',
  'Communicative',
  'Dependable',
  'Leadership',
  'Multitasking',
  'Organized',
  'Problem Solving',
  'Time Management',
  'Responsible'
];

const techSkillsUnsorted = [
  'Advanced Baking',
  'Food Cost Control',
  'Inventory',
  'Knife Skills',
  'Menu Planning',
  'Mise en Place',
  'Multilingual',
  'Nutrition',
  'Safety & Sanitation',
  'Advanced Knife Skills',
  'Food Safety Knowledge',
  'Safety Procedure Knowledge',
  'Cleaning & Sanitation',
  'Portioning',
  'Vegetable Cookery',
  'Dressing Making',
  'Meat Butchery',
  'Sauce Making',
  'Frying',
  'Sauteing',
  'Kitchen Equipment Maintenance',
  'Grilling',
  'Expediting'
];

const techSkills = techSkillsUnsorted.sort();
const softSkills = softSkillsUnsorted.sort();

export const skills = { softSkills, techSkills };

export const jobTypes = [
  'Full Time',
  'Part Time',
  'Externship',
  'Seasonal',
  'On Call'
];
