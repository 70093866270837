import * as React from 'react';
import { Box } from '@mui/material';
import { DashboardJobPost } from '@api/models/singleJobPostApi.models';
import JobTableRow from '@pages/Employer/EmployerDashboard/JobPostingsTable/JobTableRow';
import NoDataMessage from '@components/NoDataMessage';
import { sortTableData } from '@common/helpers/sortByCategory';
import { styled } from '@mui/system';
import { useTableSortContext } from '@common/context/tableSortContext';

const Styled = {
  TableContainer: styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    backgroundColor: '#FFFFFF',
    border: `1px solid ${theme.palette.GRAY_1.main}`,
    borderBottom: 'none',
    borderRadius: '0 0 4px 4px',
    overflow: 'hidden'
  })),
  RowBox: styled(Box)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.GRAY_1.main}`,
    ':hover': {
      backgroundColor: theme.palette.GRAY_2.main
    }
  })),
  EmptyBox: styled(Box)(({ theme }) => ({
    height: '220px',
    borderBottom: `1px solid ${theme.palette.GRAY_1.main}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }))
};

interface Props {
  jobList: DashboardJobPost[];
  companyName?: string;
  handleVisibility: (id: number) => Promise<void>;
}

const JobPostingsTable: React.FC<Props> = ({
  jobList,
  companyName,
  handleVisibility
}) => {
  const { sortBy, sortOrder, page, rowsPerPage } = useTableSortContext();

  return (
    <Styled.TableContainer data-testid="employer-dashboard-table-container">
      {jobList.length > 0 ? (
        <>
          {sortTableData(jobList, sortOrder, sortBy as keyof DashboardJobPost)
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((job) => (
              <Styled.RowBox key={job.id} data-testid="table-row-box">
                <JobTableRow
                  handleVisibility={handleVisibility}
                  companyName={companyName}
                  {...job}
                />
              </Styled.RowBox>
            ))}
        </>
      ) : (
        <Styled.EmptyBox>
          <NoDataMessage title="You haven't posted any jobs yet" />
        </Styled.EmptyBox>
      )}
    </Styled.TableContainer>
  );
};

export default JobPostingsTable;
