import { DateRange } from '@common/models/common.models';
import { DateTime } from 'luxon';

const formatDate = (date: string): string => {
  const isoDate = new Date(date).toISOString();
  return DateTime.fromISO(isoDate).toFormat('MM/dd/yyyy');
};

export const getChartLabel = (dateRange?: DateRange): string => {
  let label = '';

  if (!dateRange || (!dateRange.startDate && !dateRange.endDate)) {
    label = 'All Time';
  } else {
    const { startDate, endDate } = dateRange;
    const start = !startDate ? '' : formatDate(startDate);
    const end = !endDate ? '' : formatDate(endDate);

    if (start && !end) {
      label = `${start} - Present`;
    }

    if (end && !start) {
      label = `Through ${end}`;
    }

    if (start && end) {
      label = `${start} - ${end}`;
    }
  }
  return label;
};
