import * as React from 'react';
import { ReactComponent as CrownIcon } from '@assets/resumeIcons/crown.svg';
import { OverviewItem } from '@pages/Candidate/ResumePreviewModal/resumePreviewModal.models';
import ResumeIconWithLabel from './ResumeIconWithLabel';
import theme from '@themes/ui/escoffier';

const styles = {
  overview: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px'
  },
  overviewItemsWrapper: {
    display: 'flex',
    columnGap: '16px',
    rowGap: '16px',
    maxWidth: '6in',
    flexWrap: 'wrap' as 'wrap'
  },
  candidateName: {
    ...theme.typography.EC_TYPE_4XL,
    maxWidth: 'calc(50% - 22px)',
    zIndex: 20
  },
  crownWrapper: {
    position: 'absolute' as 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    flex: '0 1 auto',
    textAlign: 'center' as 'center',
    display: 'flex'
  },
  crownIcon: {
    width: '42px'
  },
  crownDecoration: {
    position: 'absolute' as 'absolute',
    top: '14px',
    overflow: 'hidden',
    width: '85px',
    height: '1px',
    content: '""',
    backgroundColor: theme.palette.GRAY_1.main
  }
};

interface Props {
  firstName: string;
  lastName: string;
  overviewItems: OverviewItem[];
  contactItems: OverviewItem[];
}

const ResumeOverviewSection: React.FC<Props> = ({
  firstName,
  lastName,
  overviewItems,
  contactItems
}) => {
  return (
    <div>
      <div style={styles.overview}>
        <div
          style={{ ...styles.candidateName }}
          data-testid="resume-overview-section-name"
        >
          {firstName} {lastName}
        </div>
        <div style={styles.crownWrapper}>
          <div style={{ ...styles.crownDecoration, right: '50px' }} />
          <CrownIcon style={styles.crownIcon} />
          <div style={{ ...styles.crownDecoration, left: '50px' }} />
        </div>
        <div>
          {contactItems.map((item, idx) => (
            <React.Fragment key={idx}>
              {item.label && !item.hidden && (
                <ResumeIconWithLabel
                  label={item.label}
                  svgIcon={item.svgIcon}
                  style={{ marginBottom: '8px' }}
                  dataTestId="resume-contact-item"
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div>
        <div style={styles.overviewItemsWrapper}>
          {overviewItems.map((item, idx) => {
            const { hidden } = item;

            if (item.label) {
              return (
                <React.Fragment key={item.label}>
                  {!hidden && (
                    <ResumeIconWithLabel
                      label={item.label}
                      svgIcon={item.svgIcon}
                      dataTestId="resume-overview-item"
                    />
                  )}
                </React.Fragment>
              );
            }

            return <React.Fragment key={idx} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default ResumeOverviewSection;
