import * as React from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { ExternshipUser } from '@api/models/careerServicesApi.models';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Paper)(({ theme }) => ({
    padding: '26px 16px 58px 48px',
    borderBottom: `1px solid ${theme.palette.GRAY_1.main}`,
    borderRadius: 0
  })),
  ColumnBox: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '60px',
    '& .info-text': {
      fontWeight: 400
    }
  })
};

interface Props {
  users: ExternshipUser[];
}

const CsCompaniesExpandPanel: React.FC<Props> = ({ users }) => {
  return (
    <Styled.Root>
      <Stack direction="row">
        <Styled.ColumnBox>
          <Typography
            variant="EC_TYPE_XS"
            className="info-title"
            fontSize="18px"
          >
            NAME
          </Typography>
          {users.map((a) => (
            <Typography
              key={a?.email}
              variant="EC_TYPE_XS"
              className="info-text"
              fontSize="16px"
              paddingTop="8px"
            >
              {a?.firstName} {a?.lastName}
            </Typography>
          ))}
        </Styled.ColumnBox>

        <Styled.ColumnBox>
          <Typography
            variant="EC_TYPE_XS"
            className="info-title"
            fontSize="18px"
          >
            ROLE
          </Typography>
          {users.map((e) => (
            <Typography
              key={e?.email}
              variant="EC_TYPE_XS"
              className="info-text"
              fontSize="16px"
              paddingTop="8px"
              sx={ { textTransform: 'capitalize' } }
            >
              {e?.approverRole + (e?.isCompanyAdmin ? ' (Admin)' : '')}
            </Typography>
          ))}
        </Styled.ColumnBox>

        <Styled.ColumnBox>
          <Typography
            variant="EC_TYPE_XS"
            className="info-title"
            fontSize="18px"
          >
            EMAIL
          </Typography>
          {users.map((e) => (
            <Typography
              key={e?.email}
              variant="EC_TYPE_XS"
              className="info-text"
              fontSize="16px"
              paddingTop="8px"
            >
              {e?.email}
            </Typography>
          ))}
        </Styled.ColumnBox>

        <Styled.ColumnBox>
          <Typography
            variant="EC_TYPE_XS"
            className="info-title"
            fontSize="18px"
          >
            PHONE
          </Typography>
          {users.map((e) => (
            <Typography
              key={e.email}
              variant="EC_TYPE_XS"
              className="info-text"
              fontSize="16px"
              paddingTop="8px"
            >
              {e.phone}
            </Typography>
          ))}
        </Styled.ColumnBox>

        <Styled.ColumnBox>
          <Typography
            variant="EC_TYPE_XS"
            className="info-title"
            fontSize="18px"
          >
            GRAD DATE
          </Typography>
          {users.map((e) => (
            <Typography
              key={e.email}
              variant="EC_TYPE_XS"
              className="info-text"
              fontSize="16px"
              paddingTop="8px"
            >
              {e?.gradDate
                ? formatDate(new Date(e.gradDate).toDateString())
                : '\u00A0'}
            </Typography>
          ))}
        </Styled.ColumnBox>

        <Styled.ColumnBox>
          <Typography
            variant="EC_TYPE_XS"
            className="info-title"
            fontSize="18px"
          >
            PROGRAM
          </Typography>
          {users.map((e) => (
            <Typography
              key={e.email}
              variant="EC_TYPE_XS"
              className="info-text"
              fontSize="16px"
              paddingTop="8px"
            >
              {e?.program || '\u00A0'}
            </Typography>
          ))}
        </Styled.ColumnBox>
      </Stack>
    </Styled.Root>
  );
};

export default CsCompaniesExpandPanel;
