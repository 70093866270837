import { createTheme } from '@mui/material/styles';
import React from 'react';

export const BLACK = '#000000';
export const BLUE_1 = '#2263C5'; // Hover color -> #174589
export const BLUE_2 = '#4F76FF';
export const BLUE_3 = '#344DA8';
export const HOVER = '#174589';
export const ERROR = '#BB1133';
export const GOLD_LIGHT = '#EBDFCD'; // tag or chip color
export const GOLD_1 = '#8E6C4A';
export const GOLD_2 = '#FFE01B';
export const GRAY_1 = '#F4EFEA';
export const GRAY_2 = '#F8F5F2';
export const GRAY_3 = '#5C6B80';
export const GRAY_4 = '#333333';
export const GRAY_5 = '#E5E5E5';
export const GRAY_F4 = '#F4F4F4';
export const GREEN_1 = '#22C55E';
export const OUTLINE_GRAY = '#A6B0BF';
export const ORANGE_1 = '#EF7A29';
export const WHITE = '#FFFFFF';
export const TABLE_ROW_ALT = '#E8E2D9';

export const BOX_SHADOW_1 = '0px 4px 4px 0px rgba(0, 0, 0, 0.25)';

const theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      headerMobile: 1020,
      lg: 1200,
      xl: 1536
    }
  },
  palette: {
    primary: {
      main: BLUE_1,
      contrastText: WHITE,
      hover: HOVER
    },
    secondary: {
      main: GOLD_1
    },
    error: {
      main: ERROR
    },
    neutral: {
      main: GRAY_3,
      contrastText: WHITE
    },
    BLACK: {
      main: BLACK,
      contrastText: WHITE
    },
    WHITE: {
      main: WHITE
    },
    // CUSTOM COLORS:
    BLUE_2: {
      main: BLUE_2
    },
    BLUE_3: {
      main: BLUE_3
    },
    GOLD_1: {
      main: GOLD_1,
      contrastText: WHITE
    },
    GOLD_2: {
      main: GOLD_2
    },
    GOLD_LIGHT: {
      main: GOLD_LIGHT
    },
    GRAY_1: {
      main: GRAY_1,
      contrastText: BLACK
    },
    GRAY_2: {
      main: GRAY_2,
      contrastText: BLACK
    },
    GRAY_3: {
      main: GRAY_3
    },
    GRAY_4: {
      main: GRAY_4
    },
    GRAY_5: {
      main: GRAY_5 // unselected/disabled tag
    },
    GRAY_F4: {
      main: GRAY_F4
    },
    ORANGE_1: {
      main: ORANGE_1
    },
    OUTLINE: {
      main: OUTLINE_GRAY
    },
    TABLE_ROW_ALT: {
      main: TABLE_ROW_ALT
    },
    GRAY_85: {
      main: '#D9D9D9' // lighter than GRAY_2, darker than GRAY_5
    },
    GREEN_1: {
      main: GREEN_1
    },
    BROWN_1: {
      main: '#E8E2D9'
    },
    gold1: {
      main: '#EBDFCD'
    },
    gold2: {
      main: '#D4AD64'
    },
    gold3: {
      main: '#AE8437'
    },
    grey1: {
      main: '#F4F4F4'
    },
    grey3: {
      main: '#5C6B80'
    },
    grey4: {
      main: '#333333'
    },
    RED_1: {
      main: '#FAD1D9'
    },
    red2: {
      main: '#BB1133'
    },
    red3: {
      main: '#990220'
    },
    red4: {
      main: '#710B1B'
    }
  },
  typography: {
    // fontFamily: ['trade-gothic-next, sans-serif'].join(','),
    // MARKETING
    EC_TYPE_BASE_MARKETING: {
      fontFamily: 'Times New Roman',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '22px',
      color: GRAY_4 // Gray-4
    },
    // H1-HEADLINE
    EC_TYPE_6XL: {
      fontFamily: 'Playfair Display, serif',
      fontStyle: 'bold',
      fontWeight: 800,
      fontSize: '54px',
      lineHeight: '72px',
      letterSpacing: '0.03em',
      color: BLACK
    },
    // H2-HEADLINE
    EC_TYPE_5XL: {
      fontFamily: 'Playfair Display, serif',
      fontStyle: 'bold',
      fontWeight: 700,
      fontSize: '48px',
      lineHeight: '60px',
      color: BLUE_1
    },
    // h3-Title
    EC_TYPE_4XL: {
      fontFamily: 'Playfair Display, serif',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '42px',
      lineHeight: '56px',
      letterSpacing: '0.01em',
      color: BLACK,
      marginBottom: '16px'
    },
    // h4-Subhead
    EC_TYPE_3XL: {
      fontFamily: 'trade-gothic-next-condensed',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '38px',
      lineHeight: '47px',
      color: GOLD_1,
      marginBottom: '24px'
    },
    // H5-INTRO
    EC_TYPE_2XL: {
      fontFamily: 'trade-gothic-next-condensed',
      fontStyle: 'condensed',
      fontWeight: 700,
      fontSize: '28px',
      lineHeight: '30px',
      color: GRAY_4
    },
    // TODO: delete this?
    EC_TYPE_LGG: {
      fontSize: '20px',
      fontWeight: 700,
      fontStyle: 'normal',
      lineHeight: '25px',
      color: '#997755'
      // textTransform: 'uppercase',
    },
    // H6-LABELS
    EC_TYPE_XL: {
      fontFamily: 'trade-gothic-next-condensed',
      fontStyle: 'condensed',
      fontWeight: 800,
      fontSize: '18px',
      lineHeight: '22px',
      letterSpacing: '0.05em',
      color: BLACK
    },
    // Section title
    EC_TYPE_LG: {
      fontFamily: 'trade-gothic-next',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '25px',
      color: BLACK
    },
    // Body Default
    EC_TYPE_BASE: {
      fontFamily: 'Barlow, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '21px',
      lineHeight: '26px',
      color: BLACK
    },
    // Body Small
    EC_TYPE_SM: {
      fontFamily: 'trade-gothic-next',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '15px',
      lineHeight: '19px',
      color: BLACK
    },
    // SMALL TITLE
    EC_TYPE_XS: {
      fontFamily: 'trade-gothic-next-condensed',
      fontStyle: 'condensed',
      fontWeight: 800,
      fontSize: '12px',
      letterSpacing: '0.05em',
      lineHeight: '15px',
      color: BLACK
    },
    // Xsmall Title
    EC_TYPE_2XS: {
      fontFamily: 'Barlow, sans-serif',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '15px',
      color: BLACK
    },
    // Tags
    EC_TYPE_3XS: {
      fontFamily: ['trade-gothic-next, sans-serif'].join(','),
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '13px',
      color: BLACK
    },
    // Used in GlobalSolutionsModal.tsx
    EC_TYPE_GS_TITLE: {
      fontFamily: 'Playfair Display, serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '32px',
      lineHeight: '30px',
      letterSpacing: '0em'
    },
    // Used in GlobalSolutionsModal.tsx
    EC_TYPE_GS_BODY: {
      fontFamily: 'Barlow, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '19px',
      lineHeight: '31px',
      letterSpacing: '0em'
    },
    // Used in GlobalSolutionsModal.tsx
    EC_TYPE_GS_BUTTON: {
      fontFamily: 'Barlow, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '19px',
      lineHeight: '25px',
      letterSpacing: '0em'
    },
    AEGS_TYPE_PARAGRAPH: {
      fontFamily: 'Barlow',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '0em'
    },
    body1: {
      fontSize: '16px',
      fontWeight: 400,
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: '0em',
      color: GRAY_4
    },
    button: {
      textTransform: 'none' as const,
      // EC_TYPE_SM
      fontSize: '15px',
      fontWeight: 700,
      fontStyle: 'normal',
      lineHeight: '19px',
      letterSpacing: '0em'
    },
    copyright: {
      fontFamily: 'Barlow, sans-serif',
      fontSize: '11px',
      fontWeight: 400,
      fontStyle: 'normal',
      lineHeight: '18px',
      color: BLACK
    }
  },
  components: {
    MuiAutocomplete: {
      defaultProps: {
        forcePopupIcon: false
      },
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-root': {
            padding: '13px',

            '.MuiAutocomplete-input': {
              padding: '0px'
            }
          },

          '& .MuiInputAdornment-root': {
            '[class*="ri-"]': {
              fontSize: '16px'
            }
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '38px',
          padding: '12px 20px',
          boxShadow: 'none',
          borderRadius: '40px',
          '&:disabled': {
            backgroundColor: OUTLINE_GRAY,
            color: WHITE
          },
          '&.MuiButton-text': {
            padding: '0 1px',
            borderRadius: '4px',
            height: 'unset'
          },
          '&.MuiButton-text:disabled': {
            color: OUTLINE_GRAY,
            backgroundColor: 'transparent'
          },
          '&.disable-while-loading': {
            color: BLUE_1
          },
          '&.MuiButton-containedWHITE': {
            color: BLUE_1,
            ':hover': {
              backgroundColor: BLUE_1,
              color: WHITE,
              outline: `2px solid ${WHITE}`
            }
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '& i': {
            color: 'inherit'
          },
          '&:disabled': {
            color: OUTLINE_GRAY
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          ['@media (max-width:599px)']: {
            margin: '16px',
            width: 'unset'
          }
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '48px',
          paddingTop: '24px',
          ['@media (max-width:599px)']: {
            padding: '24px 16px'
          }
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '48px 48px 32px',
          ['@media (max-width:599px)']: {
            padding: '36px 16px 24px'
          }
        }
      }
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          margin: '24px 0px',
          // EC_TYPE_BASE
          fontSize: '16px',
          fontWeight: 400,
          fontStyle: 'normal',
          lineHeight: '24px',
          letterSpacing: '0em',
          color: BLACK
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '48px 48px 0px',
          // EC_TYPE_LG
          fontSize: '18px',
          fontWeight: 700,
          fontStyle: 'normal',
          lineHeight: '25px',
          letterSpacing: '0em',
          color: BLACK,
          ['@media (max-width:599px)']: {
            padding: '24px 16px 0px'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: WHITE,
          height: '45px',
          fieldset: {
            borderColor: OUTLINE_GRAY
          },

          '& .MuiInputAdornment-root': {
            fontSize: '24px',
            color: GRAY_3
          },

          '&.MuiOutlinedInput-root.Mui-focused': {
            fieldset: {
              borderColor: GOLD_1
            }
          }
        },
        input: {
          height: '19px',
          padding: '13px'
        },
        inputAdornedStart: {
          paddingLeft: '0px'
        },
        inputAdornedEnd: {
          paddingRight: '0px'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: GRAY_4,
          color: WHITE
        },
        arrow: {
          color: GRAY_4
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        sx: {
          '&.MuiOutlinedInput-root': {
            typography: 'EC_TYPE_2XS',
            color: GRAY_3,
            backgroundColor: WHITE,
            height: '45px',
            fieldset: {
              borderColor: OUTLINE_GRAY
            }
          },

          '&.MuiOutlinedInput-root.Mui-focused': {
            fieldset: {
              borderColor: GOLD_1
            }
          }
        }
      }
    },
    MuiMenuItem: {
      defaultProps: {
        sx: {
          typography: 'EC_TYPE_BASE',
          color: GRAY_3,
          // rgba(174, 132, 55, 1) = '#AE8437' = GOLD-3
          '&.Mui-selected': {
            backgroundColor: 'rgba(174, 132, 55, 0.16)'
          },
          '&.Mui-selected:focus': {
            backgroundColor: 'rgba(174, 132, 55, 0.24)'
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'rgba(174, 132, 55, 0.20)'
          },
          ['@media (max-width:600px)']: {
            fontSize: '14px'
          },
          ['@media (max-width:320px)']: {
            fontSize: '12px'
          },
          ['@media (max-width:280px)']: {
            fontSize: '11px'
          }
        }
      }
    },
    MuiStepIcon: {
      defaultProps: {
        sx: {
          color: OUTLINE_GRAY,

          '&.Mui-active': {
            color: GOLD_1
          },

          '& .MuiStepIcon-text': {
            typography: 'EC_TYPE_2XS'
          },

          '&.Mui-completed': {
            color: GRAY_3
          }
        }
      }
    },
    MuiStepLabel: {
      defaultProps: {
        sx: {
          '& .MuiStepLabel-alternativeLabel': {
            typography: 'EC_TYPE_XS',
            color: OUTLINE_GRAY
          },

          '& .MuiStepLabel-label.Mui-active': {
            typography: 'EC_TYPE_XS',
            color: GOLD_1
          },

          '& .MuiStepLabel-label.Mui-completed': {
            typography: 'EC_TYPE_XS',
            color: GRAY_3
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: '75px',
          height: '39px'
        }
      },
      defaultProps: {
        sx: {
          '& .Mui-checked': {
            color: `${GOLD_1} !important`
          },
          '& .MuiSwitch-thumb': {
            borderRadius: '3px'
          },
          '& .MuiSwitch-track': {
            height: '21px',
            transform: 'translateY(-3px)',
            backgroundColor: `${GOLD_1} !important`,
            borderRadius: '3px'
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(37px)'
          }
        }
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          paddingTop: '62px',
          paddingBottom: '24px'
        }
      },
      defaultProps: {
        alternativeLabel: true
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: '1px solid #A6B0BF',
          boxShadow: 'none'
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            minHeight: '48px'
          }
        },
        content: {
          '&.Mui-expanded': {
            margin: '0 0'
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0 16px 6px'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: BLACK,
          fontWeight: 700,
          fontSize: '12px',
          lineHeight: '15px'
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: GOLD_1
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '25px',

          '& .MuiButtonBase-root': {
            padding: '0',
            minHeight: '25px',
            minWidth: 'unset',
            marginRight: '36px',
            fontWeight: '700',
            fontSize: '15px',
            '&:last-child': {
              marginRight: '0'
            },
            ['@media (max-width:360px)']: {
              fontSize: '14px'
            }
          }
        },
        indicator: {
          backgroundColor: GOLD_1
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          '& .Mui-selected': {
            backgroundColor: `${GRAY_1} !important`
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #F4F4F4', // Gray_1
          padding: '18.5px',
          paddingLeft: '0px',
          // EC_TYPE_2XS
          fontSize: '12px',
          fontWeight: 700,
          fontStyle: 'normal',
          lineHeight: '15px',
          letterSpacing: '0em',
          color: BLACK
        },
        head: {
          borderBottom: '1px solid #A6B0BF', // Gray_2
          padding: '17.5px',
          paddingLeft: '0px',
          fontSize: '12px',
          fontFamily: 'trade-gothic-next-condensed',
          fontWeight: 800,
          letterSpacing: '0.05em',
          fontStyle: 'normal',
          lineHeight: '15px',
          color: '#5C6B80' // Gray-3
          // textTransform: 'uppercase',
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          EC_TYPE_BASE_MARKETING: 'p',
          EC_TYPE_6XL: 'h1',
          EC_TYPE_5XL: 'h2',
          EC_TYPE_4XL: 'h3',
          EC_TYPE_3XL: 'h4',
          EC_TYPE_2XL: 'h5',
          EC_TYPE_XL: 'h6',
          EC_TYPE_LG: 'p',
          EC_TYPE_BASE: 'p',
          EC_TYPE_SM: 'p',
          EC_TYPE_2XS: 'p',
          EC_TYPE_3XS: 'p',
          EC_TYPE_LGG: 'h3',
          EC_TYPE_GS_TITLE: 'p',
          EC_TYPE_GS_BODY: 'p',
          AEGS_TYPE_PARAGRAPH: 'p'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0px 5px rgba(51, 51, 51, 0.4)'
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        toolbarContainer: {
          backgroundColor: '#F4F4F4',
          justifyContent: 'space-between',
          paddingLeft: '26px',
          paddingRight: '16px'
        },
        columnHeaderTitleContainer: {
          paddingLeft: 0
        },
        columnHeader: {
          paddingLeft: '29px'
        },
        columnHeaderTitle: {
          whiteSpace: 'normal',
          fontSize: '15px',
          fontWeight: 700,
          fontStyle: 'normal',
          lineHeight: '19px',
          letterSpacing: '0em',
          color: BLACK
        },
        row: {
          fontSize: '16px',
          fontWeight: 400,
          fontStyle: 'normal',
          lineHeight: '24px',
          letterSpacing: '0em',
          color: BLACK
        },
        cell: {
          paddingLeft: '29px',
          '&:focus': {
            outline: 'none !important',
            border: 0
          }
        },
        columnSeparator: {
          '&:last-child': {
            display: 'none'
          }
        }
      }
    }
  }
};

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    headerMobile: true;
  }

  interface TypographyVariants {
    EC_TYPE_BASE_MARKETING: React.CSSProperties;
    EC_TYPE_6XL: React.CSSProperties;
    EC_TYPE_5XL: React.CSSProperties;
    EC_TYPE_4XL: React.CSSProperties;
    EC_TYPE_3XL: React.CSSProperties;
    EC_TYPE_2XL: React.CSSProperties;
    EC_TYPE_XL: React.CSSProperties;
    EC_TYPE_LG: React.CSSProperties;
    EC_TYPE_BASE: React.CSSProperties;
    EC_TYPE_SM: React.CSSProperties;
    EC_TYPE_XS: React.CSSProperties;
    EC_TYPE_2XS: React.CSSProperties;
    EC_TYPE_3XS: React.CSSProperties;
    EC_TYPE_LGG: React.CSSProperties;
    EC_TYPE_GS_TITLE: React.CSSProperties;
    EC_TYPE_GS_BODY: React.CSSProperties;
    AEGS_TYPE_PARAGRAPH: React.CSSProperties;
    copyright: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    EC_TYPE_BASE_MARKETING?: React.CSSProperties;
    EC_TYPE_6XL?: React.CSSProperties;
    EC_TYPE_5XL?: React.CSSProperties;
    EC_TYPE_4XL?: React.CSSProperties;
    EC_TYPE_3XL?: React.CSSProperties;
    EC_TYPE_2XL?: React.CSSProperties;
    EC_TYPE_XL?: React.CSSProperties;
    EC_TYPE_LG?: React.CSSProperties;
    EC_TYPE_BASE?: React.CSSProperties;
    EC_TYPE_SM?: React.CSSProperties;
    EC_TYPE_XS?: React.CSSProperties;
    EC_TYPE_2XS?: React.CSSProperties;
    EC_TYPE_3XS?: React.CSSProperties;
    EC_TYPE_LGG?: React.CSSProperties;
    EC_TYPE_GS_TITLE?: React.CSSProperties;
    EC_TYPE_GS_BODY?: React.CSSProperties;
    AEGS_TYPE_PARAGRAPH?: React.CSSProperties;
    copyright?: React.CSSProperties;
  }

  interface Palette {
    neutral: Palette['primary'];
    BLACK: Palette['primary'];
    WHITE: Palette['primary'];
    GOLD_1: Palette['primary'];
    GOLD_2: Palette['primary'];
    GOLD_LIGHT: Palette['primary'];
    GRAY_1: Palette['primary'];
    GRAY_2: Palette['primary'];
    GRAY_3: Palette['primary'];
    GRAY_4: Palette['primary'];
    GRAY_5: Palette['primary'];
    GRAY_85: Palette['primary'];
    GRAY_F4: Palette['primary'];
    OUTLINE: Palette['primary'];
    ORANGE_1: Palette['primary'];
    GREEN_1: Palette['primary'];
    BROWN_1: Palette['primary'];
    TABLE_ROW_ALT: Palette['primary'];
    gold1: Palette['primary'];
    gold2: Palette['primary'];
    gold3: Palette['primary'];
    grey1: Palette['primary'];
    grey3: Palette['primary'];
    grey4: Palette['primary'];
    error: Palette['primary'];
    RED_1: Palette['primary'];
    red2: Palette['primary'];
    red3: Palette['primary'];
    red4: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
    BLACK?: PaletteOptions['primary'];
    WHITE?: PaletteOptions['primary'];
    GOLD_1?: PaletteOptions['primary'];
    GOLD_2?: PaletteOptions['primary'];
    GOLD_LIGHT?: PaletteOptions['primary'];
    GRAY_1?: PaletteOptions['primary'];
    GRAY_2?: PaletteOptions['primary'];
    GRAY_3?: PaletteOptions['primary'];
    GRAY_4?: PaletteOptions['primary'];
    GRAY_5?: PaletteOptions['primary'];
    GRAY_85?: PaletteOptions['primary'];
    GRAY_F4?: PaletteOptions['primary'];
    ORANGE_1?: PaletteOptions['primary'];
    OUTLINE?: PaletteOptions['primary'];
    GREEN_1?: PaletteOptions['primary'];
    BROWN_1?: PaletteOptions['primary'];
    TABLE_ROW_ALT?: PaletteOptions['primary'];
    gold1?: PaletteOptions['primary'];
    gold2?: PaletteOptions['primary'];
    gold3?: PaletteOptions['primary'];
    grey1?: PaletteOptions['primary'];
    grey3?: PaletteOptions['primary'];
    grey4?: PaletteOptions['primary'];
    error?: PaletteOptions['primary'];
    RED_1?: PaletteOptions['primary'];
    red2?: PaletteOptions['primary'];
    red3?: PaletteOptions['primary'];
    red4?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
    BLACK: true;
    WHITE: true;
    GOLD_1: true;
    GRAY_1: true;
    GRAY_2: true;
    GRAY_3: true;
    GRAY_4: true;
    GREEN_1: true;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    EC_TYPE_BASE_MARKETING: true;
    EC_TYPE_6XL: true;
    EC_TYPE_5XL: true;
    EC_TYPE_4XL: true;
    EC_TYPE_3XL: true;
    EC_TYPE_2XL: true;
    EC_TYPE_XL: true;
    EC_TYPE_LG: true;
    EC_TYPE_BASE: true;
    EC_TYPE_SM: true;
    EC_TYPE_XS: true;
    EC_TYPE_2XS: true;
    EC_TYPE_3XS: true;
    EC_TYPE_GS_TITLE: true;
    EC_TYPE_GS_BODY: true;
    EC_TYPE_LGG: true;
    AEGS_TYPE_PARAGRAPH: true;
    copyright: true;
  }
}

export default createTheme(theme);
