import * as React from 'react';
import { Button } from '@mui/material';
import InviteModal from '@components/InviteModal';
import { styled } from '@mui/system';

const Styled = {
  Btn: styled(Button)({
    minWidth: '130px',
    padding: '2px',
    fontSize: '10px'
  })
};

interface Props {
  modalOpen: boolean;
  candidateId: number;
  handleClose: () => void;
  onSuccess: () => void;
  handleOpen: () => void;
}

const EmployerInviteResumeButtons = ({
  modalOpen,
  candidateId,
  handleClose,
  onSuccess,
  handleOpen
}: Props): React.ReactElement => {
  return (
    <>
      <Styled.Btn fullWidth variant="outlined" onClick={handleOpen}>
        Invite to Apply
      </Styled.Btn>
      <InviteModal
        open={modalOpen}
        candidateId={candidateId}
        handleClose={handleClose}
        onSuccess={onSuccess}
      />
    </>
  );
};

export default EmployerInviteResumeButtons;
