import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { CandidateNotesInterface } from '@api/models/careerServicesApi.models';
import { CareerServicesApi } from '@api/CareerService.api';
import { getCurrentTimezoneDateTime } from '@common/helpers/dateHelpers/getCurrentTimezoneDateTime';
import ReactHookFormsTextarea from '@components/Forms/ReactHookFormsTextarea';
import { styled } from '@mui/system';

const Styled = {
  Box: styled(Box)(({ theme }) => ({
    padding: '36px 36px 0 36px',
    backgroundColor: theme.palette.WHITE.main,
    boxShadow: '0px 0px 5px rgba(51,51,51,0.4)',
    ['@media (max-width:1000px)']: {
      transform: 'none'
    }
  })),
  Button: styled(Button)({
    margin: '20px 0',
    textAlign: 'right'
  })
};

interface FormValues {
  notes: string;
}

interface Props {
  notes: CandidateNotesInterface;
  candidateId: string;
  refetch: () => {};
}
const NotesSection: React.FC<Props> = ({ notes, candidateId, refetch }) => {
  const [lastUpdatedDate, setLastUpdatedDate] = React.useState(notes?.date);

  const methods = useForm<FormValues>({
    defaultValues: {
      notes: notes.note
    }
  });

  const { handleSubmit } = methods;

  const handleValidForm = async (data: FormValues): Promise<void> => {
    const postBody = {
      note: data.notes
    };

    try {
      const res = await CareerServicesApi.addCandidateNotes(
        candidateId,
        postBody
      );
      refetch();
      setLastUpdatedDate(res.date);
    } catch (error) {
      console.error('Error in InviteModal.inviteCandidate', error);
    }
  };

  const lastUpdated = (): JSX.Element =>
    notes.user ? (
      <Typography variant="copyright">
        Last updated {getCurrentTimezoneDateTime(lastUpdatedDate.toString())} -{' '}
        {notes?.user}
      </Typography>
    ) : (
      <Typography />
    );

  return (
    <Box>
      <Typography variant="EC_TYPE_3XL">Notes</Typography>
      <Styled.Box>
        <FormProvider {...methods}>
          <ReactHookFormsTextarea
            name="notes"
            rows={15}
            required
            dataTestId="studen-notes-section"
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {lastUpdated()}
            <Styled.Button
              variant="contained"
              onClick={handleSubmit(handleValidForm)}
            >
              Save
            </Styled.Button>
          </Box>
        </FormProvider>
      </Styled.Box>
    </Box>
  );
};

export default NotesSection;
