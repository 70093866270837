import { MenuItem, Select } from '@mui/material';
import React, { Dispatch, memo, SetStateAction } from 'react';
import { CompanyStatus } from '@api/models/adminDashboardApi.models';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { WHITE } from '@themes/ui/escoffier';

interface Props {
  value: number;
  companyId: number;
  display?: string;
  settings: { label: string; value: string | number }[];
  onSelect: (
    companyId: number,
    value: any,
    callback: Dispatch<SetStateAction<CompanyStatus[]>>
  ) => any;
  setCompanies: Dispatch<SetStateAction<CompanyStatus[]>>;
}

const Dropdown = memo(
  ({
    companyId,
    display,
    settings,
    value,
    onSelect,
    setCompanies
  }: Props): React.ReactElement => {
    return (
      <Select
        onChange={(e): void =>
          onSelect(companyId, e.target.value, setCompanies)
        }
        value={value}
        renderValue={(): JSX.Element => {
          return <>{display}</>;
        }}
        sx={{
          height: '30px',
          flex: '1 0 120px',
          fontSize: '12px',
          lineHeight: 'unset',
          backgroundColor: WHITE
        }}
        data-testid={`renderDropdown-select-${companyId}`}
      >
        {settings.map((option) => (
          <MenuItem
            value={option.value}
            key={option.value}
            data-testid="renderDropdownMenuItem"
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    );
  }
);

Dropdown.displayName = 'Dropdown';

export const renderDropdown = (
  onSelect: Props['onSelect'],
  setCompanies: Dispatch<SetStateAction<CompanyStatus[]>>,
  params: GridRenderCellParams<any, any, any>
): JSX.Element => {
  if (!params.row.enabled) {
    return <></>;
  }
  return (
    <Dropdown
      onSelect={onSelect}
      setCompanies={setCompanies}
      value={params.row.value}
      companyId={params.id as number}
      display={params.value}
      settings={params.row.settings}
    />
  );
};
