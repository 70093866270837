import * as React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import FormFieldErrorText from '@components/Forms/FormFieldErrorText';

interface Props {
  name: string;
  label: string;
  rules?: RegisterOptions;
  disabled?: boolean;
}

const RhfCheckbox: React.FC<Props> = ({
  name,
  label,
  rules,
  disabled = false
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...rules }}
      render={({ field: { onChange, value } }): JSX.Element => (
        <>
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                disabled={disabled}
                onChange={onChange}
              />
            }
            label={label}
          />
          {errors[name] && (
            <FormFieldErrorText
              name={name}
              message={errors[name].message}
              disableAbsolutePosition
            />
          )}
        </>
      )}
    />
  );
};

export default RhfCheckbox;
