import * as React from 'react';
import { Button, Typography } from '@mui/material';
import googleBtnDisable from '@assets/google_logo_disable.png';
import googleBtnEnable from '@assets/google_logo_enable.png';
import { IKeycloakUser } from '@api/models/userApi.models';
import { styled } from '@mui/system';
import { useKeycloak } from '@react-keycloak/web';

const Styled = {
  Button: styled(Button)({
    borderBottom: '1px solid gray',
    cursor: 'pointer',
    '&:disabled': {
      background: 'transparent'
    }
  }),
  GoogleLogo: styled('img')({
    marginRight: '32px'
  })
};

interface Props {
  user: IKeycloakUser;
}

const GoogleButton = (props: Props): React.ReactElement => {
  const { user } = props;
  const { keycloak } = useKeycloak();

  const redirectToGoogleSSO = (): void => {
    const url = keycloak.createLoginUrl({ idpHint: 'google' });
    window.location.href = url;
  };
  return (
    <Styled.Button onClick={redirectToGoogleSSO} disabled={!!user}>
      <Styled.GoogleLogo src={!user ? googleBtnEnable : googleBtnDisable} />
      <Typography variant="EC_TYPE_XL">Continue with Google</Typography>
    </Styled.Button>
  );
};

export default GoogleButton;
