import {
  CareerPathRole,
  CareerTrack
} from '@interfaces/careerJourney.interfaces';
import { api } from './configs/base';
import { apiConfig } from './configs/api.config';
import { ApiResponse } from './models/base.models';

export class CareerApi {
  static async getCareerRoles(): Promise<ApiResponse<CareerPathRole[]>> {
    try {
      const response = await api.get<ApiResponse<CareerPathRole[]>>(
        `${apiConfig.careerUrl}/roles`
      );

      return response.data;
    } catch (error) {
      console.error(`Error for CareerApi.getCareerRoles(): ${error}`);
      throw error;
    }
  }

  static async getCareerRoleById(
    id: number
  ): Promise<ApiResponse<CareerPathRole>> {
    try {
      const response = await api.get<ApiResponse<CareerPathRole>>(
        `${apiConfig.careerUrl}/role/${id}`
      );
      return response.data;
    } catch (error) {
      console.error(`Error for CareerApi.getCareerRoleById(): ${error}`);
      throw error;
    }
  }

  static async getCareerTracks(): Promise<ApiResponse<CareerPathRole[]>> {
    try {
      const response = await api.get<ApiResponse<CareerPathRole[]>>(
        `${apiConfig.careerUrl}/tracks`
      );
      return response.data;
    } catch (error) {
      console.error(`Error for CareerApi.getCareerTracks(): ${error}`);
      throw error;
    }
  }

  static async getCareerTrackById(
    id: number
  ): Promise<ApiResponse<CareerTrack>> {
    try {
      const response = await api.get<ApiResponse<CareerTrack>>(
        `${apiConfig.careerUrl}/track/${id}`
      );
      return response.data;
    } catch (error) {
      console.error(`Error for CareerApi.getCareerTrackById(): ${error}`);
      throw error;
    }
  }
}
