export interface NotificationsPutReq {
  employerId?: number;
  type: string;
  isEnabled: boolean;
  userId: string;
}

export interface Notifications {
  type: string;
  isEnabled: boolean;
}

export enum UserNotificationType {
  NEW_CANDIDATE_NOTIFICATION = 'NEW_CANDIDATE',
  NEW_EMPLOYER_NOTIFICATION = 'NEW_EMPLOYER'
}
