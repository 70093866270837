import { Dispatch, SetStateAction } from 'react';
import { JobPost, PromotedJob } from '@api/models/singleJobPostApi.models';
import { BadgeCodebook } from './codebook.models';
import { CandidateProfile } from '@api/models/candidateApi.models';
import { GlrcContent } from '@interfaces/glrc.interfaces';
import { IExpandedFilters } from '@common/fetches/useFetchMarketplaceSearchResults';
import { IPageInfoRes } from '@interfaces/pageInfo.interfaces';
import { Location } from '@interfaces/location.interfaces';

export enum FilterType {
  Checkbox = 0,
  Distance = 1,
  SearchBar = 2
}

export enum FilterGroupNames {
  LOCATION = 'Location',
  JOB_TYPE = 'Job Type',
  WORK_LEARN = 'Work & Learn',
  SEGMENT = 'Segment',
  SPECIALTY = 'Specialty',
  COMPANY = 'Company',
  SEARCH_BAR = 'SearchBar'
}

export interface IFilterInput {
  displayValue: string;
  fieldName: string;
  value: any;
  selected: boolean;
  type: FilterType;
}

export interface FilterDistanceValue {
  location: Location;
  distance: number;
}

export interface IFilterData {
  fieldName: string;
  selected: boolean;
  value: any;
}

export interface IFilterGroup {
  groupName: string;
  filters: IFilterInput[];
  filterGroups: IFilterGroup[];
}

export interface IFilterState {
  [key: string]: any;
}

export interface SearchCriteria {
  pageInfo: IPageInfoRes;
  filters: IFilterData[];
}

export interface SearchEsourceCriteria {
  page: number;
  searchValue: string;
  selectedTypes: string[];
}

export interface SearchEsourceResult {
  result: GlrcContent[];
  total: number;
}

export interface SearchResult<ResultType> {
  pageInfo: IPageInfoRes;
  filterGroups: IFilterGroup[];
  results: ResultType[];
}

export type MarketplaceSortByOptions =
  | 'DatePostedDescending'
  | 'DatePostedAscending'
  | 'DateUpdatedDescending'
  | 'DateUpdatedAscending'
  | 'CandidateNameDescending'
  | 'CandidateNameAscending'
  | 'JobPostTitleDescending'
  | 'JobPostTitleAscending';

export type SearchResultType = JobPost | CandidateProfile;

export interface FetchSearchResults<T extends SearchResultType> {
  searchData: T[];
  pageInfo: IPageInfoRes;
  filters: IFilterGroup[];
  loading: boolean;
  loaded: boolean;
  filterMarketplaceResults: (
    filterData: IFilterData[],
    resetExpandedFilters?: boolean
  ) => void;
  resetMarketplaceResults: (resetExpandedFilters?: boolean) => void;
  fetchSearchData: (
    filterData: IFilterData[],
    pageInfo?: IPageInfoRes | null
  ) => Promise<void>;
  expandedFilters: IExpandedFilters;
  setExpandedFilters: Dispatch<SetStateAction<IExpandedFilters>>;
  filterFormState: IFilterState;
  setFilterFormState: Dispatch<SetStateAction<IFilterState>>;
  sortResults: (event, category: string) => void;
  goToPage: (pageNumber: number) => void;
}

export interface PromotedCompanySpotlight {
  id: number;
  name: string;
  imageUrl: string;
  badge?: BadgeCodebook;
  description?: string;
}

export interface PromotedContentRes {
  promotedJobs: PromotedJob[];
  employerSpotlight: PromotedCompanySpotlight[];
}
