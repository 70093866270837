import { FieldValues, FormState } from 'react-hook-form';

interface FieldErrors {
  type: string;
  message: string;
  ref: any;
}

/**
 * Gets the error object, if it exists, from a React Hook Form component with the name passed to this function.
 * Use when formatting the name to work with a fieldArray (ex: education.0.graduationType)
 * @param name Name given to the RHF component
 * @param errors Errors taken from RHF formState
 * @param useFieldArrayNaming boolean to determine if fieldArray naming should be used (ex: education.0.graduationType)
 * @returns the error object, if it exists, for the given name
 */
export const useGetReactHookFormErrorsByName = (
  name: string,
  errors: FormState<FieldValues>['errors'],
  useFieldArrayNaming: boolean,
  fieldPrefix?: string
): { errorsAtName: FieldErrors } => {
  let arrayName: string;
  let idxString: string;
  let fieldName: string;

  let errorsAtName = errors[name];

  if (useFieldArrayNaming) {
    if (fieldPrefix) {
      [fieldPrefix, arrayName, idxString, fieldName] = name.split('.');
      errorsAtName =
        errors?.[fieldPrefix]?.[arrayName]?.[parseInt(idxString)]?.[fieldName];
    } else {
      [arrayName, idxString, fieldName] = name.split('.');
      errorsAtName = errors?.[arrayName]?.[parseInt(idxString)]?.[fieldName];
    }
  }

  return { errorsAtName };
};
