import { COMPANY_STATUS, CompanyProfile } from '@api/models/companyApi.models';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { CompanyApi } from '@api/Company.api';

type FetchCompanyProfile = {
  companyProfile: CompanyProfile | null;
  setCompanyProfile: Dispatch<SetStateAction<CompanyProfile | null>>;
  profileComplete: boolean;
  loading: boolean;
  loaded: true | null;
};

/**
 * Fetch CompanyProfile from CompanyApi.getCompanyDetailsById()
 *
 * @param {number} id companyId
 * @returns `{ companyProfile: CompanyProfile; profileComplete: boolean; loading: boolean; }`
 */
export const useFetchCompanyProfile = (
  companyId?: number
): FetchCompanyProfile => {
  const [companyProfile, setCompanyProfile] = useState<CompanyProfile | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  useEffect(() => {
    if (companyId) {
      const fetchProfile = async (): Promise<void> => {
        try {
          setLoading(true);
          const res = await CompanyApi.getCompanyDetailsById(companyId);
          setCompanyProfile(res.data);
        } catch (error: any) {
          console.error(
            'Error for useFetchCompanyProfile -> CompanyApi.getById()',
            error
          );
        } finally {
          setLoading(false);
          setLoaded(true);
        }
      };

      fetchProfile();
    } else {
      setLoaded(true);
      setCompanyProfile(null);
    }
  }, [companyId]);

  const profileComplete: boolean = useMemo(() => {
    if (companyProfile) {
      return companyProfile.status === COMPANY_STATUS.COMPLETED;
    }

    return false;
  }, [companyProfile]);

  return {
    companyProfile,
    setCompanyProfile,
    profileComplete,
    loading,
    loaded
  };
};
