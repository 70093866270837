import { useEffect, useState } from 'react';
import { AttestationEmploymentType } from '@interfaces/attestation.interface';
import { Codebook } from '@api/models/codebook.models';
import { CodebookApi } from '@api/Codebook.api';

export type FetchAttestationEmploymentTypeCodebooks = {
  attestationEmploymentTypes: Codebook<AttestationEmploymentType>[];
  loading: boolean;
  loaded: boolean;
};

export const useFetchAttestationEmploymentTypesCodebooks =
  (): FetchAttestationEmploymentTypeCodebooks => {
    const [attestationEmploymentTypes, setAttestationEmploymentTypes] =
      useState<Codebook<AttestationEmploymentType>[]>([]);

    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      const fetchData = async (): Promise<void> => {
        try {
          setLoading(true);
          const res = await CodebookApi.getAttestationEmploymentTypes();
          setAttestationEmploymentTypes(res.data);
          setLoaded(true);
        } catch (error: any) {
          console.error(
            'Error for useFetchAttestationEmploymentTypesCodebooks -> CodebookApi.getAttestationEmploymentTypes()',
            error
          );
        } finally {
          setLoading(false);
          setLoaded(true);
        }
      };

      fetchData();
    }, []);

    return {
      attestationEmploymentTypes,
      loading,
      loaded
    };
  };
