export const durationMask = (value: string): string => {
  // Only allow numbers and colon, all other characters will be removed
  const onlyValidChars = value.replace(/[^0-9:]/g, '');

  // Remove colon if it is at beginning of string
  const withoutStartingColon = onlyValidChars.replace(/^:/, '');

  // Remove second occurance of colon if it exists
  const withoutSecondColon = withoutStartingColon.replace(/^(.*?:.*?):/, '$1');

  let time = withoutSecondColon;

  // If no colon exists and string is 3 characters then add colon before last number
  if (!withoutSecondColon.includes(':')) {
    if (withoutSecondColon.length === 3) {
      const lastCharacter = withoutSecondColon.slice(-1);
      // Extract the substring excluding the last character
      const substring = withoutSecondColon.slice(0, -1);
      // Concatenate the substring, colon, and last character
      const colonAdded = substring + ':' + lastCharacter;
      time = colonAdded;
    }
  }

  let timeToReturn = time;

  // Hours portion should not exceed 3 characters
  // Minutes portion should not exceed 3 characters
  const [hours, mins] = timeToReturn.split(':');

  if (hours) {
    timeToReturn = hours.substring(0, 2);
  }

  if (time.includes(':')) {
    timeToReturn += ':';
    if (mins) {
      timeToReturn += mins.substring(0, 2);
    }
  }

  return timeToReturn;
};
