import * as React from 'react';
import {
  SetLoginSettings,
  UserLoginSettings
} from '@api/models/settingsApi.models';
import ChangeLoginOptions from '@components/AccountSettings/ChangeLoginOptions';
import ChangePassword from '@components/AccountSettings/ChangePassword';
import DeactivateAccount from '@components/AccountSettings/DeactivateAccount';
import { IKeycloakUser } from '@api/models/userApi.models';
import StepSection from '@components/StepSection';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';

interface Props {
  user: IKeycloakUser;
  isUserSSO: boolean;
  isCandidate: boolean;
  isCompany: boolean;
  isGlrcUser: boolean;
  isEcCareerService: boolean;
  isChefInstructor: boolean;
  isEcAdmin: boolean;
  setLoginSettings: SetLoginSettings;
  loginSettings?: UserLoginSettings;
}

const AccountSettings: React.FC<Props> = ({
  user,
  isCandidate,
  isCompany,
  isGlrcUser,
  isEcCareerService,
  isChefInstructor,
  isEcAdmin,
  isUserSSO,
  setLoginSettings,
  loginSettings
}) => {
  const [candidateId] = useStore<number | undefined>(UseStoreKeys.CANDIDATE_ID);
  const featureFlags = useFeatureFlags();

  return (
    <StepSection title="Personal Information">
      {!isEcCareerService && (
        <ChangeLoginOptions
          isCandidate={isCandidate}
          isCompany={isCompany}
          isGlrcUser={isGlrcUser}
          isEcAdmin={isEcAdmin}
          isEcCareerService={isEcCareerService}
          isChefInstructor={isChefInstructor}
          user={user}
          isUserSSO={isUserSSO}
          loginSettings={loginSettings}
          setLoginSettings={setLoginSettings}
        />
      )}
      {!isUserSSO && <ChangePassword user={user} />}
      {featureFlags.CANDIDATE_DEACTIVATE && isCandidate && !!candidateId && (
        <DeactivateAccount candidateId={candidateId} />
      )}
    </StepSection>
  );
};

export default AccountSettings;
