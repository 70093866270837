import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { FOOTER_HEIGHT } from '@themes/ui/variables';
import { IdentityProviders } from '@api/models/userApi.models';
import { KeycloakUserRole } from '@api/models/userApi.models';
import { styled } from '@mui/system';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';

const Container = styled(Box)({
  display: 'flex',
  width: '100vw',
  height: `calc(100vh - ${FOOTER_HEIGHT}px)`,
  justifyContent: 'center',
  alignItems: 'center'
});

interface IProtectedRouteProps extends RouteProps {
  path: string;
}

const RedirectDashboardRoute: React.FC<IProtectedRouteProps> = ({ path }) => {
  const [route, setRoute] = React.useState<string | null>(null);

  const [isParentCompany] = useStore<boolean>(UseStoreKeys.IS_PARENT_COMPANY);
  const [userIdpArray] = useStore<string[]>(UseStoreKeys.USER_IDP_ARRAY);

  const isStudentPortalIdp = userIdpArray?.includes(
    IdentityProviders.STUDENT_PORTAL
  );

  const isDev = process.env.REACT_APP_FEATURE_FLAG_ENV === 'dev';

  const {
    isAdmin,
    isCandidate,
    isEcAdmin,
    isEcCareerService,
    isChefInstructor,
    isExternshipTimesheetApprover: isEcApprover,
    isEmployer,
    isRecruiter,
    isPartner,
    isGlrcUser,
    isPendingUser
  } = useKeycloakContext();

  React.useEffect(() => {
    if (isPendingUser) {
      setRoute('/pending-user/dashboard');
    } else if (isEmployer || isRecruiter) {
      setRoute(isParentCompany ? '/parent/dashboard' : '/employer/dashboard');
    } else if (isCandidate) {
      setRoute('/candidate/dashboard');
    } else if (isEcAdmin) {
      setRoute('/admin/dashboard');
    } else if (isEcCareerService) {
      setRoute(isDev ? '/career-services/dashboard' : '/externship-tracker');
    } else if (isChefInstructor) {
      setRoute('/chef-instructor/dashboard');
    } else if (isAdmin) {
      setRoute('');
    } else if (isPartner || isGlrcUser) {
      setRoute('/learning-center');
    } else if (isStudentPortalIdp) {
      setRoute(`/create-account?type=${KeycloakUserRole.CANDIDATE}`);
    } else if (isEcApprover) {
      setRoute('/approver/dashboard');
    } else {
      setRoute('/create-account');
    }
  }, []);

  return (
    <>
      {route ? (
        <Route exact path={path}>
          <Redirect to={`${route}`} />
        </Route>
      ) : (
        <Container>
          <CircularProgress color="secondary" size={80} />
        </Container>
      )}
    </>
  );
};

export default RedirectDashboardRoute;
