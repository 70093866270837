export enum Permission {
  ADMIN_COMPANIES_VIEW = 'ADMIN_COMPANIES_VIEW',
  ADMIN_COMPANIES_EDIT = 'ADMIN_COMPANIES_EDIT',
  ADMIN_DASHBOARD_VIEW = 'ADMIN_DASHBOARD_VIEW',
  ADMIN_CONTENT_MGT_VIEW = 'ADMIN_CONTENT_MGT_VIEW',
  ADMIN_CONTENT_MGT_EDIT = 'ADMIN_CONTENT_MGT_EDIT',

  CAREER_MARKETPLACE_VIEW = 'CAREER_MARKETPLACE_VIEW',

  COMPANY_PROFILE_VIEW = 'COMPANY_PROFILE_VIEW',
  COMPANY_PROFILE_EDIT = 'COMPANY_PROFILE_EDIT',
  COMPANY_DASHBOARD_VIEW = 'COMPANY_DASHBOARD_VIEW',

  JOB_POST_CREATE = 'JOB_POST_CREATE',
  JOB_POST_EDIT = 'JOB_POST_EDIT',
  JOB_POST_VIEW = 'JOB_POST_VIEW',

  TALENT_SEARCH_VIEW = 'TALENT_SEARCH_VIEW',

  CANDIDATE_PROFILE_VIEW = 'CANDIDATE_PROFILE_VIEW',
  CANDIDATE_PROFILE_EDIT = 'CANDIDATE_PROFILE_EDIT',

  CAREER_QUIZ_VIEW = 'CAREER_QUIZ_VIEW',
  CAREER_JOURNEY_VIEW = 'CAREER_JOURNEY_VIEW',

  PARTNER_SIGN_IN = 'PARTNER_SIGN_IN',
  PARTNER_GLRC_VIEW = 'PARTNER_GLRC_VIEW',

  EXTERNSHIP_VERIFICATION = 'EXTERNSHIP_VERIFICATION',
  EXTERNSHIP_TIMESHEET_READ = 'EXTERNSHIP_TIMESHEET_READ',

  CAREER_SERVICE_DASHBOARD_VIEW = 'CAREER_SERVICE_DASHBOARD_VIEW',
  CAREER_SERVICE_DASHBOARD_EDIT = 'CAREER_SERVICE_DASHBOARD_EDIT',

  CHEF_INSTRUCTOR_DASHBOARD_VIEW = 'CHEF_INSTRUCTOR_DASHBOARD_VIEW',
  CHEF_INSTRUCTOR_DASHBOARD_EDIT = 'CHEF_INSTRUCTOR_DASHBOARD_EDIT',

  CAREER_SERVICE_APPROVALS_VIEW = 'CAREER_SERVICE_APPROVALS_VIEW',

  ATTESTATION_SUBMIT = 'ATTESTATION_SUBMIT'
}
