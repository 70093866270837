import * as React from 'react';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import { CodebookCountInterface } from '@api/models/codebook.models';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const Styled = {
  Root: styled(Box)({
    display: 'flex',
    columnGap: '16px'
  }),
  Title: styled(Typography)({
    color: theme.palette.GRAY_4.main,
    marginBottom: '0px'
  })
};

interface Props {
  statuses: CodebookCountInterface[];
  handleStatusChange: (e: SelectChangeEvent<number>) => void;
  selectedStatus: number;
}

const StatusSelectionHeader: React.FC<Props> = ({
  statuses,
  handleStatusChange,
  selectedStatus
}) => {
  return (
    <Styled.Root>
      <Styled.Title variant="EC_TYPE_3XL">Not Hired:</Styled.Title>
      <FormControl>
        <Select
          value={selectedStatus}
          onChange={handleStatusChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Not Hired Reason' }}
        >
          {statuses.map((s) => (
            <MenuItem key={s.value} value={s.value}>
              {s.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Styled.Root>
  );
};

export default StatusSelectionHeader;
