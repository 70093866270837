/* eslint-disable @typescript-eslint/indent */
import { CompanyProfile } from '@api/models/companyApi.models';
import { JobPost } from '@api/models/singleJobPostApi.models';

// PreviewPageCompanyDetails
export const companyDetailsPropArray = [
  'name',
  'linkedInUrl',
  'instagramUrl',
  'facebookUrl',
  'websiteUrl',
  'logo',
  'badge'
] as const;

type CompanyDetailsType = typeof companyDetailsPropArray;
export type PreviewPageCompanyDetailsProps = Pick<
  CompanyProfile,
  CompanyDetailsType[number]
>;

// TabsPanelsEmployer
export const tabPanelCompanyPropArray = [
  'companyOverview',
  'companyMission',
  'companyVision',
  'companyValues',
  'companyCulture',
  'companyHealthSafeties'
] as const;

export const tabPanelBenefitsPropArray = [
  'healthWellnessBenefits',
  'retirementBenefits',
  'professionalDevBenefits'
] as const;

type TabsPanelsBenefitsType = typeof tabPanelBenefitsPropArray;
export type TabsPanelsBenefitsProps = Pick<
  JobPost,
  TabsPanelsBenefitsType[number]
>;

type TabsPanelsCompanyType = typeof tabPanelCompanyPropArray;
export type TabsPanelsCompanyProps = Pick<
  CompanyProfile,
  TabsPanelsCompanyType[number]
>;
