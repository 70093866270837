/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  Box,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import DashboardTableLayout, {
  DashboardTableLayoutProps
} from '@components/TableComponents/DashboardTableLayout';
import DashboardLoading from '@components/TableComponents/DashboardLoading';
import ExternalEduLink from '@components/ExternalEduLink';
import ExternshipsSection from './ExternshipsSection/ExternshipsSection';
import { getEmptyTableRows } from '@common/helpers/getEmptyTableRows';
import { GraduationTypes } from '@api/models/adminDashboardApi.models';
import { JobApplied } from '@api/models/jobApplicationApi.models';
import NoDataMessage from '@components/NoDataMessage';
import { Link as RouterLink } from 'react-router-dom';
import { sortTableData } from '@common/helpers/sortByCategory';
import { styled } from '@mui/system';
import { TableSortWrapperProps } from '@components/TableWrapperWithSort';
import { useCandidateDashboardContext } from '@common/context/candidateDashboardContext';
import { useExternshipPilotEnabled } from '@common/helpers/externshipHelpers/useExternshipPilotEnabled';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useResponsiveLayoutContext } from '@components/Layout/ResponsiveLayout';
import { useTableSortContext } from '@common/context/tableSortContext';

const ROW_HEIGHT = 78;
const ROW_COUNT = 5;
const TABLE_CONTAINER_HEIGHT = ROW_HEIGHT * ROW_COUNT;

const Styled = {
  Page: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'customBreakpointReached'
  })<{ customBreakpointReached: boolean }>(({ customBreakpointReached }) => ({
    backgroundColor: 'transparent',
    flex: !customBreakpointReached ? '2 1 878px' : '1',
    marginBottom: !customBreakpointReached ? 0 : '72px',
    height: 'max-content',
    padding: '73px 126px 48px 92px',
    ['@media (max-width:1100px)']: {
      padding: '32px 32px 48px'
    }
  })),
  TableContainer: styled(TableContainer, {
    shouldForwardProp: (prop) => prop !== 'isEmpty'
  })<{ isEmpty: boolean }>(({ isEmpty, theme }) => ({
    minHeight: `${TABLE_CONTAINER_HEIGHT}px`,
    width: '100%',
    marginTop: '18px',
    backgroundColor: theme.palette.WHITE.main,
    border: `1px solid ${theme.palette.GRAY_F4.main}`,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    overflow: 'hidden',
    ...(isEmpty && {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    })
  })),
  LinkStack: styled(Box)({
    display: 'inline-flex',
    gap: '32px',
    marginTop: '36px',
    ['@media (max-width:500px)']: {
      display: 'flex',
      flexDirection: 'column'
    }
  }),
  LinkSection: styled(Box)(({ theme }) => ({
    display: 'inline-block',
    width: '214px',
    '& i': {
      color: theme.palette.GOLD_1.main,
      fontSize: '12px',
      lineHeight: '18px'
    }
  })),
  LinkTitle: styled(Typography)(({ theme }) => ({
    color: theme.palette.GOLD_1.main,
    paddingBottom: '2px'
  })),
  JobTitleCell: styled(TableCell)({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '190px'
  }),
  JobTitle: styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }))
};

interface Props {
  jobApplications: JobApplied[];
}

interface ResourceList {
  icon: string;
  text: string;
  url: string;
}

interface ListProps {
  list: ResourceList[];
  title: string;
}

interface SortButtonProps {
  label: string;
  // path to value
  id: string;
}

const sortButtons: SortButtonProps[] = [
  {
    label: 'Date Applied',
    id: 'dateApplied'
  },
  {
    label: 'Status',
    id: 'status.value'
  },
  {
    label: 'Job Title',
    id: 'jobTitle'
  },
  {
    label: 'Company Name',
    id: 'companyName'
  }
];

const educationLinks: ResourceList[] = [
  {
    icon: 'ri-book-2-fill',
    text: 'How to Become a Chef',
    url: 'https://www.escoffier.edu/blog/culinary-pastry-careers/how-to-become-a-chef'
  },
  {
    icon: 'ri-book-2-fill',
    text: 'The 7 Segments of the Culinary Industry',
    url: 'https://www.escoffier.edu/blog/culinary-pastry-careers/how-to-become-a-chef/#segments'
  },
  {
    icon: 'ri-book-2-fill',
    text: 'Complete Guide to Culinary School Scholarships',
    url: 'https://www.escoffier.edu/blog/financing-your-education/complete-guide-to-culinary-school-scholarships/'
  },
  {
    icon: 'ri-headphone-fill',
    text: 'The Ultimate Dish Podcast',
    url: 'https://www.escoffier.edu/podcast/'
  }
];

const careerLinks: ResourceList[] = [
  {
    icon: 'ri-book-2-fill',
    text: 'Five Tips for Landing the Job Interview',
    url: 'https://www.escoffier.edu/blog/culinary-pastry-careers/five-tips-for-nailing-the-job-interview/'
  },
  {
    icon: 'ri-book-2-fill',
    text: 'Interviewing Tips for the Cooking Industry',
    url: 'https://www.escoffieronline.com/interviewing-tips-for-the-cooking-industry/'
  },
  {
    icon: 'ri-book-2-fill',
    text: 'How Chefs Can Build Standout Resumes',
    url: 'https://www.escoffier.edu/blog/culinary-pastry-careers/how-chefs-can-build-standout-resumes/'
  }
];

const LinkList = ({ list, title }: ListProps): JSX.Element => {
  return (
    <Styled.LinkSection>
      <Styled.LinkTitle variant="EC_TYPE_SM">{title}</Styled.LinkTitle>
      {list.map((link) => (
        <Stack direction="row" spacing={1} marginTop="12px" key={link.text}>
          <i className={link.icon} />
          {link.url.includes('www.escoffier.edu') ? (
            <ExternalEduLink link={link.url} text={link.text}>
              <Typography variant="EC_TYPE_2XS">{link.text}</Typography>
            </ExternalEduLink>
          ) : (
            <Link
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
            >
              <Typography variant="EC_TYPE_2XS">{link.text}</Typography>
            </Link>
          )}
        </Stack>
      ))}
    </Styled.LinkSection>
  );
};

const LinkSection = (): JSX.Element => {
  return (
    <Styled.LinkStack>
      <LinkList list={educationLinks} title="Educational Resources" />
      <LinkList list={careerLinks} title="Career Resources" />
    </Styled.LinkStack>
  );
};

const jobName = (id: number, title: string, company: string): JSX.Element => {
  return (
    <>
      <Link component={RouterLink} to={`/job/${id}`} underline="none">
        <Styled.JobTitle
          variant="EC_TYPE_LG"
          data-testid="candidate-table-job-name"
        >
          {id} - {title}
        </Styled.JobTitle>
      </Link>
      <Typography variant="EC_TYPE_SM">{company}</Typography>
    </>
  );
};

const jobStatus = (status: string, appliedDate: string): JSX.Element => {
  return (
    <>
      <Typography
        variant="EC_TYPE_3XS"
        data-testid="candidate-application-status"
      >
        STATUS: {status}
      </Typography>
      <br />
      <Typography
        variant="EC_TYPE_3XS"
        data-testid="candidate-application-date"
      >
        APPLIED: {appliedDate}
      </Typography>
    </>
  );
};

export const CandidateTable = (props: Props): JSX.Element => {
  const { jobApplications } = props;

  const { sortBy, sortOrder, page, rowsPerPage } = useTableSortContext();

  const emptyRows = getEmptyTableRows(
    page,
    rowsPerPage,
    jobApplications.length
  );

  return (
    <Styled.TableContainer isEmpty={!jobApplications.length}>
      {jobApplications.length > 0 ? (
        <Table data-testid="job-application-table">
          <TableBody>
            {sortTableData(
              jobApplications,
              sortOrder,
              sortBy as keyof JobApplied
            )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((job) => (
                <TableRow key={job.id} sx={{ height: `${ROW_HEIGHT}px` }}>
                  <Styled.JobTitleCell
                    align="left"
                    sx={{ padding: '0 0 0 22px' }}
                  >
                    {jobName(job.jobId, job.jobTitle, job.companyName)}
                  </Styled.JobTitleCell>
                  <TableCell align="right" sx={{ paddingRight: '0 22px 0 0' }}>
                    {jobStatus(job.status.label, job.dateAppliedString)}
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow sx={{ height: ROW_HEIGHT * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      ) : (
        <Box p="8px">
          <NoDataMessage
            title="You haven't applied to any jobs yet."
            subtitle="Apply today on EConnect!"
          />
        </Box>
      )}
    </Styled.TableContainer>
  );
};

const PrimaryContent: React.FC = () => {
  const featureFlags = useFeatureFlags();
  const { isExternshipPilotEnabled } = useExternshipPilotEnabled();

  const { candidateProfile, jobApplications, loaded } =
    useCandidateDashboardContext();

  const { customBreakpointReached } = useResponsiveLayoutContext();

  const tableSortWrapperProps: TableSortWrapperProps = {
    sortButtons,
    headerTitle: 'Your Applications',
    totalCount: jobApplications?.length,
    disabled: !jobApplications || jobApplications.length === 0,
    initialSortCategory: 'dateApplied',
    initialRowsPerPage: 5,
    displayMobile: customBreakpointReached
  };

  const tableLayoutProps: DashboardTableLayoutProps = {
    pageTitle: `WELCOME, ${candidateProfile?.firstName.toUpperCase()}!`,
    tableSortWrapperProps
  };

  const isEcAffiliate =
    !!candidateProfile?.graduationType?.value 
    && candidateProfile.graduationType.value !== GraduationTypes.NEITHER;

  const displayExternships =
    featureFlags.EXTERNSHIPS &&
    isEcAffiliate &&
    isExternshipPilotEnabled &&
    candidateProfile?.studentId;

  return (
    <Styled.Page customBreakpointReached={customBreakpointReached}>
      {!!loaded && jobApplications ? (
        <DashboardTableLayout {...tableLayoutProps}>
          <CandidateTable jobApplications={jobApplications} />
        </DashboardTableLayout>
      ) : (
        <DashboardLoading />
      )}
      {displayExternships && <ExternshipsSection />}
      <LinkSection />
    </Styled.Page>
  );
};

export default PrimaryContent;
