import { ExternshipProgressType } from '@api/models/externshipApi.models';

export const getProgressTypeText = (type: ExternshipProgressType): string => {
  switch (type) {
    case ExternshipProgressType.SITE_VERIFIED:
      return 'Site Verified';
    case ExternshipProgressType.SUPERVISOR_VERIFIED:
      return 'Supervisor Verified';
    case ExternshipProgressType.EMAIL_VERIFIED:
      return 'Verify Email';
    case ExternshipProgressType.JOB_DESC_VERIFIED:
      return 'Verify Job Description';
    case ExternshipProgressType.EMPLOYMENT_VERIFIED:
      return 'Verify Employment';
    case ExternshipProgressType.EXTENSION_VERIFIED:
      return 'Employment Extension Verified';
  }
};
