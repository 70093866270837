import * as React from 'react';
import {
  Box,
  Button,
  List,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ApplicationStatusCodebook } from '@api/models/codebook.models';
import { AppliedCandidate } from '@api/models/jobApplicationApi.models';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)({
    padding: '0 12px 0 6px'
  }),
  Text: styled(Typography)({
    transform: 'translateY(-25%)',
    fontSize: 18,
    color: '#A6B0BF',
    letterSpacing: '2px',
    fontWeight: 600
  }),
  Button: styled(Button)({
    height: '20px',
    minWidth: '36px',
    maxWidth: '36px'
  }),
  MenuTitle: styled(Typography)({
    padding: '0 15px',
    lineHeight: '42px',
    cursor: 'default'
  }),
  Menu: styled(Menu)({
    '.MuiMenu-paper': {
      padding: 0,
      minWidth: '240px'
    }
  })
};

interface IJobCandidatesOptionsMenu {
  id: number;
  candidate: AppliedCandidate;
  statuses: ApplicationStatusCodebook[];
  handleCandidateStatusChange: (
    id: AppliedCandidate['jobApplicationId'],
    status: ApplicationStatusCodebook,
    candidate: AppliedCandidate
  ) => void;
}

const JobCandidatesOptionsMenu: React.FC<IJobCandidatesOptionsMenu> = ({
  id,
  candidate,
  statuses,
  handleCandidateStatusChange
}) => {
  const [selectedStatus, setSelectedStatus] = React.useState<ApplicationStatusCodebook | null>(null);
  const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);

  const menuOpen = Boolean(anchor);
  const handleClick = (e: React.BaseSyntheticEvent<MouseEvent>): void => {
    setAnchor(e.currentTarget);
  };

  const handleClose = (): void => {
    setAnchor(null);
  };

  const ApplicationStatusList: React.FC = () => {
    const handleStatusUpdate = React.useCallback(
      (status: ApplicationStatusCodebook): void => {
        if (status)
        {
          if (status.selectable) {
            handleCandidateStatusChange(id, status, candidate);
            setAnchor(null);
          } 
          if (status.subStatuses && status.subStatuses.length>0) {
            if ( status === selectedStatus) 
              setSelectedStatus(null);
            else 
              setSelectedStatus(status);
          }
        }
      },
      [handleCandidateStatusChange]
    );

    return (
      <List
        subheader={
          <ListSubheader component="div">Update Candidate Status</ListSubheader>
        }
      >
        {statuses.map((status) => (
          <React.Fragment key={status.label}>
            <MenuItem
              disabled={checkDisabledMenuItem(status)}
              onClick={(): void => handleStatusUpdate(status)}
            >
              <ListItemText primary={status.label} />
              {status.subStatuses?.length ? (
                status === selectedStatus ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              ) : null}
            </MenuItem>
            {status.subStatuses && status === selectedStatus && ( 
              <List component="div" disablePadding>
                {status.subStatuses?.map((subStatus) => (
                  <MenuItem
                    disabled={checkDisabledMenuItem(subStatus)}
                    key={subStatus.label}
                    sx={{ pl: 6 }}
                  >
                    <ListItemText
                      primary={subStatus.label}
                      onClick={(): void => handleStatusUpdate(subStatus)}
                    />
                  </MenuItem>
                ))}
              </List>
            )}
          </React.Fragment>
        ))}
      </List>
    );

    function checkDisabledMenuItem(status: ApplicationStatusCodebook): boolean | undefined {
      
      // menu item is disabled if it is already candidate's status
      if (status.value === candidate.status.value) return true;

      // if item has own sub-statuses it is enabled
      if (status.subStatuses?.length) return false;

      //disable if it is not selectable
      return !status.selectable;
    }
  };

  return (
    <Styled.Root>
      <Styled.Button
        id="ellipses-button"
        aria-controls="ellipses-menu"
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleClick}
        size="small"
        variant="text"
      >
        <Styled.Text>...</Styled.Text>
      </Styled.Button>
      <Styled.Menu
        id="ellipses-menu"
        anchorEl={anchor}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'ellipses-button' }}
      >
        <ApplicationStatusList />
      </Styled.Menu>
    </Styled.Root>
  );
};

export default JobCandidatesOptionsMenu;
