/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { BOX_SHADOW_1 } from '@themes/ui/escoffier';
import CompanyLogo from '@components/CompanyLogo';
import { useFetchWorkLearnPartners } from '@common/fetches/useFetchWorkLearnPartners';

const HighlightedWorkAndLearnPartnerLogoGrid: React.FC = () => {
  const { workLearnPartnersSearchRes } = useFetchWorkLearnPartners();

  return (
    <Box>
      <Grid container spacing={3}>
        {workLearnPartnersSearchRes?.length ? (
          workLearnPartnersSearchRes.map((partner) => (
            <Grid item xs={12} sm={4} md={3} lg={2.4} key={partner.companyId}>
              <CompanyLogo
                path={`/employer/${partner.companyId}/profile`}
                logo={partner.companyLogo}
                name={partner.companyName}
                boxShadow={BOX_SHADOW_1}
              />
            </Grid>
          ))
        ) : (
          <></>
        )}
      </Grid>
    </Box>
  );
};

export default HighlightedWorkAndLearnPartnerLogoGrid;
