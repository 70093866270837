import * as React from 'react';
import { BadgeType } from '@api/models/codebook.models';
import { FileType } from '@api/models/fileApi.models';
import { Grid } from '@mui/material';
import ProfileImageUpload from '@components/ProfileImageUpload';
import StepSection from '@components/StepSection';
import StepSubSection from '@components/StepSubSection';

interface Props {
  files: FileType[];
  workLearnPartner?: boolean;
}

const CompanyLogoSection: React.FC<Props> = ({ files, workLearnPartner }) => {
  return (
    <StepSection title="Company Logo">
      <StepSubSection>
        <Grid container>
          <Grid item xs={12}>
            <ProfileImageUpload
              badgeType={BadgeType.EMPLOYER_BADGE}
              workLearnPartner={workLearnPartner}
              badgeName="badge"
              employerUpload
              uploadWellProps={{
                name: 'logoId',
                displayText: 'Drop your logo here',
                validTypes: ['image/jpg', 'image/jpeg', 'image/png'],
                files
              }}
            />
          </Grid>
        </Grid>
      </StepSubSection>
    </StepSection>
  );
};

export default CompanyLogoSection;
