import * as React from 'react';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { DailyInsight } from '@api/models/adminDashboardApi.models';
import EcBarChart from '@components/Graphs/EcBarChart';
import StepSection from '@components/StepSection';
import theme from '@themes/ui/escoffier';

interface Props {
  data: DailyInsight[];
}
const DailyInsightsSection: React.FC<Props> = ({ data }) => {
  const graphs = [
    {
      title: 'Candidate Accounts and Profiles',
      bars: [
        { dataKey: 'candidateAccounts', tooltipName: 'Candidates' },
        {
          dataKey: 'candidateProfiles',
          tooltipName: 'Profiles',
          color: theme.palette.GOLD_1.main
        }
      ]
    },
    {
      title: 'Employer Accounts',
      bars: {
        dataKey: 'employerAccounts',
        tooltipName: 'Employers',
        color: theme.palette.GOLD_1.main,
        filters: [
          { label: 'All Employers', dataKey: 'employerAccounts' },
          { label: 'Active Employers', dataKey: 'activeEmployers' },
          { label: 'Inactive Employers', dataKey: 'inactiveEmployers' }
        ]
      }
    },
    {
      title: 'Jobs Created',
      bars: [
        {
          dataKey: 'jobsCreated',
          tooltipName: 'Jobs'
        }
      ]
    }
  ];

  return (
    <StepSection title="Daily Insights" dataTestId="daily-insights-section">
      <BottomMarginContentWrapper bottomMargin="48px">
        {graphs.map((graph) => {
          return (
            <EcBarChart
              key={graph.title}
              data={data}
              xAxisDataKey="date"
              {...graph}
            />
          );
        })}
      </BottomMarginContentWrapper>
    </StepSection>
  );
};

export default DailyInsightsSection;
