import {
  eligibleExternshipEnrollmentStatuses,
  EnrollmentData,
  SchoolStatusIds
} from '@api/models/enrollmentApi.models';
import { useEffect, useState } from 'react';
import { Codebook } from '@api/models/codebook.models';
import { EnrollmentApi } from '@api/Enrollment.api';

type GetStudentInfoRes = {
  enrollmentId?: number | null;
  enrollmentStudentId?: number | null;
  error?: string | null;
};

interface FetchStudentCampusInfo {
  enrollmentCodebooks: Codebook[];
  activeEnrollment?: EnrollmentData;
  loading: boolean;
  getActiveEnrollmentData: (id: string) => Promise<GetStudentInfoRes>;
  handleSelectEnrollment: (enrollmentId: number) => void;
}

export const useFetchStudentEnrollments = (
  studentId?: string | null
): FetchStudentCampusInfo => {
  const [enrollments, setEnrollments] = useState<EnrollmentData[]>();
  const [enrollmentCodebooks, setEnrollmentCodebooks] = useState<Codebook[]>(
    []
  );
  const [activeEnrollment, setActiveEnrollment] = useState<EnrollmentData>();
  const [loading, setLoading] = useState(false);

  // Used to fetch enrollment data when a student is entering a campus student number for the first time
  const getActiveEnrollmentData = async (
    id: string
  ): Promise<GetStudentInfoRes> => {
    try {
      const res = await EnrollmentApi.getStudentEnrollments(id);
      const findActive = res.data.value.find(
        (e) => e.SchoolStatusId === SchoolStatusIds.ACTIVE
      );

      return {
        enrollmentId: findActive?.Id ?? null,
        enrollmentStudentId: findActive?.Student.Id ?? null
      };
    } catch (error: any) {
      console.error('Error', error);
      return { error: 'Error retrieving student info. Check Student ID' };
    }
  };

  const handleSelectEnrollment = (enrollmentId: number): void => {
    const findEnrollment = enrollments?.find((e) => e.Id === enrollmentId);
    setActiveEnrollment(findEnrollment);
  };

  useEffect(() => {
    if (studentId) {
      const fetchData = async (): Promise<void> => {
        try {
          setLoading(true);
          const res = await EnrollmentApi.getStudentEnrollments(studentId);

          const eligibleExternshipEnrollments = res?.data.value.filter((e) =>
            eligibleExternshipEnrollmentStatuses.includes(e.SchoolStatus.Code)
          );

          const enrollmentCodebooks =
            eligibleExternshipEnrollments.map((e) => ({
              label: e.ProgramVersionName,
              value: e.Id
            })) || [];

          if (eligibleExternshipEnrollments.length === 1) {
            setActiveEnrollment(eligibleExternshipEnrollments[0]);
          }

          setEnrollments(eligibleExternshipEnrollments);
          setEnrollmentCodebooks(enrollmentCodebooks);
        } catch (error: any) {
          console.error(
            `Error for useFetchStudentEnrollments -> EnrollmentApi.getStudentEnrollments(): ${error}`
          );
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [studentId]);

  return {
    enrollmentCodebooks,
    activeEnrollment,
    loading,
    getActiveEnrollmentData,
    handleSelectEnrollment
  };
};
