import { useEffect, useState } from 'react';
import { AdminContentManagementApi } from '@api/AdminContentManagement.api ';
import { GlrcContent } from '@interfaces/glrc.interfaces';

interface FetchVimeoData {
  vimeoData?: GlrcContent;
  loading: boolean;
  errorMsg?: string;
  fetchVimeoData: (vimeoUrl: string) => Promise<void>;
  setVimeoData: (data?: GlrcContent) => void;
  setErrorMsg: (msg?: string) => void;
}

export const useFetchVimeoDataFromUrl = (
  url?: string,
  disable: boolean = false
): FetchVimeoData => {
  const [vimeoData, setVimeoDataState] = useState<GlrcContent>();
  const [errorMsg, setErrorMsgState] = useState<string>();
  const [loading, setLoading] = useState(false);

  const setVimeoData = (data?: GlrcContent): void => {
    setVimeoDataState(data);
  };

  const setErrorMsg = (msg?: string): void => {
    setErrorMsgState(msg);
  };

  const fetchVimeoData = async (videoId: string): Promise<void> => {
    try {
      setLoading(true);

      const data = await AdminContentManagementApi.getVimeoDataByUrl(videoId);

      setVimeoDataState(data);
    } catch (error: any) {
      setErrorMsg(
        error?.response?.data?.message ||
          'Error finding video. Please verify the url.'
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (url && !disable) {
      const videoId = url.slice(url.lastIndexOf('/') + 1).replace('?h=', ':');

      setErrorMsgState(undefined);
      fetchVimeoData(videoId);
    }
  }, [url, disable]);

  return {
    vimeoData,
    loading,
    errorMsg,
    fetchVimeoData,
    setVimeoData,
    setErrorMsg
  };
};
