import * as React from 'react';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useTagManager } from '@common/hooks/useTagManager';

const Styled = {
  RouterLink: styled(RouterLink)({
    padding: '18px 10px',
    alignItems: 'center',
    position: 'relative',

    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
      cursor: 'pointer'
    }
  }),
  HeaderLink: styled(Link)({
    padding: '18px 10px',

    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
      cursor: 'pointer'
    }
  }),
  Icon: styled('i')({
    display: 'inline-block',
    color: theme.palette.GOLD_1.main,
    fontSize: '20px',
    marginRight: '10px',
    verticalAlign: 'middle',
    position: 'absolute',
    top: '19px',
    left: '-22px'
  })
};

interface Props {
  link?: string;
  onClick?: () => void;
  title: string;
  icon?: string;
}

const HeaderLink: React.FC<Props> = ({ title, link, onClick, icon }) => {
  const { sendToGtm } = useTagManager();

  const handleClick = (e, onClick?: () => void): void => {
    sendToGtm('navigation', {
      navigate_to: link,
      component: 'header',
      link_text: title
    });

    if (onClick) {
      onClick();
    }
  };

  if (onClick) {
    return (
      <Styled.HeaderLink
        underline="none"
        onClick={(): void => onClick()}
        sx={{ typography: 'EC_TYPE_SM' }}
      >
        {title}
      </Styled.HeaderLink>
    );
  }
  return (
    <Link
      component={Styled.RouterLink}
      onClick={handleClick}
      underline="none"
      to={link}
      sx={{
        typography: 'EC_TYPE_SM',
        marginRight: '10px'
      }}
    >
      {icon && <Styled.Icon className={icon} />}
      {title}
    </Link>
  );
};

export default HeaderLink;
