import * as React from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  List,
  Popper,
  Typography
} from '@mui/material';
import { GlrcContent, GlrcVideo } from '@interfaces/glrc.interfaces';
import { SortButton, SortOrderType } from '@interfaces/search.interfaces';
import { fetchVideoData } from './GlrcContent/glrc.helpers';
import GlrcEmbeddedVideo from '@pages/Partner/GlrcEmbeddedVideo';
import { sortTableData } from '@common/helpers/sortByCategory';
import { styled } from '@mui/system';
import TableSortButton from '@components/TableComponents/TableSortButton';

const Styled = {
  HeaderBox: styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.GRAY_4.main}`,
    marginBottom: '32px'
  })),
  PopperBox: styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.WHITE.main,
    border: `1px solid ${theme.palette.GRAY_2.main}`,
    borderRadius: '4px',
    overflow: 'hidden',
    padding: '16px'
  })),
  Title: styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    padding: '0px 0px 16px',
    marginBottom: '24px',
    textTransform: 'uppercase'
  })),
  Subtitle: styled(Typography)(({ theme }) => ({
    color: theme.palette.GRAY_4.main
  }))
};

const sortButtons: SortButton[] = [
  { label: 'Newest', id: 'id_desc' },
  { label: 'Oldest', id: 'id_asc' },
  { label: 'Title A - Z', id: 'title_asc' },
  { label: 'Title Z - A', id: 'title_desc' }
];

interface Props {
  title?: string;
  subtitle?: string;
  videos: GlrcContent[];
  showAllVideos?: boolean;
  companyName?: string;
  userId: string;
}

type SortState = {
  sortBy: string;
  sortOrder: SortOrderType;
};

const GlrcVideoList: React.FC<Props> = ({
  title,
  subtitle,
  videos,
  showAllVideos = false,
  companyName,
  userId
}) => {
  const [_videos, setVideos] = React.useState<GlrcVideo[]>([]);
  const [sortMenuAnchor, setSortMenuAnchor] =
    React.useState<HTMLElement | null>(null);
  const [sortState, setSortState] = React.useState<SortState>({
    sortBy: 'id',
    sortOrder: 'DESC'
  });
  const { sortBy, sortOrder } = sortState;

  const sortMenuOpen = Boolean(sortMenuAnchor);
  const popperId = sortMenuOpen ? 'video-sort-menu' : undefined;

  const handleSortMenuOpen = (e: React.MouseEvent<HTMLElement>): void => {
    setSortMenuAnchor(sortMenuAnchor ? null : e.currentTarget);
  };

  const handleClickAway = (): void => {
    sortMenuAnchor && setSortMenuAnchor(null);
  };

  const handleSortClick = <T,>(
    e: React.MouseEvent<unknown, MouseEvent>,
    category: keyof T
  ): void => {
    let newSortState: SortState = { ...sortState };

    switch (category) {
      case 'id_desc':
        newSortState = { sortOrder: 'DESC', sortBy: 'id' };
        break;
      case 'id_asc':
        newSortState = { sortOrder: 'ASC', sortBy: 'id' };
        break;
      case 'title_asc':
        newSortState = { sortOrder: 'ASC', sortBy: 'title' };
        break;
      case 'title_desc':
        newSortState = { sortOrder: 'DESC', sortBy: 'title' };
        break;
    }

    setSortState(newSortState);

    setTimeout(() => {
      setSortMenuAnchor(null);
    }, 80);
  };

  const getSortByText = (): string => {
    if (sortBy === 'id') {
      return sortOrder === 'DESC' ? 'Newest' : 'Oldest';
    }

    if (sortBy === 'title') {
      return sortOrder === 'DESC' ? 'Z - A' : 'A - Z';
    }

    return '';
  };

  React.useEffect(() => {
    const setVideoMetaData = async (): Promise<void> => {
      const videosWithMetaData = await fetchVideoData(videos);
      setVideos(videosWithMetaData);
    };

    setVideoMetaData();
  }, []);

  const videosToDisplay = showAllVideos ? _videos : _videos.slice(0, 4);
  const sortedVideosToDisplay = sortTableData(
    videosToDisplay,
    sortOrder,
    sortBy as keyof GlrcVideo
  );
  const sortByText = getSortByText();

  return (
    <Box>
      <Styled.HeaderBox>
        {title && <Styled.Title variant="EC_TYPE_3XL">{title}</Styled.Title>}
        {subtitle && (
          <Styled.Subtitle variant="EC_TYPE_3XL">{subtitle}</Styled.Subtitle>
        )}
        <Button
          onClick={handleSortMenuOpen}
          endIcon={<i className="ri-arrow-down-s-fill" />}
        >
          Sort by: {sortByText}
        </Button>
        <Popper
          id={popperId}
          open={sortMenuOpen}
          anchorEl={sortMenuAnchor}
          placement="bottom-end"
        >
          <ClickAwayListener onClickAway={handleClickAway}>
            <Styled.PopperBox>
              {/* <Typography variant="EC_TYPE_2XL">Sort by</Typography> */}
              <List
                disablePadding
                aria-label="video-sort-options"
                sx={{ width: '100%', maxWidth: '200px' }}
              >
                {sortButtons.map((b) => (
                  <TableSortButton
                    key={b.id}
                    label={b.label}
                    id={b.id}
                    onSortClick={handleSortClick}
                    isActive={`${sortBy}_${sortOrder.toLowerCase()}` === b.id}
                    sortOrder={sortOrder}
                    hideIcon
                    disableActive
                  />
                ))}
              </List>
            </Styled.PopperBox>
          </ClickAwayListener>
        </Popper>
      </Styled.HeaderBox>
      <Grid container spacing={2}>
        {sortedVideosToDisplay.map((video, idx) => (
          <Grid key={video.url + idx} item xs={12} sm={6} md={4} lg={3}>
            <GlrcEmbeddedVideo
              {...video}
              bookmarked={video.bookmarked}
              companyName={companyName}
              userId={userId}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GlrcVideoList;
