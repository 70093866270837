import * as React from 'react';
import { Box } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

interface Props {
  companyName: string;
  isParent: boolean;
}

const ParentCompanyCell = React.memo(({ companyName, isParent }: Props) => {
  return (
    <Box data-testid="renderParentCompanyCell">
      {companyName}{' '}
      {isParent && (
        <i className="ri-vip-crown-fill" style={{ marginLeft: '4px' }} />
      )}
    </Box>
  );
});

ParentCompanyCell.displayName = 'ParentCompanyCell';

export const renderParentCompanyCell = (
  params: GridRenderCellParams
): JSX.Element => {
  if (params.row.isParent || params.row.possibleParent) {
    return (
      <ParentCompanyCell companyName={params.row.companyName} isParent={true} />
    );
  } else if (!params.row.parentCompany) {
    return <></>;
  } else {
    return (
      <ParentCompanyCell
        companyName={params.row.parentCompany.name}
        isParent={params.row.isParent}
      />
    );
  }
};
