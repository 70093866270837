import { useEffect, useState } from 'react';
import { AdminContentManagementApi } from '@api/AdminContentManagement.api ';
import { MorePartners } from '@interfaces/morePartners.interfaces';

interface FetchMoreWorkLearnPartners {
  moreWorkLearnPartners: MorePartners[];
  setMorePartners: React.Dispatch<React.SetStateAction<MorePartners[]>>;
  loading: boolean;
  loaded: true | null;
}

export const useFetchMoreWorkLearnPartners = (): FetchMoreWorkLearnPartners => {
  const [morePartners, setMorePartners] = useState<MorePartners[]>([]);

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  const fetchMorePartners = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await AdminContentManagementApi.getMorePartners();
      setMorePartners(res);
    } catch (error: any) {
      console.error(
        'Error for useFetchMoreWorkLearnPartners -> AdminContentManagementApi.getMorePartners()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  useEffect(() => {
    fetchMorePartners();
  }, []);

  return {
    moreWorkLearnPartners: morePartners,
    setMorePartners,
    loading,
    loaded
  };
};
