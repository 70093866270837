import React from 'react';
import { RouteProps } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

interface IProtectedRouteProps extends RouteProps {
  path: string;
}

const RedirectLogoutRoute: React.FC<IProtectedRouteProps> = () => {
  const { keycloak } = useKeycloak();
  React.useEffect(() => {
    keycloak.login({ redirectUri: `${window.location.origin}/user/dashboard` });
  }, [keycloak]);
  return <></>;
};

export default RedirectLogoutRoute;
