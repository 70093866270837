import * as React from 'react';
import { Box, Skeleton } from '@mui/material';

const StudentHeaderSkeleton = (): React.ReactElement => {
  return (
    <Box sx={{ display: 'flex', width: 'max-content' }}>
      <Skeleton sx={{ transform: 'none' }} height="100px" width="100px" />
      <Box
        sx={{
          marginLeft: '32px',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '8px'
        }}
      >
        <Skeleton sx={{ transform: 'none' }} height="16px" width="200px" />
        <Skeleton sx={{ transform: 'none' }} height="16px" width="300px" />
        <Skeleton sx={{ transform: 'none' }} height="16px" />
        <Skeleton sx={{ transform: 'none' }} height="16px" />
      </Box>
    </Box>
  );
};

export default StudentHeaderSkeleton;
