import * as React from 'react';
import { Button, useTheme } from '@mui/material';
import GlrcPrivacyPolicyContent from './GlrcPrivacyPolicyContent';
import LegalPageWrapper from '@components/LegalPageWrapper';
import PrivacyPolicyContent from '@pages/Public/PrivacyPolicyPage/PrivacyPolicyContent';
import { scrollToId } from '@common/helpers/scrollToId';

const PrivacyPolicyPage: React.FC = () => {
  const theme = useTheme();

  const handleScrollToId = (): void => {
    scrollToId('glrc-privacy-policy');
  };

  return (
    <LegalPageWrapper title="Privacy Policy">
      <Button
        onClick={handleScrollToId}
        variant="text"
        sx={{
          typography: 'EC_TYPE_SM',
          color: theme.palette.primary.main,
          padding: 0
        }}
      >
        Skip to ESource
      </Button>
      <PrivacyPolicyContent />
      <GlrcPrivacyPolicyContent />
    </LegalPageWrapper>
  );
};

export default PrivacyPolicyPage;
