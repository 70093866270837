import * as React from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { AdminContentManagementApi } from '@api/AdminContentManagement.api ';
import { CreateMorePartnerDto } from '@interfaces/morePartners.interfaces';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import StepSection from '@components/StepSection';
import { styled } from '@mui/system';
import { useFetchMoreWorkLearnPartners } from '@common/fetches/useFetchMoreWorkAndLearnPartners';

const Styled = {
  Root: styled(Box)({
    marginTop: '68px'
  }),
  Table: styled(Box)(({ theme }) => ({
    maxHeight: '305px',
    overflowY: 'scroll',
    border: `1px solid ${theme.palette.OUTLINE.main}`,
    borderBottom: `2px solid ${theme.palette.GOLD_1.main}`,
    borderRadius: '4px',
    padding: '6px 0'
  })),
  Row: styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    ':hover': {
      backgroundColor: theme.palette.GRAY_2.main
    }
  })),
  DeleteBtn: styled(IconButton)(({ theme }) => ({
    width: '16px',
    height: '16px',
    marginLeft: '18px',
    color: theme.palette.BLACK.main
  })),
  FormBox: styled(Box)({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    columnGap: '18px'
  })
};

const WorkAndLearnMorePartners: React.FC = () => {
  const [hoverRow, setHoverRow] = React.useState<number | null>(2);

  const { moreWorkLearnPartners: partners, setMorePartners } =
    useFetchMoreWorkLearnPartners();

  const methods = useForm<CreateMorePartnerDto>({
    defaultValues: {
      name: ''
    }
  });

  const { handleSubmit, setError, setValue, reset } = methods;

  const handleMouseEnter = (id: number): void => {
    setHoverRow(id);
  };

  const handleMouseLeave = (): void => {
    setHoverRow(null);
  };

  const addPartner = async (data: CreateMorePartnerDto): Promise<void> => {
    if (!data.name || !data.name.trim()) {
      setError('name', {
        type: 'manual',
        message: 'Please enter an employer name'
      });

      setValue('name', '');
    } else {
      const response = await AdminContentManagementApi.createMorePartner({
        name: data.name.trim()
      });

      setMorePartners((prev) =>
        [...prev, response].sort((a, b) => a.name.localeCompare(b.name))
      );

      reset({ name: '' });
    }
  };

  const handleDeletePartner = async (): Promise<void> => {
    if (hoverRow) {
      await AdminContentManagementApi.deleteMorePartner(hoverRow).then(() => {
        setMorePartners((prev) => {
          return prev.filter((p) => p.id !== hoverRow);
        });
      });
    }
  };

  return (
    <Styled.Root>
      <StepSection>
        <Typography variant="EC_TYPE_4XL">More Partners</Typography>
        <Typography variant="EC_TYPE_BASE">
          Add additional employer partners that do not yet have an Employer
          profile.
        </Typography>
        <Styled.Table>
          {partners.map((partner) => {
            const hovered = hoverRow === partner.id;

            return (
              <Styled.Row
                key={partner.id}
                onMouseEnter={(): void => handleMouseEnter(partner.id)}
                onMouseLeave={handleMouseLeave}
              >
                <Typography variant="EC_TYPE_BASE">{partner.name}</Typography>
                {hovered && (
                  <Styled.DeleteBtn size="small" onClick={handleDeletePartner}>
                    <i className="ri-delete-bin-6-line" />
                  </Styled.DeleteBtn>
                )}
              </Styled.Row>
            );
          })}
        </Styled.Table>
        <form onSubmit={handleSubmit(addPartner)}>
          <FormProvider {...methods}>
            <Styled.FormBox>
              <ReactHookFormsInput name="name" fullWidth />
              <Button
                variant="contained"
                onClick={handleSubmit(addPartner)}
                sx={{ width: '150px' }}
              >
                Add
              </Button>
            </Styled.FormBox>
          </FormProvider>
        </form>
      </StepSection>
    </Styled.Root>
  );
};

export default WorkAndLearnMorePartners;
