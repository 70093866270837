/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  ActivityCommentPutReq,
  ActivityLogEntity
} from '@api/models/careerServicesApi.models';
import { Box, Button, Paper, Theme, Typography } from '@mui/material';
import {
  ExternshipWithActivity,
  useFetchExternshipsWithActivityOrProgress
} from '@common/fetches/useFetchExternshipActivity';
import { FormProvider, useForm } from 'react-hook-form';
import ActivityHistoryItems from '@pages/CareerServices/StudentPage/ActivityHistory/ActivityHistoryItems';
import { CareerServicesApi } from '@api/CareerService.api';
import EcAccordion from '@components/EcAccordion';
import { ExternshipEntity } from '@api/models/externshipApi.models';
import { IKeycloakUser } from '@api/models/userApi.models';
import InitialsCircle from '@components/InitialsCircle';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import StudentPageCardHeader from '@pages/CareerServices/StudentPage/StudentPageCardHeader';
import { styled } from '@mui/system';

const Styled = {
  CardRoot: styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.WHITE.main,
    padding: '32px',
    overflow: 'hidden'
  })),
  HistoryBtn: styled(Button)(({ theme }) => ({
    ...(theme as Theme).typography.EC_TYPE_3XS,
    color: theme.palette.primary.main,
    padding: '4px 0',
    marginTop: '10px'
  })),
  InputBox: styled(Box)({
    display: 'flex',
    width: '100%',
    paddingLeft: '10px',
    paddingRight: '32px',
    marginTop: '85px',
    columnGap: '6px',
    alignItems: 'center'
  }),
  SubmitCommentBtn: styled(Button)({
    width: '100px'
  })
};

interface Props {
  externships: ExternshipEntity[];
  keycloakUser: IKeycloakUser;
}

interface CardProps {
  externship: ExternshipWithActivity;
  keycloakUser: IKeycloakUser;
  setActivity: (externshipId: number, newActivity: ActivityLogEntity) => void;
}

const ActivityCard = ({
  externship,
  keycloakUser,
  setActivity
}: CardProps): React.ReactElement => {
  const [expanded, setExpanded] = React.useState<number | false>(false);
  const [enableComments, setEnableComments] = React.useState(false);

  const methods = useForm<ActivityCommentPutReq>({
    defaultValues: {
      userId: '',
      comment: ''
    }
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
    setError,
    watch
  } = methods;

  const watchComment = watch('comment');

  const handleExpand =
    (panel: number) =>
    (e, isExpanded: boolean): void => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleAddComment = async (
    data: ActivityCommentPutReq
  ): Promise<void> => {
    const userId = keycloakUser.sub;
    const { comment } = data;

    if (!comment) {
      setError('comment', {
        type: 'manual',
        message: 'Please enter a comment'
      });

      return;
    } else {
      const commentResponse =
        await CareerServicesApi.addExternshipHistoryComment(externship.id, {
          userId,
          comment
        });

      setActivity(externship.id, commentResponse.data);
      reset({ comment: '' });
    }
  };

  const btnText = expanded ? 'Hide history' : 'Show history';
  const userName = `${keycloakUser.given_name} ${keycloakUser.family_name}`;

  return (
    <Styled.CardRoot elevation={3}>
      <EcAccordion
        expanded={!!expanded}
        onChange={handleExpand(externship.id)}
        dataTestId="student-page-activity-history-accordion"
        summaryComponent={
          <StudentPageCardHeader
            externship={externship}
            extraButton={
              <Styled.HistoryBtn variant="text">{btnText}</Styled.HistoryBtn>
            }
          />
        }
      >
        <ActivityHistoryItems
          activities={externship.activity}
          setEnableComments={setEnableComments}
        />
        {enableComments && (
          <form onSubmit={handleSubmit(handleAddComment)}>
            <FormProvider {...methods}>
              <Styled.InputBox
                sx={errors['comment'] && { alignItems: 'start' }}
              >
                <InitialsCircle
                  name={userName}
                  style={errors['comment'] ? { marginTop: '5px' } : undefined}
                />
                <ReactHookFormsInput
                  name="comment"
                  placeholder="Add a comment"
                  fullWidth
                />
                <Styled.SubmitCommentBtn
                  variant="contained"
                  onClick={handleSubmit(handleAddComment)}
                  disabled={!watchComment?.length}
                >
                  Add
                </Styled.SubmitCommentBtn>
              </Styled.InputBox>
            </FormProvider>
          </form>
        )}
      </EcAccordion>
    </Styled.CardRoot>
  );
};

const StudentActivityHistory: React.FC<Props> = ({
  externships: _externships,
  keycloakUser
}) => {
  const { externships, setExternships } =
    useFetchExternshipsWithActivityOrProgress<ExternshipWithActivity>(
      _externships,
      'activity'
    );

  const setActivity = React.useCallback(
    (externshipId: number, newActivity: ActivityLogEntity) => {
      setExternships((prev) => {
        return prev.map((externship) => {
          if (externship.id === externshipId) {
            return {
              ...externship,
              activity: [...externship.activity, newActivity]
            };
          }

          return externship;
        });
      });
    },
    [setExternships]
  );

  return (
    <Box>
      <Typography variant="EC_TYPE_3XL">Activity History</Typography>
      {!!externships?.length &&
        externships.map((externship) => (
          <Box key={externship.id} sx={{ mb: '32px' }}>
            <ActivityCard
              externship={externship}
              keycloakUser={keycloakUser}
              setActivity={setActivity}
            />
          </Box>
        ))}
    </Box>
  );
};

export default StudentActivityHistory;
