import * as React from 'react';
import { BLACK, GOLD_1, GRAY_3, WHITE } from '@themes/ui/escoffier';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MUIRichTextEditor, { TMUIRichTextEditorRef } from 'mui-rte';
import { Stack, Typography, TypographyProps } from '@mui/material';
import { EditorState } from 'draft-js';
import FormFieldErrorText from '@components/Forms/FormFieldErrorText';
import InputCharCount from '@components/InputCharCount';
import { stateToHTML } from 'draft-js-export-html';
import { styled } from '@mui/system';

// Style overrides are only available via ThemeProvider
const richTextEditorStyles = createTheme();
Object.assign(richTextEditorStyles, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        backgroundColor: WHITE,
        outline: '1px solid #A6B0BF',
        borderBottom: `2px solid ${GOLD_1}`,
        borderRadius: '4px 4px 0 0',
        overflow: 'hidden',
        '&:hover': {
          outline: `1px solid ${BLACK}`
        },
        '&:focus-within': {
          outline: `2px solid ${GOLD_1}`,
          outlineBottom: 'none'
        }
      },
      container: {
        position: 'relative'
      },
      editor: {
        maxHeight: '300px',
        height: '300px',
        width: '100%',
        overflow: 'auto'
      },
      editorContainer: {
        padding: '13px',
        height: '300px',
        maxHeight: '300px',
        width: '100%',
        cursor: 'text'
      },
      placeHolder: {
        color: '#A6B0BF',
        position: 'absolute',
        outline: 'none'
      },
      toolbar: {
        backgroundColor: WHITE,
        borderBottom: '1px solid #A6B0BF',

        '& .MuiIconButton-sizeMedium *': {
          color: GRAY_3
        },
        '& .MuiIconButton-colorPrimary * ': {
          color: GOLD_1
        }
      }
    }
  }
});

const Styled = {
  Root: styled(Stack)({
    position: 'relative'
  }),
  LabelSection: styled(Stack)({
    justifyContent: 'space-between',
    alignItems: 'center'
  }),
  Label: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'required'
  })<{ required?: boolean }>(({ required, theme }) => ({
    marginBottom: '16px',
    position: 'relative',

    '&:after': {
      content: required ? '"*"' : '""',
      color: theme.palette.error.main,
      marginLeft: '4px'
    }
  }))
};

interface Props {
  name: string;
  label?: string;
  rules?: RegisterOptions;
  maxCharCount?: number;
  dataTestId?: string;
  placeholder?: string;
  tooltip?: JSX.Element;
  defaultValue?: string;
  labelVariant?: TypographyProps['variant'];
}

const ReactHookFormsRichText: React.FC<Props> = ({
  name,
  label,
  rules,
  maxCharCount,
  dataTestId,
  placeholder,
  tooltip,
  defaultValue,
  labelVariant = 'EC_TYPE_BASE'
}) => {
  const [state, setState] = React.useState<EditorState>(() =>
    EditorState.createEmpty()
  );
  const [charCount, setCharCount] = React.useState<number>(0);
  const rteRef = React.useRef<TMUIRichTextEditorRef | null>(null);

  const {
    control,
    formState: { errors }
  } = useFormContext();

  const handleCharCount = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    if (maxCharCount) {
      setCharCount(event.target.value.length);
    }
  };

  const handleChange = (
    onChange: (...event: any[]) => void,
    newState?: EditorState
  ): void => {
    const content = newState
      ? newState.getCurrentContent()
      : state.getCurrentContent();
    const html = stateToHTML(content);
    onChange(html);
  };

  const customControls = [
    'title',
    'header',
    'bold',
    'italic',
    'bulletList',
    'undo',
    'redo'
  ];

  return (
    <Styled.Root data-testid={`${dataTestId}`} id={name}>
      <Styled.LabelSection direction="row">
        <Styled.Label
          data-testid={`${name}-input-label`}
          variant={labelVariant}
          required={!!rules?.required}
        >
          {label ?? undefined}
        </Styled.Label>
        {tooltip ?? undefined}
      </Styled.LabelSection>
      <Controller
        name={name}
        control={control}
        rules={{ ...rules }}
        render={({ field: { onChange } }): JSX.Element => (
          <>
            <ThemeProvider theme={richTextEditorStyles}>
              <MUIRichTextEditor
                data-testid={dataTestId}
                label={placeholder || undefined}
                ref={rteRef}
                onBlur={(): void => handleChange(onChange)}
                controls={customControls}
                defaultValue={defaultValue}
                onChange={(state): void => {
                  setState(state);
                  maxCharCount && handleCharCount;
                  handleChange(onChange, state);
                }}
              />
            </ThemeProvider>
            {maxCharCount && (
              <InputCharCount
                charCount={charCount}
                maxCharCount={maxCharCount}
              />
            )}
          </>
        )}
      />
      {errors[name] && (
        <FormFieldErrorText name={name} message={errors[name].message} />
      )}
    </Styled.Root>
  );
};

export default ReactHookFormsRichText;
