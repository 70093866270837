import * as React from 'react';
import { Box, Button, Theme } from '@mui/material';
import { CSVLink } from 'react-csv';
import { styled } from '@mui/system';

const Styled = {
  DownloadWrapper: styled(Box)({
    textAlign: 'right',
    margin: '0px 0px 16px'
  }),
  DownloadBtn: styled(Button)(({ theme }) => ({
    ...(theme as Theme).typography.EC_TYPE_3XS,
    color: theme.palette.primary.main,
    padding: 0
  }))
};

interface CsvHeaders {
  label: string;
  key: string;
}

interface Props {
  headers: CsvHeaders[];
  fileName: string;
  buttonText?: string; // Optional buttonText prop
  fetchData: () => Promise<any>;
}

const CsvButton: React.FC<Props> = ({
  headers,
  fileName,
  buttonText = 'Download .csv',
  fetchData
}) => {
  const [data, setData] = React.useState<any[]>([]);
  const csvLink = React.useRef<any>();

  const handleClick = async (): Promise<void> => {
    const res = await fetchData();
    setData(res);

    if (csvLink && csvLink.current) {
      csvLink.current.link.click();
    }
  };

  return (
    <Styled.DownloadWrapper>
      <Styled.DownloadBtn variant="text" onClick={handleClick}>
        {buttonText}
      </Styled.DownloadBtn>
      <CSVLink
        style={{
          display: 'hidden'
        }}
        headers={headers}
        filename={fileName}
        data={data ?? []}
        separator={','}
        ref={csvLink}
      />
    </Styled.DownloadWrapper>
  );
};

export default CsvButton;
