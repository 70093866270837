import * as React from 'react';
import {
  Box,
  IconButton,
  Link,
  Paper,
  Popper,
  Typography
} from '@mui/material';
import { CourseType, GlrcContent } from '@interfaces/glrc.interfaces';
import { BookmarkApi } from '@api/Bookmark.api';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Paper)({
    ':hover': {
      position: 'relative',
      zIndex: 1,
      '.image-wrapper': {
        '&:before': {
          transition: 'opacity 0.5s',
          opacity: '0'
        },
        cursor: 'pointer'
      }
    }
  }),
  ImageWrapper: styled(Box)({
    position: 'relative',
    width: '100%',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      background: 'black',
      opacity: '0.5'
    }
  }),
  Image: styled('img')({
    display: 'block',
    width: '100%',
    height: '200px',
    objectFit: 'cover',
    objectPosition: '0% 50%',
    '@media (max-width: 600px)': {
      height: '250px'
    }
  }),
  BookmarkTitleWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'hidden'
  })<{ hidden?: boolean }>(({ hidden = false }) => ({
    margin: '16px 16px 16px 0px',
    padding: '0 0 12px 12px',
    visibility: hidden ? 'hidden' : 'visible'
  })),
  Title: styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
    textTransform: 'uppercase'
  })),
  Popper: styled(Popper)(({ theme }) => ({
    backgroundColor: theme.palette.GRAY_4.main,
    width: '100%'
  })),
  PopperContent: styled(Box)(({ theme }) => ({
    padding: '16px',
    backgroundColor: theme.palette.GRAY_4.main
  })),
  TopContent: styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  })
};

interface Props {
  content: GlrcContent;
  userId: string;
  onBookmarkDeleted?: (contentId: number) => void;
}

const CourseBookmark: React.FC<Props> = ({
  content,
  userId,
  onBookmarkDeleted
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [saved, setSaved] = React.useState(true);

  const handleMouseOver = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseOut = (): void => {
    setAnchorEl(null);
  };

  const handleBookmarkClick = async (e): Promise<void> => {
    e.stopPropagation();
    try {
      await BookmarkApi.removeBookmark(userId, content.id);
      setSaved(false);
      onBookmarkDeleted && onBookmarkDeleted(content.id);
    } catch (error) {
      console.error('Error for CourseBookmark.handleBookmarkClick()', error);
    }
  };

  const getAriaLabel = (): string => {
    return saved ? 'bookmark video' : 'remove bookmarked video';
  };

  const getCourseType = (): string => {
    const courseType = content.courseType;

    if (courseType === CourseType.FULL) {
      return 'Course';
    }

    if (courseType === CourseType.MICRO) {
      return 'QuickCourse';
    }

    return '';
  };

  const image =
    require(`@assets/LearningCenter/Courses/${content.thumbnail}`).default;

  return (
    <Styled.Root onMouseLeave={handleMouseOut} elevation={3}>
      <Link
        href={content.link}
        underline="none"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Styled.ImageWrapper
          onMouseEnter={handleMouseOver}
          className="image-wrapper"
        >
          <Styled.Image
            // TODO: update this to an actual image - db doesnt currently store course images
            src={image}
            alt={`${content.title} thumbnail`}
          />
        </Styled.ImageWrapper>
      </Link>
      <Styled.BookmarkTitleWrapper hidden={!!anchorEl}>
        <Styled.Title variant="EC_TYPE_2XL" sx={{ textTransform: 'none' }}>
          {getCourseType()}
        </Styled.Title>
        <Typography variant="EC_TYPE_LG">{content.title}</Typography>
      </Styled.BookmarkTitleWrapper>
      <Styled.Popper open={!!anchorEl} anchorEl={anchorEl} disablePortal>
        <Styled.PopperContent>
          <Styled.TopContent>
            <Box />
            <IconButton
              onClick={handleBookmarkClick}
              aria-label={getAriaLabel()}
              sx={{ color: 'GRAY_1.main' }}
            >
              <i className={`ri-bookmark-${saved ? 'fill' : 'line'}`}></i>
            </IconButton>
          </Styled.TopContent>
          <Styled.Title
            variant="EC_TYPE_3XL"
            mb={2}
            mt={2}
            sx={{ color: 'GRAY_1.main' }}
          >
            {content.title}
          </Styled.Title>
        </Styled.PopperContent>
      </Styled.Popper>
    </Styled.Root>
  );
};

export default CourseBookmark;
