import * as React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import AttestationSection from '@pages/Candidate/CandidateDashboard/WelcomeSection/ExternshipsSection/AttestationSection';
import { AttestationType } from '@interfaces/attestation.interface';
import ExternshipAction from '@pages/Candidate/CandidateDashboard/WelcomeSection/ExternshipsSection/ExternshipCard/ExternshipAction';
import ExternshipAgreement from '@components/ExternshipAgreements/ExternshipAgreement';
import ExternshipDetail from '@pages/Candidate/CandidateDashboard/WelcomeSection/ExternshipsSection/ExternshipCard/ExternshipDetail';
import ExternshipDetails from '@pages/Candidate/CandidateDashboard/WelcomeSection/ExternshipsSection/ExternshipCard/ExternshipDetails';
import { ExternshipEntity } from '@api/models/externshipApi.models';
import { getExternshipLocation } from '@common/helpers/externshipHelpers/getExternshipLocation';
import { getExternshipStatusLabel } from '@common/helpers/externshipHelpers/getExternshipStatusLabel';
import { styled } from '@mui/system';
import { useCandidateDashboardContext } from '@common/context/candidateDashboardContext';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchExternshipApprovers } from '@common/fetches/useFetchExternshipApprovers';

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.WHITE.main,
    border: `1px solid ${theme.palette.GRAY_2.main}`,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      border: 'none',
      borderRadius: 'unset',
      width: 'calc(100% + 64px)',
      transform: 'translateX(-32px)'
    }
  })),
  PaddingSection: styled(Box)({
    padding: '16px'
  }),
  SummaryMainContent: styled(Box)({
    marginBottom: '16px'
  }),
  FlexRow: styled(Box)({
    display: 'flex',
    alignItems: 'start',
    columnGap: '12px',
    '& i': {
      fontSize: '16px'
    }
  })
};

interface Props {
  externship: ExternshipEntity;
}

// TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - delete this file when flag is updated
const ExternshipCard: React.FC<Props> = ({ externship }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const { setExternshipsByCandidate } = useCandidateDashboardContext();

  const setExternship = React.useCallback(
    (externship: ExternshipEntity) => {
      setExternshipsByCandidate((prev) => {
        if (!prev) return [externship];

        const idx = prev?.findIndex((e) => e.id === externship.id);
        if (idx !== -1) {
          const updatedExternships = [...prev];
          updatedExternships[idx] = externship;
          return updatedExternships;
        }

        return [...prev, externship];
      });
    },
    [setExternshipsByCandidate]
  );

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const featureFlags = useFeatureFlags();

  const { id, status, attestation, attestationStatus } = externship;
  const { approvers } = useFetchExternshipApprovers(id);

  const handleOpenModal = (): void => {
    setModalOpen(true);
  };

  const companyName =
    externship?.company?.name ?? externship?.proposedCompany?.location?.name;

  const ActionComponent: JSX.Element = (
    <ExternshipAction
      externshipId={id}
      status={status}
      companyName={companyName}
      handleOpenAgreementModal={handleOpenModal}
      revisedEmploymentStartDate={
        externship?.externshipAgreement?.revisedEmploymentStartDate
      }
    />
  );

  const displayAttestationSection =
    featureFlags.EXTERNSHIPS &&
    !!attestation &&
    attestation.type.value !== AttestationType.OUT_OF_SCHOOL;

  return (
    <>
      <Styled.Root>
        <Styled.PaddingSection>
          <Styled.SummaryMainContent>
            <Styled.FlexRow>
              <Box>
                <Typography variant="EC_TYPE_LG" pt="8px" pb="8px">
                  {companyName}
                </Typography>
                <Typography variant="EC_TYPE_3XS">
                  {getExternshipLocation(externship)}
                </Typography>
              </Box>
              {!isSmall && ActionComponent}
            </Styled.FlexRow>
            <Styled.FlexRow>
              {externship?.jobTitle && (
                <ExternshipDetail
                  label="Position"
                  value={externship.jobTitle}
                />
              )}
              <ExternshipDetail
                label="Status"
                value={getExternshipStatusLabel(status)}
              />
            </Styled.FlexRow>
          </Styled.SummaryMainContent>
          {!!approvers?.length && (
            <ExternshipDetails externship={externship} approvers={approvers} />
          )}
          {isSmall && <Styled.FlexRow>{ActionComponent}</Styled.FlexRow>}
        </Styled.PaddingSection>
        {displayAttestationSection && (
          <AttestationSection
            externshipId={id}
            attestation={attestation}
            attestationStatus={attestationStatus}
            companyName={companyName}
          />
        )}
      </Styled.Root>
      {externship?.externshipAgreement && modalOpen && (
        <ExternshipAgreement
          externshipId={id}
          open={modalOpen}
          agreementTitle="Externship Agreement"
          handleClose={(): void => setModalOpen(false)}
          updateExternship={setExternship}
          studentSigning={true}
        />
      )}
    </>
  );
};

export default ExternshipCard;
