/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { CompanyApi } from '@api/Company.api';
import { CompanyTheme } from '@api/models/companyApi.models';

export type FetchCustomTheme = {
  companyTheme?: CompanyTheme;
  setCompanyTheme: React.Dispatch<
    React.SetStateAction<CompanyTheme | undefined>
  >;
  loaded: boolean;
  loading: boolean;
};

export const useFetchCompanyTheme = (companyId: number): FetchCustomTheme => {
  const [companyTheme, setCompanyTheme] = React.useState<
    CompanyTheme | undefined
  >();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchCompanyTheme = async (): Promise<void> => {
    if (companyId) {
      try {
        setLoading(true);
        const res = await CompanyApi.getTheme(companyId);

        setCompanyTheme(res);
        setLoaded(true);
      } catch (error: any) {
        console.error(
          'Error for useFetchCompanyTheme -> CompanyApi.getTheme()',
          error
        );
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    }
  };

  React.useEffect(() => {
    fetchCompanyTheme();
  }, [companyId]);

  return {
    companyTheme,
    setCompanyTheme,
    loaded,
    loading
  };
};
