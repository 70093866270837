import { PartnerApi } from '@api/Partner.api';

interface PutPartnerEmployeeCareerGoal {
  setCareerGoal: (
    partnerEmployeeId: number,
    careerRoleId: number
  ) => Promise<void>;
  removeCareerGoal: (partnerEmployeeId: number) => Promise<void>;
}

export const usePutPartnerEmployeeCareerGoal =
  (): PutPartnerEmployeeCareerGoal => {
    const setCareerGoal = async (
      partnerEmployeeId: number,
      careerRoleId: number
    ): Promise<void> => {
      try {
        await PartnerApi.setCareerGoal(partnerEmployeeId, { careerRoleId });
      } catch (error: any) {
        console.error(
          'Error for usePutPartnerEmployeeCareerGoal -> PartnerApi.setCareerGoal',
          error
        );
      }
    };

    const removeCareerGoal = async (
      partnerEmployeeId: number
    ): Promise<void> => {
      try {
        await PartnerApi.removeCareerGoal(partnerEmployeeId);
      } catch (error: any) {
        console.error(
          'Error for usePutCandidateCareerGoal -> CandidateApi.removeCareerGoal',
          error
        );
      }
    };

    return { setCareerGoal, removeCareerGoal };
  };
