/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Button, Grid, Stack, Typography } from '@mui/material';
import CompanyAlreadyExistsModal, {
  ExistingCompany
} from '@pages/Public/CreateAccountPage/CompanyAlreadyExistsModal';
import {
  EmployerSignupRequest,
  EmployerSSOSignupRequest,
  JoinCompanyRequest,
  JoinCompanyRequestBase,
  offering
} from '@api/models/authApi.models';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { IKeycloakUser, roles } from '@api/models/userApi.models';
import {
  includesLetter,
  includesNumber,
  minLength,
  notMatchEmail
} from '@common/rhf-validators/passwordValidators';
import RhfLocationSearch, {
  PlaceType
} from '@components/Forms/RhfLocationSearch';
import { AuthApi } from '@api/Auth.api';
import Box from '@mui/material/Box';
import { emailPattern } from '@common/regex/email';
import GoogleButton from '@components/GoogleButton';
import { Location } from '@interfaces/location.interfaces';
import { loginWithRedirect } from '@common/helpers/login';
import { notBeEmptyString } from '@common/rhf-validators/emptyString.validator';
import ReactHookFormsBoxSelection from '@components/Forms/ReactHookFormsBoxSelection';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import ReactHookFormsInputPhoneMask from '@components/Forms/ReactHookFormsInputPhoneMask';
import ReactHookFormsPassword from '@components/Forms/ReactHookFormsPassword';
import RhfAcceptTermsCheckbox from '@components/Forms/RhfAcceptTermsCheckbox';
import { scrollToError } from '@common/helpers/scrollToError';
import { styled } from '@mui/system';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useKeycloak } from '@react-keycloak/web';
import { useTagManager } from '@common/hooks/useTagManager';

const Styled = {
  Root: styled(Box)({
    margin: '0 auto',
    maxWidth: '474px'
  }),
  GridItem: styled(Grid)({
    marginBottom: '34px'
  }),
  CreateAccountBtn: styled(Button)({
    marginTop: '52px',
    height: '49px',
    width: '227px',
    // EC-Type-lg
    fontSize: '20px',
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '25px',
    letterSpacing: '0em',
    ['@media (max-width:600px)']: {
      width: '100%'
    }
  }),
  SsoButtonsWrapper: styled(Stack)({
    '& > Button': {
      marginBottom: '36px'
    }
  })
};

interface FormValues {
  firstName: string;
  lastName: string;
  companyName: string;
  jobTitle: string;
  email: string;
  locations: Location[];
  password: string;
  confirmPassword: string;
  acceptTerms: boolean;
  offerings: offering[];
  phone: string;
  companyPhone: string;
}

interface Props {
  setEmailConfirmationSent: React.Dispatch<React.SetStateAction<boolean>>;
  user: IKeycloakUser;
}

const CreateEmployerAccountForm: React.FC<Props> = ({
  setEmailConfirmationSent,
  user
}) => {
  const featureFlags = useFeatureFlags();
  const { sendToGtm } = useTagManager();
  const { keycloak } = useKeycloak();

  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [existingCompanies, setExistingCompanies] = React.useState<
    ExistingCompany[]
  >([]);
  const [currExistingCompany, setCurrExistingCompany] =
    React.useState<ExistingCompany>();

  const methods = useForm<FormValues>({
    defaultValues: {
      firstName: user?.given_name || '',
      lastName: user?.family_name || '',
      companyName: '',
      jobTitle: '',
      phone: '',
      companyPhone: '',
      locations: [],
      email: user?.email || '',
      password: '',
      confirmPassword: '',
      acceptTerms: false,
      offerings: []
    }
  });

  const formOrder = {
    offerings: 0,
    firstName: 1,
    lastName: 2,
    email: 3,
    phone: 4,
    jobTitle: 5,
    password: 6,
    confirmPassword: 7,
    companyName: 8,
    companyPhone: 9,
    acceptTerms: 10
  };

  const { handleSubmit, watch, setValue, setError } = methods;

  const currPassword = watch('password');
  const termsAccepted = watch('acceptTerms');
  const email = watch('email');
  const locations = watch('locations');

  React.useEffect(() => {
    if (existingCompanies.length) {
      setCurrExistingCompany(existingCompanies[0]);
    }
  }, [existingCompanies]);

  React.useEffect(() => {
    if (locations.length === 1) {
      const companyName = locations[0].name || '';
      setValue('companyName', companyName);
      const companyPhone = locations[0].phone || '';
      setValue('companyPhone', companyPhone);
    }
  }, [locations]);

  const handleModalOpen = (): void => {
    setModalIsOpen(true);
  };

  const handleModalClose = (): void => {
    setModalIsOpen(false);
  };

  const handleAcceptTermsAndConditions = (): void => {
    setValue('acceptTerms', true, { shouldValidate: true });
    handleModalClose();
  };

  const handleError = (error): void => {
    const errData = error.response.data.data;
    if (errData.length) {
      if (errData[0].companyName && errData[0].companyId) {
        setExistingCompanies(errData);
      } else {
        errData.forEach(({ field, message }) =>
          setError(field, { type: 'manual', message })
        );
        scrollToError(methods.formState.errors, formOrder);
      }
    }
  };

  const handleInvalidForm = (errors: FieldErrors): void => {
    scrollToError(errors, formOrder);
  };

  const handleValidForm = (data: FormValues): void => {
    const postBody: EmployerSignupRequest = {
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      email: data.email,
      companyName: data.companyName,
      jobTitle: data.jobTitle.trim(),
      password: data.password,
      confirm_password: data.confirmPassword,
      offerings: data.offerings,
      phone: data.phone,
      companyPhone: data.companyPhone,
      ...(featureFlags.COMPANY_LOCATION && { locations: data.locations })
    };

    let successfullyCreated;
    if (!user) {
      AuthApi.employerSignup(postBody)
        .then(() => {
          successfullyCreated = true;
          setEmailConfirmationSent(true);
        })
        .catch((error: any) => {
          successfullyCreated = false;
          handleError(error);
        })
        .finally(() => {
          sendToGtm('sign_up', {
            role: roles.EMPLOYER,
            success: successfullyCreated
          });
        });
    } else {
      const ssoPostBody: EmployerSSOSignupRequest = {
        userId: user.sub,
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        email: data.email,
        companyName: data.companyName.trim(),
        jobTitle: data.jobTitle.trim(),
        offerings: data.offerings,
        phone: data.phone,
        companyPhone: data.companyPhone,
        ...(featureFlags.COMPANY_LOCATION && { locations: data.locations })
      };
      AuthApi.employerSSOSignup(ssoPostBody)
        .then(() => {
          loginWithRedirect(null, keycloak.login);
        })
        .catch((error: any) => {
          handleError(error);
        });
    }
  };

  const handleValidFormForJoin = async (data: FormValues): Promise<void> => {
    if (currExistingCompany) {
      const postBody: JoinCompanyRequest = {
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        email: data.email,
        phone: data.phone,
        companyId: currExistingCompany.companyId,
        jobTitle: data.jobTitle.trim(),
        password: data.password,
        confirm_password: data.confirmPassword
      };

      try {
        if (!user) {
          await AuthApi.joinRequestSignUp(postBody);
          setEmailConfirmationSent(true);
        } else {
          const ssoPostBody: JoinCompanyRequestBase = {
            userId: user.sub,
            firstName: data.firstName.trim(),
            lastName: data.lastName.trim(),
            phone: data.phone,
            email: data.email,
            companyId: currExistingCompany.companyId,
            jobTitle: data.jobTitle.trim()
          };

          await AuthApi.joinRequestSsoSignUp(ssoPostBody);
        }
      } catch (error) {
        console.error(
          'Error for CreateEmployerAccountForm.handleValidFormForJoin()',
          error
        );
        handleError(error);
      }
    }
  };

  const handleRemoveLocation = (location: Location): void => {
    const newExistingCompaniesList = existingCompanies.filter(
      (l) => l.location.placeId !== location.placeId
    );
    setExistingCompanies(newExistingCompaniesList);

    const newLocationsList = locations.filter(
      (l) => l.placeId != location.placeId
    );
    setValue('locations', newLocationsList);
  };

  const handleJoinGroup = (): void => {
    handleSubmit(handleValidFormForJoin, handleInvalidForm)();
  };

  const selectionBoxOptions = [
    { label: offering.HIRE_TALENT, icon: <i className="ri-user-5-fill"></i> },
    {
      label: offering.WORK_AND_LEARN,
      icon: <i className="ri-folder-user-fill"></i>
    },
    {
      label: offering.CUSTOM_TRAINING,
      icon: <i className="ri-bookmark-3-fill"></i>
    }
  ];

  return (
    <Styled.Root>
      <Styled.SsoButtonsWrapper>
        {featureFlags.GOOGLE_SSO && <GoogleButton user={user} />}
      </Styled.SsoButtonsWrapper>
      <FormProvider {...methods}>
        <form>
          <ReactHookFormsBoxSelection
            name="offerings"
            rules={{ required: true }}
            options={selectionBoxOptions}
          />
          <Typography variant="EC_TYPE_3XL" mt={6} mb={4}>
            Sign Up
          </Typography>
          <Grid
            container
            columnSpacing={3}
            alignItems="flex-end"
            sx={{ marginBottom: '14px' }}
          >
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsInput
                name="firstName"
                label="First Name"
                disabled={!!user}
                autocomplete="given-name"
                dataTestId="create-employer-firstName-input"
                rules={{
                  required: 'First name is required',
                  validate: {
                    notBeEmptyString: notBeEmptyString('First name')
                  }
                }}
              />
            </Styled.GridItem>
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsInput
                name="lastName"
                label="Last Name"
                autocomplete="family-name"
                disabled={!!user}
                dataTestId="create-employer-lastName-input"
                rules={{
                  required: 'Last name is required',
                  validate: {
                    notBeEmptyString: notBeEmptyString('Last name')
                  }
                }}
              />
            </Styled.GridItem>
            <Styled.GridItem item xs={12} sm={12}>
              <ReactHookFormsInput
                name="email"
                label="Email"
                dataTestId="create-employer-email-input"
                autocomplete="email"
                disabled={!!user}
                rules={{
                  required: 'Email is required.',
                  pattern: {
                    value: emailPattern,
                    message: 'Please enter a valid email'
                  }
                }}
              />
            </Styled.GridItem>
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsInputPhoneMask
                name="phone"
                label="Personal Phone Number"
                dataTestId="create-employer-personal-phone-number-input"
                rules={{
                  required: 'Phone number is required'
                }}
              />
            </Styled.GridItem>
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsInput
                name="jobTitle"
                label="Job Title"
                dataTestId="create-employer-jobTitle-input"
                rules={{
                  required: 'Job title is required',
                  validate: {
                    notBeEmptyString: notBeEmptyString('Job title')
                  }
                }}
              />
            </Styled.GridItem>

            {!user && (
              <>
                <Styled.GridItem item xs={12} sm={6}>
                  <ReactHookFormsPassword
                    name="password"
                    label="Create Password"
                    dataTestId="create-employer-password-input"
                    autocomplete="new-password"
                    displayTooltip
                    rules={{
                      required: 'Password is required',
                      validate: {
                        notMatchEmail: notMatchEmail(email),
                        minLength,
                        includesNumber,
                        includesLetter
                      }
                    }}
                  />
                </Styled.GridItem>
                <Styled.GridItem item xs={12} sm={6}>
                  <ReactHookFormsPassword
                    name="confirmPassword"
                    label="Confirm Password"
                    autocomplete="new-password"
                    dataTestId="create-employer-confirmPassword-input"
                    rules={{
                      required: 'Confirm password is required',
                      validate: (value): boolean | string =>
                        value === currPassword || 'Passwords do not match'
                    }}
                  />
                </Styled.GridItem>
              </>
            )}

            {featureFlags.COMPANY_LOCATION && (
              <Styled.GridItem item xs={12} sm={12}>
                <RhfLocationSearch
                  name="locations"
                  label="Company Location"
                  placeholder="Find your location"
                  placeType={PlaceType.ESTABLISHMENTS}
                  displayMap
                  rules={{ required: 'Location is required' }}
                />
              </Styled.GridItem>
            )}
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsInput
                name="companyName"
                label="Company Name"
                dataTestId="create-employer-companyName-input"
                rules={{
                  required: 'Company name is required',
                  validate: {
                    notBeEmptyString: notBeEmptyString('Company name')
                  }
                }}
              />
            </Styled.GridItem>
            <Styled.GridItem item xs={12} sm={6}>
              <ReactHookFormsInputPhoneMask
                name="companyPhone"
                label="Company Phone Number"
                dataTestId="create-employer-phone-number-input"
                rules={{
                  required: 'Phone number is required'
                }}
              />
            </Styled.GridItem>
          </Grid>
          <RhfAcceptTermsCheckbox
            termsAccepted={termsAccepted}
            modalOpen={modalIsOpen}
            setModalOpen={handleModalOpen}
            setModalClosed={handleModalClose}
            handleAcceptTermsAndConditions={handleAcceptTermsAndConditions}
          />
          <Styled.CreateAccountBtn
            variant="contained"
            onClick={handleSubmit(handleValidForm, handleInvalidForm)}
            data-testid="create-employer-btn"
          >
            Create Account
          </Styled.CreateAccountBtn>
        </form>
      </FormProvider>
      {currExistingCompany && (
        <CompanyAlreadyExistsModal
          key={currExistingCompany.companyId}
          open={!!currExistingCompany}
          handleClose={(): void => setCurrExistingCompany(undefined)}
          company={currExistingCompany}
          handleRemoveLocation={handleRemoveLocation}
          handleJoinGroup={handleJoinGroup}
        />
      )}
    </Styled.Root>
  );
};

export default CreateEmployerAccountForm;
