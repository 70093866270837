import * as React from 'react';
import {
  CandidateExperiencePutReq,
  CandidateProfile
} from '@api/models/candidateApi.models';
import { FormProvider, useForm } from 'react-hook-form';
import { CandidateApi } from '@api/Candidate.api';
import { DateTime } from 'luxon';
import EducationSection from '@pages/Shared/ProfileSetup/EducationSection';
import FormActionsComponent from '@components/Forms/FormActionsComponent';
import FormWrapper from '@components/FormWrapper';
import { getCodebookValue } from '@common/utilities/getCodebookValue';
import PageDescriptionComponent from '@components/Layout/Common/PageDescriptionComponent';
import PageFormLayoutComponent from '@components/Layout/PageFormLayoutComponent';
import { scrollToError } from '@common/helpers/scrollToError';
import SkillSetSection from './SkillSetSection';
import { useFetchCandidateProfile } from '@common/fetches/useFetchCandidateProfile';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import WorkSection from '@pages/Shared/ProfileSetup/WorkSection';

type OmitType = 'certificates' | 'technicalSkills' | 'softSkills';

interface CandidateExperienceForm
  extends Omit<CandidateExperiencePutReq, OmitType> {
  certificates: CandidateProfile['certificates'];
  technicalSkills: CandidateProfile['technicalSkills'];
  softSkills: CandidateProfile['softSkills'];
}

interface Props {
  handleStepper: (next: boolean) => void;
}

const CandidateExperienceStep: React.FC<Props> = ({ handleStepper }) => {
  const [candidateId] = useStore<number>(UseStoreKeys.CANDIDATE_ID);
  const { candidateProfile } = useFetchCandidateProfile(candidateId);
  const [educationRequired, setEducationRequired] = React.useState(true);
  const [workExperienceRequired, setWorkExperienceRequired] =
    React.useState(true);

  const methods = useForm<CandidateExperienceForm>({
    defaultValues: {
      education: [],
      workAndLearn: '',
      certificates: [],
      workExperience: [],
      technicalSkills: [],
      softSkills: []
    }
  });

  const formOrder = {
    education: 1,
    certificates: 2,
    workAndLearn: 3,
    workExperience: 4,
    technicalSkills: 5,
    softSkills: 6
  };

  const {
    handleSubmit,
    reset,
    setError,
    formState: { errors }
  } = methods;

  const setMinLengthError = (
    field: keyof CandidateExperienceForm,
    message: string
  ): void => {
    setError(field, {
      type: 'minLength',
      message
    });
  };

  const handleInvalidForm = (): void => {
    if (methods.getValues().education?.length === 0 && educationRequired) {
      setMinLengthError(
        'education',
        'Please add education or select not applicable'
      );
    }

    if (
      methods.getValues().workExperience?.length === 0 &&
      workExperienceRequired
    ) {
      setMinLengthError(
        'workExperience',
        'Please add work experience or select not applicable'
      );
    }

    scrollToError(errors, formOrder);
  };

  // Fix since we renamed codebook label for student and alumni
  // and because we do not use codebookes here the string comparison
  // for the dropdown does not match a value
  const getEducationType = (educationType: string): string => {
    if (educationType === 'An Escoffier Student') {
      return 'Escoffier Student';
    }

    if (educationType === 'An Escoffier Graduate') {
      return 'Escoffier Alumni';
    }

    return educationType;
  };

  React.useEffect(() => {
    if (candidateProfile) {
      const { workExperience, education } = candidateProfile;

      reset({
        ...candidateProfile,
        // TODO educationType Codebook: education will have to be handled as commented below
        // education: education.map((item) => ({
        //   ...item,
        //   educationType: getCodebookValue<string>(item.educationType)
        // })),
        education: education.map((item) => ({
          ...item,
          campus: item.campus?.value,
          program: item.program?.value,
          educationType: getEducationType(item.educationType),
          // This fixes react warning "A component is changing an uncontrolled input to be controlled"
          graduationYear: item.graduationYear || ('' as unknown as number),
          areaOfFocus: item.areaOfFocus || '',
          institution: item.institution || ''
        })),
        workExperience: workExperience.map((item) => ({
          ...item,
          startDate: DateTime.fromISO(item.startDate),
          endDate: item?.endDate ? DateTime.fromISO(item.endDate) : null,
          currentPosition:
            item.currentPosition === undefined ? false : item.currentPosition,
          position: getCodebookValue<number>(item.position)
        }))
      });

      if (candidateProfile.educationNotApplicable === 1) {
        setEducationRequired(false);
      }

      if (candidateProfile.workExperienceNotApplicable === 1) {
        setWorkExperienceRequired(false);
      }
    }
  }, [candidateProfile]);

  const handleValidForm = async (
    data,
    onSuccess: () => void
  ): Promise<void> => {
    // Extra Validation Checks
    let extraErrors = false;

    if (data.education.length === 0 && educationRequired) {
      extraErrors = true;
      setMinLengthError(
        'education',
        'Please add education or select not applicable'
      );
    }

    if (data.workExperience.length === 0 && workExperienceRequired) {
      extraErrors = true;
      setMinLengthError(
        'workExperience',
        'Please add work experience or select not applicable'
      );
    }

    if (extraErrors) {
      scrollToError(methods.formState.errors, formOrder);
      return;
    }

    const postBody: CandidateExperiencePutReq = {
      workAndLearn: data.workAndLearn,
      workExperience: data.workExperience,
      workExperienceNotApplicable: !workExperienceRequired ? 1 : 0,
      education: data.education.map((edu) => ({
        ...edu,
        graduationYear: parseInt(edu.graduationYear),
        campus: edu.campus || null,
        program: edu.program || null,
        areaOfFocus: edu.areaOfFocus || null,
        institution: edu.institution || null
      })),
      certificates: data.certificates.map((cert) => cert.value),
      technicalSkills: data.technicalSkills.map((skill) => skill.value),
      softSkills: data.softSkills.map((skill) => skill.value),
      educationNotApplicable: !educationRequired ? 1 : 0
    };

    try {
      await CandidateApi.updateCandidateExperience(candidateId, postBody);
      onSuccess();
    } catch (error: any) {
      console.error(
        'Error for CandidateExperienceStep.updateCandidateExperience',
        error
      );

      const errData = error.response.data.data;
      if (errData?.length) {
        errData.forEach(({ field, message }) =>
          setError(field, { type: 'manual', message })
        );
      }

      scrollToError(methods.formState.errors, formOrder);
    }
  };

  const handleFormSubmit = (onSuccess: () => void): void => {
    const onValid = async (data): Promise<void> =>
      handleValidForm(data, onSuccess);
    handleSubmit(onValid, handleInvalidForm)();
  };

  return (
    <PageFormLayoutComponent
      sideContent={
        <PageDescriptionComponent title="Your Experience">
          Share your background so that we connect you to a position where you
          will feel confident. We’re most interested in your personal statement.
          It’s an opportunity to set yourself apart and help us make a better
          match.
        </PageDescriptionComponent>
      }
      content={
        <FormWrapper>
          <FormProvider {...methods}>
            <EducationSection
              educationRequired={educationRequired}
              setEducationRequired={setEducationRequired}
            />
            <WorkSection
              workExperienceRequired={workExperienceRequired}
              setWorkExperienceRequired={setWorkExperienceRequired}
              obsoleteJobTitle={candidateProfile?.currentJobTitle}
              obsoleteYearsExp={candidateProfile?.yearsOfExperience}
            />
            <SkillSetSection />
          </FormProvider>
        </FormWrapper>
      }
      actions={
        <FormActionsComponent
          onSubmit={handleFormSubmit}
          submitBtnText="Save and Continue"
          changeDetected={methods.formState.isDirty}
          handleStepper={handleStepper}
        />
      }
    />
  );
};

export default CandidateExperienceStep;
