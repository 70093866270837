import { createCustomContext } from '@common/context/customContext';

export type MobileFilterOverlayType = {
  filterOverlayOpen: boolean;
  mobileBreakpoint: number;
  openMobileFilter: () => void;
  closeMobileFilter: () => void;
};

export const [
  useMobileSearchFilterOverlayContext,
  MobileSearchFilterOverlayProvider
] = createCustomContext<MobileFilterOverlayType>();
