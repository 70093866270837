import { api } from './configs/base';
import { apiConfig } from './configs/api.config';
import { EmployerProfile } from './models/employerApi.models';
import { ExternshipEntity } from './models/externshipApi.models';

export class EmployerApi {
  static async getEmployerById(
    employerId: number
  ): Promise<EmployerProfile> {
    try {
      const res = await api.get(`${apiConfig.employerUrl}/${employerId}`);
      return res.data?.data;
    } catch (error: any) {
      console.error(`Error for EmployerApi.getEmployerById(): ${error}`);
      throw error;
    }
  }

  static async getExternshipsForEmployer(
    employerId: number
  ): Promise<ExternshipEntity[]> {
    try {
      const res = await api.get(`${apiConfig.employerUrl}/${employerId}/externships`);
      return res.data?.data;
    } catch (error: any) {
      console.error(`Error for EmployerApi.getExternshipsForEmployer(): ${error}`);
      throw error;
    }
  }

  static async markExternshipWelcomeModalAsViewed(
    employerId: number
  ): Promise<void> {
    try {
      await api.patch(
        `${apiConfig.employerUrl}/${employerId}/mark-externship-welcome-modal`
      );
    } catch (error: any) {
      console.error(
        `Error for EmployerApi.markExternshipWelcomeModalAsViewed(): ${error}`
      );
      throw error;
    }
  }
}
