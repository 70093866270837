/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { AdminContentManagementApi } from '@api/AdminContentManagement.api ';
import { PromotedCompany } from '@api/models/adminContentManagementApi.models';

export type FetchAdminPromotedCompanies = {
  fetchPromotedCompanies: () => Promise<void>;
  promotedCompanies: PromotedCompany[];
  setPromotedCompanies: React.Dispatch<React.SetStateAction<PromotedCompany[]>>;
  loaded: boolean;
  loading: boolean;
};

export const useFetchAdminPromotedCompanies =
  (): FetchAdminPromotedCompanies => {
    const [promotedCompanies, setPromotedCompanies] = React.useState<
      PromotedCompany[]
    >([]);
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const fetchPromotedCompanies = async (): Promise<void> => {
      try {
        setLoading(true);
        const res = await AdminContentManagementApi.getPromotedCompanies();

        setPromotedCompanies(res);
        setLoaded(true);
      } catch (error: any) {
        console.error(
          'Error for useFetchAdminPromotedCompanies -> AdminContentManagementApi.getPromotedCompanies()',
          error
        );
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    };

    React.useEffect(() => {
      fetchPromotedCompanies();
    }, []);

    return {
      promotedCompanies,
      setPromotedCompanies,
      loaded,
      loading,
      fetchPromotedCompanies
    };
  };
