export enum BannerVariant {
  NOTIFY = 1,
  WARNING = 2,
  URGENT = 3,
  MODAL = 4
}

export enum BannerType {
  UPDATE_PROFILE = 'updateProfile',
  INACTIVE_COMPANY = 'inactiveCompany',
  UPDATE_COMPANY_LOCATION = 'update_company_location'
}

export interface Banner {
  id: number;
  type: BannerType;
  variant: BannerVariant;
  title: string;
  message?: string | null;
  ctaLink?: string | null;
  ctaLabel?: string | null;
  clearable?: boolean;
  hideAtCtaLinkLocation?: boolean;
}
