/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import {
  ExternshipWithProgress,
  useFetchExternshipsWithActivityOrProgress
} from '@common/fetches/useFetchExternshipActivity';
import { ActivityLogEntity } from '@api/models/careerServicesApi.models';
import { ExternshipEntity } from '@api/models/externshipApi.models';
import ProgressItems from '@pages/CareerServices/StudentPage/Progress/ProgressItems';
import StudentPageCardHeader from '@pages/CareerServices/StudentPage/StudentPageCardHeader';
import { styled } from '@mui/system';

const Styled = {
  CardRoot: styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.WHITE.main,
    padding: '32px',
    overflow: 'hidden'
  }))
};

interface CardProps {
  externship: ExternshipWithProgress;
  setVerificationState: (
    externshipId: number,
    newVerification: ActivityLogEntity
  ) => void;
  addComment: (
    externshipId: number,
    newComment: ActivityLogEntity,
    parentActivityId?: number
  ) => void;
}

const ProgressCard = ({
  externship,
  setVerificationState,
  addComment
}: CardProps): React.ReactElement => {
  const { id } = externship;

  return (
    <Styled.CardRoot elevation={3}>
      <StudentPageCardHeader externship={externship} />
      <ProgressItems
        progress={externship.progress}
        externshipId={id}
        setVerificationState={setVerificationState}
        addComment={addComment}
      />
    </Styled.CardRoot>
  );
};

interface Props {
  externships: ExternshipEntity[];
}

const ProgressSection: React.FC<Props> = ({ externships: _externships }) => {
  const { externships, setExternships } =
    useFetchExternshipsWithActivityOrProgress<ExternshipWithProgress>(
      _externships,
      'progress'
    );

  const setVerification = (
    externshipId: number,
    newVerification: ActivityLogEntity
  ): void => {
    setExternships((prev) => {
      return prev.map((externship) => {
        if (externship.id === externshipId) {
          const updatedVerification = externship.progress.map((p) => {
            if (p.progressType === newVerification.progressType) {
              return newVerification;
            }

            return p;
          });

          return { ...externship, progress: updatedVerification };
        }

        return externship;
      });
    });
  };

  const addComment = (
    externshipId: number,
    newComment: ActivityLogEntity,
    parentActivityId?: number
  ): void => {
    setExternships((prev) => {
      return prev.map((externship) => {
        if (externship.id === externshipId) {
          // If there is no parentActivityId then create the parent activity with this comment
          if (!parentActivityId) {
            const updatedVerification = externship.progress.map((p) => {
              if (p.progressType === newComment.progressType) {
                return newComment;
              }

              return p;
            });

            return { ...externship, progress: updatedVerification };
          } else {
            // Parent activity already exists, add comment to parent
            const updatedVerification = externship.progress.map((p) => {
              if (
                p.progressType === newComment.progressType &&
                p.id === parentActivityId
              ) {
                // Set comments if this is the first comment added to a progress item
                if (p.comments === undefined) {
                  return { ...p, comments: [newComment] };
                }

                return { ...p, comments: [...p.comments, newComment] };
              }

              return p;
            });

            return { ...externship, progress: updatedVerification };
          }
        }

        return externship;
      });
    });
  };

  return (
    <Box>
      <Typography variant="EC_TYPE_3XL">Verification</Typography>
      {!!externships?.length &&
        externships.map((externship) => (
          <Box key={externship.id} sx={{ marginBottom: '50px' }}>
            <ProgressCard
              externship={externship}
              setVerificationState={setVerification}
              addComment={addComment}
            />
          </Box>
        ))}
    </Box>
  );
};

export default ProgressSection;
