import * as React from 'react';
import {
  BatchActionModalType,
  useBatchActionsContext
} from '@common/context/batchActionsContext';
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import AssignModal from '@pages/CareerServices/ExternshipTrackerPage/AssignModal';
import AttestationModal from '@pages/CareerServices/AttestationsPage/AttestationModal';
import EmailBatchModal from '@pages/CareerServices/ExternshipTrackerPage/EmailBatchModal';
import PilotProgramModal from '@pages/CareerServices/ExternshipTrackerPage/PilotProgramModal';
import { styled } from '@mui/system';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';

const Styled = {
  BatchActionsWrapper: styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    gap: '16px',
    marginTop: '24px',
    marginBottom: '16px',
    ['@media (max-width:599px)']: {
      flexDirection: 'column'
    }
  }),
  BatchActionsLabel: styled(Typography)(({ theme }) => ({
    marginBottom: '5px',
    textTransform: 'uppercase',
    color: theme.palette.grey4.main
  })),
  FormControl: styled(FormControl)({
    minWidth: '200px',
    ['@media (max-width:599px)']: {
      minWidth: 'unset'
    }
  }),
  Select: styled(Select)(({ theme }) => ({
    '.MuiSelect-select': {
      color: theme.palette.GRAY_3.main
    }
  })),
  Button: styled(Button)({
    minWidth: '100px'
  })
};

const BatchActions: React.FC = () => {
  const featureFlags = useFeatureFlags();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    actions,
    actionSelectLabel,
    rowSelectionModel,
    actionBtnText,
    modalOpen,
    selectedAction,
    handleOpenModal,
    handleSetSelectedAction
  } = useBatchActionsContext();

  const handleActionBtnClick = React.useCallback(() => {
    handleOpenModal();
  }, [handleOpenModal]);

  return (
    <Styled.BatchActionsWrapper>
      <Styled.FormControl fullWidth={isMobile}>
        <Styled.BatchActionsLabel variant="EC_TYPE_XS">
          {actionSelectLabel}
        </Styled.BatchActionsLabel>
        <Styled.Select
          onChange={(e: SelectChangeEvent<any>): void =>
            handleSetSelectedAction(e)
          }
          value={selectedAction?.value ?? ''}
        >
          {actions.map((action) => (
            <MenuItem key={action.label} value={action.value}>
              {action.label}
            </MenuItem>
          ))}
        </Styled.Select>
      </Styled.FormControl>
      <Styled.Button
        variant="contained"
        fullWidth={isMobile}
        onClick={handleActionBtnClick}
        disabled={!rowSelectionModel?.length}
      >
        {actionBtnText}
      </Styled.Button>
      <AssignModal open={modalOpen === BatchActionModalType.ASSIGN} />
      <EmailBatchModal open={modalOpen === BatchActionModalType.EMAIL} />
      <AttestationModal open={modalOpen === BatchActionModalType.ATTESTATION} />
      {featureFlags.PILOT_EXTERNSHIPS && (
        <PilotProgramModal
          open={modalOpen === BatchActionModalType.PILOT_PROGRAM}
        />
      )}
    </Styled.BatchActionsWrapper>
  );
};

export default BatchActions;
