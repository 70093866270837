import * as React from 'react';
import { Accordion, AccordionProps, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';

const Styled = {
  AccordionSummary: styled(AccordionSummary, {
    shouldForwardProp: (prop) => prop !== 'displayIcon'
  })<{ displayIcon: boolean }>(({ displayIcon }) => ({
    padding: 0,
    minHeight: 'unset',
    ...(displayIcon && {
      flexDirection: 'row-reverse'
    })
  }))
};

interface Props extends AccordionProps {
  summaryComponent: React.ReactNode;
  displayExpandIcon?: boolean;
  dataTestId?: string;
}

const EcAccordion: React.FC<Props> = ({
  summaryComponent,
  displayExpandIcon = false,
  dataTestId,
  children,
  ...accordionProps
}) => {
  const { expanded, onChange, ...rest } = accordionProps;

  return (
    <Accordion
      {...rest}
      expanded={expanded}
      onChange={onChange}
      data-testid={dataTestId}
      sx={{
        padding: 0,
        backgroundColor: 'transparent',
        border: 'none',
        outline: 'none',
        '&:before': {
          display: 'none'
        }
      }}
    >
      <Styled.AccordionSummary
        expandIcon={displayExpandIcon ? <ExpandMoreIcon /> : undefined}
        displayIcon={displayExpandIcon}
      >
        {summaryComponent}
      </Styled.AccordionSummary>
      {children}
    </Accordion>
  );
};

export default EcAccordion;
