import * as React from 'react';
import BasicModal from '@components/BasicModal';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { Typography } from '@mui/material';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleUndoChefApproval: () => Promise<void>;
}

const UndoChefTimesheetApprovalModal: React.FC<Props> = ({
  open,
  handleClose,
  handleUndoChefApproval
}) => {
  const [disableFormActions, setDisableFormActions] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState('');

  const handleConfirm = async (): Promise<void> => {
    try {
      setDisableFormActions(true);
      await handleUndoChefApproval();
    } catch (error: any) {
      console.error(
        `Error for UndoChefTimesheetApprovalModal.handleConfirm(): ${error}`
      );
      if (error?.response?.data?.message) {
        setErrMsg(error?.response?.data?.message);
      }
      setDisableFormActions(false);
    }
  };

  return (
    <BasicModal
      open={open}
      title="Undo Timesheet Approval"
      handleClose={disableFormActions ? undefined : handleClose}
      maxWidth="sm"
      errorMsg={errMsg}
      primaryAction={{
        label: 'Confirm',
        action: handleConfirm,
        disabled: disableFormActions
      }}
      secondaryAction={{
        label: 'Cancel',
        action: handleClose,
        disabled: disableFormActions
      }}
    >
      <BottomMarginContentWrapper bottomMargin="16px">
        <Typography>
          When a timesheet approval is undone, the timesheet will revert to the
          status of <strong>pending instructor approval</strong>, enabling you
          to either reject or re-approve the submitted time.
        </Typography>
        <Typography>
          Additionally, the student&apos;s attendance record for the week will
          be updated to zero minutes within campus.
        </Typography>
      </BottomMarginContentWrapper>
    </BasicModal>
  );
};

export default UndoChefTimesheetApprovalModal;
