import * as React from 'react';
import { Box, Typography } from '@mui/material';
import {
  CandidateCareerInfo,
  CareerPathRole,
  CareerRole,
  CareerTrack,
  PartnerEmployeeCareerInfo
} from '@interfaces/careerJourney.interfaces';
import { keyframes, styled } from '@mui/system';
import CareerGoalHeader from '@pages/Candidate/CareerPathPage/MyCareerGoal/CareerGoalHeader';
import CareerGoalReqSkills from '@pages/Candidate/CareerPathPage/MyCareerGoal/CareerGoalReqSkills';
import CareerTrackComponent from '@pages/Candidate/CareerPathPage/MyCareerGoal/CareerTrackComponent';
import { Codebook } from '@api/models/codebook.models';
import EmptySavedGoal from '@pages/Candidate/CareerPathPage/MyCareerGoal/EmptySavedGoal';
import { GOLD_1 } from '@themes/ui/escoffier';
import { WorkExperience } from '@api/models/profileSetupApi.models';

const CUSTOM_BREAKPOINT = 551;

const colorFade = keyframes`
  from {
    background-color: ${GOLD_1};
  }
  to {
    background-color: initial;
  }
`;

const Styled = {
  Root: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'goalSet'
  })<{ goalSet: boolean }>(({ goalSet, theme }) => ({
    position: 'relative',
    width: '100%',
    minHeight: 'max-content',
    padding: '14px 10px 50px 18px',
    backgroundColor: theme.palette.WHITE.main,
    border: `3px solid ${theme.palette.GOLD_1.main}`,
    borderRadius: '10px',
    boxShadow: '0px 4px 4px rgba(0,0,0,0.25)',
    overflow: 'hidden',
    ...(goalSet && {
      animation: `${colorFade} 1.5s ease-out`
    }),
    [`@media (min-width:${CUSTOM_BREAKPOINT}px)`]: {
      padding: '24px'
    }
  })),
  Content: styled(Box)({
    marginTop: '48px',
    float: 'unset',
    width: '94%',
    ['@media (min-width:769px)']: {
      float: 'right'
    }
  }),
  DisclaimerBox: styled(Box)({
    margin: '40px auto 2px auto',
    '& .career-track-disclaimer-text': {
      fontWeight: 400
    }
  })
};

interface CareerGoalCardProps {
  careerTrack?: CareerTrack;
  currentJobIds: number[];
  careerInfo?: CandidateCareerInfo | PartnerEmployeeCareerInfo;
  careerPathRole?: CareerPathRole;
  goalSelected: boolean;
  setCareerGoal: (careerRoleId: number) => Promise<void>;
  removeCareerGoal: () => Promise<void>;
  handleScrollToExploreMore: () => void;
}

const convertCareerPathRoleToCodebook = (arr?: CareerRole[]): Codebook[] =>
  arr?.map((item) => ({ value: item.id, label: item.name })) ?? [];

const convertCandidateWorkExperienceToCodebook = (
  arr?: WorkExperience[]
): Codebook[] => arr?.map((item) => item.position) ?? [];

const CareerGoalCard = ({
  careerTrack,
  currentJobIds,
  careerInfo,
  careerPathRole,
  goalSelected,
  setCareerGoal,
  removeCareerGoal,
  handleScrollToExploreMore
}: CareerGoalCardProps): React.ReactElement => {
  const careerPrereqs = React.useMemo(() => {
    const prereqs = convertCareerPathRoleToCodebook(
      careerPathRole?.prerequisites
    );
    return [...prereqs, ...(careerPathRole?.certifications ?? [])];
  }, [careerPathRole]);

  const candidateInfo = React.useMemo(() => {
    const prereqs = convertCandidateWorkExperienceToCodebook(
      careerInfo?.workExperience
    );
    const skills = [
      ...(careerInfo?.technicalSkills ?? []),
      ...(careerInfo?.softSkills ?? [])
    ];
    const certs = [...(careerInfo?.certifications ?? [])];
    const prevJobIds =
      careerInfo?.workExperience.map((item) => item.position.value) ?? [];

    return { prereqs, skills, certs, prevJobIds };
  }, [careerInfo]);

  return (
    <Styled.Root goalSet={goalSelected}>
      {careerInfo?.savedGoal && careerTrack ? (
        <>
          <CareerGoalHeader
            careerName={careerInfo.savedGoal.name}
            description={careerInfo.savedGoal.description}
            removeCareerGoal={removeCareerGoal}
            trackIcon={careerTrack.icon}
            trackName={careerTrack.name}
          />
          <Styled.Content>
            <CareerGoalReqSkills
              skills={careerPathRole?.requiredSkills ?? []}
              prereqs={careerPrereqs}
              candidateSkills={candidateInfo.skills}
              candidatePrereqs={candidateInfo.prereqs}
              candidateCertifications={candidateInfo.certs}
            />
            {careerPathRole && (
              <CareerTrackComponent
                careerTrack={careerTrack}
                previousJobIds={candidateInfo.prevJobIds}
                currentJobIds={currentJobIds}
                savedGoal={careerInfo.savedGoal}
                setCareerGoal={setCareerGoal}
              />
            )}
            <Styled.DisclaimerBox>
              <Typography
                variant="EC_TYPE_2XS"
                className="career-track-disclaimer-text"
              >
                <strong>NOTE:</strong> These are common career pathways that
                many people in the culinary industry have taken. They are not
                universal nor guaranteed to advance from one to another.{' '}
              </Typography>
            </Styled.DisclaimerBox>
          </Styled.Content>
        </>
      ) : (
        <EmptySavedGoal handleScrollToExploreMore={handleScrollToExploreMore} />
      )}
    </Styled.Root>
  );
};

export default CareerGoalCard;
