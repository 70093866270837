import { Path, UseFormSetError } from 'react-hook-form';

export const setMinLengthError = <T extends object>(
  field: Path<T>,
  message: string,
  setError: UseFormSetError<T>
): void => {
  setError(field, {
    type: 'minLength',
    message
  });
};
