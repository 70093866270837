import * as React from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import ApplicantsModal from '@pages/EcAdmin/AdminDashboardPage/ApplicantsModal/ApplicantsModal';
import { ApplicationsByEmployer } from '@common/models/common.models';
import ApplicationStatusOverview from '@pages/EcAdmin/AdminDashboardPage/ApplicationStatusOverview';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import ColoredBox from '@components/ColoredBox';
import CompanyLogo from '@components/CompanyLogo';
import { CompanyStatus } from '@api/models/adminDashboardApi.models';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import LabelWithValue from '@components/LabelWithValue';
import { styled } from '@mui/system';
import { useApplicationStatusData } from '@pages/EcAdmin/AdminDashboardPage/useApplicationStatusData';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';

const Styled = {
  Root: styled(Paper)(({ theme }) => ({
    padding: '16px 16px 58px 48px',
    borderBottom: `1px solid ${theme.palette.GRAY_1.main}`,
    borderRadius: 0
  })),
  ColumnBox: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '25px',
    '& .info-text': {
      fontWeight: 400
    }
  })
};

interface Props {
  company: CompanyStatus;
  applications: ApplicationsByEmployer | null;
}

const CompaniesStatusDetailPanel: React.FC<Props> = ({
  company,
  applications
}) => {
  const featureFlags = useFeatureFlags();

  const { admins, companyName, createdDate, logoUrl, subsidiaries } = company;
  const joined = formatDate(createdDate, 'LLL dd, yyyy');

  const { appStatusPieData, notHiredBarData, status, openModal, setOpenModal } =
    useApplicationStatusData(
      applications?.perStatus ?? [],
      applications?.total ?? 0
    );

  return (
    <Styled.Root>
      <Stack direction="row">
        <CompanyLogo
          logo={logoUrl}
          name={companyName}
          maxWidth="100px"
          customStyles={{ height: '58px' }}
        />
        <Styled.ColumnBox>
          <Typography variant="EC_TYPE_XS" className="info-title">
            JOINED DATE
          </Typography>
          <Typography variant="EC_TYPE_XS" className="info-text">
            {joined}
          </Typography>
        </Styled.ColumnBox>
        {!!admins?.length && (
          <>
            <Styled.ColumnBox>
              <Typography variant="EC_TYPE_XS" className="info-title">
                MANAGER(S)
              </Typography>
              {admins.map((a) => (
                <Typography
                  key={a.email}
                  variant="EC_TYPE_XS"
                  className="info-text"
                >
                  {a.firstName} {a.lastName}
                </Typography>
              ))}
            </Styled.ColumnBox>
            <Styled.ColumnBox>
              <Typography variant="EC_TYPE_XS" className="info-title">
                MANAGER EMAIL
              </Typography>
              {admins.map((a) => (
                <Typography
                  key={a.email}
                  variant="EC_TYPE_XS"
                  className="info-text"
                >
                  {a.email}
                </Typography>
              ))}
            </Styled.ColumnBox>
          </>
        )}
        {featureFlags.PARENT_CHILD_RELATION && !!subsidiaries?.length && (
          <Styled.ColumnBox sx={{ m: '0 auto' }}>
            <Typography variant="EC_TYPE_XS" className="info-title">
              SUBSIDIARIES
            </Typography>
            {subsidiaries.map((s) => (
              <Typography key={s.id} variant="EC_TYPE_XS" className="info-text">
                {s.name} (ID {s.id})
              </Typography>
            ))}
          </Styled.ColumnBox>
        )}
      </Stack>
      {!!applications && (
        <Stack direction="column" sx={{ mt: '42px' }}>
          <Typography variant="EC_TYPE_LG">Job Applications</Typography>
          <ApplicationStatusOverview
            pieData={appStatusPieData.slice(1)}
            notHiredData={notHiredBarData}
            content={
              <BottomMarginContentWrapper bottomMargin="8px">
                {appStatusPieData.map((data) => (
                  <React.Fragment key={data.name}>
                    {!!data.value && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {data.fill && <ColoredBox color={data.fill} />}
                        <LabelWithValue
                          onClick={data.onClick}
                          label={data.name}
                          value={data.value}
                        />
                      </Box>
                    )}
                  </React.Fragment>
                ))}
              </BottomMarginContentWrapper>
            }
          />
          {openModal && (
            <ApplicantsModal
              open={openModal}
              status={status}
              companyId={company.id}
              companyName={applications.employer}
              dateRange={{}}
              handleClose={(): void => setOpenModal(false)}
            />
          )}
        </Stack>
      )}
    </Styled.Root>
  );
};

export default CompaniesStatusDetailPanel;
