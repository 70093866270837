import * as React from 'react';
import { Box, Tab, Theme, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ActivityLogEntity } from '@api/models/careerServicesApi.models';
import { DateTime } from 'luxon';
import EmailActivityHistoryItem from './EmailActivityHistoryItem';
import { ExternshipActivityType } from '@api/models/externshipApi.models';
import InitialsCircle from '@components/InitialsCircle';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '32px'
  }),
  FlexBox: styled(Box)({
    display: 'flex'
  }),
  TabWrapper: styled(Box)(({ theme }) => ({
    width: '95%',
    borderBottom: `1px solid ${theme.palette.GRAY_5.main}`
  })),
  TabList: styled(TabList)(({ theme }) => ({
    '& .MuiTab-root': {
      ...(theme as Theme).typography.EC_TYPE_3XS,
      minWidth: 'max-content',
      margin: '0 6px',
      padding: '0 4px',
      minHeight: 0,
      height: '16px'
    },
    '& .MuiTabs-indicator': {
      display: 'none'
    },
    '& .pre-tabs-text': {
      marginRight: '12px',
      alignSelf: 'center'
    }
  })),
  Tab: styled(Tab)(({ theme }) => ({
    whiteSpace: 'nowrap',
    '&.Mui-selected': {
      fontWeight: 800,
      color: theme.palette.GRAY_4.main,
      backgroundColor: theme.palette.GRAY_85.main,
      borderRadius: '4px'
    }
  })),
  TabContent: styled(TabPanel)({
    padding: 0
  }),
  ContentBox: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '22px',
    padding: '16px 0 16px 10px'
  })
};

const getDate = (dateString: string): string => {
  const date = DateTime.fromISO(dateString);
  return date
    .toLocaleString({ ...DateTime.DATETIME_SHORT, hour12: true })
    .replace(',', '');
};

const getActivityLabel = (activity: ActivityLogEntity): string => {
  const label = `${activity.logType} - ${getDate(activity.date.toString())} - ${
    activity.user
  }`;
  if (activity.logType === ExternshipActivityType.COMMENTS) {
    return !activity.progressType ? label : activity.progressType + ' ' + label;
  }

  return label;
};

const tabs = [
  { label: 'View:', value: '0', style: { pointerEvents: 'none' } },
  { label: 'All', value: '1' },
  { label: 'Assignments', value: '2' },
  { label: 'Status Updates', value: '3' },
  { label: 'Timesheets', value: '4' },
  { label: 'Emails', value: '5' },
  { label: 'Comments', value: '6' },
  { label: 'Verification', value: '7' }
];

interface Props {
  activities: ActivityLogEntity[];
  setEnableComments: (value: boolean) => void;
}

const ActivityHistoryItems: React.FC<Props> = ({
  activities,
  setEnableComments
}) => {
  const [tabValue, setTabValue] = React.useState<string>('1');

  const handleTabChange = (e: React.SyntheticEvent, newVal: string): void => {
    setTabValue(newVal);
  };

  React.useEffect(() => {
    if (tabValue === '6') {
      setEnableComments(true);
    } else {
      setEnableComments(false);
    }
  }, [tabValue]);

  const sortedActivities = React.useMemo(() => {
    const all = activities
      .sort(
        (a, b) =>
          DateTime.fromISO(a.date).valueOf() -
          DateTime.fromISO(b.date).valueOf()
      )
      .filter((item) => item.description !== 'created-with-comment');

    const assignments = all.filter(
      (a) => a.logType === ExternshipActivityType.ASSIGNMENT
    );
    const statusUpdates = all.filter(
      (a) => a.logType === ExternshipActivityType.STATUS_UPDATE
    );
    const timesheets = all.filter(
      (a) => a.logType === ExternshipActivityType.TIMESHEET
    );
    const comments = all.filter(
      (a) => a.logType === ExternshipActivityType.COMMENTS
    );
    const emails = all.filter(
      (a) => a.logType === ExternshipActivityType.EMAIL
    );
    const verification = all.filter(
      (a) => a.logType === ExternshipActivityType.VERIFICATION
    );

    return {
      all,
      assignments,
      statusUpdates,
      timesheets,
      emails,
      comments,
      verification
    };
  }, [activities]);

  const tabContent = [
    { value: '1', label: 'activities', data: sortedActivities.all },
    { value: '2', label: 'assignments', data: sortedActivities.assignments },
    {
      value: '3',
      label: 'status updates',
      data: sortedActivities.statusUpdates
    },
    { value: '4', label: 'timesheets', data: sortedActivities.timesheets },
    { value: '5', label: 'emails', data: sortedActivities.emails },
    { value: '6', label: 'comments', data: sortedActivities.comments },
    { value: '7', label: 'verification', data: sortedActivities.verification }
  ];

  return (
    <Styled.Root>
      <TabContext value={tabValue}>
        <Styled.TabWrapper>
          <Styled.TabList
            onChange={handleTabChange}
            aria-label="activity history tabs"
            variant="standard"
          >
            {tabs.map((tab) => (
              <Styled.Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                sx={{ ...tab.style }}
              />
            ))}
          </Styled.TabList>
        </Styled.TabWrapper>
        {tabContent.map((item) => (
          <Styled.TabContent key={item.value} value={item.value}>
            <Styled.ContentBox>
              {item.data?.length ? (
                item.data.map((a) => (
                  <React.Fragment key={a.id}>
                    <Styled.FlexBox key={a.id}>
                      <InitialsCircle name={a.user} />
                      <Box sx={{ marginLeft: '6px', flex: 1 }}>
                        <Typography variant="EC_TYPE_2XS">
                          {getActivityLabel(a)}
                        </Typography>
                        {a.logType === ExternshipActivityType.EMAIL ? (
                          <EmailActivityHistoryItem emailLogActivity={a} />
                        ) : (
                          <Typography variant="EC_TYPE_SM">
                            {a.description}
                          </Typography>
                        )}
                      </Box>
                    </Styled.FlexBox>
                  </React.Fragment>
                ))
              ) : (
                <Typography variant="EC_TYPE_BASE">
                  There are no {item.label} to display
                </Typography>
              )}
            </Styled.ContentBox>
          </Styled.TabContent>
        ))}
      </TabContext>
    </Styled.Root>
  );
};

export default ActivityHistoryItems;
