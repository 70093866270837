import * as React from 'react';
import BasicModal from '@components/BasicModal';
import Typography from '@mui/material/Typography';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleContinueClick: () => void;
}

const StudentComplianceModal: React.FC<Props> = ({
  open,
  handleClose,
  handleContinueClick
}) => {
  return (
    <BasicModal
      open={open}
      title="External Platform:"
      handleClose={handleClose}
      primaryAction={{
        label: 'Agree & Continue',
        action: handleContinueClick
      }}
      secondaryAction={{ label: 'Cancel', action: handleClose }}
    >
      <Typography variant="EC_TYPE_BASE">
        You are transitioning to a third-party shared platform that is intended
        to enhance your student experience. This platform is operated by a third
        party, Auguste Escoffier Global Solutions, and is not controlled by
        Auguste Escoffier School of Culinary Arts. Please review the Terms of
        Use and Privacy Policy for this site, before use. By selecting
        &apos;Agree & Continue&apos;, I acknowledge my choice to use this
        optional resource site to support my Externship or Employment search.
      </Typography>
    </BasicModal>
  );
};

export default StudentComplianceModal;
