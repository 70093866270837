import * as React from 'react';
import { Box, Link, Theme, Typography, useTheme } from '@mui/material';
import { GRAY_F4 } from '@themes/ui/escoffier';
import PageTitle from '@components/PageTitle';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import { useKeycloakContext } from '@common/context/keycloakContext';

const Styled = {
  HeaderSection: styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '24px',
    flexWrap: 'wrap',
    ['@media (max-width:1300px)']: {
      flexDirection: 'column',
      alignItems: 'unset'
    }
  }),
  LinkWrapper: styled(Box)({
    display: 'flex',
    alignItems: 'center'
  }),
  LinkList: styled(Box)({
    display: 'flex',
    minWidth: '708px',
    flexWrap: 'wrap',
    gap: '16px',
    ['@media (max-width:1300px)']: {
      minWidth: 'unset'
    }
  }),
  Link: styled(RouterLink)(({ theme }) => ({
    ...(theme as Theme).typography.EC_TYPE_2XL,
    color: theme.palette.primary.main,
    textAlign: 'center'
  })),
  LinkPipe: styled(Typography)(({ theme }) => ({
    ...(theme as Theme).typography.EC_TYPE_2XL,
    padding: '0px 16px',
    color: theme.palette.primary.main
  }))
};

const CareerServicesDashboardPage: React.FC = () => {
  const theme = useTheme();
  const { keycloakUser } = useKeycloakContext();

  const links = [
    { label: 'Audit Hub', path: '/audit-hub' },
    { label: 'Externship Tracker', path: '/externship-tracker' },
    { label: 'Employer Surveys', path: '/employer-surveys' },
    { label: 'Attestations', path: '/externship-attestations' }
  ];

  return (
    <RootPageLayout muiMaxWidth={false} bgColor={GRAY_F4}>
      <Styled.HeaderSection>
        <PageTitle
          color={theme.palette.GOLD_1.main}
          text={`Welcome, ${keycloakUser.given_name}!`}
        />
        <Styled.LinkList>
          {links.map((link, idx) => (
            <Styled.LinkWrapper key={link.path}>
              <Link component={Styled.Link} to={link.path} underline="none">
                {link.label}
              </Link>
              {idx !== links.length - 1 && <Styled.LinkPipe>|</Styled.LinkPipe>}
            </Styled.LinkWrapper>
          ))}
        </Styled.LinkList>
      </Styled.HeaderSection>
    </RootPageLayout>
  );
};

export default CareerServicesDashboardPage;
