import * as React from 'react';
import { Box } from '@mui/material';
import { jobTypes } from '@common/tags/tagStrings';
import ReactHookFormsAutoComplete from '@components/Forms/ReactHookFormsAutoComplete';
import StepSection from '@components/StepSection';
import StepSubSection from '@components/StepSubSection';
import { useFetchSegmentsCodebooks } from '@common/fetches/useFetchSegmentsCodebooks';
import { useFetchSpecialtiesCodebooks } from '@common/fetches/useFetchSpecialtiesCodebooks';

const WorkPreferencesSection: React.FC = () => {
  const { segments } = useFetchSegmentsCodebooks();
  const { specialties } = useFetchSpecialtiesCodebooks();

  return (
    <StepSection title="Work Preferences">
      <StepSubSection title="What types of employment sound interesting to you?">
        <Box sx={{ '& > *:not(:last-child)': { marginBottom: '32px' } }}>
          <ReactHookFormsAutoComplete
            required
            multipleSelect
            options={jobTypes}
            name="employmentType"
            title="Type of work and schedule"
            inputPlaceholder="Select all that apply"
            rules={{ required: 'Employment type is required' }}
          />

          <ReactHookFormsAutoComplete
            required
            multipleSelect
            options={segments}
            name="segments"
            title="Segments"
            inputPlaceholder="Select all that apply"
            rules={{ required: 'Culinary segments are required' }}
          />

          <ReactHookFormsAutoComplete
            required
            multipleSelect
            options={specialties}
            name="specialties"
            title="Specialties"
            inputPlaceholder="Select all that apply"
            rules={{ required: 'Culinary specialties are required' }}
          />
        </Box>
      </StepSubSection>
    </StepSection>
  );
};

export default WorkPreferencesSection;
