import * as React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import {
  Stack,
  TextareaAutosize,
  TextareaAutosizeProps,
  Theme,
  Typography
} from '@mui/material';
import FormFieldErrorText from '@components/Forms/FormFieldErrorText';
import { styled } from '@mui/system';

const Styled = {
  Container: styled(Stack)({
    position: 'relative'
  }),
  Label: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'required'
  })<{ required: boolean }>(({ required, theme }) => ({
    marginBottom: '11px',
    position: 'relative',
    ['@media (max-width:480px)']: {
      marginBottom: '5px',
      fontSize: (theme as Theme).typography.EC_TYPE_SM.fontSize,
      lineHeight: (theme as Theme).typography.EC_TYPE_SM.lineHeight
    },
    '&:after': {
      content: required ? '"*"' : '""',
      color: theme.palette.error.main,
      marginLeft: '4px'
    }
  })),
  TextArea: styled(TextareaAutosize, {
    shouldForwardProp: (prop) => prop !== 'width' && prop !== 'maxWidth'
  })<{ width?: string; maxWidth?: string }>(({ width, maxWidth, theme }) => ({
    padding: '10px',
    font: 'inherit',
    borderRadius: '4px',
    borderColor: theme.palette.OUTLINE.main,
    boxSizing: 'border-box',
    minHeight: '32px',
    minWidth: '64px',
    width,
    maxWidth,
    '&:hover': {
      borderColor: theme.palette.BLACK.main
    },
    '&:focus': {
      outline: 0,
      border: `1.5px solid ${theme.palette.GOLD_1.main}`
    }
  }))
};

interface Props extends TextareaAutosizeProps {
  name: string;
  label?: string;
  placeholder?: string;
  rules?: RegisterOptions;
  disableRequiredAstrisk?: boolean;
  inputWidth?: string;
  inputMaxWidth?: string;
}

const RhfTextAreaAutosize: React.FC<Props> = ({
  name,
  label,
  placeholder,
  rules,
  disableRequiredAstrisk = false,
  inputWidth,
  inputMaxWidth,
  ...inputProps
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <Styled.Container id={name}>
      {label && (
        <Stack direction="row">
          <Styled.Label
            data-testid={`${name}-textAreaAutosize-label`}
            variant="EC_TYPE_BASE"
            required={!!rules?.required && !disableRequiredAstrisk}
          >
            {label}
          </Styled.Label>
        </Stack>
      )}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }): JSX.Element => (
          <Styled.TextArea
            {...field}
            {...inputProps}
            aria-label={`${name} textarea`}
            placeholder={placeholder}
            width={inputWidth}
            maxWidth={inputMaxWidth}
          />
        )}
      />
      {errors[name] && (
        <FormFieldErrorText name={name} message={errors[name].message} />
      )}
    </Styled.Container>
  );
};

export default RhfTextAreaAutosize;
