import React, { useEffect, useState } from 'react';
import { Codebook } from '@api/models/codebook.models';
import { CodebookApi } from '@api/Codebook.api';

type FetchCampusProgramsCodebook = {
  campusPrograms: Codebook[];
  loading: boolean;
  loaded: boolean;
};

/**
 * Fetch campus programs Codebook, used for CandidateAboutMeSection within account creation
 *
 * @returns `{ campusPrograms: Codebook[]; loading: boolean; loaded: boolean; }`
 */
export const useFetchCampusProgramsCodebook = (
  campusId?: number
): FetchCampusProgramsCodebook => {
  const [campusPrograms, setCampusPrograms] = useState<Codebook[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (campusId) {
      const fetchData = async (): Promise<void> => {
        try {
          setLoading(true);

          const res = await CodebookApi.getCampusPrograms(campusId);
          setCampusPrograms(res.data);
          setLoaded(true);
        } catch (error: any) {
          console.error(
            'Error for useFetchCampusProgramCodebook -> CodebookApi.getCampusPrograms()',
            error
          );
        } finally {
          setLoading(false);
          setLoaded(true);
        }
      };
      fetchData();
    }
  }, [campusId]);

  return { campusPrograms, loading, loaded };
};
