/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Button, Grid, Skeleton } from '@mui/material';
import { FieldErrors, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { useSetStoreValue, useStore } from 'react-context-hook';
import { CandidateApi } from '@api/Candidate.api';
import { Codebook } from '@api/models/codebook.models';
import { CourseData } from '@api/models/enrollmentApi.models';
import { ExternshipApi } from '@api/Externships.api';
import { ExternshipProgramPutReq } from '@api/models/externshipProposalApi.models';
import ExternshipProposalStepLayout from '@pages/Candidate/ExternshipProposalPage/ExternshipProposalStepLayout';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import ReactHookFormsSelect from '@components/Forms/ReactHookFormsSelect';
import { scrollToError } from '@common/helpers/scrollToError';
import StepSection from '@components/StepSection';
import { styled } from '@mui/system';
import { useFetchExternshipCourses } from '@common/fetches/useFetchExternshipCourses';
import { useFetchStudentEnrollments } from '@common/fetches/useFetchStudentEnrollments';
import { useStepperContext } from '@common/context/stepperContext';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';

const Styled = {
  SaveStudentIdBox: styled(Grid, {
    shouldForwardProp: (prop) => prop !== 'error'
  })<{ error: boolean }>(({ error }) => ({
    alignSelf: error ? 'center' : 'end',
    ...(error && {
      transform: 'translateY(5px)'
    })
  }))
};

interface FormValues {
  studentId: string;
  jobTitle: string;
  jobDescription: string;
  currentProgram: number;
  course: number;
  scheduledStartDate: string;
}

interface CourseCodebook extends Codebook<number, string | JSX.Element> {
  courseScheduleId: number;
}

interface Props {
  handleStepper: (next: boolean) => void;
  candidateId: number;
  proposedCompanyId?: number;
}

const ExternshipProposalProgramStep: React.FC<Props> = ({
  handleStepper,
  candidateId,
  proposedCompanyId
}) => {
  const history = useHistory();
  const { courseId } = useParams<{ courseId?: string }>();
  const { stepperLoading, setStepperLoading } = useStepperContext();

  const [courses, setCourses] = React.useState<CourseCodebook[]>();
  const [selectedCourse, setSelectedCourse] = React.useState<CourseData>();

  const [storeStudentId] = useStore<string | null>(
    UseStoreKeys.CANDIDATE_STUDENT_ID
  );
  const setStoreStudentId = useSetStoreValue(UseStoreKeys.CANDIDATE_STUDENT_ID);

  const {
    enrollmentCodebooks,
    activeEnrollment,
    getActiveEnrollmentData,
    handleSelectEnrollment,
    loading: studentInfoLoading
  } = useFetchStudentEnrollments(storeStudentId);

  const {
    courseData,
    fetchExternshipCourses,
    loading: coursesLoading
  } = useFetchExternshipCourses(
    activeEnrollment?.Id,
    activeEnrollment?.Student.Id,
    candidateId
  );

  const currProgram =
    enrollmentCodebooks?.length === 1
      ? enrollmentCodebooks[0].value
      : undefined;

  const methods = useForm<FormValues>({
    defaultValues: {
      studentId: storeStudentId ?? undefined,
      jobTitle: '',
      jobDescription: '',
      currentProgram: currProgram,
      course: undefined,
      scheduledStartDate: undefined
    }
  });

  const formOrder = {
    studentId: 10,
    jobTitle: 20,
    jobDescription: 30,
    currentProgram: 40,
    course: 50,
    scheduledStartDate: 60
  };

  const {
    clearErrors,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
    setError,
    setValue,
    watch
  } = methods;

  const watchProgram = watch('currentProgram');
  const watchCourse = watch('course');

  // Handle studentId with useStore
  React.useEffect(() => {
    if (storeStudentId) {
      reset({ studentId: storeStudentId });
    }
  }, [storeStudentId]);

  // Handle one program option
  React.useEffect(() => {
    if (enrollmentCodebooks?.length === 1) {
      setValue('currentProgram', enrollmentCodebooks[0].value);
    }
  }, [enrollmentCodebooks]);

  // Handle setting course and start date when only one course option
  React.useEffect(() => {
    if (courseId) {
      const findCourseWithParamsId = courses?.find(
        (c) => c.value === +courseId || c.courseScheduleId === +courseId
      );
      if (findCourseWithParamsId) {
        setValue('course', findCourseWithParamsId.value);
      }
    }

    if (courses?.length === 1) {
      const course = courseData?.find((c) => c.CourseId === courses[0].value);
      const startDate = course?.StartDate ?? '';

      setValue('course', courses[0].value);
      if (startDate) {
        setValue('scheduledStartDate', formatDate(startDate, 'LLLL dd, yyyy'));
      }
    }
  }, [courses, courseData, courseId]);

  // Handle setting courses based on selected program
  React.useEffect(() => {
    if (watchProgram) {
      if (watchProgram !== activeEnrollment?.Id) {
        handleSelectEnrollment(watchProgram);
      }

      const courseCodebook = courseData
        ? courseData
            .filter((c) => c.StudentEnrollmentPeriodId === watchProgram)
            .map((c) => ({
              // label: `${c.CourseCode} - ${c.Name}`,
              label: (
                <>
                  {c.CourseCode} - {c.Name}
                  {!!c.disabled && <strong> - ASSIGNED</strong>}
                </>
              ),
              value: c.CourseId,
              courseScheduleId: c.Id,
              disabled: !!c.disabled // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove when flag is updated
            }))
        : [];

      setCourses(courseCodebook);
    }
  }, [courseData, watchProgram]);

  // Handle setting start date based on course
  React.useEffect(() => {
    if (watchCourse && courseData) {
      const course = courseData.find((c) => c.CourseId === watchCourse);
      const startDate = course?.StartDate ?? '';

      setSelectedCourse(course);
      setValue('scheduledStartDate', formatDate(startDate, 'LLLL dd, yyyy'));
    }
  }, [courseData, watchCourse]);

  const handleSaveStudentId = async (): Promise<void> => {
    const studentIdVal = getValues('studentId');

    if (!studentIdVal || studentIdVal.length < 10) {
      setError('studentId', {
        type: 'manual',
        message: 'Please enter a valid 10 digit student number'
      });
      return;
    } else {
      clearErrors('studentId');
    }

    // use active enrollment IDs to get program information
    const { enrollmentId, enrollmentStudentId, error } =
      await getActiveEnrollmentData(studentIdVal);

    if (error) {
      setError('studentId', {
        type: 'manual',
        message: error
      });

      return;
    } else if (!!enrollmentId && !!enrollmentStudentId) {
      await Promise.all([
        CandidateApi.updateCandidateStudentId(candidateId, studentIdVal),
        fetchExternshipCourses(enrollmentId, enrollmentStudentId)
        // fetchCampusJobTitles(enrollmentId)
      ]);

      setStoreStudentId(studentIdVal);
      currProgram && setValue('currentProgram', currProgram);
    }
  };

  const handleInvalidForm = (errors: FieldErrors): void => {
    scrollToError(errors, formOrder);
  };

  const handleValidForm = async (
    data: FormValues,
    onSuccess: () => void
  ): Promise<void> => {
    try {
      setStepperLoading(true);

      if (proposedCompanyId && selectedCourse) {
        // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove .disabled line when flag is updated
        delete selectedCourse.disabled; // disabled should not be sent to back-end

        const postBody: ExternshipProgramPutReq = {
          ...selectedCourse,
          ClockHours: Number(selectedCourse.ClockHours),
          CreditHours: Number(selectedCourse.CreditHours),
          studentId: data.studentId,
          jobTitle: data.jobTitle,
          jobDescription: data.jobDescription,
          graduationDate: activeEnrollment?.GraduationDate
        };

        await ExternshipApi.updateProgramInfo(
          candidateId,
          proposedCompanyId,
          postBody
        );
        onSuccess();
      }
    } catch (error: any) {
      console.error(
        `Error for ExternshipProposalProgramStep.handleValidForm(): ${error}`
      );
    } finally {
      setStepperLoading(false);
    }
  };

  const handleFormSubmit = (onSuccess: () => void): void => {
    const onValid = async (data: FormValues): Promise<void> =>
      handleValidForm(data, onSuccess);
    handleSubmit(onValid, handleInvalidForm)();
  };

  const handleLastStepAction = (): void => {
    history.push('/candidate/dashboard');
  };

  const programInfoReady =
    !!enrollmentCodebooks &&
    !coursesLoading &&
    !studentInfoLoading &&
    !!storeStudentId;

  return (
    <ExternshipProposalStepLayout
      methods={methods}
      content={
        <>
          <StepSection
            title="Student Information"
            dataTestId="externship-proposal-student-information-step"
          >
            <Grid container spacing={3}>
              <Grid item xs={storeStudentId ? 12 : 9}>
                <ReactHookFormsInput
                  name="studentId"
                  label="Student Number"
                  rules={{ required: 'Student ID is required' }}
                  disabled={!!storeStudentId}
                  dataTestId="externship-proposal-student-id"
                />
              </Grid>
              {!storeStudentId && (
                <Styled.SaveStudentIdBox
                  item
                  xs={3}
                  error={!!errors['studentId']}
                >
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleSaveStudentId}
                  >
                    Save
                  </Button>
                </Styled.SaveStudentIdBox>
              )}
              {!studentInfoLoading ? (
                <>
                  <Grid item xs={12}>
                    <ReactHookFormsInput
                      name="jobTitle"
                      label="Exact Job Title"
                      rules={{ required: 'Job Title is required' }}
                      dataTestId="externship-proposal-jobTitle"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ReactHookFormsInput
                      name="jobDescription"
                      label="Job Description"
                      rules={{ required: 'Job Description is required' }}
                      dataTestId="externship-proposal-jobDescription"
                    />
                  </Grid>
                </>
              ) : (
                <>
                  {!!storeStudentId && (
                    <>
                      <Grid item xs={12}>
                        <Skeleton
                          sx={{ transform: 'none' }}
                          width="100%"
                          height="46px"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Skeleton
                          sx={{ transform: 'none' }}
                          width="100%"
                          height="46px"
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </StepSection>
          {programInfoReady ? (
            <StepSection
              title="Program Information"
              dataTestId="externship-proposal-program-step"
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ReactHookFormsSelect
                    name="currentProgram"
                    label="Current Program"
                    options={enrollmentCodebooks}
                    disabled={enrollmentCodebooks?.length === 1}
                    rules={{ required: 'Current Program is required' }}
                    dataTestId="externship-proposal-currentProgram"
                  />
                </Grid>
                {!!courses?.length && (
                  <>
                    <Grid item xs={12}>
                      <ReactHookFormsSelect
                        name="course"
                        label="Externship Course"
                        options={courses}
                        disabled={courses?.length === 1}
                        rules={{ required: 'Course is required' }}
                        dataTestId="externship-proposal-course"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ReactHookFormsInput
                        name="scheduledStartDate"
                        label="Scheduled Start Date"
                        disabled
                        rules={{ required: 'Start Date is required' }}
                        dataTestId="externship-proposal-scheduledStartDate"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </StepSection>
          ) : (
            <>
              {!!storeStudentId && (
                <Skeleton
                  height="400px"
                  width="100%"
                  sx={{ transform: 'none' }}
                />
              )}
            </>
          )}
        </>
      }
      formActions={{
        lastStepAction: handleLastStepAction,
        onSubmit: handleFormSubmit,
        submitBtnText: 'Propose',
        handleStepper: handleStepper,
        disableSubmit:
          stepperLoading || !enrollmentCodebooks || !courses?.length
      }}
    />
  );
};

export default ExternshipProposalProgramStep;
