import * as React from 'react';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import CompanyCardWithActions, {
  Action
} from '@pages/EcAdmin/ContentManagementPage/EmployerSpotlightContent/CompanyCardWithActions';
import { AdminContentManagementApi } from '@api/AdminContentManagement.api ';
import { CompanyStatus } from '@api/models/adminDashboardApi.models';
import EmployerSpotlightModal from './EmployerSpotlightModal';
import IconWithLabel from '@components/IconWithLabel';
import { PromotedCompany } from '@api/models/adminContentManagementApi.models';
import StepSection from '@components/StepSection';
import { styled } from '@mui/system';
import { useFetchAdminPromotedCompanies } from '@common/fetches/useFetchAdminPromotedCompanies';

const Styled = {
  CompanyDetailsWrapper: styled(Box)({
    display: 'flex',
    columnGap: '16px',
    rowGap: '8px',
    flexWrap: 'wrap'
  })
};

const EmployerSpotlightContent: React.FC = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const [currSpotlight, setCurrSpotlight] = React.useState<PromotedCompany>();
  const [spotlightSaved, setSpotlightSaved] = React.useState<boolean>(false);

  const {
    fetchPromotedCompanies,
    promotedCompanies,
    setPromotedCompanies,
    loading
  } = useFetchAdminPromotedCompanies();

  React.useEffect(() => {
    if (spotlightSaved) {
      fetchPromotedCompanies();
      setSpotlightSaved(false);
    }
  }, [spotlightSaved]);

  const handleDelete = async (id: number): Promise<void> => {
    try {
      await AdminContentManagementApi.deletePromotedCompany(id);
      const updatedPromotedCompanies = promotedCompanies.filter(
        (pc) => pc.company.id !== id
      );
      setPromotedCompanies(updatedPromotedCompanies);
    } catch (error: any) {
      console.error(
        `Error for EmployerSpotlightContent.deleteHotJob(): ${error}`
      );
    }
  };

  const handleEdit = (spotlight: PromotedCompany): void => {
    setCurrSpotlight(spotlight);
    setOpenModal(true);
  };

  const getActions = (spotlight: PromotedCompany): Action[] => {
    return [
      {
        icon: 'ri-edit-2-line',
        label: 'edit',
        onClick: (): void => handleEdit(spotlight)
      },
      {
        icon: 'ri-delete-bin-6-line',
        label: 'delete',
        onClick: (): Promise<void> => handleDelete(spotlight.company.id)
      }
    ];
  };

  const companyDetails = (
    company: CompanyStatus
  ): { icon: string; label: string }[] => {
    return [
      {
        icon: 'ri-calendar-event-fill',
        label: 'Joined: ' + company.createdDate
      },
      ...company.admins.map((e) => ({
        icon: 'ri-mail-fill',
        label: e.email
      }))
    ];
  };

  const handleOpenModal = (): void => {
    setOpenModal(true);
  };

  const handleCloseModal = (spotlightSaved: boolean): void => {
    setOpenModal(false);
    setSpotlightSaved(spotlightSaved);
    setCurrSpotlight(undefined);
  };

  return (
    <StepSection>
      <Typography variant="EC_TYPE_4XL">Employer Spotlight</Typography>
      <Typography variant="EC_TYPE_BASE">
        Highlight Gold Crown Partner employers in the carousel above the Career
        Marketplace. Candidates can select through the spotlighted employers, or
        wait as they cycle through automatically.
      </Typography>
      <Button variant="contained" fullWidth onClick={handleOpenModal}>
        Add Spotlight
      </Button>
      {loading ? (
        <Skeleton variant="rectangular" height={300} />
      ) : (
        <Box>
          {promotedCompanies?.map((promotedCompany) => {
            const { company } = promotedCompany;
            const imgUrl = promotedCompany.spotlightImage
              ? promotedCompany.spotlightImage.fullPath
              : company.logoUrl;

            return (
              <CompanyCardWithActions
                key={company.id}
                id={company.id}
                name={company.companyName}
                image={imgUrl}
                content={
                  <Styled.CompanyDetailsWrapper>
                    {companyDetails(company).map((detail) => (
                      <IconWithLabel
                        smallest
                        key={detail.label}
                        title={detail.label}
                        icon={detail.icon}
                      />
                    ))}
                  </Styled.CompanyDetailsWrapper>
                }
                actions={getActions(promotedCompany)}
              />
            );
          })}
        </Box>
      )}
      {openModal && (
        <EmployerSpotlightModal
          open={openModal}
          handleClose={handleCloseModal}
          promotedCompanies={promotedCompanies}
          currSpotlight={currSpotlight}
        />
      )}
    </StepSection>
  );
};

export default EmployerSpotlightContent;
