/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  ICompanySettings,
  SentInvite,
  SetCompanySettings
} from '@api/models/settingsApi.models';
import { Toast, useToast } from '@components/Toast';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { Box } from '@mui/material';
import CompanyNameChangeForm from './CompanyNameChangeForm';
import { ESourceGroupWithAccessDto } from '@interfaces/glrc.interfaces';
import { GlrcPermissions } from '@api/models/glrcAccess.models';
import { JoinRequest } from '@api/models/companyApi.models';
import SeatManagement from './SeatManagement';

export interface CompanySettingsRedesignProps {
  companyId: number;
  companySettings: ICompanySettings;
  sentInvites?: SentInvite[];
  joinRequests: JoinRequest[];
  setCompanySettings: SetCompanySettings;
  fetchSentInvites: () => void;
  glrcSeats?: number;
  glrcPermissions?: GlrcPermissions;
  handleSeatRequestUpdate: () => void;
  esourceGroups: ESourceGroupWithAccessDto[];
  setESourceGroups: React.Dispatch<
    React.SetStateAction<ESourceGroupWithAccessDto[]>
  >;
  tabValue: string;
  setTabValue: React.Dispatch<React.SetStateAction<string>>;
}

const CompanySettingsRedesign: React.FC<CompanySettingsRedesignProps> = ({
  ...props
}) => {
  const { openToast, open, ...toastProps } = useToast();
  const { companySettings, setCompanySettings, companyId } = props;

  return (
    <Box>
      <BottomMarginContentWrapper bottomMargin="34px">
        <CompanyNameChangeForm
          openToast={openToast}
          companyId={companyId}
          companySettings={companySettings}
          setCompanySettings={setCompanySettings}
        />
        <SeatManagement openToast={openToast} {...props} />
      </BottomMarginContentWrapper>
      <Toast open={open} {...toastProps} />
    </Box>
  );
};

export default CompanySettingsRedesign;
