import * as React from 'react';
import { Box, Button, Link, Modal, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';

const WIDTH = 786;
const MEDIA_QUERY_MIN = `@media (min-width:${WIDTH + 1}px)`;

const Styled = {
  ModalBox: styled(Box)({
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 4px rgba(51,51,51,0.15)',
    padding: '36px 16px 24px',
    borderRadius: '4px',
    overflow: 'hidden',
    [MEDIA_QUERY_MIN]: {
      padding: '72px 75px',
      borderRadius: '10px'
    }
  }),
  Title: styled(Typography)({
    marginBottom: '12px',
    [MEDIA_QUERY_MIN]: {
      marginBottom: '26px'
    }
  }),
  ButtonsWrapper: styled(Box)({
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'end',
    marginTop: '50px',
    rowGap: '10px',
    [MEDIA_QUERY_MIN]: {
      flexDirection: 'row',
      columnGap: '28px',
      marginTop: '70px'
    }
  }),
  CloseIcon: styled('i')(({ theme }) => ({
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: theme.palette.GRAY_3.main,
    fontSize: '16px',
    lineHeight: '16px',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.red2.main
    },
    [MEDIA_QUERY_MIN]: {
      top: '20px',
      right: '20px'
    }
  })),
  TertiaryBtnBox: styled(Box)({
    alignSelf: 'start',
    marginTop: '20px',
    [MEDIA_QUERY_MIN]: {
      alignSelf: 'end',
      position: 'relative',
      top: '20px',
      marginTop: 0
    }
  })
};

interface Props {
  open: boolean;
  handleClose: () => void;
  keepModalOpen?: boolean;
  title: string;
  handlePrimaryAction: () => void;
  primaryCtaLabel: string;
  handleSecondaryAction: () => void;
  secondaryCtaLabel: string;
  tertiaryCtaPath?: string;
  tertiaryCtaLabel?: string;
  dataTestId?: string;
  largeTitle?: boolean;
}

const PrimaryModal: React.FC<Props> = ({
  open,
  handleClose,
  keepModalOpen = false,
  title,
  handlePrimaryAction,
  primaryCtaLabel,
  handleSecondaryAction,
  secondaryCtaLabel,
  tertiaryCtaPath,
  tertiaryCtaLabel,
  dataTestId,
  largeTitle,
  children
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-content"
      data-testid={dataTestId}
      sx={{ '.MuiBox-root': { outline: 0 } }}
    >
      <Styled.ModalBox data-testid="content-box">
        <Box>
          {!keepModalOpen && (
            <Styled.CloseIcon className="ri-close-line" onClick={handleClose} />
          )}
          <Styled.Title
            variant={largeTitle ? 'EC_TYPE_4XL' : 'EC_TYPE_LG'}
            id="modal-title"
          >
            {title}
          </Styled.Title>
          <Box id="modal-content">{children}</Box>
          <Styled.ButtonsWrapper>
            <Button
              sx={{ height: '42px', minWidth: '110px' }}
              data-testid="secondary-btn"
              variant="outlined"
              className="secondary"
              color="neutral"
              onClick={handleSecondaryAction}
            >
              {secondaryCtaLabel}
            </Button>
            <Button
              sx={{ height: '42px', minWidth: '142px' }}
              data-testid="primary-btn"
              onClick={handlePrimaryAction}
              variant="contained"
              className="primary"
            >
              {primaryCtaLabel}
            </Button>
          </Styled.ButtonsWrapper>
        </Box>
        {tertiaryCtaPath && tertiaryCtaLabel && (
          <Styled.TertiaryBtnBox>
            <Link component={RouterLink} to={tertiaryCtaPath} underline="none">
              <Button variant="text" sx={{ padding: 0 }}>
                {tertiaryCtaLabel}
              </Button>
            </Link>
          </Styled.TertiaryBtnBox>
        )}
      </Styled.ModalBox>
    </Modal>
  );
};

export default PrimaryModal;
