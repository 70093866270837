import * as React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { OutlinedInput, Stack, Theme, Typography } from '@mui/material';
import FormFieldErrorText from '@components/Forms/FormFieldErrorText';
import InputMask from 'react-input-mask';
import { styled } from '@mui/system';
import { useGetReactHookFormErrorsByName } from '@common/helpers/useGetReactHookFormErrorsByName';

const Styled = {
  TextFieldContainer: styled(Stack)({
    position: 'relative'
  }),
  Label: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'required'
  })<{ required?: boolean }>(({ required, theme }) => ({
    marginBottom: '11px',
    position: 'relative',
    ['@media (max-width:480px)']: {
      marginBottom: '5px',
      fontSize: (theme as Theme).typography.EC_TYPE_SM.fontSize,
      lineHeight: (theme as Theme).typography.EC_TYPE_SM.lineHeight
    },

    '&:after': {
      content: required ? '"*"' : '""',
      color: theme.palette.error.main,
      marginLeft: '4px'
    }
  }))
};

interface Props {
  name: string;
  label?: string;
  autocomplete?: string;
  rules?: RegisterOptions;
  dataTestId?: string;
  icon?: JSX.Element;
  placeholder?: string;
  rows?: number;
  tooltip?: JSX.Element;
  disabled?: boolean;
  disableAsterisk?: boolean;
  useFieldArrayNaming?: boolean;
  fieldNamePrefix?: string;
  disableErrorAbsolutePosition?: boolean;
}

const ReactHookFormsInputPhoneMask: React.FC<Props> = ({
  label,
  name,
  rules,
  placeholder,
  dataTestId,
  tooltip = undefined,
  disabled = false,
  disableAsterisk = false,
  useFieldArrayNaming = false,
  fieldNamePrefix,
  disableErrorAbsolutePosition = false
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const { errorsAtName } = useGetReactHookFormErrorsByName(
    name,
    errors,
    useFieldArrayNaming,
    fieldNamePrefix
  );

  return (
    <Styled.TextFieldContainer id={name}>
      {(label || tooltip) && (
        <Stack
          direction="row"
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Styled.Label
            data-testid={`${name}-input-label`}
            variant="EC_TYPE_BASE"
            required={!!rules?.required && !disableAsterisk}
          >
            {label}
          </Styled.Label>
          {tooltip}
        </Stack>
      )}
      <Controller
        name={name}
        control={control}
        rules={{ ...rules }}
        render={({ field }): JSX.Element => {
          return (
            <>
              <InputMask
                mask="(999) 999-9999"
                disabled={disabled}
                maskChar=" "
                {...field}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ): void => {
                  field.onChange(event);
                }}
              >
                {(): JSX.Element => (
                  <OutlinedInput
                    placeholder={placeholder}
                    error={!!errorsAtName}
                    disabled={disabled}
                    inputProps={{
                      'data-testid': dataTestId
                    }}
                  />
                )}
              </InputMask>
            </>
          );
        }}
      />
      {errorsAtName && (
        <FormFieldErrorText
          name={name}
          message={errorsAtName.message}
          disableAbsolutePosition={disableErrorAbsolutePosition}
        />
      )}
    </Styled.TextFieldContainer>
  );
};

export default ReactHookFormsInputPhoneMask;
