/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import DashboardTableLayout, {
  DashboardTableLayoutProps
} from '@components/TableComponents/DashboardTableLayout';
import { Toast, useToast } from '@components/Toast';
import { useLocation, useParams } from 'react-router-dom';
import { ApplicationStatusCodebook } from '@api/models/codebook.models';
import { AppliedCandidate } from '@api/models/jobApplicationApi.models';
import { Box } from '@mui/material';
import companyDefault from '@assets/svg/employer-def.svg';
import DashboardLoading from '@components/TableComponents/DashboardLoading';
import { getEmployerPathWithParamsId } from '@common/helpers/getPathWithParamsId';
import { JobApplicationApi } from '@api/JobApplication.api';
import JobCandidateTable from '@pages/Employer/JobCandidates/JobCandidateTable';
import { JobPostApi } from '@api/JobPost.api';
import { JobPostType } from '@api/models/singleJobPostApi.models';
import NoDataMessage from '@components/NoDataMessage';
import { ResponsiveLayoutProvider } from '@components/Layout/ResponsiveLayout';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { styled } from '@mui/system';
import { TableSortWrapperProps } from '@components/TableWrapperWithSort';
import { useFetchApplicationStatusCodebooks } from '@common/fetches/useFetchApplicationStatusCodebooks';
import { useFetchCandidatesByJobId } from '@common/fetches/useFetchCandidatesByJobId';
import { useFetchCompanyProfile } from '@common/fetches/useFetchCompanyProfile';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';
import { useTagManager } from '@common/hooks/useTagManager';
import { useWindowDimensions } from '@common/hooks/uiHelpers';
import { VettedQuestionsAnswers } from '@api/models/jobApplicationApi.models';

const CUSTOM_BREAKPOINT = 768;

const Styled = {
  NoDataWrapper: styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  })
};

interface IParams {
  jobId: string;
  companyId: string;
}

interface RouterState {
  state: {
    jobTitle: string;
    companyName?: string;
  };
}

interface SortButtonProps {
  label: string;
  // path to value
  id: string;
}

const sortButtons: SortButtonProps[] = [
  {
    label: 'Date Applied',
    id: 'submittedDate'
  },
  {
    label: 'First Name',
    id: 'firstName'
  },
  {
    label: 'Last Name',
    id: 'lastName'
  },
  {
    label: 'Status',
    id: 'status.value'
  }
];

const JobApplicantsPage: React.FC = () => {
  const { sendToGtm } = useTagManager();
  const { state } = useLocation() as RouterState;
  const { jobId } = useParams<IParams>();
  const { companyId, paramsId } = useParamsOrStoreCompanyId();

  const { companyProfile, loaded: companyProfileLoaded } =
    useFetchCompanyProfile(companyId);

  const {
    applicantData,
    invitedCandidates,
    loaded: candidatesLoaded
  } = useFetchCandidatesByJobId(jobId);

  const isDirectConnectPost =
    !!applicantData &&
    applicantData.length &&
    applicantData[0].jobPost.jobPostType === JobPostType.DIRECT_CONNECT;

  const { statuses, loaded: statusesLoaded } =
    useFetchApplicationStatusCodebooks();

  const [candidates, setCandidates] = React.useState<AppliedCandidate[]>([]);
  const [jobTitle, setJobTitle] = React.useState<string>();
  const [companyName, setCompanyName] = React.useState<string>();

  const updatedCandidate = React.useRef<AppliedCandidate | null>(null);

  const { width } = useWindowDimensions();

  const { openToast, ...otherToastProps } = useToast();

  const breadcrumbTitle = companyId
    ? `${companyName}'s Dashboard`
    : 'Dashboard';

  const mappedData = React.useMemo(() => {
    if (applicantData) {
      const candidateData: AppliedCandidate[] = applicantData.map((data) => {
        const { candidate } = data;
        return {
          candidateId: candidate.id,
          firstName: candidate.firstName,
          lastName: candidate.lastName,
          email: candidate.email,
          phone: candidate.phone,
          address: candidate.locations
            .map((loc: { description: string }) => loc.description)
            .join('\n'),
          facebookUrl: candidate.facebookUrl,
          instagramUrl: candidate.instagramUrl,
          linkedInUrl: candidate.linkedInUrl,
          websiteUrl: candidate.websiteUrl,
          status: data.status,
          submittedDate: new Date(data.submittedDate).toLocaleDateString(
            'en-US'
          ),
          jobApplicationId: data.id,
          resume: data?.resume,
          interestInWorkAndLearn: data.education,
          jobOffersWorksAndLearn: data.jobPost.workLearnEligible
        };
      });

      const vettedQuestionData: VettedQuestionsAnswers[] = isDirectConnectPost
        ? []
        : applicantData.map((data) => {
            const { jobPost } = data;
            return {
              candidateId: data.candidate.id,
              jobPostId: jobPost.id,
              jobYearsExp: jobPost.experienceLevel,
              jobTechSkills:
                jobPost.technicalSkills?.map((skill) => skill.label) ?? [],
              jobSoftSkills:
                jobPost.softSkills?.map((skill) => skill.label) ?? [],
              answerYearsExp: data.experience,
              answerWorkLearn: data.education,
              answerTechSkills: data.technicalSkills?.map((s) => s.label) ?? [],
              answerSoftSkills: data.softSkills?.map((s) => s.label) ?? []
            };
          });

      return { candidateData, vettedQuestionData };
    }
  }, [applicantData]);

  const fetchJobTitle = async (): Promise<void> => {
    try {
      const res = await JobPostApi.getJobPostById(jobId);

      setJobTitle(res.data.jobTitle);
      setCompanyName(res.data.company.name);
    } catch (error) {
      console.error(
        'Error for JobApplicantsPage -> JobPostApi.getJobPostById()',
        error
      );
    }
  };

  React.useEffect(() => {
    if (state) {
      setJobTitle(state.jobTitle);
      setCompanyName(state.companyName);
    } else {
      fetchJobTitle();
    }
  }, []);

  React.useEffect(() => {
    if (mappedData?.candidateData) {
      setCandidates(mappedData.candidateData);
    }
  }, [mappedData]);

  const handleCandidateStatusChange = async (
    jobAppId: AppliedCandidate['jobApplicationId'],
    status: ApplicationStatusCodebook,
    candidate: AppliedCandidate
  ): Promise<void> => {
    const postBody = {
      statusId: status.value
    };

    try {
      const res = await JobApplicationApi.updateJobApplicationStatus(
        jobAppId,
        postBody
      );

      sendToGtm('candidate_application_status', {
        candidate_id: candidate.candidateId,
        application_id: jobAppId,
        job_id: jobId,
        status: status,
        job_offers_work_and_learn: candidate.jobOffersWorksAndLearn,
        candidates_work_and_learn_interest: candidate.interestInWorkAndLearn
      });

      setCandidates((prevState: AppliedCandidate[]) =>
        prevState?.map((candidate: AppliedCandidate) => {
          if (candidate.jobApplicationId === jobAppId) {
            return Object.assign({}, candidate, {
              status: res.data.status
            });
          } else {
            return candidate;
          }
        })
      );

      const found =
        candidates.find(
          (candidate) => candidate.jobApplicationId === jobAppId
        ) || null;
      if (found) {
        updatedCandidate.current = found;
      }
      openToast(`Candidate status updated to ${status.label}`);
    } catch (error: any) {
      console.error(
        'Error for JobApplicantsPage.handleCandidateStatusChange()',
        error
      );
    }
  };

  React.useEffect(() => {
    if (updatedCandidate) {
      setTimeout(() => {
        updatedCandidate.current = null;
      }, 500);
    }
  }, [updatedCandidate.current]);

  const tableSortWrapperProps: TableSortWrapperProps = {
    headerTitle: breadcrumbTitle,
    sortButtons,
    totalCount: candidates.length,
    breadcrumb: {
      href: getEmployerPathWithParamsId('/dashboard', paramsId),
      location: `${jobTitle} #${jobId}`
    },
    disabled: !candidates || candidates.length === 0,
    displayMobile: width < CUSTOM_BREAKPOINT,
    initialSortCategory: 'submittedDate',
    initialRowsPerPage: 10,
    rowsPerPageOptions: [10, 25, 50, 100]
  };

  const tableLayoutProps: DashboardTableLayoutProps = {
    pageTitle: companyProfile?.name || 'Welcome',
    logo: companyProfile?.logo?.fullPath || companyDefault,
    companyEnabled: companyProfile?.enabled,
    tableSortWrapperProps
  };

  const loaded =
    !!candidatesLoaded &&
    !!statusesLoaded &&
    !!statuses &&
    !!companyProfileLoaded;
  const emptyRows = !!candidatesLoaded && candidates.length < 1;

  return (
    <RootPageLayout>
      {loaded ? (
        <DashboardTableLayout {...tableLayoutProps}>
          <ResponsiveLayoutProvider customBreakpoint={CUSTOM_BREAKPOINT}>
            <JobCandidateTable
              candidates={candidates}
              vettedQuestionsAnswers={mappedData?.vettedQuestionData}
              handleCandidateStatusChange={handleCandidateStatusChange}
              updatedCandidate={updatedCandidate.current}
              statuses={statuses}
              invitedCandidates={invitedCandidates}
            />
          </ResponsiveLayoutProvider>
        </DashboardTableLayout>
      ) : (
        <DashboardLoading />
      )}
      {emptyRows && (
        <Styled.NoDataWrapper>
          <NoDataMessage title="No candidates have applied to this job" />
        </Styled.NoDataWrapper>
      )}
      <Toast {...otherToastProps} />
    </RootPageLayout>
  );
};

export default JobApplicantsPage;
