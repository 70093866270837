import * as React from 'react';
import { Button, Typography, useMediaQuery } from '@mui/material';
import BasicModal from '@components/BasicModal';
import StepSubSection from '@components/StepSubSection';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useKeycloak } from '@react-keycloak/web';
import { useSetCandidateEnabled } from '@common/putRequests/useSetCandidateEnabled';

const Styled = {
  Text: styled(Typography)({
    marginBottom: '19px'
  })
};

interface Props {
  candidateId: number;
}

const DeactivateAccount: React.FC<Props> = ({ candidateId }) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const { setDisabled } = useSetCandidateEnabled();
  const { keycloak } = useKeycloak();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenModal = (): void => {
    setOpen(true);
  };

  const handleCloseModal = (): void => {
    setOpen(false);
  };

  const handleDeactivate = (): void => {
    setDisabled(candidateId);
    keycloak.logout({
      redirectUri: `${window.location.origin}/logout`
    });
  };

  const primaryAction = {
    label: 'Deactivate',
    action: handleDeactivate
  };

  const secondaryAction = {
    label: 'Cancel',
    action: handleCloseModal
  };

  return (
    <>
      <StepSubSection title="Deactivate Account">
        <Styled.Text variant="EC_TYPE_BASE">
          Deactivating your account will hide your profile on the website.
        </Styled.Text>
        <Button
          data-testid="deactivate-account-btn"
          variant="outlined"
          fullWidth={isMobile}
          onClick={handleOpenModal}
        >
          Deactivate
        </Button>
      </StepSubSection>
      <BasicModal
        open={open}
        handleClose={handleCloseModal}
        title="Are you sure?"
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
        maxWidth="md"
      >
        <Typography variant="EC_TYPE_BASE">
          If you deactivate your profile, you will not be able to apply to any
          jobs. To regain normal functionality, you will have to reactivate your
          account.
        </Typography>
      </BasicModal>
    </>
  );
};

export default DeactivateAccount;
