import { useEffect, useState } from 'react';
import { CompanyApi } from '@api/Company.api';
import { EmployerProfile } from '@api/models/employerApi.models';

export type FetchEmployerByKeycloakUserId = {
  employer?: EmployerProfile;
  loaded: boolean;
  loading: boolean;
};

export const useFetchEmployerByKeycloakUserId = (
  keycloakUserId: string
): FetchEmployerByKeycloakUserId => {
  const [employer, setEmployer] = useState<EmployerProfile>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchEmployerByKeycloakUserId = async (
    keycloakUserId: string
  ): Promise<void> => {
    try {
      setLoading(true);
      const res = await CompanyApi.getByKeycloakId(keycloakUserId);

      setEmployer(res.data);
    } catch (error: any) {
      console.error(
        'Error for useFetchEmployerByKeycloakUserId -> CompanyApi.getByKeycloakId()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (keycloakUserId) {
      fetchEmployerByKeycloakUserId(keycloakUserId);
    }
  }, [keycloakUserId]);

  return {
    employer,
    loaded,
    loading
  };
};
