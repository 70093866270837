/* eslint-disable */
import { useEffect, useState } from 'react';
import {
  CourseWithExternships,
  EnrollmentCoursesExternships
} from '@api/models/enrollmentApi.models';
import { ExternshipApi } from '@api/Externships.api';
import { ExternshipEntity } from '@api/models/externshipApi.models';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';

export type FetchExternships = {
  externshipById?: ExternshipEntity;
  externshipsByCandidate?: ExternshipEntity[];
  candidateEnrollments: EnrollmentCoursesExternships[];
  coursesAssignable: boolean; // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove when flag is updated
  loaded: true | null;
  loading: boolean;
  hasAvailableExternshipCourses: (candidateId: number) => Promise<void>; // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove when flag is updated
  fetchByCandidateId: (candidateOrStudentId: number | string) => Promise<void>;
  setExternshipsByCandidate: React.Dispatch<
    React.SetStateAction<ExternshipEntity[] | undefined> // eslint-disable-line @typescript-eslint/indent
  >;
  // setCandidateEnrollments: React.Dispatch<
  //   React.SetStateAction<EnrollmentCoursesExternships[]> // eslint-disable-line @typescript-eslint/indent
  // >;
  setCandidateEnrollments: (value: EnrollmentCoursesExternships[]) => void;
  addExternshipToState: (updatedExternship: ExternshipEntity) => void;
  removeExternshipFromState: (externshipId: number) => void;
};

export const useFetchExternships = (
  candidateId?: number | string | null,
  externshipIdFromParams?: string | number | null
): FetchExternships => {
  const featureFlags = useFeatureFlags();
  const [externshipById, setExternshipById] = useState<ExternshipEntity>();

  // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove when flag is updated
  const [externshipsByCandidate, setExternshipsByCandidate] =
    useState<ExternshipEntity[]>();

  const [candidateEnrollments, setCandidateEnrollments] = useStore<
    EnrollmentCoursesExternships[] // eslint-disable-line @typescript-eslint/indent
  >(UseStoreKeys.STUDENT_ENROLLMENT_STORE, []);
  const [loaded, setLoaded] = useState<true | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [coursesAssignable, setCoursesAssignable] = useState<boolean | null>(
    null
  );

  const fetchByCandidateId = async (
    candidateOrStudentId: number | string
  ): Promise<void> => {
    try {
      setLoading(true);

      // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - Only need getByStudentNumber once flag is updated
      if (
        featureFlags.MULTIPLE_EXTERNSHIPS_PER_COURSE &&
        typeof candidateOrStudentId === 'string'
      ) {
        const res = await ExternshipApi.getByStudentNumber(
          candidateOrStudentId as string
        );

        setCandidateEnrollments(res.data as EnrollmentCoursesExternships[]);
      } else {
        const res = await ExternshipApi.getByCandidateId(
          candidateOrStudentId as number
        );

        setExternshipsByCandidate(res.data as ExternshipEntity[]);
      }
    } catch (error: any) {
      console.error(
        'Error for useFetchExternships -> ExternshipApi.getByCandidateId()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  const fetchByExternshipId = async (externshipId: number): Promise<void> => {
    try {
      if (externshipId) setLoading(true);
      const res = await ExternshipApi.getById(externshipId);

      setExternshipById(res.data);
      setLoaded(true);
    } catch (error: any) {
      console.error(
        'Error for useFetchExternships -> ExternshipApi.getById()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  const hasAvailableExternshipCourses = async (
    candidateId: number
  ): Promise<void> => {
    try {
      setLoading(true);
      const res = await ExternshipApi.hasAvailableExternshipCourses(
        candidateId
      );

      setCoursesAssignable(res);
    } catch (error: any) {
      console.error(
        'Error for useFetchExternships -> ExternshipApi.hasAvailableExternshipCourses',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const addExternshipToState = (updatedExternship: ExternshipEntity): void => {
    const prevEnrollments = [...candidateEnrollments];
    const updatedEnrollments: EnrollmentCoursesExternships[] =
      prevEnrollments.map((enrollment) => {
        return {
          ...enrollment,
          courses: enrollment.courses.map((course) => {
            const courseDate = new Date(course.StartDate);
            courseDate.setUTCHours(12);
            console.log('addExternshipToState courseDate', courseDate);

            const updatedCourseDate = new Date(
              updatedExternship.scheduledStartDate
            );
            updatedCourseDate.setUTCHours(12);
            console.log(
              'addExternshipToState updatedCourseDate',
              updatedCourseDate
            );

            if (
              !(
                course.CourseId === updatedExternship.course.Id &&
                courseDate.toISOString() === updatedCourseDate.toISOString()
              )
            ) {
              return course;
            }

            return {
              ...course,
              externships: course.externships.map((externship) => {
                if (externship.id !== updatedExternship.id) {
                  return externship;
                }
                return updatedExternship;
              })
            };
          })
        };
      });

    setCandidateEnrollments(updatedEnrollments);
  };

  const removeExternshipFromState = (externshipId: number): void => {
    const prevEnrollments = [...candidateEnrollments];
    const updatedEnrollments = prevEnrollments?.map((enrollment) => {
      return {
        ...enrollment,
        courses: enrollment.courses.map((course) => {
          return {
            ...course,
            externships: course.externships.filter((e) => e.id !== externshipId)
          };
        })
      };
    });

    setCandidateEnrollments(updatedEnrollments);
  };

  useEffect(() => {
    if (candidateId) {
      if (
        !featureFlags.MULTIPLE_EXTERNSHIPS_PER_COURSE &&
        typeof candidateId === 'number'
      ) {
        // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove when flag is updated
        hasAvailableExternshipCourses(candidateId);
      }
      fetchByCandidateId(candidateId);
    } else if (externshipIdFromParams) {
      const id =
        typeof externshipIdFromParams === 'string'
          ? parseInt(externshipIdFromParams)
          : externshipIdFromParams;

      fetchByExternshipId(id);
    }
  }, [candidateId, externshipIdFromParams, featureFlags]);

  return {
    externshipById,
    externshipsByCandidate,
    candidateEnrollments,
    coursesAssignable: !!coursesAssignable, // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove when flag is updated
    loaded,
    loading,
    fetchByCandidateId,
    setExternshipsByCandidate,
    setCandidateEnrollments,
    addExternshipToState,
    removeExternshipFromState,
    hasAvailableExternshipCourses // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove when flag is updated
  };
};
