import * as React from 'react';
import {
  attestationEmploymentMap,
  attestationWaiverMap
} from '@interfaces/attestation.interface';
import { GRAY_3, OUTLINE_GRAY, WHITE } from '@themes/ui/escoffier';
import { AttestationFormValues } from './ExternshipAttestationPage';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { getSignatureText } from './getSignatureText';
import { PdfTypes } from '@api/models/pdfApi.models';
import { useFormContext } from 'react-hook-form';

const styles = {
  root: {
    padding: '32px 48px 42px',
    width: '8.5in',
    backgroundColor: WHITE
  },
  title: {
    fontFamily: 'trade-gothic-next-condensed',
    fontSize: '28px',
    lineHeight: '30px',
    fontWeight: 700,
    marginBottom: '40px'
  },
  content: {
    display: 'flex',
    flexFlow: 'column nowrap',
    rowGap: '16px'
  },
  infoDiv: {
    flex: 1,
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'start'
  },
  infoDivTwoCol: {
    display: 'flex',
    columnGap: '20px'
  },
  label: {
    fontFamily: 'trade-gothic-next',
    fontSize: '21px',
    fontWeight: 400,
    lineHeight: '26px',
    margin: '0 0 11px 0'
  },
  subLabel: {
    fontSize: '13px',
    fontWeight: 700,
    fontFamily: 'Barlow',
    color: GRAY_3,
    margin: '0 0 8px 0'
  },
  input: {
    width: '100%',
    border: `1px solid ${OUTLINE_GRAY}`,
    borderRadius: '4px',
    padding: '4px 12px',
    lineHeight: 0,
    color: GRAY_3
  },
  sigImage: {
    margin: '0 auto',
    backgroundSize: '200px 50px',
    width: '100%',
    height: '100px',
    backgroundColor: WHITE
  }
};

interface Props {
  attestationTitle: string;
  attestationType?: number;
  signature?: string | null;
}

const AttestationHtmlForPdf = ({
  attestationTitle,
  attestationType,
  signature: _signature
}: Props): React.ReactElement => {
  const { watch } = useFormContext<AttestationFormValues>();
  const [signature, setSignature] = React.useState<string | null | undefined>(
    _signature
  );

  React.useEffect(() => {
    if (_signature) {
      setSignature(_signature);
    }
  }, [_signature]);

  const data = {
    firstName: watch('firstName'),
    lastName: watch('lastName'),
    studentNumber: watch('studentNumber'),
    companyName: watch('companyName'),
    employmentStartDate: watch('employmentStartDate'),
    jobTitle: watch('jobTitle'),
    employmentType: watch('employmentType'),
    waiverType: watch('waiverType')
  };

  const sigText = getSignatureText(attestationType);
  const signDate = formatDate(new Date().toISOString(), 'MM/dd/yyyy');

  return (
    <div style={styles.root} id={PdfTypes.STUDENT_ATTESTATION}>
      <h1 style={styles.title}>{attestationTitle}</h1>
      <div style={styles.content}>
        <div style={styles.infoDiv}>
          <h4 style={styles.label}>Student Number</h4>
          <p>{data.studentNumber}</p>
        </div>

        <div style={styles.infoDivTwoCol}>
          <div style={{ ...styles.infoDiv, width: 'max-content', flex: 0.5 }}>
            <h4 style={styles.label}>First Name</h4>
            <p>{data.firstName}</p>
          </div>
          <div style={{ ...styles.infoDiv, width: 'max-content' }}>
            <h4 style={styles.label}>Last Name</h4>
            <p>{data.lastName}</p>
          </div>
        </div>
        {data.companyName && (
          <div style={styles.infoDiv}>
            <h4 style={styles.label}>Employer</h4>
            <p>{data.companyName}</p>
          </div>
        )}

        {data.employmentStartDate && (
          <div style={styles.infoDiv}>
            <h4 style={styles.label}>Employment Start Date</h4>
            <p style={styles.subLabel}>
              (The day that you first started working for this employer)
            </p>
            <p>{formatDate(data.employmentStartDate?.toString())}</p>
          </div>
        )}

        {data.jobTitle && (
          <div style={styles.infoDiv}>
            <h4 style={styles.label}>Job Title</h4>
            <p>{data.jobTitle}</p>
          </div>
        )}
        {data.employmentType && (
          <div style={styles.infoDiv}>
            <h4 style={styles.label}>Part time or temporary</h4>
            <p>{attestationEmploymentMap[data.employmentType]}</p>
          </div>
        )}
        {data.waiverType && (
          <div style={styles.infoDiv}>
            <h4 style={styles.label}>Reason for waiver</h4>
            <p>{attestationWaiverMap[data.waiverType]}</p>
          </div>
        )}
        {signature && (
          <div
            style={{
              ...styles.infoDiv,
              maxWidth: '500px',
              marginRight: 'auto'
            }}
          >
            <p>{sigText}</p>
            <div style={{ ...styles.input, padding: 0, overflow: 'hidden' }}>
              <img style={styles.sigImage} src={signature} />
            </div>
            <p>Date Signed: {signDate}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AttestationHtmlForPdf;
