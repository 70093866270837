import * as React from 'react';
import { Box, useTheme } from '@mui/material';
import IconWithLabel from '@components/IconWithLabel';
import { styled } from '@mui/system';

const Styled = {
  PreviewPageInfoContainer: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'disablePadding'
  })<{ disablePadding?: boolean }>(({ disablePadding = false }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '31px',
    rowGap: '13px',
    marginBottom: '20px',
    marginTop: disablePadding ? '30px' : 0,
    paddingTop: disablePadding ? 0 : '30px',
    ['@media (max-width:320px)']: {
      paddingTop: '5px'
    }
  }))
};

export type InfoItemType = {
  value?: string | null;
  icon?: string;
  svg?: string;
};

interface OwnProps {
  itemList: InfoItemType[];
  candidateProfile?: boolean;
}

const PreviewPageInfo: React.FC<OwnProps> = ({
  itemList,
  candidateProfile = false
}) => {
  const theme = useTheme();

  return (
    <Styled.PreviewPageInfoContainer
      data-testid="jobInfoContainer"
      disablePadding={candidateProfile}
    >
      {itemList.map((item, index) => {
        if (!item.value) {
          return null;
        }

        return (
          <IconWithLabel
            key={index}
            icon={item?.icon ?? ''}
            svg={item?.svg ?? ''}
            title={item.value}
            color={theme.palette.GRAY_3.main}
            wrapText={true}
          />
        );
      })}
    </Styled.PreviewPageInfoContainer>
  );
};

export default PreviewPageInfo;
