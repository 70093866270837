import * as React from 'react';
import { Bookmark } from '@api/models/bookmarkApi.models';
import { BookmarkApi } from '@api/Bookmark.api';

export type FetchBookmarks = {
  bookmarks: Bookmark[];
  setBookmarks: React.Dispatch<React.SetStateAction<Bookmark[]>>;
  loaded: boolean;
  loading: boolean;
};

export const useFetchBookmarks = (
  userId: string,
  companyId: number
): FetchBookmarks => {
  const [bookmarks, setBookmarks] = React.useState<Bookmark[]>([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchBookmarks = async (
    userId: string,
    companyId: number
  ): Promise<void> => {
    try {
      setLoading(true);
      const res = await BookmarkApi.getBookmarks(userId, companyId);

      setBookmarks(res);
      setLoaded(true);
    } catch (error: any) {
      console.error(
        'Error for useFetchBookmarks -> BookmarkApi.getBookmarks()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    fetchBookmarks(userId, companyId);
  }, []);

  return {
    bookmarks,
    setBookmarks,
    loaded,
    loading
  };
};
