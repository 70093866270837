import { DateTime } from 'luxon';

export const getDateMonthAndYear = (isoDate?: string | null): string => {
  if (isoDate) {
    const dateTime = DateTime.fromISO(isoDate);
    return `${dateTime.monthShort} ${dateTime.year}`;
  }

  return 'Present';
};
