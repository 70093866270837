import * as React from 'react';
import { ExternshipApi } from '@api/Externships.api';
import { TimesheetStatus } from '@api/models/externshipApi.models';

export type FetchTimesheetStatuses = {
  timesheetStatuses: any[];
  loaded: boolean;
  loading: boolean;
};

export const useFetchTimesheetStatuses = (): FetchTimesheetStatuses => {
  const [timesheetStatuses, setTimesheetStatuses] = React.useState<
    TimesheetStatus[] // eslint-disable-line @typescript-eslint/indent
  >([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchTimesheetStatuses = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await ExternshipApi.getTimesheetStatuses();

      setTimesheetStatuses(res);
      setLoaded(true);
    } catch (error: any) {
      console.error(
        'Error for useFetchTimesheetStatuses -> ExternshipApi.getTimesheetStatuses()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    fetchTimesheetStatuses();
  }, []);

  return {
    timesheetStatuses,
    loaded,
    loading
  };
};
