import * as React from 'react';
import ReactHookFormsRichText from '@components/Forms/ReactHookFormsRichText';
import StepSection from '@components/StepSection';
import StepSubSection from '@components/StepSubSection';

type PropsType = {
  defaultValue?: string;
};

const PersonalStatementSection: React.FC<PropsType> = (props) => {
  const { defaultValue } = props;

  return (
    <StepSection
      title="Personal Statement"
      dataTestId="candidate-personalStatement-section"
    >
      <StepSubSection title="What makes you a unique candidate?" required>
        <ReactHookFormsRichText
          defaultValue={defaultValue}
          name="statement"
          label="Share how your background, identity, interests or talents drive your
          curiosity and perspective."
          rules={{ required: 'Personal statement is required' }}
        />
      </StepSubSection>
    </StepSection>
  );
};

export default PersonalStatementSection;
