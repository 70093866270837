import * as React from 'react';
import bakingImg from '@assets/WorkAndLearnPage/candidate.work_learn_tab.baking.jpg';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import culinaryImg from '@assets/WorkAndLearnPage/candidate.work_learn_tab.culinary.jpg';
import hospitalityImg from '@assets/WorkAndLearnPage/candidate.work_learn_tab.hospitality.jpg';
import nutritionImg from '@assets/WorkAndLearnPage/candidate.work_learn_tab.nutrition.jpg';
import overviewImg from '@assets/WorkAndLearnPage/candidate.work_learn_tab.overview.jpg';
import plantBasedImg from '@assets/WorkAndLearnPage/candidate.work_learn_tab.plant_based.jpg';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';

const Styled = {
  List: styled('ul')({
    paddingLeft: 0,
    marginLeft: '2px'
  }),
  ListItem: styled('li')({
    paddingLeft: 0,
    marginLeft: '16px'
  })
};

interface TextProps {
  children: React.ReactNode;
}

const Text = ({ children }: TextProps): JSX.Element => (
  <Typography variant="EC_TYPE_BASE">{children}</Typography>
);

export const tabContent = [
  {
    value: '1',
    image: overviewImg,
    cardTitle: 'Overview',
    cardContent: (
      <>
        <Text>
          Whether you want to hone your skills, advance your career, or are just
          starting out in the culinary industry, Escoffier can help you get
          there.
        </Text>
        <Styled.List>
          <Styled.ListItem>
            <Text>
              Classes from different disciplines to help you gain skills for
              your culinary career
            </Text>
          </Styled.ListItem>
          <Styled.ListItem>
            <Text>
              Our online programs allow you to learn from anywhere with internet
              access and a working kitchen
            </Text>
          </Styled.ListItem>
          <Styled.ListItem>
            <Text>
              Support from Escoffier teams like Career Services and Student
              Services can help you reach your goals
            </Text>
          </Styled.ListItem>
          <Styled.ListItem>
            <Text>
              Financial Aid may be available to those who apply and qualify
            </Text>
          </Styled.ListItem>
        </Styled.List>
      </>
    )
  },
  {
    value: '2',
    image: culinaryImg,
    cardTitle: 'Culinary Arts',
    cardContent: (
      <BottomMarginContentWrapper bottomMargin="32px">
        <Text>
          The Diploma in Culinary Arts is designed to produce competent
          professionals for potential employment at entry-level positions in
          restaurants, resorts, hotels, and other foodservice establishments.
        </Text>
        <Text>
          The Associate of Occupational Studies (AOS) Degree in Culinary Arts
          program was designed to prepare students to enter the foodservice and
          hospitality industry. The competencies to be achieved in this program
          deliver a comprehensive knowledge of foodservice preparation,
          operations, and management.
        </Text>
        <Text>
          These curriculums cover both cooking techniques and professional
          skills, such as menu planning, safety, and food cost control. This
          program emphasizes hands-on experience through an externship in a
          foodservice establishment.
        </Text>
      </BottomMarginContentWrapper>
    )
  },
  {
    value: '3',
    image: bakingImg,
    cardTitle: 'Baking & Pastry',
    cardContent: (
      <BottomMarginContentWrapper bottomMargin="32px">
        <Text>
          The Diploma in Pastry Arts is designed to give students a solid
          foundation to the Pastry Arts and to prepare them for entry- to
          mid-level jobs as a baker, pastry assistant or assistant pastry chef.
        </Text>
        <Text>
          The Associate of Occupational Studies (AOS) in Baking and Pastry
          program builds on knowledge learned in the diploma program and was
          developed to provide the practical, as well as theoretical foundations
          essential for success in the foodservice and hospitality industry.
        </Text>
        <Text>
          Both curriculums combine lectures, demonstrations, baking, and
          evaluation for an integrated approach to pastry arts and culminate
          with an externship to provide on-the-job experience.
        </Text>
      </BottomMarginContentWrapper>
    )
  },
  {
    value: '4',
    image: plantBasedImg,
    cardTitle: 'Plant-Based Culinary Arts',
    cardContent: (
      <BottomMarginContentWrapper bottomMargin="32px">
        <Text>
          The Diploma and the Associate of Occupational Studies (AOS) in
          Plant-Based Culinary Arts programs teach the fundamentals of
          plant-based cooking, including ingredients, meal composition, cultural
          cuisines, food safety, and ingredient substitutions. Novices and
          experienced chefs alike will enjoy learning the fundamentals of
          plant-based cooking, including the tools needed and basic ingredients
          to have on hand.
        </Text>
        <Text>
          The focus is on creating nutritious, inclusive, and delicious
          plant-based food that is relevant in today&apos;s food culture. These
          programs culminate in an externship course to build on-the-job
          experience.
        </Text>
      </BottomMarginContentWrapper>
    )
  },
  {
    value: '5',
    image: nutritionImg,
    cardTitle: 'Holistic Nutrition & Wellness',
    cardContent: (
      <BottomMarginContentWrapper bottomMargin="32px">
        <Text>
          The Diploma and the Associate of Occupational Studies (AOS) in
          Holistic Nutrition and Wellness programs provide education in both
          culinary arts and nutrition/wellness for those wanting to enter the
          health-conscious food industry. The programs cover topics such as
          nutrition, wellness coaching, and a foundation in culinary skills to
          support clients looking to improve their health through food.
        </Text>
        <Text>
          Both programs include an externship to support additional learning and
          on-the-job experience. Programs in Holistic Nutrition and Wellness are
          only available through Escoffier’s online programs.
        </Text>
      </BottomMarginContentWrapper>
    )
  },
  {
    value: '6',
    image: hospitalityImg,
    cardTitle: 'Hospitality & Restaurant Operations Management',
    cardContent: (
      <BottomMarginContentWrapper bottomMargin="32px">
        <Text>
          The Associate of Occupational Studies (AOS) in Hospitality and
          Restaurant Operations Management program was developed to provide
          students with the understanding of the hospitality industry from a
          business perspective. It covers topics such as leadership, HR
          management, event management, purchasing, cost control, and menu
          management, as well as technical writing, nutrition, math &
          accounting, communication, and culinary history.
        </Text>
        <Text>
          The curriculum includes an externship in a foodservice establishment
          and is only available through Escoffier’s online programs.
        </Text>
      </BottomMarginContentWrapper>
    )
  }
];
