import * as React from 'react';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import RootPageLayout from '@components/Layout/RootPageLayout';
import theme from '@themes/ui/escoffier';
import { Typography } from '@mui/material';

interface Props {
  title: string;
}

const LegalPageWrapper: React.FC<Props> = ({ title, children }) => {
  return (
    <RootPageLayout>
      <BottomMarginContentWrapper bottomMargin="40px">
        <Typography
          variant="EC_TYPE_3XL"
          color={theme.palette.GRAY_4.main}
          mb={0}
        >
          {title}
        </Typography>
        {children}
      </BottomMarginContentWrapper>
    </RootPageLayout>
  );
};

export default LegalPageWrapper;
