import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'disabled'
  })<{ disabled: boolean }>(({ disabled }) => ({
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    pointerEvents: disabled ? 'none' : 'auto',
    ':hover': {
      cursor: disabled ? 'default' : 'pointer'
    }
  })),
  Icon: styled('i', {
    shouldForwardProp: (prop) => prop !== 'isSmaller'
  })<{ isSmaller?: boolean }>(({ theme, isSmaller }) => ({
    fontSize: isSmaller ? '10px' : '12px',
    lineHeight: isSmaller ? '13px' : '15px',
    marginRight: '4px',
    color: theme.palette.BLACK.main
  })),
  Svg: styled('img')(({ theme }) => ({
    marginRight: '4px',
    color: theme.palette.BLACK.main
  })),
  Text: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'wrapText'
  })<{ wrapText: boolean }>(({ wrapText, theme }) => ({
    color: theme.palette.BLACK.main,
    whiteSpace: wrapText ? 'normal' : 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }))
};

interface IconWithLabelProps {
  title: string;
  icon?: string;
  svg?: string;
  smallest?: boolean;
  wrapText?: boolean;
  iconStyle?: React.CSSProperties;
  color?: string;
  disabled?: boolean | null;
  onClick?: () => void;
  dataTestId?: string;
}

const IconWithLabel: React.FC<IconWithLabelProps> = (props): JSX.Element => {
  const {
    title,
    icon,
    svg,
    smallest = false,
    wrapText = false,
    iconStyle,
    color,
    disabled = null,
    onClick,
    dataTestId = 'jobItemInfoContainer'
  } = props;
  const textVariant = smallest ? 'EC_TYPE_3XS' : 'EC_TYPE_XS';
  const _disabled = disabled ?? !onClick;

  return (
    <Styled.Root
      data-testid={dataTestId}
      disabled={_disabled}
      onClick={onClick}
    >
      {icon && (
        <Styled.Icon
          isSmaller={smallest}
          data-testid="jobItemIcon"
          className={icon}
          sx={{ color: color ?? 'default', ...iconStyle }}
        />
      )}
      {svg && <Styled.Svg src={svg} data-testid="jobItemSvg" />}
      <Styled.Text
        variant={textVariant}
        wrapText={wrapText}
        sx={{ color: color ?? 'default' }}
        data-testid={`${dataTestId}-text`}
      >
        {title}
      </Styled.Text>
    </Styled.Root>
  );
};

export default IconWithLabel;
