export interface ApiConfig {
  adminContentManagementUrl: string;
  adminDashboardUrl: string;
  attestationsUrl?: string;
  bannersUrl: string;
  candidateSSOSignupUrl: string;
  candidateSignupUrl: string;
  candidateUrl: string;
  careerUrl: string;
  careerServiceUrl: string;
  codebooksUrl: string;
  companyUrl: string;
  contactUsUrl: string;
  employerSSOSignupUrl: string;
  employerSignupUrl: string;
  employerUrl: string;
  externshipManagerSignupUrl: string;
  signupWithInviteUrl: string;
  externshipsUrl: string;
  filesUrl: string;
  gridTableUrl: string;
  inviteUrl: string;
  jobApplicationUrl: string;
  jobPostUrl: string;
  notificationsFrequencyUrl: string;
  notificationsUrl: string;
  partnersUrl: string;
  permissionsUrl: string;
  pdfUrl: string;
  quizUrl: string;
  recruiterSignupUrl: string;
  searchUrl: string;
  secureFilesUrl: string;
  settingsUrl: string;
  studentUrl: string;
  videosUrl: string;
}

export const apiConfig: ApiConfig = {
  adminContentManagementUrl: '/admin/content-mgt',
  adminDashboardUrl: '/admin/dashboard',
  attestationsUrl: '/attestations',
  bannersUrl: '/banners',
  candidateSignupUrl: '/signup',
  candidateSSOSignupUrl: '/sso-signup',
  candidateUrl: '/candidates',
  careerUrl: '/careers',
  careerServiceUrl: '/career-service',
  codebooksUrl: '/codebooks',
  companyUrl: '/companies',
  contactUsUrl: '/contact-us',
  employerSignupUrl: '/employers/signup',
  employerSSOSignupUrl: '/employers/sso-signup',
  employerUrl: '/employers',
  externshipManagerSignupUrl: '/externship-manager/signup',
  signupWithInviteUrl: '/signup-with-invite',
  externshipsUrl: '/externships',
  filesUrl: '/files',
  gridTableUrl: '/grid-tables',
  inviteUrl: '/invite',
  jobApplicationUrl: '/job-applications',
  jobPostUrl: '/job-posts',
  notificationsFrequencyUrl: '/notifications-frequency',
  notificationsUrl: '/notifications',
  partnersUrl: '/partners',
  permissionsUrl: '/permissions',
  pdfUrl: '/pdf',
  quizUrl: '/quiz',
  recruiterSignupUrl: '/employers/recruiter-signup',
  searchUrl: '/search',
  secureFilesUrl: '/files/secure',
  settingsUrl: '/settings',
  studentUrl: '/students',
  videosUrl: '/videos'
};
