import * as React from 'react';
import { roles } from '@api/models/userApi.models';
import TagManager from 'react-gtm-module';
import { useKeycloak } from '@react-keycloak/web';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';

type PageProps = {
  children: React.ReactNode;
};

const GoogleAnalyticsWrapper: React.FC<PageProps> = ({ children }) => {
  const { keycloak } = useKeycloak();
  const [companyId] = useStore<number>(UseStoreKeys.COMPANY_ID);
  const [candidateId] = useStore<number>(UseStoreKeys.CANDIDATE_ID);

  const getUserInfo = (): { userId?: number; role?: roles } => {
    const isEmployer = keycloak.hasResourceRole(roles.EMPLOYER);
    const isCandidate = keycloak.hasResourceRole(roles.CANDIDATE);

    if (isEmployer) {
      return { userId: companyId, role: roles.EMPLOYER };
    }

    if (isCandidate) {
      return { userId: candidateId, role: roles.CANDIDATE };
    }

    return { userId: undefined, role: undefined };
  };

  React.useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID,
      auth: process.env.REACT_APP_GTM_AUTH,
      preview: process.env.REACT_APP_GTM_PREVIEW,
      dataLayer: {
        ...getUserInfo()
      }
    });
  }, []);

  return <>{children}</>;
};

export default GoogleAnalyticsWrapper;
