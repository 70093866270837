import * as React from 'react';
import { CandidateContentProps } from '@pages/Candidate/candidatePreviewPageComponentProps';
import CandidateProfileContentSection from './CandidateProfileContentSection';

const CandidateProfileContent = (
  props: CandidateContentProps
): React.ReactElement => {
  const {
    workExperience,
    education,
    certificates,
    candidateCulinaryPhotos,
    statement
  } = props;

  return (
    <>
      {!!workExperience?.length && (
        <CandidateProfileContentSection
          title="experience"
          content={workExperience}
          contentType="workExperience"
        />
      )}
      {!!education?.length && (
        <CandidateProfileContentSection
          title="education"
          content={education}
          contentType="education"
        />
      )}
      {!!certificates?.length && (
        <CandidateProfileContentSection
          title="certifications"
          content={certificates}
          contentType="codebook"
          flexDisplay
        />
      )}
      {!!candidateCulinaryPhotos?.length && (
        <CandidateProfileContentSection
          title="photos"
          content={candidateCulinaryPhotos}
          contentType="candidateCulinaryPhotos"
          flexDisplay
        />
      )}
      <CandidateProfileContentSection
        title="personal statement"
        content={[statement]}
        contentType="statement"
        disableVerticalPadding
      />
    </>
  );
};

export default CandidateProfileContent;
