import * as React from 'react';
import { Button, Grid, Stack, Typography } from '@mui/material';
import {
  CandidateSignupRequest,
  CandidateSSOSignupRequest
} from '@api/models/authApi.models';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import {
  IdentityProviders,
  IKeycloakUser,
  KeycloakUserRole
} from '@api/models/userApi.models';
import { AuthApi } from '@api/Auth.api';
import Box from '@mui/material/Box';
import CandidateAboutMeSection from './CandidateAboutMeSection';
import CandidateSignUpSection from './CandidateSignUpSection';
import GoogleButton from '@components/GoogleButton';
import { GraduationTypes } from '@api/models/adminDashboardApi.models';
import { Location } from '@interfaces/location.interfaces';
import { loginWithRedirect } from '@common/helpers/login';
import RhfAcceptTermsCheckbox from '@components/Forms/RhfAcceptTermsCheckbox';
import { scrollToError } from '@common/helpers/scrollToError';
import StudentComplianceModal from '@pages/Public/CreateAccountPage/StudentComplianceModal';
import { styled } from '@mui/system';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchKeycloakUserProfile } from '@common/fetches/useFetchKeycloakUserProfile';
import { useKeycloak } from '@react-keycloak/web';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import { useTagManager } from '@common/hooks/useTagManager';

const Styled = {
  Root: styled(Box)({
    margin: '0 auto',
    maxWidth: '474px',
    '& .about-me': {
      marginTop: '42px'
    }
  }),
  FormLabel: styled(Typography)({
    marginBottom: '27px'
  }),
  GridItem: styled(Grid)({
    marginBottom: '34px'
  }),
  CreateAccountBtn: styled(Button)({
    marginTop: '52px',
    height: '49px',
    width: '227px',
    // EC-Type-lg
    fontSize: '20px',
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '25px',
    letterSpacing: '0em',
    ['@media (max-width:600px)']: {
      width: '100%'
    }
  }),
  SsoButtonsWrapper: styled(Stack)({
    '& > Button': {
      marginBottom: '36px'
    }
  }),
  LegalTextBox: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '24px',
    '& .legal-text': {
      marginTop: '24px',
      lineHeight: '24px'
    }
  })
};

export interface CreateAccountFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  acceptTerms: boolean;
  graduationType: string;
  interests: number[];
  locations: Location[];
  phone: string;
  studentId?: string;
}

interface Props {
  setEmailConfirmationSent: React.Dispatch<React.SetStateAction<boolean>>;
  user: IKeycloakUser;
}

const CreateCandidateAccountForm: React.FC<Props> = ({
  setEmailConfirmationSent,
  user
}) => {
  const { keycloakUserProfile } = useFetchKeycloakUserProfile();

  // TODO: Fix studentID in keycloak to be 10 digit ID (currently pulling in 6 digit ID)
  const keycloakStudentId = keycloakUserProfile?.attributes?.studentnumber?.[0];

  const [disableBtn, setDisableBtn] = React.useState(false);
  const [userIdpArray] = useStore<string[]>(UseStoreKeys.USER_IDP_ARRAY);
  const isStudentPortalIdp = userIdpArray?.includes(
    IdentityProviders.STUDENT_PORTAL
  );

  const { sendToGtm } = useTagManager();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [complianceModalOpen, setComplianceModalOpen] = React.useState(false);

  const featureFlags = useFeatureFlags();

  const { keycloak } = useKeycloak();

  const methods = useForm<CreateAccountFormValues>({
    defaultValues: {
      firstName: user?.given_name || '',
      lastName: user?.family_name || '',
      email: user?.email || '',
      password: '',
      confirmPassword: '',
      graduationType: '',
      interests: [],
      locations: [],
      phone: '',
      acceptTerms: false,
      studentId: keycloakStudentId || ''
    },
    shouldUnregister: true
  });

  const formOrder = {
    firstName: 1,
    lastName: 2,
    email: 3,
    password: 4,
    confirmPassword: 5,
    graduationType: 6,
    interests: 7,
    locations: 8,
    phone: 9,
    acceptTerms: 10
  };

  const { handleSubmit, watch, setValue, setError } = methods;

  const currPassword = watch('password');
  const termsAccepted = watch('acceptTerms');
  const email = watch('email');
  const gradType = watch('graduationType') && parseInt(watch('graduationType'));

  React.useEffect(() => {
    if (isStudentPortalIdp) {
      setValue(
        'graduationType',
        GraduationTypes.EC_STUDENT as unknown as string
      );

      setValue('studentId', keycloakStudentId || '');
    }
  }, [isStudentPortalIdp, keycloakStudentId]);

  const handleModalOpen = (): void => {
    setModalIsOpen(true);
  };

  const handleModalClose = (): void => {
    setModalIsOpen(false);
  };

  const handleComplianceModalOpen = (): void => {
    setComplianceModalOpen(true);
  };

  const handleComplianceModalClose = (): void => {
    setComplianceModalOpen(false);
  };

  const handleAcceptTermsAndConditions = (): void => {
    setValue('acceptTerms', true, { shouldValidate: true });
    handleModalClose();
  };

  const handleInvalidForm = (errors: FieldErrors): void => {
    setComplianceModalOpen(false);
    scrollToError(errors, formOrder);
  };

  const handleCreateAccount = (data: CreateAccountFormValues): void => {
    setDisableBtn(true);
    setComplianceModalOpen(false);

    const postBody: CandidateSignupRequest = {
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      email: data.email,
      password: data.password,
      confirm_password: data.confirmPassword,
      graduationType: parseInt(data.graduationType),
      interests: data.interests,
      locations: data.locations,
      phone: data.phone,
      ...(data.studentId && { studentId: data.studentId })
    };

    let successfullyCreated;

    if (!user) {
      AuthApi.candidateSignUp(postBody)
        .then(() => {
          successfullyCreated = true;
          setEmailConfirmationSent(true);
        })
        .catch((error: any) => {
          successfullyCreated = false;

          const errData = error.response.data.data;

          if (errData.length) {
            errData.forEach(({ field, message }) =>
              setError(field, { type: 'manual', message })
            );
          }

          scrollToError(methods.formState.errors, formOrder);
        })
        .finally(() => {
          setDisableBtn(false);

          sendToGtm('sign_up', {
            role: KeycloakUserRole.CANDIDATE,
            success: successfullyCreated
          });
        });
    } else {
      const ssoPostBody: CandidateSSOSignupRequest = {
        userId: user.sub,
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        email: data.email,
        graduationType: parseInt(data.graduationType),
        locations: data.locations,
        phone: data.phone,
        ...(data.studentId && { studentId: data.studentId })
      };

      AuthApi.candidateSSOSignup(ssoPostBody)
        .then(() => {
          loginWithRedirect(null, keycloak.login);
        })
        .finally(() => {
          setDisableBtn(false);
        });
    }
  };

  return (
    <>
      <Styled.Root>
        <Styled.SsoButtonsWrapper>
          {featureFlags.GOOGLE_SSO && <GoogleButton user={user} />}
        </Styled.SsoButtonsWrapper>
        <FormProvider {...methods}>
          <form>
            <Styled.FormLabel variant="EC_TYPE_3XL">Sign Up</Styled.FormLabel>
            <CandidateSignUpSection
              user={user}
              email={email}
              currPassword={currPassword}
            />

            {featureFlags.CANDIDATE_ACCOUNT_UPDATES && (
              <>
                <Styled.FormLabel variant="EC_TYPE_3XL" className="about-me">
                  About Me
                </Styled.FormLabel>
                <CandidateAboutMeSection />
              </>
            )}
            <Box mt={4}>
              <RhfAcceptTermsCheckbox
                termsAccepted={termsAccepted}
                modalOpen={modalIsOpen}
                setModalOpen={handleModalOpen}
                setModalClosed={handleModalClose}
                handleAcceptTermsAndConditions={handleAcceptTermsAndConditions}
              />
            </Box>
            <Styled.CreateAccountBtn
              variant="contained"
              disabled={disableBtn}
              onClick={handleComplianceModalOpen}
            >
              Create Account
            </Styled.CreateAccountBtn>
          </form>
        </FormProvider>
        {featureFlags.CANDIDATE_ACCOUNT_UPDATES && gradType === 99 && (
          <Styled.LegalTextBox>
            <Typography variant="EC_TYPE_3XS" className="legal-text">
              Creating an account constitutes your express written consent to be
              contacted via phone, text, and/or emails by Auguste Escoffier
              School of Culinary Arts at the number(s)/email you provided,
              regarding furthering your education. You understand that these
              calls may be generated using an automated technology.
            </Typography>
            <Typography variant="EC_TYPE_3XS" className="legal-text">
              You can unsubscribe at any time or request removal of street
              address, phone number, email address via Escoffier website.
            </Typography>
          </Styled.LegalTextBox>
        )}
      </Styled.Root>
      <StudentComplianceModal
        open={complianceModalOpen}
        handleClose={handleComplianceModalClose}
        handleContinueClick={handleSubmit(
          handleCreateAccount,
          handleInvalidForm
        )}
      />
    </>
  );
};

export default CreateCandidateAccountForm;
