import {
  Applicant,
  ApplicantsRes,
  PageInfoSearchReq
} from '@api/models/adminDashboardApi.models';
import { ApplicationOverview, DateRange } from '@common/models/common.models';
import {
  ApproveJoinRequest,
  CompaniesSpotlight,
  CompanyBenefitsGetRequest,
  CompanyBenefitsPutRequest,
  CompanyCulturePutRequest,
  CompanyEthosPutRequest,
  CompanyMediaPutRequest,
  CompanyProfile,
  CompanyPutRequest,
  CompanySettingsPutRequest,
  CompanyTheme,
  CompanyThemePutRequest,
  JoinRequest,
  Subsidiary,
  UpdateCompanyLocationRequest
} from '@api/models/companyApi.models';
import { api } from '@api/configs/base';
import { apiConfig } from '@api/configs/api.config';
import { ApiResponse } from '@api/models/base.models';
import { EmployerProfile } from '@api/models/employerApi.models';

export class CompanyApi {
  static async getCompanyDetailsById(
    id: number
  ): Promise<ApiResponse<CompanyProfile>> {
    try {
      const response = await api.get<ApiResponse<CompanyProfile>>(
        `${apiConfig.companyUrl}/${id}`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for CompanyApi.getCompanyDetailsById(): ${error}`);
      throw error;
    }
  }

  static async getCompanyPublicById(
    id: number
  ): Promise<ApiResponse<CompanyProfile>> {
    try {
      const response = await api.get<ApiResponse<CompanyProfile>>(
        `${apiConfig.companyUrl}/${id}/public`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for CompanyApi.getCompanyPublicById(): ${error}`);
      throw error;
    }
  }

  static async getCompanyBenefitsById(
    id: number
  ): Promise<ApiResponse<CompanyBenefitsGetRequest>> {
    try {
      const response = await api.get<ApiResponse<CompanyBenefitsGetRequest>>(
        `${apiConfig.companyUrl}/${id}/benefits`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for CompanyApi.getCompanyBenefitsById(): ${error}`);
      throw error;
    }
  }

  static async getByKeycloakId(
    key: string
  ): Promise<ApiResponse<EmployerProfile>> {
    try {
      const response = await api.get<ApiResponse<EmployerProfile>>(
        `${apiConfig.employerUrl}/user/${key}`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for CompanyApi.getByKeycloakId(): ${error}`);
      throw error;
    }
  }

  static async getAllEmployers(): Promise<ApiResponse<EmployerProfile[]>> {
    try {
      const response = await api.get<ApiResponse<EmployerProfile[]>>(
        `${apiConfig.employerUrl}`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for CompanyApi.getAllEmployers(): ${error}`);
      throw error;
    }
  }

  static async getEmployersByCompanyId(
    id: number
  ): Promise<ApiResponse<EmployerProfile[]>> {
    try {
      const response = await api.get<ApiResponse<EmployerProfile[]>>(
        `${apiConfig.companyUrl}/${id}/employers`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for CompanyApi.getEmployersByCompanyId(): ${error}`);
      throw error;
    }
  }

  static async updateCompany(
    id: number,
    data: CompanyPutRequest
  ): Promise<void> {
    try {
      await api.put(`${apiConfig.companyUrl}/${id}`, data);
      return;
    } catch (error: any) {
      console.error(`Error for CompanyApi.updateCompany(): ${error}`);
      throw error;
    }
  }

  static async updateCompanyLocation(
    id: number | undefined,
    data: UpdateCompanyLocationRequest
  ): Promise<CompanyProfile> {
    try {
      const response = await api.put<ApiResponse<CompanyProfile>>(
        `${apiConfig.companyUrl}/${id}/updateLocation`,
        data
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for CompanyApi.updateCompany(): ${error}`);
      throw error;
    }
  }

  static async updateCompanyPhotos(
    id: number,
    data: CompanyMediaPutRequest
  ): Promise<void> {
    try {
      await api.put(`${apiConfig.companyUrl}/${id}/photos`, data);
      return;
    } catch (error: any) {
      console.error(`Error for CompanyApi.updateCompanyPhotos(): ${error}`);
      throw error;
    }
  }

  static async updateCompanyEthos(
    id: number,
    data: CompanyEthosPutRequest
  ): Promise<void> {
    try {
      await api.put(`${apiConfig.companyUrl}/${id}/ethos`, data);
      return;
    } catch (error: any) {
      console.error(`Error for CompanyApi.updateCompanyEthos(): ${error}`);
      throw error;
    }
  }

  static async updateCompanyCulture(
    id: number,
    data: CompanyCulturePutRequest
  ): Promise<void> {
    try {
      await api.put(`${apiConfig.companyUrl}/${id}/culture`, data);
      return;
    } catch (error: any) {
      console.error(`Error for CompanyApi.updateCompanyCulture(): ${error}`);
      throw error;
    }
  }

  static async updateCompanyBenefits(
    id: number,
    data: CompanyBenefitsPutRequest
  ): Promise<void> {
    try {
      await api.put(`${apiConfig.companyUrl}/${id}/benefits`, data);
      return;
    } catch (error: any) {
      console.error(`Error for CompanyApi.updateCompanyBenefits(): ${error}`);
      throw error;
    }
  }

  static async updateCompanyTheme(
    companyId: number,
    data: CompanyThemePutRequest
  ): Promise<CompanyTheme> {
    try {
      const response = await api.put<ApiResponse<CompanyTheme>>(
        `${apiConfig.companyUrl}/${companyId}/theme`,
        data
      );
      return response.data.data;
    } catch (error: any) {
      console.error(`Error for CompanyApi.updateCompanyTheme(): ${error}`);
      throw error;
    }
  }

  static async getTheme(companyId: number): Promise<CompanyTheme | undefined> {
    try {
      const response = await api.get<ApiResponse<CompanyTheme | undefined>>(
        `${apiConfig.companyUrl}/${companyId}/theme`
      );
      return response.data.data;
    } catch (error: any) {
      console.error(`Error for CompanyApi.getTheme(): ${error}`);
      throw error;
    }
  }

  static async getRandomCompanies(
    limit: number
  ): Promise<ApiResponse<CompaniesSpotlight[]>> {
    try {
      const response = await api.get<ApiResponse<CompaniesSpotlight[]>>(
        `${apiConfig.companyUrl}/spotlight?limit=${limit}`
      );
      return response.data;
    } catch (error: any) {
      console.error(`Error for CompanyApi.getRandomCompanies(): ${error}`);
      throw error;
    }
  }

  static async updateCompanySettings(
    id: number,
    data: CompanySettingsPutRequest
  ): Promise<void> {
    try {
      await api.put(`${apiConfig.companyUrl}/${id}/settings`, data);
      return;
    } catch (error: any) {
      console.error(`Error for CompanyApi.updateCompanySettings(): ${error}`);
      throw error;
    }
  }

  static async getSubsidiariesByCompanyId(
    companyId: number
  ): Promise<ApiResponse<Subsidiary[]>> {
    try {
      const response = await api.get<ApiResponse<Subsidiary[]>>(
        `${apiConfig.companyUrl}/${companyId}/subsidiaries`
      );
      return response.data;
    } catch (error: any) {
      console.error(`Error for CompanyApi.getSubsidiaries(): ${error}`);
      throw error;
    }
  }

  static async getParentStats(
    id: number,
    req?: DateRange
  ): Promise<ApiResponse<ApplicationOverview>> {
    try {
      const response = await api.post<ApiResponse<ApplicationOverview>>(
        `${apiConfig.companyUrl}/${id}/dashboard/subsidiaries`,
        req
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for CompanyApi.getParentStats(): ${error}`);
      throw error;
    }
  }

  static async getParentCompanyApplicants(
    parentCompanyId: number,
    req: PageInfoSearchReq
  ): Promise<ApiResponse<ApplicantsRes>> {
    try {
      const response = await api.post<ApiResponse<ApplicantsRes>>(
        `${apiConfig.companyUrl}/${parentCompanyId}/applicants`,
        req
      );

      return response.data;
    } catch (error: any) {
      console.error(
        `Error for CompanyApi.getParentCompanyApplicants(): ${error}`
      );
      throw error;
    }
  }

  static async getParentCompanyApplicantsCsv(
    parentCompanyId: number,
    req: DateRange
  ): Promise<ApiResponse<Applicant[]>> {
    try {
      const response = await api.post<ApiResponse<Applicant[]>>(
        `${apiConfig.companyUrl}/${parentCompanyId}/applicants/csv`,
        req
      );

      return response.data;
    } catch (error: any) {
      console.error(
        `Error for CompanyApi.getParentCompanyApplicantsCsv(): ${error}`
      );
      throw error;
    }
  }

  static async getSubsidiaryCompanyApplicants(
    parentCompanyId: number,
    companyId: number,
    req: PageInfoSearchReq
  ): Promise<ApiResponse<ApplicantsRes>> {
    try {
      const response = await api.post<ApiResponse<ApplicantsRes>>(
        `${apiConfig.companyUrl}/${parentCompanyId}/subsidiaries/${companyId}/applicants`,
        req
      );

      return response.data;
    } catch (error: any) {
      console.error(
        `Error for CompanyApi.getSubsidiaryCompanyApplicants(): ${error}`
      );
      throw error;
    }
  }

  static async getSubsidiaryCompanyApplicantsCsv(
    parentCompanyId: number,
    companyId: number,
    req: DateRange
  ): Promise<ApiResponse<Applicant[]>> {
    try {
      const response = await api.post<ApiResponse<Applicant[]>>(
        `${apiConfig.companyUrl}/${parentCompanyId}/subsidiaries/${companyId}/applicants/csv`,
        req
      );

      return response.data;
    } catch (error: any) {
      console.error(
        `Error for CompanyApi.getSubsidiaryCompanyApplicantsCsv(): ${error}`
      );
      throw error;
    }
  }

  static async getPendingRequests(companyId: number): Promise<JoinRequest[]> {
    try {
      const response = await api.get<ApiResponse<JoinRequest[]>>(
        `${apiConfig.companyUrl}/${companyId}/join-requests/pending`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for CompanyApi.getPendingRequests(): ${error}`);
      throw error;
    }
  }

  static async approveJoinRequest(
    companyId: number,
    joinRequestId: number,
    req: ApproveJoinRequest
  ): Promise<JoinRequest[]> {
    try {
      const response = await api.post<ApiResponse<JoinRequest[]>>(
        `${apiConfig.companyUrl}/${companyId}/join-requests/${joinRequestId}/approve`,
        req
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for CompanyApi.approveJoinRequest(): ${error}`);
      throw error;
    }
  }

  static async rejectJoinRequest(
    companyId: number,
    joinRequestId: number
  ): Promise<JoinRequest[]> {
    try {
      const response = await api.put<ApiResponse<JoinRequest[]>>(
        `${apiConfig.companyUrl}/${companyId}/join-requests/${joinRequestId}/reject`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for CompanyApi.rejectJoinRequest(): ${error}`);
      throw error;
    }
  }

  static async getJoinRequestsByUserId(userId: string): Promise<JoinRequest[]> {
    try {
      const response = await api.get<ApiResponse<JoinRequest[]>>(
        `${apiConfig.companyUrl}/join-requests/user/${userId}`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(`Error for CompanyApi.getJoinRequestsByUserId(): ${error}`);
      throw error;
    }
  }

  static async addSubsidiariesToParent(
    parentCompanyId: number,
    subsidiaryIds: number[]
  ): Promise<void> {
    try {
      for (const id of subsidiaryIds) {
        await api.put(
          `${apiConfig.companyUrl}/${id}/parent/${parentCompanyId}`
        );
      }

      return;
    } catch (error: any) {
      console.error(`Error for CompanyApi.addSubsidiariesToParent(): ${error}`);
      throw error;
    }
  }

  static async removeParentFromSubsidiary(subCompanyId: number): Promise<void> {
    try {
      await api.put(`${apiConfig.companyUrl}/${subCompanyId}/parent/remove`);
    } catch (error: any) {
      console.error(
        `Error for CompanyApi.removeParentFromSubsidiary(): ${error}`
      );
      throw error;
    }
  }
}
