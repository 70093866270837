/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  GridTable,
  GridTableView,
  SaveGridTableViewColumnsDto,
  SaveGridTableViewDto,
  SaveGridTableViewFiltersDto
} from '@api/models/gridTableApi.models';
import BasicModal from '@components/BasicModal';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { ExternshipTrackerView } from '@common/fetches/useFetchGridTableByType';
import { GridColDef } from '@mui/x-data-grid-pro';
import { GridTableApi } from '@api/GridTable.api';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import { Typography } from '@mui/material';
import { useKeycloakContext } from '@common/context/keycloakContext';

interface Props {
  open: boolean;
  handleClose: (e?) => void;
  gridTable: GridTable;
  columns: GridColDef[];
  viewToRename?: GridTableView;
  getFilterColumns: (columns: GridColDef[]) => SaveGridTableViewFiltersDto;
  getSaveGridTableViewColumns: () => SaveGridTableViewColumnsDto[];
  setGridTable: React.Dispatch<React.SetStateAction<GridTable | undefined>>;
  setCurrView: React.Dispatch<
    React.SetStateAction<ExternshipTrackerView | undefined>
  >;
}

interface FormValues {
  viewName: string;
}

const SaveGridTableViewModal: React.FC<Props> = ({
  open,
  handleClose,
  viewToRename,
  gridTable,
  columns,
  getFilterColumns,
  getSaveGridTableViewColumns,
  setGridTable,
  setCurrView
}) => {
  const { keycloakUser } = useKeycloakContext();
  const [isSaving, setIsSaving] = React.useState(false);

  const methods = useForm<FormValues>({
    defaultValues: {
      viewName: viewToRename?.name || ''
    }
  });

  const { handleSubmit, reset } = methods;

  React.useEffect(() => {
    if (viewToRename) {
      reset({ viewName: viewToRename.name });
    }
  }, [viewToRename]);

  const handleValidForm = async (data: FormValues): Promise<void> => {
    if (gridTable) {
      try {
        setIsSaving(true);

        if (viewToRename) {
          const updatedView = await GridTableApi.updateGridTableView(
            viewToRename.id,
            data.viewName
          );

          const newGridTable = {
            ...gridTable,
            customViews: gridTable.customViews.map((v) => {
              if (v.id === viewToRename.id) {
                return {
                  ...v,
                  name: updatedView.name
                };
              }
              return v;
            })
          };
          setGridTable(newGridTable);
        } else {
          const postBody: SaveGridTableViewDto = {
            name: data.viewName,
            filters: getFilterColumns(columns),
            viewColumns: getSaveGridTableViewColumns(),
            ordinalNumber:
              [
                ...(gridTable?.defaultViews || []),
                ...(gridTable?.customViews || [])
              ].length + 1
          };

          const newView = await GridTableApi.saveGridTableView(
            gridTable.id,
            keycloakUser.sub,
            postBody
          );

          const newGridTable: GridTable = {
            ...gridTable,
            columns: newView.viewColumns
              .sort((a, b) => (a.ordinalNumber > b.ordinalNumber ? 1 : -1))
              .map((vc) => vc.column),
            customViews: [...gridTable.customViews, newView]
          };

          // Must set grid first to avoid MUI select errpr where option isnt found
          setGridTable(newGridTable);
          setCurrView({ currView: newView.id });
        }

        reset({ viewName: '' });
        handleClose();
      } catch (error) {
        console.error(error);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleSaveView = (e): void => {
    e.stopPropagation();
    handleSubmit(handleValidForm)();
  };

  return (
    <BasicModal
      open={open}
      handleClose={handleClose}
      title={viewToRename ? 'Rename View' : 'Save View'}
      primaryAction={{
        label: 'Save',
        action: (e): void => handleSaveView(e),
        disabled: isSaving
      }}
      secondaryAction={{ label: 'Cancel', action: handleClose }}
      maxWidth="sm"
    >
      <BottomMarginContentWrapper bottomMargin="24px">
        {!viewToRename && (
          <Typography variant="EC_TYPE_BASE">
            Give a name to the current layout for easy access to these columns
            and filters.
          </Typography>
        )}
        <FormProvider {...methods}>
          <ReactHookFormsInput
            name="viewName"
            label="View Name"
            labelVariant="EC_TYPE_XS"
            labelStyles={{ color: 'GRAY_4.main', textTransform: 'uppercase' }}
            rules={{ required: 'View name is required' }}
          />
        </FormProvider>
      </BottomMarginContentWrapper>
    </BasicModal>
  );
};

export default SaveGridTableViewModal;
