/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  Box,
  Button,
  Icon,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import BasicModal from '@components/BasicModal';
import { ExternshipApi } from '@api/Externships.api';
import { ExternshipApproverEntity, ExternshipStatus, StudentViewExternship } from '@api/models/externshipApi.models';
import { getUIBaseURL } from '@common/helpers/getBaseUIUrl';
import { styled } from '@mui/system';
import { useToast } from '@components/Toast';
import { useStudentViewContext } from '@common/context/studentViewContext';

enum Links {
  EMPLOYER_DASHBOARD = 1,
  CREATE_ACCOUNT = 2,
  TIMESHEET = 3
}

interface ResendModalsProps {
  externshipId: number;
  externshipStatus: number;
  open: boolean;
  closeModal: () => void;
  approver: ExternshipApproverEntity;
}
const Styled = {
  EmailText: styled(Typography)({
    margin: '8px',
    marginBottom: '25px'
  }),
  ResendBtn: styled(Button)(({ theme }) => ({
    ...(theme as Theme).typography.EC_TYPE_2XS,
    whiteSpace: 'nowrap',
    '&.MuiButtonBase-root:hover': {
      bgcolor: 'transparent'
    },
    fontSize: '15px !important'
  })),
  ResendIcon: styled(Icon)({
    fontSize: '15px !important'
  }),
  MissingSignatureText: styled('span')(({ theme }) => ({
    ...(theme as Theme).typography.EC_TYPE_2XS,
    color: theme.palette.error.main
  })),
  WarningIcon: styled('i', {
    shouldForwardProp: (prop) => prop !== 'leftMargin'
  })<{ leftMargin?: boolean }>(({ leftMargin }) => ({
    marginLeft: leftMargin ? '8px' : 0,
    marginRight: '4px'
  }))
};

const ResendModals: React.FC<ResendModalsProps> = ({
  externshipId,
  externshipStatus,
  approver,
  open,
  closeModal,
}) => {
  const theme = useTheme();
  const { openToast } = useToast();
  const [resendError, setResendError] = React.useState<string>();
  const [showTooltop, setShowTooltop] = React.useState<boolean>(false);
  const [showTimesheetTooltop, setShowTimesheetTooltop] =
    React.useState<boolean>(false);
  const baseURL = getUIBaseURL();

  const { setExternships } = useStudentViewContext();

  const isPendingSoftVerify =
    externshipStatus === ExternshipStatus.PENDING_SOFT_VERIFICATION;
  const accountCreated = !!approver?.user;

  const showCreateAccount = !isPendingSoftVerify && !accountCreated;

  const showTimesheet =
    externshipStatus === ExternshipStatus.ACTIVE && !showCreateAccount;

  const showEmployerDashboard = !showCreateAccount && !showTimesheet;

  const employerDashboardLink = `${baseURL}/employer/dashboard`;
  const createAccountLink = `${baseURL}/create-account?invite-code=${approver.invite?.inviteCode}`;
  const timesheetLink = `${baseURL}/externship/${externshipId}/timesheets`;

  const sendButtonLabel = approver.invite?.sentAt ? 'Resend' : 'Send';
  const sendTitle = approver.invite?.sentAt ? 'Resend' : 'Send';
  const sendCopy = approver.invite?.sentAt ? 'Send another invitation to create an account to:' : 'Send invitation to create an account to:';

  const handleCopy = async (linkType: Links): Promise<void> => {
    const linkToCopy: string | undefined =
      linkType === Links.CREATE_ACCOUNT
        ? createAccountLink
        : linkType === Links.EMPLOYER_DASHBOARD
          ? employerDashboardLink // eslint-disable-line @typescript-eslint/indent
          : linkType === Links.TIMESHEET // eslint-disable-line @typescript-eslint/indent
            ? timesheetLink // eslint-disable-line @typescript-eslint/indent
            : undefined; // eslint-disable-line @typescript-eslint/indent

    if (linkToCopy) {
      try {
        await navigator.clipboard.writeText(linkToCopy);
        linkType === Links.TIMESHEET
          ? setShowTimesheetTooltop(true)
          : setShowTooltop(true);

        setTimeout(() => {
          linkType === Links.TIMESHEET
            ? setShowTimesheetTooltop(false)
            : setShowTooltop(false);
        }, 2000);
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    }
  };

  const handleResend = async (): Promise<void> => {
    try {

      const res = await ExternshipApi.sendExternshipApproverInvite(externshipId, approver.id);

      const mapUpdatedApproverToExternship = (
        externship: StudentViewExternship
      ): StudentViewExternship => {
        if (externship.id === externshipId) {
          const approvers = externship.approvers.map((approver) => {
            if (approver.id === res.id) {
              approver = res;
            }

            return approver;
          });

          return {
            ...externship,
            approvers,
          };
        }
        return { ...externship };
      };

      setExternships((prev) => prev.map(mapUpdatedApproverToExternship));

      closeModal();
    } catch (error: any) {
      console.error(`Error for ResendModals -> handleResend: ${error}`);
      const errorMsg = error?.response?.data?.message;
      setResendError(errorMsg);
    } finally {
      openToast('Proposal invitation resent successfully.');
    }
  };

  return (
    <BasicModal
      open={open}
      handleClose={closeModal}
      {...(!showTimesheet && {
        primaryAction: {
          label: sendButtonLabel,
          action: handleResend,
        },
        secondaryAction: {
          label: 'Cancel',
          action: closeModal
        }
      })}
      maxWidth="sm"
    >
      <Box>
        {showCreateAccount && (
          <>
            {approver.invite && !accountCreated ? (
              <>
                <Typography variant="EC_TYPE_LG">{sendTitle} Invite</Typography>
                <Typography sx={{ mt: 2 }}>
                  {sendCopy}
                </Typography>
                <Styled.EmailText
                  sx={{
                    typography: (theme as Theme).typography.EC_TYPE_SM
                  }}
                >
                  {approver.invite?.email}
                </Styled.EmailText>

                {approver.invite?.inviteCode && (
                  <>
                    <Typography sx={{ mt: 2 }}>Or copy the direct link:</Typography>

                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <TextField
                        fullWidth
                        value={createAccountLink}
                        InputProps={{
                          readOnly: true,
                          style: {
                            ...(theme as Theme).typography.AEGS_TYPE_PARAGRAPH,
                            color: theme.palette.primary.main,
                            fontWeight: 'bold'
                          }
                        }}
                        variant="outlined"
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          pl: '20px'
                        }}
                      >
                        <Tooltip
                          title={'The link was copied!'}
                          placement="top"
                          open={showTooltop}
                        >
                          <Styled.ResendBtn
                            variant="text"
                            className="disable-while-loading"
                            startIcon={
                              <Styled.ResendIcon className="ri-links-line" />
                            }
                            onClick={(): Promise<void> =>
                              handleCopy(Links.CREATE_ACCOUNT)
                            }
                          >
                            Copy link
                          </Styled.ResendBtn>
                        </Tooltip>
                      </Box>
                    </Box>
                </>
                )}
              </>
            ) : (
              <Box>
                The invite has not been used yet, indicating that the manager
                has either not created their account or has not signed the
                externship agreement.
              </Box>
            )}
          </>
        )}

        {showEmployerDashboard && (
          <>
            <Typography variant="EC_TYPE_LG">
              Resend Link to Employer Dashboard
            </Typography>
            <Typography sx={{ m: '15px 45px 35px 0' }}>
              Please copy the following link to the employer dashboard and share
              it with the approver or resend it. This will allow them to sign
              any necessary documents or log in as needed.
            </Typography>

            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <TextField
                fullWidth
                value={employerDashboardLink}
                InputProps={{
                  readOnly: true,
                  style: {
                    ...(theme as Theme).typography.AEGS_TYPE_PARAGRAPH,
                    color: theme.palette.primary.main,
                    fontWeight: 'bold'
                  }
                }}
                variant="outlined"
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  pl: '20px'
                }}
              >
                <Tooltip
                  title={'The link was copied!'}
                  placement="top"
                  open={showTooltop}
                >
                  <Styled.ResendBtn
                    variant="text"
                    className="disable-while-loading"
                    startIcon={<Styled.ResendIcon className="ri-links-line" />}
                    onClick={(): Promise<void> =>
                      handleCopy(Links.EMPLOYER_DASHBOARD)
                    }
                  >
                    Copy link
                  </Styled.ResendBtn>
                </Tooltip>
              </Box>
            </Box>
          </>
        )}

        {showTimesheet && (
          <>
            <Typography variant="EC_TYPE_LG">Link to Dashboard</Typography>
            <Typography sx={{ mt: 2 }}>Dashboard:</Typography>
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <TextField
                fullWidth
                value={employerDashboardLink}
                InputProps={{
                  readOnly: true,
                  style: {
                    ...(theme as Theme).typography.AEGS_TYPE_PARAGRAPH,
                    color: theme.palette.primary.main,
                    fontWeight: 'bold'
                  }
                }}
                variant="outlined"
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  pl: '20px'
                }}
              >
                <Tooltip
                  title={'The link was copied!'}
                  placement="top"
                  open={showTooltop}
                >
                  <Styled.ResendBtn
                    variant="text"
                    className="disable-while-loading"
                    startIcon={<Styled.ResendIcon className="ri-links-line" />}
                    onClick={(): Promise<void> =>
                      handleCopy(Links.EMPLOYER_DASHBOARD)
                    }
                  >
                    Copy link
                  </Styled.ResendBtn>
                </Tooltip>
              </Box>
            </Box>

            {approver.canApproveTimesheets && (
              <>
                <Typography sx={{ mt: 2 }}>Timesheets:</Typography>
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <TextField
                    fullWidth
                    value={timesheetLink}
                    InputProps={{
                      readOnly: true,
                      style: {
                        ...(theme as Theme).typography.AEGS_TYPE_PARAGRAPH,
                        color: theme.palette.primary.main,
                        fontWeight: 'bold'
                      }
                    }}
                    variant="outlined"
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      pl: '20px'
                    }}
                  >
                    <Tooltip
                      title={'The link was copied!'}
                      placement="top"
                      open={showTimesheetTooltop}
                    >
                      <Styled.ResendBtn
                        variant="text"
                        className="disable-while-loading"
                        startIcon={
                          <Styled.ResendIcon className="ri-links-line" />
                        }
                        onClick={(): Promise<void> =>
                          handleCopy(Links.TIMESHEET)
                        }
                      >
                        Copy link
                      </Styled.ResendBtn>
                    </Tooltip>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
        {resendError && (
          <Box>
            <Styled.MissingSignatureText>
              <Styled.WarningIcon
                className="ri-error-warning-line"
                sx={{ marginLeft: 0 }}
              />
              {resendError}
            </Styled.MissingSignatureText>
          </Box>
        )}
      </Box>
    </BasicModal>
  );
};

export default ResendModals;
