import React, { useEffect, useState } from 'react';
import { Codebook } from '@api/models/codebook.models';

// TODO: uncomment line below when CodebookApi.getMessageTypes() is created
// import { CodebookApi } from '@api/Codebook.api';

type FetchMessageTypeCodebook = {
  messageTypes: Codebook[];
  loading: boolean;
};

// TODO: remove this const once messageTypesCodebook is stored in database
const messageTypeCodebook: Codebook[] = [
  {
    value: 5,
    label: 'Externship Support'
  },
  {
    value: 2,
    label: 'Career Services Support'
  },
  {
    value: 3,
    label: 'Corporate Partnerships'
  },
  {
    value: 1,
    label: 'Technical Support'
  }
  // {
  //   value: 4,
  //   label: 'Other'
  // }
];

/**
 * Fetch messageTypes Codebook, used for ContactUsPage (public contact us form)
 *
 * @returns `{ messageTypes: Codebook[]; loaded: boolean; }`
 */
export const useFetchMessageTypeCodebook = (): FetchMessageTypeCodebook => {
  const [messageTypes, setMessageTypes] = useState<Codebook[]>([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        setLoading(true);

        // TODO: create CodebookApi.getMessageTypes() then uncomment lines below
        // const res = await CodebookApi.getMessageTypes();
        // setMessageTypes(res.data);

        setMessageTypes(messageTypeCodebook);
      } catch (error: any) {
        console.error(
          'Error for useFetchMessageTypeCodebook -> CodebookApi.getMessageTypes()',
          error
        );
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return { messageTypes, loading };
};
