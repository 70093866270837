import * as React from 'react';
import ErrorPageLayout from '@pages/Errors/ErrorPageLayout';
import ExternshipProposalLocationStep from '@pages/Candidate/ExternshipProposalPage/ExternshipProposalLocationStep';
import ExternshipProposalManagerStep from '@pages/Candidate/ExternshipProposalPage/ManagerStep/ExternshipProposalManagerStep';
import ExternshipProposalProgramStep from '@pages/Candidate/ExternshipProposalPage/ExternshipProposalProgramStep';
import Link from '@mui/material/Link';
import NotFoundPage from '@pages/Errors/NotFoundPage';
import ProfileSetupStepper from '@components/ProfileSetupStepper';
import { Link as RouterLink } from 'react-router-dom';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchExternshipCodebooks } from '@common/fetches/useFetchExternshipCodebooks';
import { useFetchExternships } from '@common/fetches/useFetchExternships';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import { useTheme } from '@mui/material';

const steps = ['COMPANY', 'MANAGER', 'PROGRAM'];

const ExternshipProposalPage: React.FC = () => {
  const featureFlags = useFeatureFlags();
  const theme = useTheme();

  const [proposedCompanyId, setProposedCompanyId] = React.useState<number>();
  const [externshipId, setExternshipId] = React.useState<number | null>(null);
  const [loadingStep, setLoadingStep] = React.useState(false);
  const [candidateId] = useStore<number>(UseStoreKeys.CANDIDATE_ID);
  const [isExternshipPilotEnabled] = useStore(
    UseStoreKeys.CANDIDATE_EXTERNSHIP_PILOT
  );

  // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove this and related code when flag is updated
  const { coursesAssignable, hasAvailableExternshipCourses, loading } =
    useFetchExternships();
  const { supportedPrograms, loaded: codebooksLoaded } =
    useFetchExternshipCodebooks();

  React.useEffect(() => {
    hasAvailableExternshipCourses(candidateId);
  }, [candidateId]);

  const stepContent = (handleStepper: (next: boolean) => void) => {
    return (step: number): React.ReactElement => {
      let content: React.ReactElement;

      switch (step) {
        case 0:
          content = (
            <ExternshipProposalLocationStep
              handleStepper={handleStepper}
              setProposedCompanyId={setProposedCompanyId}
              programOptions={supportedPrograms}
              candidateId={candidateId}
              loaded={!!codebooksLoaded}
              externshipId={externshipId}
              setExternshipId={setExternshipId}
            />
          );
          break;
        case 1:
          content = (
            <ExternshipProposalManagerStep
              handleStepper={handleStepper}
              candidateId={candidateId}
              proposedCompanyId={proposedCompanyId}
              externshipId={externshipId}
            />
          );
          break;
        case 2:
          content = (
            <ExternshipProposalProgramStep
              handleStepper={handleStepper}
              candidateId={candidateId}
              proposedCompanyId={proposedCompanyId}
            />
          );
          break;
        default:
          content = <>Oops!</>;
          break;
      }

      return content;
    };
  };

  if (!isExternshipPilotEnabled) {
    return <NotFoundPage />;
  } else if (
    !featureFlags.MULTIPLE_EXTERNSHIPS_PER_COURSE &&
    !coursesAssignable &&
    !loading
  ) {
    return (
      <ErrorPageLayout
        disableImage
        header="You don't have any available courses. Delete or remove an existing externship draft or proposal to begin a new proposal."
        headerVariant="EC_TYPE_3XL"
        headerTextStyle={{ color: theme.palette.BLACK.main, mt: '24px' }}
        rootMaxWidth="md"
        subtext={
          <>
            Back to{' '}
            <Link component={RouterLink} to="/user/dashboard" underline="none">
              dashboard
            </Link>
          </>
        }
      />
    );
  } else {
    return (
      <ProfileSetupStepper
        steps={steps}
        stepContent={stepContent}
        loading={loadingStep}
        setLoading={setLoadingStep}
      />
    );
  }
};

export default ExternshipProposalPage;
