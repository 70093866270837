import { useEffect, useRef, useState } from 'react';
import { ExternshipApi } from '@api/Externships.api';
import { ExternshipApprover } from '@api/models/externshipApi.models';

interface FetchExternshipApprovers {
  approvers?: ExternshipApprover[];
  loading: boolean;
}

export const useFetchExternshipApprovers = (
  externshipId?: number
): FetchExternshipApprovers => {
  const [approvers, setApprovers] = useState<ExternshipApprover[]>();
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);

  const fetchApprovers = async (id: number): Promise<void> => {
    try {
      setLoading(true);
      const res = await ExternshipApi.getApprovers(id);

      if (isMounted.current) {
        setApprovers(res.data);
      }
    } catch (error: any) {
      console.error(
        'Error for getExternshipApprovers -> ExternshipApi.getApprovers()',
        error
      );
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (externshipId) {
      fetchApprovers(externshipId);
    }

    return (): void => {
      isMounted.current = false;
    };
  }, [externshipId]);

  return { approvers, loading };
};
