import { api } from './configs/base';
import { apiConfig } from './configs/api.config';
import { ApiResponse } from './models/base.models';
import { FrequencyPutReq } from './models/notificationsFrequencyApi.models';
import { GetFrequency } from './models/notificationsFrequencyApi.models';

export class NotificationsFrequencyApi {
  static async getNotificationsFrequencyByEmployerId(
    employerId: number
  ): Promise<ApiResponse<GetFrequency[]>> {
    try {
      const response = await api.get<ApiResponse<GetFrequency[]>>(
        `${apiConfig.notificationsFrequencyUrl}/employer/${employerId}`
      );
      return response.data;
    } catch (error) {
      console.error(
        `Error for NotificationApi.getNotificationsByEmployerId: ${error}`
      );
      throw error;
    }
  }

  static async getNotificationsFrequencyByUserId(
    userId: string
  ): Promise<ApiResponse<GetFrequency[]>> {
    try {
      const response = await api.get<ApiResponse<GetFrequency[]>>(
        `${apiConfig.notificationsFrequencyUrl}/user/${userId}`
      );
      return response.data;
    } catch (error) {
      console.error(
        `Error for NotificationApi.getNotificationsFrequencyByUserId: ${error}`
      );
      throw error;
    }
  }

  static async updateNotificationFrequency(
    data: FrequencyPutReq
  ): Promise<ApiResponse<GetFrequency>> {
    try {
      const response = await api.put<ApiResponse<GetFrequency>>(
        `${apiConfig.notificationsFrequencyUrl}/frequency`,
        data
      );
      return response.data;
    } catch (error) {
      console.error(
        `Error for NotificationApi.updateNotificationFrequency: ${error}`
      );
      throw error;
    }
  }
}
