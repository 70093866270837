import * as React from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { CompanyApi } from '@api/Company.api';
import { CompanyCulturePutRequest } from '@api/models/companyApi.models';
import { cultureHealthSafety } from '@common/tags/tagStrings';
import FormActionsComponent from '@components/Forms/FormActionsComponent';
import FormWrapper from '@components/FormWrapper';
import PageDescriptionComponent from '@components/Layout/Common/PageDescriptionComponent';
import PageFormLayoutComponent from '@components/Layout/PageFormLayoutComponent';
import ReactHookFormsTagSelection from '@components/Forms/ReactHookFormsTagSelection';
import { scrollToError } from '@common/helpers/scrollToError';
import StepSection from '@components/StepSection';
import StepSubSection from '@components/StepSubSection';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';

interface FormValues {
  attributes: string[];
}
interface Props {
  handleStepper: (next: boolean) => void;
}

const CultureStep: React.FC<Props> = ({ handleStepper }) => {
  const { companyId } = useParamsOrStoreCompanyId();

  const methods = useForm<FormValues>({
    defaultValues: {
      attributes: []
    }
  });

  const { handleSubmit, reset } = methods;

  React.useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const res = await CompanyApi.getCompanyDetailsById(companyId);
        reset({
          attributes: res.data.companyCulture.map((culture) => culture.type)
        });
      } catch (error) {
        console.error('Error for CultureStep.getById()', error);
      }
    };

    fetchData();
  }, []);

  const handleInvalidForm = (errors: FieldErrors): void => {
    scrollToError(errors, { attributes: 1 });
  };

  const handleValidForm = async (
    data: FormValues,
    onSuccess: () => void
  ): Promise<void> => {
    const postBody: CompanyCulturePutRequest = {
      companyCulture: data.attributes
    };

    try {
      await CompanyApi.updateCompanyCulture(companyId, postBody);
      onSuccess();
    } catch (error: any) {
      console.error('Error for CultureStep.handleSave()', error);
      window.scrollTo(0, 0);
    }
  };

  const handleFormSubmit = (onSuccess: () => void): void => {
    const onValid = async (data: FormValues): Promise<void> =>
      handleValidForm(data, onSuccess);
    handleSubmit(onValid, handleInvalidForm)();
  };

  const validateLength = (value): boolean => {
    if (value.length >= 3 && value.length <= 10) {
      return true;
    }

    return false;
  };

  return (
    <PageFormLayoutComponent
      sideContent={
        <PageDescriptionComponent title="Company Culture">
          Choose 3-10 attributes that are important for your team.
        </PageDescriptionComponent>
      }
      pageTitle="Select your team attributes:"
      content={
        <FormWrapper>
          <FormProvider {...methods}>
            <StepSection>
              <StepSubSection>
                <ReactHookFormsTagSelection
                  name="attributes"
                  tags={cultureHealthSafety.culture}
                  subtitle="(Select 3-10)"
                  rules={{
                    required: true,
                    validate: {
                      length: validateLength
                    }
                  }}
                />
              </StepSubSection>
            </StepSection>
          </FormProvider>
        </FormWrapper>
      }
      actions={
        <FormActionsComponent
          hidePreviousBtn={false}
          onSubmit={handleFormSubmit}
          submitBtnText="Save and Continue"
          handleStepper={handleStepper}
          changeDetected={methods.formState.isDirty}
        />
      }
    />
  );
};

export default CultureStep;
