import { GridLogicOperator } from '@mui/x-data-grid-pro';

export enum GridTableType {
  'CS_DASHBOARD' = 10
}

export interface GridTableFilter {
  id: number;
  value: string | string[];
  operator: string;
  column: GridTableColumn;
  ordinalNumber: number;
}

export interface GridTableColumn {
  id: number;
  field: string;
  headerName: string;
  minWidth?: number;
  link?: string | null;
}

export interface GridTableViewColumn {
  id: number;
  column: GridTableColumn;
  ordinalNumber: number;
  isVisible: boolean;
}

export interface GridTableView {
  id: number;
  name: string;
  filters: GridTableFilter[];
  viewColumns: GridTableViewColumn[];
  filterLogicalOperator: GridLogicOperator;
  userId?: string;
}

export interface GridTable {
  id: number;
  defaultViewId: number;
  defaultViews: GridTableView[];
  customViews: GridTableView[];
  columns: GridTableColumn[];
}

export interface SaveGridTableViewDto {
  name: string;
  filters: SaveGridTableViewFiltersDto;
  viewColumns: SaveGridTableViewColumnsDto[];
  ordinalNumber: number;
}

export interface SaveGridTableViewColumnsDto {
  columnId: number;
  isVisible: boolean;
  ordinalNumber: number;
}

export interface SaveGridTableViewFiltersDto {
  items?: SaveGridTableViewFilterItemDto[];
  logicOperator?: GridLogicOperator;
}

export interface SaveGridTableViewFilterItemDto {
  field: string;
  operator: string;
  value: string;
  columnId: number;
  ordinalNumber: number;
}

// New
export enum GridType {
  EXTERNSHIP_TRACKER = 10,
  CHEF_TIMESHEETS = 20
}

export interface GridViewEntity {
  id: number;
  type: GridType;
  userId?: string | null;
  name: string;
  ordinalNumber?: number | null;
  defaultView: boolean;
  gridState: any;
}

export interface GridViewDto extends GridViewEntity {
  defaultView: boolean;
}

export interface SaveGridViewDto {
  gridState: string;
  type: GridType;
  name: string;
}

export interface SetGridViewDefaultDto {
  viewId: number;
  type: GridType;
}
