import {
  ExternshipEntity,
  ExternshipStatus
} from '@api/models/externshipApi.models';

const externshipStatusesToExclude: ExternshipStatus[] = [
  ExternshipStatus.DRAFT,
  ExternshipStatus.PENDING_SOFT_VERIFICATION,
  ExternshipStatus.CS_REJECTED,
  ExternshipStatus.DECLINED,
  ExternshipStatus.CANCELED,
  ExternshipStatus.ARCHIVED,
];

export const getDisplayedExternships = (
  externships: ExternshipEntity[]
): ExternshipEntity[] => {
  return externships.filter(
    (ex) => !externshipStatusesToExclude.includes(ex.status)
  );
};
