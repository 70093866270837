import * as React from 'react';
import {
  ExternshipEntity,
  SiteAgreementData
} from '@api/models/externshipApi.models';
import ExternshipsTable from '@pages/Employer/EmployerDashboard/ExternshipsTable/ExternshipsTable';
import TableWrapperWithSort from '@components/TableWrapperWithSort';
import { EmployerProfile } from '@api/models/employerApi.models';

const sortButtons = [
  { label: 'Created At', id: 'id' },
  { label: 'Status', id: 'status' },
  { label: 'Externship Start', id: 'scheduledStartDate' }
];

export interface EmployerExternshipsSectionProps {
  employer: EmployerProfile;
  externships: ExternshipEntity[];
  setExternships: React.Dispatch<
    React.SetStateAction<ExternshipEntity[]> // eslint-disable-line @typescript-eslint/indent
  >;
  siteAgreementData?: SiteAgreementData[];
  setSiteAgreementData?: React.Dispatch<
    React.SetStateAction<SiteAgreementData[]> // eslint-disable-line @typescript-eslint/indent
  >;
}

const EmployerDashExternshipsSection: React.FC<EmployerExternshipsSectionProps> = ({
  employer,
  externships,
  setExternships,
  siteAgreementData,
  setSiteAgreementData,
}) => {
  return (
    <>
      <TableWrapperWithSort
        headerTitle="Externships"
        sortButtons={sortButtons}
        initialSortOrder="DESC"
        initialSortCategory="scheduledStartDate"
        displayMobile={false}
        disabled={externships.length < 2}
        initialRowsPerPage={externships.length}
        styledHeader
      >
        <ExternshipsTable
          employer={employer}
          externships={externships}
          setExternships={setExternships}
          siteAgreementData={siteAgreementData}
          setSiteAgreementData={setSiteAgreementData}
        />
      </TableWrapperWithSort>
    </>
  );
};

export default EmployerDashExternshipsSection;
