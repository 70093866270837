import * as React from 'react';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';

const Styled = {
  Title: styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: (theme as Theme).typography.EC_TYPE_5XL.fontSize,
    lineHeight: (theme as Theme).typography.EC_TYPE_5XL.lineHeight
  })),
  TopFiltersWrapper: styled(Box)({
    display: 'flex',
    alignItems: 'flex-end',
    gap: '24px'
  }),
  TopFilterFormControl: styled(FormControl)({
    minWidth: '150px'
  })
};

interface Props {
  label: string;
  onChange: (e: SelectChangeEvent) => void;
  value: any;
  options: { label: string; value: any }[];
}

const EcGridTopFilterSelect: React.FC<Props> = ({
  label,
  onChange,
  value,
  options
}) => {
  if (!options.length) {
    return <></>;
  }

  return (
    <Box>
      <Typography
        variant="EC_TYPE_XS"
        component={'p'}
        color={'GRAY_4.main'}
        mb={'12px'}
        sx={{ textTransform: 'uppercase' }}
      >
        {label}
      </Typography>
      <Styled.TopFilterFormControl>
        <Select displayEmpty value={value} onChange={onChange}>
          {options?.map((opt, idx) => (
            <MenuItem key={opt.value + idx} value={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </Styled.TopFilterFormControl>
    </Box>
  );
};

export default EcGridTopFilterSelect;
