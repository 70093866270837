import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)({
    display: 'flex',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    ['@media (max-width: 700px)']: {
      flexDirection: 'column'
    }
  }),
  LeftContent: styled(Box)({
    flex: 3,
    padding: '70px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ['@media (max-width: 950px)']: {
      padding: '28px',
      flex: 3
    }
  }),
  ActionsWrapper: styled(Box)({
    display: 'flex',
    columnGap: '40px',
    rowGap: '32px',
    marginTop: '40px',
    flexWrap: 'wrap'
  }),
  RightContent: styled(Box)({
    flex: 4,
    ['@media (max-width: 950px)']: {
      flex: 2
    }
  }),
  Image: styled('img')({
    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'cover',
    objectPosition: 'center'
  })
};

export interface GlrcProduct {
  id: number;
  title: string;
  description: string;
  image: string;
  link: string;
}

const GlrcProductCard: React.FC<GlrcProduct> = ({
  title,
  description,
  image,
  link
}) => {
  return (
    <Styled.Root>
      <Styled.LeftContent>
        <BottomMarginContentWrapper bottomMargin="28px">
          <Typography variant="EC_TYPE_4XL" sx={{ margin: '0px' }}>
            {title}
          </Typography>
          <Typography variant="EC_TYPE_BASE">{description}</Typography>
        </BottomMarginContentWrapper>
        <Styled.ActionsWrapper>
          <Button
            variant="contained"
            endIcon={<i className="ri-arrow-right-line" />}
          >
            Add to cart
          </Button>
          <Button
            variant="text"
            component={RouterLink}
            to={link}
            sx={{ fontWeight: 400 }}
          >
            Learn more
          </Button>
        </Styled.ActionsWrapper>
      </Styled.LeftContent>
      <Styled.RightContent>
        <Styled.Image src={image} />
      </Styled.RightContent>
    </Styled.Root>
  );
};

export default GlrcProductCard;
