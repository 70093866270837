/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { SortButton, SortOrderType } from '@interfaces/search.interfaces';
import { styled } from '@mui/system';

const Styled = {
  Button: styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== 'isDisabledAndActive'
  })<{ isDisabledAndActive: boolean }>(({ isDisabledAndActive }) => ({
    ...(isDisabledAndActive && {
      pointerEvents: 'none',
      '& .MuiListItemText-primary': {
        fontWeight: 800
      }
    })
  }))
};

interface Props extends SortButton {
  isActive: boolean;
  sortOrder: SortOrderType;
  hideIcon?: boolean;
  disableActive?: boolean;
  onSortClick: <T>(
    event: React.MouseEvent<HTMLDivElement | unknown, MouseEvent>,
    sortBy: keyof T
  ) => void;
}

const TableSortButton = ({
  label,
  id,
  isActive,
  sortOrder,
  hideIcon = false,
  disableActive = false,
  onSortClick
}: Props): React.ReactElement => {
  const isAsc = sortOrder === 'ASC';
  const iconClassName = isActive
    ? isAsc
      ? 'ri-arrow-up-line'
      : 'ri-arrow-down-line'
    : '';

  const handleSortClick =
    (sortBy: typeof id) =>
    (event: React.MouseEvent<HTMLDivElement | unknown, MouseEvent>): void => {
      onSortClick(event, sortBy);
    };

  return (
    <ListItem
      disablePadding
      onClick={handleSortClick(id)}
      alignItems="center"
      data-testid={`sort-button-${id}`}
    >
      <Styled.Button isDisabledAndActive={disableActive && isActive}>
        {!hideIcon && (
          <ListItemIcon sx={{ minWidth: '30px' }}>
            <i className={iconClassName} />
          </ListItemIcon>
        )}
        <ListItemText
          primary={label}
          className="list-item-text"
          sx={{ paddingLeft: 0, fontWeight: 'inherit' }}
        />
      </Styled.Button>
    </ListItem>
  );
};

export default TableSortButton;
