import * as React from 'react';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { GRAY_3, GRAY_4 } from '@themes/ui/escoffier';
import { AttestationType } from '@interfaces/attestation.interface';
import { getSignatureText } from './getSignatureText';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import { Link as RouterLink } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { styled } from '@mui/system';
import { useFormContext } from 'react-hook-form';

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    width: '540px',
    backgroundColor: theme.palette.WHITE.main,
    border: '',
    borderRadius: '4px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    padding: '40px',
    margin: '0 auto',
    ['@media (max-width:700px)']: {
      width: '100%'
    }
  }))
};

interface Props {
  title: string;
  onSubmit: () => void;
  attestationType?: AttestationType;
  sigRef: React.MutableRefObject<any>;
  attestationComplete?: boolean;
  signatureImage?: string;
  signatureError?: string;
}

const AttestationBase: React.FC<Props> = ({
  children,
  title,
  onSubmit,
  attestationType,
  sigRef,
  attestationComplete,
  signatureImage,
  signatureError
}) => {
  const theme = useTheme();
  const { register } = useFormContext();

  return (
    <Styled.Root>
      <Typography variant="EC_TYPE_2XL" mb={5}>
        {title}
      </Typography>
      <Grid container spacing={3} justifyContent="flex-end">
        <Grid item xs={12}>
          <ReactHookFormsInput
            name="studentNumber"
            label="Student Number"
            disabled
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReactHookFormsInput
            name="firstName"
            label="First Name"
            disabled
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReactHookFormsInput
            name="lastName"
            label="Last Name"
            disabled
            rules={{ required: true }}
          />
        </Grid>
        {children}
        <Grid item>
          <Typography variant="AEGS_TYPE_PARAGRAPH">
            {getSignatureText(attestationType)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ border: `1px solid ${GRAY_3}` }}>
            {attestationComplete ? (
              <img
                src={signatureImage}
                style={{
                  backgroundSize: '200px 50px',
                  width: '100%',
                  height: '100px',
                  backgroundColor: 'white'
                }}
              />
            ) : (
              <SignatureCanvas
                {...register('signature')}
                penColor={GRAY_4}
                canvasProps={{
                  height: 100,
                  width: 500,
                  className: 'sigCanvas'
                }}
                ref={sigRef}
              />
            )}
          </Box>
        </Grid>
        {signatureError && (
          <Grid item xs={12} sx={{ marginRight: 'auto', paddingTop: 0 }}>
            <Typography variant="EC_TYPE_2XS" color={theme.palette.error.main}>
              {signatureError}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={'auto'}>
          <Button
            variant="outlined"
            component={RouterLink}
            to={'/candidate/dashboard'}
            fullWidth
            data-testid="cancel-attestation"
            className="ga-cta"
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} sm={'auto'}>
          <Button variant="contained" fullWidth onClick={onSubmit}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </Styled.Root>
  );
};

export default AttestationBase;
