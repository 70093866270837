import * as React from 'react';
import {
  AttestationEntity,
  AttestationStatus
} from '@interfaces/attestation.interface';
import { Box, Button, Link, Typography } from '@mui/material';
import { getFilePath } from '@common/helpers/getFilePath';
import { styled } from '@mui/system';
import { useHistory } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Styled = {
  PaddingSection: styled(Box)({
    padding: '16px'
  }),
  SummaryMainContent: styled(Box)(({ theme }) => ({
    marginBottom: '16px',
    borderTop: `1px solid ${theme.palette.GRAY_3.main}`
  })),
  FlexRow: styled(Box)({
    display: 'flex',
    alignItems: 'center',
    columnGap: '12px',
    '& i': {
      fontSize: '16px'
    }
  }),
  DownloadBtn: styled(Button)({
    padding: 0,
    whiteSpace: 'nowrap'
  })
};

interface AttestationProps {
  externshipId: number;
  attestation: AttestationEntity;
  attestationStatus: AttestationStatus;
  companyName: string;
}

const AttestationSection: React.FC<AttestationProps> = ({
  externshipId,
  attestation,
  attestationStatus,
  companyName
}) => {
  const history = useHistory();

  const handleSurveyClick = (): void => {
    history.push(`/externship/${externshipId}/attestation`);
  };

  const filePath = getFilePath(attestation.file?.fullName);

  return (
    <Styled.SummaryMainContent>
      <Styled.PaddingSection>
        <Styled.FlexRow>
          <Box>
            <Typography variant="EC_TYPE_LG">
              <i className="ri-checkbox-multiple-line" /> {companyName}
            </Typography>
            <Typography variant="EC_TYPE_3XS" fontWeight={700}>
              {attestation.type?.label} attestation survey
            </Typography>
          </Box>
          {(attestationStatus === AttestationStatus.SENT ||
            attestationStatus === AttestationStatus.OPENED) && (
            <Button
              variant="outlined"
              onClick={handleSurveyClick}
              sx={{
                ml: 'auto',
                typography: 'EC_TYPE_2XS',
                color: 'primary.main',
                whiteSpace: 'nowrap'
              }}
            >
              Take Survey
            </Button>
          )}
          {attestationStatus === AttestationStatus.COMPLETED && (
            <Link
              href={`${BASE_URL}/${filePath}`}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
              sx={{ ml: 'auto' }}
            >
              <Styled.DownloadBtn
                variant="text"
                startIcon={<i className="ri-file-text-fill" />}
                size="small"
              >
                Download Attestation
              </Styled.DownloadBtn>
            </Link>
          )}
        </Styled.FlexRow>
      </Styled.PaddingSection>
    </Styled.SummaryMainContent>
  );
};

export default AttestationSection;
