import { useEffect, useState } from 'react';
import { ApplicationStatusCodebook } from '@api/models/codebook.models';
import { CodebookApi } from '@api/Codebook.api';

export type FetchBadgeCodebooks = {
  statuses: ApplicationStatusCodebook[] | null;
  loading: boolean;
  loaded: true | null;
};

export const useFetchApplicationStatusCodebooks = (): FetchBadgeCodebooks => {
  const [statuses, setStatuses] = useState<ApplicationStatusCodebook[] | null>(
    null
  );

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        setLoading(true);
        const res = await CodebookApi.getApplicationStatuses();
        const sortedStatuses = res.data.sort((a, b) => a.value - b.value);

        setStatuses(sortedStatuses);
      } catch (error: any) {
        console.error(
          'Error for useFetchApplicationStatusCodebooks -> CodebookApi.getApplicationStatuss()',
          error
        );
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    };

    fetchData();
  }, []);

  return {
    statuses,
    loading,
    loaded
  };
};
