import * as React from 'react';
import { Chip, Icon, TypographyProps } from '@mui/material';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const Styled = {
  Chip: styled(Chip, {
    shouldForwardProp: (prop) =>
      prop !== 'chipColor' && prop !== 'rounded' && prop !== 'labelColor'
  })<{ chipColor: string; labelColor: string; rounded?: boolean }>(
    ({ chipColor, labelColor, rounded }) => ({
      backgroundColor: chipColor,
      '.MuiChip-label': {
        color: labelColor
      },
      height: 'auto',
      padding: '7px 5px',
      borderRadius: !rounded ? '4px' : '16px'
    })
  ),
  Delete: styled(Icon, {
    shouldForwardProp: (prop) => prop !== 'iconColor'
  })<{ iconColor: string }>(({ iconColor }) => ({
    color: `${iconColor} !important`,
    fontSize: '10px !important',
    lineHeight: '10px !important'
  }))
};

interface Props {
  label: string;
  handleDelete?: any;
  goldColor?: boolean;
  typography?: TypographyProps['variant'];
  chipColor?: string;
  labelColor?: string;
  rounded?: boolean;
  customSx?: React.CSSProperties;
  dataTestId?: string;
}

const EcChip: React.FC<Props> = ({
  label,
  handleDelete,
  typography = 'EC_TYPE_3XS',
  chipColor = theme.palette.GRAY_3.main,
  labelColor = theme.palette.WHITE.main,
  rounded = false,
  customSx,
  dataTestId = 'ec-chip'
}) => {
  return (
    <Styled.Chip
      data-testid={dataTestId}
      label={label}
      deleteIcon={
        handleDelete && (
          <Styled.Delete className="ri-close-line" iconColor={labelColor} />
        )
      }
      onDelete={handleDelete}
      chipColor={chipColor}
      labelColor={labelColor}
      rounded={rounded}
      sx={{ typography, ...customSx }}
    />
  );
};

export default EcChip;
