import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';
import { StatusCodes } from 'http-status-codes';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

// NOTE I had to use react-toastify to show the error message
// as it supports event driven error handling.
// It will have different style as the other toast messages from MUI.
// But due to the fact that this error message will not be shown as long as user is browsing the pages
// maybe it is ok for now to work with this implementation.
export const api: AxiosInstance = axios.create({
  baseURL: baseUrl
});

api.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    config.headers!.Authorization = `Bearer ${localStorage.getItem('token')}`;

    return config;
  },
  (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    return response;
  },
  (error): Promise<AxiosError> => {
    const { status } = error.response;
    const errMsg: string = error.response?.data?.message;

    if (status === StatusCodes.FORBIDDEN) {
      window.location.href = '/not-found';
    }

    if (status === StatusCodes.INTERNAL_SERVER_ERROR) {
      window.location.href = '/error';
    }

    if (status === StatusCodes.GONE) {
      window.location.href = '/expired-invite';
    }

    if (
      status === StatusCodes.CONFLICT &&
      errMsg.includes('Invalid invite code')
    ) {
      window.location.href = '/invalid-invite';
    }

    return Promise.reject(error);
  }
);
