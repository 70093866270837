import * as React from 'react';
import { Grid, OutlinedInput, Typography } from '@mui/material';
import ReactHookFormsFieldArray, {
  FieldArrayFieldProps
} from '@components/Forms/ReactHookFormsFieldArray';
import { DateTime } from 'luxon';
import StepSection from '@components/StepSection';
import StepSubSection from '@components/StepSubSection';
import theme from '@themes/ui/escoffier';
import { useFetchCareerRoles } from '@common/fetches/useFetchCareerRoles';
import { useFormContext } from 'react-hook-form';
import { WorkExperience } from '@api/models/profileSetupApi.models';

interface Props {
  workExperienceRequired: boolean;
  setWorkExperienceRequired: (required: boolean) => void;
  // TODO: OBSOLETE - remove obsolete props and corresponding JSX once existing profiles are updated
  obsoleteJobTitle?: string | null;
  obsoleteYearsExp?: { value: number; label: string } | null;
}

type WorkExperienceFields = {
  id: string;
} & WorkExperience;

const WorkSection: React.FC<Props> = ({
  workExperienceRequired,
  setWorkExperienceRequired,
  obsoleteJobTitle,
  obsoleteYearsExp
}) => {
  const MIN_DATE = DateTime.local(1950, 1);
  const tempEndDate = React.useRef<DateTime | null>(null);
  const { careerRolesCodebook } = useFetchCareerRoles('careerRolesCodebook');
  const { setValue, watch } = useFormContext();

  const hasObsoleteProp = Boolean(obsoleteJobTitle || obsoleteYearsExp);

  const getMinEndDate = (idx: number): DateTime => {
    const startDate: DateTime | undefined | null = watch(
      `workExperience.${idx}.startDate`
    );

    if (startDate) {
      return startDate.plus({ days: 1 });
    }

    return MIN_DATE;
  };

  const handleCurrentPositionChecked = (idx: number): void => {
    const updateEndDate = `workExperience.${idx}.endDate`;
    const updateCurrentPosition = `workExperience.${idx}.currentPosition`;
    const currentPosition = watch(updateCurrentPosition);
    const endDate = watch(updateEndDate);

    if (currentPosition === false) {
      tempEndDate.current = endDate;
      setValue(updateCurrentPosition, true);
      setValue(updateEndDate, null);
    } else {
      setValue(updateCurrentPosition, false);
      setValue(updateEndDate, tempEndDate.current);
      tempEndDate.current = null;
    }
  };

  const isEndDateRequired = (field): boolean => {
    if (field['currentPosition']) {
      const { currentPosition } = field;
      return currentPosition !== true;
    }

    return true;
  };

  const fieldArray: FieldArrayFieldProps<WorkExperienceFields>[] = [
    {
      fieldName: 'company',
      inputType: 'outlined_input',
      inputLabel: 'Company',
      displayAsterisk: true
    },
    {
      fieldName: 'position',
      inputType: 'select',
      inputLabel: 'Position',
      selectOptions: careerRolesCodebook,
      displayAsterisk: true
    },
    {
      fieldName: 'startDate',
      inputType: 'date_picker',
      inputLabel: 'Start Date',
      displayAsterisk: true,
      datePickerRequired: true,
      datePickerMinDate: MIN_DATE,
      gridXSCols: 6
    },
    {
      fieldName: 'endDate',
      inputType: 'date_picker',
      inputLabel: 'End Date',
      datePickerNotRequiredText: 'Present',
      getDependentFieldRequired: isEndDateRequired,
      getMinEndDate,
      gridXSCols: 6
    },
    {
      fieldName: 'currentPosition',
      inputType: 'check_box',
      checkBoxText: 'Current position',
      onCheckboxChecked: handleCurrentPositionChecked,
      fieldStyle: {
        minHeight: 'unset'
      }
    },
    {
      fieldName: 'responsibilities',
      inputType: 'text_area',
      inputLabel: 'Your Responsibilities',
      getDependentFieldRequired: (): boolean => false,
      disabledDisplay: false,
      gridSMCols: 12,
      fieldStyle: {
        marginTop: 0
      }
    }
  ];

  const defaultValues = {
    company: '',
    position: '',
    startDate: null,
    endDate: null,
    currentPosition: false,
    responsibilities: ''
  };

  return (
    <StepSection title="Work Experience" dataTestId="candidate-work-section">
      <StepSubSection
        title="What is your work experience?"
        wrapperId="workExperience"
        required={true}
        disableMargin
      >
        <Grid
          container
          // TODO: OBSOLETE - remove spacing and pt when obsolete props are removed
          columnSpacing={hasObsoleteProp ? 3 : undefined}
          rowSpacing={hasObsoleteProp ? 2 : undefined}
          pt={hasObsoleteProp ? '12px' : undefined}
        >
          {obsoleteJobTitle && (
            <Grid item xs={12} sm={6}>
              <Typography variant="EC_TYPE_BASE" mb="10px">
                Current Job Title
              </Typography>
              <OutlinedInput
                disabled
                fullWidth
                placeholder={obsoleteJobTitle}
              />
            </Grid>
          )}
          {obsoleteYearsExp && (
            <Grid item xs={12} sm={6}>
              <Typography variant="EC_TYPE_BASE" mb="10px">
                Years of Experience
              </Typography>
              <OutlinedInput
                disabled
                fullWidth
                placeholder={obsoleteYearsExp.label}
              />
            </Grid>
          )}
          {hasObsoleteProp && (
            <Grid item xs={12}>
              <Typography
                variant="EC_TYPE_BASE"
                sx={{
                  ':after': {
                    content: '"*"',
                    color: '#BB1133',
                    marginLeft: '4px'
                  }
                }}
              >
                Please update your Work Experience by using the +Add Work
                Experience button below.
              </Typography>

              <Typography
                variant="EC_TYPE_XS"
                sx={{
                  color: theme.palette.red2.main
                }}
              >
                Current Job Title and Years of Experience will be replaced after
                you &ldquo; Save and Finish &rdquo;
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <ReactHookFormsFieldArray
              fieldArrayName="workExperience"
              fieldMap={fieldArray}
              defaultValues={defaultValues}
              fieldArrayRequired={workExperienceRequired}
              setFieldArrayRequired={setWorkExperienceRequired}
              addBtnText="Add Work Experience"
            />
          </Grid>
        </Grid>
      </StepSubSection>
    </StepSection>
  );
};

export default WorkSection;
