import * as React from 'react';
import { Box, Grid, Link, Typography, useTheme } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import { ContactUsApi } from '@api/ContactUs.api';
import { ContactUsPostReq } from '@api/models/contactUsApi.models';
import CrownSection from '@components/CrownSection';
import { emailPattern } from '@common/regex/email';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import ReactHookFormsInputPhoneMask from '@components/Forms/ReactHookFormsInputPhoneMask';
import { styled } from '@mui/system';
import { useTagManager } from '@common/hooks/useTagManager';
import { useToast } from '@components/Toast';

const Styled = {
  Root: styled(Box)({
    padding: '80px 120px',
    '@media (max-width:900px)': {
      padding: '80px 65px'
    },
    '@media (max-width:600px)': {
      padding: '80px 32px'
    },
    '@media (max-width:480px)': {
      padding: '80px 16px'
    }
  }),
  Button: styled(Button)({
    marginTop: '20px',
    height: 'unset'
  })
};

interface Props {
  sectionTitle?: string;
}

const ContactFormSection: React.FC<Props> = () => {
  const theme = useTheme();
  const { sendToGtm } = useTagManager();

  const methods = useForm<ContactUsPostReq>({
    defaultValues: {
      interestedIn: 'Work & Learn',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: ''
    }
  });

  const { handleSubmit, reset, setError } = methods;

  const { openToast } = useToast();

  const handleInvalidForm = (): void => {};

  const handleValidForm = async (data: ContactUsPostReq): Promise<void> => {
    const postBody = {
      interestedIn: data.interestedIn,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber
    } as ContactUsPostReq;

    try {
      await ContactUsApi.sendWorkAndLearnForm(postBody as ContactUsPostReq);
      sendToGtm('candidate_contact_form_submitted', {
        interested_in: postBody.interestedIn
      });

      reset();
      openToast('Your message has been sent to Escoffier. Thank you!');
    } catch (error: any) {
      console.error('Error for ContactFormSection', error);
      const errData = error.response.data.data;

      if (errData.length) {
        errData.forEach(({ field, message }) =>
          setError(field, { type: 'manual', message })
        );
      }
    }
  };

  return (
    <CrownSection bgColor={theme.palette.GRAY_F4.main}>
      <Styled.Root>
        <Typography variant="EC_TYPE_3XL" mb={5} color={'primary'}>
          Request Information
        </Typography>
        <Box data-testid="formContainer" id="contact-us-form-section">
          <FormProvider {...methods}>
            <form>
              <Grid container rowSpacing={10}>
                <Grid item xs={12} xl={6}>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={6}>
                      <ReactHookFormsInput
                        name="firstName"
                        label="First name"
                        rules={{ required: 'First name is required' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ReactHookFormsInput
                        name="lastName"
                        label="Last name"
                        rules={{ required: 'Last name is required' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ReactHookFormsInput
                        name={'email'}
                        label={'Email'}
                        rules={{
                          required: 'Email is required',
                          pattern: {
                            value: emailPattern,
                            message: 'Please enter a valid email'
                          }
                        }}
                        dataTestId="contact-form-email-input"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ReactHookFormsInputPhoneMask
                        name="phoneNumber"
                        label="Phone Number"
                        rules={{ required: 'Phone number is required' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Styled.Button
                        variant="contained"
                        onClick={handleSubmit(
                          handleValidForm,
                          handleInvalidForm
                        )}
                        data-testid="formButtonId"
                        fullWidth
                      >
                        Send Request
                      </Styled.Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={0} xl={6}></Grid>
                <Grid item xs={12}>
                  <Typography variant="EC_TYPE_3XS">
                    Clicking the &quot;Send Request&quot; button constitutes
                    your express request, and your express written consent, to
                    be contacted by and to receive automated or pre-recorded
                    call, texts, messages and/or emails from via phone, text,
                    and/or emails by Auguste Escoffier School of Culinary Arts
                    at the number(s)/email you provided, regarding furthering
                    your education and enrolling. You understand that these
                    calls, texts, messages and/or emails may be generated using
                    an automated or pre-recorded technology. You are not
                    required to agree to receive automated or pre-recorded
                    calls, texts, messages or emails as a condition of enrolling
                    at Escoffier. You can unsubscribe at any time or request
                    removal of street address, phone number, email address via
                    Escoffier{' '}
                    <Link
                      component="a"
                      underline="hover"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://escoffierglobal.com/privacy/#econnect"
                    >
                      website.
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Styled.Root>
    </CrownSection>
  );
};

export default ContactFormSection;
