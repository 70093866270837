import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import EcToolTip from '@components/EcToolTip';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { roles } from '@api/models/userApi.models';
import { SeatRole } from '@pages/Shared/SettingsPage/CompanySettings/ManageSeatsTableRow';
import { SentInvite } from '@api/models/settingsApi.models';
import { styled } from '@mui/system';

const Styled = {
  TableBodyRow: styled(TableRow)({
    '&:last-child td, &:last-child th': { border: 0 }
  })
};

interface Props {
  sentInvites: SentInvite[];
}

const ExpiredToolTip = ({ date }: { date: string }): JSX.Element => (
  <Typography variant="EC_TYPE_2XS" color="#A6B0BF">
    {date}
    <EcToolTip
      dataTestId="pending-invites-expired-tooltip"
      message="Expired"
      customIconStyles={{
        position: 'absolute',
        marginLeft: '10px',
        color: 'inherit'
      }}
    />
  </Typography>
);

const PendingInvitesTable: React.FC<Props> = ({ sentInvites }) => {
  const getExpirationString = (
    date: string,
    isExpired: boolean
  ): JSX.Element | string =>
    isExpired ? (
      <ExpiredToolTip date={formatDate(date, 'M/dd/yyyy')} />
    ) : (
      formatDate(date, 'M/dd/yyyy')
    );

  const roleLabel = (role: string): string => {
    const findRole =
      SeatRole.find((s) => s.value === role.substring(4))?.label || '';
    return findRole === roles.GLRC_USER ? roles.ESOURCE_USER : findRole;
  };

  return (
    <TableContainer data-testid="pending-invites-table-container">
      <Table aria-label="pending-invites-table">
        <TableHead>
          <TableRow>
            <TableCell>EMAIL</TableCell>
            <TableCell>ROLE</TableCell>
            <TableCell sx={{ width: '100px' }}>SENT DATE</TableCell>
            <TableCell>EXPIRATION</TableCell>
          </TableRow>
        </TableHead>
        <TableBody data-testid="pending-invites-table-body">
          {sentInvites.map((invitee) => (
            <Styled.TableBodyRow key={invitee.id}>
              <TableCell>{invitee.email}</TableCell>
              <TableCell>{roleLabel(invitee.roles)}</TableCell>
              <TableCell>{formatDate(invitee.dateSent, 'M/dd/yyyy')}</TableCell>
              <TableCell data-testid="pending-invites-expiry-cell">
                {getExpirationString(invitee.expiryDate, !!invitee?.expired)}
              </TableCell>
            </Styled.TableBodyRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PendingInvitesTable;
