import { Country, Location } from '@interfaces/location.interfaces';

/**
 * @param {Location} The location to format into a two line address
 * @returns Returns an object { line1: string;, line2: string }
 */
export const getTwoLineAddressObject = (
  location: Location
): { line1: string; line2: string } => {
  let line1 = '';
  let line2 = '';

  if (location.streetNumber) {
    line1 += location.streetNumber;
  }

  if (location.streetName) {
    line1 += `${line1.length ? ' ' : ''}${location.streetName}`;
  }

  if (location.city) {
    line2 += `${line2.length ? ', ' : ''}${location.city}`;
  }

  if (location.country === Country.PUERTO_RICO) {
    line2 += `${line2.length ? ', ' : ''}${location.country}`;
  } else {
    if (location.state) {
      line2 += `${line2.length ? ', ' : ''}${location.state}`;
    }
  }

  if (location.zip) {
    line2 += `${line2.length ? ', ' : ''}${location.zip}`;
  }

  return {
    line1,
    line2
  };
};
