import * as React from 'react';
import LegalContentSection from '@components/LegalContentSection';

const PrivacyPolicyContent: React.FC = () => {
  return (
    <LegalContentSection title="Escoffier Connect">
      <p>
        <strong>
          <span>Our Commitment to Privacy</span>
        </strong>
      </p>
      <p>
        <span>
          Our Privacy Policy was developed as an extension of our commitment to
          combine the highest quality products and services with the highest
          level of integrity in dealing with our users. The Policy is designed
          to assist you in understanding how we collect, use, disclose and
          safeguard personal and company information, and to assist you in
          making informed decisions, as you seek job opportunities or needed
          employees on this website, related phone apps, and related social
          media accounts (the &ldquo;
          <strong>Website</strong>&rdquo;), or when you otherwise engage with us
          or others on the Website. This statement will be continuously assessed
          against new technologies, business practices, and our customers&rsquo;
          needs.
        </span>
      </p>
      <p>
        <strong>
          <span>What Information Do We Collect?</span>
        </strong>
      </p>
      <p>
        <span>
          When you visit our Website, you may provide Auguste Escoffier School
          of Culinary Arts, which is owned by Triumph Higher Education
          Corporation (&ldquo;<strong>AESCA</strong>&rdquo;, &ldquo;
          <strong>we</strong>&rdquo;, &ldquo;<strong>us</strong>&rdquo; and
          &ldquo;<strong>our</strong>&rdquo;) with two types of information: (1)
          personal or company information you knowingly choose to disclose, to
          us, which we collect on an individual basis, and (2) website use
          information that we automatically collect on an aggregate basis as you
          browse our Website. The latter type of information may be considered
          personal or company information to the extent it identifies you as an
          individual or a company.
        </span>
      </p>
      <p>
        <strong>
          <em>
            <span>(1) Personal Information You Choose to Provide</span>
          </em>
        </strong>
      </p>
      <p>
        <span>
          If you choose to access the Website, either as a job seeker, an
          employer, or in some other capacity, and/or to interact with us in
          some other way, you may need to give personal or company information
          and/or authorization for us to obtain your personal or company
          information from various third-party sources, such as credit reporting
          agencies and other credit services. For example, you may need to
          provide the following information:
        </span>
      </p>
      <ol>
        <li>
          <span>Name</span>
        </li>
        <li>
          <span>Mailing address</span>
        </li>
        <li>
          <span>Email address and other email information</span>
        </li>
        <li>
          <span>Credit card number and other information</span>
        </li>
        <li>
          <span>Home, cell, and/or business phone number</span>
        </li>
        <li>
          <span>Credit or debit card information</span>
        </li>
        <li>
          <span>
            Information necessary to offer or accept employment and/or to assess
            employment status, veteran or military status, professional or
            current or prior employment information
          </span>
        </li>
        <li>
          <span>Other personal or company information</span>
        </li>
      </ol>
      <p>
        <span>
          If you choose to correspond further with us through email, we may
          retain the content of your email messages to us together with your
          email address and our responses. We provide the same protections for
          these electronic communications that we employ in the maintenance of
          information received through our Website or by mail and telephone.
        </span>
      </p>
      <p>
        <span>
          In addition to providing the foregoing information, when apply for
          employment or post an employment opportunity, we collect certain
          additional personal information, including, but not limited to:
          resumes, job descriptions, compensation levels, education, and other
          items relating to employment and employment history.
        </span>
      </p>
      <p>
        <strong>
          <em>
            <span>(2) Website Use Information</span>
          </em>
        </strong>
      </p>
      <p>
        <span>
          We may collect certain types of information electronically when you
          interact with our Website, emails, social media accounts, phone apps,
          online advertising or through other technologies. The information
          helps us understand what actions you take on the Website and allows
          the Website to work correctly.
        </span>
      </p>
      <p>
        <span>
          Similar to other commercial websites, our Website utilizes a standard
          technology called &ldquo;cookies&rdquo; or &quot;widgets&quot; (see
          explanation below, &ldquo;What Are Cookies and&nbsp;
        </span>
        <u>
          <span>Widgets</span>
        </u>
        <span>
          ?&rdquo;) and web server logs to collect information about how our
          Website is used. Information gathered through cookies, widgets, and
          web server logs may include the date and time of visits, the pages or
          screens viewed, time spent at our Website, and the websites visited
          just before and just after our Website. This information is collected
          on an aggregate basis. None of this information is associated with you
          as an individual.
        </span>
      </p>
      <p>
        <strong>
          <em>
            <span>What Are Cookies and Widgets?</span>
          </em>
        </strong>
      </p>
      <p>
        <span>
          Cookies are a feature of web browser software that allows web servers
          to recognize the computer used to access a website. Cookies are small
          pieces of data that are stored by a user&rsquo;s web browser on the
          user&rsquo;s hard drive. Cookies can remember what information a user
          accesses on one webpage to simplify subsequent interactions with that
          website by the same user or to use the information to streamline the
          user&rsquo;s transactions on related webpages. This makes it easier
          for a user to move from webpage to webpage and to complete commercial
          transactions over the Internet. Cookies should make your online
          experience easier and more personalized. &nbsp;Widgets are used by
          phone apps and are equivalents to cookies.
        </span>
      </p>
      <p>
        <span>&nbsp;</span>
      </p>
      <p>
        <strong>
          <em>
            <span>
              How Do We Use Information We Collect from Cookies and Widgets?
            </span>
          </em>
        </strong>
      </p>
      <p>
        <span>
          We use Website software tools, such as cookies, widgets, and web
          server logs to gather information about our Website users&rsquo;
          activities, in order to constantly improve our Website and better
          serve our customers. This information assists us to design and arrange
          our webpages and screens in the most user-friendly manner and to
          continually improve our Website to better meet the needs of our
          customers and prospective customers, including pushing notifications
          and information to you.
        </span>
      </p>
      <p>
        <span>
          Cookies and widgets help us collect important business and technical
          statistics. The information in the cookies and widgets lets us trace
          the paths followed by users to our Website as they move from one page
          or screen to another. Web server logs allow us to count how many
          people visit our Website and evaluate our Website&rsquo;s visitor
          capacity. We do not use these technologies to capture your individual
          email address or any personally identifying information about you,
          although they do permit us to send focused online banner
          advertisements or other such responses to you.
        </span>
      </p>
      <p>
        <strong>
          <em>
            <span>
              Tracking Options and California Do Not Track Disclosures
            </span>
          </em>
        </strong>
      </p>
      <p>
        <span>
          We may use cookies and widgets to deliver advertising we believe is
          relevant to you and to link data collected across other devices you
          use. You can adjust your browser settings to limit tracking or decline
          cookies and widgets, but, as a result, you may not be able to use
          certain features of the Website or take full advantage of all of our
          offerings. Please refer to your device or browser settings for more
          information on how to delete or decline cookies and widgets and
          control your tracking preferences. &nbsp;We do not respond to Do Not
          Track signals or&nbsp;
        </span>
        <span>
          other mechanisms that provide consumers the ability to exercise choice
          regarding the collection of PII about an individual consumer&rsquo;s
          online activities over time and across third-party Web sites or online
          services
        </span>
        <span>.</span>
      </p>
      <p>
        <strong>
          <em>
            <span>
              Collection Through Other Technologies, Third-Party Agencies, and
              Tracking
            </span>
          </em>
        </strong>
      </p>
      <p>
        <span>
          We may collect personal information through the following other
          technologies:
        </span>
      </p>
      <p>
        <strong>
          <em>
            <span>Online Interest-based Advertising.</span>
          </em>
        </strong>
        <em>
          <span>&nbsp;</span>
        </em>
        <span>
          We may also allow a limited number of trusted third parties, including
          our advertising agencies (including Validated Insights Inc., PRESENCE,
          LLC dba TELL ME MORE and Education Dynamics) to install cookies on
          your hard drive from our Website. The Website may include third-party
          advertising and links to other websites which may be used to generate
          personalized advertisements. Personalized ads, sometimes referred to
          as interest-based or behavioral ads, are ads based upon information
          about you, such as your page views on our Website, your information
          requests or purchases on the Website. We do not provide any personal
          information to advertisers or to third party sites that display
          interest-based ads on our Website. However, advertisers and other
          third parties (including the ad networks, ad-serving companies, and
          other service providers they may use) may assume that users who
          interact with or click on a personalized ad or content displayed on
          our Website are part of the group that the ad or content is directed
          towards. Advertisers or ad companies working on their behalf sometimes
          use technology to serve the ads that appear on our Website directly to
          your browser. They may also use cookies to measure the effectiveness
          of their ads and to personalize ad content. We do not have access to
          or control over cookies or other features that advertisers and third
          party websites may use, and the information practices of these
          advertisers and third-party websites are not covered by this Privacy
          Policy. Please contact them directly for more information about their
          privacy practices. You can also generally opt-out of receiving
          personalized ads from third-party advertisers and ad networks who are
          members of the Digital Advertising Alliance&rsquo;s (DAA) or Digital
          Advertising Alliance of Canada if you reside in Canada. Please visit
          the&nbsp;
        </span>
        <a
          href="https://optout.aboutads.info/?c=2&lang=EN"
          target="_blank"
          rel="noreferrer noopener"
        >
          <span>DAA opt-out page</span>
        </a>
        <span>&nbsp;and the&nbsp;</span>
        <a
          href="https://youradchoices.ca/fr/en-savoir-plus"
          target="_blank"
          rel="noreferrer noopener"
        >
          <span>DAAC opt-out page</span>
        </a>
        <span>&nbsp;for more information.</span>
      </p>
      <p>
        <strong>
          <em>
            <span>Google Analytics:</span>
          </em>
        </strong>
        <em>
          <span>&nbsp;</span>
        </em>
        <span>
          Our advertising agencies may use ABC Google Analytics, which allows
          them to see information on user website activities, including, but not
          limited to, page views, source and time spent on our Website. This
          information is depersonalized and is displayed as numbers, meaning
          that it cannot be tracked back to individuals. You may opt out of our
          use of Google Analytics by visiting the&nbsp;
        </span>
        <a
          href="https://tools.google.com/dlpage/gaoptout"
          target="_blank"
          rel="noreferrer noopener"
        >
          <span>Google Analytics opt-out page</span>
        </a>
        <span>.</span>
      </p>
      <p>
        <strong>
          <em>
            <span>Third-Party Social Networks:</span>
          </em>
        </strong>
        <em>
          <span>&nbsp;</span>
        </em>
        <span>
          Third-party social networks that provide interactive plug-ins to
          enable social network features (e.g., to connect to Facebook) on the
          Site may use cookies or widgets to gather information on your use of
          the Website. How this collected data is used by a third party is
          dependent on the privacy policy of the social network, which is
          available on the appropriate parties&rsquo; website. ABC encourages
          you to review the privacy policies of third parties as well. Third
          parties involved may use these tracking methods such as but not
          limited to cookies to achieve their own business goals and purposes by
          relating and combining information about your usage of the Website to
          any other personal information they may have collected on you. ABC may
          also obtain and use analytical and statistical information from
          third-party social networks to help it measure performance and
          effectiveness of content displayed by ABC on social networks, for
          example, by measuring impressions and clicks on the content ABC
          promotes.
        </span>
      </p>
      <p>
        <strong>
          <em>
            <span>Google Ads:</span>
          </em>
        </strong>
        <em>
          <span>&nbsp;</span>
        </em>
        <span>
          ABC uses Google Ads Remarketing to advertise ABC across the Internet
          and to advertise on third-party websites (including Google) to
          previous visitors to the Website. Google Ads remarketing will display
          ads to you based on what parts of the&nbsp;
        </span>
        <u>
          <span>Website</span>
        </u>
        <span>&nbsp;</span>
        <span>
          you have viewed by placing a cookie on your web browser. It could mean
          that ABC advertises to previous visitors who haven&rsquo;t completed a
          task on the Website or this could be in the form of an advertisement
          on the Google search results page, or a website in the Google Display
          Network. This cookie does not in any way identify you or give access
          to your computer or mobile device. The cookie is only used to indicate
          to other websites that you have visited a particular page on the
          Website, so that they may show you ads relating to that page. If you
          do not wish to participate in ABC&rsquo;s Google Ads Remarketing, you
          can opt out by visiting&nbsp;
        </span>
        <a
          href="https://adssettings.google.com/u/0/authenticated?hl=en-GB"
          target="_blank"
          rel="noreferrer noopener"
        >
          <span>Google&rsquo;s Ads Preferences Manager</span>
        </a>
        <span>
          .&nbsp;You may delete or disable certain of these technologies at any
          time via your browser. However, if you do so, you may not be able to
          use some of the features on our Website.
        </span>
      </p>
      <p>
        <span>&nbsp;</span>
      </p>
      <p>
        <strong>
          <span>2. How Do We Use the Information That You Provide to Us?</span>
        </strong>
      </p>
      <p>
        <span>
          We may use your personal information to provide you with our products,
          services, programs and other offers, to manage our business
          operations, to communicate information and offers we think might
          interest you, to generally enhance your customer experience, and as
          otherwise permitted or required by law. Broadly speaking, we may use
          your personal information for the following purposes:
        </span>
      </p>
      <ul>
        <li>
          <span>
            to manage our business operations, including facilitating the
            seeking and providing of employment, and authenticating your
            identify;
          </span>
        </li>
        <li>
          <span>
            to respond to an inquiry, to send you materials, and to otherwise
            communicate with you by email, phone or mail;
          </span>
        </li>
        <li>
          <span>
            to provide customer service, products and/or services to you;
          </span>
        </li>
        <li>
          <span>to customize the content that you see on our Website;</span>
        </li>
        <li>
          <span>
            to conduct market research and data analytics by tracking and
            analyzing current or previously collected information;
          </span>
        </li>
        <li>
          <span>
            to market and otherwise make available other products and services
            to our customers and prospective customers;
          </span>
        </li>
        <li>
          <span>
            to notify you about important changes to our Website, new products,
            services and special offers we think you will find valuable;
          </span>
        </li>
        <li>
          <span>
            to meet our contractual, legal and regulatory obligations.
          </span>
        </li>
      </ul>
      <p>
        <strong>
          <em>
            <span>Notice of New Services and Changes</span>
          </em>
        </strong>
      </p>
      <p>
        <span>
          Occasionally, we may also use the information we collect to notify you
          about important changes to our Website, new products, services and
          special offers we think you will find valuable. The lists used to send
          you product and service offers are developed and managed under our
          traditional corporate standards designed to safeguard the security and
          privacy of our customers&rsquo; personal information. As our customer,
          you will be given the opportunity to notify us of your desire not to
          receive these offers by clicking on a response box when you receive
          such an offer or by sending an email to&nbsp;
        </span>
        <a href="mailto:compliance@escoffier.edu">
          <span>compliance@escoffier.edu</span>
        </a>
        <span>.</span>
      </p>
      <p>
        <strong>
          <em>
            <span>Limiting Use of Personal Information</span>
          </em>
        </strong>
      </p>
      <p>
        <span>
          Our corporate values, ethical standards, policies and practices are
          committed to the protection of customer personal information. In
          general, our business practices limit employee access to personal and
          confidential information, and limit the use and disclosure of such
          information to authorized persons, for authorized processes and
          transactions.
        </span>
      </p>
      <p>
        <span>&nbsp;</span>
      </p>
      <p>
        <strong>
          <span>
            To Whom Do We Disclose The Information That You Provide To Us?
          </span>
        </strong>
      </p>
      <p>
        <strong>
          <em>
            <span>Sharing Information with our Affiliates</span>
          </em>
        </strong>
      </p>
      <p>
        <span>
          We will share personal information of our users with our affiliates.
        </span>
      </p>
      <p>
        <strong>
          <em>
            <span>Sharing Information with Strategic Partners</span>
          </em>
        </strong>
      </p>
      <p>
        <span>
          If we enter into strategic marketing alliances or partnerships with
          third parties, we will not share any personal information about our
          customers or potential customers.
        </span>
      </p>
      <p>
        <strong>
          <em>
            <span>Do We Disclose Information to Outside Parties?</span>
          </em>
        </strong>
      </p>
      <p>
        <span>
          We may hire service providers, which may be affiliates, to perform
          services on our behalf. This may include, without limitation, a person
          or an organization retained by us to perform work on our behalf,
          including for marketing research, customer service and web-related
          services, and quality control assessment. We provide them with a
          limited amount of information which is necessary in order for them to
          provide the services required. They are prohibited from using the
          information for purposes other than to facilitate and carry out the
          services they have been engaged to provide.
        </span>
      </p>
      <p>
        <span>
          We may also disclose personal information to outside parties in the
          following circumstances:
        </span>
      </p>
      <ul>
        <li>
          <span>
            In connection with a sale or transfer of business or assets, an
            amalgamation, reorganization or financing of parts of our business
            (including the proceedings of insolvency or bankruptcy). In the
            event the transaction is completed, your personal information will
            remain protected by applicable privacy laws. In the event the
            transaction is not completed, we will require the other party not to
            use or disclose your personal information in any manner whatsoever
            and to completely delete such information, in compliance with
            applicable laws.
          </span>
        </li>
        <li>
          <span>
            When legally compelled to do so in response to a law, regulation,
            court order, subpoena, valid demand, search warrant, government
            investigation or other legally valid request or inquiry, in other
            words, when we, in good faith, believe that the law requires it or
            for the protection of our legal rights.
          </span>
        </li>
        <li>
          <span>
            To our accountants, auditors, agents and lawyers in connection with
            the enforcement or protection of our legal rights.
          </span>
        </li>
        <li>
          <span>
            When we have reasonable grounds to believe that such disclosure is
            reasonably necessary to protect the rights, property and safety of
            others and ourselves, in accordance with or as authorized by law.
          </span>
        </li>
        <li>
          <span>Where otherwise permitted or required to do so by law.</span>
        </li>
      </ul>
      <p>
        <span>
          To the extent permitted by law, we may also provide aggregate
          information about our customers, placements, salary levels, potential
          customers, sales, website traffic patterns, and related website
          information to our affiliates or other reputable third parties, but
          this information will not include personally identifiable information.
        </span>
      </p>
      <p>
        <span>&nbsp;</span>
      </p>
      <p>
        <strong>
          <span>How We Manage Consent</span>
        </strong>
      </p>
      <p>
        <span>
          By visiting our Website (including using our online services, making
          purchases, creating an account or participating in an online program)
          and/or submitting information to us in connection with our products
          and services, you are providing your consent to the collection, use
          and disclosure of personal information as set out in this Privacy
          Policy. In some cases, your consent may be &ldquo;implied,&rdquo;{' '}
          <em>i.e.</em>, your permission is assumed based on your action or
          inaction at the point of collection, use or sharing of your personal
          information.
        </span>
      </p>
      <p>
        <span>
          We will generally obtain consent when we want to use personal
          information for a new purpose or for a purpose other than those stated
          at the time of collection in this Privacy Policy or in the terms and
          conditions of a specific product, service, program, promotion or event
          you signed up for, participated in or purchased. Your consent can be
          withdrawn at any time, except in limited circumstances, including
          legal or regulatory requirements or as a result of your contractual
          obligations with us.
        </span>
      </p>
      <p>
        <span>
          If you choose not to provide us with certain personal information or
          if you withdraw your consent, where such withdrawal is available, we
          may not be able to offer you the products, services or programs that
          you requested or that could be offered to you.
        </span>
      </p>
      <p>
        <span>&nbsp;</span>
      </p>
      <p>
        <strong>
          <span>How Can You Access and Correct Your Information?</span>
        </strong>
      </p>
      <p>
        <span>
          You may request access to your personally identifiable information
          that we collect through our Website and maintain in our database,
          subject to legal restrictions, by emailing us at&nbsp;
        </span>
        <a href="mailto:compliance@escoffier.edu">
          <span>compliance@escoffier.edu</span>
        </a>
        <span>
          . You can also request to update, amend, add or remove your personal
          information at any time by emailing us at the above email address. It
          is your responsibility to provide accurate, correct and complete
          information.
        </span>
      </p>
      <p>
        <span>&nbsp;</span>
      </p>
      <p>
        <strong>
          <span>How We Store and Safeguard Personal Information</span>
        </strong>
      </p>
      <p>
        <span>
          We take the security of your personal information very seriously and
          are committed to protecting your privacy by using a combination of
          administrative, physical and technical safeguards implemented by us.
        </span>
      </p>
      <p>
        <span>
          We employ organizational, physical and technological measures to
          protect the confidentiality of personal information and to safeguard
          personal information against loss or theft, as well as unauthorized
          access, disclosure, copying, use or modification, in light of, among
          other things, the sensitivity of the information and the purposes for
          which it is to be used. These measures include access restrictions,
          encryption, and personnel training concerning the importance of
          preserving the privacy, confidentiality, and security of the personal
          information collected. These safeguards also apply when we dispose of
          or destroy your personal information.
        </span>
      </p>
      <p>
        <span>
          Please be careful whenever sending personal information to us
          electronically. Please note that transmissions over the Internet are
          never completed protected or exempt from error. It is the reason why
          we cannot guarantee the security of the information transmitted
          electronically since such transmissions are conducted at your risk and
          peril. More specifically, an email sent to our Website or from our
          Website cannot be protected. We recommend that you keep this in mind
          when you determine the nature of the information transmitted
          electronically. Moreover, when you use them, the protection of your
          login, password and other special access elements of our Website is
          your exclusive responsibility. Please ensure that they are kept
          confidential.
        </span>
      </p>
      <p>
        <span>&nbsp;</span>
      </p>
      <p>
        <strong>
          <span>Retention of Personal Information</span>
        </strong>
      </p>
      <p>
        <span>
          Personal information will be retained only as long as necessary for
          the fulfilment of the purposes for which it was collected and for
          which consent was received, unless otherwise authorized or required by
          law. Subject to the foregoing, personal information that is no longer
          required to fulfil the identified purposes will be destroyed, erased
          or made anonymous.
        </span>
      </p>
      <p>
        <strong>
          <span>Transfer of Personal Information Outside of The Country</span>
        </strong>
      </p>
      <p>
        <span>
          Your personal information may be stored and/or processed by us or by
          our services providers outside of your country of residence. We uses
          reasonable safeguards, such as contractual requirements, to ensure
          that our service providers protect your personal information wherever
          it is used or stored.
        </span>
      </p>
      <p>
        <span>
          As a result of the foregoing, when your personal information is used,
          access, stored or processed in a country other than your country of
          residence, it may be subject to the laws of this foreign jurisdiction,
          including any law permitting or requiring disclosure of the
          information to the government, government agencies, courts and law
          enforcement in that jurisdiction.
        </span>
      </p>
      <p>
        <strong>
          <span>Minors</span>
        </strong>
      </p>
      <p>
        <span>
          It is neither our goal nor our intention to collect personal
          information concerning persons under the age of majority in their
          state, province or territory of residence without their parent or
          legal guardian&rsquo;s consent. We do not knowingly provide any
          personal information collected from such persons, regardless of its
          source, to any third party for any purpose whatsoever. Notwithstanding
          the foregoing, it is possible that we nevertheless collect such
          information if same is provided to us.
        </span>
      </p>
      <p>
        <span>
          We, therefore, encourage parents to inform their children about the
          possible consequences of using our Website and disclosing information
          through the Website. We will not assume any responsibility with
          respect to the use of our Website or disclosure of information via our
          Website by children.
        </span>
      </p>
      <p>
        <span>
          MINORS ARE HEREBY REQUIRED TO OBTAIN THE PERMISSION OF THEIR PARENT OR
          LEGAL GUARDIAN BEFORE PROVIDING ANY PERSONAL INFORMATION TO US.
        </span>
      </p>
      <p>
        <strong>
          <span>Information for California Consumers</span>
        </strong>
      </p>
      <p>
        <span>
          The California Consumer Privacy Act of 2018 (&ldquo;
          <strong>CCPA</strong>&rdquo;) grants a right of privacy certain rights
          and protections to California residents. This section is intended to
          further describe the categories presented in the updated act including
          (a) what personal information is being collected about them; (b)
          whether or not their personal information is being shared or disclosed
          and to whom (c) the right to say no to the sale of personal
          information; and (d) the right to access their personal information.
        </span>
      </p>
      <p>
        <strong>
          <em>
            <span>
              Categories of Data AESCA Collects from California and Canadian
              Consumers:&nbsp;
            </span>
          </em>
        </strong>
        <span>
          We collect the following categories of data: (a) identifiers, such as
          a name, email address, telephone number, physical address, unique
          personal identifier (such as student ID), online identifier, IP
          address, account name, Social Security number or Social Insurance
          number, driver&rsquo;s license number; (b) personal identifiers under
          Cal. Civ. Code &sect; 1798.80(e), such as education, employment
          status, bank account information, credit card or debit card number,
          financial information; (c) protected classification characteristics
          under California law, such as age, marital status, gender, veteran or
          military status; (d) commercial information, such as products or
          services obtained, or considered, or other purchasing or consuming
          histories or tendencies; (e) internet activity, such as browsing
          history and search history information; (f) geolocation data; (g)
          professional or employment information, such as consumer-reported
          current or past job history; (h) inferences drawn from some of the
          information above (<em>e.g.</em>, email open rates and time). Personal
          information collected as noted in this policy that goes beyond name,
          address, email, location, or program of interest is collected only
          when necessary to enable us to provided expected services to
          you.&nbsp;
        </span>
      </p>
      <p>
        <strong>
          <em>
            <span>CCPA Rights</span>
          </em>
        </strong>
        <em>
          <span>:&nbsp;</span>
        </em>
        <span>
          The CCPA requires certain rights and protections to California
          residents including: (a) the right to request that we delete any
          &ldquo;personal information&rdquo; that we have collected from you;
          (b) the right to request that we disclose the categories and/or
          specific pieces of personal information collected and sold; and (c)
          the right to direct us not to sell their &ldquo;personal
          information&rdquo;. &ldquo;Personal information&rdquo; is defined
          under the CCPA as &ldquo;information that identifies, relates to,
          describes, is reasonably capable of being associated with, or could
          reasonably be linked, directly or indirectly, with a particular
          consumer or household.&rdquo;
        </span>
      </p>
      <p>
        <span>
          California residents who would like to submit a Request to Know,
          Delete, or Opt-Out should request an opt-out by emailing us at&nbsp;
        </span>
        <a href="mailto:compliance@escoffier.edu">
          <span>compliance@escoffier.edu&nbsp;</span>
        </a>
        <span>
          or by calling us at (855) 280-3980 . We will use your email address to
          verify your identity. Provided we are able to confirm your identify,
          we will respond within 30 days of your request.
        </span>
      </p>
      <p>
        <strong>
          <em>
            <span>Demographic &amp; Personal Information</span>
          </em>
        </strong>
        <em>
          <span>:&nbsp;</span>
        </em>
        <span>
          We may collect your name and address if you have requested information
          from us or have been an active student with us at any point. We also
          collect phone numbers and email addresses. We may also collect your
          information regarding your age, gender, race or ethnicity if that was
          appropriate information based on your relationship with potential
          employers, but only if it is required by them.&nbsp;
        </span>
      </p>
      <p>
        <strong>
          <em>
            <span>Device and location information</span>
          </em>
        </strong>
        <em>
          <span>:&nbsp;</span>
        </em>
        <span>
          &nbsp;We may also collect information about the browser or app you are
          using. We might look at what website you came from, or what website
          you visit when you leave us. If you use our app, we might look at how
          often you use the app and where you downloaded it. This might include
          your device identification number, IP address or mobile operating
          system. We may also collect location information.&nbsp;
        </span>
      </p>
      <p>
        <strong>
          <em>
            <span>Enrollment and payment information</span>
          </em>
        </strong>
        <em>
          <span>:&nbsp;</span>
        </em>
        <span>
          We may collect information, including date of birth, citizenship and
          military status, as applicable. We may also collect information about
          other schools you have attended and employment history. We might also
          collect your credit card or bank account number and billing
          address.&nbsp;
        </span>
      </p>
      <p>
        <strong>
          <span>What About Other Websites Linked to Our Website?</span>
        </strong>
      </p>
      <p>
        <span>
          We are not responsible for the practices employed by websites linked
          to or from our Website nor the information or content contained
          therein. Links to other websites are provided solely as pointers to
          information on topics that may be useful to the users of our Website.
        </span>
      </p>
      <p>
        <strong>
          <span>Changes to The Privacy Policy</span>
        </strong>
      </p>
      <p>
        <span>
          We may make changes to this Privacy Policy from time to time. Any
          changes that we make will become effective when we post a modified
          version of the Policy on our Website. If the changes made are
          significant, we will provide a more prominent notice when required by
          applicable laws. By visiting our Website, or using services after a
          modified version of the Privacy Policy has been posted or you have
          been informed of such update, you are accepting the changes to the
          Privacy Policy. It is your obligation to ensure that you read,
          understand and agree to the latest version of the Privacy Policy. The
          &ldquo;Effective Date&rdquo; at the top of the Privacy Policy
          indicates when it was last updated. If significant changes are
          expected to occur in our Privacy Policy, we will notify the applicable
          users in advance and provide a mechanism to opt in or out of these
          changes.
        </span>
      </p>
      <p>
        <strong>
          <span>How to Contact Us</span>
        </strong>
      </p>
      <p>
        <span>
          If you have any questions regarding our data collection and data
          protection practices or this Privacy Policy, or those of our service
          providers, or if you have a complaint in connection with matters
          relating to this Privacy Policy, you can contact our Privacy Officer
          at the following coordinates:
        </span>
      </p>
      <p>
        <strong>
          <em>
            <span>Email</span>
          </em>
        </strong>
        <span>:&nbsp;</span>
        <a href="mailto:compliance@escoffier.edu">
          <span>compliance@escoffier.edu</span>
        </a>
      </p>
      <p>
        <strong>
          <em>
            <span>Phone</span>
          </em>
        </strong>
        <span>: (855) 280-3980</span>
      </p>
      <p>
        <strong>
          <em>
            <span>Mail</span>
          </em>
        </strong>
        <span>
          :<br />
          &nbsp;Triumph Higher Education Corporation
          <br />
          &nbsp;Attn: Privacy Officer, Auguste Escoffier School of Culinary Arts
          <br />
          &nbsp;150 North Martingale Rd., Suite 300
          <br />
          &nbsp;Schaumburg, IL U.S.A. 60173
        </span>
      </p>
      <p>
        <span>Effective as of&nbsp;</span>
        <u>
          <span>4/12/2022</span>
        </u>
        <span>.</span>
      </p>
    </LegalContentSection>
  );
};

export default PrivacyPolicyContent;
