import * as React from 'react';
import { Container, Step, StepLabel, Stepper } from '@mui/material';
import Box from '@mui/material/Box';
import ScrollToTopOnMount from '@components/ScrollToTopOnMount';
import { StepperContextProvider } from '@common/context/stepperContext';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

interface Props {
  steps: string[];
  stepContent: (
    handleStepper: (next: boolean) => void
  ) => (step: number) => React.ReactElement;
  loading?: boolean;
  setLoading: (value: boolean) => void;
}

const ProfileSetupStepper: React.FC<Props> = ({
  steps,
  stepContent,
  loading = false,
  setLoading
}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const { width } = useWindowDimensions();

  const handleStepper = (next: boolean): void => {
    if (!loading) {
      const move = next ? 1 : -1;
      setActiveStep((prevActiveStep) => prevActiveStep + move);
      window.scrollTo(0, 0);
    }
  };

  const content = stepContent(handleStepper);

  return (
    <StepperContextProvider
      value={{ stepperLoading: loading, setStepperLoading: setLoading }}
    >
      <Box
        sx={{
          backgroundColor: '#F4F4F4',
          paddingBottom: '90px'
        }}
      >
        <ScrollToTopOnMount />
        <Container
          disableGutters={width < 600}
          sx={{ backgroundColor: '#F4F4F4' }}
        >
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((step) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={step} {...stepProps}>
                  <StepLabel {...labelProps}>{step}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <React.Fragment>{content(activeStep)}</React.Fragment>
        </Container>
      </Box>
    </StepperContextProvider>
  );
};

export default ProfileSetupStepper;
