/* eslint-disable @typescript-eslint/indent */
import {
  ActivityCommentPutReq,
  ActivityLogEntity,
  AddCandidateNotesDto,
  AssignCareerServicesUserToStudentsPutReq,
  CandidateNotesInterface,
  CareerServiceExternshipFile,
  CareerServicesExtern,
  CareerServicesUserEntity,
  ChefInstructorTimesheetInterface,
  CsCompanyProfile,
  DeprecatedChefInstructorTimesheetInterface,
  DeprecatedGetTimesheetsForChefInstructorDto,
  EcEnrollmentsResponse,
  EnablePilotProgramReq,
  GetTimesheetsForChefInstructorDto,
  ProgressPostReq,
  SendEmailBatchReq,
  UploadFilesDto
} from '@api/models/careerServicesApi.models';
import { api } from './configs/base';
import { apiConfig } from './configs/api.config';
import { ApiResponse } from '@api/models/base.models';
import KeycloakUser from '@interfaces/keycloak.interfaces';
import { StudentViewExternship } from '@api/models/externshipApi.models';

export class CareerServicesApi {
  static async GetExternshipEnrollmentsByExternshipBeginDate(
    externshipBeginDate: Date,
    refresh?: boolean
  ): Promise<EcEnrollmentsResponse> {
    // eslint-disable-next-line @typescript-eslint/indent
    try {
      const response = await api.get<ApiResponse<EcEnrollmentsResponse>>(
        `${
          apiConfig.careerServiceUrl
        }/students/externship-enrollments/${externshipBeginDate.toISOString()}/refresh/${!!refresh}`
      );

      return response.data.data;
    } catch (error) {
      console.error(
        `Error for CareerServicesApi.GetExternshipEnrollmentsByExternshipBeginDate(): ${error}`
      );
      throw error;
    }
  }

  static async getCareerServicesUsers(): Promise<KeycloakUser[]> {
    try {
      const response = await api.get<ApiResponse<KeycloakUser[]>>(
        `${apiConfig.careerServiceUrl}/users`
      );

      return response.data.data;
    } catch (error) {
      console.error(
        `Error for CareerServicesApi.GetCareerServicesUsers(): ${error}`
      );
      throw error;
    }
  }

  static async getStudentExternships(
    candidateId: number
  ): Promise<StudentViewExternship[]> {
    try {
      const response = await api.get<ApiResponse<StudentViewExternship[]>>(
        `${apiConfig.careerServiceUrl}/students/${candidateId}/externships`
      );

      return response.data.data;
    } catch (error) {
      console.error(
        `Error for CareerServicesApi.getStudentExternships(): ${error}`
      );
      throw error;
    }
  }

  static async renewExternship(
    externshipId: number,
    studentCourseId: number
  ): Promise<StudentViewExternship> {
    try {
      const response = await api.post<ApiResponse<StudentViewExternship>>(
        `${apiConfig.careerServiceUrl}/externship/${externshipId}/student-course/${studentCourseId}/renew`
      );

      return response.data.data;
    } catch (error) {
      console.error(`Error for CareerServicesApi.renewExternship(): ${error}`);
      throw error;
    }
  }

  static async acknowledgeStartDateChange(
    externshipId: number
  ): Promise<StudentViewExternship> {
    try {
      const response = await api.post<ApiResponse<StudentViewExternship>>(
        `${apiConfig.careerServiceUrl}/externship/${externshipId}/acknowledge-start-date-change`
      );

      return response.data.data;
    } catch (error) {
      console.error(
        `Error for CareerServicesApi.acknowledgeStartDateChange(): ${error}`
      );
      throw error;
    }
  }

  static async getCompanies(): Promise<CsCompanyProfile[]> {
    try {
      const response = await api.get<ApiResponse<CsCompanyProfile[]>>(
        `${apiConfig.careerServiceUrl}/companies`
      );

      return response.data.data;
    } catch (error) {
      console.error(`Error for CareerServicesApi.getCompanies: ${error}`);
      throw error;
    }
  }

  /**
   * Activity & Progress
   */

  static async getExternshipActivity(
    externshipId: number
  ): Promise<ApiResponse<ActivityLogEntity[]>> {
    try {
      const response = await api.get<ApiResponse<ActivityLogEntity[]>>(
        `${apiConfig.careerServiceUrl}/externships/${externshipId}/activity`
      );

      return response.data;
    } catch (error) {
      console.error(
        `Error for CareerServicesApi.getExternshipActivity(): ${error}`
      );
      throw error;
    }
  }

  static async getExternshipProgress(
    externshipId: number
  ): Promise<ApiResponse<ActivityLogEntity[]>> {
    try {
      const response = await api.get<ApiResponse<ActivityLogEntity[]>>(
        `${apiConfig.careerServiceUrl}/externships/${externshipId}/progress`
      );

      return response.data;
    } catch (error) {
      console.error(
        `Error for CareerServicesApi.getExternshipProgress(): ${error}`
      );
      throw error;
    }
  }

  static async addExternshipHistoryComment(
    externshipId: number,
    data: ActivityCommentPutReq
  ): Promise<ApiResponse<ActivityLogEntity>> {
    try {
      const response = await api.put<ApiResponse<ActivityLogEntity>>(
        `${apiConfig.careerServiceUrl}/externships/${externshipId}/comment`,
        data
      );

      return response.data;
    } catch (error) {
      console.error(
        `Error for CareerServicesApi.addExternshipHistoryComment(): ${error}`
      );
      throw error;
    }
  }

  static async addExternshipProgressWithComment(
    externshipId: number,
    data: ProgressPostReq
  ): Promise<ActivityLogEntity> {
    try {
      const response = await api.post<ApiResponse<ActivityLogEntity>>(
        `${apiConfig.careerServiceUrl}/externships/${externshipId}/progress-with-comment`,
        data
      );

      return response.data.data;
    } catch (error) {
      console.error(
        `Error for CareerServicesApi.addExternshipProgressWithComment(): ${error}`
      );
      throw error;
    }
  }

  static async addCommentToProgress(
    parentProgressId: number,
    data: ProgressPostReq
  ): Promise<ActivityLogEntity> {
    try {
      const response = await api.put<ApiResponse<ActivityLogEntity>>(
        `${apiConfig.careerServiceUrl}/progress/${parentProgressId}/comment`,
        data
      );

      return response.data.data;
    } catch (error) {
      console.error(
        `Error for CareerServicesApi.addCommentToProgress(): ${error}`
      );
      throw error;
    }
  }

  static async createProgressWithChecked(
    externshipId: number,
    data: ProgressPostReq
  ): Promise<ActivityLogEntity> {
    try {
      const response = await api.post<ApiResponse<ActivityLogEntity>>(
        `${apiConfig.careerServiceUrl}/externships/${externshipId}/progress-with-checked`,
        data
      );

      return response.data.data;
    } catch (error) {
      console.error(
        `Error for CareerServicesApi.createProgressWithChecked: ${error}`
      );
      throw error;
    }
  }

  static async updateProgressChecked(
    progressId: number,
    data: ProgressPostReq
  ): Promise<ActivityLogEntity> {
    try {
      const response = await api.put<ApiResponse<ActivityLogEntity>>(
        `${apiConfig.careerServiceUrl}/progress/${progressId}/check`,
        data
      );

      return response.data.data;
    } catch (error) {
      console.error(
        `Error for CareerServicesApi.updateProgressChecked: ${error}`
      );
      throw error;
    }
  }

  static async assignCareerServicesUserToStudents(
    data: AssignCareerServicesUserToStudentsPutReq
  ): Promise<CareerServicesExtern[]> {
    try {
      const response = await api.put<ApiResponse<CareerServicesExtern[]>>(
        `${apiConfig.careerServiceUrl}/students/assignedTo`,
        data
      );

      return response.data.data;
    } catch (error) {
      console.error(
        `Error for CareerServicesApi.assignCareerServicesUserToStudents(): ${error}`
      );
      throw error;
    }
  }

  static async sendEmailToStudents(data: SendEmailBatchReq): Promise<any> {
    try {
      const response = await api.post<ApiResponse<any>>(
        `${apiConfig.careerServiceUrl}/students/sendEmails`,
        data
      );

      return response.data.data;
    } catch (error) {
      console.error(
        `Error for CareerServicesApi.assignCareerServicesUserToStudents(): ${error}`
      );
      throw error;
    }
  }

  static async enableExternshipPilotForStudents(
    data: EnablePilotProgramReq
  ): Promise<CareerServicesExtern[]> {
    try {
      const response = await api.put<ApiResponse<CareerServicesExtern[]>>(
        `${apiConfig.careerServiceUrl}/students/pilot-program`,
        data
      );

      return response.data.data;
    } catch (error) {
      console.error(
        `Error for CareerServicesApi.enableExternshipPilotForStudents(): ${error}`
      );
      throw error;
    }
  }

  // TODO: Delete when new dash is fully adopted
  static async deprecatedGetTimesheetsForChefInstructor(
    data: DeprecatedGetTimesheetsForChefInstructorDto
  ): Promise<DeprecatedChefInstructorTimesheetInterface[]> {
    try {
      const response = await api.post<
        ApiResponse<DeprecatedChefInstructorTimesheetInterface[]> // eslint-disable-line @typescript-eslint/indent
      >(`${apiConfig.careerServiceUrl}/deprecated-timesheets`, data);

      return response.data.data;
    } catch (error) {
      console.error(
        `Error for CareerServicesApi.deprecatedGetTimesheetsForChefInstructor(): ${error}`
      );
      throw error;
    }
  }

  static async getTimesheetsForChefInstructor(
    data: GetTimesheetsForChefInstructorDto
  ): Promise<ChefInstructorTimesheetInterface[]> {
    try {
      const response = await api.post<
        ApiResponse<ChefInstructorTimesheetInterface[]> // eslint-disable-line @typescript-eslint/indent
      >(`${apiConfig.careerServiceUrl}/timesheets`, data);

      return response.data.data;
    } catch (error) {
      console.error(
        `Error for CareerServicesApi.getTimesheetsForChefInstructor(): ${error}`
      );
      throw error;
    }
  }

  static async getCandidateNotes(
    candidateId: string
  ): Promise<CandidateNotesInterface> {
    try {
      const response = await api.get<
        ApiResponse<CandidateNotesInterface> // eslint-disable-line @typescript-eslint/indent
      >(`${apiConfig.careerServiceUrl}/notes/${candidateId}`);

      return response.data.data;
    } catch (error) {
      console.error(
        `Error for CareerServicesApi.getCandidateNotes(): ${error}`
      );
      throw error;
    }
  }

  static async addCandidateNotes(
    candidateId: string,
    data: AddCandidateNotesDto
  ): Promise<CandidateNotesInterface> {
    try {
      const response = await api.post<
        ApiResponse<CandidateNotesInterface> // eslint-disable-line @typescript-eslint/indent
      >(`${apiConfig.careerServiceUrl}/notes/${candidateId}`, data);

      return response.data.data;
    } catch (error) {
      console.error(
        `Error for CareerServicesApi.getCandidateNotes(): ${error}`
      );
      throw error;
    }
  }

  static async uploadFiles(
    externshipId: number,
    data: UploadFilesDto
  ): Promise<CareerServiceExternshipFile[]> {
    try {
      const response = await api.put<
        ApiResponse<CareerServiceExternshipFile[]> // eslint-disable-line @typescript-eslint/indent
      >(
        `${apiConfig.careerServiceUrl}/externship/${externshipId}/upload-files`,
        data
      );

      return response.data.data;
    } catch (error) {
      console.error(`Error for CareerServicesApi.uploadFiles(): ${error}`);
      throw error;
    }
  }

  static async saveExternshipDatePreference(
    date: Date
  ): Promise<CareerServicesUserEntity> {
    try {
      const response = await api.put<ApiResponse<CareerServicesUserEntity>>(
        `${apiConfig.careerServiceUrl}/externship-start-date-preference`,
        {
          date
        }
      );

      return response.data.data;
    } catch (error) {
      console.error(
        `Error for CareerServicesApi.saveExternshipDatePreference(): ${error}`
      );
      throw error;
    }
  }
}
