/* eslint-disable @typescript-eslint/indent */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CareerServicesApi } from '@api/CareerService.api';
import { StudentViewExternship } from '@api/models/externshipApi.models';

interface FetchStudentExternships {
  externships: StudentViewExternship[];
  allExternships: StudentViewExternship[];
  loaded: true | null;
  loading: boolean;
  setExternships: Dispatch<SetStateAction<StudentViewExternship[]>>;
  setAllExternships: Dispatch<SetStateAction<StudentViewExternship[]>>;
}

export const useFetchStudentExternshipsForCareerServices = (
  candidateId?: number
): FetchStudentExternships => {
  const [externships, setExternships] = useState<StudentViewExternship[]>([]);
  const [allExternships, setAllExternships] = useState<StudentViewExternship[]>(
    []
  );
  const [loaded, setLoaded] = useState<true | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchExternships = async (id: number): Promise<void> => {
    try {
      setLoading(true);
      const res = await CareerServicesApi.getStudentExternships(id);

      setExternships(res);
      setAllExternships(res);
    } catch (error) {
      console.error(
        'Error for useFetchStudentExternshipsForCareerServices',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (candidateId) {
      fetchExternships(candidateId);
    }
  }, [candidateId]);

  return {
    externships,
    loaded,
    loading,
    setExternships,
    allExternships,
    setAllExternships
  };
};
