import * as React from 'react';
import { Collapse, Typography } from '@mui/material';
import { Box } from '@mui/system';
import RotatingCaret from './RotatingCaret';
import { styled } from '@mui/system';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const Styled = {
  Title: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'clickable'
  })<{ clickable?: boolean }>(({ clickable }) => ({
    display: 'flex',
    ':hover': {
      cursor: clickable ? 'pointer' : 'default'
    }
  })),
  CaretWrapper: styled(Box)({
    top: '4px',
    position: 'relative',
    marginRight: '8px'
  })
};

interface Props {
  title?: string;
  dataTestId?: string;
  expandable?: boolean;
  padding?: string;
  disableBoxShadow?: boolean;
}

const StepSection: React.FC<Props> = ({
  children,
  title,
  dataTestId,
  expandable,
  padding,
  disableBoxShadow = false
}) => {
  const [expand, setExpand] = React.useState<boolean>(false);

  const { width } = useWindowDimensions();

  const margin = width < 600 ? '0px 0px 24px 16px' : '0px 0px 24px 0px';
  const _padding = padding ? padding : width < 600 ? '46px 24px' : '62px 76px';

  return (
    <Box data-testid={dataTestId}>
      {title && (
        <Styled.Title
          clickable={expandable}
          onClick={(): void => setExpand(!expand)}
        >
          {expandable && (
            <Styled.CaretWrapper data-testid={`${title}-caret`}>
              <RotatingCaret
                expanded={expand}
                onClick={(): void => setExpand(!expand)}
              />
            </Styled.CaretWrapper>
          )}
          <Typography variant="EC_TYPE_3XL" sx={{ margin: margin }}>
            {title}
          </Typography>
        </Styled.Title>
      )}
      <Collapse in={expandable ? expand : true}>
        <Box
          data-testid={`${title}-expandable-content`}
          sx={{
            padding: _padding,
            backgroundColor: 'white',
            borderRadius: '4px',
            boxShadow: disableBoxShadow
              ? 'none'
              : '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            '& > *:not(:last-child)': { marginBottom: '32px' }
          }}
        >
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

export default StepSection;
