import * as React from 'react';
import {
  Box,
  Button,
  Divider,
  Icon,
  Skeleton,
  Typography
} from '@mui/material';
import {
  ExternshipStatus,
  Timesheet,
} from '@api/models/externshipApi.models';
import { convertHoursAndMinsToMins } from '@common/helpers/timesheetHelpers/convertHoursAndMinsToMins';
import { convertMinutesToHoursAndMinutes } from '@common/helpers/timesheetHelpers/convertMinsToHoursAndMins';
import EcProgressBar from '@components/EcProgressBar';
import { getFilePath } from '@common/helpers/getFilePath';
import { getOneLineAddress } from '@common/helpers/addressHelpers/getOneLineAddress';
import { getTimesheetStatusHours } from '@common/helpers/externshipHelpers/getTimesheetStatusHours';
import GoldenInfoBox from '@pages/Shared/TimesheetPage/GoldenInfoBox';
import NoDataMessage from '@components/NoDataMessage';
import NotFoundPage from '@pages/Errors/NotFoundPage';
import PdfLink from '@components/PdfLink';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { styled } from '@mui/system';
import TimesheetAccetStatement from '@pages/Shared/TimesheetPage/TimesheetAccetStatement';
import TimesheetAccordion from '@pages/Shared/TimesheetPage/TimesheetAccordion';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchTimesheetsByExternship } from '@common/fetches/useFetchTimesheetsByExternship';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { useParams } from 'react-router-dom';

const Styled = {
  OverviewSection: styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'end',
    columnGap: '48px',
    rowGap: '48px',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  })),
  CandidateOrCompanyName: styled(Typography)(({ theme }) => ({
    color: theme.palette.GRAY_4.main
  })),
  ExternshipDetailsWrapper: styled(Box)({
    display: 'flex',
    columnGap: '24px',
    rowGap: '32px',
    margin: '48px 0px 0px',
    flexWrap: 'wrap'
  }),
  ExternshipDetail: styled(Box)({
    minWidth: '150px'
  }),
  ExternshipProgressWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'displayProgressBar'
  })<{ displayProgressBar: boolean }>(({ displayProgressBar }) => ({
    flex: displayProgressBar ? '0 1 443px' : 'unset',
    display: 'flex',
    marginLeft: 'auto',
    columnGap: '8px',
    alignItems: 'flex-start'
  })),
  Divider: styled(Divider)(({ theme }) => ({
    margin: '32px 0px',
    backgroundColor: theme.palette.GRAY_2.main
  })),
  AddSupervisorBtn: styled(Button)({
    '&.MuiButtonBase-root:hover': {
      bgcolor: 'transparent'
    },
    marginTop: '5px'
  }),
  AddSupervisorIcon: styled(Icon)({
    marginLeft: '2px',
    marginRight: '-6px'
  })
};

interface RouteParams {
  externshipId: string;
}

const TimesheetPage: React.FC = () => {
  const [isApproverViewing, setIsApproverViewing] = React.useState(false);
  const [isCandidateViewing, setIsCandidateViewing] = React.useState(true);
  const [approverNames, setApproverNames] = React.useState('');
  const featureFlags = useFeatureFlags();
  const { keycloakUser } = useKeycloakContext();

  const { externshipId } = useParams<RouteParams>();

  const { loading, externship, timesheets, setTimesheets } = useFetchTimesheetsByExternship(+externshipId);

  React.useEffect(() => {
    if (externship) {
      // Set approver names
      const approverNamesArray: string[] = externship.approvers?.map(
        (approver) => `${approver.firstName} ${approver.lastName}`,
      ) ?? [];
      setApproverNames(approverNamesArray.join(', '));

      if (keycloakUser) {
        // Set isApprover
        const newIsApprover = externship.approvers?.some(approver =>
          approver.user?.id === keycloakUser.id && approver.canApproveTimesheets
        ) ?? false;
        const isCurrentUserBothCandidateAndApprover = externship.approvers?.some(approver =>
          approver.user?.id === keycloakUser.id &&
          approver.user?.id === externship.candidate.user?.id
        );
        setIsApproverViewing(newIsApprover && !isCurrentUserBothCandidateAndApprover);
        setIsCandidateViewing(keycloakUser.id === externship.candidate.user?.id);
      }
    }
  }, [externship, keycloakUser]);

  const VIEWABLE_STATUSES = [
    ExternshipStatus.ACTIVE,
    ExternshipStatus.COMPLETED,
    ExternshipStatus.NO_PASS
  ];

  if (externship?.status && !VIEWABLE_STATUSES.includes(externship?.status)) {
    return <NotFoundPage />;
  }

  const { approvedTime, submittedTime, approvedMinutes, pendingProgress } =
    getTimesheetStatusHours(timesheets, externship?.course.ClockHours);

  const updateTimesheets = (timesheet: Timesheet): void => {
    const newTimesheets = [...timesheets];
    const idx = timesheets?.findIndex((t) => t.id === timesheet.id);

    if (idx !== -1) {
      newTimesheets[idx] = timesheet;
    }

    setTimesheets(newTimesheets);
  };

  if (loading) {
    return (
      <RootPageLayout muiMaxWidth={'xl'}>
        <Skeleton height={'50vh'} />
      </RootPageLayout>
    );
  }

  if (!externship) {
    return <NotFoundPage />;
  }

  const externshipInfo = [
    { label: 'Program', value: externship?.currentProgram.label },
    {
      label: 'Course',
      value: `${externship?.course.Code} - ${externship?.course.Name}`
    },
    {
      label: 'Approver(s)',
      value: approverNames
    }
  ];

  const externshipComplete = externship.status === ExternshipStatus.COMPLETED;
  const pdf = getFilePath(externship.externshipAgreement?.file?.fullName);
  const percentComplete =
    100 * (approvedMinutes / (externship.course.ClockHours * 60));
  const pageTitle = isApproverViewing
    ? `${externship.candidate.firstName} ${externship.candidate.lastName}`
    : 'Submit Hours';

  const getApprovedAndSubmittedTime = (): string => {
    const submittedMins =
      convertHoursAndMinsToMins(approvedTime) +
      convertHoursAndMinsToMins(submittedTime);

    return convertMinutesToHoursAndMinutes(submittedMins);
  };

  return (
    <RootPageLayout muiMaxWidth="xl" bgImgVariant={6}>
      <Typography variant="EC_TYPE_4XL">Externship Time Sheets</Typography>
      <Typography
        variant="EC_TYPE_2XL"
        mb="24px"
        data-testid="timesheet-page-title"
      >
        {pageTitle}
      </Typography>
      <Styled.OverviewSection>
        <Box>
          <Styled.CandidateOrCompanyName
            variant="EC_TYPE_2XL"
            data-testid="timesheet-candidate-or-company-name"
          >
            {externship.company.name}
          </Styled.CandidateOrCompanyName>
          <Typography variant="EC_TYPE_BASE">
            {getOneLineAddress(externship?.company.locations[0])}
          </Typography>
        </Box>
        <Styled.ExternshipProgressWrapper
          displayProgressBar={!featureFlags.MULTIPLE_EXTERNSHIPS_PER_COURSE}
        >
          {!featureFlags.MULTIPLE_EXTERNSHIPS_PER_COURSE ? (
            <>
              <EcProgressBar
                percentComplete={percentComplete}
                pendingProgress={pendingProgress}
                subtext={`Approved ${approvedTime} / Submitted ${submittedTime} / of ${externship.course.ClockHours} hours`}
              />
              <GoldenInfoBox title="Approved Hours" text={`${approvedTime}`} />
            </>
          ) : (
            <GoldenInfoBox
              title="Submitted Time"
              text={getApprovedAndSubmittedTime()}
            />
          )}
        </Styled.ExternshipProgressWrapper>
      </Styled.OverviewSection>

      <Styled.ExternshipDetailsWrapper>
        {externshipInfo.map((info, idx) => (
          <Styled.ExternshipDetail key={info.label + idx}>
            <Typography variant="EC_TYPE_XL">{info.label}</Typography>
            <Typography variant="EC_TYPE_BASE">{info.value}</Typography>
          </Styled.ExternshipDetail>
        ))}
        <Box ml="auto">
          <PdfLink pdfPath={pdf} buttonText="View Externship Agreement" />
        </Box>
      </Styled.ExternshipDetailsWrapper>
      <Styled.Divider />
      <Box mb={5}>
        <TimesheetAccetStatement />
      </Box>
      <Box>
        {!timesheets.length && (
          <NoDataMessage title="No Timesheets Available" />
        )}
        {timesheets?.map((t) => (
          <TimesheetAccordion
            key={t.id}
            timesheet={t}
            updateTimesheets={updateTimesheets}
            isApproverViewing={isApproverViewing}
            isCandidateViewing={isCandidateViewing}
            externshipComplete={externshipComplete}
          />
        ))}
      </Box>
    </RootPageLayout>
  );
};

export default TimesheetPage;
