import * as React from 'react';
import { Button } from '@mui/material';
import GlrcTermsOfUseContent from './GlrcTermsOfUseContent';
import LegalPageWrapper from '@components/LegalPageWrapper';
import { scrollToId } from '@common/helpers/scrollToId';
import TermsAndConditionsContent from './TermsAndConditionsContent';
import theme from '@themes/ui/escoffier';

const TermsOfUsePage = (): React.ReactElement => {
  const handleScrollToId = (): void => {
    scrollToId('glrc-terms-of-use');
  };

  return (
    <LegalPageWrapper title={'Terms of Use'}>
      <Button
        onClick={handleScrollToId}
        variant="text"
        sx={{
          typography: 'EC_TYPE_SM',
          color: theme.palette.primary.main,
          padding: 0
        }}
      >
        Skip to ESource
      </Button>
      <TermsAndConditionsContent />
      <GlrcTermsOfUseContent />
    </LegalPageWrapper>
  );
};

export default TermsOfUsePage;
