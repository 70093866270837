import * as React from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { AppRoles } from '@api/models/userApi.models';
import { CompanySettingsContextProvider } from '@common/context/companySettingsContext';
import { CompanySettingsRedesignProps } from './CompanySettingsRedesign';
import ESourceTabContent from './ESourceTabContent';
import InvitesTabContent from './InvitesTabContent';
import SeatApprovalsTabContent from './SeatApprovalsTabContent';
import SeatManagementTabContent from './SeatManagementTabContent';
import StepSection from '@components/StepSection';
import { styled } from '@mui/system';
import { Tab } from '@mui/material';

const Styled = {
  TabList: styled(TabList)({
    '&.MuiTabs-indicator': {
      backgroundColor: 'red'
    }
  }),
  Tab: styled(Tab)(({ theme }) => ({
    '&.MuiTab-root': {
      ...(theme.typography as any).EC_TYPE_2XL,
      marginBottom: '8px',
      color: theme.palette.GRAY_3.main
    }
  })),
  TabPanel: styled(TabPanel)({
    padding: 0
  })
};
interface Props extends CompanySettingsRedesignProps {
  openToast: (msg: string) => void;
}

const SeatManagement: React.FC<Props> = ({ openToast, ...props }) => {
  const {
    fetchSentInvites,
    companySettings,
    setCompanySettings,
    joinRequests = [],
    sentInvites = [],
    glrcSeats = 0,
    glrcPermissions,
    handleSeatRequestUpdate,
    companyId,
    esourceGroups,
    setESourceGroups,
    tabValue,
    setTabValue
  } = props;

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: string
  ): void => {
    setTabValue(newValue);
  };

  const glrcSeatsRemaining = (): number => {
    const { seats } = companySettings;

    const activeGlrcUsers = seats.filter(
      (s) => s.role?.filter((r) => r.name === AppRoles.ESOURCE_USER).length || 0
    );

    const invitedGlrcUsers = sentInvites.filter(
      (i) => i.roles === AppRoles.ESOURCE_USER && !i.expired
    );

    const totalGlrcUsers = invitedGlrcUsers.length + activeGlrcUsers.length;

    return glrcSeats - totalGlrcUsers;
  };

  return (
    <>
      <StepSection>
        <TabContext value={tabValue}>
          <Styled.TabList onChange={handleTabChange}>
            <Styled.Tab label="Seats" value="1" />
            <Styled.Tab label="ESource" value="2" />
            <Styled.Tab
              label={`Approvals (${joinRequests.length})`}
              value="3"
            />
            <Styled.Tab label={`Invites (${sentInvites.length})`} value="4" />
          </Styled.TabList>
          <CompanySettingsContextProvider
            value={{
              companyId,
              glrcSeats,
              glrcPermissions,
              companySettings,
              sentInvites,
              joinRequests,
              setCompanySettings,
              fetchSentInvites,
              handleSeatRequestUpdate,
              esourceGroups,
              setESourceGroups,
              openToast,
              glrcSeatsRemaining
            }}
          >
            <Styled.TabPanel value="1">
              <SeatManagementTabContent />
            </Styled.TabPanel>
            <Styled.TabPanel value="2">
              <ESourceTabContent />
            </Styled.TabPanel>
            <Styled.TabPanel value="3">
              <SeatApprovalsTabContent />
            </Styled.TabPanel>
            <Styled.TabPanel value="4">
              <InvitesTabContent />
            </Styled.TabPanel>
          </CompanySettingsContextProvider>
        </TabContext>
      </StepSection>
    </>
  );
};

export default SeatManagement;
