import {
  Box,
  Button,
  Pagination,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import GlrcPageLayout from '../GlrcPageLayout';
import React from 'react';
import SearchBar from '@components/SearchBar';
import SearchResultItem from './SearchResultItem';
import SimpleSelect from '@components/Forms/SimpleSelect';
import { styled } from '@mui/system';
import { useFetchEsourceSearch } from '@common/fetches/useFetchEsourceSearch';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';

const Styled = {
  SearchSection: styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '24px',
    ['@media (max-width: 599px)']: {
      flexDirection: 'column'
    }
  }),
  SearchButton: styled(Button, {
    shouldForwardProp: (prop) => prop !== 'isSmall'
  })<{ isSmall: boolean }>(({ isSmall, theme }) => ({
    typography: 'EC_TYPE_BASE',
    color: isSmall ? theme.palette.WHITE.main : 'unset',
    '&.MuiButton-text': {
      padding: '12px 20px'
    },
    '&.Mui-focusVisible': {
      borderRadius: '4px'
    }
  })),
  FilterContainer: styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',
    paddingBottom: '16px',
    ['@media (max-width: 600px)']: {
      flexDirection: 'column-reverse'
    }
  })
};

const EsourceSearchPage: React.FC = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const { companyId } = useParamsOrStoreCompanyId();
  const [page, setPage] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState('');
  const [selectedTypes, setSelectedTypes] = React.useState([
    'QuickSkills',
    'QuickCourses',
    'Courses'
  ]);
  const { searchContent, fetch } = useFetchEsourceSearch(
    searchValue,
    page,
    selectedTypes,
    companyId
  );

  const filterOptions = ['QuickSkills', 'QuickCourses', 'Courses'];

  const onChange = (_e, value): any => {
    setPage(value);
  };

  const clearSearch = (): void => console.log('object');

  const handleChange = (event: SelectChangeEvent<any>): void => {
    setSelectedTypes(event.target.value);
  };

  const handleSearchSubmit = (): void => {
    setPage(1);
    fetch();
  };

  React.useEffect(() => {
    fetch();
  }, [page, selectedTypes]);

  return (
    <GlrcPageLayout backBtn={false} heroTitle={'Search'} contentLoaded={true}>
      <Box>
        <Styled.SearchSection>
          <SearchBar
            onChange={(e): void => setSearchValue(e.target.value)}
            onSubmit={handleSearchSubmit}
            clearSearch={clearSearch}
            showIcon={false}
            value={searchValue}
          />
          <Styled.SearchButton
            data-testid="ec-search-bar-search-btn"
            aria-label="search"
            variant={isSmall ? 'contained' : 'text'}
            fullWidth={isSmall}
            isSmall={isSmall}
            onClick={handleSearchSubmit}
            startIcon={<i className="ri-search-line"></i>}
          >
            Search
          </Styled.SearchButton>
        </Styled.SearchSection>
        <Typography variant="EC_TYPE_LG" py={5}>
          Search Results
        </Typography>
        <Styled.FilterContainer>
          <Typography
            variant="EC_TYPE_2XS"
            justifySelf={'flex-end'}
            alignSelf={'end'}
          >
            {searchContent?.total} Results
          </Typography>
          <SimpleSelect
            handleChange={handleChange}
            fullWidth
            value={selectedTypes}
            labelTypography="EC_TYPE_BASE"
            options={filterOptions.map((opt) => ({
              label: opt,
              value: opt
            }))}
            multiple
            label="Show:"
          />
        </Styled.FilterContainer>

        {searchContent?.result?.map((content) => {
          return <SearchResultItem esourceContent={content} key={content.id} />;
        })}

        <Box display="flex" justifyContent={'center'} alignSelf={'center'}>
          <Pagination
            count={Math.ceil((searchContent?.total ?? 0) / 10)}
            page={page}
            onChange={onChange}
            defaultPage={1}
          />
        </Box>
      </Box>
    </GlrcPageLayout>
  );
};

export default EsourceSearchPage;
