import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AdminContentManagementApi } from '@api/AdminContentManagement.api ';
import { AutoCompleteOption } from '@components/Forms/ReactHookFormsAutoComplete';
import BasicModal from '@components/BasicModal';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { CompanyStatus } from '@api/models/adminDashboardApi.models';
import { DirectConnectPost } from '@api/models/adminContentManagementApi.models';
import { getSavedRTEContent } from '@components/Forms/getSavedRTEContent';
import { Location } from '@interfaces/location.interfaces';
import ReactHookFormsAutoCompleteSingle from '@components/Forms/ReactHookFormsAutoCompleteSingle';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import ReactHookFormsRichText from '@components/Forms/ReactHookFormsRichText';
import ReactHookFormsTextarea from '@components/Forms/ReactHookFormsTextarea';
import RhfCheckbox from '@components/Forms/RhfCheckbox';
import RhfLocationSearch from '@components/Forms/RhfLocationSearch';
import { strippedText } from '@common/helpers/strippedText';
import { urlPattern } from '@common/regex/url';

interface FormValues {
  id: number;
  company: number;
  cardDescription: string;
  description: string;
  locations: Location[];
  externalLink?: string;
  visibleOnMarketplace: boolean;
}

interface Props {
  open: boolean;
  handleClose: (directConnectSaved: boolean) => void;
  directConnectPosts: DirectConnectPost[];
  currDirectConnectPost?: DirectConnectPost;
  allCompanies?: CompanyStatus[];
}

const DirectConnectModal: React.FC<Props> = ({
  open,
  handleClose,
  directConnectPosts,
  currDirectConnectPost,
  allCompanies
}) => {
  const methods = useForm<FormValues>({
    defaultValues: {
      id: undefined,
      company: undefined,
      cardDescription: '',
      description: '',
      locations: [],
      externalLink: '',
      visibleOnMarketplace: false
    }
  });

  const { handleSubmit, getValues, reset, setError } = methods;

  React.useEffect(() => {
    reset({
      id: currDirectConnectPost?.id || undefined,
      company: currDirectConnectPost?.companyId || undefined,
      cardDescription: currDirectConnectPost?.jobCardDescription || '',
      description: currDirectConnectPost?.jobDescription || '',
      externalLink: currDirectConnectPost?.externalJobPost || '',
      visibleOnMarketplace:
        currDirectConnectPost?.visibleOnMarketplace || false,
      locations: currDirectConnectPost?.locations || []
    });
  }, [currDirectConnectPost]);

  const options = React.useMemo((): AutoCompleteOption[] => {
    const autocompleteOptions =
      allCompanies
        ?.sort((a, b) => a.companyName.localeCompare(b.companyName))
        .map((company) => ({
          label: `${company.id} - ${company.companyName}`,
          value: company.id
        })) || [];

    return autocompleteOptions;
  }, [directConnectPosts, allCompanies, getValues().company]);

  const defaultDesc = React.useMemo(() => {
    if (!currDirectConnectPost) return '';

    return getSavedRTEContent(currDirectConnectPost.jobDescription);
  }, [currDirectConnectPost]);

  const descriptionError = (desc: string): boolean => {
    if (strippedText(desc) === '') {
      setError('description', {
        type: 'manual',
        message: 'Description is required'
      });
      return true;
    }
    return false;
  };

  const handleInvalidForm = (): void => {
    descriptionError(getValues().description);
  };

  const handleValidForm = async (data: FormValues): Promise<void> => {
    const descError = descriptionError(data.description);

    if (descError) {
      return;
    }

    const postBody = {
      companyId: data.company,
      jobCardDescription: data.cardDescription,
      jobDescription: data.description,
      externalJobPost: data.externalLink,
      visibleOnMarketplace: data.visibleOnMarketplace,
      locations: data.locations
    };

    if (currDirectConnectPost) {
      await AdminContentManagementApi.updateDirectConnectPost(
        data.id,
        postBody
      );
    } else {
      await AdminContentManagementApi.createDirectConnectPost(postBody);
    }

    handleClose(true);
  };

  const handleSave = async (): Promise<void> => {
    await handleSubmit(handleValidForm, handleInvalidForm)();
  };

  return (
    <BasicModal
      title={`${currDirectConnectPost ? 'Edit' : 'Add'} Direct Connect Post`}
      open={open}
      handleClose={(): void => handleClose(false)}
      maxWidth="sm"
      primaryAction={{ label: 'Save', action: handleSave }}
      secondaryAction={{
        label: 'Cancel',
        action: (): void => handleClose(false)
      }}
    >
      <BottomMarginContentWrapper bottomMargin="20px">
        <FormProvider {...methods}>
          <ReactHookFormsAutoCompleteSingle
            label="Select company"
            labelVariant="EC_TYPE_SM"
            subTitle="Type in the name of the company you want to make a post"
            name="company"
            options={options}
            rules={{ required: 'Company selection is required' }}
          />
          <ReactHookFormsTextarea
            labelVariant="EC_TYPE_SM"
            subTitle="This brief description appears on EConnect to entice candidates to read the full job post"
            name="cardDescription"
            label="Job Card Description"
            rules={{ required: 'Job card description is required' }}
          />
          <ReactHookFormsRichText
            defaultValue={defaultDesc}
            labelVariant="EC_TYPE_SM"
            name="description"
            label="Direct Connect Post Description"
            rules={{ required: 'Description is required' }}
          />
          <RhfLocationSearch name="locations" label="Select location" />
          <ReactHookFormsInput
            labelVariant="EC_TYPE_SM"
            name="externalLink"
            label="External link (optional)"
            rules={{
              pattern: {
                value: urlPattern,
                message: 'Please enter a valid URL'
              }
            }}
          />
          <RhfCheckbox
            name="visibleOnMarketplace"
            label="Visible on Marketplace"
            // Allows user to check the box when adding a post
            disabled={
              currDirectConnectPost && !currDirectConnectPost?.companyEnabled
            }
          />
        </FormProvider>
      </BottomMarginContentWrapper>
    </BasicModal>
  );
};

export default DirectConnectModal;
