/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { CandidateProfile } from '@api/models/candidateApi.models';
import ResumePreview from './ResumePreview/ResumePreview';
import ResumePreviewActionBar from './ResumePreviewActionBar';
import { styled } from '@mui/styles';

const Styled = {
  Dialog: styled(Dialog)({
    '& .MuiDialog-paper': {
      padding: '0px',
      borderRadius: '0px',
      ['@media (max-width:567px)']: {
        padding: '0px',
        margin: '0px'
      },
      ['@media (max-width:320px)']: {
        padding: '0px'
      }
    }
  }),
  DialogContent: styled(DialogContent)({
    overflow: 'auto',
    padding: '0px',
    ['@media (max-width:599px)']: {
      padding: '0px'
    }
  })
};

type AnchorType = {
  [x: string]: HTMLElement | null;
};

type OpenType = {
  [x: string]: boolean;
};

export type ResumePopperState = {
  anchorEl: AnchorType | [];
  open: OpenType | [];
};

interface Props {
  open: boolean;
  candidateProfile: CandidateProfile;
  onClose: () => any;
}

const ResumePreviewModal: React.FC<Props> = ({
  open,
  candidateProfile,
  onClose
}) => {
  const [loading, setLoading] = React.useState(false);
  const [disableActionBar, setDisableActionBar] = React.useState(false);
  const [displayEditButtons, setDisplayEditButtons] = React.useState(true);
  const [popperState, setPopperState] = React.useState<ResumePopperState>({
    anchorEl: [],
    open: []
  });

  const handleOptionClick =
    (id: string) =>
    (event: React.MouseEvent<HTMLElement>): void => {
      const { currentTarget } = event;

      setPopperState((popperState) => ({
        anchorEl: {
          [id]: currentTarget
        },
        open: {
          [id]: !popperState.open[id]
        }
      }));
    };

  const handleClosePopper = (): void => {
    setPopperState({
      anchorEl: [],
      open: []
    });
  };

  return (
    <Styled.Dialog
      data-testid="resume-preview-modal"
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <Styled.DialogContent>
        <ResumePreviewActionBar
          onClose={onClose}
          setDisplayEditButtons={setDisplayEditButtons}
          loading={loading}
          setLoading={setLoading}
          disableActionBar={disableActionBar}
        />
        <ResumePreview
          candidateProfile={candidateProfile}
          displayEditButtons={displayEditButtons}
          popperState={popperState}
          loading={loading}
          handleOptionClick={handleOptionClick}
          handleClosePopper={handleClosePopper}
          setDisableActionBar={setDisableActionBar}
        />
      </Styled.DialogContent>
    </Styled.Dialog>
  );
};

export default ResumePreviewModal;
