import * as React from 'react';
import { Box, styled } from '@mui/system';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import SelectableTag from '@components/SelectableTag';
import { Typography } from '@mui/material';

const Styled = {
  Title: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'required'
  })<{ required: boolean }>(({ required, theme }) => ({
    marginBottom: '14px',

    '&:after': {
      content: required ? '"*"' : '""',
      color: theme.palette.error.main,
      marginLeft: '4px'
    }
  })),
  TagSectionSubtitle: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'error'
  })<{ error: boolean }>(({ error, theme }) => ({
    fontSize: '15px',
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '19px',
    color: error ? theme.palette.error.main : theme.palette.GRAY_3.main,
    marginBottom: '22px'
  })),
  TagContainer: styled(Box)({
    margin: '-8px'
  }),
  Tag: styled(Box)({
    margin: '8px',
    display: 'inline-block'
  })
};

interface TagCodebook {
  label: string;
  value: any;
  [key: string]: any;
}

interface Props {
  tags: string[] | TagCodebook[];
  name: string;
  title?: string;
  subtitle?: string;
  rules?: RegisterOptions;
}
const ReactHookFormsTagSelection: React.FC<Props> = ({
  tags,
  name,
  title,
  subtitle,
  rules
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const requiredField = !!rules?.required;

  const getLabel = (tag: string | TagCodebook): string =>
    typeof tag === 'string' ? tag : tag.label;

  const isSelected = (value, tag): boolean => {
    return typeof tag === 'string'
      ? value?.includes(tag)
      : value?.some((t: TagCodebook) => t.value === tag.value);
  };

  const handleSelection = (
    tag: string | TagCodebook,
    value,
    onChange: (...event: any[]) => void
  ): void => {
    const isString = typeof tag === 'string';

    let newTags: string[] | TagCodebook[];

    const tagIsPresent = value.some((t) =>
      isString ? t === tag : t.value === tag.value
    );

    if (tagIsPresent) {
      // Remove tag from value
      newTags = value.filter((t) =>
        isString ? t !== tag : t.value !== tag.value
      );
    } else {
      // Add tag to value
      newTags = [...value, tag];
    }

    onChange(newTags);
  };

  return (
    <Box id={name}>
      {title && (
        <Styled.Title
          data-testid={`rhf--title-${name}`}
          variant="EC_TYPE_BASE"
          required={requiredField}
        >
          {title}
        </Styled.Title>
      )}
      {subtitle && (
        <Styled.TagSectionSubtitle
          data-testid={
            errors[name] ? `rhf-subtitle-${name}-error` : `rhf-subtitle-${name}`
          }
          error={!!errors[name]}
        >
          {subtitle}
        </Styled.TagSectionSubtitle>
      )}
      <Controller
        control={control}
        name={name}
        rules={{ ...rules }}
        render={({ field: { value, onChange } }): JSX.Element => {
          return (
            <Styled.TagContainer data-testid={`rhf-tag-container-${name}`}>
              {tags.sort().map((tag) => (
                <Styled.Tag key={getLabel(tag)}>
                  <SelectableTag
                    label={getLabel(tag)}
                    selected={isSelected(value, tag)}
                    handleSelection={(): void =>
                      handleSelection(tag, value, onChange)
                    }
                  />
                </Styled.Tag>
              ))}
            </Styled.TagContainer>
          );
        }}
      />
    </Box>
  );
};

export default ReactHookFormsTagSelection;
