import * as React from 'react';
import { Box, Link, Typography } from '@mui/material';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import Hero from '@components/Hero';
import HeroImage from '@assets/LegalPage/hero_image.png';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const Styled = {
  Title: styled(Typography)({
    color: theme.palette.GRAY_4.main
  })
};

const LegalPage: React.FC = () => {
  const legalItems = [
    {
      title: 'Privacy Policy',
      linkText: 'View policy',
      link: '/privacy-policy'
    },
    {
      title: 'Terms of Use',
      linkText: 'View terms',
      link: '/terms-of-use'
    },
    {
      title: 'ESource',
      linkText: 'View terms and conditions',
      link: '/glrc-terms-and-conditions'
    },
    {
      title: 'Gold Crown Partnership',
      linkText: 'View terms and conditions',
      link: '/gold-crown-terms-and-conditions'
    }
  ];

  return (
    <RootPageLayout muiMaxWidth={false} padding="0px">
      <Hero imgUrl={HeroImage} title="Legal"></Hero>
      <RootPageLayout muiMaxWidth={false}>
        <BottomMarginContentWrapper bottomMargin="48px">
          {legalItems.map((i) => (
            <Box key={i.title}>
              <Styled.Title variant="EC_TYPE_5XL">{i.title}</Styled.Title>
              <Link
                component={RouterLink}
                to={i.link}
                underline="none"
                sx={{
                  typography: 'EC_TYPE_SM',
                  color: theme.palette.primary.main
                }}
              >
                {i.linkText}
              </Link>
            </Box>
          ))}
        </BottomMarginContentWrapper>
      </RootPageLayout>
    </RootPageLayout>
  );
};

export default LegalPage;
