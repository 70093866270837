import * as React from 'react';
import { SxProps, Theme, Typography, TypographyProps } from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/system';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const Styled = {
  Title: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'redTitle'
  })<{ redTitle?: boolean }>(({ theme, redTitle }) => ({
    color: redTitle ? theme.palette.red2.main : theme.palette.BLACK.main,
    fontSize: (theme as Theme).typography.EC_TYPE_4XL.fontSize,
    lineHeight: (theme as Theme).typography.EC_TYPE_4XL.lineHeight
  }))
};

interface Props {
  title?: string;
  redTitle?: boolean;
  textVariant?: TypographyProps['variant'];
  textSx?: SxProps<Theme>;
}

const PageDescriptionComponent: React.FC<Props> = ({
  title = '',
  redTitle,
  textVariant = 'EC_TYPE_BASE',
  textSx,
  children
}) => {
  const { width } = useWindowDimensions();
  const margin = width < 600 ? '0px 16px' : '0px';

  return (
    <Box sx={{ margin: margin }}>
      <Styled.Title redTitle={!!redTitle} variant="EC_TYPE_3XL">
        {title}
      </Styled.Title>

      <Typography variant={textVariant} sx={{ ...textSx }}>
        {children}
      </Typography>
    </Box>
  );
};

export default PageDescriptionComponent;
