import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { IExpandedFilters } from '@common/fetches/useFetchMarketplaceSearchResults';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import Typography from '@mui/material/Typography';
import WorkLearnIcon from '@components/WorkLearnIcon';

const Styled = {
  Arrow: styled('i')({
    color: theme.palette.grey4.main
  }),
  Text: styled(Typography)({
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center'
  })
};

interface IndividualFilterProps {
  filterTitle: string;
  filterContent: React.ReactNode;
  expandedFilters: IExpandedFilters;
  setExpandedFilters: React.Dispatch<React.SetStateAction<IExpandedFilters>>;
}

const IndividualFilter: React.FC<IndividualFilterProps> = (props) => {
  const { filterTitle, filterContent, expandedFilters, setExpandedFilters } =
    props;
  const expanded = !!expandedFilters[filterTitle];

  const handleClick = (): void => {
    setExpandedFilters({
      ...expandedFilters,
      [filterTitle]: !expandedFilters[filterTitle]
    });
  };

  return (
    <Accordion
      sx={{ marginBottom: '15px' }}
      expanded={expanded}
      onChange={handleClick}
    >
      <AccordionSummary
        expandIcon={<Styled.Arrow className="ri-arrow-down-s-line" />}
        aria-controls="filter-control"
        id="filter-control-header"
        sx={{ alignItems: 'center' }}
      >
        <Styled.Text data-testid="individualFilterText" variant="EC_TYPE_2XS">
          {filterTitle}
          {filterTitle === 'Work & Learn' && (
            <WorkLearnIcon
              display="Yes"
              showTooltip={false}
              sx={{ marginLeft: '10px' }}
            />
          )}
        </Styled.Text>
      </AccordionSummary>
      <AccordionDetails>{filterContent}</AccordionDetails>
    </Accordion>
  );
};

export default IndividualFilter;
