import * as React from 'react';

export const displayTextWithSelectLettersLarger = (
  text: string,
  fontSize: number
): JSX.Element => {
  const pattern = /\[(.*?)\]/g;
  const replacement = `<span style="font-size: ${fontSize}px;">$1</span>`;
  const result = text.replace(pattern, replacement);
  return <div dangerouslySetInnerHTML={{ __html: result }}></div>;
};
