import * as React from 'react';
import { AppRoles, roles } from '@api/models/userApi.models';
import {
  Box,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import {
  ICompanySettings,
  Seat,
  SettingsPutReq
} from '@api/models/settingsApi.models';
import EcDialog from '@components/EcDialog';
import ManageSeatsTableRow from '@pages/Shared/SettingsPage/CompanySettings/ManageSeatsTableRow';
import { SettingsApi } from '@api/Settings.api';
import { styled } from '@mui/system';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useHistory } from 'react-router-dom';

const Styled = {
  GlrcSeatInfoWrapper: styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    columnGap: '16px'
  })
};

interface Props {
  companySettings: ICompanySettings;
  setCompanySettings: (newSettings: ICompanySettings) => void;
  openToast: (msg: string) => void;
  glrcSeats?: number;
  glrcSeatsRemaining: () => number;
}

const ManageSeatsTable: React.FC<Props> = ({
  companySettings,
  setCompanySettings,
  openToast,
  glrcSeats = 0,
  glrcSeatsRemaining
}) => {
  const featureFlags = useFeatureFlags();
  const { seats } = companySettings;

  const [openDialog, setOpenDialog] = React.useState(false);
  const [idForDelete, setIdForDelete] = React.useState<number>();
  const [idxForDelete, setIdxForDelete] = React.useState<number>();
  const [executeDelete, setExecuteDelete] = React.useState(false);
  const [dialogContent, setDialogContent] = React.useState({});

  const history = useHistory();

  const handleConfirm = (): void => {
    setOpenDialog(false);
    setExecuteDelete(true);
  };

  const handleClose = (): void => {
    setOpenDialog(false);
  };

  React.useEffect(() => {
    if (executeDelete) {
      const postBody: SettingsPutReq = {
        employerId: idForDelete ?? 0
      };
      try {
        SettingsApi.deleteUser(postBody);

        // upon successful delete update companySettings state
        const updatedSeats = [...seats];
        updatedSeats.splice(idxForDelete ?? 0, 1);

        setCompanySettings({ ...companySettings, seats: updatedSeats });
        openToast('Successfully Deleted Seat');
        setExecuteDelete(false);
      } catch (error: any) {
        console.error('Error for ManageSeatsTable.deleteUser()', error);
      }
    }
  }, [executeDelete]);

  const confirmDeleteDialog = {
    content:
      'In order to remove this user from the account, you must first confirm this dialogue.',
    confirmActionText: 'Delete user',
    handleConfirm
  };

  const lastAdminDeleteDialog = {
    content:
      'In order to remove this admin from the account, you must first assign admin permissions to another user.',
    confirmActionText: 'Manage Seats',
    handleConfirm: handleClose
  };

  const lastAdminRoleDialog = {
    content:
      'In order to update this admin to a recruiter, you must first assign admin permissions to another user.',
    confirmActionText: 'Manage Seats',
    handleConfirm: handleClose
  };

  const glrcSeatsUnavailableDialog = {
    content:
      'In order to update this user to a glrc-user, you must remove access from another user or request more seats as there are zero glrc seats remaining at this time.',
    confirmActionText: 'Manage Seats',
    handleConfirm: handleClose
  };

  const handleChange = async (
    e: SelectChangeEvent<any>,
    employerId: number,
    idx: number,
    isLastAdmin: boolean
  ): Promise<void> => {
    if (isLastAdmin) {
      setDialogContent(lastAdminRoleDialog);
      setOpenDialog(true);
      return;
    }

    if (e.target.value === roles.GLRC_USER && glrcSeatsRemaining() <= 0) {
      setDialogContent(glrcSeatsUnavailableDialog);
      setOpenDialog(true);
      return;
    }

    let roleName: string = e.target.value;

    if (roleName === 'admin') {
      roleName = 'employer';
    }

    const postBody = {
      employerId: employerId,
      name: roleName
    };

    try {
      await SettingsApi.updateUserRole(postBody);
      openToast('Successfully Updated Permissions');
    } catch (error: any) {
      console.error('Error for SettingsApi.updateUserRole()', error);
    }

    // get updated values for user
    // TODO get better component update flow
    try {
      const response = await SettingsApi.getUser(employerId);
      const seatRole = response.data.roles;
      const updatedSeats = [...seats];
      updatedSeats[idx].role = seatRole!;

      setCompanySettings({ ...companySettings, seats: updatedSeats });

      history.push(history.location.pathname);
    } catch (error: any) {
      console.error('Error for SettingsApi.getUser()', error);
    }
  };

  const isLastAdmin = (seat: Seat): boolean => {
    const adminSeats = seats.filter((s) =>
      s.role.some((s) => s.name === AppRoles.EMPLOYER)
    );

    if (
      adminSeats.length === 1 &&
      seat.email.toLowerCase() === adminSeats[0].email.toLowerCase()
    ) {
      return true;
    }

    return false;
  };

  const handleDelete = async (
    employerId: number,
    idx: number
  ): Promise<void> => {
    if (isLastAdmin(seats[idx])) {
      setDialogContent(lastAdminDeleteDialog);
    } else {
      setDialogContent(confirmDeleteDialog);
    }

    setOpenDialog(true);
    setIdForDelete(employerId);
    setIdxForDelete(idx);
  };

  return (
    <>
      {featureFlags.PARTNER_EXPERIENCE && (
        <Styled.GlrcSeatInfoWrapper>
          <Typography component={'p'} variant="EC_TYPE_XS" mb={2}>
            Total ESource Seats: {glrcSeats}
          </Typography>
          <Typography component={'p'} variant="EC_TYPE_XS" mb={2}>
            Available ESource Seats: {glrcSeatsRemaining()}
          </Typography>
        </Styled.GlrcSeatInfoWrapper>
      )}
      <TableContainer data-testid="manage-seats-table">
        <Table aria-label="manage-seats-table">
          <TableHead>
            <TableRow>
              <TableCell>NAME</TableCell>
              <TableCell>EMAIL</TableCell>
              <TableCell sx={{ width: '100px' }}>PERMISSIONS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="manage-seats-table-body">
            {seats.map((seat, idx) => (
              <ManageSeatsTableRow
                key={seat.employerId}
                isLastAdmin={isLastAdmin(seat)}
                seat={seat}
                idx={idx}
                handleChange={handleChange}
                handleDelete={handleDelete}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EcDialog
        dataTestId="manage-seats-dialog"
        title="Seat Management"
        open={openDialog}
        handleClose={handleClose}
        {...dialogContent}
      />
    </>
  );
};

export default ManageSeatsTable;
