import * as React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import PreviewPageInfo, {
  InfoItemType
} from '@components/PreviewPageComponents/PreviewPageInfo';
import { BadgeCodebook } from '@api/models/codebook.models';
import { BadgeMapping } from '@common/mappings/BadgeMappings';
import { CandidatePersonalInfoProps } from '@pages/Candidate/candidatePreviewPageComponentProps';
import defaultCandidateImage from '@assets/svg/candidate-def.svg';
import EmployerInviteResumeButtons from './EmployerInviteResumeButtons';
import ImageWithBadge from '@components/ImageWithBadge';
import { styled } from '@mui/system';
import { useToast } from '@components/Toast';

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    marginBottom: '30px',
    borderBottom: `1px solid ${theme.palette.GRAY_2.main}`,
    [theme.breakpoints.up('sm')]: {
      borderRadius: '4px',
      border: `1px solid ${theme.palette.GOLD_1.main}`
    }
  })),
  UpperBox: styled(Box)(({ theme }) => ({
    display: 'flex',
    paddingRight: '20px',
    flexWrap: 'wrap',
    '& .candidate-contact-info': {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '10px',
      alignItems: 'end',
      alignSelf: 'end',
      marginLeft: 'auto'
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: '20px',
      '& .candidate-contact-info': {
        alignSelf: 'start'
      }
    }
  })),
  Name: styled(Typography)(({ theme }) => ({
    flexGrow: 1,
    alignSelf: 'start',
    color: theme.palette.GRAY_4.main,
    order: -1,
    flex: '0 0 100%',
    ['@media (min-width:456px)']: {
      order: 'unset',
      marginLeft: '20px',
      flex: 'unset',
      marginTop: '8px'
    }
  })),
  LowerBox: styled(Box)(({ theme }) => ({
    paddingBottom: '10px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '40px'
    }
  })),
  PhotoWrapper: styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
      transform: 'translate(-9px, -29px)'
    }
  })),
  ProfileImage: styled('img')(({ theme }) => ({
    width: '110px',
    height: '110px',
    objectFit: 'cover',
    objectPosition: 'center',
    marginRight: '36px',
    zIndex: 10,
    [theme.breakpoints.up('sm')]: {
      transform: 'translate(-9px, -29px)'
    }
  }))
};

interface Props extends CandidatePersonalInfoProps {
  candidateDetails: InfoItemType[];
  candidateId: number;
  badge: BadgeCodebook;
  isEmployerViewing?: boolean;
  currentPosition?: string;
}

// TODO: View Resume and Invite to Apply will be moved in the future
// when an employer is viewing a candidate's profile from the Candidate Marketplace
const CandidateProfilePersonalInfo = (props: Props): React.ReactElement => {
  const {
    firstName,
    lastName,
    candidateId,
    photo,
    badge,
    isEmployerViewing,
    candidateDetails
  } = props;
  const [openInviteModal, setOpenInviteModal] = React.useState(false);
  const { openToast } = useToast();
  const theme = useTheme();
  const badgeMap = BadgeMapping(badge);

  const nameVariant = 'EC_TYPE_3XL'; // 4XL for smaller

  const onSuccessfulInvite = React.useCallback(() => {
    setOpenInviteModal(false);
    openToast('Your invite to apply has been sent successfully!');
  }, [setOpenInviteModal, openToast]);

  const handleOpenInviteModal = React.useCallback(() => {
    setOpenInviteModal(true);
  }, [setOpenInviteModal]);

  const handleCloseInviteModal = React.useCallback(() => {
    setOpenInviteModal(false);
  }, [setOpenInviteModal]);

  return (
    <Styled.Root data-testid="candidate-personal-info-root">
      <Styled.UpperBox>
        <Styled.PhotoWrapper>
          <ImageWithBadge
            dataTestId="candidate-personal-info-candidateProfileImage"
            src={photo ? photo.fullPath : defaultCandidateImage}
            height="110px"
            width="110px"
            badge={badgeMap?.[badge?.value]}
            backgroundColor={theme.palette.WHITE.main}
          />
        </Styled.PhotoWrapper>
        <Styled.Name
          variant={nameVariant}
          data-testid="candidate-personal-info-name"
        >
          {firstName} {lastName}
        </Styled.Name>
        <Box className="candidate-contact-info">
          {isEmployerViewing && (
            <EmployerInviteResumeButtons
              modalOpen={openInviteModal}
              candidateId={candidateId}
              handleClose={handleCloseInviteModal}
              handleOpen={handleOpenInviteModal}
              onSuccess={onSuccessfulInvite}
            />
          )}
        </Box>
      </Styled.UpperBox>
      <Styled.LowerBox data-testid="candidate-personal-info-lower-box">
        <PreviewPageInfo itemList={candidateDetails} candidateProfile />
      </Styled.LowerBox>
    </Styled.Root>
  );
};

export default CandidateProfilePersonalInfo;
