import * as React from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Skeleton,
  Theme,
  Typography,
  useTheme
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { GlrcAccesses, GlrcCompanyAccess } from '@api/models/glrcAccess.models';
import { AdminDashboardApi } from '@api/AdminDashboard.api';
import BasicModal from '@components/BasicModal';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { GlrcAccessApi } from '@api/GlrcAccess.api';
import { GlrcAccessData } from '@pages/EcAdmin/ManageCompaniesPage/CompaniesStatusTable';
import { isInteger } from '@common/rhf-validators/isInteger.validator';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import { styled } from '@mui/system';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchGlrcContentTopicsCodebook } from '@common/fetches/useFetchGlrcContentTopicsCodebook';
import { useFetchGlrcContentTypesCodebook } from '@common/fetches/useFetchGlrcContentTypesCodebook';

const Styled = {
  FormWrapper: styled(Box)({
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'space-between'
  }),
  FormControlLabel: styled(FormControlLabel)(({ theme }) => ({
    '& .MuiFormControlLabel-label': {
      typography: (theme as Theme).typography.EC_TYPE_BASE
    }
  })),
  Checkbox: styled(Checkbox)(({ theme }) => ({
    '&.Mui-checked': {
      color: theme.palette.primary.main
    }
  }))
};

interface FormValues {
  seats: string;
}

interface Props {
  open: boolean;
  handleClose: (companyId?: number) => void;
  companyId: number;
  glrcAccessData: GlrcAccessData;
  setGlrcAccessData: React.Dispatch<
    React.SetStateAction<GlrcAccessData | undefined> // eslint-disable-line @typescript-eslint/indent
  >;
  glrcSeats: number;
  setGlrcSeats: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const CompanyGlrcAccessModal: React.FC<Props> = ({
  open,
  handleClose,
  companyId,
  glrcAccessData,
  setGlrcAccessData,
  glrcSeats,
  setGlrcSeats
}) => {
  const theme = useTheme();

  const featuresFlags = useFeatureFlags();

  const [disableCheckboxes, setDisableCheckboxes] = React.useState(false);
  const [_customThemeAccess, setCustomThemeAccess] = React.useState(
    glrcAccessData.customThemeAccess
  );
  const [accesses, setAccesses] = React.useState<GlrcAccesses[]>(
    JSON.parse(JSON.stringify(glrcAccessData.glrcAccess))
  );
  const [saved, setSaved] = React.useState(false);

  const { glrcContentTypes, loading: typesLoading } =
    useFetchGlrcContentTypesCodebook();
  const { glrcContentTopics, loading: topicsLoading } =
    useFetchGlrcContentTopicsCodebook();

  const methods = useForm<FormValues>({
    defaultValues: {
      seats: Number(glrcSeats).toString()
    }
  });

  const { handleSubmit, watch } = methods;

  const seats = watch('seats');

  const disableAndRemoveGlrcAccess = (): void => {
    const newAccesses = accesses.map((a) => ({ ...a, hasAccess: false }));
    setAccesses(newAccesses);
    setCustomThemeAccess(false);
    setDisableCheckboxes(true);
  };

  React.useEffect(() => {
    if (parseInt(seats) === 0) {
      disableAndRemoveGlrcAccess();
    } else {
      setDisableCheckboxes(false);
    }
  }, [seats]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    contentTypeId: number,
    contentTypeName: string
  ): void => {
    const contentTopicId = Number(event.target.value);
    const newAccesses = [...accesses];
    const accessToUpdateIdx = newAccesses.findIndex(
      (a) =>
        a.contentTopicId === contentTopicId && a.contentTypeId === contentTypeId
    );

    if (accessToUpdateIdx === -1) {
      newAccesses.push({
        contentTypeName,
        contentTypeId,
        contentTopicName: event.target.name,
        contentTopicId,
        hasAccess: event.target.checked
      });
    } else {
      newAccesses[accessToUpdateIdx].hasAccess = event.target.checked;
    }

    setAccesses(newAccesses);
  };

  const saveGlrcCourseAccess = async (): Promise<void> => {
    try {
      const postBody: any = {
        companyId,
        accesses,
        customThemeAccess: _customThemeAccess
      };

      const glrcCompanyAccess: GlrcCompanyAccess[] =
        await GlrcAccessApi.updateGlrcCompanyAccesses(companyId, postBody);

      // GlrcCompanyAccess to GlrcAccesses
      const updatedGlrcAccess = glrcCompanyAccess.map((a) => ({
        contentTopicId: a.contentTopic.value,
        contentTopicName: a.contentTopic.label,
        contentTypeId: a.contentType.value,
        contentTypeName: a.contentType.label,
        hasAccess: a.hasAccess
      }));

      setGlrcAccessData({
        glrcAccess: updatedGlrcAccess,
        customThemeAccess: _customThemeAccess
      });
    } catch (error) {
      console.error(
        `Error for CompanyGlrcAccessModal.updateGlrcCompanyAccesses(): ${error}`
      );
      throw error;
    }
  };

  const handleValidForm = async (data: FormValues): Promise<void> => {
    const updatedGlrcSeats = await AdminDashboardApi.updateCompanyGlrcSeats(
      companyId,
      Number(data.seats)
    );
    setGlrcSeats(updatedGlrcSeats);

    await saveGlrcCourseAccess();
    setSaved(true);
  };

  const handleSave = async (): Promise<void> => {
    await handleSubmit(handleValidForm)();
  };

  const handleCloseModal = React.useCallback(() => {
    handleClose(companyId);
  }, [handleClose, companyId]);

  React.useEffect(() => {
    if (saved) {
      handleCloseModal();
    }
  }, [saved]);

  const isChecked = (
    contentTypeId: number,
    contentTopicId: number
  ): boolean => {
    const access = accesses.find(
      (a) =>
        a.contentTopicId === contentTopicId && a.contentTypeId === contentTypeId
    );

    if (!access) {
      return false;
    }

    return access.hasAccess;
  };

  return (
    <BasicModal
      open={open}
      handleClose={handleClose}
      title="ESource Access"
      primaryAction={{ label: 'Save', action: handleSave }}
      secondaryAction={{ label: 'Cancel', action: handleClose }}
      maxWidth="xs"
    >
      <BottomMarginContentWrapper bottomMargin="24px">
        <Box>
          <Typography variant="EC_TYPE_BASE" mb={2}>
            Enter the number of seats the company will have:
          </Typography>
          <Grid container>
            <Grid item>
              <FormProvider {...methods}>
                <ReactHookFormsInput
                  name="seats"
                  label="Seats"
                  labelVariant="EC_TYPE_SM"
                  rules={{
                    required: 'Number of seats is required',
                    min: {
                      value: 0,
                      message: 'Number of seats must be 0 or greater'
                    },
                    validate: { isInteger }
                  }}
                  inputType="number"
                />
              </FormProvider>
            </Grid>
          </Grid>
        </Box>
        {featuresFlags.GLRC_CUSTOMIZATION && (
          <FormControl sx={{ m: 0 }} component="fieldset" variant="standard">
            <FormLabel
              component="legend"
              sx={{
                textTransform: 'uppercase',
                typography: theme.typography.EC_TYPE_XL
              }}
            >
              Customization
            </FormLabel>
            <FormGroup>
              <Styled.FormControlLabel
                control={
                  <Styled.Checkbox
                    disabled={disableCheckboxes}
                    value={_customThemeAccess}
                    checked={_customThemeAccess}
                    onChange={(): void =>
                      setCustomThemeAccess(!_customThemeAccess)
                    }
                    name={'customization'}
                  />
                }
                label="Header, images, title and colors"
              />
            </FormGroup>
          </FormControl>
        )}
        <Box>
          <Typography variant="EC_TYPE_BASE">
            Select the courses that this company will have access:
          </Typography>

          {topicsLoading || typesLoading ? (
            <Skeleton
              height={190}
              variant="rectangular"
              sx={{ marginTop: '24px' }}
            />
          ) : (
            <Styled.FormWrapper>
              {glrcContentTypes.map((contentType) => (
                <FormControl
                  key={contentType.value}
                  sx={{ m: 0 }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormLabel
                    component="legend"
                    sx={{
                      textTransform: 'uppercase',
                      typography: theme.typography.EC_TYPE_XL
                    }}
                  >
                    {contentType.label}
                  </FormLabel>
                  <FormGroup>
                    {glrcContentTopics.map((contentTopic) => (
                      <Styled.FormControlLabel
                        key={contentTopic.value}
                        control={
                          <Styled.Checkbox
                            disabled={disableCheckboxes}
                            value={contentTopic.value}
                            checked={isChecked(
                              contentType.value,
                              contentTopic.value
                            )}
                            onChange={(e): void =>
                              handleChange(
                                e,
                                contentType.value,
                                contentType.label
                              )
                            }
                            name={contentTopic.label}
                          />
                        }
                        label={contentTopic.label}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              ))}
            </Styled.FormWrapper>
          )}
        </Box>
      </BottomMarginContentWrapper>
    </BasicModal>
  );
};

export default CompanyGlrcAccessModal;
