import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { Education, WorkExperience } from '@api/models/profileSetupApi.models';
import { CandidateProfileContentType } from '@common/models/candidateProfile.models';
import { Codebook } from '@api/models/codebook.models';
import { getDateMonthAndYear } from '@common/helpers/dateHelpers/getDateMonthAndYear';
import IconWithLabel from '@components/IconWithLabel';
import { styled } from '@mui/system';

const Styled = {
  TitleBox: styled(Box)({
    display: 'flex',
    justifyContent: 'space-between'
  }),
  Title: styled(Typography)(({ theme }) => ({
    color: theme.palette.GRAY_4.main,
    letterSpacing: '0.02em'
  })),
  Content: styled(Box)(({ theme }) => ({
    marginLeft: '16px',
    marginBottom: '32px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '20px'
    },
    '& .responsibilities': {
      whiteSpace: 'pre-wrap'
    }
  })),
  BoldXS: styled(Typography)(({ theme }) => ({
    fontWeight: 800,
    color: theme.palette.GRAY_4.main,
    fontSize: '15px',
    lineHeight: '19px',
    letterSpacing: '0em'
  })),
  Photo: styled('img')({
    height: '94px',
    width: '94px',
    borderRadius: '4px',
    objectFit: 'cover',
    objectPostion: 'center'
  }),
  SectionTitleBox: styled(Box)(({ theme }) => ({
    marginBottom: '36px',
    borderBottom: `1px solid ${theme.palette.GRAY_2.main}`,
    '& .section-title-text': {
      lineHeight: '24px',
      textTransform: 'uppercase'
    }
  }))
};

interface ListItem<T extends CandidateProfileContentType> {
  content: T;
  smallWindow: boolean;
  sectionTitle?: string;
}

// TODO: refactor from Candidate into Shared

export const WorkExperienceListItem = ({
  content,
  smallWindow,
  sectionTitle
}: ListItem<WorkExperience>): React.ReactElement => {
  const { company, position, responsibilities, startDate, endDate } = content;

  const dateRange = `${getDateMonthAndYear(startDate)} - ${getDateMonthAndYear(
    endDate
  )}`;

  return (
    <>
      {sectionTitle && (
        <Styled.SectionTitleBox>
          <Typography variant="EC_TYPE_4XL" className="section-title-text">
            {sectionTitle}
          </Typography>
        </Styled.SectionTitleBox>
      )}
      <Styled.TitleBox data-testid="candidate-list-item-work-experience-title-box">
        <Styled.Title variant="EC_TYPE_XL" textTransform="uppercase">
          {company}
        </Styled.Title>
        {!smallWindow && (
          <Styled.BoldXS variant="EC_TYPE_XS">{dateRange}</Styled.BoldXS>
        )}
      </Styled.TitleBox>
      <Styled.Content data-testid="candidate-list-item-work-experience-content">
        <Typography variant="EC_TYPE_LG">
          {position.label} <br /> {smallWindow && `(${dateRange})`}
        </Typography>
        <Typography variant="EC_TYPE_BASE" className="responsibilities">
          {responsibilities}
        </Typography>
      </Styled.Content>
    </>
  );
};

export const EducationListItem = ({
  content,
  smallWindow,
  sectionTitle
}: ListItem<Education<Codebook>>): React.ReactElement => {
  const {
    institution,
    campus,
    program,
    educationType,
    graduationYear,
    areaOfFocus
  } = content;

  return (
    <>
      {sectionTitle && (
        <Styled.SectionTitleBox>
          <Typography variant="EC_TYPE_4XL" className="section-title-text">
            {sectionTitle}
          </Typography>
        </Styled.SectionTitleBox>
      )}
      <Styled.TitleBox data-testid="candidate-list-item-education-title-box">
        <Styled.Title variant="EC_TYPE_XL">
          {institution ||
            `Auguste Escoffier School of Culinary Arts (${campus?.label})`}
        </Styled.Title>
        {!smallWindow && (
          <Typography variant="EC_TYPE_BASE" mr="auto" ml="4px">
            {' '}
            - {educationType}
          </Typography>
        )}
        <Styled.BoldXS variant="EC_TYPE_XS">{graduationYear}</Styled.BoldXS>
      </Styled.TitleBox>
      <Styled.Content data-testid="candidate-list-item-education-content">
        {(areaOfFocus || program?.label) && (
          <li>
            <Typography
              variant="EC_TYPE_BASE"
              component="span"
              sx={{ position: 'relative', left: '-8px' }}
            >
              {areaOfFocus || program?.label}
            </Typography>
          </li>
        )}
      </Styled.Content>
    </>
  );
};

export const IconLabelListItem = ({
  content
}: ListItem<Codebook>): JSX.Element => {
  const { label } = content;
  return (
    <>
      <IconWithLabel
        title={label}
        icon="ri-shield-star-fill"
        iconStyle={{ transform: 'translateY(2px)' }}
      />
    </>
  );
};

export const CandidateStatement = ({
  content
}: ListItem<string>): JSX.Element => {
  return (
    <Typography
      data-testid="candidate-list-item-statement"
      dangerouslySetInnerHTML={{
        __html: content
      }}
    />
  );
};
