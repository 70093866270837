import * as React from 'react';
import AgreementModal, { AgreementModalProps } from './AgreementModal';
import { CandidateProfile } from '@api/models/candidateApi.models';
import { EmployerProfile } from '@api/models/employerApi.models';
import { ExternshipApi } from '@api/Externships.api';
import { ExternshipEntity } from '@api/models/externshipApi.models';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { getOneLineAddress } from '@common/helpers/addressHelpers/getOneLineAddress';
import { getPdfHtmlByElementId } from '@common/helpers/pdfHelpers';
import { PdfTypes } from '@api/models/pdfApi.models';
import { useKeycloakContext } from '@common/context/keycloakContext';

const styles = {
  boldText: {
    margin: '0px',
    fontWeight: 800
  }
};

interface Props extends AgreementModalProps {
  externship: ExternshipEntity;
  employerId: number;
  updateExternship: (externship: ExternshipEntity) => void;
}

const ProxyAgreement: React.FC<Props> = ({
  employerId,
  externship,
  handleClose,
  updateExternship,
  ...modalProps
}) => {
  const [errorText, setErrorText] = React.useState<string>();
  const [saving, setSaving] = React.useState(false);

  const { keycloakUser } = useKeycloakContext();
  const signedBy = `${keycloakUser.given_name} ${keycloakUser.family_name}`;

  const handleCloseModal = (): void => {
    setErrorText(undefined);
    handleClose();
  };

  const handleAcceptClick = async (): Promise<void> => {
    setSaving(true);

    try {
      const html = getPdfHtmlByElementId(PdfTypes.PROXY_AGREEMENT);

      const postBody = {
        html
      };

      const updatedExternship = await ExternshipApi.signProxyAgreement(
        externship.id,
        employerId,
        postBody
      );

      updateExternship(updatedExternship);

      handleClose();
    } catch (error: any) {
      console.error(`Error saving proxy agreement: ${error}`);
    } finally {
      setSaving(false);
    }
  };

  const supervisor = React.useMemo(() => {
    if (!externship.supervisors?.length) {
      return;
    }

    const supervisor = externship?.supervisors?.find(
      (s) => s.employer.id === employerId
    );

    if (!supervisor) {
      return;
    }

    return supervisor;
  }, [externship]);

  const getDisplayName = (
    person?: EmployerProfile | CandidateProfile
  ): string => {
    if (!person) {
      return '';
    }

    return `${person.firstName} ${person.lastName}`;
  };

  const { company, proposedCompany, candidate, course } = externship;

  const proxyInfoSections = [
    [
      { label: 'Business Name', value: company.name },
      { label: 'Business Phone', value: company.phone },
      {
        label: 'Business Address',
        value: getOneLineAddress(
          proposedCompany?.location || company?.locations[0]
        )
      }
    ],
    [
      { label: 'Proxy Name', value: getDisplayName(supervisor?.employer) },
      {
        label: 'Phone',
        value:
          externship.proposedCompany?.supervisors?.find(
            (s) =>
              s.email.toLowerCase() === supervisor?.employer.email.toLowerCase()
          )?.phoneNumber || supervisor?.employer.phone
      },
      { label: 'Title', value: supervisor?.employer.jobTitle },
      { label: 'Work Email', value: supervisor?.employer.email }
    ],
    [
      { label: 'Student Name', value: getDisplayName(candidate) },
      { label: 'Phone', value: candidate.phone },
      { label: 'Email', value: candidate.email }
    ]
  ];

  return (
    <AgreementModal
      {...modalProps}
      htmlWrapperId={PdfTypes.PROXY_AGREEMENT}
      handleClose={handleCloseModal}
      handleAccept={handleAcceptClick}
      errorText={errorText}
      pdfLoading={saving}
    >
      <div>
        {proxyInfoSections.map((section, idx) => (
          <React.Fragment key={idx}>
            <div>
              {section.map((item, idx) => (
                <p style={styles.boldText} key={idx + item.label}>
                  {item.label}:{' '}
                  <span style={{ fontWeight: 400 }}>{item.value}</span>
                </p>
              ))}
            </div>
            <br />
          </React.Fragment>
        ))}
        <h3>EXTERNSHIP SUPERVISOR:</h3>
        <p>
          I hereby verify that I have accepted the student listed above as an
          extern at my establishment, and I am aware of my responsibilities as
          the designated supervisor of this student during their externship,
          including verifying weekly attendance reporting and the assessment
          process for completing the required student evaluations. If I am
          unable to verify time, I agree to designate an additional authorized
          person to do so as my proxy.
        </p>
        <p>
          I am an authorized supervisor for this business establishment, and I
          attest that all information provided in this agreement is true and
          correct.
        </p>
        <div style={{ margin: '32px 0' }}>
          <p style={styles.boldText}>
            SIGNED BY: <span style={{ fontWeight: 400 }}>{signedBy}</span>
          </p>
          <p style={styles.boldText}>
            DATE SIGNED:{' '}
            <span style={{ fontWeight: 400 }}>
              {formatDate(new Date().toISOString())}
            </span>
          </p>
        </div>
        <div>
          <strong>EXTERNSHIP SITE GUIDELINES:</strong>
          <ol type="a">
            <li>
              Provide training that is similar to that which would be given in a
              vocational school environment.
            </li>
            <li>Provide guidance and direction to the student.</li>
            <li>
              Work with the student to develop a schedule that includes
              rotational and progressive tasks.
            </li>
            <li>
              Provide the student {Number(course?.ClockHours)} hours over 6
              weeks.
            </li>
            <li>
              Sign the student&apos;s timesheet{' '}
              <div
                style={{
                  display: 'inline-block',
                  borderBottom: '1px solid black'
                }}
              >
                weekly
              </div>{' '}
              verifying the dates and hours worked that week.
            </li>
            <li>Complete evaluations and review them with student.</li>
            <li>
              Contact the school immediately if the student quits or is
              terminated during the externship.
            </li>
            <li>
              Be an equal opportunity employer and comply with all labor laws.
            </li>
            <li>
              Not require the student to work more than 8 hours per day for 5
              consecutive days.
            </li>
          </ol>
        </div>
      </div>
    </AgreementModal>
  );
};

export default ProxyAgreement;
