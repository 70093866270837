import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Intentions } from '@api/models/adminDashboardApi.models';
import { styled } from '@mui/system';

const Styled = {
  IntentionsTableWrapper: styled(Box)(({ theme }) => ({
    margin: '0 auto',
    display: 'block',
    border: `1px solid ${theme.palette.GOLD_1.main}`,
    maxWidth: '500px',
    borderRadius: '4px',
    '&>*:nth-of-type(odd)': {
      backgroundColor: theme.palette.GRAY_1.main
    },
    '.table-header': {
      backgroundColor: theme.palette.gold1.main,
      borderRadius: '4px 4px 0px 0px',
      borderBottom: `1px solid ${theme.palette.GOLD_1.main}`
    }
  })),
  IntentionRow: styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    columnGap: '16px',

    '&:hover': {
      backgroundColor: theme.palette.gold1.main,
      transition: '0.5s'
    }
  }))
};

interface Props {
  intentions: Intentions;
}

const IntentionsTabPanel: React.FC<Props> = ({ intentions }) => {
  const intentionRows = [
    { label: 'Apply', count: intentions.apply },
    { label: 'Browse', count: intentions.browse },
    { label: 'Learn More', count: intentions.learnMore },
    { label: 'Apply & Browse', count: intentions.applyAndBrowse },
    { label: 'Apply & Learn More', count: intentions.applyAndLearnMore },
    { label: 'Browse & Learn More', count: intentions.browseAndLearnMore },
    {
      label: 'Apply, Browse & Learn More',
      count: intentions.applyBrowseAndLearnMore
    }
  ];

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={5}>
        <Typography variant="EC_TYPE_BASE">
          Here we can see a breakdown of intentions selected by candidates. Some
          candidates may only select one intention while others select all or a
          combination of the three options.
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Styled.IntentionsTableWrapper>
          <Styled.IntentionRow className="table-header">
            <Typography variant="EC_TYPE_XL">Selection</Typography>
            <Typography variant="EC_TYPE_XL">Count</Typography>
          </Styled.IntentionRow>
          {intentionRows.map((intention) => {
            return (
              <Styled.IntentionRow key={intention.label}>
                <Typography variant="EC_TYPE_LG">{intention.label}</Typography>
                <Typography variant="EC_TYPE_BASE">
                  {intention.count}
                </Typography>
              </Styled.IntentionRow>
            );
          })}
        </Styled.IntentionsTableWrapper>
      </Grid>
    </Grid>
  );
};

export default IntentionsTabPanel;
