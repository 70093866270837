import * as React from 'react';
import { Codebook } from '@api/models/codebook.models';
import ResumeIconWithLabel from './ResumeIconWithLabel';
import ResumeSection from './ResumeSection';
import { ReactComponent as ShieldIcon } from '@assets/resumeIcons/ri-shield-star-fill.svg';
import theme from '@themes/ui/escoffier';

const styles = {
  certficatesWrapper: {
    display: 'flex',
    columnGap: '16px',
    rowGap: '12px',
    flexWrap: 'wrap' as 'wrap'
  }
};

interface Props {
  certificates: Codebook[];
}

const ResumeCertificationSection: React.FC<Props> = ({ certificates }) => {
  if (!certificates.length) {
    return <></>;
  }

  return (
    <ResumeSection title="Certifications" indent>
      <div style={styles.certficatesWrapper}>
        {certificates.map((c) => {
          const hidden = c['hidden'] === true;

          if (!hidden) {
            return (
              <ResumeIconWithLabel
                key={c.value}
                label={c.label}
                svgIcon={ShieldIcon}
                style={{ textTransform: 'uppercase' }}
                color={theme.palette.GRAY_3.main}
                dataTestId="resume-cert-section-item"
              />
            );
          }
        })}
      </div>
    </ResumeSection>
  );
};

export default ResumeCertificationSection;
