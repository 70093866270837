import * as React from 'react';
import {
  ExternshipEntity,
  ExternshipStatus
} from '@api/models/externshipApi.models';
import { ExternshipApi } from '@api/Externships.api';

export type FetchExternshipsByStatus = {
  externships: ExternshipEntity[];
  loaded: boolean;
  loading: boolean;
};

export const useFetchExternshipsByStatus = (
  externshipStatus: ExternshipStatus
): FetchExternshipsByStatus => {
  const [externships, setExternships] = React.useState<ExternshipEntity[]>([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchExternshipsByStatus = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await ExternshipApi.getByStatus(externshipStatus);

      setExternships(res);
      setLoaded(true);
    } catch (error: any) {
      console.error(
        'Error for useFetchExternshipsByStatus -> ExternshipApi.getByStatus()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    fetchExternshipsByStatus();
  }, []);

  return {
    externships,
    loaded,
    loading
  };
};
