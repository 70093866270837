import {
  Question,
  QuestionWithAnswers,
  QuizInfo,
  QuizPostReq,
  QuizResults
} from './models/quizApi.models';
import { api } from './configs/base';
import { apiConfig } from './configs/api.config';
import { ApiResponse } from './models/base.models';

export class QuizApi {
  static async postResults(
    id: number,
    data: QuizPostReq
  ): Promise<ApiResponse<QuizResults[]>> {
    try {
      const response = await api.post<ApiResponse<QuizResults[]>>(
        `${apiConfig.quizUrl}/${id}/results`,
        data
      );
      return response.data;
    } catch (error: any) {
      console.error(`Error for QuizApi.postResults(): ${error}`);
      throw error;
    }
  }

  static async getQuizInfo(id: number): Promise<ApiResponse<QuizInfo>> {
    try {
      const response = await api.get<ApiResponse<QuizInfo>>(
        `${apiConfig.quizUrl}/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.error(`Error for QuizApi.getQuizInfo(): ${error}`);
      throw error;
    }
  }

  static async getQuestionWithAnswers(
    id: number,
    ordinalNumber: number
  ): Promise<ApiResponse<QuestionWithAnswers>> {
    try {
      const response = await api.get<ApiResponse<QuestionWithAnswers>>(
        `${apiConfig.quizUrl}/${id}/questions/${ordinalNumber}`
      );
      return response.data;
    } catch (error: any) {
      console.error(`Error for QuizApi.getQuestionWithAnswers(): ${error}`);
      throw error;
    }
  }

  static async getAllActiveQuestions(
    id: number
  ): Promise<ApiResponse<Question[]>> {
    try {
      const response = await api.get<ApiResponse<Question[]>>(
        `${apiConfig.quizUrl}/${id}/questions`
      );
      return response.data;
    } catch (error: any) {
      console.error(`Error for QuizApi.getAllActiveQuestions(): ${error}`);
      throw error;
    }
  }
}
