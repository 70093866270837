/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Toast, useToast } from '@components/Toast';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { Box, Typography } from '@mui/material';
import { CompanyProfile } from '@api/models/companyApi.models';
import CustomizationSettings from '../CustomizationSettings/CustomizationSettings';
import CompanyNameChangeForm from './CompanyNameChangeForm';
import SeatManagement from './SeatManagement';

export interface CompanySettingsRedesignProps {
  company: CompanyProfile;
}

const CompanySettingsRedesign: React.FC<CompanySettingsRedesignProps> = ({
  company,
}) => {
  const toastProps = useToast();

  return (
    <Box
      sx={{ 
        padding: '1rem',
        border: '1px solid #ccc',
        margin: '2rem 0',
      }}
    >
      <BottomMarginContentWrapper 
        bottomMargin="2rem"
      >
          <Typography variant="EC_TYPE_3XL" sx={{ margin: '1rem 0' }}>
            {company.name}
          </Typography>

        <Typography variant="h3"></Typography>
        
        <CompanyNameChangeForm
          openToast={toastProps.openToast}
          company={company}
        />
        <SeatManagement 
          openToast={toastProps.openToast} 
          company={company} 
        />
        {company.customThemeAccess && 
          <CustomizationSettings
            company={company}
            toastProps={toastProps}
          />
        }

      </BottomMarginContentWrapper>
      <Toast {...toastProps} />
    </Box>
  );
};

export default CompanySettingsRedesign;
