import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { Banner } from '@api/models/bannersApi.models';
import { createCustomContext } from '@common/context/customContext';
import { Footnote } from '@common/models/common.models';

/**
 * List of routes where the footer should not be displayed for any user
 */
export const footerBlacklist = ['/learning-center'];

/**
 * List of routes where the footer should not be displayed specifically for ESource (GLRC) user
 */
export const esourceUserFooterBlacklist = [
  '/contact-us',
  '/account',
  '/learning-center'
];

export interface HeaderFooterBannerContext {
  banners?: Banner[];
  closeBannerByBannerId: (bannerId: number) => void;
  footnotes: Footnote[];
  setFootnotes: Dispatch<SetStateAction<Footnote[]>>;
  supRef?: MutableRefObject<HTMLDivElement | undefined>;
  onSupClick: () => void;
}

export const [useHeaderFooterBannerContext, HeaderFooterBannerProvider] =
  createCustomContext<HeaderFooterBannerContext>();
