import * as React from 'react';
import { Box, Divider, Skeleton, Typography } from '@mui/material';
import {
  ExternshipEntity,
  Timesheet,
} from '@api/models/externshipApi.models';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import EcProgressBar from '@components/EcProgressBar';
import { getTimesheetStatusHours } from '@common/helpers/externshipHelpers/getTimesheetStatusHours';
import GoldenInfoBox from '@pages/Shared/TimesheetPage/GoldenInfoBox';
import NotFoundPage from '@pages/Errors/NotFoundPage';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { styled } from '@mui/system';
import TimesheetSiteInfo from '@pages/Shared/TimesheetPage/TimesheetSiteInfo';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchTimesheetsByCandidateAndCourse } from '@common/fetches/useFetchTimesheetsByCandidateAndCourse';
import { useParams } from 'react-router-dom';

const Styled = {
  OverviewSection: styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'end',
    marginTop: '8px',
    columnGap: '48px',
    rowGap: '48px',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  })),
  ExternshipDetail: styled(Box)({
    minWidth: '150px'
  }),
  ExternshipProgressWrapper: styled(Box)({
    marginLeft: 'auto',
    flex: '0 1 443px',
    display: 'flex',
    columnGap: '8px',
    alignItems: 'flex-start'
  }),
  Divider: styled(Divider)(({ theme }) => ({
    margin: '12px 0 64px',
    backgroundColor: theme.palette.GRAY_2.main
  }))
};

interface RouteParams {
  candidateId?: string;
  courseId?: string;
}

const TimesheetPageMultiSite: React.FC = () => {
  const featureFlags = useFeatureFlags();

  const { candidateId, courseId } = useParams<RouteParams>();

  const {
    timesheetsWithExternship,
    allTimesheets: timesheets,
    course,
    loading,
    setTimesheetsWithExternship,
    setAllTimesheets
  } = useFetchTimesheetsByCandidateAndCourse(candidateId, courseId);

  const updateTimesheets = (timesheet: Timesheet): void => {
    const newData = timesheetsWithExternship.map((item) => {
      if (item.externship.id === timesheet.externship.id) {
        return {
          ...item,
          timesheets: item.timesheets.map((ts) =>
            ts.id === timesheet.id ? timesheet : ts
          )
        };
      }

      return item;
    });

    const newAllTimesheets = newData.flatMap((item) => item.timesheets);

    setTimesheetsWithExternship(newData);
    setAllTimesheets(newAllTimesheets);
  };

  const { approvedTime, submittedTime, approvedMinutes, pendingProgress } =
    getTimesheetStatusHours(timesheets, course?.ClockHours);

  if (loading) {
    return (
      <RootPageLayout muiMaxWidth="xl">
        <Skeleton height="50vh" />
      </RootPageLayout>
    );
  }

  if (
    !timesheetsWithExternship.length ||
    !featureFlags.MULTIPLE_EXTERNSHIPS_PER_COURSE
  ) {
    return <NotFoundPage />;
  }

  const percentComplete = course?.ClockHours
    ? 100 * (approvedMinutes / (course.ClockHours * 60))
    : 0;

  return (
    <RootPageLayout muiMaxWidth="xl" bgImgVariant={6}>
      <Typography variant="EC_TYPE_4XL">Externship Time Sheets</Typography>
      <Typography variant="EC_TYPE_2XL">
        {timesheetsWithExternship[0].externship.candidate.firstName}{' '}
        {timesheetsWithExternship[0].externship.candidate.lastName}
      </Typography>
      <Styled.OverviewSection>
        <Styled.ExternshipDetail>
          <Typography variant="EC_TYPE_XL">Program</Typography>
          <Typography variant="EC_TYPE_BASE">{course?.programName}</Typography>
        </Styled.ExternshipDetail>
        <Styled.ExternshipDetail>
          <Typography variant="EC_TYPE_XL">Course</Typography>
          <Typography variant="EC_TYPE_BASE">
            {course?.Code} - {course?.Name}
          </Typography>
        </Styled.ExternshipDetail>
        <Styled.ExternshipProgressWrapper>
          <EcProgressBar
            percentComplete={percentComplete}
            pendingProgress={pendingProgress}
            subtext={`Approved ${approvedTime} / Submitted ${submittedTime} / of ${course?.ClockHours} hours`}
          />
          <GoldenInfoBox
            title="TOTAL TIME"
            text={approvedTime}
            totalHours={course?.ClockHours}
          />
        </Styled.ExternshipProgressWrapper>
      </Styled.OverviewSection>
      <Styled.Divider />
      <BottomMarginContentWrapper bottomMargin="86px">
        {timesheetsWithExternship.map((item) => (
          <Box key={item.externship.id}>
            <TimesheetSiteInfo
              timesheetsWithExternship={item}
              externship={item.externship as ExternshipEntity}
              updateTimesheets={updateTimesheets}
            />
          </Box>
        ))}
      </BottomMarginContentWrapper>
    </RootPageLayout>
  );
};

export default TimesheetPageMultiSite;
