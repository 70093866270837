import * as React from 'react';
import {
  AgreementEntity,
  APPROVER_ERROR,
  ExternshipEntity,
  ExternshipStatus,
  StudentViewExternship
} from '@api/models/externshipApi.models';
import {
  Alert,
  Box,
  Button,
  Icon,
  IconButton,
  Theme,
  Typography
} from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import ApproverActivation from './ApproverActivation';
import CancelIcon from '@mui/icons-material/Cancel';
import { ExternshipApi } from '@api/Externships.api';
import ExternshipApproverFieldArray from '@components/Forms/ExternshipApproverFieldArray';
import { ExternshipProposedManagerOrSupervisor } from '@api/models/externshipApi.models';
import RemoveApproverModal from '@pages/CareerServices/StudentPage/StudentSiteInfo/RemoveApproverModal';
import ResendModals from './ResendModals';
import { styled } from '@mui/system';
import { useStudentViewContext } from '@common/context/studentViewContext';
import { useStudentViewSiteInfoContext } from '@common/context/studentViewSiteInfoContext';
import { useToast } from '@components/Toast';

const Styled = {
  Root: styled(Box)({
    '& > *:not(:first-of-type)': {
      marginTop: '24px'
    }
  }),
  PaddingBox: styled(Box)({
    '& > *:not(:last-child)': {
      paddingBottom: '8px'
    },
    display: 'flex',
    justifyContent: 'space-between'
  }),
  EditSupervisorBox: styled(Box)(({ theme }) => ({
    display: 'flex',
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.OUTLINE.main}`,
      paddingBottom: '12px'
    },
    '& .remove-supervisor-btn': {
      color: theme.palette.primary.main,
      height: '13px',
      width: '13px',
      marginLeft: '8px'
    }
  })),
  WarningIcon: styled('i')({
    marginRight: '4px'
  }),
  MissingSignatureText: styled('span')(({ theme }) => ({
    ...(theme as Theme).typography.EC_TYPE_2XS,
    color: theme.palette.error.main
  })),
  ApproverTitle: styled(Typography)({
    textTransform: 'uppercase',
    marginBottom: '8px'
  }),
  AddBtn: styled(Button)(({ theme }) => ({
    ...(theme as Theme).typography.EC_TYPE_2XS,
    color: theme.palette.primary.main,
    padding: 0,
    marginTop: '12px'
  })),
  TextBtn: styled(Button)(({ theme }) => ({
    ...(theme as Theme).typography.EC_TYPE_2XS,
    '& .MuiButton-endIcon': {
      marginLeft: '4px',
      marginRight: 0
    },
    '&.MuiButtonBase-root:hover': {
      bgcolor: 'transparent'
    },
    minWidth: 0
  })),
  BtnIcon: styled(Icon)({
    fontSize: '15px !important'
  }),
  VerticalLine: styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    width: '1px',
    height: '10px'
  })),
  ApproverActionsWrapper: styled(Box)({
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    marginTop: '8px'
  })
};

const statusesEligibleToRemoveManager = [
  ExternshipStatus.PENDING,
  ExternshipStatus.PENDING_EXT_MNG_AGREEMENT,
  ExternshipStatus.PENDING_EXT_STD_AGREEMENT,
  ExternshipStatus.PENDING_EXT_CS_AGREEMENT,
  ExternshipStatus.PENDING_PROXY_AGREEMENT,
  ExternshipStatus.ACTIVE
];

export interface SiteCardApproverDetails
  extends ExternshipProposedManagerOrSupervisor {
  proxyAgreement?: AgreementEntity | null;
  active?: boolean;
  approverHasCreatedAccount?: boolean;
}

interface Approvers {
  manager?: SiteCardApproverDetails;
  supervisors?: SiteCardApproverDetails[];
}

interface ApproverProps extends SiteCardApproverDetails {
  type: string;
  externshipId: number;
  index?: number;
  showResendBtn?: boolean;
  externshipStatus: number;
}

interface ApproverDetailsProps extends Approvers {
  externship: ExternshipEntity;
}

const defaultSupervisorFields = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  jobTitle: '',
  jobCategory: 'Supervisor'
};

const fieldGridSizes = {
  firstName: { xs: 6 },
  lastName: { xs: 6 },
  email: { xs: 12 },
  phone: { xs: 12 },
  jobTitle: { xs: 12 }
};

const ApproverSection = ({
  type,
  externshipId,
  index,
  showResendBtn = false,
  externshipStatus,
  ...approverData
}: ApproverProps): React.ReactElement => {
  const { externships, setExternships } = useStudentViewContext();

  const { openToast } = useToast();

  const [showRemoveSupervisorModal, setShowRemoveSupervisorModal] =
    React.useState<boolean>(false);
  const [resendError, setResendError] = React.useState<string>();
  const [resendModal, setResendModal] = React.useState<boolean>(false);

  const isSupervisor = type !== 'Manager';
  const {
    approverError,
    firstName,
    lastName,
    jobTitle,
    phoneNumber,
    email,
    proxyAgreement,
    active,
    id
  } = approverData;

  const showSupervisorResendBtn = showResendBtn;

  const TIMESHEET_ACCES_TOGGLE_STATUSES = [
    ExternshipStatus.ACTIVE,
    ExternshipStatus.NO_PASS,
    ExternshipStatus.COMPLETED
  ];

  const showActivationButton =
    TIMESHEET_ACCES_TOGGLE_STATUSES.includes(externshipStatus) &&
    active !== undefined &&
    proxyAgreement;

  const linkToDashboardButton = approverData.proxyAgreement;

  const handleRemoveSupervisorFromExternship = async (): Promise<void> => {
    try {
      const res = await ExternshipApi.removeSupervisor(externshipId, email);
      openToast('Supervisor was removed sucessfully.');
      setShowRemoveSupervisorModal(false);
      const externship: StudentViewExternship = externships.find(
        (e) => e.id === externshipId
      ) as StudentViewExternship;

      externship.status = res.externshipStatus;

      if (externship?.proposedCompany?.supervisors) {
        externship.proposedCompany.supervisors =
          externship?.proposedCompany?.supervisors.filter(
            (s) => s.email !== email
          );
      }

      if (externship.supervisors) {
        externship.supervisors = externship.supervisors.map((s) => {
          if (s.employer.email === email) {
            s.removeDate = new Date();
          }
          return s;
        });
      }

      const updatedExternships = externships.map((e) => {
        if (e.id === externship.id) {
          return externship;
        }
        return e;
      });

      setExternships(updatedExternships);
    } catch (error: any) {
      console.error(
        `Error for ApproverDetails -> ExternshipApi.removeSupervisor: ${error}`
      );
      const errorMsg = error?.response?.data?.message;
      setResendError(errorMsg);
    }
  };

  const getSupervisorNameAndEmail = (): string => {
    let supervisorLabel = '';
    if (approverData.firstName) {
      supervisorLabel += approverData.firstName;
    }

    if (approverData.lastName) {
      supervisorLabel += ` ${approverData.lastName}`;
    }

    if (approverData.email) {
      if (supervisorLabel.length) {
        supervisorLabel += ` - ${approverData.email}`;
      } else {
        supervisorLabel += approverData.email;
      }
    }

    return supervisorLabel;
  };

  const getApproverErrorLabel = (approverError: APPROVER_ERROR): string => {
    const labelMap = {
      [APPROVER_ERROR.COMPANY_MISMATCH]: 'Company mismatch with site proposal',
      [APPROVER_ERROR.INVALID_ROLE]: 'Invalid Role'
    };

    return labelMap[approverError] || 'Error';
  };

  const DISABLED_TIMESHEET_ACCESS_STATUSES = [
    ExternshipStatus.NO_PASS,
    ExternshipStatus.COMPLETED
  ];

  return (
    <Styled.PaddingBox>
      <Box sx={{ width: '100%' }}>
        {(index === undefined || index === 0) && (
          <Styled.ApproverTitle variant="EC_TYPE_2XS">
            {type}:
          </Styled.ApproverTitle>
        )}
        <Typography variant="EC_TYPE_SM" fontWeight={700}>
          {firstName} {lastName}
        </Typography>
        <Typography variant="EC_TYPE_2XS">{email}</Typography>
        <Typography variant="EC_TYPE_2XS">{phoneNumber}</Typography>
        <Typography variant="EC_TYPE_2XS">{jobTitle}</Typography>
        {isSupervisor && !proxyAgreement && (
          <Styled.MissingSignatureText className="missing-signature-span">
            <Styled.WarningIcon className="ri-error-warning-line" />
            Missing signature
          </Styled.MissingSignatureText>
        )}
        {!!approverError && (
          <Alert severity="error" sx={{ margin: '8px 0px 0px' }}>
            {getApproverErrorLabel(approverError)}
          </Alert>
        )}

        {isSupervisor && (
          <>
            <Styled.ApproverActionsWrapper>
              {showSupervisorResendBtn && (
                <>
                  <Styled.TextBtn
                    variant="text"
                    className="disable-while-loading"
                    endIcon={
                      <Styled.BtnIcon
                        className={`${
                          linkToDashboardButton
                            ? 'ri-links-line'
                            : 'ri-mail-send-line'
                        }`}
                      />
                    }
                    onClick={(): void => setResendModal(true)}
                  >
                    {linkToDashboardButton ? 'Link to dashboard' : 'Resend'}{' '}
                  </Styled.TextBtn>
                  <Styled.VerticalLine />
                </>
              )}
              {statusesEligibleToRemoveManager.includes(externshipStatus) && (
                <Styled.TextBtn
                  variant="text"
                  className="disable-while-loading"
                  endIcon={<Styled.BtnIcon className="ri-delete-bin-6-line" />}
                  onClick={(): void => setShowRemoveSupervisorModal(true)}
                >
                  Remove
                </Styled.TextBtn>
              )}
            </Styled.ApproverActionsWrapper>
            {resendError && (
              <Box>
                <Styled.MissingSignatureText>
                  <Styled.WarningIcon className="ri-error-warning-line" />
                  {resendError}
                </Styled.MissingSignatureText>
              </Box>
            )}
          </>
        )}
      </Box>
      {showActivationButton && (
        <ApproverActivation
          disabled={DISABLED_TIMESHEET_ACCESS_STATUSES.includes(
            externshipStatus
          )}
          active={active}
          externshipId={externshipId}
          supervisorId={id}
          email={email}
        />
      )}
      <RemoveApproverModal
        open={showRemoveSupervisorModal}
        handleClose={(): void => setShowRemoveSupervisorModal(false)}
        approver={getSupervisorNameAndEmail()}
        handleRemoveApprover={handleRemoveSupervisorFromExternship}
      />
      {resendModal && (
        <ResendModals
          externshipStatus={externshipStatus}
          approver={approverData}
          externshipId={externshipId}
          open={resendModal}
          closeModal={(): void => {
            setResendModal(false);
          }}
          isSupervisor={isSupervisor}
        />
      )}
    </Styled.PaddingBox>
  );
};

const ApproverDetails: React.FC<ApproverDetailsProps> = ({
  manager,
  supervisors,
  externship
}) => {
  const { openToast } = useToast();

  const { setExternships } = useStudentViewContext();

  const [showRemoveSupervisorModal, setShowRemoveSupervisorModal] =
    React.useState<boolean>(false);
  const [resendModal, setResendModal] = React.useState<boolean>(false);

  const { getIsEditing } = useStudentViewSiteInfoContext();
  const isEditing = getIsEditing('Approvers');
  const { control, watch, reset, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: 'approvers.supervisors',
    control
  });

  const { id: externshipId, status } = externship;

  React.useEffect(() => {
    if (isEditing) {
      reset({
        ...getValues(),
        approvers: {
          manager: {
            firstName: manager?.firstName,
            lastName: manager?.lastName,
            email: manager?.email,
            phoneNumber: manager?.phoneNumber,
            jobTitle: manager?.jobTitle
          },
          supervisors
        }
      });
    }
  }, [isEditing, manager, supervisors]);

  const supervisorFieldArray = watch('approvers.supervisors') || [];
  const controlSupervisorFields = fields.map((field, idx) => ({
    ...field,
    ...supervisorFieldArray[idx]
  }));

  const handleAddSupervisor = (): void => {
    append(defaultSupervisorFields);
  };

  const handleRemoveSupervisor = (idx: number): void => {
    remove(idx);
  };

  const handleRemoveManagerFromExternship = async (): Promise<void> => {
    try {
      const res = await ExternshipApi.removeManager(externshipId);
      setShowRemoveSupervisorModal(false);
      openToast('Manager has been removed from the externship');

      setExternships((prev) => {
        return [...prev].map((e) => {
          if (e.id === res.id) {
            return {
              ...e,
              status: res.status,
              proposedCompany: {
                ...e.proposedCompany,
                manager: res.proposedCompany.manager
              },
              manager: res.manager,
              externshipAgreement: res.externshipAgreement
            };
          }
          return { ...e };
        });
      });
    } catch (error: any) {
      console.error(
        `Error for ApproverDetails -> ExternshipApi.removeManager: ${error}`
      );
    }
  };

  const invalidResendStatuses = [
    ExternshipStatus.DRAFT,
    ExternshipStatus.PENDING_SOFT_VERIFICATION,
    ExternshipStatus.CS_REJECTED,
    ExternshipStatus.DECLINED,
    ExternshipStatus.CANCELED,
    ExternshipStatus.ARCHIVED,
    ExternshipStatus.COMPLETED,
    ExternshipStatus.NO_PASS
  ];

  const showResendBtn = !invalidResendStatuses.includes(status);
  const showSupervisorResendBtn = !invalidResendStatuses.includes(status);

  const linkToDashboardButton =
    manager?.proxyAgreement && manager?.approverHasCreatedAccount;

  const getManagerLabel = (): string => {
    let label = '';

    if (manager) {
      const { firstName, lastName, email } = manager;

      if (firstName && lastName) {
        label += `${manager.firstName} ${manager.lastName}`;
      }

      if (email) {
        label += `${label.length ? ' - ' : ''}${email}`;
      }
    }

    return label;
  };

  return (
    <Styled.Root>
      {!isEditing ? (
        <>
          {manager && (
            <Box>
              <ApproverSection
                type="Manager"
                {...manager}
                externshipId={externshipId}
                externshipStatus={status}
              />
              {/* Manager Resend Paperwork/Create Account and Remove */}
              <Styled.ApproverActionsWrapper>
                {showResendBtn && (
                  <>
                    <Styled.TextBtn
                      variant="text"
                      className="disable-while-loading"
                      endIcon={
                        <Styled.BtnIcon
                          className={`${
                            linkToDashboardButton
                              ? 'ri-links-line'
                              : 'ri-mail-send-line'
                          }`}
                        />
                      }
                      onClick={(): void => setResendModal(true)}
                    >
                      {linkToDashboardButton ? 'Link to dashboard' : 'Resend'}
                    </Styled.TextBtn>
                    <Styled.VerticalLine />
                  </>
                )}
                {statusesEligibleToRemoveManager.includes(
                  externship.status
                ) && (
                  <>
                    <Styled.TextBtn
                      variant="text"
                      className="disable-while-loading"
                      endIcon={
                        <Styled.BtnIcon className="ri-delete-bin-6-line" />
                      }
                      onClick={(): void => setShowRemoveSupervisorModal(true)}
                    >
                      Remove
                    </Styled.TextBtn>
                    <RemoveApproverModal
                      open={showRemoveSupervisorModal}
                      handleClose={(): void =>
                        setShowRemoveSupervisorModal(false)
                      }
                      isManager
                      approver={getManagerLabel()}
                      handleRemoveApprover={handleRemoveManagerFromExternship}
                    />
                  </>
                )}
              </Styled.ApproverActionsWrapper>
            </Box>
          )}
          {supervisors?.map((s, idx) => (
            <React.Fragment key={s.email}>
              <ApproverSection
                {...s}
                type="Supervisor(s)"
                externshipId={externshipId}
                index={idx}
                showResendBtn={showSupervisorResendBtn}
                externshipStatus={status}
              />
            </React.Fragment>
          ))}
        </>
      ) : (
        <>
          <Styled.ApproverTitle variant="EC_TYPE_2XS">
            Manager:
          </Styled.ApproverTitle>
          <ExternshipApproverFieldArray
            disableOnlyPersonSigningField
            disableLabels
            showPlaceholders
            containerSpacing={1}
            fieldGridProps={fieldGridSizes}
            fieldNamePrefix="approvers.manager."
          />
          {!!controlSupervisorFields.length && (
            <>
              <Styled.ApproverTitle variant="EC_TYPE_2XS">
                supervisor&#40;s&#41;:
              </Styled.ApproverTitle>
              {controlSupervisorFields.map((field, idx) => (
                <Styled.EditSupervisorBox key={field.id}>
                  <ExternshipApproverFieldArray
                    fieldArrayIndex={idx}
                    isSupervisor={true}
                    handleRemoveFieldArrayItem={handleRemoveSupervisor}
                    disableLabels
                    disableOnlyPersonSigningField
                    disableRemoveButton
                    showPlaceholders
                    containerSpacing={1}
                    fieldGridProps={fieldGridSizes}
                    fieldNamePrefix="approvers."
                  />
                  <IconButton
                    aria-label="remove-supervisor"
                    className="remove-supervisor-btn"
                    onClick={(): void => handleRemoveSupervisor(idx)}
                  >
                    <CancelIcon />
                  </IconButton>
                </Styled.EditSupervisorBox>
              ))}
            </>
          )}
          <Styled.AddBtn variant="text" onClick={handleAddSupervisor}>
            Add Supervisor
          </Styled.AddBtn>
        </>
      )}
      {resendModal && manager && (
        <ResendModals
          approver={manager}
          externshipStatus={status}
          externshipId={externshipId}
          open={resendModal}
          closeModal={(): void => {
            setResendModal(false);
          }}
        />
      )}
    </Styled.Root>
  );
};

export default ApproverDetails;
