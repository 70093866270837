/* eslint-disable */
import * as React from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Skeleton,
  Theme,
  Typography
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import { styled } from '@mui/system';
import { useFetchGlrcContentTopicsCodebook } from '@common/fetches/useFetchGlrcContentTopicsCodebook';
import { useFetchGlrcContentTypesCodebook } from '@common/fetches/useFetchGlrcContentTypesCodebook';
import { ESourceAccess } from '@api/models/glrcAccess.models';
import {
  CreateESourceGroupDto,
  ESourceGroupWithAccessDto
} from '@interfaces/glrc.interfaces';
import { GlrcContentApi } from '@api/GlrcContent.api';
import { useCompanySettingsContext } from '@common/context/companySettingsContext';

const Styled = {
  FormWrapper: styled(Box)({
    marginTop: '24px'
  }),
  FormControlLabel: styled(FormControlLabel)(({ theme }) => ({
    '& .MuiFormControlLabel-label': {
      typography: (theme as Theme).typography.EC_TYPE_BASE
    }
  })),
  Checkbox: styled(Checkbox)(({ theme }) => ({
    '&.Mui-checked': {
      color: theme.palette.primary.main
    }
  }))
};

interface FormValues {
  name: string;
}

interface Props {
  closeForm: () => void;
  currGroup?: ESourceGroupWithAccessDto;
}

const ESourceGroupAccessForm: React.FC<Props> = ({ closeForm, currGroup }) => {
  const { companyId, esourceGroups, setESourceGroups, glrcPermissions } =
    useCompanySettingsContext();

  const [_customThemeAccess, setCustomThemeAccess] = React.useState(
    currGroup?.customization || false
  );
  const [accesses, setAccesses] = React.useState<ESourceAccess[]>(
    currGroup?.accesses.map((a) => ({
      contentTopicId: a.contentTopicId,
      contentTypeId: a.contentTypeId,
      hasAccess: a.hasAccess
    })) || []
  );

  // JSON.parse(JSON.stringify(glrcAccessData.glrcAccess))
  const [saved, setSaved] = React.useState(false);

  const { glrcContentTypes, loading: typesLoading } =
    useFetchGlrcContentTypesCodebook();
  const { glrcContentTopics, loading: topicsLoading } =
    useFetchGlrcContentTopicsCodebook();

  const methods = useForm<FormValues>({
    defaultValues: {
      name: currGroup?.name || ''
    }
  });

  const { handleSubmit } = methods;

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    contentTypeId: number
  ): void => {
    const contentTopicId = Number(event.target.value);
    const newAccesses = [...accesses];
    const accessToUpdateIdx = newAccesses.findIndex(
      (a) =>
        a.contentTopicId === contentTopicId && a.contentTypeId === contentTypeId
    );

    if (accessToUpdateIdx === -1) {
      newAccesses.push({
        contentTypeId,
        contentTopicId,
        hasAccess: event.target.checked
      });
    } else {
      newAccesses[accessToUpdateIdx].hasAccess = event.target.checked;
    }

    setAccesses(newAccesses);
  };

  //   const saveGlrcCourseAccess = async (): Promise<void> => {
  //     try {
  //       const postBody: any = {
  //         companyId,
  //         accesses,
  //         customThemeAccess: _customThemeAccess
  //       };

  //       const glrcCompanyAccess: GlrcCompanyAccess[] =
  //         await GlrcAccessApi.updateGlrcCompanyAccesses(companyId, postBody);

  //       // GlrcCompanyAccess to GlrcAccesses
  //       const updatedGlrcAccess = glrcCompanyAccess.map((a) => ({
  //         contentTopicId: a.contentTopic.value,
  //         contentTopicName: a.contentTopic.label,
  //         contentTypeId: a.contentType.value,
  //         contentTypeName: a.contentType.label,
  //         hasAccess: a.hasAccess
  //       }));

  //       setGlrcAccessData({
  //         glrcAccess: updatedGlrcAccess,
  //         customThemeAccess: _customThemeAccess
  //       });
  //     } catch (error) {
  //       console.error(
  //         `Error for CompanyGlrcAccessModal.updateGlrcCompanyAccesses(): ${error}`
  //       );
  //       throw error;
  //     }
  //   };

  const handleValidForm = async (data: FormValues): Promise<void> => {
    const postBody: CreateESourceGroupDto = {
      companyId,
      name: data.name,
      customization: _customThemeAccess,
      accesses: accesses
    };

    let savedGroup: ESourceGroupWithAccessDto;
    let newList: ESourceGroupWithAccessDto[];

    if (currGroup) {
      savedGroup = await GlrcContentApi.updateESourceGroup(
        currGroup.id,
        postBody
      );
      newList = [...esourceGroups];
      const index = newList.findIndex((i) => i.id === savedGroup.id);
      newList[index] = savedGroup;
    } else {
      savedGroup = await GlrcContentApi.createESourceGroup(postBody);
      newList = [...esourceGroups, savedGroup];
    }

    setESourceGroups(newList);
    closeForm();
  };

  const isChecked = (
    contentTypeId: number,
    contentTopicId: number
  ): boolean => {
    const access = accesses.find(
      (a) =>
        a.contentTopicId === contentTopicId && a.contentTypeId === contentTypeId
    );

    if (!access) {
      return false;
    }

    return access.hasAccess;
  };

  // Disable esource group content permissions if their company has no access to them
  const isDisabled = (
    contentTypeId: number,
    contentTopicId: number
  ): boolean => {
    const access = glrcPermissions?.accesses.find((a) => {
      if (!a.hasAccess) {
        return false;
      }
      return (
        a.contentTopicId === contentTopicId && a.contentTypeId === contentTypeId
      );
    });

    if (!access) {
      return true;
    }

    return false;
  };

  const handleSave = async (): Promise<void> => {
    await handleSubmit(handleValidForm)();
  };

  return (
    <BottomMarginContentWrapper bottomMargin="24px">
      <Box>
        <Typography variant="EC_TYPE_LG" mb={2}>
          Permission Group
        </Typography>
        <Grid container>
          <Grid item>
            <FormProvider {...methods}>
              <ReactHookFormsInput
                name="name"
                label="Group Name"
                labelVariant="EC_TYPE_SM"
                rules={{
                  required: 'Group name is required'
                }}
              />
            </FormProvider>
          </Grid>
        </Grid>
      </Box>

      <Box>
        {topicsLoading || typesLoading ? (
          <Skeleton
            height={190}
            variant="rectangular"
            sx={{ marginTop: '24px' }}
          />
        ) : (
          <Styled.FormWrapper>
            {glrcContentTypes.map((contentType) => (
              <Box sx={{ marginBottom: '32px' }}>
                <FormControl
                  key={contentType.value}
                  sx={{ m: 0 }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormLabel
                    component="legend"
                    sx={{
                      textTransform: 'uppercase',
                      typography: 'EC_TYPE_XL'
                    }}
                  >
                    {contentType.label}
                  </FormLabel>
                  <FormGroup>
                    {glrcContentTopics.map((contentTopic) => {
                      return (
                        <Styled.FormControlLabel
                          key={contentTopic.value}
                          control={
                            <Styled.Checkbox
                              disabled={isDisabled(
                                contentType.value,
                                contentTopic.value
                              )}
                              value={contentTopic.value}
                              checked={isChecked(
                                contentType.value,
                                contentTopic.value
                              )}
                              onChange={(e): void =>
                                handleChange(e, contentType.value)
                              }
                              name={contentTopic.label}
                            />
                          }
                          label={contentTopic.label}
                        />
                      );
                    })}
                  </FormGroup>
                </FormControl>
              </Box>
            ))}
          </Styled.FormWrapper>
        )}
      </Box>
      <Grid container spacing={2} mt={'48px'}>
        <Grid item xs={12} sm={4}>
          <Button fullWidth variant="outlined" onClick={closeForm}>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button fullWidth variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Grid>
      </Grid>
    </BottomMarginContentWrapper>
  );
};

export default ESourceGroupAccessForm;
