import * as React from 'react';
import { Codebook } from '@api/models/codebook.models';
import { GraduationTypes } from '@api/models/adminDashboardApi.models';
import { Grid } from '@mui/material';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import ReactHookFormsSelect from '@components/Forms/ReactHookFormsSelect';
import { studentIdPattern } from '@common/regex/studentId';
import { useFormContext } from 'react-hook-form';

interface Props {
  disabled?: boolean;
  graduationTypes: Codebook[];
  isStudentSso?: boolean;
}

const CandidateEscoffierAffiliationForm: React.FC<Props> = ({
  disabled = false,
  graduationTypes,
  isStudentSso = false
}) => {
  const isProd = process.env.REACT_APP_FEATURE_FLAG_ENV === 'prod';

  const {
    setValue,
    watch,
    formState: { dirtyFields }
  } = useFormContext();

  const graduationType =
    watch('graduationType') && parseInt(watch('graduationType'));
  const campusId = watch('campus');

  React.useEffect(() => {
    // If campus is dirty and changed clear program
    if (dirtyFields.campus) {
      setValue('program', '');
    }
  }, [campusId]);

  const isEscoffierStudent = graduationType === GraduationTypes.EC_STUDENT;

  return (
    <>
      {!isStudentSso && (
        <Grid item xs={12} sm={12}>
          <ReactHookFormsSelect
            name="graduationType"
            label="I am..."
            options={graduationTypes}
            disabled={disabled}
            rules={{ required: 'Graduation type is required' }}
          />
        </Grid>
      )}
      {(isEscoffierStudent || isStudentSso) && (
        <Grid item xs={12} sm={12}>
          <ReactHookFormsInput
            name="studentId"
            label="Student ID"
            disabled={isProd}
            rules={{
              pattern: {
                value: studentIdPattern,
                message: 'Student ID must be 10 digits'
              },
              required: 'Student ID is required'
            }}
          />
        </Grid>
      )}
    </>
  );
};

export default CandidateEscoffierAffiliationForm;
