import * as React from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import {
  ExternshipFilter,
  studentViewFilterMap
} from '@pages/CareerServices/StudentPage/studentViewFilters.constants';
import { BadgeMapping } from '@common/mappings/BadgeMappings';
import { CandidateProfilePublic } from '@api/models/candidateApi.models';
import defaultImage from '@assets/svg/candidate-def.svg';
import ImageWithBadge from '@components/ImageWithBadge';
import { Link as RouterLink } from 'react-router-dom';
import SimpleSelect from '@components/Forms/SimpleSelect';
import { StudentWithSchoolStatus } from '@api/models/studentApi.models';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useStudentViewContext } from '@common/context/studentViewContext';

const Styled = {
  Root: styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',
    ['@media (max-width: 750px)']: {
      flexDirection: 'column'
    }
  }),
  Box: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '32px',
    '& .candidate-name': {
      color: theme.palette.GRAY_4.main
    }
  }),
  Info: styled(Box)({
    display: 'flex',
    columnGap: '16px',
    rowGap: '4px',
    flexWrap: 'wrap'
  }),
  SelectWrapper: styled(Box)({
    width: '200px',
    ['@media (max-width: 750px)']: {
      width: '100%'
    }
  })
};

interface Props {
  profile: CandidateProfilePublic;
  studentWithSchoolStatus: StudentWithSchoolStatus;
}

const StudentPageHeader: React.FC<Props> = ({
  profile,
  studentWithSchoolStatus
}) => {
  const { firstName, lastName, photo, badge, email, phone } = profile;
  const { assignedTo, schoolStatus } = studentWithSchoolStatus;

  const {
    allExternships,
    externships,
    setExternships,
    currentFilter,
    setCurrentFilter
  } = useStudentViewContext();

  const badgeMap = BadgeMapping(badge);
  const isDev = process.env.REACT_APP_FEATURE_FLAG_ENV === 'dev';

  const csReps = assignedTo
    ? `${assignedTo.firstName} ${assignedTo.lastName}`
    : null;

  const handleFilterChange = (e: any): void => {
    const value = e.target.value;
    setCurrentFilter(value);
  };

  const filterExternships = (filterValue: ExternshipFilter): void => {
    const statuses = studentViewFilterMap[filterValue];
    const currentExternships = externships;
    const filteredExternships = allExternships
      .map((e) => {
        const currExternship = currentExternships.find(
          (ext) => ext.id === e.id
        );

        return currExternship || e;
      })
      .filter((e) => statuses.includes(e.status));

    setExternships(filteredExternships);
  };

  React.useEffect(() => {
    filterExternships(currentFilter);
  }, [currentFilter]);

  const filterOptions = [
    { label: 'All Sites', value: ExternshipFilter.ALL_STATUSES },
    {
      label: 'Proposed Sites',
      value: ExternshipFilter.PROPOSED_STATUSES
    },
    { label: 'Active Sites', value: ExternshipFilter.ACTIVE_STATUSES },
    {
      label: 'Completed Sites',
      value: ExternshipFilter.COMPLETED_STATUSES
    },
    { label: 'Archived Sites', value: ExternshipFilter.INACTIVE_STATUSES }
  ];

  return (
    <Styled.Root>
      <Box display={'flex'}>
        <ImageWithBadge
          src={photo ? photo.fullPath : defaultImage}
          badge={badgeMap?.[badge?.value]}
          height="100px"
          width="100px"
          backgroundColor={theme.palette.WHITE.main}
        />
        <Styled.Box>
          <Link
            component={RouterLink}
            to={isDev ? '/career-services/dashboard' : '/externship-tracker'}
            underline="none"
          >
            <Button
              variant="text"
              startIcon={<i className="ri-arrow-left-line" />}
              sx={{ padding: 0 }}
            >
              Dashboard
            </Button>
          </Link>
          <Typography
            variant="EC_TYPE_3XL"
            className="candidate-name"
            data-testid="student-name"
            mb={0}
          >
            {firstName} {lastName}
          </Typography>
          <Styled.Info>
            <Typography
              variant="EC_TYPE_2XS"
              className="candidate-email"
              data-testid="student-email"
            >
              <i className="ri-mail-fill" /> {email}
            </Typography>
            {!!phone && (
              <Typography variant="EC_TYPE_2XS" data-testid="student-phone">
                <i className="ri-phone-fill" /> {phone}
              </Typography>
            )}
            <Typography
              variant="EC_TYPE_2XS"
              data-testid="student-school-status"
            >
              STATUS: {schoolStatus}
            </Typography>
          </Styled.Info>
          {csReps && (
            <Box sx={{ display: 'flex', gap: '4px', marginTop: '4px' }}>
              <Typography variant="EC_TYPE_XS">CS REP:</Typography>
              <Typography variant="EC_TYPE_2XS">{csReps}</Typography>
            </Box>
          )}
        </Styled.Box>
      </Box>
      <Styled.SelectWrapper>
        <SimpleSelect
          fullWidth
          value={currentFilter}
          handleChange={handleFilterChange}
          options={filterOptions}
        />
      </Styled.SelectWrapper>
    </Styled.Root>
  );
};

export default StudentPageHeader;
