import * as React from 'react';
import { Box, Collapse, Grid, Typography } from '@mui/material';
import NoDataMessage from '@components/NoDataMessage';
import { ProfileLocation } from '@api/models/adminDashboardApi.models';
import StepSection from '@components/StepSection';
import { styled } from '@mui/system';

const Styled = {
  State: styled(Typography)({
    fontWeight: '700',
    ':hover': { cursor: 'pointer' }
  }),
  ExpandableDiv: styled(Box)({
    marginLeft: '24px'
  }),
  City: styled(Typography)({
    margin: '8px 0px'
  })
};

interface Props {
  locations: ProfileLocation[];
  gridOnly?: boolean;
}

const LocationsSection: React.FC<Props> = ({ locations, gridOnly }) => {
  const sortByProfileCount = (
    profileLocs: ProfileLocation[]
  ): ProfileLocation[] => profileLocs.sort((a, b) => b.profiles - a.profiles);

  const sortedLocations = sortByProfileCount(locations);

  const StateLocation: React.FC<{ location: ProfileLocation }> = ({
    location
  }) => {
    const [expandState, setExpandState] = React.useState(false);

    const { state, profiles, cities } = location;
    return (
      <Box>
        <Styled.State
          onClick={(): void => setExpandState(!expandState)}
          data-testid={`${state}-label`}
        >
          {expandState && '-'} {state} ({profiles})
        </Styled.State>
        <Collapse in={expandState} data-testid={`${state}-city-list`}>
          <Styled.ExpandableDiv>
            {cities.map((city) => (
              <Styled.City key={city.city}>
                {city.city} ({city.profiles})
              </Styled.City>
            ))}
          </Styled.ExpandableDiv>
        </Collapse>
      </Box>
    );
  };

  const LocationGrid: React.FC = () => {
    return (
      <Grid container spacing={1}>
        {sortedLocations.map((profileLoc) => {
          return (
            <Grid key={profileLoc.state} item xs={12} sm={6} md={4} lg={2}>
              <StateLocation location={profileLoc} />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  if (gridOnly) {
    return <LocationGrid />;
  }

  return (
    <StepSection title="Locations" dataTestId="locations-section" expandable>
      {locations.length ? (
        <>
          <Typography variant="EC_TYPE_LG">
            List of locations from all completed candidate profiles:
          </Typography>
          <LocationGrid />
        </>
      ) : (
        <NoDataMessage
          title="No profiles completed within the selected date range"
          dataTestId="no-locations-container"
        />
      )}
    </StepSection>
  );
};

export default LocationsSection;
