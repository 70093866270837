/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Box, Link, Paper, Stack, Typography } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { CourseData } from '@api/models/enrollmentApi.models';
import EcAccordion from '@components/EcAccordion';
import EcProgressBar from '@components/EcProgressBar';
import { ExternshipEntity } from '@api/models/externshipApi.models';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { getExternshipStatusLabel } from '@common/helpers/externshipHelpers/getExternshipStatusLabel';
import { getTimesheetStatusHours } from '@common/helpers/externshipHelpers/getTimesheetStatusHours';
import { styled } from '@mui/system';
import TimesheetAccordion from '@pages/Shared/TimesheetPage/TimesheetAccordion';
import { useKeycloakContext } from '@common/context/keycloakContext';

const Styled = {
  CardRoot: styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.WHITE.main,
    borderRadius: '4px',
    overflow: 'hidden',
    padding: '32px'
  })),
  ExternshipSection: styled(Box)(({ theme }) => ({
    '& > *:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.GRAY_F4.main}`
    }
  }))
};

export interface TimeCardCourseCardProps {
  course: CourseData;
  externships: ExternshipEntity[];
}

const ExternshipDetail = ({
  label,
  value
}: {
  label: string;
  value?: string;
}): React.ReactElement => {
  if (!value) {
    return <></>;
  }

  return (
    <Box>
      <Typography variant="EC_TYPE_XL" textTransform="uppercase">
        {label}
      </Typography>
      <Typography variant="EC_TYPE_BASE">{value}</Typography>
    </Box>
  );
};

const TimeCardExternship = (
  externship: ExternshipEntity
): React.ReactElement => {
  const [expanded, setExpanded] = React.useState<number | false>(false);

  const {
    company,
    proposedCompany,
    jobTitle,
    scheduledStartDate,
    status,
    weeklyTimeSheets
  } = externship;

  const location = company?.locations[0]?.name ?? proposedCompany.location.name;

  const externshipDetails = [
    { label: 'site', value: location },
    { label: 'position', value: jobTitle },
    {
      label: 'start date',
      value: formatDate(scheduledStartDate, 'MM/dd/yy', false, true)
    },
    { label: 'status', value: getExternshipStatusLabel(status) }
  ];

  const handleExpand =
    (id: number) =>
    (e, isExpanded: boolean): void => {
      setExpanded(isExpanded ? id : false);
    };

  return (
    <Box>
      <Stack direction="row" columnGap="40px" margin="30px 0">
        {externshipDetails.map((detail) => (
          <ExternshipDetail
            key={detail.label}
            label={detail.label}
            value={detail.value}
          />
        ))}
      </Stack>
      {!!weeklyTimeSheets.length && (
        <EcAccordion
          expanded={!!expanded}
          onChange={handleExpand(externship.id)}
          summaryComponent={
            <Typography variant="EC_TYPE_XL">TIME CARDS</Typography>
          }
          displayExpandIcon
        >
          <Box ml="24px">
            {weeklyTimeSheets.map((t) => (
              <TimesheetAccordion
                key={t.id}
                timesheet={t}
                updateTimesheets={(): void => undefined}
                isApproverViewing={false}
                isCandidateViewing={true}
                careerServiceView
              />
            ))}
          </Box>
        </EcAccordion>
      )}
    </Box>
  );
};

const TimeCardCourseCard: React.FC<TimeCardCourseCardProps> = ({
  course,
  externships
}) => {
  const { candidateId } = useParams<{ candidateId: string }>();
  const { isChefInstructor } = useKeycloakContext();
  const allTimesheets = externships.flatMap((e) => e.weeklyTimeSheets);

  const { approvedTime, approvedMinutes, pendingProgress } =
    getTimesheetStatusHours(allTimesheets, course.ClockHours);

  const percentComplete = 100 * (approvedMinutes / (course.ClockHours * 60));
  const progressSubtext = `${approvedTime} of ${course.ClockHours} complete`;
  const manageTimecardRoute = `/candidate/${candidateId}/course/${course.Id}/timesheets`;
  const courseHasExternshipWithTimesheets = externships.some(
    (e) => e.weeklyTimeSheets.length > 0
  );
  const showManageTimeCardsBtn =
    isChefInstructor && courseHasExternshipWithTimesheets;

  return (
    <Styled.CardRoot elevation={3}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="EC_TYPE_2XL">
          {course.CourseCode} {course.Name}
        </Typography>
        {showManageTimeCardsBtn && (
          <Link
            component={RouterLink}
            to={manageTimecardRoute}
            underline="none"
          >
            Manage Time Cards
          </Link>
        )}
      </Stack>
      <Box maxWidth="293px" mt="24px">
        <EcProgressBar
          percentComplete={percentComplete}
          pendingProgress={pendingProgress}
          subtext={progressSubtext}
        />
      </Box>
      <Styled.ExternshipSection>
        {externships.map((e) => (
          <TimeCardExternship key={e.id} {...e} />
        ))}
      </Styled.ExternshipSection>
    </Styled.CardRoot>
  );
};

export default TimeCardCourseCard;
