import * as React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import RootPageLayout from './Layout/RootPageLayout';

interface Props {
  text?: string;
}

const RedirectPage: React.FC<Props> = ({ text }) => {
  return (
    <RootPageLayout muiMaxWidth={false}>
      <Box textAlign={'center'} sx={{ margin: '80px 0px' }}>
        {text ? (
          <Typography variant="EC_TYPE_2XL">{text}</Typography>
        ) : (
          <CircularProgress size={80} />
        )}
      </Box>
    </RootPageLayout>
  );
};

export default RedirectPage;
