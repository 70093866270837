import { AttestationType } from '@interfaces/attestation.interface';

export const getSignatureText = (attestationType?: AttestationType): string => {
  switch (attestationType) {
    case AttestationType.FULL_TIME:
      return 'By signing below, I attest that I am satisfied with continuing employment in the position I have listed above, that I have worked for 30 days, and that I am making training related income.';
    case AttestationType.PART_TIME:
      return 'By signing below, I attest that I am satisfied with part-time or temporary employment in the position listed above, that I have worked for 30 days, and that I am making training related income.';
    case AttestationType.SELF_EMPLOYMENT:
      return 'By signing below, I attest that I am satisfied with self-employment in the position listed above, that I have worked for 30 days, and that I am making training related income.';
    default:
      return '';
  }
};
