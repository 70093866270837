import * as React from 'react';
import { Box, Container, Typography } from '@mui/material';
import backgroundLeft from '@assets/quiz-background-left.jpg';
import backgroundRight from '@assets/quiz-background-right.jpg';
import backgroundSmall from '@assets/quiz-background-small.jpg';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const Styled = {
  Root: styled(Container, {
    shouldForwardProp: (prop) => prop !== 'isSmall'
  })<{ isSmall: boolean }>(({ isSmall }) => ({
    position: 'relative',
    padding: '38px 0 75px 0',
    width: '100vw',
    maxWidth: '1820px',
    minHeight: '100%',
    overflow: 'hidden',
    '& .left-img': {
      display: isSmall ? 'none' : 'block',
      position: 'absolute',
      top: 75,
      left: 0,
      zIndex: -1
    },
    '& .right-img': {
      display: isSmall ? 'none' : 'block',
      position: 'absolute',
      top: 260,
      right: 0,
      zIndex: -1
    },
    '& .small-img': {
      display: isSmall ? 'block' : 'none',
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: -1
    }
  })),
  Content: styled(Box)({
    display: 'flex',
    margin: '42px auto',
    justifyContent: 'space-between',
    columnGap: '12px',
    maxWidth: '900px',
    minHeight: '540px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '87%',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      columnGap: 'unset',
      alignItems: 'center',
      margin: '42px auto'
    },
    [theme.breakpoints.up('lg')]: {
      transform: 'translateX(-12%)'
    }
  }),
  TitleBox: styled(Box)({
    flex: '0 3 260px',
    height: 'max-content',
    borderRadius: '4px',
    '& .headline': {
      color: theme.palette.GRAY_4.main,
      textTransform: 'uppercase',
      textShadow: '4px 4px 6px #FFF'
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '32px',
      flex: 'unset',
      '& .headline': {
        fontSize: '30px',
        lineHeight: '37px',
        letterSpacing: '0em',
        fontWeight: 700
      }
    }
  }),
  Loading: styled(Box)({
    display: 'flex',
    flex: 1,
    width: '100vw',
    minHeight: '516px',
    height: '50vh',
    alignItems: 'center',
    justifyContent: 'center'
  })
};

const images = [
  {
    src: backgroundLeft,
    alt: 'quiz-img-left',
    class: 'left-img'
  },
  {
    src: backgroundRight,
    alt: 'quiz-img-right',
    class: 'right-img'
  },
  {
    src: backgroundSmall,
    alt: 'quiz-img-small',
    class: 'small-img'
  }
];

interface QuizLayoutProps {
  description: string | undefined;
}

const QuizLayout: React.FC<QuizLayoutProps> = (props) => {
  const { description, children } = props;

  const { width } = useWindowDimensions();
  const isSmall = width < theme.breakpoints.values.sm;

  return (
    <Styled.Root
      data-testid="quiz-background-root"
      maxWidth={false}
      isSmall={isSmall}
    >
      {images.map((img) => (
        <Box
          key={img.alt}
          data-testid={`quiz-layout-img-${img.class}`}
          component="img"
          src={img.src}
          alt={img.alt}
          className={img.class}
        />
      ))}
      {description ? (
        <Styled.Content>
          <Styled.TitleBox>
            <Typography
              data-testid="quiz-description"
              variant="EC_TYPE_5XL"
              className="headline"
            >
              {description}
            </Typography>
          </Styled.TitleBox>
          {children}
        </Styled.Content>
      ) : (
        <Styled.Loading data-testid="quiz-loading-display">
          <Typography variant="EC_TYPE_2XL">Loading Quiz...</Typography>
        </Styled.Loading>
      )}
    </Styled.Root>
  );
};

export default QuizLayout;
