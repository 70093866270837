import * as React from 'react';
import {
  Button,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import { roles, SeatRoleSelectOption } from '@api/models/userApi.models';
import { CompanyApi } from '@api/Company.api';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { JoinRequest } from '@api/models/companyApi.models';
import { SeatRole } from '@pages/Shared/SettingsPage/CompanySettings/ManageSeatsTableRow';
import SimpleSelect from '@components/Forms/SimpleSelect';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';

const Styled = {
  TableBodyRow: styled(TableRow)({
    position: 'relative',
    '&:last-child td, &:last-child th': { border: 0 }
  }),
  Button: styled(Button)({
    padding: '8px',
    ...theme.typography.EC_TYPE_2XS
  }),
  Icon: styled(Icon)({
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.palette.GRAY_3.main
  })
};

interface Props {
  joinRequests: JoinRequest[];
  companyId: number;
  handleSeatRequestUpdate: () => void;
  glrcSeatsRemaining: () => number;
}

const PendingApprovalsTable: React.FC<Props> = ({
  joinRequests,
  companyId,
  handleSeatRequestUpdate,
  glrcSeatsRemaining
}) => {
  const featureFlags = useFeatureFlags();
  const [approved, setApproved] = React.useState({});
  const [selectedRoles, setSelectedRoles] = React.useState({});

  const handleApproval = (requestId: number): void => {
    const newApproved = { ...approved };
    newApproved[requestId] = true;
    setApproved(newApproved);
  };

  const handleReject = async (requestId: number): Promise<void> => {
    await CompanyApi.rejectJoinRequest(companyId, requestId);
    handleSeatRequestUpdate();
  };

  const handleCancelApproval = (requestId: number): void => {
    const newApproved = { ...approved };
    newApproved[requestId] = false;
    setApproved(newApproved);
  };

  const handleRoleChange = async (
    requestId: number,
    role: any
  ): Promise<void> => {
    const newSelectedRoles = { ...selectedRoles };
    newSelectedRoles[requestId] = role;
    setSelectedRoles(newSelectedRoles);

    let roleName: string = role;

    if (roleName === 'admin') {
      roleName = 'employer';
    }

    try {
      await CompanyApi.approveJoinRequest(companyId, requestId, {
        roles: roleName
      });
      handleSeatRequestUpdate();
    } catch (error) {
      console.error(
        'Error approving JoinRequest in PendingApprovalsTable.handleRoleChange()',
        error
      );
    }
  };

  const seatRoleOptions = (
    joinRequest: JoinRequest
  ): SeatRoleSelectOption<roles>[] => {
    return SeatRole.filter((r) => {
      // Externship Managers will only be assigned the admin role
      if (joinRequest.externship) {
        return r.value === roles.ADMIN;
      }

      if (!featureFlags.PARTNER_EXPERIENCE || glrcSeatsRemaining() <= 0) {
        return r.value !== roles.GLRC_USER;
      }
      return true;
    });
  };

  return (
    <TableContainer data-testid="seat-requests-table-container">
      <Table aria-label="seat-approvals-table">
        <TableHead>
          <TableRow>
            <TableCell>NAME</TableCell>
            <TableCell>EMAIL</TableCell>
            {featureFlags.EXTERNSHIPS && (
              <TableCell align="center">EXTENRSHIP</TableCell>
            )}
            <TableCell>SENT DATE</TableCell>
            <TableCell align="center" />
            <TableCell align="center" />
          </TableRow>
        </TableHead>
        <TableBody data-testid="seat-requests-table-body">
          {joinRequests.map((r) => (
            <Styled.TableBodyRow key={r.id}>
              <TableCell>
                {featureFlags.EXTERNSHIPS && r.externship && (
                  <Tooltip title="Externship Request">
                    <Styled.Icon
                      className="ri-graduation-cap-line"
                      sx={{ color: 'primary.main' }}
                    />
                  </Tooltip>
                )}{' '}
                {r.firstName} {r.lastName}
              </TableCell>
              <TableCell>{r.email}</TableCell>
              <TableCell align="center">{r.externship ? 'Y' : 'N'}</TableCell>
              <TableCell>{formatDate(r.submittedAt, 'M/dd/yyyy')}</TableCell>
              <TableCell align="center" sx={{ width: '100px' }}>
                {approved[r.id] ? (
                  <SimpleSelect
                    disabled={!!selectedRoles[r.id]}
                    placeholder="Select Role"
                    padding="8px"
                    value={selectedRoles[r.id] || ''}
                    handleChange={(e): Promise<void> =>
                      handleRoleChange(r.id, e.target.value)
                    }
                    options={seatRoleOptions(r)}
                    useOptionValueOnly
                  />
                ) : (
                  <Styled.Button
                    variant="text"
                    color={'primary'}
                    onClick={(): void => handleApproval(r.id)}
                    sx={{ color: theme.palette.primary.main }}
                  >
                    Approve
                  </Styled.Button>
                )}
              </TableCell>
              <TableCell align="center">
                {approved[r.id] ? (
                  <IconButton
                    data-testid={`cancel-seat-request-${r.id}`}
                    aria-label="delete"
                    onClick={(): void => handleCancelApproval(r.id)}
                  >
                    <Styled.Icon className="ri-close-fill" />
                  </IconButton>
                ) : (
                  <Styled.Button
                    variant="text"
                    color="BLACK"
                    onClick={(): Promise<void> => handleReject(r.id)}
                  >
                    Reject
                  </Styled.Button>
                )}
              </TableCell>
            </Styled.TableBodyRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PendingApprovalsTable;
