import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { Codebook } from '@api/models/codebook.models';
import { styled } from '@mui/system';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Tag } from '@api/models/common.models';
import theme from '@themes/ui/escoffier';

const Styled = {
  Title: styled(Typography)({
    marginBottom: '32px'
  }),
  TabList: styled(TabList)({
    width: '100%',
    maxWidth: '280px',
    '& .MuiTab-root': {
      whiteSpace: 'nowrap',
      marginLeft: 0,
      marginRight: 'auto',
      flexShrink: 1,
      columns: 3
    }
  }),
  TabContent: styled(Box)({
    fontSize: '15px',
    color: theme.palette.grey4.main,
    paddingTop: '30px',
    '& ul': {
      margin: '5px 0 30px 0'
    }
  }),
  TabLabel: styled(Tab)({
    color: theme.palette.GRAY_3.main,
    '&.Mui-selected': {
      color: theme.palette.GOLD_1.main
    }
  }),
  TabPanelContent: styled(TabPanel)({
    padding: 0
  })
};

interface TabContent {
  title: string;
  tabLabel: string;
  tabValue: string;
  text?: Tag[] | Codebook[];
}

interface TabsPanelsDataProps {
  tabContent: TabContent[];
}

const TabsPanels: React.FC<TabsPanelsDataProps> = (props): JSX.Element => {
  const [value, setValue] = React.useState('one');
  const { tabContent } = props;

  const handleChange = (event, newValue): void => {
    setValue(newValue);
  };

  return (
    <Box data-testid="tabsPanelsContainer" sx={{ width: '100%' }}>
      <Styled.Title variant="EC_TYPE_LG">Ideal Employer Match</Styled.Title>
      <TabContext value={value}>
        <Box>
          <Styled.TabList
            onChange={handleChange}
            data-testid="individualTab"
            aria-label="tab panels label"
          >
            {tabContent.map((item, index) => {
              return (
                <Styled.TabLabel
                  data-testid="tab-item-label"
                  key={index}
                  label={item.tabLabel}
                  value={item.tabValue}
                />
              );
            })}
          </Styled.TabList>
        </Box>
        {tabContent.map((item, index) => {
          return (
            <Styled.TabPanelContent key={index} value={item.tabValue}>
              <Styled.TabContent>
                <div>
                  <div>
                    <strong>{item.title}</strong>
                    <ul>
                      {item?.text?.map((item, index) => {
                        return <li key={index}>{item?.type || item?.label}</li>;
                      })}
                    </ul>
                  </div>
                </div>
              </Styled.TabContent>
            </Styled.TabPanelContent>
          );
        })}
      </TabContext>
    </Box>
  );
};

export default TabsPanels;
