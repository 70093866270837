import * as React from 'react';
import {
  CandidateAccount,
  CandidateAccountsReq
} from '@api/models/adminDashboardApi.models';
import { AdminDashboardApi } from '@api/AdminDashboard.api';
import { IPageInfoRes } from '@interfaces/pageInfo.interfaces';

export type FetchCandidateAccounts = {
  candidates: CandidateAccount[];
  pageInfo: IPageInfoRes | null;
  loaded: boolean;
};

export const useFetchCandidateAccounts = (
  req: CandidateAccountsReq,
  getCompletedProfiles: boolean
): FetchCandidateAccounts => {
  const [candidates, setCandidates] = React.useState<CandidateAccount[]>([]);
  const [pageInfo, setPageInfo] = React.useState<IPageInfoRes | null>(null);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const fetchCandidateAccounts = async (): Promise<void> => {
    try {
      const res = await AdminDashboardApi.getCandidates(
        req,
        getCompletedProfiles
      );

      setCandidates(res.data.candidates);
      setPageInfo(res.data.pageInfo);
      setLoaded(true);
    } catch (error: any) {
      console.error(
        'Error for useFetchCandidateAccounts -> AdminDashboardApi.getCandidates()',
        error
      );
    } finally {
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    fetchCandidateAccounts();
  }, [req, getCompletedProfiles]);

  return { candidates, pageInfo, loaded };
};
