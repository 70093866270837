import * as React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import {
  SessionStorageKeys,
  useStateWithSessionStorage
} from '@common/helpers/storage';
import BasicImageCardWithContent from '@components/BasicImageCardWithContent';
import { Partner } from '@api/models/partnerApi.models';
import { sortTableData } from '@common/helpers/sortByCategory';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useKeycloak } from '@react-keycloak/web';
import { useTableSortContext } from '@common/context/tableSortContext';

const Styled = {
  Root: styled(Box)({
    marginTop: '48px',
    maxWidth: '500px',
    margin: '48px auto'
  }),
  MainContent: styled(Box)({
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  }),
  CompanyName: styled(Typography)({
    color: theme.palette.GRAY_4.main,
    ':hover': {
      color: theme.palette.red2.main,
      transition: 'color 0.2s ease'
    }
  })
};

interface Props {
  partners: Partner[];
}

const PartnersTable: React.FC<Props> = ({ partners }) => {
  const { keycloak } = useKeycloak();

  const { setSessionValue } = useStateWithSessionStorage(
    SessionStorageKeys.GLRC_PARTNER_SIGNIN
  );

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const { sortBy, sortOrder, page, rowsPerPage } = useTableSortContext();

  const onPartnerClick = (idpAlias: string, partnerName: string) => {
    return (): void => {
      const sessionValue = JSON.stringify({
        partner_sign_in_name: partnerName
      });

      setSessionValue(sessionValue);

      const url = keycloak.createLoginUrl({
        idpHint: idpAlias,
        redirectUri: `${window.location.origin}/learning-center`
      });
      window.location.href = url;
    };
  };

  return (
    <Styled.Root>
      {sortTableData(partners, sortOrder, sortBy as keyof Partner)
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((partner, idx) => (
          <BasicImageCardWithContent
            key={partner.idpAlias + idx}
            onClick={onPartnerClick(partner.idpAlias, partner.name)}
            imageUrl={partner.logoUrl}
          >
            <Styled.MainContent>
              <Styled.CompanyName
                variant={isSmall ? 'EC_TYPE_XS' : 'EC_TYPE_XL'}
              >
                {partner.name}
              </Styled.CompanyName>
            </Styled.MainContent>
          </BasicImageCardWithContent>
        ))}
    </Styled.Root>
  );
};

export default PartnersTable;
