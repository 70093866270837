import * as React from 'react';
import RhfLocationSearch, {
  PlaceType
} from '@components/Forms/RhfLocationSearch';
import CandidateEscoffierAffiliationForm from '@pages/Public/CreateAccountPage/CandidateEscoffierAffiliationForm';
import { Codebook } from '@api/models/codebook.models';
import { GraduationTypes } from '@api/models/adminDashboardApi.models';
import { Grid } from '@mui/material';
import { IdentityProviders } from '@api/models/userApi.models';
import ReactHookFormsInputPhoneMask from '@components/Forms/ReactHookFormsInputPhoneMask';
import ReactHookFormsMultiSelect from '@components/Forms/ReactHookFormsMultiSelect';
import { useFetchPublicCodebooks } from '@common/fetches/useFetchPublicCodebooks';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';

const CandidateAboutMeSection: React.FC = () => {
  const [userIdpArray] = useStore<string[]>(UseStoreKeys.USER_IDP_ARRAY);
  const isStudentPortalIdp = userIdpArray?.includes(
    IdentityProviders.STUDENT_PORTAL
  );

  const { codebooks, loaded: publicCodebooksLoaded } =
    useFetchPublicCodebooks();

  const graduationTypes = codebooks?.graduationTypes?.filter((t) => {
    if (!isStudentPortalIdp) {
      return t.value !== GraduationTypes.EC_STUDENT;
    }
  });

  const isProd = process.env.REACT_APP_FEATURE_FLAG_ENV === 'prod';

  const _gradTypes = (): Codebook[] => {
    if (isProd) {
      return graduationTypes.filter(
        (t) => t.value !== GraduationTypes.EC_STUDENT
      );
    } else {
      return graduationTypes;
    }
  };

  return (
    <>
      {publicCodebooksLoaded && (
        <Grid container columnSpacing={3} rowSpacing={4} alignItems="flex-end">
          <CandidateEscoffierAffiliationForm
            disabled={isStudentPortalIdp}
            graduationTypes={_gradTypes()}
            isStudentSso={isStudentPortalIdp}
          />
          {!isStudentPortalIdp && (
            <Grid item xs={12} sm={12}>
              <ReactHookFormsMultiSelect
                name="interests"
                label="I want to..."
                options={codebooks.interests}
                rules={{ required: 'Please select at least one interest' }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <RhfLocationSearch
              name="locations"
              label="Your location"
              placeType={PlaceType.CITIES}
              rules={{ required: 'Location is required' }}
            />
          </Grid>
          <Grid item xs={12}>
            <ReactHookFormsInputPhoneMask name="phone" label="Phone number" />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CandidateAboutMeSection;
