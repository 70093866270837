import * as React from 'react';
import {
  ApplicationsByEmployer,
  DateRange
} from '@common/models/common.models';
import { Box, Collapse, Typography } from '@mui/material';
import ApplicantsModal from '@pages/EcAdmin/AdminDashboardPage/ApplicantsModal/ApplicantsModal';
import ApplicationStatusOverview from '@pages/EcAdmin/AdminDashboardPage/ApplicationStatusOverview';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import ColoredBox from '@components/ColoredBox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LabelWithValue from '@components/LabelWithValue';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useApplicationStatusData } from '@pages/EcAdmin/AdminDashboardPage/useApplicationStatusData';

const Styled = {
  Root: styled(Box)({ margin: '8px 0px' }),
  EmployerRow: styled(Box)({
    position: 'relative',
    padding: '8px',
    backgroundColor: theme.palette.gold1.main,
    borderRadius: '4px 4px 0px 0px',
    borderBottom: `1px solid ${theme.palette.GOLD_1.main}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.8'
    }
  }),
  Caret: styled(ExpandMoreIcon, {
    shouldForwardProp: (prop) => prop !== 'expanded'
  })<{ expanded: boolean }>(({ expanded }) => ({
    position: 'absolute',
    right: '8px',
    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.2s linear'
  })),
  ExpandableField: styled(Box)({
    padding: '40px',
    backgroundColor: theme.palette.grey1.main,
    border: `1px solid ${theme.palette.gold1.main}`,
    borderRadius: '0px 0px 4px 4px'
  })
};

interface Props {
  employerApps: ApplicationsByEmployer;
  chartLabel: string;
  dateRange?: DateRange;
  hideModals?: boolean;
  parentCompanyId?: number;
}

const ExpandableEmployerBreakdown: React.FC<Props> = ({
  employerApps,
  chartLabel,
  dateRange,
  hideModals,
  parentCompanyId
}) => {
  const [expand, setExpand] = React.useState<boolean>(false);

  const { perStatus, total, companyId } = employerApps;
  const { appStatusPieData, notHiredBarData, status, openModal, setOpenModal } =
    useApplicationStatusData(perStatus, total, hideModals);

  return (
    <Styled.Root>
      <Styled.EmployerRow
        onClick={(): void => setExpand(!expand)}
        data-testid={`${employerApps.employer}-breakdown-row`}
      >
        <Typography variant="EC_TYPE_LG">{employerApps.employer}</Typography>
        <Styled.Caret expanded={expand} />
      </Styled.EmployerRow>
      <Collapse in={expand}>
        <Styled.ExpandableField
          data-testid={`${employerApps.employer}-breakdown-chart`}
        >
          <ApplicationStatusOverview
            label={chartLabel}
            pieData={appStatusPieData.slice(1)}
            notHiredData={notHiredBarData}
            content={
              <BottomMarginContentWrapper bottomMargin="16px">
                {appStatusPieData.map((data) => (
                  <React.Fragment key={data.name}>
                    {!!data.value && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {data.fill && <ColoredBox color={data.fill} />}
                        <LabelWithValue
                          onClick={data.onClick}
                          label={data.name}
                          value={data.value}
                        />
                      </Box>
                    )}
                  </React.Fragment>
                ))}
              </BottomMarginContentWrapper>
            }
          />
        </Styled.ExpandableField>
      </Collapse>
      {openModal && dateRange && (
        <ApplicantsModal
          open={openModal}
          status={status}
          parentCompanyId={parentCompanyId}
          companyId={companyId}
          companyName={employerApps.employer}
          dateRange={dateRange}
          handleClose={(): void => setOpenModal(false)}
        />
      )}
    </Styled.Root>
  );
};

export default ExpandableEmployerBreakdown;
