import { useEffect, useState } from 'react';
import { AdminDashboardApi } from '@api/AdminDashboard.api';
import { CompanyStatus } from '@api/models/adminDashboardApi.models';

interface FetchCompanies {
  companies?: CompanyStatus[];
  loading: boolean;
  loaded: true | null;
}

export const useFetchCompaniesForEcAdmin = (): FetchCompanies => {
  const [companies, setCompanies] = useState<CompanyStatus[]>();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  const fetchCompanies = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await AdminDashboardApi.getCompanies();

      setCompanies(res);
    } catch (error) {
      console.error(`Error for useFetchCompaniesForEcAdmin: ${error}`);
    } finally {
      setLoaded(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  return { companies, loading, loaded };
};
