import * as React from 'react';
import { AdminContentManagementApi } from '@api/AdminContentManagement.api ';
import { HotJob } from '@api/models/adminContentManagementApi.models';

export type FetchAdminHotJobs = {
  hotJobs: HotJob[];
  setHotJobs: React.Dispatch<React.SetStateAction<HotJob[]>>;
  fetchHotJobs: () => Promise<void>;
  loaded: boolean;
  loading: boolean;
};

export const useFetchAdminHotJobs = (): FetchAdminHotJobs => {
  const [hotJobs, setHotJobs] = React.useState<HotJob[]>([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchHotJobs = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await AdminContentManagementApi.getHotJobs();

      setHotJobs(res);
      setLoaded(true);
    } catch (error: any) {
      console.error(
        'Error for useFetchAdminHotJobs -> AdminContentManagementApi.getHotJobs()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    fetchHotJobs();
  }, []);

  return { hotJobs, setHotJobs, fetchHotJobs, loaded, loading };
};
