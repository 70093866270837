import { CandidateResume, OverviewItem } from './resumePreviewModal.models';
import { ReactComponent as BriefcaseIcon } from '@assets/resumeIcons/ri-briefcase-fill.svg';
import { CandidateProfile } from '@api/models/candidateApi.models';
import { ReactComponent as LocationIcon } from '@assets/resumeIcons/ri-map-pin-2-fill.svg';
import { ReactComponent as MailIcon } from '@assets/resumeIcons/ri-mail-fill.svg';
import { ReactComponent as PhoneIcon } from '@assets/resumeIcons/ri-phone-fill.svg';
import { ReactComponent as StarIcon } from '@assets/resumeIcons/ri-star-half-line.svg';
import { ReactComponent as UserLocationIcon } from '@assets/resumeIcons/ri-map-pin-user-fill.svg';

/**
 * Returns a CandidateProfile with certain attributes restructured to work
 * with the CandidateResume structure.
 * @param candidateProfile CandidateProfile
 * @returns CandidateResume object
 */
export const getResumeProfile = (
  candidateProfile: CandidateProfile
): CandidateResume => {
  const {
    firstName,
    lastName,
    locations: _locations,
    relocation: _relocation,
    workAndLearn: _workAndLearn,
    employmentType: _employmentType,
    phone: _phone,
    email: _email,
    technicalSkills: _technicalSkills,
    softSkills: _softSkills,
    workExperience: _workExperience,
    education: _education,
    certificates: _certificates,
    candidateCulinaryPhotos: _photos,
    statement: _statement
  } = candidateProfile;

  const locations: OverviewItem = {
    label: _locations.map((loc) => loc.description).join(' / '),
    svgIcon: LocationIcon,
    hidden: undefined
  };
  const relocation: OverviewItem = {
    label: _relocation === 'YES' ? 'Open to Relocation' : null,
    svgIcon: UserLocationIcon,
    hidden: undefined
  };
  const workAndLearn: OverviewItem = {
    label: _workAndLearn !== 'NO' ? 'Open to Escoffier Culinary Program' : null,
    svgIcon: StarIcon,
    hidden: undefined
  };
  const employmentType: OverviewItem = {
    label: _employmentType.map((item) => item.type).join(', '),
    svgIcon: BriefcaseIcon,
    hidden: undefined
  };
  const phone: OverviewItem = {
    label: _phone,
    svgIcon: PhoneIcon,
    hidden: undefined
  };
  const email: OverviewItem = {
    label: _email,
    svgIcon: MailIcon,
    hidden: undefined
  };
  const technicalSkills = _technicalSkills.map((skill) => ({
    ...skill,
    hidden: undefined
  }));
  const softSkills = _softSkills.map((skill) => ({
    ...skill,
    hidden: undefined
  }));
  const _skills = [...technicalSkills, ...softSkills];

  const overviewItems = {
    props: [locations, relocation, workAndLearn, employmentType].filter(
      (prop) => !!prop.label
    ),
    hidden: false
  };

  const contactItems = {
    props: [phone, email],
    hidden: false
  };

  const overview = { overviewItems, contactItems };

  const skills = {
    props: _skills,
    hidden: false,
    order: 1
  };

  const workExperience = {
    props: _workExperience,
    hidden: false,
    order: 2
  };

  const education = {
    props: _education.map((i) => ({
      ...i,
      institution:
        i.institution ||
        `Auguste Escoffier School of Culinary Arts (${i.campus?.label})`
    })),
    hidden: false,
    order: 3
  };

  const certificates = {
    props: _certificates,
    hidden: false,
    order: 4
  };

  const candidateCulinaryPhotos = {
    props: _photos.map((photo) => photo.thumbnail ?? photo.file),
    hidden: false,
    order: 5
  };

  const statement = {
    props: [{ label: _statement, hidden: undefined }],
    hidden: false,
    order: 6
  };

  return {
    firstName,
    lastName,
    overview,
    skills,
    workExperience,
    education,
    certificates,
    candidateCulinaryPhotos,
    statement
  };
};
