/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  Box,
  ClickAwayListener,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { GRAY_1, WHITE } from '@themes/ui/escoffier';
import { keyframes, styled } from '@mui/system';
import ChefHat from '@assets/svg/chef_hat.round.gray1.svg';
import EllipseButton from '@components/EllipseButton';
import ImageWithBadge from '@components/ImageWithBadge';
import { Link as RouterLink } from 'react-router-dom';

const moveAnimation = keyframes`
  from {
    background-color: ${GRAY_1};
  }
  to {
    background-color: ${WHITE};
  }
`;

const Styled = {
  Root: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'moved'
  })<{ moved: boolean | null }>(({ moved, theme }) => ({
    position: 'relative',
    border: `1px solid ${theme.palette.OUTLINE.main}`,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px',
    columnGap: '16px',
    ':hover': {
      backgroundColor: theme.palette.GRAY_2.main,
      transition: 'background-color 0.5s ease'
    },
    ...(moved !== null && {
      animation: moved ? `${moveAnimation} 1.2s` : 'none',
      transition: 'background-color 0.5s ease'
    })
  })),
  LeftContent: styled(Box)({
    width: '100%',
    minWidth: '50px',
    display: 'flex',
    columnGap: '16px'
  }),
  MainContent: styled(Box)({
    flex: 1,
    minWidth: '50px',
    paddingRight: '8px',
    paddingBottom: '8px'
  }),
  Title: styled(Typography)(({ theme }) => ({
    minWidth: '50px',
    marginBottom: '8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ':hover': {
      color: theme.palette.primary.main,
      transition: 'color 0.2s ease'
    }
  })),
  Spacer: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'actionsLength'
  })<{ actionsLength: number }>(({ actionsLength }) => ({
    width: `${actionsLength * 28 - 8}px`,
    minWidth: `${actionsLength * 28 - 8}px`
  })),
  ImageWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'grayscale'
  })<{ grayscale: boolean }>(({ grayscale }) => ({
    img: {
      objectFit: 'scale-down',
      backgroundColor: 'white',
      ...(grayscale && {
        filter: 'grayscale(100%)',
        opacity: '0.4'
      })
    }
  })),
  TitleWrapper: styled(Box)({
    display: 'flex',
    justifyContent: 'space-between'
  }),
  ActionsWrapper: styled(Box)({
    position: 'absolute',
    top: 0,
    right: 0
  }),
  InactiveTag: styled('span')(({ theme }) => ({
    color: theme.palette.error.main,
    textTransform: 'uppercase'
  }))
};

export interface Action {
  // remixicon
  icon: string;
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

interface Props {
  id: number;
  name: string;
  image?: string;
  content: React.ReactNode;
  actions: Action[];
  large?: boolean;
  inactive?: boolean;
  useExpandableActionsMenu?: boolean;
  itemHasMoved?: boolean | null;
}

const CompanyCardWithActions: React.FC<Props> = ({
  id,
  name,
  image,
  content,
  actions,
  large = false,
  inactive = false,
  useExpandableActionsMenu = false,
  itemHasMoved = null
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const title = `${name} (id: ${id})`;

  const [showActions, setShowActions] = React.useState(false);

  const handleClickAway = (): void => {
    setShowActions(false);
  };

  const ActionIconButtons = (
    <>
      {actions.map(
        (action) =>
          !action?.disabled && (
            <IconButton
              size="small"
              key={action.label}
              aria-label={`${action.label} ${title}`}
              onClick={action.onClick}
              sx={{
                color: useExpandableActionsMenu
                  ? theme.palette.BLACK.main
                  : theme.palette.OUTLINE.main
              }}
            >
              <i className={action.icon} />
            </IconButton>
          )
      )}
    </>
  );

  const ActionsComponent = useExpandableActionsMenu ? (
    showActions ? (
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box width="120px">{ActionIconButtons}</Box>
      </ClickAwayListener>
    ) : (
      <Box sx={{ minWidth: '40px' }}>
        <EllipseButton
          iconClassName="ri-more-2-line"
          onClick={(): void => setShowActions(!showActions)}
        />
      </Box>
    )
  ) : (
    <Styled.ActionsWrapper>{ActionIconButtons}</Styled.ActionsWrapper>
  );

  return (
    <Styled.Root moved={itemHasMoved}>
      <Styled.LeftContent>
        <Styled.ImageWrapper grayscale={inactive}>
          <Link
            component={RouterLink}
            to={`/employer/${id}/profile`}
            underline="none"
          >
            <ImageWithBadge
              src={image || ChefHat}
              width={large ? (isSmall ? '134px' : '235px') : '134px'}
              height={large ? (isSmall ? '77px' : '135px') : '77px'}
            />
          </Link>
        </Styled.ImageWrapper>
        <Styled.MainContent>
          <Styled.TitleWrapper>
            <Link
              component={RouterLink}
              to={`/employer/${id}/profile`}
              underline="none"
              sx={{ minWidth: '50px' }}
            >
              <Styled.Title variant="EC_TYPE_XL">
                {title}{' '}
                {inactive && (
                  <>
                    - <Styled.InactiveTag>INACTIVE</Styled.InactiveTag>
                  </>
                )}
              </Styled.Title>
            </Link>
            {actions.length && <Styled.Spacer actionsLength={actions.length} />}
          </Styled.TitleWrapper>
          {content}
        </Styled.MainContent>
      </Styled.LeftContent>
      {ActionsComponent}
      {/* <Styled.ActionsWrapper>
        {actions.map((action) => (
          <IconButton
            size="small"
            key={action.label}
            aria-label={`${action.label} ${title}`}
            onClick={action.onClick}
            sx={{ color: theme.palette.OUTLINE.main }}
          >
            <i className={action.icon} />
          </IconButton>
        ))}
      </Styled.ActionsWrapper> */}
    </Styled.Root>
  );
};

export default CompanyCardWithActions;
