import * as React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import EcDialog, { EcDialogProps } from '@components/EcDialog';
import { FormProvider, useForm } from 'react-hook-form';
import AddVideosImg from '@assets/png/add-videos.png';
import { Codebook } from '@api/models/codebook.models';
import { getGlrcSectionsByTopic } from '@pages/Partner/GlrcContent/glrc.helpers';
import { GlrcVideo } from '@interfaces/glrc.interfaces';
import ReactHookFormsBasicComboBox from '@components/Forms/ReactHookFormsBasicComboBox';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import ReactHookFormsSelect from '@components/Forms/ReactHookFormsSelect';
import { styled } from '@mui/system';
import { useFetchVimeoDataFromUrl } from '@common/fetches/useFetchVimeoDataFromUrl';

const Styled = {
  Image: styled('img')({
    maxWidth: '179px'
  }),
  Divider: styled(Grid)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.GRAY_1.main}`
  })),
  ErrorText: styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
  }))
};

export interface GlrcVideoFormValues {
  videoUrl: string;
  topic: number;
  section: { label: string };
  title: string;
  thumbnail: string;
  lengthOfVideo: number | null;
}

interface Props extends EcDialogProps {
  video?: GlrcVideo;
  videoList?: GlrcVideo[];
  errorMsg?: string;
  setCustomError: (msg?: string) => void;
  glrcContentTopics: Codebook[];
  handleCreateOrEdit: (data: GlrcVideoFormValues) => Promise<void>;
}

const GlrcVideoForm: React.FC<Props> = ({
  video,
  videoList,
  errorMsg,
  setCustomError,
  glrcContentTopics,
  handleCreateOrEdit,
  ...dialogProps
}) => {
  const { handleClose, ...restDialogProps } = dialogProps;
  const [videoUrl, setVideoUrl] = React.useState<string>();

  const isEditing = dialogProps.title === 'Edit Content';

  const methods = useForm<GlrcVideoFormValues>({
    mode: 'onChange',
    defaultValues: {
      videoUrl: video?.url ?? '',
      topic: video?.topicCodebook.value ?? undefined,
      section: { label: video?.section ?? '' }
    }
  });

  const { handleSubmit, reset, watch } = methods;

  const watchTopic = watch('topic');
  const watchUrl = watch('videoUrl');
  const watchSection = watch('section');

  const {
    vimeoData,
    errorMsg: vimeoError,
    setVimeoData,
    setErrorMsg
  } = useFetchVimeoDataFromUrl(videoUrl, isEditing);

  const handleReset = (videoData?: GlrcVideo): void => {
    if (!videoData) {
      reset({
        videoUrl: '',
        topic: undefined,
        section: { label: '' }
      });
    } else {
      reset({
        videoUrl: videoData.url,
        topic: videoData.topicCodebook.value,
        section: { label: videoData.section }
      });
    }
  };

  const handleCloseModal = (): void => {
    setVimeoData(undefined);
    setVideoUrl(undefined);
    handleClose();
    handleReset();
  };

  const isValidUrl = (url: string): boolean => {
    // eslint-disable-next-line no-useless-escape
    const pattern = /^https?:\/\/([a-z0-9]+\.)*com([a-z0-9\.\/\?\=\&\%\-]*)$/i;
    return pattern.test(url);
  };

  const handleUrlBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const url = e.target.value;
    if (isValidUrl(url)) {
      setVideoUrl(url);
      if (errorMsg) {
        setCustomError(undefined);
      }
    } else {
      setErrorMsg('Invalid URL');
    }
  };

  const handleEnterPress = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    if (e.code === 'Enter') {
      setVideoUrl(watchUrl);
    }
  };

  React.useEffect(() => {
    if (!watchUrl) {
      setVimeoData(undefined);
    }
  }, [watchUrl]);

  React.useEffect(() => {
    handleReset(video);
  }, [video]);

  React.useEffect(() => {
    if (vimeoData) {
      const { title, thumbnail, lengthOfVideo } = vimeoData;
      reset({
        videoUrl: vimeoData.link,
        title,
        thumbnail,
        lengthOfVideo
      });
    }
  }, [vimeoData]);

  const sections = React.useMemo(() => {
    return videoList && !!watchTopic
      ? getGlrcSectionsByTopic(videoList, watchTopic)
      : [];
  }, [video, watchTopic]);

  const handleValidForm = React.useCallback(
    async (data: GlrcVideoFormValues): Promise<void> => {
      await handleCreateOrEdit(data);
    },
    [handleCreateOrEdit]
  );

  const handleFormSubmit = (): void => {
    const onValid = async (data: GlrcVideoFormValues): Promise<void> =>
      handleValidForm(data);

    handleSubmit(onValid)();
  };

  const thumbnailAlt = video?.title ? `${video.title} thumbnail` : 'Add Videos';
  const thumbnail = video?.imgUrl ?? vimeoData?.thumbnail ?? AddVideosImg;
  const videoTitle = video?.title ?? vimeoData?.title ?? '';

  const enableAddBtn = React.useMemo((): boolean => {
    const topicAndSection = Boolean(
      watchTopic && !!watchSection && watchSection.label !== ''
    );
    const error = Boolean(vimeoError || errorMsg);

    if (isEditing) {
      return Boolean(topicAndSection && !error);
    } else {
      return Boolean(vimeoData && topicAndSection && !error);
    }
  }, [watchTopic, watchSection, vimeoError, errorMsg, vimeoData, isEditing]);

  const hideTopic = !isEditing && !vimeoData;

  return (
    <EcDialog
      {...restDialogProps}
      handleConfirm={handleFormSubmit}
      handleClose={handleCloseModal}
      disableConfirmAction={!enableAddBtn}
    >
      <FormProvider {...methods}>
        <Grid container spacing={3} sx={{ marginTop: '32px' }} alignItems="end">
          <Grid item textAlign="center">
            <Styled.Image src={thumbnail} alt={thumbnailAlt} />
            <Typography>{videoTitle}</Typography>
          </Grid>
          <Styled.Divider item xs={12} />
          {errorMsg && (
            <Grid item xs={12}>
              <Styled.ErrorText variant="EC_TYPE_XL">
                {errorMsg}
              </Styled.ErrorText>
            </Grid>
          )}
          <Grid item xs={isEditing ? 12 : vimeoData ? 10 : 9}>
            <ReactHookFormsInput
              disabled={isEditing}
              name="videoUrl"
              label="Video URL"
              placeholder="Paste Video URL here"
              handleBlur={(e): void => handleUrlBlur(e)}
              handleKeyDown={(e): void => handleEnterPress(e)}
              rules={{
                required: 'Please enter a valid URL'
              }}
            />
            {vimeoError && (
              <Styled.ErrorText variant="EC_TYPE_2XS">
                {vimeoError}
              </Styled.ErrorText>
            )}
          </Grid>
          {!isEditing && (
            <Grid item xs={vimeoData ? 2 : 3}>
              <Button
                variant="contained"
                sx={{
                  whiteSpace: 'nowrap',
                  position: 'relative',
                  bottom: vimeoError ? 18 : 4
                }}
              >
                {vimeoData ? '\u2713' : 'Find Video'}
              </Button>
            </Grid>
          )}
          {glrcContentTopics && !hideTopic && (
            <Grid item xs={12}>
              <ReactHookFormsSelect
                name="topic"
                label="Topic"
                options={glrcContentTopics}
                rules={{
                  required: 'Please select a topic'
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <ReactHookFormsBasicComboBox
              name="section"
              label="Section"
              options={sections}
              placeholder="Select a section or enter a new one"
              rules={{ required: 'Please enter a section' }}
              freeSolo={true}
            />
          </Grid>
        </Grid>
      </FormProvider>
    </EcDialog>
  );
};

export default GlrcVideoForm;
