import * as React from 'react';
import PromoBox from '@components/PromoBox';
import quizPromoImage from '@assets/quiz-promo.jpg';
import { useTagManager } from '@common/hooks/useTagManager';

const QuizPromoBox = (): React.ReactElement => {
  const { sendToGtm } = useTagManager();

  const handleClick = (): void => {
    sendToGtm('navigation', {
      navigate_to: '/quiz/1',
      component: 'quiz_promo_box',
      link_text: 'Quiz CTA'
    });
  };

  return (
    <PromoBox
      title="What's your culinary personality?"
      subTitle="Do you have a passion for food but don't know how to build a
        fulfilling long-term career?"
      text="Answer some questions about your personality, any previous
        culinary experience, and what you look for in an employer to find
        the best career path for you and your ideal lifestyle."
      img={quizPromoImage}
      imgAlt="food on cutting board"
      cta={{ path: '/quiz/1', text: 'Take the Quiz', handleClick }}
    />
  );
};

export default QuizPromoBox;
