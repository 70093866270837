import * as React from 'react';
import {
  handleMoveDown,
  handleMoveUp
} from '@common/helpers/handleMoveUpOrDown';
import { Action } from '@pages/EcAdmin/ContentManagementPage/EmployerSpotlightContent/CompanyCardWithActions';
import { AdminContentManagementApi } from '@api/AdminContentManagement.api ';
import { AutoCompleteOption } from '@components/Forms/ReactHookFormsAutoComplete';
import PartnerSearchAndList from '@components/AdminContentManagement/PartnerSearchAndList';
import { useFetchAdminDashboardCompanyStatus } from '@common/fetches/useFetchAdminDashboardCompanyStatus';
import { useFetchWorkAndLearnPartners } from '@common/fetches/useFetchWorkAndLearnPartners';
import { WorkLearnPartnerEntity } from '@interfaces/workLearnPartner.interfaces';

const WorkAndLearnContent: React.FC = () => {
  const { workAndLearnPartners, setWorkAndLearnPartners, loading } =
    useFetchWorkAndLearnPartners();

  const { companies: allCompanies } = useFetchAdminDashboardCompanyStatus({
    active: true,
    workAndLearnPartner: true
  });

  const options = React.useMemo((): AutoCompleteOption[] => {
    const autocompleteOptions =
      allCompanies
        ?.sort((a, b) => a.companyName.localeCompare(b.companyName))
        .map((company) => ({
          label: `${company.id} - ${company.companyName}`,
          value: company.id,
          disable: workAndLearnPartners.some((p) => p.companyId === company.id)
        })) || [];

    return autocompleteOptions;
  }, [workAndLearnPartners, allCompanies]);

  const addPartner = async (searchValue: AutoCompleteOption): Promise<void> => {
    const workLearnPartners =
      await AdminContentManagementApi.createWorkLearnPartner(searchValue.value);

    setWorkAndLearnPartners(workLearnPartners);
  };

  const movePartnerUp = React.useCallback(
    (companyId: number) => {
      const currIdx = workAndLearnPartners.findIndex(
        (p) => p.companyId === companyId
      );

      const move = AdminContentManagementApi.moveWorkLearnPartnerUp;

      handleMoveUp(
        companyId,
        currIdx,
        workAndLearnPartners,
        setWorkAndLearnPartners,
        move
      );
    },
    [handleMoveUp, workAndLearnPartners]
  );

  const movePartnerDown = React.useCallback(
    (companyId: number) => {
      const currIdx = workAndLearnPartners.findIndex(
        (p) => p.companyId === companyId
      );
      const move = AdminContentManagementApi.moveWorkLearnPartnerDown;

      handleMoveDown(
        companyId,
        currIdx,
        workAndLearnPartners,
        setWorkAndLearnPartners,
        move
      );
    },
    [handleMoveDown, workAndLearnPartners]
  );

  const handleDelete = async (companyId: number): Promise<void> => {
    try {
      await AdminContentManagementApi.deleteWorkLearnPartner(companyId);
      const partners = workAndLearnPartners.filter(
        (p) => p.companyId !== companyId
      );
      setWorkAndLearnPartners(partners);
    } catch (error: any) {
      console.error(
        `Error for WorkAndLearnContent -> AdminContentManagementApi.deleteWorkLearnPartner(): ${error}`
      );
    }
  };

  const getActions = (
    partner: WorkLearnPartnerEntity,
    idx?: number
  ): Action[] => {
    return [
      {
        icon: 'ri-arrow-down-line',
        label: 'move down',
        onClick: (): void => movePartnerDown(partner.companyId),
        disabled: idx === workAndLearnPartners.length - 1
      },
      {
        icon: 'ri-arrow-up-line',
        label: 'move up',
        onClick: (): void => movePartnerUp(partner.companyId),
        disabled: idx === 0
      },
      {
        icon: 'ri-delete-bin-6-line',
        label: 'delete',
        onClick: (): Promise<void> => handleDelete(partner.companyId)
      }
    ];
  };

  const companyDetails = (
    workLearnPartner: WorkLearnPartnerEntity
  ): { icon: string; label: string }[] => {
    return [
      { icon: 'ri-account-circle-fill', label: workLearnPartner.email },
      {
        icon: 'ri-calendar-event-fill',
        label: `Joined: ${workLearnPartner.createdDate}`
      }
    ];
  };

  return (
    <PartnerSearchAndList
      pageTitle="Work & Learn Partners"
      pageDescription="Highlight employer partners on the Work and Learn page"
      searchProps={{
        id: 'work-learn-autocomplete',
        options,
        ctaText: 'Add Partner',
        onCtaClick: addPartner,
        requiredMsg: 'Please select a company',
        placeholder: 'Search for a company'
      }}
      loading={loading}
      getCardActions={getActions}
      getCompanyDetails={companyDetails}
      partners={workAndLearnPartners}
      useExpandableActionsMenu
    />
  );
};

export default WorkAndLearnContent;
