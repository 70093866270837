import * as React from 'react';
import {
  Box,
  Button,
  Card,
  Icon,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { BookmarkApi } from '@api/Bookmark.api';
import { convertMinutesToHoursAndMinutes } from '@common/helpers/timesheetHelpers/convertMinsToHoursAndMins';
import { GlrcContent } from '@interfaces/glrc.interfaces';
import { styled } from '@mui/system';
import { useCustomThemeContext } from '@common/context/customThemeContext';
import { useTagManager } from '@common/hooks/useTagManager';

const Styled = {
  Card: styled(Card)({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }),
  TopContent: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'color'
  })<{ color: string }>(({ color }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    paddingRight: '8px',
    backgroundColor: color
  })),
  Title: styled(Typography)(({ theme }) => ({
    color: theme.palette.GRAY_4.main,
    margin: 0
  })),
  Image: styled('img')({
    display: 'block',
    width: '100%',
    height: '160px',
    objectFit: 'cover',
    objectPosition: '0% 50%',
    '@media (min-width: 900px)': {
      height: '250px'
    },
    '@media (min-width:1200px)': {
      height: '300px'
    }
  }),
  Description: styled(Typography)({
    padding: '16px'
  }),
  BottomContent: styled(Box)({
    padding: '16px',
    display: 'flex',
    gap: '32px',
    justifyContent: 'space-between',
    alignItems: 'center',
    ['@media (min-width:1200px) and (max-width:1360px)']: {
      flexDirection: 'column'
    },
    ['@media (max-width:600px)']: {
      flexDirection: 'column'
    }
  }),
  LeftText: styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    flexWrap: 'wrap'
  })
};

interface Props extends GlrcContent {
  userId: string;
}

const FullCourseCard: React.FC<Props> = ({
  id,
  title,
  thumbnail,
  description,
  link,
  numberOfLessons,
  userId,
  bookmarked,
  lengthOfCourse
}) => {
  const { sendToGtm } = useTagManager();

  const [_bookmarked, setBookmarked] = React.useState(bookmarked);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const getAriaLabel = (): string => {
    return _bookmarked ? 'remove video from list' : 'add video to list';
  };

  const handleCourseClick = (): void => {
    sendToGtm('glrc_full_course_click', {
      glrc_course_id: id,
      glrc_course_title: title
    });
  };

  const image = thumbnail
    ? require(`@assets/LearningCenter/Courses/${thumbnail}`).default
    : '';

  const handleBookmarkClick = async (e): Promise<void> => {
    e.stopPropagation();
    try {
      if (_bookmarked) {
        await BookmarkApi.removeBookmark(userId, id);
        setBookmarked(false);
      } else {
        await BookmarkApi.addBookmark(userId, { contentId: id });
        setBookmarked(true);
      }
    } catch (error) {
      console.error('Error for FullCourseCard.handleBookmarkClick()', error);
    }
  };

  const customThemeContext = useCustomThemeContext();

  let cardColor = theme.palette.gold1.main;

  if (customThemeContext && customThemeContext.tileColor) {
    cardColor = customThemeContext.tileColor;
  }

  return (
    <Styled.Card>
      <Box>
        <Styled.TopContent color={cardColor}>
          <Styled.Title
            variant="EC_TYPE_3XL"
            data-testid="full-course-card-title"
          >
            {title}
          </Styled.Title>
          <IconButton
            onClick={handleBookmarkClick}
            aria-label={getAriaLabel()}
            sx={{ color: 'primary.main' }}
          >
            <i className={`ri-bookmark-${_bookmarked ? 'fill' : 'line'}`}></i>
          </IconButton>
        </Styled.TopContent>
        <Styled.Image
          src={image}
          alt={`${title} image`}
          data-testid="full-course-card-img"
        />
      </Box>
      <Styled.Description
        variant="EC_TYPE_BASE"
        data-testid="full-course-card-description"
      >
        {description}
      </Styled.Description>
      {lengthOfCourse && (
        <Typography variant="EC_TYPE_SM" sx={{ padding: '0px 16px 16px' }}>
          Length: {`${convertMinutesToHoursAndMinutes(lengthOfCourse, true)}`}
        </Typography>
      )}
      <Styled.BottomContent>
        <Styled.LeftText>
          <Icon className={'ri-stack-line'} sx={{ color: 'secondary.main' }} />
          <Typography
            variant="EC_TYPE_2XL"
            data-testid="full-course-card-lessons-count"
          >
            Course | {numberOfLessons} Lessons
          </Typography>
        </Styled.LeftText>
        <Button
          data-testid="full-course-card-course-btn"
          fullWidth={isSmall}
          variant="contained"
          target="_blank"
          rel="noopener noreferrer"
          component={Link}
          href={link}
          onClick={handleCourseClick}
          sx={{
            ['@media (min-width:1200px) and (max-width:1360px)']: {
              width: '100%'
            }
          }}
        >
          Launch Course
        </Button>
      </Styled.BottomContent>
    </Styled.Card>
  );
};

export default FullCourseCard;
