import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { GridType, GridViewEntity } from '@api/models/gridTableApi.models';
import BasicModal from '@components/BasicModal';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { GridTableApi } from '@api/GridTable.api';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import { Typography } from '@mui/material';
import { useGridViewContext } from '@common/context/gridVIewContext';

interface Props {
  open: boolean;
  handleClose: (e?) => void;
  viewToRename?: GridViewEntity;
  exportState: () => any;
  gridType: GridType;
}

interface FormValues {
  viewName: string;
}

const SaveGridViewModal: React.FC<Props> = ({
  open,
  handleClose,
  viewToRename,
  exportState,
  gridType
}) => {
  const { setGridViews } = useGridViewContext();

  const [isSaving, setIsSaving] = React.useState(false);

  const methods = useForm<FormValues>({
    defaultValues: {
      viewName: viewToRename?.name || ''
    }
  });

  const { handleSubmit, reset } = methods;

  React.useEffect(() => {
    if (viewToRename) {
      reset({ viewName: viewToRename.name });
    }
  }, [viewToRename]);

  const handleValidForm = async (data: FormValues): Promise<void> => {
    try {
      setIsSaving(true);
      if (viewToRename) {
        const allViews = await GridTableApi.renameGridView(
          viewToRename.id,
          data.viewName
        );
        reset({ viewName: '' });
        setGridViews(allViews);
      } else {
        const postBody = {
          gridState: exportState(),
          type: gridType,
          name: data.viewName
        };
        const allViews = await GridTableApi.save(postBody);
        reset({ viewName: '' });
        setGridViews(allViews);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
      handleClose();
    }
  };

  const handleSaveView = (e): void => {
    e.stopPropagation();
    handleSubmit(handleValidForm)();
  };

  return (
    <BasicModal
      open={open}
      handleClose={handleClose}
      title={viewToRename ? 'Rename View' : 'Save View'}
      primaryAction={{
        label: 'Save',
        action: (e): void => handleSaveView(e),
        disabled: isSaving
      }}
      secondaryAction={{ label: 'Cancel', action: handleClose }}
      maxWidth="sm"
    >
      <BottomMarginContentWrapper bottomMargin="24px">
        {!viewToRename && (
          <Typography variant="EC_TYPE_BASE">
            Give a name to the current layout for easy access to these columns
            and filters.
          </Typography>
        )}
        <FormProvider {...methods}>
          <ReactHookFormsInput
            name="viewName"
            label="View Name"
            labelVariant="EC_TYPE_XS"
            labelStyles={{ color: 'GRAY_4.main', textTransform: 'uppercase' }}
            rules={{ required: 'View name is required' }}
          />
        </FormProvider>
      </BottomMarginContentWrapper>
    </BasicModal>
  );
};

export default SaveGridViewModal;
