import * as React from 'react';
import {
  CandidateProfileContentId,
  CandidateProfileContentType
} from '@common/models/candidateProfile.models';
import {
  CandidateStatement,
  EducationListItem,
  IconLabelListItem,
  WorkExperienceListItem
} from '@pages/Candidate/CandidateProfileComponents/CandidateListItems';
import { Education, WorkExperience } from '@api/models/profileSetupApi.models';
import { Codebook } from '@api/models/codebook.models';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

interface Props<T extends CandidateProfileContentType> {
  contentType: CandidateProfileContentId;
  content: T;
  sectionTitle?: string;
}

// TODO: refactor from Candidate into Shared

const CandidateProfileListItem = <T extends CandidateProfileContentType>({
  content,
  contentType,
  sectionTitle
}: Props<T>): React.ReactElement => {
  const { width } = useWindowDimensions();
  const smallWindow = width < 600;

  switch (contentType) {
    case 'workExperience':
      return (
        <WorkExperienceListItem
          smallWindow={smallWindow}
          content={content as WorkExperience}
          sectionTitle={sectionTitle}
        />
      );
    case 'education':
      return (
        <EducationListItem
          smallWindow={smallWindow}
          content={content as Education<Codebook>}
          sectionTitle={sectionTitle}
        />
      );
    case 'codebook':
      return (
        <IconLabelListItem
          smallWindow={smallWindow}
          content={content as Codebook}
        />
      );
    case 'statement':
      return (
        <CandidateStatement
          smallWindow={smallWindow}
          content={content as string}
        />
      );
    default:
      return <></>;
  }
};

export default CandidateProfileListItem;
