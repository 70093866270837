import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { isVideoType, MediaType } from '@api/models/mediaApi.models';
import AdditionalImagesCard from '@components/AdditionalImagesCard';
import EcCarousel from '@components/EcCarousel';
import { styled } from '@mui/system';

const MAX_DISPLAYED_IMAGES = 7;

const Styled = {
  Root: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    marginTop: '50px'
  }),
  Gallery: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'fullWidth'
  })<{ fullWidth: boolean }>(({ fullWidth }) => ({
    display: 'grid',
    whiteSpace: 'nowrap',
    gridAutoFlow: 'column',
    gridAutoColumns: '131px',
    gap: '10px',
    flexWrap: 'nowrap',
    marginTop: '12px',
    paddingRight: '76px',
    width: fullWidth ? '100vw' : undefined,
    overflowY: 'hidden',
    overflowX: 'scroll',
    WebkitOverflowScrolling: 'touch'
  })),
  ImgDiv: styled(Box)({
    display: 'block',
    position: 'relative',
    cursor: 'pointer',
    borderRadius: '4px',
    overflow: 'hidden',
    boxSizing: 'border-box',
    '&:before': {
      content: '""',
      paddingTop: '100%',
      display: 'block'
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      zIndex: 2,
      top: 0,
      left: 0,
      height: '100%',
      width: '100vw',
      background:
        'linear-gradient(90deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 5%, rgba(0,0,0,0) 80%, rgba(0,0,0,0.9) 100%)',
      backgroundAttachment: 'fixed'
    },
    '& .gallery-img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: '50% 50%'
    }
  })
};

interface Props {
  media: MediaType[];
  sectionTitle?: string;
  maxDisplayedMedia?: number;
  fullWidthDisplay?: boolean;
}

const ProfileMediaGallery = (props: Props): React.ReactElement => {
  const {
    media,
    maxDisplayedMedia = MAX_DISPLAYED_IMAGES,
    sectionTitle,
    fullWidthDisplay = true
  } = props;
  const [currentMediaIdx, setCurrentMediaIdx] = React.useState<number | null>(
    null
  );
  const displayedMedia = React.useRef<MediaType[] | null>(null);
  const stashedMedia = React.useRef<MediaType[] | null>(null);

  if (media.length > maxDisplayedMedia) {
    displayedMedia.current = media.slice(0, maxDisplayedMedia - 1);
    stashedMedia.current = media.slice(maxDisplayedMedia - 1, media.length);
  } else {
    displayedMedia.current = media.slice(0, maxDisplayedMedia);
    stashedMedia.current = media.slice(maxDisplayedMedia, media.length);
  }

  const handleOpenCarousel = (index: number): void => {
    setCurrentMediaIdx(index);
  };

  const handleCloseCarousel = (): void => {
    setCurrentMediaIdx(null);
  };

  return (
    <>
      <Styled.Root data-testid="root-container">
        {sectionTitle && (
          <Typography variant="EC_TYPE_2XL" textTransform="uppercase">
            {sectionTitle}
          </Typography>
        )}
        <Styled.Gallery fullWidth={fullWidthDisplay}>
          {displayedMedia.current &&
            displayedMedia.current.map((item, idx) => {
              const src = isVideoType(item) ? item.thumbnailUrl : item.fullPath;
              const alt = isVideoType(item) ? item.title : item.originalname;
              return (
                <Styled.ImgDiv
                  key={idx}
                  onClick={(): void => handleOpenCarousel(idx)}
                  data-testid={`image-div-${idx}`}
                >
                  <Box
                    component="img"
                    src={src}
                    alt={`${alt} thumbnail`}
                    className="gallery-img"
                  />
                </Styled.ImgDiv>
              );
            })}
          {stashedMedia.current && stashedMedia.current.length > 0 && (
            <AdditionalImagesCard
              count={stashedMedia.current.length}
              maxDisplayCount={maxDisplayedMedia}
              handleOpen={handleOpenCarousel}
            />
          )}
        </Styled.Gallery>
      </Styled.Root>
      <EcCarousel
        media={media}
        selectedIdx={currentMediaIdx}
        handleClose={handleCloseCarousel}
      />
    </>
  );
};

export default ProfileMediaGallery;
