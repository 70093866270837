import { useEffect, useState } from 'react';
import { Codebook } from '@api/models/codebook.models';
import { CodebookApi } from '@api/Codebook.api';

export type FetchCandidateCodebooks = {
  certificates: Codebook[];
  // TODO: OBSOLETE - remove yearsOfExperience when possible
  yearsOfExperience: Codebook[];
  loading: boolean;
  loaded: boolean;
};

/**
 * Fetch all candidate codebooks from CodebookApi.getCandidateCodebooks()
 *
 * @returns `{ certificates: Codebook[]; yearsOfExperience: Codebook[]; loading: boolean; loaded: boolean }`
 */
export const useFetchCandidateCodebooks = (): FetchCandidateCodebooks => {
  const [certificates, setCertificates] = useState<Codebook[]>([]);
  // TODO: OBSOLETE - remove yearsOfExperience when possible
  const [yearsOfExperience, setYearsOfExperience] = useState<Codebook[]>([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        setLoading(true);
        const res = await CodebookApi.getCandidateCodebooks();

        setCertificates(res.data.certificates);
        setYearsOfExperience(res.data.yearsOfExperience);
        setLoaded(true);
      } catch (error: any) {
        console.error(
          'Error for useFetchCandidateCodebooks -> CodebookApi.getCandidateCodebooks()',
          error
        );
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    };

    fetchData();
  }, []);

  return { certificates, yearsOfExperience, loading, loaded };
};
