import { useEffect, useState } from 'react';
import { SearchApi } from '@api/Search.api';
import { SearchEsourceResult } from '@api/models/searchApi.models';

interface FetchEsourceSearch {
  searchContent: SearchEsourceResult | undefined;
  loading?: boolean;
  loaded?: true | null;
  fetch: () => {};
}

export const useFetchEsourceSearch = (
  searchValue: string,
  page: number,
  selectedTypes: string[],
  companyId: number
): FetchEsourceSearch => {
  const [searchContent, setSearchContent] = useState<SearchEsourceResult>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  const fetch = async (): Promise<void> => {
    try {
      setLoading(true);

      const res = await SearchApi.getEsourceContent(
        searchValue,
        page,
        selectedTypes,
        companyId
      );

      setSearchContent(res.data);
    } catch (error: any) {
      console.error(
        `Error for useFetchEsourceSearch -> SearchApi.getEsourceContent(): ${error}`
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return { searchContent, loading, loaded, fetch };
};
