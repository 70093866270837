import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CompanyApi } from '@api/Company.api';
import { Subsidiary } from '@api/models/companyApi.models';

type FetchSubsidiaries = {
  subsidiaries: Subsidiary[] | undefined;
  loading: boolean;
  loaded: boolean;
  fetchSubsidiaries: (companyId: number) => Promise<void>;
  setSubsidiaries: Dispatch<SetStateAction<Subsidiary[] | undefined>>;
};

export const useFetchSubsidiariesByCompanyId = (
  companyId?: number
): FetchSubsidiaries => {
  const [subsidiaries, setSubsidiaries] = useState<Subsidiary[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const fetchSubsidiaries = async (companyId: number): Promise<void> => {
    try {
      setLoading(true);
      const res = await CompanyApi.getSubsidiariesByCompanyId(companyId);

      setSubsidiaries(res.data);
      setLoaded(true);
    } catch (e) {
      console.error(
        'Error for useFetchSubsidiariesByCompanyId -> CompanyApi.getSubsidiaries()',
        e
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (companyId) {
      fetchSubsidiaries(companyId);
    }
  }, [companyId]);

  return { subsidiaries, loading, loaded, fetchSubsidiaries, setSubsidiaries };
};
