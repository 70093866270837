/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { AdminContentManagementApi } from '@api/AdminContentManagement.api ';
import { WorkLearnPartnerEntity } from '@interfaces/workLearnPartner.interfaces';

export type FetchWorkAndLearnPartners = {
  workAndLearnPartners: WorkLearnPartnerEntity[];
  setWorkAndLearnPartners: React.Dispatch<
    React.SetStateAction<WorkLearnPartnerEntity[]>
  >;
  fetchWorkAndLearnPartners: () => Promise<void>;
  loaded: boolean;
  loading: boolean;
};

export const useFetchWorkAndLearnPartners = (): FetchWorkAndLearnPartners => {
  const [workAndLearnPartners, setWorkAndLearnPartners] = React.useState<
    WorkLearnPartnerEntity[]
  >([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchWorkAndLearnPartners = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await AdminContentManagementApi.getWorkLearnPartners();

      setWorkAndLearnPartners(res);
      setLoaded(true);
    } catch (error: any) {
      console.error(
        'Error for useFetchWorkAndLearnPartners -> AdminContentManagementApi.getWorkLearnPartners()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    fetchWorkAndLearnPartners();
  }, []);

  return {
    workAndLearnPartners,
    setWorkAndLearnPartners,
    fetchWorkAndLearnPartners,
    loaded,
    loading
  };
};
