import * as React from 'react';
import {
  SessionStorageKeys,
  useStateWithSessionStorage
} from '@common/helpers/storage';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { glrcContentTypes } from '@pages/Partner/GlrcContent/glrcContentTypes';
import GlrcPageLayout from '@pages/Partner/GlrcPageLayout';
import GlrcTiles from '@pages/Partner/LearningCenterPage/GlrcTiles';
import MyBookmarksSection from '@pages/Partner/LearningCenterPage/MyBookmarksSection';
import { useCustomThemeContext } from '@common/context/customThemeContext';
import { useFetchCompanyProfilePublic } from '@common/fetches/useFetchCompanyProfilePublic';
import { useFetchGlrcTopics } from '@common/fetches/useFetchGlrcTopics';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';
import { useSetStoreValue } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import { useTagManager } from '@common/hooks/useTagManager';

const LearningCenterPage: React.FC = () => {
  const [companyName, setCompanyName] = React.useState<string>();
  const setCompanyLogo = useSetStoreValue<string | undefined>(
    UseStoreKeys.COMPANY_LOGO
  );
  const { companyId } = useParamsOrStoreCompanyId();

  const { companyPublicProfile, loaded: companyLoaded } =
    useFetchCompanyProfilePublic(companyId);

  const { glrcTopics, loaded: topicsLoaded } = useFetchGlrcTopics(companyId);

  const { keycloakUser } = useKeycloakContext();

  const { sendToGtm } = useTagManager();

  const { parsedSessionValue, removeSessionValue } =
    useStateWithSessionStorage<{
      partner_sign_in_name: string;
    }>(SessionStorageKeys.GLRC_PARTNER_SIGNIN);

  React.useEffect(() => {
    if (companyPublicProfile) {
      setCompanyName(companyPublicProfile.name);
      setCompanyLogo(companyPublicProfile.logo?.fullPath);

      sendToGtm('partner_sign_in', {
        partner_session_name: companyPublicProfile.name
      });
    }

    // TODO: Leaving this in for cases where a user navigates from /partnerships
    if (parsedSessionValue) {
      const sessionValue = { ...parsedSessionValue };
      sendToGtm('partner_sign_in', sessionValue);
      removeSessionValue();
    }
  }, [companyPublicProfile, parsedSessionValue]);

  const loaded = !!glrcTopics && !!topicsLoaded && !!companyLoaded;

  const customThemeContext = useCustomThemeContext();

  let headerText = 'ESource';

  if (customThemeContext) {
    if (customThemeContext.glrcHeaderText) {
      headerText = customThemeContext.glrcHeaderText;
    }
  }

  return (
    <GlrcPageLayout
      backBtn={false}
      heroTitle={headerText}
      contentLoaded={loaded}
    >
      {loaded && companyId && (
        <BottomMarginContentWrapper bottomMargin="70px">
          <MyBookmarksSection
            userId={keycloakUser.sub}
            companyId={companyId}
            companyName={companyName}
          />
          <GlrcTiles
            // If there are no topics then there isn't a content type that is accessible
            tileContents={!glrcTopics.length ? [] : glrcContentTypes}
            sectionTitle="Ways to Learn"
          />
          <GlrcTiles tileContents={glrcTopics} sectionTitle="Learn by Topic" />
        </BottomMarginContentWrapper>
      )}
    </GlrcPageLayout>
  );
};

export default LearningCenterPage;
