import { createCustomContext } from '@common/context/customContext';

export type SiteInfoEditableSections =
  | 'Position'
  | 'Approvers'
  | 'Company'
  | 'Externship';

interface SiteInfoContext {
  getIsEditing: (section: SiteInfoEditableSections) => boolean;
  editingSections: SiteInfoEditableSections[];
  setEditingSections: React.Dispatch<
    React.SetStateAction<SiteInfoEditableSections[]> // eslint-disable-line @typescript-eslint/indent
  >;
}

export const [useStudentViewSiteInfoContext, StudentViewSiteInfoProvider] =
  createCustomContext<SiteInfoContext>();
