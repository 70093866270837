import * as React from 'react';
import { Box, Skeleton } from '@mui/material';
import {
  redirectStorageKeys,
  redirectStorageMessages
} from '@common/helpers/storage';
import IncompleteProfileModal from '@components/PreviewPageComponents/IncompleteProfileModal';
import LayoutRightSidebar from '@components/Layout/LayoutRightSidebar';
import { roles } from '@api/models/userApi.models';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

const PrimaryContent: React.FC = () => {
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'right' }}>
        <Skeleton variant="rectangular" height={51} width={161} />
      </Box>
      <Skeleton
        height="50px"
        variant="rectangular"
        sx={{
          marginBottom: '20px',
          marginTop: '35px'
        }}
      />
      <Skeleton variant="rectangular" height="60vh" />
    </Box>
  );
};

const SecondaryContent: React.FC = () => {
  return (
    <Box>
      <Box sx={{ display: 'flex' }}>
        <Skeleton
          variant="rectangular"
          width={136}
          height={136}
          sx={{ marginBottom: '16px', marginRight: '16px' }}
        />
        <Skeleton variant="rectangular" width={150} height={45} />
      </Box>
      <Skeleton
        variant="rectangular"
        height="21vh"
        sx={{ marginBottom: '16px' }}
      />
      <Skeleton variant="rectangular" height="38vh" />
    </Box>
  );
};

interface Props {
  modalOpen?: boolean;
}

const JobPostSkeleton: React.FC<Props> = ({ modalOpen = false }) => {
  const { keycloak } = useKeycloak();
  const history = useHistory();

  const closeSigninModal = (): void => {
    history.goBack();
  };

  const loginFromModal = React.useCallback(() => {
    localStorage.removeItem(redirectStorageKeys.JOB_APPLICATION);

    const redirect = window.location.href;
    keycloak.login({ redirectUri: redirect });
  }, [keycloak]);

  const unauthenticatedModalProps = {
    title: 'Have you joined Escoffier Connect?',
    subtitle:
      'Sign in or create an account to access this opportunity on EConnect.',
    ignoreIncompleteProfile: true,
    localStorageMessage: redirectStorageMessages.NO_USER,
    localStorageKey: redirectStorageKeys.JOB_APPLICATION,
    primaryButtonText: 'Create Account',
    secondaryButtonText: 'Sign In',
    primaryClickLocation: `/create-account?type=${roles.CANDIDATE}`,
    secondaryClick: loginFromModal
  };

  return (
    <>
      <LayoutRightSidebar
        primaryContent={<PrimaryContent />}
        secondaryContent={<SecondaryContent />}
      />
      <IncompleteProfileModal
        open={modalOpen}
        handleClose={closeSigninModal}
        {...unauthenticatedModalProps}
      />
    </>
  );
};

export default JobPostSkeleton;
