import * as React from 'react';
import {
  Icon,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow
} from '@mui/material';
import { roles, SeatRoleSelectOption } from '@api/models/userApi.models';
import { Seat } from '@api/models/settingsApi.models';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';

const Styled = {
  TableBodyRow: styled(TableRow)({
    '&:last-child td, &:last-child th': { border: 0 }
  })
};

export const SeatRole: SeatRoleSelectOption<roles>[] = [
  {
    label: roles.EMPLOYER,
    value: roles.ADMIN // Using admin for display purposes
  },
  {
    label: roles.RECRUITER,
    value: roles.RECRUITER
  },
  {
    label: roles.GLRC_USER,
    value: roles.GLRC_USER
  }
];

interface Props {
  seat: Seat;
  idx: number;
  handleChange: (
    e: SelectChangeEvent<any>,
    employerId: number,
    idx: number,
    isLastAdmin: boolean
  ) => void;
  handleDelete: (employerId: number, idx: number) => void;
  isLastAdmin: boolean;
}

const ManageSeatsTableRow: React.FC<Props> = ({
  seat,
  idx,
  handleChange,
  handleDelete,
  isLastAdmin
}) => {
  const isSelf: boolean = seat.isSelf;
  const [permissionValue, setPermissionValue] = React.useState<string>();

  const featureFlags = useFeatureFlags();

  React.useEffect(() => {
    seat.role.map((item) => {
      const data = SeatRole?.find((role) => {
        if (role.label === item.name.substring(4)) {
          setPermissionValue(role.value);
        }
      });
      return data;
    });
  }, [seat]);

  const DeleteIcon = (): JSX.Element => (
    <IconButton
      data-testid={`delete-seat-icon-${idx}`}
      aria-label="delete"
      onClick={(): void => handleDelete(seat.employerId, idx)}
    >
      <Icon
        className="ri-delete-bin-5-fill"
        sx={{
          fontSize: '12px',
          lineHeight: '15px',
          color: theme.palette.GRAY_3.main
        }}
      ></Icon>
    </IconButton>
  );

  return (
    <Styled.TableBodyRow data-testid={`seat-row-${idx}`}>
      <TableCell component="th" scope="row">
        {`${seat.name}${isSelf ? ' (You)' : ''}`}
      </TableCell>
      <TableCell>{seat.email}</TableCell>
      <TableCell>
        {isSelf ? (
          seat.role.map(
            (item) =>
              ' ' + (item.name.substring(4) === 'employer' ? 'admin' : '')
          )
        ) : (
          <Select
            inputProps={{
              'data-testid': `role-select-${idx}`
            }}
            value={permissionValue ?? ''}
            fullWidth
            sx={{
              width: '100px',
              '.MuiSelect-select': {
                padding: '0px'
              },
              typography: 'EC_TYPE_2XS',
              fieldset: {
                border: 'none'
              }
            }}
            onChange={(e: SelectChangeEvent<any>): void =>
              handleChange(e, seat.employerId, idx, isLastAdmin)
            }
          >
            {SeatRole?.map(({ value }) => {
              const displayVal =
                value === roles.GLRC_USER ? roles.ESOURCE_USER : value;
              return (
                <MenuItem
                  key={`${value}`}
                  value={value}
                  disabled={
                    !featureFlags.PARTNER_EXPERIENCE &&
                    value === roles.GLRC_USER
                  }
                  data-testid={`role-${value}`}
                >
                  {displayVal}
                </MenuItem>
              );
            })}
            ;
          </Select>
        )}
      </TableCell>
      <TableCell align="center">{!isSelf && <DeleteIcon />}</TableCell>
    </Styled.TableBodyRow>
  );
};

export default ManageSeatsTableRow;
