import * as React from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import SelectionBox from '@components/SelectionBox';
import { styled } from '@mui/system';

const Styled = {
  SelectionBoxLabel: styled(Box)((props: { error: boolean }) => ({
    fontSize: '15px',
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '19px',
    color: props.error ? '#BB1133' : '#5C6B80', // Red-2 : Gray-3
    marginBottom: '24px'
  }))
};

interface Props {
  name: string;
  options: any[];
  rules?: RegisterOptions;
}

const ReactHookFormsBoxSelection: React.FC<Props> = ({
  name,
  options,
  rules
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    control,
    formState: { errors }
  } = useFormContext();

  const handleSelection = (
    selection: string,
    currValue: string[],
    onChange
  ): void => {
    let newTags: string[];

    if (currValue.includes(selection)) {
      newTags = currValue.filter((s) => s !== selection);
    } else {
      newTags = [...currValue, selection];
    }
    onChange(newTags);
  };

  return (
    <Box id={name}>
      <Typography variant="EC_TYPE_3XL" mb={2}>
        I want to:
      </Typography>
      <Styled.SelectionBoxLabel
        error={errors[name]}
        data-testid={`${name}-label`}
      >
        (Select one or more)
      </Styled.SelectionBoxLabel>
      <Controller
        control={control}
        name={name}
        rules={{ ...rules }}
        render={({ field: { value, onChange } }): JSX.Element => {
          return (
            <Grid container spacing={isSmall ? 1 : 2}>
              {options.map((option, idx) => (
                <Grid item key={option.label} xs={4}>
                  <SelectionBox
                    option={option.label}
                    icon={option.icon}
                    selected={value && value.includes(option.label)}
                    handleSelection={(): void =>
                      handleSelection(option.label, value, onChange)
                    }
                    dataTestId={`${idx}-${name}-box`}
                  />
                </Grid>
              ))}
            </Grid>
          );
        }}
      />
    </Box>
  );
};

export default ReactHookFormsBoxSelection;
