import * as React from 'react';
import { getDateMonthAndYear } from '@common/helpers/dateHelpers/getDateMonthAndYear';
import ResumeSection from './ResumeSection';
import theme from '@themes/ui/escoffier';
import { WorkExperience } from '@api/models/profileSetupApi.models';

const styles = {
  companyName: {
    ...theme.typography.EC_TYPE_SM,
    textTransform: 'uppercase' as 'uppercase'
  },
  positionWrapper: {
    marginLeft: '16px'
  },
  positionDatesSpan: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  position: {
    ...theme.typography.EC_TYPE_XS,
    color: theme.palette.GRAY_4.main
  },
  employmentDates: {
    ...theme.typography.EC_TYPE_XS,
    color: theme.palette.GRAY_4.main,
    marginRight: '0.5em'
  },
  responsibilities: {
    ...theme.typography.EC_TYPE_3XS,
    color: theme.palette.GRAY_4.main,
    whiteSpace: 'pre-wrap' as 'pre-wrap'
  }
};

interface Props {
  workExperience: WorkExperience[];
}

const ResumeExperienceSection: React.FC<Props> = ({ workExperience }) => {
  const getBottomMargin = (idx: number): string => {
    const isLastIdx = workExperience.length - 1 === idx;
    return isLastIdx ? '0px' : '16px';
  };

  if (!workExperience.length) {
    return <></>;
  }

  return (
    <ResumeSection title="Work Experience" indent>
      {workExperience.map((exp, idx) => {
        const hidden = exp['hidden'] === true;
        const dateRange = `${getDateMonthAndYear(
          exp.startDate
        )} - ${getDateMonthAndYear(exp.endDate)}`;

        if (!hidden) {
          return (
            <div key={exp.id} style={{ marginBottom: getBottomMargin(idx) }}>
              <div
                style={styles.companyName}
                data-testid="resume-experience-section-company-name"
              >
                {exp.company}
              </div>
              <div style={styles.positionWrapper}>
                <span style={styles.positionDatesSpan}>
                  <div
                    style={styles.position}
                    data-testid="resume-experience-section-position"
                  >
                    {exp.position.label}
                  </div>
                  <div style={styles.employmentDates}>({dateRange})</div>
                </span>
                {exp.responsibilities && (
                  <div
                    style={styles.responsibilities}
                    data-testid="resume-experience-section-responsibilities"
                  >
                    {exp.responsibilities}
                  </div>
                )}
              </div>
            </div>
          );
        }
      })}
    </ResumeSection>
  );
};

export default ResumeExperienceSection;
