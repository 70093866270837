import {
  AdminDashJobPost,
  AdminDashJobPostsReq
} from '@api/models/adminDashboardApi.models';
import { useEffect, useState } from 'react';
import { AdminDashboardApi } from '@api/AdminDashboard.api';
import { IPageInfoRes } from '@interfaces/pageInfo.interfaces';

interface FetchJobPostsForAdmin {
  jobPosts: AdminDashJobPost[] | null;
  pageInfo: IPageInfoRes | null;
  loading: boolean;
  loaded: true | null;
}

export const useFetchJobPostsForAdminDash = (
  req: AdminDashJobPostsReq,
  companyId?: number
): FetchJobPostsForAdmin => {
  const [jobPosts, setJobPosts] = useState<AdminDashJobPost[] | null>(null);
  const [pageInfo, setPageInfo] = useState<IPageInfoRes | null>(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  const fetchJobPosts = async (companyId: number): Promise<void> => {
    try {
      setLoading(true);

      const res = await AdminDashboardApi.getJobPostsByCompanyId(
        companyId,
        req
      );

      setJobPosts(res.data.jobPosts);
      setPageInfo(res.data.pageInfo);
    } catch (error: any) {
      console.error(
        'Error for useFetchJobPostsForAdminDash -> AdminDashboardApi.getJobPostsByCompanyId()',
        error
      );
    } finally {
      setLoading(false);
      setLoading(true);
    }
  };

  useEffect(() => {
    if (companyId) {
      fetchJobPosts(companyId);
    } else {
      setLoaded(true);
    }
  }, [req, companyId]);

  return { jobPosts, pageInfo, loading, loaded };
};
