import * as React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';

const Styled = {
  TitleWrapper: styled(Box)({
    marginBottom: '30px',
    display: 'flex',
    justifyContent: 'space-between'
  }),
  Title: styled(Typography)(({ theme }) => ({
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    ['@media (max-width:600px)']: {
      margin: '0px 24px 24px'
    }
  }))
};

interface Props {
  title: string;
  border?: boolean;
  action?: JSX.Element;
}

const GlrcSection: React.FC<Props> = ({
  title,
  border = false,
  children,
  action
}) => {
  const theme = useTheme();

  return (
    <Box>
      <Styled.TitleWrapper
        sx={{
          borderBottom: border
            ? `1px solid ${theme.palette.BLACK.main}`
            : 'none'
        }}
      >
        <Styled.Title variant="EC_TYPE_3XL">{title}</Styled.Title>
        {action}
      </Styled.TitleWrapper>
      <Box>{children}</Box>
    </Box>
  );
};

export default GlrcSection;
