import { useEffect, useState } from 'react';
import { ExternshipAgreementData } from '@api/models/externshipApi.models';
import { ExternshipApi } from '@api/Externships.api';

interface FetchExternshipAgreementData {
  externshipAgreement?: ExternshipAgreementData;
  loading: boolean;
}

export const useFetchExternshipAgreementData = (
  externshipId: number
): FetchExternshipAgreementData => {
  const [externshipAgreement, setExternshipAgreement] =
    useState<ExternshipAgreementData>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        setLoading(true);

        const res = await ExternshipApi.getExternshipAgreementData(
          externshipId
        );
        setExternshipAgreement(res.data);
      } catch (error: any) {
        console.error(
          'Error for useFetchExternshipAgreementData -> ExternshipApi.getExternshipAgreementData',
          error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { externshipAgreement, loading };
};
