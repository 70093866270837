/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { ESourceGroupWithAccessDto } from '@interfaces/glrc.interfaces';
import { GlrcContentApi } from '@api/GlrcContent.api';

export type FetchESourceGroups = {
  esourceGroups: ESourceGroupWithAccessDto[];
  setESourceGroups: React.Dispatch<
    React.SetStateAction<ESourceGroupWithAccessDto[]>
  >;
  loaded: boolean;
  loading: boolean;
};

export const useFetchESourceGroups = (
  companyId: number
): FetchESourceGroups => {
  const [esourceGroups, setESourceGroups] = React.useState<
    ESourceGroupWithAccessDto[]
  >([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchESourceGroups = async (): Promise<void> => {
    if (!companyId) {
      return;
    }

    try {
      setLoading(true);
      const res = await GlrcContentApi.getESourceGroupsByCompany(companyId);

      setESourceGroups(res);
      setLoaded(true);
    } catch (error: any) {
      console.error(
        'Error for useFetchESourceGroups -> GlrcContentApi.getESourceGroupsByCompany()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    fetchESourceGroups();
  }, []);

  return {
    esourceGroups,
    setESourceGroups,
    loaded,
    loading
  };
};
