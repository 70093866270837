import * as React from 'react';
import { Codebook } from '@api/models/codebook.models';
import { Education } from '@api/models/profileSetupApi.models';
import ResumeSection from './ResumeSection';
import theme from '@themes/ui/escoffier';

const styles = {
  gradYear: {
    ...theme.typography.EC_TYPE_XS,
    color: theme.palette.GRAY_4.main,
    marginRight: '8px'
  },
  institution: {
    ...theme.typography.EC_TYPE_2XS
  },
  educationType: {
    fontWeight: '400'
  },
  areaOfFocus: {
    display: 'list-item',
    marginLeft: '32px',
    ...theme.typography.EC_TYPE_3XS
  }
};

interface Props {
  education: Education<Codebook>[];
}

const ResumeEducationSection: React.FC<Props> = ({ education }) => {
  if (!education.length) {
    return <></>;
  }

  return (
    <ResumeSection title="Education" indent>
      {education.map((e, idx) => {
        const hidden = e['hidden'] === true;

        if (!hidden) {
          return (
            <div
              key={e.id}
              style={{
                display: 'flex',
                marginBottom: education.length - 1 === idx ? '0px' : '8px'
              }}
            >
              <div
                style={styles.gradYear}
                data-testid="resume-education-section-graduationYear"
              >
                {e.graduationYear}
              </div>
              <div>
                <div
                  style={styles.institution}
                  data-testid="resume-education-section-institution-educationType"
                >
                  {e.institution ||
                    `Auguste Escoffier School of Culinary Arts (${e.campus?.label})`}{' '}
                  <span style={styles.educationType}>- {e.educationType}</span>
                </div>
                {(e.areaOfFocus || e.program) && (
                  <div
                    style={styles.areaOfFocus}
                    data-testid="resume-education-section-areaOfFocus"
                  >
                    {e.areaOfFocus || e.program?.label}
                  </div>
                )}
              </div>
            </div>
          );
        }
      })}
    </ResumeSection>
  );
};

export default ResumeEducationSection;
