import { GlrcContent, GlrcVideo } from '@interfaces/glrc.interfaces';
import { AdminContentManagementApi } from '@api/AdminContentManagement.api ';
import { convertGlrcContentToGlrcVideo } from '@pages/Partner/GlrcContent/glrc.helpers';

interface FetchGlrcContentCsv {
  fetchGlrcContentCsv: () => Promise<GlrcVideo[]>;
}

export const useFetchGlrcContentCsv = (): FetchGlrcContentCsv => {
  const convertToGlrcVideo = (content: GlrcContent[]): GlrcVideo[] => {
    const data: GlrcVideo[] = [];

    content.forEach((item) => {
      data.push(convertGlrcContentToGlrcVideo(item));
    });

    return data.sort((a, b) => a.topicLabel.localeCompare(b.topicLabel));
  };

  const fetchGlrcContentCsv = async (): Promise<GlrcVideo[]> => {
    let data: GlrcVideo[] = [];
    try {
      const res = await AdminContentManagementApi.getVideosForEcAdmin();

      data = convertToGlrcVideo(res);

      return data;
    } catch (error: any) {
      console.error(
        'Error for useFetchGlrcContentCsv -> GlrcContentApi.getVideosForEcAdmin()',
        error
      );
    }

    return data;
  };

  return { fetchGlrcContentCsv };
};
