import { useEffect, useState } from 'react';
import { PartnerApi } from '@api/Partner.api';
import { PartnerEmployeeCareerInfo } from '@interfaces/careerJourney.interfaces';

interface FetchPartnerEmployeeCareerInfo {
  partnerEmployeeCareerInfo: PartnerEmployeeCareerInfo | undefined;
  fetchCareerInfo: () => Promise<void>;
  loading: boolean;
  loaded: true | null;
}

export const useFetchPartnerEmployeeCareerInfo = (
  partnerEmployeeId: number
): FetchPartnerEmployeeCareerInfo => {
  const [partnerEmployeeCareerInfo, setPartnerEmployeeCareerInfo] =
    useState<PartnerEmployeeCareerInfo>();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  const fetchCareerInfo = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await PartnerApi.getCareerInfo(partnerEmployeeId);
      setPartnerEmployeeCareerInfo(res.data);
    } catch (error: any) {
      console.error(
        'Error for useFetchPartnerEmployeeCareerInfo -> PartnerApi.getCareerInfo()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  useEffect(() => {
    fetchCareerInfo();
  }, []);

  return { partnerEmployeeCareerInfo, fetchCareerInfo, loading, loaded };
};
