import * as React from 'react';
import ErrorPageLayout from '@pages/Errors/ErrorPageLayout';

const ExternshipInviteErrorPage: React.FC = () => {
  return (
    <ErrorPageLayout
      header="EXPIRED OR CANCELLED INVITATION"
      subtext="The invitation you are looking for has expired or the request has been cancelled."
    />
  );
};

export default ExternshipInviteErrorPage;
