import * as React from 'react';
import LabelWithValue from '@components/LabelWithValue';

interface Props {
  label: string;
  value: string;
}

// TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - This file can be moved into /StudentCourseCard folder
const ExternshipDetail: React.FC<Props> = ({ label, value }) => (
  <LabelWithValue
    label={label}
    value={value}
    labelVariant="EC_TYPE_3XS"
    valueVariant="EC_TYPE_3XS"
    columnGap="4px"
  />
);

export default ExternshipDetail;
