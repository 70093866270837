import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import BasicModal from '@components/BasicModal';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import ExternshipAgreement from '@components/ExternshipAgreements/ExternshipAgreement';
import { ExternshipEntity } from '@api/models/externshipApi.models';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import NoDataMessage from '@components/NoDataMessage';
import { sortTableData } from '@common/helpers/sortByCategory';
import { styled } from '@mui/system';
import { useTableSortContext } from '@common/context/tableSortContext';

const Styled = {
  TableRoot: styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.WHITE.main,
    border: `1px solid ${theme.palette.GRAY_1.main}`,
    borderBottom: 'none',
    borderRadius: '0 0 4px 4px',
    overflow: 'hidden'
  })),
  RowBox: styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.GRAY_1.main}`,
    columnGap: '16px',
    padding: '10px 18px',
    ':hover': {
      backgroundColor: theme.palette.gold1.main
    }
  }))
};

interface Props {
  externships: ExternshipEntity[];
}

const CsApprovalsTable: React.FC<Props> = ({ externships }) => {
  const { sortBy, sortOrder, page, rowsPerPage } = useTableSortContext();

  const [reviewModalOpen, setReviewModalOpen] = React.useState<number>();
  const [isRevising, setIsRevising] = React.useState(false);
  const [revisionConfirmationModalOpen, setRevisionConfirmationModalOpen] =
    React.useState(false);

  const [_externships, setExternship] =
    React.useState<ExternshipEntity[]>(externships);

  const handleUpdateExternship = (externship: ExternshipEntity): void => {
    const currExternships = [..._externships];
    const filtered = currExternships.filter((e) => e.id !== externship.id);
    setExternship(filtered);
  };

  const handleCloseExternshipAgreement = (): void => {
    if (isRevising) {
      setIsRevising(false);
    }
    setReviewModalOpen(undefined);
  };

  const handleOpenRevisionConfirmationModal = (): void => {
    setReviewModalOpen(undefined);
    setRevisionConfirmationModalOpen(true);
  };

  const handleCloseRevisioConfirmationnModal = (): void =>
    setRevisionConfirmationModalOpen(false);

  const handleConfirmRevision = (externshipId: number): void => {
    handleCloseRevisioConfirmationnModal();
    setIsRevising(true);
    setReviewModalOpen(externshipId);
  };

  const ExternshipDetails: React.FC<{ externship: ExternshipEntity }> = ({
    externship
  }) => {
    const employmentStartDate =
      externship.externshipAgreement?.employmentStartDate;
    const revisedEmploymentStartDate =
      externship.externshipAgreement?.revisedEmploymentStartDate;

    const startDate = revisedEmploymentStartDate
      ? revisedEmploymentStartDate
      : employmentStartDate;

    const details = [
      {
        label: 'ROLE',
        value: externship.jobTitle
      },
      { label: 'LOCATION', value: externship.company.name },
      {
        label: 'START DATE',
        value: externship.externshipAgreement?.employmentStartDate
          ? formatDate(startDate || '', undefined, true)
          : ''
      }
    ];

    return (
      <>
        {details.map((d) => (
          <Typography
            key={d.value}
            variant="EC_TYPE_XS"
            sx={{ color: 'grey4.main', marginRight: '16px' }}
          >
            {d.label}: {d.value}
          </Typography>
        ))}
      </>
    );
  };

  const RevisionConfirmationModal: React.FC<{ externshipId: number }> = ({
    externshipId
  }) => {
    return (
      <BasicModal
        open={revisionConfirmationModalOpen}
        handleClose={handleCloseRevisioConfirmationnModal}
        primaryAction={{
          label: 'Yes, Revise',
          action: (): void => handleConfirmRevision(externshipId)
        }}
        secondaryAction={{
          label: 'Cancel',
          action: handleCloseRevisioConfirmationnModal
        }}
        maxWidth="sm"
      >
        <BottomMarginContentWrapper bottomMargin="32px">
          <Typography variant="EC_TYPE_LG">
            Are you sure you want to revise?
          </Typography>
          <Typography variant="EC_TYPE_BASE">
            If you revise the signed agreement, the extern and manager will have
            to sign the agreement again.
          </Typography>
        </BottomMarginContentWrapper>
      </BasicModal>
    );
  };

  return (
    <Styled.TableRoot>
      {_externships?.length ? (
        <>
          {sortTableData(
            _externships,
            sortOrder,
            sortBy as keyof ExternshipEntity
          )
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((e, i) => (
              <Styled.RowBox key={e.id + i}>
                <Box>
                  <Typography
                    variant="EC_TYPE_LG"
                    className="candidate-name"
                    data-testid="externships-table-candidate-name"
                    sx={{ color: 'primary.main' }}
                  >
                    {e.candidate.firstName} {e.candidate.lastName}
                  </Typography>
                  <ExternshipDetails externship={e} />
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    onClick={(): void => setReviewModalOpen(e.id)}
                  >
                    Review
                  </Button>
                  {reviewModalOpen === e.id && (
                    <ExternshipAgreement
                      externshipId={e.id}
                      open={reviewModalOpen === e.id}
                      agreementTitle="Externship Agreement"
                      handleClose={handleCloseExternshipAgreement}
                      updateExternship={handleUpdateExternship}
                      careerServicesSigning={true}
                      isRevising={isRevising}
                      handleOpenRevisionModal={
                        handleOpenRevisionConfirmationModal
                      }
                    />
                  )}
                  {revisionConfirmationModalOpen && (
                    <RevisionConfirmationModal externshipId={e.id} />
                  )}
                </Box>
              </Styled.RowBox>
            ))}
        </>
      ) : (
        <Box sx={{ padding: '48px 24px' }}>
          <NoDataMessage title={'No approvals at this time.'} />
        </Box>
      )}
    </Styled.TableRoot>
  );
};

export default CsApprovalsTable;
