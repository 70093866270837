import * as React from 'react';
import {
  AttestationStatus,
  AttestationType
} from '@interfaces/attestation.interface';
import {
  BatchActionModalType,
  BatchActionsContextProvider
} from '@common/context/batchActionsContext';
import {
  Button,
  createTheme,
  SelectChangeEvent,
  Skeleton,
  ThemeProvider,
  useTheme
} from '@mui/material';
import {
  GridFilterModel,
  GridRowSelectionModel,
  GridRowsProp,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import { attestationDataGridColumns } from '@pages/CareerServices/AttestationsPage/attestationDataGridColumns';
import BatchActionsPageLayout from '@components/Layout/BatchActionsPageLayout';
import { Codebook } from '@api/models/codebook.models';
import EcDataGrid from '@components/DataGrid/EcDataGrid';
import { ExternshipApi } from '@api/Externships.api';
import { ExternshipEntity } from '@api/models/externshipApi.models';
import { GridTableType } from '@api/models/gridTableApi.models';
import { useFetchAttestationTypesCodebooks } from '@common/fetches/useFetchAttestationTypesCodebooks';
import { useFetchGridTable } from '@common/fetches/useFetchGridTableByType';

const CustomToolbar = (): JSX.Element => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          allColumns: true
        }}
        printOptions={{ disableToolbarButton: true }}
      />
    </GridToolbarContainer>
  );
};

const CareerServicesAttestationsPage: React.FC = () => {
  const theme = useTheme();

  const apiRef = useGridApiRef();
  const [currDate, setCurrDate] = React.useState<Date>();
  const [loadingExterns, setLoadingExterns] = React.useState(false);
  const [externs, setExterns] = React.useState<ExternshipEntity[]>([]);

  // For batch actions
  const [selectedAction, setSelectedAction] =
    React.useState<Codebook<AttestationType>>();
  const [modalOpen, setModalOpen] = React.useState<BatchActionModalType>();

  // For MUI Data-Grid
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 0
  });

  const { ...gridTableProps } = useFetchGridTable(GridTableType.CS_DASHBOARD);
  const { attestationTypes } = useFetchAttestationTypesCodebooks();

  const {
    filterModel,
    setFilterModel,
    columnVisibilityModel,
    setColumnVisibilityModel,
    loading: loadingGridTable
  } = gridTableProps;

  const handleUpdateExterns = React.useCallback(
    (updatedExterns: ExternshipEntity[]) => {
      setExterns((prev) => {
        return prev.map((extern) => {
          const updatedExtern = updatedExterns.find(
            (newExtern) => newExtern.id === extern.id
          );

          return updatedExtern ?? extern;
        });
      });
    },
    [setExterns]
  );

  const fetchExterns = async (gradDate: Date): Promise<void> => {
    try {
      setLoadingExterns(true);
      const res = await ExternshipApi.getByGraduationDate(gradDate);
      setExterns(res);
    } catch (error: any) {
      console.error(
        'Error for CareerServicesAttestationsPage fetchExterns',
        error
      );
    } finally {
      setLoadingExterns(false);
    }
  };

  React.useEffect(() => {
    if (currDate) {
      fetchExterns(currDate);
    }
  }, [currDate]);

  const studentMap = {};
  const rows: GridRowsProp = externs.map((e) => {
    const row = {
      id: JSON.stringify({
        externshipId: e.id,
        candidateId: e.candidate.id,
        studentEmail: e.candidate.email,
        studentName: `${e.candidate.firstName} ${e.candidate.lastName}`
      }),
      studentNumber: e.candidate.studentId,
      studentName: `${e.candidate.firstName} ${e.candidate.lastName}`,
      studentEmail: e.candidate.email,
      studentPhone: e.candidate.phone,
      company: e.company.name,
      programName: e.currentProgram.versionName,
      startDate: e.externshipAgreement?.employmentStartDate,
      chefInstructor: e.chefInstructor?.name,
      type: e.attestation?.type.label
    };

    attestationDataGridColumns.forEach((c) => {
      row[c.field as string] = e[c.field];
    });

    studentMap[e.candidate.studentId!] = row;
    return row;
  });

  const handleFilterModelChange = (filterModel: GridFilterModel): void => {
    setFilterModel(filterModel);
  };

  const handleRefresh = (): void => {
    if (currDate) {
      fetchExterns(currDate);
    }
  };

  const handleSetSelectedAction = (e?: SelectChangeEvent<number>): void => {
    // Only select eligible rows if no rows are already selected
    if (!rowSelectionModel?.length) {
      const eligibleRows = rows
        .filter((r) => r.attestationStatus === AttestationStatus.ELIGIBLE)
        .map((r) => r.id);

      apiRef.current.selectRows(eligibleRows);
    }

    const type = attestationTypes.find((t) => t.value === e?.target?.value);
    setSelectedAction(type);
  };

  const handleOpenModal = (): void => {
    setModalOpen(BatchActionModalType.ATTESTATION);
  };

  const handleCloseModal = (): void => {
    setModalOpen(undefined);
  };

  const handleClearSelectedRows = (): void => {
    setRowSelectionModel([]);
  };

  return (
    <BatchActionsContextProvider
      value={{
        actions: attestationTypes,
        actionSelectLabel: 'attestations',
        rowSelectionModel,
        actionBtnText: 'Send',
        modalOpen,
        selectedAction,
        handleOpenModal,
        handleCloseModal,
        handleSetSelectedAction,
        refetch: handleRefresh,
        onSubmitCallback: handleClearSelectedRows,
        updateDataGridData: handleUpdateExterns
      }}
    >
      <BatchActionsPageLayout
        pageTitle="Extern Attestations"
        externs={externs}
        loadingExterns={loadingExterns}
        gridTableProps={gridTableProps}
        currDate={currDate}
        setCurrDate={setCurrDate}
        dateFetchType="GRAD_DATES"
        displayRefreshSection={false}
        customFilterBtnText="Apply"
        // TODO: use RouterLink here once results task is completed
        pageBtnMidRight={
          <Button
            variant="contained"
            sx={{ marginLeft: 'auto', marginBottom: 'auto' }}
            disabled={loadingExterns}
          >
            View Results
          </Button>
        }
      >
        <ThemeProvider theme={createTheme({ palette: theme.palette })}>
          {loadingExterns || loadingGridTable ? (
            <Skeleton variant="rectangular" width="100%" height="600px" />
          ) : (
            <EcDataGrid
              apiRef={apiRef}
              autoHeight
              checkboxSelectionVisibleOnly
              checkboxSelection
              rowHeight={35}
              columnHeaderHeight={35}
              pagination
              rows={rows}
              columns={attestationDataGridColumns}
              getRowClassName={(params): string =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              isRowSelectable={(params): boolean =>
                params.row.attestationStatus === AttestationStatus.ELIGIBLE
              }
              rowSelectionModel={rowSelectionModel}
              onRowSelectionModelChange={(newModel): void =>
                setRowSelectionModel(newModel)
              }
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              filterModel={filterModel}
              onFilterModelChange={handleFilterModelChange}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel): void =>
                setColumnVisibilityModel(newModel)
              }
              pageSizeOptions={[25, 50, 100]}
              slots={{ toolbar: CustomToolbar }}
              customHeaderColor={theme.palette.GRAY_4.main}
              styles={{
                '& .MuiCheckbox-root svg': {
                  color: theme.palette.GRAY_3.main
                },
                '& .MuiCheckbox-root.Mui-disabled': {
                  visibility: 'hidden'
                },
                '& .MuiDataGrid-sortIcon, .MuiDataGrid-menuIconButton': {
                  color: theme.palette.WHITE.main,
                  opacity: 1
                }
              }}
            />
          )}
        </ThemeProvider>
      </BatchActionsPageLayout>
    </BatchActionsContextProvider>
  );
};

export default CareerServicesAttestationsPage;
