import { useEffect, useState } from 'react';
import { QuizApi } from '@api/Quiz.api';
import { QuizInfo } from '@api/models/quizApi.models';

type FetchQuizInfo = {
  quizInfo: QuizInfo | null;
  loading: boolean;
};

/**
 * Fetch QuizInfo from QuizApi.getQuizInfo()
 *
 * @param {number} id
 * @returns `{ quizInfo: QuizInfo; loading: boolean; }`
 */
export const useFetchQuizInfo = (id?: number): FetchQuizInfo => {
  const [quizInfo, setQuizInfo] = useState<QuizInfo | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      const fetchData = async (): Promise<void> => {
        try {
          setLoading(true);
          const res = await QuizApi.getQuizInfo(id);
          setQuizInfo(res.data);
        } catch (error: any) {
          console.error(
            'Error for useFetchQuizInfo -> QuizApi.getQuizInfo()',
            error
          );
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [id]);

  return { quizInfo, loading };
};
