import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import NotFoundPage from '@pages/Errors/NotFoundPage';
import { useTagManager } from '@common/hooks/useTagManager';

interface Props extends RouteProps {
  component?: any;
  redirect?: string;
  internalRedirect?: string;
  title?: string;
  on?: boolean;
  description?: string;
}

const EcRoute: React.FC<Props> = ({
  component: Component,
  redirect,
  title,
  on,
  render: RenderComponent,
  description,
  internalRedirect,
  ...rest
}) => {
  const defaultPageDesc =
    'Learn more about our world-class culinary school. Fulfill your education on campus or online and enter the workforce prepared to achieve your goals. Request information today and enroll in an exemplary culinary arts program.';

  const { sendToGtm } = useTagManager();
  const docTitle = title ? `${title} | Escoffier Connect` : 'Escoffier Connect';
  document.title = docTitle;

  React.useEffect(() => {
    if (title) {
      sendToGtm('pageview', {
        page_title: docTitle
      });
    }
  }, [docTitle]);

  if (internalRedirect) {
    return <Redirect to={internalRedirect} />;
  }

  if (redirect) {
    window.location.replace(redirect);
    return <Component />;
  }

  if (on !== undefined && !on) {
    return <NotFoundPage />;
  }

  if (RenderComponent) {
    return <Route {...rest} render={RenderComponent} />;
  }

  return (
    <Route
      {...rest}
      render={(props): JSX.Element => (
        <>
          <Helmet>
            <title>{docTitle}</title>
            <meta name="description" content={description || defaultPageDesc} />
          </Helmet>
          <Component {...props} />
        </>
      )}
    />
  );
};

export default EcRoute;
