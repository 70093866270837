import * as React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import CompaniesStatusTable from './CompaniesStatusTable';
import ManageCompaniesSkeleton from '@components/Skeletons/ManageCompaniesSkeleton';
import RootPageLayout from '@components/Layout/RootPageLayout';
import StepSection from '@components/StepSection';
import { styled } from '@mui/system';
import { useFetchCompaniesForEcAdmin } from '@common/fetches/useFetchCompaniesForEcAdmin';

const Styled = {
  BorderBox: styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.OUTLINE.main}`,
    borderRadius: '4px',
    padding: '20px'
  }))
};

const ManageEmployersPage: React.FC = () => {
  const theme = useTheme();

  const { companies, loading, loaded } = useFetchCompaniesForEcAdmin();

  const fetchError = !companies && !loading && !loaded;
  const readyToRender = !!companies && !fetchError;

  return (
    <RootPageLayout
      muiMaxWidth="xl"
      pageTitle="Manage Companies"
      bgColor={theme.palette.grey1.main}
    >
      <StepSection>
        <Typography variant="EC_TYPE_BASE" sx={{ marginBottom: '32px' }}>
          You may manage the employers here by switching their status to active
          or inactive. By default a new employer will be set to inactive. An
          inactive employer is unable to create a profile or a new job. If an
          inactive employer does have job posts that were created previously,
          these jobs will not be visible on EConnect.
        </Typography>
        {fetchError ? (
          <Styled.BorderBox>
            Error fetching data. Please try again later.
          </Styled.BorderBox>
        ) : readyToRender ? (
          <CompaniesStatusTable companies={companies} />
        ) : (
          <ManageCompaniesSkeleton />
        )}
      </StepSection>
    </RootPageLayout>
  );
};

export default ManageEmployersPage;
