import * as React from 'react';
import { Button, ButtonProps, SxProps, Theme, Typography } from '@mui/material';

export interface QuizButtonProps extends ButtonProps {
  label: string;
  dataTestId: string;
  btnVariant?: 'text' | 'contained' | 'outlined';
  textColor?: string;
  sx?: SxProps<Theme>;
}

const QuizButton = (props: QuizButtonProps): React.ReactElement => {
  const {
    label,
    dataTestId,
    btnVariant = 'contained',
    textColor = '#FFFFFF',
    sx,
    ...buttonProps
  } = props;

  return (
    <Button
      {...buttonProps}
      data-testid={dataTestId}
      variant={btnVariant}
      sx={{ padding: '12px 24px', ...sx }}
    >
      <Typography variant="EC_TYPE_BASE" color={textColor} fontWeight={700}>
        {label}
      </Typography>
    </Button>
  );
};

export default QuizButton;
