import * as React from 'react';
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  Popper,
  Typography
} from '@mui/material';
import {
  CareerPathRole,
  CareerRole
} from '@interfaces/careerJourney.interfaces';
import EcChip from '@components/EcChip';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const MEDIA_QUERY = '@media (min-width:400px)';

const Styled = {
  Popover: styled(Popper, {
    shouldForwardProp: (prop) => prop !== 'expanded'
  })<{ expanded: boolean }>(({ expanded }) => ({
    position: 'relative',
    display: 'flex',
    flexFlow: 'row-reverse',
    backgroundColor: theme.palette.red4.main,
    padding: expanded ? '22px 34px' : 0,
    maxWidth: expanded ? '580px' : 'min-content',
    borderRadius: '4px',
    overflow: 'hidden',
    zIndex: 1000,
    '.MuiPopover-paper': {
      backgroundColor: theme.palette.red4.main,
      padding: expanded ? '22px 34px' : 0,
      maxWidth: expanded ? '580px' : 'min-content'
    },
    '& .accordion-container': {
      backgroundColor: theme.palette.red4.main,
      border: 'none'
    },
    '& .accordion-summary-text': {
      color: theme.palette.WHITE.main
    },
    '& .accordion-path-name': {
      margin: 'auto 0 8px 0',
      fontSize: theme.typography.EC_TYPE_3XL.fontSize,
      lineHeight: theme.typography.EC_TYPE_3XL.lineHeight,
      [MEDIA_QUERY]: {
        fontSize: theme.typography.EC_TYPE_4XL.fontSize,
        lineHeight: theme.typography.EC_TYPE_4XL.lineHeight
      }
    }
  })),
  Summary: styled(AccordionSummary)({
    padding: '4px 12px 4px 20px',
    alignItems: 'center',
    [MEDIA_QUERY]: {
      whiteSpace: 'nowrap'
    }
  }),
  SummaryButtonBox: styled(Box)({
    position: 'relative',
    right: 0,
    top: 0,
    transform: 'translate(-11px, 13px)',
    marginLeft: '12px',
    [MEDIA_QUERY]: {
      marginLeft: '60px'
    }
  }),
  CloseButton: styled(IconButton)({
    position: 'absolute',
    right: 0,
    fontSize: '16px',
    lineHeight: '16px',
    top: 0,
    '& .ri-close-line': {
      color: '#FFFFFF'
    }
  }),
  CareerInfo: styled(Box)({
    marginBottom: '22px'
  }),
  CareerText: styled(Typography)({
    color: 'white',
    marginBottom: '15px'
  }),
  ButtonWrapper: styled(Box)({
    margin: '20px 0 40px'
  }),
  GoalButton: styled(Button)({
    backgroundColor: 'white',
    height: '40px',
    width: 'max-content',
    fontSize: '15px',
    lineHeight: '18px',
    ':hover': {
      backgroundColor: theme.palette.gold1.main
    }
  })
};

interface SetGoalButtonProps {
  handleSetGoal: () => void;
  isSavedGoal: boolean;
  dataTestId: string;
  icon: string;
  text?: string;
  hideLabel: boolean;
}

const SetGoalButton = ({
  handleSetGoal,
  dataTestId,
  icon,
  text,
  hideLabel
}: SetGoalButtonProps): React.ReactElement => {
  return (
    <Styled.GoalButton
      data-testid={dataTestId}
      variant="outlined"
      color="secondary"
      onClick={handleSetGoal}
      startIcon={!hideLabel && <i className={icon} />}
    >
      {!hideLabel ? text : <i className={icon} />}
    </Styled.GoalButton>
  );
};

interface TagSectionProps {
  title: string;
  tags: string[];
}

const TagSection: React.FC<TagSectionProps> = ({ title, tags }) => {
  return (
    <Box>
      <Typography
        variant="EC_TYPE_XS"
        sx={{ color: 'white' }}
        data-testid="career-node-popover-tag-section-title"
      >
        {title.toUpperCase()}
      </Typography>
      <Grid container spacing={1} mt={0.5}>
        {tags.map((tag, idx) => {
          return (
            <Grid item key={idx}>
              <EcChip
                dataTestId={`${tag}-chip`}
                label={tag}
                chipColor={theme.palette.GRAY_4.main}
                typography="EC_TYPE_2XS"
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

interface Props<T extends CareerPathRole | CareerRole> {
  index: number;
  open: boolean;
  anchorEl: HTMLElement | null;

  // TODO: CareerRole is missing requiredSkills, certifications, ad prerequisites.
  // CareerRole comes from CareerApi.getCareerRoles(), whereas CareerPathRole comes from CareerApi.getCareerTrackById()
  // Look into CareerTrackComponent.tsx and CareerPathExploreMoreCard.tsx to see the different types passed to this component.
  role: T;
  expanded: string | false;
  isSavedGoal: boolean;
  handlePopoverClose: () => void;
  handleMenuEnter: () => void;
  handleMenuLeave: () => void;
  handleExpand: (
    panel: string,
    role: T
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  setCareerGoal: (careerRoleId: number) => Promise<void>;
}

const CareerNodePopover = <T extends CareerPathRole | CareerRole>({
  index,
  open,
  anchorEl,
  role,
  expanded,
  isSavedGoal,
  handlePopoverClose,
  handleMenuEnter,
  handleMenuLeave,
  handleExpand,
  setCareerGoal
}: Props<T>): React.ReactElement => {
  const { name, description } = role;

  const { width } = useWindowDimensions();

  const handleMouseLeave = (): void => {
    if (expanded === false) {
      handleMenuLeave();
    }
  };

  const handleSetGoal = React.useCallback((): void => {
    setCareerGoal(role.id);
    handlePopoverClose();
  }, [setCareerGoal, handlePopoverClose]);

  const handleClickAway = React.useCallback((): void => {
    if (expanded !== false) {
      handlePopoverClose();
    }
  }, [handlePopoverClose, expanded]);

  const setGoalBtnText: string | undefined = React.useMemo(() => {
    if (width <= 400) {
      return undefined;
    } else if (expanded && isSavedGoal) {
      return 'Remove Goal';
    } else if (expanded && !isSavedGoal) {
      return 'Set as goal';
    }

    if (width > 400 && isSavedGoal) {
      return 'Remove Goal';
    } else if (width > 400 && !isSavedGoal) {
      return 'Set as goal';
    }
  }, [isSavedGoal, expanded, width]);

  const setGoalBtnIcon: string = React.useMemo(() => {
    if (isSavedGoal) {
      return 'ri-delete-bin-6-line';
    } else {
      return 'ri-star-line';
    }
  }, [isSavedGoal]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Styled.Popover
        open={open}
        anchorEl={anchorEl}
        expanded={expanded !== false}
        onMouseLeave={handleMouseLeave}
        data-testid="career-node-popover-root"
      >
        {expanded === false && (
          <Styled.SummaryButtonBox onMouseEnter={handleMenuEnter}>
            <SetGoalButton
              handleSetGoal={handleSetGoal}
              isSavedGoal={isSavedGoal}
              dataTestId="career-node-summary-set-goal-btn"
              text={setGoalBtnText}
              icon={setGoalBtnIcon}
              hideLabel={width <= 400 && !expanded}
            />
          </Styled.SummaryButtonBox>
        )}
        <Accordion
          expanded={expanded === role.name}
          onChange={handleExpand(role.name, role)}
          TransitionProps={{ exit: false }}
          className="accordion-container"
          data-testid="career-node-popover-accordion"
        >
          {expanded === false && (
            <Styled.Summary
              aria-controls="career-content"
              id="career-content-title"
              data-testid="career-node-popover-accordion-summary"
              onMouseEnter={handleMenuEnter}
              tabIndex={index}
            >
              <Box>
                <Typography
                  variant="EC_TYPE_4XL"
                  className="accordion-summary-text accordion-path-name"
                >
                  {name}
                </Typography>
                <Typography
                  variant="EC_TYPE_2XS"
                  className="accordion-summary-text"
                >
                  VIEW MORE
                </Typography>
              </Box>
            </Styled.Summary>
          )}
          <Styled.CloseButton
            aria-label="close"
            data-testid="career-node-popover-close-btn"
            onClick={handlePopoverClose}
          >
            <i className="ri-close-line" />
          </Styled.CloseButton>
          <Styled.CareerInfo>
            <Styled.CareerText
              variant="EC_TYPE_4XL"
              data-testid="career-node-popover-career-name"
            >
              {name}
            </Styled.CareerText>
            <Styled.ButtonWrapper>
              <SetGoalButton
                handleSetGoal={handleSetGoal}
                isSavedGoal={isSavedGoal}
                dataTestId="career-node-popover-set-goal-btn"
                text={setGoalBtnText}
                icon={setGoalBtnIcon}
                hideLabel={width <= 400 && !expanded}
              />
            </Styled.ButtonWrapper>
            <Styled.CareerText
              variant="EC_TYPE_BASE"
              data-testid="career-node-popover-career-description"
            >
              {description}
            </Styled.CareerText>
          </Styled.CareerInfo>
          <Grid container rowSpacing={2}>
            {'requiredSkills' in role && (
              <Grid item xs={12}>
                <TagSection
                  title="Skills"
                  tags={role.requiredSkills?.map((s) => s.label) || []}
                />
              </Grid>
            )}
            {'prerequisites' in role && (
              <Grid item xs={12}>
                <TagSection
                  title="Prerequisites"
                  tags={role.prerequisites?.map((pr) => pr.name) || []}
                />
              </Grid>
            )}
          </Grid>
        </Accordion>
      </Styled.Popover>
    </ClickAwayListener>
  );
};

export default CareerNodePopover;
