import * as React from 'react';
import { Box } from '@mui/material';
import CareerCenter from './CareerCenter';
import PrimaryContent from './PrimaryContent';
import { styled } from '@mui/system';
import { useResponsiveLayoutContext } from '@components/Layout/ResponsiveLayout';

const Styled = {
  Background: styled(Box)({
    backgroundColor: 'transparent'
  }),
  Root: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'customBreakpointReached'
  })<{ customBreakpointReached: boolean }>(({ customBreakpointReached }) => ({
    display: 'flex',
    flexFlow: customBreakpointReached ? 'column' : 'row nowrap',
    margin: '0 auto',
    overflowX: 'hidden'
  }))
};

const WelcomeSection: React.FC = () => {
  const { customBreakpointReached } = useResponsiveLayoutContext();

  return (
    <Styled.Background data-testid="welcome-section-root">
      <Styled.Root customBreakpointReached={customBreakpointReached}>
        <PrimaryContent />
        <CareerCenter />
      </Styled.Root>
    </Styled.Background>
  );
};

export default WelcomeSection;
