/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Box, Button, ListItemText, Menu, MenuItem } from '@mui/material';
import {
  handleActivityToggle,
  handleMarkCompanyPossibleParent,
  updateWorkLearnPartner
} from '@common/helpers/manageCompaniesTableHelpers';
import { CompanyStatus } from '@api/models/adminDashboardApi.models';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useHistory } from 'react-router-dom';

export type MenuItemType = {
  label: string;
  onClick: (params: GridRenderCellParams) => void;
  labelGetter?: (params: GridRenderCellParams) => string;
  disabled?: ((params: GridRenderCellParams) => boolean) | boolean;
};

type SetCompaniesType = React.Dispatch<React.SetStateAction<CompanyStatus[]>>;

interface Props {
  params: GridRenderCellParams<any, any, any>;
  setCompanies: SetCompaniesType;
}

const OptionsMenu = React.memo(
  ({ params, setCompanies }: Props): React.ReactElement => {
    const featureFlags = useFeatureFlags();
    const history = useHistory();

    const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);

    const handleOpenMenu = (e: React.BaseSyntheticEvent<MouseEvent>): void => {
      setAnchor(e.currentTarget);
    };

    const handleClose = (): void => {
      setAnchor(null);
    };

    const {
      id,
      row: {
        enabled,
        isParent,
        possibleParent,
        workLearnPartner,
        parentCompany
      }
    } = params;

    const displayParentDashLink =
      featureFlags.PARENT_CHILD_RELATION && (isParent || possibleParent);

    const activeLabel = enabled
      ? isParent
        ? 'Remove subsidiaries to deactivate'
        : 'Deactivate'
      : 'Activate';

    const promoteLabel = isParent
      ? 'Remove subsidiaries to demote'
      : possibleParent
      ? 'Demote to non-primary'
      : 'Promote to primary';

    const workLearnLabel = !workLearnPartner
      ? 'Add Work & Learn'
      : 'Remove Work & Learn';

    const optionsMenuItems: MenuItemType[] = [
      {
        label: 'Settings',
        onClick: (): void => history.push(`/employer/${id}/settings/company`)
      },
      ...(displayParentDashLink
        ? [
            {
              label: 'Parent Dashboard',
              onClick: (): void => history.push(`/parent/${id}/dashboard`)
            }
          ]
        : []),
      {
        label: 'Employer Dashboard',
        onClick: (): void => {
          history.push(`/employer/${id}/dashboard`);
        }
      },
      {
        label: activeLabel,
        disabled: enabled && isParent,
        onClick: async (): Promise<void> =>
          await handleActivityToggle(id as number, enabled, setCompanies)
      },
      {
        label: promoteLabel,
        // Disable if parent or if parentCompany is not self
        disabled: isParent || (parentCompany && parentCompany.id !== id),
        onClick: async (): Promise<void> =>
          await handleMarkCompanyPossibleParent(
            id as number,
            possibleParent,
            setCompanies
          )
      },
      {
        label: workLearnLabel,
        disabled: !enabled,
        onClick: async (): Promise<void> =>
          await updateWorkLearnPartner(
            id as number,
            workLearnPartner,
            setCompanies
          )
      }
    ];

    return (
      <Box>
        <Button
          size="small"
          variant="text"
          id="options-menu-button"
          aria-controls="options-menu"
          aria-haspopup="true"
          aria-expanded={!!anchor || undefined}
          data-testid={`renderOptionsMenu-menu-button-${id}`}
          onClick={handleOpenMenu}
          sx={{
            minWidth: 0,
            width: 'max-content',
            fontWeight: 800
          }}
        >
          ...
        </Button>
        <Menu
          id="options-menu"
          data-testid={`renderOptionsMenu-menu-${id}`}
          anchorEl={anchor}
          open={!!anchor}
          onClose={handleClose}
          MenuListProps={{ 'aria-labelledby': 'options-menu-button' }}
        >
          {optionsMenuItems.map((item) => {
            const { onClick } = item;
            const handleClick = (): void => {
              onClick(params);
              handleClose();
            };

            return (
              <MenuItem
                key={item.label}
                disabled={!!item.disabled}
                onClick={handleClick}
                data-testid={`renderOptionsMenu-menu-item-${item.label}`}
              >
                <ListItemText
                  data-testid={`renderOptionsMenu-list-item-${item.label}`}
                >
                  {item.label}
                </ListItemText>
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    );
  }
);

OptionsMenu.displayName = 'OptionsMenu';

export const renderOptionsMenu = (
  params: GridRenderCellParams<any, any, any>,
  setCompanies: SetCompaniesType
): JSX.Element => {
  return <OptionsMenu params={params} setCompanies={setCompanies} />;
};
