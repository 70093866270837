import { CandidateApi } from '@api/Candidate.api';

interface PutCandidateCareerGoal {
  setCareerGoal: (careerRoleId: number) => Promise<void>;
  removeCareerGoal: (candidateId: number) => Promise<void>;
}

export const usePutCandidateCareerGoal = (
  candidateId: number
): PutCandidateCareerGoal => {
  const setCareerGoal = async (careerRoleId: number): Promise<void> => {
    try {
      await CandidateApi.setCareerGoal(candidateId, { careerRoleId });
    } catch (error: any) {
      console.error(
        'Error for usePutCandidateCareerGoal -> CandidateApi.setCareerGoal',
        error
      );
    }
  };

  const removeCareerGoal = async (candidateId: number): Promise<void> => {
    try {
      await CandidateApi.removeCareerGoal(candidateId);
    } catch (error: any) {
      console.error(
        'Error for usePutCandidateCareerGoal -> CandidateApi.removeCareerGoal',
        error
      );
    }
  };

  return { setCareerGoal, removeCareerGoal };
};
