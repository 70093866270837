import * as React from 'react';
import { Box, Typography } from '@mui/material';
import {
  CandidateProfileContentId,
  CandidateProfileContentType
} from '@common/models/candidateProfile.models';
import CandidateProfileGallery from './CandidateProfileGallery';
import CandidateProfileListItem from './CandidateProfileListItem';
import { Photo } from '@api/models/candidateApi.models';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)({
    marginTop: '32px'
  }),
  Content: styled(Box, {
    shouldForwardProp: (prop) =>
      prop !== 'flexDisplay' &&
      prop !== 'disableVertPadding' &&
      prop !== 'wideBorder'
  })<{
    flexDisplay: boolean;
    disableVertPadding: boolean;
    wideBorder: boolean;
  }>(({ theme, flexDisplay, disableVertPadding, wideBorder }) => ({
    display: flexDisplay ? 'flex' : 'block',
    flexWrap: flexDisplay ? 'wrap' : 'initial',
    columnGap: '16px',
    alignItems: 'center',
    border: `${wideBorder ? '3px' : '1px'} solid ${theme.palette.GOLD_1.main}`,
    borderRadius: '10px',
    paddingTop: disableVertPadding ? 0 : '16px',
    paddingRight: '16px',
    paddingBottom: flexDisplay || !disableVertPadding ? '16px' : 0,
    paddingLeft: '16px',
    [theme.breakpoints.up('sm')]: {
      borderRadius: wideBorder ? '10px' : '4px',
      paddingTop: disableVertPadding ? 0 : '26px',
      paddingRight: '22px',
      paddingBottom: flexDisplay || !disableVertPadding ? '26px' : 0,
      paddingLeft: '22px'
    }
  })),
  BoldXS: styled(Typography)(({ theme }) => ({
    fontWeight: 800,
    color: theme.palette.GRAY_4.main
  }))
};

interface SectionProps<T extends CandidateProfileContentType> {
  content: T[];
  contentType: CandidateProfileContentId;
  title?: string;
  sectionTitle?: string;
  flexDisplay?: boolean;
  disableVerticalPadding?: boolean;
  wideBorder?: boolean;
}

// TODO: refactor from Candidate into Shared

const CandidateProfileContentSection = <T extends CandidateProfileContentType>({
  title,
  sectionTitle,
  content,
  contentType,
  flexDisplay = false,
  disableVerticalPadding = false,
  wideBorder = false
}: SectionProps<T>): React.ReactElement => {
  return (
    <Styled.Root data-testid="candidate-profile-content-section-root">
      {title && (
        <Styled.BoldXS
          data-testid={`candidate-profile-content-title-${title}`}
          variant="EC_TYPE_XS"
          textTransform="uppercase"
        >
          {title}
        </Styled.BoldXS>
      )}
      <Styled.Content
        flexDisplay={flexDisplay}
        disableVertPadding={disableVerticalPadding}
        wideBorder={wideBorder}
      >
        {contentType === 'candidateCulinaryPhotos' ? (
          <CandidateProfileGallery
            media={content as Photo[]}
            maxDisplayed={5}
          />
        ) : (
          content.map((item, idx) => (
            <CandidateProfileListItem
              key={`${title}-${idx}`}
              content={item}
              contentType={contentType}
              sectionTitle={sectionTitle}
            />
          ))
        )}
      </Styled.Content>
    </Styled.Root>
  );
};

export default CandidateProfileContentSection;
