import { GridType, GridViewDto } from '@api/models/gridTableApi.models';
import { createCustomContext } from '@common/context/customContext';
import { ViewCounts } from '@common/fetches/useFetchGridViewByType';

type GridViewContext = {
  gridViews: GridViewDto[];
  setGridViews: React.Dispatch<React.SetStateAction<any[]>>;
  gridType: GridType;
  viewCounts: ViewCounts;
  items: any[];
};

export const [useGridViewContext, GridViewContextProvider] =
  createCustomContext<GridViewContext>();
