import * as React from 'react';
import {
  companyDetailsPropArray,
  PreviewPageCompanyDetailsProps,
  tabPanelBenefitsPropArray,
  tabPanelCompanyPropArray,
  TabsPanelsBenefitsProps,
  TabsPanelsCompanyProps
} from './employerPreviewPageComponentProps';
import { JobPost, JobPostType } from '@api/models/singleJobPostApi.models';
import PreviewPageInfo, {
  InfoItemType
} from '@components/PreviewPageComponents/PreviewPageInfo';
import {
  redirectStorageKeys,
  redirectStorageMessages
} from '@common/helpers/storage';
import { useLocation, useParams } from 'react-router-dom';
import EmployerPreviewPageTopBar from '@pages/Employer/PreviewPage/EmployerPreviewPageTopBar';
import IncompleteProfileModal from '@components/PreviewPageComponents/IncompleteProfileModal';
import JobApplication from '@components/PreviewPageComponents/JobApplication';
import JobPostSkeleton from '@components/Skeletons/JobPostSkeleton';
import LayoutRightSidebar from '@components/Layout/LayoutRightSidebar';
import { MediaType } from '@api/models/mediaApi.models';
import { pickProps } from '@common/helpers/pickProps';
import PreviewPageCompanyDetails from '@components/PreviewPageCompanyDetails';
import PreviewPageContent from '@components/PreviewPageComponents/PreviewPageContent';
import PreviewPageImageGallery from '@components/PreviewPageComponents/PreviewPageImageGallery';
import PreviewPageTitle from '@components/PreviewPageComponents/PreviewPageTitle';
import PreviewPageTopBar from '@components/PreviewPageComponents/PreviewPageTopBar';
import Skillset from '@components/Skillset';
import TabsPanelsEmployer from '@components/TabsPanelsEmployer';
import { useFetchCandidateProfile } from '@common/fetches/useFetchCandidateProfile';
import { useFetchCompanyProfilePublic } from '@common/fetches/useFetchCompanyProfilePublic';
import { useFetchHasCandidateAppliedToJob } from '@common/fetches/useFetchHasCandidateAppliedToJob';
import { useFetchJobPostById } from '@common/fetches/useFetchJobPostById';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

// Defined here and in LayoutRightSidebar.tsx
const MOBILE_BREAKPOINT = 900;

const JobListingPage: React.FC = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const [candidateId] = useStore<number>(UseStoreKeys.CANDIDATE_ID);

  const [isParentCompany] = useStore<boolean>(UseStoreKeys.IS_PARENT_COMPANY);
  const { companyId } = useParamsOrStoreCompanyId();

  const { state } = useLocation();
  const { isAuthenticated, isCandidate, isEcAdmin } = useKeycloakContext();
  const { width } = useWindowDimensions();

  const [disableButton, setDisableButton] = React.useState<boolean>(false);
  const [rightSidebarApplyActive, setRightSidebarApplyActive] =
    React.useState<boolean>(false);
  const [showIncompleteProfileModal, setShowIncompleteProfileModal] =
    React.useState<boolean>(false);
  const [submittedApplicationId, setSubmittedApplicationId] =
    React.useState<number>();

  const {
    jobPost,
    isOwner,
    loaded: jobPostLoaded
  } = useFetchJobPostById(jobId);

  const jobPostCompanyId = jobPost ? jobPost.company.id : undefined;
  const isDirectConnectPost =
    jobPost?.jobPostType === JobPostType.DIRECT_CONNECT;

  const { companyPublicProfile: companyProfile, loaded: companyLoaded } =
    useFetchCompanyProfilePublic(jobPostCompanyId);

  const {
    candidateProfile,
    profileComplete,
    loaded: candidateLoaded
  } = useFetchCandidateProfile(candidateId);

  const { hasCandidateAppliedToJob, loaded: hasAppliedLoaded } =
    useFetchHasCandidateAppliedToJob(jobId, candidateId);

  const readyToRender =
    !!jobPostLoaded &&
    !!companyLoaded &&
    !!candidateLoaded &&
    !!hasAppliedLoaded;

  React.useEffect(() => {
    if (state && state.shouldClearSession === true) {
      localStorage.removeItem(redirectStorageKeys.JOB_APPLICATION);
    }
  }, [state]);

  React.useLayoutEffect(() => {
    if (companyId || isEcAdmin || hasCandidateAppliedToJob) {
      setDisableButton(true);
    }
  }, [companyId, isEcAdmin, hasCandidateAppliedToJob]);

  React.useEffect(() => {
    if (candidateId) {
      localStorage.removeItem(redirectStorageKeys.JOB_APPLICATION);
    }
  }, [candidateId]);

  const handleClick = (): void => {
    setRightSidebarApplyActive(!rightSidebarApplyActive);
  };

  const handleIWantThisJobClick = (): void =>
    profileComplete
      ? setRightSidebarApplyActive(true)
      : setShowIncompleteProfileModal(true);

  const handleCloseIncompleteProfileModal = React.useCallback(
    (): void => setShowIncompleteProfileModal(false),
    [setShowIncompleteProfileModal]
  );

  // Handle job application modal and redirect
  const handleSuccessfulApplication = (jobApplicationId: number): void => {
    setSubmittedApplicationId(jobApplicationId);
    setDisableButton(true);
    window.scrollTo(0, 0);
  };

  const incompleteModalProps = {
    title: 'Ready to apply?',
    subtitle: 'Complete your profile to apply to jobs on EConnect.',
    isCandidateProfileComplete: profileComplete,
    localStorageMessage: redirectStorageMessages.INCOMPLETE_PROFILE,
    localStorageKey: redirectStorageKeys.JOB_APPLICATION,
    primaryButtonText: 'Candidate Profile',
    secondaryButtonText: 'Cancel',
    primaryClickLocation: '/candidate/profile-setup',
    secondaryClick: handleCloseIncompleteProfileModal
  };

  const compensationDisplay = (
    type: string[],
    description: string
  ): InfoItemType => {
    const compType = type.length
      ? type.map((comp) => comp).join(', ')
      : undefined;
    const desc = description.length ? description : undefined;

    const display =
      !!compType && !!desc ? compType + ' - ' + desc : compType || desc;

    const hideDisplay: boolean = compType === undefined && desc === undefined;

    return {
      value: display ?? '',
      icon: !hideDisplay ? 'ri-money-dollar-box-line' : ''
    };
  };

  const getTitle = (): string => {
    if (isDirectConnectPost) {
      return `${jobPost.company.name} - Direct Connect`;
    }

    return jobPost?.jobTitle || '';
  };

  const getApplyBtnText = (): string => {
    if (disableButton && !companyId && !isEcAdmin) {
      return 'Application submitted';
    }

    if (isDirectConnectPost) {
      return 'I want to apply';
    }

    return 'I want this job';
  };

  const previewPageTopBarProps = {
    buttonText: getApplyBtnText(),
    iconName: 'ri-star-fill'
  };

  const jobInfoItems: InfoItemType[] = React.useMemo(() => {
    const locationList = jobPost?.locations
      .map((loc) => loc.description)
      .join(', ');
    const compensation = compensationDisplay(
      jobPost?.compensationType ?? [],
      jobPost?.compensationDetails ?? ''
    );

    return [
      {
        value: locationList,
        icon: 'ri-map-pin-2-fill'
      },
      {
        value: jobPost?.jobType.map((type) => type).join(', '),
        icon: 'ri-briefcase-fill'
      },
      compensation,
      {
        value: jobPost?.jobSegments.map((seg) => seg.label).join(', '),
        icon: 'ri-restaurant-2-fill'
      },
      {
        value: jobPost?.jobSpecialties.map((spec) => spec.label).join(', '),
        icon: 'ri-checkbox-blank-circle-fill'
      }
    ];
  }, [jobPost]);

  const companyDetailsProps: PreviewPageCompanyDetailsProps =
    React.useMemo(() => {
      return pickProps(
        companyDetailsPropArray,
        companyProfile
      ) as PreviewPageCompanyDetailsProps;
    }, [companyProfile]);

  const tabsPanelsProps = React.useMemo(() => {
    const companyProps = pickProps(
      tabPanelCompanyPropArray,
      companyProfile
    ) as TabsPanelsCompanyProps;
    const benefitsProps = pickProps(
      tabPanelBenefitsPropArray,
      jobPost
    ) as TabsPanelsBenefitsProps;

    return { ...companyProps, ...benefitsProps };
  }, [companyProfile, jobPost]);

  const galleryImagesVideos: MediaType[] = React.useMemo(() => {
    if (companyProfile) {
      return [
        ...companyProfile.companyPhotos.map((item) => item.file),
        ...companyProfile.companyVideos.map((item) => item.video)
      ];
    }

    return [];
  }, [companyProfile]);

  const skillSetProps: string[] = [
    ...(jobPost?.technicalSkills?.map((skill) => skill.label) ?? []),
    ...(jobPost?.softSkills?.map((skill) => skill.label) ?? [])
  ];

  const displayTopBar = React.useCallback((): boolean => {
    if (isDirectConnectPost) {
      return false;
    }

    return (!isCandidate && isOwner) || isEcAdmin;
  }, [isDirectConnectPost, isCandidate, isOwner, isEcAdmin]);

  const PrimaryContent: React.FC<{ jobPost: JobPost }> = ({ jobPost }) => (
    <>
      <PreviewPageTopBar
        buttonStatus={disableButton}
        topBarProps={previewPageTopBarProps}
        onClick={handleIWantThisJobClick}
        hideBtn={rightSidebarApplyActive}
        isCandidatePage={false}
      />
      <PreviewPageTitle
        title={getTitle()}
        workLearnEligible={jobPost.workLearnEligible}
      />
      <PreviewPageInfo itemList={jobInfoItems} />
      {!isDirectConnectPost && <Skillset skillset={skillSetProps} />}
      <PreviewPageContent
        statement={jobPost.jobDescription}
        isCandidatePage={false}
      />
    </>
  );

  const SecondaryDesktop: JSX.Element = (
    <>
      <PreviewPageCompanyDetails
        companyId={companyProfile?.id}
        {...companyDetailsProps}
      />
      <PreviewPageImageGallery media={galleryImagesVideos} />
      <TabsPanelsEmployer {...tabsPanelsProps} />
      <IncompleteProfileModal
        open={showIncompleteProfileModal}
        handleClose={handleCloseIncompleteProfileModal}
        {...incompleteModalProps}
      />
    </>
  );

  // Image Gallery goes on the bottom
  const SecondaryMobile: JSX.Element = (
    <>
      <PreviewPageCompanyDetails
        companyId={companyProfile?.id}
        {...companyDetailsProps}
      />
      <TabsPanelsEmployer {...tabsPanelsProps} />
      <PreviewPageImageGallery media={galleryImagesVideos} />
      <IncompleteProfileModal
        open={showIncompleteProfileModal}
        handleClose={handleCloseIncompleteProfileModal}
        {...incompleteModalProps}
      />
    </>
  );

  const SecondaryContent: JSX.Element =
    width <= MOBILE_BREAKPOINT ? SecondaryMobile : SecondaryDesktop;

  if (!isAuthenticated) {
    return <JobPostSkeleton modalOpen={true} />;
  }

  if (!readyToRender) {
    return <JobPostSkeleton />;
  } else {
    return (
      <>
        {jobPost && (
          <>
            {displayTopBar() && companyProfile && (
              <EmployerPreviewPageTopBar
                isVisible={jobPost.visibleOnMarketplace}
                jobId={jobPost.id}
                companyId={
                  isEcAdmin || isParentCompany ? companyProfile.id : null
                }
              />
            )}
            <LayoutRightSidebar
              rightSidebarApplyActive={rightSidebarApplyActive}
              primaryContent={<PrimaryContent jobPost={jobPost} />}
              secondaryContent={SecondaryContent}
              activeStateContent={
                candidateProfile &&
                companyProfile && (
                  <JobApplication
                    isDirectConnectPost={isDirectConnectPost}
                    onSuccessfulApplication={handleSuccessfulApplication}
                    onClick={handleClick}
                    jobPostId={parseInt(jobId)}
                    workAndLearn={candidateProfile.workAndLearn}
                    savedResume={candidateProfile.resume}
                    companyId={companyProfile.id}
                    jobPostYearsOfExperience={jobPost.experienceLevel}
                    techSkills={jobPost.technicalSkills}
                    softSkills={jobPost.softSkills}
                    externalJobPost={jobPost.externalJobPost}
                    submittedApplicationId={submittedApplicationId}
                  />
                )
              }
            />
          </>
        )}
      </>
    );
  }
};

export default JobListingPage;
