import * as React from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { AdminStatsResponse } from '@api/models/adminDashboardApi.models';
import { DateRange } from '@common/models/common.models';
import IntentionsTabPanel from './IntentionsTabPanel';
import LocationsSection from '../LocationsSection';
import OverviewTabPanel from './OverviewTabPanel';
import StepSection from '@components/StepSection';
import { styled } from '@mui/system';

const Styled = {
  TabPanel: styled(TabPanel)({
    '&.MuiTabPanel-root': {
      padding: '0px',
      margin: '0px'
    }
  })
};

interface Props {
  stats: AdminStatsResponse;
  chartLabel: string;
  dateRange: DateRange;
}

const CandidateAccountsSection: React.FC<Props> = ({
  stats,
  chartLabel,
  dateRange
}) => {
  const { intentions, locations } = stats.createdAccounts;
  const [value, setValue] = React.useState('1');

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: string
  ): void => {
    setValue(newValue);
  };

  return (
    <StepSection
      title="Candidate Accounts"
      dataTestId="candidate-accounts-section"
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleTabChange}
            aria-label="candidate accounts tabs"
          >
            <Tab label="Overview" value="1" />
            <Tab label="Intentions" value="2" />
            <Tab label="Locations" value="3" />
          </TabList>
        </Box>
        <Styled.TabPanel value="1">
          <OverviewTabPanel
            stats={stats}
            chartLabel={chartLabel}
            dateRange={dateRange}
          />
        </Styled.TabPanel>
        <Styled.TabPanel value="2">
          <IntentionsTabPanel intentions={intentions} />
        </Styled.TabPanel>
        <Styled.TabPanel value="3">
          <LocationsSection locations={locations} gridOnly />
        </Styled.TabPanel>
      </TabContext>
    </StepSection>
  );
};

export default CandidateAccountsSection;
