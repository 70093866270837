import * as React from 'react';
import ErrorPageLayout from '@pages/Errors/ErrorPageLayout';

const InvalidInviteCodePage: React.FC = () => {
  return (
    <ErrorPageLayout
      header="INVALID INVITE CODE"
      subtext="Please contact your admin to verify your invite."
    />
  );
};

export default InvalidInviteCodePage;
