import { JobApplication } from '@api/models/jobApplicationApi.models';
import { JobApplicationApi } from '@api/JobApplication.api';
import { useState } from 'react';

interface FetchJobApplicationsForCompanyId {
  jobApplications: JobApplication[];
  fetchData: () => Promise<void>;
  loading: boolean;
  loaded: true | null;
}

export const useFetchJobApplicationsForCompanyId = (
  candidateId: number,
  companyId?: number | null
): FetchJobApplicationsForCompanyId => {
  const [jobApplications, setJobApplications] = useState<JobApplication[]>([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  const fetchData = async (): Promise<void> => {
    if (companyId) {
      try {
        setLoading(true);
        const res =
          await JobApplicationApi.getCandidateApplicationsForCompanyId(
            companyId,
            candidateId
          );

        setJobApplications(res);
      } catch (error: any) {
        console.error(
          'Error for useFetchJobApplicationsForCompanyId -> JobApplicationApi.getCandidateApplicationsForCompanyId()',
          error
        );
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    }
  };

  return { jobApplications, fetchData, loading, loaded };
};
