import * as React from 'react';

export interface EscoffierLink {
  url: string | null;
  title: string;
  internal?: boolean;
  openModalOnClick?: boolean;
}

export interface EcFooterLink {
  section: string;
  links: EscoffierLink[];
}

export interface SocialLink {
  url: string;
  icon: JSX.Element;
  gtmName: string;
}

const escoffierLinks: EcFooterLink[] = [
  {
    section: 'services',
    links: [
      {
        title: 'For employers',
        url: 'https://escoffierglobal.com/',
        internal: false
      },
      {
        title: 'For career seekers',
        url: 'https://escoffierglobal.com/econnect/',
        internal: false
      }
    ]
  },
  {
    section: 'company',
    links: [
      {
        title: 'Contact us',
        url: 'https://escoffierglobal.com/contact/',
        internal: false
      }
    ]
  },
  {
    section: 'resources',
    links: [
      {
        title: 'Escoffier.edu',
        url: null,
        internal: false,
        openModalOnClick: true
      },
      {
        title: 'Legal',
        url: 'https://escoffierglobal.com/legal/',
        internal: false
      }
    ]
  }
];

export const getEcLinks = (isEcStudent: boolean): EcFooterLink[] => {
  let ecLinks = [...escoffierLinks];

  if (isEcStudent) {
    ecLinks = ecLinks.filter((link) => link.section !== 'company');
  }

  return ecLinks;
};

export const socialLinks: SocialLink[] = [
  {
    icon: <i className="ri-linkedin-fill" />,
    url: 'https://www.linkedin.com/company/escoffierglobal/about/',
    gtmName: 'linkedin'
  }
];
