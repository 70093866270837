export interface CompanyPartnershipPermissions {
  JOB_POST_EXPIRATION_DAYS: number;
  TALENT_SEARCH_ENABLED: boolean;
  HOT_JOBS_AVAILABLE: boolean;
  EMPLOYER_SPOTLIGHT_AVAILABLE: boolean;
  DIRECT_CONNECT_POSTS_AVAILABLE: boolean;
  PREMIER_PARTNERS_AVAILABLE: boolean;
  WORK_AND_LEARN_PARTNERS_AVAILABLE: boolean;
}

export enum PartnershipLevel {
  FreeConnect = 10,
  GoldCrown = 20
}
