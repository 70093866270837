import * as React from 'react';
import { Theme, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Styled = {
  Title: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'color'
  })<{ color?: string }>(({ color, theme }) => ({
    ...(theme as Theme).typography.EC_TYPE_3XL,
    color: color || theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: (theme as Theme).typography.EC_TYPE_5XL.fontSize,
    lineHeight: (theme as Theme).typography.EC_TYPE_5XL.lineHeight,
    marginBottom: '0px'
  }))
};

interface Props {
  text: string;
  color?: string;
}

const PageTitle: React.FC<Props> = ({ text, color }) => {
  return <Styled.Title color={color}>{text}</Styled.Title>;
};

export default PageTitle;
