import * as React from 'react';
import { Skeleton, Stack } from '@mui/material';

const DashboardLoading = (): React.ReactElement => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ minHeight: '0px', padding: 0, margin: 0 }}
      >
        <div>
          <Skeleton width="220px" height="40px" />
          <Skeleton width="220px" height="20px" />
        </div>
        <Skeleton width="170px" height="58px" />
      </Stack>
      <Stack>
        <Skeleton
          height="350px"
          sx={{ marginTop: '20px', transform: 'none' }}
        />
      </Stack>
    </>
  );
};

export default DashboardLoading;
