import * as React from 'react';
import { Box, Modal, Skeleton, Typography } from '@mui/material';
import IncompleteProfileModal, {
  ModalProps
} from '@components/PreviewPageComponents/IncompleteProfileModal';
import {
  QUIZ_RESULTS_STORAGE_KEY,
  redirectStorageKeys,
  redirectStorageMessages,
  useStateWithLocalStorage
} from '@common/helpers/storage';
import { Redirect, useLocation } from 'react-router-dom';
import QuizResultCardDisplay from '@components/Quiz/QuizResults/QuizResultCardDisplay';
import QuizResultDescription from '@components/Quiz/QuizResults/QuizResultDescription';
import { QuizResults } from '@api/models/quizApi.models';
import QuizResultsLayout from '@components/Quiz/QuizResults/QuizResultsLayout';
import { ResponsiveLayoutProvider } from '@components/Layout/ResponsiveLayout';
import { KeycloakUserRole } from '@api/models/userApi.models';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useFetchCandidateProfilePublic } from '@common/fetches/useFetchCandidateProfilePublic';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { usePutCandidateCareerGoal } from '@common/putRequests/usePutCandidateCareerGoal';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import { useTagManager } from '@common/hooks/useTagManager';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const BREAKPOINT = 940;
const MEDIA_QUERY = `@media (max-width:${BREAKPOINT}px)`;
const MEDIA_MIN_QUERY = `@media (min-width:${BREAKPOINT + 1}px)`;

const Styled = {
  Root: styled(Box)({
    display: 'block',
    [MEDIA_MIN_QUERY]: {
      display: 'flex',
      width: '100%',
      height: '100%',
      columnGap: '40px'
    }
  }),
  Results: styled(Box)({
    display: 'flex',
    flex: '1 1 412px',
    flexDirection: 'column',
    rowGap: '16px',
    [MEDIA_QUERY]: {
      flex: 1,
      flexBasis: '100%'
    },
    '& .result-label': {
      color: theme.palette.GRAY_4.main,
      lineHeight: '12px'
    }
  }),
  Description: styled(Box)({
    position: 'relative',
    marginTop: '42px',
    flex: '1 3 690px',
    [MEDIA_QUERY]: {
      position: 'absolute',
      marginTop: 'unset',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: '85vh',
      width: '90%',
      maxWidth: '690px',
      borderRadius: '4px',
      overflowX: 'hidden',
      overflowY: 'scroll',
      '& i': {
        position: 'absolute',
        top: '16px',
        right: '16px',
        cursor: 'pointer',
        fontSize: '24px',
        lineHeight: '24px',
        color: '#000000'
      }
    }
  })
};

interface QuizResultsProps {
  quizId: number;
  pageTitle: string;
  pageSubtitle: string;
  resultLabel: string;
}

type ResultState = {
  state: {
    results: QuizResults[];
  };
};

type CareerPathModalProps = Omit<ModalProps, 'open' | 'handleClose'>;

const QuizResultsPage = ({
  quizId,
  resultLabel,
  ...layoutProps
}: QuizResultsProps): React.ReactElement => {
  const { sendToGtm } = useTagManager();
  const { state } = useLocation() as ResultState;
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const [candidateId] = useStore<number>(UseStoreKeys.CANDIDATE_ID);
  const { profileComplete } = useFetchCandidateProfilePublic(candidateId);
  const { setCareerGoal } = usePutCandidateCareerGoal(candidateId);

  const [showProfileModal, setShowProfileModal] = React.useState(false);
  const [modalProps, setModalProps] = React.useState<CareerPathModalProps>();
  const [results, setResults] = React.useState<QuizResults[]>();
  const [selectedResult, setSelectedResult] =
    React.useState<QuizResults | null>(null);

  const { setStoredValue } = useStateWithLocalStorage(QUIZ_RESULTS_STORAGE_KEY);

  const { width } = useWindowDimensions();
  const breakpointReached = width < BREAKPOINT;

  const sortByPercentage = (results: QuizResults[]): QuizResults[] =>
    results.sort((a, b) => b.percentage - a.percentage);

  // Set the quiz results from router state
  React.useEffect(() => {
    if (state !== undefined) {
      const _results = state.results;
      const sortedResults = sortByPercentage(_results);
      setResults(sortedResults);
      !breakpointReached && setSelectedResult(sortedResults[0]);
    }
  }, [state]);

  const handleSelectResult = (result: QuizResults): void => {
    setSelectedResult(result);
  };

  const handleDeselectResult = (): void => {
    setSelectedResult(null);
  };

  const handleCareerPathClick = (): void => {
    if (candidateId && selectedResult) {
      setCareerGoal(selectedResult.id);

      sendToGtm('set_career_goal', {
        career_id: selectedResult.id,
        career_title: selectedResult.result
      });

      history.push({ pathname: '/career-journey', state: { selectedResult } });
    } else {
      selectedResult && setStoredValue(JSON.stringify(selectedResult));
      setShowProfileModal(true);
    }
  };

  const loginFromModal = React.useCallback(() => {
    const redirect = window.location.origin + '/career-journey';

    localStorage.removeItem(redirectStorageKeys.CAREER_PATH);
    keycloak.login({ redirectUri: redirect });
  }, [keycloak]);

  const handleCloseCareerPathModal = React.useCallback(() => {
    setShowProfileModal(false);
  }, [setShowProfileModal]);

  const isSelected = (id: number): boolean => selectedResult?.id === id;

  const maxResultPercent = results
    ? Math.max(...results.map((res) => res.percentage))
    : 0;

  const openDescriptionModal = Boolean(selectedResult);

  React.useEffect(() => {
    if (!breakpointReached) {
      if (!selectedResult) {
        results && setSelectedResult(results[0]);
      }
    }
  }, [breakpointReached, selectedResult]);

  React.useEffect(() => {
    if (candidateId && profileComplete) {
      localStorage.removeItem(redirectStorageKeys.CAREER_PATH);
    }

    // no user signed in
    if (!candidateId) {
      setModalProps({
        title: 'Have you joined Escoffier Connecet?',
        subtitle: 'Sign in or create an account to create your Career Path.',
        isCandidateProfileComplete: profileComplete,
        localStorageMessage: redirectStorageMessages.NO_USER,
        localStorageKey: redirectStorageKeys.CAREER_PATH,
        primaryButtonText: 'Create Account',
        secondaryButtonText: 'Sign In',
        primaryClickLocation: `/create-account?type=${KeycloakUserRole.CANDIDATE}`,
        customRedirectRoute: '/career-journey',
        secondaryClick: loginFromModal
      });
    } else {
      localStorage.removeItem(redirectStorageKeys.CAREER_PATH);
    }
  }, [candidateId, profileComplete]);

  // TODO: right now the user is redirected if they somehow get to this url, /quiz/1/results
  // but in the future we will need to check to see if a user is authenticated and has results
  // stored in the database, or if a user has landed here from taking the quiz for the first time.

  // Memoize since this components props dont change between desktop and mobile
  const Description = React.useMemo(
    () => (
      <QuizResultDescription
        handleCtaClick={handleCareerPathClick}
        description={selectedResult?.description}
        personality={selectedResult?.personality}
        result={selectedResult?.result}
        careerRole={selectedResult?.careerRole}
      />
    ),
    [selectedResult]
  );

  return (
    <>
      {state === undefined ? (
        <Redirect to={`/quiz/${quizId}`} />
      ) : (
        <ResponsiveLayoutProvider customBreakpoint={BREAKPOINT}>
          <QuizResultsLayout quizId={quizId} {...layoutProps}>
            <Styled.Root>
              <Styled.Results>
                <Typography variant="EC_TYPE_LGG" className="result-label">
                  {resultLabel}
                </Typography>
                <Typography variant="EC_TYPE_2XS">
                  Click below to learn more
                </Typography>
                {results ? (
                  <QuizResultCardDisplay
                    results={results}
                    isSelected={isSelected}
                    maxPercent={maxResultPercent}
                    handleSelectResult={handleSelectResult}
                  />
                ) : (
                  <Skeleton variant="rectangular" height="700px" width="100%" />
                )}
              </Styled.Results>
              {!breakpointReached ? (
                <Styled.Description>{Description}</Styled.Description>
              ) : (
                <Modal
                  open={openDescriptionModal}
                  onClose={handleDeselectResult}
                >
                  <Styled.Description className="small-card">
                    <i
                      className="ri-close-line"
                      onClick={handleDeselectResult}
                    />
                    {Description}
                  </Styled.Description>
                </Modal>
              )}
            </Styled.Root>
          </QuizResultsLayout>
        </ResponsiveLayoutProvider>
      )}
      {modalProps && (
        <IncompleteProfileModal
          open={showProfileModal}
          handleClose={handleCloseCareerPathModal}
          {...modalProps}
        />
      )}
    </>
  );
};

export default QuizResultsPage;
