import * as React from 'react';
import {
  ActivityLogEntity,
  ProgressPostReq
} from '@api/models/careerServicesApi.models';
import { Box, Checkbox, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { CareerServicesApi } from '@api/CareerService.api';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { getProgressTypeText } from '@common/helpers/externshipHelpers/getExternshipProgressTypeText';
import ProgressItemComments from '@pages/CareerServices/StudentPage/Progress/ProgressItemComments';
import { styled } from '@mui/system';
import { useKeycloakContext } from '@common/context/keycloakContext';

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    marginTop: '18px',
    border: `1px solid ${theme.palette.OUTLINE.main}`,
    '& > *:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.OUTLINE.main}`
    }
  })),
  Item: styled(Box)({
    padding: '20px'
  }),
  Checkbox: styled(Checkbox)({
    padding: 0,
    height: '22px',
    width: '22px',
    marginRight: '10px'
  }),
  CheckedByBox: styled(Box)({
    position: 'absolute',
    top: 0,
    right: 0,
    textAlign: 'right',
    zIndex: 1
  })
};

interface Props {
  externshipId: number;
  progress: ActivityLogEntity[];
  setVerificationState: (
    externshipId: number,
    newVerification: ActivityLogEntity
  ) => void;
  addComment: (
    externshipId: number,
    newComment: ActivityLogEntity,
    parentActivityId?: number
  ) => void;
}

const ProgressItems: React.FC<Props> = ({
  externshipId,
  progress,
  setVerificationState,
  addComment
}) => {
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const {
    keycloakUser: { sub: userId, given_name, family_name }
  } = useKeycloakContext();

  const methods = useForm<ProgressPostReq>({
    defaultValues: {
      userId,
      comment: '',
      checked: null
    }
  });

  const handleExpand = (panel: string): void => {
    setExpanded((prev) =>
      prev.includes(panel) ? prev.filter((x) => x !== panel) : [...prev, panel]
    );
  };

  const handleCheck = async (
    progressItem: ActivityLogEntity
  ): Promise<void> => {
    const postReq = {
      userId,
      progressType: progressItem.progressType,
      checked: !progressItem.checked,
      comments: progressItem.comments ?? []
    };

    let res: ActivityLogEntity;
    if (!progressItem.id) {
      res = await CareerServicesApi.createProgressWithChecked(
        externshipId,
        postReq
      );
    } else {
      res = await CareerServicesApi.updateProgressChecked(
        progressItem.id,
        postReq
      );
    }

    setVerificationState(externshipId, res);
  };

  const getFormattedDate = (item: ActivityLogEntity): string =>
    item.date ? formatDate(item.date, 'M/dd/yyyy h:mm a') : '-';

  return (
    <Styled.Root>
      {progress.map((p) => (
        <Styled.Item key={p.progressType}>
          <FormProvider {...methods}>
            <Stack direction="row" position="relative">
              <Styled.Checkbox
                checked={!!p.checked}
                onClick={(): Promise<void> => handleCheck(p)}
              />
              <Typography variant="EC_TYPE_SM">
                {getProgressTypeText(p.progressType)}
              </Typography>
              <Styled.CheckedByBox>
                <Typography variant="EC_TYPE_2XS">
                  {getFormattedDate(p)}
                </Typography>
                <Typography variant="EC_TYPE_2XS">{p.user}</Typography>
              </Styled.CheckedByBox>
            </Stack>
            <Box sx={{ transform: 'translateX(32px)' }}>
              <ProgressItemComments
                externshipId={externshipId}
                progressItem={p}
                userId={userId}
                keycloakUserName={`${given_name} ${family_name}`}
                expanded={expanded}
                handleExpand={handleExpand}
                addComment={addComment}
              />
            </Box>
          </FormProvider>
        </Styled.Item>
      ))}
    </Styled.Root>
  );
};

export default ProgressItems;
