import { useEffect, useState } from 'react';
import { AdminDashboardApi } from '@api/AdminDashboard.api';
import { AdminStatsResponse } from '@api/models/adminDashboardApi.models';
import { DateRange } from '@common/models/common.models';
import { getChartLabel } from '@common/helpers/getChartLabel';

interface FetchAdminStats {
  stats: AdminStatsResponse | null;
  chartLabel: string;
  dateRange: DateRange;
  loading: boolean;
  loaded: true | null;
  fetchStats: (req?: DateRange) => Promise<void>;
}

export const useFetchAdminStats = (): FetchAdminStats => {
  const [stats, setStats] = useState<AdminStatsResponse | null>(null);
  const [chartLabel, setChartLabel] = useState<string>('All Time');
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: null,
    endDate: null
  });
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  const fetchStats = async (req?: DateRange): Promise<void> => {
    try {
      setLoading(true);
      const postBody = {
        startDate: req?.startDate || null,
        endDate: req?.endDate || null,
        filterStaffEmails: req?.filterStaffEmails || null
      };
      const stats = await AdminDashboardApi.getStats(postBody);
      const label = getChartLabel(req);

      setChartLabel(label);
      setDateRange(postBody);
      setStats(stats.data);
    } catch (error: any) {
      console.error('Error for AdminDashboardApi.getStats', error);
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  return { stats, chartLabel, dateRange, loading, loaded, fetchStats };
};
