import * as React from 'react';
import { Box } from '@mui/system';
import ReactHookFormsAutoComplete from '@components/Forms/ReactHookFormsAutoComplete';
import StepSection from '@components/StepSection';
import StepSubSection from '@components/StepSubSection';
import { useFetchSkillsCodebooks } from '@common/fetches/useFetchSkillsCodebooks';

const SkillSetSection: React.FC = () => {

  const { technicalSkills, softSkills, loaded } = useFetchSkillsCodebooks();

  return (
    <>
      {loaded && (
        <StepSection title="Skill Sets" dataTestId="candidate-skillSet-section">
          <StepSubSection title="What skill sets do you bring to a team?">
            <Box
              sx={{
                '& > *:not(:last-child)': {
                  marginBottom: '32px'
                }
              }}
            >
              <ReactHookFormsAutoComplete
                required
                multipleSelect
                options={technicalSkills}
                name="technicalSkills"
                title="In which technical skills are you confident?"
                subtitle="(Select one or more)"
                inputPlaceholder="Search for technical skills"
                rules={{ required: true }}
              />
              <ReactHookFormsAutoComplete
                required
                multipleSelect
                options={softSkills}
                name="softSkills"
                title="In which soft skills are you confident?"
                subtitle="(Select one or more)"
                inputPlaceholder="Search for soft skills"
                rules={{ required: true }}
              />
            </Box>
          </StepSubSection>
        </StepSection>
      )}
    </>
  );
};

export default SkillSetSection;
