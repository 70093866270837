import * as React from 'react';
import {
  Avatar,
  Box,
  Button,
  Icon,
  IconButton,
  Popover,
  Typography
} from '@mui/material';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { styled } from '@mui/system';

const Styled = {
  Popover: styled(Popover)({
    '.MuiPopover-paper': {
      width: '100%',
      maxWidth: '375px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)'
    }
  }),
  PopoverContent: styled(Box)({
    padding: '16px 24px'
  }),
  CartItemWrapper: styled(Box)({
    display: 'flex',
    alignItems: 'center'
  }),
  Avatar: styled(Avatar)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    width: '74px',
    height: '74px'
  })),
  ItemDetails: styled(Box)({
    padding: '8px 0px 8px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
    columnGap: '16px'
  }),
  LightText: styled(Typography)(({ theme }) => ({
    color: theme.palette.GRAY_3.main,
    textAlign: 'right',
    lineHeight: '19px'
  })),
  TotalWrapper: styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '52px',
    marginBottom: '16px'
  })
};

export interface CartItem {
  icon: string;
  name: string;
  price: number;
  seats: number;
}

interface Props {
  open: boolean;
  cartItems: CartItem[];
  anchorEl: null | HTMLElement;
  handleClose: () => void;
}

const CartItem: React.FC<{ item: CartItem }> = ({ item }) => {
  const { icon, name, price, seats } = item;

  return (
    <Styled.CartItemWrapper>
      <Box>
        <Styled.Avatar>
          <IconButton size="large" sx={{ color: 'WHITE.main' }}>
            <Icon fontSize="large" className={icon} />
          </IconButton>
        </Styled.Avatar>
      </Box>
      <Styled.ItemDetails>
        <Typography variant="EC_TYPE_SM">{name}</Typography>
        <Box>
          <Styled.LightText variant="EC_TYPE_BASE">${price}</Styled.LightText>
          <Styled.LightText variant="EC_TYPE_BASE">
            {seats} seats
          </Styled.LightText>
        </Box>
      </Styled.ItemDetails>
    </Styled.CartItemWrapper>
  );
};

const GlrcCart: React.FC<Props> = ({
  open,
  anchorEl,
  cartItems,
  handleClose
}) => {
  const getCartTotal = (): string => {
    return cartItems
      .reduce((acc, item) => acc + item.price, 0)
      .toLocaleString();
  };

  return (
    <Styled.Popover
      id="cart-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <Styled.PopoverContent>
        <Typography variant="EC_TYPE_SM" sx={{ marginBottom: '16px' }}>
          Your Cart
        </Typography>
        <BottomMarginContentWrapper bottomMargin="28px">
          {cartItems.map((i) => (
            <CartItem key={i.name} item={i} />
          ))}
        </BottomMarginContentWrapper>
        <Styled.TotalWrapper>
          <Typography variant="EC_TYPE_SM">Estimated Total</Typography>
          <Typography variant="EC_TYPE_SM">${getCartTotal()}</Typography>
        </Styled.TotalWrapper>
        <Button fullWidth variant="contained">
          Checkout
        </Button>
      </Styled.PopoverContent>
    </Styled.Popover>
  );
};

export default GlrcCart;
