import * as React from 'react';
import DashboardTableLayout, {
  DashboardTableLayoutProps
} from '@components/TableComponents/DashboardTableLayout';
import Box from '@mui/material/Box';
import EmployerDashExternshipsSection from '@pages/Employer/EmployerDashboard/EmployerDashExternshipsSection';
import { EmployerProfile } from '@api/models/employerApi.models';
import { ExternshipEntity } from '@api/models/externshipApi.models';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { styled } from '@mui/system';
import { useStore } from 'react-context-hook';
import { EmployerApi } from '@api/Employer.api';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';

const Styled = {
  ExternshipBox: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '80px'
  }),
};

const ExternshipTimesheetApproverDashboard: React.FC = () => {
  const [employerId] = useStore<number>(UseStoreKeys.EMPLOYER_ID);
  const [employer, setEmployer] = React.useState<EmployerProfile | null>(null);
  const [externships, setExternships] = React.useState<ExternshipEntity[]>([]);

  const tableLayoutProps: DashboardTableLayoutProps = {
    pageTitle: 'Externship Timesheet Approver Dashboard',
    logo: undefined,
    ctaButton: undefined,
  };

  React.useEffect(() => {
    console.debug('ExternshipTimesheetApproverDashboard: useEffect called with employerId:', employerId);
    
    const fetchEmployer = async (employerId: number): Promise<void> => {
      const fetchedEmployer = await EmployerApi.getEmployerById(employerId);
      setEmployer(fetchedEmployer);
      console.debug('ExternshipTimesheetApproverDashboard: fetched employer:', fetchedEmployer);
    };

    const fetchExternships = async (employerId: number): Promise<void> => {
      const fetchedExternships = await EmployerApi.getExternshipsForEmployer(employerId);
      setExternships(fetchedExternships);
      console.debug('ExternshipTimesheetApproverDashboard: fetched externships:', fetchedExternships);
    };

    if (employerId) {
      Promise.all([
        fetchEmployer(employerId),
        fetchExternships(employerId),
      ]);
    }
  }, [employerId]);

  return (
    <RootPageLayout bgImgVariant={1}>
      <DashboardTableLayout {...tableLayoutProps} >
        {!!employer && !!externships?.length &&  (
          <Styled.ExternshipBox>
            <EmployerDashExternshipsSection
              employer={employer}
              externships={externships}
              setExternships={setExternships}
            />
          </Styled.ExternshipBox>
        )}
      </DashboardTableLayout>
    </RootPageLayout>
  );
};

export default ExternshipTimesheetApproverDashboard;