import * as React from 'react';
import { CandidateResume } from '../resumePreviewModal.models';
import ResumeCertificationSection from './ResumeCertificationSection';
import { ResumeComponentArray } from '../resumePreviewModal.models';
import ResumeEducationSection from './ResumeEducationSection';
import ResumeExperienceSection from './ResumeExperienceSection';
import ResumeOverviewSection from './ResumeOverviewSection';
import ResumePhotoSection from './ResumePhotoSection';
import ResumeSkillsSection from './ResumeSkillsSection';
import ResumeStatementSection from './ResumeStatementSection';

export const getResumeComponentArray = (
  resumeProfile: CandidateResume
): ResumeComponentArray[] => {
  const {
    overview,
    education,
    firstName,
    lastName,
    workExperience,
    certificates,
    candidateCulinaryPhotos,
    statement,
    skills
  } = resumeProfile;

  return [
    {
      id: 'overview',
      order: 0,
      component: (
        <ResumeOverviewSection
          firstName={firstName}
          lastName={lastName}
          overviewItems={overview.overviewItems.props}
          contactItems={overview.contactItems.props}
        />
      ),
      options: {
        title: 'Overview',
        items: [
          {
            props: overview.overviewItems.props,
            profileKey: 'overviewItems',
            displayValue: 'label'
          },
          {
            props: overview.contactItems.props,
            profileKey: 'contactItems',
            displayValue: 'label'
          }
        ]
      }
    },
    {
      id: 'skills',
      component: <ResumeSkillsSection skills={skills.props} />,
      hidden: resumeProfile['skills']['hidden'],
      order: resumeProfile['skills']['order'] ?? 1,
      options: {
        title: 'Skills',
        items: {
          props: skills.props,
          profileKey: 'skills',
          displayValue: 'label'
        }
      }
    },
    {
      id: 'workExperience',
      component: (
        <ResumeExperienceSection workExperience={workExperience.props} />
      ),
      hidden: resumeProfile['workExperience']['hidden'],
      order: resumeProfile['workExperience']['order'] ?? 2,
      options: {
        title: 'Work Experience',
        items: {
          props: workExperience.props,
          profileKey: 'workExperience',
          displayValue: 'company'
        }
      }
    },
    {
      id: 'education',
      component: <ResumeEducationSection education={education.props} />,
      hidden: resumeProfile['education']['hidden'],
      order: resumeProfile['education']['order'] ?? 3,
      options: {
        title: 'Education',
        items: {
          props: education.props,
          profileKey: 'education',
          displayValue: 'institution'
        }
      }
    },
    {
      id: 'certificates',
      component: (
        <ResumeCertificationSection certificates={certificates.props} />
      ),
      hidden: resumeProfile['certificates']['hidden'],
      order: resumeProfile['certificates']['order'] ?? 4,
      options: {
        title: 'Certifications',
        items: {
          props: certificates.props,
          profileKey: 'certificates',
          displayValue: 'label'
        }
      }
    },
    {
      id: 'candidateCulinaryPhotos',
      component: (
        <ResumePhotoSection
          candidateCulinaryPhotos={candidateCulinaryPhotos.props}
        />
      ),
      hidden: resumeProfile['candidateCulinaryPhotos']['hidden'],
      order: resumeProfile['candidateCulinaryPhotos']['order'] ?? 5,
      options: {
        title: 'Photos',
        items: {
          props: candidateCulinaryPhotos.props.slice(0, 16),
          profileKey: 'candidateCulinaryPhotos',
          // displayValue: ['file', 'fullPath']
          displayValue: 'fullPath'
        }
      }
    },
    {
      id: 'statement',
      component: <ResumeStatementSection {...statement.props[0]} />,
      hidden: resumeProfile['statement']['hidden'],
      order: resumeProfile['statement']['order'] ?? 6,
      options: {
        title: 'Statement',
        items: {
          props: [statement.props],
          profileKey: 'statement',
          displayValue: 'disableSection'
        }
      }
    }
  ];
};
