import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';

interface UseExternshipPilotEnabled {
  isExternshipPilotEnabled: boolean;
  setIsPilotEnabled: (value: boolean) => void;
}

export const useExternshipPilotEnabled = (): UseExternshipPilotEnabled => {
  const featureFlags = useFeatureFlags();
  const [isExternshipPilotEnabled, setIsExternshipPilotEnabled] =
    useStore<boolean>(UseStoreKeys.CANDIDATE_EXTERNSHIP_PILOT);

  const enableForAllStudents = true;

  const setIsPilotEnabled = (candidatePilotStatus?: boolean): void =>
    setIsExternshipPilotEnabled(
      !featureFlags.PILOT_EXTERNSHIPS
        ? enableForAllStudents
        : !!candidatePilotStatus
    );

  return { isExternshipPilotEnabled, setIsPilotEnabled };
};
