import * as React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import Carousel from '@components/Carousel';
import { MorePartners } from '@interfaces/morePartners.interfaces';
import { styled } from '@mui/system';
import { useFetchMoreWorkLearnPartners } from '@common/fetches/useFetchMoreWorkAndLearnPartners';

const MAX_ITEMS_PER_SLIDE = 4;

const Styled = {
  PartnerColumn: styled(Box)({
    flex: 1,
    height: '100%',
    minHeight: '224px',
    padding: '0px 16px'
  })
};

const WorkAndLearnMorePartnersSection: React.FC = () => {
  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));

  const { moreWorkLearnPartners } = useFetchMoreWorkLearnPartners();

  // Splits the array into arrays of 6 items
  const splitIntoChunksOfSix = (arr: any[]): any[] => {
    const chunkSize = 6;

    const chunks: any[] = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      chunks.push(chunk);
    }

    return chunks;
  };

  const PartnerColumn: React.FC<{ partners: MorePartners[] }> = ({
    partners
  }) => {
    return (
      <Styled.PartnerColumn>
        <BottomMarginContentWrapper bottomMargin="16px">
          {partners.map((partner) => (
            <Typography key={partner.id}>{partner.name}</Typography>
          ))}
        </BottomMarginContentWrapper>
      </Styled.PartnerColumn>
    );
  };

  const getPartnerColumns = (partners: MorePartners[]): JSX.Element[] => {
    const chunksOfSix = splitIntoChunksOfSix(partners);

    return chunksOfSix.map((partnerArr) => (
      <PartnerColumn key={partnerArr[0].id} partners={partnerArr} />
    ));
  };

  const itemsPerSlide = (): number => {
    if (isSmall) {
      return 1;
    }

    if (isMedium) {
      return 2;
    }

    if (isLarge) {
      return 3;
    }

    return MAX_ITEMS_PER_SLIDE;
  };

  if (!moreWorkLearnPartners.length) {
    return <></>;
  }

  return (
    <Box>
      <Typography variant="EC_TYPE_2XL" color="primary" mb={4} ml="44px">
        More Partners
      </Typography>

      <Carousel
        items={getPartnerColumns(moreWorkLearnPartners)}
        itemsPerSlide={itemsPerSlide()}
        useMaxWidth
      />
    </Box>
  );
};

export default WorkAndLearnMorePartnersSection;
