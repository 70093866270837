import * as React from 'react';
import { GlrcContent, GlrcTopic } from '@interfaces/glrc.interfaces';
import GlrcVideoPage from '@pages/Partner/GlrcVideoPage/GlrcVideoPage';
import LessonsAndCoursesSection from '@pages/Partner/LessonsAndCoursesSection';

interface Props {
  path: string;
  topics?: GlrcTopic[];
  userId: string;
}

const GlrcContentSection: React.FC<Props> = ({ path, topics, userId }) => {
  const courses: GlrcContent[] | undefined = React.useMemo(() => {
    if (topics) {
      if (path === 'courses') {
        return topics.flatMap((t) => t.fullCourses || []);
      }

      if (path === 'quick-courses') {
        return topics.flatMap((t) => t.microCourses || []);
      }
    }

    return undefined;
  }, [path, topics]);

  return (
    <>
      {!!courses && (
        <LessonsAndCoursesSection courses={courses} userId={userId} showAll />
      )}
      {path === 'videos' && !!topics && <GlrcVideoPage topics={topics} />}
    </>
  );
};

export default GlrcContentSection;
