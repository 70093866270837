/* eslint-disable @typescript-eslint/indent */
import {
  JobApplication,
  JobApplicationAppliedCandidate,
  JobApplicationCandidates,
  JobApplicationPostReq,
  JobApplicationStatusPutReq,
  JobApplicationStatusPutRes
} from './models/jobApplicationApi.models';
import { api } from './configs/base';
import { apiConfig } from './configs/api.config';
import { ApiResponse } from './models/base.models';

export class JobApplicationApi {
  static async createJobApplication(
    data: JobApplicationPostReq
  ): Promise<ApiResponse<JobApplication>> {
    try {
      const response = await api.post<ApiResponse<JobApplication>>(
        `${apiConfig.jobApplicationUrl}`,
        data
      );
      return response.data;
    } catch (error: any) {
      console.error(
        `Error for JobApplicationApi.createJobApplication(): ${error}`
      );
      throw error;
    }
  }

  static async getJobApplicationById(
    id: number
  ): Promise<ApiResponse<JobApplication>> {
    try {
      const response = await api.get<ApiResponse<JobApplication>>(
        `${apiConfig.jobApplicationUrl}/${id}`
      );

      return response.data;
    } catch (error: any) {
      console.error(
        `Error for JobApplicationApi.getJobApplicationById(): ${error}`
      );
      throw error;
    }
  }

  static async getJobApplicationsByCandidateId(
    id: number
  ): Promise<ApiResponse<JobApplication[]>> {
    try {
      const response = await api.get<ApiResponse<JobApplication[]>>(
        `${apiConfig.jobApplicationUrl}/candidate/${id}`
      );

      return response.data;
    } catch (error: any) {
      console.error(
        `Error for JobApplicationApi.getJobApplicationsByCandidateId(): ${error}`
      );
      throw error;
    }
  }

  static async getCandidatesByJobPostId(
    id: number
  ): Promise<ApiResponse<JobApplicationAppliedCandidate[]>> {
    try {
      const response = await api.get<
        ApiResponse<JobApplicationAppliedCandidate[]>
      >(`${apiConfig.jobApplicationUrl}/${id}/candidates`);

      return response.data;
    } catch (error: any) {
      console.error(
        `Error for JobApplicationApi.getCandidatesByJobPostId(): ${error}`
      );
      throw error;
    }
  }

  static async updateJobApplicationStatus(
    id: number,
    data: JobApplicationStatusPutReq
  ): Promise<ApiResponse<JobApplicationStatusPutRes>> {
    try {
      const response = await api.put<ApiResponse<JobApplicationStatusPutRes>>(
        `${apiConfig.jobApplicationUrl}/${id}/status`,
        data
      );
      return response.data;
    } catch (error: any) {
      console.error(
        `Error for JobApplicationApi.updateJobApplicationStatus(): ${error}`
      );
      throw error;
    }
  }

  static async checkIfCandidateAppliedForJob(
    jobPostId: number,
    candidateId: number
  ): Promise<ApiResponse<boolean>> {
    try {
      const response = await api.get<ApiResponse<boolean>>(
        `${apiConfig.jobApplicationUrl}/${jobPostId}/candidate/${candidateId}/check`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for JobPostApi.createJobPost(): ${error}`);
      throw error;
    }
  }

  static async getCandidatesByCompanyId(
    id: number
  ): Promise<ApiResponse<JobApplicationCandidates[]>> {
    try {
      const response = await api.get<ApiResponse<JobApplicationCandidates[]>>(
        `${apiConfig.jobApplicationUrl}/companies/${id}/candidates`
      );

      return response.data;
    } catch (error: any) {
      console.error(
        `Error for JobApplicationApi.getCandidatesByCompanyId(): ${error}`
      );
      throw error;
    }
  }

  static async getCandidateApplicationsForCompanyId(
    companyId: number,
    candidateId: number
  ): Promise<JobApplication[]> {
    try {
      const response = await api.get<ApiResponse<JobApplication[]>>(
        `${apiConfig.jobApplicationUrl}/companies/${companyId}/candidates/${candidateId}`
      );

      return response.data.data;
    } catch (error: any) {
      console.error(
        `Error for JobApplicationApi.getCandidateApplicationsForCompanyId(): ${error}`
      );
      throw error;
    }
  }

  static async updateJobApplicationExternalStatus(
    applicationId: number,
    externalStatus: boolean
  ): Promise<{
    message: string;
  }> {
    try {
      const response = await api.patch<{ message: string }>(
        `${apiConfig.jobApplicationUrl}/${applicationId}/external-status/${externalStatus}`
      );

      return response.data;
    } catch (error: any) {
      console.error(
        `Error for JobApplicationApi.updateJobApplicationExternalStatus(): ${error}`
      );
      throw error;
    }
  }
}
