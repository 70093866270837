/**
 * Function to create an object containing a subset of key/value pairs from another object.
 * Useful for passing large amounts of specific props to components.
 * @param keyArray array of strings that are included as keys of the object passed in as the second arg.
 * @param keyValObject the object containing values associated with keys in the string array, must not be undefined.
 * @returns an object containing only key value pairs matching strings in keyArray.
 * @example const someProps: PropType = pickProps(['a', 'b', 'd'], { a: 1, b: 2, c: 3, d: 4}); will create { a: 1, b: 2, d: 4 }
 */
export const pickProps = <T,>(keyArray: readonly string[], keyValObject: any): T => {
  const propArray: Partial<T> = keyArray.reduce((obj, key) => {
    obj[key] = keyValObject && keyValObject[key];
    return obj as keyof T;
  }, {});

  return propArray as Required<T>;
};