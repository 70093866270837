import * as React from 'react';
import { Box, Grid, Theme, Typography } from '@mui/material';
import {
  CourseType,
  GlrcContent,
  TopicTypes
} from '@interfaces/glrc.interfaces';
import {
  getGlrcTopicPath,
  getTopicTitle
} from '@pages/Partner/GlrcContent/glrc.helpers';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import FullCourseCard from '@pages/Partner/FullCourseCard';
import { getTextWithNewLines } from '@common/helpers/getTextWithNewLines';
import MicroCourseCard from '@pages/Partner/MicroCourseCard';
import { styled } from '@mui/system';

const Styled = {
  Title: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'showBorder'
  })<{ showBorder: boolean }>(({ showBorder, theme }) => ({
    color: theme.palette.primary.main,
    fontSize: (theme as Theme).typography.EC_TYPE_5XL.fontSize,
    lineHeight: (theme as Theme).typography.EC_TYPE_5XL.lineHeight,
    textTransform: 'uppercase',
    marginBottom: '24px',
    ...(showBorder && {
      paddingBottom: '16px',
      marginBottom: '24px',
      borderBottom: `1px solid ${theme.palette.GRAY_4.main}`
    })
  })),
  SubtitleWrapper: styled(Box)(({ theme }) => ({
    paddingBottom: '16px',
    marginBottom: '24px',
    borderBottom: `1px solid ${theme.palette.GRAY_4.main}`,
    '& > *:not(:last-child)': { marginBottom: '8px' }
  }))
};

interface Props {
  courses?: GlrcContent[];
  showAll?: boolean;
  userId: string;
}

interface SectionProps {
  courses?: GlrcContent[];
  title: string;
  subtitle?: string;
  containerMargin?: string;
  userId: string;
}

const Section = ({
  courses,
  title,
  subtitle,
  containerMargin,
  userId
}: SectionProps): JSX.Element | null => {
  if (courses?.length) {
    return (
      <Box sx={{ marginTop: containerMargin }}>
        <Styled.Title variant="EC_TYPE_3XL" showBorder={!subtitle}>
          {title}
        </Styled.Title>
        {subtitle && (
          <Styled.SubtitleWrapper>
            {getTextWithNewLines(subtitle)}
          </Styled.SubtitleWrapper>
        )}
        <Grid container spacing={3}>
          {courses?.map((course) =>
            course.courseType === CourseType.FULL ? (
              <Grid item key={course.id} xs={12} md={6} lg={6}>
                <FullCourseCard {...course} userId={userId} />
              </Grid>
            ) : (
              <Grid item key={course.id} xs={12} md={6} lg={4}>
                <MicroCourseCard {...course} userId={userId} />
              </Grid>
            )
          )}
        </Grid>
      </Box>
    );
  } else {
    return null;
  }
};

const LessonsAndCoursesSection: React.FC<Props> = ({
  courses = [],
  showAll = false,
  userId
}) => {
  const courseTopicMap = Object.values(TopicTypes);

  const filterCourses = React.useMemo(() => {
    const fullCourses = courses.filter(
      (course) => course.courseType === CourseType.FULL
    );
    const microCourses = courses.filter(
      (course) => course.courseType === CourseType.MICRO
    );

    return { fullCourses, microCourses };
  }, [courses]);

  const { fullCourses, microCourses } = filterCourses;

  const fullLength = fullCourses.length;
  const microLength = microCourses.length;
  const containerMargin = !!fullLength && !!microLength ? '51px' : '0';

  return (
    <Box>
      {showAll ? (
        <BottomMarginContentWrapper bottomMargin="91px">
          <>
            {courseTopicMap.map((topic, idx) => {
              const _courses = courses.filter(
                (course) =>
                  getGlrcTopicPath(course.contentTopic.label) === topic
              );
              const title = getTopicTitle(topic);

              return (
                <Section
                  key={topic + idx}
                  courses={_courses}
                  title={title}
                  userId={userId}
                />
              );
            })}
          </>
        </BottomMarginContentWrapper>
      ) : (
        <>
          <Section courses={fullCourses} title="courses" userId={userId} />
          <Section
            courses={microCourses}
            title="quick courses"
            userId={userId}
            containerMargin={containerMargin}
          />
        </>
      )}
    </Box>
  );
};

export default LessonsAndCoursesSection;
