import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import ExploreMoreSubwayMapNode from '@pages/Candidate/CareerPathPage/ExploreMore/SubwayMaps/ExploreMoreSubwayMapNode';
import { styled } from '@mui/system';
import { subwayTracks } from '@pages/Candidate/CareerPathPage/ExploreMore/SubwayMaps/subwayTracks';
import TabPanel from '@mui/lab/TabPanel';

const Styled = {
  TabPanel: styled(TabPanel)({
    padding: '0 4px 0 20px',
    display: 'flex',
    flexFlow: 'column nowrap'
  }),
  TrackItem: styled(Grid)({
    position: 'relative',
    '& svg': {
      position: 'absolute'
    },
    '& .left-track-line-vertical': {
      transform: 'translateY(-1px)'
    },
    '& .track-primary-connector': {
      transform: 'translate(-2px, -1px)'
    }
  }),
  TrackTitle: styled(Typography)({
    textTransform: 'uppercase',
    margin: '16px 0 35px 16px'
  })
};

const CareerPathExploreMoreSubwayLayout = (): React.ReactElement => {
  return (
    <Styled.TabPanel value="2">
      <Grid
        data-testid="explore-more-subway-layout-container"
        container
        spacing={3}
        justifyContent="end"
      >
        {subwayTracks.map((trackItem, idx) => (
          <Styled.TrackItem key={idx} item xs={12} sm={6} lg={4}>
            <Styled.TrackTitle variant="EC_TYPE_2XL">
              {trackItem.trackTitle} track
            </Styled.TrackTitle>
            {trackItem.roles.map((roleProps, idx) => {
              const finalIndex = trackItem.roles.length - 1;

              return (
                <Box
                  key={`${roleProps.roleName}-${idx}`}
                  data-testid="explore-more-subway-node-box"
                >
                  <ExploreMoreSubwayMapNode
                    index={idx}
                    finalIndex={finalIndex}
                    {...roleProps}
                  />
                </Box>
              );
            })}
          </Styled.TrackItem>
        ))}
      </Grid>
    </Styled.TabPanel>
  );
};

export default CareerPathExploreMoreSubwayLayout;
