import * as React from 'react';
import theme from '@themes/ui/escoffier';

const styles = {
  root: {
    marginTop: '24px'
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.EC_TYPE_2XL,
    marginBottom: '16px'
  },
  title: {
    ...theme.typography.EC_TYPE_2XL
  },
  hrWrapper: {
    flex: 1,
    ...theme.typography.EC_TYPE_2XL,
    marginLeft: '19px'
  },
  hr: {
    border: 'none',
    borderBottom: `0.5px solid ${theme.palette.GRAY_2.main}`
  }
};

interface Props {
  title: string;
  indent?: boolean;
}

const ResumeSection: React.FC<Props> = ({ title, indent, children }) => {
  return (
    <div style={styles.root}>
      <div style={styles.titleWrapper}>
        <div style={styles.title}>{title}</div>
        <div style={styles.hrWrapper}>
          <hr style={styles.hr} />
        </div>
      </div>
      <div style={{ marginLeft: indent ? '16px' : '0px' }}>{children}</div>
    </div>
  );
};

export default ResumeSection;
