import * as React from 'react';
import { CompanyApi } from '@api/Company.api';
import { JoinRequest } from '@api/models/companyApi.models';

export type FetchAdminJoinRequestsByUserId = {
  joinRequests: JoinRequest[];
  setJoinRequests: React.Dispatch<React.SetStateAction<JoinRequest[]>>;
  fetchJoinRequestsByUserId: () => Promise<void>;
  loaded: boolean;
  loading: boolean;
};

export const useFetchJoinRequestsByUserId = (
  userId: string
): FetchAdminJoinRequestsByUserId => {
  const [joinRequests, setJoinRequests] = React.useState<JoinRequest[]>([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchJoinRequestsByUserId = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await CompanyApi.getJoinRequestsByUserId(userId);

      setJoinRequests(res);
      setLoaded(true);
    } catch (error: any) {
      console.error(
        'Error for useFetchAdminJoinRequestsByUserId -> CompanyApi.getJoinRequestsByUserId()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    fetchJoinRequestsByUserId();
  }, [userId]);

  return {
    joinRequests,
    setJoinRequests,
    fetchJoinRequestsByUserId,
    loaded,
    loading
  };
};
