import * as React from 'react';
import {
  CandidateAccount,
  CandidateAccountsReq,
  CandidatesCsvReq,
  CSVFileType,
  GraduationTypes
} from '@api/models/adminDashboardApi.models';
import {
  getMultipleLocationsDisplay,
  withCellClickHandler
} from '@common/helpers/tableModalHelpers';
import { Table, TableBody, TableHead } from '@mui/material';
import BasicModal from '@components/BasicModal';
import BottomPagination from '@components/BottomPagination';
import { CodebookCountInterface } from '@api/models/codebook.models';
import CsvButton from '@components/CsvButton';
import { DateRange } from '@common/models/common.models';
import { SortOrderType } from '@interfaces/search.interfaces';
import { Styled } from './StyledTabledComponents';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchCandidateAccounts } from '@common/fetches/useFetchCandidateAccounts';
import { useFetchCandidateAccountsCsv } from '@common/fetches/csv/useFetchCandidateAccountsCsv';

interface RowProps {
  candidate: CandidateAccount;
}

const Row: React.FC<RowProps> = ({ candidate }) => {
  const {
    id,
    createdDate,
    profileComplete,
    firstName,
    lastName,
    email,
    phone,
    locations,
    campus,
    program
  } = candidate;

  const handleClick = (): void => {
    if (profileComplete) {
      window.open(`/candidate/${id}/profile`, '_blank');
    }
  };

  const cities = React.useMemo(() => {
    if (locations) {
      return getMultipleLocationsDisplay('city', locations);
    }
  }, [locations]);

  const states = React.useMemo(() => {
    if (locations) {
      return getMultipleLocationsDisplay('state', locations);
    }
  }, [locations]);

  const name = withCellClickHandler(`${firstName} ${lastName}`, handleClick);

  const cells = [
    name,
    email,
    phone,
    states,
    cities,
    program,
    campus,
    createdDate
  ];

  return (
    <Styled.TableRow>
      {cells.map((cell, idx) => (
        <Styled.TableCell key={idx}>{cell}</Styled.TableCell>
      ))}
    </Styled.TableRow>
  );
};

interface Props {
  open: boolean;
  handleClose: () => void;
  getCompletedProfiles?: boolean;
  dateRange: DateRange;
  graduationType?: CodebookCountInterface;
}

const AccountsModal: React.FC<Props> = ({
  open,
  handleClose,
  getCompletedProfiles,
  dateRange,
  graduationType
}) => {
  const defaultPageInfo = {
    rowsPerPage: 25,
    currentPage: 1,
    sortCategory: 'createdAt',
    sortOrder: 'DESC' as SortOrderType
  };

  const [req, setReq] = React.useState<CandidateAccountsReq>({
    pageInfo: defaultPageInfo,
    ...dateRange,
    ...(graduationType && { graduationTypeId: graduationType.value })
  });

  const [csvReq] = React.useState<CandidatesCsvReq>({
    ...dateRange,
    ...(graduationType && { graduationTypeId: graduationType.value })
  });

  const featureFlags = useFeatureFlags();

  const { fetchCandidateAccounts } = useFetchCandidateAccountsCsv(
    csvReq,
    !!getCompletedProfiles
  );

  const { candidates, pageInfo } = useFetchCandidateAccounts(
    req,
    !!getCompletedProfiles
  );

  const getCSVFileName = (isCompletedOnly: boolean): string => {
    if (graduationType) {
      switch (graduationType.value) {
        case GraduationTypes.EC_ALUMNI:
          return CSVFileType.EC_ALUMNI_ACCOUNTS;
        case GraduationTypes.EC_STUDENT:
          return CSVFileType.EC_STUDENT_ACCOUNTS;
        case GraduationTypes.NEITHER:
          return CSVFileType.NEITHER_ACCOUNTS;
        default:
          return '';
      }
    }

    switch (isCompletedOnly) {
      case false:
        return CSVFileType.CREATED_PROFILES;
      case true:
        return CSVFileType.COMPLETED_PROFILES;
      default:
        return '';
    }
  };

  const handlePagination = (event, value: number): void => {
    const newPageInfo = { ...req.pageInfo, currentPage: value };
    setReq({ ...req, pageInfo: newPageInfo });
  };

  const getTitle = (): string => {
    if (graduationType) {
      return graduationType.label;
    }
    if (getCompletedProfiles) {
      return 'Completed Profiles';
    }
    return 'Accounts Created';
  };

  const columns = [
    'Name',
    'Email',
    'Phone',
    'State',
    'City',
    'Program',
    'Campus',
    'Created'
  ];

  const csvHeaders = [
    { label: 'Id', key: 'id' },
    { label: 'Profile Completed', key: 'profileComplete' },
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Email', key: 'email' },
    { label: 'Phone', key: 'phone' },
    { label: 'Location', key: 'locations' },
    { label: 'Program', key: 'program' },
    { label: 'Campus', key: 'campus' },
    { label: 'Created Date', key: 'createdAt' }
  ];

  return (
    <BasicModal
      title={getTitle()}
      titleMarginBottom="0px"
      open={open}
      handleClose={handleClose}
    >
      {candidates && pageInfo && (
        <>
          {featureFlags.REPORTING_DASH && (
            <CsvButton
              headers={csvHeaders}
              fileName={getCSVFileName(!!getCompletedProfiles)}
              fetchData={fetchCandidateAccounts}
            />
          )}
          <Styled.TableContainer>
            <Table stickyHeader aria-label="accounts table">
              <TableHead>
                <Styled.TableRow className="table-head">
                  {columns.map((col) => (
                    <Styled.HeaderTableCell key={col}>
                      {col}
                    </Styled.HeaderTableCell>
                  ))}
                </Styled.TableRow>
              </TableHead>
              <TableBody>
                {candidates.map((row) => (
                  <Row key={row.id} candidate={row} />
                ))}
              </TableBody>
            </Table>
          </Styled.TableContainer>
          <BottomPagination
            count={pageInfo.totalPages}
            page={pageInfo.currentPage}
            onChange={handlePagination}
            defaultPage={1}
            wrapperSx={{
              marginTop: '35px'
            }}
          />
        </>
      )}
    </BasicModal>
  );
};

export default AccountsModal;
