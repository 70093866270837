import * as React from 'react';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '@themes/ui/variables';
import { Box } from '@mui/material';
import ResponsiveLayout from './ResponsiveLayout';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

// Defined here and in PreviewPage.tsx
const MOBILE_BREAKPOINT = 900;

// Only used here
const MOBILE_PADDING_BREAK = 680;

const MOBILE_SMALL_QUERY = 480;

const MOBILE_360_BREAK = 360;

const MEDIA_QUERY = `@media (max-width:${MOBILE_BREAKPOINT}px)`;

const PADDING_QUERY = `@media (max-width:${MOBILE_PADDING_BREAK}px)`;

const MOBILE_QUERY = `@media (max-width:${MOBILE_SMALL_QUERY}px)`;

const MOBILE_360_QUERY = `@media (max-width:${MOBILE_360_BREAK}px)`;

const Styled = {
  Container: styled(Box)({
    display: 'flex',
    margin: '0 auto',
    padding: 0,
    maxWidth: '1820px',
    height: 'max-content',
    [MEDIA_QUERY]: {
      flexDirection: 'column'
    }
  }),
  Primary: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'rightSidebarApplyActive'
  })(({ rightSidebarApplyActive }: { rightSidebarApplyActive?: boolean }) => ({
    flex: '1 1 840px',
    padding: '48px 65px',
    backgroundColor: rightSidebarApplyActive ? theme.palette.GRAY_1.main : '',
    [MEDIA_QUERY]: {
      padding: '60px 24px 48px 24px'
    },
    [MOBILE_QUERY]: {
      padding: '30px 16px 48px 16px'
    }
  })),
  Secondary: styled(Box)({
    flex: '0.75 1 601px',
    padding: '48px 0',
    display: 'flex',
    minHeight: `calc(100vh - (${FOOTER_HEIGHT}px + ${HEADER_HEIGHT}px))`,
    backgroundColor: theme.palette.GRAY_1.main,
    borderLeft: `1px solid ${theme.palette.GRAY_2.main}`,
    '& .secondary-div': {
      width: '85%',
      height: '100%',
      margin: '0 auto'
    }
  }),
  MobilePrimary: styled(Box)({
    flex: 1,
    padding: '30px 65px 48px 65px',
    [PADDING_QUERY]: {
      padding: '30px 28px 48px 28px'
    },
    [MOBILE_QUERY]: {
      padding: '30px 16px 48px 10px'
    },
    [MOBILE_360_QUERY]: {
      padding: '30px 10px 48px 10px'
    }
  }),
  MobileSecondary: styled(Box)({
    flex: 1,
    padding: '30px 65px 48px 65px',
    backgroundColor: theme.palette.GRAY_1.main,
    [PADDING_QUERY]: {
      padding: '30px 28px 48px 28px'
    },
    [MOBILE_QUERY]: {
      padding: '30px 16px 48px 10px'
    },
    [MOBILE_360_QUERY]: {
      padding: '30px 10px 48px 10px'
    }
  })
};

interface PreviewPageDataProps {
  primaryContent: React.ReactNode;
  secondaryContent?: React.ReactNode;
  activeStateContent?: React.ReactNode;
  rightSidebarApplyActive?: boolean;
}

const LayoutRightSidebar: React.FC<PreviewPageDataProps> = (
  props
): JSX.Element => {
  const {
    primaryContent,
    secondaryContent,
    activeStateContent,
    rightSidebarApplyActive
  } = props;

  return (
    <ResponsiveLayout
      customBreakpoint={MOBILE_BREAKPOINT}
      useMobileContainer={true}
      displayGutters={false}
      desktopComponent={
        <Styled.Container data-testid="layoutRightSidebarContainer">
          <Styled.Primary>{primaryContent}</Styled.Primary>
          <Styled.Secondary>
            <div className="secondary-div">
              {rightSidebarApplyActive === true
                ? activeStateContent
                : secondaryContent}
            </div>
          </Styled.Secondary>
        </Styled.Container>
      }
      mobileComponent={
        <Styled.Container data-testid="layoutRightSidebarContainer">
          <Styled.MobilePrimary>
            {rightSidebarApplyActive === true
              ? activeStateContent
              : primaryContent}
          </Styled.MobilePrimary>
          {!rightSidebarApplyActive && (
            <Styled.MobileSecondary>{secondaryContent}</Styled.MobileSecondary>
          )}
        </Styled.Container>
      }
    />
  );
};

export default LayoutRightSidebar;
