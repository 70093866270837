import * as React from 'react';
import { BadgeType, Codebook } from '@api/models/codebook.models';
import { availabilityMap } from '@common/select-field-labels/availability';
import CandidateEscoffierAffiliationForm from '@pages/Public/CreateAccountPage/CandidateEscoffierAffiliationForm';
import { GraduationTypes } from '@api/models/adminDashboardApi.models';
import { Grid } from '@mui/material';
import { IdentityProviders } from '@api/models/userApi.models';
import { notBeEmptyString } from '@common/rhf-validators/emptyString.validator';
import ProfileImageUpload from '@components/ProfileImageUpload';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import ReactHookFormsInputPhoneMask from '@components/Forms/ReactHookFormsInputPhoneMask';
import ReactHookFormsSelect from '@components/Forms/ReactHookFormsSelect';
import RhfLocationSearch from '@components/Forms/RhfLocationSearch';
import StepSection from '@components/StepSection';
import StepSubSection from '@components/StepSubSection';
import { useFetchPublicCodebooks } from '@common/fetches/useFetchPublicCodebooks';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';

type Sections = 'firstName' | 'lastName';

interface Props {
  files: any[];
  candidateAccount?: boolean;
  disableSections?: Sections[];
}

const PersonalInfoSection: React.FC<Props> = ({
  files,
  disableSections,
  candidateAccount = true
}) => {
  const [userIdpArray] = useStore<string[]>(UseStoreKeys.USER_IDP_ARRAY);
  const isStudentPortalIdp = userIdpArray?.includes(
    IdentityProviders.STUDENT_PORTAL
  );

  const { codebooks, loaded: codebooksLoaded } = useFetchPublicCodebooks();

  const availabilityOptions = Object.keys(availabilityMap).map((key) => ({
    label: availabilityMap[key],
    value: key
  }));

  const getDisabledStatus = (section: Sections): boolean => {
    return !!disableSections?.includes(section);
  };

  const isProd = process.env.REACT_APP_FEATURE_FLAG_ENV === 'prod';

  const _gradTypes = (): Codebook[] => {
    if (isProd) {
      return codebooks.graduationTypes.filter(
        (t) => t.value !== GraduationTypes.EC_STUDENT
      );
    } else {
      return codebooks.graduationTypes;
    }
  };

  return (
    <StepSection title="Personal Information">
      <StepSubSection title="Share your contact information and where you're located">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <ReactHookFormsInput
              name="firstName"
              dataTestId="about-step-firstName"
              label="First name"
              disabled={getDisabledStatus('firstName') || isStudentPortalIdp}
              rules={{
                required: getDisabledStatus('firstName')
                  ? false
                  : 'First name is required',
                validate: {
                  notBeEmptyString: notBeEmptyString('First name')
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReactHookFormsInput
              name="lastName"
              dataTestId="about-step-lastName"
              label="Last name"
              disabled={getDisabledStatus('lastName') || isStudentPortalIdp}
              rules={{
                required: getDisabledStatus('lastName')
                  ? false
                  : 'Last name is required',
                validate: {
                  notBeEmptyString: notBeEmptyString('Last name')
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <ReactHookFormsInputPhoneMask
                  name="phone"
                  label="Phone Number"
                  rules={{ required: 'Phone Number is required' }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <RhfLocationSearch
              label="Location"
              name="locations"
              rules={{ required: 'At least one location is required' }}
            />
          </Grid>
          {candidateAccount && (
            <>
              <Grid item xs={12} md={6}>
                <ReactHookFormsSelect
                  name="relocation"
                  label="Open to relocation?"
                  placeholder="Select yes or no"
                  options={[
                    { label: 'Yes', value: 'YES' },
                    { label: 'No', value: 'NO' }
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ReactHookFormsSelect
                  name="availability"
                  label="Availability"
                  placeholder="Select your availability"
                  options={availabilityOptions}
                  rules={{ required: 'Availability is required' }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </StepSubSection>
      {candidateAccount && (
        <StepSubSection title="Share your Escoffier Affiliation">
          <Grid container spacing={3}>
            {codebooksLoaded && (
              <CandidateEscoffierAffiliationForm
                graduationTypes={_gradTypes()}
                isStudentSso={isStudentPortalIdp}
              />
            )}
          </Grid>
        </StepSubSection>
      )}
      <StepSubSection title="Profile Photo">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ProfileImageUpload
              badgeType={BadgeType.CANDIDATE_BADGE}
              badgeName="badge"
              disableBadge={!candidateAccount}
              uploadWellProps={{
                name: 'photoId',
                displayText: 'Drop your photo here',
                validTypes: ['image/jpg', 'image/jpeg', 'image/png'],
                files
              }}
            />
          </Grid>
        </Grid>
      </StepSubSection>
    </StepSection>
  );
};

export default PersonalInfoSection;
