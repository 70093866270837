import * as React from 'react';

/**
 * Hook for creating custom context.
 * ex: const [useMyContext, CustomContextProvider] = createCustomContext<MyContextType>();
 * @returns createCustomContext hook.
 */
export function createCustomContext<ContextType>(): readonly [() => ContextType, React.Provider<ContextType | undefined>] {
  const customContext = React.createContext<ContextType | undefined>(undefined);
  function useCustomContext(): ContextType {
    const c = React.useContext(customContext);
    if (!c) {
      throw new Error(
        'useCustomContext must be inside a Provider with a value.'
      );
    }
    return c;
  }
  return [useCustomContext, customContext.Provider] as const;
}
