import * as React from 'react';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import { CareerServicesApi } from '@api/CareerService.api';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { getClosestDate } from '@common/helpers/dateHelpers/getClosestDate';
import { styled } from '@mui/system';
import { useFetchExternshipDates } from '@common/fetches/useFetchExternshipDates';

const Styled = {
  TopFiltersWrapper: styled(Box)({
    display: 'flex',
    alignItems: 'flex-end',
    gap: '24px'
  }),
  TopFilterFormControl: styled(FormControl)({
    minWidth: '150px'
  })
};

interface Props {
  year?: string;
  setYear: React.Dispatch<React.SetStateAction<string | undefined>>;
  currDate?: Date;
  setCurrDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
}

export const useExternshipStartDateFilter = (): Props => {
  // For externship start date selection to retrieve enrollments
  const [year, setYear] = React.useState<string>();
  const [currDate, setCurrDate] = React.useState<Date>();

  return {
    year,
    setYear,
    currDate,
    setCurrDate
  };
};

const ExternshipStartDateFilter: React.FC<Props> = ({
  year,
  setYear,
  currDate,
  setCurrDate
}) => {
  // Retrieve all externship start dates for the drop down filter
  const {
    externshipDatesByYear,
    years,
    loaded: externshipStartDateLoaded,
    savedExternshipDate
  } = useFetchExternshipDates();

  // Once externshipDatesByYear and years loads
  // set year to current year or latest year
  // set currDate to closest externship start date to today
  React.useEffect(() => {
    if (!!externshipDatesByYear && years?.length) {
      const todaysDate = new Date();
      const todaysYear = String(todaysDate.getFullYear());

      const datesInYear = externshipDatesByYear[todaysYear] || [];

      if (externshipStartDateLoaded) {
        const defaultYear = savedExternshipDate
          ? new Date(savedExternshipDate).getFullYear().toString()
          : todaysYear;

        setYear(defaultYear);

        setCurrDate(
          savedExternshipDate || getClosestDate(datesInYear, todaysDate)
        );
      }
    }
  }, [
    externshipDatesByYear,
    years,
    externshipStartDateLoaded,
    savedExternshipDate
  ]);

  const saveExternshipDatePreference = (date: Date): void => {
    CareerServicesApi.saveExternshipDatePreference(date);
  };

  React.useEffect(() => {
    if (currDate) {
      saveExternshipDatePreference(currDate);
    }
  }, [currDate]);

  const handleYearChange = (e: SelectChangeEvent<string>): void => {
    const selectedYear = e.target.value;
    const datesInYear = externshipDatesByYear[selectedYear];

    setYear(selectedYear);
    setCurrDate(datesInYear[datesInYear.length - 1]);
  };

  const handleDateChange = (e: SelectChangeEvent<string>): void => {
    setCurrDate(new Date(e.target.value));
  };

  return (
    <>
      {externshipStartDateLoaded && currDate && (
        <Box>
          <Typography
            variant="EC_TYPE_XS"
            component={'p'}
            color={'GRAY_4.main'}
            mb={'11px'}
          >
            SELECT AN EXTERNSHIP START DATE
          </Typography>

          <Select value={year} onChange={handleYearChange}>
            {years?.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
          <Styled.TopFilterFormControl>
            <Select value={currDate?.toISOString()} onChange={handleDateChange}>
              {year &&
                externshipDatesByYear[year]?.map((date, i) => {
                  return (
                    <MenuItem key={i} value={date.toISOString()}>
                      {formatDate(date.toISOString(), 'MMMM d', false, true)}
                    </MenuItem>
                  );
                })}
            </Select>
          </Styled.TopFilterFormControl>
        </Box>
      )}
    </>
  );
};

export default ExternshipStartDateFilter;
