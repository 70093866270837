import * as React from 'react';
import { CareerApi } from '@api/Career.api';
import { CareerRole } from '@interfaces/careerJourney.interfaces';
import { Codebook } from '@api/models/codebook.models';
import { CodebookApi } from '@api/Codebook.api';

export type FetchCareerRoles = {
  careerRoles: CareerRole[];
  careerRolesCodebook: Codebook[];
  loading: boolean;
  loaded: true | null;
};

type FetchType = 'careerRoles' | 'careerRolesCodebook';

export const useFetchCareerRoles = (fetchType: FetchType): FetchCareerRoles => {
  const [careerRoles, setCareerRoles] = React.useState<CareerRole[]>([]);
  const [careerRolesCodebook, setCareerRolesCodebook] = React.useState<
    Codebook[] // eslint-disable-line @typescript-eslint/indent
  >([]);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<true | null>(null);

  const fetchCareerRoles = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await CareerApi.getCareerRoles();
      const sortedRoles = res.data.sort((a, b) => a.name.localeCompare(b.name));

      setCareerRoles(sortedRoles);
    } catch (error: any) {
      console.error(
        'Error for useFetchCareerRoles -> CareerApi.getCareerRoles()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  const fetchCareerRolesCodebook = async (): Promise<void> => {
    try {
      setLoading(true);
      const res = await CodebookApi.getCandidateCodebooks();
      setCareerRolesCodebook(res.data.careerRoles);
    } catch (error: any) {
      console.error(
        'Error for useFetchCareerRoles -> CodebookApi.getCandidateCodebooks()',
        error
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    switch (fetchType) {
      case 'careerRoles':
        fetchCareerRoles();
        break;
      case 'careerRolesCodebook':
        fetchCareerRolesCodebook();
        break;
    }
  }, [fetchType]);

  return { careerRoles, loading, loaded, careerRolesCodebook };
};
