import * as React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Location } from '@interfaces/location.interfaces';

interface Props {
  locations: Location[];
  containerStyle?: React.CSSProperties;
}

const EcGoogleMap: React.FC<Props> = ({ locations, containerStyle }) => {
  const _locations = locations.map((l) => ({
    ...l,
    lat: Number(l.lat),
    lng: Number(l.lng)
  }));

  const style = containerStyle ?? { width: '100%', height: '300px' };

  if (!_locations[0].placeId) return <></>;
  return (
    <GoogleMap
      center={_locations[0]}
      zoom={_locations.length === 1 ? 15 : 11}
      mapContainerStyle={style}
      options={{
        zoomControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false
      }}
    >
      {_locations.map((loc) => (
        <Marker key={loc.placeId} position={{ lat: loc.lat, lng: loc.lng }} />
      ))}
    </GoogleMap>
  );
};

export default EcGoogleMap;
