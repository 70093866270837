import * as React from 'react';
import { KeycloakAppRole, KeycloakUserRole } from '@api/models/userApi.models';
import {
  AssignUserToESourceGroupDto,
  BulkAssignUserToGroupDto
} from '@interfaces/glrc.interfaces';
import {
  Box,
  Button,
  FormControl,
  Icon,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
  useTheme,
  Checkbox,
  ListItemText,
  ListSubheader
} from '@mui/material';
import {
  BulkUpdateUserRoleDto,
  Seat,
  SettingsEmployerSeat,
  UpdateUserRoleReq
} from '@api/models/settingsApi.models';
import {
  GridActionsCellItem,
  GridColDef,
  GridRowSelectionModel
} from '@mui/x-data-grid-pro';
import { allowedGridFilterOperators } from '@common/fetches/useFetchGridTableByType';
import { allSeatOptions } from './SeatApprovalsTabContent';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { convertEmployersToSeats } from '@common/fetches/useFetchSettings';
import EcDataGrid from '@components/DataGrid/EcDataGrid';
import EcDialog from '@components/EcDialog';
import EcGridTableToolbar from '@components/DataGrid/EcGridTableToolbar';
import { GlrcContentApi } from '@api/GlrcContent.api';
//import InviteUserBox from '../CompanySettings/InviteUserBox';
import { SettingsApi } from '@api/Settings.api';
import { styled } from '@mui/system';
import { useCompanySettingsContext } from '@common/context/companySettingsContext';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useHistory } from 'react-router-dom';
import { WHITE } from '@themes/ui/escoffier';
import { EmployerRole } from '@api/models/employerApi.models';
import { CompanyProfile } from '@api/models/companyApi.models';

const UNASSIGNED_ESOURCE_GROUP_VALUE = 'Unassigned';

const Styled = {
  Select: styled(Select)({
    height: '30px',
    width: '200px', // Set fixed width for consistency
    flex: '1 0 120px',
    fontSize: '12px',
    lineHeight: 'unset',
    backgroundColor: WHITE,
    margin: '2px',
    '& .MuiSelect-select': {
      textAlign: 'left',
      padding: '4px ',
    }
  }),
  GlrcSeatInfoWrapper: styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    columnGap: '16px',
    marginBottom: '24px'
  }),
  BulkAssignWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'visible'
  })<{ visible: boolean }>(() => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    gap: '16px',
    margin: '32px 0px',
    ['@media (max-width:599px)']: {
      flexDirection: 'column'
    }
  })),
  BulkAssignLabel: styled(Typography)(({ theme }) => ({
    marginBottom: '5px',
    textTransform: 'uppercase',
    color: theme.palette.grey4.main
  })),
  FormControl: styled(FormControl)({
    minWidth: '100px',
    ['@media (max-width:599px)']: {
      minWidth: 'unset'
    }
  })
};

interface RoleOption {
  value: EmployerRole;
  label: string;
}

const COMPANY_ADMIN = EmployerRole.COMPANY_ADMIN.toUpperCase();

// Add role options based on EmployerRole enum
const roleOptions: RoleOption[] = Object.entries(EmployerRole).map(([key, value]) => {
  key = (key === COMPANY_ADMIN) ? 'Manager' : key;

  return {
    value: value as EmployerRole,
    label: key.split('_').map(word => word.charAt(0) + word.slice(1).toLowerCase()).join(' ')
  };
});

const EMPLOYMENT_TYPES: EmployerRole[] = [
  EmployerRole.COMPANY_ADMIN,
  EmployerRole.EMPLOYEE,
  EmployerRole.NON_EMPLOYEE
];

const ADDITIONAL_ROLES: EmployerRole[] = [
  EmployerRole.RECRUITER,
  EmployerRole.ESOURCE_USER
];

const convertKeycloakUserRoleToEmployerRole = (keycloakRole: KeycloakUserRole): EmployerRole | undefined => {
  switch (keycloakRole) {
    case KeycloakUserRole.RECRUITER:
      return EmployerRole.RECRUITER;
    case KeycloakUserRole.GLRC_USER:
    case KeycloakUserRole.ESOURCE_USER:
      return EmployerRole.ESOURCE_USER;
    default:
      return undefined;
  }
};

interface SeatManagementTabContentProps {
  company: CompanyProfile,
  companySeats: Seat[],
  setCompanySeats: (seats: Seat[]) => void;
}

const SeatManagementTabContent: React.FC<SeatManagementTabContentProps> = ({
  company,
  companySeats,
  setCompanySeats,
}) => {
  const featureFlags = useFeatureFlags();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [openDialog, setOpenDialog] = React.useState(false);
  const [idForDelete, setIdForDelete] = React.useState<number>();
  const [executeDelete, setExecuteDelete] = React.useState(false);
  const [dialogContent, setDialogContent] = React.useState({});

  // For bulk role/esource group assignment
  const [bulkRoleSelection, setBulkRoleSelection] = React.useState<string>(
    allSeatOptions[0].value
  );
  const [bulkEsourceSelection, setBulkEsourceSelection] =
    React.useState<string>(UNASSIGNED_ESOURCE_GROUP_VALUE);
  const [savingBulkAssign, setSavingBulkAssign] =
    React.useState<boolean>(false);
  const [esourceSeatsRemaining, setEsourceSeatsRemaining] = React.useState<number>(0);

  // For MUI Data-Grid
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  const {
    //fetchSentInvites,
    openToast,
    esourceGroups,
  } = useCompanySettingsContext();
  const history = useHistory();

  React.useEffect(() => {
    if (company.glrcSeats > 0) {
      const esourceSeatsUsed: number = companySeats?.filter(seat => {
        const hasESourceRole = seat.keycloakUserRoles?.some(role => role.name === KeycloakAppRole.ESOURCE_USER);
        return hasESourceRole;
      })?.length || 0;
      const seatsRemaining = company.glrcSeats - esourceSeatsUsed;
      setEsourceSeatsRemaining(seatsRemaining);
    }
  }, [company, companySeats]);

  const getEmploymentType = (roles: string[]): string | undefined => {
    return roles.find(role => EMPLOYMENT_TYPES.includes(role as EmployerRole));
  };

  const getAdditionalRoles = (seat: Seat): EmployerRole[] => {
    return seat.keycloakUserRoles
      .map(role => convertKeycloakUserRoleToEmployerRole(role.name.replace('app-', '') as KeycloakUserRole))
      .filter((role): role is EmployerRole =>
        role !== undefined && ADDITIONAL_ROLES.includes(role)
      );
  };

  const isLastAdmin = (seat: Seat): boolean => {
    const adminSeats = companySeats?.filter((s) =>
      s.employerRoles?.includes(EmployerRole.COMPANY_ADMIN)
    );

    if (
      adminSeats.length === 1 &&
      seat.email.toLowerCase() === adminSeats[0].email.toLowerCase()
    ) {
      return true;
    }
    return false;
  };

  const handleDelete = async (employerId: number): Promise<void> => {
    const seatIdx = companySeats.findIndex((s) => s.employerId === employerId);

    if (isLastAdmin(companySeats[seatIdx])) {
      setDialogContent(lastAdminDeleteDialog);
    } else {
      setDialogContent(confirmDeleteDialog);
    }

    setOpenDialog(true);
    setIdForDelete(employerId);
  };

  const handleConfirm = (): void => {
    setOpenDialog(false);
    setExecuteDelete(true);
  };

  const handleClose = (): void => {
    setOpenDialog(false);
  };

  const handleRoleChange = async (
    e: SelectChangeEvent<string[]>,
    employerId: number
  ): Promise<void> => {
    const idx = companySeats.findIndex((s) => s.employerId === employerId);
    const seatToUpdate = companySeats[idx];

    let newValues: Set<string> = new Set<string>(e.target.value);

    const currentEmploymentType = getEmploymentType(seatToUpdate.employerRoles || []);

    // Check to see if there is more than one employment type in the array. If so, use the array with the old type removed
    const newValuesWithoutCurrentEmploymentType = Array.from(newValues).filter(role => role !== currentEmploymentType);
    if (newValuesWithoutCurrentEmploymentType.some(role => EMPLOYMENT_TYPES.includes(role as EmployerRole))) {
      if (currentEmploymentType === EmployerRole.COMPANY_ADMIN && isLastAdmin(seatToUpdate)) {
        setDialogContent(lastAdminRoleDialog);
        setOpenDialog(true);
        return;
      }
      newValues = new Set<string>(newValuesWithoutCurrentEmploymentType);
    }

    // Validate at least one role is selected
    if (Array.from(newValues).length === 0) {
      setDialogContent({
        content: 'At least one role must be selected.',
        confirmActionText: 'OK',
        handleConfirm: handleClose
      });
      setOpenDialog(true);
      return;
    }

    if (Array.from(newValues).includes(EmployerRole.ESOURCE_USER) &&
      !seatToUpdate?.employerRoles?.includes(EmployerRole.ESOURCE_USER) &&
      esourceSeatsRemaining <= 0
    ) {
      setDialogContent(glrcSeatsUnavailableDialog);
      setOpenDialog(true);
      return;
    }

    const updateUserRolesPostBody = {
      employerId: employerId,
      companyId: company.id,
      roles: Array.from(newValues),
    } as UpdateUserRoleReq;

    try {
      await SettingsApi.updateUserRoles(updateUserRolesPostBody);
      const seat = companySeats.find((s) => s.employerId === employerId);

      if (!seat?.keycloakUserRoles.map((role) => role.name).includes(KeycloakAppRole.ESOURCE_USER)) {
        await GlrcContentApi.assignESourceGroupToUser({
          employerId,
          groupId: undefined
        });
      }
      openToast('Successfully Updated Permissions');
    } catch (error: any) {
      console.error('Error for SettingsApi.updateUserRole()', error);
    }

    // get updated values for user
    // TODO get better component update flow
    try {
      const response = await SettingsApi.getUser(employerId);
      const settingsEmployerSeat: SettingsEmployerSeat = response.data;
      const seatRoles = settingsEmployerSeat.employer?.companyToEmployers?.filter(link => link.company.id === company.id)
        .map(link => link.employerRole);

      const updatedSeats = [...companySeats];
      updatedSeats[idx].employerRoles = seatRoles!;

      history.push(history.location.pathname);
    } catch (error: any) {
      console.error('Error for SettingsApi.getUser()', error);
    }
  };

  const handleESourceGroupChange = async (
    e: SelectChangeEvent<any>,
    employerId: number
  ): Promise<void> => {
    const seatIdx = companySeats.findIndex((s) => s.employerId === employerId);
    const groupId = e.target.value;

    const postBody: AssignUserToESourceGroupDto = {
      employerId,
      groupId: groupId === UNASSIGNED_ESOURCE_GROUP_VALUE ? undefined : groupId
    };
    try {
      const updatedEmployer = await GlrcContentApi.assignESourceGroupToUser(
        postBody
      );

      // Update UI with new role
      const updatedSeat = convertEmployersToSeats([updatedEmployer], company.id)[0];
      const updatedSeats = [...companySeats];
      updatedSeats[seatIdx] = updatedSeat;

      openToast('Successfully assigned ESource group');
    } catch (error) {
      console.error(
        'Error SeatManagementTabContent.handleESourceGroupChange()'
      );
    }
  };

  const handleBulkAssign = React.useCallback(async (): Promise<void> => {
    const employerIds = rowSelectionModel.map((rowId) => rowId as number);
    if (
      bulkRoleSelection !== KeycloakUserRole.EMPLOYER &&
      employerIds.length === companySeats.length
    ) {
      setDialogContent(oneAdminRequiredDialog);
      setOpenDialog(true);
    } else {
      const rolePostBody: BulkUpdateUserRoleDto = {
        name: bulkRoleSelection,
        employerIds
      };

      const groupPostBody: BulkAssignUserToGroupDto = {
        groupId:
          bulkEsourceSelection === UNASSIGNED_ESOURCE_GROUP_VALUE
            ? undefined
            : Number(bulkEsourceSelection),
        employerIds
      };

      try {
        setSavingBulkAssign(true);
        await SettingsApi.updateUserRolesBulk(rolePostBody);
        const updatedEmployers =
          await GlrcContentApi.bulkAssignESourceGroupToEmployers(groupPostBody);

        const updatedSeats = convertEmployersToSeats(updatedEmployers, company.id);

        setCompanySeats(updatedSeats);
        setRowSelectionModel([]);
      } catch (error) {
        console.error('Error: SeatManagementTabContent.handleBulkAssign()');
      } finally {
        setSavingBulkAssign(false);
      }
    }
  }, [bulkRoleSelection, bulkEsourceSelection, rowSelectionModel]);

  const confirmDeleteDialog = {
    content:
      'In order to remove this user from the account, you must first confirm this dialogue.',
    confirmActionText: 'Delete user',
    handleConfirm
  };

  const oneAdminRequiredDialog = {
    content:
      'At least one user must remain an admin. In order to continue, unselect an admin.',
    confirmActionText: 'Manage Seats',
    handleConfirm: handleClose
  };

  const lastAdminDeleteDialog = {
    content:
      'In order to remove this admin from the account, you must first assign admin permissions to another user.',
    confirmActionText: 'Manage Seats',
    handleConfirm: handleClose
  };

  const lastAdminRoleDialog = {
    content:
      'In order to update this admin to a recruiter, you must first assign admin permissions to another user.',
    confirmActionText: 'Manage Seats',
    handleConfirm: handleClose
  };

  const glrcSeatsUnavailableDialog = {
    content:
      'In order to update this user to a glrc-user, you must remove access from another user or request more seats as there are zero glrc seats remaining at this time.',
    confirmActionText: 'Manage Seats',
    handleConfirm: handleClose
  };

  const hasESourceRole = (seat: Seat): boolean => {
    if (!seat) {
      return false;
    }
    return seat.keycloakUserRoles?.some((role) => role.name === KeycloakAppRole.ESOURCE_USER);
  };

  const renderRoleSelect = (params: any): JSX.Element => {
    const seat = params.row;
    const currentEmployerRoles = seat.employerRoles || [];
    const currentEmploymentType = getEmploymentType(currentEmployerRoles);
    const currentAdditionalRoles = getAdditionalRoles(seat);

    // Combine all current roles for the value prop
    const combinedRoles = [...(currentEmploymentType ? [currentEmploymentType] : []), ...currentAdditionalRoles];

    return (
      <Styled.Select
        multiple
        onChange={(e: SelectChangeEvent<unknown>): Promise<void> =>
          handleRoleChange(e as SelectChangeEvent<string[]>, seat.employerId)
        }
        value={combinedRoles}
        renderValue={(selected: unknown): string => {
          if (Array.isArray(selected)) {
            const employmentType = getEmploymentType(selected);
            const additionalRoles = selected.filter(role =>
              ADDITIONAL_ROLES.includes(role)
            );

            const labels: string[] = [];

            if (employmentType) {
              const option = roleOptions.find(opt => opt.value === employmentType);
              if (option) {
                labels.push(option.label);
              }
            }

            additionalRoles.forEach(role => {
              const option = roleOptions.find(opt => opt.value === role);
              if (option) {
                labels.push(option.label);
              }
            });

            return labels.join(', ');
          }
          return '';
        }}
      >
        <ListSubheader>Employment Type</ListSubheader>
        {roleOptions
          .filter(opt => EMPLOYMENT_TYPES.includes(opt.value))
          .map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox
                checked={currentEmploymentType === option.value}
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        <ListSubheader>Additional Roles</ListSubheader>
        {roleOptions
          .filter(opt => ADDITIONAL_ROLES.includes(opt.value))
          .map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox
                checked={currentAdditionalRoles.includes(option.value)}
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
      </Styled.Select>
    );
  };

  const currentSeatColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      filterOperators: allowedGridFilterOperators,
      cellClassName: 'padded-cell' // Add this class
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      filterOperators: allowedGridFilterOperators,
      cellClassName: 'padded-cell' // Add this class
    },
    {
      field: 'role',
      headerName: 'Roles',
      align: 'left',
      width: 160,
      flex: 1,
      filterOperators: allowedGridFilterOperators,
      cellClassName: 'padded-cell', // Add this class
      valueGetter: (params): string => {
        const roles = params.row.employerRoles || [];
        const employmentType = getEmploymentType(roles);
        const additionalRoles = getAdditionalRoles(params.row);

        const labels: string[] = [];

        if (employmentType) {
          const option = roleOptions.find(opt => opt.value === employmentType);
          if (option) {
            labels.push(option.label);
          }
        }

        additionalRoles.forEach(role => {
          const option = roleOptions.find(opt => opt.value === role);
          if (option) {
            labels.push(option.label);
          }
        });

        return labels.join(', ');
      },
      renderCell: renderRoleSelect
    },
    {
      field: 'esource',
      headerName: 'ESource',
      align: 'left',
      width: 160,
      flex: 1,
      filterOperators: allowedGridFilterOperators,
      valueGetter: (params): string => {
        return params.row.e_source_group?.name || '';
      },
      renderCell: (params): JSX.Element => {
        if (!hasESourceRole(params.row)) {
          return <></>;
        }

        return (
          <Styled.Select
            onChange={(e: SelectChangeEvent<any>): Promise<void> =>
              handleESourceGroupChange(e, params.row.employerId)
            }
            value={params.row.esourceValue}
          >
            <MenuItem value={UNASSIGNED_ESOURCE_GROUP_VALUE}>
              {UNASSIGNED_ESOURCE_GROUP_VALUE}
            </MenuItem>
            {esourceGroups.map((opt) => (
              <MenuItem key={opt.id} value={opt.id}>
                {opt.name} {opt.id}
              </MenuItem>
            ))}
          </Styled.Select>
        );
      }
    },
    {
      field: 'actions',
      type: 'actions',
      width: 10,
      filterOperators: allowedGridFilterOperators,
      getActions: (params): JSX.Element[] => [
        <GridActionsCellItem
          key={params.id}
          icon={<Icon className="ri-delete-bin-6-line" />}
          label="Delete"
          onClick={(): Promise<void> => handleDelete(Number(params.id))}
        />
      ]
    }
  ];

  // Add state for grid rows
  const [dataGridRows, setDataGridRows] = React.useState<any[]>([]);

  // Add effect to update grid rows when seats change
  React.useEffect(() => {
    if (companySeats) {
      const rows = companySeats.map((seat) => {
        const roles = seat.employerRoles || [];

        return {
          ...seat,
          id: seat.employerId,
          employerRoles: roles, // Store as employerRoles to match the rest of the component
          esourceValue: seat.e_source_group?.id || UNASSIGNED_ESOURCE_GROUP_VALUE
        };
      });
      setDataGridRows(rows);
    }
  }, [companySeats, company]);

  React.useEffect(() => {
    if (executeDelete) {
      const deleteUser = async (): Promise<void> => {
        const postBody: UpdateUserRoleReq = {
          employerId: idForDelete ?? 0,
          companyId: company.id,
        };
        try {
          await SettingsApi.removeEmployerFromCompany(postBody);
          // Update both the parent state and local grid state
          const updatedSeats = companySeats.filter((seat) => seat.employerId !== idForDelete);
          setCompanySeats(updatedSeats);
          setDataGridRows(updatedSeats.map(seat => ({
            ...seat,
            id: seat.employerId,
            employerRoles: seat.employerRoles || [],
            esourceValue: seat.e_source_group?.id || UNASSIGNED_ESOURCE_GROUP_VALUE
          })));
          openToast('Successfully Deleted Seat');
        } catch (error: any) {
          console.error('Error for SeatManagementTabContent.deleteUser()', error);
        } finally {
          setExecuteDelete(false);
        }
      };
      deleteUser();
    }
  }, [executeDelete, idForDelete, company.id, companySeats, setCompanySeats]);

  React.useEffect(() => {
    if (!bulkRoleSelection.includes(KeycloakUserRole.GLRC_USER)) {
      setBulkEsourceSelection(UNASSIGNED_ESOURCE_GROUP_VALUE);
    }
  }, [bulkRoleSelection]);

  return (
    <>
      <BottomMarginContentWrapper bottomMargin="48px">
        {/* Remove for now until invites are updated with new invites infrastructure
        <InviteUserBox
          openToast={openToast}
          companyId={company.id}
          fetchSentInvites={fetchSentInvites}
        /> */}
        <Box>
          {featureFlags.PARTNER_EXPERIENCE && (
            <Styled.GlrcSeatInfoWrapper>
              <Typography component={'p'} variant="EC_TYPE_XS">
                Total ESource Seats: {company.glrcSeats}
              </Typography>
              <Typography component={'p'} variant="EC_TYPE_XS">
                Available ESource Seats: {esourceSeatsRemaining}
              </Typography>
            </Styled.GlrcSeatInfoWrapper>
          )}
          {!!rowSelectionModel.length && (
            <Styled.BulkAssignWrapper visible={!!rowSelectionModel.length}>
              <Styled.FormControl fullWidth={isMobile}>
                <Styled.BulkAssignLabel variant="EC_TYPE_XS">
                  Role
                </Styled.BulkAssignLabel>
                <Select
                  value={bulkRoleSelection}
                  onChange={(e: SelectChangeEvent<any>): void => {
                    setBulkRoleSelection(e.target.value);
                  }}
                >
                  {roleOptions.map((opt) => (
                    <MenuItem key={opt.value} value={opt.value}>
                      <Checkbox checked={bulkRoleSelection === opt.value} />
                      <ListItemText primary={opt.label} />
                    </MenuItem>
                  ))}
                </Select>
              </Styled.FormControl>
              {bulkRoleSelection.includes(KeycloakUserRole.GLRC_USER) && (
                <Styled.FormControl fullWidth={isMobile}>
                  <Styled.BulkAssignLabel variant="EC_TYPE_XS">
                    ESource
                  </Styled.BulkAssignLabel>
                  <Select
                    value={bulkEsourceSelection}
                    onChange={(e: SelectChangeEvent<any>): void => {
                      setBulkEsourceSelection(e.target.value);
                    }}
                  >
                    <MenuItem value={UNASSIGNED_ESOURCE_GROUP_VALUE}>
                      {UNASSIGNED_ESOURCE_GROUP_VALUE}
                    </MenuItem>
                    {esourceGroups.map((opt) => (
                      <MenuItem key={opt.id} value={opt.id}>
                        {opt.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Styled.FormControl>
              )}
              <Button
                disabled={savingBulkAssign}
                variant="contained"
                color="primary"
                sx={{ height: '45px', typography: 'EC_TYPE_SM', color: WHITE }}
                fullWidth={isMobile}
                onClick={handleBulkAssign}
              >
                Assign
              </Button>
            </Styled.BulkAssignWrapper>
          )}
          <EcDataGrid
            // checkboxSelection // Turn off Bulk selection for now.
            autoHeight={true}
            disableRowSelectionOnClick
            columns={currentSeatColumns}
            onRowSelectionModelChange={(newRowSelectionModel): void => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rows={dataGridRows}
            slots={{
              toolbar: EcGridTableToolbar
            }}
            sx={{
              '& .padded-cell': {
                padding: '8px 16px' // Add padding to all cells
              }
            }}
          />
        </Box>
      </BottomMarginContentWrapper>
      <EcDialog
        dataTestId="manage-seats-dialog"
        title="Seat Management"
        open={openDialog}
        handleClose={handleClose}
        {...dialogContent}
      />
    </>
  );
};

export default SeatManagementTabContent;
