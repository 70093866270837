import * as React from 'react';
import { createCustomContext } from '@common/context/customContext';
import { ExternshipFilter } from '@pages/CareerServices/StudentPage/studentViewFilters.constants';
import { StudentViewExternship } from '@api/models/externshipApi.models';

interface StudentViewContext {
  currentFilter: ExternshipFilter;
  setCurrentFilter: React.Dispatch<
    React.SetStateAction<ExternshipFilter> // eslint-disable-line @typescript-eslint/indent
  >;
  allExternships: StudentViewExternship[];
  setAllExternships: React.Dispatch<
    React.SetStateAction<StudentViewExternship[]> // eslint-disable-line @typescript-eslint/indent
  >;
  externships: StudentViewExternship[];
  setExternships: React.Dispatch<
    React.SetStateAction<StudentViewExternship[]> // eslint-disable-line @typescript-eslint/indent
  >;
}

export const [useStudentViewContext, StudentViewProvider] =
  createCustomContext<StudentViewContext>();

export const withStudentViewContext = (
  Component: React.ComponentType<any>,
  externships: StudentViewContext['externships'],
  setExternships: StudentViewContext['setExternships'],
  allExternships: StudentViewContext['allExternships'],
  setAllExternships: StudentViewContext['setAllExternships'],
  currentFilter: StudentViewContext['currentFilter'],
  setCurrentFilter: StudentViewContext['setCurrentFilter']
): React.ComponentType<any> => {
  const WithStudentViewContext = (props: any): JSX.Element => (
    <StudentViewProvider
      value={{
        externships,
        setExternships,
        allExternships,
        currentFilter,
        setCurrentFilter,
        setAllExternships
      }}
    >
      <Component {...props} />
    </StudentViewProvider>
  );

  return WithStudentViewContext;
};
