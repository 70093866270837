import * as React from 'react';
import WorkAndLearnContent from '@pages/EcAdmin/ContentManagementPage/WorkAndLearnContent/WorkAndLearnContent';
import WorkAndLearnMorePartners from '@pages/EcAdmin/ContentManagementPage/WorkAndLearnContent/WorkAndLearnMorePartners';

const ManageWorkAndLearnContent: React.FC = () => {
  return (
    <>
      <WorkAndLearnContent />
      <WorkAndLearnMorePartners />
    </>
  );
};

export default ManageWorkAndLearnContent;
