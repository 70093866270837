import { useEffect, useState } from 'react';
import { JobApplicationApi } from '@api/JobApplication.api';

interface HasCandidateAppliedToJob {
  hasCandidateAppliedToJob: boolean;
  loading: boolean;
  loaded: true | null;
}

export const useFetchHasCandidateAppliedToJob = (
  jobId: string,
  candidateId: number | null
): HasCandidateAppliedToJob => {
  const [hasCandidateAppliedToJob, setHasCandidateAppliedToJob] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState<true | null>(null);
  const _jobId = parseInt(jobId);

  useEffect(() => {
    if (candidateId) {
      const fetchData = async (): Promise<void> => {
        try {
          setLoading(true);
          const res = await JobApplicationApi.checkIfCandidateAppliedForJob(
            _jobId,
            candidateId
          );

          setHasCandidateAppliedToJob(res.data);
        } catch (error: any) {
          console.error(
            'Error for useFetchHasCandidateAppliedToJob -> JobApplicationApi.checkIfCandidateAppliedForJob()',
            error
          );
        } finally {
          setLoading(false);
          setLoaded(true);
        }
      };

      fetchData();
    } else {
      setLoaded(true);
    }
  }, [candidateId]);

  return { hasCandidateAppliedToJob, loading, loaded };
};
