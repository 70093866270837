import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useHistory } from 'react-router-dom';
import { useResponsiveLayoutContext } from '@components/Layout/ResponsiveLayout';

const Styled = {
  Root: styled(Box, {
    shouldForwardProp: (prop) =>
      prop !== 'customBreakpoint' && prop !== 'customBreakpointReached'
  })<{ customBreakpoint: number; customBreakpointReached: boolean }>(
    ({ customBreakpoint, customBreakpointReached }) => ({
      position: 'relative',
      marginTop: '187px',
      padding: '42px 0',
      minWidth: '358px',
      maxWidth: '566px',
      maxHeight: '907px',
      flex: !customBreakpointReached ? '0 1 566px' : '1',
      [`@media (max-width:${customBreakpoint}px)`]: {
        marginTop: 'unset',
        maxWidth: 'unset',
        minWidth: 'unset'
      }
    })
  ),
  Card: styled(Box)({
    margin: '0 auto',
    backgroudColor: 'transparent',
    minWidth: '336px',
    width: '77%',
    ['@media (max-width:768px)']: {
      width: '97%'
    },
    ['@media (max-width:350px)']: {
      width: '290px',
      minWidth: 'unset'
    },
    '& > *:not(:last-child)': {
      marginBottom: '75px'
    }
  }),
  CardContent: styled(Box)({
    marginBottom: '50px',
    paddingLeft: '32px',
    paddingRight: '32px',
    '& i': {
      fontSize: '22px',
      verticalAlign: 'text-bottom'
    },
    '& .career-center-cta': {
      height: '47px',
      width: '100%',
      maxWidth: '266px',
      marginTop: '26px'
    }
  }),
  ContentTitle: styled(Typography)({
    marginBottom: '16px',
    '& span': {
      marginRight: '14px'
    }
  })
};

interface CardSectionProps {
  icon: string;
  title: string;
  text: string;
  btnText: string;
  href: string;
}

const CardSection = ({
  icon,
  title,
  text,
  btnText,
  href
}: CardSectionProps): JSX.Element => {
  const history = useHistory();
  const handleCtaClick = (): void => {
    history.push(href);
  };

  return (
    <Styled.CardContent data-testid="card-section">
      <Styled.ContentTitle variant="EC_TYPE_LG">
        <span>
          <i className={icon} />
        </span>
        {title}
      </Styled.ContentTitle>
      <Typography variant="EC_TYPE_BASE">{text}</Typography>
      <Button
        variant="contained"
        className="career-center-cta"
        onClick={handleCtaClick}
        fullWidth
      >
        {btnText}
      </Button>
    </Styled.CardContent>
  );
};

const CareerCenter: React.FC = () => {
  const { customBreakpoint, customBreakpointReached } =
    useResponsiveLayoutContext();

  return (
    <Styled.Root
      data-testid="career-center-root-container"
      customBreakpoint={customBreakpoint}
      customBreakpointReached={customBreakpointReached}
    >
      <Styled.Card>
        <CardSection
          icon="ri-file-user-line"
          title="Manage Your Profile"
          text="Keep your profile up-to-date so that you're matched to employers that meet your lifestyle needs and career goals."
          btnText="Manage Profile"
          href={'/candidate/profile-setup'}
        />
        <CardSection
          icon="ri-briefcase-fill"
          title="Find Your Next Job"
          text="Looking for a new opportunity? Check out EConnect and start applying to jobs you think would be a good match."
          btnText="See EConnect"
          href="/econnect"
        />
      </Styled.Card>
    </Styled.Root>
  );
};

export default CareerCenter;
