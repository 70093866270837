import { Codebook } from '@api/models/codebook.models';
import { FileType } from '@api/models/fileApi.models';

export enum AttestationType {
  FULL_TIME = 1,
  PART_TIME = 2,
  SELF_EMPLOYMENT = 3,
  PLACEMENT_WAIVER = 4,
  OUT_OF_SCHOOL = 5
}

export enum AttestationEmploymentType {
  PART_TIME = 1,
  TEMP = 2
}

export enum AttestationWaiverType {
  MEDICAL = 1,
  INCARCERATION = 2,
  MILITARY = 3,
  NO_INTENT = 4,
  OTHER_SCHOOL = 5
}

export enum AttestationStatus {
  NOT_ELIGIBLE = 10,
  ELIGIBLE = 20,
  SENT = 30,
  OPENED = 40,
  COMPLETED = 50
}

export const attestationWaiverMap = {
  [AttestationWaiverType.MEDICAL]: 'Medical Condition (including pregnancy)',
  [AttestationWaiverType.INCARCERATION]: 'Incarceration',
  [AttestationWaiverType.MILITARY]: 'Military',
  [AttestationWaiverType.NO_INTENT]:
    'Not intending to seek employment in the industry',
  [AttestationWaiverType.OTHER_SCHOOL]: 'Continuing education at another school'
};

export const attestationEmploymentMap = {
  [AttestationEmploymentType.PART_TIME]: 'Part-Time',
  [AttestationEmploymentType.TEMP]: 'Temporary'
};

export interface AttestationEntity {
  id: number;
  type: Codebook<AttestationType>;
  externshipId: number; 
  employmentStartDate?: Date | null;
  jobTitle?: string | null;
  // might end up being file type
  signature?: string | null;
  // for part-time or temporary only
  employmentType?: Codebook<AttestationEmploymentType> | null;
  companyName?: string | null;
  waiverType?: Codebook<AttestationWaiverType> | null;
  file?: FileType | null;
  completedAt?: Date | null;
}
