import * as React from 'react';
import LegalContentSection from '@components/LegalContentSection';
import LegalPageWrapper from '@components/LegalPageWrapper';

const GoldCrownPartnershipTermsAndConditionsPage: React.FC = () => {
  return (
    <LegalPageWrapper title="Gold Crown Terms and Conditions">
      <LegalContentSection>
        <p>
          <span>&ldquo;</span>
          <strong>
            <span>Provider</span>
          </strong>
          <span>&rdquo; has the meaning set forth in the Cover page.</span>
        </p>
        <p>
          <span>&ldquo;</span>
          <strong>
            <span>Client</span>
          </strong>
          <span>
            &rdquo; means the party or parties (who will be jointly and
            severally liable to the Service Provider) to whom Provider is to
            provide services pursuant to this Agreement.
          </span>
        </p>
        <p>
          <span>1.&nbsp;</span>
          <span>SUBSCRIBED SERVICES, GRANT OF LICENSE</span>
        </p>
        <p>
          <span>
            1.1 Provider will make the Services available to Client via
            password-protected online access accessible by the Service Recipient
            with usernames and passwords. &nbsp;The Service Provider grants to
            Client a non-exclusive, non-transferrable license to access and use
            the Services in accordance with this Agreement and during the Term
            of this Agreement.&nbsp;
          </span>
        </p>
        <p>
          <span>
            1.2 The Services will be provided as they exist and are updated and
            amended throughout the Term. &nbsp;Certain portions of the Services
            may be provided by third party licensors, and the Provider&rsquo;s
            ability to provide such information may be subject to the
            willingness of such licensors to continue to contract with Provider.
            &nbsp;Features are provided &ldquo;as is&rdquo; and as they may be
            modified, supplemented, or removed from time to time in
            Provider&rsquo;s sole discretion. &nbsp; Provider shall have no
            liability to Client for any modification to any Service, provided
            that the product or service provided substantially conforms to the
            description in the Cover Page.
          </span>
        </p>
        <p>
          <span>
            1.3 Ownership. &nbsp;Client acknowledges and agrees that, as between
            Provider and Client, all Provider software and information and
            related documentation are the property of Provider, whether or not
            they are trademarked, copyrighted, or patented, and the Service
            Recipient expressly disclaims and waives any and all claims to any
            ownership interest in any such software or information.
            &nbsp;Provider, its affiliates and/or its licensors reserve all
            right, title, and interest in and to such software and information,
            including all related intellectual property rights. &nbsp;No rights
            are granted to Client hereunder other than as expressly set forth
            herein. &nbsp; All other trademarks, registered trademarks, product
            names, and company names or logos are the property of their
            respective owners.
          </span>
        </p>
        <p>
          <span>1.4 Third Party Applications. &nbsp;&ldquo;</span>
          <strong>
            <span>Third Party Applications</span>
          </strong>
          <span>
            &rdquo; means computer software programs and other technology that
            are provided or made available to Client by third parties.
            &nbsp;Provider is not responsible for and does not endorse any Third
            Party Applications. &nbsp;Client shall not integrate any such
            programs or technology into any software or other technology.
          </span>
        </p>
        <p>
          <span>
            1.5 Support. &nbsp;Provider will provide reasonable assistance and
            ongoing support to assist Client in connection with its receipt of
            Services. &nbsp;&nbsp;
          </span>
        </p>
        <p>
          <span>2. AUTHORIZED USE</span>
        </p>
        <p>
          <span>
            2.1 Client shall be responsible for its users&apos; compliance with
            the terms of this Agreement. &nbsp;Client acknowledges and agrees
            that each user must provide Provider with certain identifying
            information, including their name and a business email address, and
            that each user may be required to accept an end-user license
            agreement agreeing to Provider&rsquo;s privacy policy and terms of
            use and representing that they are authorized to access the Services
            on Client&rsquo;s behalf.
          </span>
        </p>
        <p>
          <span>
            2.3 Client shall not access or use the Services for the benefit of
            or on behalf of any person or entity except Client. &nbsp;Client
            shall not incorporate the Services into Client&rsquo;s own products
            or services. &nbsp; Upon expiration or termination of this Agreement
            for any reason, Client shall cease accessing the Services. &nbsp;
          </span>
        </p>
        <p>
          <span>
            2.4 Client will not (a) reverse assemble, reverse engineer,
            decompile, or otherwise attempt to derive source code from any of
            Provider&apos;s software or information; (b) reproduce, modify,
            create, or prepare derivative works of any of any of Provider&apos;s
            software or information or related documentation; (c) distribute or
            display any of Provider&apos;s software or information; (d) share,
            sell, rent, or lease or otherwise distribute access to any of
            Provider&apos;s software or information, or use any of
            Provider&apos;s software or information to operate any timesharing,
            service bureau, or similar business; (e) create any security
            interest in any of Provider&apos;s software or information; (f)
            alter, destroy, or otherwise remove any proprietary notices or
            labels on or embedded within or on any of Provider&apos;s software
            or information or related documentation; (g) disclose the results of
            any of Provider&apos;s software or information or program benchmark
            tests to any third parties without Provider&rsquo;s prior written
            consent; or (i) use automated means, such as bots or crawlers, to
            access any of Provider&apos;s software or information (except as are
            expressly approved in advance in writing by Provider). &nbsp; Client
            may use the Services only in accordance with this Agreement and not
            for the benefit of any third party, except with Provider&rsquo;s
            express prior written permission.
          </span>
        </p>
        <p>
          <span>
            2.5 Limitations on Use of the Services. &nbsp;Client professional
            manner consistent with the intended and permissible uses herein and
            consistent with standard industry practice. &nbsp;Client shall not
            override or circumvent, or attempt to override or circumvent, any
            security feature, control, or use limits of any of Provider&apos;s
            software or information. &nbsp;Client will not use the Services for
            any purpose not permitted under this Agreement. &nbsp; Provider may
            use technological means to place reasonable use limits to prohibit
            excessive use, including excessive downloads or screen views that
            indicate a violation of this Agreement, such as sharing with third
            parties or attempting to circumvent limitations. &nbsp;If
            Client&rsquo;s access to the Services is limited under this
            paragraph, it may request that the limit be removed, and Provider
            may remove or modify a particular limitation if it determines in its
            sole and absolute discretion that the proposed use by Client is in
            good faith and otherwise consistent with this Agreement.
          </span>
        </p>
        <p>
          <span>
            2.6 2.7 Client Data. &nbsp;Client is solely responsible for all
            data, graphics, images, files, information, text, voice content,
            recordings, and other content and materials that are collected,
            uploaded, posted, delivered, provided, or otherwise transmitted or
            stored by Client in connection with its use of the Services
            (collectively, &ldquo;
          </span>
          <strong>
            <span>Client Data</span>
          </strong>
          <span>
            &rdquo;), and Client represents and warrants that it has all rights
            and authority necessary to provide Client Data to Provider without
            violation of any third party rights, including without limitation,
            any privacy rights, publicity rights, copyrights, trademarks,
            contract rights, or any other intellectual property or proprietary
            rights. &nbsp;Client shall be solely responsible for making any
            required notices (including without limitation any privacy notices
            required by applicable local, state, federal, and international laws
            and regulations) and for obtaining any required consents sufficient
            to authorize Provider&rsquo;s performance of its obligations and
            exercise of its rights as set forth in this Agreement.
          </span>
        </p>
        <p>
          <span>
            2.8 Unauthorized Access and Use. &nbsp;In the event Provider has a
            reasonable belief that Client is engaged in or facilitated any
            unauthorized access or use of the Services in violation of this
            Agreement, Provider, in its sole discretion, may immediately suspend
            Client&rsquo;s access until such violation is resolved to
            Provider&rsquo;s reasonable satisfaction. &nbsp; Provider will have
            no liability to Client for such period of suspension and a
            suspension shall have no effect on the Term of this Agreement nor on
            Client&rsquo;s obligation to pay the Subscription Fee.
          </span>
        </p>
        <p>
          <span>3. TERM AND TERMINATION</span>
        </p>
        <p>
          <span>
            3.1 Term. &nbsp;The Initial Term of the Agreement is that which is
            set forth in the Cover Page (together with any period of extension
            under Section 3.2 hereof, the &ldquo;
          </span>
          <strong>
            <span>Term</span>
          </strong>
          <span>
            &rdquo;). &nbsp;This Agreement is not cancellable and shall remain
            in effect until it expires or is earlier terminated according to its
            terms.
          </span>
        </p>
        <p>
          <span>
            3.2 Automatic Extension of the Term. &nbsp;On the date that is 60
            days prior to the last day of the Term (the &ldquo;
          </span>
          <strong>
            <span>Extension Date</span>
          </strong>
          <span>
            &rdquo;), the Term will automatically extend for a period equal to
            the length of the Initial Term or one year, whichever is longer,
            unless either party, on or before the Extension Date, notifies the
            other in writing that the Term shall not so extend. &nbsp;In the
            event that the Term is extended under this paragraph: (a) the
            Service Recipient shall remain subscribed during such period of
            extension to the Services to which it was subscribed as of the
            Extension Date, and (b) the Subscription Fees to be paid to the
            Service Provider for such Services during such period of extension
            shall be equal to the annualized amount of the Subscription Fee
            applicable to all Services to which the Service Recipient was
            subscribed as of the Extension Date, plus the stated costs of any
            Services for which the fees were waived for the first year only,
            plus 10% of such fee, plus any applied discount, multiplied by the
            length of the term in years. &nbsp; Subscription Fees for the period
            of extension hereunder shall be due upon extension of the Term and
            shall be payable as invoiced. &nbsp;The Service Provider will
            invoice Subscription Fees for any period of extension on an annual
            basis or in a manner substantially consistent with the payment
            schedule that applied to the Agreement as of the Extension Date, in
            the Service Provider&rsquo;s discretion.
          </span>
        </p>
        <p>
          <span>
            3.3 Termination. &nbsp; Either party may terminate this Agreement
            immediately, without further obligation to the other party, in the
            event of a material breach of this Agreement by the other party that
            is not remedied within twenty-one (21) days after the breaching
            party&rsquo;s receipt of written notice of such breach. The parties
            may terminate this Agreement at any time upon their mutual
            Agreement.&nbsp;
          </span>
        </p>
        <p>
          <span>3.4 Effect of Termination.&nbsp;</span>
        </p>
        <p>
          <span>
            3.4.1 &nbsp;Expiration or Termination for any Reason. Upon
            expiration or termination of this Agreement for any reason, the
            Service Recipient acknowledges and agrees that its access to the
            Services may be automatically terminated, all passwords and
            individual accounts removed, and all information that has been
            uploaded into Provider&rsquo;s systems by Client destroyed.
            &nbsp;Upon expiration or termination of this Agreement for any
            reason, unless otherwise provided herein, the Service Recipient
            agrees to destroy any and all copies any of Provider&apos;s software
            or information it has obtained from Provider, whether in hard copy
            or electronic form.&nbsp;
          </span>
        </p>
        <p>
          <span>
            3.4.2 &nbsp;Termination by the Provider. &nbsp;If this Agreement is
            terminated by Provider due to an uncured material breach by Client,
            all Subscription Fees payable to be paid to the Service Provider for
            the remainder of the then-current Term shall be immediately due and
            payable to Provider, and Client shall promptly remit all such fees
            to the Service Provider.&nbsp;
          </span>
        </p>
        <p>
          <span>
            3.4.3. &nbsp;Termination by Service Recipient. If this Agreement is
            terminated by Client due to an uncured material breach by Provider,
            Provider shall promptly refund the pro-rata amount of any pre-paid
            Subscription Fees attributable to periods after the date of such
            termination.
          </span>
        </p>
        <p>
          <span>4. FEES AND TAXES</span>
        </p>
        <p>
          <span>
            4.1 &nbsp;The Service Recipient shall pay all fees stated in the
            Cover Page and any other fees applicable to its subscription to
            Services as provided hereunder (the &ldquo;
          </span>
          <strong>
            <span>Subscription Fee</span>
          </strong>
          <span>
            &rdquo;). &nbsp; All Subscription Fees are due upon execution of the
            Cover Page, or notice of a deemed subscription as provided herein,
            and payable on the terms set forth therein. If no payment schedule
            is specified for any Subscription Fees, the entire amount shall be
            payable within 30 days of Provider&rsquo;s transmission to Client of
            an appropriate invoice. &nbsp;All amounts payable by Client under
            this Agreement will be paid to Provider without setoff or
            counterclaim, and without any deduction or withholding.
            &nbsp;Provider&rsquo;s acceptance of partial payment or any payment
            of less than the full amount payable at any given time shall not
            constitute a waiver or release of Provider&rsquo;s right to unpaid
            amounts.
          </span>
        </p>
        <p>
          <span>
            4.2 &nbsp;If Client fails to timely make any payment of Subscription
            Fees, Provider may, in its sole discretion, take any or all of the
            following actions: (a) restrict or suspend Client&apos;s access to
            the Services until all past-due payments are made, (b) terminate
            this Agreement, or (c) accelerate the payment of Subscription Fees
            such that all unpaid Subscription Fees shall be immediately payable.
            &nbsp;The Service Provider shall have the right to charge interest
            at the rate of 1.5% per month (or, if less, the highest rate
            permitted by law) on any late payments. &nbsp; Restriction or
            suspension of Client&rsquo;s access to the Services during period of
            non-payment shall have no effect on the Term of this Agreement nor
            on Client&rsquo;s obligation to pay the Subscription Fee.
          </span>
        </p>
        <p>
          <span>
            4.3 &nbsp;Client is responsible for any applicable taxes, including,
            without limitation, any sales, use, levies, duties, or any value
            added or similar taxes payable with respect to Client&rsquo;s
            subscription and assessable by any local, state, provincial,
            federal, or foreign jurisdiction. &nbsp;Unless expressly specified
            otherwise in the Cover Page, all fees, rates, and estimates exclude
            sales taxes.
          </span>
        </p>
        <p>
          <span>5. DATA PROTECTION AND CONFIDENTIALITY</span>
        </p>
        <p>
          <span>
            5.1 &nbsp;The Service Recipient acknowledges and agrees that the
            Service Provider will operate in accordance with its published
            Privacy Policy (available at&nbsp;
          </span>
          <a href="https://go.escoffierglobal.com/privacy-policy">
            <span>https://go.escoffierglobal.com/privacy-policy</span>
          </a>
          <span>
            &nbsp;or as Provider may otherwise indicate), which is incorporated
            herein by reference.
          </span>
        </p>
        <p>
          <span>5.2 &nbsp;&ldquo;</span>
          <strong>
            <span>Confidential Information</span>
          </strong>
          <span>
            &rdquo; of a party means such party&rsquo;s (or its
            affiliate&rsquo;s): confidential or proprietary information that is
            not generally available or known to the public. &ldquo;Confidential
            Information&rdquo; does not include the Licensed Materials (which
            are subject to other restrictions under this Agreement). &nbsp;All
            business terms of this Agreement, including, but not limited to,
            pricing and access, shall be considered Confidential Information of
            the Service Provider.
          </span>
        </p>
        <p>
          <span>
            5.3 &nbsp;Each party shall keep in confidence all Confidential
            Information of the other party obtained prior to or during the Term
            of this Agreement and shall protect the confidentiality of such
            information in a manner consistent with the manner in which such
            party treats its own similar confidential material, but in no event
            with less than reasonable care. Without the prior written consent of
            the other party, a party shall not disclose or make available any
            portion of the other party&rsquo;s Confidential Information to any
            person, firm, association, or corporation, or use such Confidential
            Information, directly or indirectly, except for the performance of
            this Agreement. The foregoing restrictions shall not apply to
            Confidential Information that: (a) was known to such party (as
            evidenced by its written record) or was in the public domain prior
            to the time obtained by such party; (b) was lawfully disclosed to
            such party by a third party who did not receive it directly or
            indirectly from such party and who is under no obligation of secrecy
            with respect to the Confidential Information; (c) became generally
            available to the public, by publication or otherwise, through no
            fault of such party or (d) was developed independently by the
            receiving party without reference to the Confidential Information of
            the other party. &nbsp;The parties shall take all necessary and
            appropriate steps in order to ensure that its employees and
            subcontractors adhere to the provisions of this section. All
            Confidential Information shall be returned to the disclosing party
            or destroyed upon receipt by the receiving party of a written
            request from the disclosing party. The receiving party may disclose
            the disclosing party&rsquo;s Confidential Information to the extent
            required by law or legal process, provided, however, the receiving
            party will (unless prohibited by law or legal process): (a) give the
            disclosing party prior written notice of such disclosure to afford
            the disclosing party a reasonable opportunity to appear, object, and
            obtain a protective order or other appropriate relief regarding such
            disclosure; (b) use diligent efforts to limit disclosure to that
            which is legally required; and (c) reasonably cooperate with the
            disclosing party, at the disclosing party&rsquo;s expense, in its
            efforts to obtain a protective order or other legally available
            means of protection.
          </span>
        </p>
        <p>
          <span>
            5.4 Personal Information. To the extent that either party transmits
            or receives personal information under this Agreement, such party
            shall comply with all applicable laws, rules, and regulations
            regarding privacy and the lawful processing of personal information.
          </span>
        </p>
        <p>
          <span>
            5.6 Related Information. &nbsp; Provider may access, collect, and
            use any information from or relating to Client and Client&rsquo;s
            use of the Services (&ldquo;
          </span>
          <strong>
            <span>Related Information</span>
          </strong>
          <span>
            &rdquo;) for customer and technical support, for regulatory and
            third party compliance purposes, to protect and enforce
            Provider&rsquo;s rights, to monitor compliance with and investigate
            potential breaches of the terms of this Agreement, and to recommend
            additional products or services to Client. &nbsp;Provider may share
            this information with Provider&rsquo;s partners or affiliates for
            the same purposes. &nbsp;Client grants Provider and Provider
            affiliates the perpetual right to use Related Information for
            purposes such as to test, develop, improve, and enhance
            Provider&rsquo;s products and services, and to create and own
            derivative works based on Related Information, so long as neither
            Client is identifiable as the source of such information.
          </span>
        </p>
        <p>
          <span>6. REPRESENTATIONS AND WARRANTIES</span>
        </p>
        <p>
          <span>
            6.1 &nbsp;Each party represents and warrants that: (a) it is duly
            organized and validly existing and authorized to do business in the
            jurisdictions where it operates; and (b) it has the requisite power
            and authority to enter this Agreement and entering and complying
            with its obligations under this Agreement does not violate any legal
            obligation by which such party is bound.
          </span>
        </p>
        <p>
          <span>
            6.2 Client represents and warrants, and covenants that it will not,
            in connection with this Agreement, including its use of or access to
            the Services, engage in, encourage, or permit conduct that violates
            or would violate any applicable law, rule, or regulation or any
            right of any third party.&nbsp;
          </span>
        </p>
        <p>
          <span>
            6.3 Provider represents and warrants that it possesses all necessary
            authority and permissions to provision Client with access to the
            Services.
          </span>
        </p>
        <p>
          <span>7.&nbsp;</span>
          <span>REMEDIES</span>
        </p>
        <p>
          <span>
            7.1 Remedies not Exclusive. &nbsp; No remedy provided in this
            Agreement shall be deemed exclusive of any other remedy that a party
            may have at law or in equity unless it is expressly stated herein
            that such remedy is exclusive.&nbsp;
          </span>
        </p>
        <p>
          <span>
            7.2 Provisional Remedies. &nbsp; Each party recognizes that the
            unauthorized disclosure of Confidential Information or, as to
            Client, any of Provider&apos;s software or information, may cause
            irreparable harm to the other party for which monetary damages may
            be insufficient, and in the event of such disclosure, such other
            party shall be entitled to seek an injunction, temporary restraining
            order, or other provisional remedy as appropriate without being
            required to post bond or other security.&nbsp;
          </span>
        </p>
        <p>
          <span>
            7.3 Liquidated Damages. &nbsp;Client acknowledges that the Services
            are for its own use only for the benefit of or on behalf of any
            third party, will cause damage to Provider in an amount that is
            difficult to quantify. &nbsp;In order to avoid the time and expense
            of quantifying damages, if the Service Recipient, negligently or
            intentionally damages Provider, it will pay an amount equal to the
            Subscription Fee as liquidated damages.
          </span>
        </p>
        <p>
          <span>8. ATTORNEY FEES, DISPUTE RESOLUTION, CLASS ACTION WAIVER</span>
        </p>
        <p>
          <span>
            8.1 Attorney Fees. &nbsp;In the event of any dispute arising under
            this Agreement, the prevailing party shall be entitled to recover
            its reasonable costs and expenses actually incurred in endeavoring
            to enforce the terms of this Agreement, including reasonable
            attorney fees.&nbsp;
          </span>
        </p>
        <p>
          <span>
            8.2 Mandatory Arbitration. &nbsp; Except for Litigation Claims
            (defined below), any dispute, claim, or controversy arising out of
            or relating to this Agreement, including, without limitation (a)
            claims relating to the breach, termination, enforcement,
            interpretation or validity thereof, (b) claims alleging tortious
            conduct (including negligence) in connection with the negotiation,
            execution, or performance thereof, or (c) the determination of the
            scope or applicability of this agreement to arbitrate, shall be
            settled by arbitration administered by JAMS pursuant to its
            Comprehensive Arbitration Rules and Procedures and in accordance
            with the Expedited Procedures in those Rules or pursuant to
            JAMS&rsquo; Streamlined Arbitration Rules and Procedures. The
            arbitration shall be heard by a single arbitrator. The arbitration
            award shall be final and binding, and such award may be entered in
            any court having jurisdiction. This section shall not preclude
            parties from seeking provisional remedies in aid of arbitration from
            a court of appropriate jurisdiction. The arbitrator shall have the
            power to award any remedy provided under applicable law, except that
            the arbitrator shall have no power to award: (a) punitive,
            exemplary, or multiple damages under any legal theory, except in the
            event of a party&rsquo;s or its agent&rsquo;s gross negligence or
            intentional misconduct; (b) mandatory or prohibitory injunctive
            relief, except for temporary relief in aid of the arbitration or to
            secure the payment of an award; or (c) any damages in excess of the
            limits set forth in this section or Section 10 (Limitation of
            Liability) of this Agreement.
          </span>
        </p>
        <p>
          <span>
            8.3 Class Action Waiver. No party shall commence or seek to
            prosecute or defend any dispute, controversy, or claim based on any
            legal theory arising out of or relating to this Agreement, or the
            breach thereof, other than on an individual, non-class,
            non-collective action basis. &nbsp;No party shall seek to prosecute
            or defend any dispute, controversy, or claim arising out of or
            relating to this Agreement, or the breach thereof, in a
            representative or private attorney general capacity. &nbsp;The
            arbitrator shall not have the power to consolidate any arbitration
            under this Agreement with any other arbitration, absent agreement of
            all parties involved, or otherwise to deal with any matter on a
            non-individual, class, collective, representative, or private
            attorney general basis.
          </span>
        </p>
        <p>
          <span>
            8.4 Litigation Claims. &nbsp; The following claims (&ldquo;
          </span>
          <strong>
            <span>Litigation Claims</span>
          </strong>
          <span>
            &rdquo;) shall be litigated and not arbitrated: (a) claims against a
            party to this Agreement under the provisions involving claims by
            third parties; (b) claims by a party for the unauthorized use, or
            the misuse, by the other party of the first party&rsquo;s
            Confidential Information; (c) claims by Provider to collect
            Subscription Fees; and (d) claims for mandatory or prohibitory
            injunctive relief, except for temporary relief in aid of arbitration
            or to secure the payment of an arbitration award under this
            Agreement. &nbsp;The Litigation Claims are not subject to
            arbitration and are expressly excluded by the parties from
            arbitration unless otherwise agreed in writing.
          </span>
        </p>
        <p>
          <span>9. INDEMNIFICATION</span>
        </p>
        <p>
          <span>
            9.1 Client agrees to indemnify, defend, and hold harmless Provider
            and its officers, directors, employees, shareholders, agents,
            partners, successors, and permitted assigns from and against any and
            all actual or threatened claims of third parties arising out of or
            in connection with (a) Client&rsquo;s access or use of the Services
            in violation of any law, (b) Client&rsquo;s violation of any
            provision of this Agreement, (c) Client&rsquo;s sending of any
            information, messages, or materials (including, but not limited to,
            through e-mail, mail, or fax) in violation of any law or the rights
            of any third party, or (d) the Services by any third party to whom
            Client has granted access (including access obtained by such third
            party through use of the usernames and passwords assigned to Client
            and its personnel).&nbsp;
          </span>
        </p>
        <p>
          <span>
            9.2 Provider shall indemnify Client for any damages finally awarded
            by any court of competent jurisdiction against Client in, or for
            amounts paid by Cient under a settlement approved by Provider in
            writing of, any legal proceeding brought by a third party alleging
            that any Provider software or information infringes upon or violates
            the intellectual property rights of any such third party. &nbsp;The
            foregoing indemnification obligations do not apply if (a) the
            allegation does not state with specificity that the Services are the
            basis of the claim against Client; (b) a claim against Client arises
            from the use or combination of the Services or any part thereof with
            software, hardware, data, or processes not provided by Provider, if
            the Services or use thereof would not infringe without such
            combination; or (c) a claim against Client arises from
            Client&rsquo;s breach of this Agreement.
          </span>
        </p>
        <p>
          <span>9</span>
          <span>
            .3 As a condition to any right to indemnification under this
            Agreement, the indemnified party must (a) promptly give the
            indemnifying party written notice of the claim or proceeding, (b)
            give the indemnifying party sole control of the defense and
            settlement of the claim or proceeding (except that the indemnifying
            party may not settle any claim or proceeding unless it
            unconditionally releases the indemnified party of all liability),
            and (c) give the indemnifying party all reasonable assistance, at
            the indemnifying party&rsquo;s expense. This section states the
            indemnifying party&rsquo;s sole liability to, and the indemnified
            party&rsquo;s exclusive remedy against, the other party for any
            claim or proceeding subject to indemnification hereunder.
          </span>
        </p>
        <p>
          <span>10. LIMITATION OF LIABILITY</span>
        </p>
        <p>
          <span>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW AND EXCEPT FOR
            INSTANCES OF A PARTY&rsquo;S OR ITS AGENT&rsquo;S GROSS NEGLIGENCE
            OR INTENTIONAL MISCONDUCT, IN NO EVENT WILL EITHER PARTY BE LIABLE
            TO THE OTHER PARTY FOR ANY PUNITIVE, EXEMPLARY, MULTIPLE, INDIRECT,
            CONSEQUENTIAL, SPECIAL, LOST BUSINESS, LOST OR CORRUPTED DATA, OR
            LOST PROFITS DAMAGES ARISING FROM OR RELATING TO THIS AGREEMENT,
            WHETHER FORESEEABLE OR UNFORESEEABLE, EVEN IF ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. CLIENT&rsquo;S SOLE AND EXCLUSIVE
            REMEDY FOR ANY UNCURED BREACH BY THE SERVICE PROVIDER OF ITS
            OBLIGATIONS UNDER THIS AGREEMENT IS TERMINATION BY WRITTEN NOTICE TO
            PROVIDER, AND REFUND OF A PRORATED PORTION OF THE SUBSCRIPTION FEES
            THAT CLIENT HAS PAID. PROVIDER&rsquo;S MAXIMUM LIABILITY TO THE
            SERVICE RECIPIENT SHALL BE THE AMOUNTS ACTUALLY PAID TO PROVIDER BY
            CLIENT UNDER THIS AGREEMENT IN THE TWELVE (12) MONTHS PRECEDING THE
            EVENT GIVING RISE TO CLIENT&rsquo;S CAUSE OF ACTION. EXCEPTING
            LIABILITY ARISING FROM CLIENT&rsquo;S OR ITS AGENT&rsquo;S GROSS
            NEGLIGENCE OR INTENTIONAL MISCONDUCT OR THE SERVICE
            RECIPIENT&rsquo;S INDEMNIFICATION OBLIGATIONS HEREUNDER,&nbsp;
          </span>
        </p>
        <p>
          <span>11. DISCLAIMER OF WARRANTIES</span>
        </p>
        <p>
          <span>
            EXCEPT FOR ANY EXPRESS REPRESENTATIONS AND WARRANTIES STATED HEREIN,
            ANY SERVICES ARE PROVIDED &ldquo;AS IS&rdquo; AND ON AN &ldquo;AS
            AVAILABLE&rdquo; BASIS, AND NEITHER PARTY MAKES ANY ADDITIONAL
            REPRESENTATION OR WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED
            (EITHER IN FACT OR BY OPERATION OF LAW), OR STATUTORY, AS TO ANY
            MATTER WHATSOEVER AND EACH PARTY EXPRESSLY DISCLAIMS ALL IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            QUALITY, ACCURACY, TITLE, AND NON-INFRINGEMENT. NEITHER PARTY WILL
            HAVE THE RIGHT TO MAKE OR PASS ON ANY REPRESENTATION OR WARRANTY ON
            BEHALF OF THE OTHER PARTY TO ANY THIRD PARTY.
          </span>
        </p>
        <p>
          <span>
            PROVIDER DOES NOT REPRESENT, COVENANT, WARRANT, OR PROMISE THAT ANY
            OF THE SERVICES MAY BE USED OR RELIED UPON BY CLIENT OR ANY OTHER
            PARTY TO COMPLY WITH ANY LAW, RULE, REGULATION, INDUSTRY STANDARD,
            OR POLICY, NOR THAT ANY OF THE SERVICES WILL RENDER CLIENT NOR ANY
            OTHER PARTY COMPLIANT WITH ANY LAW, RULE, REGULATION, INDUSTRY
            STANDARD, OR POLICY, AND PROVIDER EXPRESSLY DISCLAIMS TO THE FULLEST
            EXTENT PERMISSIBLE BY APPLICABLE LAW ANY SUCH REPRESENTATION,
            COVENANT, WARRANTY, OR PROMISE. IF AND TO THE EXTENT THAT THE
            SERVICE RECIPIENT USES ANY OF THE SERVICES WITH THE INTENTION OF OR
            FOR THE PURPOSE OF COMPLYING WITH ANY LAW, RULE, REGULATION,
            INDUSTRY STANDARD, OR POLICY, CLIENT ACKNOWLEDGES AND AGREES THAT
            SUCH SERVICES ARE, IN THAT REGARD, PROVIDED &ldquo;AS IS,&rdquo; AND
            CLIENT ASSUMES FULL RESPONSIBILITY FOR ITS COMPLIANCE. &nbsp;CLIENT
            AGREES THAT PROVIDER SHALL HAVE NO LIABILITY TO CLIENT FOR
            CLIENT&rsquo;S USE OF OR RELIANCE ON ANY SERVICES FOR SUCH PURPOSES.
            THIS PARAGRAPH IS NOT INTENDED TO DIMINISH, MODIFY, OR RELEASE ANY
            EXPRESS REPRESENTATIONS AND WARRANTIES STATED HEREIN.
          </span>
        </p>
        <p>
          <span>13. MISCELLANEOUS PROVISIONS</span>
        </p>
        <p>
          <span>
            13.1 Marketing. Client hereby authorizes Provider to use
            Client&rsquo;s name and logo for its marketing efforts unless and
            until such authorization is revoked in writing.
          </span>
        </p>
        <p>
          <span>
            13.2 Assignment. Either party hereto may assign this Agreement to a
            successor-in-interest pursuant to an acquisition of such party
            (whether by merger, stock sale, or asset sale) without the other
            party&rsquo;s consent, provided however that (a) Client&rsquo;s
            assignment hereof shall be effective only after fourteen (14)
            days&rsquo; written notice to Provider, and (b) Client may not
            assign this agreement to any competitor of Provider without
            Provider&rsquo;s express written consent. No rights or obligations
            under this Agreement may be assigned or delegated except as provided
            in this section without the prior written consent of the other
            party, and any assignment or delegation in violation of this section
            shall be void.
          </span>
        </p>
        <p>
          <span>
            13.3 Notices. Client shall provide an email address for notices
            under this Agreement. &nbsp;All notices or other communications
            permitted or required to be given hereunder shall be sent by
            electronic mail to the email address provided by the other party for
            such purpose and shall be deemed given when sent. Notices to
            Provider shall be sent to&nbsp;
          </span>
          <a href="mailto:partnerships@escoffier.edu">
            partnerships@escoffier.edu
          </a>
          <span>
            . If Client fails to provide an email address for notices, Provider
            may provide notices hereunder by any means reasonably calculated to
            provide Client with actual notice thereof.
          </span>
        </p>
        <p>
          <span>
            13.4 Currency. All monetary amounts specified in this Agreement are
            in United States dollars unless otherwise expressly stated.&nbsp;
          </span>
        </p>
        <p>
          <span>
            13.5 Suggestions and Feedback. Provider shall have a royalty-free,
            worldwide, transferable, sub-licensable, irrevocable, perpetual
            license to use or incorporate into the Services any suggestions,
            enhancement requests, recommendations or other feedback provided by
            Client relating to the operation of the Services provided such
            information does not include any Client Confidential
            Information.&nbsp;
          </span>
        </p>
        <p>
          <span>
            13.6 Entire Agreement. This Agreement constitutes the entire
            Agreement of the parties and supersedes all prior communications,
            understandings, and agreements relating to the subject matter
            hereof, whether oral or written. Any un-expired subscription set
            forth in any Cover Page or agreement between the parties for access
            to Provider Services shall be governed by the terms hereof.
          </span>
        </p>
        <p>
          <span>
            13.7 Amendment. Provider may propose amendments to this Agreement at
            any time by providing notice of such proposed amendments in a manner
            permitted hereunder. Such proposed amendments shall be deemed
            accepted and become part of this Agreement thirty (30) days after
            the date such notice is given unless Client informs Provider that it
            does not accept such amendments. In the event Client informs
            Provider that it does not accept the proposed amendments, the
            proposed amendments will not take effect and the existing terms will
            continue in full force and effect. No other modification or claimed
            waiver of any provision of this Agreement shall be valid except by
            written amendment signed by authorized representatives of Provider
            and Client.&nbsp;
          </span>
        </p>
        <p>
          <span>
            13.8 Force Majeure. Neither Provider nor any of its affiliates will
            be liable for any delay or failure to perform any obligation under
            this Agreement where the delay or failure results from any cause
            beyond its reasonable control, including, but not limited to, acts
            of God, labor disputes or other industrial disturbances, electrical
            or power outages, utilities or other telecommunications failures,
            earthquake, storms or other elements of nature, blockages,
            embargoes, riots, acts or orders of government, acts of terrorism,
            or war.
          </span>
        </p>
        <p>
          <span>
            13.9 Export Compliance. The Services and derivatives thereof may be
            subject to export laws and regulations of the United States and
            other jurisdictions. &nbsp;Provider and Client each represents that
            it is not on any U.S. government denied-party list. &nbsp;Client
            will use any Services in a U.S.-embargoed country or region or in
            violation of any U.S. export law or regulation.
          </span>
        </p>
        <p>
          <span>
            13.10 Unenforceability and Survival. If any provision of this
            Agreement is held to be unenforceable, then that provision is to be
            construed either by modifying it to the minimum extent necessary to
            make it enforceable (if permitted by law) or disregarding it (if not
            permitted by law), and the rest of this Agreement is to remain in
            effect as written. Any obligations and duties which by their nature
            extend beyond the expiration or termination of this Agreement will
            survive the expiration or termination of this Agreement.
          </span>
        </p>
        <p>
          <span>
            13.11 Provider Contracting Entity, Governing Law, and Venue. All
            arbitration proceedings will be located in Chicago, Illinois.
            &nbsp;The parties hereby agree to exclude all rights to seek a
            determination by the court of a preliminary point of law under
            section 45 of the Arbitration Act 1996 and all rights of appeal on a
            point of law from any arbitration award under section 69 of the
            Arbitration Act 1996. A person who is not party to this Agreement
            shall have no rights under the Contracts (Rights of Third Parties)
            Act 1999 or otherwise to enforce any term of this Agreement. &nbsp;
          </span>
          <sup>
            <span>&nbsp;</span>
          </sup>
          <span>
            Each party irrevocably consents to the personal jurisdiction of the
            state and federal courts located in the State of Illinois for
            purposes of any lawsuit seeking to enforce this Agreement, and
            agrees that the exclusive venue for any litigation, action, suits,
            or proceeding arising out of or relating to this Agreement shall lie
            in the County Court in and for Cook County, Illinois, or, if federal
            jurisdiction exists, in the United States District Court for the
            Northern District of Illinois.
          </span>
        </p>
        <p>
          <strong>
            <span>&nbsp;</span>
          </strong>
        </p>
      </LegalContentSection>
    </LegalPageWrapper>
  );
};

export default GoldCrownPartnershipTermsAndConditionsPage;
