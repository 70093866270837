import { AttestationStatus } from '@interfaces/attestation.interface';

export const getAttestationStatusLabel = (
  status: AttestationStatus
): string => {
  switch (status) {
    case AttestationStatus.NOT_ELIGIBLE:
      return 'INELIGIBLE';
    case AttestationStatus.ELIGIBLE:
      return 'ELIGIBLE';
    case AttestationStatus.SENT:
      return 'SENT';
    case AttestationStatus.OPENED:
      return 'OPENED';
    case AttestationStatus.COMPLETED:
      return 'COMPLETED';
  }
};
