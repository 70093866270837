import * as React from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

const Styled = {
  SelectionBox: styled(Box)((props: { selected: boolean }) => ({
    border: '1px solid #997755',
    borderRadius: '4px',
    textAlign: 'center',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    backgroundColor: props.selected ? '#997755' : 'white',
    justifyContent: 'center',
    color: props.selected ? 'white' : '#997755',
    padding: '26px 8px',
    ['@media (max-width:600px)']: {
      padding: '20px 8px'
    },

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: props.selected ? '#997755' : '#EBDFCD',
      color: props.selected ? 'white' : '#997755',

      '.MuiTypography-root': {
        color: props.selected ? 'white' : '#997755'
      },

      border: '1px solid transparent'
    }
  })),
  SelectionBoxText: styled(Typography)((props: { selected: boolean }) => ({
    color: props.selected ? 'white' : '#997755'
  }))
};

interface Props {
  option: any;
  icon?: React.ReactNode;
  selected: boolean;
  handleSelection: (option: any) => void;
  dataTestId?: string;
}

const SelectionBox: React.FC<Props> = (props) => {
  const { option, icon, selected, handleSelection, dataTestId } = props;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Styled.SelectionBox
      onClick={(): void => handleSelection(option)}
      selected={selected}
      data-testid={dataTestId}
    >
      <Box>
        {icon ? icon : null}
        <Styled.SelectionBoxText
          variant={isSmall ? 'EC_TYPE_2XS' : 'EC_TYPE_SM'}
          selected={selected}
        >
          {option}
        </Styled.SelectionBoxText>
      </Box>
    </Styled.SelectionBox>
  );
};

export default SelectionBox;
