import * as React from 'react';
import {
  AppBar,
  Box,
  Button,
  Container,
  Icon,
  IconButton,
  Slide,
  Toolbar
} from '@mui/material';
import EsourceNavSearch from './EsourceNavSearch';
import HeaderLink from './HeaderLink';
import HeaderLogo from '@components/Layout/Header/HeaderLogo';
import MobileSubMenu from '@components/Layout/Header/MobileSubMenu';
import { NavigationHeader } from './Header.models';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useTagManager } from '@common/hooks/useTagManager';

const HEADER_HEIGHT = '75px';

const Styled = {
  AppBar: styled(AppBar)({
    backgroundColor: 'white',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)'
  }),
  Toolbar: styled(Toolbar)({
    display: 'flex',
    justifyContent: 'space-between',
    height: HEADER_HEIGHT
  }),
  IconButton: styled(IconButton)({
    color: theme.palette.GRAY_4.main
  }),
  MobileNavMenu: styled(Box)({
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: '0px',
    backgroundColor: 'white',
    borderRadius: '0px 0px 4px 4px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    height: `calc(100vh - ${HEADER_HEIGHT})`,
    width: '100%',
    overflowY: 'scroll'
  }),
  MobileNavMenuList: styled(Box, {
    shouldForwardProp: (prop) =>
      prop !== 'hasTopBorder' && prop != 'hasLargerPadding'
  })<{ hasTopBorder?: boolean; hasLargerPadding?: boolean }>(
    ({ hasTopBorder, hasLargerPadding }) => ({
      display: 'flex',
      flexDirection: 'column',
      borderTop: hasTopBorder
        ? `1px solid ${theme.palette.GRAY_1.main}`
        : 'none',
      padding: hasLargerPadding ? '0px 40px' : '0px 15px'
    })
  )
};

interface Props {
  nav: NavigationHeader;
  login: () => void;
  logout: () => void;
  isAuthenticated: boolean;
}

const MobileNav: React.FC<Props> = ({
  nav,
  login,
  isAuthenticated,
  logout
}) => {
  const { sendToGtm } = useTagManager();
  const [openMenu, setOpenMenu] = React.useState<boolean>(false);

  const handleMobileMenuIcon = (): void => {
    setOpenMenu(!openMenu);
  };

  const handleClick = (): void => {
    sendToGtm('navigation', {
      navigate_to: '/create-account',
      component: 'header',
      link_text: 'Create Account'
    });
  };

  const navIcon = openMenu ? 'ri-close-line' : 'ri-menu-fill';
  const hasLinkIcon = !!(Array.from(nav.avatarLinks)).filter((i) => i.mobileIcon).length;

  const Menu: React.FC = () => (
    <Slide direction="right" in={openMenu} mountOnEnter unmountOnExit>
      <Styled.MobileNavMenu>
        <Box onClick={(): void => setOpenMenu(false)}>
          {isAuthenticated && (
            <Styled.MobileNavMenuList hasLargerPadding={hasLinkIcon}>
              {Array.from(nav.primaryLinks).map((navItem) => {
                if (!navItem.hideInMobile) {
                  return (
                    <HeaderLink
                      key={navItem.label}
                      title={navItem.label}
                      link={navItem.path}
                    />
                  );
                }
              })}
            </Styled.MobileNavMenuList>
          )}

          <Styled.MobileNavMenuList
            hasTopBorder={!!nav.avatarLinks.size}
            hasLargerPadding={hasLinkIcon}
          >
            {Array.from(nav.avatarLinks).map((navItem) => (
              <React.Fragment key={navItem.label}>
                {!navItem.hideInMobile && (
                  <HeaderLink
                    title={navItem.label}
                    link={navItem.path}
                    icon={navItem.mobileIcon}
                  />
                )}
              </React.Fragment>
            ))}
            {!!nav.contentManagementLinks?.size && (
              <MobileSubMenu
                label="Content Management"
                items={nav.contentManagementLinks}
              />
            )}

            {!!nav.settingsLinks?.size && (
              <MobileSubMenu label="Settings" items={nav.settingsLinks} />
            )}

            {!isAuthenticated ? (
              <>
                <HeaderLink title="Sign In" onClick={login} />
                <Button
                  component={RouterLink}
                  to="/create-account"
                  onClick={handleClick}
                  variant="contained"
                  data-testid="mobile-nav-create-account-btn"
                  sx={{
                    padding: '9.5px 22.5px',
                    margin: '15px 10px',
                    alignSelf: 'flex-start'
                  }}
                >
                  Create Account
                </Button>
              </>
            ) : (
              <HeaderLink title="Sign Out" onClick={logout} />
            )}
          </Styled.MobileNavMenuList>
        </Box>
      </Styled.MobileNavMenu>
    </Slide>
  );

  return (
    <Styled.AppBar position="sticky">
      <Container>
        <Styled.Toolbar disableGutters>
          <Box onClick={(): void => setOpenMenu(false)}>
            <HeaderLogo />
          </Box>

          <Box>
            <EsourceNavSearch />
            <Styled.IconButton aria-label="menu" onClick={handleMobileMenuIcon}>
              <Icon className={navIcon} />
            </Styled.IconButton>
          </Box>
        </Styled.Toolbar>
      </Container>
      <Menu />
    </Styled.AppBar>
  );
};

export default MobileNav;
