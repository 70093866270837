import { BadgeCodebook, Codebook } from '@api/models/codebook.models';
import {
  CompanyPartnershipPermissions,
  PartnershipLevel
} from '@api/permissions/companyPartnershipLevel.permissions';
import { EmployerProfile } from '@api/models/employerApi.models';
import { AgreementEntity, ExternshipEntity } from './externshipApi.models';
import { FileType } from '@api/models/fileApi.models';
import { GlrcPermissions } from '@api/models/glrcAccess.models';
import { Location } from '@interfaces/location.interfaces';
import { VideoType } from '@api/models/videoApi.models';

export enum COMPANY_STATUS {
  CREATED = 0,
  COMPLETED = 3
}

export type CompanyBenefitsGroup =
  | 'companyHealthInsurance'
  | 'companyRetirement'
  | 'companyProfDevelopment';

export interface CompanyProfile {
  id: number;
  status: COMPANY_STATUS;
  name: string;
  phone: string;
  size: number;
  logo?: FileType | null;
  badge?: BadgeCodebook;
  websiteUrl: string;
  facebookUrl: string;
  instagramUrl: string;
  linkedInUrl: string;
  enabled: boolean;
  companySegments: Codebook[];
  companyHealthSafeties: { id: string; type: string }[];
  companyVideos: { id: number; video: VideoType }[];
  companyPhotos: { id: number; file: FileType }[];
  companyOverview: string;
  companyMission: string;
  companyVision: string;
  companyValues: string;
  companyBenefits: {
    benefitsGroup: CompanyBenefitsGroup;
    benefitsValue: string;
  }[];
  companyCulture: { id: string; type: string }[];
  glrcSeats: number;
  glrcPermissions?: GlrcPermissions;
  hasEsourceAccess: boolean;
  partnershipLevel: PartnershipLevel;
  partnershipLevelPermissions: CompanyPartnershipPermissions;
  createdAt: string;
  locations: Location[];
  siteAgreements: AgreementEntity[];
  customThemeAccess: boolean;
  workLearnPartner: boolean;
  isGlobalSolutions: boolean;
  isExternship: boolean;
}

export type CompanyBenefitsGetRequest = CompanyProfile['companyBenefits'];

export enum CompanyBenefitGroups {
  companyHealthInsurance = 'companyHealthInsurance',
  companyProfDevelopment = 'companyProfDevelopment',
  companyRetirement = 'companyRetirement'
}

export interface CompanyPutRequest {
  phone: string;
  size?: number | null;
  logoId?: number | null;
  websiteUrl?: string | null;
  linkedInUrl?: string | null;
  instagramUrl?: string | null;
  facebookUrl?: string | null;
  companySegments: number[];
  companyHealthSafeties: string[];
  badge: number;
}

export interface CompanyEthosPutRequest {
  companyOverview?: string | null;
  companyMission?: string | null;
  companyVision?: string | null;
  companyValues?: string | null;
}

export interface CompanyCulturePutRequest {
  companyCulture: string[];
}

export interface CompanyBenefitsPutRequest {
  companyHealthInsurance?: string[] | null;
  companyRetirement?: string[] | null;
  companyProfDevelopment?: string[] | null;
}

export interface CompanyMediaPutRequest {
  companyPhotos: number[];
  companyVideos: number[];
}

export interface UpdateCompanyLocationRequest {
  location: Location;
  phone: string;
}

export interface CompanyTheme {
  id: number;
  primaryColor: string;
  secondaryColor: string;
  tileColor: string;
  glrcHeaderImage: FileType;
  glrcHeaderText: string;
}

export interface CompanyThemePutRequest
  extends Omit<CompanyTheme, 'id' | 'glrcHeaderImage'> {
  id?: number;
  glrcHeaderImageId?: number;
}

export interface CompaniesSpotlightBenefits {
  id: number;
  benefitsGroup: string;
  benefitsValue: string;
}
export interface CompaniesSpotlight {
  id: number;
  name: string;
  logoUrl: string;
  // TODO: OBSOLETE - benefits may not be needed, need to verify before removing
  benefits: CompaniesSpotlightBenefits[];
  badge?: BadgeCodebook;
}

export interface CompanySettingsPutRequest {
  name: string;
}

export interface Subsidiary {
  id: number;
  name: string;
  logo?: {
    fullPath: string;
  };
}

export enum JoinRequestStatus {
  PENDING = 10,
  APPROVED = 20,
  REJECTED = 30
}

export interface JoinRequest {
  id: number;
  userId: string;
  jobTitle: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  status: JoinRequestStatus;
  submittedAt: string;
  approvedAt: string | null;
  rejectedAt?: string | null;
  assignedRole: string | true;
  company: CompanyProfile;
  approvedBy: EmployerProfile | null;
  rejectedBy: EmployerProfile | null;
  externship?: ExternshipEntity;
}

export interface ApproveJoinRequest {
  roles: string;
}
