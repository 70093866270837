import * as React from 'react';
import LegalContentSection from '@components/LegalContentSection';

const GlrcTermsOfUseContent: React.FC = () => {
  return (
    <LegalContentSection id="glrc-terms-of-use" title="ESource">
      <p>
        <strong>
          <span>Terms of Use</span>
        </strong>
      </p>
      <p>
        <span>
          These Terms of Use (&quot;<strong>Terms</strong>&quot;) apply to all
          users of the ESource, including both administrators and users (&quot;
          <strong>you</strong>&quot;) of this website, any related phone apps,
          and any related social media accounts (the &ldquo;
          <strong>Website</strong>&rdquo;). These Terms include our Privacy
          Policy (
        </span>
        <span>
          <a href="https://go.escoffierglobal.com/privacy-policy">
            <span>https://go.escoffierglobal.com/privacy-policy</span>
          </a>
        </span>
        <span>)</span>
      </p>
      <p>
        <em>
          <span>Services Offered</span>
        </em>
      </p>
      <p>
        <span>
          We offer the following services (the &quot;<strong>Services</strong>
          &quot;) on the Website:
        </span>
      </p>
      <ul>
        <li>
          <span>training videos; and</span>
        </li>
        <li>
          <span>courses relating to the culinary arts</span>
        </li>
      </ul>
      <p>
        <em>
          <span>Binding Agreement; Revisions</span>
        </em>
      </p>
      <p>
        <span>
          These Terms constitute a binding agreement between you and Auguste
          Escoffier School of Culinary Arts, which is owned by Triumph Higher
          Education Corporation (&ldquo;<strong>we</strong>,&rdquo; &ldquo;
          <strong>us</strong>,&rdquo; and &ldquo;<strong>our</strong>&rdquo;),
          and you accept these Terms each time that you use or access the
          Website or its content or otherwise use our tools or our Services.
        </span>
      </p>
      <p>
        <span>
          If we have entered into a &quot;hard-copy&quot; written agreement with
          you that incorporates these Term of Use by reference, the combination
          of that written agreement and these Term of Use shall be the binding
          agreement between you and us. In the event of any inconsistency or
          conflict between that written agreement and these Terms of Use, the
          written agreement shall take precedence.
        </span>
      </p>
      <p>
        <span>
          We may revise these Terms from time to time. If we make any revisions,
          they will become effective when we post the modified version of these
          Terms on our Website. By visiting our Website or using our Services
          after we have posted revisions, you accept those revisions. The date
          at the end of these Terms indicates when they were last updated.
        </span>
      </p>
      <p>
        <span>
          These Terms will remain in full force and effect while you are a user
          of the Website. We may pursue all of our legal remedies, including
          removal of Your Content from the Website and immediate termination of
          your account and your access to the Website, its content, our tools
          and our Services upon any breach by you of these Terms. Even after you
          are no longer a user of the Website, certain provisions of these Terms
          will remain in effect, including&nbsp;
        </span>
        <em>
          <span>&quot;Binding Agreement; Revisions,&quot;&nbsp;</span>
        </em>
        <span>
          &quot;
          <em>
            Use of our Website; Services and Content; Consequences of Violating
            These Terms
          </em>
          ,&quot; &quot;<em>Terms Applicable to All Users</em>,&quot; &quot;
          <em>Ideas</em>,&quot; &quot;
          <em>
            Identification of Agent to Receive Notification and Elements of
            Notification of Claimed Copyright or Trademark Infringement
          </em>
          ,&quot; &quot;
          <em>
            Policy Regarding Termination of Users Who Infringe the Copyright or
            Other Intellectual
          </em>
          Property,&quot; &quot;<em>Liability Disclaimers</em>,&quot; &quot;
          <em>
            Disclaimer of Warranties,&quot; &quot;Disclaimer of Consequential
            Damages,&quot; &quot;Limitation of Liability,&quot; &quot;Class
            Action Waiver,&quot; &quot;Links to Other Sites,&quot; and
            &quot;Indemnity.
          </em>
        </span>
        <em>
          <span>&quot;</span>
        </em>
      </p>
      <p>
        <em>
          <span>
            Use of our Website; Services and Content; Consequences of Violating
            These Terms
          </span>
        </em>
      </p>
      <p>
        <span>
          When you register with the Website, you will be asked to login with
          Facebook, Google or another service or to create an account with us
          and provide us with your information, including your name, zip code,
          career and education level and a valid email address
          (&quot;Information&quot;). All Information that you supply to us must
          be accurate, complete and not misleading and must apply to you and not
          any other person. You are solely responsible for the content and
          accuracy of any Information that you submit to us. We may offer
          services and products to you based on the Information that you submit
          to us or that you agree to receive from us in accordance with our
          Privacy Policy (
        </span>
        <span>
          <a href="https://go.escoffierglobal.com/privacy-policy">
            <span>https://go.escoffierglobal.com/privacy-policy</span>
          </a>
        </span>
        <span>)</span>
      </p>
      <p>
        <span>
          You have no ownership rights in your account, and if you cancel your
          account with us or your account is terminated due to inactivity or
          otherwise, your Information will no longer be available to you or to
          third parties, but it may continue to be used to compile aggregate
          data
        </span>
      </p>
      <p>
        <span>
          You may only use the Website, its content, our tools, and our Services
          in accordance with these Terms and then only for your personal or
          company use. The content of the Website, including page designs,
          layouts, text, graphics, images, video, audio, data and information,
          logos, button and icons, and software, and our tools (<em>e.g.</em>,
          our profile creator) are protected under copyright, trademark and
          other laws. All content on the Website (other than certain content
          that you and other users submit and retain) and all tools that we
          provide are our or our licensors&apos; property. Your unauthorized use
          of content or tools may violate applicable law for which you will be
          held responsible. If you are authorized to make a copy of any content
          or any tool, you must preserve all copyright, trademark, service mark,
          and other proprietary notices contained in that content.
        </span>
      </p>
      <p>
        <span>
          Any software code that is used to operate the Website, generate or
          display any content on the Website or provide our tools or our
          Services is also protected by copyright and other law, and you may not
          copy, use, or adapt any such software code. You agree not to sell or
          modify content (other than your own content) or any tool or reproduce,
          display, publicly perform, distribute, or otherwise use either in any
          way, except as explicitly authorized by these Terms.
        </span>
      </p>
      <p>
        <span>
          If you violate these Terms, we can terminate your access to the
          Website and its content and its tools, and you will no longer be able
          to use our Services. In addition, violations of these Terms could
          subject you to civil and/or criminal liability. We will investigate
          occurrences that may involve such violations, and we will cooperate
          with law enforcement authorities.
        </span>
      </p>
      <p>
        <em>
          <span>Terms Applicable to All Users</span>
        </em>
      </p>
      <ul>
        <li>
          <span>
            Content, including our content and other features, may be used only
            by GLRC administrators and authorized users seeking training in the
            culinary arts.
          </span>
        </li>
        <li>
          <span>
            Tools that we may supply may only be used by individuals seeking
            training services.
          </span>
        </li>
        <li>
          <span>
            You are required to maintain the confidentiality of your account,
            your profile, and your password. You may not share your password
            with anyone, and you are solely responsible for your account, even
            if you have not authorized those uses. You will immediately notify
            us of any unauthorized use of your account or password.
          </span>
        </li>
        <li>
          <span>
            You cannot post, provide, distribute, store or destroy any material,
            including content, in violation of applicable law or in breach of
            our Privacy Policy.
          </span>
        </li>
        <li>
          <span>
            You cannot do anything that imposes an unreasonable burden or load
            on the Website.
          </span>
        </li>
        <li>
          <span>
            You cannot attempt to interfere with our Services to anyone else.
          </span>
        </li>
        <li>
          <span>
            You cannot violate or attempt to violate the security of the
            Website, including the use of probes, scans or tests of
            vulnerabilities unless we authorize you to do so.
          </span>
        </li>
        <li>
          <span>
            You cannot forge any TCP/IP packet header or any part of any other
            header information.
          </span>
        </li>
        <li>
          <span>
            You cannot reverse engineer or decompile any parts of the Website or
            its content or its tools.
          </span>
        </li>
        <li>
          <span>
            You cannot navigate or search the Website using tools other than the
            ones that we make available to you or have otherwise approved,
            though you may use standard web browsers.
          </span>
        </li>
        <li>
          <span>
            You cannot use any data mining, robots, scrapers, or similar data
            gathering or extraction methods.
          </span>
        </li>
        <li>
          <span>
            You cannot aggregate, copy or duplicate any content, unless
            explicitly permitted by these Terms.
          </span>
        </li>
        <li>
          <span>
            You cannot frame or link to any content, unless explicitly permitted
            by these Terms.
          </span>
        </li>
        <li>
          <span>
            You cannot post any content that (includes links to another who)
            promotes, endorses or assists false or misleading information or
            illegal or any other activities that violate these Terms.
          </span>
        </li>
        <li>
          <span>
            You cannot access data or information not intended for you.
          </span>
        </li>
        <li>
          <span>
            You cannot submit information if it violates the intellectual
            property rights of others.
          </span>
        </li>
        <li>
          <span>You cannot delete or alter anything posted by others.</span>
        </li>
        <li>
          <span>
            You cannot harass, incite harassment or advocate harassment of any
            group, company, or individual.
          </span>
        </li>
        <li>
          <span>
            You cannot send unsolicited mail or email, &ldquo;junk mail,&rdquo;
            &ldquo;chain letters,&rdquo; or unsolicited mass mailing,
            &ldquo;spamming,&rdquo; or &ldquo;phishing,&rdquo; or make
            unsolicited phone calls or send unsolicited texts, tweets or faxes
            promoting and/or advertising products or services to any user of the
            Website.
          </span>
        </li>
        <li>
          <span>
            You cannot use our Services for any unlawful purpose or any illegal
            activity.
          </span>
        </li>
        <li>
          <span>
            You cannot post or submit any content, resume, or job ad that is
            defamatory, libelous, offensive, vulgar, obscene, threatening,
            abusive, hateful, racist, discriminatory, menacing, of a sexual
            nature, or likely to cause annoyance, inconvenience, embarrassment,
            violence, or anxiety or could cause harassment to any person or
            include any links to pornographic, indecent or sexually explicit
            material of any kind, nor can you link to any items that facilitate
            any of the foregoing.
          </span>
        </li>
        <li>
          <span>
            You cannot provide or link to material that exploits people under
            the age of 18 in a sexual, violent or other manner, or solicits
            personal information from anyone under 18.
          </span>
        </li>
        <li>
          <span>
            You cannot provide instructional information about illegal
            activities or other activities prohibited by these Terms, including
            making or buying illegal weapons.
          </span>
        </li>
        <li>
          <span>
            You must be 13 years of age or older to use the Website, and, if you
            are over 13 and under 18, you cannot use the Website without the
            supervision of a parent or legal guardian.
          </span>
        </li>
      </ul>
      <p>
        <em>
          <span>Ideas</span>
        </em>
      </p>
      <p>
        <span>
          You should not send us your creative suggestions, ideas, drawings,
          concepts, inventions, or other information (&quot;
          <strong>Your Ideas</strong>&quot;), but if you do, we may use Your
          Ideas that you submit to us without liability to you.
        </span>
      </p>
      <p>
        <em>
          <span>
            Identification of Agent to Receive Notification and Elements of
            Notification of Claimed Copyright or Trademark Infringement
          </span>
        </em>
      </p>
      <p>
        <span>
          If you believe that your copyrighted work or trademark has been
          uploaded, posted or copied to the Website and is accessible in a way
          that constitutes infringement against your rights, please contact us
          at:&nbsp;
        </span>
        <span>
          <a href="mailto:partnerships@escoffier.edu">
            <span>partnerships@escoffier.edu</span>
          </a>
        </span>
      </p>
      <p>
        <span>or by regular mail at:</span>
      </p>
      <p>
        <span>Auguste Escoffier School of Culinary Arts</span>
      </p>
      <p>
        <span>150 N. Martingale Rd.</span>
      </p>
      <p>
        <span>Suite 300</span>
      </p>
      <p>
        <span>Schaumburg, IL. 60173</span>
      </p>
      <p>
        <span>Attn: Partnerships</span>
      </p>
      <p>
        <em>
          <span>
            Policy Regarding Termination of Users Who Infringe the Copyright or
            Other Intellectual
          </span>
        </em>
        <span>Property</span>
      </p>
      <p>
        <span>
          We require that our users refrain from violating the intellectual
          property rights of others. You agree not to use the Website to
          infringe the intellectual property rights of any other person, and we
          reserve the right to terminate your account if you do. If, however,
          you believe in good faith that a notice of copyright infringement has
          been wrongly filed against you, please contact us as provided in
          Section 6 above.
        </span>
      </p>
      <p>
        <em>
          <span>Liability Disclaimers</span>
        </em>
      </p>
      <p>
        <span>
          We are not responsible for any content provided by users, or its
          quality, truth, accuracy, reliability, completeness, timeliness or
          legality, and we make no representation or warranties about any
          content provided by users.
        </span>
      </p>
      <p>
        <span>
          The risks of using content provided by other users may include risks
          of physical harm or of dealing with strangers, underage persons or
          people acting under false pretenses. You assume all of those risks,
          and we make no representation or warranties about relating to such
          risks. Because user authentication is difficult, we cannot know that a
          user is who they claim to be.
        </span>
      </p>
      <p>
        <span>
          In any of your dealings with other users, if a dispute arises, your
          sole remedy is against them, you release us (and our agents and
          employees) from any claims, demands and damages (actual and
          consequential and direct and indirect) of every kind and nature, known
          and unknown, suspected and unsuspected, disclosed and undisclosed,
          arising out of or in any way connected with such disputes to the
          fullest extent permitted by law.
        </span>
      </p>
      <p>
        <span>
          The Website and its content and its tools may contain or create
          inaccuracies or errors and any tool or form that you use may not work
          properly or as you expect. we makes no representations or warranties
          about the accuracy, reliability, completeness, or timeliness of the
          Website or its content or about the utility or the effectiveness of
          the tools, and you use the Website and its content and the tools is at
          your own risk.
        </span>
      </p>
      <p>
        <span>
          If you are a California resident, you waive California Civil Code
          Section 1542, that says: &quot;A general release does not extend to
          claims which the creditor does not know or suspect to exist in his or
          her favor at the time of executing the release, which if known by him
          or her must have materially affected his or her settlement with the
          debtor.&quot;
        </span>
      </p>
      <p>
        <span>
          We encourage you to keep a back-up copy of any of Your Content. We are
          not liable for the deletion, loss, or unauthorized modification of any
          User Content.
        </span>
      </p>
      <p>
        <span>
          We do not provide or make any representation as to the quality or
          nature of any third party products or services purchased through the
          Website.
        </span>
      </p>
      <p>
        <span>
          If you believe that something on the Site violates these Terms, please
          contact us&nbsp;
        </span>
        <span>
          <a href="mailto:partnerships@escoffier.edu">
            <span>partnerships@escoffier.edu</span>
          </a>
        </span>
      </p>
      <p>
        <span>
          If we are notified of any content, tools or other materials that do
          not conform to these Terms, we may investigate and make an appropriate
          determination.
        </span>
      </p>
      <p>
        <em>
          <span>Disclaimer of Warranties</span>
        </em>
      </p>
      <p>
        <span>
          TO THE FULLEST EXTENT PROVIDED BY LAW, WE DO NOT WARRANT THAT THE
          WEBSITE WILL OPERATE ERROR-FREE OR THAT OUR WEBSITE IS FREE OF
          COMPUTER VIRUSES OR OTHERHARMFUL MECHANISMS. IF YOUR USE OF OUR
          WEBSITE, ITS CONTENT, ANY TOOLS, OR OUR SERVICES RESULTS IN THE NEED
          FOR SERVICING OR REPLACING EQUIPMENT OR DATA OR ANY OTHER COSTS, WE
          ARE NOT RESPONSIBLE FOR THOSE COSTS. OUR WEBSITE AND ITS CONTENT AND
          OUR TOOLS ARE PROVIDED ON AN &quot;AS IS&quot; BASIS WITHOUT ANY
          WARRANTIES OF ANY KIND. WE, TO THE FULLEST EXTENT PERMITTED BYLAW,
          DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING
          THEWARRANTY OF MERCHANTABILITY, FITNESS FOR PARTICULAR PURPOSE AND
          NON-INFRINGEMENT. WE MAKE NO WARRANTIES ABOUT THE
          ACCURACY,RELIABILITY, COMPLETENESS, OR TIMELINESS OF THE WEBSITE, ITS
          CONTENT, OUR TOOLS, OUR SERVICES, SOFTWARE, TEXT, GRAPHICS, AND LINKS.
        </span>
      </p>
      <p>
        <em>
          <span>Disclaimer of Consequential Damages</span>
        </em>
      </p>
      <p>
        <span>
          WE DISCLAIM ALL INCIDENTAL AND CONSEQUENTIAL DAMAGES, LOST PROFITS, OR
          DAMAGES RESULTING FROM LOST DATA, LOST EMPLOYMENT OPPORTUNITY OR
          BUSINESS INTERRUPTION RESULTING FROM THE USE OR INABILITY TO USE THE
          WEBSITE, ITS CONTENT, OUR TOOLS AND OUR SERVICES, WHETHER BASED ON
          WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR
          NOT WE ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </span>
      </p>
      <p>
        <em>
          <span>Limitation of Liability</span>
        </em>
      </p>
      <p>
        <span>
          OUR MAXIMUM LIABILITY ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF
          THE WEBSITE, ITS CONTENT, OUR TOOLS, OR OUR SERVICES, REGARDLESS OF
          THE CAUSE OF ACTION (WHETHER IN CONTRACT, TORT,BREACH OF WARRANTY OR
          OTHERWISE) WILL NOT EXCEED $100.
        </span>
      </p>
      <p>
        <em>
          <span>Class Action Waiver</span>
        </em>
      </p>
      <p>
        <span>
          As a condition for your ability to use or access the Website, its
          content, our tools and our Services, you agree not to sue us as a
          class plaintiff or class representative, join a class action as a
          class member, or participate in a class-action lawsuit brought against
          us relating to your use of or access to the Website, its content, our
          tools or our Services. If you are a job seeker, you acknowledge that
          your use of the Website is at no charge to you, and you further
          acknowledge that part of the price you are paying us to use the
          Website is your acceptance of this class action waiver. Nothing in
          this paragraph, however, limits your right to bring a lawsuit as an
          individual plaintiff.
        </span>
      </p>
      <p>
        <em>
          <span>Links to Other Sites</span>
        </em>
      </p>
      <p>
        <span>
          The Website contains links to third-party websites. These links are
          provided solely as a convenience to you, and we are not responsible
          for the content of linked third-party sites and do not make any
          representations or warranties regarding the content or accuracy of
          materials on such third-party websites. If you decide to access a
          linked third-party website, you do so at your own risk.
        </span>
      </p>
      <p>
        <em>
          <span>Indemnity</span>
        </em>
      </p>
      <p>
        <span>
          You agree to defend, indemnify, and hold harmless us, our affiliates,
          and our and their officers, directors, employees and agents, from and
          against any claims, actions or demands, including without limitation
          reasonable legal and accounting fees, alleging or resulting from (i)
          any of Your Content or other material you provide to us, (ii) your use
          of the Website, its content, our tools, or our Services, or (iii) your
          breach of these Terms.
        </span>
      </p>
      <p>
        <em>
          <span>General</span>
        </em>
      </p>
      <p>
        <span>
          We make no claim or representation that the Website or its content may
          be lawfully viewed or accessed, or that our tools may be used, outside
          of the United States. Access to the Website or the content or our
          tools may not be legal by certain persons or in certain countries. If
          you access the Website or its content or use our tools, you do so at
          your own risk. You are responsible for compliance with the laws of
          your country or jurisdiction. Any software downloaded from the Website
          is further subject to United States export control laws, and you are
          responsible for the violation of any such laws. By downloading or
          using such software, you represent and warrant that you are not
          violating such laws.
        </span>
      </p>
      <p>
        <span>
          These Terms are governed by the internal substantive laws of the State
          of Illinois, without respect to its conflict of laws principles.
          Jurisdiction for any claims arising under this agreement shall lie
          exclusively with the state or federal courts within Cook County,
          Illinois. If any provision of these Terms are found to be invalid by
          any court having competent jurisdiction, the invalidity of such
          provision shall not affect the validity of the remaining provisions of
          these Terms, which shall remain in full force an d effect. No waiver
          of any term of these Terms shall be deemed a further or continuing
          waiver of such term or any other term. In addition, our failure to
          enforce any term of these Terms shall not be deemed as a waiver of
          such term or otherwise affect our ability to enforce such term at any
          point in the future. These Terms constitute the entire agreement
          between you and us with respect to the use of the Website, its
          content, our tools, or our Services. No changes to these Terms shall
          be made except by a revised posting on this page.
        </span>
      </p>
      <p>
        <em>
          <span>Mobile Services.</span>
        </em>
      </p>
      <p>
        <span>
          If you use the Website through a mobile device, you agree that
          information about your use of the Website through your mobile device
          and carrier may be communicated to us, including the identity of your
          mobile carrier and your mobile device and your physical location. In
          addition, use of the Website through a mobile device may cause data to
          be displayed on and through your mobile device. By accessing the
          Website using a mobile device, you represent that to the extent you
          import any of Your Content to your mobile device that you have
          authority to share the transferred data with your mobile carrier or
          other access provider. In the event you change or deactivate your
          mobile account, you must promptly update your Website account
          information to ensure that your messages are not sent to the person
          that acquires your old number and the failure to do so is your
          responsibility. You acknowledge you are responsible for all charges
          and necessary permissions related to accessing the Website through
          your mobile access provider. Therefore, you should check with your
          provider to find out if the Website is available and the terms for
          such services for your specific mobile devices.
        </span>
      </p>
      <p>
        <span>
          By using any downloadable application to enable your use of the
          Website, you are confirming your acceptance of the terms of the end
          user license agreement associated with the app provided at download or
          installation, or as it may be updated from time to time.
        </span>
      </p>
      <p>
        <span>Update effective:&nbsp;</span>
        <span>05/21/2023.</span>
      </p>
      <p>&nbsp;</p>
    </LegalContentSection>
  );
};

export default GlrcTermsOfUseContent;
