import * as React from 'react';
import { Theme, Typography } from '@mui/material';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { displayTextWithSelectLettersLarger } from '@common/helpers/displayTextWithSelectLettersLarger';
import { getGlrcTopicPath } from '@pages/Partner/GlrcContent/glrc.helpers';
import GlrcContentSection from '@pages/Partner/GlrcContent/GlrcContentSection';
import { glrcContentTypes } from '@pages/Partner/GlrcContent/glrcContentTypes';
import GlrcPageLayout from '@pages/Partner/GlrcPageLayout';
import { GlrcTopic } from '@interfaces/glrc.interfaces';
import GlrcVideoList from '@pages/Partner/GlrcVideoList';
import LessonsAndCoursesSection from '@pages/Partner/LessonsAndCoursesSection';
import { styled } from '@mui/system';
import { useFetchGlrcTopics } from '@common/fetches/useFetchGlrcTopics';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { useParams } from 'react-router-dom';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';

const Styled = {
  Title: styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: (theme as Theme).typography.EC_TYPE_5XL.fontSize,
    lineHeight: (theme as Theme).typography.EC_TYPE_5XL.lineHeight,
    padding: '0px 0px 16px',
    margin: '90px 0 24px',
    textTransform: 'uppercase'
  }))
};

interface RouteParams {
  topicPath: string;
}

const GlrcContentPage: React.FC = () => {
  const topicPath = useParams<RouteParams>().topicPath;
  const { companyId } = useParamsOrStoreCompanyId();
  const { keycloakUser } = useKeycloakContext();

  const [topic, setTopic] = React.useState<GlrcTopic>();

  const { glrcTopics, loaded: topicsLoaded } = useFetchGlrcTopics(companyId);

  const content = glrcContentTypes.find((c) => c.path === topicPath);

  const title = topic?.name ?? content?.name;
  const subtitle = content?.description ?? topic?.description ?? '';
  const hasSubtopics = !!topic?.subTopics.some((st) => st.name !== null);

  React.useEffect(() => {
    if (topicsLoaded) {
      const findTopic = glrcTopics?.find(
        (t) => getGlrcTopicPath(t.name) === topicPath
      );

      setTopic(findTopic);
    }
  }, [topicsLoaded, glrcTopics, content]);

  return (
    <GlrcPageLayout
      heroTitle={title || ''}
      heroSubtitle={subtitle}
      contentLoaded={!!topicsLoaded}
    >
      <BottomMarginContentWrapper bottomMargin="30px">
        {!!topic && (
          <>
            <LessonsAndCoursesSection
              courses={topic.fullCourses}
              userId={keycloakUser.sub}
            />
            <LessonsAndCoursesSection
              courses={topic.microCourses}
              userId={keycloakUser.sub}
            />
            {hasSubtopics && (
              <Styled.Title variant="EC_TYPE_3XL">
                {displayTextWithSelectLettersLarger('[Q]UICK[S]KILLS', 58)}
              </Styled.Title>
            )}
            {topic?.subTopics.map((subTopic, idx) => (
              <GlrcVideoList
                key={subTopic.name + idx}
                subtitle={subTopic.name}
                videos={subTopic.videos}
                showAllVideos
                userId={keycloakUser.sub}
              />
            ))}
          </>
        )}
        {!!content && (
          <GlrcContentSection
            path={topicPath}
            topics={glrcTopics}
            userId={keycloakUser.sub}
          />
        )}
      </BottomMarginContentWrapper>
      <Typography
        variant="EC_TYPE_SM"
        sx={{ marginTop: '95px', fontWeight: 400 }}
      >
        * Completion of QuickCourses or Courses does not constitute academic
        credit, nor will they qualify for transfer of academic credit in any
        academic program or course offered by Auguste Escoffier School of
        Culinary Arts under the terms of the school&apos;s licensing or
        accredited programs.
      </Typography>
    </GlrcPageLayout>
  );
};

export default GlrcContentPage;
