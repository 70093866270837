import { ApproverRole, ExternshipApproverEntity } from '@api/models/externshipApi.models';
import { ExternshipProposalApprover } from '@api/models/externshipProposalApi.models';

/**
 * Sorting approvers
 * 1. Manager always first
 * 2. Supervisors and Mentors ascending by id 
 */
export const sortExternshipProposalApprovers = (approvers: ExternshipApproverEntity[]): ExternshipProposalApprover[] => {
  return approvers.sort((a, b) => {
    return a.id - b.id;
  }).sort((a) => {
    return a.approverRole === ApproverRole.MANAGER ? -1 : 1;
  }).map((approver) => {
    return { ...approver, ...{ email: approver.user?.email || approver.invite?.email || '' } };
  });
};