/* eslint-disable */
import * as React from 'react';
import {
  AttestationEntity,
  AttestationStatus
} from '@interfaces/attestation.interface';
import { Box, Button, Link, Theme, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useHistory } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    display: 'flex',
    float: 'right',
    marginLeft: 'auto',
    alignItems: 'center',
    columnGap: '32px',
    '& i': {
      fontSize: '16px'
    },
    [theme.breakpoints.down('sm')]: {
      margin: 'unset',
      float: 'unset',
      width: '100%'
    }
  })),
  DownloadBtn: styled(Button)(({ theme }) => ({
    ...(theme as Theme).typography.EC_TYPE_2XS,
    color: theme.palette.primary.main,
    padding: 0,
    whiteSpace: 'nowrap'
  }))
};

interface AttestationProps {
  externshipId: number;
  attestation: AttestationEntity;
  attestationStatus: AttestationStatus;
  companyName: string;
}

const AttestationSection: React.FC<AttestationProps> = ({
  externshipId,
  attestation,
  attestationStatus,
  companyName
}) => {
  const history = useHistory();

  const handleSurveyClick = (): void => {
    history.push(`/externship/${externshipId}/attestation`);
  };

  const filePath = attestation.file?.fullName
    ? `files/${attestation.file?.fullName}`
    : '';

  return (
    <Styled.Root>
      <Typography variant="EC_TYPE_3XS" fontWeight={700} ml="auto">
        Attestation Survey - {attestation.type?.label}
      </Typography>
      {(attestationStatus === AttestationStatus.SENT ||
        attestationStatus === AttestationStatus.OPENED) && (
        <Button
          variant="outlined"
          onClick={handleSurveyClick}
          sx={{
            ml: 'auto',
            typography: 'EC_TYPE_2XS',
            color: 'primary.main',
            whiteSpace: 'nowrap'
          }}
        >
          Take Survey
        </Button>
      )}
      {attestationStatus === AttestationStatus.COMPLETED && (
        <Link
          href={`${BASE_URL}/${filePath}`}
          target="_blank"
          rel="noopener noreferrer"
          underline="none"
          sx={{ ml: 'auto' }}
        >
          <Styled.DownloadBtn
            variant="text"
            startIcon={<i className="ri-file-text-fill" />}
            size="small"
          >
            Download Attestation
          </Styled.DownloadBtn>
        </Link>
      )}
    </Styled.Root>
  );
};

export default AttestationSection;
