import * as React from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import CounterBox, {
  CounterBoxProps
} from '@components/TableComponents/CounterBox';
import TableWrapperWithSort, {
  TableSortWrapperProps
} from '@components/TableWrapperWithSort';
import EcCrownWithLines from '@components/EcCrownWithLines';
import ImageWithBadge from '@components/ImageWithBadge';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useKeycloakContext } from '@common/context/keycloakContext';

const Styled = {
  FlexRow: styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    rowGap: '20px',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  })),
  LogoNameBox: styled(Box)({
    display: 'flex',
    alignItems: 'center',
    columnGap: '20px',
    minWidth: '0px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    img: {
      objectFit: 'contain',
      padding: '8px'
    }
  }),
  CounterDiv: styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '16px',
    [theme.breakpoints.down('md')]: {
      gap: '8px'
    }
  })),
  CrownBox: styled(Box)({
    flex: 2,
    display: 'flex',
    justifyContent: 'center'
  }),
  PageHeaderTitle: styled(Typography)(({ theme }) => ({
    marginRight: '8px',
    marginBottom: '6px',
    fontSize: '36px',
    lineHeight: '44px',
    color: theme.palette.GOLD_1.main,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }))
};

interface DashButtonProps {
  href: string;
  variant?: 'text' | 'outlined' | 'contained';
  icon: string;
  dataTestId?: string;
  label: string;
  sx?: React.CSSProperties;
  fullWidth?: boolean;
}

const DashButton = ({
  href,
  variant,
  icon,
  dataTestId,
  label,
  fullWidth,
  sx
}: DashButtonProps): JSX.Element => {
  return (
    <Button
      size="small"
      component={RouterLink}
      variant={variant}
      startIcon={<i className={icon} />}
      data-testid={dataTestId}
      fullWidth={fullWidth}
      to={href}
      sx={{
        ...sx,
        height: '58px'
      }}
    >
      {label}
    </Button>
  );
};

export interface DashboardTableLayoutProps {
  pageTitle: string;
  logo?: string;
  subtitleButton?: DashButtonProps;
  ctaButton?: DashButtonProps;
  children?: React.ReactNode;
  handlePageChange?: (event, newPage: number) => void;
  handleRowsPerPageChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  companyEnabled?: boolean;
  counterProps?: CounterBoxProps[];
  tableSortWrapperProps?: TableSortWrapperProps | null;
}

const DashboardTableLayout: React.FC<DashboardTableLayoutProps> = ({
  pageTitle,
  logo,
  subtitleButton,
  ctaButton,
  companyEnabled,
  children,
  counterProps,
  tableSortWrapperProps = null
}) => {
  const featureFlags = useFeatureFlags();

  const theme = useTheme();

  const { isEcAdmin, isEmployer } = useKeycloakContext();
  const isEcAdminOrEmployer = isEcAdmin || isEmployer;

  return (
    <>
      <Styled.FlexRow
        sx={{
          ...(counterProps && {
            mb: '48px',
            [theme.breakpoints.down('sm')]: {
              mb: '28px'
            }
          })
        }}
      >
        <Styled.LogoNameBox>
          {logo && <ImageWithBadge src={logo} height="100px" width="160px" />}
          <Styled.PageHeaderTitle
            variant="EC_TYPE_3XL"
            data-testid="dashboard-page-title"
          >
            {pageTitle}
          </Styled.PageHeaderTitle>
        </Styled.LogoNameBox>
        {counterProps && featureFlags.EMPLOYER_MARKETING_METRICS && (
          <Styled.CounterDiv>
            {counterProps.map((counter) => (
              <CounterBox
                key={counter.title}
                title={counter.title}
                count={counter.count}
                resizeBreakpoint={900}
              />
            ))}
          </Styled.CounterDiv>
        )}
      </Styled.FlexRow>
      {subtitleButton && isEcAdminOrEmployer && (
        <DashButton
          href={subtitleButton.href}
          variant={subtitleButton.variant}
          icon={subtitleButton.icon}
          dataTestId={subtitleButton.dataTestId}
          label={subtitleButton.label}
          sx={{
            minWidth: 0,
            minHeight: 0,
            height: 'unset',
            width: 'unset',
            padding: 0,
            color: theme.palette.GRAY_3.main
          }}
        />
      )}
      <Styled.FlexRow sx={{ margin: '28px 0' }}>
        {ctaButton && companyEnabled === true && (
          <DashButton
            href={ctaButton.href}
            variant={ctaButton.variant}
            icon={ctaButton.icon}
            dataTestId={ctaButton.dataTestId}
            label={ctaButton.label}
            sx={{
              ...ctaButton.sx,
              minWidth: 0,
              maxWidth: '169px',
              [theme.breakpoints.down('sm')]: {
                maxWidth: 'unset'
              }
            }}
          />
        )}
        {counterProps && (
          <Styled.CrownBox>
            <EcCrownWithLines
              opacity={0.5}
              maxWidth="60%"
              wrapperStyle={{
                transform: 'scale(0.7)',
                [theme.breakpoints.down('sm')]: {
                  display: 'none'
                }
              }}
            />
          </Styled.CrownBox>
        )}
      </Styled.FlexRow>
      {tableSortWrapperProps ? (
        <TableWrapperWithSort {...tableSortWrapperProps}>
          {children}
        </TableWrapperWithSort>
      ) : (
        children
      )}
    </>
  );
};

export default DashboardTableLayout;
