import * as React from 'react';
import {
  AdminDashJobPost,
  AdminDashJobPostsReq
} from '@api/models/adminDashboardApi.models';
import {
  getMultipleLocationsDisplay,
  withCellClickHandler
} from '@common/helpers/tableModalHelpers';
import { Table, TableBody, TableHead } from '@mui/material';
import BasicModal from '@components/BasicModal';
import BottomPagination from '@components/BottomPagination';
import CsvButton from '@components/CsvButton';
import { DateRange } from '@common/models/common.models';
import { SortOrderType } from '@interfaces/search.interfaces';
import { Styled } from '@pages/EcAdmin/AdminDashboardPage/CandidateAccountsSection/StyledTabledComponents';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchJobPostByCompanyIdCsv } from '@common/fetches/csv/useFetchJobPostByCompanyIdCsv';
import { useFetchJobPostsForAdminDash } from '@common/fetches/useFetchJobPostsForAdminDash';

interface RowProps {
  jobPost: AdminDashJobPost;
}

const Row: React.FC<RowProps> = ({ jobPost }) => {
  const {
    jobPostId,
    jobTitle,
    locations,
    visibleOnMarketplace,
    createdDate,
    applicants
  } = jobPost;

  const visStatus = visibleOnMarketplace ? 'Active' : 'Inactive';

  const cities = React.useMemo(() => {
    return getMultipleLocationsDisplay('city', locations);
  }, [locations]);

  const states = React.useMemo(() => {
    return getMultipleLocationsDisplay('state', locations);
  }, [locations]);

  const handleJobTitleClick = (e): void => {
    e.stopPropagation();
    window.open(`/job/${jobPostId}`);
  };

  const cells = [
    withCellClickHandler(jobTitle, handleJobTitleClick),
    cities,
    states,
    applicants,
    visStatus,
    createdDate
  ];

  return (
    <Styled.TableRow>
      {cells.map((cell, idx) => (
        <Styled.TableCell key={idx} bottomBorder={false}>
          {cell}
        </Styled.TableCell>
      ))}
    </Styled.TableRow>
  );
};

interface Props {
  open: boolean;
  companyId: number;
  companyName: string;
  dateRange?: DateRange;
  handleClose: () => void;
}

const defaultPageInfo = {
  rowsPerPage: 25,
  currentPage: 1,
  sortCategory: 'createdAt',
  sortOrder: 'ASC' as SortOrderType
};

const JobPostsModal: React.FC<Props> = ({
  open,
  companyId,
  companyName,
  dateRange,
  handleClose
}) => {
  const [jobPostsReq, setJobPostsReq] = React.useState<AdminDashJobPostsReq>({
    ...dateRange,
    pageInfo: defaultPageInfo
  });

  const featureFlags = useFeatureFlags();

  const { jobPosts, pageInfo } = useFetchJobPostsForAdminDash(
    jobPostsReq,
    companyId
  );

  const { fetchJobPostsCsvByCompany } =
    useFetchJobPostByCompanyIdCsv(companyId);

  const handlePagination = (event, value: number): void => {
    const newPageInfo = { ...jobPostsReq.pageInfo, currentPage: value };
    setJobPostsReq({ ...jobPostsReq, pageInfo: newPageInfo });
  };

  const columns = [
    'Job Title',
    'City',
    'State',
    'Applicants',
    'Status',
    'Create Date'
  ];

  const csvHeaders = [
    { label: 'Id', key: 'jobPostId' },
    { label: 'Job Title', key: 'jobTitle' },
    { label: 'Locations', key: 'locations' },
    { label: 'Applicants', key: 'applicants' },
    { label: 'Active', key: 'visibleOnMarketplace' },
    { label: 'Created Date', key: 'createdAt' }
  ];

  return (
    <BasicModal
      title={`Job Posts (${companyName})`}
      titleMarginBottom="0px"
      open={open}
      handleClose={handleClose}
    >
      {jobPosts && pageInfo && (
        <>
          {featureFlags.REPORTING_DASH && (
            <CsvButton
              headers={csvHeaders}
              fileName={`job_posts_${companyName}.csv`}
              fetchData={fetchJobPostsCsvByCompany}
            />
          )}
          <Styled.TableContainer>
            <Table stickyHeader aria-label="job posts table">
              <TableHead>
                <Styled.TableRow>
                  {columns.map((col, idx: number) => (
                    <Styled.HeaderTableCell key={`${col}-${idx}`}>
                      {col}
                    </Styled.HeaderTableCell>
                  ))}
                </Styled.TableRow>
              </TableHead>
              <TableBody>
                {jobPosts.map((job, idx: number) => (
                  <Row key={`${job.jobPostId}-${idx}`} jobPost={job} />
                ))}
              </TableBody>
            </Table>
          </Styled.TableContainer>
          <BottomPagination
            count={pageInfo.totalPages}
            page={pageInfo.currentPage}
            onChange={handlePagination}
            defaultPage={1}
            wrapperSx={{
              marginTop: '35px'
            }}
          />
        </>
      )}
    </BasicModal>
  );
};

export default JobPostsModal;
