/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Box, OutlinedInput, useTheme } from '@mui/material';
import CsCompaniesTable from '@pages/CareerServices/CsCompaniesPage/CsCompaniesTable';
import ManageCompaniesSkeleton from '@components/Skeletons/ManageCompaniesSkeleton';
import NoDataMessage from '@components/NoDataMessage';
import RootPageLayout from '@components/Layout/RootPageLayout';
import SearchIcon from '@mui/icons-material/Search';
import StepSection from '@components/StepSection';
import { useFetchCareerServiceCompanies } from '@common/fetches/useFetchCareerServiceCompanies';

const CsCompaniesPage: React.FC = () => {
  const theme = useTheme();

  const { companies, loading } = useFetchCareerServiceCompanies();
  const [filteredCompanies, setFilteredCompanies] = React.useState(companies);

  React.useEffect(() => {
    setFilteredCompanies(companies);
  }, [companies]);

  const filterCompanies = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const keyword = e.target.value.toLowerCase().trim();

    const filtered = !keyword?.length
      ? companies
      : companies.filter((company) =>
          company?.users?.some((user) => {
            const fullName = `${user?.firstName} ${user?.lastName}`
              .toLowerCase()
              .trim();
            const fullNameReversed = `${user?.lastName} ${user?.firstName}`
              .toLowerCase()
              .trim();
            return (
              user?.email?.toLowerCase().includes(keyword) ||
              fullName?.toLowerCase().includes(keyword) ||
              fullNameReversed?.toLowerCase().includes(keyword) ||
              user?.phone?.toLowerCase().includes(keyword) ||
              user?.phone?.replace(/\D/g, '').toLowerCase().includes(keyword)
            );
          })
        );

    setFilteredCompanies(filtered);
  };

  return (
    <RootPageLayout
      muiMaxWidth="xl"
      pageTitle="Companies"
      bgColor={theme.palette.grey1.main}
    >
      <StepSection>
        {loading ? (
          <ManageCompaniesSkeleton />
        ) : (
          <>
            <OutlinedInput
              onChange={filterCompanies}
              endAdornment={<SearchIcon />}
            />
            {filteredCompanies?.length ? (
              <CsCompaniesTable companies={filteredCompanies} />
            ) : (
              <Box p={2}>
                <NoDataMessage
                  title={'No externship related users found for any company'}
                />
              </Box>
            )}
          </>
        )}
      </StepSection>
    </RootPageLayout>
  );
};

export default CsCompaniesPage;
