/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  Box,
  Button,
  FormControl,
  Icon,
  Select,
  Theme,
  Typography,
  useTheme
} from '@mui/material';
import {
  DateFetchType,
  useFetchExternshipDates
} from '@common/fetches/useFetchExternshipDates';
import {
  getClosestDate,
  getLastDatePast31Days,
  getYear31DaysAgo
} from '@common/helpers/dateHelpers/getClosestDate';
import BasicModal from '@components/BasicModal';
import BatchActions from '@pages/CareerServices/shared/BatchActions';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { CareerServicesApi } from '@api/CareerService.api';
import { CareerServicesExtern } from '@api/models/careerServicesApi.models';
import CSDashboardTopFilters from '@pages/CareerServices/CareerServicesDashboardPage/CSDashboardTopFilters';
import { ExternshipEntity } from '@api/models/externshipApi.models';
import { FetchGridTable } from '@common/fetches/useFetchGridTableByType';
import { GridColDef } from '@mui/x-data-grid-pro';
import { GridTableApi } from '@api/GridTable.api';
import { GridTableView } from '@api/models/gridTableApi.models';
import RootPageLayout from '@components/Layout/RootPageLayout';
import SaveGridTableViewModal from '@pages/CareerServices/ExternshipTrackerPage/SaveGridTableViewModal';
import { styled } from '@mui/system';
import { useBatchActionsContext } from '@common/context/batchActionsContext';
import { useKeycloakContext } from '@common/context/keycloakContext';

const Styled = {
  FlexContentWrapper: styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    rowGap: '8px',
    columnGap: '16px',
    '@media (max-width:900px)': {
      flexDirection: 'column'
    },
    '& .page-title': {
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
      fontSize: (theme as Theme).typography.EC_TYPE_5XL.fontSize,
      lineHeight: (theme as Theme).typography.EC_TYPE_5XL.lineHeight
    }
  })),
  FormControl: styled(FormControl)({
    minWidth: '200px',
    ['@media (max-width:599px)']: {
      minWidth: 'unset'
    }
  }),
  Select: styled(Select)(({ theme }) => ({
    '.MuiSelect-select': {
      color: theme.palette.GRAY_3.main
    }
  })),
  RefreshButton: styled(Button)({
    '&.MuiButtonBase-root:hover': {
      bgcolor: 'transparent'
    }
  }),
  RefreshSection: styled(Box)({
    textAlign: 'right',
    '@media (max-width:900px)': {
      textAlign: 'left'
    }
  })
};

interface Props {
  pageTitle: string;
  lastRefresh?: string;
  externs: CareerServicesExtern[] | ExternshipEntity[];
  loadingExterns?: boolean;
  pageBtnMidRight?: React.ReactElement | null;
  gridTableProps: FetchGridTable;
  gridTableColumns?: GridColDef[];
  displayViewSelect?: boolean;
  currDate?: Date;
  dateFetchType?: DateFetchType;
  displayRefreshSection?: boolean;
  customFilterBtnText?: string;
  setCurrDate: (date?: Date) => void;
  sessionValue?: string;
}

const BatchActionsPageLayout: React.FC<Props> = ({
  pageTitle,
  lastRefresh,
  externs,
  loadingExterns,
  pageBtnMidRight,
  currDate,
  gridTableProps,
  gridTableColumns,
  displayViewSelect = false,
  dateFetchType = 'START_DATES',
  displayRefreshSection = true,
  customFilterBtnText,
  children,
  setCurrDate,
  sessionValue
}) => {
  const fetchByGradDates = dateFetchType === 'GRAD_DATES';
  const theme = useTheme();
  const { keycloakUser } = useKeycloakContext();

  const { refetch: handleRefresh } = useBatchActionsContext();

  // For renaming view modal
  const [viewToRename, setViewToRename] = React.useState<GridTableView>();

  // For deleting view modal
  const [openDeleteViewModal, setOpenDeleteViewModal] =
    React.useState<number>();

  // For Delete View Modal
  const [isDeleting, setIsDeleting] = React.useState(false);

  // For displaying the "Save View" Modal
  const [showViewModal, setShowViewModal] = React.useState(false);

  // For externship start date selection to retrieve enrollments
  const [year, setYear] = React.useState<string>();

  const {
    gridTable,
    currView,
    setCurrView,
    setGridTable,
    getFilterColumns,
    getSaveGridTableViewColumns,
    handleViewChange,
    calculateViewCounts,
    viewCounts
  } = gridTableProps;

  // Retrieve all externship start dates for the drop down filter
  const {
    externshipDatesByYear,
    years,
    loaded: startDateLoaded,
    savedExternshipDate
  } = useFetchExternshipDates(dateFetchType);

  // Once externshipDatesByYear and years loads
  // set year to current year or latest year
  // set currDate to closest externship start date to today
  React.useEffect(() => {
    if (!!externshipDatesByYear && years?.length) {
      const todaysDate = new Date();

      let calcYear = fetchByGradDates
        ? getYear31DaysAgo(todaysDate, externshipDatesByYear)
        : String(todaysDate.getFullYear());

      if (!years.includes(calcYear)) {
        calcYear = years[years.length - 1];
      }

      if (startDateLoaded) {
        const defaultYear = savedExternshipDate
          ? new Date(savedExternshipDate).getFullYear().toString()
          : calcYear;

        setYear(defaultYear);
      }

      const datesInYear = externshipDatesByYear[calcYear] || [];

      // Grad dates need to display the most recent date within 31 days by default
      if (fetchByGradDates) {
        const pastDate = getLastDatePast31Days(datesInYear, todaysDate);
        setCurrDate(pastDate);
      } else {
        if (startDateLoaded) {
          setCurrDate(
            savedExternshipDate || getClosestDate(datesInYear, todaysDate)
          );
        }
      }
    }
  }, [
    externshipDatesByYear,
    years,
    fetchByGradDates,
    savedExternshipDate,
    startDateLoaded
  ]);

  const saveExternshipDatePreference = (date: Date): void => {
    CareerServicesApi.saveExternshipDatePreference(date);
  };

  React.useEffect(() => {
    if (currDate && dateFetchType === 'START_DATES') {
      saveExternshipDatePreference(currDate);
    }
  }, [currDate]);

  // Default view will always be the same so only update
  // counts if customViews are updated
  React.useEffect(() => {
    if (gridTable && externs) {
      calculateViewCounts(externs);
    }
  }, [externs, gridTable?.customViews]);

  const handleCloseViewModal = (e?): void => {
    if (e) {
      e.stopPropagation();
    }

    setShowViewModal(false);
    setViewToRename(undefined);
  };

  const handleDeleteViewModalClose = (e): void => {
    e.stopPropagation();
    setOpenDeleteViewModal(undefined);
  };

  const handleDeleteView = async (e, viewId: number): Promise<void> => {
    e.stopPropagation();
    setIsDeleting(true);

    if (gridTable) {
      await GridTableApi.deleteGridTableView(gridTable.id, viewId);

      const newCustomViews = gridTable.customViews.filter(
        (v) => v.id !== viewId
      );

      const newGridTable = {
        ...gridTable,
        customViews: newCustomViews
      };

      setGridTable(newGridTable);
      setOpenDeleteViewModal(undefined);
      setCurrView({ currView: gridTable.defaultViews[0].id });
      setIsDeleting(false);
    }
  };

  // Only Available in lower environments for all users
  // In prod it will only be available for test.cs@yopmail.com (developer account)
  const isProd = process.env.REACT_APP_FEATURE_FLAG_ENV === 'prod';
  const displayRefreshBtn =
    !isProd || keycloakUser.sub === 'b9596f94-0ee6-4d6a-80c1-3fc9e5aec6ae';

  return (
    <>
      <RootPageLayout muiMaxWidth={false} bgColor={theme.palette.WHITE.main}>
        <BottomMarginContentWrapper bottomMargin="40px">
          <Styled.FlexContentWrapper>
            <Typography variant="EC_TYPE_3XL" className="page-title">
              {pageTitle}
            </Typography>
            {displayRefreshSection && (
              <Styled.RefreshSection>
                {displayRefreshBtn && (
                  <Styled.RefreshButton
                    variant="text"
                    className="disable-while-loading"
                    startIcon={<Icon className="ri-history-line" />}
                    onClick={(): void => handleRefresh()}
                    disabled={!!loadingExterns}
                    sx={{ typography: (theme as Theme).typography.EC_TYPE_LG }}
                  >
                    Refresh Data
                  </Styled.RefreshButton>
                )}
                {lastRefresh && (
                  <Typography>Last updated {lastRefresh}</Typography>
                )}
              </Styled.RefreshSection>
            )}
          </Styled.FlexContentWrapper>
          <Styled.FlexContentWrapper>
            {pageBtnMidRight}
          </Styled.FlexContentWrapper>
          <Styled.FlexContentWrapper>
            {!!startDateLoaded && !!currView && (
              <CSDashboardTopFilters
                gridTable={gridTable}
                currView={currView}
                year={year}
                years={years}
                dateString={currDate?.toISOString()}
                externshipDatesByYear={externshipDatesByYear}
                viewCounts={viewCounts}
                displayViewSelect={displayViewSelect}
                pageLoading={loadingExterns}
                isGradDateSelect={fetchByGradDates}
                customFilterBtnText={customFilterBtnText}
                setViewToRename={setViewToRename}
                setShowViewModal={setShowViewModal}
                setOpenDeleteViewModal={setOpenDeleteViewModal}
                setGridTable={setGridTable}
                setYear={setYear}
                setCurrDate={setCurrDate}
                handleViewChange={handleViewChange}
                handleRetrieveEnrollments={(): void => handleRefresh(false)}
                sessionValue={sessionValue}
              />
            )}
            <BatchActions />
          </Styled.FlexContentWrapper>
          {children}
        </BottomMarginContentWrapper>
      </RootPageLayout>
      {gridTable && gridTableColumns && (
        <SaveGridTableViewModal
          setCurrView={setCurrView}
          getFilterColumns={getFilterColumns}
          getSaveGridTableViewColumns={getSaveGridTableViewColumns}
          open={showViewModal}
          viewToRename={viewToRename}
          handleClose={(e): void => handleCloseViewModal(e)}
          gridTable={gridTable}
          columns={gridTableColumns}
          setGridTable={setGridTable}
        />
      )}
      {openDeleteViewModal && (
        <BasicModal
          open={!!openDeleteViewModal}
          handleClose={(e): void => handleDeleteViewModalClose(e)}
          title={'Delete View'}
          primaryAction={{
            label: 'Confirm',
            action: (e): Promise<void> =>
              handleDeleteView(e, openDeleteViewModal),
            disabled: isDeleting
          }}
          secondaryAction={{
            label: 'Cancel',
            action: (e): void => handleDeleteViewModalClose(e)
          }}
          maxWidth="sm"
        >
          <Typography variant="EC_TYPE_BASE">
            Are you sure you want to delete this view?
          </Typography>
        </BasicModal>
      )}
    </>
  );
};

export default BatchActionsPageLayout;
