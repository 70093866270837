import { api } from './configs/base';
import { apiConfig } from './configs/api.config';
import { ApiResponse } from './models/base.models';
import { Banner } from './models/bannersApi.models';

export class BannersApi {
  static async getBannersForUser(
    userId: string
  ): Promise<ApiResponse<Banner[]>> {
    try {
      const res = await api.get<ApiResponse<Banner[]>>(
        `${apiConfig.bannersUrl}/users/${userId}`
      );
      return res.data;
    } catch (error: any) {
      console.error(`Error for BannersApi.getBannersForUser(): ${error}`);
      throw error;
    }
  }

  static async markBannerAsRead(bannerId: number): Promise<void> {
    try {
      await api.patch(`${apiConfig.bannersUrl}/${bannerId}/read`);
      return;
    } catch (error: any) {
      console.error(`Error for BannersApi.markBannerAsRead(): ${error}`);
      throw error;
    }
  }
}
