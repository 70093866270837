import * as React from 'react';
import { IconButton } from '@mui/material';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const Icon = styled('i')({
  fontSize: '11px',
  transform: 'translateY(1px)'
});

interface EditButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  btnColor?: string;
  iconColor?: string;
  size?: string;
  sx?: React.CSSProperties;
  dataTestId?: string;
}

const EditSectionButton = ({
  onClick,
  btnColor = theme.palette.gold1.main,
  iconColor = theme.palette.GRAY_4.main,
  size = '28px',
  sx,
  dataTestId
}: EditButtonProps): React.ReactElement => {
  return (
    <IconButton
      data-testid={dataTestId}
      onClick={onClick}
      sx={{
        backgroundColor: btnColor,
        color: iconColor,
        height: size,
        width: size,
        ':hover': {
          backgroundColor: theme.palette.gold1.main
        },
        ...sx
      }}
    >
      <Icon className="ri-edit-fill" />
    </IconButton>
  );
};

export default EditSectionButton;
