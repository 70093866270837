import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';

const Styled = {
  RatingBox: styled(Box)(
    (props: { selected: boolean; uneditable: boolean }) => ({
      border: `1px solid ${theme.palette.GRAY_3.main}`,
      borderRadius: '4px',
      padding: '8px',
      textAlign: 'center',
      backgroundColor: props.selected
        ? theme.palette.GOLD_1.main
        : theme.palette.WHITE.main,
      boxSizing: 'border-box',
      '.MuiTypography-root': {
        color: props.selected ? 'white' : theme.palette.GRAY_4.main
      },
      ...(!props.uneditable && {
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: props.selected
            ? theme.palette.GOLD_1.main
            : theme.palette.gold1.main,

          '.MuiTypography-root': {
            color: props.selected ? 'white' : theme.palette.GOLD_1.main
          }
        }
      })
    })
  )
};

interface Props {
  name: string;
  ratings: number[];
  rules?: RegisterOptions;
  label: string;
  uneditable: boolean;
}

const ReactHookFormsRatingSelection: React.FC<Props> = ({
  name,
  ratings,
  rules,
  label,
  uneditable
}) => {
  const { control } = useFormContext();

  return (
    <Box id={name}>
      {!uneditable && (
        <Typography variant="EC_TYPE_BASE" mb={2}>
          {label}
        </Typography>
      )}
      <Controller
        control={control}
        name={name}
        rules={{ ...rules }}
        render={({ field: { value, onChange } }): JSX.Element => {
          return (
            <>
              {uneditable ? (
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={3}
                  alignItems="center"
                >
                  <Grid item xs={3} sm={2} md={1}>
                    <Styled.RatingBox uneditable={uneditable} selected={false}>
                      <Typography
                        variant="EC_TYPE_BASE"
                        sx={{ fontWeight: '800' }}
                      >
                        {value}
                      </Typography>
                    </Styled.RatingBox>
                  </Grid>
                  <Grid item xs={9} sm={10} md={11}>
                    <Typography variant="EC_TYPE_BASE">{label}</Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={1} alignItems="center">
                  {ratings.map((rating) => (
                    <Grid item key={rating} xs={12 / 5} md={1.5}>
                      <Styled.RatingBox
                        uneditable={uneditable}
                        selected={value === rating}
                        onClick={
                          uneditable ? undefined : (): void => onChange(rating)
                        }
                      >
                        <Typography variant="EC_TYPE_BASE">{rating}</Typography>
                      </Styled.RatingBox>
                    </Grid>
                  ))}
                </Grid>
              )}
            </>
          );
        }}
      />
    </Box>
  );
};

export default ReactHookFormsRatingSelection;
