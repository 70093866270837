import * as React from 'react';
import { Button, Grid, GridProps } from '@mui/material';
import { emailPattern } from '@common/regex/email';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import ReactHookFormsInputPhoneMask from '@components/Forms/ReactHookFormsInputPhoneMask';
import ReactHookFormsSelect from '@components/Forms/ReactHookFormsSelect';
import { styled } from '@mui/system';

const Styled = {
  Button: styled(Button)({
    marginTop: '24px',
    padding: 0
  }),
  BtnIcon: styled('i')({
    fontSize: '17px',
    lineHeight: '17px'
  })
};

const selectOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 }
];

interface FieldGridProps {
  firstName?: GridProps;
  lastName?: GridProps;
  email?: GridProps;
  phone?: GridProps;
  jobTitle?: GridProps;
  signingTimeCard?: GridProps;
}

const defaultGridItemSize = { xs: 12, sm: 6 };
const defaultGridProps: FieldGridProps = {
  firstName: defaultGridItemSize,
  lastName: defaultGridItemSize,
  email: defaultGridItemSize,
  phone: defaultGridItemSize,
  jobTitle: defaultGridItemSize,
  signingTimeCard: { xs: 12 }
};

interface Props {
  isManagersVerificationPage?: boolean;
  isSupervisor?: boolean;
  disableOnlyPersonSigningField?: boolean;
  disableLabels?: boolean;
  disableRemoveButton?: boolean;
  showPlaceholders?: boolean;
  containerSpacing?: number;
  fieldNamePrefix?: string;
  fieldGridProps?: FieldGridProps;
  fieldArrayIndex?: number;
  handleRemoveFieldArrayItem?: (idx: number) => void;
}

/**
 * @deprecated
 * Use ExternshipProposalApproverFieldArray
 */
const ExternshipApproverFieldArray: React.FC<Props> = ({
  isManagersVerificationPage,
  isSupervisor = false,
  disableOnlyPersonSigningField = false,
  disableLabels = false,
  disableRemoveButton = false,
  showPlaceholders = false,
  containerSpacing = 3,
  fieldNamePrefix,
  fieldGridProps = defaultGridProps,
  fieldArrayIndex,
  handleRemoveFieldArrayItem
}) => {
  const getFieldName = (name: string): string => {
    const fieldName = isSupervisor
      ? `supervisors.${fieldArrayIndex}.${name}`
      : name;

    return `${fieldNamePrefix ?? ''}${fieldName}`;
  };

  const handleRemove = (): void => {
    if (fieldArrayIndex !== undefined && handleRemoveFieldArrayItem) {
      handleRemoveFieldArrayItem(fieldArrayIndex);
    }
  };

  return (
    <Grid
      container
      spacing={containerSpacing}
      id={isSupervisor ? 'approvers.supervisors' : 'manager'}
    >
      <Grid item {...fieldGridProps.firstName}>
        <ReactHookFormsInput
          name={getFieldName('firstName')}
          placeholder={showPlaceholders ? 'First Name' : undefined}
          label={!disableLabels ? 'First Name' : undefined}
          rules={{ required: 'First Name is required' }}
          useFieldArrayNaming={isSupervisor}
          fieldNamePrefix={fieldNamePrefix}
          disableErrorAbsolutePosition={disableLabels}
          dataTestId="externship-approver-firstName"
        />
      </Grid>
      <Grid item {...fieldGridProps.lastName}>
        <ReactHookFormsInput
          name={getFieldName('lastName')}
          placeholder={showPlaceholders ? 'Last Name' : undefined}
          label={!disableLabels ? 'Last Name' : undefined}
          rules={{ required: 'Last Name is required' }}
          useFieldArrayNaming={isSupervisor}
          fieldNamePrefix={fieldNamePrefix}
          disableErrorAbsolutePosition={disableLabels}
          dataTestId="externship-approver-lastName"
        />
      </Grid>
      <Grid item {...fieldGridProps.email}>
        <ReactHookFormsInput
          name={getFieldName('email')}
          placeholder={showPlaceholders ? 'Email' : undefined}
          label={!disableLabels ? 'Email' : undefined}
          disabled={!!isManagersVerificationPage}
          autocomplete="email"
          useFieldArrayNaming={isSupervisor}
          fieldNamePrefix={fieldNamePrefix}
          disableErrorAbsolutePosition={disableLabels}
          dataTestId="externship-approver-email"
          rules={{
            required: 'Email is required',
            pattern: {
              value: emailPattern,
              message: 'Please enter a valid email'
            }
          }}
        />
      </Grid>
      <Grid item {...fieldGridProps.phone}>
        <ReactHookFormsInputPhoneMask
          name={getFieldName('phone')}
          placeholder={showPlaceholders ? 'Phone Number' : undefined}
          label={!disableLabels ? 'Phone' : undefined}
          useFieldArrayNaming={isSupervisor}
          fieldNamePrefix={fieldNamePrefix}
          disableErrorAbsolutePosition={disableLabels}
          rules={{ required: 'Phone number is required' }}
          dataTestId="externship-approver-phone"
        />
      </Grid>
      <Grid item {...fieldGridProps.jobTitle}>
        <ReactHookFormsInput
          name={getFieldName('jobTitle')}
          placeholder={showPlaceholders ? 'Job Title' : undefined}
          label={!disableLabels ? 'Job Title' : undefined}
          rules={{ required: 'Job Title is required' }}
          useFieldArrayNaming={isSupervisor}
          fieldNamePrefix={fieldNamePrefix}
          disableErrorAbsolutePosition={disableLabels}
          dataTestId="externship-approver-jobTitle"
        />
      </Grid>
      {!disableOnlyPersonSigningField && !isSupervisor && (
        <Grid item {...fieldGridProps.signingTimeCard}>
          <ReactHookFormsSelect
            name="signingTimeCard"
            label={
              !disableLabels
                ? 'Is this the only person who will be signing your time card?'
                : undefined
            }
            options={selectOptions}
            rules={{
              required:
                'Please select whether this person will be signing your time card'
            }}
            dataTestId="externship-approver-timeCard"
          />
        </Grid>
      )}
      {isSupervisor && !disableRemoveButton && (
        <Grid item xs={12}>
          <Styled.Button
            variant="text"
            onClick={handleRemove}
            startIcon={<Styled.BtnIcon className="ri-delete-bin-6-line" />}
          >
            Remove supervisor
          </Styled.Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ExternshipApproverFieldArray;
