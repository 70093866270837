import * as React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import {
  QUIZ_RESULTS_STORAGE_KEY,
  redirectStorageKeys,
  useStateWithLocalStorage,
  useStorageRedirectWithExpiration
} from '@common/helpers/storage';
import CareerGoalCard from '@pages/Candidate/CareerPathPage/MyCareerGoal/CareerGoalCard';
import CareerPathExploreMoreCard from '@pages/Candidate/CareerPathPage/ExploreMore/CareerPathExploreMoreCard';
import CareerPathLayout from '@pages/Candidate/CareerPathPage/CareerPathLayout';
import CareerPathSkeleton from '@components/Skeletons/CareerPathSkeleton';
import { mediaQueries } from '@themes/ui/variables';
import MySkillsCard from '@pages/Candidate/CareerPathPage/MySkills/MySkillsCard';
import NextStepsSection from './NextStepsSection.tsx/NextStepsSection';
import { QuizResults } from '@api/models/quizApi.models';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { styled } from '@mui/system';
import { useFetchCandidateCareerInfo } from '@common/fetches/useFetchCandidateCareerInfo';
import { useFetchCandidateProfilePublic } from '@common/fetches/useFetchCandidateProfilePublic';
import { useFetchCareerJourney } from '@common/fetches/useFetchCareerJourney';
import { useFetchCareerRoles } from '@common/fetches/useFetchCareerRoles';
import { useLocation } from 'react-router-dom';
import { usePutCandidateCareerGoal } from '@common/putRequests/usePutCandidateCareerGoal';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';

const Styled = {
  Root: styled(Box)({
    marginTop: '24px'
  }),
  PaddingCardBox: styled(Box)({
    padding: '0 10px',
    [mediaQueries.MIN_WIDTH_361]: {
      padding: '0 16px'
    },
    [mediaQueries.MIN_WIDTH_481]: {
      padding: '0 32px'
    },
    [mediaQueries.MIN_WIDTH_601]: {
      padding: '0 65px'
    }
  }),
  NoPaddingCardBox: styled(Box)({
    padding: 0,
    marginTop: '36px',
    [mediaQueries.MIN_WIDTH_601]: {
      padding: '0 65px'
    }
  })
};

type StateData = {
  state: {
    selectedResult: QuizResults;
  };
};

// TODO: if the user hasn't finished their account, redirect data will need to be stored
// so that CandidateExperienceStep.tsx knows to reroute here.
const CareerPathPage = (): React.ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [candidateId] = useStore<number>(UseStoreKeys.CANDIDATE_ID);

  const { profileComplete, loaded: candidateProfileLoaded } =
    useFetchCandidateProfilePublic(candidateId);

  const {
    candidateCareerInfo,
    fetchCareerInfo,
    loaded: careerInfoLoaded
  } = useFetchCandidateCareerInfo(candidateId);

  const {
    careerTrack,
    careerPathRole,
    loading: careerJourneyLoading
  } = useFetchCareerJourney(candidateCareerInfo);

  const { careerRoles, loaded: careerRolesLoaded } =
    useFetchCareerRoles('careerRoles');

  const loaded =
    !!candidateProfileLoaded &&
    !!careerInfoLoaded &&
    !!careerRolesLoaded &&
    !careerJourneyLoading;

  const { setCareerGoal, removeCareerGoal } =
    usePutCandidateCareerGoal(candidateId);

  const { state } = useLocation() as StateData;
  const { parsedStoredValue } = useStateWithLocalStorage<QuizResults>(
    QUIZ_RESULTS_STORAGE_KEY
  );
  const storage = useStorageRedirectWithExpiration(
    redirectStorageKeys.CAREER_PATH
  );

  const exploreMoreRef = React.useRef<HTMLDivElement | null>(null);
  const [exploreMoreTabValue, setExploreMoreTabValue] =
    React.useState<string>('1');
  const [expandExploreMoreOnScroll, setExpandExploreMoreOnScroll] =
    React.useState(false);
  const [goalSelected, setGoalSelected] = React.useState(false);

  const [quizResult, setQuizResult] = React.useState<QuizResults | null>(
    parsedStoredValue
  );

  const handleRemoveCareerGoal = async (): Promise<void> => {
    await removeCareerGoal(candidateId).then(() => {
      fetchCareerInfo();
    });
  };

  const handleSetCareerGoal = async (careerRoleId: number): Promise<void> => {
    if (candidateCareerInfo?.savedGoal?.id === careerRoleId) {
      handleRemoveCareerGoal();
    } else {
      setGoalSelected(true);
      await setCareerGoal(careerRoleId)
        .then(() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          fetchCareerInfo();
        })
        .finally(() => {
          setTimeout(() => {
            setGoalSelected(false);
          }, 2000);
        });
    }
  };

  const handleScrollToExploreMore = React.useCallback(() => {
    const { current } = exploreMoreRef;
    if (current) {
      setExpandExploreMoreOnScroll(true);
      setTimeout(() => {
        current.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }
  }, [exploreMoreRef]);

  const handleExploreMoreTabChange = (
    event: React.SyntheticEvent,
    newVal: string
  ): void => {
    setExploreMoreTabValue(newVal);
  };

  // handle data passed via local storage (user was not logged in)
  React.useEffect(() => {
    if (parsedStoredValue && !quizResult) {
      const storageValue = parsedStoredValue;
      setQuizResult(storageValue);
    }
    if (storage) {
      localStorage.removeItem(redirectStorageKeys.CAREER_PATH);
    }
    if (quizResult) {
      const resultId = quizResult.id;
      handleSetCareerGoal(resultId);
      setQuizResult(null);
      localStorage.removeItem(QUIZ_RESULTS_STORAGE_KEY);
    }
  }, [parsedStoredValue, quizResult, storage]);

  // handle data passed via route state (user already logged in)
  React.useEffect(() => {
    if (state && state.selectedResult) {
      const { selectedResult } = state;
      setQuizResult(selectedResult);
    }
  }, [state]);

  const currentJobIds =
    candidateCareerInfo?.workExperience
      .filter((exp) => exp.currentPosition)
      .map((exp) => exp.position.value) || [];

  return (
    <>
      <CareerPathLayout>
        <Styled.Root>
          {!loaded ? (
            <CareerPathSkeleton />
          ) : (
            <>
              <Styled.PaddingCardBox>
                {candidateCareerInfo && !careerJourneyLoading && (
                  <CareerGoalCard
                    careerTrack={careerTrack}
                    currentJobIds={currentJobIds}
                    careerInfo={candidateCareerInfo}
                    careerPathRole={careerPathRole}
                    goalSelected={goalSelected}
                    setCareerGoal={handleSetCareerGoal}
                    removeCareerGoal={handleRemoveCareerGoal}
                    handleScrollToExploreMore={handleScrollToExploreMore}
                  />
                )}
              </Styled.PaddingCardBox>
              <Styled.NoPaddingCardBox>
                <MySkillsCard
                  profileComplete={profileComplete}
                  careerInfo={candidateCareerInfo}
                  requiredSkills={careerPathRole?.requiredSkills ?? []}
                  prerequisites={careerPathRole?.prerequisites ?? []}
                  certifications={careerPathRole?.certifications ?? []}
                />
              </Styled.NoPaddingCardBox>
              {careerRoles && (
                <Styled.NoPaddingCardBox
                  sx={{ marginTop: '36px' }}
                  ref={exploreMoreRef}
                >
                  <CareerPathExploreMoreCard
                    careerRoles={careerRoles}
                    setCareerGoal={handleSetCareerGoal}
                    currentJobIds={currentJobIds}
                    workExperience={candidateCareerInfo?.workExperience}
                    savedGoal={candidateCareerInfo?.savedGoal}
                    expandOnScroll={expandExploreMoreOnScroll}
                    setExpandOnScroll={setExpandExploreMoreOnScroll}
                    tabValue={exploreMoreTabValue}
                    setTabValue={handleExploreMoreTabChange}
                  />
                </Styled.NoPaddingCardBox>
              )}
            </>
          )}
        </Styled.Root>
      </CareerPathLayout>
      <RootPageLayout
        muiMaxWidth="lg"
        bgColor={'white'}
        pr={isMobile ? '0px' : undefined}
        pl={isMobile ? '0px' : undefined}
      >
        <NextStepsSection />
      </RootPageLayout>
    </>
  );
};

export default CareerPathPage;
