import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import {
  ExternalJobsSearchReq,
  getExternalJobPostBody,
  getSearchPostBody
} from '@components/Filters/FiltersWrapper';
import BottomPagination from '@components/BottomPagination';
import ExternalJobCard from '@components/JobMarketplace/ExternalJobCard';
import { ExternalJobsResponse } from '@api/models/singleJobPostApi.models';
import { IFilterState } from '@api/models/searchApi.models';
import { MainContent } from '@components/Skeletons/PageLayoutLeftSidebarSkeleton';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';

const DEFAULT_PAGE_NUMBER = 1;

interface Props {
  externalJobsResponse?: ExternalJobsResponse;
  filterExternalJobs: (filterData?: ExternalJobsSearchReq) => void;
  loading: boolean;
  filterFormState: IFilterState;
}

const ExternalJobsSection: React.FC<Props> = ({
  externalJobsResponse,
  filterExternalJobs,
  loading,
  filterFormState
}) => {
  const featureFlags = useFeatureFlags();

  const externalJobsPageInfo = externalJobsResponse?.pageInfo;
  const externalJobs = externalJobsResponse?.jobs || [];

  const getExternalFiltersAppliedText = (): string => {
    let text = '';
    const filtersApplied = externalJobsResponse?.pageInfo?.filtersApplied;

    if (filtersApplied?.location && filtersApplied?.location.displayName) {
      if (filtersApplied.location.distance) {
        text += `Within ${filtersApplied.location.distance} miles of ${filtersApplied?.location.displayName}`;
      } else {
        text += filtersApplied?.location.displayName;
      }
    }

    if (filtersApplied?.jobType) {
      if (text.length) {
        text += `; ${filtersApplied.jobType}`;
      } else {
        text += filtersApplied.jobType;
      }
    }

    if (text.length) {
      return `Filters Applied: ${text}`;
    }

    return '';
  };

  const getAvailableJobsText = (): string => {
    let text = '';

    if (externalJobsPageInfo) {
      const { startRow, endRow, count } = externalJobsPageInfo;

      text = `Showing ${startRow.toLocaleString()} - ${endRow.toLocaleString()} of ${count.toLocaleString()} Available Jobs`;
    }

    return text;
  };

  const handleExternalJobsPageSelection = (event, pageNumber: number): void => {
    const data = getSearchPostBody(filterFormState);
    const externalJobFilter = getExternalJobPostBody(filterFormState, data);
    filterExternalJobs({ ...externalJobFilter, page: pageNumber });
  };

  return (
    <>
      {featureFlags.EXTERNAL_JOB_POSTS && loading && MainContent}
      {featureFlags.EXTERNAL_JOB_POSTS && !loading && !!externalJobs.length ? (
        <Box>
          <Grid container spacing={2} mt={'32px'}>
            <Grid item xs={12}>
              <Typography variant="EC_TYPE_4XL" mb={0}>
                More Jobs...
              </Typography>
              <Typography variant="EC_TYPE_BASE" color={'GRAY_3.main'}>
                Opportunities found in this area may be filtered by LOCATION and
                JOB TYPE only.
              </Typography>
              <Typography
                variant="EC_TYPE_LG"
                color="grey3.main"
                component={'p'}
                sx={{ marginBottom: '16px' }}
              >
                {getExternalFiltersAppliedText()}
              </Typography>
              {!!externalJobsPageInfo && (
                <Typography variant="EC_TYPE_SM" color="grey3.main">
                  {getAvailableJobsText()}
                </Typography>
              )}
            </Grid>
            {externalJobs.map((job) => (
              <Grid item key={job.id} xl={3} lg={4} md={6} sm={6} xs={12}>
                <ExternalJobCard {...job} />
              </Grid>
            ))}
          </Grid>
          {!!externalJobsPageInfo && (
            <BottomPagination
              wrapperSx={{ marginTop: '32px' }}
              count={externalJobsPageInfo.totalPages}
              page={externalJobsPageInfo.currentPage}
              onChange={handleExternalJobsPageSelection}
              defaultPage={DEFAULT_PAGE_NUMBER}
            />
          )}
        </Box>
      ) : (
        <Box mt={'40px'} />
      )}
    </>
  );
};

export default ExternalJobsSection;
