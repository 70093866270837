import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { useWindowDimensions } from '@common/hooks/uiHelpers';
import WorkLearnIcon from '@components/WorkLearnIcon';

const MEDIA_QUERY = '@media (min-width:516px)';

const Styled = {
  Root: styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    [MEDIA_QUERY]: {
      flexWrap: 'nowrap'
    },
    ['@media (max-width:320px)']: {
      marginBottom: '25px'
    }
  }),
  Title: styled(Typography, {
    shouldForwardProp: (prop) =>
      prop !== 'isCandidatePage' && prop !== 'isEmployer'
  })<{ isCandidatePage?: boolean; isEmployer?: boolean }>(
    ({ isCandidatePage, isEmployer, theme }) => ({
      color: theme.palette.BLACK.main,
      marginRight: '10px',
      marginBottom: 0,
      marginTop: isCandidatePage && !isEmployer ? '20px' : '0',
      wordBreak: 'break-all',
      fontSize: '20px',
      [MEDIA_QUERY]: {
        wordBreak: 'normal',
        marginRight: '20px',
        fontSize: '32px'
      }
    })
  )
};

interface PreviewPageTitleProps {
  title: string;
  workLearnEligible?: string;
  isCandidatePage?: boolean;
}

const PreviewPageTitle: React.FC<PreviewPageTitleProps> = (
  props
): JSX.Element => {
  const { title, workLearnEligible, isCandidatePage } = props;
  const { width } = useWindowDimensions();

  const { isEmployer } = useKeycloakContext();

  const textVariant = width > 515 ? 'EC_TYPE_3XL' : 'EC_TYPE_2XL';

  return (
    <Styled.Root data-testid="jobTitleContainer">
      <Styled.Title
        isCandidatePage={isCandidatePage}
        isEmployer={isEmployer}
        data-testid="mainTitle"
        variant={textVariant}
      >
        {title}
      </Styled.Title>
      <WorkLearnIcon display={workLearnEligible ?? ''} />
    </Styled.Root>
  );
};

export default PreviewPageTitle;
