import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { isVideoType, MediaType } from '@api/models/mediaApi.models';
import AdditionalImagesCard from '@components/AdditionalImagesCard';
import EcCarousel from '@components/EcCarousel';
import { styled } from '@mui/system';
import { useResponsiveLayoutContext } from '@components/Layout/ResponsiveLayout';

const MAX_DISPLAYED_IMAGES = 6;

const Styled = {
  Root: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'customBreakpointReached'
  })<{ customBreakpointReached: boolean }>(({ customBreakpointReached }) => ({
    marginBottom: '50px',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(100px, 131px))',
    gap: customBreakpointReached ? '10px' : '20px',
    ['@media (max-width:480px)']: {
      gridTemplateColumns: 'repeat(3, 1fr)'
    }
  })),
  ImageDiv: styled(Box)({
    position: 'relative',
    boxSizing: 'border-box',
    borderRadius: '4px',
    overflow: 'hidden',
    cursor: 'pointer',
    '&:before': {
      content: '""',
      paddingTop: '100%',
      display: 'block'
    },
    '& .gallery-img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectPosition: '50% 50%',
      objectFit: 'cover'
    },
    '&:hover': {
      opacity: 0.5,
      transition: '0.5s'
    }
  }),
  Header: styled(Typography)(({ theme }) => ({
    marginBottom: '25px',
    [theme.breakpoints.down('md')]: {
      marginTop: '64px'
    }
  })),
  StashedCard: styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.GOLD_1.main}`,
    cursor: 'pointer',
    '& .more-photos': {
      color: theme.palette.GRAY_3.main,
      '& i': {
        marginBottom: '6px',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '18px'
      }
    }
  }))
};

interface Props {
  media: MediaType[];
  maxDisplayed?: number;
  isCandidateProfile?: boolean;
}

const PreviewPageImageGallery: React.FC<Props> = ({
  media,
  maxDisplayed = MAX_DISPLAYED_IMAGES,
  isCandidateProfile
}) => {
  const { customBreakpointReached } = useResponsiveLayoutContext();

  const displayedMedia = React.useRef<MediaType[] | null>(null);
  const stashedMedia = React.useRef<MediaType[] | null>(null);

  const [currentImgIdx, setCurrentImgIdx] = React.useState<number | null>(null);

  const handleOpenCarousel = (index: number): void => {
    setCurrentImgIdx(index);
  };

  const handleCloseCarousel = React.useCallback((): void => {
    setCurrentImgIdx(null);
  }, [setCurrentImgIdx]);

  React.useEffect(() => {
    if (media.length > maxDisplayed) {
      displayedMedia.current = media.slice(0, maxDisplayed - 1);
      stashedMedia.current = media.slice(maxDisplayed - 1, media.length);
    } else {
      displayedMedia.current = media.slice(0, maxDisplayed);
      stashedMedia.current = media.slice(maxDisplayed, media.length);
    }
  }, [media, displayedMedia.current, stashedMedia.current]);

  const getLabel = isCandidateProfile ? 'Portfolio' : 'Photos and Videos';

  return (
    <>
      {media.length > 0 && (
        <>
          <Styled.Header variant="EC_TYPE_LG">{getLabel}</Styled.Header>
          <Styled.Root
            data-testid="preview-page-image-gallery"
            customBreakpointReached={customBreakpointReached}
          >
            {displayedMedia.current &&
              displayedMedia.current.map((item, index) => {
                const src = isVideoType(item)
                  ? item.thumbnailUrl
                  : item.fullPath;
                const alt = isVideoType(item) ? item.title : item.originalname;
                return (
                  <Styled.ImageDiv
                    key={index}
                    onClick={(): void => handleOpenCarousel(index)}
                  >
                    <img src={src} alt={alt} className="gallery-img" />
                  </Styled.ImageDiv>
                );
              })}
            {stashedMedia.current && stashedMedia.current.length > 0 && (
              <AdditionalImagesCard
                count={stashedMedia.current.length}
                maxDisplayCount={maxDisplayed}
                handleOpen={handleOpenCarousel}
              />
            )}
          </Styled.Root>
        </>
      )}
      <EcCarousel
        media={media}
        selectedIdx={currentImgIdx}
        handleClose={handleCloseCarousel}
      />
    </>
  );
};

export default PreviewPageImageGallery;
