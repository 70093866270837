import { api } from './configs/base';
import { apiConfig } from './configs/api.config';

export class EmployerApi {
  static async markExternshipWelcomeModalAsViewed(
    employerId: number
  ): Promise<void> {
    try {
      await api.patch(
        `${apiConfig.employerUrl}/${employerId}/mark-externship-welcome-modal`
      );
    } catch (error: any) {
      console.error(
        `Error for EmployerApi.markExternshipWelcomeModalAsViewed(): ${error}`
      );
      throw error;
    }
  }
}
