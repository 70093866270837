import { useEffect, useState } from 'react';
import { CareerProfile } from '@interfaces/careerProfile.interfaces';
import { PartnerApi } from '@api/Partner.api';
import { useSetStoreValue } from 'react-context-hook';

interface FetchPartnerEmployeeProfile {
  partnerEmployeeProfile: CareerProfile | null;
  setStoreIdByKeycloakId: (userId: string) => Promise<void>;
  loading: boolean;
  loaded: true | null;
}

export const useFetchPartnerEmployeeProfile = (
  partnerEmployeeId?: number
): FetchPartnerEmployeeProfile => {
  const setId = useSetStoreValue('partnerEmployeeId');

  const [partnerEmployeeProfile, setPartnerEmployeeProfile] =
    useState<CareerProfile | null>(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  useEffect(() => {
    if (partnerEmployeeId) {
      const fetchProfile = async (): Promise<void> => {
        try {
          setLoading(true);

          const res = await PartnerApi.getPartnerEmployeeById(
            partnerEmployeeId
          );

          setPartnerEmployeeProfile(res.data);
        } catch (error: any) {
          console.error(
            'Error for useFetchPartnerEmployeeProfile -> PartnerApi.getPartnerEmployeeById()',
            error
          );
        } finally {
          setLoading(false);
          setLoaded(true);
        }
      };

      fetchProfile();
    } else {
      setLoaded(true);
    }
  }, [partnerEmployeeId]);

  const setStoreIdByKeycloakId = async (userId: string): Promise<void> => {
    try {
      const res = await PartnerApi.getPartnerEmployeeByKeycloakId(userId);

      setId(res?.data?.id);
    } catch (error: any) {
      console.error(
        'Error for useFetchPartnerEmployeeProfile -> PartnerApi.getPartnerEmployeeByKeycloakId()',
        error
      );
    }
  };

  return {
    partnerEmployeeProfile,
    setStoreIdByKeycloakId,
    loading,
    loaded
  };
};
