import * as React from 'react';
import { Box, Typography } from '@mui/material';
import Skill from '@components/Skill';
import { styled } from '@mui/system';
import { useResponsiveLayoutContext } from '@components/Layout/ResponsiveLayout';

const BORDER = '1px solid #A6B0BF';

const Styled = {
  Title: styled(Typography)(({ theme }) => ({
    fontWeight: 800,
    color: theme.palette.GRAY_4.main
  }))
};

interface JobSkillsetDataProps {
  skillset: string[];
  removeBorders?: boolean;
  candidatePage?: boolean;
}

const Skillset: React.FC<JobSkillsetDataProps> = (props): JSX.Element => {
  const { skillset, removeBorders = false, candidatePage = false } = props;
  const { customBreakpointReached } = useResponsiveLayoutContext();

  const paddingBottom = candidatePage || customBreakpointReached ? 0 : '13px';
  const paddingTop = candidatePage || customBreakpointReached ? 0 : '25px';
  const border = removeBorders || customBreakpointReached ? 'none' : BORDER;

  return (
    <>
      {candidatePage && (
        <Styled.Title variant="EC_TYPE_XS">SKILLS</Styled.Title>
      )}
      <Box
        data-testid="skillsetContainer"
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: '10px',
          paddingBottom,
          paddingTop,
          borderBottom: border,
          borderTop: border
        }}
      >
        {skillset?.map((item, index) => {
          return <Skill key={index} skill={item} />;
        })}
      </Box>
    </>
  );
};

export default Skillset;
