import { Bookmark, CreateBookmarkReq } from './models/bookmarkApi.models';
import { api } from '@api/configs/base';
import { apiConfig } from '@api/configs/api.config';
import { ApiResponse } from '@api/models/base.models';

export class BookmarkApi {
  static async getBookmarks(
    userId: string,
    companyId: number
  ): Promise<Bookmark[]> {
    try {
      const res = await api.get<ApiResponse<Bookmark[]>>(
        `${apiConfig.partnersUrl}/glrc-content/company/${companyId}/user/${userId}/bookmarks`
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for BookmarkApi.getBookmarks(): ${error}`);
      throw error;
    }
  }

  static async addBookmark(
    userId: string,
    data: CreateBookmarkReq
  ): Promise<any> {
    try {
      const res = await api.post<ApiResponse<Bookmark>>(
        `${apiConfig.partnersUrl}/glrc-content/user/${userId}/bookmarks`,
        data
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for BookmarkApi.addBookmark(): ${error}`);
      throw error;
    }
  }

  static async removeBookmark(
    userId: string,
    contentId: number
  ): Promise<void> {
    try {
      const res = await api.delete<ApiResponse<void>>(
        `${apiConfig.partnersUrl}/glrc-content/user/${userId}/bookmarks/${contentId}`
      );

      return res.data.data;
    } catch (error: any) {
      console.error(`Error for BookmarkApi.removeBookmark(): ${error}`);
      throw error;
    }
  }
}
