import * as React from 'react';
import { Backdrop, Box, Fade, IconButton, Modal } from '@mui/material';
import { Carousel, CarouselProps } from 'react-responsive-carousel';
import { isVideoType, MediaType } from '@api/models/mediaApi.models';
import { styled } from '@mui/system';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const VIDEO_WIDTH = '720';
const VIDEO_HEIGHT = '405';

const Styled = {
  Content: styled(Box)({
    position: 'absolute' as 'absolute',
    backgroundColor: 'rgba(0,0,0,0.75)',
    borderRadius: '4px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }),
  CloseBtn: styled(IconButton)({
    position: 'absolute',
    top: 0,
    right: 24,
    color: '#FFF'
  })
};

// react-responsive-carousel will not take MUI styled items as children
const imgStyle: React.CSSProperties = {
  flexGrow: 1,
  objectFit: 'contain',
  maxWidth: '100%',
  maxHeight: '420px'
};

const iframeStyle: React.CSSProperties = {
  display: 'block',
  width: '100%',
  flexGrow: 1,
  margin: 0,
  padding: 0,
  border: 'none'
};

const thumbStyle: React.CSSProperties = {
  display: 'block',
  maxHeight: '32px',
  width: '100%',
  objectFit: 'cover',
  objectPosition: 'center center'
};

type CarouselRenderProps = {
  alt: string;
  key: any;
  src: string;
  style: React.CSSProperties;
};

// Type coming from Carousel children
interface RenderItem {
  key: string;
  type: string;
  props: CarouselRenderProps;
}

interface EcCarouselProps extends Partial<CarouselProps> {
  media: MediaType[];
  selectedIdx: number | null;
  handleClose: () => void;
}

const EcCarousel = (props: EcCarouselProps): React.ReactElement => {
  const { media, selectedIdx, handleClose, ...carouselProps } = props;
  const {
    showStatus = false,
    showThumbs = true,
    showIndicators = false
  } = carouselProps;

  const isOpen = Boolean(selectedIdx !== null);

  const videoRef = document.getElementById('player') as HTMLIFrameElement;

  const pauseVideo = (): void => {
    if (!videoRef) {
      return;
    }

    const { contentWindow } = videoRef;
    if (contentWindow) {
      contentWindow.postMessage(
        '{"event":"command","func":"' + 'pauseVideo' + '","args":""}',
        '*'
      );
    }
  };

  const customRenderItem = (
    item: React.ReactNode,
    options?: { isSelected: boolean; isPrevious: boolean }
  ): React.ReactNode => {
    // must cast to different type from what Carousel defines
    const renderItem = item as React.ReactElement<RenderItem>;

    if (renderItem.type === 'iframe' && options?.isPrevious) {
      pauseVideo();
    }

    return <renderItem.type {...renderItem.props} options={options} />;
  };

  const customRenderThumbs = (
    children: React.ReactChild[]
  ): React.ReactChild[] => {
    // must cast to different type from what Carousel defines
    const _children = children as RenderItem[];

    const source = (item: RenderItem): string =>
      item.type === 'iframe' ? item.key : item.props.src;

    return _children.map((item) => (
      <img key={item.key} src={source(item)} style={thumbStyle} />
    ));
  };

  const carouselChild = (item: MediaType): JSX.Element => {
    if (isVideoType(item)) {
      const embedUrl = `${item.videoUrl}?autoplay=0&enablejsapi=1`;

      return (
        <iframe
          key={item.thumbnailUrl}
          data-testid="carousel-iframe"
          id="player"
          width={VIDEO_WIDTH}
          height={VIDEO_HEIGHT}
          src={embedUrl}
          title="Video player"
          frameBorder="0"
          allowFullScreen
          sandbox="allow-scripts allow-same-origin allow-presentation"
          allow="autoplay"
          style={iframeStyle}
        ></iframe>
      );
    } else {
      return (
        <img
          key={item.fullPath}
          src={item.fullPath}
          alt={item.originalname}
          style={imgStyle}
        />
      );
    }
  };

  const onClose = (): void => {
    pauseVideo();
    handleClose();
  };

  const handleChange = (): void => pauseVideo();

  const escAction = (event): void => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  React.useEffect(() => {
    document.addEventListener('keydown', escAction, false);

    return (): void => {
      document.removeEventListener('keydown', escAction, false);
    };
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <Styled.Content className="modal-content">
          <Carousel
            autoFocus={true}
            infiniteLoop
            emulateTouch
            useKeyboardArrows
            dynamicHeight={true}
            showStatus={showStatus}
            showIndicators={showIndicators}
            showThumbs={showThumbs}
            thumbWidth={36}
            selectedItem={selectedIdx ?? 0}
            renderThumbs={customRenderThumbs}
            renderItem={customRenderItem}
            onChange={handleChange}
          >
            {media.map((item) => carouselChild(item))}
          </Carousel>
          <Styled.CloseBtn
            onClick={onClose}
            id="close-modal"
            data-testid="ec-carousel-modal-close-btn"
          >
            <i className="ri-close-line" />
          </Styled.CloseBtn>
        </Styled.Content>
      </Fade>
    </Modal>
  );
};

export default EcCarousel;
