import * as React from 'react';
import crown from '@assets/svg/work_learn.crown_icon.svg';
import { styled } from '@mui/system';
import { Tooltip } from '@mui/material';

const WLIcon = styled('img')(() => ({
  width: '22px',
  height: '22px',
  ['@media (min-width:516px)']: {
    width: '25px',
    height: '25px'
  }
}));

interface WorkLearnIconProps {
  display: string; // workLearn fetch result ("Yes" or "No")
  showTooltip?: boolean;
  sx?: React.CSSProperties;
}

const WorkLearnIcon = (
  props: WorkLearnIconProps
): React.ReactElement | null => {
  const { display, sx, showTooltip = true } = props;

  if (display === 'Yes') {
    return (
      <>
        {showTooltip ? (
          <Tooltip arrow placement="right-start" title="Work & Learn Eligible">
            <WLIcon src={crown} alt="work & learn eligible" sx={{ ...sx }} />
          </Tooltip>
        ) : (
          <WLIcon src={crown} alt="work & learn eligible" sx={{ ...sx }} />
        )}
      </>
    );
  } else {
    return null;
  }
};

export default WorkLearnIcon;
