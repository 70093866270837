import * as React from 'react';
import { Grid } from '@mui/material';
import ReactHookFormsDatePicker from '@components/Forms/ReactHookFormsDatePicker';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';

interface Props {
  isSelfEmployed: boolean;
}

const AttestationEmploymentInfoSection: React.FC<Props> = ({
  isSelfEmployed
}) => {
  return (
    <>
      <Grid item xs={12}>
        <ReactHookFormsInput
          name="companyName"
          label={isSelfEmployed ? 'Business Name' : 'Employer'}
          rules={{
            required: `${
              isSelfEmployed ? 'Business name' : 'Employer'
            } is required.`
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ReactHookFormsDatePicker
          name="employmentStartDate"
          allowPast={true}
          label={
            isSelfEmployed ? 'Business Start Date' : 'Employment Start Date'
          }
          sublabel={
            isSelfEmployed
              ? undefined
              : '(The day that you first started working for this employer)'
          }
          rules={{ required: 'Start date is required.' }}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <ReactHookFormsInput
          name="jobTitle"
          label="Job Title"
          rules={{ required: 'Job title is required.' }}
        />
      </Grid>
    </>
  );
};

export default AttestationEmploymentInfoSection;
