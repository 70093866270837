import * as React from 'react';

export enum redirectStorageKeys {
  JOB_APPLICATION = 'candidate-job-application-token',
  CAREER_PATH = 'candidate-create-career-path-token',
  CREATE_JOB_POST = 'employer-create-job-post-token',
  CAREER_MARKETPLACE = 'career-marketplace-auth-token',
  CANDIDATE_PROFILE = 'candidiate-profile-preview-token',
  USER_TIMEOUT = 'user-token-expired'
}

export enum redirectStorageMessages {
  INCOMPLETE_PROFILE = 'incomplete-candidate-profile',
  INCOMPLETE_PROFILE_EMPLOYER = 'employer-create-job-post-incomplete-company-profile',
  NO_USER = 'no-authenticated-user'
}

export enum SessionStorageKeys {
  GLRC_PARTNER_SIGNIN = 'glrc-partner-signin',
  EXTERNSHIP_TRACKER_STATE = 'externship-tracker-state'
}

// Used to pass data from QuizResultsPage to CareerPath only
export const QUIZ_RESULTS_STORAGE_KEY = 'quiz-results-storage-data';

interface ISessionStorage<T> {
  sessionValue: string;
  parsedSessionValue: T | null;
  setSessionValue: React.Dispatch<React.SetStateAction<string>>;
  removeSessionValue: () => void;
}

/**
 * Use to set and get sessonStorage values.
 * @param sessionKey a string key to identify the sessionStorage value.
 * @returns sessionStorage value, a parsed value, and sessionStorage setter function.
 */
export const useStateWithSessionStorage = <T,>(
  sessionKey: string
): ISessionStorage<T> => {
  const [sessionValue, setSessionValue] = React.useState(
    sessionStorage.getItem(sessionKey) || ''
  );

  React.useEffect(() => {
    sessionStorage.setItem(sessionKey, sessionValue);
  }, [sessionValue]);

  const parsedSessionValue: T | null = sessionValue
    ? JSON.parse(sessionValue)
    : null;

  const removeSessionValue = (): void => {
    setSessionValue('');
    sessionStorage.removeItem(sessionKey);
  };

  return {
    sessionValue,
    parsedSessionValue,
    setSessionValue,
    removeSessionValue
  };
};

interface ILocalStorage<T> {
  storedValue: string;
  setStoredValue: React.Dispatch<React.SetStateAction<string>>;
  parsedStoredValue: T | null;
}

/**
 * Use to set and get localStorage values.
 * @param storageKey a string key to identify the localStorage value.
 * @returns localStorage value, and localStorage setter function.
 */
export const useStateWithLocalStorage = <T,>(
  storageKey: string
): ILocalStorage<T> => {
  const [storedValue, setStoredValue] = React.useState(
    localStorage.getItem(storageKey) ?? ''
  );

  React.useEffect(() => {
    localStorage.setItem(storageKey, storedValue);
  }, [storedValue]);

  const parsedStoredValue: T | null = storedValue
    ? JSON.parse(storedValue)
    : null;

  return { storedValue, setStoredValue, parsedStoredValue };
};

type StorageRedirectData = {
  redirect: string;
  message: string;
  storageKey: string;
} | null;

/**
 * Use to get localStorage with an expiration time. Will also remove the
 * value from localStorage if it is expired.
 * @param storageKey a string key to identify the localStorage value.
 * @returns a redirect and a message from the localStorage item.
 */
export const useStorageRedirectWithExpiration = (
  storageKey: string
): StorageRedirectData => {
  const storageString = localStorage.getItem(storageKey);

  if (!storageString) {
    return null;
  }

  const storage = JSON.parse(storageString);
  const now = new Date();

  if (now.getTime() > storage.expiration) {
    localStorage.removeItem(storageKey);
    return null;
  } else {
    return {
      redirect: storage?.redirect,
      message: storage?.message,
      storageKey
    };
  }
};

/**
 * Get storage for all of the redirectStorageKeys, if it exists.
 */
export const redirectStores: StorageRedirectData[] = Object.values(
  redirectStorageKeys
).map((val) => {
  return useStorageRedirectWithExpiration(val);
});

/**
 * removes all storage associated with redirectStorageKeys
 */
export const clearRedirectStores = (): void => {
  redirectStores.forEach((store) => {
    if (store) {
      localStorage.removeItem(store.storageKey);
    }
  });
};
