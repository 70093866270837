import * as React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import ContactFormSection from '@pages/Public/WorkAndLearnPage/ContactFormSection';
import ECrownLogoWithTitle from '@components/Layout/Common/ECrownLogoWithTitle';
import EscoffierLogo from '@assets/escoffier_edu_logo.png';
import HighlightedWorkAndLearnPartnerLogoGrid from './HighlightedWorkAndLearnPartnerLogoGrid';
import HowItWorksSection from './HowItWorksSection';
import Image from '@assets/WorkAndLearnCandidatePage/escoffier_edu_card_image.png';
import PromoBox from '@components/PromoBox';
import RootPageLayout from '@components/Layout/RootPageLayout';
import WorkAndLearnMorePartnersSection from './WorkAndLearnMorePartnersSection';
import WorkLearnExplorePrograms from '@pages/Public/WorkAndLearnPage/WorkAndLearnCandidatePage/WorkLearnExplorePrograms/WorkLearnExplorePrograms';

const WorkAndLearnCandidatePage2: React.FC = () => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));

  const contactRef = React.useRef<HTMLElement | null>(null);

  const scrollToContact = (): void => {
    if (contactRef.current) {
      const { current } = contactRef;
      current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <RootPageLayout muiMaxWidth={false} bgImgVariant={4}>
        <Box mb={'48px'}>
          <ECrownLogoWithTitle
            text="Work & Learn"
            subText="Earn a paycheck while you earn your culinary credentials."
          />
        </Box>
        <BottomMarginContentWrapper bottomMargin="148px">
          <Box sx={{ padding: `0px ${isLarge ? '0px' : '48px'}` }}>
            <BottomMarginContentWrapper bottomMargin="96px">
              <Box sx={{ padding: `24px ${isLarge ? '0px' : '48px'}` }}>
                <BottomMarginContentWrapper bottomMargin="96px">
                  <BottomMarginContentWrapper bottomMargin="24px">
                    <Typography variant="EC_TYPE_BASE">
                      You don&apos;t have to choose between making money and
                      going to school. Auguste Escoffier Global Solutions
                      (Escoffier Global) works together with Auguste Escoffier
                      School of Culinary Arts (Escoffier) so you can do both.
                    </Typography>
                    <Typography variant="EC_TYPE_BASE">
                      Our employer partners are committed to helping their
                      workers grow. Work & Learn combines any tuition benefits
                      your employer offers with a scholarship from Escoffier and
                      financial aid for those who apply and qualify. With Work &
                      Learn, you can gain valuable on-the-job experience from
                      your employer while going to school.
                    </Typography>
                  </BottomMarginContentWrapper>
                  <HighlightedWorkAndLearnPartnerLogoGrid />
                </BottomMarginContentWrapper>
              </Box>
              <Box sx={{ padding: '0px 4px' }}>
                <WorkAndLearnMorePartnersSection />
              </Box>
            </BottomMarginContentWrapper>
          </Box>
          <HowItWorksSection scrollToContact={scrollToContact} />
          <WorkLearnExplorePrograms />
          <Box sx={{ padding: isLarge ? '0px 0px 88px' : '0px 88px 88px' }}>
            <PromoBox
              text="Pursue your culinary dreams at Auguste Escoffier School of Culinary Arts. Learn more about available programs and request more information today."
              img={Image}
              contentImg={EscoffierLogo}
              imgAlt="escoffier students"
              cta={{
                text: 'Learn More',
                path: 'https://www.escoffier.edu/'
              }}
            />
          </Box>
          <Box ref={contactRef}>
            <ContactFormSection sectionTitle="Contact us to learn more about Escoffier's Work & Learn program" />
          </Box>
        </BottomMarginContentWrapper>
      </RootPageLayout>
    </>
  );
};

export default WorkAndLearnCandidatePage2;
