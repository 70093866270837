import { Redirect, RouteProps } from 'react-router-dom';
import EcRoute from '@common/utilities/EcRoute';
import NotFoundPage from '@pages/Errors/NotFoundPage';
import { Permission } from 'src/permissions/permission.enum';
import { PermissionsConfig } from 'src/permissions/permissions.config';
import React from 'react';
import { KeycloakUserRole } from '@api/models/userApi.models';
import { useKeycloak } from '@react-keycloak/web';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';

interface IProtectedRouteProps extends RouteProps {
  component: any;
  allowedRoles?: string[];
  requiredPermissions?: Permission[];
  title: string;
  on?: boolean;
  allowAllAuthenticated?: boolean;
}

const ProtectedRoute: React.FC<IProtectedRouteProps> = ({
  component: Component,
  allowedRoles,
  requiredPermissions,
  title,
  on,
  allowAllAuthenticated,
  ...rest
}) => {
  const { keycloak } = useKeycloak();
  const [permissions] = useStore<PermissionsConfig>(UseStoreKeys.PERMISSIONS);
  const isAuthenticated = keycloak.authenticated;

  if (on !== undefined && !on) {
    return <NotFoundPage />;
  }

  const isAuthorized = (
    allowedRoles?: string[],
    requiredPermissions?: Permission[]
  ): boolean => {
    if (!keycloak) {
      return false;
    }

    if (requiredPermissions) {
      return requiredPermissions.every(
        (permission) => !!permissions[permission]
      );
    }

    if (allowedRoles) {
      // If route is guarder, allow access if user has ADMIN role
      allowedRoles = [...allowedRoles, KeycloakUserRole.ADMIN];

      return allowedRoles.some((role: string) => {
        const realm = keycloak.hasResourceRole(role);

        return realm;
      });
    }

    if (allowAllAuthenticated) {
      return true;
    }

    return false;
  };

  if (!isAuthenticated) {
    keycloak.login();
    return <></>;
  }

  if (isAuthorized(allowedRoles, requiredPermissions)) {
    return <EcRoute {...rest} title={title} component={Component} />;
  } else {
    return <Redirect to={{ pathname: '/not-found' }} />;
  }
};

export default ProtectedRoute;
