import * as React from 'react';
import { Box, Typography } from '@mui/material';
import {
  candidateContentArray,
  CandidateContentProps,
  candidatePersonalInfoArray,
  CandidatePersonalInfoProps
} from './candidatePreviewPageComponentProps';
import {
  redirectStorageKeys,
  redirectStorageMessages
} from '@common/helpers/storage';
import { useHistory, useParams } from 'react-router-dom';
import CandidatePreviewPageSkeleton from '@components/Skeletons/CandidatePreviewPageSkeleton';
import { CandidateProfile } from '@api/models/candidateApi.models';
import CandidateProfileContent from '@pages/Candidate/CandidateProfileComponents/CandidateProfileContent';
import CandidateProfilePersonalInfo from '@pages/Candidate/CandidateProfileComponents/CandidateProfilePersonalInfo';
import CandidateProfileTopBar from '@pages/Candidate/CandidateProfileComponents/CandidateProfileTopBar';
import { Codebook } from '@api/models/codebook.models';
import { DateTime } from 'luxon';
import IncompleteProfileModal from '@components/PreviewPageComponents/IncompleteProfileModal';
import { InfoItemType } from '@components/PreviewPageComponents/PreviewPageInfo';
import { JobApplication } from '@api/models/jobApplicationApi.models';
import LayoutRightSidebar from '@components/Layout/LayoutRightSidebar';
import { pickProps } from '@common/helpers/pickProps';
import ResumePreviewModal from '@pages/Candidate/ResumePreviewModal/ResumePreviewModal';
import { KeycloakUserRole } from '@api/models/userApi.models';
import Skillset from '@components/Skillset';
import { styled } from '@mui/system';
import TabsPanels from '@components/TabsPanels';
import { Tag } from '@api/models/common.models';
import { useFetchCandidateProfile } from '@common/fetches/useFetchCandidateProfile';
import { useFetchCandidateProfilePublic } from '@common/fetches/useFetchCandidateProfilePublic';
import { useFetchJobApplicationsForCompanyId } from '@common/fetches/useFetchJobApplicationsForCompanyId';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { useParamsOrStoreCompanyId } from '@common/hooks/useParamsOrStoreCompanyId';
import { useTagManager } from '@common/hooks/useTagManager';

const Styled = {
  HorizontalRule: styled('hr')({
    margin: '30px 0px'
  }),
  ApplicationWrapper: styled(Box)(({ theme }) => ({
    '&>*:nth-of-type(odd)': {
      backgroundColor: theme.palette.GRAY_1.main
    }
  })),
  AppListItemWrapper: styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px'
  }),
  AppSectionTitle: styled(Typography)({
    marginBottom: '16px'
  }),
  JobAppDescription: styled(Typography)(({ theme }) => ({
    color: theme.palette.GRAY_4.main
  })),
  JobAppStatus: styled(Typography)(({ theme }) => ({
    fontWeight: '400',
    color: theme.palette.GRAY_4.main,
    minWidth: '110px',
    textAlign: 'right'
  }))
};

interface RouteParams {
  id: string;
}

type TabItem = {
  title: string;
  tabLabel: string;
  tabValue: string;
  text?: Tag[] | Codebook[];
};

interface Props {
  isEmployerViewing?: boolean;
  candidateIdFromProps?: number;
}

const CandidateProfilePage: React.FC<Props> = ({
  isEmployerViewing,
  candidateIdFromProps
}) => {
  const params = useParams<RouteParams>();
  const { isCandidate } = useKeycloakContext();

  const [openResumeModal, setOpenResumeModal] = React.useState(false);
  const { companyId } = useParamsOrStoreCompanyId();
  const { sendToGtm } = useTagManager();

  const candidateId = params.id
    ? Number(params.id)
    : (candidateIdFromProps as number);

  const { candidateProfile, profileComplete, loading, loaded } = isCandidate
    ? useFetchCandidateProfile(candidateId)
    : useFetchCandidateProfilePublic(candidateId);

  const { jobApplications, fetchData: fetchJobApps } =
    useFetchJobApplicationsForCompanyId(candidateId, companyId);
  const history = useHistory();

  const closeIncompleteModal = (): void => {
    history.goBack();
  };

  const handleOpenResumeModal = React.useCallback(() => {
    setOpenResumeModal(true);
    sendToGtm('resume_builder', {
      action: 'open',
      role: KeycloakUserRole.CANDIDATE
    });
  }, [setOpenResumeModal]);

  const handleCloseResumeModal = React.useCallback(() => {
    setOpenResumeModal(false);
  }, [setOpenResumeModal]);

  React.useEffect(() => {
    if (isEmployerViewing) {
      fetchJobApps();
    }
  }, [isEmployerViewing]);

  const candidateDetails: InfoItemType[] = React.useMemo(() => {
    return [
      {
        value: candidateProfile?.locations
          .map((loc) => loc.description)
          .join(' / '),
        icon: 'ri-map-pin-2-fill'
      },
      {
        value:
          candidateProfile?.relocation === 'YES' ? 'Open to Relocation' : null,
        icon: 'ri-map-pin-user-fill'
      },
      // TODO: A decision needs to be made on what data to display here
      // {
      //   value:
      //     candidateProfile?.currentJobTitle ??
      //     candidateProfile?.workExperience[0]?.position.label,
      //   svg: chefHatIcon
      // },
      // {
      //   value:
      //     candidateProfile?.yearsOfExperience?.label ??
      //     candidateProfile?.workExperience[0]?.yearsOfExperience.label,
      //   icon: 'ri-star-half-s-line'
      // },
      {
        value: `Seeking: ${candidateProfile?.employmentType
          .map((type) => type.type)
          .join(', ')}`,
        icon: 'ri-briefcase-fill'
      },
      {
        value:
          candidateProfile?.workAndLearn !== 'NO'
            ? 'Open to Escoffier Culinary Program'
            : null,
        icon: 'ri-star-fill'
      }
    ];
  }, [candidateProfile]);

  const componentProps = React.useMemo(() => {
    return {
      personalInfo: pickProps(
        candidatePersonalInfoArray,
        candidateProfile
      ) as CandidatePersonalInfoProps,
      content: pickProps(
        candidateContentArray,
        candidateProfile
      ) as CandidateContentProps
    };
  }, [candidateProfile]);

  const skillSet: string[] = React.useMemo(() => {
    const tech =
      candidateProfile?.technicalSkills.map((skill) => skill.label) ?? [];
    const soft = candidateProfile?.softSkills.map((skill) => skill.label) ?? [];
    return [...tech, ...soft];
  }, [candidateProfile]);

  const tabContent: TabItem[] = [
    {
      title: 'Benefits',
      tabLabel: 'Benefits',
      tabValue: 'one',
      text: candidateProfile?.benefits
    },
    {
      title: 'Culture',
      tabLabel: 'Culture',
      tabValue: 'two',
      text: candidateProfile?.attributes
    },
    {
      title: 'Looking for work in',
      tabLabel: 'Looking for work in',
      tabValue: 'three',
      text: candidateProfile?.segments
    }
  ];

  const jobAppDescription = (jobApplication: JobApplication): string => {
    return `${jobApplication.jobPost.id} ${jobApplication.jobPost.jobTitle}`.toUpperCase();
  };

  const jobAppStatusText = (jobApplication: JobApplication): string => {
    return `${jobApplication.status.label} (${DateTime.fromISO(
      jobApplication.submittedDate.toString()
    ).toFormat('MM/dd/yyyy')})`;
  };

  const incompleteModalProps = {
    title: 'Finish Your Profile',
    subtitle:
      'Fill in the required fields in the profile to get access to your resume',
    isCandidateProfileComplete: profileComplete,
    localStorageMessage: redirectStorageMessages.INCOMPLETE_PROFILE,
    localStorageKey: redirectStorageKeys.CANDIDATE_PROFILE,
    primaryButtonText: 'Candidate Profile',
    secondaryButtonText: 'Cancel',
    primaryClickLocation: '/candidate/profile-setup',
    secondaryClick: closeIncompleteModal
  };

  const ApplicationSection: React.FC = () => {
    return (
      <Box>
        <Styled.HorizontalRule />
        <Styled.AppSectionTitle variant="EC_TYPE_SM">
          Application History
        </Styled.AppSectionTitle>
        {jobApplications?.length ? (
          <Styled.ApplicationWrapper>
            {jobApplications.map((jobApp) => {
              return (
                <Styled.AppListItemWrapper key={jobApp.id}>
                  <Styled.JobAppDescription variant="EC_TYPE_XS">
                    {jobAppDescription(jobApp)}
                  </Styled.JobAppDescription>
                  <Styled.JobAppStatus variant="EC_TYPE_XS">
                    {jobAppStatusText(jobApp)}
                  </Styled.JobAppStatus>
                </Styled.AppListItemWrapper>
              );
            })}
          </Styled.ApplicationWrapper>
        ) : (
          <Styled.JobAppDescription variant="EC_TYPE_XS">
            No Applications
          </Styled.JobAppDescription>
        )}
      </Box>
    );
  };

  if (loading || !profileComplete) {
    return (
      <>
        <CandidatePreviewPageSkeleton />
        <IncompleteProfileModal
          open={!!loaded && !profileComplete}
          handleClose={closeIncompleteModal}
          {...incompleteModalProps}
        />
      </>
    );
  }

  return (
    <>
      {isCandidate && params.id && (
        <CandidateProfileTopBar openResumeModal={handleOpenResumeModal} />
      )}
      <LayoutRightSidebar
        primaryContent={
          <>
            <CandidateProfilePersonalInfo
              {...componentProps.personalInfo}
              candidateDetails={candidateDetails}
              candidateId={candidateId}
              isEmployerViewing={isEmployerViewing}
              currentPosition={
                candidateProfile?.workExperience[0]?.position.label
              }
            />
            <Skillset skillset={skillSet} removeBorders candidatePage />
            <CandidateProfileContent {...componentProps.content} />
            {isEmployerViewing && <ApplicationSection />}
            {openResumeModal && candidateProfile && isCandidate && (
              <ResumePreviewModal
                open={openResumeModal}
                candidateProfile={candidateProfile as CandidateProfile}
                onClose={handleCloseResumeModal}
              />
            )}
          </>
        }
        secondaryContent={
          <>
            <TabsPanels tabContent={tabContent} />
          </>
        }
      />
    </>
  );
};

export default CandidateProfilePage;
