/* eslint-disable @typescript-eslint/indent */
import { AdminDashboardApi } from '@api/AdminDashboard.api';
import { AdminDashJobPostCsv } from '@api/models/adminDashboardApi.models';

export type FetchJobPostsCsv = {
  fetchJobPostsCsvByCompany: () => Promise<AdminDashJobPostCsv[]>;
};

/**
 * Fetch AdminDashJobPostCsv[] from AdminDashboardApi.getJobPostsCsvByCompanyId()
 *
 * @param {number} id companyId
 * @returns `{ fetchJobPostsCsvByCompany:  () => Promise<AdminDashJobPostCsv[]>}`
 */
export const useFetchJobPostByCompanyIdCsv = (
  companyId: number
): FetchJobPostsCsv => {
  const fetchJobPostsCsvByCompany = async (): Promise<
    AdminDashJobPostCsv[]
  > => {
    let data: AdminDashJobPostCsv[] = [];

    try {
      const res = await AdminDashboardApi.getJobPostsCsvByCompanyId(companyId);
      data = res.data;
    } catch (error: any) {
      console.error(
        'Error for useFetchJobPostByCompanyIdCsv -> AdminDashboardApi.getJobPostsCsvByCompanyId()',
        error
      );
    }

    return data;
  };

  return { fetchJobPostsCsvByCompany };
};
