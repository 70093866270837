import * as React from 'react';
import { IconButton, Snackbar, SnackbarProps } from '@mui/material';
import { createCustomContext } from '@common/context/customContext';
import { styled } from '@mui/system';

export interface ToastProps {
  open: boolean;
  message: string;
  closeToast: () => void;
  openToast: (msg: string) => void;
}

const StyledSnackbar = styled((props: SnackbarProps) => (
  <Snackbar {...props} />
))({
  '& .MuiSnackbar-root': {
    // This is here in case we need custom styling
  }
});

export const [useToast, ToastProviderWrapper] =
  createCustomContext<ToastProps>();

// TODO: Provide severity cases.
// TODO: Adjust position when decided.
export const useToastProps = (): Required<ToastProps> => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');

  React.useEffect(() => {
    if (open) {
      setTimeout(() => {
        setMessage('');
        setOpen(false);
      }, 4000);
    }
  }, [open]);

  const openToast = (msg = 'Something went wrong...'): void => {
    setMessage(msg);
    setOpen(true);
  };

  const closeToast = (): void => {
    if (open) {
      setMessage('');
      setOpen(false);
    }
  };

  return { open, message, openToast, closeToast };
};

export const Toast: React.FC<Partial<ToastProps>> = ({
  open,
  message,
  closeToast
}) => {
  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeToast}
      >
        <i className="ri-close-fill" />
      </IconButton>
    </>
  );

  return (
    <StyledSnackbar
      data-testid="toast-message"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={open}
      onClose={closeToast}
      message={<span>{message}</span>}
      action={action}
    />
  );
};

export const ToastProvider: React.FC = ({ children }) => {
  const { ...toastProps } = useToastProps();

  return (
    <ToastProviderWrapper value={{ ...toastProps }}>
      {children}
      <Toast {...toastProps} />
    </ToastProviderWrapper>
  );
};
