import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const StashedCard = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  backgroundColor: '#FFFFFF',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  borderRadius: '4px',
  border: `1px solid ${theme.palette.GOLD_1.main}`,
  cursor: 'pointer',
  '& .more-photos': {
    color: theme.palette.GRAY_3.main,
    '& i': {
      marginBottom: '6px',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '18px'
    }
  }
}));

interface AdditionalImgProps {
  count: number;
  maxDisplayCount: number;
  handleOpen: (index: number) => void;
}

const AdditionalImagesCard = ({
  count,
  maxDisplayCount,
  handleOpen
}: AdditionalImgProps): JSX.Element => {
  const handleClick = React.useCallback(() => {
    // Select first stashed image
    handleOpen(maxDisplayCount - 1);
  }, [handleOpen]);

  return (
    <StashedCard onClick={handleClick}>
      <Typography variant="EC_TYPE_2XS" className="more-photos">
        <i className="ri-image-line more-photos" />
        <br />
        View&nbsp;{count}&nbsp;More
        <br />
        Photos
      </Typography>
    </StashedCard>
  );
};

export default AdditionalImagesCard;
