import * as React from 'react';
import { Button, ThemeProvider, Typography } from '@mui/material';
import {
  ExternshipEntity,
  ExternshipWithPhaseAndStatus
} from '@api/models/externshipApi.models';
import BasicModal from '@components/BasicModal';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import ExternshipAgreement from '@components/ExternshipAgreements/ExternshipAgreement';

import theme from '@themes/ui/escoffier';

interface Props {
  externshipId: number;
  label: string;
  handleUpdateExternshipStatus: (
    externship: ExternshipEntity | ExternshipWithPhaseAndStatus
  ) => void;
}

const ExternshipAgreementButton: React.FC<Props> = ({
  externshipId,
  handleUpdateExternshipStatus,
  label
}) => {
  const [openExternshipAgreement, setOpenExternshipAgreement] =
    React.useState(false);

  const [isRevising, setIsRevising] = React.useState(false);
  const [revisionConfirmationModalOpen, setRevisionConfirmationModalOpen] =
    React.useState(false);

  const handleCloseExternshipAgreement = (): void => {
    if (isRevising) {
      setIsRevising(false);
    }
    setOpenExternshipAgreement(false);
  };

  const handleOpenRevisionConfirmationModal = (): void => {
    setOpenExternshipAgreement(false);
    setRevisionConfirmationModalOpen(true);
  };

  const handleCloseRevisioConfirmationnModal = (): void =>
    setRevisionConfirmationModalOpen(false);

  const handleConfirmRevision = (): void => {
    handleCloseRevisioConfirmationnModal();
    setIsRevising(true);
    setOpenExternshipAgreement(true);
  };

  const handleClick = (): void => {
    setOpenExternshipAgreement(true);
  };

  const RevisionConfirmationModal: React.FC = () => {
    return (
      <BasicModal
        open={revisionConfirmationModalOpen}
        handleClose={handleCloseRevisioConfirmationnModal}
        primaryAction={{
          label: 'Yes, Revise',
          action: handleConfirmRevision
        }}
        secondaryAction={{
          label: 'Cancel',
          action: handleCloseRevisioConfirmationnModal
        }}
        maxWidth="sm"
      >
        <BottomMarginContentWrapper bottomMargin="32px">
          <Typography variant="EC_TYPE_LG">
            Are you sure you want to revise?
          </Typography>
          <Typography variant="EC_TYPE_BASE">
            If you revise the signed agreement, the extern and manager will have
            to sign the agreement again.
          </Typography>
        </BottomMarginContentWrapper>
      </BasicModal>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Button sx={{ justifyContent: 'left' }} onClick={handleClick}>
        {label}
      </Button>
      {revisionConfirmationModalOpen && <RevisionConfirmationModal />}

      {openExternshipAgreement && (
        <ExternshipAgreement
          externshipId={externshipId}
          open={openExternshipAgreement}
          agreementTitle="Externship Agreement"
          handleClose={handleCloseExternshipAgreement}
          updateExternship={handleUpdateExternshipStatus}
          careerServicesSigning={true}
          isRevising={isRevising}
          handleOpenRevisionModal={handleOpenRevisionConfirmationModal}
        />
      )}
    </ThemeProvider>
  );
};

export default ExternshipAgreementButton;
