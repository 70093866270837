import * as React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  Link,
  Theme,
  Typography,
  useTheme
} from '@mui/material';
import { isVideoType, MediaType } from '@api/models/mediaApi.models';
import { convertFileSize } from '@components/ImageUpload/ImageUploadWell';
import { getFilePath } from '@common/helpers/getFilePath';
import { getVideoDuration } from '@common/helpers/getVideoDuration';
import ImageUploadPlaceholderIcon from '@assets/svg/imageUploadPlaceholderIcon.svg';
import { styled } from '@mui/system';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    display: 'block',
    marginTop: '26px',
    minHeight: '85px',
    backgroundColor: theme.palette.WHITE.main,
    border: `1px solid ${theme.palette.GRAY_3.main}`,
    borderRadius: '4px',
    overflow: 'hidden'
  })),
  MediaBox: styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '0 16px',
    width: '100%',
    height: '85px',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .thumbnail-img': {
      flexShrink: 0,
      justifySelf: 'flex-start',
      width: '50px',
      height: '50px',
      objectFit: 'cover',
      objectPosition: '50% 50%',
      borderRadius: '4px',
      overflow: 'hidden'
    },
    '& .delete-btn': {
      color: theme.palette.primary.main
    }
  })),
  CenterContent: styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    margin: '0 12px',
    overflow: 'hidden',
    '& .title': {
      color: theme.palette.GRAY_3.main,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& .link-button': {
      padding: 0,
      typography: (theme as Theme).typography.EC_TYPE_SM
    },
    ['@media (max-width:430px)']: {
      flexDirection: 'column'
    }
  })),
  Grid: styled(Grid)({
    height: '85px',
    padding: '0 32px',
    alignItems: 'center'
  }),
  LoadingBox: styled(Box)({
    position: 'relative',
    display: 'inline-flex'
  }),
  IconBox: styled(Box)({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }),
  LoadingIcon: styled(Icon)(({ theme }) => ({
    fontSize: '11.25px',
    lineHeight: '11.25px',
    color: theme.palette.GOLD_1.main
  })),
  Title: styled(Typography)({
    marginTop: '60px'
  }),
  PlaceholderBox: styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px 0 40px 0',
    '& .placeholder-img': {
      marginBottom: '15px'
    },
    '& .placeholder-text': {
      color: theme.palette.GRAY_3.main
    }
  }))
};

interface FileInfoProps {
  media: MediaType;
  remove: (file: any) => void;
  clickable: boolean;
  uploading?: boolean;
}

interface Props {
  dropCount: number;
  mediaArray: MediaType[];
  uploading: boolean;
  clickableFiles: boolean;
  remove: (file: any) => void;
  multiple?: boolean;
  customSectionTitle?: string;
}

export const UploadingFile = (): JSX.Element => {
  return (
    <Styled.Grid container>
      <Grid item sm={1} xs={1}>
        <Styled.LoadingBox>
          <CircularProgress size="36px" sx={{ color: '#997755' }} />
          <Styled.IconBox>
            <Styled.LoadingIcon className="ri-image-fill" />
          </Styled.IconBox>
        </Styled.LoadingBox>
      </Grid>
      <Grid item sm={7} xs={7}></Grid>
      <Grid item sm={2} xs={2}></Grid>
      <Grid item sm={2} xs={2}></Grid>
    </Styled.Grid>
  );
};

const FileInfo = ({
  media,
  clickable,
  remove
}: FileInfoProps): React.ReactElement => {
  const theme = useTheme();

  const mediaData = React.useMemo(() => {
    if (isVideoType(media)) {
      return {
        title: media.title,
        detail: media?.duration ? getVideoDuration(media.duration) : 'N/A',
        src: media.thumbnailUrl,
        alt: `${media.title} thumbnail`
      };
    } else {
      return {
        title: media.originalname,
        detail: convertFileSize(media.size),
        src: media.fullPath,
        alt: `${media.originalname} thumbnail`
      };
    }
  }, [media]);

  const { title, detail, src, alt } = mediaData;

  const filePath = 'fullName' in media ? getFilePath(media.fullName) : '';

  return (
    <Styled.MediaBox>
      {!isVideoType(media) && media.mimetype === 'application/pdf' ? (
        <i
          className="ri-file-text-line"
          style={{ ...(clickable && { color: theme.palette.primary.main }) }}
        />
      ) : (
        <Box component="img" src={src} alt={alt} className="thumbnail-img" />
      )}
      <Styled.CenterContent>
        {clickable ? (
          <Link
            href={`${BASE_URL}/${filePath}`}
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
          >
            <Button variant="text" className="link-button">
              {title}
            </Button>
          </Link>
        ) : (
          <Typography variant="EC_TYPE_SM" className="title">
            {title}
          </Typography>
        )}
        <Typography color={theme.palette.GRAY_3.main} variant="EC_TYPE_2XS">
          {detail}
        </Typography>
      </Styled.CenterContent>
      <IconButton
        onClick={(): void => remove(media)}
        size="small"
        aria-label="remove media"
        className="delete-btn"
      >
        <i className="ri-delete-bin-fill" />
      </IconButton>
    </Styled.MediaBox>
  );
};

const UploadedFileDisplay: React.FC<Props> = ({
  dropCount,
  mediaArray,
  uploading,
  clickableFiles,
  remove,
  multiple,
  customSectionTitle
}) => {
  const uploadingCount: number = React.useMemo(() => {
    const count = dropCount - mediaArray.length;
    if (count >= 0) {
      return count;
    } else {
      return 0;
    }
  }, [dropCount, mediaArray]);

  const title = customSectionTitle ?? 'Your Media Library';

  return (
    <>
      {multiple && <Styled.Title variant="EC_TYPE_LG">{title}</Styled.Title>}
      {multiple && mediaArray.length === 0 && !uploading && (
        <Styled.Root>
          <Styled.PlaceholderBox>
            <Box
              component="img"
              src={ImageUploadPlaceholderIcon}
              alt="image upload cloud"
              className="placeholder-img"
            />
            <Typography variant="EC_TYPE_SM" className="placeholder-text">
              You don&apos;t have any media uploaded yet.
            </Typography>
          </Styled.PlaceholderBox>
        </Styled.Root>
      )}
      {(uploading || mediaArray.length > 0) && (
        <Styled.Root>
          {mediaArray.map((media) => (
            <FileInfo
              key={media.id}
              media={media}
              clickable={clickableFiles}
              remove={remove}
            />
          ))}
          {uploadingCount > 0 &&
            [...Array(uploadingCount)].map((_, idx) => (
              <UploadingFile key={idx} />
            ))}
        </Styled.Root>
      )}
    </>
  );
};

export default UploadedFileDisplay;
