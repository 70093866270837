import * as React from 'react';
import { Box, Checkbox, Link, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { styled } from '@mui/system';
import TermsAndConditionsDialog from '@components/TermsAndConditionsDialog';

const Styled = {
  AcceptBox: styled(Box)({
    position: 'relative',
    ['@media (max-width:340px)']: {
      marginBottom: '20px'
    }
  }),
  CheckboxLabel: styled('label')(({ theme }) => ({
    a: {
      cursor: 'pointer',
      color: theme.palette.primary.main
    },

    'p:after': {
      content: '"*"',
      color: theme.palette.error.main,
      marginLeft: '4px'
    }
  })),
  AgreeText: styled(Typography)({
    position: 'absolute',
    top: '9px',
    left: '36px'
  }),
  CheckboxErrorText: styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main
  }))
};

interface Props {
  termsAccepted: boolean;
  modalOpen: boolean;
  name?: string;
  setModalOpen: () => void;
  setModalClosed: () => void;
  handleAcceptTermsAndConditions: () => void;
}

const RhfAcceptTermsCheckbox: React.FC<Props> = ({
  termsAccepted,
  modalOpen,
  name = 'acceptTerms',
  setModalOpen,
  setModalClosed,
  handleAcceptTermsAndConditions
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <>
      <Styled.AcceptBox id="acceptTerms">
        <Controller
          control={control}
          name={name}
          rules={{
            validate: (value): boolean | string =>
              value || 'You must accept the terms and conditions'
          }}
          render={({ field }): JSX.Element => (
            <Checkbox
              {...field}
              checked={termsAccepted}
              sx={{ paddingLeft: 0 }}
            />
          )}
        />
        <Styled.CheckboxLabel htmlFor="acceptTerms">
          <Styled.AgreeText>
            I agree to the{' '}
            <Link underline="hover" onClick={setModalOpen}>
              terms and conditions
            </Link>
            .
          </Styled.AgreeText>
        </Styled.CheckboxLabel>
      </Styled.AcceptBox>
      {errors.acceptTerms && (
        <Styled.CheckboxErrorText
          variant="EC_TYPE_2XS"
          data-testid="acceptTerms-error-message"
        >
          {errors.acceptTerms?.message}
        </Styled.CheckboxErrorText>
      )}

      <TermsAndConditionsDialog
        open={modalOpen}
        onClose={setModalClosed}
        onAccept={handleAcceptTermsAndConditions}
      />
    </>
  );
};

export default RhfAcceptTermsCheckbox;
