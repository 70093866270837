import * as React from 'react';
import { Box, Button, Theme } from '@mui/material';
import ChefHatLogo from '@assets/svg/chef_hat.round.gold4.svg';
import ECrownLogoWithTitle from '@components/Layout/Common/ECrownLogoWithTitle';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { styled } from '@mui/system';
import { useHistory } from 'react-router-dom';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';

const Styled = {
  ContentWrapper: styled(Box)({
    margin: '60px 70px',
    ['@media (max-width:1200px)']: {
      margin: '60px 24px'
    }
  }),
  Header: styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 60px',
    flexWrap: 'wrap-reverse',
    columnGap: '144px',
    rowGap: '32px',
    '& .company-logo': {
      maxWidth: '120px',
      flex: 1,
      marginLeft: 'auto'
    }
  }),
  BackBtn: styled(Button)(({ theme }) => ({
    fontSize: (theme as Theme).typography.EC_TYPE_2XL.fontSize,
    lineHeight: (theme as Theme).typography.EC_TYPE_2XL.lineHeight,
    color: theme.palette.primary.main,
    alignItems: 'center',
    marginBottom: '24px',
    padding: '12px 8px',
    '& i': {
      color: theme.palette.BLACK.main
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: '48px'
    }
  }))
};

interface Props {
  heroTitle: string;
  heroSubtitle?: string;
  backBtn?: boolean;
  contentLoaded?: boolean;
}

const GlrcPageLayout: React.FC<Props> = ({
  heroTitle,
  heroSubtitle,
  backBtn = true,
  contentLoaded,
  children
}) => {
  const [companyLogo] = useStore<string>(UseStoreKeys.COMPANY_LOGO);
  const history = useHistory();
  const handleBackBtnClick = (): void => {
    history.goBack();
  };

  return (
    <RootPageLayout muiMaxWidth="xl" bgImgVariant={3}>
      <Styled.Header>
        <ECrownLogoWithTitle text={heroTitle} subText={heroSubtitle} />
        <img
          src={companyLogo || ChefHatLogo}
          className="company-logo"
          alt="company-logo"
        />
      </Styled.Header>
      <Styled.ContentWrapper>
        {!!contentLoaded && (
          <>
            {backBtn && (
              <Styled.BackBtn
                startIcon={<i className="ri-arrow-left-line" />}
                onClick={handleBackBtnClick}
              >
                Back
              </Styled.BackBtn>
            )}
            {children}
          </>
        )}
      </Styled.ContentWrapper>
    </RootPageLayout>
  );
};

export default GlrcPageLayout;
