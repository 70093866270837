import * as React from 'react';
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography
} from '@mui/material';
import {
  ActivityLogEntity,
  ProgressPostReq
} from '@api/models/careerServicesApi.models';
import { FormProvider, useForm } from 'react-hook-form';
import { CareerServicesApi } from '@api/CareerService.api';
import { ExternshipProgressType } from '@api/models/externshipApi.models';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import InitialsCircle from '@components/InitialsCircle';
import RhfTextAreaAutosize from '@components/Forms/RhfTextAreaAutosize';
import { styled } from '@mui/system';

const Styled = {
  Accordion: styled(Accordion)({
    padding: 0,
    border: 'none',
    maxWidth: 'calc(100% - 32px)',
    '&.Mui-expanded': {
      minHeight: 0,
      padding: 0
    }
  }),
  AccordionSummary: styled(AccordionSummary)({
    border: 'none',
    padding: 0,
    minHeight: 0,
    maxHeight: '16px',
    '& .comments-button': {
      position: 'absolute',
      top: 0,
      left: 0
    }
  }),
  CommentDiv: styled(Stack)({
    columnGap: '12px',
    marginTop: '8px',
    alignItems: 'center',
    '& .comment-input-form': {
      width: '100%',
      maxWidth: '520px'
    }
  })
};

interface FormValues {
  'comments_site-verified': string;
  'comments_supervisor-verified': string;
  'comments_email-verified': string;
  'comments_job-desc-verified': string;
  'comments_employment-verified': string;
}

interface CommentSectionProps {
  progressItem: ActivityLogEntity;
  activity?: ActivityLogEntity;
  externshipId: number;
  userId: string;
  keycloakUserName: string;
  expanded: string[];
  handleExpand: (panel: string) => void;
  addComment: (
    externshipId: number,
    newComment: ActivityLogEntity,
    parentActivityId?: number
  ) => void;
}

const Comment = (comment: ActivityLogEntity): React.ReactElement => {
  return (
    <Styled.CommentDiv direction="row">
      <InitialsCircle
        name={comment.user}
        textVariant="EC_TYPE_3XS"
        style={{ height: '24px', width: '24px' }}
      />
      <Box>
        <Typography variant="EC_TYPE_2XS">
          {formatDate(comment.date, 'M/dd/yyyy h:mma')} - {`@${comment.user}`}
        </Typography>
        <Typography
          variant="EC_TYPE_2XS"
          fontWeight={400}
          whiteSpace="pre-wrap"
        >
          {comment.description}
        </Typography>
      </Box>
    </Styled.CommentDiv>
  );
};

const ProgressItemComments = ({
  progressItem,
  externshipId,
  userId,
  keycloakUserName,
  expanded,
  handleExpand,
  addComment
}: CommentSectionProps): React.ReactElement => {
  const methods = useForm<FormValues>({
    defaultValues: {
      'comments_site-verified': '',
      'comments_supervisor-verified': '',
      'comments_email-verified': '',
      'comments_job-desc-verified': '',
      'comments_employment-verified': ''
    }
  });

  const { setError, setValue, handleSubmit, reset } = methods;

  const handleShowCommentsClick = React.useCallback(() => {
    handleExpand(progressItem.progressType);
  }, [handleExpand]);

  const handleAddComment = async (
    parentId: number,
    data: ProgressPostReq
  ): Promise<void> => {
    const res = await CareerServicesApi.addCommentToProgress(parentId, data);

    if (res) {
      addComment(externshipId, res, parentId);
    }
  };

  const handleCreateWithComment = async (
    postReq: ProgressPostReq
  ): Promise<void> => {
    const res = await CareerServicesApi.addExternshipProgressWithComment(
      externshipId,
      postReq
    );

    if (res) {
      addComment(externshipId, res);
    }
  };

  const getCommentValue = (
    data: FormValues
  ): { [key in keyof FormValues]?: string } | null => {
    for (const key in data) {
      if (data[key].trim() !== '') {
        return { [key]: data[key] };
      } else {
        setError(key as keyof FormValues, {
          type: 'required',
          message: 'Please enter a comment'
        });

        setValue(key as keyof FormValues, '');
      }
    }

    return null;
  };

  const handleSubmitComment = async (data: FormValues): Promise<void> => {
    const progressWithValue = getCommentValue(data);
    if (progressWithValue) {
      const [type, comment] = Object.entries(progressWithValue)[0];

      const postReq: ProgressPostReq = {
        comment,
        userId,
        progressType: type.split('_')[1] as ExternshipProgressType
      };

      if (progressItem.id) {
        await handleAddComment(progressItem.id, postReq);
      } else {
        await handleCreateWithComment(postReq);
      }

      reset({ [type]: '' });
    }
  };

  const btnText =
    (expanded.includes(progressItem.progressType)
      ? 'Hide comments'
      : 'Show comments') + ` (${progressItem.comments?.length ?? 0})`;

  return (
    <Styled.Accordion
      expanded={expanded.includes(progressItem.progressType)}
      onChange={handleShowCommentsClick}
      disableGutters
    >
      <Styled.AccordionSummary>
        <Button variant="text" size="small" className="comments-button">
          {btnText}
        </Button>
      </Styled.AccordionSummary>
      <Typography variant="EC_TYPE_2XS" mt="0px">
        Comments:
      </Typography>
      {!!progressItem.comments?.length &&
        progressItem.comments.map((comment) => (
          <Comment key={comment.id} {...comment} />
        ))}
      <Styled.CommentDiv direction="row">
        <InitialsCircle name={keycloakUserName} />
        <FormProvider {...methods}>
          <form
            className="comment-input-form"
            onSubmit={handleSubmit(handleSubmitComment)}
          >
            <RhfTextAreaAutosize
              key={progressItem.progressType}
              name={`comments_${progressItem.progressType}`}
              placeholder="Add a comment"
              inputWidth="75%"
              inputMaxWidth="calc(100% - 32px)"
              onKeyDown={(
                e: React.KeyboardEvent<HTMLTextAreaElement>
              ): void => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit(handleSubmitComment)();
                }
              }}
            />
          </form>
        </FormProvider>
      </Styled.CommentDiv>
    </Styled.Accordion>
  );
};

export default ProgressItemComments;
