import * as React from 'react';
import { Box } from '@mui/material';
import CareerPathNode from '@pages/Candidate/CareerPathPage/MyCareerGoal/CareerPathNode';
import { styled } from '@mui/system';
import { useExploreMoreContext } from '@pages/Candidate/CareerPathPage/ExploreMore/CareerPathExploreMoreCard';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const SMALL_NODES_BREAKPOINT = 451;

const REG_NODE_SIZE = 55;
const SM_NODE_SIZE = 34;

const LINE_WIDTH = 10;
const LINE_WIDTH_HALF = LINE_WIDTH / 2;

const HORIZONTAL_LINE_LENGTH = 50;
const VERTICAL_LINE_LENGTH = 37;

const MEDIA_QUERY = `@media (min-width:${SMALL_NODES_BREAKPOINT}px)`;

const Styled = {
  NodeBox: styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginLeft: `${HORIZONTAL_LINE_LENGTH - 2}px`,
    height: `${SM_NODE_SIZE}px`,
    [MEDIA_QUERY]: {
      height: `${REG_NODE_SIZE}px`
    },
    '&:last-of-type': {
      marginBottom: 0
    }
  }),
  LineBox: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'hidden'
  })<{ hidden?: boolean }>(({ hidden }) => ({
    display: hidden ? 'none' : 'block',
    height: '35px',
    width: '55px'
  }))
};

type LeftLineVariant = 'vertical' | 'horizontal' | 'double';

export interface SubwayMapNode {
  roleName: string;
  disableConnectNext?: boolean;
  leftLineExtension?: boolean;
  leftLineExtensionDouble?: boolean;
  leftLineVariant?: LeftLineVariant;
  midBranch?: boolean;
  extendFromMidBranch?: boolean;
}

interface Props extends SubwayMapNode {
  index: number;
  finalIndex: number;
}

type LeftLineProps = Partial<SubwayMapNode> & {
  nodeSize: number;
};

const LeftLine = ({
  nodeSize,
  leftLineVariant,
  midBranch = false,
  extendFromMidBranch = false,
  leftLineExtensionDouble = false
}: LeftLineProps): JSX.Element => {
  const nodeSizeHalf = nodeSize / 2;

  const verticalX = leftLineExtensionDouble
    ? LINE_WIDTH
    : LINE_WIDTH_HALF + HORIZONTAL_LINE_LENGTH / 2;

  const verticalY2 = nodeSize * 1.5 + VERTICAL_LINE_LENGTH + 1;

  const horLineX1 = leftLineExtensionDouble
    ? LINE_WIDTH
    : HORIZONTAL_LINE_LENGTH / 2;

  const coords = React.useMemo(() => {
    if (extendFromMidBranch) {
      return {
        x1: verticalX,
        x2: verticalX,
        y1: nodeSize + VERTICAL_LINE_LENGTH / 2,
        y2: verticalY2
      };
    }

    if (leftLineVariant) {
      switch (leftLineVariant) {
        case 'horizontal':
          return {
            x1: horLineX1,
            x2: HORIZONTAL_LINE_LENGTH,
            y1: nodeSizeHalf,
            y2: nodeSizeHalf
          };
        case 'double': {
          return {
            x1: LINE_WIDTH_HALF,
            x2: HORIZONTAL_LINE_LENGTH,
            y1: nodeSizeHalf,
            y2: nodeSizeHalf
          };
        }
        case 'vertical':
          return {
            x1: verticalX,
            x2: verticalX,
            y1: nodeSizeHalf,
            y2: verticalY2
          };
      }
    } else {
      return { x1: 0, x2: 0, y1: 0, y2: 0 };
    }
  }, [extendFromMidBranch, leftLineVariant, nodeSize]);

  return (
    <>
      {midBranch && ( // mid-point horizontal line
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="left-track-line-horizontal-mid-branch"
        >
          <line
            x1={HORIZONTAL_LINE_LENGTH / 2}
            x2={HORIZONTAL_LINE_LENGTH + nodeSizeHalf}
            y1={nodeSize + VERTICAL_LINE_LENGTH / 2}
            y2={nodeSize + VERTICAL_LINE_LENGTH / 2}
            stroke="#5C6B80"
            strokeWidth={LINE_WIDTH}
          />
        </svg>
      )}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="left-track-line-vertical"
      >
        <line
          x1={coords.x1}
          x2={coords.x2}
          y1={coords.y1}
          y2={coords.y2}
          stroke="#5C6B80"
          strokeWidth={LINE_WIDTH}
        />
      </svg>
    </>
  );
};

const ExploreMoreSubwayMapNode = ({
  roleName,
  index,
  finalIndex,
  leftLineVariant,
  midBranch = false,
  extendFromMidBranch = false,
  leftLineExtension = false,
  disableConnectNext = false,
  leftLineExtensionDouble = false
}: Props): React.ReactElement => {
  const {
    savedGoal,
    expandedTrackRole,
    getStatus,
    getRoleByName,
    setCareerGoal,
    setExpandedTrackRole
  } = useExploreMoreContext();

  const trackRole = getRoleByName(roleName);

  const { width } = useWindowDimensions();

  const smallNodes = width < SMALL_NODES_BREAKPOINT;
  const nodeSize =
    width < SMALL_NODES_BREAKPOINT ? SM_NODE_SIZE : REG_NODE_SIZE;

  const PRIMARY_LINE_X_POS = HORIZONTAL_LINE_LENGTH + nodeSize / 2;

  return (
    <>
      <LeftLine
        leftLineVariant={leftLineVariant}
        midBranch={midBranch}
        nodeSize={nodeSize}
      />
      {leftLineExtension && (
        <LeftLine leftLineVariant="vertical" nodeSize={nodeSize} />
      )}
      {leftLineExtensionDouble && (
        <LeftLine
          leftLineVariant="vertical"
          leftLineExtensionDouble={true}
          nodeSize={nodeSize}
        />
      )}
      {extendFromMidBranch && (
        <LeftLine
          leftLineVariant="vertical"
          nodeSize={nodeSize}
          extendFromMidBranch={true}
        />
      )}
      <Styled.NodeBox>
        <CareerPathNode
          disableMapPin
          smallNodes={smallNodes}
          displayVertical
          index={index}
          status={getStatus(trackRole)}
          role={trackRole}
          isSavedGoal={savedGoal ? savedGoal.id === trackRole.id : false}
          expandedRole={expandedTrackRole}
          setExpandedRole={setExpandedTrackRole}
          setCareerGoal={setCareerGoal}
        />
      </Styled.NodeBox>
      {!disableConnectNext && index !== finalIndex ? (
        <Styled.LineBox>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="track-primary-connector"
          >
            <line
              x1={PRIMARY_LINE_X_POS}
              x2={PRIMARY_LINE_X_POS}
              y1={0}
              y2={VERTICAL_LINE_LENGTH}
              stroke="#5C6B80"
              strokeWidth={LINE_WIDTH}
            />
          </svg>
        </Styled.LineBox>
      ) : (
        <Styled.LineBox hidden={index === finalIndex} />
      )}
    </>
  );
};

export default ExploreMoreSubwayMapNode;
