import * as React from 'react';
import { Box, Link, Stack, Typography } from '@mui/material';
import badge from '@assets/svg/escoffier-heritage-badge.svg';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import { useWindowDimensions } from '@common/hooks/uiHelpers';

const Styled = {
  Root: styled(Box)({
    padding: '8px'
  })
};

interface Props {
  title: string;
  subtitle?: string;
  dataTestId?: string;
  subTitleColor?: string;
  path?: string;
}

const NoDataMessage: React.FC<Props> = ({
  title,
  subtitle,
  path,
  dataTestId,
  subTitleColor
}) => {
  const { width } = useWindowDimensions();
  const atBreakPoint = width <= 420;

  return (
    <Styled.Root
      data-testid={dataTestId}
      textAlign={atBreakPoint ? 'center' : 'left'}
    >
      <Stack
        direction={atBreakPoint ? 'column' : 'row'}
        alignItems="center"
        justifyContent="center"
        spacing="15px"
      >
        <img color="997755" src={badge} alt="Escoffier Heritage Badge" />
        <Typography variant="EC_TYPE_SM">
          {title}
          <br />
          {path && subtitle ? (
            <Link component={RouterLink} to={path} underline="none">
              {subtitle}
            </Link>
          ) : (
            <Box component={'span'} sx={{ color: subTitleColor }}>
              {subtitle}
            </Box>
          )}
        </Typography>
      </Stack>
    </Styled.Root>
  );
};

export default NoDataMessage;
