/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Box, Typography } from '@mui/material';
import {
  FilterGroupNames,
  IFilterData,
  IFilterGroup
} from '@api/models/searchApi.models';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Filters from './Filters';
import { IExpandedFilters } from '@common/fetches/useFetchMarketplaceSearchResults';
import IndividualFilterWrapper from '@components/Filters/IndividualFilterWrapper';
import { makeStyles } from '@mui/styles';
import { useMobileSearchFilterOverlayContext } from '@common/context/mobileSearchFilterOverlayContext';

const useStyles = makeStyles({
  button: {
    marginTop: '30px'
  },
  buttonReset: {
    cursor: 'pointer',
    marginTop: '30px'
  },
  root: {
    minWidth: '200px',
    marginBottom: 0
  },
  accordionRootInner: {
    border: 0
  },
  accordionSummaryInner: {
    padding: 0
  }
});

export interface IFilterState {
  [key: string]: any;
}

export const getFDistanceZipcode = (state: IFilterState): string => {
  const fDistance = state['f-Distance'];

  if (!fDistance) {
    return '';
  }

  return fDistance.value.location.zip;
};

export const getSearchPostBody = (state: IFilterState): IFilterData[] => {
  const data: IFilterData[] = [];

  for (const [key, value] of Object.entries(state)) {
    const filterArr = key.split('___');
    data.push({
      fieldName: filterArr[0],
      value: filterArr[1] ? filterArr[1] : value?.value ?? value,
      selected: filterArr[1] ? value : true
    });
  }

  return data;
};

export interface ExternalJobLocationFilter {
  placeId: string;
  distance?: number;
}

export interface ExternalJobsSearchReq {
  locationFilters: ExternalJobLocationFilter[];
  jobTypeFilter: string[];
  page?: number;
}

export const getExternalJobPostBody = (
  state: IFilterState,
  filterData: IFilterData[]
): ExternalJobsSearchReq => {
  // List of google maps places ids to filter jobs
  const fLocationFilters: ExternalJobLocationFilter[] = filterData
    .filter((f) => f.fieldName === 'f-Location' && f.selected === true)
    .map((f) => ({ placeId: f.value as string }));

  const fDistanceFilter: ExternalJobLocationFilter[] = filterData
    .filter((f) => f.fieldName === 'f-Distance' && f.selected === true)
    .map((f) => ({
      placeId: f.value.location.placeId as string,
      distance: f.value.distance as number,
      zipcode: getFDistanceZipcode(state)
    }));

  const locationFilters: ExternalJobLocationFilter[] = [
    ...fLocationFilters,
    ...fDistanceFilter
  ];

  const jobTypeFilter: string[] = filterData
    .filter((f) => f.fieldName === 'jp."jobType"::jsonb' && f.selected === true)
    .map((f) => f.value as string);

  const postBody: ExternalJobsSearchReq = {
    locationFilters,
    jobTypeFilter
  };

  return postBody;
};

const NoResultMessage: React.FC<{ message: string }> = ({ message }) => {
  return (
    <Box textAlign={'center'} sx={{ p: '16px' }}>
      <Typography variant="EC_TYPE_2XS">{message}</Typography>
    </Box>
  );
};

interface FiltersWrapperProps {
  filtersData: IFilterGroup[];
  filterResults: (
    filterData: IFilterData[],
    resetExpandedFilters?: boolean
  ) => void;
  resetResults: (resetExpandedFilters?: boolean) => void;
  filterExternalJobs?: (filterData?: ExternalJobsSearchReq) => void;
  state: IFilterState;
  setState: React.Dispatch<React.SetStateAction<IFilterState>>;
  expandedFilters: IExpandedFilters;
  setExpandedFilters: React.Dispatch<React.SetStateAction<IExpandedFilters>>;
}

const FiltersWrapper: React.FC<FiltersWrapperProps> = (props): JSX.Element => {
  const classes = useStyles();
  const {
    filtersData,
    filterResults,
    filterExternalJobs,
    resetResults,
    state,
    setState,
    expandedFilters,
    setExpandedFilters
  } = props;
  const { filterOverlayOpen, closeMobileFilter } =
    useMobileSearchFilterOverlayContext();

  const handleChange = (event): void => {
    setState({
      ...state,
      [event.target.name]: event.target.checked
    });
  };

  const handleSubmit = (): void => {
    const data = getSearchPostBody(state);
    filterResults(data, true);

    if (filterExternalJobs) {
      const externalJobFilter = getExternalJobPostBody(state, data);
      filterExternalJobs(externalJobFilter);
    }

    filterOverlayOpen && closeMobileFilter();
  };

  const handleReset = (): void => {
    setState({});
    resetResults(true);
    !!filterExternalJobs && filterExternalJobs();
    filterOverlayOpen && closeMobileFilter();
  };

  return (
    <div className={classes.root}>
      {filtersData
        .filter((f) => !!f.filters.length)
        .map((group, index) => {
          // Search bar located in PageLayoutLeftSidebar in UI
          if (group.groupName !== FilterGroupNames.SEARCH_BAR) {
            return (
              <IndividualFilterWrapper
                key={`${group.groupName}-${index}`}
                filterTitle={group.groupName}
                expandedFilters={expandedFilters}
                setExpandedFilters={setExpandedFilters}
                filterContent={
                  <>
                    {group.filters.length ? (
                      <Filters
                        group={group.groupName}
                        filters={group.filters}
                        state={state}
                        handleChange={handleChange}
                        filterResults={filterResults}
                        setState={setState}
                        expandedFilters={expandedFilters}
                        setExpandedFilters={setExpandedFilters}
                        filterExternalJobs={filterExternalJobs}
                      />
                    ) : (
                      <NoResultMessage message="No Results" />
                    )}
                    {group.groupName === 'Location' &&
                    !group.filterGroups.length ? (
                      <></>
                    ) : (
                      group.filterGroups.map((subGroup, index) => {
                        return (
                          <Accordion
                            className={classes.accordionRootInner}
                            key={`accordion-${index}`}
                          >
                            <AccordionSummary
                              className={classes.accordionSummaryInner}
                              style={{ minHeight: '30px' }}
                              expandIcon={
                                <i className="ri-arrow-down-s-line" />
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography variant="EC_TYPE_2XS">
                                {subGroup.groupName}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: 0 }}>
                              <Filters
                                group={group.groupName}
                                filters={subGroup.filters}
                                state={state}
                                handleChange={handleChange}
                                filterResults={filterResults}
                                setState={setState}
                                expandedFilters={expandedFilters}
                                setExpandedFilters={setExpandedFilters}
                              />
                            </AccordionDetails>
                          </Accordion>
                        );
                      })
                    )}
                  </>
                }
              />
            );
          }
        })}
      <Button
        fullWidth
        variant="contained"
        onClick={handleSubmit}
        className={classes.button}
      >
        Apply Filters
      </Button>
      <Box onClick={handleReset} className={classes.buttonReset}>
        <Typography variant="EC_TYPE_2XS">Reset Filters</Typography>
      </Box>
    </div>
  );
};

export default FiltersWrapper;
