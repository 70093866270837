/* eslint-disable @typescript-eslint/indent */
import {
  ApplicationStatusCodebook,
  BadgeCodebook,
  CandidateCodebooks,
  Codebook,
  PublicCodebooks,
  SkillCodebook
} from '@api/models/codebook.models';
import {
  AttestationEmploymentType,
  AttestationType,
  AttestationWaiverType
} from '@interfaces/attestation.interface';
import { api } from '@api/configs/base';
import { apiConfig } from '@api/configs/api.config';
import { ApiResponse } from '@api/models/base.models';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export class CodebookApi {
  static async getPublicCodebooks(): Promise<ApiResponse<PublicCodebooks>> {
    try {
      const response = await api.get<ApiResponse<PublicCodebooks>>(
        `${baseUrl}${apiConfig.codebooksUrl}/public`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for CodebookApi.getPublicCodebooks(): ${error}`);
      throw error;
    }
  }

  static async getAllSkills(): Promise<ApiResponse<SkillCodebook[]>> {
    try {
      const response = await api.get<ApiResponse<SkillCodebook[]>>(
        `${baseUrl}${apiConfig.codebooksUrl}/skills`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for CodebookApi.getSkills(): ${error}`);
      throw error;
    }
  }

  static async getTechnicalSkills(): Promise<ApiResponse<SkillCodebook[]>> {
    try {
      const response = await api.get<ApiResponse<SkillCodebook[]>>(
        `${baseUrl}${apiConfig.codebooksUrl}/skills/technical`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for CodebookApi.getTechnicalSkills(): ${error}`);
      throw error;
    }
  }

  static async getSoftSkills(): Promise<ApiResponse<SkillCodebook[]>> {
    try {
      const response = await api.get<ApiResponse<SkillCodebook[]>>(
        `${baseUrl}${apiConfig.codebooksUrl}/skills/soft`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for CodebookApi.getSoftSkills(): ${error}`);
      throw error;
    }
  }

  // prettier-ignore
  static async getCandidateCodebooks(): Promise<
  ApiResponse<CandidateCodebooks>
  > {
    try {
      const response = await api.get<ApiResponse<CandidateCodebooks>>(
        `${baseUrl}${apiConfig.codebooksUrl}/candidate/profile`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for CodebookApi.getCandidateCodebooks(): ${error}`);
      throw error;
    }
  }
  static async getAllSegments(): Promise<ApiResponse<Codebook[]>> {
    try {
      const response = await api.get<ApiResponse<Codebook[]>>(
        `${baseUrl}${apiConfig.codebooksUrl}/segments/`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for CodebookApi.getAllSegments(): ${error}`);
      throw error;
    }
  }

  //getAllSpecialties
  static async getSpecialties(): Promise<ApiResponse<Codebook[]>> {
    try {
      const response = await api.get<ApiResponse<Codebook[]>>(
        `${baseUrl}${apiConfig.codebooksUrl}/specialties`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for CodebookApi.getSpecialties(): ${error}`);
      throw error;
    }
  }

  static async getBadges(): Promise<ApiResponse<BadgeCodebook[]>> {
    try {
      const response = await api.get<ApiResponse<BadgeCodebook[]>>(
        `${baseUrl}${apiConfig.codebooksUrl}/badges`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for CodebookApi.getBadges(): ${error}`);
      throw error;
    }
  }

  static async getApplicationStatuses(): Promise<
    ApiResponse<ApplicationStatusCodebook[]>
  > {
    try {
      const response = await api.get<ApiResponse<ApplicationStatusCodebook[]>>(
        `${baseUrl}${apiConfig.codebooksUrl}/application-statuses`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for CodebookApi.getApplicationStatuses(): ${error}`);
      throw error;
    }
  }

  static async getCampuses(): Promise<ApiResponse<Codebook[]>> {
    try {
      const response = await api.get<ApiResponse<Codebook[]>>(
        `${baseUrl}${apiConfig.codebooksUrl}/campuses`
      );
      return response.data;
    } catch (error: any) {
      console.error(`Error for CodebookApi.getCampuses(): ${error}`);
      throw error;
    }
  }

  static async getCampusPrograms(
    campusId: number
  ): Promise<ApiResponse<Codebook[]>> {
    try {
      const response = await api.get<ApiResponse<Codebook[]>>(
        `${baseUrl}${apiConfig.codebooksUrl}/campuses/${campusId}/programs`
      );
      return response.data;
    } catch (error: any) {
      console.error(`Error for CodebookApi.getCampusPrograms(): ${error}`);
      throw error;
    }
  }

  static async getPartnershipLevels(): Promise<ApiResponse<Codebook[]>> {
    try {
      const response = await api.get<ApiResponse<Codebook[]>>(
        `${baseUrl}${apiConfig.codebooksUrl}/partnership-levels`
      );
      return response.data;
    } catch (error: any) {
      console.error(`Error for CodebookApi.getPartnershipLevels(): ${error}`);
      throw error;
    }
  }

  static async getGlrcContentTypes(): Promise<ApiResponse<Codebook[]>> {
    try {
      const response = await api.get<ApiResponse<Codebook[]>>(
        `${baseUrl}${apiConfig.codebooksUrl}/content-types`
      );
      return response.data;
    } catch (error: any) {
      console.error(`Error for CodebookApi.getGlrcContentTypes(): ${error}`);
      throw error;
    }
  }

  static async getGlrcContentTopics(): Promise<ApiResponse<Codebook[]>> {
    try {
      const response = await api.get<ApiResponse<Codebook[]>>(
        `${baseUrl}${apiConfig.codebooksUrl}/content-topics`
      );
      return response.data;
    } catch (error: any) {
      console.error(`Error for CodebookApi.getGlrcContentTopics(): ${error}`);
      throw error;
    }
  }

  static async getExternshipSupportedPrograms(): Promise<
    ApiResponse<Codebook[]>
  > {
    try {
      const res = await api.get<ApiResponse<Codebook[]>>(
        `${baseUrl}${apiConfig.codebooksUrl}/externship-programs`
      );
      return res.data;
    } catch (error: any) {
      console.error(
        `Error for CodebookApi.getExternshipCompanyPrograms(): ${error}`
      );
      throw error;
    }
  }

  static async getExternshipStartDates(): Promise<ApiResponse<Date[]>> {
    try {
      const res = await api.get<ApiResponse<Date[]>>(
        `${baseUrl}${apiConfig.codebooksUrl}/externship-start-dates`
      );
      return res.data;
    } catch (error: any) {
      console.error(
        `Error for CodebookApi.getExternshipStartDates(): ${error}`
      );
      throw error;
    }
  }

  static async getExternshipStartDatesByYear(
    year: number
  ): Promise<ApiResponse<Date[]>> {
    try {
      const res = await api.get<ApiResponse<Date[]>>(
        `${baseUrl}${apiConfig.codebooksUrl}/externship-start-dates/academic-year/${year}`
      );
      return res.data;
    } catch (error: any) {
      console.error(
        `Error for CodebookApi.getExternshipStartDatesByYear(): ${error}`
      );
      throw error;
    }
  }

  static async getAttestationTypes(): Promise<
    ApiResponse<Codebook<AttestationType>[]>
  > {
    try {
      const response = await api.get<ApiResponse<Codebook<AttestationType>[]>>(
        `${baseUrl}${apiConfig.codebooksUrl}/attestation-types`
      );
      return response.data;
    } catch (error: any) {
      console.error(`Error for CodebookApi.getAttestationTypes(): ${error}`);
      throw error;
    }
  }

  static async getAttestationEmploymentTypes(): Promise<
    ApiResponse<Codebook<AttestationEmploymentType>[]>
  > {
    try {
      const response = await api.get<
        ApiResponse<Codebook<AttestationEmploymentType>[]>
      >(`${baseUrl}${apiConfig.codebooksUrl}/attestation-employment-types`);
      return response.data;
    } catch (error: any) {
      console.error(
        `Error for CodebookApi.getAttestationEmploymentTypes(): ${error}`
      );
      throw error;
    }
  }

  static async getAttestationWaiverTypes(): Promise<
    ApiResponse<Codebook<AttestationWaiverType>[]>
  > {
    try {
      const response = await api.get<
        ApiResponse<Codebook<AttestationWaiverType>[]>
      >(`${baseUrl}${apiConfig.codebooksUrl}/attestation-waiver-types`);
      return response.data;
    } catch (error: any) {
      console.error(
        `Error for CodebookApi.getAttestationWaiverTypes(): ${error}`
      );
      throw error;
    }
  }
}
