import * as React from 'react';
import { Box, useTheme } from '@mui/material';
import { Badge } from '@common/mappings/BadgeMappings';
import IconBadge from '@components/IconBadge';
import { styled } from '@mui/system';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';

const Styled = {
  ImageWrapper: styled(Box)(({ theme }) => ({
    position: 'relative',
    height: 'fit-content',
    width: 'fit-content',
    backgroundColor: theme.palette.WHITE.main
  })),
  CandidatePhoto: styled('img', {
    shouldForwardProp: (prop) => prop !== 'noBorderRadius'
  })<{ noBorderRadius?: boolean }>(({ noBorderRadius }) => ({
    display: 'block',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: noBorderRadius ? 0 : '4px',
    boxSizing: 'border-box',
    padding: '8px'
  })),
  BadgeWrapper: styled(Box)({
    position: 'absolute',
    bottom: '-6px',
    left: '-6px'
  })
};

interface Props {
  dataTestId?: string;
  src: string;
  badge?: Badge;
  expandableBadge?: boolean;
  width?: string;
  height?: string;
  borderColor?: string;
  noBorder?: boolean;
  noBorderRadius?: boolean;
  backgroundColor?: string;
}

const ImageWithBadge: React.FC<Props> = (props) => {
  const theme = useTheme();

  const {
    dataTestId,
    src,
    badge,
    expandableBadge = false,
    width = '100px',
    height = '100px',
    borderColor = theme.palette.GOLD_1.main,
    noBorder = false,
    noBorderRadius = false,
    backgroundColor = 'transparent'
  } = props;

  const featureFlags = useFeatureFlags();
  // const border = noBorder ? 'none' : `1px solid ${borderColor}`;

  const handleClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
  };

  return (
    <Styled.ImageWrapper className="image-wrapper">
      <Styled.CandidatePhoto
        data-testid={dataTestId}
        src={src}
        sx={{
          width,
          height,
          backgroundColor,
          ...(!noBorder && { border: `1px solid ${borderColor}` })
        }}
        noBorderRadius={noBorderRadius}
      />
      {badge && featureFlags.PROFILE_BADGE && (
        <Styled.BadgeWrapper
          onClick={handleClick}
          data-testid={`badge-${badge.label}`}
        >
          <IconBadge {...badge} expandable={expandableBadge} />
        </Styled.BadgeWrapper>
      )}
    </Styled.ImageWrapper>
  );
};

export default ImageWithBadge;
