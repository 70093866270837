import * as React from 'react';
import { Link, useTheme } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  path: string;
  companyName: string;
  dataTestId: string;
}

const CompanyProfileLink = React.memo(
  ({ path, companyName, dataTestId }: Props) => {
    const theme = useTheme();

    return (
      <Link
        component={RouterLink}
        to={path}
        underline="hover"
        sx={{
          color: theme.palette.BLACK.main,
          ':hover': { color: theme.palette.primary.main }
        }}
        data-testid={dataTestId}
      >
        {companyName}
      </Link>
    );
  }
);

CompanyProfileLink.displayName = 'CompanyProfileLink';

export const renderCompanyProfileLink = (
  params: GridRenderCellParams
): JSX.Element => {
  const path = `/employer/${params.id}/profile`;

  return (
    <CompanyProfileLink
      path={path}
      companyName={params.row.companyName}
      dataTestId={`renderCompanyProfileLink-${params.id}`}
    />
  );
};
