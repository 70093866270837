import * as React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { ExternalAdzunaJob } from '@api/models/singleJobPostApi.models';
import IconWithLabel from '@components/IconWithLabel';
import { styled } from '@mui/system';
import { useTagManager } from '@common/hooks/useTagManager';

const Styled = {
  Card: styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '16px',
    height: '245px',
    border: `1px solid ${theme.palette.grey3.main}`,
    padding: '16px',
    borderRadius: '4px',
    overflow: 'hidden',
    cursor: 'pointer'
  })),
  JobTitle: styled(Typography)({
    marginBottom: '8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  }),
  Description: styled(Typography)({
    fontWeight: 400,
    marginTop: '16px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 7,
    WebkitBoxOrient: 'vertical'
  })
};

const ExternalJobCard: React.FC<ExternalAdzunaJob> = ({
  id,
  jobTitle,
  location,
  description,
  companyName,
  redirectUrl
}) => {
  const { sendToGtm } = useTagManager();

  const handleClick = (): void => {
    sendToGtm('external_job_click', {
      job_id: id,
      job_title: jobTitle,
      company_name: companyName,
      location: location,
      redirect_url: redirectUrl
    });
  };

  return (
    <Link
      href={redirectUrl}
      target="_blank"
      rel="noopener noreferrer"
      underline="none"
      onClick={handleClick}
    >
      <Styled.Card>
        <Box>
          <Styled.JobTitle variant="EC_TYPE_SM">{jobTitle}</Styled.JobTitle>
          <IconWithLabel icon="ri-map-pin-2-fill" title={location} />
          <Styled.Description variant="EC_TYPE_2XS" color="GRAY_3.main">
            {description}
          </Styled.Description>
        </Box>
        <Typography variant="EC_TYPE_SM" sx={{ fontWeight: 400 }}>
          {companyName}
        </Typography>
      </Styled.Card>
    </Link>
  );
};

export default ExternalJobCard;
