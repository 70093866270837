import * as React from 'react';
import { Box, BoxProps, Icon } from '@mui/material';

const REG_NODE_SIZE = 55;
const SM_NODE_SIZE = 34;

export enum NodeCircleColors {
  BG_INCOMPLETE = '#FBF9F5', // very light grey
  BORDER_INCOMPLETE = '#EBE4DD', // light grey
  BG_COMPLETE = '#EBDFCD', // gold1
  BORDER_COMPLETE = '#997755' // GOLD_1
}

interface Props extends BoxProps {
  bgColor: NodeCircleColors;
  smallNodes?: boolean;
  enableHover?: boolean;
  checked?: boolean;
  dataTestId?: string;
  iconDataTestId?: string;
}

const NodeCircle = React.forwardRef<HTMLElement, Props>((props, ref) => {
  const {
    bgColor,
    smallNodes = false,
    enableHover = true,
    checked = false,
    dataTestId,
    iconDataTestId,
    ...boxProps
  } = props;

  const borderColor = React.useMemo(() => {
    if (bgColor === NodeCircleColors.BG_COMPLETE) {
      return NodeCircleColors.BORDER_COMPLETE;
    } else {
      return NodeCircleColors.BORDER_INCOMPLETE;
    }
  }, [bgColor]);

  const nodeSize = React.useMemo(() => {
    if (smallNodes) {
      return `${SM_NODE_SIZE}px`;
    } else {
      return `${REG_NODE_SIZE}px`;
    }
  }, [smallNodes]);

  return (
    <Box
      {...boxProps}
      ref={ref}
      data-testid={dataTestId}
      sx={{
        position: 'relative',
        flexShrink: 0,
        height: nodeSize,
        width: nodeSize,
        backgroundColor: bgColor,
        border: `5px solid ${borderColor}`,
        borderRadius: '50%',
        boxSizing: 'border-box',
        zIndex: 10,
        ...(enableHover && {
          ':hover': {
            pointer: 'cursor',
            backgroundColor: NodeCircleColors.BORDER_COMPLETE,
            '& .ri-checkbox-circle-fill': {
              color: NodeCircleColors.BG_COMPLETE
            }
          }
        })
      }}
    >
      {checked && (
        <Icon
          data-testid={iconDataTestId}
          className="ri-checkbox-circle-fill"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: NodeCircleColors.BORDER_COMPLETE
          }}
        />
      )}
    </Box>
  );
});

NodeCircle.displayName = 'NodeCircle';

export default NodeCircle;
