import { ApplicationStatusCodebook } from '@api/models/codebook.models';
import { NotHiredStatusValues } from '@api/models/jobApplicationApi.models';

export const getApplicationStatusLabel = (
  status: ApplicationStatusCodebook,
  hideNotHiredReason?: boolean
): string => {
  const { value, label } = status;
  if (NotHiredStatusValues.includes(value)) {
    return hideNotHiredReason ? 'Not Hired' : `Not Hired: ${label}`;
  }

  return label;
};
