import * as React from 'react';
import BasicModal from '@components/BasicModal';
import { Typography } from '@mui/material';

interface Props {
  handleClose: (e) => void;
  viewToDelete?: number;
  handleDelete: (e) => Promise<void>;
  isDeleting: boolean;
}

const DeleteGridViewModal: React.FC<Props> = ({
  handleClose,
  viewToDelete,
  handleDelete,
  isDeleting
}) => {
  if (!viewToDelete) {
    return <></>;
  }

  return (
    <BasicModal
      open={!!viewToDelete}
      handleClose={handleClose}
      title={'Delete View'}
      primaryAction={{
        label: 'Confirm',
        action: handleDelete,
        disabled: isDeleting
      }}
      secondaryAction={{
        label: 'Cancel',
        action: handleClose
      }}
      maxWidth="sm"
    >
      <Typography variant="EC_TYPE_BASE">
        Are you sure you want to delete this view?
      </Typography>
    </BasicModal>
  );
};

export default DeleteGridViewModal;
