import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';

interface CheckboxSingleProps {
  control: any;
  label: string;
  onChange: any;
  name: string;
}

const CheckboxSingle: React.FC<CheckboxSingleProps> = (props): JSX.Element => {
  const { control, label, onChange, name } = props;
  return (
    <>
      <FormControlLabel
        control={control}
        label={label}
        onChange={onChange}
        name={name}
      />
    </>
  );
};

export default CheckboxSingle;
