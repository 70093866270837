import * as React from 'react';
import { Box, Typography } from '@mui/material';
import BasicCardWithLeftImage from '@components/BasicCardWithLeftImage';
import ButtonArrow from '@components/ButtonArrow';
// import EmbeddedVideo from '@components/EmbeddedVideo';
import ExternalEduLink from '@components/ExternalEduLink';
import QuizPromoBox from '@components/Quiz/common/QuizPromoBox';
import { styled } from '@mui/system';
// import vidKnifeSkills from '@assets/thumbnails/video-thumbnail.knife-skills.png';
// import vidPlating from '@assets/thumbnails/video-thumbnail.plating-poached-dishes.png';
// import vidSanitation from '@assets/thumbnails/video-thumbnail.sanitation.png';
// import vidWhiteStock from '@assets/thumbnails/video-thumbnail.white-stock.png';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import wlCulinaryImg from '@assets/work-learn.culinary-arts.png';
import wlFoodEntrepreneurshipImg from '@assets/work-learn.food-entrepreneurship.jpg';
import wlHolisticNutritionImg from '@assets/work-learn.holistic-nutrition.jpg';
import wlHospitalityImg from '@assets/work-learn.hospitality-management.png';
import wlPastryImg from '@assets/work-learn.pastry-arts.png';
import wlPlantBasedImg from '@assets/work-learn.plant-based-arts.png';
import WorkLearnDescriptionCard from '@components/WorkLearnDescriptionCard';

// TODO: leaving commented code because Escoffier might want to add videos back in the future.

const Styled = {
  Root: styled(Box)({
    padding: '40px 0px'
  }),
  PromoBoxesSection: styled(Box)({
    width: '100%',
    maxWidth: '1120px',
    margin: '0 auto',
    marginBottom: '56px',
    padding: '0px 16px',
    '& > *:not(:last-child)': { marginBottom: '80px' }
  }),
  ProgramsSection: styled(Box)({
    backgroundColor: 'transparent',
    padding: '58px 16px'
  }),
  CardBox: styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1120px',
    margin: '0 auto',
    '& .grid-header': {
      marginBottom: '40px'
    },
    '& .btn-link': {
      alignSelf: 'center',
      margin: '70px 0'
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '650px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '280px'
    }
  })),
  CardGrid: styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '20px',
    margin: '0 auto',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr'
    }
  })),
  GridItem: styled(Box)({
    justifySelf: 'center'
  })
};

const cards = [
  {
    image: wlCulinaryImg,
    title: 'culinary arts',
    text: 'Hands-on techniques and methods in the preparation of foods, professional kitchen management, business skills, and more.',
    link: 'https://www.escoffier.edu/about/campuses/online-culinary/'
  },
  {
    image: wlPastryImg,
    title: 'pastry arts',
    text: 'Advanced learning in the technical skills and science of baking and pastry methods, with a balance of practical business skills.',
    link: 'https://www.escoffier.edu/about/campuses/online-pastry/'
  },
  {
    image: wlHospitalityImg,
    title: 'hospitality & restaurant operations management',
    text: 'A greater emphasis on business-oriented classes prepares students for opportunities in hospitality and restaurant operations management.',
    link: 'https://www.escoffier.edu/academics/food-and-beverage-online/'
  },
  {
    image: wlPlantBasedImg,
    title: 'plant-based culinary arts',
    text: 'Create delightful foods with plant-based ingredients and techniques, plus business skills to promote plant-based nutrition and lifestyle choices.',
    link: 'https://www.escoffier.edu/plant-based-programs/'
  },
  {
    image: wlHolisticNutritionImg,
    title: 'Holistic nutrition & Wellness',
    text: 'Hands-on techniques and methods in the preparation of foods, professional kitchen management, business skills, and more.',
    link: 'https://www.escoffier.edu/holistic-nutrition-and-wellness/'
  },
  {
    image: wlFoodEntrepreneurshipImg,
    title: 'Food Entrepreneurship',
    text: 'Explore food startups and the skills you may need to start a food truck, open a resaurant, and more.',
    link: 'https://www.escoffier.edu/food-entrepreneurship/'
  }
];

const WorkLearnSection: React.FC = () => {
  const featureFlags = useFeatureFlags();
  return (
    <Styled.Root>
      <Styled.PromoBoxesSection>
        <WorkLearnDescriptionCard />
        {featureFlags.CAREER_QUIZ && <QuizPromoBox />}
      </Styled.PromoBoxesSection>
      <Styled.ProgramsSection>
        <Styled.CardBox>
          <Typography variant="EC_TYPE_4XL" className="grid-header">
            Programs
          </Typography>
          <Styled.CardGrid>
            {cards.map((card) => (
              <Styled.GridItem key={card.title}>
                <ExternalEduLink link={card.link}>
                  <BasicCardWithLeftImage {...card} />
                </ExternalEduLink>
              </Styled.GridItem>
            ))}
          </Styled.CardGrid>
          <Box className="btn-link">
            <ButtonArrow
              buttonText="See Education Program Details"
              buttonLink="https://www.escoffier.edu/academics/"
              external={true}
            />
          </Box>
        </Styled.CardBox>
      </Styled.ProgramsSection>
    </Styled.Root>
  );
};

export default WorkLearnSection;
