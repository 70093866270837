/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { styled } from '@mui/system';

const Styled = {
  Button: styled(Button, {
    shouldForwardProp: (prop) => prop !== 'enabled'
  })<{ enabled: boolean }>(({ enabled, theme }) => ({
    padding: '0 1px',
    height: '16px',
    minWidth: 'unset',
    width: 'max-content',
    fontWeight: enabled ? 700 : 400,
    ...(!enabled && {
      color: theme.palette.BLACK.main,
      pointerEvents: 'none'
    })
  }))
};

interface Props extends ButtonProps {
  value: number;
  dataTestId: string;
  clickableAtZero?: boolean;
  inactiveCompany?: boolean;
}

const ValueButton = React.memo(
  ({
    value,
    clickableAtZero,
    inactiveCompany,
    dataTestId,
    ...btnProps
  }: Props) => {
    const { onClick } = btnProps;

    const clickable = inactiveCompany
      ? false
      : clickableAtZero
      ? true
      : value > 0;

    const displayVal = inactiveCompany ? '-' : value;

    return (
      <Styled.Button
        variant="text"
        onClick={onClick}
        enabled={clickable}
        data-testid={dataTestId}
      >
        {displayVal}
      </Styled.Button>
    );
  }
);

ValueButton.displayName = 'ValueButton';

export const renderCellValueButton = (
  params: GridRenderCellParams,
  onClick: (...args: any[]) => void,
  useActivity: boolean | undefined = false,
  clickableAtZero: boolean | undefined = false
): JSX.Element => {
  const inactive = useActivity ? params.row.enabled === false : false;

  return (
    <ValueButton
      value={params.value}
      onClick={onClick}
      clickableAtZero={clickableAtZero}
      inactiveCompany={inactive}
      dataTestId={`renderCellValueButton-${params.field}-${params.id}`}
    />
  );
};
