import { SubwayMapNode } from '@pages/Candidate/CareerPathPage/ExploreMore/SubwayMaps/ExploreMoreSubwayMapNode';

export interface SubwayTrack {
  trackTitle: string;
  roles: SubwayMapNode[];
}

const culinaryTrack: SubwayTrack = {
  trackTitle: 'culinary',
  roles: [
    {
      roleName: 'prep cook'
    },
    {
      roleName: 'line cook'
    },
    {
      roleName: 'kitchen lead'
    },
    {
      roleName: 'sous chef'
    },
    {
      roleName: 'chef',
      leftLineVariant: 'horizontal',
      leftLineExtension: true
    },
    {
      roleName: 'private chef',
      leftLineExtension: true,
      disableConnectNext: true
    },
    {
      roleName: 'research and development chef',
      leftLineVariant: 'horizontal'
    }
  ]
};

const artisanTrack: SubwayTrack = {
  trackTitle: 'artisan',
  roles: [
    {
      roleName: 'junior baker',
      leftLineVariant: 'horizontal',
      leftLineExtension: true
    },
    {
      roleName: 'baker',
      leftLineExtension: true
    },
    {
      roleName: 'senior baker',
      disableConnectNext: true,
      leftLineExtension: true
    },
    {
      roleName: 'pastry chef',
      leftLineVariant: 'horizontal',
      midBranch: true,
      extendFromMidBranch: true
    },
    {
      roleName: 'chocolatier',
      disableConnectNext: true,
      leftLineExtension: true
    },
    {
      roleName: 'food stylist',
      leftLineVariant: 'horizontal',
      leftLineExtension: true,
      disableConnectNext: true
    },
    {
      roleName: 'cake designer',
      leftLineVariant: 'horizontal'
    }
  ]
};

const managementTrack: SubwayTrack = {
  trackTitle: 'management',
  roles: [
    {
      roleName: 'shift lead'
    },
    {
      roleName: 'kitchen lead'
    },
    {
      roleName: 'restaurant manager'
    },
    {
      roleName: 'regional manager'
    }
  ]
};

const serviceTrack: SubwayTrack = {
  trackTitle: 'service',
  roles: [
    {
      roleName: 'busser/host',
      leftLineVariant: 'double',
      leftLineExtensionDouble: true
    },
    {
      roleName: 'server',
      leftLineVariant: 'horizontal',
      leftLineExtension: true,
      leftLineExtensionDouble: true
    },
    {
      roleName: 'maitre d',
      disableConnectNext: true,
      leftLineExtension: true,
      leftLineExtensionDouble: true
    },
    {
      roleName: 'bartender',
      leftLineVariant: 'double',
      leftLineExtension: true
    },
    {
      roleName: 'mixologist',
      leftLineExtension: true
    },
    {
      roleName: 'sommelier',
      leftLineVariant: 'horizontal'
    }
  ]
};

const entrepreneurTrack: SubwayTrack = {
  trackTitle: 'entrepreneur',
  roles: [
    {
      roleName: 'chef',
      leftLineVariant: 'horizontal',
      leftLineExtension: true
    },
    {
      roleName: 'nutrition coach',
      leftLineExtension: true,
      disableConnectNext: true
    },
    {
      roleName: 'restaurant manager',
      leftLineExtension: true,
      midBranch: true
    },
    {
      roleName: 'caterer',
      leftLineExtension: true,
      disableConnectNext: true
    },
    {
      roleName: 'restaurant owner',
      leftLineVariant: 'horizontal',
      leftLineExtension: true,
      disableConnectNext: true
    },
    {
      roleName: 'food truck owner',
      leftLineVariant: 'horizontal'
    }
  ]
};

export const subwayTracks = [
  culinaryTrack,
  artisanTrack,
  managementTrack,
  serviceTrack,
  entrepreneurTrack
];
