export const getClosestDate = (dates: Date[], date: Date): Date => {
  const sortedDatesClosestToFurthest = [...dates].sort((a, b) => {
    const distanceA = Math.abs(date.valueOf() - new Date(a).valueOf());
    const distanceB = Math.abs(date.valueOf() - new Date(b).valueOf());
    return distanceA - distanceB;
  });

  return sortedDatesClosestToFurthest[0];
};

export const getLastDatePast31Days = (
  dates: Date[],
  todaysDate: Date
): Date | undefined => {
  return dates
    .filter((date) => {
      const diffTime = Math.abs(todaysDate.getTime() - date.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays > 31;
    })
    ?.pop();
};

export const getYear31DaysAgo = (
  todaysDate: Date,
  externshipDatesByYear: { [key: string]: Date[] }
): string => {
  const date31DaysAgo = new Date(
    todaysDate.getTime() - 31 * 24 * 60 * 60 * 1000
  );
  const year31DaysAgo = date31DaysAgo.getFullYear();
  const externshipDatesAtReturnYear =
    externshipDatesByYear[year31DaysAgo] ?? [];
  const checkDate = getLastDatePast31Days(
    externshipDatesAtReturnYear,
    todaysDate
  );

  // If the last grad date 31 days ago or more is greater than the current date,
  // we need to set the year that we index by back by one. This is necessary to handle dates in January.
  if (checkDate && checkDate.getTime() > todaysDate.getTime()) {
    const lastYear = year31DaysAgo - 1;
    return String(lastYear);
  }

  return String(year31DaysAgo);
};
