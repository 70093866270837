import { BadgeCodebook, BadgeType } from '@api/models/codebook.models';
import { useEffect, useState } from 'react';
import { CodebookApi } from '@api/Codebook.api';

export type FetchBadgeCodebooks = {
  candidateBadges: BadgeCodebook[];
  employerBadges: BadgeCodebook[];
  loading: boolean;
  loaded: boolean;
};

export const useFetchBadgeCodebooks = (): FetchBadgeCodebooks => {
  const [candidateBadges, setCandidateBadges] = useState<BadgeCodebook[]>([]);
  const [employerBadges, setEmployerBadges] = useState<BadgeCodebook[]>([]);

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        setLoading(true);
        const res = await CodebookApi.getBadges();

        setCandidateBadges(
          res.data.filter((badge) => badge.type === BadgeType.CANDIDATE_BADGE)
        );
        setEmployerBadges(
          res.data.filter((badge) => badge.type === BadgeType.EMPLOYER_BADGE)
        );

        setLoaded(true);
      } catch (error: any) {
        console.error(
          'Error for useFetchBadgeCodebooks -> CodebookApi.getBadges()',
          error
        );
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    };

    fetchData();
  }, []);

  return {
    candidateBadges,
    employerBadges,
    loading,
    loaded
  };
};
