import * as React from 'react';
import { Box, Button, Container, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ImageWithOffsetCard from '@components/ImageWithOffsetCard';
import { styled } from '@mui/system';
import { tabContent } from '@pages/Public/WorkAndLearnPage/WorkAndLearnCandidatePage/WorkLearnExplorePrograms/exploreProgramsTabContent';
import theme from '@themes/ui/escoffier';

const Styled = {
  Root: styled(Container)({
    margin: '0 auto',
    padding: 0,
    '& .explore-section-title': {
      color: theme.palette.primary.main,
      paddingLeft: '16px'
    }
  }),
  TabRoot: styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '12px',
    '& .explore-section-title': {
      color: theme.palette.primary.main,
      paddingLeft: '24px'
    },
    ['@media (max-width:900px)']: {
      flexDirection: 'column'
    }
  }),
  TabNavWrapper: styled(Box)({
    flex: 1,
    minWidth: '350px'
  }),
  TabWrapper: styled(Box)({
    paddingLeft: '36px',
    textAlign: 'left',
    marginBottom: '64px'
  }),
  TabList: styled(TabList)({
    '& .MuiTab-root': {
      borderRight: 'none',
      alignSelf: 'start',
      padding: '24px 0',
      textAlign: 'left',
      fontSize: '16px',
      lineHeight: '24px',
      transform: 'translateX(-1px)',
      color: theme.palette.GRAY_3.main,
      marginBottom: '6px',
      ['@media (min-width:400px)']: {
        padding: '40px 0',
        fontSize: '22px',
        lineHeight: '30px'
      }
    },
    '& .MuiTabs-indicator': {
      display: 'none'
    }
  }),
  Tab: styled(Tab)({
    '&.Mui-selected': {
      color: theme.palette.GRAY_4.main,
      borderRight: 'none',
      boxShadow: `-1px 5px 0 ${theme.palette.primary.main}`
    }
  }),
  TabContent: styled(TabPanel)({
    flex: 2,
    minWidth: '334px',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      minWidth: 'unset'
    }
  }),
  LinksWrapper: styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '16px',
    margin: '24px 0px 72px'
  }),
  PdfButton: styled(Button)({
    padding: 0
  })
};

const tabs = [
  {
    label: 'OVERVIEW',
    value: '1'
  },
  {
    label: 'CULINARY ARTS',
    value: '2'
  },
  {
    label: 'BAKING & PASTRY',
    value: '3'
  },
  {
    label: 'PLANT-BASED CULINARY ARTS',
    value: '4'
  },
  {
    label: 'HOLISTIC NUTRITION & WELLNESS',
    value: '5'
  },
  {
    label: 'HOSPITALITY & RESTAURANT OPERATIONS MANAGEMENT',
    value: '6'
  }
];

const WorkLearnExplorePrograms: React.FC = () => {
  const [tabValue, setTabValue] = React.useState<string>('1');

  const handleChange = (event: React.SyntheticEvent, newVal: string): void => {
    setTabValue(newVal);
  };

  return (
    <Styled.Root maxWidth="xl" data-testid="work-learn-explore-programs-root">
      <TabContext value={tabValue}>
        <Typography variant="EC_TYPE_3XL" className="explore-section-title">
          EXPLORE ESCOFFIER&apos;S ACCREDITED PROGRAMS
        </Typography>
        <Styled.TabRoot>
          <Styled.TabNavWrapper>
            <Styled.TabWrapper>
              <Styled.TabList
                onChange={handleChange}
                aria-label="work & learn explor programs tabs"
                orientation="vertical"
                variant="fullWidth"
                data-testid="work-learn-explore-programs-tablist"
              >
                {tabs.map((tab) => (
                  <Styled.Tab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                  />
                ))}
              </Styled.TabList>
            </Styled.TabWrapper>
          </Styled.TabNavWrapper>
          {tabContent.map((item) => {
            const { value, ...rest } = item;

            return (
              <Styled.TabContent
                key={value}
                value={value}
                data-testid={`work-learn-explore-programs-content-${value}`}
              >
                <ImageWithOffsetCard {...rest} />
              </Styled.TabContent>
            );
          })}
        </Styled.TabRoot>
      </TabContext>
    </Styled.Root>
  );
};

export default WorkLearnExplorePrograms;
