import * as React from 'react';
import QuizIntroCard, { QuizIntroProps } from '@components/Quiz/QuizIntroCard';
import QuizQuestions from '@components/Quiz/QuizQuestions';

interface Props {
  quizStarted: boolean;
  quizIntroProps: QuizIntroProps;
}

const QuizController = (props: Props): React.ReactElement => {
  const { quizStarted, quizIntroProps } = props;

  return !quizStarted ? (
    <QuizIntroCard {...quizIntroProps} />
  ) : (
    <QuizQuestions />
  );
};

export default QuizController;
