/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import {
  Applicant,
  ApplicantsCsvReq,
  ApplicantsReq
} from '@api/models/adminDashboardApi.models';
import {
  Box,
  Collapse,
  IconButton,
  SelectChangeEvent,
  Table,
  TableBody,
  TableHead
} from '@mui/material';
import BasicModal from '@components/BasicModal';
import BottomPagination from '@components/BottomPagination';
import CandidateApplicationsTable from '@pages/EcAdmin/AdminDashboardPage/CandidateApplicationsTable';
import { CodebookCountInterface } from '@api/models/codebook.models';
import CsvButton from '@components/CsvButton';
import { DateRange } from '@common/models/common.models';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { SortOrderType } from '@interfaces/search.interfaces';
import StatusSelectionHeader from './StatusSelectionHeader';
import { Styled } from '@pages/EcAdmin/AdminDashboardPage/CandidateAccountsSection/StyledTabledComponents';
import theme from '@themes/ui/escoffier';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchApplicants } from '@common/fetches/useFetchApplicants';
import { useFetchApplicantsCsv } from '@common/fetches/csv/useFetchApplicantsCsv';

interface RowProps {
  applicant: Applicant;
}

const Row: React.FC<RowProps> = ({ applicant }) => {
  const [expand, setExpand] = React.useState(false);
  const { firstName, lastName, email, phone, totalApplications, createdDate } =
    applicant;

  const handleCandidateClick = (e): void => {
    e.stopPropagation();
    window.open(`/candidate/${applicant.id}/profile`, '_blank');
  };

  const cells = [
    <>
      <IconButton
        aria-label="expand row"
        sx={{ padding: '0px', marginRight: '5px' }}
      >
        {expand ? (
          <KeyboardArrowUpIcon sx={{ fontSize: '15px' }} />
        ) : (
          <KeyboardArrowDownIcon sx={{ fontSize: '15px' }} />
        )}
      </IconButton>
      <Box
        sx={{
          display: 'inline-block',
          '&:hover': {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            textDecoration: 'underline'
          }
        }}
        onClick={(e): void => handleCandidateClick(e)}
      >
        {firstName} {lastName}
      </Box>
    </>,
    email,
    phone,
    totalApplications,
    createdDate
  ];

  return (
    <>
      <Styled.ExpandableTableRow onClick={(): void => setExpand(!expand)}>
        {cells.map((cell, idx) => (
          <Styled.TableCell key={idx} bottomBorder={expand}>
            {cell}
          </Styled.TableCell>
        ))}
      </Styled.ExpandableTableRow>
      <Styled.ExpandableTableRow>
        <Styled.ExpandableCell colSpan={5} expanded={expand}>
          <Collapse in={expand} timeout="auto" unmountOnExit>
            <CandidateApplicationsTable applications={applicant.applications} />
          </Collapse>
        </Styled.ExpandableCell>
      </Styled.ExpandableTableRow>
    </>
  );
};

interface Props {
  open: boolean;
  status?: CodebookCountInterface | CodebookCountInterface[];
  companyId?: number;
  companyName?: string;
  handleClose: () => void;
  dateRange: DateRange;
  parentCompanyId?: number;
}

// TODO Could eventually refactor this to handle all Table Modals

const ApplicantsModal: React.FC<Props> = ({
  open,
  status,
  companyId,
  companyName,
  handleClose,
  dateRange,
  parentCompanyId
}) => {
  const defaultPageInfo = {
    rowsPerPage: 25,
    currentPage: 1,
    sortCategory: 'createdAt',
    sortOrder: 'DESC' as SortOrderType
  };

  const isStatusArr = Array.isArray(status);

  const getInitialStatus = (): CodebookCountInterface | undefined => {
    if (!status) {
      return undefined;
    } else if (isStatusArr) {
      return status.length > 0 ? status[0] : undefined;
    } else {
      return status;
    }
  };

  const [localStatus, setLocalStatus] = React.useState<
    CodebookCountInterface | undefined
  >(getInitialStatus());

  const [applicantsReq, setApplicantsReq] = React.useState<ApplicantsReq>({
    pageInfo: defaultPageInfo,
    ...dateRange,
    statusId: localStatus?.value
  });

  const [csvReq, setCsvReq] = React.useState<ApplicantsCsvReq>({
    ...dateRange,
    statusId: localStatus?.value
  });

  const featureFlags = useFeatureFlags();

  const { applicants, pageInfo } = useFetchApplicants(
    applicantsReq,
    companyId,
    parentCompanyId
  );

  const { fetchApplicantsCsv } = useFetchApplicantsCsv(
    csvReq,
    companyId,
    parentCompanyId
  );

  const handlePagination = (event, value: number): void => {
    const newPageInfo = { ...applicantsReq.pageInfo, currentPage: value };
    setApplicantsReq({ ...applicantsReq, pageInfo: newPageInfo });
  };

  const columns = ['Name', 'Email', 'Phone', 'Applications', 'Created'];

  const csvHeaders = [
    { label: 'Candidate Id', key: 'candidateId' },
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Email', key: 'email' },
    { label: 'Phone', key: 'phone' },
    { label: 'Created Date', key: 'createDate' },
    { label: 'Application Id', key: 'applicationId' },
    { label: 'Job Id', key: 'jobId' },
    { label: 'Job Title', key: 'jobTitle' },
    { label: 'Company Id', key: 'companyId' },
    { label: 'Company Name', key: 'companyName' },
    { label: 'Apply Date', key: 'applyDate' },
    { label: 'Status', key: 'status' },
    ...(featureFlags.ATS_WORKAROUND
      ? [{ label: 'External Link', key: 'externalApplicationStatus' }]
      : [])
  ];

  const getTitle = (): string | undefined => {
    if (isStatusArr) {
      return undefined;
    }

    const basePart = localStatus?.label || 'Applicants';
    const companyPart = companyName ? ` (${companyName})` : '';

    return `${basePart}${companyPart}`;
  };

  const companyPart = companyName ? `_${companyName}` : '';
  const statusPart = localStatus?.label ? `_${localStatus?.label}` : '';

  const csvFileName = `applicants${companyPart}${statusPart}.csv`;

  const handleStatusChange = (e: SelectChangeEvent<number>): void => {
    const newStatusValue = e.target.value;
    const newLocalStatus = isStatusArr
      ? status.find((s) => s.value === newStatusValue)
      : undefined;
    setLocalStatus(newLocalStatus);
    setApplicantsReq({ ...applicantsReq, statusId: newLocalStatus?.value });
    setCsvReq({ ...csvReq, statusId: newLocalStatus?.value });
  };

  return (
    <BasicModal
      title={getTitle()}
      titleMarginBottom="0px"
      open={open}
      handleClose={handleClose}
    >
      {applicants && pageInfo && (
        <>
          {isStatusArr && localStatus && (
            <StatusSelectionHeader
              statuses={status}
              selectedStatus={localStatus?.value}
              handleStatusChange={handleStatusChange}
            />
          )}
          {featureFlags.REPORTING_DASH && (
            <CsvButton
              headers={csvHeaders}
              fileName={csvFileName}
              fetchData={fetchApplicantsCsv}
            />
          )}
          <Styled.TableContainer>
            <Table stickyHeader aria-label="accounts table">
              <TableHead>
                <Styled.TableRow>
                  {columns.map((col) => (
                    <Styled.HeaderTableCell key={col}>
                      {col}
                    </Styled.HeaderTableCell>
                  ))}
                </Styled.TableRow>
              </TableHead>
              <TableBody>
                {applicants.map((applicant) => (
                  <Row key={applicant.id} applicant={applicant} />
                ))}
              </TableBody>
            </Table>
          </Styled.TableContainer>
          <BottomPagination
            count={pageInfo.totalPages}
            page={pageInfo.currentPage}
            onChange={handlePagination}
            defaultPage={1}
            wrapperSx={{
              marginTop: '35px'
            }}
          />
        </>
      )}
    </BasicModal>
  );
};

export default ApplicantsModal;
