import * as React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { Typography, useMediaQuery } from '@mui/material';
import DirectConnectContent from '@pages/EcAdmin/ContentManagementPage/DirectConnectContent/DirectConnectContent';
import EmployerSpotlightContent from '@pages/EcAdmin/ContentManagementPage/EmployerSpotlightContent/EmployerSpotlightContent';
import { GlrcVideo } from '@interfaces/glrc.interfaces';
import HotJobsContent from '@pages/EcAdmin/ContentManagementPage/HotJobsContent/HotJobsContent';
import ManageWorkAndLearnContent from '@pages/EcAdmin/ContentManagementPage/WorkAndLearnContent/ManageWorkAndLearnContent';
import { NavItem } from '@components/Layout/Header/Header.models';
import NestedLinkList from '@components/NestedLinkList';
import PageFormLayoutComponent from '@components/Layout/PageFormLayoutComponent';
import PremierPartnersContent from './PremierPartnersContent/PremierPartnersContent';
import PrivateRoute from '@common/utilities/PrivateRoute';
import { KeycloakUserRole } from '@api/models/userApi.models';
import theme from '@themes/ui/escoffier';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useFetchGlrcContentTopicsCodebook } from '@common/fetches/useFetchGlrcContentTopicsCodebook';
import { useFetchGlrcVideosForEcAdmin } from '@common/fetches/useFetchGlrcVideosForEcAdmin';
import { useToast } from '@components/Toast';
import VideoContent from '@pages/EcAdmin/ContentManagementPage/VideoContent/VideoContent';

export const contentManagementNavLinks = (): NavItem[] => {
  const featureFlags = useFeatureFlags();

  return [
    { label: 'Hot Jobs', path: '/hot-jobs' },
    { label: 'Employer Spotlight', path: '/employer-spotlight' },
    ...(featureFlags.DIRECT_CONNECT_MANAGEMENT
      ? [{ label: 'Direct Connect', path: '/direct-connect' }]
      : []),
    ...(featureFlags.PREMIER_PARTNERS_MANAGEMENT
      ? [{ label: 'Premier Partners', path: '/premier-partners' }]
      : []),
    ...(featureFlags.WORK_AND_LEARN_MANAGEMENT
      ? [{ label: 'Work & Learn', path: '/work-and-learn' }]
      : []),
    { label: 'ESource Content', path: '/esource-content' }
  ];
};

const ContentManagementPage: React.FC = () => {
  const { glrcVideos, setGlrcVideos } = useFetchGlrcVideosForEcAdmin();
  const { glrcContentTopics } = useFetchGlrcContentTopicsCodebook();
  const { ...toastProps } = useToast();

  const featureFlags = useFeatureFlags();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isLessThanHeaderBreakpoint = useMediaQuery(
    theme.breakpoints.down('headerMobile')
  );

  const { path } = useRouteMatch();

  // Used to maintain search value through re-renders
  const parentSearchVal = React.useRef<string>();
  const setParentSearchVal = (str?: string): void => {
    parentSearchVal.current = str;
  };

  const setParentGlrcVideos = React.useCallback(
    (videos: GlrcVideo[]) => {
      return setGlrcVideos(videos);
    },
    [setGlrcVideos]
  );

  const GlrcContent = (): JSX.Element => (
    <>
      {glrcVideos && (
        <VideoContent
          glrcVideos={glrcVideos}
          glrcContentTopics={glrcContentTopics}
          setParentVideos={setParentGlrcVideos}
          toastProps={toastProps}
          parentSearchVal={parentSearchVal.current}
          setParentSearchVal={setParentSearchVal}
        />
      )}
    </>
  );

  const Content = (
    <Switch>
      <PrivateRoute
        exact
        allowedRoles={[KeycloakUserRole.ESCOFFIER_ADMIN]}
        path={`${path}/hot-jobs`}
        component={HotJobsContent}
        title="Hot Jobs"
      />
      <PrivateRoute
        exact
        allowedRoles={[KeycloakUserRole.ESCOFFIER_ADMIN]}
        path={`${path}/employer-spotlight`}
        component={EmployerSpotlightContent}
        title="Employer Spotlight"
      />
      {featureFlags.DIRECT_CONNECT_MANAGEMENT && (
        <PrivateRoute
          exact
          allowedRoles={[KeycloakUserRole.ESCOFFIER_ADMIN]}
          path={`${path}/direct-connect`}
          component={DirectConnectContent}
          title="Direct Connect"
        />
      )}
      {featureFlags.PREMIER_PARTNERS_MANAGEMENT && (
        <PrivateRoute
          exact
          allowedRoles={[KeycloakUserRole.ESCOFFIER_ADMIN]}
          path={`${path}/premier-partners`}
          component={PremierPartnersContent}
          title="Premier Partners"
        />
      )}
      {featureFlags.WORK_AND_LEARN_MANAGEMENT && (
        <PrivateRoute
          exact
          allowedRoles={[KeycloakUserRole.ESCOFFIER_ADMIN]}
          path={`${path}/work-and-learn`}
          component={ManageWorkAndLearnContent}
          title="Work and Learn"
        />
      )}
      <PrivateRoute
        exact
        allowedRoles={[KeycloakUserRole.ESCOFFIER_ADMIN]}
        path={`${path}/esource-content`}
        component={GlrcContent}
        title="ESource Content"
      />
    </Switch>
  );

  return (
    <PageFormLayoutComponent
      hideSideContent={isLessThanHeaderBreakpoint}
      headerContent={
        <Typography
          variant="EC_TYPE_3XL"
          sx={{ margin: isSmallScreen ? '0px 16px' : 'unset' }}
        >
          Content Management
        </Typography>
      }
      sideContent={<NestedLinkList links={contentManagementNavLinks()} />}
      content={Content}
    />
  );
};

export default ContentManagementPage;
