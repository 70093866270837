import * as React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import {
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack,
  SxProps,
  Theme,
  Typography,
  TypographyProps
} from '@mui/material';
import FormFieldErrorText from '@components/Forms/FormFieldErrorText';
import InputCharCount from '@components/InputCharCount';
import { styled } from '@mui/system';
import { useGetReactHookFormErrorsByName } from '@common/helpers/useGetReactHookFormErrorsByName';

const Styled = {
  TextFieldContainer: styled(Stack, {
    shouldForwardProp: (prop) => prop !== 'fullWidth'
  })<{ fullWidth: boolean }>(({ fullWidth }) => ({
    ...(fullWidth && {
      width: '100%'
    }),
    position: 'relative',
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0
    }
  })),
  Label: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'required'
  })<{ required: boolean }>(({ required, theme }) => ({
    marginBottom: '11px',
    position: 'relative',
    ['@media (max-width:480px)']: {
      marginBottom: '5px',
      fontSize: (theme as Theme).typography.EC_TYPE_SM.fontSize,
      lineHeight: (theme as Theme).typography.EC_TYPE_SM.lineHeight
    },

    '&:after': {
      content: required ? '"*"' : '""',
      color: theme.palette.error.main,
      marginLeft: '4px'
    }
  })),
  SubLabel: styled(Typography)(({ theme }) => ({
    marginBottom: '16px',
    color: theme.palette.GRAY_3.main,
    fontSize: '13px'
  }))
};

interface Props {
  name: string;
  label?: string;
  subLabel?: string;
  autocomplete?: string;
  rules?: RegisterOptions;
  maxCharCount?: number;
  dataTestId?: string;
  icon?: JSX.Element;
  placeholder?: string;
  rows?: number;
  tooltip?: JSX.Element;
  disabled?: boolean;
  disableRequiredAsterisk?: boolean;
  labelVariant?: TypographyProps['variant'];
  inputStyles?: SxProps<Theme>;
  labelStyles?: any;
  inputType?: React.HTMLInputTypeAttribute;
  fullWidth?: boolean;
  useFieldArrayNaming?: boolean;
  fieldNamePrefix?: string;
  disableErrorAbsolutePosition?: boolean;
  multiline?: boolean;
  handleBlur?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => void;
  handleFocus?: () => void;
  mask?: (val: string) => string;
  handleKeyDown?: (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const ReactHookFormsInput: React.FC<Props> = ({
  label,
  subLabel: subLabel,
  name,
  autocomplete,
  rules,
  maxCharCount,
  dataTestId,
  icon,
  placeholder,
  tooltip = undefined,
  disabled = false,
  disableRequiredAsterisk = false,
  labelVariant = 'EC_TYPE_BASE',
  fullWidth = false,
  inputStyles,
  inputType,
  useFieldArrayNaming = false,
  fieldNamePrefix,
  disableErrorAbsolutePosition = false,
  multiline = false,
  labelStyles,
  handleBlur,
  handleFocus,
  handleKeyDown,
  mask
}) => {
  const [charCount, setCharCount] = React.useState<number>(0);

  const {
    control,
    formState: { errors }
  } = useFormContext();

  const handleCharCount = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setCharCount(event.target.value.length);
  };

  const { errorsAtName } = useGetReactHookFormErrorsByName(
    name,
    errors,
    useFieldArrayNaming,
    fieldNamePrefix
  );

  return (
    <Styled.TextFieldContainer id={name} fullWidth={fullWidth}>
      {(label || tooltip) && (
        <Stack
          direction="row"
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Styled.Label
            data-testid={`${name}-input-label`}
            variant={labelVariant}
            sx={labelStyles}
            required={!!rules?.required && !disableRequiredAsterisk}
          >
            {label}
          </Styled.Label>
          {tooltip ?? null}
        </Stack>
      )}
      {subLabel && (
        <Styled.SubLabel variant="EC_TYPE_SM">{subLabel}</Styled.SubLabel>
      )}
      <Controller
        name={name}
        control={control}
        rules={{ ...rules }}
        render={({ field }): JSX.Element => {
          return (
            <OutlinedInput
              {...field}
              value={field.value ?? ''}
              type={inputType}
              multiline={multiline}
              sx={{
                ...inputStyles,
                ...(multiline && { height: 'auto', padding: 0 })
              }}
              error={!!errorsAtName}
              autoComplete={autocomplete}
              placeholder={placeholder}
              disabled={disabled}
              startAdornment={
                icon ? (
                  <InputAdornment
                    position="start"
                    sx={{ backgroundColor: 'white' }}
                  >
                    {icon}
                  </InputAdornment>
                ) : null
              }
              inputProps={{
                'data-testid': dataTestId,
                maxLength: maxCharCount && maxCharCount
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                if (mask) {
                  event.target.value = mask(event.target.value);
                }
                maxCharCount && handleCharCount(event);
                field.onChange(event);
              }}
              onBlur={(e): void => {
                handleBlur && handleBlur(e);
                field.onBlur();
              }}
              onFocus={(): void => {
                handleFocus && handleFocus();
              }}
              onKeyDown={(e): void => {
                handleKeyDown && handleKeyDown(e);
              }}
            />
          );
        }}
      />
      <Grid container justifyContent="space-between" columnSpacing={2}>
        <Grid item>
          {errorsAtName && (
            <FormFieldErrorText
              name={name}
              message={errorsAtName.message}
              disableAbsolutePosition={disableErrorAbsolutePosition}
            />
          )}
        </Grid>
        <Grid item>
          {maxCharCount && (
            <InputCharCount charCount={charCount} maxCharCount={maxCharCount} />
          )}
        </Grid>
      </Grid>
    </Styled.TextFieldContainer>
  );
};

export default ReactHookFormsInput;
