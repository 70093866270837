import {
  BadgeCodebook,
  Codebook,
  SkillCodebook
} from '@api/models/codebook.models';
import { CompanyProfile } from '@api/models/companyApi.models';
import { Location } from '@interfaces/location.interfaces';

export enum JobPostType {
  REGULAR = 0,
  DIRECT_CONNECT = 4
}

export interface JobPostBase {
  id: number;
  jobTitle: string;
  jobType: string[];
  jobPostType: JobPostType;
  locations: Location[];
  workLearnEligible: string;
  expirationDate: string | null;
  keepOpen: boolean;
  compensationType: string[];
  compensationDetails: string;
  jobCardDescription: string;
  jobDescription: string;
  healthWellnessBenefits: string[];
  retirementBenefits: string[];
  professionalDevBenefits: string[];
  experienceLevel: string;
  company: CompanyProfile;
  status: number;
  visibleOnMarketplace: boolean;
  createdAt: string;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  threeKeyBenefits: string[];
  externalJobPost?: string;
}

export interface JobPost extends JobPostBase {
  skills: SkillCodebook[];
  technicalSkills: SkillCodebook[];
  softSkills: SkillCodebook[];
  jobSegments: Codebook[];
  jobSpecialties: Codebook[];
}

// prettier-ignore
export interface JobCard
  extends Pick<
  JobPost,
  | 'id'
  | 'jobTitle'
  | 'locations'
  | 'threeKeyBenefits'
  | 'jobCardDescription'
  | 'workLearnEligible'
  | 'jobPostType'
  > {
  companyName: CompanyProfile['name'];
  companyLogo: string | undefined;
  companyBadge?: BadgeCodebook;
}

// prettier-ignore
export interface JobPostCreateReq
  extends Omit<
  JobPost,
  'id' | 'createdAt' | 'createdBy' | 'expirationDate' | 'updatedAt' | 'updatedBy' | 'status' | 'company' | 'skills' | 'technicalSkills' | 'softSkills' | 'jobSegments' | 'jobSpecialties' | 'jobPostType'
  > {
  companyId: number;
  expirationDate: Date | null;
  technicalSkills: number[];
  softSkills: number[];
  jobSegments:number[];
  jobSpecialties:number[];
}

// prettier-ignore
export interface DashboardJobPost
  extends Pick<
  JobPost,
  | 'id'
  | 'jobTitle'
  | 'workLearnEligible'
  | 'createdAt'
  | 'visibleOnMarketplace'
  | 'jobPostType'
  > {
  locations: string[];
  candidates: number;
}

export const getJobCardProps = (jobPosts: JobPost[]): JobCard[] => {
  return (
    jobPosts?.map((job) => ({
      id: job.id,
      jobTitle: job.jobTitle,
      locations: job.locations,
      threeKeyBenefits: job.threeKeyBenefits,
      jobCardDescription: job.jobCardDescription,
      workLearnEligible: job.workLearnEligible,
      companyName: job.company.name,
      companyLogo: job.company?.logo?.fullPath,
      companyBadge: job.company?.badge,
      jobPostType: job.jobPostType
    })) || []
  );
};

export interface PromotedJob {
  id: number;
  title: string;
  location: string;
  company: string;
  offersWorkAndLearn?: string;
  logo?: string;
}

export interface ExternalAdzunaJob {
  id: string;
  jobTitle: string;
  description: string;
  location: string;
  companyName: string;
  redirectUrl: string;
}

export interface ExternalJobsResponse {
  jobs: ExternalAdzunaJob[];
  pageInfo: {
    count: number;
    currentPage: number;
    totalPages: number;
    filtersApplied: {
      location?: { displayName: string; distance?: number };
      jobType?: string;
    };
    startRow: number;
    endRow: number;
    rowsPerPage: number;
  };
}
