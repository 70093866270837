import { useEffect, useState } from 'react';
import { SettingsApi } from '@api/Settings.api';
import { UserIdp } from '@api/models/userApi.models';

interface FetchUserIdpData {
  userIdp: UserIdp[] | null;
  loading: boolean;
  loaded: boolean | null;
}

/**
 * fetch user IDP data from their keycloak userId. userIdp will be null if the user
 * did not sign up using an IDP.
 * @param { string } userId
 * @returns userIdp data if the user created an account using an IDP. Null if not
 */
export const useFetchUserIdpData = (userId: string): FetchUserIdpData => {
  const [userIdp, setUserIdp] = useState<UserIdp[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState<boolean | null>(null);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        setLoading(true);
        const res = await SettingsApi.getUserIdp(userId);

        if (res.data) {
          setUserIdp(res.data);
        }
      } catch (error: any) {
        console.error(
          'Error for useFetchUserIdpData -> SettingsApi.getUserIdp()',
          error
        );
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    };

    fetchData();
  }, []);

  return { userIdp, loading, loaded };
};
