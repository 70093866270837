import * as React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Icon,
  Typography,
  useTheme
} from '@mui/material';
import {
  FAILED_TIMESHEET_STATUSES,
  LmsRespone,
  Timesheet,
  TimesheetPerformanceItem,
  TimesheetStatus
} from '@api/models/externshipApi.models';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatDate } from '@common/helpers/dateHelpers/formatDate';
import { getTimesheetStatusLabel } from '@common/helpers/externshipHelpers/getTimesheetStatusLabel';
import { getTimesheetTotalAsHoursAndMins } from '@common/helpers/timesheetHelpers/getTimesheetTotalAsHoursAndMins';
import { styled } from '@mui/system';
import TimesheetAccordionContent from '@pages/Shared/TimesheetPage/TimesheetAccordionContent';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { ExternshipApi } from '@api/Externships.api';

const Styled = {
  Accordion: styled(Accordion)({
    border: 'none',
    '&:before': {
      display: 'none'
    }
  }),
  AccordionSummary: styled(AccordionSummary)({
    minHeight: 'unset',
    flexDirection: 'row-reverse',
    padding: '16px 16px 0px 0px',
    '.MuiAccordionSummary-content': {
      marginLeft: '8px'
    },
    '.MuiAccordionSummary-content.Mui-expanded': {
      marginLeft: '8px',
      paddingBottom: '16px',
      paddingTop: '16px'
    }
  }),
  AccordionSummaryContent: styled(Box)({
    display: 'flex',
    flexWrap: 'wrap'
  }),
  AccordionDetails: styled(AccordionDetails)({
    padding: '0px 16px 6px 32px'
  }),
  StatusIcon: styled(Icon, {
    shouldForwardProp: (prop) => prop !== 'iconColor'
  })<{ iconColor?: string }>(({ theme, iconColor }) => ({
    ...(iconColor ? { color: theme.palette[iconColor].main } : {}),
    fontSize: '16px',
    lineHeight: '18px',
    marginRight: '2px'
  })),
  ErrorIcon: styled(Icon)({
    fontSize: '16px',
    lineHeight: '18px'
  }),
  SummaryItem: styled(Typography)({
    textTransform: 'uppercase'
  }),
  SummaryItemValue: styled(Box)({
    textTransform: 'none'
  }),
  Pipe: styled(Box)({
    margin: '0px 24px',
    display: 'inline-block'
  })
};

interface TimesheetIcon {
  icon?: string;
  iconColor?: string;
}

interface Props {
  timesheet: Timesheet;
  updateTimesheets: (timesheet: Timesheet) => void;
  isApproverViewing: boolean;
  isCandidateViewing: boolean;
  careerServiceView?: boolean;
  externshipComplete?: boolean;
}

const TimesheetAccordion: React.FC<Props> = ({
  timesheet,
  updateTimesheets,
  isApproverViewing,
  isCandidateViewing,
  careerServiceView = false,
  externshipComplete = false
}) => {
  const [timesheetPerformanceItems, setTimesheetPerformanceItems] = React.useState<TimesheetPerformanceItem[]>([]);

  const theme = useTheme();
  const {
    isEcAdmin,
    isChefInstructor,
    isEcCareerService,
    isCandidate,
    isEmployer,
    isRecruiter
  } = useKeycloakContext();

  React.useEffect(() => {
    const fetchTimesheetPerformanceQuestions = async (): Promise<void> => {
      try {
        const res = await ExternshipApi.getTimesheetPerformanceReviewQuestions();
        setTimesheetPerformanceItems(res);
      } catch (error: any) {
        console.error(
          'Error for useFetchTimesheetPerformanceQuestions -> ExternshipApi.getTimesheetPerformanceReviewQuestions()',
          error
        );
      }
    };
    if (isApproverViewing && !timesheetPerformanceItems.length) {
      fetchTimesheetPerformanceQuestions();
    }
  }, [isApproverViewing]);

  const { status, dailyEntries } = timesheet;

  const [totalHours, setTotalHours] = React.useState(
    getTimesheetTotalAsHoursAndMins(dailyEntries)
  );

  const lmsErrorResponses = [LmsRespone.FAIL, LmsRespone.UNKNOWN];

  const yellowCheckStatuses = [
    ...(isChefInstructor || isEcAdmin || isEcCareerService
      ? FAILED_TIMESHEET_STATUSES
      : [])
  ];

  const greenCheckStatuses = [
    TimesheetStatus.ACCEPTED,
    TimesheetStatus.SENT_SUCCESSFULLY,
    ...(!isChefInstructor && !isEcAdmin && !isEcCareerService
      ? FAILED_TIMESHEET_STATUSES
      : [])
  ];

  const redXStatuses = [
    TimesheetStatus.REJECTED_BY_INSTRUCTOR,
    TimesheetStatus.REJECTED_BY_SUPERVISOR
  ];

  const getIcon = (): TimesheetIcon => {
    const icon: TimesheetIcon = {};

    if (greenCheckStatuses.includes(status)) {
      icon.icon = 'ri-checkbox-circle-fill';
      icon.iconColor = 'GREEN_1';
    }

    if (redXStatuses.includes(status)) {
      icon.icon = 'ri-close-circle-fill';
      icon.iconColor = 'primary';
    }

    if (yellowCheckStatuses.includes(status)) {
      icon.icon = 'ri-error-warning-fill';
      icon.iconColor = 'ORANGE_1';
    }

    return icon;
  };

  const displayErrMsg =
    isEcAdmin && lmsErrorResponses.some((r) => r === timesheet.lmsResponse);

  const displayApprovalIcon =
    isChefInstructor && status === TimesheetStatus.PENDING_INSTRUCTOR_APPROVAL;

  const hideCVueError = isCandidate || isEmployer || isRecruiter;

  const timesheetSummary = React.useMemo((): {
    label: string;
    value: string | number;
    icon?: string;
    iconColor?: string;
    valueColor?: string;
    valueFontWeight?: number;
  }[] => {
    const firstDate = dailyEntries.length
      ? dailyEntries[0].date
      : timesheet.weekStartDate;

    const summary = [
      {
        label: 'Week Starting',
        value: formatDate(new Date(firstDate).toISOString(), 'MM/dd/yyyy')
      },
      {
        label: 'Total Hours',
        value: totalHours
      },
      {
        label: 'Status',
        value: getTimesheetStatusLabel(status, isEcAdmin, !hideCVueError),
        ...(displayApprovalIcon && {
          valueColor: theme.palette.primary.main,
          valueFontWeight: 800
        }),
        ...getIcon()
      }
    ];

    return summary;
  }, [timesheet, totalHours]);

  const displayPipe = React.useCallback(
    (idx: number) => idx !== timesheetSummary.length - 1,
    [timesheet.dailyEntries]
  );

  const SummaryItem: React.FC<{
    label: string;
    value: string | number;
    icon?: string;
    iconColor?: string;
    valueColor?: string;
    valueFontWeight?: number;
    idx: number;
  }> = ({
    label,
    value,
    icon,
    iconColor,
    valueColor,
    valueFontWeight = 400,
    idx
  }) => {
    return (
      <Styled.SummaryItem variant="EC_TYPE_LG">
        {label}:{' '}
        {icon && <Styled.StatusIcon className={icon} iconColor={iconColor} />}
        <Styled.SummaryItemValue
          component={'span'}
          color={valueColor}
          fontWeight={valueFontWeight}
        >
          {value}
        </Styled.SummaryItemValue>
        {displayPipe(idx) && <Styled.Pipe component={'span'}>|</Styled.Pipe>}
      </Styled.SummaryItem>
    );
  };

  return (
    <Styled.Accordion sx={{ backgroundColor: 'transparent' }}>
      <Styled.AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box>
          <Styled.AccordionSummaryContent>
            {timesheetSummary.map((s, idx) => (
              <SummaryItem
                key={s.label}
                value={s.value}
                label={s.label}
                icon={s.icon}
                iconColor={s.iconColor}
                idx={idx}
                valueColor={s.valueColor}
                valueFontWeight={s.valueFontWeight}
              />
            ))}
          </Styled.AccordionSummaryContent>
          {displayErrMsg && (
            <Box>
              <Styled.ErrorIcon
                className="ri-error-warning-line"
                color="primary"
              />
              <Typography
                variant="EC_TYPE_BASE"
                component={'span'}
                color="primary"
                ml={'4px'}
              >
                {timesheet.lmsErrorText || 'There was an error'}
              </Typography>
            </Box>
          )}
        </Box>
      </Styled.AccordionSummary>
      <Styled.AccordionDetails>
        <TimesheetAccordionContent
          timesheet={timesheet}
          updateTimesheets={updateTimesheets}
          setTotalHours={setTotalHours}
          isApproverViewing={isApproverViewing}
          isCandidateViewing={isCandidateViewing}
          timesheetPerformanceItems={timesheetPerformanceItems}
          careerServiceView={careerServiceView}
          externshipComplete={externshipComplete}
        />
      </Styled.AccordionDetails>
    </Styled.Accordion>
  );
};

export default TimesheetAccordion;
