import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { CandidateContentProps } from '@pages/Candidate/candidatePreviewPageComponentProps';
import { styled } from '@mui/system';

const Styled = {
  PreviewPageContent: styled(Box)({
    marginTop: '31px',
    '& hr': {
      margin: '5px 0px 30px 0'
    }
  }),
  List: styled('ul')({
    margin: '5px 0px 30px 0px'
  })
};

interface PreviewPageContentProps {
  statement: string;
  isCandidatePage: boolean;
  candidateProps?: CandidateContentProps;
}

const PreviewPageContent: React.FC<PreviewPageContentProps> = (
  props
): JSX.Element => {
  const { candidateProps, isCandidatePage, statement } = props;

  const getEducationText = (educationItem): string => {
    return `${educationItem.educationType}, ${educationItem.institution}, ${educationItem.graduationYear}`;
  };

  const hasCertificates = !!candidateProps?.certificates.length;

  return (
    <>
      <Styled.PreviewPageContent data-testid="jobPostContentContainer">
        <div className="topContent">
          {isCandidatePage && (
            <Grid container spacing={3}>
              {!!candidateProps?.education?.length && (
                <Grid item xs={12} md={6}>
                  <Typography variant="EC_TYPE_SM">Education</Typography>
                  <Styled.List>
                    {candidateProps.education.map((item) => {
                      return (
                        <li key={item.id}>
                          {getEducationText(item)}
                          {item.areaOfFocus && (
                            <ul>
                              <li>{item.areaOfFocus}</li>
                            </ul>
                          )}
                        </li>
                      );
                    })}
                  </Styled.List>
                </Grid>
              )}

              {!!candidateProps?.education.length && !hasCertificates && (
                <Grid item md={6} display={{ xs: 'none', md: 'block' }} />
              )}

              {hasCertificates && (
                <Grid item xs={12} md={6}>
                  <Typography variant="EC_TYPE_SM">Certifications</Typography>
                  <Styled.List>
                    {candidateProps.certificates.map((item) => {
                      return <li key={item.value}>{item.label}</li>;
                    })}
                  </Styled.List>
                </Grid>
              )}

              {!candidateProps?.education.length && hasCertificates && (
                <Grid item md={6} display={{ xs: 'none', md: 'block' }} />
              )}

              <Grid item xs={12} md={6}>
                <Typography variant="EC_TYPE_SM">
                  Looking for work in:
                </Typography>
                <Styled.List>
                  {candidateProps?.segments?.map((item, index) => {
                    return <li key={index}>{item.label}</li>;
                  })}
                </Styled.List>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="EC_TYPE_SM">Specialties: </Typography>
                <Styled.List>
                  {candidateProps?.specialties?.map((item, index) => {
                    return <li key={index}>{item.label}</li>;
                  })}
                </Styled.List>
              </Grid>
            </Grid>
          )}
          {isCandidatePage && <hr />}
          <Typography
            dangerouslySetInnerHTML={{
              __html: statement
            }}
          />
        </div>
      </Styled.PreviewPageContent>
    </>
  );
};

export default PreviewPageContent;
