/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';

const Styled = {
  ErrorText: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'disableAbsolute'
  })<{ disableAbsolute: boolean }>(({ disableAbsolute, theme }) => ({
    color: theme.palette.error.main,
    ...(disableAbsolute
      ? {
          marginTop: '5px'
        }
      : {
          position: 'absolute',
          bottom: '-16px'
        })
  }))
};

interface Props {
  message: string;
  name: string;
  disableAbsolutePosition?: boolean;
}

const FormFieldErrorText: React.FC<Props> = ({
  message,
  name,
  disableAbsolutePosition = false
}) => {
  return (
    <Styled.ErrorText
      variant="EC_TYPE_2XS"
      disableAbsolute={disableAbsolutePosition}
      data-testid={`${name}-error-message`}
    >
      {message}
    </Styled.ErrorText>
  );
};

export default FormFieldErrorText;
