/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import BatchActionModalLayout, {
  SelectedRowEnrollmentEntity
} from '@components/Layout/BatchActionModalLayout';
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import { AssignCareerServicesUserToStudentsPutReq } from '@api/models/careerServicesApi.models';
import { CareerServicesApi } from '@api/CareerService.api';
import { styled } from '@mui/system';
import { useBatchActionsContext } from '@common/context/batchActionsContext';
import { useFetchCareerServicesUsers } from '@common/fetches/useFetchCareerServicesUsers';

const Styled = {
  AssignToLabel: styled(Typography)(({ theme }) => ({
    marginBottom: '5px',
    textTransform: 'uppercase',
    color: theme.palette.grey4.main
  })),
  Select: styled(Select)(({ theme }) => ({
    '.MuiSelect-select': {
      color: theme.palette.GRAY_3.main
    }
  }))
};

interface AssignToSelection {
  id: string;
  name: string;
}

interface Props {
  open: boolean;
}

const AssignModal: React.FC<Props> = ({ open }) => {
  const { handleCloseModal, updateDataGridData } = useBatchActionsContext();

  const [assignToSelection, setAssignToSelection] =
    React.useState<AssignToSelection>({
      id: '',
      name: ''
    });
  const [selectedRows, setSelectedRows] = React.useState<
    SelectedRowEnrollmentEntity[]
  >([]);
  const [assigning, setAssigning] = React.useState(false);

  const { careerServicesUsers } = useFetchCareerServicesUsers();

  React.useEffect(() => {
    if (careerServicesUsers.length) {
      setAssignToSelection({
        id: careerServicesUsers[0].id,
        name: `${careerServicesUsers[0].firstName} ${careerServicesUsers[0].lastName}`
      });
    }
  }, [careerServicesUsers]);

  const handleAssignToSelectioin = (e: SelectChangeEvent<any>): void => {
    const csUser = careerServicesUsers.find(
      (user) => user.id === e.target.value
    );
    if (csUser)
      setAssignToSelection({
        id: csUser.id,
        name: `${csUser.firstName} ${csUser.lastName}`
      });
  };

  const handleAssignBtn = async (): Promise<void> => {
    const postBody: AssignCareerServicesUserToStudentsPutReq = {
      csUserId: assignToSelection.id,
      studentIds: selectedRows.map((row) => row.studentId)
    };

    try {
      setAssigning(true);
      const res = await CareerServicesApi.assignCareerServicesUserToStudents(
        postBody
      );

      updateDataGridData(res);
    } catch (error) {
      console.error('Error: CareerServicesDashboardPage.handleAssignBtn()');
    } finally {
      handleCloseModal();
      setAssigning(false);
    }
  };

  return (
    <BatchActionModalLayout
      open={open}
      modalTitle="Assign"
      modalSubtext="Assign the new owner of the selected student(s)"
      primaryBtnText="Assign"
      maxTagsToDisplay={27}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      handleSubmitClick={handleAssignBtn}
      handleClose={handleCloseModal}
      loading={assigning}
      toastMessage={`Selected student(s) assigned to ${assignToSelection.name}`}
    >
      <Box display="grid">
        <Styled.AssignToLabel variant="EC_TYPE_XS">
          Assign To
        </Styled.AssignToLabel>

        <Styled.Select
          value={assignToSelection.id}
          onChange={(e: SelectChangeEvent<any>): void =>
            handleAssignToSelectioin(e)
          }
        >
          {careerServicesUsers.map((u) => (
            <MenuItem key={u.id} value={u.id}>
              {u.firstName} {u.lastName}
            </MenuItem>
          ))}
        </Styled.Select>
      </Box>
    </BatchActionModalLayout>
  );
};

export default AssignModal;
