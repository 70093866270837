import * as React from 'react';
import { Box, Typography } from '@mui/material';
import CtaButton from '@components/CtaButton';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { styled } from '@mui/system';
import theme from '@themes/ui/escoffier';
import { useResponsiveLayoutContext } from '@components/Layout/ResponsiveLayout';

const MEDIA_QUERY = '@media (min-width:650px)';

const Styled = {
  Root: styled(Box)({
    position: 'relative',
    maxWidth: '1142px',
    height: '100%',
    margin: '0 auto',
    '& .subtitle': {
      fontSize: '12px',
      lineHeight: '16px',
      marginBottom: '20px',
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: '0em',
      color: theme.palette.GRAY_4.main,
      [MEDIA_QUERY]: {
        fontSize: '16px',
        lineHeight: '24px',
        marginBottom: '36px'
      }
    }
  }),
  TitleDiv: styled(Box)({
    display: 'flex',
    alignItems: 'start',
    marginBottom: '24px',
    flexFlow: 'column wrap',
    [MEDIA_QUERY]: {
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    '& .title': {
      textTransform: 'uppercase',
      fontFamily: 'trade-gothic-next-condensed',
      fontWeight: 700,
      fontStyle: 'normal',
      letterSpacing: '0.03em',
      color: theme.palette.red2.main,
      fontSize: '22px',
      lineHeight: '30px',
      marginBottom: '16px',
      marginRight: '24px',
      [MEDIA_QUERY]: {
        fontSize: '45px',
        lineHeight: '56px'
      }
    }
  })
};

interface Props {
  quizId: number;
  pageTitle: string;
  pageSubtitle: string;
}

const QuizResultsLayout: React.FC<Props> = ({
  quizId,
  pageTitle,
  pageSubtitle,
  children
}) => {
  const { customBreakpointReached: smallScreen } = useResponsiveLayoutContext();

  return (
    <RootPageLayout
      muiMaxWidth={false}
      bgColor={!smallScreen ? theme.palette.GRAY_1.main : '#FFFFFF'}
    >
      <Styled.Root data-testid="quiz-results-layout-root">
        <Styled.TitleDiv>
          <Typography data-testid="quiz-results-layout-title" className="title">
            {pageTitle}
          </Typography>
          <CtaButton
            label="Retake quiz"
            to={`/quiz/${quizId}`}
            routeState={{ beginQuiz: true }}
            variant="text"
            textColor={theme.palette.GOLD_1.main}
            color="GOLD_1"
            startIcon={<i className="ri-arrow-left-line" />}
            sx={{ flexShrink: 0 }}
            btnSx={{ width: 'max-content', padding: 0 }}
          />
        </Styled.TitleDiv>
        <Typography
          data-testid="quiz-results-layout-subtitle"
          className="subtitle"
        >
          {pageSubtitle}
        </Typography>
        {children}
      </Styled.Root>
    </RootPageLayout>
  );
};

export default QuizResultsLayout;
