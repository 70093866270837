import * as React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import CompanyDefaultLogo from '@assets/svg/employer-def.svg';
import { CompanyProfile } from '@api/models/companyApi.models';
import { ReactComponent as CrownIcon } from '@assets/resumeIcons/crown.svg';
import ImageWithBadge from './ImageWithBadge';
import { styled } from '@mui/system';

const Styled = {
  Root: styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    columnGap: '16px',
    rowGap: '32px',

    flexWrap: 'wrap-reverse',
    [theme.breakpoints.down('sm')]: {
      margin: '0px 24px'
    }
  })),
  CompanyWrapper: styled(Box)({
    display: 'flex',
    columnGap: '16px',
    alignItems: 'center',
    img: {
      objectFit: 'contain',
      padding: '8px'
    },
    marginBottom: '8px'
  }),
  CompanyName: styled(Typography)(({ theme }) => ({
    marginRight: '8px',
    marginBottom: '6px',
    fontSize: '36px',
    color: theme.palette.GOLD_1.main
  })),
  CrownWithLines: styled(Box)({
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  Line: styled(Box)(({ theme }) => ({
    height: '1px',
    backgroundColor: theme.palette.GRAY_2.main,
    opacity: '50%',
    flexGrow: 1
  })),
  CrownWrapper: styled(Box)(({ theme }) => ({
    margin: '0px 25px',
    svg: {
      height: '55px',
      width: '100px',
      path: { fill: theme.palette.GRAY_2.main, fillOpacity: '50%' }
    }
  }))
};

interface Props {
  company?: CompanyProfile | null;
}

const CompanyLogoHeader: React.FC<Props> = ({ company }) => {
  return (
    <Styled.Root>
      <Styled.CompanyWrapper>
        {company ? (
          <>
            <ImageWithBadge
              src={company.logo?.fullPath || CompanyDefaultLogo}
              height="100px"
              width="160px"
            />
            <Styled.CompanyName variant="EC_TYPE_3XL">
              {company.name}
            </Styled.CompanyName>
          </>
        ) : (
          <>
            <Skeleton variant="rectangular" height={100} width={160} />
            <Styled.CompanyName variant="EC_TYPE_3XL">
              <Skeleton variant="rectangular" width={100} height={40} />
            </Styled.CompanyName>
          </>
        )}
      </Styled.CompanyWrapper>
      <Styled.CrownWithLines>
        <Styled.Line />
        <Styled.CrownWrapper>
          <CrownIcon />
        </Styled.CrownWrapper>
        <Styled.Line />
      </Styled.CrownWithLines>
    </Styled.Root>
  );
};

export default CompanyLogoHeader;
