import * as React from 'react';
import { BadgeMapping, getBadge } from '@common/mappings/BadgeMappings';
import { Box, Grid, Theme, Typography, useTheme } from '@mui/material';
import { getJobCardProps, JobPost } from '@api/models/singleJobPostApi.models';
import { BadgeCodebook } from '@api/models/codebook.models';
import BottomPagination from '@components/BottomPagination';
import EmployerProfileJobPostsSkeleton from '@components/Skeletons/EmployerProfileJobPostsSkeleton';
import { FetchSearchResults } from '@api/models/searchApi.models';
import JobMarketplaceCard from '@components/JobMarketplace/JobMarketplaceCard/JobMarketplaceCard';
import RootPageLayout from '@components/Layout/RootPageLayout';
import { styled } from '@mui/system';

const BREAKPOINT = 768;

const Styled = {
  Content: styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: '975px',
    margin: '0 auto',
    '& .showing-jobs': {
      color: theme.palette.GRAY_3.main,
      margin: '18px 0'
    }
  })),
  CardWrapper: styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '20px',
    [`@media (max-width:${BREAKPOINT}px)`]: {
      flexDirection: 'column',
      alignContent: 'center'
    }
  }),
  SectionTitle: styled(Typography)(({ theme }) => ({
    fontSize: (theme as Theme).typography.EC_TYPE_4XL.fontSize,
    color: theme.palette.BLACK.main
  }))
};

const EmployerProfileJobPosts = (
  props: FetchSearchResults<JobPost>
): React.ReactElement => {
  const theme = useTheme();
  const { searchData: jobPosts, pageInfo, goToPage, loading } = props;
  const jobCards = getJobCardProps(jobPosts);
  const paginationDisabled = !pageInfo ? true : pageInfo.totalPages <= 1;

  const handlePageChange = (event, newPage: number): void => {
    goToPage(newPage);
  };

  const Content: JSX.Element =
    !loading && jobPosts?.length < 1 ? (
      <Box>
        <Typography variant="EC_TYPE_BASE">
          This employer has not created any job posts
        </Typography>
      </Box>
    ) : (
      <>
        <Typography
          variant="EC_TYPE_SM"
          className="showing-jobs"
          data-testid="job-count-display"
        >
          Showing {pageInfo?.currentPageStartRow} -{' '}
          {pageInfo?.currentPageEndRow} of {pageInfo?.filteredRows} Posted Jobs
        </Typography>
        <Grid container spacing={2} data-testid="card-wrapper">
          {jobCards.map((jobCard, idx) => {
            const badgeMap = BadgeMapping(
              jobCard.companyBadge as BadgeCodebook
            );
            const badge = getBadge(badgeMap, jobCard.companyBadge?.value);
            return (
              <Grid item key={idx} lg={4} md={6} sm={6} xs={12}>
                <JobMarketplaceCard {...jobCard} badge={badge} useBoxShadow />
              </Grid>
            );
          })}
        </Grid>
        <BottomPagination
          count={pageInfo?.totalPages}
          page={pageInfo?.currentPage}
          onChange={handlePageChange}
          disabled={paginationDisabled}
          wrapperSx={{ marginTop: '54px' }}
        />
      </>
    );

  return (
    <RootPageLayout bgColor={theme.palette.GRAY_F4.main} muiMaxWidth={false}>
      <Styled.Content data-testid="job-posts-content-container">
        <Styled.SectionTitle variant="EC_TYPE_3XL">
          Posted Jobs
        </Styled.SectionTitle>
        {loading ? <EmployerProfileJobPostsSkeleton /> : Content}
      </Styled.Content>
    </RootPageLayout>
  );
};

export default EmployerProfileJobPosts;
