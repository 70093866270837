import * as React from 'react';
import { Box, Skeleton } from '@mui/material';

const ManageCompaniesSkeleton = (): React.ReactElement => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
      <Skeleton
        width={20}
        height={20}
        sx={{ transform: 'none', marginBottom: '10px' }}
      />
      <Skeleton height={350} width="100%" sx={{ transform: 'none' }} />
    </Box>
  );
};

export default ManageCompaniesSkeleton;
