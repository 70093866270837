import {
  CampusJobtitles,
  CourseData,
  EnrollmentResponse,
  StudentResponse
} from '@api/models/enrollmentApi.models';
import { api } from '@api/configs/base';
import { apiConfig } from '@api/configs/api.config';
import { ApiResponse } from '@api/models/base.models';

export class EnrollmentApi {
  static path = apiConfig.externshipsUrl;

  static async getStudentEnrollments(
    studentId: string
  ): Promise<ApiResponse<StudentResponse>> {
    try {
      const response = await api.get<ApiResponse<StudentResponse>>(
        `${this.path}/student/${studentId}/enrollments`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for EnrollmentApi.getStudentInfo(): ${error}`);
      throw error;
    }
  }

  static async getStudentCourseSchedule(
    enrollmentId: number,
    campusStudentId: number
  ): Promise<ApiResponse<EnrollmentResponse>> {
    try {
      const response = await api.get<ApiResponse<EnrollmentResponse>>(
        `${this.path}/enrollments/${enrollmentId}/students/${campusStudentId}`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for EnrollmentApi.getStudentEnrollment(): ${error}`);
      throw error;
    }
  }

  static async getStudentExternshipCourses(
    enrollmentId: number,
    campusStudentId: number,
    candidateId: number
  ): Promise<ApiResponse<CourseData[]>> {
    try {
      const response = await api.get<ApiResponse<CourseData[]>>(
        `${this.path}/enrollment/${enrollmentId}/student/${campusStudentId}/candidate/${candidateId}`
      );

      return response.data;
    } catch (error: any) {
      console.error(
        `Error for EnrollmentApi.getStudentExternshipCourses(): ${error}`
      );
      throw error;
    }
  }

  static async getCampusJobTitles(
    enrollmentId: number
  ): Promise<ApiResponse<CampusJobtitles[]>> {
    try {
      const response = await api.get<ApiResponse<CampusJobtitles[]>>(
        `${this.path}/enrollments/${enrollmentId}/jobTitles`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for EnrollmentApi.getCampusJobTitles(): ${error}`);
      throw error;
    }
  }

  static async getExternshipBeginDates(): Promise<ApiResponse<any>> {
    try {
      const response = await api.get<ApiResponse<any>>(`${this.path}/dates/`);

      return response.data;
    } catch (error: any) {
      console.error(
        `Error for EnrollmentApi.getExternshipBeginDates(): ${error}`
      );
      throw error;
    }
  }

  static async getExternshipCourses(): Promise<ApiResponse<CourseData[]>> {
    try {
      const response = await api.get<ApiResponse<CourseData[]>>(
        `${this.path}/courses/`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for EnrollmentApi.getExternshipCourses(): ${error}`);
      throw error;
    }
  }
}
