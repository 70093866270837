import * as React from 'react';
import { Action } from '@pages/EcAdmin/ContentManagementPage/EmployerSpotlightContent/CompanyCardWithActions';
import { AdminContentManagementApi } from '@api/AdminContentManagement.api ';
import { AutoCompleteOption } from '@components/Forms/ReactHookFormsAutoComplete';
import PartnerSearchAndList from '@components/AdminContentManagement/PartnerSearchAndList';
import { PremierPartner } from '@api/models/adminContentManagementApi.models';
import { useFetchAdminDashboardCompanyStatus } from '@common/fetches/useFetchAdminDashboardCompanyStatus';
import { useFetchPremierPartners } from '@common/fetches/useFetchPremierPartners';

const PARTNER_LIMIT = 20;

const PremierPartnersContent: React.FC = () => {
  const { partners, setPartners, loading } = useFetchPremierPartners();

  const { companies: allCompanies } = useFetchAdminDashboardCompanyStatus({
    permission: 'PREMIER_PARTNERS_AVAILABLE',
    active: true,
    profileComplete: true
  });

  const options = React.useMemo((): AutoCompleteOption[] => {
    const autocompleteOptions =
      allCompanies
        ?.sort((a, b) => a.companyName.localeCompare(b.companyName))
        .map((company) => ({
          label: `${company.id} - ${company.companyName}`,
          value: company.id,
          disable: partners.some((p) => p.companyId === company.id)
        })) || [];

    return autocompleteOptions;
  }, [partners, allCompanies]);

  const addPromotedCompany = async (
    searchValue: AutoCompleteOption
  ): Promise<void> => {
    try {
      const partners = await AdminContentManagementApi.addPromotedPartner(
        searchValue?.value
      );
      setPartners(partners);
    } catch (error) {
      console.error(
        'Error for PremierPartnersContent -> AdminContentManagementApi.addPremierPartner()',
        error
      );
    }
  };

  const handleDelete = async (id: number): Promise<void> => {
    try {
      const partners = await AdminContentManagementApi.deletePremierPartner(id);
      setPartners(partners);
    } catch (error: any) {
      console.error(
        `Error for PremierPartnersContent -> AdminContentManagementApi.deletePremierPartner(): ${error}`
      );
    }
  };

  const getActions = (partner: PremierPartner): Action[] => {
    return [
      {
        icon: 'ri-delete-bin-6-line',
        label: 'delete',
        onClick: (): Promise<void> => handleDelete(partner.companyId)
      }
    ];
  };

  const companyDetails = (
    partner: PremierPartner
  ): { icon: string; label: string }[] => {
    return [
      { icon: 'ri-map-pin-2-fill', label: partner.email },
      {
        icon: 'ri-calendar-event-fill',
        label: 'Joined: ' + partner.createdDate
      }
    ];
  };

  return (
    <PartnerSearchAndList
      pageTitle="Premier Partners"
      pageDescription="Highlight Gold Crown employers on the homepage and the candidate dashboard. Logos from highlighted companies logos appear in a cycling carousel and link to the company profile."
      searchProps={{
        id: 'premier-partner-autocomplete',
        options,
        ctaText: 'Add Partner',
        onCtaClick: addPromotedCompany,
        requiredMsg: 'Please select a company',
        disabledErrMsg: `You may only select ${PARTNER_LIMIT}. Delete a company to add another.`,
        placeholder: 'Search for a company'
      }}
      loading={loading}
      getCardActions={getActions}
      getCompanyDetails={companyDetails}
      partners={partners}
    />
  );
};

export default PremierPartnersContent;
