import * as React from 'react';
import { Box, styled } from '@mui/system';
import ChefHat from '@assets/svg/chef_hat.round.gray1.svg';
import ImageWithBadge from './ImageWithBadge';

const Styled = {
  Card: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'clickable'
  })<{ clickable: boolean }>(({ clickable, theme }) => ({
    border: `1px solid ${theme.palette.GRAY_2.main}`,
    borderRadius: '4px',
    display: 'flex',
    padding: '8px',
    columnGap: '16px',
    ':hover': {
      backgroundColor: theme.palette.GRAY_1.main,
      transition: 'background-color 0.5s ease',
      cursor: clickable ? 'pointer' : 'unset'
    }
  })),
  ImageWrapper: styled(Box)({
    img: {
      objectFit: 'scale-down',
      backgroundColor: 'white'
    }
  })
};

interface Props {
  onClick?: () => void;
  imageUrl?: string;
}

const BasicImageCardWithContent: React.FC<Props> = ({
  onClick,
  imageUrl,
  children
}) => {
  return (
    <Styled.Card onClick={onClick} clickable={!!onClick}>
      <Styled.ImageWrapper>
        <ImageWithBadge src={imageUrl || ChefHat} width="134px" height="77px" />
      </Styled.ImageWrapper>
      {children}
    </Styled.Card>
  );
};

export default BasicImageCardWithContent;
