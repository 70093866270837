import { api } from './configs/base';
import { apiConfig } from './configs/api.config';
import { ApiResponse } from './models/base.models';
import { FileType } from './models/fileApi.models';

export class FilesApi {
  static async upload(
    file: any,
    secure: boolean = false
  ): Promise<ApiResponse<FileType>> {
    const path = secure ? apiConfig.secureFilesUrl : apiConfig.filesUrl;

    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await api.post<ApiResponse<FileType>>(
        path,
        formData
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for FilesApi.upload(): ${error}`);
      throw error;
    }
  }

  static async getFile(
    file: string,
  ): Promise<ApiResponse<FileType>> {
    try {
      const response = await api.get<ApiResponse<FileType>>(
        `${apiConfig.filesUrl}/files/${file}`
      );

      return response.data;
    } catch (error: any) {
      console.error(`Error for FilesApi.getFile(): ${error}`);
      throw error;
    }
  }
}
