export const getUIBaseURL = (): string => {
  const env = process.env.REACT_APP_FEATURE_FLAG_ENV;

  const isProd = env === 'prod';

  let baseURL: string;

  if (isProd) {
    baseURL = 'https://go.escoffierglobal.com';
  } else {
    // Could be dev or test. If it's local host will stay as dev
    baseURL = `https://${env}.go.escoffierglobal.com`;
  }
  return baseURL;
};
