import * as React from 'react';
import CandidateAboutStep from '@pages/Candidate/CandidateProfileSetupPage/CandidateAboutStep/CandidateAboutStep';
import CandidateExperienceStep from '@pages/Candidate/CandidateProfileSetupPage/CandidateExperienceStep/CandidateExperienceStep';
import CandidatePersonalizeStep from '@pages/Candidate/CandidateProfileSetupPage/CandidatePersonalizeStep/CandidatePersonalizeStep';
import ProfileSetupStepper from '@components/ProfileSetupStepper';

const steps = ['ABOUT', 'EXPERIENCE', 'PERSONALIZE'];

const CandidateProfileSetupPage: React.FC = () => {
  const [loadingStep, setLoadingStep] = React.useState(false);

  const stepContent = (handleStepper: (next: boolean) => void) => {
    return (step: number): React.ReactElement => {
      let content: React.ReactElement;

      switch (step) {
        case 0:
          content = <CandidateAboutStep handleStepper={handleStepper} />;
          break;
        case 1:
          content = <CandidateExperienceStep handleStepper={handleStepper} />;
          break;
        default:
          content = <CandidatePersonalizeStep handleStepper={handleStepper} />;
          break;
      }
      return content;
    };
  };

  return (
    <ProfileSetupStepper
      steps={steps}
      stepContent={stepContent}
      loading={loadingStep}
      setLoading={setLoadingStep}
    />
  );
};

export default CandidateProfileSetupPage;
