import * as React from 'react';
import { IconButton, IconButtonProps, SxProps, Theme } from '@mui/material';

interface EllipseButtonProps extends IconButtonProps {
  iconClassName?: string;
  iconColor?: string;
  btnSx?: SxProps<Theme>;
  dataTestId?: string;
}

const EllipseButton = (props: EllipseButtonProps): React.ReactElement => {
  const {
    iconClassName = 'ri-more-fill',
    iconColor = '#000000',
    btnSx,
    dataTestId = 'ellipse-button-test-id',
    ...buttonProps
  } = props;

  return (
    <IconButton
      aria-label="more options"
      sx={{ color: iconColor, ...btnSx }}
      data-testid={dataTestId}
      {...buttonProps}
    >
      <i className={iconClassName} />
    </IconButton>
  );
};

export default EllipseButton;
