import * as React from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/system';

const Styled = {
  Form: styled('form', {
    shouldForwardProp: (prop) => prop !== 'isCentered' && prop !== 'fullWidth'
  })<{ isCentered?: boolean; fullWidth: boolean }>(
    ({ isCentered, fullWidth }) => ({
      maxHeight: '44px',
      width: '100%',
      margin: isCentered ? '0 auto' : 'unset',
      ...(!fullWidth && {
        maxWidth: '340px'
      })
    })
  ),
  Label: styled(Typography)({
    lineHeight: 'unset'
  })
};

export interface SearchBarProps {
  name?: string;
  value?: string;
  placeholder?: string;
  searchParams?: string[];
  showIcon?: boolean;
  fullWidth?: boolean;
  iconPosition?: 'start' | 'end';
  isCentered?: boolean;
  customStyle?: React.CSSProperties;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearSearch: () => void;
  onSubmit?: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  name = 'search',
  value,
  placeholder,
  searchParams,
  showIcon = true,
  fullWidth = true,
  iconPosition = 'end',
  isCentered = false,
  customStyle,
  onChange,
  onSubmit,
  clearSearch
}) => {
  const handleSubmit = React.useCallback(
    (e: React.FormEvent<HTMLElement>) => {
      e.preventDefault();
      onSubmit && onSubmit();
    },
    [onSubmit]
  );

  const handleMouseDown = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault();
  };

  const SearchButton = (
    <InputAdornment position={iconPosition}>
      <IconButton
        data-testid="ec-search-bar-search-btn"
        aria-label="search"
        onClick={onSubmit}
        onMouseDown={handleMouseDown}
        edge="start"
      >
        <SearchIcon />
      </IconButton>
    </InputAdornment>
  );

  const ClearButton = (
    <InputAdornment position={iconPosition}>
      <IconButton
        data-testid="ec-search-bar-clear-btn"
        aria-label="clear"
        onClick={clearSearch}
        onMouseDown={handleMouseDown}
        edge="end"
        color="primary"
      >
        <ClearIcon />
      </IconButton>
    </InputAdornment>
  );

  const label = searchParams
    ? `Search by ${searchParams.join(', ')}`
    : undefined;

  return (
    <Box sx={{ ...(fullWidth && { width: '100%' }) }}>
      <Styled.Form
        onSubmit={handleSubmit}
        isCentered={isCentered}
        fullWidth={fullWidth}
        sx={customStyle}
      >
        <OutlinedInput
          name={name}
          value={value ?? ''}
          placeholder={placeholder}
          onChange={onChange}
          fullWidth={fullWidth}
          startAdornment={showIcon && SearchButton}
          endAdornment={showIcon && !!value && ClearButton}
          sx={{ ...customStyle, width: '100%' }}
          inputProps={{
            'data-testid': 'ec-search-bar'
          }}
        />
        <Styled.Label variant="EC_TYPE_XS">{label}</Styled.Label>
      </Styled.Form>
    </Box>
  );
};

export default SearchBar;
