import { Box, Typography } from '@mui/material';
import contactUsImg from '@assets/employer-contact-us.png';
import React from 'react';
import { styled } from '@mui/system';

const Styled = {
  Wrapper: styled(Box)({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    margin: '20px 0'
  }),
  TextWrapper: styled(Box)({
    maxWidth: '350px',
    padding: '10px 40px'
  }),
  TextItem: styled(Typography)(({ theme }) => ({
    color: theme.palette.BLACK.main,
    lineHeight: '25px',
    fontWeight: 400,
    paddingTop: '20px'
  })),
  EmailText: styled(Typography)({
    fontSize: '15px',
    fontWeight: 700
  }),
  ImageWrapper: styled(Box)({
    '& .contact-us-img': {
      maxWidth: '317px',
      maxHeight: '211px'
    }
  })
};

const ContactUsSection: React.FC = () => {
  return (
    <Styled.Wrapper>
      <Styled.ImageWrapper>
        <img src={contactUsImg} alt="contact us" className="contact-us-img" />
      </Styled.ImageWrapper>

      <Styled.TextWrapper>
        <Typography
          variant="AEGS_TYPE_PARAGRAPH"
          sx={{
            fontSize: '20px',
            fontWeight: 700
          }}
        >
          If you have any externship related questions, please contact us:
        </Typography>

        <Styled.TextItem variant="AEGS_TYPE_PARAGRAPH">
          Jane Jepson, <br /> Senior Director of Career Services <br />
          Escoffier Career Services Department <br />
          855-224-0453
        </Styled.TextItem>

        <Styled.EmailText variant="AEGS_TYPE_PARAGRAPH" color="primary.main">
          careerservices@escoffier.edu
        </Styled.EmailText>
      </Styled.TextWrapper>
    </Styled.Wrapper>
  );
};

export default ContactUsSection;
