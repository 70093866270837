import * as React from 'react';
import { Box, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/system';

const Styled = {
  Label: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'clickable'
  })<{ clickable?: boolean }>(({ clickable, theme }) => ({
    fontWeight: 700,
    '&:hover': {
      color: clickable ? theme.palette.primary.main : 'default',
      cursor: clickable ? 'pointer' : 'default',
      textDecoration: clickable ? 'underline' : 'default'
    }
  }))
};

interface Props {
  label: string;
  value: string | number;
  onClick?: () => void;
  labelVariant?: TypographyProps['variant'];
  valueVariant?: TypographyProps['variant'];
  columnGap?: string;
}

const LabelWithValue: React.FC<Props> = ({
  label,
  value,
  onClick,
  labelVariant = 'EC_TYPE_LG',
  valueVariant = 'EC_TYPE_BASE',
  columnGap = '8px'
}) => {
  return (
    <Box sx={{ display: 'flex', columnGap }} onClick={onClick}>
      <Styled.Label variant={labelVariant} clickable={!!onClick}>
        {label}:
      </Styled.Label>
      <Typography variant={valueVariant}>{value}</Typography>
    </Box>
  );
};

export default LabelWithValue;
