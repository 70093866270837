import { useEffect, useState } from 'react';
import { GlrcContentApi } from '@api/GlrcContent.api';
import { GlrcTopic } from '@interfaces/glrc.interfaces';

interface FetchGlrcContent {
  glrcTopics?: GlrcTopic[];
  loading: boolean;
  loaded: true | null;
}

export const useFetchGlrcTopics = (companyId: number): FetchGlrcContent => {
  const [glrcTopics, setGlrcTopics] = useState<GlrcTopic[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<true | null>(null);

  const fetch = async (): Promise<void> => {
    try {
      setLoading(true);

      const res = await GlrcContentApi.getPrepared(companyId);

      setGlrcTopics(res);
    } catch (error: any) {
      console.error(
        `Error for useFetchGlrcTopics -> GlrcContentApi.getPrepared(): ${error}`
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return { glrcTopics, loading, loaded };
};
