interface CacheData<T> {
  data: T;
  timestamp: number;
}

/**
 * Generic utility for caching data in localStorage with timeout functionality
 */
export class LocalStorageCache<T> {
  private cacheKey: string;
  private timeoutMs: number;

  /**
   * Creates a new LocalStorageCache instance
   * @param cacheKey Unique key used to store data in localStorage
   * @param timeoutMinutes Cache expiry time in minutes (defaults to 15 minutes)
   */
  constructor(cacheKey: string, timeoutMinutes = 15) {
    this.cacheKey = cacheKey;
    this.timeoutMs = timeoutMinutes * 60 * 1000;
  }

  /**
   * Retrieves data from cache if available and not expired
   * @returns The cached data or null if not found or expired
   */
  get(): T | null {
    try {
      const cacheString = localStorage.getItem(this.cacheKey);
      
      if (cacheString) {
        const cache = JSON.parse(cacheString) as CacheData<T>;
        const now = Date.now();
        
        // Check if cache is still valid (not expired)
        if (cache.data && now - cache.timestamp < this.timeoutMs) {
          return cache.data;
        }
      }
      
      return null;
    } catch (error) {
      console.error(`Error accessing cache for key ${this.cacheKey}:`, error);
      return null;
    }
  }

  /**
   * Stores data in cache with current timestamp
   * @param data The data to be cached
   */
  set(data: T): void {
    try {
      const cacheData: CacheData<T> = {
        data,
        timestamp: Date.now(),
      };
      
      localStorage.setItem(this.cacheKey, JSON.stringify(cacheData));
    } catch (error) {
      console.error(`Error setting cache for key ${this.cacheKey}:`, error);
    }
  }
}
